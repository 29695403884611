import { dataTypes, regexTypes, stateswithOptionalEmployeeStatus } from '../constants';
import { toMMDDYYYYFormatSlash } from '../../../utils/formatters/dates';
import { getDOBFromAge, getAgeFromDOB } from '../../../utils/formatters/numbers';
import { dropDowns, statesWithNonBinary } from "../constants";
import moment from "moment";

export const getReduxStoreReference = () => {
    const { store } = require('../../../index');
    return store;
}


export const checkQuotingStatus = (value) => {
    if (typeof value === dataTypes.string) {
        return value === 'Quote' ? true : false
    }
    else if (typeof value === dataTypes.object) {
        let key = Object.keys(value)[0];
        return value[key];
    }
    else
        return false;
};

export const isEmpty = (value) => value === null || value === undefined || value === '';

export const isNotEmpty = (value) => value !== null && value !== undefined && value !== '';

export const getDobFromAge = (args) => {
    if (args.dob) return args.dob;

    if (isEmpty(args.age) && !args.age) {
        return '';
    }

    let effectiveDate = ''
    if (args.effectiveDate) {
        // effectiveDate = formatToMMDDYYYYFormatSlash(args.effectiveDate);
        effectiveDate = moment(args.effectiveDate).format('MM/DD/YYYY');
    } else {
        let { companyProfile } = getReduxStoreReference().getState();
        effectiveDate = companyProfile.effectiveDate
    }
    return getDOBFromAge(args.age, effectiveDate, false);
}

export const getAgeFromDob = (args) => {
    if (args.age) return args.age;

    if (isEmpty(args.dob)) {
        return '';
    }
    let effectiveDate = ''
    if (args.effectiveDate) {
        // effectiveDate = formatToMMDDYYYYFormatSlash(args.effectiveDate);
        effectiveDate = moment(args.effectiveDate).format('MM/DD/YYYY');
    } else {
        let { companyProfile } = getReduxStoreReference().getState();
        effectiveDate = companyProfile.effectiveDate
    }
    // let dob = formatToMMDDYYYYFormatSlash(args.dob);
    let dob = moment(args.dob).format('MM/DD/YYYY');
    let a = getAgeFromDOB(dob, effectiveDate);
    return a;
}

export const isMedicalOnly = (args) => {
    let products = args.selectedProducts;
    let isMedicalOnly = true;
    Object.keys(products).forEach(key => {
        if (key !== 'medical' && products[key]) {
            isMedicalOnly = false;
        }
    });
    return isMedicalOnly;
}

export const isWaived = (value) => {
    return value === 'Waive' ? true : false;
}

/** If format date in ISO String format */
export const formatDate = (value) => {
    if (typeof value === dataTypes.object && value instanceof Date) {
        return value.toISOString();
    } else {
        return value;
    }
}

/** If value has @ in excel then the value becomes object with text property */
export const formatIfHyperLink = (value) => {
    if (typeof value === dataTypes.object && value.hasOwnProperty('text')) {
        return value.text;
    }
    return value;
}

/** Append zeroes to make length 7 */
export const appendLeadingZeros = (value) => {
    if (typeof value === dataTypes.number) {
        value = value.toString();
    }
    if (typeof value === dataTypes.string && value.length < 7) {
        let numZeros = 7 - value.length;
        return '0'.repeat(numZeros) + value;
    }
    return value;
}

/* Returns date in MM/DD/YYYY format if valid date otherwise return blank string */
export const formatToMMDDYYYYFormatSlash = (value) => {

    if (isValidDate(value)) {
        if (typeof value === dataTypes.string) {
            return value;
        }
        let date = new Date(value);
        // return moment(date).format('MM/DD/YYYY');
        return toMMDDYYYYFormatSlash(date.toISOString());
    }
    return '';
}

/* Checks if date is valid */
// For string type date, it should always be in MM/DD/YYYY format
export const isValidDate = (value) => {
    if (isEmpty(value))
        return false;
    if ((typeof value === dataTypes.object && value instanceof Date) || (typeof value === dataTypes.string && regexTypes.date.test(value))) {
        if (!isNaN(new Date(value))) {
            return true;
        }
    }
    return false;
}

export const mapSuffix = (suffixValue) => {
    switch (suffixValue) {
        case 'Jr.':
            return 'JR';
        case 'Sr.':
            return 'SR';
        case 'I':
            return '1';
        case 'II':
            return '2';
        case 'III':
            return '3';
        case 'IV':
            return '4';
        case 'V':
            return '5';
        case 'VI':
            return '6';
        case 'VII':
            return '7';
        case 'VIII':
            return '8';
        default:
            return '';
    }
};

export const mapSuffixReverse = (suffixValue) => {
    switch (suffixValue) {
        case 'JR':
            return 'Jr.';
        case 'SR':
            return 'Sr.';
        case '1':
            return 'I';
        case '2':
            return 'II';
        case '3':
            return 'III';
        case '4':
            return 'IV';
        case '5':
            return 'V';
        case '6':
            return 'VI';
        case '7':
            return 'VII';
        case '8':
            return 'VIII';
        default:
            return '';
    }
};

export const stringToCoverageCode = codeString => {
    switch (codeString) {
        case 'EE':
        case 'Employee Only':
            return 'EE';
        case 'ES':
        case 'Employee & Spouse':
            return 'EE/SP';
        case 'EC':
        case 'Employee & Child':
            return 'EE/CH';
        case 'FAM':
        case 'Employee & Family':
            return 'EE/FAM'
        case 'W':
        case 'Waive':
            return 'W'
        case 'EL':
        case 'Elected':
            return 'EL'
        default:
            return 'W';
    }
}


/* Check if dob is filled or not */
export const isDobFilled = (args) => {
    return args.dob ? true : false;
}

export const handleGender = (value) => {
    switch (value) {
        case 'M':
        case 'Male':
            return 'Male'
        case 'F':
        case 'X'://for papercase submitetd census
        case 'Female':
            return 'Female'
    }
}

export const isWaivingAll = (value) => {
    if (value === 'Waive All Products') {
        return 'Yes'
    }
    return value
}

export const isWaivingAllNo = (value) => {
    if (value === 'Waive All Products') {
        return false
    }
    return true
}

export const isProductWaived = (value) => {
    if (value === 'waive') {
        return 'waive'
    }
    return value
}

export const isMedicalWaived = (value) => {
    if (value === 'waive') {
        return 'medical'
    }
    return undefined
}

export const isDentalWaived = (value) => {
    if (value === 'waive') {
        return 'dental'
    }
    return undefined
}

export const isVisionWaived = (value) => {
    if (value === 'waive') {
        return 'vision'
    }
    return undefined
}

export const isLifeWaived = (value) => {
    if (value === 'waive') {
        return 'life'
    }
    return undefined
}

export const isSuppLifeWaived = (value) => {
    if (value === 'waive') {
        return 'supplife'
    }
    return undefined
}

export const isSTDWaived = (value) => {
  if (value === 'waive') {
      return 'std'
  }
  return undefined
}

export const isLTDWaived = (value) => {
  if (value === 'waive') {
      return 'ltd'
  }
  return undefined
}

export const isAutoAssignedPCP = (value) => {
    if (value === 'Auto Assign') {
        return false
    }
    return true
}

export const isYesSelected = (value) => {
    if (value === 'Yes') {
        return true
    }
    return false
}

export const isCOBRAEmployee = (value) => {
    if (value === 'COBRA') {
        return true
    }
    return false
}

export const getMedicalWaiverReasons = () => {
    const store = getReduxStoreReference();
    const { rules: { enrollmentForm: { employee: enrollmentFormEmployees = [] } } } = store.getState();
    if (enrollmentFormEmployees && enrollmentFormEmployees.length > 0) {
        const waiveCoverageForm = enrollmentFormEmployees.find(form => form.sectionName === 'waiveCoverage');
        const medicalWaiveReasonForm = waiveCoverageForm.fields.find(form => form.fieldName === 'medicalWaiverReason');
        const medicalWaiverOptions = medicalWaiveReasonForm.dropdownOptions[0].options;
        return medicalWaiverOptions;
    }
    return dropDowns.medicalWaiverReason
}

export const valueEvaluator = (value) => {
    return value || '';
}

export const generatePlanCodes = (plans, type) => {
    const codes = [];
    if (plans.length > 0) {
        if (type === 'medical') {
            for (const plan of plans) {
                codes.push(plan.codeDisplay);
            }
        } else {
            for (const plan of plans) {
                codes.push(plan.code);
            }
        }
    }
    codes.push('', 'waive');
    return codes;
};

export const generateSpouseSuppLifePlanCodes = (plans, type, relationship) => {
    const codes = [];
    if (type === 'suppLife') {
        for (const plan of plans) {
            if (plan.checked === 'SuppDepLife' && plan.SLSpPlan.lifeBenefitType === 'Spouse') {
                relationship = 'Spouse'
                codes.push(plan.SLSpPlanCode);
            }
        }
    }
    codes.push('', 'waive');
    return codes;
};

export const generateChildSuppLifePlanCodes = (plans, type, relationship) => {
    const codes = [];
    if (type === 'suppLife') {
        for (const plan of plans) {
            if (plan.checked === 'SuppDepLife' && plan.SLChPlan.lifeBenefitType === 'Child') {
                relationship = 'Child'
                codes.push(plan.SLChPlanCode);
            }
        }
    }
    codes.push('', 'waive');
    return codes;
};

export const checkIfPlanIsPCP = (enrolledMedicalPlans, planCode) => {
    const medicalPlan = enrolledMedicalPlans.find(plan => plan.codeDisplay === planCode);
    if (medicalPlan) {
        return medicalPlan.pcpRequired;
    } else {
        return false;
    }
};

export const checkIfPCPRequired = (employee, index) => {
    if (employee.pcpAssignment && employee.pcpAssignment[index] && employee.pcpAssignment[index].assignmentMethod && (employee.pcpAssignment[index].assignmentMethod === 'Auto Assign' || employee.pcpAssignment[index].assignmentMethod === 'N/A')) {
        return false;
    }
    return true;
}

export const isPCPDisabled = (employee, index) => {
    if (employee.pcpAssignment && employee.pcpAssignment[index] && employee.pcpAssignment[index].assignmentMethod && (employee.pcpAssignment[index].assignmentMethod === 'Auto Assign' || employee.pcpAssignment[index].assignmentMethod === 'N/A')) {
        return true;
    }
    return false;
}

export const formatSSN = (value) => {
    const allDigitsRegex = new RegExp('^[0-9]{9}$');
    const allDigitsCheck = allDigitsRegex.test(value);
    if (allDigitsCheck) {
        const stringSsn = value.toString();
        const ssnPt1 = stringSsn.slice(0, 3);
        const ssnPt2 = stringSsn.slice(3, 5);
        const ssnPt3 = stringSsn.slice(5, 9);

        const transformedSsn = ssnPt1 + '-' + ssnPt2 + '-' + ssnPt3;
        return transformedSsn.padStart(9, "0")
    }
    else if(value && value.toString().length < 9){
        const valueWithoutZeros= value.toString().padStart(9, "0");
        const stringWithLeadingZeros=valueWithoutZeros.slice(0, 3) + '-' + valueWithoutZeros.slice(3, 5) + '-' + valueWithoutZeros.slice(5, 9);
        return stringWithLeadingZeros;
    }
   else if(value){
        return value.toString();
    }
}

export const getMinNumOfHours = () => {
    const store = getReduxStoreReference();
    const { enrollment } = store.getState();
    const minNumOfHours = enrollment?.application?.eligibility?.participation?.numHoursPerWeekToBeEligible || 0
    return minNumOfHours
}

export const getRelationship = (value) => {
    let relation = '';
    switch (value) {
        case 'SP':
            relation = dropDowns.relationship[1];
            break;
        case 'CH':
        case 'ST':
        case 'HM':
            relation = dropDowns.relationship[0];
            break;
        default:
            relation = value;
    }
    return relation;
}

export const formatNumberToString=(value) =>{
    const stringValue = value ? value.toString() : '' 
    return stringValue;
}

  export const zipLeadingZerosToString=(value) =>{
      const stringValue = value ? value.toString() : '' 
      if(stringValue!=='' && stringValue.length >= 1 && stringValue.length <= 5){
          return stringValue.padStart(5, "0")
      }
     else if(stringValue!=='' && stringValue.length > 5 ){
        return stringValue.padStart(9, "0")
     }
      return stringValue;
  }

  export const formatNumberOfHours=(value) =>{
      const numValue = parseInt(value);
      return !isNaN(numValue) && numValue >= 50 ? "40" : isNaN(numValue) ? '' : numValue.toString()
    
}

export const formatPhoneNumber = (phoneNumber) => {
    const allDigitsRegex = new RegExp('^[0-9]{10}$');
    const phoneNumDashRegex = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$');

    //If phone number provided is all digits, transform to dash format.
    const allDigitsCheck = allDigitsRegex.test(phoneNumber);
    if (allDigitsCheck) {
        const stringPhoneNumb = phoneNumber.toString();
        const phoneNumPt1 = stringPhoneNumb.slice(0, 3);
        const phoneNumPt2 = stringPhoneNumb.slice(3, 6);
        const phoneNumPt3 = stringPhoneNumb.slice(6, 10);

        const transformedPhoneNum = phoneNumPt1 + '-' + phoneNumPt2 + '-' + phoneNumPt3;
        return transformedPhoneNum
    }
}

export const getDependentDropdown = (employee, dependentIndex) => {
    const relationshipToHandle = ['Spouse', 'Domestic Partner', 'Civil Union Partner'];
    const dependents = employee.dependents || [];
    if (dependents.filter(dependent => relationshipToHandle.includes(dependent.relationship)).length > 0) {
        const index = dependents.findIndex(dependent => relationshipToHandle.includes(dependent.relationship));
        if (dependentIndex === index) {
            return dropDowns.relationship;
        } else {
            return ['Child'];
        }
    }
    return dropDowns.relationship;
}

export const getEmployeeAndDependentGender = () => {
    const store = getReduxStoreReference();
    const { zipData: { stateCode } } = store.getState().companyProfile.locations[0];
    if (stateCode && statesWithNonBinary.includes(stateCode)) {
        return dropDowns.nonBinaryGender
    }
    return dropDowns.gender
}

export const getUpdatedEmployeeStatusOptions=()=>{
    const store=getReduxStoreReference();
    const { zipData: { stateCode } } = store.getState().companyProfile.locations[0];
    if (stateCode && stateswithOptionalEmployeeStatus.includes(stateCode)) {
        return dropDowns.employeeStatusOptions
    }
    return dropDowns.empStatus;
}

export const isWorkersCompSelected = () => {
    const store = getReduxStoreReference();
    const { workersComp = "" } = store.getState().enrollment?.application?.employer?.additionalGeneralInformation || {};
    if (workersComp === "Yes") {
        return  true;
    }
    return false;
}