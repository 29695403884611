
import React from 'react';
import ModalPopUp from './ModalPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { notifyTechnicalError } from '../../../actions/errorHandlerActions';
import { ineligibleCaseNotif, isEligibleToFinalize, displayUnsupportedOxfordZipCode } from '../../../actions/overallActions';
import { sendErrorTrackingDataAction } from '../../../actions/analyticsAction';

/**
 * Modal popup to display the Alert when there is an unhandled exception
 * @param {*} props Display is the input properties and onButtonClick is the callback or output action that sends the action performed on the Modal popup. 
 */
const ErrorHandlingModalPopup = (props) => {
    const dispatch = useDispatch();
    const errorHandler = useSelector((state) => state.errorHandler)
    const overall = useSelector((state) => state.overall ) 
    const { onButtonClick } = props;


    const message_error =
        `There is a technical issue preventing this activity at this time, please contact us at ${overall.platform === 'PRIME' ? '1-866-764-7736' : '888-201-4216'}. <br /><br />
     The SAMx Support Team is available Monday through Friday 8 a.m. - 5 p.m. EST. <br /><br />
     For technical issues related specifically to the NH UnitedHealthcare Freedom Plan, please contact us at 1-866-764-7735.`;

    const message_NB =
        `This case is no longer eligible to submit for installation, you will need to modify your quote to an eligible effective date.`

    const message_RN =
        `This case is no longer eligible to submit for installation due to the effective date.`
    
    const message_Oxford_Out_Of_Coverage = 
        `Plans for the zip code specified are not available.`  
    /**
     * Method to handle the button click performed on the popup.  
     * This method send the action info to the consumer of this component.
     * @param {*} eventData 
     */
    const handlePopupButtonClick = (eventData) => {
        if(overall.ineligibleCaseNotif){
            dispatch(ineligibleCaseNotif(false))
            dispatch(isEligibleToFinalize(false))
            sendErrorTrackingDataAction({
                activityName : `Case Ineligible`,
                errorFields :`Details: Case no longer eligible for submittion due to the effective date`,
                errorType :"Ui Error"
            })
        }
        else if(overall.unsupportedOxfordZip){
            dispatch(displayUnsupportedOxfordZipCode(false))
            sendErrorTrackingDataAction({
                activityName : `Unsupported Oxford Zip`,
                errorFields :`Details: ${message_Oxford_Out_Of_Coverage}`,
                errorType :"Ui Error"
            })
        }
        else{
            errorHandler.notifyError = false;
            errorHandler.actionType = 'apiRequest';
            dispatch(notifyTechnicalError(errorHandler));
        }

        if (onButtonClick !== undefined && typeof (onButtonClick) === "function") {
            onButtonClick(eventData);
        }
    }

    let message = ''
    if(overall.ineligibleCaseNotif){
        if(overall.isRenewals)
            message = message_RN;
        else message = message_NB;
    }
    else if(overall.unsupportedOxfordZip){
        message = message_Oxford_Out_Of_Coverage;
    }
    else{
        message = message_error;
    }

    return (
        overall.ineligibleCaseNotif || errorHandler.notifyError || overall.unsupportedOxfordZip?
            <ModalPopUp
                type='alert'
                size='large'
                message={message}
                onButtonClick={(eventData) => handlePopupButtonClick(eventData)} /> :
            ''
    );
}

export default ErrorHandlingModalPopup;