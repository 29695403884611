import * as types from '../../actions/actionTypes';
import { handleRequestPlans, handleReceiveSpecialtyPlans } from './helpers/fetchingReducerHelpers';
import { initialFilterCriteria, initialState } from './consts/dentalReducerConsts';

export default function sul(state = initialState, action) {

    switch (action.type) {
        case types.SERVICE.SUL.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state,action, initialFilterCriteria, initialFilterCriteria);

        case types.SERVICE.SUL.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action.payload);

        default:
            return state
    }
}