import { isUhcRenewals } from '../../utils/businessLogic/platformUtils';
import { fiftyStates } from './JSONconsts';

export default function returnJson(life, overallPlatform, state, isUHCNB) {

    const json = {
        waiveCoverage: {
            isWaivingAll: {
                fieldNameDisplay: 'Is this employee waiving coverage for all products?',
                fieldType: 'dropdown',
                isRequired: true,
                dropdownOptions: [
                    {
                        options: [
                            'Yes',
                            'No',
                        ],
                    },
                ],
            },
        },
        employeeInfo: {
            firstName: {
                fieldNameDisplay: 'First Name',
                fieldType: 'textbox',
                isRequired: true,
                validations: isUhcRenewals() ? [
                    {
                        validationType: 'inputRegex',
                        value: '^([a-z A-Z.]|-)*$',
                        regexDescription: 'Must be a letter or . or -',
                    },
                    {
                        validationType: 'maxLength',
                        value: 35,
                    }
                ]
                :[
                    {
                        validationType: 'maxLength',
                        value: 35,
                    },
                    {
                        validationType: 'lettersOnly'
                    }
                ]
            },
            middleInitial: {
                fieldNameDisplay: 'Middle Initial',
                fieldType: 'textbox',
                isRequired: false,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'inputRegex',
                        value: '^[A-Z]$',
                        regexDescription: 'Must be one capital letter',
                    },
                ],
            },
            lastName: {
                fieldNameDisplay: 'Last Name',
                fieldType: 'textbox',
                isRequired: true,
                validations: isUhcRenewals() ? [
                    {
                        validationType: 'inputRegex',
                        value: '^([a-z A-Z.]|-)*$',
                        regexDescription: 'Must be a letter or . or -',
                    },
                    {
                        validationType: 'maxLength',
                        value: 60,
                    },
                    
                ] : [
                    {
                        validationType: 'maxLength',
                        value: 60,
                    },
                    {
                        validationType: 'lettersOnly'
                    }
                ],
            },
            suffix: {
                fieldNameDisplay: 'Suffix',
                fieldType: 'dropdown',
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                dropdownOptions: [
                    {
                        options: [
                            ' , ',
                            'JR,Jr.',
                            'SR,Sr.',
                            '1,I',
                            '2,II',
                            '3,III',
                            '4,IV',
                            '5,V',
                            '6,VI',
                            '7,VII',
                            '8,VIII',
                        ],
                    },
                ],
            },
            ssnTin: {
                fieldNameDisplay: 'SSN or ITIN',
                fieldType: 'textbox',
                isRequired: true,
                showIf: {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'appState',
                            appState: {
                                stateKey: 'employeeInfo',
                                stateSubKey: 'ssnCheck',
                                logic: 'is',
                                stateValue: false,
                            },
                        },
                        {
                            "conditionType": "appState",
                            "appState": {
                                "stateKey": "waiveCoverage",
                                "stateSubKey": "isWaivingAll",
                                "logic": "is not",
                                "stateValue": "Yes"
                            }
                        },
                    ],
                },
                validations: [
                    {
                        validationType: 'ssn',
                    },
                ],
                helpContent: {
                    question: 'SSN Rules',
                    content: 'The same consecutive number such as ‘111-11-1111’ is invalid. The following numbers are invalid: ‘123-45-6789’, ‘219-09-9999’, ‘078-05-1120’, ‘987-65-4320’, ‘987-65-4329’. Numbers with ‘666’ or ‘000’ are invalid as the first three digits. Number with ‘00’ is invalid as the middle two digits. Number with ‘0000’ is invalid as the last 4 digits. Incrementing number such as ‘123-45-6789’ or ‘234-56-7891’ are invalid.',
                },
            },
            noSSNReason: {
                fieldNameDisplay: 'Reason SSN is not provided',
                fieldType: 'dropdown',
                isRequired: true,
                showIf: {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'appState',
                            appState: {
                                stateKey: 'employeeInfo',
                                stateSubKey: 'ssnCheck',
                                logic: 'is',
                                stateValue: true,
                            },
                        },
                        {
                            "conditionType": "appState",
                            "appState": {
                                "stateKey": "waiveCoverage",
                                "stateSubKey": "isWaivingAll",
                                "logic": "is not",
                                "stateValue": "Yes"
                            }
                        },
                    ],
                },
                dropdownOptions: [
                    {
                        options: [
                            'Expatriate',
                            'Newborn',
                            'Refusal to Provide',
                            'Personal ID Number Satisfied',
                            'Unknown',
                        ],
                    },
                ],
            },
            ssnCheck: {
                fieldNameDisplay: 'SSN or ITIN not available',
                fieldType: 'checkbox',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                }
            },
            dob: {
                fieldNameDisplay: 'Date of Birth',
                fieldType: 'date',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'date',
                    },
                    {
                        validationType: 'dob',
                    },
                    {
                        validationType: 'validDOB',
                    },
                ],
            },
            gender: {
                fieldNameDisplay: 'Gender',
                fieldType: 'dropdown',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                dropdownOptions: [{
                    options: [
                        'Male',
                        'Female',
                    ],
                }],
            },
            numberOfHoursWorked: {
                fieldNameDisplay: 'Number of Hours Worked',
                fieldType: 'textbox',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'inputRegex',
                        value: '^([0-9]|[1-3][0-9]|40)$',
                        regexDescription: 'Maximum Upto 40',
                    },
                    // {
                    //     validationType: 'min',
                    //     value: 0,
                    // },
                    // {
                    //     validationType: 'max',
                    //     value: 40,
                    // },
                    {
                        validationType:'numberOfHours',
                    }
                ],
            },
            dateOfHire: {
                fieldNameDisplay: 'Date of Hire',
                fieldType: 'date',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'date',
                    },
                    {
                        validationType: 'validDateOfHire',
                    },
                ],
            },
            salary: {
                fieldNameDisplay: 'Salary',
                fieldType: 'textbox',
                isRequired: false,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'salary',
                    },
                ],
            },
            employeeStatus: {
                fieldNameDisplay: 'Employee Status',
                fieldType: 'dropdown',
                dropdownOptions: [{
                    options: overallPlatform && overallPlatform === 'PRIME' ? state === 'CA' && isUHCNB ? ['Active', 'COBRA','1099 Independent Contractors'] : ['Active', 'COBRA'] : ['Active', 'COBRA', 'Retired'], // No retired option for PRIME
                    "requiredConditions": "none"
                }],
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                }
            },
            cobraStartDate: {
                fieldNameDisplay: 'COBRA Start Date',
                fieldType: 'date',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "employeeInfo",
                            "stateSubKey": "employeeStatus",
                            "logic": "is",
                            "stateValue": "COBRA"
                        }
                    },
                    {
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'date',
                    },
                ],
            },
            cobraEndDate: {
                fieldNameDisplay: 'COBRA End Date',
                fieldType: 'date',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "employeeInfo",
                            "stateSubKey": "employeeStatus",
                            "logic": "is",
                            "stateValue": "COBRA"
                        }
                    }, {
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                validations: [
                    {
                        validationType: 'validCobraEndDate',
                    },
                ],
            },
            anyOtherCoverage: {
                fieldNameDisplay: 'On the day this coverage begins, will you, your spouse or any of your dependents be covered under any other medical health plan or policy including another UnitedHealthcare Plan?',
                fieldType: 'radio',
                isRequired: true,
                radioOptions: [
                    {
                        options: [
                            'Yes',
                            'No',
                        ],
                    },
                ],
                showIf: {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'selectedProducts',
                            selectedProducts: ['medical'],
                        },
                        {
                            "conditionType": "appState",
                            "appState": {
                                "stateKey": "waiveCoverage",
                                "stateSubKey": "isWaivingAll",
                                "logic": "is not",
                                "stateValue": "Yes"
                            }
                        },
                    ],
                },
            },
            medicarePrimary: {
                fieldNameDisplay: 'Does this member have Medicare coverage?',
                fieldType: 'radio',
                isRequired: true,
                "showIf": {
                    "requiredConditions": "all",
                    "conditions": [{
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                },
                radioOptions: [{
                    requiredConditions: 'none',
                    conditions: [],
                    options: [
                        'Yes',
                        'No',
                    ],
                }],
            },
            medicareReminderMessage: {
                fieldName: "medicareReminderMessage",
                fieldNameDisplay: 'medicareReminderMessage',
                isRequired: false,
                fieldType: "medicareReminderMessage",
                showIf: {
                    requiredConditions: "all",
                    conditions: [{
                        conditionType: "appState",
                        appState: {
                            stateKey: "employeeInfo",
                            stateSubKey: "medicarePrimary",
                            logic: "is",
                            stateValue: "Yes"
                        }
                    }, {
                        "conditionType": "appState",
                        "appState": {
                            "stateKey": "waiveCoverage",
                            "stateSubKey": "isWaivingAll",
                            "logic": "is not",
                            "stateValue": "Yes"
                        }
                    }]
                }
            },
            aoCoverageEmployee: {
              fieldName: 'aoCoverageEmployee',
              fieldNameDisplay: 'Is this an Owner/Partner not covered by Workers Comp?',
              fieldType: 'aoCoverageEmployeeRadio',
              isRequired: (overallPlatform && overallPlatform === 'PRIME' && isUHCNB) ? true : false,
          }
        },

        contactInfo: {
            streetAddress: {
                fieldNameDisplay: 'Street Address',
                fieldType: 'textbox',
                isRequired: true,
                validations: [
                    {
                        validationType: 'maxLength',
                        value: 55,
                    },
                ],
                helpContent: {
                    question: 'Are there invalid Street Addresses?',
                    content: 'Yes, the Primary Location for the employer cannot be a PO Box, Canadian and/or any other foreign addresses.',
                },
            },
            streetAddressln2: {
                fieldNameDisplay: 'Street Address Line 2',
                fieldType: 'textbox',
                isRequired: false,
                validations: [
                    {
                        validationType: 'maxLength',
                        value: 55,
                    },
                ],
            },
            city: {
                fieldNameDisplay: 'City',
                fieldType: 'textbox',
                isRequired: true,
                readOnly: [{ value: true }],
                validations: [
                    {
                        validationType: 'maxLength',
                        value: 50,
                    },
                ],
            },
            state: {
                fieldNameDisplay: 'State',
                fieldType: 'dropdown',
                isRequired: true,
                readOnly: [{ value: true }],
                dropdownOptions: [
                    {
                        options: fiftyStates,
                    },
                ],
            },
            zipCode: {
                fieldName: 'zipCode',
                fieldNameDisplay: 'Zip Code',
                fieldType: 'zipCode',
                isRequired: true,
            },
            zipCode4: {
                fieldNameDisplay: 'Zip Code + 4',
                fieldType: 'textbox',
                isRequired: false,
                validations: [
                    {
                        validationType: 'zip4',
                    },
                ],
            },
            telephone: {
                fieldNameDisplay: 'Telephone',
                fieldType: 'textbox',
                isRequired: false,
                validations: [
                    {
                        validationType: 'phone',
                    },
                ],
            },
            telephoneType: {
                fieldNameDisplay: 'Phone Type',
                fieldType: 'dropdown',
                dropdownOptions: [{
                    options: [
                        'Home',
                        'Mobile',
                        'Work',
                    ],
                }],
                isRequired: false,
            },
            email: {
                fieldNameDisplay: 'Email',
                fieldType: 'textbox',
                isRequired: false,
                validations: [
                    {
                        validationType: 'email',
                    },
                    {
                        validationType: 'maxLength',
                        value: 254,
                    },
                ],
            },
            outOfArea: {
                fieldNameDisplay: 'Out of Area?',
                fieldType: 'dropdown',
                dropdownOptions: [{
                    options: [
                        'Yes',
                        'No',
                    ],
                }],
                isRequired: true,
            },
        },
        dependentInfo: {
            state: {
                fieldNameDisplay: 'State',
                fieldType: 'dropdown',
                isRequired: true,
                showIf: {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'appState',
                            appState: {
                                stateKey: 'dependentInfo',
                                stateSubKey: 'addressDifferentThanEmployee',
                                logic: 'is',
                                stateValue: 'Yes',
                            },
                        },
                    ],
                },
                dropdownOptions: [
                    {
                        options: fiftyStates,
                    },
                ],
            },
        },
        productAndPlanSelection: {
            productSelectionTable: {
                fieldType: 'productSelectionTable',
            },
        },
        medicalCoverageInfo: {
            pcpTable: {
                fieldType: 'pcpTable',
                showIf: {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'appState',
                            appState: {
                                stateKey: 'medicalCoverageInfo',
                                stateSubKey: 'medicalWaived',
                                logic: 'is',
                                stateValue: false,
                            },
                        },
                        {
                            conditionType: 'selectedProducts',
                            selectedProducts: ['medical'],
                        },
                    ],
                },
            },
        },
    };
    if(isUhcRenewals()){
        json.employeeInfo.optedLifeClass = {
            fieldNameDisplay: 'Employee Class',
            fieldType: 'textbox',
            isRequired: life && life[0] && life[0].selectedClass ? true : false,
            readOnly: [{ value: true }]
        }
    } else {
        json.employeeInfo.optedLifeClass = {
            fieldNameDisplay: 'Employee Class',
            fieldType: 'dropdown',
            isRequired: life && life.employeeClassing && life.employeeClassing.classes ? true : false,
            dropdownOptions: [{
                options: life && life.employeeClassing ? life.employeeClassing.classes : []
            }],
        }
    }

    return (json)
}