import { workSheets } from '../constants';

export const getUHCSchema = (schemaName) => {
    switch (schemaName) {
        case workSheets.companyInfo:
            return getCompanyInfoSchema();
        case workSheets.location:
            return getLocationSchema();
        case workSheets.employeeInfo:
            return getEmpInfoSchema();
        case workSheets.enrollmentInfo:
            return getEnrollmentInfoSchema();
        case workSheets.enrollmentForm:
            return getEnrollmentFormSchema();
        case 'Summary':
            return getMMRSchema();    

    }
}

function getMMRSchema() {
    return {
        metaData: {
            isError: false,
            errors: []
        },
        data: [
            {
                pcuNotes: '',
                fuw: '',
                state: '',
                cirrusId: '',
                groupName: '',
                currentMedPlan: '',
                renewalPlan: '',
                renewalRx: '',
                renewalPackageId: '',
                renewalDate: '',
                trAcr: '',
                force: '',
                comments: '',
                ovrRenewalLifeRate: '',
                ovrADDRen: '',
                ovrDepLifeSpoRtRen: '',
                ovrDepLifeChdRtRen: '',
                dentEmpOvrRtRen: '',
                dentSpoOvrRtRen: '',
                dentChdOvrRtRen: '',
                dentFamOvrRtRen: '',
                ddentEmpOvrRtRen: '',
                ddentSpoOvrRtRen: '',
                ddentChdOvrRtRen: '',
                ddentChFamOvrRtRen: '',
                visionEmpOvrRtRen: '',
                visionSpoOvrRtRen: '',
                visionChdOvrRtRen: '',
                visionFamOvrRtRen: ''
            }
        ]
    }
}

function getCompanyInfoSchema() {
    return {
        metaData: {
            isError: false,
            errors: []
        },
        data: {
            companyName: '',
            effectiveDate: '',
            primaryContact: {
                firstName: '',
                lastName: '',
                email: ''
            },
            sicCode: '',
            franchiseCode: '',
            selectedProducts: {
                medical: false,
                dental: false,
                vision: false,
                life: false,
                suppLife: false,
                std: false,
                ltd: false
            }
        }
    }
}

function getLocationSchema() {
    return {
        metaData: {
            isError: false,
            errors: []
        },
        data: {
            locations: [
                {
                    primaryInd: true,
                    zipCode: '',
                    totalEmps: '',
                    totalEligEmps: '',
                    atne: '',
                    fte: '',
                    id: 0,
                    state: ''
                }
            ]
        }
    }
}

function getEmpInfoSchema() {
    return {
        metaData: {
            isError: false,
            errors: [],
        },
        data: [{
            firstName: '',
            lastName: '',
            suffix: '',
            coverages: {
                medical: 'EE',
                dental: 'EE',
                vision: 'EE',
                basicLife: 'EL',
                std: 'EE',
                ltd: 'EE',
                suppLife: 'EE'
            },
            age: '',
            dob: '',
            salary: '',
            zipCode: '',
            gender: '',
            employeeStatus: '',
            specialEmployeeStatus: '',
            status: 'In Progress',
            outOfArea: '',
            newEmployee: true,
            dependents: [
                {
                    relationship: '',
                    firstName: '',
                    middleInitial: '',
                    lastName: '',
                    suffix: '',
                    age: '',
                    dob: '',
                    gender: '',
                    zipCode: '',
                    newDependent: true,
                    errors: {
                        relationship: null,
                        dob: null,
                        age: null,
                        gender: null
                    },
                    dobInput: false
                }
            ],
            pcpAssignment: [],
            productSelection: [],
            waiveCoverage: {
                isWaivingAll: "",
                medicalWaiverReason: ""
            },
            medicalCoverageInfo: {
                medicalWaived: false,
                medicalWaiverReason: "",
                pcpTable: ""
            },
            waiveReason: "",
            optedLifeClass: "",
            locIndex: 0,
            employeeInfo: {
                firstName: '',
                lastName: '',
                middleInitial: '',
                suffix: '',
                dob: '',
                gender: '',
                salary: '',
                employeeStatus: '',
                status: 'In Progress',
            },
            errors: {
                coverages: null,
                outOfArea: null,
                salary: null,
                zipCode: null,
                gender: null,
                firstName: null,
                lastName: null,
                suffix: null,
                dob: null,
                age: null
            },
            dobInput: false
        }]
    }
}

function getEnrollmentInfoSchema() {
    return {
        metaData: {
            isError: false,
            errors: []
        },
        data: [{
            firstName: '',
            lastName: '',
            suffix: '',
            dob: '',
            salary: '',
            zipCode: '',
            gender: '',
            employeeStatus: '',
            status: 'In Progress',
            outOfArea: '',
            newEmployee: true,
            dependents: [
                {
                    relationship: '',
                    firstName: '',
                    middleInitial: '',
                    lastName: '',
                    suffix: '',
                    ssnTin: '',
                    noSSNReason: '',
                    ssnCheck: true,
                    dob: '',
                    gender: '',
                    medicarePrimary: '',
                    medicareClaimNum: '',
                    medicareReason: '',
                    medicareCoverages: [],
                    medicarePartAStartDate: '',
                    medicarePartBStartDate: '',
                    medicarePartDStartDate: '',
                    streetAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    telephone: '',
                    isDisabled: '',
                    disabilityType: '',
                    otherCarrier: '',
                    nameOtherCarrier: '',
                    newDependent: true,
                    waiveOption: [],
                    waiveCoverage: {
                        isWaivingAll: ''
                    },
                    pcpAssignment: [{
                        assignmentMethod: '',
                        existingPatient: '',
                        pcpCode: ''
                    }],
                    productSelection: [{
                        medical: '',
                        dental: '',
                        vision: '',
                        basicLife: 'select',
                        life: '',
                        suppLife: '',
                    }]
                }
            ],
            pcpAssignment: [{
                assignmentMethod: '',
                existingPatient: '',
                pcpCode: ''
            }],
            productSelection: [{
                medical: '',
                dental: '',
                vision: '',
                basicLife: 'select',
                life: '',
                suppLife: '',
                std: '',
                ltd: ''
            }],
            waiveCoverage: {
                isWaivingAll: '',
                medicalWaiverReason: ''
            },
            medicalCoverageInfo: {
                pcpTable: '',
                medicalWaiverReason: '',
                medicalWaived: false
            },
            waiveReason: '',
            optedLifeClass: '',
            locIndex: 0,
            waiveOption: [],
            employeeInfo: {
                firstName: '',
                lastName: '',
                middleInitial: '',
                suffix: '',
                ssnTin: '',
                noSSNReason: '',
                ssnCheck: true,
                dob: '',
                gender: '',
                numberOfHoursWorked: '',
                dateOfHire: '',
                salary: '',
                employeeStatus: '',
                optedLifeClass: '',
                cobraStartDate: '',
                cobraEndDate: '',
                anyOtherCoverage: '',
                nameOtherCarrier: '',
                medicarePrimary: '',
                medicareClaimNum: '',
                medicareReason: '',
                medicareCoverages: [],
                medicarePartAStartDate: '',
                medicarePartBStartDate: '',
                medicarePartDStartDate: '',
                status: 'In Progress',
                classingOpted: false,
                isDisabled: ''
            },
            contactInfo: {
                streetAddress: '',
                zipCode: '',
                city: '',
                state: '',
                telephone: '',
                telephoneType: '',
                email: '',
                outOfArea: '',
                county: ''
            }
        }]
    }
}

function getEnrollmentFormSchema() {
    return {
        metaData: {
            isError: false,
            errors: [],
            isWarn: false,
            warns: [],
        },
        data: [{
            firstName: '',
            lastName: '',
            suffix: '',
            coverages: {
                medical: 'EE',
                dental: 'EE',
                vision: 'EE',
                basicLife: 'EL',
                std: 'EE',
                ltd: 'EE',
                suppLife: 'EE'
            },
            dob: '',
            salary: '',
            zipCode: '',
            gender: '',
            employeeStatus: '',
            status: 'In Progress',
            outOfArea: 'No',
            newEmployee: true,
            dependents: [
                {
                    relationship: '',
                    firstName: '',
                    middleInitial: '',
                    lastName: '',
                    suffix: '',
                    ssnTin: '',
                    noSSNReason: '',
                    ssnCheck: true,
                    dob: '',
                    gender: '',
                    medicarePrimary: '',
                    medicareClaimNum: '',
                    medicareReason: '',
                    medicareCoverages: [],
                    medicarePartAStartDate: '',
                    medicarePartBStartDate: '',
                    medicarePartDStartDate: '',
                    streetAddress: '',
                    city: '',
                    state: '',
                    zipCode: '',
                    telephone: '',
                    isDisabled: '',
                    disabilityType: '',
                    otherCarrier: '',
                    nameOtherCarrier: '',
                    newDependent: true,
                    dobInput: true,
                    waiveOption: [],
                    waiveCoverage: {
                        isWaivingAll: ''
                    },
                    pcpAssignment: [{
                        assignmentMethod: '',
                        existingPatient: '',
                        pcpCode: ''
                    }],
                    productSelection: [{
                        medical: '',
                        dental: '',
                        vision: '',
                       // basicLife: 'select',
                        life: '',
                        suppLife: ''
                    }]
                }
            ],
            pcpAssignment: [{
                assignmentMethod: '',
                existingPatient: '',
                pcpCode: ''
            }],
            productSelection: [{
                medical: '',
                dental: '',
                vision: '',
                //basicLife: 'select',
                life: '',
                suppLife:'',
                std: '',
                ltd: ''
            }],
            waiveCoverage: {
                isWaivingAll: '',
                medicalWaiverReason: ''
            },
            medicalCoverageInfo: {
                pcpTable: '',
                medicalWaiverReason: '',
                medicalWaived: false
            },
            waiveReason: '',
            optedLifeClass: '',
            locIndex: 0,
            waiveOption: [],
            employeeInfo: {
                firstName: '',
                lastName: '',
                middleInitial: '',
                suffix: '',
                ssnTin: '',
                noSSNReason: '',
                ssnCheck: true,
                dob: '',
                gender: '',
                numberOfHoursWorked: '',
                dateOfHire: '',
                salary: '',
                employeeStatus: '',
                optedLifeClass: '',
                cobraStartDate: '',
                cobraEndDate: '',
                anyOtherCoverage: '',
                nameOtherCarrier: '',
                medicarePrimary: '',
                medicareClaimNum: '',
                medicareReason: '',
                medicareCoverages: [],
                medicarePartAStartDate: '',
                medicarePartBStartDate: '',
                medicarePartDStartDate: '',
                status: 'In Progress',
                classingOpted: false,
                isDisabled: ''
            },
            contactInfo: {
                streetAddress: '',
                streetAddressln2:'',
                zipCode: '',
                city: '',
                state: '',
                telephone: '',
                telephoneType: '',
                email: '',
                outOfArea: 'No',
                county: ''
            },
            dobInput: true
        }]
    }
}