import { PAYMENT_ACTIONS } from '../../actions/actionTypes';
import * as types from '../../actions/actionTypes';
import PAYMENT_CONSTS from '../../components/activities/Enrollment/Payment/paymentConsts';

const { PAYMENT_METHODS, BILLING_METHODS } = PAYMENT_CONSTS;

function createDefaultPayment() {
    return {
        method: null,
        binder: {
            cloudFileName: null,
            clientFileName: null,
        },
        ebinder: {
            cloudFileName: null,
            clientFileName: null,
        },
        debit: {},
        externallyUploaded: false
    };
}

function createDefaultBilling() {
    return {
        method: null,
        electronic: {
            selectedContact: null,
        },
        paper: {
            confirmed: false,
            confirmedAddress: {}
        },
    };
}

const initialState = {
    payment: createDefaultPayment(),
    billing: createDefaultBilling(),
};

const billingAliases = {
    [BILLING_METHODS.ELECTRONIC]: 'electronic',
    [BILLING_METHODS.PAPER]: 'paper',
};

const paymentAliases = {
    [PAYMENT_METHODS.UPLOAD_BINDER]: 'binder',
    [PAYMENT_METHODS.ONETIME_BINDER]: 'ebinder',
    [PAYMENT_METHODS.DIRECT_DEBIT]: 'debit',
};

function updateBilling(state, method, update = {}) {
    const alias = billingAliases[method];

    return {
        ...state,
        billing: {
            ...createDefaultBilling(),
            method,
            [alias]: {
                ...state.billing[alias],
                ...update,
            },
        },
    };
}

function updatePayment(state, method, update = {}) {
    const alias = paymentAliases[method];

    return {
        ...state,
        payment: {
            ...createDefaultPayment(),
            method,
            [alias]: {
                ...state.payment[alias],
                ...update,
            },
        },
    };
}

function paymentBillingReducer(state = initialState, action) {
    switch (action.type) {
        case PAYMENT_ACTIONS.UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    method: action.paymentMethod,
                },
            };
        case PAYMENT_ACTIONS.UPDATE_BILLING_METHOD:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    method: action.billingMethod,
                },
            };
        case PAYMENT_ACTIONS.UPDATE_SELECTED_CONTACT:
            return {
                ...state,
                billing: {
                    ...state.billing,
                    selectedContact: action.contact,
                },
            };
        case PAYMENT_ACTIONS.UPDATE_BINDER:
            return updatePayment(state, PAYMENT_METHODS.UPLOAD_BINDER, action.binder);
        case PAYMENT_ACTIONS.UPDATE_EBINDER:
            return updatePayment(state, PAYMENT_METHODS.ONETIME_BINDER, action.ebinder);
        case PAYMENT_ACTIONS.UPDATE_DEBIT:
            return updatePayment(state, PAYMENT_METHODS.DIRECT_DEBIT, action.debit);
        case PAYMENT_ACTIONS.CLEAR_PAYMENT:
            return {
                ...state,
                payment: createDefaultPayment(),
            };
        case PAYMENT_ACTIONS.CLEAR_BILLING:
            return {
                ...state,
                billing: createDefaultBilling(),
            };
        case PAYMENT_ACTIONS.UPDATE_ELECTRONIC:
            return updateBilling(state, BILLING_METHODS.ELECTRONIC, action.electronic);
        case PAYMENT_ACTIONS.UPDATE_PAPER:
            return updateBilling(state, BILLING_METHODS.PAPER, action.paper);
        case PAYMENT_ACTIONS.SET_EXTERNAL_UPLOAD_PAYMENT:
            return {
                ...state,
                payment: {
                    ...state.payment,
                    externallyUploaded: action.externallyUploaded
                }
            }
        
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
            return initialState;
            
        default:
            return state;
    }
}

export default paymentBillingReducer;
