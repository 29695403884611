import {
    INITIALIZE_EMPLOYEE_INFO, UPDATE_EMPLOYEE_INFO,
    OPEN_INDIVIDUAL_EMPLOYEE, CLOSE_INDIVIDUAL_EMPLOYEE,
    UPDATE_EMPLOYEE_TABLE,
    UPDATE_EMPLOYEE_TABLE_ROW,
    ADD_ENROLLED_DEPENDENT, REMOVE_ENROLLED_DEPENDENT, RESET_FLOW, RESET_TO_INITIALSTATE, UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION, SET_MEDICAL_PRODUCT
} from '../../../actions/actionTypes';
import { initializeEnrollmentPageState } from './helper';
import { isEmpty } from '../../../utils/formatters/strings';
import { newPcpAssignmentObject } from '../../../components/shared/ConfigurableForms/Components/PcpTable/pcpAssignmentHelper';
import { newPcdAssignmentObject } from '../../../components/shared/ConfigurableForms/Components/PcdTable/pcdAssignmentHelper';
import { makeNewProductSelectionObject } from '../../../components/shared/ConfigurableForms/Components/ProductSelectionTable/productSelectionHelper';
import { removeAtIndex } from '../../../utils/formatters/objects';

const initialState = {
    locationIndex: 0,
    employeeIndex: 0,
    numDependents: 0,
    pcpAssignment: [],
    pcdAssignment: [],
    productSelection: [],
    errors: {},
};

export default function employeeReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_EMPLOYEE_INFO: {
            const initializedState = initializeEnrollmentPageState(action.payload, action.conditionEvaluators);
            return {
                ...state,
                numDependents: 0,
                ...initializedState,
            };
        }

        case OPEN_INDIVIDUAL_EMPLOYEE: {
            const {
                type, employee, flow, desQuote,...newState
            } = action;
            const {
                firstName, lastName, dob, dobInput, gender, salary, memberId, zipCode, dependents, status, streetAddress, city, telephone, stateCode, employeeStatus,
                email, suffix, optedLifeClass, relationship,dobReceived
            } = employee;
            let  benefitAmount = {EEPlan:'',SPPlan:'',CHPlan:''};
            const middleInitial = employee?.employeeInfo?.middleInitial || ''
            const outOfArea = employee.contactInfo && employee.contactInfo.outOfArea ? employee.contactInfo.outOfArea : employee.outOfArea ? employee.outOfArea : '';
            // We filter out stale dependent info from the state from previous employee views that were used
            const wantedStateKeys = Object.keys(state).filter((keyName) => !(keyName.indexOf('dependentInfo') === 0 && keyName !== 'dependentInfo'));
            const wantedState = {};
            const prevProductSelection = employee.productSelection || [];
            const updatedProductSelection = [];
            wantedStateKeys.forEach((keyName) => {
                wantedState[keyName] = state[keyName];
            });
            
            if(employee.benefitAmount){
              benefitAmount ={...benefitAmount,...employee.benefitAmount};
            }

            // For new employee initialise the product selection
            if (prevProductSelection.length === 0) {
                updatedProductSelection.push(makeNewProductSelectionObject(action.products))
            }
            // convert the dependents are they are stored in the companyProfile reducer
            // (an array in the employee object) to a set of objects for multiple formSections
            const dependentInfos = {};
            dependents.forEach((dep, i) => {
                dependentInfos[`dependentInfo${i}`] = {
                    ...state.dependentInfo, // initialize with blank dependent info
                    ...dep, // add dependent info from companyProfile
                    dob: dep.dobInput || flow === 'renewals' ? dep.dob : null,
                    errors: {},
                };

                // For new employee initialise the product selection
                if (prevProductSelection.length === 0) {
                    updatedProductSelection.push(makeNewProductSelectionObject(action.products))
                }
            });
            return {
                ...wantedState,
                ...newState,
                ...employee,
                benefitAmount,
                productSelection: prevProductSelection.length > 0 ? employee.productSelection : updatedProductSelection,
                employeeInfo: {
                    ...wantedState.employeeInfo,
                    ...employee.employeeInfo,
                    firstName,
                    lastName,
                    dob: dobInput || flow === 'renewals' ? dob : null,
                    middleInitial,
                    suffix,
                    dobInput,
                    gender,
                    salary,
                    status,
                    memberId,
                    employeeStatus,
                    optedLifeClass,
                    classingOpted: action.classingOpted,
                    relationship,
                },
                contactInfo: {
                    ...wantedState.contactInfo,
                    ...employee.contactInfo,
                    zipCode: employee.contactInfo ? employee.contactInfo.zipCode : employee.zipCode,
                    outOfArea,
                    email: employee.contactInfo ? employee.contactInfo.email : employee.email ? employee.email : '',
                },
                medicalCoverageInfo: {
                    ...wantedState.medicalCoverageInfo,
                    ...employee.medicalCoverageInfo,
                    // Not sure why this was here but it made it so that re opening an individual employee made the pcp required
                   medicalWaived: employee && employee.medicalCoverageInfo && employee.medicalCoverageInfo.medicalWaived ? employee.medicalCoverageInfo.medicalWaived :
                                    employee && employee.productSelection && employee.productSelection.length > 0 && employee.productSelection[0].medical && employee.productSelection[0].medical === 'waive' ? true : false,
                //     medicalWaived: !isEmpty(employee.medicalCoverageInfo.medicalWaived) ? employee.medicalCoverageInfo.medicalWaived : false,
                },
                numDependents: dependents.length,
                ...dependentInfos,
                employeeEnrollmentCompletion : state.employeeEnrollmentCompletion ?  state.employeeEnrollmentCompletion : false
            };
        }
        case UPDATE_EMPLOYEE_INFO: {
            const {
                update, group, isError, makeHiddenFieldsNull = {},
            } = action;
            if (isError) {
                const resolvedErrorGroup = state.errors[group] || {};
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [group]: {
                            ...resolvedErrorGroup,
                            ...update,
                            ...makeHiddenFieldsNull,
                        },
                    },
                };
            }

            if (update && update.employeeStatus && update.employeeStatus === 'COBRA') {
                if (state.errors && state.errors.employeeInfo && state.errors.employeeInfo.salary)
                    state.errors.employeeInfo.salary = null
            }

            if (update && update.isWaivingAll && update.isWaivingAll === 'Yes') {
                const updatedState = {
                     ...state,
                     employeeInfo: {
                        ...state.employeeInfo,
                         medicarePrimary: '',
                         anyOtherCoverage: ''
                     }
                 }
                 state = updatedState;
             }
            const resolvedGroup = state[group] || {};
            return {
                ...state,
                [group]: {
                    ...resolvedGroup,
                    ...update,
                    ...makeHiddenFieldsNull,
                },
            };
        }

        case UPDATE_EMPLOYEE_TABLE: {
            const update = {
                [action.tableName]: action.newState,
            };
            if (action.tableName === 'productSelection') {
                const medicalWaived = (action.newState[0] || {}).medical === 'waive';
                update.medicalCoverageInfo = {
                    ...state.medicalCoverageInfo,
                    medicalWaived,
                };
            }
            return {
                ...state,
                ...update,
            };
        }

        case UPDATE_EMPLOYEE_TABLE_ROW: {
            const { tableName, memberIndex, productsToWaive } = action;
            const newState = state;
            const productSelection = newState[tableName];
            productSelection[memberIndex] = { ...productSelection[memberIndex], productsToWaive };
            newState[tableName] = productSelection;
            const update = {
                [action.tableName]: productSelection,
            };
            if (action.tableName === 'productSelection') {
                const medicalWaived = (newState[0] || {}).medical === 'waive';
                update.medicalCoverageInfo = {
                    ...state.medicalCoverageInfo,
                    medicalWaived,
                };
            }
            return {
                ...state,
                ...update,
            };
        }

        case CLOSE_INDIVIDUAL_EMPLOYEE: {
            const { employeeSections, conditionEvaluators } = action;
            const blankEmployeeState = initializeEnrollmentPageState(employeeSections, conditionEvaluators);
            return {
                ...state,
                // Reset info
                ...blankEmployeeState,
                pcpAssignment: [],
                pcdAssignment: [],
                productSelection: [],
            };
        }
        case ADD_ENROLLED_DEPENDENT:
            let newDepInfo = state.dependentInfo;
            newDepInfo.newDependent = true;
            return {
                ...state,
                numDependents: state.numDependents + 1,
                [`dependentInfo${state.numDependents}`]: newDepInfo, // blank dependent info from formSections
                pcpAssignment: state.pcpAssignment.concat(newPcpAssignmentObject),
                pcdAssignment: state.pcdAssignment.concat(newPcdAssignmentObject),
                productSelection: state.productSelection
                    .concat(makeNewProductSelectionObject(action.products)),
            };

        case REMOVE_ENROLLED_DEPENDENT: {
            const newDependents = {};
            const allStateKeys = Object.keys(state);
            const dependentStateKeys = allStateKeys.filter((keyName) => keyName.indexOf('dependentInfo') === 0 && keyName !== 'dependentInfo');
            // Shift dependents forwared 1 to overwite the deleted dependent
            for (let i = action.dependentIndex; i < state.numDependents - 1; i++) {
                newDependents[`dependentInfo${i}`] = state[`dependentInfo${i + 1}`];
            }
            // Remove the final dependent object from state
            const lastDependentStateKey = dependentStateKeys[state.numDependents - 1];
            const wantedState = {};
            const wantedStateKeys = allStateKeys.filter((keyName) => keyName !== lastDependentStateKey);
            wantedStateKeys.forEach((keyName) => {
                wantedState[keyName] = state[keyName];
            });
            
            return {
                ...wantedState,
                numDependents: state.numDependents - 1,
                ...newDependents,
                // first index is for the employee
                pcpAssignment: removeAtIndex(state.pcpAssignment, action.dependentIndex + 1),
                pcdAssignment: removeAtIndex(state.pcdAssignment, action.dependentIndex + 1),
                productSelection: removeAtIndex(state.productSelection, action.dependentIndex + 1),
            };
        }
        case UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION:
            return {
                ...state,
                employeeEnrollmentCompletion : action.payload
            }
        case SET_MEDICAL_PRODUCT:
            const productSelection = state.productSelection;
            for(const member of productSelection){
                member.medical=action.payload
            }
            return {
                ...state,
                ...productSelection
            }
        case RESET_TO_INITIALSTATE:
            return initialState;
        default:
            return state;
    }
}
