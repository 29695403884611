import { userTypes, dependencyNames, workSheets } from '../../constants';
import * as helper from '../../mappingHolder/mappingHelper';
import { validateParentChildDependency } from '../dependencyHelper';

export const getUHCDependency = (dependencyName, userType, currentWorkSheetName = null) => {
    switch (dependencyName) {
        case dependencyNames.WORKSHEET_DEPENDENCY:
            return getWorkSheetDependencies(userType, currentWorkSheetName);
        case dependencyNames.SELF_DEPENDENCY:
            return getSelfDependencies(userType, currentWorkSheetName);
        case dependencyNames.AFTER_PARSE_DEPENDENCY:
            return getAfterParseDependencies(userType, currentWorkSheetName);
        case dependencyNames.CHILD_PARENT_DEPENDENCY:
            return getChildParentDependencies(currentWorkSheetName)
        case dependencyNames.PARENT_CHILD_DEPENDENCY:
            return getParentChildDependencies(currentWorkSheetName)
    }
}

function getWorkSheetDependencies(userType, currentWorkSheet) {
    if (userType === userTypes.employee) {
        switch (currentWorkSheet) {
            case workSheets.employeeInfo:
                return {
                    [workSheets.companyInfo]: [
                        {
                            cols: ['P', 'Q', 'S', 'T', 'U', 'V', 'W'],
                            depField: 'required',
                            fieldValues: [
                                helper.isMedicalOnly,
                                helper.checkQuotingStatus,
                                helper.checkQuotingStatus,
                                helper.checkQuotingStatus,
                                helper.checkQuotingStatus,
                                helper.checkQuotingStatus,
                                helper.checkQuotingStatus],
                            args: [
                                { selectedProducts: 'companyInfo.selectedProducts' },
                                { medical: 'companyInfo.selectedProducts.medical' },
                                { dental: 'companyInfo.selectedProducts.dental' },
                                { vision: 'companyInfo.selectedProducts.vision' },
                                { life: 'companyInfo.selectedProducts.life' },
                                { std: 'companyInfo.selectedProducts.std' },
                                { ltd: 'companyInfo.selectedProducts.ltd' }
                            ]
                        }
                    ]
                }
            case workSheets.enrollmentInfo:
                return {
                    [workSheets.companyInfo]:[
                        {
                            cols: ['AD'],
                            depField: 'required',
                            fieldValues: [
                                helper.isMedicalOnly
                            ],
                            args: [
                                {selectedProducts: 'companyInfo.selectedProducts'}
                            ]
                        }
                    ]
                }
        }
    }
    else {

    }
}

function getSelfDependencies(userType, currentWorkSheetName) {
    switch(currentWorkSheetName){
        case workSheets.employeeInfo:
            let dependecies = [
                {
                    col: 'J',
                    depCols: ['I'],
                    depFields: ['required'],
                    fieldValues: [helper.isEmpty]
                },
                {
                    col: 'I',
                    depCols: ['J'],
                    depFields: ['required'],
                    fieldValues: [helper.isEmpty]
                },
            ];
            if(userType === userTypes.employee){

            }else{

            }
            return dependecies
        case workSheets.enrollmentInfo:
            let enrollmentInfoDependencies = [
                {
                    col: 'J',
                    depCols: ['H', 'I', 'K', 'P', 'R', 'AE', 'AG', 'AH', 'AI', 'AK', 'AL', 'AP', 'AR'],
                    depFields: ['required', 'required', 'required', 'required', 'required', 'required', 'required', 'required', 'required', 'required', 'required', 'required', 'required'],
                    fieldValues: [helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo]
                },
                {
                    col: 'M',
                    depCols: ['O'],
                    depFields: ['required'],
                    fieldValues: [helper.isAutoAssignedPCP]
                },
                {
                    col: 'AE',
                    depCols: ['AF'],
                    depFields: ['required'],
                    fieldValues: [helper.isEmpty]
                },
                {
                    col: 'AF',
                    depCols: ['AE'],
                    depFields: ['required'],
                    fieldValues: [helper.isEmpty]
                },
                {
                    col: 'AP',
                    depCols: ['AQ'],
                    depFields: ['required'],
                    fieldValues: [helper.isYesSelected]
                },
                {
                    col: 'AR',
                    depCols: ['AS', 'AT', 'AU', 'AV', 'AW'],
                    depFields: ['required', 'required', 'required', 'required', 'required'],
                    fieldValues: [helper.isYesSelected, helper.isYesSelected, helper.isYesSelected, helper.isYesSelected, helper.isYesSelected]
                },
            ]

            if(userType === userTypes.employee) {
                enrollmentInfoDependencies.push(
                    {
                        col: 'C',
                        depCols: ['AX', 'AY'],
                        depFields: ['required', 'required'],
                        fieldValues: [helper.isCOBRAEmployee, helper.isCOBRAEmployee]
                    },
                    {
                        col: 'J',
                        depCols: ['C', 'S', 'AA', 'AC', 'AD'],
                        depFields: ['required', 'required', 'required', 'required', 'required'],
                        fieldValues: [helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo]
                    },
                    {
                        col: 'K',
                        depCols: ['L'],
                        depFields: ['required'],
                        fieldValues: [helper.isWaived]
                    },
                )
            } else {
                enrollmentInfoDependencies = [...enrollmentInfoDependencies,
                    {
                        col: 'J',
                        depCols: ['B', 'T', 'BA'],
                        depFields: ['required', 'required', 'required'],
                        fieldValues: [helper.isWaivingAllNo, helper.isWaivingAllNo, helper.isWaivingAllNo]
                    },
                ]
            }
            return enrollmentInfoDependencies
    }
}

function getAfterParseDependencies(userType, currentWorkSheetName) {
    switch(currentWorkSheetName){
        case workSheets.employeeInfo:
            let dependecies = [
                {
                    fieldPath: 'dobInput',
                    fieldValue: helper.isDobFilled,
                    args: {
                        dob: 'dob'
                    }
                },
                {
                    fieldPath: 'dob',
                    fieldValue: helper.getDobFromAge,
                    args: {
                        age: 'age',
                        dob: 'dob',
                        effectiveDate: 'otherData.effectiveDate'
                    }
                },
                {
                    fieldPath: 'age',
                    fieldValue: helper.getAgeFromDob,
                    args: {
                        age: 'age',
                        dob: 'dob',
                        effectiveDate: 'otherData.effectiveDate'
                    }
                },
            ];
            if(userType === userTypes.employee){
            }
            else{
        
            }
            return dependecies;
    }
}

function getChildParentDependencies(currentWorkSheetName) {
    switch(currentWorkSheetName) {
        case workSheets.enrollmentInfo:
            return [
                {
                    type: 'move',
                    src: 'child',
                    dest: 'parent',
                    pathArr: [
                        {
                            srcPath: 'pcpAssignment.0',
                            destPath: 'pcpAssignment.i',
                            deletePath: 'pcpAssignment'
                        },
                        {
                            srcPath: 'productSelection.0',
                            destPath: 'productSelection.i',
                            deletePath: 'productSelection'
                        }
                    ]
                }
            ]
    }
}

function getParentChildDependencies(currentWorkSheetName) {
    switch(currentWorkSheetName) {
        case workSheets.employeeInfo:
            return [
                {
                    fieldName: 'Relationship',
                    parentFieldPath: 'coverages',
                    childFieldPath: 'relationship',
                    validateValue: validateParentChildDependency,
                    col: 'B'
                }
            ]
    }
}