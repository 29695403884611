const rolesWithOneLife = ['UHC Install Rep', 'Production Support Admin'];
const stateWithOneLife = ['TX'];

export const isValidCount = (employeesCount, roleName, stateCode) => {
    if (employeesCount) {
        const validMinCount = isOneLife(roleName, stateCode) ? 1 : 2;
        if (parseInt(employeesCount) >= validMinCount && parseInt(employeesCount) <= 100) {
            return true;
        }
        return false;
    }
    return false;
}

export const isOneLife = (roleName, stateCode) => {
    if (rolesWithOneLife.includes(roleName) || stateWithOneLife.includes(stateCode)) {
        return true;
    }
    return false;
}