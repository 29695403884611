import * as types from '../../actions/actionTypes';

/**
 * Initial state of the Error handler reducer object, contains flag (this flag helps to show UI message to the user and trigger notification to the support team) 
 */
const initialState = {
    notifyError: false
}

/**
 * Error handler reducer to update the error details.
 * @param {*} state Error handler state
 * @param {*} action action details to update the error details
 */
export default function errorHandler(state, action) {
    let currentState = state;

    if (currentState === undefined || currentState === null) {
        currentState = initialState;
    }

    switch (action.type) {
        case types.UPDTE_FAILED_API_REQUEST:

            return {
                ...currentState,
                ...action.payload
            };
        default:
            return currentState;
    }
}