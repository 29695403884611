import {
  sendPageLoadData,
  sendClickEventData,
  sendErrorTrackingData,
} from "../utils/analytics/adobeAnalyticsHelper";
import {
  getPrimaryLocation,
  getPrimaryLocationStateCode,
} from "../components/activities/FullShopping/Census/helpers/checkPrimaryLocation";
import { getCaseIndexForNB } from "../reducers/products/helpers/companyProfileConsts";
import { getEncryptKey } from "../utils/consts/tokenVars";

let secretKey = "";

export function sendPageLoadDataAction(pageData = {}, additionalData = {}) {
  const CryptoJS = require("crypto-js");

  if (!secretKey) {
    secretKey = getEncryptKey();
  }

  const encryptFields = (val) =>
    CryptoJS.AES.encrypt(val, secretKey).toString();
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const brokerRoles = ["Broker", "Broker Admin", "Broker Delegate"];
      const agencyRoles = ["Agency Delegate", "Agency Admin"];
      const gaRoles = [
        "General Agent Admin",
        "GA Admin",
        "GA Delegate",
        "General Agent Delegate",
      ];
      // const salesRepRole = ["Sales Rep"];
      const quotingPages = [
        "Employee Census Page",
        "Company Profile",
        "Owner Assignment",
        "Quote Review Page",
        "Plan Selection",
      ];
      const fullProps = getState();
      const {
        pageName,
        siteSectionL1,
        siteSectionL2,
        siteSectionL3,
        errorType,
      } = pageData;
      const { fullName, roleName, role } = fullProps.renewals;
      const { optumId, userName } =
        fullProps.renewals.logInData && fullProps.renewals.logInData[0]
          ? fullProps.renewals.logInData[0]
          : {};
      const { ownerAssignment } = fullProps.reviewCards
        ? fullProps.reviewCards
        : {};
      const { salesRep } = ownerAssignment || {};
      const { producer } = ownerAssignment || {};
      const { agency } = ownerAssignment || {};
      const { generalAgent } = ownerAssignment || {};
      const loggedInUserName = fullName || userName || "";
      const brokerPcisId =
        fullProps && fullProps.renewals && fullProps.renewals.val
          ? fullProps.renewals.val
          : "";
      const caseTracking = fullProps.caseTracking;
      const renewalsFlow =
        fullProps.overall.isRenewals || fullProps.overall.source === "renewal";
      const companyProfile = fullProps.companyProfile;
      const products =
        renewalsFlow === true
          ? ""
          : Object.keys(companyProfile.selectedProducts).length > 0
          ? companyProfile.selectedProducts
          : fullProps.specialtyCriteria &&
            fullProps.specialtyCriteria.viewSpecialty
          ? fullProps.specialtyCriteria.viewSpecialty
          : "";

      let productsDisplay = "";
      for (const product in products) {
        if (products[product]) {
          if (productsDisplay === "") productsDisplay = product.toUpperCase();
          else
            productsDisplay = productsDisplay + " | " + product.toUpperCase();
        }
      }
      const depLifePlans =
        fullProps.lifeDep &&
        fullProps.lifeDep.selectedPlans &&
        fullProps.lifeDep.selectedPlans.length > 0
          ? fullProps.lifeDep.selectedPlans
          : [];
      const depLifeEnrollmentPlans =
        fullProps.rules &&
        fullProps.rules.enrollment &&
        fullProps.rules.enrollment.enrolledDependentLifePlans &&
        fullProps.rules.enrollment.enrolledDependentLifePlans.length > 0
          ? fullProps.rules.enrollment.enrolledDependentLifePlans
          : [];
      if (
        pageName &&
        quotingPages.includes(pageName) &&
        depLifePlans.length > 0
      ) {
        if (productsDisplay === "") productsDisplay = "DEPENDENT LIFE";
        else productsDisplay = productsDisplay + " | " + "DEPENDENT LIFE";
      } else if (pageName && depLifeEnrollmentPlans.length > 0) {
        if (productsDisplay === "") productsDisplay = "DEPENDENT LIFE";
        else productsDisplay = productsDisplay + " | " + "DEPENDENT LIFE";
      }
      const caseId =
        renewalsFlow === true
          ? ""
          : caseTracking && caseTracking.caseId
          ? caseTracking.caseId
          : "";
      const quoteId =
        renewalsFlow === true
          ? ""
          : caseTracking && caseTracking.currentQuoteId
          ? caseTracking.currentQuoteId
          : "";
      const effectiveDate =
        renewalsFlow === true
          ? ""
          : companyProfile && companyProfile.effectiveDate
          ? companyProfile.effectiveDate
          : "";
      const memberGroupIdRenewals = companyProfile.memberGroupId
        ? companyProfile.memberGroupId
        : renewalsFlow === true &&
          fullProps.renewals.renewalCaseData &&
          fullProps.renewals.renewalCaseData.memberGroupId
        ? fullProps.renewals.renewalCaseData.memberGroupId
        : "";
      let marketType = "";
      let marketInd =
        fullProps.overall.appConfigDetails &&
        fullProps.overall.appConfigDetails.marketType
          ? fullProps.overall.appConfigDetails.marketType
          : "";
      if (marketInd && marketInd != "") {
        if (marketInd.toUpperCase() == "CIRRUS") marketType = "Oxford";
        else marketType = "UHC";
      }
      const memberGroupId = renewalsFlow === true ? memberGroupIdRenewals : "";
      const customerNo =
        renewalsFlow == true &&
        fullProps.renewals.renewalCaseData &&
        fullProps.renewals.renewalCaseData.primeCustomerNumber
          ? fullProps.renewals.renewalCaseData.primeCustomerNumber
          : "";
      let renewalPlan;
      if (fullProps.renewals.globalSearchFlag) {
        renewalPlan =
          renewalsFlow === true ? fullProps.renewals.renewalSearchCase : null;
      } else {
        renewalPlan =
          renewalsFlow === true
            ? fullProps.renewals &&
              fullProps.renewals.renewals &&
              fullProps.renewals.renewals.find(
                (plan) =>
                  plan.memberGroupId === fullProps.renewals.memberGroupId
              )
            : null;
      }
      let actualCaseProgress =
        renewalsFlow === true && renewalPlan
          ? renewalPlan.caseProgressUI
          : null;
      const updateRLcaseProgress = fullProps.renewals.rlCaseProgress;
      let renewalsCaseProgress =
        renewalsFlow === true
          ? caseTracking &&
            caseTracking.caseTrack &&
            caseTracking.caseTrack[0] &&
            caseTracking.caseTrack[0].caseStatus
            ? caseTracking.caseTrack[0].caseStatus
            : updateRLcaseProgress
            ? updateRLcaseProgress
            : null
          : null;
      let idx = getCaseIndexForNB(caseTracking);
      let caseProgress =
        renewalsFlow === true
          ? renewalsCaseProgress
            ? renewalsCaseProgress
            : actualCaseProgress
          : caseTracking &&
            caseTracking.caseTrack &&
            caseTracking.caseTrack[idx] &&
            caseTracking.caseTrack[idx].caseStatus
          ? caseTracking.caseTrack[idx].caseStatus
          : "";
      if (caseProgress && caseProgress.toLowerCase() === "inprogress")
        caseProgress = "In Progress";

      const locations = renewalsFlow === true ? "" : companyProfile.locations;
      const zipCode =
        locations && locations.length > 0
          ? getPrimaryLocation(locations).zipData.zipCode
            ? getPrimaryLocation(locations).zipData.zipCode
            : ""
          : "";
      const state =
        locations && locations.length > 0
          ? getPrimaryLocationStateCode(locations)
          : "";
      const sicCode =
        renewalsFlow === true
          ? ""
          : companyProfile.sicCode
          ? companyProfile.sicCode
          : "";
      const groupSize =
        fullProps.overall.quickQuoteRequest &&
        fullProps.overall.quickQuoteRequest.groupSize
          ? fullProps.overall.quickQuoteRequest.groupSize
          : "";
      const franchiseCode =
        renewalsFlow === true
          ? ""
          : fullProps.companyProfile.franchiseCode
          ? fullProps.companyProfile.franchiseCode
          : "";
      const county =
        locations && locations.length > 0
          ? getPrimaryLocation(locations).zipData.county
            ? getPrimaryLocation(locations).zipData.county
            : ""
          : "";

      let brokerName =
        brokerRoles.includes(roleName) && loggedInUserName
          ? loggedInUserName
          : "";
      let agencyName =
        agencyRoles.includes(roleName) && loggedInUserName
          ? loggedInUserName
          : "";
      let generalAgentLoggedIn =
        gaRoles.includes(roleName) && loggedInUserName ? loggedInUserName : "";
      let salesRepresentative = "";
      if (role === "Internal") {
        brokerName = loggedInUserName;
      }
      if (producer !== {}) {
        producer.map((producers) => {
          if (brokerName == "") brokerName += producers.producerName;
          else {
            if (brokerName !== producers.producerName) {
              brokerName += "|";
              brokerName += producers.producerName;
            }
          }
        });
      }
      if (agency !== {}) {
        agency.map((agencies) => {
          if (agencyName == "") agencyName += agencies.agencyName;
          else {
            if (agencyName !== agencies.agencyName) {
              agencyName += "|";
              agencyName += agencies.agencyName;
            }
          }
        });
      }
      if (generalAgent !== {}) {
        generalAgent.map((generalAgents) => {
          if (generalAgentLoggedIn == "")
            generalAgentLoggedIn += generalAgents.generalAgentName;
          else {
            if (generalAgentLoggedIn !== generalAgents.generalAgentName) {
              generalAgentLoggedIn += "|";
              generalAgentLoggedIn += generalAgents.generalAgentName;
            }
          }
        });
      }
      if (salesRep !== {} && salesRep !== []) {
        salesRep.map((salesRepresentatives) => {
          if (salesRepresentative == "")
            salesRepresentative += salesRepresentatives.salesRepName;
          else {
            if (salesRepresentative !== salesRepresentatives.salesRepName) {
              salesRepresentative += "|";
              salesRepresentative += salesRepresentatives.salesRepName;
            }
          }
        });
      }

      const data = {
        pageName: pageName || "",
        siteSectionL1: siteSectionL1 || "",
        siteSectionL2: siteSectionL2 || "",
        siteSectionL3: siteSectionL3 || "",
        pageLanguage: "EN",
        userType: role || "",
        subUserType: roleName || "",
        pcisId: brokerPcisId,
        optumID: optumId ? encryptFields(optumId) : "",
        loginStatus: "LoggedIN",
        brokerName,
        agencyName,
        generalAgentLoggedIn,
        salesRepresentative,
        marketType,
        insuranceType: "fully insured",
        quoteId: quoteId,
        caseId: caseId,
        effectiveDate: effectiveDate,
        groupSize: groupSize,
        zipcode: zipCode,
        state: state,
        county: county,
        siccode: sicCode,
        franchiseCode: franchiseCode,
        coverage: productsDisplay,
        quoteStatus: caseProgress || "",
        delegateName:
          fullProps.user && fullProps.user.userName
            ? fullProps.user.userName
            : "",
        customerNumber: customerNo,
        memberGroupID: memberGroupId,
        ...additionalData,
        errorType: errorType || "", //Ex: "404 error", "page not found"}
      };
      sendPageLoadData(data);
    });
}

export function sendClickEventDataAction(clickData = {}) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      // const fullProps = getState();
      const {
        clickName,
        clickLocation,
        clickDestination,
        clickType,
        clickElement,
        planFilter,
        productType,
        planName,
        planCode,
        coverageType,
        rateAdjustment,
      } = clickData;

      const data = {
        clickName: clickName || "", // Name of the link/button/filter/Template
        clickLocation: clickLocation || "",
        clickDestination: clickDestination || "",
        clickType: clickType || "", //Example: "internal", "exit", "download"
        clickElement: clickElement || "", //Example: "link", "button", "filter"
        planFilter: planFilter || "", //Example: "standard"
        productType: productType || "", //Example: "PPO|HMO“
        planName: planName || "",
        planCode: planCode || "",
        coverageType: coverageType || "",
        rateAdjustment: rateAdjustment || "", //Example: "original amount: 20$|Adjust value:15$|Adjusted rate: 15%"
      };
      sendClickEventData(data);
    });
}

export function sendErrorTrackingDataAction(errorData = {}) {
  const { activityName, errorFields, errorType, errorCodes } = errorData;

  const data = {
    activityName: activityName || "",
    errorFields: errorFields || "",
    errorType: errorType || "",
    errorCodes: errorCodes || "",
  };
  sendErrorTrackingData(data);
}
