import * as types from '../../../actions/actionTypes';

const initialState = { 
    cases : [],
    enrollingNBCases:[],
    enrollingNBCaseCount: 0,
    notCompletedRLCases:[],
    notCompletedRLCaseCount: 0,
    noDataTextNb:'No Records Found'
  }
export default function newBusiness(state = initialState, action) {

    switch (action.type) {
        case types.REQUEST_NEW_BUSINESS_CASE:
            return { 
                ...state,
                noDataTextNb : 'Loading....'
            }
            
        case types.RECEIVE_NEW_BUSINESS_CASE:
            return {
                ...state,
                cases : action.payload,
                noDataTextNb : 'No Records Found'
            };

            case types.RECEIVE_NEW_BUSINESS_CASE_RANGE:
                return {
                    ...state,
                    cases: state.cases ? [...new Map((state.cases).concat(action.payload).map(o => [o.caseid, o])).values()] : action.payload ,
                    noDataTextNb : 'No Records Found'
                    //cases: state.cases ? (state.cases).concat(action.payload) : action.payload
                    };
    

        case types.ERROR_NEW_BUSINESS_CASE:
            return {
                ...state,
                noDataTextNb : 'No Records Found',
                error : true
            }
        case types.ERROR_NEW_BUSINESS_CASE:
            return {
                ...state,
                error : true
            }
        case types.GET_NB_ENROLL_CASE_COUNT: 
            return {
                ...state,
                enrollingNBCaseCount: action.payload
            }
        case types.GET_NB_ENROLL_CASE: 
            return {
                ...state,
                enrollingNBCases: action.payload
            }
        case types.GET_RL_NOT_COMPLETED_CASE: 
            return {
                ...state,
                notCompletedRLCases: action.payload
            }
        case types.GET_RL_NOT_COMPLETED_CASE_COUNT: 
            return {
                ...state,
                notCompletedRLCaseCount: action.payload
            }
        case types.RESET_TO_INITIALSTATE:
            return initialState
        default:
            return state
    }
}