import * as types from '../../actions/actionTypes';

const initialState = {
    isLoadingPDF: false,
    loadingIndex: 0,
    loadingLocation: 0,
    errorMappingPDF: false,
    errorIndex: 0,
    errorLocation: 0,
};

export default function employeeCensus(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_MAPPED_PDF:
            return {
                ...state,
                isLoadingPDF: true,
                loadingIndex: action.index,
                loadingLocation: action.location,
            };
        case types.RECIEVED_MAPPED_PDF:
            return {
                ...state,
                isLoadingPDF: false,
                errorMappingPDF: false,
            };
        case types.ERROR_MAPPING_PDF:
            return {
                ...state,
                isLoadingPDF: false,
                errorMappingPDF: true,
                errorIndex: action.index,
                errorLocation: action.location,
            };
        case types.RESET_TO_INITIALSTATE:
            return initialState
        default:
            return state;
    }
}
