import * as types from '../../actions/actionTypes';

const initialState = {
    pendData: {
        employeePendData: [],
        employerPendData: [],
    },
    requestPendData: false,
};

export default function internalPendManager(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_INTERNAL_PENDMGR_DATA:
            return {
                ...state,
                requestPendData: true,
            };

        case types.RECEIVE__INTERNAL_PENDMGR_DATA:
            return {
                ...state,
                requestPendData: false,
                pendData: action.payload,
            };

        default:
            return state;
    }
}
