import { getPrimaryLocation } from '../../../components/activities/FullShopping/Census/helpers/checkPrimaryLocation';
import { findObjectThatMeetsConditions } from '../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions';
import { convertDateString } from '../../../utils/formatters/dates';
import deepCopy from '../../../utils/deepCopy';
import { toMoney } from '../../../utils/formatters/numbers';
import { isGuaranteedRule } from '../../../utils/businessLogic/guaranteedPeriod';
import { reduceStateStorePayload } from '../../../utils/stateStorePayload/reduce/reducePayload';
import { isUhcRenewals } from '../../../utils/businessLogic/platformUtils'; 
import moment from 'moment-timezone';

/**
 *  Totals the Total Employees Applying field from Company Profile
 *  @param {array} locations - companyProfile.locations
 *
 *  @returns the tally of employees for the field from all locations in Company Profile
 */
const getTotalEmployeesFromLocation = (locations) => {
    let totalEmployees = 0;
    locations.forEach((location) => {
        totalEmployees += location.totalEmps;
    });
    return totalEmployees;
};

/**
 * Totals the number of employees that have selected medical from the employee census
 * @param {array} employees - companyProfile.employees
 *
 * @returns the tally of employees who have selected medical
 */
const getValuesFromEmployees = (employees, val) => {
    let totalEmployees = 0;
    // the employees are mapped to the locations array, setting this for locations
    const locations = [...employees];

    switch (val) {
        case 'medical':
            locations.forEach((location) => {
                location.forEach((employee) => {
                    if (employee.coverages.medical !== 'W') {
                        totalEmployees += 1;
                    }
                });
            });
            break;
        case 'cobra':
            locations.forEach((location) => {
                location.forEach((employee) => {
                    if (employee.employeeStatus === 'COBRA') {
                        totalEmployees += 1;
                    }
                });
            });
            break;
        case 'waiving':
            {
                let returnVal = 'No';
                locations.forEach((location) => {
                    location.forEach((employee) => {
                        if (employee.coverages.medical === 'W') {
                            returnVal = 'Yes';
                        }
                    });
                });
                return returnVal;
            }
        case 'waivingMedicalTotal':
            locations.forEach((location) => {
                location.forEach((employee) => {
                    if (employee.coverages.medical === 'W') {
                        totalEmployees += 1;
                    }
                });
            });
            break;
        case 'retired':
            locations.forEach((location) => {
                location.forEach((employee) => {
                    if (employee.employeeStatus === 'Retired') {
                        totalEmployees += 1;
                    }
                });
            });
            break;
        default:
            break;
    }
    return `${totalEmployees}`;
};

/**
 *
 * @param {string} sectionName "sectionName" attribute of section from DB
 * @param {array} defaultValueObjs array from the enrollment form reducer (may or may not be an attribute of a field/section) -- see defaultValue in configurableFormPropTypes
 * @param {object} conditionEvaluators current app conditions that affect form rules, acquired from getConditionEvaluators()
 * @param {object} companyProfile the current companyProfile reducer state
 *
 * @returns the value that a field should be defaulted to on initialization
 */
const getDefaultValue = (sectionName, defaultValueObjs, conditionEvaluators, objt = {}) => {
    const matchingDefaultValue = findObjectThatMeetsConditions(
        sectionName,
        defaultValueObjs,
        conditionEvaluators,
    );

    if (!matchingDefaultValue) {
        return '';
    }

    const { valueType, value } = matchingDefaultValue;
    if (valueType === 'text' || valueType === 'boolean') {
        return value;
    }
    if (valueType === 'fromState') {
        // TODO
        return '';
    }
    if (valueType === 'custom') {
        if (objt.companyName) {
            switch (value) {
                case 'effectiveDate':
                    return convertDateString(objt.effectiveDate, '-', 'YYYYMMDD', '/', 'MMDDYYYY');
                case 'primaryCity':
                    return getPrimaryLocation(objt.locations).zipData.city;
                case 'primaryState':
                    return getPrimaryLocation(objt.locations).zipData.stateCode;
                case 'primaryZip':
                    return getPrimaryLocation(objt.locations).zipData.zipCode;
                case 'groupLegalName':
                case 'companyName':
                    return objt.companyName;
                case 'primaryFirstName':
                    return objt.primaryContact.firstName;
                case 'primaryLastName':
                    return objt.primaryContact.lastName;
                case 'primaryEmailAddress':
                    return objt.primaryContact.email;
                case 'sicCode':
                    return objt.sicCode;
                default:
                    return '';
            }
        } else if (objt.employerContribution) {
            switch (value) {
                case 'employeeContributionMedical':
                    return `${objt.employerContribution}` || '0';
                case 'dependentContributionMedical':
                    return `${objt.dependentPercent}` || '0';
                case 'dependentContributionDental':
                    // TODO fix this when dental contribution is complete
                    return '0';
                case 'dependentContributionVision':
                    // TODO fix this when vision contribution is complete
                    return '0';
                case 'dependentContributionLife':
                    // TODO fix this when basic life contribution is complete
                    return '0';
                case 'totalEmployees':
                    return getTotalEmployeesFromLocation(objt.companyProfile.locations);
                case 'medicalCensus':
                    return getValuesFromEmployees(objt.companyProfile.employees, 'medical');
                case 'cobraCensus':
                    return getValuesFromEmployees(objt.companyProfile.employees, 'cobra');
                case 'retiredStatus':
                    return getValuesFromEmployees(objt.companyProfile.employees, 'retired');
                case 'totalMedicalWaiving':
                    return getValuesFromEmployees(objt.companyProfile.employees, 'waivingMedicalTotal');
                case 'totalSpousalWaiving':
                    // TODO set this up when we collect spousal info
                    return '0';
                case 'totalWaivingMedicare':
                    // TODO set this up when we collect medicare info
                    return '0';
                case 'totalWaivingMedicaid':
                    // TODO set this up when we collect medicaid info
                    return '0';
                case 'totalWaivingVeterans':
                    // TODO set this up when we collect veteran info
                    return '0';
                case 'totalWaivingParents':
                    // TODO set this up when we collect parental info
                    return '0';
                case 'totalWaivingOther':
                    // TODO set this up when we collect valid other info
                    return '0';
                case 'totalWaivingValid':
                    // TODO set this up when we collect all valid reasons for waiving
                    return '0';
                default:
                    return '';
            }
        } else {
            switch (value) {
                case 'medicalEmployerContribution':
                    const { primaryLocationIdx } = objt.companyProfile;
                    const { stateCode } = objt.companyProfile.locations[primaryLocationIdx].zipData;
                    const { totalEligEmps } = objt.companyProfile.locations[primaryLocationIdx];

                    if (stateCode === 'SC' || stateCode === 'NC') {
                        if (totalEligEmps <= 3) {
                            return 75;
                        }

                        return 50;
                    }

                    return 50;

                default:
                    return '';
            }
        }
    }
    return '';
};

/**
 *
 * @param {array} sections the list of form sections returned by the DB for a specific page
 * @param {object} conditionEvaluators current app conditions that affect form rules, acquired from getConditionEvaluators()
 * @param {object} objt the object that contains redux values to be parsed for custom values
 *                      right now that only includes quotes and companyProfile
 *
 * @returns state to initialize the reducer for this specific page with all values either empty or filled with their defaults
 */
export const initializeEnrollmentPageState = (sections, conditionEvaluators, objt) => {
    const initializedState = {};
    if (sections && sections.length > 0) {
        sections.forEach((section) => {
            const sectionState = {};
            section.fields.forEach((field) => {
                let value = '';

                switch (field.fieldType) {
                    case 'checkbox':
                        value = false;
                        break;
                    case 'configurableTable':
                        value = [];
                        break;
                    case 'section':
                        const fieldSectionValue = initializeEnrollmentPageState([field.sectionOptions], conditionEvaluators, objt);
                        value = fieldSectionValue[field.fieldName];
                        break;
                }

                if (field.defaultValue) {
                    value = getDefaultValue(section.sectionName, field.defaultValue, conditionEvaluators, objt);
                }

                if (field.fieldName === 'medicalEmployerContribution') {
                    let guaranteedPeriod = null;
                    if (field.validations && field.validations.length > 0) {
                        const min = field.validations.find(validation => validation.validationType === 'min');
                        if (min) {
                            if (conditionEvaluators.platform === 'PRIME') {
                                guaranteedPeriod = min.guaranteedPeriodUhc ? min.guaranteedPeriodUhc : null;
                            } else {
                                guaranteedPeriod = min.guaranteedPeriod ? min.guaranteedPeriod : null;
                            }
                        }
                    }
                    const isGuaranteedPeriod = isGuaranteedRule(guaranteedPeriod, objt.companyProfile.effectiveDate, conditionEvaluators.platform, objt.overall.appConfigDetails.guaranteedPeriod);
                    if (isGuaranteedPeriod) {
                        value = '0'
                    }
                }
                if (field.fieldName === 'numHoursPerWeekToBeEligible') {
                    if (field.validations && field.validations.length > 0) {
                        const min = field.validations.find(validation => validation.validationType === 'min');
                        if(min) {
                            value = min.value;
                        }   
                    }      
                }

                sectionState[field.fieldName] = value;
            });

            initializedState[section.sectionName] = sectionState;
        });
    }
    return initializedState;
};
/* For Renewals */
export const populateEnrollmentEligibilityStateFromContractChangeUhc = (data) => {
    const additionalEligibilityInformation = data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.additionalEligibilityInformation ? data.employerEnrollment.eligibility.additionalEligibilityInformation : {};
    const currentCarrierInformation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.currentCarrierInformation ? data.employerEnrollment.employer.currentCarrierInformation : {};
    const eligibilityInformation = data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.eligibilityInformation ? data.employerEnrollment.eligibility.eligibilityInformation : {};
    const participation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.participation ? data.employerEnrollment.employer.participation : {};
    const standardHRA = data.employerEnrollment && data.employerEnrollment.standardHRA && data.employerEnrollment.standardHRA ? data.employerEnrollment.standardHRA : {};
    const consumerDrivenHealthPlans = data.employerEnrollment && data.employerEnrollment.employer.consumerDrivenHealthPlans && data.employerEnrollment.employer.consumerDrivenHealthPlans ? data.employerEnrollment.employer.consumerDrivenHealthPlans : {};
    const waitingPeriod = data.employerEnrollment && data.employerEnrollment.employer.waitingPeriod && data.employerEnrollment.employer.waitingPeriod ? data.employerEnrollment.employer.waitingPeriod : {};
    const consumerDrivenHealthPlanOption = data.employerEnrollment && data.employerEnrollment.employer.consumerDrivenHealthPlans && data.employerEnrollment.employer.consumerDrivenHealthPlans ? data.employerEnrollment.employer.consumerDrivenHealthPlans : {};
    const contribution = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.contribution ? data.employerEnrollment.employer.contribution : {};

    const obj = {
        additionalEligibilityInformation,
        currentCarrierInformation,
        eligibilityInformation,
        participation,
        standardHRA,
        consumerDrivenHealthPlans,
        consumerDrivenHealthPlanOption,
        waitingPeriod,
        contribution
    };
    return obj;
}

// Employer data from contract change//
export const populateEnrollmentPageStateFromContractChange = (data, employerContribution, caseSubmitted) => {
    const generalInformation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.generalInformation ? data.employerEnrollment.employer.generalInformation : {};
    const primaryLocation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.primaryLocation ? data.employerEnrollment.employer.primaryLocation : {};
    const billingAddress = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.billingAddress ? data.employerEnrollment.employer.billingAddress : {};
    const primaryContactInformation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.primaryContactInformation ? data.employerEnrollment.employer.primaryContactInformation : {};
    const additionalContacts = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.additionalContacts ? data.employerEnrollment.employer.additionalContacts : {};
    const additionalGeneralInformationRenewal = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.additionalGeneralInformationRenewal ? data.employerEnrollment.employer.additionalGeneralInformationRenewal : {}; // new
    const waitingPeriod = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.waitingPeriod ? data.employerEnrollment.employer.waitingPeriod : data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.waitingPeriod ? data.employerEnrollment.eligibility.waitingPeriod : {};
    const consumerDrivenHealthPlans = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.consumerDrivenHealthPlans ? data.employerEnrollment.employer.consumerDrivenHealthPlans : data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.consumerDrivenHealthPlans ? data.employerEnrollment.eligibility.consumerDrivenHealthPlans : {};
    const currentCarrierInformation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.currentCarrierInformation ? data.employerEnrollment.employer.currentCarrierInformation : data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.currentCarrierInformation ? data.employerEnrollment.eligibility.currentCarrierInformation : {};
    const contribution = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.contribution ? data.employerEnrollment.employer.contribution : data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.contribution ? data.employerEnrollment.eligibility.contribution : {};
    const participation = data.employerEnrollment && data.employerEnrollment.employer && data.employerEnrollment.employer.participation ? data.employerEnrollment.employer.participation : data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.participation ? data.employerEnrollment.eligibility.participation : {};
    const eligibilityInformation = data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.eligibilityInformation ? data.employerEnrollment.eligibility.eligibilityInformation : {};
    const additionalEligibilityInformation = data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.additionalEligibilityInformation ? data.employerEnrollment.eligibility.additionalEligibilityInformation : {};

    const obj = {
        generalInformation,
        primaryLocation,
        billingAddress,
        primaryContactInformation,
        additionalContacts,
        additionalGeneralInformationRenewal,
        waitingPeriod,
        contribution,
        participation,
        consumerDrivenHealthPlans,
        currentCarrierInformation,
        eligibilityInformation,
        additionalEligibilityInformation
    };

    if (!caseSubmitted) {
        obj.contribution = employerContribution;
    }
    return obj;
};

// Employer data from API//
export const populateEnrollmentPageState = (data) => {
    const { generalInformation } = data.employerEnrollmentSchema.employerSchema;
    const { primaryLocation } = data.employerEnrollmentSchema.employerSchema;
    const { billingAddress } = data.employerEnrollmentSchema.employerSchema;
    const { primaryContactInformation } = data.employerEnrollmentSchema.employerSchema;
    const { additionalContacts } = data.employerEnrollmentSchema.employerSchema;
    const { additionalGeneralInformation } = data.employerEnrollmentSchema.employerSchema;
    const { waitingPeriod } = data.employerEnrollmentSchema.eligibilitySchema;
    const { consumerDrivenHealthPlanOption } = data.employerEnrollmentSchema.eligibilitySchema;
    const { currentCarrierInformation } = data.employerEnrollmentSchema.eligibilitySchema;
    const [year, month, day] = generalInformation.requestedEffectiveDate.split('-');
    const dOB = `${month}/${day}/${year}`;
    const obj = {
        generalInformation,
        primaryLocation,
        billingAddress,
        primaryContactInformation,
        additionalContacts,
        additionalGeneralInformationRenewal: {
            medicalBenefitPlanOptionRenewal: additionalGeneralInformation.medicalBenefitPlanOption,
            domesticPartnerCoverageRenewal: additionalGeneralInformation.domesticPartnerCoverage,
        },

        waitingPeriod: {
            waitingPeriodForNewHires1: waitingPeriod.waitingPeriodForNewHires,
            terminationLag1: waitingPeriod.terminationLag,
            addAdditionalWaitingPeriod: 'No',
            waitingPeriodWaivedForInitialEnrolleesRenewal: waitingPeriod.waitingPeriodWaivedForInitialEnrollees,
            waitingPeriodWaivedForRehiredEmployeesRenewal: waitingPeriod.waitingPeriodWaivedForRehiredEmployees,
        },

        consumerDrivenHealthPlans: consumerDrivenHealthPlanOption,
        currentCarrierInformation,
    };
    obj.generalInformation.requestedEffectiveDate = dOB;

    return obj;
};

export const updateNullFieldsToDefault = (stateValues, defaultValues) => {
    for (const key in stateValues) {
        for (const dKey in defaultValues) {
            if (key === dKey && stateValues[key] === '') {
                stateValues[key] = defaultValues[dKey];
            }
        }
    }
    return stateValues;
};

/* Need for testing purpose */
// export const populateEnrollmentPageState = data=>{
//     const employer={
//         generalInformation:{
//             requestedEffectiveDate:"07/14/2020",
//             groupLegalName:"CONNECTICUT STATE ASSOCIATION",
//             groupNameOnIDCard:"CONNECTICUT STATE ASSOCIATION",
//             taxID:"1234567890"
//         },
//         primaryLocation:{
//             streetAddress:"Broad St",
//             streetAddressln2:" Bristol BS1 2EL",
//             city:"Bristol",
//             state:"CT",
//             zipCode:"06010",
//             isBillingAddressSame:true
//         },
//         billingAddress:{
//             billingStreetAddress:"BS2 3EL",
//             billingStreetAddressln2:"Street 3",
//             billingCity:"Bristol",
//             billingState:"CT",
//             billingZipCode:"09010"
//         },
//         primaryContactInformation:{
//             primaryFirstName:"Jack",
//             primaryLastName:"Hickman",
//             primaryTitle:"Mr",
//             primaryEmailAddress:'jack.hickman@optum.com',
//             primaryTelephone:"9876543234",
//             primaryExt:"123",
//             primaryPhoneType:'Mobile'
//         },
//         additionalContacts:{
//             contactsTable:"",
//             additionalContactFirstName1:"Jack",
//             additionalContactLastName1:"Hayden",
//             additionalContactTitle1:"Mr.",
//             additionalContactEmail1:"jack.hayden@uhg.com",
//             additionalContactTelephone1:"997-383-4564",
//             additionalContactExt1:"123",
//             additionalContactPhoneType2:"Business",
//             additionalContactFirstName2:"Michael",
//             additionalContactLastName2:"Clarke",
//             additionalContactTitle2:"Mr.",
//             additionalContactEmail2:"michael.clarke@uhg.com",
//             additionalContactTelephone2:"954-383-4564",
//             additionalContactExt2:"156",
//             additionalContactPhoneType2:"Business",
//         },
//         additionalGeneralInformationRenewal:{
//             medicalBenefitPlanOptionRenewal:"Policy Year",
//             domesticPartnerCoverageRenewal:"Yes"
//         },
//         waitingPeriod:{
//             waitingPeriodForNewHires1:"Date of Event",
//             terminationLag1:"Date of Termination",
//             waitingPeriodClassName1:"Class A",
//             addAdditionalWaitingPeriod:"No",
//             waitingPeriodForNewHires2:"Date of Event",
//             terminationLag2:"Date of Termination",
//             waitingPeriodClassName2:"Class B",
//             waitingPeriodWaivedForInitialEnrolleesRenewal:"Yes",
//             waitingPeriodWaivedForRehiredEmployeesRenewal:"Yes"
//         },
//         consumerDrivenHealthPlans:{
//             healthSavingsAccount:"Other",
//             offerHRA:"Yes",
//             identifyType:"United Health Care"
//         },
//         currentCarrierInformation:{
//             havePriorDentalCoverage:"Yes",
//             havePriorLifeCoverage:"Yes"
//         }

//     }
//     return employer

//     }

const getRefinedErrors = (state) => {
    const { errors, ...rest } = state;
    return rest;
};

export const getEnrollmentStateRefined = (fullProps) => {
    const { overall } = fullProps;
    const { companyProfile } = fullProps;
    const { rules } = fullProps;
    const { enrollment } = fullProps;
    const { paymentBilling } = fullProps;
    const { debitForm } = fullProps;
    const { reviewCards } = fullProps;
    const { quotes, dental, vision, life, lifedep: dependentLife, additionalReqdInfo, specialtyCriteria, proposal, caseTracking, supportedArea, solarisReducer, packageSavings } = fullProps;

    const refinedEnrollment = {
        ...enrollment,
        application: {
            ...enrollment.application,
            attestation: getRefinedErrors(enrollment.application.attestation),
            employer: getRefinedErrors(enrollment.application.employer),
            eligibility: getRefinedErrors(enrollment.application.eligibility),
            employee: getRefinedErrors(enrollment.application.employee),
            employerCertification: getRefinedErrors(enrollment.application.employerCertification),
        },
    };

    const payload = {
        overall,
        companyProfile,
        rules,
        enrollment: refinedEnrollment,
        paymentBilling,
        debitForm,
        reviewCards,
        quotes,
        dental,
        vision,
        life,
        dependentLife,
        additionalReqdInfo,
        specialtyCriteria,
        proposal,
        caseTracking,
        supportedArea,
        solarisReducer,
        packageSavings
    }
    const minifiedPayload = reduceStateStorePayload(payload);
    return minifiedPayload;
};

/**
 * Looks for the contact info. General Agent takes priority, producer secondary. Looks
 * for the communication email first, if none, adds the email field
 * @param {array} ownerObj - array that includes the generalAgent and producer information
 * from enrollment.application.producers
 * @returns array of email strings
 */
export const getProducerAssignedMails = (ownerObj) => {
    const {
        generalAgent: {
            gaCommunicationEmail0: gaCommEmail = null,
            gaEmail0: gaEmail = null
        } = {},
        producer = {},
    } = ownerObj;

    let mailArray = [];
    if (gaCommEmail) {
            mailArray.push(gaCommEmail);
        } else {
            mailArray.push(gaEmail);
        }
   
        let totalProducers = 0;
        Object.keys(producer).forEach((key) => {
            if (key.includes('FirstName')) {
                totalProducers += 1;
            }
        });
        for (let i = 0; i < totalProducers; i++) {
            const email = producer[`producerEmail${i}`] || null;
            const commEmail = producer[`producerCommunicationEmail${i}`] || null;
            if (commEmail) {
                mailArray.push(commEmail);
            } else {
                mailArray.push(email);
            }
        }
    return mailArray;
};

export const getTotalNumberCurrPlans = (renewalData) => {
    const { planRates } = renewalData;
    const count = (planRates || []).reduce((a, b) => {
        if (b.productType === 'Medical' && b.renewalPlanType === 'Renewal') { a++; }
        return a;
    }, 0);
    return count;
}

export const saveStateStoreFinalized = (store) => {
    const fullProps = store;
    const quotesObj = {
        ...fullProps.quotes,
        isFetchingPlans: false,
        numRateBatchesFetching: 0,
    };
    const dentalPlans = {
        ...fullProps.dental,
        allPlans: [],
        filteredResults: [],
        advancefilterSet: [],
        basicFilters: [],
        isFetchingPlans: false,
    };
    const visionPlans = {
        ...fullProps.vision,
        allPlans: [],
        filteredResults: [],
        advancefilterSet: [],
        basicFilters: [],
        isFetchingPlans: false,
    };
    const lifePlans = {
        ...fullProps.life,
        allPlans: [],
        filteredResults: [],
        advancefilterSet: [],
        basicFilters: [],
        isFetchingPlans: false,
    };
    const dependentLifePlans = {
        ...fullProps.lifeDep,
        allPlans: [],
        filteredResults: [],
        advancefilterSet: [],
        basicFilters: [],
        isFetchingPlans: false,
    };
    const proposal = {
        ...fullProps.proposal,
        isGenerating: false,
        isSendingEmail: false,
    };
    const compProfile = {
        ...fullProps.companyProfile,
        isUpdatingProfile: false,
        isImporting: false,
    };
    const caseTrackObj = {
        ...fullProps.caseTracking,
        updatingCaseTrack: false,
    };
    const overallObj = {
        ...fullProps.overall,
    };
    delete overallObj.appConfigDetails;
    const addReqInfo = {
        ...fullProps.additionalReqdInfo,
        isUploadingDocument: false,
        isUploadingToEdms: false,
        uploadEdmsError: false,
        isUploadingToDoc360: false,
        uploadDoc360Error: false,
    };
    const stateStoreObj = {
        quotes: quotesObj,
        overall: overallObj,
        dental: dentalPlans,
        vision: visionPlans,
        life: lifePlans,
        dependentLife: dependentLifePlans,
        companyProfile: compProfile,
        additionalReqdInfo: addReqInfo,
        specialtyCriteria: fullProps.specialtyCriteria,
        rules: fullProps.rules,
        enrollment: {
            ...fullProps.enrollment,
            application: {
                ...fullProps.enrollment.application,
                employerCertification: getRefinedErrors(fullProps.enrollment.application.employerCertification)
            }
        },
        paymentBilling: fullProps.paymentBilling,
        debitForm: fullProps.debitForm,
        proposal,
        caseTracking: caseTrackObj,
        supportedArea: fullProps.supportedArea,
        reviewCards: fullProps.reviewCards,
        solarisReducer: fullProps.solarisReducer,
        packageSavings: fullProps.packageSavings
    };
    const minifiedPayload = reduceStateStorePayload(stateStoreObj);
    return minifiedPayload;
}

export const buildWagesAndTaxRequest = (enrollment, companyProfile, caseId) => {
    let enrollmentData = {}

    enrollmentData.caseId = caseId
    enrollmentData.memberGroupId = companyProfile.memberGroupId ? companyProfile.memberGroupId.toString() : "";
    enrollmentData.CompanyName = enrollment && enrollment.application && enrollment.application.employer && enrollment.application.employer.generalInformation.groupLegalName ? enrollment.application.employer.generalInformation.groupLegalName : companyProfile.companyName
    enrollmentData.TinNumber = enrollment && enrollment.application && enrollment.application.employer && enrollment.application.employer.generalInformation.taxID ? enrollment.application.employer.generalInformation.taxID : ''
    enrollmentData.EffectiveDate = companyProfile.effectiveDate ? companyProfile.effectiveDate : ""
    
    return enrollmentData
}


export const handleDates = (date, isDeadlineDate) => {
    let newDeadlineDate
    if (!isDeadlineDate) {
        let newEffDate = date.includes("T") ? date.split("T")[0] : date
        let neweDate = moment.tz(newEffDate, 'America/New_York').endOf('day');
        const newCurrDate = moment.tz('America/New_York')
        return { neweDate, newCurrDate }
    }
    else {
        if (date.includes('T')) {

            let deadlineDate = date.split("T")
            newDeadlineDate = moment.tz(deadlineDate[0], 'America/New_York').endOf('day');
        }
        else {
            newDeadlineDate = moment.tz(date, 'America/New_York').endOf('day');
        }
        return { newDeadlineDate }
    }
}

export const handleDatesUHC = (date) => {
    let newDeadlineDate
        if (date.includes('T')) {
            let deadlineDate = date.split("T")
            newDeadlineDate = moment.tz(deadlineDate[0], 'America/New_York').endOf('month').endOf("day");
        }
        else {
            newDeadlineDate = moment.tz(date, 'America/New_York').endOf('month').endOf("day");
        }
        return { newDeadlineDate }
}

export const checkForFinalizeEligiblity = (effectiveDate, stateCode, renewals) => {
    let dateEligible = true
    if(isUhcRenewals()){
        let { newDeadlineDate } = handleDatesUHC(effectiveDate)
        let  newCurrDate = moment.tz('America/New_York')
        if((newCurrDate).isAfter(newDeadlineDate)) {
            dateEligible = false;
        } else {
            dateEligible = true;
        }
    } else {
    const { neweDate, newCurrDate } = handleDates(effectiveDate, false)
    if (stateCode === 'NJ' && (neweDate.get('date') !== 1 && neweDate.get('date') !== 15)) {
        if (newCurrDate.isAfter(neweDate)) {
            dateEligible = false
        }
    }
    else {
        const eligibleDate = renewals.dateRules;
        let renewalEffDate = effectiveDate.includes("T") ? effectiveDate.split("T")[0] : effectiveDate
        let deadlineDateFound = false
        for (var i = 0; i < eligibleDate.length; i++) {
            if (renewalEffDate === eligibleDate[i].effectiveDate) {
                deadlineDateFound = true
                const { newDeadlineDate } = handleDates(eligibleDate[i].deadlineDate, true)
                if (newCurrDate.isAfter(newDeadlineDate)) {
                    dateEligible = false
                }
            }
        }
        if (!deadlineDateFound) {
            if (newCurrDate.isAfter(neweDate)) {
                dateEligible = false
            }
        }
    }
    }

    return dateEligible
}

export const checkForAllExchangePlans = (plans) => {
    let exchangePlans = [], nonExchangePlans = []
    if (plans && plans.length > 1) {
        exchangePlans = plans.filter(plan => plan.isExchange)
        nonExchangePlans = plans.filter(plan => !plan.isExchange)
    }
    if (exchangePlans.length > 0 && nonExchangePlans.length > 0)
        return false
    else
        return true

}
