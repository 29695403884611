const { isEmpty } = require("./strings");

// If you don't know whether a string is a % or $ but want it formatted
exports.toMoneyOrPercent = (str) => {
    if (str.indexOf('%') > -1) {
        return str;
    }
    return this.toMoney(str);
};

exports.toMoney = (str, includeDollarSign = true, includeCent = true) => {
    try {
        if (str.match(/[a-zA-Z]/i)) {
            // alphabet letters found
            return str;
        }
        
        if (str === NaN) {
            // Value is NAN
            return 0;
        }

        if (str.indexOf('$') >= 0) {
            str = str.replace(/\$/gi, '');
        }

    } catch (err) {
        // Don't attempt to convert an invalid input
    }

    const ret = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: includeCent ? 2 : 0 }).format(str);
    if (includeDollarSign) {
        return ret;
    }

    return ret.split('$')[1];
};

exports.addComma = (str) => {
    try {
        return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } catch (err) {
        // Don't attempt to convert an invalid input
    }
};

/**
 * @param str Can be a string OR a number, in DECIMAL form
 */
exports.toPercentage = (str, includePercentSign = true) => {
    try {
        if (str.match(/[a-zA-Z]/i)) {
            // alphabet letters found
            return str;
        }
    } catch (err) {
        // Don't attempt to convert an invalid input
    }

    const ret = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 0 }).format(str);
    if (includePercentSign) {
        return ret;
    }

    return ret.split('%')[0];
};

exports.isPercentage = (str) => {
    return str[str.length-1] === "%";
}

exports.parseMoneyString = (str) => parseFloat(str
    .replace(/\$/gi, '')
    .replace(/,/gi, '')
    .replace(/ /gi, ''));

exports.pdfPercentage = (str) => parseFloat(str) * 100;

exports.tierPercentage = (str) => parseFloat(str) * 10;

exports.validateMoney = (str) => {
    if (typeof str === 'number') {
        str = str.toString();
    }

    if (!str.match(/^([1-9](\d{1,6})?((\.((\d)?(\d)?)?)?)?)?$/)) { // if the string isn't up to 7 digits, followed by decimal, followed by up to 2 digits, or any subset thereof
        return null;
    }

    return str;
};

exports.is0to100 = (str) => str.match(/^([0-9]|([1-9][0-9])|100)$/);

exports.isValidCurrency = (str) => {
    str = String(str);
    if (!str.match(/^[1-9](\d{1,6})?(\.\d{2})?$/)) { // if the string isn't up to 7 digits, optionally followed by decimal & exactly 2 digits
        return false;
    }
    return true;
};

const ageRegex = /^[0-9](\d{1})?$/; // accepts ages 0 to 99

exports.validateAge = (str) => ((str === '' || ageRegex.test(str)) ? str : null);

exports.isValidAge = (str) => ageRegex.test(str);

exports.getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

const zipRegex = /^\d{0,5}$/; // accepts 5 digits

exports.validateZipCode = (str) => (zipRegex.test(str) ? str : null);

/**
 * @description Returns age from DOB
 *
 * @param {string} str DOB in MM/DD/YYYY or YYYY-MM-DD (excel) format
 *
 * @returns {string} age as a string
 */
exports.getAgeFromDOB = (str, effectiveDate) => {

    if (isEmpty(str) || isEmpty(effectiveDate)) {
        return null
    }
    
    // Today is set based on effectiveDate format (MM/DD/YYYY YYYY-MM-DD)
    let splitEffectiveDate, today;
    if (effectiveDate.includes('/')) {
        splitEffectiveDate = effectiveDate.split('/')
        today = new Date(splitEffectiveDate[2], splitEffectiveDate[0], splitEffectiveDate[1]);

    }
    if (effectiveDate.includes('-')) {
        splitEffectiveDate = effectiveDate.split('-')
        today = new Date(splitEffectiveDate[0], splitEffectiveDate[1], splitEffectiveDate[2]);
    }

    const splitBirthDate = str.split('/');

    // Create date objects from the split dates
    const birthDate = new Date(splitBirthDate[2], splitBirthDate[0], splitBirthDate[1]);

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    // Throw an error if date format is incorrect
    if (Number.isNaN(age)) {
        throw 'Invalid Date';
    }

    if (age < 0) {
        return 0
    }

    return age;
};

exports.getDOBFromAge = (nbr, givenDate, uiCalculation = false) => {

    if (isEmpty(givenDate)) {
        return null
    }

    if (givenDate.includes('/')) {
        const splitDate = givenDate.split("/");

        if (uiCalculation === true) {
            let MM = Math.abs(splitDate[1])
            let DD = Math.abs(splitDate[2])
            const YYYY = Math.abs(splitDate[0] - nbr)

            if (MM < 10) { MM = `0${MM}` }
            if (DD < 10) { DD = `0${DD}` }

            return (`${MM}/${DD}/${YYYY}`)
        }

        let MM = Math.abs(splitDate[0])
        let DD = Math.abs(splitDate[1])
        const YYYY = Math.abs(splitDate[2] - nbr)

        if (MM < 10) { MM = `0${MM}` }
        if (DD < 10) { DD = `0${DD}` }

        return (`${MM}/${DD}/${YYYY}`)
    }

    if (givenDate.includes('-')) {
        const splitDate = givenDate.split("-");

        let MM = Math.abs(splitDate[1])
        let DD = Math.abs(splitDate[2])
        const YYYY = Math.abs(splitDate[0] - nbr)

        if (MM < 10) { MM = `0${MM}` }
        if (DD < 10) { DD = `0${DD}` }

        return (`${MM}/${DD}/${YYYY}`)
    }

    const YYYY = Math.abs(givenDate.slice(0, 4));
    const newYear = YYYY - nbr;
    return (`${givenDate.slice(5, 7)}/${givenDate.slice(8, 10)}/${newYear}`);
};


// exports.getDOBFromAge = (nbr, givenDate) => {
//     if (givenDate.includes('/')) {
//         const splitDate = givenDate.split("/");
//
//         let MM = Math.abs(splitDate[0])
//         let DD = Math.abs(splitDate[1])
//         const YYYY = Math.abs(splitDate[2] - nbr)
//
//         if (MM < 10) { MM = `0${MM}` }
//         if (DD < 10) { DD = `0${DD}` }
//
//         return (`${MM}/${DD}/${YYYY}`)
//     }
//
//     if (givenDate.includes('-')) {
//         const splitDate = givenDate.split("-");
//
//         let MM = Math.abs(splitDate[0])
//         let DD = Math.abs(splitDate[1])
//         const YYYY = Math.abs(splitDate[2] - nbr)
//
//         if (MM < 10) { MM = `0${MM}` }
//         if (DD < 10) { DD = `0${DD}` }
//
//         return (`${MM}/${DD}/${YYYY}`)
//     }
//
//     const YYYY = Math.abs(givenDate.slice(0, 4));
//     const newYear = YYYY - nbr;
//     return (`${givenDate.slice(5, 7)}/${givenDate.slice(8, 10)}/${newYear}`);
// };

exports.toNumber = (str) => parseInt(str, 10);

exports.toFloat = (str) => parseFloat(str, 10);

// Finds percent difference between 2 numerical values
exports.percentDifference = (a, b) => {
    return 100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
}