import React, { Suspense, lazy } from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './Routes/PrivateRoute';
import './shared.css';

const Home = lazy(() => import('./Home'));
const QuickQuotesHome = lazy(() => import('./QuickQuotesHome'));
// const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const CompanyProfile = lazy(() => import('../activities/FullShopping/CompanyProfile/CompanyProfile'));
const EmployeeCensus = lazy(() => import('../activities/FullShopping/Census/EmployeeCensus'));
const Plans = lazy(() => import('../shared/Plans/Plans'));
const QuoteReview = lazy(() => import('../activities/FullShopping/QuoteReview/QuoteReview'));
const Confirmation = lazy(() => import('../activities/FullShopping/Confirmation/Confirmation'));
const OwnerAssignment = lazy(() => import('../activities/FullShopping/OwnerAssignment/OwnerAssignmentPage'));
const EnrollManager = lazy(() => import('../activities/Enrollment/EnrollManager/EnrollManager'));
const EnrollmentDetailsContainer = lazy(() => import('../activities/Enrollment/shared/EnrollmentDetails/EnrollmentDetailsContainer'));
const Expired = lazy(() => import('../messages/Expired'));
const Unauthorized = lazy(() => import('../messages/Unauthorized'));
const Logout = lazy(() => import('../messages/Logout'));
const SavedCasesPage = lazy(() => import('../shared/SavedCases/SavedCasesPage'));
const ServiceDownSplash = lazy(() => import('../../components/messages/ServiceDownSplash'));

// Renewal components
const LogIn = lazy(() => import('../activities/Renewal/Login/Login.jsx'));
const RenewalDashboard = lazy(() => import('../activities/Renewal/RenewalDashboard/RenewalDashboard'));
const MmrDashboard = lazy(() => import('../activities/Renewal/MmrDashboard/MmrDashboard'));
const ReviewPage = lazy(() => import('../activities/Renewal/Review/ReviewPage'));
const DecidePage = lazy(() => import('../activities/Renewal/Decide/DecidePage'));
const RenewalEnrollment = lazy(() => import('../activities/Renewal/RenewalEnrollment/EnrollManager/RenewalEnrollment'));
const Renewalshopping = lazy(() => import('../activities/Renewal/RenewalShopping/RenewalShopping'));
const RenewalsEnrollmentDetailsContainer = lazy(() => import('../activities/Renewal/RenewalEnrollment/shared/EnrollmentDetails/EnrollmentDetailsContainer'));
const Finalize = lazy(() => import('../activities/Renewal/Finalize/Finalize'));
const RenewalFinalizeCase = lazy(() => import('../activities/Renewal/Finalize/RenewalFinalizeCase'));
const UtilizationDashboard = lazy(() => import('../activities/Renewal/UtilizationDashboard/UtilizationDashboard'));

// New Shopping DashBoard
const NewShoppingDashboard = lazy(() => import('../Dashboard/NewShopping/NewShoppingDashboard'));

//DashboardMaintainence
const DashboardMaintainence = lazy(()=>import("../Dashboard-Maintainence/DashboardMaintainence"));

//MaintainencePage

const  MaintainencePage  = lazy(()=>import("../Dashboard-Maintainence/MaintainencePage"));

//SubmissionExtensionPage

const SubmissionExtension =lazy(()=>import("../Dashboard-Maintainence/SubmissionExtension"));

//PcpAutomation

const PcpAutomation =lazy(()=>import("../Dashboard-Maintainence/PcpAutomation"));

// Finalize Case
const FinalizeCase = lazy(() => import('../activities/Enrollment/Finalize/FinalizeCase'));

// Renewal history
const actionHistory = lazy(() => import('../activities/Renewal/History/ActionHistory'));
const auditHistory = lazy(() => import('../activities/Renewal/History/AuditHistory'));

const MappingPdfMaintenance = lazy(() => import('../MappingpdfMaintenance/MappingPdfMaintenance'));

let NonSSOLogin;
if (process.env.REACT_APP_ENV === 'local') {
    NonSSOLogin = lazy(()=> import('../activities/Renewal/Login/LoginLocal.jsx'));
}

const ContentContainer = () => {
    const loading = (
        <Dimmer active inverted>
            <Loader inverted>Loading...</Loader>
        </Dimmer>
    );

    let nonSSOLoginRoute;
    if (process.env.REACT_APP_ENV === 'local') {
        nonSSOLoginRoute = <PrivateRoute exact path="/" component={process.env.REACT_APP_SPECIALTY_ONLY === 'true' ? QuickQuotesHome : 
        process.env.REACT_APP_ISLOCAL === 'true'? NonSSOLogin: LogIn }/> 
    } else {
        nonSSOLoginRoute =  <PrivateRoute exact path="/" component={process.env.REACT_APP_SPECIALTY_ONLY === 'true' ? QuickQuotesHome : LogIn }/> 
    }

    let nonSSOLoginRouteQuickQuotes;
    if (process.env.REACT_APP_ENV === 'local') {
        nonSSOLoginRouteQuickQuotes = <PrivateRoute path="/login" component={process.env.REACT_APP_SPECIALTY_ONLY === 'true' ? () => <Redirect to="/quick-quotes" /> : (process.env.REACT_APP_ISLOCAL==='true'? NonSSOLogin :  LogIn)} />
    } else {
        nonSSOLoginRouteQuickQuotes = <PrivateRoute path="/login" component={process.env.REACT_APP_SPECIALTY_ONLY === 'true' ? () => <Redirect to="/quick-quotes" /> : LogIn} />
    }

    return (
        <Suspense fallback={loading}>
            <Segment className="appContentContainer">
                <Switch>
                    {window.scrollTo(0, 0)}
                    <PrivateRoute exact path="/home" component={Home} />

                    {nonSSOLoginRoute}
                    
                    {/* <PrivateRoute exact path="/" component={Dashboard} /> */}
                    <PrivateRoute exact path="/quick-quotes" component={QuickQuotesHome} />
                    { /* Full shopping paths */}
                    {/* <PrivateRoute exact path="/full/" component={CompanyProfile} /> */}
                    <PrivateRoute exact path="/full/" component={process.env.REACT_APP_SPECIALTY_ONLY === 'true' ? () => <Redirect to="/quick-quotes" /> : CompanyProfile} />
                    <PrivateRoute exact path="/plans" component={Plans} />
                    {/* <PrivateRoute exact path="/plans" component={Plans} /> */}
                    <PrivateRoute path="/employee/" component={EmployeeCensus} />
                    {/* <PrivateRoute path="/employee/" component={EmployeeCensus} /> */}
                    <PrivateRoute path="/saved-quotes" component={SavedCasesPage} />
                    <PrivateRoute path="/summary" component={QuoteReview} />
                    <PrivateRoute path="/owner-assignment" component={OwnerAssignment} />
                    <PrivateRoute path="/confirmation" component={Confirmation} />
                    <PrivateRoute path="/enrollment/manager" component={EnrollManager} />
                    <PrivateRoute path="/enrollment/*" component={EnrollmentDetailsContainer} />
                    { /* End of Full shopping paths */}

                    {/* Renewal paths */}
                    
                    {nonSSOLoginRouteQuickQuotes}

                    <PrivateRoute path="/renewal-dashBoard" component={RenewalDashboard} />
                    <PrivateRoute path="/mmr-dashboard" component={MmrDashboard} />
                    <PrivateRoute path="/reviewDetail" component={ReviewPage} />
                    <PrivateRoute path="/decideDetail" component={DecidePage} />
                    <PrivateRoute path="/renewalShopping" component={Renewalshopping} />
                    <PrivateRoute path="/enrollmentManager/manager" component={RenewalEnrollment} />
                    <PrivateRoute path="/enrollmentManager/*" component={RenewalsEnrollmentDetailsContainer} />
                    <PrivateRoute path="/finalize" component={Finalize} />
                    <PrivateRoute path="/renewal-finalize-case" component={RenewalFinalizeCase} />
                    <PrivateRoute path="/utilization-dashboard" component={UtilizationDashboard} />

                    {/* New Shopping Dashboard paths */}
                    <PrivateRoute path="/new-shopping-dashboard" component={NewShoppingDashboard} />
                    <PrivateRoute path ="/Dashboard-Maintainence" component ={DashboardMaintainence}/>
                    <PrivateRoute path ="/MaintainencePage"       component ={MaintainencePage}/>
                    <PrivateRoute path ="/SubmissionExtension"       component ={SubmissionExtension}/>
                    <PrivateRoute path ="/PcpAutomation"       component ={PcpAutomation}/>

                    {/* Dual Door paths */}
                    <PrivateRoute path="/finalize-case" component={FinalizeCase} />

                    {/* Renewal case history paths */}
                    <PrivateRoute path="/action-history" component={actionHistory} />
                    <PrivateRoute path="/audit-history" component={auditHistory} />

                    <PrivateRoute path="/expired" component={Expired} />

                    <PrivateRoute path="/unauthorized" component={Unauthorized} />
                    <PrivateRoute path="/logout" component={Logout} />
                    <PrivateRoute path='/app-down-maintainence' component={ServiceDownSplash} />
                    <PrivateRoute path='/mapping-pdf-maintainence' component={MappingPdfMaintenance} />
                    <Route path="*" component={() => <Redirect to="/" />} />
                </Switch>
            </Segment>
        </Suspense>
    );
};
export default ContentContainer;