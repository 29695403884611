import * as types from '../../actions/actionTypes';
import {
    filterPlans, findNonPremiumDomainMaxes, findPremiumDomainMax, standaloneFilter, packageFilter,
    sortPlans,getSelectedPlansBenefitRiders
} from './helpers/filterHelper';
import {
    handleUpdatePlan, handleUpdateAllPlans, handleChangeSortOrder, handleChangeFilterCriteria, handleResetFilterCriteria, handleTogglePreferredPlans, handleReceiveBenefitRiderRates,
} from './helpers/filteringReducerHelpers';
import {
    handlePlansError, handleRequestPlans, handleRequestRates, handleRatesError, handleRequestBenefitRiderRates, handleSaveSelectedRidersInPlans, handleRequestPlanRatesWithSelectedRiders, handleReceivePlanRatesWithSelectedRiders, handleRestorePremiumOnDeselect,
} from './helpers/fetchingReducerHelpers';
import { calculateQuickQuotesFromRates } from '../../utils/businessLogic/calculateQuotes';
import { updateRateToDefaultType, setAlternateRatingMethod } from '../../utils/Renewal/uhcUpdateRates';

const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    ratingMethod: {
        age: false,
        tier: false,
    },
    planType: {
        medical: false,
        dental: false,
        vision: false,
    },
    ooa: {
        In: false,
        Out: false,
        'Out - Live and Travel': false,
        'Out - Travel': false,
    },
    tiered: {
        isTiered: false,
    },
    network: {
        Liberty: false,
        Freedom: false,
        'CHOICE PLUS': false,
    },
    pcpRequired: {
        yes: false,
        no: false,
    },
    oopRange: [0, 9999999],
    dedRange: [0, 9999999],
    premRange: [0, 9999999],

    // unique to Fully Insured

    standaloneSelected: true,
    selectedPackageCode: null,

    metalType: {
        platinum: false,
        gold: false,
        silver: false,
        bronze: false,
    },
    licenseType: {
        hmo: false,
        ppo: false,
        ins: false,
        hny: false,
        epo: false,
    },
    hsaHra: {
        hsa: false,
        hra: false,
    },
    uhcRewardLevel: {
        uhcRewardsCore: false,
        uhcRewardsPremium: false,
    },
    riderDomesticPartner: {
        yes: false,
        no: false,
    },
    riderAge29: {
        yes: false,
        no: false,
    },
};

const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    ratingMethod: {
        age: 0,
        tier: 0,
    },
    planType: {
        medical: 0,
        dental: 0,
        vision: 0,
    },
    metalType: {
        platinum: 0,
        gold: 0,
        silver: 0,
        bronze: 0,
    },
    licenseType: {
        hmo: 0,
        ppo: 0,
        ins: 0,
        hny: 0,
        epo: 0,
    },
    hsaHra: {
        hsa: 0,
        hra: 0,
    },
    uhcRewardLevel: {
        uhcRewardsCore: 0,
        uhcRewardsPremium: 0,
    },
    tiered: {
        isTiered: 0,
    },
    ooa: {
        In: 0,
        Out: 0,
        'Out - Live and Travel': 0,
        'Out - Travel': 0,
    },
    network: {},
    pcpRequired: {
        yes: 0,
        no: 0,
    },
    riderDomesticPartner: {
        yes: 0,
        no: 0,
    },
    riderAge29: {
        yes: 0,
        no: 0,
    },
};

const initialBreadCrumbAccess = {
    full: true,
    employee: false,
    plans: false,
    summary: false,
    enrollmanager: false,
};

const initialState = {
    renewalShoppingRequest: {
        groupSize: null,
        zipCode: null,
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    isFetchingPlans: false,
    isFetchingMedicalPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    zipCodeReturnError: null,
    sicCodeReturnError: null,
    isCirrus: true,
    planNetworks: [],
    plans: [],
    visiblePlans: [],
    selectedPlans: [],
    favoritedPlans: [],
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    sortEnabled: true,
    ascending: true,
    numStandalonePlans: 0,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    breadCrumbAccess: initialBreadCrumbAccess,
    filterTotalsCache: initialfilterTotalsCache,
    oopDomainMax: [0, 9999999],
    dedDomainMax: [0, 9999999],
    premDomainMax: [0, 9999999],
    maxAgeBandedPlans: null,
    showPreferredPlans: true,
    employerContribution: 50,
    employeeContribution: 50,
    showAnnualPremium: false,
    preferredDentalPlans: false,
    preferredVisionPlans: false,
    preferredLifePlans: false,
    renewalMedicalAllowed: true,
    numBenRidersRatesFetched : 0,
    allBenRidersRatesFetched : false,
    allNewRatesFetched : true,
    optionalBenefitRiders : [],
    selectedPlansBenefitRiders : [],
    deselectedPlanCodes : [],
    enableMedical: false,
    eligibilityCountsEntered: false,
    eligibilityCounts: {
        eligible: "",
        atne: "",
        fte: ""
    }
};
export default function renewalShopping(state = initialState, action) {
    let newPlans;
    let newSelectedPlans;
    let newState;

    switch (action.type) {
        case 'SET_RENEWAL_CASE_DATA': return { ...state, renewalCaseData: action.payload };
        case types.RESET_SHOPPING_DATA:
            return initialState;
        case types.SHOPPING_REQUEST:
            return {
                ...state,
                shoppingReq: action.payload,
            };

        case types.SET_DEFAULT_RATING_METHOD:
            return {
                ...state,
                filterCriteria: {
                    ...state.filterCriteria,
                    ratingMethod: {
                        ...action.payload
                    }
                }
            }
        case types.SERVICE.RS.REQUEST_REN_ADDITIONAL_PLANS:
            return handleRequestPlans(state,action, initialFilterCriteria, initialfilterTotalsCache);

        case types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_PLANS:
            const plans = action.payload.medicalPlans.map((plan) => ({ ...plan, selected: false, favorited: false }));
            // Get a list of unique plan networks available
            const planNetworksObj = {};
            plans.forEach((plan) => {
                const network = plan.medicalPlanType;
                if (planNetworksObj[network] !== false) {
                    planNetworksObj[network] = false; // We are setting it to FALSE so we can use this object as the initial filter criteria
                }
            });
            const planNetworkKeys = Object.keys(planNetworksObj);
            const totals = {
                ...state.visiblePlanFilterTotals,
                network: planNetworkKeys.reduce((obj, item) => (obj[item] = 0, obj), {}),
            };

            let visiblePlans;
            const isCirrus = action.payload.medicalPlans[0].platform === 'CIRRUS';
            let standaloneSelected = true;
            let numStandalonePlans = 0;
            let selectedPackageCode = null;
            if (!isCirrus) {
                const standalonePlans = plans.filter(standaloneFilter);
                numStandalonePlans = standalonePlans.length;
                if (action.payload.medicalPackages.length === 0) { // If Prime zip code with 0 packages
                    // Display standalone plans (we assume that if there are 0 packages, then there aren't also 0 standalone plans - that would mean 0 total plans)
                    visiblePlans = standalonePlans;
                } else {
                    // Display plans from the 1st package
                    standaloneSelected = false;
                    selectedPackageCode = action.payload.medicalPackages[0].code;
                    visiblePlans = plans.filter((plan) => packageFilter(plan, selectedPackageCode));
                }
            } else {
                visiblePlans = JSON.parse(JSON.stringify(plans));
            }

            const favoritedPlans = [];
            const favoritedPlanCodes = JSON.parse(localStorage.getItem(`favorites_${plans[0].platform}_${action.renewalShoppingRequest.zipCode}_${action.renewalShoppingRequest.countyCode}`) || '[]');
            favoritedPlanCodes.forEach((code) => {
                const planIdx = plans.findIndex((plan) => plan.code === code);
                const visiblePlanIdx = visiblePlans.findIndex((plan) => plan.code === code);
                plans[planIdx].favorited = true;
                visiblePlans[visiblePlanIdx].favorited = true;
                favoritedPlans.push(plans[planIdx]);
            });

            let selectedState = {
                plans,
                visiblePlans,
                selectedPlans: [...state.selectedPlans],
                selectedPlansMap: {...state.selectedPlanMap},
            };

            const { overall } = action.fullProps;
            if (!(overall.appConfigDetails && overall.appConfigDetails.marketType === 'PRIME' && overall.isRenewals)) {
                if (action.fullProps.renewalShoppingHelper && action.fullProps.renewalShoppingHelper.shoppingPlans && action.fullProps.renewalShoppingHelper.shoppingPlans.length > 0) {
                    const selectedShoppingPlans = action.fullProps.renewalShoppingHelper.shoppingPlans;
                    for (const plan of selectedShoppingPlans) {
                        if (plan.renewalPlanType !== 'renewing') {
                            selectedState = handleUpdatePlan(selectedState, {
                                type: types.SERVICE.FI.UPDATE_PLAN,
                                plan,
                                field: 'selected',
                                pkg: 'Single',
                            }, 'RS');
                        }
                    }
                }
            }

            const {
                plans: updatedPlans,
                visiblePlans: updatedVisiblePlans,
                selectedPlans,
                selectedPlansMap,
            } = selectedState;

            return {
                ...state,
                renewalShoppingRequest: action.renewalShoppingRequest,
                plans: updatedPlans,
                planNetworks: planNetworkKeys,
                visiblePlans: updatedVisiblePlans,
                selectedPlans,
                selectedPlansMap,
                favoritedPlans,
                packages: action.payload.medicalPackages,
                isCirrus,
                numStandalonePlans,
                visiblePlanFilterTotals: totals,
                filterCriteria: {
                    ...state.filterCriteria,
                    standaloneSelected,
                    selectedPackageCode,
                    network: planNetworksObj,
                    oopRange: findNonPremiumDomainMaxes('oop', action.payload.medicalPlans),
                    dedRange: findNonPremiumDomainMaxes('ded', action.payload.medicalPlans),
                },
                numRatesFetched: 0,
                oopDomainMax: findNonPremiumDomainMaxes('oop', action.payload.medicalPlans), // update oopDomainMax & dedDomainMax
                dedDomainMax: findNonPremiumDomainMaxes('ded', action.payload.medicalPlans),
                rxPlans: action.payload.rxPlans,
                optionalBenefitRiders: action.payload.optionalBenefitRider,
                selectedPlansBenefitRiders: getSelectedPlansBenefitRiders(selectedPlans, action.payload.optionalBenefitRider)
            };

        case types.SERVICE.RS.UPDATE_PLAN:
            return handleUpdatePlan(state, action, 'RS');

        case types.SERVICE.RS.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action, 'RS');

        case types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS:
            return handlePlansError(state, action.payload);

        case types.SERVICE.RS.REQUEST_REN_ADDITIONAL_RATES:
            return handleRequestRates(state);

        // Configures the rates response into the 'plans' array of objects (note that rates come in batches of 10)
        //
        // Parameters
        //  rates:          Array of rates - either age-banded or tiered
        //  index:          Keeps track of how many rates have already come. Necessary due to having to read rates 10 at a time
        //  quotes:         The calculated rate for the plan
        case types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_RATES:
            newPlans = JSON.parse(JSON.stringify(state.plans));
            newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
            const numRatesFetched = state.numRatesFetched + action.rates.length;
            const numAltRatesFetched = state.numAltRatesFetched ? state.numAltRatesFetched + action.rates.length : action.rates.length;
            const allRatesFetched = state.plans.length === numRatesFetched;

            // Add the rates from this batch to their corresponding plans
            action.rates.forEach((rate) => {
                if (action.isUhcRenewal) {
                    const renewingPlan = (state.selectedPlans || []).find(plan => plan.renewalPlanType === 'renewing');
                    if (renewingPlan) {
                        const { defaultRatingType } = renewingPlan;
                        rate = updateRateToDefaultType(rate, defaultRatingType === 'ageBands');
                    } else {
                        if(state.filterCriteria) {
                            if(state.filterCriteria.ratingMethod && state.filterCriteria.ratingMethod.age) {
                                rate = updateRateToDefaultType(rate, true);
                            } else if(state.filterCriteria.ratingMethod && state.filterCriteria.ratingMethod.tier) {
                                rate = updateRateToDefaultType(rate, false);
                            }
                        }
                    }
                }
                const planIdx = newPlans.findIndex((plan) => plan.code === rate.medicalPlanCode);
                const selectedPlanIdx = newSelectedPlans.findIndex((plan) => plan.code === rate.medicalPlanCode);

                let totalPremiumKey = rate.defaultRatingType === 'ageBands' ? 'premiumTotalAgeBandedMonthly' : 'premiumTotalCompositeMonthly';
                if(action.isUhcRenewal && action.getAlternateRatingMethod){
                    totalPremiumKey = rate.defaultRatingType === 'ageBands' ? 'premiumTotalCompositeMonthly' : 'premiumTotalAgeBandedMonthly';
                }

                if (action.isUhcRenewal) {
                    if (
                        action.getAlternateRatingMethod &&
                        action.alternatePlanCodes
                    ) {
                        const alternatePlanIdx = newPlans.findIndex(
                            (plan) =>
                                action.alternatePlanCodes.includes(
                                    rate.medicalPlanCode
                                ) && plan.code === rate.medicalPlanCode
                        );

                        if(alternatePlanIdx > -1) {
                            newPlans[alternatePlanIdx] = {
                                ...newPlans[alternatePlanIdx],
                                isAlternatePlan: true
                            }
                        }
                    }
                }

                if(planIdx > -1) {
                    if(!newPlans[planIdx].renewalPlanType || (
                        newPlans[planIdx].renewalPlanType !== 'renewing' && 
                        newPlans[planIdx].renewalPlanType !== 'alternate' && 
                        !newPlans[planIdx].isAlternatePlan
                    )) {
                        newPlans[planIdx] = {
                            ...newPlans[planIdx],
                            ...rate,
                            quote: rate[totalPremiumKey],
                        };
                    } else if(newPlans[planIdx].renewalPlanType && (
                        newPlans[planIdx].renewalPlanType === 'renewing' || 
                        newPlans[planIdx].renewalPlanType === 'alternate' || 
                        newPlans[planIdx].isAlternatePlan    
                    )) {
                        if(action.getAlternateRatingMethod) {
                            newPlans[planIdx] = {
                                ...setAlternateRatingMethod(newPlans[planIdx], rate),
                                quote: rate[totalPremiumKey],
                            }
                        }
                    }
                }

                if (selectedPlanIdx > -1) {
                    if(!newSelectedPlans[selectedPlanIdx].renewalPlanType 
                        || (
                            newSelectedPlans[selectedPlanIdx].renewalPlanType !== 'renewing' && 
                            newSelectedPlans[selectedPlanIdx].renewalPlanType !== 'alternate' && 
                            !newSelectedPlans[selectedPlanIdx].isAlternatePlan
                        )) {
                        newSelectedPlans[selectedPlanIdx] = {
                            ...newSelectedPlans[selectedPlanIdx],
                            ...rate,
                            quote: rate[totalPremiumKey],
                        };
                    } else if(newSelectedPlans[selectedPlanIdx].renewalPlanType 
                        && (newSelectedPlans[selectedPlanIdx].renewalPlanType === 'renewing' || 
                            newSelectedPlans[selectedPlanIdx].renewalPlanType === 'alternate' || 
                            newSelectedPlans[selectedPlanIdx].isAlternatePlan
                        )) {
                        if(action.getAlternateRatingMethod) {
                            newSelectedPlans[selectedPlanIdx] = {
                                ...setAlternateRatingMethod(newSelectedPlans[selectedPlanIdx], rate),
                                quote: rate[totalPremiumKey],
                            }
                        }
                    }
                }
            });

            newPlans = sortPlans(newPlans, state.ascending);

            newState = {
                ...state,
                plans: newPlans,
                selectedPlans: newSelectedPlans,
                ...filterPlans(newPlans, state.filterCriteria, state.isCirrus, allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending, action.isUhcRenewal),
            };

            if(action.getAlternateRatingMethod) {
                newState.numAltRatesFetched = numAltRatesFetched;
                if(numAltRatesFetched >= state.plans.length) {
                    newState.fetchingAlternateRatingMethod = false
                    newState.premDomainMax = [0, findPremiumDomainMax(newPlans)];
                    newState.filterCriteria.premRange = newState.premDomainMax;
                    newState.isFetchingMedicalPlans = false;
                }
            } else {
                newState.numRatesFetched = numRatesFetched
                newState.allRatesFetched = allRatesFetched
                newState.numRateBatchesFetching = state.numRateBatchesFetching - 1

                // If we are done fetching rates, then we can find the premDomainMax
                if (state.plans.length === numRatesFetched) {
                    newState.premDomainMax = [0, findPremiumDomainMax(newPlans)];
                    newState.filterCriteria.premRange = newState.premDomainMax;
                    newState.isFetchingMedicalPlans = false;
                }
            }
            return newState;

        case types.SET_FETCHING_ALTERNATE_RATING_METHOD:
            return {
                ...state,
                fetchingAlternateRatingMethod: action.payload,
                numAltRatesFetched: action.payload === null ? 0 : state.numAltRatesFetched
            }

        case types.SET_IS_FETCHING_MEDICAL_PLANS:
            return {
                ...state,
                isFetchingMedicalPlans: action.payload
            }
        case types.SERVICE.RS.ERR_REN_ADDITIONAL_RATES:
            return handleRatesError(state, action.payload);

        case types.REQUEST_BENEFIT_RIDER_RATES_RS:
            return handleRequestBenefitRiderRates(state)

        case types.RECEIVE_BENEFIT_RIDER_RATES_RS:
            return handleReceiveBenefitRiderRates(state, action);

        case types.SAVE_SELECTED_RIDERS_IN_PLANS_RS:
            return handleSaveSelectedRidersInPlans(state, action);

        case types.REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS_RS:
            return handleRequestPlanRatesWithSelectedRiders(state, action)

        case types.RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS_RS:
            return handleReceivePlanRatesWithSelectedRiders(state, action);

        case types.RESTORE_PREMIUM_ON_DESELECT_RS:
            return handleRestorePremiumOnDeselect(state, action);

        case types.SERVICE.RS.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.SERVICE.RS.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.RS.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        // Updates the 'ages' array, then calculates the new rates for 'plans' and 'selectedPlans' arrays (but not
        // 'visiblePlans', which is updated in UPDATE_VISIBLE_PLANS. The function also automatically updates the filters
        // for premiums to allow for the new, and possibly higher, costs
        //
        // Parameters
        //  index:          The value indicating which employee had their age changed
        //  age:            The new age value to assign to the employee
        case types.SERVICE.RS.UPDATE_AGES:
            const quotes = calculateQuickQuotesFromRates(state.plans, action.newAges, state.isCirrus);
            let newMaxPrem = parseFloat(state.filterCriteria.premRange[1]);

            newPlans = state.plans.map((plan, i) => {
                if (quotes[i] > newMaxPrem) { newMaxPrem = Math.ceil(parseFloat(quotes[i]) / 100) * 100; }
                return { ...plan, quote: quotes[i] };
            });

            newSelectedPlans = state.selectedPlans.map((plan, i) => ({ ...plan, quote: quotes[i] }));

            const newPremDomainMax = findPremiumDomainMax(newPlans);
            const finalPremDomainMax = newPremDomainMax > state.premDomainMax[1] ? newPremDomainMax : state.premDomainMax[1];

            const newFilterCriteria = {
                ...state.filterCriteria,
                premRange: [state.filterCriteria.premRange[0], newMaxPrem],
            };

            return {
                ...state,
                plans: newPlans,
                selectedPlans: newSelectedPlans,
                premDomainMax: [state.premDomainMax[0], finalPremDomainMax],
                filterCriteria: newFilterCriteria,
                visiblePlans: filterPlans(newPlans, newFilterCriteria, state.isCirrus, state.allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending).visiblePlans,
            };

        case types.UPDATE_BREADCRUMB_ACCESS:
            const inefficientObject = initialBreadCrumbAccess;
            // Only allowing new access - never removing access
            // TODO: This may need to be changed. If and when a user goes back a page and changes information, new information might have to be entered
            // TODO: on the next screen fast forwarding will again be disallowed
            inefficientObject[action.page] = true;
            return {
                ...state,
                breadCrumbAccess: inefficientObject,
            };

        case types.ZIP_RETURN_ERROR:
            return {
                ...state,
                zipCodeReturnError: action.payload,

            };

        case types.SIC_RETURN_ERROR:
            return {
                ...state,
                sicCodeReturnError: action.payload,
            };

        case types.RECEIVE_ENROLLMENT_RULES:
            if (action.maxAgeBandedPlans) {
                return {
                    ...state,
                    maxAgeBandedPlans: action.maxAgeBandedPlans,
                };
            }

            return state;

        case types.SERVICE.RS.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.TOGGLE_MONTHLY_ANNUAL:
            return {
                ...state,
                showAnnualPremium: !state.showAnnualPremium,
            };
        case types.SET_SHOPPING_RATE_BAND:
            return {
                ...state,
                filterCriteria: {
                    ...state.filterCriteria,
                    ratingMethod: {
                        ...state.filterCriteria.ratingMethod,
                        age: action.payload,
                        tier: !action.payload,
                    },
                },
            };

        case types.UPDATE_REVIEW_SHOP_PLANS:
            return {
                ...state,
                reviewShopPlans: action.payload,
            };

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
        case types.RESET_RENEWALS:
            return initialState;

        case types.SET_PREFERRED_PLANS_DISPLAY:
            if (action.payload.toLowerCase() == 'dental') {
                return {
                    ...state,
                    preferredDentalPlans: true
                }
            }
            if (action.payload == 'vision') {
                return {
                    ...state,
                    preferredVisionPlans: true
                }
            }
            if (action.payload == 'life') {
                return {
                    ...state,
                    preferredLifePlans: true
                }
            }

        case types.UPDATE_RENEWAL_MEDICAL_ALLOWED:
            return {
                ...state,
                renewalMedicalAllowed: action.status,
            };
        
        case types.UPDATE_RENEWAL_PACKAGE_LABEL:
            return {
                ...state,
                selectedPackageLabel: action.payload,
            };

        case types.SET_PREFERRED_PLANS_DISPLAY_TO_DEFAULT:
            if (action.payload.toLowerCase() == 'dental') {
                return {
                    ...state,
                    preferredDentalPlans: false
                }
            }
            if (action.payload == 'vision') {
                return {
                    ...state,
                    preferredVisionPlans: false
                }
            }
            if (action.payload == 'life') {
                return {
                    ...state,
                    preferredLifePlans: false
                }
            }

        case types.UPDATE_RENEWAL_SHOPPING_ELIGIBILITY_COUNTS:
            return {
                ...state,
                eligibilityCountsEntered: action.eligibilityCountsEntered || true,
                enableMedical: action.enableMedical,
                eligibilityCounts: action.eligibilityCounts,
                renewalMedicalAllowed: action.enableMedical
            }

        default:
            return state;
    }
}
