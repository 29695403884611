import FileSaver from 'file-saver';
import { auditUserAction } from '../../actions/actionHelpers/createHistoryHelper';
import * as types from '../../actions/actionTypes';
import { createHistory } from '../../actions/historyAction';
import { decideHelper, renewalShoppingPageHelper, reviewHelper } from './renewalsProposalHelper';
import { reviewHelperMMR, decideHelperMMR } from './mmrProposalHelper';
import {
    formatBrokers, formatGeneralAgents, formatSalesReps, formatAgencies,
} from './proposalHelper';
import sendLog from '../../actions/logActions';
import { email } from './apiRouteConfig';
import { axios } from './axiosConfig';
import { environmentVariables } from '../consts/consts';
import curateState from './proposalUtils/curateStatePayload';
import { sendClickEventDataAction } from '../../actions/analyticsAction';
import { extractAdobeData, extractNewPlans } from '../../uhcTemplate/utils/helperFunctions';
import { getMarketType, isUhcRenewals, MARKET_TYPES } from '../businessLogic/platformUtils';
import { logError } from '../errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../errorLogging/logKeys';
import { store } from '../../index';
import deepCopy from '../deepCopy';

const EMAIL_SUBJECT = 'SAMX Proposal Generation Error';
const to = environmentVariables.itSupportEmails;

const downloadCSV = (result, isTemplate = false) => {
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(result.data, 'quick-quotes');
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(result.data);
            link.setAttribute('href', url);
            link.setAttribute('download', isTemplate ? 'template' : 'quotes-proposal');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
};

function getUserDetails() {
    if (!sessionStorage.getItem('QuickQuotes_authorized')) {
        return {
            userId: 'N/A',
            firstName: 'N/A',
            lastName: 'N/A',
        };
    }
    return {
        userId: sessionStorage.getItem('QuickQuotes_authorized'),
        firstName: sessionStorage.getItem('QuickQuotes_user_FName'),
        lastName: sessionStorage.getItem('QuickQuotes__user_LName'),
    };
}
// Following method is temporary fix to genrate a pseudo quoteID,
// Need to remove this after implementation of QuoteID at server Side.
function getTempQuoteId() {
    const quoteID = parseInt(localStorage.getItem('QQQuoteID'), 10) || 1;
    localStorage.setItem('QQQuoteID', quoteID + 1);
    return `${new Date().toLocaleDateString()}-${quoteID}`;
}

/**
 * Function that sends an api call to the email server notifying IT team of cirrus technical errors
 * * @param {String} subject - subject line of email to be send,
 * * @param {String} msg - body of email to be send,
 * * @param {String} to - optional arg to set recipient,
 * * @param {String} from - optional arg to set sender,
 */
export const techErrorEmail = (subject, msg,
    to = undefined, from = undefined, isUhcRenewal = false) => async (dispatch) => {
        try {
            // to test this out you can add to: 'your email address' to test this
            // and send an email to yourself other wise it will default to another list
            const payload = {
                subject, msg, to, from, isUhcRenewal
            };
            await axios.post(`${email}/emails/sendGenericEmail`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (err) {
            if (subject === 'SAMX Cirrus Connection Issue') {
                dispatch({ type: types.INSTALL_MEM_GROUP_LW_TECH_ERROR });
            }
            console.log('Problem sending error email:', err);
        }
    };

/**
 * Converting JSONString blob to Object and getting the docType error
 * @param {string} error JSONString blob taken from proposal service
 */
async function getErrorType(error) {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();

            reader.onload = (e) => {
                const err = e.target.result;
                const errorObj = JSON.parse(err);
                resolve(errorObj.docType);
            };
            if (error.response) {
                reader.readAsText(error.response.data);
            } else {
                resolve('Server Error');
            }
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Sends an email if the number of attempts to retrieve the pdf or csv
 * exceeds the maximum tries
 * @param {function} dispatch - dispatch function from redux
 * @param {string} EMAIL_MESSAGE - message to be sent in email
 * @returns undefined
 */
const dispatchNumAttemptsError = (dispatch, EMAIL_MESSAGE) => {
    dispatch({ type: types.ERROR_PROPOSAL, error: 'Server Error' });
    dispatch(techErrorEmail(EMAIL_SUBJECT, EMAIL_MESSAGE, to));
};

/**
 *
 * @param {string} pdfCSV "pdf" or "csv"
 */
const checkPDFCSV = (pdfCSV, numAttempts = 0) => (dispatch, getState) => {
    const { transactionID } = getState().proposal;
    const { overall, caseTracking } = getState();

    const EMAIL_MESSAGE = `There was an error generating a proposal in SAMX, please investigate Case: ${caseTracking.caseId}, Platform: ${overall.platform === 'PRIME' ? 'UHC' : 'Oxford'}`;
    if (pdfCSV === 'pdf' && numAttempts > 30) {
        dispatchNumAttemptsError(dispatch, EMAIL_MESSAGE);
        return;
    }
    if (pdfCSV === 'csv' && numAttempts > 20) {
        dispatchNumAttemptsError(dispatch, EMAIL_MESSAGE);
        return;
    }

    // const route = `${redisRoute}/checkTransaction/${transactionID}`;
    let route = email;

    const TIMEOUT_INTERVAL = 5 * 1000;
    if (pdfCSV === 'csv') {
        route += '/csv/';
    } else {
        route += '/pdf/';
    }
    route += transactionID;

    axios.get(route, { responseType: 'blob' })
        .then((result) => {
            if (result.status === 204) {
                setTimeout(() => {
                    dispatch(checkPDFCSV(pdfCSV, numAttempts + 1));
                }, TIMEOUT_INTERVAL);
                return;
            }
            // else, we received a file
            // axios.get(`${email}/pdf/${transactionID}`, { responseType: 'blob' })
            //     .then((res) => {
            switch (pdfCSV) {
                case 'pdf':
                    {
                        const url = URL.createObjectURL(result.data);                       
                        window.open(url);
                        axios.get(`${email}/pdf/checkMissingDocs/${transactionID}`)
                            .then((docs) => {
                                const { data: { missingDocs = [] } = {} } = docs;
                                if (missingDocs && missingDocs.length > 0) {
                                    dispatch({
                                        type: types.ERROR_PROPOSAL,
                                        error: missingDocs,
                                    });
                                }
                                dispatch({ type: types.RECEIVE_PROPOSAL });
                            })
                            .catch((err) => { throw err; });
                        break;
                    }
                case 'csv':
                    downloadCSV(result, false);
                    axios.get(`${email}/pdf/checkMissingDocs/${transactionID}`)
                        .then((docs) => {
                            const { data: { missingDocs = [] } = {} } = docs;
                            if (missingDocs && missingDocs.length > 0) {
                                dispatch({
                                    type: types.ERROR_PROPOSAL,
                                    error: missingDocs,
                                });
                            }
                            dispatch({ type: types.RECEIVE_PROPOSAL });
                        })
                        .catch((err) => { throw err; });
                    break;
                default:
                    break;
            }
            // })
            // .catch((err) => {
            //     throw err;
            // });
        })
        .catch((err) => {
            if (!overall.isRenewals) {
                dispatch(auditUserAction(`Plan Type: ${overall.activeTabKey.toUpperCase()}`, `Generate Proposal ${pdfCSV.toUpperCase()}`, `Error occuped while fetching the file ${route}: ${err}`));
            }

            getErrorType(err).then((result) => {
                if(isUhcRenewals()) {
                    logError(KEY_NAME, UHC_REN_APIS.PROPOSAL, 'proposals.checkPDFCSV', {transactionID}, err);
                } else {
                    if (result === 'Server Error') {
                        dispatch(techErrorEmail(EMAIL_SUBJECT, EMAIL_MESSAGE, to));
                    } else if (result) {
                        dispatch({ type: types.ERROR_PROPOSAL, error: result });
                    } else {
                        dispatch({ type: types.ERROR_PROPOSAL, error: 'Server Error' });
                        dispatch(techErrorEmail(EMAIL_SUBJECT, EMAIL_MESSAGE, to));
                    }
                }
            }).catch((error) => {
                dispatch({ type: types.ERROR_PROPOSAL, error });
            });
        });
};

export const generatePDFCSV = (
    pageType,
    pdfCSV,
    optionalDocs,
    benefitSummarySelectedPlans,
    includeSummary = true,
    includeDetailed = false,
    companyName = '',
    countConversion = false,
    useEnrolledPlans = false,
    sbcCheckStatusArray = [],
    socCheckStatusArray = [],
    packageSavings = {}
) => (dispatch, getState) => {
    const state = getState();
    // TODO: This payload being sent to the email server is WAAAAY too big!
    // Figure out what you need and send just that
    packageSavings = state.packageSavings || {}
    const {
        quotes,
        caseTracking: { caseId },
        overall,
        renewalShopping,
    } = state;

    const {
        selectedPlans, filterCriteria, isCirrus, optionalBenefitRiders, contributions,
    } = quotes; // These are the only things needed to send for medical

    const creationDate = new Date().toLocaleString('en-us', { timeZoneName: 'short' });

    if (!overall.isRenewals) {
        dispatch(auditUserAction('Generate Proposal', ` ${pdfCSV.toUpperCase()}`, 'Button Clicked'));
    }

    const copiedState = {
        ...curateState(state),
        quotes: {
            selectedPlans, filterCriteria, isCirrus, optionalBenefitRiders, contributions,
        },
    };

    const extractPackageSavingsProducts = (packageSavings) => {

        let pkgSavingSelectedProd = []
        if (packageSavings && packageSavings.discount && packageSavings.discount[0] && packageSavings.discount[0].pkgSavingProducts) {
            packageSavings.discount[0].pkgSavingProducts.map(products => {
                pkgSavingSelectedProd.push(products.productType)
            })
        }
        return pkgSavingSelectedProd

    }

    // If useEnrolledPlans is true, overwrite selectedPlans in the payload with enrolledPlans

    if (useEnrolledPlans) {
        const enrolledMedicalPlans = [];
        for (const [pkg, arrayOfPlans] of Object.entries(state.rules.enrollment.enrolledPlans)) {
            for (const plan of arrayOfPlans) {
                enrolledMedicalPlans.push(plan);
            }
        }
        copiedState.dental.selectedPlans = state.rules.enrollment.enrolledDentalPlans;
        copiedState.vision.selectedPlans = state.rules.enrollment.enrolledVisionPlans;
        copiedState.std.selectedPlans = state.rules.enrollment.enrolledSTDPlans;
        copiedState.life.selectedPlans = state.rules.enrollment.enrolledLifePlans;
        copiedState.ltd.selectedPlans = state.rules.enrollment.enrolledLTDPlans;
        copiedState.suppLife.selectedPlans = state.rules.enrollment.enrolledSuppLifePlans;
        copiedState.quotes.selectedPlans = enrolledMedicalPlans;
    }

    if(getMarketType() === MARKET_TYPES.UHC && overall.source === "full") //marketType and NB fullQuote
    {
        
        let planIndex = -1;

        //extracting plans from within packages
        const flatMedicalPlans = Object.keys(state?.rules?.enrollment?.enrolledPlans)
            .map((key) => state?.rules?.enrollment?.enrolledPlans[key])
            .flat() || [];

        //setting installPlatform depending on ipps response
        (copiedState.quotes.selectedPlans).forEach(selectedPlan=> {
                planIndex = flatMedicalPlans.findIndex(plan => plan.code === selectedPlan.code)
                if(planIndex > -1){
                    let filteredPlan = flatMedicalPlans.filter(plan => plan.code === selectedPlan.code)
                    if(filteredPlan.length > 0 )
                    {
                        copiedState.quotes.selectedPlans[planIndex].installPlatform = filteredPlan[0].installPlatform
                    }
                    
                }
        });
    }
    let pdfData;

    if(overall.source === 'mmr'){
        if (pageType === 'decide') {
            pdfData = decideHelperMMR(state, useEnrolledPlans);
        }

        if (pageType === 'review') pdfData = reviewHelperMMR(state);

    }
    else{
        if (pageType === 'decide') {
            pdfData = decideHelper(state, useEnrolledPlans);
        }
        
        if (pageType === 'review') pdfData = reviewHelper(state);

        if (pageType === 'renewalShopping') pdfData = renewalShoppingPageHelper(state);
    }
    const data = pdfData;
    const payload = pageType === 'fullshopping' || pageType === 'quick-quotes' ? {
        ...copiedState,
        creationDate,
        caseId,
        soc: socCheckStatusArray,
        sbc: sbcCheckStatusArray,
        benefitSummaryChecked: optionalDocs
            ? optionalDocs.benefitSummaryChecked : false,
        dentalBenefitSummaryChecked: optionalDocs
            ? optionalDocs.dentalBenefitSummaryChecked : false,
        visionBenefitSummaryChecked: optionalDocs
            ? optionalDocs.visionBenefitSummaryChecked : false,
        stdBenefitSummaryChecked: optionalDocs
            ? optionalDocs.stdBenefitSummaryChecked : false,
        lifeBenefitSummaryChecked: optionalDocs
            ? optionalDocs.lifeBenefitSummaryChecked : false,
        ltdBenefitSummaryChecked: optionalDocs
            ? optionalDocs.ltdBenefitSummaryChecked : false,
        suppLifeBenefitSummaryChecked: optionalDocs
            ? optionalDocs.suppLifeBenefitSummaryChecked : false,
        
        benefitSummarySelectedPlans,
        companyName,
        pageType,
        includeSummary,
        includeDetailed,
        packageSavingsChecked: optionalDocs
            ? optionalDocs.packageSavingsChecked : false,
        packageSavingsEnable: state.companyProfile.packageSavingsEnable,
        packageSavingsDiscountValue: packageSavings && packageSavings.discount && packageSavings.discount[0] && packageSavings.discount[0].creditEstimateTotal ? `${"$" + packageSavings.discount[0].creditEstimateTotal}` : 0,
        packageSavingsProducts: extractPackageSavingsProducts(packageSavings),
        packageSavings:state.packageSavings,
        includeFootNotes: true,
        brokers: formatBrokers(state.reviewCards.ownerAssignment.producer),
        agencies: formatAgencies(state.reviewCards.ownerAssignment.agency),
        salesRep: formatSalesReps(state.reviewCards.ownerAssignment.salesRep),
        generalAgent: formatGeneralAgents(state.reviewCards.ownerAssignment.generalAgent),
        gaSalesReps: formatSalesReps(state.reviewCards.ownerAssignment.gaSalesRep),
        marketType: (state.overall?.appConfigDetails?.marketType === 'PRIME') ? 'UHC' : 'OXFORD'
    } : {
            data,
            caseId,
            creationDate,
            soc: socCheckStatusArray,
            sbc: sbcCheckStatusArray,
            benefitSummaryChecked: optionalDocs
                ? optionalDocs.benefitSummaryChecked : false,
            dentalBenefitSummaryChecked: optionalDocs
                ? optionalDocs.dentalBenefitSummaryChecked : false,
            visionBenefitSummaryChecked: optionalDocs
                ? optionalDocs.visionBenefitSummaryChecked : false,
            stdBenefitSummaryChecked: optionalDocs
                ? optionalDocs.stdBenefitSummaryChecked : false,
            lifeBenefitSummaryChecked: optionalDocs
                ? optionalDocs.lifeBenefitSummaryChecked : false,
            ltdBenefitSummaryChecked: optionalDocs
                ? optionalDocs.ltdBenefitSummaryChecked : false,
            benefitSummarySelectedPlans,
            packageSavingsEnable: 'N',
            packageSavingsDiscountValue: 0,
            packageSavingsProducts: extractPackageSavingsProducts(packageSavings),
            pageType,
            isUhcRenewals: isUhcRenewals(),
            includeSummary,
            includeDetailed,
            packageSavingsChecked: optionalDocs
                ? optionalDocs.packageSavingsChecked : false,
            hraHsaChecked : optionalDocs ? optionalDocs.hraHsaChecked : false,
            isMMR: overall.source === 'mmr' ? true : false,
            marketType:getMarketType() === MARKET_TYPES.UHC ? 'UHC' : 'OXFORD',
            renewalShopping,
        };

    const userDetails = getUserDetails();
    if(pageType == 'review' || pageType == 'decide' || pageType == 'renewalShopping') {
        let {newMedicalPlans,newDentalPlans, newVisionPlans,newLifePlans,newDependentLifePlans, newStdPlans, newLtdPlans}=extractNewPlans(data.medicalPlans,data.dental.selectedPlans,data.vision.selectedPlans,data.life.selectedPlans,data.lifeDep.selectedPlans, data.std.selectedPlans,data.ltd.selectedPlans)
        if(newMedicalPlans.length>0||newDentalPlans.length>0 || newVisionPlans.length>0 || newLifePlans.length>0 || newLtdPlans.length>0 || newStdPlans.length>0 ){
            let {coverageType, planNames ,productType,planFilter,planCodes}=extractAdobeData(newMedicalPlans,newDentalPlans,newVisionPlans,newLifePlans,newLtdPlans, newStdPlans)
            dispatch(sendClickEventDataAction({
                clickName: 'Proposal : ' + pdfCSV.toUpperCase() + '|Summary Proposal : ' + includeSummary + '|Detailed Proposal : ' + includeDetailed,
                clickElement: 'Button',
                clickType: 'download',
                coverageType: coverageType,
                planName: planNames,
                productType : productType,
                planFilter:planFilter,
                planCode:planCodes
        }))
        }
    }else{
        let {coverageType, planNames ,productType,planFilter,planCodes}=extractAdobeData(copiedState.quotes.selectedPlans,copiedState.dental.selectedPlans,copiedState.vision.selectedPlans,copiedState.life.selectedPlans)
        dispatch(sendClickEventDataAction({
            clickName: 'Proposal : ' + pdfCSV.toUpperCase() + '|Summary Proposal : ' + includeSummary + '|Detailed Proposal : ' + includeDetailed,
            clickElement: 'Button',
            clickType: 'download',
            coverageType: coverageType,
            planName: planNames,
            productType : productType,
            planFilter:planFilter,
            planCode:planCodes
        
        }))
    }
    sendLog({
        action: 'EXPORT PLANS',
        type: pdfCSV === 'pdf' ? 'pdf' : 'csv',
        quoteDetail: copiedState.overall.quickQuoteRequest,
        medicalSelection: copiedState.quotes.selectedPlans,
        dentalSelection: copiedState.dental.selectedPlans,
        visionSelection: copiedState.vision.selectedPlans,
        stdSelection: copiedState.std.selectedPlans,
        lifeSelection: copiedState.life.selectedPlans,
        ltdSelection: copiedState.ltd.selectedPlans,
        suppLifeSelection: copiedState.suppLife.selectedPlans,
        userDetails,
        countConversion,
        companyName,
        XQuoteID: getTempQuoteId(),
        ...copiedState.overall.quickQuoteRequest,

    });

    // pageType === 'fullshopping' ? csvName = 'enrollment-template' : csvName = 'quotes-proposal';
    let route = email;
    if (pdfCSV === 'csv') {
        route += '/csv';
        //route = `${redisRoute}/csv`;
    } else {
        route += '/pdf';
        //route = `${redisRoute}/pdf`;
    }
    
    dispatch({ type: types.REQUEST_PROPOSAL });

    axios.post(route, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Max-Age': 7200,
        },
    })
        .then((result) => {
            dispatch({
                type: types.RECEIVE_PROPOSAL_TRANSACTION_ID,
                payload: result.data.transactionID,
            });
            setTimeout(() => {
                dispatch(checkPDFCSV(pdfCSV));
            }, 5 * 1000);
        })
        .catch((err) => {
            console.log(err);
            dispatch({ type: types.ERROR_PROPOSAL_TRANSACTION_ID, error: err });
            if(isUhcRenewals()) {
                logError(KEY_NAME, UHC_REN_APIS.PROPOSAL, 'proposals.generatePDFCSV', payload, err);
            }
        });
};

const checkEmail = (numAttempts = 0) => (dispatch, getState) => {
    if (numAttempts > 50) {
        dispatch({ type: types.ERROR_EMAIL, error: 'Email generation timed out.' });
        return;
    }

    const { proposal: { emailTransactionID } } = getState();

    const route = `${email}/emails/${emailTransactionID}`;
    // const route = `${redisRoute}/checkEmailTransaction/${emailTransactionID}`;
    const TIMEOUT_INTERVAL = 7 * 1000;

    axios.get(route, { responseType: 'text' })
        .then((result) => {
            if (result.status === 204) {
                setTimeout(() => {
                    dispatch(checkEmail(numAttempts + 1));
                }, TIMEOUT_INTERVAL);
                return;
            }
            // const emailRoute = `${email}/emails/${emailTransactionID}`;
            // axios.get(emailRoute, { responseType: 'json' })
            //     .then((res) => {
            // else, we received a file
            dispatch({ type: types.RECEIVE_EMAIL });
            // })
            // .catch((err) => { throw err; });
        })
        .catch(() => {
            dispatch({ type: types.ERROR_EMAIL, error: 'Something went wrong.' });
        });
};

/**
 * Sends the information to the server to create pdfs and csvs and emails them
 * @param {Objec} payload - a json object that contains the redux state,
 *                          an array of emails, and booleans for whether
 *                          there should be a pdf, csv, soc and sbc
 */

export const sendEmail = (payload) => (dispatch) => {
    if (payload.pageType === 'fullshopping' || payload.pageType === 'quick-quotes') {
        const { 
            overall: { quickQuoteRequest },
            rules: {
                enrollment: {
                    enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans,
                }
            },
            quotes: { selectedPlans }, 
            dental: { selectedPlans: selectedDentalPlans },
            vision: { selectedPlans: selectedVisionPlans },
            life: { selectedPlans: selectedLifePlans }, 
        } = store.getState();

        let selectedMedicalPlans;
        let dentalSelectedPlans;
        let visionSelectedPlans;
        let lifeSelectedPlans;
        if (payload.useEnrolledPlans) {
            selectedMedicalPlans = enrolledPlans;
            dentalSelectedPlans = enrolledDentalPlans;
            visionSelectedPlans = enrolledVisionPlans;
            lifeSelectedPlans = enrolledLifePlans;
        } else {
            selectedMedicalPlans = selectedPlans;
            dentalSelectedPlans = selectedDentalPlans;
            visionSelectedPlans = selectedVisionPlans;
            lifeSelectedPlans = selectedLifePlans;
        }
        const { countConversion, companyName } = payload;

        sendLog({
            action: 'EXPORT PLANS',
            type: 'email',
            quoteDetail: quickQuoteRequest,
            medicalSelection: selectedMedicalPlans,
            dentalSelection: dentalSelectedPlans,
            visionSelection: visionSelectedPlans,
            lifeSelection: lifeSelectedPlans,
            userDetails: getUserDetails(),
            XQuoteID: getTempQuoteId(),
            countConversion,
            companyName,
        });
    }

    dispatch({ type: types.REQUEST_EMAIL });
    // axios.post(`${redisRoute}/email`, payload, {
    axios.post(`${email}/emails`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((result) => {
            const { data: { transactionID } } = result;
            dispatch({
                type: types.RECEIVE_EMAIL_TRANSACTION_ID,
                payload: transactionID,
            });
            setTimeout(() => {
                dispatch(checkEmail());
            }, 5 * 1000);
        })
        .catch((err) => {
            dispatch({ type: types.ERROR_EMAIL_TRANSACTION_ID, error: err.response ? err.response.data ? err.response.data.error ? err.response.data.error : 'Cannot connect to the email server. Please try again later' : 'Cannot connect to the email server. Please try again later' : 'Cannot connect to the email server. Please try again later' });
        });
};

/**
 * Sends the information to the server to email pend information and save the email upon completion
 * @param {Objec} payload - a json object that contains the recipient emails,
 *                          sender email, subject, and body of the email
 */
export const sendPendEmail = (payload) => new Promise((resolve, reject) => {
    axios.post(`${email}/pendEmails`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    }).then(
        (result) => {
            resolve(result);
        },
        (error) => {
            reject(error);
        },
    );
});

export const requestAssistanceEmail = (payload) => new Promise((resolve, reject) => {
    axios.post(`${email}/requestAssistance`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    }).then(
        (result) => {
            resolve(result);
        },
        (error) => {
            reject(error);
        },
    );
});

export const slaViolationEmail = (payload) => new Promise((resolve, reject) => {
    axios.post(`${email}/slaViolation`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    }).then(
        (result) => {
            resolve(result);
        },
        (error) => {
            reject(error);
        },
    );
});

/**
 * Retreives an downloads a template from the server
 * @param {string} source - default to fullQuotes
 * @param {object} companyProfileObj - an object containing the attribute
 *                                     companyProfile which is the reducer state object
 * @param {boolean} vertical - boolean of whether the template should be vertical
 */
export const retrieveTemplate = (source = 'fullQuotes', compProfObj = null, vertical = false, pageType = '', enrollmentRules = null) => {
    // TODO: Should probably look into this nested plan info
    let templateType = '';
    if (vertical && compProfObj) {
        templateType = 'Vertical Prepopulated Template';
    } else if (vertical) {
        templateType = 'Vertical Blank Template';
    } else if (compProfObj) {
        templateType = 'Horizontal Prepopulated Template';
    } else {
        templateType = 'Horizontal Blank Template';
    }

    const { platform } = store.getState().overall;

    const companyProfileObj = {
        ...compProfObj,
    };

    const selectedMedicalPlans = enrollmentRules && enrollmentRules.enrolledPlans ? Object.keys(enrollmentRules.enrolledPlans).map((key) => enrollmentRules.enrolledPlans[key]).flat() : [];
    const selectedDentalPlans = enrollmentRules && enrollmentRules.enrolledDentalPlans || [];
    const selectedVisionPlans = enrollmentRules && enrollmentRules.enrolledVisionPlans || [];
    const selectedLifePlans = enrollmentRules && enrollmentRules.enrolledLifePlans || []; 
    const selectedSuppLifePlans = enrollmentRules && enrollmentRules.enrolledSuppLifePlans || [];    
    const selectedStdPlans = enrollmentRules && enrollmentRules.enrolledSTDPlans || [];
    const selectedLTDPlans = enrollmentRules && enrollmentRules.enrolledLTDPlans || [];

    const quotedPlanOptions = {
        selectedMedicalPlans: selectedMedicalPlans.length > 0 ? [...selectedMedicalPlans, 'waive'] : ['waive'],
        selectedDentalPlans: selectedDentalPlans.length > 0 ? [...selectedDentalPlans, 'waive'] : ['waive'],
        selectedVisionPlans: selectedVisionPlans.length > 0 ? [...selectedVisionPlans, 'waive'] : ['waive'],
        selectedLifePlans: selectedLifePlans.length > 0 ? [...selectedLifePlans, 'waive'] : ['waive'],    
        selectedSuppLifePlans: selectedSuppLifePlans.length > 0 ? [...selectedSuppLifePlans, 'waive'] : ['waive'],        
        selectedStdPlans: selectedStdPlans.length > 0 ? [...selectedStdPlans, 'waive'] : ['waive'],
        selectedLTDPlans: selectedLTDPlans.length > 0 ? [...selectedLTDPlans, 'waive'] : ['waive'],

    };

    const payload = {
        source,
        companyProfileObj,
        vertical,
        platform,
        quotedPlanOptions,
    };

    axios.post(`${email}/template`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    }).then(
        (result) => {
            console.log("soource",source)
            downloadCSV(result, true);
            if (source === 'employeeCensus') {
                store.dispatch(createHistory(`Download ${templateType}`, 'Employee Census', 'Success', compProfObj, result, 'NB'));
                store.dispatch(sendClickEventDataAction({
                    clickName: "Employee Census : template : " + templateType,
                    clickElement: 'Button',
                    clickType: 'download'
                }))
            }
            if (source === 'companyProfile'|| (source==='fullQuotes' && pageType==="") ){
                store.dispatch(sendClickEventDataAction({
                    clickName: "Group Information : template : " + templateType,
                    clickElement: 'Button',
                    clickType: 'download'
                }))
            }
            if (pageType === 'NB') { store.dispatch(createHistory(`Download ${templateType}`, 'Enrollment Manager', 'Success', compProfObj, result, 'NB')); }
        },
        (error) => {
            // TODO: make better error handling
            console.log('error', error);
            if (source === 'employeeCensus') { store.dispatch(createHistory(`Download ${templateType}`, 'Employee Census', 'Error', compProfObj, error, 'NB')); }
            if (pageType === 'NB') { store.dispatch(createHistory(`Download ${templateType}`, 'Enrollment Manager', 'Error', compProfObj, error, 'NB')); }
        },
    );
};
/**
 * Retreives an downloads a template from the server
 * @param {object} companyProfileObj - an object containing the attribute
 *                                     companyProfile which is the reducer state object
 * @param {Number} employeeIndex - Number representing the employee
 */
export const retrievePopulatedPDF = (
    employeeIndex = null, locationIndex = null, specialty = false, isNJLife = false, isOxfordLife = false, isNMHMO = false, isMEHMO = false,
) => (dispatch, getState) => {
    const { platform } = store.getState().overall;
    const { rules: { enrollment }, companyProfile, enrollment: { application } } = getState();
    const payload = {
        companyProfile,
        application,
        enrollment,
        employeeIndex,
        locationIndex,
        platform,
        isNJLife,
        isOxfordLife,
        isNMHMO,
        isMEHMO,
    };

    dispatch({ type: types.FETCH_MAPPED_PDF, index: employeeIndex, location: locationIndex });
    // TODO:: refactor the code for default/specialty products
    // TODO:: NEED TO ADD FLAG FOR OXFORD LIFE PDF GENERATION
    axios.post(`${email}/pdf/populated/${!specialty ? !isOxfordLife ? 'employee' : 'life/employee' : 'specialty/employee'}`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(
        (result) => {
            try {
                dispatch({ type: types.RECIEVED_MAPPED_PDF });
                const { data: { ContentType, mappedBufferPDF: { data: mappedBufferData } } } = result;
                // Apparently s3 sends data back as a nested array that
                // needs to be converted before its converted
                const unit8ArrayBuffer = new Uint8Array(mappedBufferData);
                const pdfBlob = new Blob([unit8ArrayBuffer], { type: ContentType });
                const url = window.URL.createObjectURL(pdfBlob);
                window.open(url);
            }
            catch(err) {
                console.log('There was an error', err);
                dispatch({
                    type: types.ERROR_MAPPING_PDF,
                    index: employeeIndex,
                    location: locationIndex,
                });
            }
        },
        () => {
            // TODO: make better error handling
            console.log('There was an error');
            dispatch({
                type: types.ERROR_MAPPING_PDF,
                index: employeeIndex,
                location: locationIndex,
            });
        },
    );
};

/**
 * Retreives an downloads a sample template from the server
 */
export const retrieveSample = () => {
    axios.get(`${email}/sample`, {
        responseType: 'blob',
    }).then(
        (result) => {
            downloadCSV(result, true);
        },
        (error) => {
            // TODO: make better error handling
            console.log('error', error);
        },
    );
};

/**
 * Retreives an downloads a template from the server
 * @param {object} companyProfileObj - an object containing the attribute
 *                                     companyProfile which is the reducer state object
 * @param {object} companyProfileObj - an object containing the enrollment
 *                                     application which is the reducer state object
 * @param {String} billingMethod - String representing billing method of group
 * @param {number} progress - String representing billing method of group
 * @param {object} enrollmentRules - an object containing the rules enrollment
 *                                   which is the reducer state object
 * @param {String} overrideGroup - Option to pass to email server
 * @param {boolean} specialty - Option denoting if specialty PDF should be accessed
 */
export const retrieveEmployerPDF = (
    companyProfile = null, application = null, billingMethod = null,
    progress, enrollmentRules = null, specialty = false, overrideGroup = null, isHMO = null
) => {
    const { platform } = store.getState().overall;
    const payload = {
        companyProfile,
        application,
        billingMethod,
        progress,
        enrollmentRules,
        specialty,
        overrideGroup,
        platform,
        isHMO
    };
    // TODO:: refactor the code for default/specialty products
    axios.post(`${email}/pdf/populated/${!specialty ? 'employer' : 'specialty/employer'}`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(
        (result) => {
            const { data: { ContentType, mappedBufferPDF: { data: mappedBufferData } } } = result;
            // Apparently s3 sends data back as a nested array that needs to
            // be converted before its converted
            const unit8ArrayBuffer = new Uint8Array(mappedBufferData);
            const pdfBlob = new Blob([unit8ArrayBuffer], { type: ContentType });
            const url = window.URL.createObjectURL(pdfBlob);
            window.open(url);
        },
        (error) => {
            // TODO: make better error handling
            console.log('error', error);
        },
    );
};

export const retrieveUHCTemplate = (companyProfile = null,page="") => {
    const { rules: { enrollment, enrollmentForm } } = store.getState();
    const enrollmentFormEmployees = enrollmentForm.employee || [];
    if (isUhcRenewals()) {
        // Displays employees alphabetically
        // employees is array of arrays
        companyProfile = deepCopy(companyProfile);
        companyProfile.employees = companyProfile.employees.map(empArray => empArray.sort((a, b) => a.lastName.localeCompare(b.lastName)));
    }
    const payload = {
        companyProfile,
        enrollment,
        enrollmentFormEmployees,
    };
    axios.post(`${email}/uhcTemplate`, payload, { responseType: 'blob' })
        .then((result) => {
            const fileName = 'template';
            const data = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(data, `${fileName}.xlsx`);
            if(page !== 'EmployeeEnrollment'){
                store.dispatch(sendClickEventDataAction({
                    clickName: "Group Information Export : template",
                    clickElement: 'Button',
                    clickType: 'download'
                }))
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export const handleHmoEmployerPdf =  (enrolledMedicalPlans) => {
    let isHMO = false;
    let isNonHMO = false;
     enrolledMedicalPlans.forEach(plan => {
        if (plan.licenseType!==null && plan.licenseType === 'HMO') {
            isHMO = true;
        } else if (plan.licenseType!==null && plan.licenseType !== 'HMO') {
            isNonHMO = true;
        }
    })
    const isBoth = isHMO === true && isNonHMO === true ? true : false;
    return {
        isHMO,
        isBoth
    }
}

export const sendHraTermNotification = (payload) => new Promise((resolve, reject) => {
    axios.post(`${email}/hraTermNotification`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    }).then(
        (result) => {
            resolve(result);
        },
        (error) => {
            reject(error);
        },
    );
});