import {
    workSheets,
    constrintErrors,
    constrintErrorsSubmittedCensus,
    userTypes,
    userTypesSubmittedCensus,
    dependencyNames,
    dataTypes,
    getColsArr,
    valuesToIgnore,
    dropDowns,
    statesWithNonBinary,
    employeeAndDependentGenderKeys,
    stateswithOptionalEmployeeStatus,
    employeeStatusKeys
} from './constants';
import { checkConstraint } from './constraints';
import * as dependencyManager from './dependencyManager/dependencyManager';
import * as mappingHolder from './mappingHolder/mappingHolder';
import * as schemaHolder from './schemaHolder/schemaHolder';
import moment from "moment";
import { getOrUpdateValue } from './dependencyManager/dependencyHelper'

let otherData = null;
const COLS_ARR = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC'];

/**
 * 
 * @param {object} workbook | object of workbook 
 * @param {String} pageSource | soruce of page where workbook is uploaded
 * @param {String} marketType | UHC/OXFORD
 */
function parseWorkbook(workbook, marketType, isEnrollment = false) {

    dependencyManager.setData('marketType', marketType);
    mappingHolder.setData('marketType', marketType);
    schemaHolder.setData('marketType', marketType);

    return {
        isWorksheetFilled: (workSheetName) => {
            const baseRow = 4;
            const cols = getColsArr(workSheetName);
            const worksheet = workbook.getWorksheet(workSheetName);
            return getNumOfFilledRows(worksheet, baseRow, cols, true) > 0 ? true : false;
        },
        parseWorkSheet: (worksheetName, companyInfo = null, locationInfo = null) => {
            dependencyManager.setData('currentWorkSheetName', worksheetName);
            const worksheet = workbook.getWorksheet(worksheetName);
            const mapping = mappingHolder.getMapping(worksheetName);
            const schema = schemaHolder.getSchema(worksheetName);
            if (worksheetName === workSheets.employeeInfo || worksheetName === workSheets.enrollmentInfo) {
                resolveWorkSheetDependency(userTypes.employee, mapping, { companyInfo: companyInfo });
                resolveWorkSheetDependency(userTypes.dependent, mapping, { companyInfo: companyInfo });
                otherData = {
                        effectiveDate: companyInfo.effectiveDate
                    }
                    // Add non-binary in gender constrain to allow non-binary gender for select states
                updateGenderMappingForNonBinary(worksheetName, mapping, companyInfo, locationInfo);
                //added options for addtional employee status for select states
                updateEmployeeStatus(worksheetName, mapping, companyInfo, locationInfo);
            }
            let result = parseWorkSheet(worksheet, mapping, schema, companyInfo);
            checkForSingleSpouse(result, worksheetName, isEnrollment);
            otherData = null;
            return result;
        },
        clearMemory: () => {
            dependencyManager.clearMemory();
            mappingHolder.clearMemory();
            schemaHolder.clearMemory();
        }
    }
}

function parseWorkbookMMR(workbook) {

    dependencyManager.setData('marketType', 'UHC');
    mappingHolder.setData('marketType', 'UHC');
    schemaHolder.setData('marketType', 'UHC');

    return {
        isWorksheetFilled: (workSheetName) => {
            const baseRow = 2;
            const cols = COLS_ARR;
            const worksheet = workbook.getWorksheet(workSheetName);
            return getNumOfFilledRows(worksheet, baseRow, cols, true) > 0 ? true : false;
        },
        parseWorkSheet: (worksheetName) => {
            //dependencyManager.setData('currentWorkSheetName', worksheetName);
            const worksheet = workbook.getWorksheet(worksheetName);
            const mapping = mappingHolder.getMapping(worksheetName);
            const schema = schemaHolder.getSchema(worksheetName);
            let result = parseWorkSheet(worksheet, mapping, schema, {}, 'MMR');
            otherData = null;
            return result;
        },
        clearMemory: () => {
            dependencyManager.clearMemory();
            mappingHolder.clearMemory();
            schemaHolder.clearMemory();
        }
    }
}

function parseWorkbookSubmittedCensus(workbook, marketType, props) {

    dependencyManager.setData('marketType', marketType);
    mappingHolder.setData('marketType', marketType);
    schemaHolder.setData('marketType', marketType);

    return {
        parseWorkSheet: (worksheetName, companyInfo = null) => {
            //dependencyManager.setData('currentWorkSheetName', worksheetName);
           // const worksheet = workbook.getWorksheet(worksheetName);
            const worksheet = workbook.Sheets[worksheetName];
            const mapping = mappingHolder.getMapping(worksheetName);
            console.log("worksheetName",worksheetName);
            console.log("mapping for the upload",mapping);
            const schema = schemaHolder.getSchema(worksheetName);
            // if(worksheetName === workSheets.employeeInfo || worksheetName === workSheets.enrollmentInfo){
            //     resolveWorkSheetDependency(userTypes.employee, mapping, {companyInfo: companyInfo});
            //     resolveWorkSheetDependency(userTypes.dependent, mapping, {companyInfo: companyInfo});
            //     otherData = {
            //         effectiveDate: companyInfo.effectiveDate
            //     }
            // }
            let result = parseWorkSheetSubmittedCensus(worksheet, mapping, schema, companyInfo);
            otherData = null;
            return result;
        },
        clearMemory: () => {
            dependencyManager.clearMemory();
            mappingHolder.clearMemory();
            schemaHolder.clearMemory();
        }
    }
}

function parseWorkSheet(worksheet, mappingObj, objSchema, companyInfo, type = '') {
    let baseRow = mappingObj.metaData.baseRow;
    const mapping = mappingObj.mapping;
    if(mappingObj.metaData.isIterative && type === 'MMR'){
        const cols = COLS_ARR;
        let numOfFilledRows = getNumOfFilledRows(worksheet, baseRow, cols);
        let mmrSchema = objSchema.data[0];
        objSchema.data.splice(0, 1);
        for (let i = 0; i < numOfFilledRows; i++) {
            let newObjSchema = deepCopy(mmrSchema);
            let worksheetRow = worksheet.getRow(baseRow);
            parseRow(worksheetRow, mapping, newObjSchema, objSchema.metaData, baseRow);
            objSchema.data.push(newObjSchema);
            baseRow++;
        }
    } else if (mappingObj.metaData.isIterative) {
        let numOfFilledRows = getNumOfFilledRows(worksheet, baseRow, [...Object.keys(mapping[userTypes.employee]), ...Object.keys(mapping[userTypes.dependent])]);
        let empSchema = objSchema.data[0];
        let depSchema = empSchema.dependents[0];
        objSchema.data.splice(0, 1);
        empSchema.dependents.splice(0, 1);
        let parentIndex = 0;
        let numOfDependents = 0;
        let skip = 0;
        for (let i = 0; i < numOfFilledRows; i++) {
            let cell = worksheet.getRow(baseRow).getCell('A');
            let userType = getCellValue(cell);
            if (userType === null || (userType !== userTypes.employee && userType !== userTypes.dependent)) {
                console.log('Inside');
                updateObjectMetadata(objSchema.metaData, {
                    isError: true,
                    errors: [{
                        constraint: 'User Type',
                        fieldDisplay: 'User Type Missing',
                        error: constrintErrors['userTypeMissing'](baseRow, 'A')
                    }]
                });
                baseRow++;
                skip++;
                continue;
            }
            let newObjSchema = isEmployee(userType) ? deepCopy(empSchema) : deepCopy(depSchema);
            let worksheetRow = worksheet.getRow(baseRow);
            resolveSelfDependency(userType, mappingObj.metaData, mapping[userType], worksheetRow);
            parseRow(worksheetRow, mapping[userType], newObjSchema, objSchema.metaData, baseRow);
            resolveAfterParseDependency(userType, mappingObj.metaData, newObjSchema);
            if (isEmployee(userType)) {
                parentIndex = i - numOfDependents - skip;
                objSchema.data.push(newObjSchema);
            } else if (parentIndex !== i) {
                resolveChildParentDependency(userType, mappingObj.metaData, {
                    parentIndex,
                    parentObj: objSchema.data[parentIndex],
                    childIndex: i - parentIndex - 1 - skip,
                    childObj: newObjSchema,
                    companyInfo
                });
                resolveParentChildDependency(userType, mappingObj.metaData, {
                    parentObj: objSchema.data[parentIndex],
                    childObj: newObjSchema,
                    metaData: objSchema.metaData,
                    row: baseRow,
                    companyInfo
                });
                objSchema.data[parentIndex].dependents.push(newObjSchema);
                numOfDependents++;
            }
            baseRow++;
        }
    } else {
        parseRow(worksheet.getRow(baseRow), mapping, objSchema.data, objSchema.metaData, baseRow);
    }
    return objSchema;
}

function parseWorkSheetSubmittedCensus(worksheet, mappingObj, objSchema, companyInfo) {
    let baseRow = mappingObj.metaData.baseRow;
    const mapping = mappingObj.mapping;
    if (mappingObj.metaData.isIterative) {
        let numOfFilledRows = getNumOfFilledCensusRows(worksheet, baseRow, [...Object.keys(mapping[userTypes.employee]), ...Object.keys(mapping[userTypes.dependent])]);
        //  let numOfFilledRows=1;
        let empSchema = objSchema.data[0];
        let depSchema = empSchema.dependents[0];
        objSchema.data.splice(0, 1);
        empSchema.dependents.splice(0, 1);
        let parentIndex = 0;
        let numOfDependents = 0;
        let skip = 0;
        let parentRelation = '';
        let coverages = {
            medicalEmployee: false,
            medicalSpouse: false,
            medicalChild: false,
            dentalEmployee: false,
            dentalSpouse: false,
            dentalChild: false,
            visionEmployee: false,
            visionSpouse: false,
            visionChild: false,
            lifeEmployee: false,
            lifeSpouse: false,
            lifeChild: false
        }
        for (let i = 0; i < numOfFilledRows; i++) {
          //  let cell = worksheet.getRow(baseRow).getCell('C');
             let cell = worksheet['C'+ baseRow];
           // let userType = getUserType(getCellValue(cell));
            let userType = getUserType(cell.v);
           // let relationShip = getRelationship(getCellValue(cell));
           let relationShip = getRelationship(cell.v);
            if (userType === null && relationShip !== userTypesSubmittedCensus.RR) {
                // setInsideErrors(objSchema,'Relation','Invalid Relation','missingRelationShip',baseRow,'A', skip)
                return 'InvalidRelationship';
            } else if (userType === null && relationShip === userTypesSubmittedCensus.RR) {
                parentRelation = userTypesSubmittedCensus.RR;
                setInsideWarns(objSchema, 'Relation', 'Invalid Relation', 'invalidRelationshipEmployee', baseRow, 'C', skip)
                baseRow++;
                skip++;
                continue;
            } else if (parentRelation === userTypesSubmittedCensus.RR && relationShip === dropDowns.relationship[1]) {
                setInsideWarns(objSchema, 'Relation', 'Invalid Relation', 'invalidRelationshipSpouse', baseRow, 'C', skip)
                baseRow++;
                skip++;
                continue;
            } else if (parentRelation === userTypesSubmittedCensus.RR && relationShip === dropDowns.relationship[0]) {
                setInsideWarns(objSchema, 'Relation', 'Invalid Relation', 'invalidRelationshipChild', baseRow, 'C', skip)
                baseRow++;
                skip++;
                continue;
            }
            let newObjSchema = isEmployee(userType) ? deepCopy(empSchema) : deepCopy(depSchema);
          //  let worksheetRow = worksheet.getRow(baseRow);
           // parseRowSubmittedCensus(worksheetRow, mapping[userType], newObjSchema, objSchema.metaData, baseRow);
            parseRowSubmittedCensus(worksheet, mapping[userType], newObjSchema, objSchema.metaData, baseRow);
            if (isEmployee(userType)) {
                coverages = {...coverages,
                    medicalEmployee: false,
                    medicalSpouse: false,
                    medicalChild: false,
                    dentalEmployee: false,
                    dentalSpouse: false,
                    dentalChild: false,
                    visionEmployee: false,
                    visionSpouse: false,
                    visionChild: false,
                    lifeEmployee: false,
                    lifeSpouse: false,
                    lifeChild: false
                }
                parentRelation = '';
                parentIndex = i - numOfDependents - skip;
               // setEmployeeStatus(newObjSchema, getCellValue(cell));
                setEmployeeStatus(newObjSchema, cell.w);
               // setAge(newObjSchema, getCellValue(worksheet.getRow(baseRow).getCell('H')));
                 setAge(newObjSchema, worksheet['H'+baseRow].w);
               // setEmployeeStreetAddress(newObjSchema, getCellValue(worksheet.getRow(baseRow).getCell('N')));
               if(worksheet['N'+baseRow] != undefined) { 
                  setEmployeeStreetAddress(newObjSchema, worksheet['N'+baseRow].w);
               }   
               // setCoverageType(newObjSchema, worksheet.getRow(baseRow), relationShip, coverages);
                 setCoverageType(newObjSchema, worksheet,baseRow,relationShip, coverages);
               // setPlanCode(newObjSchema, worksheet.getRow(baseRow), 0, companyInfo);
                setPlanCode(newObjSchema, worksheet,baseRow,0,companyInfo);
                //setMedicareQuestions(newObjSchema, userType, relationShip, newObjSchema["waiveCoverage"].isWaivingAll, getCellValue(worksheet.getRow(baseRow).getCell('BB')));
                setMedicareQuestions(newObjSchema, userType, relationShip, newObjSchema["waiveCoverage"].isWaivingAll, worksheet['BB'+baseRow].w);
                objSchema.data.push(newObjSchema);
            } else if (parentIndex !== i && objSchema.data[parentIndex] && objSchema.data[parentIndex].dependents) {
               // setAge(newObjSchema, getCellValue(worksheet.getRow(baseRow).getCell('H')));
                setAge(newObjSchema, worksheet['H'+baseRow].w); 
                setCoverageType(objSchema.data[parentIndex], worksheet,baseRow, relationShip, coverages);
                setPlanCode(objSchema.data[parentIndex], worksheet,baseRow,numOfDependents + 1, companyInfo);
                // setCoverageType(objSchema.data[parentIndex], worksheet.getRow(baseRow), relationShip, coverages);
               // setPlanCode(objSchema.data[parentIndex], worksheet.getRow(baseRow), numOfDependents + 1, companyInfo);
                setMedicareQuestions(newObjSchema, userType, relationShip, objSchema.data[parentIndex]["waiveCoverage"].isWaivingAll);
                objSchema.data[parentIndex].dependents.push(newObjSchema);
                numOfDependents++;

            }
            baseRow++;
        }
    }
    return objSchema;
}

function setAge(newObjSchema, value) {
    if (value && value !== '') {
        const ageMS = Date.parse(Date()) - Date.parse(value);
        const age = new Date();
        age.setTime(ageMS);
        const ageYear = age.getFullYear() - 1970;
        newObjSchema.age = ageYear;
    } else {
        newObjSchema.age = ''
    }

}

function setEmployeeStreetAddress(newObjSchema, streetAddress) {
    console.log("streetAddress",streetAddress);
    const streetAddress1 = streetAddress && streetAddress.length > 30 ? streetAddress.substring(0, 30) : streetAddress;
    const streetAddress2 = streetAddress && streetAddress.length > 30 ? streetAddress.substring(30) : '';
    newObjSchema['contactInfo'].streetAddress = streetAddress1;
    newObjSchema['contactInfo'].streetAddressln2 = streetAddress2;
}

function setMedicareQuestions(newObjSchema, userType, relationShip, isWaivingAll, cobValue = '') {
    if (isWaivingAll === 'No') {
        if (isEmployee(userType) && (newObjSchema['employeeInfo'].medicarePartAStartDate || newObjSchema['employeeInfo'].medicarePartBStartDate || newObjSchema['employeeInfo'].medicarePartDStartDate)) {
            newObjSchema['employeeInfo'].medicarePrimary = "Yes";
        } else if (isEmployee(userType) && relationShip === userTypesSubmittedCensus.MC) {
            newObjSchema['employeeInfo'].medicarePrimary = "Yes";
        } else if (isEmployee(userType) && relationShip !== userTypesSubmittedCensus.MC) {
            newObjSchema['employeeInfo'].medicarePrimary = "No";
        } else if (!isEmployee(userType)) {
            newObjSchema.medicarePrimary = "No";
        }

        if (isEmployee(userType) && (cobValue === 'Y' || relationShip === userTypesSubmittedCensus.MC)) {
            newObjSchema['employeeInfo'].anyOtherCoverage = "Yes";
        } else if (isEmployee(userType)) {
            newObjSchema['employeeInfo'].anyOtherCoverage = "No";
        }
    }

}

function setInsideErrors(objSchema, constraint, fieldDisplay, constraintIndex, baseRow, column, skip) {
    updateObjectMetadata(objSchema.metaData, {
        isError: true,
        errors: [{
            constraint: constraint,
            fieldDisplay: fieldDisplay,
            error: constrintErrorsSubmittedCensus[constraintIndex](baseRow, column)
        }]
    });
}

function setInsideWarns(objSchema, constraint, fieldDisplay, constraintIndex, baseRow, column, skip) {
    updateObjectMetadataSubmittedCensus(objSchema.metaData, {
        isWarn: true,
        warns: [{
            constraint: constraint,
            fieldDisplay: fieldDisplay,
            warn: constrintErrorsSubmittedCensus[constraintIndex](baseRow, column)
        }]
    });
}

function getRelationship(value) {
    let relation = '';
    switch (value) {
        case userTypesSubmittedCensus.SP:
            relation = dropDowns.relationship[1];
            break;
        case userTypesSubmittedCensus.CH:
        case userTypesSubmittedCensus.ST:
        case userTypesSubmittedCensus.HM:
            relation = dropDowns.relationship[0];
            break;
        case userTypesSubmittedCensus.RR:
            relation = userTypesSubmittedCensus.RR;
            break;
        default:
            relation = value;
    }
    return relation;
}

function setEmployeeStatus(objSchema, userType) {
    if (userType === userTypesSubmittedCensus.EE || userType === userTypesSubmittedCensus.WV || userType === userTypesSubmittedCensus.MC) {
        objSchema.employeeStatus = dropDowns.empStatus[0];
        objSchema['employeeInfo'].employeeStatus = dropDowns.empStatus[0];
        objSchema['employeeInfo'].cobraStartDate = ''; //if cobra start date entered 
        objSchema['employeeInfo'].cobraEndDate = ''; //if cobra end date entered

    } else if (userType === userTypesSubmittedCensus.CB) {
        objSchema.employeeStatus = dropDowns.empStatus[1];
        objSchema['employeeInfo'].employeeStatus = dropDowns.empStatus[1];
    }
}

function setCoverageType(objSchema, row,baseRow, relationShip, coverage) {
    let medicalCoverage = ''; 
    if(row['AA'+baseRow] !== undefined)
      {
      medicalCoverage  = row['AA'+baseRow].w;
      }
           

   let dentalCoverage  = '';
      if(row['AG'+baseRow] !== undefined)
      {
        dentalCoverage     = row['AG'+baseRow].w;
      }  
   let visionCoverage  = '';
   if(row['AJ'+baseRow] !== undefined)
   {
    visionCoverage = row['AJ'+baseRow].w;
   } 
   let lifeCoverageEmployee = '' ;
   if (row['AK'+baseRow] !== undefined)
    {
     lifeCoverageEmployee = row['AK'+baseRow].w;
    }   
   let  lifeCoverageDependent = '';
   if(row['AP'+baseRow] !== undefined){
      lifeCoverageDependent  = row['AP'+baseRow].w;
   } 
   // const medicalCoverage = getCellValue(row.getCell('AA'));
   // const dentalCoverage = getCellValue(row.getCell('AG'));
   // const visionCoverage = getCellValue(row.getCell('AJ'));
   // const lifeCoverageEmployee = getCellValue(row.getCell('AK'));
   // const lifeCoverageDependent = getCellValue(row.getCell('AP'));
    setMedicalCoverageType(objSchema, medicalCoverage, relationShip, coverage)
    setDentalCoverageType(objSchema, dentalCoverage, relationShip, coverage)
    setVisionCoverageType(objSchema, visionCoverage, relationShip, coverage)
    if (relationShip === userTypesSubmittedCensus.EE || relationShip === userTypesSubmittedCensus.CB || relationShip === userTypesSubmittedCensus.WV) {
        setLifeCoverageType(objSchema, lifeCoverageEmployee, relationShip, coverage)
    } else if (relationShip === dropDowns.relationship[1] || relationShip === dropDowns.relationship[0]) {
        setLifeCoverageType(objSchema, lifeCoverageDependent, relationShip, coverage)
    }

}

function setMedicalCoverageType(objSchema, coverageValue, relationShip, coverage) {
    if (coverageValue === 'Y' && (relationShip === userTypesSubmittedCensus.EE || relationShip === userTypesSubmittedCensus.CB)) {
        objSchema['coverages'].medical = "EE";
        objSchema['medicalCoverageInfo'].medicalWaived = false;
        coverage.medicalEmployee = true;

    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[1]) { //spouse
        if (coverage.medicalEmployee && !coverage.medicalSpouse) {
            objSchema['coverages'].medical = "EE/SP";
            objSchema['medicalCoverageInfo'].medicalWaived = false;
            coverage.medicalSpouse = true;
        }
    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[0]) { //child
        if (coverage.medicalEmployee && !coverage.medicalSpouse && !coverage.medicalChild) {
            objSchema['coverages'].medical = "EE/CH";
            objSchema['medicalCoverageInfo'].medicalWaived = false;
            coverage.medicalChild = true;
        } else if (coverage.medicalEmployee && coverage.medicalSpouse && !coverage.medicalChild) {
            objSchema['coverages'].medical = "EE/CH";
            objSchema['medicalCoverageInfo'].medicalWaived = false;
            coverage.medicalChild = true;
        }
    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.EE) {
        objSchema['coverages'].medical = 'W'
        objSchema['medicalCoverageInfo'].medicalWaived = true
    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.WV) {
        objSchema['coverages'].medical = 'W'
        objSchema['medicalCoverageInfo'].medicalWaived = true
    }
    if (coverage.medicalEmployee && coverage.medicalSpouse && coverage.medicalChild) {
        objSchema['coverages'].medical = 'EE/FAM'
    }
}

function setDentalCoverageType(objSchema, coverageValue, relationShip, coverage) {
    console.log("Coverage type",coverageValue);
    console.log("relationShip",relationShip);
  
    if (coverageValue === 'Y' && (relationShip === userTypesSubmittedCensus.EE || relationShip === userTypesSubmittedCensus.CB)) {
        objSchema['coverages'].dental = "EE";
        console.log("Inside Coverage type",coverageValue);
        console.log("Inside relationShip",relationShip);
        coverage.dentalEmployee = true;

    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[1]) { //spouse
        if (coverage.dentalEmployee && !coverage.dentalSpouse) {
            objSchema['coverages'].dental = "EE/SP";
            coverage.dentalSpouse = true;
        }
    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[0]) { //child
        if (coverage.dentalEmployee && !coverage.dentalSpouse && !coverage.dentalChild) {
            objSchema['coverages'].dental = "EE/CH";
            coverage.dentalChild = true;
        } else if (coverage.dentalEmployee === true && coverage.dentalSpouse && !coverage.dentalChild) {
            objSchema['coverages'].dental = "EE/CH";
            coverage.dentalChild = true;
        }
    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.EE) {
        objSchema['coverages'].dental = 'W';

    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.WV) {
        objSchema['coverages'].dental = 'W'
    } else if(coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.CB) {
        objSchema['coverages'].dental = 'W'
             
    }
    if (coverage.dentalEmployee && coverage.dentalSpouse && coverage.dentalChild) {
        objSchema['coverages'].dental = 'EE/FAM'
    }
}

function setVisionCoverageType(objSchema, coverageValue, relationShip, coverage) {
    console.log("Coverage type",coverageValue);
    console.log("relationShip",relationShip);
        if (coverageValue === 'Y' && (relationShip === userTypesSubmittedCensus.EE || relationShip === userTypesSubmittedCensus.CB)) {
        objSchema['coverages'].vision = "EE";
        console.log("Inside Coverage type",coverageValue);
        console.log("Inside relationShip",relationShip);
        coverage.visionEmployee = true;

    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[1]) { //spouse
        if (coverage.visionEmployee && !coverage.visionSpouse) {
            objSchema['coverages'].vision = "EE/SP";
            coverage.visionSpouse = true;
        }
    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[0]) { //spouse
        if (coverage.visionEmployee && !coverage.visionSpouse && !coverage.visionChild) {
            objSchema['coverages'].vision = "EE/CH";
            coverage.visionChild = true;
        } else if (coverage.medicalEmployee && coverage.visionSpouse && !coverage.visionChild) {
            objSchema['coverages'].vision = "EE/CH";
            coverage.visionChild = true;
        }
    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.EE) {
        objSchema['coverages'].vision = 'W'

    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.WV) {
        objSchema['coverages'].vision = 'W'
    } else if(coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.CB) {
        objSchema['coverages'].vision = 'W'
    }
    if (coverage.visionEmployee && coverage.visionSpouse && coverage.visionChild) {
        objSchema['coverages'].vision = 'EE/FAM'
    }
}

function setLifeCoverageType(objSchema, coverageValue, relationShip, coverage) {
    console.log("Coverage type",coverageValue);
    console.log("relationShip",relationShip);
        
    if (coverageValue === 'Y' && (relationShip === userTypesSubmittedCensus.EE )) {
        console.log("Inside Coverage type",coverageValue);
        console.log("Inside relationShip",relationShip);
      
        objSchema['coverages'].basicLife = "EL";
        coverage.lifeEmployee = true;

    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[1]) { //spouse
        if (coverage.lifeEmployeeEmployee === true && coverage.lifeEmployeeSpouse === false) {
            objSchema['coverages'].basicLife = "EL";
            coverage.lifeSpouse = true;
        }
    } else if (coverageValue === 'Y' && relationShip === dropDowns.relationship[0]) { //spouse
        if (coverage.medicalEmployee === true && coverage.medicalSpouse === false && coverage.medicalChild === false) {
            objSchema['coverages'].basicLife = "EL";
            coverage.medicalChild = true;
        } else if (coverage.medicalEmployee === true && coverage.medicalSpouse === true && coverage.medicalChild === false) {
            objSchema['coverages'].basicLife = "EL";
            coverage.medicalChild = true;
        }
    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.EE) {
        objSchema['coverages'].basicLife = 'W'

    } else if (coverageValue !== 'Y' && relationShip === userTypesSubmittedCensus.WV) {
        objSchema['coverages'].basicLife = 'W'
    } else if( relationShip === userTypesSubmittedCensus.CB){
        objSchema['coverages'].basicLife = 'NA'
    }
}

const isAllProductsWaived = (selectedProducts, memberProductSelection) => {
    const products = [
        'medical',
        'dental',
        'vision',
        'life'
    ]
    let isWaived = true;
    products.forEach(product => {
        if (selectedProducts[product] && memberProductSelection[product] !== 'waive') {
            isWaived = false
        }
    })
    return isWaived;
}

function setPlanCode(objSchema, row,baseRow,index, companyInfo) {
    let medicalCoverage ;

    //const medicalCoverage = getCellValue(row.getCell('AA'));
     if (row['AA'+baseRow] !== undefined ){
      medicalCoverage = row['AA'+baseRow].w;
     } 
     let dentalCoverage;
    //const dentalCoverage = getCellValue(row.getCell('AG'));
     if (row['AG'+baseRow] !== undefined){
      dentalCoverage = row['AG'+baseRow].w;
     } 
    //const visionCoverage = getCellValue(row.getCell('AJ'));
    let visionCoverage ;
     if(row['AJ'+baseRow] !== undefined){
      visionCoverage = row['AJ'+baseRow].w;
     } 
     let lifeCoverageEmployee ;
    //const lifeCoverageEmployee = getCellValue(row.getCell('AK'));
     if(row['AK'+baseRow] !== undefined ){
      lifeCoverageEmployee = row['AK'+baseRow].w;
     }
     let lifeCoverageDependent;
    //const lifeCoverageDependent = getCellValue(row.getCell('AP'));
    if(row['AP'+baseRow] !== undefined){
         lifeCoverageDependent = row['AP'+baseRow].w;
    }   
    //const medicalPlanCodeValues = getCellValue(row.getCell('AB'));
     let medicalPlanCodeValues ;
     if(row['AB'+baseRow] !== undefined)
     {
      medicalPlanCodeValues = row['AB'+baseRow].w;
     } 
    const medicalPlanCode = medicalPlanCodeValues || '';
    //const dentalPlanCodeValues = getCellValue(row.getCell('AH'));
    let dentalPlanCodeValues ;
    if(row['AH'+baseRow] !== undefined)
    {
     dentalPlanCodeValues = row['AH'+baseRow].w;
    } 
    const dentalPlanCode = dentalPlanCodeValues || '';
    //const pcpCodeValue = getCellValue(row.getCell('AD'));
    let pcpCodeValue ;
    
    if(row['AD'+baseRow] !== undefined){
     pcpCodeValue = row['AD'+baseRow].w ;
    }
    const pcpCode = pcpCodeValue || '';
    if (index === 0) {
        const productSelection = objSchema["productSelection"][index];
        productSelection.medical = medicalCoverage === 'Y' ? medicalPlanCode : 'waive';
        objSchema["pcpAssignment"][index].assignmentMethod = medicalCoverage === 'Y' && pcpCode.toUpperCase() === 'AUTO' ? dropDowns.pcpAssignment[0] : medicalCoverage === 'Y' && pcpCode !== '' ? dropDowns.pcpAssignment[1] : '';
        objSchema["pcpAssignment"][index].pcpCode = medicalCoverage === 'Y' && pcpCode.toUpperCase() !== 'AUTO' ? pcpCode : '';
        productSelection.dental = dentalCoverage === 'Y' ? dentalPlanCode : 'waive'
        productSelection.vision = visionCoverage !== 'Y' ? 'waive' : 'vision'
        productSelection.life = lifeCoverageEmployee !== 'Y' ? 'waive' : 'life'
        if (isAllProductsWaived(companyInfo.selectedProducts, productSelection)) {
            objSchema["waiveCoverage"].isWaivingAll = 'Yes';
        } else {
            objSchema["waiveCoverage"].isWaivingAll = 'No';
        }
    } else if (index > 0) {
        let productSelectionObj = {};
        let pcpAssignment = {};
        if (medicalCoverage === 'Y' && objSchema['coverages'].medical !== 'W') {
            productSelectionObj = {...productSelectionObj, medical: objSchema["productSelection"][0].medical }
        }
        if (medicalCoverage === 'Y' && pcpCode.toUpperCase() === 'AUTO') {
            pcpAssignment = {...pcpAssignment, assignmentMethod: dropDowns.pcpAssignment[0], pcpCode: '' }
        } else if (medicalCoverage === 'Y' && pcpCode !== '') {
            pcpAssignment = {...pcpAssignment, assignmentMethod: dropDowns.pcpAssignment[1], pcpCode: pcpCode }
        }

        if (dentalCoverage === 'Y' && objSchema['coverages'].dental !== 'W') {
            productSelectionObj = {...productSelectionObj, dental: objSchema["productSelection"][0].dental }
        }

        if (medicalCoverage !== 'Y') {
            productSelectionObj = {...productSelectionObj, medical: "waive" }
        }
        if (dentalCoverage !== 'Y') {
            productSelectionObj = {...productSelectionObj, dental: 'waive' }
        }
        if (visionCoverage !== 'Y') {
            productSelectionObj = {...productSelectionObj, vision: "waive" }
        }
        if (lifeCoverageDependent !== 'Y' || objSchema['coverages'].life === 'W') {
            productSelectionObj = {...productSelectionObj, life: 'waive' }
        }
        objSchema["productSelection"].push(productSelectionObj);
        objSchema["pcpAssignment"].push(pcpAssignment);
    }


}

function getUserType(value) {
    switch (value) {
        case userTypesSubmittedCensus.EE:
        case userTypesSubmittedCensus.CB:
        case userTypesSubmittedCensus.WV:
        case userTypesSubmittedCensus.MC:
            value = userTypes.employee;
            break;
        case userTypesSubmittedCensus.SP:
        case userTypesSubmittedCensus.CH:
        case userTypesSubmittedCensus.ST:
        case userTypesSubmittedCensus.HM:
        case userTypesSubmittedCensus.HP:
            value = userTypes.dependent;
            break;
        case userTypesSubmittedCensus.MC:
        case userTypesSubmittedCensus.SS:
        case userTypesSubmittedCensus.RR:
            value = null;
            break;
        default:
            value = null;
    }
    return value;
}

function parseRow(row, mapping, objSchema, metaData, rowNum) {
    Object.keys(mapping).forEach(key => {
        let cell = row.getCell(key);
        let value = getCellValue(cell);
        validateValue(value, mapping[key], objSchema, metaData, { row: rowNum, col: key });
    });
}

function parseRowSubmittedCensus(row, mapping, objSchema, metaData, rowNum) {
    Object.keys(mapping).forEach(key => {
      //  let cell = row.getCell(key);
        let cell = row[key+rowNum];
        //let value = getCellValue(cell);
        console.log("key value" ,key);
        let value;
        if(cell !== undefined){
            value = cell.w
           if(key === 'AM'|| key === 'R')
           {
            value = cell.v
           } 
           
       
         console.log("value in the parseRowsubmittedCensus",value); 
        } 
        validateValueSubmittedCensus(value, mapping[key], objSchema, metaData, { row: rowNum, col: key });
    });
}



function validateValue(value, mapping, objSchema, metaData, cell) {
    let isError = checkIfRequired(value, mapping, metaData, cell);
    if (!isError && isFilled(value)) {
        isError = checkAllConstraints(value, mapping.constraints, mapping.fieldDisplay, metaData, cell);
        if (!isError) {
            updateObjSchema(value, mapping, objSchema);
        }
    }

}


function validateValueSubmittedCensus(value, mapping, objSchema, metaData, cell) {

    console.log("entered in validateValueSubmitted Census");      
    if (Array.isArray(mapping.fieldPath)) {
        for (let i = 0; i < mapping.fieldPath.length; i++) {
            const fieldvalue = mapping.fieldValue[i](value);
            let isError = checkIfRequired(fieldvalue, mapping, metaData, cell);
            if (!isError && isFilled(fieldvalue)) {
                isError = checkAllConstraints(fieldvalue, mapping.constraints, mapping.fieldDisplay, metaData, cell);
                if (!isError) {
                    console.log("value in validate submittedCensus",fieldvalue)
                    updateObjSchemaSubmitetdCensus(fieldvalue, mapping.fieldPath[i], objSchema);
                }
            }
        }

    } else {
        let  fieldValue = mapping.fieldValue ? mapping.fieldValue(value) : value;
        if(mapping.fieldDisplay === "Date Of Hire" || mapping.fieldDisplay === "COBRA Start Date" || mapping.fieldDisplay === "COBRA End Date" )
        {
            fieldValue = moment(new Date(value)).format('MM/DD/YYYY');
            console.log("date of value",fieldValue);
        }

        console.log("value in validate submittedCensus",fieldValue);
        updateObjSchemaSubmitetdCensus(fieldValue, mapping.fieldPath, objSchema);

    }

}

function getFieldValue(mapping, value) {
    if (Array.isArray(mapping.fieldPath)) {
        for (let i = 0; i < mapping.fieldPath.length; i++) {
            return mapping.fieldValue[i](value);
        }
    } else {
        const fieldValue = mapping.fieldValue ? mapping.fieldValue(value) : value;
        return fieldValue;
    }
}

function checkIfRequired(value, mapping, metaData, cell) {
    if (mapping.required && !isFilled(value)) {
        let fieldDisplay = mapping.fieldDisplay;
        updateObjectMetadata(metaData, {
            isError: true,
            errors: [{
                constraint: 'requiredField',
                fieldDisplay,
                error: constrintErrors['requiredField'](fieldDisplay, cell)
            }]
        });
        return true;
    }
    return false;
}

function checkAllConstraints(value, constraints, fieldDisplay, metaData, cell) {
    let result = {
        isError: false,
        failedConstraints: []
    }
    if (constraints)
        Object.keys(constraints).forEach(key => {
            if (!checkConstraint(value, key, constraints[key])) {
                result.isError = true;
                result.failedConstraints.push({
                    constraint: key,
                    fieldDisplay,
                    error: constrintErrors[key](fieldDisplay, constraints[key], cell)
                });
            }
        });
    if (result.isError) {
        updateObjectMetadata(metaData, {
            isError: true,
            errors: result.failedConstraints
        });
    }
    return result.isError;
}

function updateObjSchema(value, mapping, objSchema) {
    if (Array.isArray(mapping.fieldPath)) {
        for (let i = 0; i < mapping.fieldPath.length; i++) {
            updateObjSchemaUtil(objSchema, mapping.fieldPath[i], mapping.fieldValue[i](value));
        }
    } else {
        const fieldValue = mapping.fieldValue ? mapping.fieldValue(value) : value;
        updateObjSchemaUtil(objSchema, mapping.fieldPath, fieldValue);
    }
}

function updateObjSchemaSubmitetdCensus(value, fieldPath, objSchema) {
    updateObjSchemaUtil(objSchema, fieldPath, value);

}

function updateObjSchemaUtil(objSchema, fieldPath, fieldValue) {
    getOrUpdateValue(objSchema, fieldPath, fieldValue)
}

function updateObjectMetadata(metaData, errorObj) {
    metaData.isError = errorObj.isError;
    metaData.errors = [...metaData.errors, ...errorObj.errors]
}

function updateObjectMetadataSubmittedCensus(metaData, warnObj) {
    metaData.isWarn = warnObj.isWarn;
    metaData.warns = [...metaData.warns, ...warnObj.warns]
}

function isFilled(value) {
    return (value !== null && value !== undefined && value !== '')
}

function getNumOfFilledRows(worksheet, baseRow, cols, checkIfManuallyFilled = false) {
    let numOfFilledRows = 0;
    const maxIteration = 10000;
    while (!isRowEmpty(worksheet, baseRow++, cols, checkIfManuallyFilled) && numOfFilledRows < maxIteration) {
        numOfFilledRows++;
    }
    return numOfFilledRows === maxIteration ? -1 : numOfFilledRows;
}

function getNumOfFilledCensusRows(worksheet, baseRow, cols, checkIfManuallyFilled = false) {
    let numOfFilledRows = 0;
    const maxIteration = 10000;
    while (!isRowCensusEmpty(worksheet, baseRow++, cols, checkIfManuallyFilled) && numOfFilledRows < maxIteration) {
        numOfFilledRows++;
    }
    return numOfFilledRows === maxIteration ? -1 : numOfFilledRows;
}


function isRowEmpty(worksheet, row, cols, checkIfManuallyFilled = false) {
    for (let i = 0; i < cols.length; i++) {
        let cell = worksheet.getRow(row).getCell(cols[i]);
        let value = getCellValue(cell);
        if (isFilled(value)) {
            if (checkIfManuallyFilled) {
                if (!cell.formula)
                    return false;
            } else {
                return false;
            }
        }
    }
    return true;
}

function isRowCensusEmpty(worksheet, row, cols, checkIfManuallyFilled = false) {
    for (let i = 0; i < cols.length; i++) {
        console.log("worksheet values",worksheet[cols[i]+row])
      if(worksheet[cols[i]+row] !== undefined){ 
         let value = worksheet[cols[i]+row].v;
        console.log("value isRowempty",value);
      if (isFilled(value)) {
            if (checkIfManuallyFilled) {
                if (!cell.formula)
                    return false;
            } else {
                return false;
            }
        }
    }   
    }
    return true;
}


function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
};

function isEmployee(value) {
    if (value === userTypes.employee) return true;
    else if (value === userTypes.dependent) return false;
    else return null;
}

function resolveWorkSheetDependency(userType, mappingSchema, dataObj) {
    if (mappingSchema.metaData[userType].includes(dependencyNames.WORKSHEET_DEPENDENCY)) {
        dependencyManager.resolve(dependencyNames.WORKSHEET_DEPENDENCY, {
            userType,
            dataObj,
            mapping: mappingSchema.mapping[userType],
            worksheetRow: null
        })
    }
}


function resolveSelfDependency(userType, mappingMetaData, mapping, row) {
    const isDependencyPresent = userType && mappingMetaData[userType];
    if (isDependencyPresent && mappingMetaData[userType].includes(dependencyNames.SELF_DEPENDENCY)) {
        dependencyManager.resolve(dependencyNames.SELF_DEPENDENCY, {
            userType: userType,
            dataObj: null,
            mapping,
            worksheetRow: row
        });
    }
}

function resolveAfterParseDependency(userType, mappingMetaData, objSchema) {
    const isDependencyPresent = userType && mappingMetaData[userType];
    objSchema.otherData = otherData;
    if (isDependencyPresent && mappingMetaData[userType].includes(dependencyNames.AFTER_PARSE_DEPENDENCY)) {
        dependencyManager.resolve(dependencyNames.AFTER_PARSE_DEPENDENCY, {
            userType: userType,
            dataObj: objSchema,
            mapping: null,
            worksheetRow: null
        });
    }
    delete objSchema['otherData'];
}

function resolveChildParentDependency(userType, mappingMetaData, dataObj) {
    const isDependencyPresent = userType && mappingMetaData[userType];
    if (isDependencyPresent && mappingMetaData[userType].includes(dependencyNames.CHILD_PARENT_DEPENDENCY)) {
        dependencyManager.resolve(dependencyNames.CHILD_PARENT_DEPENDENCY, {
            userType: null,
            dataObj,
            mapping: null,
            worksheetRow: null
        })
    }
}

function resolveParentChildDependency(userType, mappingMetaData, dataObj) {
    const isDependencyPresent = userType && mappingMetaData[userType];
    if (isDependencyPresent && mappingMetaData[userType].includes(dependencyNames.PARENT_CHILD_DEPENDENCY)) {
        dependencyManager.resolve(dependencyNames.PARENT_CHILD_DEPENDENCY, {
            userType: null,
            dataObj,
            mapping: null,
            worksheetRow: null
        })
    }
}

function trimStringValue(value) {
    if (typeof value === 'string') {
        return value.trim();
    }
    return value;
}

function getCellValue(cell) {

    let value = cell.value;

    if (typeof value === dataTypes.object) {

        if (value === null) {
            return null;
        }
        // email object
        if (value.hasOwnProperty('text')) {
            return trimStringValue(value.text);
        }
        // date object
        if (value instanceof Date) {
            return isNaN(value) ? '' : value;
        }
        // formula object
        if (value.result) {
            value = value.result;
            if (value instanceof Date) {
                return isNaN(value) ? '' : value;
            }
            return valuesToIgnore.includes(value) ? '' : value;
        }
        return undefined;
    }
    return trimStringValue(value);
}

function checkForSingleSpouse(empData, worksheetName, isEnrollment) {
    const relationships = ["Spouse", "Domestic Partner", "Civil Union Partner"];
    if (worksheetName === workSheets.employeeInfo || worksheetName === workSheets.enrollmentInfo) {
        const employees = empData.data;
        let moreThanOneSpouseError = false;
        for (let i = 0; i < employees.length; i++) {
            let employee = employees[i];
            let dependents = employee.dependents;
            let count = 0;
            for (let j = 0; j < dependents.length; j++) {
                let relationship = dependents[j].relationship;
                if (relationships.includes(relationship)) {
                    count++;
                    if (count > 1) {
                        if (isEnrollment) {
                            empData.data[i].dependents[j].relationship = "";
                        } else {
                            if (!moreThanOneSpouseError) {
                                moreThanOneSpouseError = true
                                updateObjectMetadata(empData.metaData, {
                                    isError: true,
                                    errors: [{
                                        error: constrintErrors.spouseCount
                                    }]
                                });
                            }
                        }
                    }
                }
            }
        }
    }
}

const updateGenderMappingForNonBinary = (worksheetName, mapping, companyInfo, locationInfo) => {
    // Extract the state code from companyInfo or locationInfo
    let stateCode;
    if (locationInfo) {
        stateCode = locationInfo.locations[0].stateCode;
    } else {
        const { zipData } = companyInfo.locations[0];
        stateCode = zipData.stateCode;
    }
    // Update mapping to include non-binary option in employee and dependent mapping
    if (worksheetName === workSheets.employeeInfo) {
        if (stateCode && statesWithNonBinary.includes(stateCode)) {
            // Handle employee mapping
            mapping.mapping.Employee[employeeAndDependentGenderKeys.employeeInfo.employee].constraints.dropDownValues = dropDowns.nonBinaryGender
                // Handle dependent mapping
            mapping.mapping.Dependent[employeeAndDependentGenderKeys.employeeInfo.dependent].constraints.dropDownValues = dropDowns.nonBinaryGender
        }
    } else if (worksheetName === workSheets.enrollmentInfo) {
        if (stateCode && statesWithNonBinary.includes(stateCode)) {
            // Handle employee mapping
            mapping.mapping.Employee[employeeAndDependentGenderKeys.enrollmentInfo.employee].constraints.dropDownValues = dropDowns.nonBinaryGender
                // Handle dependent mapping
            mapping.mapping.Dependent[employeeAndDependentGenderKeys.enrollmentInfo.dependent].constraints.dropDownValues = dropDowns.nonBinaryGender
        }
    }
}

const updateEmployeeStatus = (worksheetName, mapping, companyInfo, locationInfo) => {
    // Extract the state code from companyInfo or locationInfo
    let stateCode;
    if (locationInfo) {
        stateCode = locationInfo.locations[0].stateCode;
    } else {
        const { zipData } = companyInfo.locations[0];
        stateCode = zipData.stateCode;
    }
    // Update mapping to include non-binary option in employee and dependent mapping
    if (worksheetName === workSheets.employeeInfo) {
        if (stateCode && stateswithOptionalEmployeeStatus.includes(stateCode)) {
            // Handle employee mapping
            mapping.mapping.Employee[employeeStatusKeys.employeeInfo.employee].constraints.dropDownValues = dropDowns.employeeStatusOptions
                // Handle dependent mapping
            // mapping.mapping.Dependent[employeeStatusKeys.employeeInfo.dependent].constraints.dropDownValues = dropDowns.employeeStatusOptions
        }
    } else if (worksheetName === workSheets.enrollmentInfo) {
        if (stateCode && stateswithOptionalEmployeeStatus.includes(stateCode)) {
            // Handle employee mapping
            mapping.mapping.Employee[employeeStatusKeys.enrollmentInfo.employee].constraints.dropDownValues = dropDowns.employeeStatusOptions
                // Handle dependent mapping
            // mapping.mapping.Dependent[employeeStatusKeys.enrollmentInfo.dependent].constraints.dropDownValues = dropDowns.employeeStatusOptions
        }
    }
}

export { parseWorkbook, parseWorkbookSubmittedCensus, parseWorkbookMMR };
export default parseWorkbook