exports.isUhcToUsp = () => {
    const { store } = require('../../index');
    const state = store.getState()
    const BNEPlatform = state.overall.platform;
    const installationPlatform = state.caseTracking.platform;
    
    return BNEPlatform.toUpperCase() === 'PRIME' && installationPlatform.toUpperCase() === 'CIRRUS' || installationPlatform.toUpperCase() === 'DUAL';
}

exports.isWATwoTierHW = () => {
    const { store } = require('../../index');
    const { overall, quotes } = store.getState();
    const { quickQuoteRequest: { zipObj: { stateCode },  otherDetails: { selectedProducts } } } = overall;
    const { filterCriteria: { ratingMethod } } = quotes;

    return stateCode === 'WA' && selectedProducts.includes('medical') && ratingMethod.tier
}