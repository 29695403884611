import {marketTypes} from '../constants'
import {getUHCMapping} from './uhc/mappings'

/** data = {
*    marketType: 'UHC/OXFORD'
* }
*/
let data = null

/**
 * 
 * @param {String} markeType | UHC/OXFORD
 * @param {String} worksheetName | name of worksheet
 * @returns the mapping of sheet.
 */
export const  getMapping = (worksheetName) => {
    if(data.marketType === marketTypes.UHC){
        return getUHCMapping(worksheetName);
    }
    else{

    }
}

export const setData = (fieldName, fieldValue) => {
    if(data === null)
        data = {};
    data[fieldName] = fieldValue;
}

export const clearMemory = () => {
    data = null;
}