
import { toMoney } from "../../../../utils/formatters/numbers";

//Default values for contributions
export const defaultContributions = {
    medEValue : '50',
    medDValue : '0',
    denEValue : '0',
    denDValue : '0',
    visEValue : '0',
    visDValue : '0',
    lifeEValue : '25',
    lifeDValue : '0'
}
export const nonContributoryTypes = ['non-contributory','non-contributary'];

// function to update conribution values when landing on enrollment page.
// returns query used to update mdvContribution object.
// accepts 2 arguments : 
//     memberGroup: data from SW.
//     mdvContribution: samx data. May not comes in case of renew as is.
// Scope to remove contributionType logic, looks useless, nowhere saw contribution to be allowed flat only saw percent value.
export const updateContribution = (memberGroup, mdvContribution = null) => { 
    const medicalContribution = memberGroup?.employerContribution && Object.keys(memberGroup.employerContribution).length!==0 ? memberGroup.employerContribution : null;
    const specialtyContribution =memberGroup?.specialtyEmployerContribution && Object.keys( memberGroup.specialtyEmployerContribution).length!==0 ? memberGroup.specialtyEmployerContribution : null;
    let { medEValue, medDValue, denEValue, denDValue, visEValue, visDValue, lifeEValue} = defaultContributions;
    
    // Landing on enrollment page from Decide, Stepper, Renew as is from each page except dashboard.
    if(mdvContribution && Object.keys(mdvContribution).length !== 0){
        medEValue = mdvContribution.medical.eValue;
        medDValue = mdvContribution.medical.dValue;
        denEValue = mdvContribution.dental.eValue;
        denDValue = mdvContribution.dental.dValue;
        visEValue = mdvContribution.vision.eValue;
        visEValue = mdvContribution.vision.dValue;
        lifeEValue = mdvContribution.life.eValue;
    }
    // Landing on enrollment page, when Renew As is from dashboard
    else{
        if(medicalContribution){
            medEValue = medicalContribution.subscriber.value;
            medDValue = medicalContribution.dependent.value;
        }
        if(specialtyContribution){
            const dental = specialtyContribution.find(contr => contr.productType === "Dental");
            if (dental) {
                denEValue = dental.subscriber.value;
                denDValue = dental.dependent.value;
            }
            const vision = specialtyContribution.find(contr => contr.productType === "Vision");
            if (vision) {
                visEValue = vision.subscriber.value;
                visDValue = vision.dependent.value;
            } 
        }
    }

    const query = {
        medEValue: medEValue.toString(),
        medDValue: medDValue.toString(),
        denEValue: denEValue.toString(),
        denDValue: denDValue.toString(),
        visEValue: visEValue.toString(),
        visDValue: visDValue.toString(),
        lifeEValue: lifeEValue.toString(),
        errorMessage: parseInt(medEValue) < 50 ? 'Min Cont is 50%' : '',
    }

    return query;
}
