const {
    environmentVariables: {
        isLocal,
        apiUrls: {
            xport, email, mongo,
            logging, batch, redis,
            fileRetrieval
        },
    },
} = require('../consts/environmentVariables');

if(process.env.REACT_APP_ISLOCAL && process.env.REACT_APP_ISLOCAL === 'true'){ 
     quickQuotesRoute = process.env.REACT_APP_SAMX_XPORT_API_ENDPOINT;
     emailRoute = process.env.REACT_APP_SAMX_EMAIL_API_ENDPOINT;
     mongoRoute = process.env.REACT_APP_SAMX_QL_API_ENDPOINT;
     loggingRoute = process.env.REACT_APP_SAMX_LOG_API_ENDPOINT;
     batchRoute = process.env.REACT_APP_SAMX_BATCH_API_ENDPOINT;

}else{
     quickQuotesRoute = `/xport`;
     loggingRoute = `/logging`;
     emailRoute = `/email`;
     mongoRoute = `/ql`;
     batchRoute = '/batch';
}
var quickQuotesRoute;
var loggingRoute;
var emailRoute;
var mongoRoute;
var batchRoute;
var redisRoute = '';
var fileRetrievalRoute = '';
if (isLocal && isLocal === 'true') {
    quickQuotesRoute = xport;
    emailRoute = email;
    mongoRoute = mongo;
    loggingRoute = logging;
    batchRoute = batch;
    redisRoute = redis;
    fileRetrievalRoute = fileRetrieval;
} else {
    quickQuotesRoute = '/xport';
    loggingRoute = '/logging';
    emailRoute = '/email';
    mongoRoute = '/ql';
    batchRoute = '/batch';
    redisRoute = '/transaction';
    fileRetrievalRoute = '/file';
}

module.exports = {
    quickQuotes: quickQuotesRoute,
    logging: loggingRoute,
    email: emailRoute,
    mongo: mongoRoute,
    batchRoute,
    redisRoute,
    fileRetrievalRoute,
};
