import { newEmployee, newDependent } from './employeeConsts';
import { removeAtIndex } from '../../../../../utils/formatters/objects';
import { updateProductTier } from './addEditDeleteHelper';

/**
 * @description returns the object that would result from adding a blank employee
 *
 * @param {Array} employees array of employees, consistent with companyProfile.employees reducer
 * @param {number} groupSize groupSize, found in copanyProfile reducer
 * @param {number} locIndex the index of the appropriate location to add an employee object to
 *
 * @returns object with keys 'employees' and 'groupSize' of updated value
 */
export const addEmployee = (employees, groupSize, locIndex, locations) => {
    const updatedLocationEmployees = (employees[locIndex] || employees).concat(newEmployee);
    let updatedLocation = locations[locIndex];
    updatedLocation = {
        ...updatedLocation,
        totalEmps: parseInt(updatedLocation.totalEmps) + 1,
        totalEligEmps: parseInt(updatedLocation.totalEligEmps) + 1,
        atne: parseInt(updatedLocation.atne) + 1,
        fte: parseInt(updatedLocation.fte) + 1,
    };
    return {
        employees: employees
            .slice(0, locIndex)
            .concat([updatedLocationEmployees])
            .concat(employees.slice(locIndex + 1)),
        groupSize: parseInt(groupSize) + 1,
        locations: locations.slice(0, locIndex).concat(updatedLocation).concat(locations.slice(locIndex + 1)),
    };
};

/**
 * @description returns the object that would result from adding a blank employee
 *
 * @param {Array} employees array of employees, consistent with companyProfile.employees reducer
 * @param {number} groupSize groupSize, found in copanyProfile reducer
 * @param {number} locIndex the index of the appropriate location to add an employee object to
 *
 * @returns object with keys 'employees' and 'groupSize' of updated value
 */
export const addEmployeeCensus = (employees, groupSize, locIndex) => {
    const updatedLocationEmployees = (employees[locIndex] || employees).concat(newEmployee);
    return {
        employees: employees
            .slice(0, locIndex)
            .concat([updatedLocationEmployees])
            .concat(employees.slice(locIndex + 1)),
    };
};

/**
 * @description returns the object that would result from adding a blank employee
 *
 * @param {Array} employees array of employees, consistent with companyProfile.employees reducer
 * @param {number} locIndex the index of the appropriate location to add an employee object to
 *
 * @returns object with keys 'employees' and 'groupSize' of updated value
 */
export const addEmployeeEnrollment = (employees, locIndex, locations) => {
    const updatedLocationEmployees = (employees[locIndex] || employees).concat(newEmployee);
    let updatedLocation = locations[locIndex];
    updatedLocation = {
        ...updatedLocation,
        totalEmps: parseInt(updatedLocation.totalEmps) + 1
    };
    return {
        employees: employees
            .slice(0, locIndex)
            .concat([updatedLocationEmployees])
            .concat(employees.slice(locIndex + 1)),
        locations: locations.slice(0, locIndex).concat(updatedLocation).concat(locations.slice(locIndex + 1)),
    };
};

/**
 * @description returns a new employees array with an updated instance of the dependent object under the appropriate employee
 *
 * @param {Array} employees array of employees, consistent with companyProfile.employees reducer
 * @param locIndex - the index of the appropriate location where the employee is held
 * @param empIndex - the index of the appropriate employee to add a dependent object to
 *
 * @returns updated list of employees
 */
export const addDependent = (employees, locIndex, empIndex) => {
    const updatedEmployee = {
        ...employees[locIndex][empIndex],
        status : "In Progress",
        dependents: employees[locIndex][empIndex].dependents.concat(newDependent),
    };
    const updatedLocation = employees[locIndex]
        .slice(0, empIndex)
        .concat(updatedEmployee)
        .concat(employees[locIndex].slice(empIndex + 1));
    return employees.slice(0, locIndex).concat([updatedLocation]).concat(employees.slice(locIndex + 1));
};

/**
 * @description returns a new employees array with an updated dependent array
 * (one removed at index) under the appropriate employee
 *
 * @param {Array} employees array of employees, consistent with companyProfile.employees reducer
 * @param depIndex - the index of the appropriate dependent to delete
 * @param locIndex - the index of the appropriate location where the employee is held
 * @param empIndex - the index of the appropriate employee where the dependent is held
 */
export const removeDependent = (employees, depIndex, empIndex, locIndex) => {
    
    const updatedEE = updateProductTier(employees[locIndex][empIndex], depIndex ,null);
    const updatedEmployee = {
        ...updatedEE,
        dependents: employees[locIndex][empIndex].dependents
            .slice(0, depIndex)
            .concat(employees[locIndex][empIndex].dependents.slice(depIndex + 1))
    };

    const updatedLocation = employees[locIndex]
        .slice(0, empIndex)
        .concat(updatedEmployee)
        .concat(employees[locIndex].slice(empIndex + 1));
    return employees
        .slice(0, locIndex)
        .concat([updatedLocation])
        .concat(employees.slice(locIndex + 1));
};

export const removeDependentofRenewals = (employees, depIndex, empIndex, locIndex, employee) => {

    const updatedEmployee = {
        ...employees[locIndex][empIndex],
        dependents: employees[locIndex][empIndex].dependents
            .slice(0, depIndex)
            .concat(employees[locIndex][empIndex].dependents.slice(depIndex + 1)),
        productSelection: removeAtIndex(employee.productSelection, depIndex + 1),
        pcpAssignment:employee.pcpAssignment && employee.pcpAssignment.length>1 ? removeAtIndex(employee.pcpAssignment, depIndex + 1) : employee.pcpAssignment
    };

    const updatedLocation = employees[locIndex]
        .slice(0, empIndex)
        .concat(updatedEmployee)
        .concat(employees[locIndex].slice(empIndex + 1));
    return employees
        .slice(0, locIndex)
        .concat([updatedLocation])
        .concat(employees.slice(locIndex + 1));
};
