import * as types from '../../actions/actionTypes';
import { initialSpecialtyCriteria } from './consts/dentalReducerConsts';
import { isUndefinedOrNull } from '../../utils/validation/commonValidations';

export default function specialty(state = initialSpecialtyCriteria, action) {

    switch (action.type) {
        //sets the criteria entered into specialty criteria modal
        case types.SET_SPECIALTY_CRITERIA:

            const { censusCompleted } = action.specialtyCriteria;

            return {
                ...state,
                censusCompleted: !isUndefinedOrNull(censusCompleted) ? censusCompleted : state.censusCompleted,
                sicCode: action.specialtyCriteria.sicCode,
                sicObj: action.specialtyCriteria.sicObj,
                viewSpecialty: action.specialtyCriteria.viewSpecialty,
                elected: action.specialtyCriteria.elected,
                employeeOnly: action.specialtyCriteria.employeeOnly,
                employeeWithSpouse: action.specialtyCriteria.employeeWithSpouse,
                employeeWithChild: action.specialtyCriteria.employeeWithChild,
                employeeWithFamily: action.specialtyCriteria.employeeWithFamily,
                dentalCensusSummary: {
                    ...state.dentalCensusSummary,
                    elected: 0,
                    employeeOnly: action.specialtyCriteria.dentalCensusSummary ? action.specialtyCriteria.dentalCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.dentalCensusSummary ? action.specialtyCriteria.dentalCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.dentalCensusSummary ? action.specialtyCriteria.dentalCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.dentalCensusSummary ? action.specialtyCriteria.dentalCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily
                },
                visionCensusSummary: {
                    ...state.visionCensusSummary,
                    elected: 0,
                    employeeOnly: action.specialtyCriteria.visionCensusSummary ? action.specialtyCriteria.visionCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.visionCensusSummary ? action.specialtyCriteria.visionCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.visionCensusSummary ? action.specialtyCriteria.visionCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.visionCensusSummary ? action.specialtyCriteria.visionCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily
                },
                lifeCensusSummary: {
                    ...state.lifeCensusSummary,
                    elected: action.specialtyCriteria.lifeCensusSummary ? action.specialtyCriteria.lifeCensusSummary.elected : action.specialtyCriteria.elected,
                    employeeOnly: action.specialtyCriteria.lifeCensusSummary ? action.specialtyCriteria.lifeCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.lifeCensusSummary ? action.specialtyCriteria.lifeCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.lifeCensusSummary ? action.specialtyCriteria.lifeCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.lifeCensusSummary ? action.specialtyCriteria.lifeCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily
                },
                dependentLifeCensusSummary: {
                    ...state.dependentLifeCensusSummary,
                    employeeOnly: action.specialtyCriteria.dependentLifeCensusSummary ? action.specialtyCriteria.dependentLifeCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.dependentLifeCensusSummary ? action.specialtyCriteria.dependentLifeCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.dependentLifeCensusSummary ? action.specialtyCriteria.dependentLifeCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.dependentLifeCensusSummary ? action.specialtyCriteria.dependentLifeCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily,
                },
                stdCensusSummary: {
                    ...state.stdCensusSummary,
                    employeeOnly: action.specialtyCriteria.stdCensusSummary ? action.specialtyCriteria.stdCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.stdCensusSummary ? action.specialtyCriteria.stdCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.stdCensusSummary ? action.specialtyCriteria.stdCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.stdCensusSummary ? action.specialtyCriteria.stdCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily
                },
                ltdCensusSummary: {
                    ...state.ltdCensusSummary,
                    employeeOnly: action.specialtyCriteria.ltdCensusSummary ? action.specialtyCriteria.ltdCensusSummary.employeeOnly : action.specialtyCriteria.employeeOnly,
                    employeeWithSpouse: action.specialtyCriteria.ltdCensusSummary ? action.specialtyCriteria.ltdCensusSummary.employeeWithSpouse : action.specialtyCriteria.employeeWithSpouse,
                    employeeWithChild: action.specialtyCriteria.ltdCensusSummary ? action.specialtyCriteria.ltdCensusSummary.employeeWithChild : action.specialtyCriteria.employeeWithChild,
                    employeeWithFamily: action.specialtyCriteria.ltdCensusSummary ? action.specialtyCriteria.ltdCensusSummary.employeeWithFamily : action.specialtyCriteria.employeeWithFamily
}
            }

        case types.SET_CENSUS_STATUS:
            return {
                ...state,
                censusCompleted: action.censusComplete
            }

        case types.SET_SELECTED_PRODUCTS:
            return {
                ...state,
                viewSpecialty: {
                    ...state.viewSpecialty,
                    medical: action.selectedProducts.medical,
                    dental: action.selectedProducts.dental,
                    vision: action.selectedProducts.vision,
                    life: action.selectedProducts.life,
                    std: action.selectedProducts.std,
                    ltd: action.selectedProducts.ltd,
                    sul: action.selectedProducts.sul
                }
            }

        case types.SET_CENSUS_SALARY_RATING_METHOD:
            return {
                ...state,
                salaryRatingMethod: action.salaryRatingMethodSelection
            }

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
        case types.RESET_RENEWALS:
            return initialSpecialtyCriteria;

        default:
            return state
    }
}
