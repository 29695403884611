const pageConsts = {
    EMPLOYER_INFO: {
        route: 'employer-info',
        title: 'Employer Information',
    },
    ELIGIBILITY_INFO: {
        route: 'eligibility-info',
        title: 'Eligibility Information',
    },
    PRODUCER_INFO: {
        route: 'producer-info',
        title: 'Producer and General Agent Information',
    },
    EMPLOYER_ATTESTATION: {
        route: 'employer-attestation',
        title: 'Employer Attestation',
    },
    EMPLOYER_CONFIRMATION: {
        route: 'employer-confirmation',
        title: 'Employer Confirmation',
    },
    REQUIRED_DOCUMENTS: {
        route: 'required-documents',
        title: 'Required Documents',
    },
    PAYMENT: {
        route: 'payment',
        title: 'Payment / Billing',
    },
    DEBIT: {
        route: 'direct-debit',
        title: 'Direct Debit',
    },
    CENSUS_TABLE: {
        route: 'employees-overview',
        title: 'Employee Enrollment',
    },
    EMPLOYEE_INFO: {
        route: 'employee-info',
        title: 'Employee Information',
    },
    EMPLOYEE_ATTESTATION: {
        route: 'employee-attestation',
        title: 'Employee Attestation',
    },
    THANK_YOU: {
        route: 'thank-you',
        title: 'Thank You',
    },
    FINALIZE: {
        route: 'finalize',
        title: 'Finalize',
    },
    EMPLOYEE_CONFIRMATION: {
        route: 'employee-confirmation',
        title: 'Employee Confirmation',
    },
    FINALIZE_ENROLLMENT:{
        route: 'finalize-enrollment',
        title: 'Finalize Enrollment',
    },
    ADDITIONAL_REQUIRED_INFORMATION: {
        route:'additional-required-information',
        title:'Additional Required Information'
    },
    FINALIZE_ENROLLMENT_RENEWALS: {
        route: 'finalize-enrollment-thankYou',
        title: 'Finalize Enrollment',
    },
    EMPLOYER_CERTIFICATION: {
        route: 'employerCertification',
        title: 'Employer Certification',
    },
    INTERNAL_PEND_MANAGER: {
        route: 'pend-manager',
        title: 'Pend Manager'
    },
    STANDARD_HRA: {
        route: 'standard-hra',
        title: 'Standard HRA'
    }
};

const routeToTitleHelper = {};
const titleToRouteHelper = {};
const routeConstsHelper = {};
const titleConstsHelper = {};

const pageKeys = Object.keys(pageConsts);
pageKeys.forEach((pageKey) => {
    const { route, title } = pageConsts[pageKey];
    routeToTitleHelper[route] = title;
    titleToRouteHelper[title] = route;
    routeConstsHelper[pageKey] = route;
    titleConstsHelper[pageKey] = title;
});

export const routeToTitle = routeToTitleHelper;
export const titleToRoute = titleToRouteHelper;
export const routeConsts = routeConstsHelper;
export const titleConsts = titleConstsHelper;
