import * as types from '../actionTypes';
import { createHistory } from '../historyAction';

export const getRatingTier = (medicalRatingMethod) => {
    return medicalRatingMethod.age ? '4 Tier' : '2 Tier';
}

// Returns if the case is washington 2 tier case or not
export const isWasinghtonTwoTierCase = (quickQuoteRequest) => {
    return quickQuoteRequest.zipObj.stateCode === 'WA' && quickQuoteRequest.otherDetails.selectedProducts.includes('medical');
} 

export const getTierKeyMap = () => {
    return {
        '2 Tier' : 'twoTier',
        '4 Tier' : 'fourTier',
    }
}

export function restrictUserIfNotLicensed(stateCode, restrictedState, effectiveDate = null){
    if(effectiveDate === '')
        effectiveDate = null;
    let isUserRestricted = true;
    const {store} = require('../../index');
    const {renewals, reviewCards} = store.getState();
    const role = renewals.logInData[0].role;
    const licenseCheck = reviewCards.licenseCheck;
    if(!restrictedState || role === 'Internal'){
        isUserRestricted = false;
    }
    else{
        licenseCheck.forEach(license => {
            if(license.stateCode === stateCode && !isExpired(license.endDate, effectiveDate)){
                isUserRestricted = false;
            }
        });
    }
    if (isUserRestricted) {
        const PCIS_ID = renewals.val || ''
        const requestBody = {
            PCIS_ID: PCIS_ID, 
            licenseCheck: licenseCheck, 
            timestamp: new Date(),
            stateCode: stateCode
        }
        store.dispatch(createHistory('RESTRICTED_USER_ERROR', '', 'Error', requestBody, '', 'NB'));
    }
    store.dispatch({
        type: types.UPDATE_RESTRICTED_USER_FLAG,
        isUserRestricted: isUserRestricted
    })
    
    return isUserRestricted;
}

function isExpired(licenseDate, effectiveDate){
    let date1 = new Date(licenseDate);
    let date2 = new Date(effectiveDate);
    return date1 < date2;
}

export function restrictUserIfLicenseExpired(effectiveDate){
    const {store} = require('../../index');
    const {stateCode, restrictedState} = store.getState().overall.uhcStateConfig;
    if(stateCode){
        restrictUserIfNotLicensed(stateCode, restrictedState, effectiveDate);
    }
}

export function doStopRateCalls () {
    const currentPath = window.location.pathname
    if (currentPath === '/home') {
        return true
    }
    return false
}