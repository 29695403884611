import { getAgeFromDOB } from "../../../../../utils/formatters/numbers"
import deepCopy from "../../../../../utils/deepCopy"

export const validEmpCensus = (employees, service, effectiveDate, platform) => {
    let updatedCensus = []
    let newCopyEmployees = deepCopy(employees)

    let counter = 1
    newCopyEmployees.map((employee, index) => {
        // if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0][service.toLowerCase()]) {
        // if (!['', '-', 'select', 'waive'].includes(employee.productSelection[0][service.toLowerCase()])) {
        let updatedDeps = []
        if (employee.dependents && employee.dependents.length > 0) {
            employee.dependents.map((dependent, i) => {
                let depAge = dependent.age ? dependent.age : dependent.dob ? getAgeFromDOB(dependent.dob, effectiveDate) : 0
                if (employee.productSelection[i + 1] && !['', '-', 'select', 'waive'].includes(employee.productSelection[i + 1][service.toLowerCase()])) {
                    dependent.age = parseInt(depAge) === 0 ? 1 : depAge
                    dependent.gender = dependent.gender ? dependent.gender.charAt(0) : ''
                    dependent.relationship = dependent.relationship ?  dependent.relationship :''
                    updatedDeps.push(dependent)
                }
            })
        }
        let employeeAge = employee.age ? employee.age : employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : platform.toUpperCase() === 'CIRRUS' ? 10 : 15
        employee['dependents'] = updatedDeps
        // employee['employeeId'] = employee.memberId ? employee.memberId : index
        employee['employeeId'] = employee.memberId ? employee.memberId : index + 1
        employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        employee['age'] = parseInt(employeeAge) === 0 ? 10 : parseInt(employeeAge)
        if (employee.employeeInfo) {
            employee.employeeInfo['gender'] = employee.gender.charAt(0)
        }

        updatedCensus.push(employee)
        // }
        // else {
        //     let employeeAge = employee.age ? employee.age : employee.dob ? getAgeFromDOB(employee.dob, effectiveDate) : 10
        //     employee['dependents'] = []
        //     employee['employeeId'] = employee.memberId ? employee.memberId : index
        //     employee['gender'] = employee.gender ? employee.gender.charAt(0) : ''
        //     employee['age'] = parseInt(employeeAge) === 0 ? 10 : parseInt(employeeAge)
        //     if (employee.employeeInfo) {
        //         employee.employeeInfo['gender'] = employee.gender.charAt(0)
        //     }
        //     updatedCensus.push(employee)

        // }
        // }
    })

    return updatedCensus
}

export const removeWaivedAllEmployees = (employees) => {
    const updatedEmployees = employees.filter(employee => employee?.waiveCoverage?.isWaivingAll !== "Yes")
    return updatedEmployees
}