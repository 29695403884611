import * as consts from '../utils/consts/consts';

// TODO: Namespace the constants so there is no need for commented sections

export const BEGIN_SAVING_QUOTE = 'BEGIN_SAVING_QUOTE';
export const FINISH_SAVING_QUOTE = 'FINISH_SAVING_QUOTE';
export const FINISH_UPDATING_QUOTE = 'FINISH_UPDATING_QUOTE';
export const ERROR_SAVING_QUOTE = 'ERROR_SAVING_QUOTE';
export const REQUEST_ALL_QUOTES = 'REQUEST_ALL_QUOTES';
export const RECEIVE_ALL_QUOTES = 'RECEIVE_ALL_QUOTES';
export const ERROR_ALL_QUOTES = 'ERROR_ALL_QUOTES';

export const BEGIN_RESUMING_QUOTE = 'BEGIN_RESUMING_QUOTE';
export const FINISH_RESUMING_QUOTE = 'FINISH_RESUMING_QUOTE';
export const ERROR_RESUMING_QUOTE = 'ERROR_RESUMING_QUOTE';

///////////////////////////// Full Shopping ///////////////////////////
//// Company Profile ///////
export const PROFILE_INPUT = 'PROFILE_INPUT';
export const PROFILE_UPDATING = 'PROFILE_UPDATING';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PROFILE_UPDATE_ERRORED = 'PROFILE_UPDATE_ERRORED';
export const PROFILE_RETRIEVED = 'PROFILE_RETRIEVED';
export const UPDATE_BREADCRUMB_ACCESS = 'UPDATE_BREADCRUMB_ACCESS';
export const RESET_BREADCRUMB_ACCESS = 'RESET_BREADCRUMB_ACCESS';
export const IMPORT_PROFILE = 'IMPORT_PROFILE';
export const RESET_PROFILE = 'RESET_PROFILE';
export const RESET_FLOW = 'RESET_FLOW';
export const RESET_TO_INITIALSTATE = 'RESET_TO_INITIALSTATE';
export const UPDATE_CASE_PROGRESS = 'UPDATE_CASE_PROGRESS';
export const UPDATE_PEND_ACCEPTANCE_REASON = 'UPDATE_PEND_ACCEPTANCE_REASON';
export const UPDATE_RL_CASE_PROGRESS = 'UPDATE_RL_CASE_PROGRESS';
export const UPDATE_CASE_PEND_REP = 'UPDATE_CASE_PEND_REP';
export const UPDATE_FINAL_SUBMIT_FLAG = 'UPDATE_FINAL_SUBMIT_FLAG';
export const UPDATE_QUOTE_STATUS = 'UPDATE_QUOTE_STATUS';
export const UPDATE_QUOTE_PROGRESS = 'UPDATE_QUOTE_PROGRESS';
export const UPDATE_IMPORT_DATA = 'UPDATE_IMPORT_DATA';
export const UPDATE_EDIT_IMPORT = 'UPDATE_EDIT_IMPORT';
export const UPDATE_IMPORT_STATUS = 'UPDATE_IMPORT_STATUS';
export const REQUESTED_EMPLOYEE_ASSISTANCE = 'REQUESTED_EMPLOYEE_ASSISTANCE';
export const UPDATE_ENROLLMENT_SELECTED_PRODUCTS = 'UPDATE_ENROLLMENT_SELECTED_PRODUCTS';
export const UPDATE_COMPANY_PROFILE_LOCATIONS = 'UPDATE_COMPANY_PROFILE_LOCATIONS';
export const UPDATE_COMPANY_PROFILE_EMPLOYEES = 'UPDATE_COMPANY_PROFILE_EMPLOYEES';
export const SET_SALESREP_AVAIL_FLAG = 'SET_SALESREP_AVAIL_FLAG';
export const SET_GASALESREP_AVAIL_FLAG = 'SET_GASALESREP_AVAIL_FLAG';
export const SET_ACTIVE_DATES = 'SET_ACTIVE_DATES';
export const SET_CANNABIS_EMP = 'SET_CANNABIS_EMP';
export const SAVE_MEDICAL_PARTICIPATION = 'SAVE_MEDICAL_PARTICIPATION';
export const UPDATE_TERMINATED_EMPLOYEES_AND_DEPENDENTS = 'UPDATE_TERMINATED_EMPLOYEES_AND_DEPENDENTS'
export const IS_SIC_ENTERED = 'IS_SIC_ENTERED';
//FI TO LF Button enable/disable value action
export const UPDATE_FILF_BUTTON_ENABLE_DISABLE = 'UPDATE_FILF_BUTTON_ENABLE_DISABLE';
export const UPDATE_MICROGROUP_STATUS = 'UPDATE_MICROGROUP_STATUS';
export const UPDATE_DEPENDENT_RULES = 'UPDATE_DEPENDENT_RULES';
export const UPDATE_PREFERRED_PLANS_PKG = 'UPDATE_PREFERRED_PLANS_PKG';
export const REMOVE_DESELECTED_ENROLLED_PLANS = 'REMOVE_DESELECTED_ENROLLED_PLANS';
export const SET_UNSAVED_ENROLL_FORM_CHANGES = 'SET_UNSAVED_ENROLL_FORM_CHANGES';

//// Full Shopping Actions ///////
//TODO: This should take over for everything eventually, or just be a stop gap for now
export const FULL_SHOPPING_UPDATE = 'FULL_SHOPPING_UPDATE';

//////////////////////////// Quick Quotes ////////////////////////////

//////// Overall ////////
export const SET_QUICK_QUOTE_REQUEST_INFO = 'SET_QUICK_QUOTE_REQUEST_INFO';
export const TOGGLE_FI_OR_ASO = 'TOGGLE_FI_OR_ASO';
export const UPDATE_NAMES = 'UPDATE_NAMES';
export const UPDATE_GENDERS = 'UPDATE_GENDERS';
export const UPDATE_SOURCE = 'UPDATE_SOURCE';
export const UPDATE_PREVIOUS = 'UPDATE_PREVIOUS';
export const UPDATE_NEXT = 'UPDATE_NEXT';
export const ZIP_RETURN_ERROR = 'ZIP_RETURN_ERROR';
export const SIC_RETURN_ERROR = 'SIC_RETURN_ERROR';
export const EDIT_COMPANY_PROFILE = 'EDIT_COMPANY_PROFILE';
export const EDIT_COMPANY_NAME = 'EDIT_COMPANY_NAME';
export const COMPANY_NAME_EDIT_ERRORED = 'COMPANY_NAME_EDIT_ERRORED';
export const INITIALIZE_APP_CONFIG = 'INITIALIZE_APP_CONFIG';
export const INITIALIZE_APP_CONFIG_ERROR = 'INITIALIZE_APP_CONFIG_ERROR';
export const GET_ZIP_CONFIG = 'GET_ZIP_CONFIG';
export const GET_ZIP_CONFIG_ERROR = 'GET_ZIP_CONFIG_ERROR';
export const UPDATE_SUPPORTED_AREA_QQ = 'UPDATE_SUPPORTED_AREA_QQ';
export const UPDATE_SUPPORTED_AREA_FS = 'UPDATE_SUPPORTED_AREA_FS';
export const UPDATE_UHC_STATE_CONFIG = 'UPDATE_UHC_STATE_CONFIG';
export const SET_USERTOKEN = 'SET_USERTOKEN';
export const INELIGIBLE_CASE_MODAL_POPUP = 'INELIGIBLE_CASE_MODAL_POPUP';
export const IS_ELIGIBLE_TO_FINALIZE = 'IS_ELIGIBLE_TO_FINALIZE';
export const INITIALIZE_EFFECTIVE_DATE_RULES = 'INITIALIZE_EFFECTIVE_DATE_RULES';
export const INITIALIZE_PLAN_TYPE_RULES = 'INITIALIZE_PLAN_TYPE_RULES';
export const UPDATE_BASIC_CLASSES = 'UPDATE_BASIC_CLASSES';
export const UPDATE_BASIC_STD_CLASSES = 'UPDATE_BASIC_STD_CLASSES';
export const UPDATE_BASIC_LTD_CLASSES = 'UPDATE_BASIC_LTD_CLASSES';
export const CLEAR_SELECTED_CLASSES = 'CLEAR_SELECTED_CLASSES';
export const CLEAR_SELECTED_STD_CLASSES = 'CLEAR_SELECTED_CLASSES';
export const CLEAR_SELECTED_LTD_CLASSES = 'CLEAR_SELECTED_CLASSES';
export const ADD_CLASSING_OPTION = 'ADD_CLASSING_OPTION';
export const SET_EMPLOYEE_SELECTED_CLASSES = 'SET_EMPLOYEE_SELECTED_CLASSES';
export const SET_EMPLOYEE_SELECTED_STD_CLASSES = 'SET_EMPLOYEE_SELECTED_STD_CLASSES';
export const SET_EMPLOYEE_SELECTED_LTD_CLASSES = 'SET_EMPLOYEE_SELECTED_LTD_CLASSES';

export const SET_LIFE_CLASS = 'SET_LIFE_CLASS';
export const SET_LIFE_CLASS_RATES_FETCHED = 'SET_LIFE_CLASS_RATES_FETCHED';
export const SET_STD_CLASS = 'SET_STD_CLASS';
export const SET_STD_CLASS_RATES_FETCHED = 'SET_STD_CLASS_RATES_FETCHED';
export const SET_LTD_CLASS = 'SET_LTD_CLASS';
export const SET_LTD_CLASS_RATES_FETCHED = 'SET_LTD_CLASS_RATES_FETCHED';
export const SET_IS_FETCHING_CLASS_RATES = 'SET_IS_FETCHING_CLASS_RATES';
export const UPDATE_LIFE_PLANS_CLASS_FILTER = 'UPDATE_LIFE_PLANS_CLASS_FILTER';
export const UPDATE_STD_PLANS_CLASS_FILTER = 'UPDATE_STD_PLANS_CLASS_FILTER';
export const UPDATE_LTD_PLANS_CLASS_FILTER = 'UPDATE_LTD_PLANS_CLASS_FILTER';
export const SET_DUPLICATE_TIN = 'SET_DUPLICATE_TIN';
export const UPDATE_RESTRICTED_USER_FLAG = 'UPDATE_RESTRICTED_USER_FLAG';
export const UNSUPPORTED_OXFORD_ZIP_ERROR = 'UNSUPPORTED_OXFORD_ZIP_ERROR';
export const UNSUPPORTED_OXFORD_ZIP_ERROR_POPUP_SHOW = 'UNSUPPORTED_OXFORD_ZIP_ERROR_POPUP_SHOW';
export const SET_PREPOPULATED_FLAG = 'SET_PREPOPULATED_FLAG';
export const SET_MED_UPSEL = 'SET_MED_UPSEL';
export const DEFAULT_GA_REMOVED = 'DEFAULT_GA_REMOVED';
export const IS_PARTICIPATION_BYPASSED = 'IS_PARTICIPATION_BYPASSED';
export const UPDATE_RENEWAL_PARENT_PLAN_CODE_LIFE_DEP_BASIC= 'UPDATE_RENEWAL_PARENT_PLAN_CODE_LIFE_DEP_BASIC';
export const UPDATE_RENEWAL_SHOPPING_PARENT_PLAN_CODE_LIFE_DEP_BASIC= 'UPDATE_RENEWAL_SHOPPING_PARENT_PLAN_CODE_LIFE_DEP_BASIC';

//////// 1 per medical plan type ////////
const serviceTypes = [
    consts.FI_IDENTIFIER,
    consts.ASO_IDENTIFIER,
    consts.MEDICAL,
    consts.DENTAL,
    consts.VISION,
    consts.LIFE,
    consts.LIFE_EMP_SUPP,
    consts.LIFE_DEP_BASIC,
    consts.LIFE_DEP_SUPP_SPOUSE,
    consts.LIFE_DEPP_SUPP_CHILD,
    consts.STD,
    consts.LTD,
    consts.SUL,
    consts.RENEWAL_SHOPPING,
    consts.SUPPLIFE,
    consts.SUPP_DEP_LIFE,
    consts.SUPPDEPLIFE,
];
const serviceTypeConsts = {};
serviceTypes.forEach((type) => {
    serviceTypeConsts[type] = {
        REQUEST_QUICK_QUOTE_PLANS: `REQUEST_QUICK_QUOTE_PLANS_${type}`,
        RECEIVE_QUICK_QUOTE_PLANS: `RECEIVE_QUICK_QUOTE_PLANS_${type}`,
        ERROR_QUICK_QUOTE_PLANS: `ERROR_QUICK_QUOTE_PLANS_${type}`,
        DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE: `DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE_${type}`,
        REQUEST_QUICK_QUOTE_RATES: `REQUEST_QUICK_QUOTE_RATES_${type}`,
        RECEIVE_QUICK_QUOTE_RATES: `RECEIVE_QUICK_QUOTE_RATES_${type}`,
        ERROR_QUICK_QUOTE_RATES: `ERROR_QUICK_QUOTE_RATES_${type}`,
        FETCHING_FINALIZED_QUOTE_RATES: `FETCHING_FINALIZED_QUOTE_RATES_${type}`,
        RECEIVE_FINALIZED_QUOTE_RATES: `RECEIVE_FINALIZED_QUOTE_RATES_${type}`,
        ERROR_FINALIZED_QUOTE_RATES: `ERROR_FINALIZED_QUOTE_RATES_${type}`,
        UPDATE_PLAN: `UPDATE_PLAN_${type}`,
        UPDATE_RENEWAL_PLAN: `UPDATE_RENEWAL_PLAN_${type}`,
        UPDATE_RENEWAL_PLAN_SHOPPING_HELPER: `UPDATE_RENEWAL_PLAN_SHOPPING_HELPER${type}`,
        UPDATE_ALL_PLANS: `UPDATE_ALL_PLANS_${type}`,
        UPDATE_SELECTED_PLAN:`UPDATE_SELECTED_PLAN_${type}`,
        UPDATE_ALL_PLANS_FROM_INSIDE: `UPDATE_ALL_PLANS_FROM_INSIDE_${type}`,
        CHANGE_FILTER_CRITERIA: `CHANGE_FILTER_CRITERIA_${type}`,
        RESET_FILTER_CRITERIA: `RESET_FILTER_CRITERIA_${type}`,
        CHANGE_SORT_ORDER: `CHANGE_SORT_ORDER_${type}`,
        TOGGLE_PLAN_ENROLL: `TOGGLE_PLAN_ENROLL_${type}`,
        TOGGLE_PLAN_ENROLL_RENEWAL: `TOGGLE_PLAN_ENROLL_RENEWAL_${type}`,
        TOGGLE_PACKAGE_ENROLL: `TOGGLE_PACKAGE_ENROLL_${type}`,
        TOGGLE_PREFERRED_PLANS: `TOGGLE_PREFERRED_PLANS_${type}`,
        RECEIVE_QUICK_QUOTE_ALL_PLANS: `RECEIVE_QUICK_QUOTE_ALL_PLANS_${type}`,
        RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS: `RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS_${type}`,
        RECEIVE_QUICK_QUOTE_PLAN_RATES: `RECEIVE_QUICK_QUOTE_PLAN_RATES_${type}`,
        RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES: `RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES_${type}`,
        SET_ADVANCE_FILTER_DATA: `SET_ADVANCE_FILTER_DATA_${type}`,
        SET_ADVANCE_FILTER_30_PLANS: `SET_ADVANCE_FILTER_30_PLANS${type}`,
        APPLY_FILTER: `APPLY_FILTER_${type}`,
        SET_BASIC_FILTERS_DIRECTLY: `SET_BASIC_FILTERS_DIRECTLY_${type}`,
        SET_BASIC_FILTERS_DIRECTLY_UHC: `SET_BASIC_FILTERS_DIRECTLY_UHC_${type}`,
        VIEW_FILTERED_PLANS: `VIEW_FILTERED_PLANS_${type}`,
        RESET_FILTERS: `RESET_FILTERS_${type}`,
        RESET_DATA: `RESET_DATA_${type}`,
        ADJUST_RATE: `ADJUST_RATE_${type}`,
        CLEAR_SELECTED_PLANS: `CLEAR_SELECTED_PLANS_${type}`,
        CLEAR_SELECTED_VISIBLE_PLANS: `CLEAR_SELECTED_VISIBLE_PLANS_${type}`,
        CLEAR_ENROLLED_PLANS: `CLEAR_ENROLLED_PLANS_${type}`,
        ADJUST_RENEWALS_RATE: `ADJUST_RENEWALS_RATE_${type}`,
        ADJUST_RENEWALS_RATE_REVIEW: `ADJUST_RENEWALS_RATE_REVIEW_${type}`,
        UPDATE_AGES: `UPDATE_AGES_${type}`,
        CHANGE_CUSTOM_PLAN_NAME: `CHANGE_CUSTOM_PLAN_NAME_${type}`,
        CLEAR_ERROR_MESSAGE: `CLEAR_ERROR_MESSAGE_${type}`,
        //Renewal Shopping actions
        REQUEST_REN_ADDITIONAL_PLANS: `REQUEST_REN_ADDITIONAL_PLANS_${type}`,
        RECEIVE_REN_ADDITIONAL_PLANS: `RECEIVE_REN_ADDITIONAL_PLANS_${type}`,
        ERR_REN_ADDITIONAL_PLANS: `ERR_REN_ADDITIONAL_PLANS_${type}`,
        REQUEST_REN_ADDITIONAL_RATES: `REQUEST_REN_ADDITIONAL_RATES_${type}`,
        RECEIVE_REN_ADDITIONAL_RATES: `RECEIVE_REN_ADDITIONAL_RATES_${type}`,
        ERR_REN_ADDITIONAL_RATE: `ERR_REN_ADDITIONAL_RATES_${type}`,
        //RENEWAL PLANS
        REQUEST_RENEWAL_PLANS: `REQUEST_RENEWAL_PLANS_${type}`,
        RECEIVE_RENEWAL_PLANS: `RECEIVE_RENEWAL_PLANS_${type}`,
        SET_RENEWAL_PLANS_RECEIVED: `SET_RENEWAL_PLANS_RECEIVED_${type}`,
        ERROR_RENEWAL_PLANS: `ERROR_RENEWAL_PLANS_${type}`,
        REQUEST_RENEWAL_RATES: `REQUEST_RENEWAL_RATES_${type}`,
        RECEIVE_RENEWAL_RATES: `RECEIVE_RENEWAL_RATES_${type}`,
        ERROR_RENEWAL_RATES: `ERROR_RENEWAL_RATES_${type}`,
        //MMR
        SET_MMR_PLANS: `SET_MMR_PLANS_${type}`,
        SET_MMR_IS_FETCHING: `SET_MMR_IS_FETCHING_${type}`
    };
});

export const SERVICE = serviceTypeConsts;
export const UPDATE_ENROLLED_MEDICAL_PLANS = 'UPDATE_ENROLLED_MEDICAL_PLANS';
export const UPDATE_PLANS_HIOS_DATA = 'UPDATE_PLANS_HIOS_DATA';
export const UPDATE_ENROLLED_DENTAL_PLANS = 'UPDATE_ENROLLED_DENTAL_PLANS';
export const UPDATE_ENROLLED_VISION_PLANS = 'UPDATE_ENROLLED_VISION_PLANS';
export const UPDATE_ENROLLED_LTD_PLANS = 'UPDATE_ENROLLED_LTD_PLANS';
export const UPDATE_ENROLLED_LTD_PLANS_RATES = 'UPDATE_ENROLLED_LTD_PLANS_RATES';
export const UPDATE_ENROLLED_BASIC_LIFE_PLANS = 'UPDATE_ENROLLED_BASIC_LIFE_PLANS';
export const UPDATE_ENROLLED_STD_PLANS = 'UPDATE_ENROLLED_STD_PLANS';
export const UPDATE_ENROLLED_STD_PLANS_RATES = 'UPDATE_ENROLLED_STD_PLANS_RATES';
export const UPDATE_ENROLLED_SUPP_DEP_LIFE_PLANS = 'UPDATE_ENROLLED_SUPP_DEP_LIFE_PLANS';
export const UPDATE_ENROLLED_SUPPLIFE_PLANS = 'UPDATE_ENROLLED_SUPPLIFE_PLANS';
export const UPDATE_GUARANTEED_ISSUE_AMT_IN_LIFE_PLANS = 'UPDATE_GUARANTEED_ISSUE_AMT_IN_LIFE_PLANS';
export const UPDATE_SELECTED_DENTAL_PLAN = 'UPDATE_SELECTED_DENTAL_PLAN';
export const UPDATE_SELECTED_STD_PLAN = 'UPDATE_SELECTED_STD_PLAN';
export const UPDATE_SELECTED_VISION_PLAN = 'UPDATE_SELECTED_VISION_PLAN';
export const UPDATE_SELECTED_MEDICAL_PLAN = 'UPDATE_SELECTED_MEDICAL_PLAN';
export const UPDATE_SELECTED_DEPENDENTLIFE_PLAN = 'UPDATE_SELECTED_DEPENDENTLIFE_PLAN';
export const UPDATE_SELECTED_LIFE_PLAN = 'UPDATE_SELECTED_LIFE_PLAN';
export const UPDATE_SELECTED_LTD_PLAN = 'UPDATE_SELECTED_LTD_PLAN';
export const RESET_COPY_FLAG = 'RESET_COPY_FLAG';
export const UPDATE_QUOTE_REVIEW_ERRORS = 'UPDATE_QUOTE_REVIEW_ERRORS';
export const SET_DEPENDENT_LIFE_COMPONENTS = 'SET_DEPENDENT_LIFE_COMPONENTS';
export const SET_DEPENDENT_SUPPLIFE_COMPONENTS = 'SET_DEPENDENT_SUPPLIFE_COMPONENTS';
export const SET_SUPP_LIFE_COMPONENTS = 'SET_SUPP_LIFE_COMPONENTS';
export const UPDATE_RENEWAL_PLAN_MEDICAL = 'UPDATE_RENEWAL_PLAN_MEDICAL'
export const UPDATE_RENEWAL_PLAN_SHOPPING_HELPER_MEDICAL = 'UPDATE_RENEWAL_PLAN_SHOPPING_HELPER_MEDICAL'

// Specific for Washington 2 Tier rates for dental and vision
export const CHANGE_RATE_TIER_FOR_SPECIALTY = 'CHANGE_RATE_TIER_FOR_SPECIALTY'

//////////////////////////// Benefit Rider Actions /////////////////////

export const REQUEST_BENEFIT_RIDER_RATES = 'REQUEST_BENEFIT_RIDER_RATES';
export const RECEIVE_BENEFIT_RIDER_RATES = 'RECEIVE_BENEFIT_RIDER_RATES';
export const REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS = 'REQUEST_PLAN_RATES_WITH_RIDERS';
export const RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS = 'RECEIVE_PLAN_RATES_WITH_RIDERS';
export const SAVE_SELECTED_RIDERS_IN_PLANS = 'SAVE_SELECTED_RIDERS_IN_PLANS';
export const RESTORE_PREMIUM_ON_DESELECT = 'RESTORE_PREMIUM_ON_DESELCT';


// Renewals
export const REQUEST_BENEFIT_RIDER_RATES_RS = 'REQUEST_BENEFIT_RIDER_RATES_RS';
export const RECEIVE_BENEFIT_RIDER_RATES_RS = 'RECEIVE_BENEFIT_RIDER_RATES_RS';
export const SAVE_SELECTED_RIDERS_IN_PLANS_RS = 'SAVE_SELECTED_RIDERS_IN_PLANS_RS';
export const REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS_RS = 'REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS_RS';
export const RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS_RS = 'RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS_RS';
export const RESTORE_PREMIUM_ON_DESELECT_RS = 'RESTORE_PREMIUM_ON_DESELCT_RS';
export const SET_DEFAULT_RATING_METHOD = 'SET_DEFAULT_RATING_METHOD'
export const SET_FETCHING_ALTERNATE_RATING_METHOD = 'SET_FETCHING_ALTERNATE_RATING_METHOD';
export const UPDATE_ALTERNATE_RATES_MEDICAL_REN_SHOP = 'UPDATE_ALTERNATE_RATES_MEDICAL_REN_SHOP';
export const CHANGE_ENROLLED_RATING_METHOD_REN_SHOP = 'CHANGE_ENROLLED_RATING_METHOD_REN_SHOP';
export const SET_IS_FETCHING_MEDICAL_PLANS = 'SET_IS_FETCHING_MEDICAL_PLANS';
export const DISABLE_TOP_HEADER = 'DISABLE_TOP_HEADER';
//////////////////////////// Resizing ////////////////////////////////

export const UPDATE_SCREEN_SIZE = 'UPDATE_SCREEN_SIZE';

//////////////////////////// RENEWAL PRIME HW ///////////////////////////////
export const INSTALL_PRIME_MEM_GROUP_HW_REQUEST = 'INSTALL_PRIME_MEM_GROUP_HW_REQUEST';
export const INSTALL_PRIME_MEM_GROUP_HW_SUCCESS = 'INSTALL_PRIME_MEM_GROUP_HW_SUCCESS';
export const INSTALL_PRIME_MEM_GROUP_HW_ERROR = 'INSTALL_PRIME_MEM_GROUP_HW_ERROR';
export const INSTALL_PRIME_MEM_GROUP_HW_TECH_ERROR = 'INSTALL_PRIME_MEM_GROUP_HW_TECH_ERROR';

export const INSTALL_PRIME_MEM_HW_REQUEST = 'INSTALL_PRIME_MEM_HW_REQUEST';
export const INSTALL_PRIME_MEM_HW_SUCCESS = 'INSTALL_PRIME_MEM_HW_SUCCESS';
export const INSTALL_PRIME_MEM_HW_ERROR = 'INSTALL_PRIME_MEM_HW_ERROR';
export const INSTALL_PRIME_MEM_HW_TECH_ERROR = 'INSTALL_PRIME_MEM_HW_TECH_ERROR';

export const RENEWAL_BTN_DISABLE_FLAG = 'RENEWAL_BTN_DISABLE_FLAG';
export const RENEWAL_HW_DISABLE = 'RENEWAL_HW_DISABLE';

//////////////////////////// Enrollment ////////////////////////////////

export const INSTALL_MEM_GROUP_LW_REQUEST = 'INSTALL_MEM_GROUP_LW_REQUEST';
export const INSTALL_MEM_GROUP_LW_SUCCESS = 'INSTALL_MEM_GROUP_LW_SUCCESS';
export const INSTALL_MEM_GROUP_LW_ERROR = 'INSTALL_MEM_GROUP_LW_ERROR';
export const INSTALL_MEM_GROUP_LW_TECH_ERROR = 'INSTALL_MEM_GROUP_LW_TECH_ERROR';

export const INSTALL_MEM_GROUP_HW_REQUEST = 'INSTALL_MEM_GROU_HW_REQUEST';
export const INSTALL_MEM_GROUP_HW_SUCCESS = 'INSTALL_MEM_GROUP_HW_SUCCESS';
export const GOT_TRANSACTION_STATUS = 'GOT_TRANSACTION_STATUS';
export const INSTALL_MEM_GROUP_HW_ERROR = 'INSTALL_MEM_GROUP_HW_ERROR';
export const INSTALL_MEM_GROUP_HW_TECH_ERROR = 'INSTALL_MEM_GROUP_HW_TECH_ERROR';

export const INSTALL_MEM_LW_REQUEST = 'INSTALL_MEM_LW_REQUEST';
export const INSTALL_MEM_LW_SUCCESS = 'INSTALL_MEM_LW_SUCCESS';
export const INSTALL_MEM_LW_ERROR = 'INSTALL_MEM_LW_ERROR';
export const INSTALL_MEM_LW_TECH_ERROR = 'INSTALL_MEM_LW_TECH_ERROR';

export const INSTALL_MEM_HW_REQUEST = 'INSTALL_MEM_HW_REQUEST';
export const INSTALL_MEM_HW_SUCCESS = 'INSTALL_MEM_HW_SUCCESS';
export const INSTALL_MEM_HW_ERROR = 'INSTALL_MEM_HW_ERROR';
export const INSTALL_MEM_HW_TECH_ERROR = 'INSTALL_MEM_HW_TECH_ERROR';
export const CLEAR_TECH_ERROR = 'CLEAR_TECH_ERROR';
export const HW_LOADING = 'HW_LOADING';
export const SET_HW_LOADING_FALSE = 'SET_HW_LOADING_FALSE';

export const INSTALL_MEM_LW_PRIME_REQUEST = 'INSTALL_MEM_LW_PRIME_REQUEST'
export const UPDATE_ALT_ID_MEM_GROUP = 'UPDATE_ALT_ID_MEM_GROUP'
export const INSTALL_MEM_LW_PRIME_SUCCESS = 'INSTALL_MEM_LW_PRIME_SUCCESS'
export const INSTALL_MEM_LW_PRIME_ERROR = 'INSTALL_MEM_LW_PRIME_ERROR'
export const PRODUCT_CANCELLATION_TECH_ERROR = 'PRODUCT_CANCELLATION_TECH_ERROR'
export const MEMBER_TERMINATION_TECH_ERROR = 'MEMBER_TERMINATION_TECH_ERROR'

export const CHANGE_EMPLOYEE_CONTRIB = 'CHANGE_EMPLOYEE_CONTRIB';
export const CHANGE_EMPLOYEE_LIFE_CONTRIB = 'CHANGE_EMPLOYEE_LIFE_CONTRIB';
export const TOGGLE_MONTHLY_ANNUAL = 'TOGGLE_MONTHLY_ANNUAL'; // so far just used for medical

export const UPLOAD_REQUIRED_DOCUMENT = 'UPLOAD_REQUIRED_DOCUMENT';
export const REMOVE_REQUIRED_DOCUMENT = 'REMOVE_REQUIRED_DOCUMENT';
export const LOAD_UPLOADED_REQUIRED_DOCUMENTS = 'LOAD_UPLOADED_REQUIRED_DOCUMENTS';
export const VIEW_REQUIRED_DOCUMENT = 'VIEW_REQUIRED_DOCUMENT';
export const RESET_REQUIRED_DOCUMENT = 'RESET_REQUIRED_DOCUMENT';
export const VIEW_DOC_INSTRUCTIONS = 'VIEW_DOC_INSTRUCTIONS';
export const DOWNLOAD_REQUIRED_DOC_FORM = 'DOWNLOAD_REQUIRED_DOC_FORM';
export const DOWNLOAD_PAYMENT_BILLING_FORM = 'DOWNLOAD_PAYMENT_BILLING_FORM';
export const SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT = 'SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT';

/////////////// Enrollment Application updates ////////////
export const INITIALIZE_EMPLOYER_INFO = 'INITIALIZE_EMPLOYER_INFO';
export const INITIALIZE_ELIGIBILITY_INFO = 'INITIALIZE_ELIGIBILITY_INFO';
export const INITIALIZE_PRODUCER_INFO = 'INITIALIZE_PRODUCER_INFO';
export const INITIALIZE_ATTESTATION_INFO = 'INITIALIZE_ATTESTATION_INFO';
export const INITIALIZE_EMPLOYEE_INFO = 'INITIALIZE_EMPLOYEE_INFO';
export const INITIALIZE_EMPLOYER_CERTIFICATION_INFO = 'INITIALIZE_EMPLOYER_CERTIFICATION_INFO';
export const INITIALIZE_STANDARD_HRA = 'INITIALIZE_STANDARD_HRA';
export const REFRESH_EMPLOYER_CERTIFICATION_INFO = 'REFRESH_EMPLOYER_CERTIFICATION_INFO';
export const SAVE_EMPLOYER_CERTIFICATION_INFO = 'SAVE_EMPLOYER_CERTIFICATION_INFO';
export const OPEN_INDIVIDUAL_EMPLOYEE = 'OPEN_INDIVIDUAL_EMPLOYEE';
export const CLOSE_INDIVIDUAL_EMPLOYEE = 'CLOSE_INDIVIDUAL_EMPLOYEE';
export const SAVE_INDIVIDUAL_EMPLOYEE = 'SAVE_INDIVIDUAL_EMPLOYEE';
export const UPDATE_EMPLOYEE_TABLE = 'UPDATE_EMPLOYEE_TABLE';
export const UPDATE_EMPLOYEE_TABLE_ROW = 'UPDATE_EMPLOYEE_TABLE_ROW';
export const ADD_ENROLLED_DEPENDENT = 'ADD_ENROLLED_DEPENDENT';
export const REMOVE_ENROLLED_DEPENDENT = 'REMOVE_ENROLLED_DEPENDENT';
export const UPDATE_PROPOSED_RATE_REFERENCE_ID = 'UPDATE_PROPOSED_RATE_REFERENCE_ID';
export const UPDATE_INSTALLED_RATE_REFERENCE_ID = 'UPDATE_INSTALLED_RATE_REFERENCE_ID';
export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE';
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';
export const DISABLE_FULL_SHOPPING_BREADCRUMB = 'DISABLE_FULL_SHOPPING_BREADCRUMB';
export const POPULATE_ELIGIBILITY_INFO = 'POPULATE_ELIGIBILITY_INFO';
export const UPDATE_STANDARD_HRA = 'UPDATE_STANDARD_HRA';
export const POPULATE_STANDARD_HRA = 'POPULATE_STANDARD_HRA';
export const POPULATE_PRODUCER_INFO = 'POPULATE_PRODUCER_INFO';

export const UPDATE_EMPLOYER_INFO = 'UPDATE_EMPLOYER_INFO';
export const UPDATE_ELIGIBILITY_INFO = 'UPDATE_ELIGIBILITY_INFO';
export const UPDATE_PRODUCER_INFO = 'UPDATE_PRODUCER_INFO';
export const UPDATE_ATTESTATION_INFO = 'UPDATE_ATTESTATION_INFO';
export const UPDATE_EMPLOYEE_INFO = 'UPDATE_EMPLOYEE_INFO';
export const UPDATE_EMPLOYER_CERTIFICATION = 'UPDATE_EMPLOYER_CERTIFICATION';
export const UPDATE_EMPLOYER_CERTIFICATION_BULK_ERR = 'UPDATE_EMPLOYER_CERTIFICATION_BULK_ERR';

export const REMOVE_ENROLLED_PLAN = 'REMOVE_ENROLLED_PLAN';
export const REQUEST_ENROLLMENT_FORM = 'REQUEST_ENROLLMENT_FORM';
export const RECEIVE_ENROLLMENT_FORM = 'RECEIVE_ENROLLMENT_FORM';
export const ERROR_ENROLLMENT_FORM = 'ERROR_ENROLLMENT_FORM';
export const UPDATE_ENROLLMENT_FORM_FUNCS = 'UPDATE_ENROLLMENT_FORM_FUNCS';
export const SET_MEDICAL_PRODUCT = 'SET_MEDICAL_PRODUCT';

export const COMPLETED_EMPLOYER_INFO = 'COMPLETED_EMPLOYER_INFO';
export const COMPLETED_ELIGIBILITY_INFO = 'COMPLETED_ELIGIBILITY_INFO';
export const COMPLETED_PRODUCER_INFO = 'COMPLETED_PRODUCER_INFO';
export const COMPLETED_ATTESTATION_INFO = 'COMPLETED_ATTESTATION_INFO';
export const REFRESH_ATTESTATION_INFO = 'REFRESH_ATTESTATION_INFO';
export const RESET_ENROLLMENT_RULES = 'RESET_ENROLLMENT_RULES';
export const HARD_RESET_ENROLLMENT_RULES = 'HARD_RESET_ENROLLMENT_RULES';
export const COMPLETED_EMPLOYER_CERTIFICATION = 'COMPLETED_EMPLOYER_CERTIFICATION';

export const SAVE_EMPLOYER_FORM = 'SAVE_EMPLOYER_FORM';
export const SAVE_ELIGIBILITY_FORM = 'SAVE_ELIGIBILITY_FORM';
export const SAVE_PRODUCER_FORM = 'SAVE_PRODUCER_FORM';

export const REMOVE_ADDITIONAL_CONTACT = 'REMOVE_ADDITIONAL_CONTACT';
export const ADD_ADDITIONAL_CONTACTS_RL = 'ADD_ADDITIONAL_CONTACTS_RL';
export const REMOVE_PRODUCER = 'REMOVE_PRODUCER';
export const REMOVE_GENERAL_AGENT = 'REMOVE_GENERAL_AGENT';
export const SET_GENERAL_AGENTS = 'SET_GENERAL_AGENTS';
export const SET_PRODUCERS = 'SET_PRODUCERS';
export const SET_COMMISSION_PAYABLE_FIELDS = 'SET_COMMISSION_PAYABLE_FIELDS';

export const TOGGLE_GROUP_NOT_ELIGIBLE = 'TOGGLE_GROUP_NOT_ELIGIBLE';
export const RESET_EMPLOYER_ATTESTATION = 'RESET_EMPLOYER_ATTESTATION';
export const UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION = 'UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION';

export const ADD_RENEWAL_NEW_EMPLOYEE = 'ADD_RENEWAL_NEW_EMPLOYEE';

export const REQUEST_FINALIZE_CASE_SUMMARY = 'REQUEST_FINALIZE_CASE_SUMMARY';
export const RECEIVE_FINALIZE_CASE_SUMMARY = 'RECEIVE_FINALIZE_CASE_SUMMARY';
export const ERROR_RECEIVE_FINALIZE_CASE_SUMMARY = 'ERROR_RECEIVE_FINALIZE_CASE_SUMMARY';
export const SET_NICE_CASE_SUMMARY = 'SET_NICE_CASE_SUMMARY';
export const SET_RENEWAL_NICE_CASE_SUMMARY= 'SET_RENEWAL_NICE_CASE_SUMMARY';

export const REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY = 'REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY';
export const RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY = 'RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY';
export const ERROR_RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY = 'ERROR_RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY';


export const RESET_FINALIZE_FLAG = 'RESET_FINALIZE_FLAG'

export const UPDATE_HWPAUSED_STATUS = 'UPDATE_HWPAUSED_STATUS';
export const UPDATE_HWRESUME_STATUS = 'UPDATE_HWRESUME_STATUS';
export const UPDATE_EMPLOYER_TIN = 'UPDATE_EMPLOYER_TIN';
export const UPDATE_GROUP_LEGAL_NAME = 'UPDATE_GROUP_LEGAL_NAME';

export const NUM_ELIG_EMP_CHANGED = 'NUM_ELIG_EMP_CHANGED';
export const SET_NUM_ELIGIBLE_EMPLOYEES = 'SET_NUM_ELIGIBLE_EMPLOYEES';

export const UPDATE_MEDICAL_EMPLOYER_CONTRIBUTION = 'UPDATE_MEDICAL_EMPLOYER_CONTRIBUTION';
export const OVERRIDE_VALIDATIONS = 'OVERRIDE_VALIDATIONS';
//////////// Additional Required Info actions //////////////
export const PUSH_TO_PENDS_ARRAY = 'PUSH_TO_PENDS_ARRAY';
export const EDIT_PENDS_ARRAY = 'EDIT_PENDS_ARRAY';
export const UPDATE_PEND = 'UPDATE_PEND';
export const UPLOAD_ADDITIONAL_INFO = 'UPLOAD_ADDITIONAL_INFO';
export const UPLOAD_ADDITIONAL_INFO_ERROR = 'UPLOAD_ADDITIONAL_INFO_ERROR';
export const REMOVE_UPLOAD_ADDITIONAL_INFO = 'REMOVE_UPLOAD_ADDITIONAL_INFO';
export const REMOVE_UPLOAD_ADDITIONAL_INFO_ERROR = 'REMOVE_UPLOAD_ADDITIONAL_INFO_ERROR';
export const IS_UPLOADING_ADDITIONAL_INFO = 'IS_UPLOADING_ADDITIONAL_INFO';
export const DONE_UPLOADING_ADDITIONAL_INFO = 'DONE_UPLOADING_ADDITIONAL_INFO';
export const IS_UPLOADING_ADDITIONAL_INFO_EDMS = 'IS_UPLOADING_ADDITIONAL_INFO_EDMS';
export const DONE_UPLOADING_ADDITIONAL_INFO_EDMS = 'DONE_UPLOADING_ADDITIONAL_INFO_EDMS';
export const ERROR_UPLOADING_ADDITIONAL_INFO_EDMS = 'ERROR_UPLOADING_ADDITIONAL_INFO_EDMS';
export const UPLOADED_ADDITIONAL_INFO_EDMS = 'UPLOADED_ADDITIONAL_INFO_EDMS';
export const PUSH_TO_EMAILS_ARRAY = 'PUSH_TO_EMAILS_ARRAY';
export const SET_REQUESTED_ASSISTANCE = 'SET_REQUESTED_ASSISTANCE';
export const SET_PEND_TAB_INDEX = 'SET_PEND_TAB_INDEX';

export const REQUEST_INTERNAL_PENDMGR_DATA = 'REQUEST_INTERNAL_PENDMGR_DATA';
export const RECEIVE__INTERNAL_PENDMGR_DATA = 'RECEIVE_INTERNAL__PENDMGR_DATA';
export const ERROR_RECEIVE_INTERNAL__PENDMGR_DATA = 'ERROR_RECEIVE_INTERNAL__PENDMGR_DATA';
export const PEND_MANAGERS_DATA = 'PEND_MANAGERS_DATA';

//////////// Employee Census Actions //////////////
export const FETCH_MAPPED_PDF = 'FETCH_MAPPED_PDF';
export const RECIEVED_MAPPED_PDF = 'RECIEVED_MAPPED_PDF';
export const ERROR_MAPPING_PDF = 'ERROR_MAPPING_PDF';

//////////// Plan Configs actions //////////////

export const REQUEST_MARKET_LIST = 'REQUEST_MARKET_LIST';
export const RECEIVE_MARKET_LIST = 'RECEIVE_MARKET_LIST';
export const ERROR_MARKET_LIST = 'ERROR_MARKET_LIST';

export const REQUEST_ZIPS_LIST = 'REQUEST_ZIPS_LIST';
export const RECEIVE_ZIPS_LIST = 'RECEIVE_ZIPS_LIST';
export const ERROR_ZIPS_LIST = 'ERROR_ZIPS_LIST';
export const ZIP_CONFIG_REMOVED = 'ZIP_CONFIG_REMOVED';

export const REQUEST_CONFIG_UPDATE = 'REQUEST_CONFIG_UPDATE';
export const MARKET_CONFIG_UPDATED = 'MARKET_CONFIG_UPDATED';
export const ZIP_CONFIG_UPDATED = 'ZIP_CONFIG_UPDATED';
export const ZIP_CONFIG_ADDED = 'ZIP_CONFIG_ADDED';
export const ERROR_CONFIG_UPDATE = 'ERROR_CONFIG_UPDATE';
export const FAKE_COUNTER = 'FAKE_COUNTER';
export const SEARCH_IN_MARKET = 'SEARCH_IN_MARKET';
export const SEARCH_IN_ZIP = 'SEARCH_IN_ZIP';

//////////// Specialty Actions  //////////////

export const SET_SPECIALTY_CRITERIA = 'SET_SPECIALTY_CRITERIA';
export const SET_ACTIVE_TAB_KEY = 'SET_ACTIVE_TAB_KEY';
export const SET_CENSUS_STATUS = 'SET_CENSUS_STATUS';
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS';
export const SET_QUOTE_CRITERIA = 'SET_QUOTE_CRITERIA';
export const SET_OI_SA_BIT = 'SET_OI_SA_BIT'
export const SET_CENSUS_SALARY_RATING_METHOD = 'SET_CENSUS_SALARY_RATING_METHOD';

///////////// Business Rules  ///////////////

export const REQUEST_ENROLLMENT_RULES = 'REQUEST_ENROLLMENT_RULES';
export const RECEIVE_ENROLLMENT_RULES = 'RECEIVE_ENROLLMENT_RULES';
export const ERROR_ENROLLMENT_RULES = 'ERROR_ENROLLMENT_RULES';

export const REQUEST_RATE_ADJUSTMENT_RULES = 'REQUEST_RATE_ADJUSTMENT_RULES';
export const RECEIVE_RATE_ADJUSTMENT_RULES = 'RECEIVE_RATE_ADJUSTMENT_RULES';
export const ERROR_RATE_ADJUSTMENT_RULES = 'ERROR_RATE_ADJUSTMENT_RULES';

/** Below Action types Related to Renewals */

// Reset Review, Decide, Shopping collections
export const RESET_RENEWALS = 'RESET_RENEWALS';
export const UPDTE_FAILED_API_REQUEST = 'UPDTE_FAILED_API_REQUEST';

// stepper access for renewals
export const UPDATE_STEPPER_ACCESS = 'UPDATE_STEPPER_ACCESS';
export const INITIAL_STEPPER_ACCESS = 'UPDATE_INITIAL_STEPPER_ACCESS';

export const CURRENT_CASE_STATUS = 'CURRENT_CASE_STATUS';
export const UPDATE_CASE_STATUS = 'UPDATE_CASE_STATUS';
export const ERR_CASE_STATUS = 'ERR_CASE_STATUS';

// total case for perticular months    //
export const REQUEST_MONTH_RENEWALS = 'REQUEST_MONTH_RENEWALS';
export const RECEIVE_MONTH_RENEWALS = 'RECEIVE_MONTH_RENEWALS';
export const ERR_MONTH_RENEWALS = 'ERR_MONTH_RENEWALS';

/** SAVING LAST MODIFIED PAGE */
export const REQUEST_LAST_MODIFIED = 'REQUEST_LAST_MODIFIED';
export const RECEIVE_LAST_MODIFIED = 'RECEIVE_LAST_MODIFIED';
export const ERR_LAST_MODIFIED = 'ERR_LAST_MODIFIED';

/////////Renewal Actions///////////
export const UPDATE_TRANSACTION_TYPE = 'UPDATE_TRANSACTION_TYPE';
export const SET_MEDICAL_CENSUS_SUMMARY = 'SET_MEDICAL_CENSUS_SUMMARY';
export const REQUEST_UPCOMING_RENEWALS = 'REQUEST_UPCOMING_RENEWALS';
export const RECEIVE_UPCOMING_RENEWALS = 'RECEIVE_UPCOMING_RENEWALS';
export const ERR_UPCOMING_RENEWALS = 'ERR_UPCOMING_RENEWALS';
export const GET_SALESREP_DATA = 'GET_SALESREP_DATA';
export const GET_SALES_REP_FROM_MEMBERGROUPID = 'GET_SALES_REP_FROM_MEMBERGROUPID';
export const ADD_SALES_REP_FROM_MEMBERGROUPID = 'ADD_SALES_REP_FROM_MEMBERGROUPID';
export const RECEIVE_SALESREP_DETAILS = 'RECEIVE_SALESREP_DETAILS';
export const ADD_OWNER_ASSIGNMENT_DETAILS = 'ADD_OWNER_ASSIGNMENT_DETAILS';
export const ADD_SALES_REP_DETAILS = 'ADD_SALES_REP_DETAILS';
export const ADD_GA_SALES_REP_DETAILS = 'ADD_GA_SALES_REP_DETAILS';
export const ADD_GENERAL_AGENT_DETAILS = 'ADD_GENERAL_AGENT_DETAILS';
export const UPDATE_SALES_REP_DATA = 'UPDATE_SALES_REP_DATA';
export const REMOVE_OWNER_ASSIGNMENT = 'REMOVE_OWNER_ASSIGNMENT';
export const ADD_AGENCY_DETAILS = 'ADD_AGENCY_DETAILS';
export const SET_CASE_REINSTATE_FLAG_RENEWALS = 'SET_CASE_REINSTATE_FLAG_RENEWALS';
export const SET_CASE_CANCEL_FLAG_RENEWALS = 'SET_CASE_CANCEL_FLAG_RENEWALS';
export const RESET_OWNER_ASSIGNMENT_DETAILS = 'RESET_OWNER_ASSIGNMENT_DETAILS';
export const SET_REFRESH_APIS_LOADER = 'SET_REFRESH_APIS_LOADER';
export const UPDATE_OWNER_ASSIGNMENT_RENEWALS_PAGE = 'UPDATE_OWNER_ASSIGNMENT_RENEWALS_PAGE';
export const UPDATE_LICENSE_CHECK = 'UPDATE_LICENSE_CHECK';

export const UPDATE_SALES_REP_RENEWALS_REVIEW = 'UPDATE_SALES_REP_RENEWALS_REVIEW';
export const UPDATE_SALES_REP_RENEWALS_SHOPPING = 'UPDATE_SALES_REP_RENEWALS_SHOPPING';
export const UPDATE_SALES_REP_RENEWALS_DECIDE = 'UPDATE_SALES_REP_RENEWALS_DECIDE';
export const UPDATE_SALES_REP_RENEWALS_ENROLLMENT = 'UPDATE_SALES_REP_RENEWALS_ENROLLMENT';
export const UPDATE_RENEWAL_MEDICAL_ALLOWED = 'UPDATE_RENEWAL_MEDICAL_ALLOWED';
export const UPDATE_RENEWAL_PACKAGE_LABEL = 'UPDATE_RENEWAL_PACKAGE_LABEL';

///////// Get Renewal based on days 30 (or) 60 (or) 90 ///////////
export const REQUEST_SELECTED_RENEWALS = 'REQUEST_SELECTED_RENEWALS';
export const RECEIVE_SELECTED_RENEWALS = 'RECEIVE_SELECTED_RENEWALS';
export const ERR_SELECTED_RENEWALS = 'ERR_SELECTED_RENEWALS';

export const REQUEST_PAGE_TYPE = 'REQUEST_PAGE_TYPE';
export const REQUEST_MEMBER_ID = 'REQUEST_MEMBER_ID';
// Review Page Details Actions////
export const REQUEST_REVIEW_DETAILS = 'REQUEST_REVIEW_DETAILS';
export const RECEIVE_REVIEW_DETAILS = 'RECEIVE_REVIEW_DETAILS';
export const ERR_REVIEW_DETAILS = 'ERR_REVIEW_DETAILS';
export const UPDATE_PLAN_ISCHECKED_VALUE = 'UPDATE_PLAN_ISCHECKED_VALUE';
export const RESET_FLOW_DVL_REVIEW_PAGE = 'RESET_FLOW_DVL_REVIEW_PAGE';

//MMR shopping
export const SET_MMR_MEDICAL_PLANS = 'SET_MMR_MEDICAL_PLANS';
export const SET_MMR_IS_FETCHING_MEDICAL = 'SET_MMR_IS_FETCHING_MEDICAL';
export const SET_RENEWING_PLANS_MMR = 'SET_RENEWING_PLANS_MMR';
export const UPDATE_SUPPLIFEDEP_INDICATOR_MMR = 'UPDATE_SUPPLIFEDEP_INDICATOR_MMR';
export const REMOVE_RENEWING_PLANS_MMR = 'REMOVE_RENEWING_PLANS_MMR';
export const UPDATE_SELECTED_PLAN_MMR = 'UPDATE_SELECTED_PLAN_MMR'
export const REQUEST_MMR_ADDITIONAL_RATES = 'REQUEST_MMR_ADDITIONAL_RATES'
export const RECEIVE_MMR_ADDITIONAL_RATES = 'RECEIVE_MMR_ADDITIONAL_RATES'
export const RECEIVE_MMR_OVERRIDE_RATES = 'RECEIVE_MMR_OVERRIDE_RATES'
export const ERROR_MMR_ADDITIONAL_RATES = 'ERROR_MMR_ADDITIONAL_RATES'
export const REQUEST_MMR_BENEFIT_RIDER_RATES = 'REQUEST_MMR_BENEFIT_RIDER_RATES'
export const RECEIVE_MMR_BENEFIT_RIDER_RATES = 'RECEIVE_MMR_BENEFIT_RIDER_RATES'

// Review Page update details in DB
export const UPDATE_REVIEW_DETAILS = 'UPDATE_REVIEW_DETAILS';
export const UPDATED_REVIEW_DETAILS = 'UPDATED_REVIEW_DETAILS';
export const ERR_UPDATE_REVIEW_DETAILS = 'ERR_UPDATE_REVIEW_DETAILS';

// Show More Less count for Review Page
export const UPDATE_SHOW_MORE_LESS = 'UPDATE_SHOW_MORE_LESS';
export const UPDATE_SHOW_MORE_LESS_MEDICAL = 'UPDATE_SHOW_MORE_LESS_MEDICAL';
export const UPDATE_SHOW_MORE_LESS_DENTAL = 'UPDATE_SHOW_MORE_LESS_DENTAL';
export const UPDATE_SHOW_MORE_LESS_VISION = 'UPDATE_SHOW_MORE_LESS_VISION';
export const UPDATE_SHOW_MORE_LESS_LIFE = 'UPDATE_SHOW_MORE_LESS_LIFE';
export const UPDATE_SHOW_MORE_LESS_DEP_LIFE = 'UPDATE_SHOW_MORE_LESS_DEP_LIFE';
export const UPDATE_SHOW_MORE_LESS_STD= 'UPDATE_SHOW_MORE_LESS_STD';
export const UPDATE_SHOW_MORE_LESS_LTD = 'UPDATE_SHOW_MORE_LESS_LTD';
export const UPDATE_SHOW_MORE_LESS_SUPP_LIFE = 'UPDATE_SHOW_MORE_LESS_SUPP_LIFE';
export const UPDATE_SHOW_MORE_LESS_SUPP_DEP_LIFE = 'UPDATE_SHOW_MORE_LESS_SUPP_DEP_LIFE';

// renewal plan codes for SBC popup //
export const REQUEST_RENEWAL_PLAN_CODE = 'REQUEST_RENEWAL_PLAN_CODE';
export const RECEIVE_RENEWAL_PLAN_CODE = 'RECEIVE_RENEWAL_PLAN_CODE';
export const ERR_RENEWAL_PLAN_CODE = 'ERR_RENEWAL_PLAN_CODE';

export const PLAN_CHANGE_FIELD = 'PLAN_CHANGE_FIELD';
export const ERROR_PLAN_CHANGE_FIELD = 'ERROR_PLAN_CHANGE_FIELD';

// Logging In
export const LOGGING_IN = 'LOGGING_IN';
export const SET_TABLE_PREFERENCE = 'SET_TABLE_PREFERENCE'
export const LOGGED_IN = 'LOGGED_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ERR_LOGGING_IN = 'ERR_LOGGING_IN';
export const UPDATED_USER_CASES = 'UPDATED_USER_CASES';
export const LOGOUT_RENEWAL = 'LOGOUT_RENEWAL';
export const RESET_RENEWAL_FLAG = 'RESET_RENEWAL_FLAG';
export const RESET_REVIEW_FLAG = 'RESET_REVIEW_FLAG';
export const RESET_DECIDE_FLAG = 'RESET_DECIDE_FLAG';

export const ENABLE_RENEWAL_STEPPER_FLAG = 'ENABLE_RENEWAL_STEPPER_FLAG';
//renewal Dashboad and home actions
export const STORE_NAME = 'STORE_NAME';
export const GET_ROLE = 'GET_ROLE';
export const STORE_EMAIL = 'STORE_EMAIL';
export const SET_MEMBERGROUP_ID = 'SET_MEMBERGROUP_ID';
export const STORE_ROLE_NAME = 'STORE_ROLE_NAME';
export const STORE_DELEGATE_INFO = 'STORE_DELEGATE_INFO';
export const STORE_ROLES_PERMISSON_NAMES = 'STORE_ROLES_PERMISSON_NAMES';
export const GLOBAL_SEARCH_FLAG = 'GLOBAL_SEARCH_FLAG';
export const RENEWAL_SEARCH_CASE = 'RENEWAL_SEARCH_CASE';
export const SET_RENEW_AS_IS = 'SET_RENEW_AS_IS';
export const GET_ACCESS_ROLES = 'GET_ACCESS_ROLES';
export const ERR_GET_ACCESS_ROLES = 'ERR_GET_ACCESS_ROLES';
export const GET_ACCESS_ROLES_MMR_RN = 'GET_ACCESS_ROLES_MMR_RN';
export const ERR_GET_ACCESS_ROLES_MMR_RN = 'ERR_GET_ACCESS_ROLES_MMR_RN';

// User Preferences
export const TOGGLE_DISPLAY_GOLD_WARNING = 'TOGGLE_DISPLAY_GOLD_WARNING';

// PCIS id
export const GET_PCISID = 'GET_PCISID';

export const PAYMENT_ACTIONS = {
    UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
    UPDATE_BILLING_METHOD: 'UPDATE_BILLING_METHOD',
    UPLOAD_FILE: 'UPLOAD_FILE',
    VIEW_UPLOADED_FILE: 'VIEW_UPLOADED_FILE',
    UPDATE_SELECTED_CONTACT: 'UPDATE_SELECTED_CONTACT',
    ADD_CONTACT: 'ADD_CONTACT',
    UPLOAD_PAYMENT_CONFIRMATION: 'UPLOAD_PAYMENT_CONFIRMATION',
    UPDATE_BINDER: 'UPDATE_BINDER',
    UPDATE_EBINDER: 'UPDATE_EBINDER',
    UPDATE_DEBIT: 'UPDATE_DEBIT',
    CLEAR_PAYMENT: 'CLEAR_PAYMENT',
    CLEAR_BILLING: 'CLEAR_BILLING',
    UPDATE_ELECTRONIC: 'UPDATE_ELECTRONIC',
    UPDATE_PAPER: 'UPDATE_PAPER',
    REMOVE_UPLOADED_BINDER: 'REMOVE_UPLOADED_BINDER',
    SET_EXTERNAL_UPLOAD_PAYMENT: 'SET_EXTERNAL_UPLOAD_PAYMENT',
};

// Debit Authorization Form //

export const DEBIT_ACTIONS = {
    DEBIT_INPUT: 'DEBIT_INPUT',
    DEBIT_UPDATED: 'DEBIT_UPDATED',
    DEBIT_UPDATE_ERROR: 'DEBIT_UPDATE_ERROR',
    DEBIT_RETRIEVED: 'DEBIT_RETRIEVED',
    DEBIT_RESET: 'DEBIT_RESET',
    DEBIT_CANCEL: 'DEBIT_CANCEL',
    DEBIT_UPDATE_OWNER_ID: 'DEBIT_UPDATE_OWNER_ID'
};

//Premium calculation
export const ADD_MEDICAL_PLAN = 'ADD_MEDICAL_PLAN';
export const REMOVE_ALL_MEDICAL_PLANS = 'REMOVE_ALL_MEDICAL_PLANS';
export const ADD_ALL_MEDICAL_PLANS = 'ADD_ALL_MEDICAL_PLANS';
export const UPDATE_KEEP_MEDICAL_VALUE = 'UPDATE_KEEP_MEDICAL_VALUE';

export const ADD_ALL_DENTAL_PLANS = 'ADD_ALL_DENTAL_PLAN';
export const REMOVE_ALL_DENTAL_PLANS = 'REMOVE_ALL_DENTAL_PLANS';

export const ADD_ALL_VISION_PLANS = 'ADD_ALL_VISION_PLAN';
export const REMOVE_ALL_VISION_PLANS = 'REMOVE_ALL_VISION_PLANS';

export const ADD_ALL_LIFE_PLANS = 'ADD_ALL_LIFE_PLAN';
export const REMOVE_ALL_LIFE_PLANS = 'REMOVE_ALL_LIFE_PLANS';

export const ADD_ALL_SUPP_LIFE_PLANS = 'ADD_ALL_SUPP_LIFE_PLANS';
export const REMOVE_ALL_SUPP_LIFE_PLANS = 'REMOVE_ALL_SUPP_LIFE_PLANS';

export const ADD_ALL_DEPLIFE_PLANS = 'ADD_ALL_DEPLIFE_PLAN';
export const REMOVE_ALL_DEPLIFE_PLANS = 'REMOVE_ALL_DEPLIFE_PLANS';

export const ADD_ALL_STD_PLANS = 'ADD_ALL_STD_PLAN';
export const REMOVE_ALL_STD_PLANS = 'REMOVE_ALL_STD_PLANS';

export const ADD_ALL_LTD_PLANS = 'ADD_ALL_LTD_PLAN';
export const REMOVE_ALL_LTD_PLANS = 'REMOVE_ALL_LTD_PLANS';

export const ADD_SHOPPING_PLANS_TO_REVIEW = 'ADD_SHOPPING_PLANS_TO_REVIEW';
export const RESET_RENEWAL_FLOW = 'RESET_RENEWAL_FLOW';

//MDV contribution
export const UPDATE_MDV_ENROLL = 'UPDATE_MDV_ENROLL';
export const UPDATE_MDV_CONTRIBUTION = 'UPDATE_MDV_CONTRIBUTION';
export const UPDATE_MDV_CONTRIBUTION_DECIDE = 'UPDATE_MDV_CONTRIBUTION_DECIDE';

//Update Employer contribution///
export const UPDATE_RENEWAL_CONTRIBUTION = 'UPDATE_RENEWAL_CONTRIBUTION';
export const UPDATE_EMPLOYER_CONTRIBUTION = 'UPDATE_EMPLOYER_CONTRIBUTION';
export const UPDATE_ENROLL_APP_EMP_CONTRIBUTION = 'UPDATE_ENROLL_APP_EMP_CONTRIBUTION';
export const CHANGE_DEPENDENT_PERCENT = 'CHANGE_DEPENDENT_PERCENT';
export const ADD_ALTERNATE_PLANS_TO_RENEW = 'ADD_ALTERNATE_PLANS_TO_RENEW';
export const ADD_MEDICAL_PLANS_TO_RENEW = 'ADD_MEDICAL_PLANS_TO_RENEW';
export const ADD_DENTAL_PLANS_TO_RENEW = 'ADD_DENTAL_PLANS_TO_RENEW';
export const ADD_LIFE_PLANS_TO_RENEW = 'ADD_LIFE_PLANS_TO_RENEW';
export const ADD_RENEWAL_CLASS_IN_REVIEW = 'ADD_RENEWAL_CLASS_IN_REVIEW';
export const ADD_DEPENDENT_LIFE_PLANS_TO_RENEW = 'ADD_DEPENDENT_LIFE_PLANS_TO_RENEW';
export const ADD_VISION_PLANS_TO_RENEW = 'ADD_VISION_PLANS_TO_RENEW';
export const ADD_STD_PLANS_TO_RENEW = 'ADD_STD_PLANS_TO_RENEW';
export const ADD_LTD_PLANS_TO_RENEW = 'ADD_LTD_PLANS_TO_RENEW';
export const ADD_SUPP_LIFE_PLANS_TO_RENEW = 'ADD_SUPP_LIFE_PLANS_TO_RENEW';
export const UPDATE_GROUP_TIER_STRUCTURE_ID = 'UPDATE_GROUP_TIER_STRUCTURE_ID';


//Update Number of Selected Renewing Plans Count////
export const UPDATE_NUM_OF_SELECTED_RENEW_PLANS = 'UPDATE_NUM_OF_SELECTED_RENEW_PLANS';
export const UPDATED_ALLOWED_PLANS_COUNT = 'UPDATED_ALLOWED_PLANS_COUNT';

//Decide Page Update Premium Toggle Value//
export const UPDATE_PREMIUM_TOGGLE_VALUE = 'UPDATE_PREMIUM_TOGGLE_VALUE';
export const UPDATE_PREMIUM_TOGGLE_VALUE_REVIEW = 'UPDATE_PREMIUM_TOGGLE_VALUE_REVIEW';

// Decide Page Details Actions////
export const UPDATED_DECIDE_DETAILS = 'UPDATED_DECIDE_DETAILS';
export const ERR_UPDATE_DECIDE_DETAILS = 'ERR_UPDATE_DECIDE_DETAILS';
export const REQUEST_DECIDE_DETAILS = 'REQUEST_DECIDE_DETAILS';
export const RECEIVE_DECIDE_DETAILS = 'RECEIVE_DECIDE_DETAILS';
export const ERR_DECIDE_DETAILS = 'ERR_DECIDE_DETAILS';
export const REMOVE_DECIDE_PLAN = 'REMOVE_DECIDE_PLAN';
export const REMOVE_MODAL_PREFERENCE = 'REMOVE_MODAL_PREFERENCE';
export const UPDATE_ENROLLED_PLANS = 'UPDATE_ENROLLED_PLANS';
export const UPDATE_ENROLLED_PLANS_FROM_DECIDE = 'UPDATE_ENROLLED_PLANS_FROM_DECIDE';
export const ADD_ENROLLED_PLANS = 'ADD_ENROLLED_PLANS';
export const ADD_MAX_PLAN_SELECTION_LIMIT = 'ADD_MAX_PLAN_SELECTION_LIMIT';
export const UPDATE_ENROLLED_PLANS_SELECTED_COUNT = 'UPDATE_ENROLLED_PLANS_SELECTED_COUNT';
export const UPDATE_ENROLL_ADDITIONAL_PLANS_SELCETED_COUNT = 'UPDATE_ENROLL_ADDITIONAL_PLANS_SELCETED_COUNT';
export const IS_ERROR_ENROLL_PLANS_COUNT = 'IS_ERROR_ENROLL_PLANS_COUNT';
export const DAYS_LEFT_FOR_RENEWAL = 'DAYS_LEFT_FOR_RENEWAL';
export const UPDATE_BEEN_TO_RENEWALSHOPPING = 'UPDATE_BEEN_TO_RENEWALSHOPPING';
export const ADD_SHOPPING_PLANS_TO_DECIDE = 'ADD_SHOPPING_PLANS_TO_DECIDE';
export const UPDATE_SELECTED_PLAN_DECIDE = 'UPDATE_SELECTED_PLAN_DECIDE';
export const UPDATE_PLAN_SELECTION_DECIDE = 'UPDATE_PLAN_SELECTION_DECIDE';
export const SET_ENROLLED_PLANS_DECIDE = 'SET_ENROLLED_PLANS_DECIDE';
export const UPDATE_NO_OF_ENROLLED_PLANS_DECIDE = 'UPDATE_NO_OF_ENROLLED_PLANS_DECIDE';
export const UPDATE_DECIDE_SELECTED_PLANS_MMR = 'UPDATE_DECIDE_SELECTED_PLANS_MMR';
export const SET_PRODUCT_TYPE_LIST = 'SET_PRODUCT_TYPE_LIST';
export const SET_ALTERNATE_PLANS_IND = 'SET_ALTERNATE_PLANS_IND';
export const CLEAR_ENROLLED_PLANS = 'CLEAR_ENROLLED_PLANS'
export const REMOVE_ENROLLED_PLANS_FROM_DECIDE = 'REMOVE_ENROLLED_PLANS_FROM_DECIDE'
export const REMOVE_ENROLLED_PLANS_FROM_REVIEW = 'REMOVE_ENROLLED_PLANS_FROM_REVIEW'

// Renewal Shopping Actions //
// export const REQUEST_REN_ADDITIONAL_PLANS = 'REQUEST_REN_ADDITIONAL_PLANS';
// export const RECEIVE_REN_ADDITIONAL_PLANS = 'RECEIVE_REN_ADDITIONAL_PLANS';
// export const ERR_REN_ADDITIONAL_PLANS = 'ERR_REN_ADDITIONAL_PLANS';
// export const REQUEST_REN_ADDITIONAL_RATES = 'REQUEST_REN_ADDITIONAL_RATES';
// export const RECEIVE_REN_ADDITIONAL_RATES = 'RECEIVE_REN_ADDITIONAL_RATES';
// export const ERR_REN_ADDITIONAL_RATES = 'ERR_REN_ADDITIONAL_RATES';
export const SET_REN_SHOPPING = 'SET_REN_SHOPPING';
export const SET_MMR_INDICATOR = 'SET_MMR_INDICATOR'
export const SET_ZIP_AND_STATE = 'SET_ZIP_AND_STATE';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SHOPPING_REQUEST = 'SHOPPING_REQUEST';
export const RESET_SHOPPING_DATA = 'RESET_SHOPPING_DATA';
export const SET_SHOPPING_RATE_BAND = 'SET_SHOPPING_RATE_BAND';
export const ACTIVATE_SHOPPING_TAB = 'ACTIVATE_SHOPPING_TAB';
export const SET_PREFERRED_PLANS_DISPLAY = 'SET_PREFERRED_PLANS_DISPLAY';
export const SET_PREFERRED_PLANS_DISPLAY_TO_DEFAULT = 'SET_PREFERRED_PLANS_DISPLAY_TO_DEFAULT';
export const UPDATE_RENEWAL_SHOPPING_ELIGIBILITY_COUNTS = 'UPDATE_RENEWAL_SHOPPING_ELIGIBILITY_COUNTS';
    // Renewal Enrollment Actions //
export const GET_ENROLLMENT_DETAILS = 'GET_ENROLLMENT_DETAILS';
export const REMOVE_ENROLLMENT_PLAN = 'REMOVE_ENROLLMENT_PLAN';
export const UPDATE_ENROLLEMENT_ACC_DECIDE = 'UPDATE_ENROLLEMENT_ACC_DECIDE';
export const POPULATE_EMPLOYER_INFO = 'POPULATE_EMPLOYER_INFO';
export const UPDATE_RENEWAL_COMPANY_PROFILE = 'UPDATE_RENEWAL_COMPANY_PROFILE';
export const UPDATE_MDV_CONTRIBUTION_ENROLL = 'UPDATE_MDV_CONTRIBUTION_ENROLL';
export const UPDATE_ENROLLMENT_CONTRIBUTION = 'UPDATE_ENROLLMENT_CONTRIBUTION';
export const REMOVE_ENROLLMENT_PLAN_SHOPPING = 'REMOVE_ENROLLMENT_PLAN_SHOPPING';
export const UPDATE_RENEWAL_EFFECTIVE_DATE = 'UPDATE_RENEWAL_EFFECTIVE_DATE';
export const UPDATE_RENEWALS_SELECTED_PRODUCTS = 'UPDATE_RENEWALS_SELECTED_PRODUCTS';
export const UPDATE_USER_CARDS_SELECTED_PRODUCTS = 'UPDATE_USER_CARDS_SELECTED_PRODUCTS';
export const UPDATE_RENEWAL_EMPLOYER_INFO = 'UPDATE_RENEWAL_EMPLOYER_INFO';
export const ADD_SELECTED_PRODUCTS_TO_RENEWAL_ENROLLMENT = 'ADD_SELECTED_PRODUCTS_TO_RENEWAL_ENROLLMENT';
export const ADD_NEW_PRODUCTS_TO_RENEWAL_ENROLLMENT = 'ADD_NEW_PRODUCTS_TO_RENEWAL_ENROLLMENT';
export const INITIALIZE_ELIGIBILITY_INTO_EMPLOYER_INFO = 'INITIALIZE_ELIGIBILITY_INTO_EMPLOYER_INFO';
export const RESET_RENEWAL_ENROLLMENT = 'RESET_RENEWAL_ENROLLMENT';
export const UPDATE_RATES_API_SUCCESS = 'UPDATE_RATES_API_SUCCESS';
export const ERROR_ZIP_MAPPINGS = 'ERROR_ZIP_MAPPINGS';
export const UPDATE_PREMIUM_TOGGLE_VALUE_ENROLLMENT = 'UPDATE_PREMIUM_TOGGLE_VALUE_ENROLLMENT';
export const SET_PLAN_INSTALL_PLATFORM = 'SET_PLAN_INSTALL_PLATFORM';

export const ENROLLMENT_MODIFIED_PAGE_SUCCESS = 'ENROLLMENT_MODIFIED_PAGE_SUCCESS';
export const UPDATE_NUM_OF_ADDITIONAL_CONTACTS = 'UPDATE_NUM_OF_ADDITIONAL_CONTACTS';
export const CONTAINS_NEWLY_ADDED_CONTACTS = 'CONTAINS_NEWLY_ADDED_CONTACTS';

export const REVIEW_INIT_IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE = 'REVIEW_INIT_IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE';
export const REVIEW_INIT_UPDATE_BEEN_TO_FINALIZE = 'REVIEW_INIT_UPDATE_BEEN_TO_FINALIZE';
export const IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE = 'IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE';
export const UPDATE_BEEN_TO_FINALIZE = 'UPDATE_BEEN_TO_FINALIZE';
export const UPDATE_EMPLOYEES_ENROLL_STATUS = 'UPDATE_EMPLOYEES_ENROLL_STATUS';
export const SAVE_ENROLLMENT_RATES_RENEWALS = 'SAVE_ENROLLMENT_RATES_RENEWALS';

export const UPDATE_ENROLLED_PLANS_RENEWALS = 'UPDATE_ENROLLED_PLANS_RENEWALS';
export const GET_ENROLLED_PLANS_RENEWALS = 'GET_ENROLLED_PLANS_RENEWALS';

export const UPDATE_SELECTED_PRODUCTS_RENEWALS = 'UPDATE_SELECTED_PRODUCTS_RENEWALS';

export const REQUEST_ENROLLMENT_FORM_ELIGIBILTY_ONLY = 'REQUEST_ENROLLMENT_FORM_ELIGIBILTY_ONLY';
export const ERROR_ENROLLMENT_FORM_ELIGIBILTY_ONLY = 'ERROR_ENROLLMENT_FORM_ELIGIBILTY_ONLY';

export const SET_EMPLOYEE_SAVE_LOADER = 'SET_EMPLOYEE_SAVE_LOADER';
export const SET_EMPLOYEE_SAVE_RETURN_LOADER = 'SET_EMPLOYEE_SAVE_RETURN_LOADER;';
export const SET_SSN_CHECK_POPUP = 'SET_SSN_CHECK_POPUP';

export const SET_EMPLOYERCERTIFICATION_SAVE_LOADER = 'SET_EMPLOYERCERTIFICATION_SAVE_LOADER';
export const SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER = 'SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER';

export const UPDATE_CASE_SUBMITTED = 'UPDATE_CASE_SUBMITTED';

export const LATEST_RENEWAL_CASE_DATA = 'LATEST_RENEWAL_CASE_DATA';

// Permissions Actions //
export const REQUEST_PERMISSIONS = 'REQUEST_PERMISSIONS';
export const RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS';
export const ERROR_PERMISSIONS = 'ERROR_PERMISSIONS';

// New Businesss Actions //
export const REQUEST_NEW_BUSINESS_CASE = 'REQUEST_NEW_BUSINESS_CASE';
export const RECEIVE_NEW_BUSINESS_CASE = 'RECEIVE_NEW_BUSINESS_CASE';
export const RECEIVE_NEW_BUSINESS_CASE_RANGE = 'RECEIVE_NEW_BUSINESS_CASE_RANGE';
export const ERROR_NEW_BUSINESS_CASE = 'ERROR_NEW_BUSINESS_CASE';
export const GET_NB_ENROLL_CASE_COUNT = 'GET_NB_ENROLL_CASE_COUNT';
export const GET_NB_ENROLL_CASE = 'GET_NB_ENROLL_CASE';
export const GET_RL_NOT_COMPLETED_CASE = 'GET_RL_NOT_COMPLETED_CASE';
export const GET_RL_NOT_COMPLETED_CASE_COUNT = 'GET_RL_NOT_COMPLETED_CASE_COUNT';

export const UPDATE_REVIEW_SHOP_PLANS = "UPDATE_REVIEW_SHOP_PLANS"
    //Package Filter
export const UPDATE_PACKAGE_LABEL = 'UPDATE_PACKAGE_LABEL';

//case Tracking
export const CASE_TRACK_UPDATING = 'CASE_TRACK_UPDATING';
export const DISABLE_SAVE_AND_EXIT = 'DISABLE_SAVE_AND_EXIT';
export const UPDATED_CASE_TRACK = 'UPDATED_CASE_TRACK';
export const CASE_TRACK_UPDATE_ERRORED = 'CASE_TRACK_UPDATE_ERRORED';
export const GET_CASE_TRACK = 'GET_CASE_TRACK';
export const REQUEST_NB_NEW_FULL_QUOTE = 'REQUEST_NB_NEW_FULL_QUOTE';
export const RECEIVE_NB_NEW_FULL_QUOTE = 'RECEIVE_NB_NEW_FULL_QUOTE';
export const REQUEST_NB_NEW_COPY_QUOTE = 'REQUEST_NB_NEW_COPY_QUOTE';
export const RECEIVE_NB_NEW_COPY_QUOTE = 'RECEIVE_NB_NEW_COPY_QUOTE';
export const REQUEST_NB_REMOVE_FULL_QUOTE = 'REQUEST_NB_REMOVE_FULL_QUOTE';
export const RECEIVE_NB_REMOVE_FULL_QUOTE = 'RECEIVE_NB_REMOVE_FULL_QUOTE';
export const RECEIVE_NB_PROPOSAL_ID = 'RECEIVE_NB_REMOVE_FULL_QUOTE';
export const REQUEST_NB_WITHDRAW_CASE = 'REQUEST_NB_WITHDRAW_CASE';
export const RECEIVE_NB_WITHDRAW_CASE = 'RECEIVE_NB_WITHDRAW_CASE';
export const REQUEST_NB_CLOSE_CASE = 'REQUEST_NB_CLOSE_CASE';
export const RECEIVE_NB_CLOSE_CASE = 'RECEIVE_NB_CLOSE_CASE';
export const REQUEST_NB_NEW_CASE = 'REQUEST_NB_NEW_CASE';
export const RECEIVE_NB_NEW_CASE = 'RECEIVE_NB_NEW_CASE';
export const TOGGLE_PAPER_CASE = 'TOGGLE_PAPER_CASE';
export const SET_ENROLLMENT_QUOTE_ID = 'SET_ENROLLMENT_QUOTE_ID';
export const SET_CURRENT_QUOTE_ID = 'SET_CURRENT_QUOTE_ID';
export const SET_UPDATED_PLANS = 'SET_UPDATED_PLANS';
export const SET_CONTINUED_FROM_QQ_CASE = 'SET_CONTINUED_FROM_QQ_CASE';
export const UPDATE_DIRECT_SALE = 'UPDATE_DIRECT_SALE';
export const UPDATE_STATE_STORE_ID = 'UPDATE_STATE_STORE_ID';
export const UPDATE_IS_IPPSBYPASSED = 'UPDATE_IS_IPPSBYPASSED';
export const SAVE_TOTAL_PROGRESS = 'SAVE_TOTAL_PROGRESS';
export const SET_MANUAL_INSTALLATION = 'SET_MANUAL_INSTALLATION';
export const UPDATE_MANUAL_FLAG = 'UPDATE_MANUAL_FLAG';
export const UPDATE_TRIPLE_PLATFORM = 'UPDATE_TRIPLE_PLATFORM';

export const SAVE_CURRENT_PAGE = 'SAVE_CURRENT_PAGE';

export const RESET_RL_CASE_TRACK = 'RESET_RL_CASE_TRACK';
// Save Renewal History Actions //
export const REQUEST_CREATE_HISTORY = 'REQUEST_CREATE_HISTORY';
export const RECEIVE_CREATE_HISTORY = 'RECEIVE_CREATE_HISTORY';
export const ERR_CREATE_HISTORY = 'ERR_CREATE_HISTORY';

// Audit History Actions //
export const REQUEST_AUDIT_HISTORY = 'REQUEST_AUDIT_HISTORY';
export const RECEIVE_AUDIT_HISTORY = 'RECEIVE_AUDIT_HISTORY';
export const ERROR_AUDIT_HISTORY = 'ERROR_AUDIT_HISTORY';
export const TOOGLE_LW_LOADING = 'TOOGLE_LW_LOADING';
export const RESET_AUDIT_HISTORY = 'RESET_AUDIT_HISTORY';
export const RESET_ACTION_HISTORY = 'RESET_ACTION_HISTORY';
export const RECEIVE_AUDIT_HISTORY_RANGE = 'RECEIVE_AUDIT_HISTORY_RANGE';
export const SAVE_AUDIT_HISTORY = "SAVE_AUDIT_HISTORY";

// Action History Actions //
export const STORE_ACTIVE_HISTORY_CASE = 'STORE_ACTIVE_HISTORY_CASE';
export const REQUEST_ACTION_HISTORY = 'REQUEST_ACTION_HISTORY';
export const RECEIVE_ACTION_HISTORY = 'RECEIVE_ACTION_HISTORY';
export const ERROR_ACTION_HISTORY = 'ERROR_ACTION_HISTORY';

////////////// Proposals //////////////

export const REQUEST_PROPOSAL = 'REQUEST_PROPOSAL';
export const RECEIVE_PROPOSAL_TRANSACTION_ID = 'RECEIVE_PROPOSAL_TRANSACTION_ID';
export const ERROR_PROPOSAL_TRANSACTION_ID = 'ERROR_PROPOSAL_TRANSACTION_ID';
export const RECEIVE_PROPOSAL = 'RECEIVE_PROPOSAL';
export const ERROR_PROPOSAL = 'ERROR_PROPOSAL';

export const REQUEST_EMAIL = 'REQUEST_EMAIL';
export const RECEIVE_EMAIL_TRANSACTION_ID = 'RECEIVE_EMAIL_TRANSACTION_ID';
export const ERROR_EMAIL_TRANSACTION_ID = 'ERROR_EMAIL_TRANSACTION_ID';
export const RECEIVE_EMAIL = 'RECEIVE_EMAIL';
export const ERROR_EMAIL = 'ERROR_EMAIL';

export const AUTO_ENROLL_IN_LIFE = 'AUTO_ENROLL_IN_LIFE';
// dependent life
export const REQUEST_DEPENDENT_LIFE_PLANS = 'REQUEST_DEPENDENT_LIFE_PLANS'
export const RECEIVE_DEPENDENT_LIFE_PLANS = 'RECEIVE_DEPENDENT_LIFE_PLANS'
export const RECEIVE_DEPENDENT_LIFE_RATES = 'RECEIVE_DEPENDENT_LIFE_RATES'
export const UPDATE_LIFE_DEP_BASIC_PREMIUM = 'UPDATE_LIFE_DEP_BASIC_PREMIUM'
export const FILTER_LIFE_DEP_PLANS = 'FILTER_LIFE_DEP_PLANS'
export const CHANGE_FILTER_LIFE_DEP_PLANS = 'CHANGE_FILTER_LIFE_DEP_PLANS'
export const UPDATE_PARENT_PLAN_CODE_LIFE_DEP_BASIC = 'UPDATE_PARENT_PLAN_CODE_LIFE_DEP_BASIC'


//////// Connect to Solaris //////////
export const SOLARIS = {
    SEND_TO_SOLARIS: 'SEND_TO_SOLARIS',
    SEND_SUCCESS: 'SOLARIS_SEND_SUCCESS',
    SEND_ERROR: 'SOLARIS_SEND_ERROR',
    SHOULD_GENERATE_ID: 'SHOULD_GENERATE_ID',
    UPDATE_QUOTED_PLANS: 'UPDATE_QUOTED_PLANS',
    FIRST_TIME_ENROLL: 'FIRST_TIME_ENROLL',
    COPY_QUOTE: 'SOLARIS_COPY_QUOTE',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_MMR_STATUS: 'UPDATE_MMR_STATUS'
};

////////////////// Renewal Shopping Helper /////////////////////
export const SUBMIT_RENEWAL_SHOPPING_REQUEST = 'SUBMIT_RENEWAL_SHOPPING_REQUEST';
export const RECEIVE_RENEWAL_SHOPPING_HELPER_DETAILS = 'RECEIVE_RENEWAL_SHOPPING_HELPER_DETAILS';
export const ERR_RENEWAL_SHOPPING_HELPER_DETAILS = 'ERR_RENEWAL_SHOPPING_HELPER_DETAILS';
export const RESET_RENEWAL_SHOPPING_HELPER_DETAILS = 'RESET_RENEWAL_SHOPPING_HELPER_DETAILS';
export const UPDATE_PLAN_ISCHECKED_VALUE_SHOPPING = 'UPDATE_PLAN_ISCHECKED_VALUE_SHOPPING';
export const UPDATE_MDV_CONTRIBUTION_SHOPPING = 'UPDATE_MDV_CONTRIBUTION_SHOPPING';
export const UPDATE_EMPLOYER_CONTRIBUTION_SHOPPING = 'UPDATE_EMPLOYER_CONTRIBUTION_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_SHOPPING = 'UPDATE_SHOW_MORE_LESS_SHOPPING';
export const UPDATE_NUM_OF_SELECTED_RENEW_PLANS_SHOPPING = 'UPDATE_NUM_OF_SELECTED_RENEW_PLANS_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_MEDICAL_SHOPPING = 'UPDATE_SHOW_MORE_LESS_MEDICAL_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_DENTAL_SHOPPING = 'UPDATE_SHOW_MORE_LESS_DENTAL_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_VISION_SHOPPING = 'UPDATE_SHOW_MORE_LESS_VISION_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_LIFE_SHOPPING = 'UPDATE_SHOW_MORE_LESS_LIFE_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_DEPLIFE_SHOPPING = 'UPDATE_SHOW_MORE_LESS_DEPLIFE_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_STD_SHOPPING = 'UPDATE_SHOW_MORE_LESS_STD_SHOPPING';
export const UPDATE_SHOW_MORE_LESS_LTD_SHOPPING = 'UPDATE_SHOW_MORE_LESS_LTD_SHOPPING';

export const UPDATE_RENEWAL_SIC_CODE_COMPANY_PROFILE = 'UPDATE_RENEWAL_SIC_CODE_COMPANY_PROFILE';
export const SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL = 'SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL';
export const UPDATE_SIC_CODE_TO_RENEWAL_DATA = 'UPDATE_SIC_CODE_TO_RENEWAL_DATA';

export const UPDATE_RENEWALS_CUSTOM_PLAN_NAME = 'UPDATE_RENEWALS_CUSTOM_PLAN_NAME';
export const UPDATE_RENEWALS_CASE_STATUS = 'UPDATE_RENEWALS_CASE_STATUS';
export const RESET_SHOPPING_FLOW_SPECIALTY = 'RESET_SHOPPING_FLOW_SPECIALTY';
export const UPDATE_SPECIALTY_RATES_RENEWAL_SHOPPING = 'UPDATE_SPECIALTY_RATES_RENEWAL_SHOPPING';

////////////////// Limited Distribution API /////////////////////
export const REQUEST_LIMITED_DISTRIBUTION_API = 'REQUEST_LIMITED_DISTRIBUTION_API';
export const RESET_LIMITED_DISTRIBUTION_API_FLAG = 'RESET_LIMITED_DISTRIBUTION_API_FLAG';

export const REMOVE_INDIVIDUAL_EMPLOYEE_EMPLOYER_CERT = 'REMOVE_INDIVIDUAL_EMPLOYEE_EMPLOYER_CERT';
export const FILL_RENEWAL_EMPLOYER_CERTIFICATION_FORM = 'FILL_RENEWAL_EMPLOYER_CERTIFICATION_FORM';
export const LOAD_RENEWAL_UPLOADED_REQUIRED_DOCUMENTS = 'LOAD_RENEWAL_UPLOADED_REQUIRED_DOCUMENTS';
export const SAVE_MEDICAL_FINALIZE_RATES = 'SAVE_MEDICAL_FINALIZE_RATES';
export const UPDATE_RENEWAL_RATE_REFERENCE_ID = 'UPDATE_RENEWAL_RATE_REFERENCE_ID';
export const UPDATE_RENEWAL_PROGRESS = 'UPDATE_RENEWAL_PROGRESS';
export const UPDATE_RENEWALS_ACCEPTED_DATE = 'UPDATE_RENEWALS_ACCEPTED_DATE';
export const UPDATE_RENEWALS_SUBMITTED_DATE = 'UPDATE_RENEWALS_SUBMITTED_DATE';
export const UPDATE_RENEWALS_SUBMITTER_DATA = 'UPDATE_RENEWALS_SUBMITTER_DATA';
export const UPDATE_PRIME_ANCILLARY_RENEWAL_TO_NB = 'UPDATE_PRIME_ANCILLARY_RENEWAL_TO_NB';
export const RESET_TO_INITIALSTATE_REN_PRIME_ANC = 'RESET_TO_INITIALSTATE_REN_PRIME_ANC';

export const UPDATE_SAVE_STORE_VALUE = 'UPDATE_SAVE_STORE_VALUE';
export const UPDATE_PARTICIPATION_RULES = "UPDATE_PARTICIPATION_RULES";

//doc 360
export const SAVE_DOCS_TO_DOC360 = "SAVE_DOCS_TO_DOC360"

export const UPDATE_AUDIT_STATUS_FLAG = 'UPDATE_AUDIT_STATUS_FLAG';

////////////////// Data Transmission API /////////////////////
export const UPDATE_CASE_TRACKING_TABLE_FROM_DE = 'UPDATE_CASE_TRACKING_TABLE_FROM_DE';
export const PUSH_DATA_FROM_DATA_EXTERNALIZATION_TO_SAMX = 'PUSH_DATA_FROM_DATA_EXTERNALIZATION_TO_SAMX';
export const RECEIVE_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO = 'RECEIVE_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO';
export const RESET_QUOTE_TRACKING_NO_API_FLAG = 'RESET_QUOTE_TRACKING_NO_API_FLAG';
export const RESET_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO = 'RESET_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO';
export const OWNER_ASSIGNMENT_UPDATE_FROM_DES_FLOW = 'OWNER_ASSIGNMENT_UPDATE_FROM_DES_FLOW';
export const CLEAR_B2B_DES_DATA = 'CLEAR_B2B_DES_DATA';

export const UPDATE_FTERULE_QQ = 'UPDATE_FTERULE_QQ';
export const UPDATE_FTERULE_QQ_ERROR = 'UPDATE_FTERULE_QQ_ERROR';
export const IPPS_PLATFORM_AND_CODE_UPDATE = 'IPPS_PLATFORM_AND_CODE_UPDATE'

export const UPDATE_INSTALLED_PLATFORM = "UPDATE_INSTALLED_PLATFORM"

export const UPDATE_PDF_CSV_LOADER = "UPDATE_PDF_CSV_LOADER"

export const CHANGE_ENROLLED_RATING_METHOD = "CHANGE_ENROLLED_RATING_METHOD"

export const IS_UPLOADING_ADDITIONAL_INFO_DOC360 = 'IS_UPLOADING_ADDITIONAL_INFO_DOC360';
export const UPLOADED_ADDITIONAL_INFO_DOC360 = 'UPLOADED_ADDITIONAL_INFO_DOC360';
export const ERROR_UPLOADING_ADDITIONAL_INFO_DOC360 = 'ERROR_UPLOADING_ADDITIONAL_INFO_DOC360';

export const PRIME_SERVICE_DOWN_ERROR_POPUP = 'PRIME_SERVICE_DOWN_ERROR_POPUP';
export const STORE_USER_INTENT = 'STORE_USER_INTENT';

export const SAVE_CRID_CODE = 'SAVE_CRID_CODE';

////////////////// Package Savings /////////////////////
export const RECEIVE_PACKAGE_SAVINGS_RESPONSE = 'RECEIVE_PACKAGE_SAVINGS_RESPONSE';
export const PACKAGE_SAVINGS_SET_PRODUCT_FOCUS = 'PACKAGE_SAVINGS_SET_PRODUCT_FOCUS';

export const UHC_RENEWALS_UPDATE_USER_ADJUST_FLAG = 'UHC_RENEWALS_UPDATE_USER_ADJUST_FLAG';
// User favorite
export const USER_FAVORITE_MEDICAL_PLANS = 'USER_FAVORITE_MEDICAL_PLANS';
export const USER_FAVORITE_DENTAL_PLANS = 'USER_FAVORITE_DENTAL_PLANS';
export const USER_FAVORITE_STD_PLANS = 'USER_FAVORITE_STD_PLANS';
export const USER_FAVORITE_LTD_PLANS = 'USER_FAVORITE_LTD_PLANS';
export const USER_FAVORITE_VISION_PLANS = 'USER_FAVORITE_VISION_PLANS';
export const USER_FAVORITE_LIFE_PLANS = 'USER_FAVORITE_LIFE_PLANS';
export const USER_FAVORITE_LIFEDEP_PLANS = 'USER_FAVORITE_LIFEDEP_PLANS';
export const USER_FAVORITE_SUPPLIFE_PLANS = 'USER_FAVORITE_SUPPLIFE_PLANS';
export const REQUEST_DEP_SUPP_LIFE_PLANS = 'REQUEST_DEP_SUPP_LIFE_PLANS';
export const REQUEST_SUPP_LIFE_PLANS = 'REQUEST_SUPP_LIFE_PLANS';
export const RECEIVE_DEP_SUPP_LIFE_PLANS = 'RECEIVE_DEP_SUPP_LIFE_PLANS';
export const RECEIVE_SUPP_LIFE_PLANS = 'RECEIVE_SUPP_LIFE_PLANS';

export const UPDATE_MEDICAL_ENROLLED_PLANS = 'UPDATE_MEDICAL_ENROLLED_PLANS';
export const UPDATE_DEPENDENT_LIFE_ENROLLED_PLANS = 'UPDATE_DEPENDENT_LIFE_ENROLLED_PLANS';

export const SAVE_PCP_AUTO_ASSIGNMENT_RULES = 'SAVE_PCP_AUTO_ASSIGNMENT_RULES';

//Prior Year Action Type
export const STORE_PRIOR_YEAR_DATA_VAL = 'STORE_PRIOR_YEAR_DATA_VAL';

export const SET_VALIDATE_QUOTE_DATA = 'SET_VALIDATE_QUOTE_DATA';

//Submission Page actions

export const GET_RETRIEVE_DATES = 'GET_RETRIEVE_DATES';

export const ADD_DATE_RULES = 'ADD_DATE_RULES';

export const DELETE_DATE_RULES = 'DELETE_DATE_RULES';

export const DELETE_EFFECTIVE_DATES = "DELETE_EFFECTIVE_DATES";

export const SAVE_UPDATE_DATE = "SAVE_UPDATE_DATE"
export const ENABLE_FINALIZE_ENROLLMENT = "ENABLE_FINALIZE_ENROLLMENT"


// Pcp page actions

export const GET_RETRIEVE_PLANTYPES = 'GET_RETRIEVE_PLANTYPES';
export const DELETE_PLAN_TYPES = "DELETE_PLAN_TYPES";
export const SAVE_UPDATE_TYPE = "SAVE_UPDATE_TYPE"
export const ADD_DATE_VALUES = 'ADD_DATE_VALUES';
export const DELETE_DATE_VALUES = 'DELETE_DATE_VALUES';

export const SET_RENEWAL_CASE_DATA = "SET_RENEWAL_CASE_DATA";

export const RESET_MEM_GROUP_LW_ERROR = 'RESET_MEM_GROUP_LW_ERROR';

export const SET_HW_TRIGGER_ERROR = 'SET_HW_TRIGGER_ERROR';

// isMember only cirrus
export const RECEIVE_IS_MEMBER_ONLY_CIRRUS_RESPONSE = 'RECEIVE_IS_MEMBER_ONLY_CIRRUS_RESPONSE';
// Has ER and Member Changes cirrus
export const RECEIVE_HAS_MEMBER_CHANGE_CIRRUS = 'RECEIVE_HAS_MEMBER_CHANGE_CIRRUS';
export const RECEIVE_HAS_EMPLOYER_CHANGE_CIRRUS = 'RECEIVE_HAS_EMPLOYER_CHANGE_CIRRUS'

export const UPDATE_RENEWAL_ENROLLMENT_PLANS = 'UPDATE_RENEWAL_ENROLLMENT_PLANS'
export const UPDATE_MEMBERS_IN_PLANS = 'UPDATE_MEMBERS_IN_PLANS'

export const SET_SHOULD_INITIALIZE_ENROLLMENT_FORM = 'SET_SHOULD_INITIALIZE_ENROLLMENT_FORM';
export const RESET_ENROLLMENT_FORM = 'RESET_ENROLLMENT_FORM';
export const SPECIALTY_RATE_CALL_PROGRESS = 'SPECIALTY_RATE_CALL_PROGRESS';
export const INITIALIZE_MEDICAL_FILTER_CRITERIA = 'INITIALIZE_MEDICAL_FILTER_CRITERIA';
export const IS_DES_QUOTE = 'IS_DES_QUOTE';

export const SET_EMPLOYER_ENROLLMENT_COMPLETE = 'SET_EMPLOYER_ENROLLMENT_COMPLETE';
export const SET_MMR_INSTALL_ONLY_PLANS = 'SET_MMR_INSTALL_ONLY_PLANS';
export const SET_MMR_INSTALL_ONLY_PLANS_DETAILS = 'SET_MMR_INSTALL_ONLY_PLANS_DETAILS';
export const SET_TERMED_PLATFORM = 'SET_TERMED_PLATFORM';
export const UPDATE_RENEWAL_CASE_STATUS_FOR_DASHBOARD = 'UPDATE_RENEWAL_CASE_STATUS_FOR_DASHBOARD';
export const DISPLAY_DYNAMIC_ERROR_MESSAGE = 'DISPLAY_DYNAMIC_ERROR_MESSAGE';
export const AUTOMATE_MMR_UPLOAD = 'AUTOMATE_MMR_UPLOAD';  
export const IS_AUTOMATE_MMR_UPLOAD_IN_PROGRESS = 'IS_AUTOMATE_MMR_UPLOAD_IN_PROGRESS';