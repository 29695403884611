import * as types from './actionTypes';
import { history } from '../utils/history';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import {
    addSalesRepData,
    addOwnerAssignmentData,
    addAgencyData,
    addGeneralAgentData,
    addGASalesRepData,
} from './reviewCardsAction';
import { getEnrollmentForm } from './enrollmentFormActions';
import { createHistory } from './historyAction';
import { isEmpty } from '../utils/formatters/strings';
import { updateLicenseCheck } from './reviewCardsAction';
import { getMarketType, MARKET_TYPES, isUhcRenewals, isMmr } from '../utils/businessLogic/platformUtils';
import { saveReviewDetailQuery, saveShoppingHelperDetailQuery, setShoppingPlanSelectionExceptDecide } from '../components/activities/Renewal/RenewalsCommonComponents/saveDetails';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { resetReviewFlag } from './reviewActions';
import { saveEmployerToCompanyProfile } from '../reducers/products/helpers/renewalCompanyProfileHelper';
import { updatePrevious } from './overallActions';
import deepCopy from '../utils/deepCopy';
import { CASE_PROGRESS } from '../reducers/products/helpers/companyProfileConsts';

const apiRoutes = require('../utils/api/apiRouteConfig');


export function getUpcomingRenewals(reqDate, urlParameters, scrolled = false) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const renewalsRequest = {
            reqDate,
        };
        sendLog({
            action: 'SUBMIT_RENEWAL_REQUEST',
            ...renewalsRequest,
        });

        dispatch({
            type: types.REQUEST_UPCOMING_RENEWALS,
            payload: renewalsRequest,
        });
        let pageNo;
        if (reqDate && urlParameters && urlParameters.length > 0) {
            pageNo = scrolled ? Math.ceil(reqDate/20) : 1;
        } else if (reqDate) {
            pageNo = Math.ceil((reqDate / 20));
        } else {
            pageNo = 1;
        }
        let urlProps = "";
        if (urlParameters) {
            urlParameters.forEach((filter) => {
                urlProps += `${"&" + filter.id + "=" + filter.value}`;

            });
        }
        // axios.get(`${apiRoutes.quickQuotes}/get-all-renewals?brokerPcisId=${fullProps.renewals.val}&pageNo=${pageNo}&size=20${url}`)
        let url;
        if (fullProps.renewals.logInData[0].role === 'ExternalInvite') {
            fullProps.renewals.loginRequest.reqLogin.memberGroupId = fullProps.renewals.loginRequest.reqLogin.optumId;
        }
        if(fullProps.overall.source === 'mmr') {
            url = `${apiRoutes.quickQuotes}/uhcRenewal/get-all-renewals-uhc?pageNo=${pageNo}&size=20&isMMRCase=true${urlProps}`
        } else if (fullProps.renewals.logInData[0].role === 'Internal' && fullProps.overall.dashboardCheckPlatform==="CIRRUS") {
            url = `${apiRoutes.quickQuotes}/get-all-renewals?pageNo=${pageNo}&size=20&userType=${fullProps.renewals.logInData[0].role}${urlProps}`;
        } else if(fullProps.renewals.logInData[0].role === 'Internal' && fullProps.overall.dashboardCheckPlatform==="PRIME") {
            url = `${apiRoutes.quickQuotes}/uhcRenewal/get-all-renewals-uhc?pageNo=${pageNo}&size=20&userType=${fullProps.renewals.logInData[0].role}${urlProps}`
        } else if (fullProps.renewals.role === 'Employer' && fullProps.overall.dashboardCheckPlatform==="CIRRUS") {
            url = `${apiRoutes.quickQuotes}/get-all-renewals?customerNumber=${fullProps.renewals.loginRequest.reqLogin.memberGroupId}&pageNo=${pageNo}&size=20&userType=Employer${urlProps}`
        } else if (fullProps.renewals.role === 'Employer' && fullProps.overall.dashboardCheckPlatform==="PRIME") {
            url = `${apiRoutes.quickQuotes}/uhcRenewal/get-all-renewals-uhc?customerNumber=${fullProps.renewals.loginRequest.reqLogin.memberGroupId}&pageNo=${pageNo}&size=20&userType=Employer${urlProps}`
        } else if (fullProps.overall.dashboardCheckPlatform==="CIRRUS"){
            url = `${apiRoutes.quickQuotes}/get-all-renewals?brokerPcisId=${fullProps.renewals.val}&pageNo=${pageNo}&size=20${urlProps}`
        } else {
            url = `${apiRoutes.quickQuotes}/uhcRenewal/get-all-renewals-uhc?brokerPcisId=${fullProps.renewals.val}&pageNo=${pageNo}&size=20${urlProps}`;
        }

        axios.get(url)
            .then((response) => {
                dispatch({
                    type: types.RECEIVE_UPCOMING_RENEWALS,
                    payload: response.data,
                    renewalsRequest,
                    // source: 'upcoming-renewals',
                });
                dispatch({
                    type: types.RESET_RENEWAL_FLAG,
                    payload: true,
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_UPCOMING_RENEWALS,
                    payload: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Renewal List is not loading in DB',
                });
            });
    };
}

export function checkForAcceptedOrPendingInDB(memberGroupId, effectiveDate = '') {
    return new Promise ((resolve, reject)=> {
        axios.get(`${apiRoutes.quickQuotes}/mmr/checkRenewalAcceptedOrPending?memberGroupId=${memberGroupId}&effectiveDate=${effectiveDate}`)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
               reject(err)
        });
    })
};

export function getSelectedRenewals(selectedDays) {
    return (dispatch) => {
        const selectedRenewalsRequest = {
            selectedDays,
        };
        sendLog({
            action: 'SUBMIT_SELECTED_RENEWALS_REQUEST',
            ...selectedRenewalsRequest,
        });

        dispatch({
            type: types.REQUEST_SELECTED_RENEWALS,
            payload: selectedRenewalsRequest,
        });

        axios.get(`${apiRoutes.quickQuotes}/selected-renewals/${selectedDays}`)
            .then((response) => {
                dispatch({
                    type: types.RECEIVE_SELECTED_RENEWALS,
                    payload: response.data,
                    selectedRenewalsRequest,
                    source: 'selected-renewals',
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_SELECTED_RENEWALS,
                    payload: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Selected Renewals Fail',
                });
            });
    };
}

//* *TODO Move loggin methods into login action file */
export function loggingIn(reqLogin) {
    return (dispatch) => new Promise((resolve, reject) => {
        const loginRequest = {
            reqLogin,
        };
        sendLog({
            action: 'LOGGING_IN',
            ...loginRequest,
        });

        dispatch({
            type: types.LOGGING_IN,
            payload: loginRequest,
        });

        axios.get(`${apiRoutes.quickQuotes}/userPreference?userName=${reqLogin.username}&role=${reqLogin.role}&optumId=${reqLogin.optumId}&email=${reqLogin.email}`)
            .then((res) => {
                const payload = res.data;

                if (payload && payload[0] && !isEmpty(payload[0].multiUserId)) {
                    //make call to get-restrictedStates-having-license in service layer
                    axios.get(`${apiRoutes.quickQuotes}/get-restrictedStates-having-license?pcis=${payload[0].multiUserId}`).then((resp) => {
                        //store response array to redux under reviewCards
                        dispatch(updateLicenseCheck(resp.data.licenseCheck))
                    })
                }

                dispatch({
                    type: types.LOGGED_IN,
                    payload,
                    loginRequest,
                    source: 'loggingIN',
                });

                dispatch({
                    type: types.UPDATE_SOURCE,
                    newSource: reqLogin.screen,
                });

                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_LOGGING_IN,
                    payload: err,
                });
                reject();
            });
    });
}

export function getAccessRolesList() {
    return (dispatch) => new Promise(async (resolve, reject) => {
        axios.get(`${apiRoutes.quickQuotes}/getAccessRoles`)
            .then((response) => {
                dispatch({
                    type: types.GET_ACCESS_ROLES,
                    payload: response.data
                })
                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_GET_ACCESS_ROLES,
                    payload: err,
                });
                reject();
            });
    })
}
export function getAccessRoleMmrRn() {
    return (dispatch) => new Promise(async (resolve, reject) => {
        axios.get(`${apiRoutes.quickQuotes}/getAccessRoleMmrRn`)
            .then((response) => {
                dispatch({
                    type: types.GET_ACCESS_ROLES_MMR_RN,
                    payload: response.data
                })
                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_GET_ACCESS_ROLES_MMR_RN,
                    payload: err,
                });
                reject();
            });
    })
}
export function userPreference(req) {
    return (dispatch) => {
        const loginRequest = {
            req,
        };
        sendLog({
            action: 'LOGGING_IN',
            ...loginRequest,
        });

        dispatch({
            type: types.LOGGING_IN,
            payload: loginRequest,
        });
        axios.put(`${apiRoutes.quickQuotes}/userPreferenceUpdate?userName=${req.userName}&role=${req.role}&tablePreference=${req.tablePreference}&graphPreference=${req.graphPreference}&optumId=${req.optumId}&nbTablePreference=${req.nbTablePreference}&multiUserId=${req.multiUserId}`)
            .then((res) => {
                dispatch({
                    type: types.LOGGED_IN,
                    payload: res.data,
                    loginRequest,
                    source: 'loggingIN',
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_LOGGING_IN,
                    payload: err,
                });
            });
    };
}

export const setLoginDataTablePreference = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_TABLE_PREFERENCE,
            payload: data
        })
    }
}

export function getSelectedMonthCases(val) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const totalCases = {
            val,
        };
        sendLog({
            action: 'SUBMIT_RENEWAL_REQUEST',
            ...totalCases,
        });

        dispatch({
            type: types.REQUEST_MONTH_RENEWALS,
            payload: totalCases,
        });
        axios.get(`${apiRoutes.quickQuotes}/renewalStatusPerMonth?brokerPcisId=${fullProps.renewals.val}&month=${val.month}&year=${val.year}&userType=${fullProps.renewals.logInData[0].role}`)
            .then((res) => {
                dispatch({
                    type: types.RECEIVE_MONTH_RENEWALS,
                    payload: res.data[0],
                    totalCases,
                    source: 'month-renewal-cases',
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_MONTH_RENEWALS,
                    payload: err,
                });
            });
    };
}

export function updateStepperAccess(page) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE STEPPER ACCESS',
            page,
        });

        dispatch({
            type: types.UPDATE_STEPPER_ACCESS,
            page,
        });
    };
}

export function updateInitialStepperAccess(stepper) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE INITIAL STEPPER ACCESS',
            stepper,
        });

        dispatch({
            type: types.INITIAL_STEPPER_ACCESS,
            payload: stepper,
        });
    };
}

export function getPcISid(val) {
    return (dispatch) => {
        const pcisId = {
            val,
        };
        dispatch({
            type: types.GET_PCISID,
            payload: pcisId,
        });
    };
}

export function storeName(val) {
    return (dispatch) => {
        dispatch({
            type: types.STORE_NAME,
            payload: val,
        });
    };
}

export function setMemberGroupId(memberGroupId) {
    return (dispatch) => {
        dispatch({
            type: types.SET_MEMBERGROUP_ID,
            payload: memberGroupId
        })
    }
}
export function storeUserToken(token) {
    return (dispatch) => {
        dispatch({
            type: 'SET_TOKEN',
            payload: token
        })
    }
}

export function clearLoginData() {
    return (dispatch) => {
        dispatch({
            type: types.LOGOUT_RENEWAL
        })
    }
}

export function storeEmail(email) {
    return (dispatch) => {
        dispatch({
            type: types.STORE_EMAIL,
            email,
        });
    };
}

export function storeRoleName(roleName) {
    return (dispatch) => {
        dispatch(createHistory('Store Role Name', 'Home', 'Success', '', roleName));
        dispatch({
            type: types.STORE_ROLE_NAME,
            roleName,
        });
    };
}

export function storeDelegateInfo(delegateInfo) {
    return (dispatch) => {
        dispatch({
            type: types.STORE_DELEGATE_INFO,
            delegateInfo,
        });
    };
}

export function storePermissions(rolesPermissions) {
    return (dispatch) => {
        dispatch(createHistory('Roles and Permissions from BNE', 'Home', 'Success', '', rolesPermissions));
        dispatch({
            type: types.STORE_ROLES_PERMISSON_NAMES,
            payload: rolesPermissions
        })
    }
}

export function getRole(val) {
    return (dispatch) => {
        //dispatch(createHistory('Get Role from BNE', 'Home', 'Success', '', val, 'RL'));
        dispatch({
            type: types.GET_ROLE,
            payload: val,
        });
    };
}

export function isLoggedIn(val) {
    return (dispatch) => {
        dispatch({
            type: 'IS_LOGGEDIN',
            payload: val,
        });
    };
}

export function setGlobalSearchFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.GLOBAL_SEARCH_FLAG,
            payload: val
        })
    }
}

export function setSearchRenewalCase(renewalCase) {
    return (dispatch) => {
        dispatch({
            type: types.RENEWAL_SEARCH_CASE,
            payload: renewalCase
        })
    }
}
export function setRenewAsIs(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_RENEW_AS_IS,
            payload: val
        })
    }
}

export const setRenewalCaseData = (renewalCaseData) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_RENEWAL_CASE_DATA,
            payload: {...renewalCaseData}
        })
    }
}

export const getSalesRepData = (renewalQuoteId, memberGroupId, effDate) => async (dispatch, getState) => {

    sendLog({
        action: 'GET_SALESREP_DATA',
    });

    dispatch({
        type: types.GET_SALESREP_DATA,
    });

    try {
        const fullProps = getState();
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
        const platform = renewalQuoteId ? 'CIRRUS' : 'PRIME'
        const effectiveDate = effDate || fullProps?.renewals?.renewalCaseData?.effectiveDate
        const priorYearDataSelected = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
        const apiRequestUrl = getMarketType() === MARKET_TYPES.OXFORD ? `${apiRoutes.quickQuotes}/renewalReview?memberGroupId=${memberGroupId}&renewalQuoteId=${renewalQuoteId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}&priorYearDataSelected=${priorYearDataSelected}`
        : `${apiRoutes.quickQuotes}/uhcRenewal/renewalReviewUhc?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}&isMMRCase=${fullProps.overall.source === 'mmr'}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`
        const renewalReviewRequestResponse = await axios.get(apiRequestUrl);
        const renewalData = renewalReviewRequestResponse.data;
        if(priorYearDataSelected === 'Y') renewalData.priorYearDataSelected = 'Y'
        dispatch(createHistory('RENEWAL Review Calling inside getSalesRepData method in renewal Action', 'Review', 'Success', memberGroupId, renewalData, 'RL'));
        dispatch({
            type: types.RECEIVE_SALESREP_DETAILS,
            payload: renewalData,
        });

        if (renewalData && !renewalData.errors) {
            dispatch(getEnrollmentForm(renewalData.renewalCaseData.situsState, 'renewals'))
            dispatch(formatSalesRepData(renewalData, getState()));
            if (renewalData.gaSalesRepData && renewalData.gaSalesRepData.length > 0) {
                dispatch(formatGaSalesRepData(renewalData, getState()))
            }
        }

        dispatch(addReviewCardsDetails(getState()))

    } catch (err) {
        dispatch(createHistory('RENEWAL Review Calling inside getSalesRepData method in renewal Action', 'Review', 'Error', memberGroupId, err, 'RL'));
        dispatch({
            type: types.ERR_REVIEW_DETAILS,
            payload: err,
        });
    }
};

const formatSalesRepData = (props, store) => (dispatch) => {
    const fullProps = store;
    if ((props.salesRepData && props.salesRepData.length>0) || props.renewalCaseData.salesRepData) {
        const dataForSalesRep = fullProps.reviewCards.ownerAssignment.salesRep;
        const identifiedPlatform = fullProps.overall.platform ? fullProps.overall.platform.toUpperCase() : "CIRRUS"
        let salesRepExists = false;
        let salesRepData = props.salesRepData.length > 0 ? props.salesRepData : props.renewalCaseData.salesRepData 
        salesRepData.map((data) => {
            dataForSalesRep.map((existingData) => {
                if (existingData.PCIS_ID == data.assignedAccountManagerProducerId) {
                    salesRepExists = true;
                }
            });
            if (salesRepExists === false) {
                const salesRepData = {
                    // salesRepName: `${data.assignedAccountManagerFN} ${data.assignedAccountManagerLN}`,
                    salesRepName: (data.assignedAccountManagerFN != undefined ? data.assignedAccountManagerFN : '') + ' ' + (data.assignedAccountManagerLN != undefined ? data.assignedAccountManagerLN : ''),
                    streetAddress: '-',
                    city: '-',
                    state: '-',
                    zipCode: '-',
                    PCIS_ID: data.assignedAccountManagerProducerId,
                    payeeCode: null,
                    isAssignedofsalesRep: true,
                    salesRepNameFN: data.assignedAccountManagerFN,
                    salesRepNameLN: data.assignedAccountManagerLN,
                    platform: identifiedPlatform
                };
                dispatch(addSalesRepData(salesRepData));
                salesRepExists = false;
            }
        });
    }
};

const formatGaSalesRepData = (props, store) => (dispatch) => {
    const fullProps = store;
    if (props.gaSalesRepData) {
        const dataForGaSalesRep = fullProps.reviewCards.ownerAssignment.gaSalesRep;
        let gaSalesRepExists = false;
        props.gaSalesRepData.map((data) => {
            dataForGaSalesRep.map((existingData) => {
                if (existingData.PCIS_ID == data.generalAgentPCISCode) {
                    gaSalesRepExists = true;
                }
            });
            if (gaSalesRepExists === false) {
                const gaSalesRepData = {
                    salesRepName: (data.salesRepFirstName != undefined ? data.salesRepFirstName : '') + ' ' + (data.salesRepLastName != undefined ? data.salesRepLastName : ''),
                    streetAddress: '-',
                    city: '-',
                    state: '-',
                    zipCode: '-',
                    PCIS_ID: data.generalAgentPCISCode,
                    payeeCode: null,
                    isAssignedofsalesRep: true,
                    salesRepNameFN: data.salesRepFirstName,
                    salesRepNameLN: data.salesRepLastName
                };
                dispatch(addGASalesRepData(gaSalesRepData));
                gaSalesRepExists = false;
            }
        });
    }
};
const addReviewCardsDetails = (store, memberGroupId) => async(dispatch) => {
    const fullProps = store
    let agentDataIndex = []
    let uhcPlatformCode = fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.uhcPlatformCode
    if(isUhcRenewals() && uhcPlatformCode === "U"){
        fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.map(memberGroup => {
            if(memberGroup.memberGroupContractId == fullProps.renewals.renewalCaseData.memberGroupContractId){
                agentDataIndex = memberGroup;
            }
        })
        // agentDataIndex = fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData
        // && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.length> 0 ? fullProps.renewals.renewalCaseData.agentData.memberGroupContractList[0]: [];
    }
    else {
        fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.map(memberGroup => {
            if(isUhcRenewals()) {
                if(fullProps.renewals.renewalCaseData.primePolicyNumber.includes(memberGroup.memberGroupContractId)){
                    agentDataIndex = memberGroup;
                } else if (memberGroup.memberGroupContractId == fullProps.renewals.renewalCaseData.memberGroupContractId) {
                    agentDataIndex = memberGroup;
                }
            } else {
                if (memberGroup.memberGroupContractId == fullProps.renewals.renewalData.memberGroup.memberGroupContractId) {
                    agentDataIndex = memberGroup;
                }
            }
        })
    }
    if (agentDataIndex.producerCompensationList && agentDataIndex.producerCompensationList.length > 0) {
        for(let j = 0;j < agentDataIndex.producerCompensationList.length;j++) {
        if (agentDataIndex.producerCompensationList[j].producerList && agentDataIndex.producerCompensationList[j].producerList.length > 0 ) {
            let producerData;
            let maxCommission = 0;
            let isProducer = false;
            let finalProducerData = [];
            let finalAgencyData = [];
            let finalGeneralAgency = [];

            for(let k = 0; k < agentDataIndex.producerCompensationList[j].producerList.length; k++) {
                let producer = agentDataIndex.producerCompensationList[j].producerList[k];
                // agentDataIndex.producerCompensationList[j].producerList.map((producer) => {
                
                if (producer.producerType === "1" || producer.producerType === "3") {
                    if(producer.producerExternalId != 999999 && producer.writingAgentExternalId != 999999) {
                        let isExpired = false
                        if (producer.expirationDate) {
                            //let noOfDays = findDaysTillEffDate(producer.expirationDate)
                            //if (noOfDays < 0) {
                            isExpired = true
                            //}
                        }

                        isProducer = true;
                        // if (producer.pctOfCommission > maxCommission) {
                        //     maxCommission = producer.pctOfCommission;
                        if (!isExpired) {
                            const pcisId = producer.writingAgentExternalId ? producer.writingAgentExternalId : '';
                            let producerInfo = pcisId ? await  axios.get(`${apiRoutes.quickQuotes}/searchOwnerDataByPCIS?pcis=${pcisId}&ownerType=producer&workflow=renewal`) : null;
                            let prodcer =  producerInfo ? producerInfo?.data?.owner && producerInfo.data.owner[0] : {};
                            producerData = {
                                producerName: producer.writingAgentName,
                                producerPhone: producer.writingAgentPhoneNumber ? producer.writingAgentPhoneNumber : 'N/A',
                                PCIS_ID: producer.writingAgentExternalId ? producer.writingAgentExternalId : 'N/A',
                                pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0,
                                city:prodcer.city ? prodcer.city : "",
                                state: prodcer.state ? prodcer.state : "",
                                email: prodcer.email ? prodcer.email : "",
                                streetAddress : prodcer.streetAddress ? prodcer.streetAddress :"",
                                zipCode: prodcer.zipCode ? prodcer.zipCode.substring(0,5) : ""

                            };
                            finalProducerData.push(producerData)
                            finalProducerData.sort((a, b) => (a.pctOfCommission < b.pctOfCommission) ? 1 : -1)


                            if (producer.producerType === '1') {
                                const agencyData = {
                                    agencyName: producer.producerName,
                                    PCIS_ID: producer.writingAgentExternalId ? producer.writingAgentExternalId : 'N/A',
                                    pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0

                                };
                                finalAgencyData.push(agencyData)
                                finalAgencyData.sort((a, b) => (a.pctOfCommission < b.pctOfCommission) ? 1 : -1)
                            } else {
                                const agencyData = {
                                    agencyName: '-',
                                    PCIS_ID: producer.writingAgentExternalId ? producer.writingAgentExternalId : 'N/A',
                                    pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0
                                };
                                finalAgencyData.push(agencyData)
                                finalAgencyData.sort((a, b) => (a.pctOfCommission < b.pctOfCommission) ? 1 : -1)
                            }
                        }
                    }
                }
                if (producer.producerType === "2") {
                    let isExpired = false
                    if (producer.expirationDate) {
                        //let noOfDays = findDaysTillEffDate(producer.expirationDate)
                        //if (noOfDays < 0) {
                        isExpired = true
                        //}
                    }
                    if (!isExpired) {
                        const pcisId = producer.writingAgentExternalId ? producer.writingAgentExternalId : '';
                        let generalAgentInfo = pcisId ? await  axios.get(`${apiRoutes.quickQuotes}/searchOwnerDataByPCIS?pcis=${pcisId}&ownerType=generalagent&workflow=renewal`) : null;
                        let agentInfo = generalAgentInfo ? generalAgentInfo?.data?.owner && generalAgentInfo.data.owner[0] : {};
                    const generalAgentData = {
                            generalAgentName: producer.writingAgentName,
                            PCIS_ID: producer.writingAgentExternalId ? producer.writingAgentExternalId : 'N/A',
                            pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0,
                            gaEmail:agentInfo && agentInfo.gaEmail || ''
                        };
                        finalGeneralAgency.push(generalAgentData)
                        finalGeneralAgency.sort((a, b) => (a.pctOfCommission < b.pctOfCommission) ? 1 : -1)
                        if (fullProps.reviewCards.ownerAssignment.gaSalesRep.length == 0 && finalGeneralAgency.length > 0) {
                           const resp = await axios.get(`${apiRoutes.quickQuotes}/getGaSalesRep?memberGroupId=${memberGroupId}&pcisId=${generalAgentData.PCIS_ID}`);
                                if (resp.data.gaSalesRepData && resp.data.gaSalesRepData.length > 0) {
                                    const gaSalesRepData = {
                                        salesRepName: (resp.data.gaSalesRepData[0].salesRepFirstName ? resp.data.gaSalesRepData[0].salesRepFirstName : '') + ' ' + (resp.data.gaSalesRepData[0].salesRepLastName ? resp.data.gaSalesRepData[0].salesRepLastName : ''),
                                        streetAddress: '-',
                                        city: '-',
                                        state: '-',
                                        zipCode: '-',
                                        PCIS_ID: resp.data.gaSalesRepData[0].generalAgentPCISCode,
                                        salesRepNameFN: resp.data.gaSalesRepData[0].salesRepFirstName,
                                        salesRepNameLN: resp.data.gaSalesRepData[0].salesRepLastName
                                    };
                                    dispatch(addGASalesRepData(gaSalesRepData));

                                }
                           // })
                        }
                    }
                    //   dispatch(addGeneralAgentData(generalAgentData));
                }
                // if (producer.producerType == 3) {
                //     const agencyData = {
                //         agencyName: producer.writingAgentName,
                //     };
                //     dispatch(addAgencyData(agencyData));
                // }
            //});
            }
            finalProducerData.map(producer => {
                producerData = {
                    producerName: producer.producerName,
                    producerPhone: producer.producerPhone ? producer.producerPhone : 'N/A',
                    PCIS_ID: producer.PCIS_ID ? producer.PCIS_ID : 'N/A',
                    pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0,
                    city:producer.city ? producer.city : "",
                    state: producer.state ? producer.state : "",
                    email: producer.email ? producer.email : "",
                    streetAddress : producer.streetAddress ? producer.streetAddress :"",
                    zipCode: producer.zipCode ? producer.zipCode : ""

                };
                dispatch(addOwnerAssignmentData(producerData));

            })
            finalAgencyData.map(agency => {
                const agencyData = {
                    agencyName: agency.agencyName,
                    PCIS_ID: agency.PCIS_ID ? agency.PCIS_ID : 'N/A',
                    pctOfCommission: agency.pctOfCommission ? agency.pctOfCommission : 0

                };
                dispatch(addAgencyData(agencyData));

            })
            finalGeneralAgency.map(agent => {
                const generalAgentData = {
                    generalAgentName: agent.generalAgentName,
                    PCIS_ID: agent.PCIS_ID ? agent.PCIS_ID : 'N/A',
                    pctOfCommission: agent.pctOfCommission ? agent.pctOfCommission : 0,
                    gaEmail:agent.gaEmail ? agent.gaEmail : ''
                }
                dispatch(addGeneralAgentData(generalAgentData));
            })
            //make a call to backend to store the owner assignment Information
            if(isUhcRenewals()) {
                const reqbody = {
                    memberGroupId : fullProps.renewals.renewalCaseData.memberGroupId,
                    quoteTrackingNumber:fullProps.caseTracking.currentQuoteId,
                    finalAgencyData,
                    finalGeneralAgency,
                    finalProducerData,
                    priorYearDataSelected: fullProps.renewals.priorYearDataSelected,
                    effectiveDate: fullProps.renewals.renewalCaseData.effectiveDate
                }
                await axios.post(`${apiRoutes.quickQuotes}/storeOwnerdata`,reqbody);
        }

            // if (isProducer) {
            //     dispatch(addOwnerAssignmentData(producerData));
            // }
        }
        }
    }

};

export function resetRenewals() {
    return (dispatch) => {
        sendLog({
            action: 'RESET_RENEWALS',
        });

        dispatch({
            type: types.RESET_RENEWALS,
        });
    };
}

export function resetRenewalData() {
    return (dispatch) => {
        dispatch({
            type: 'RESET_RENEWALS',
            payload: []
        })
    }
}

export function renewalLogOut() {
    return (dispatch) => {
        sendLog({
            action: 'LOGOUT_RENEWAL',
        });

        dispatch({
            type: types.LOGOUT_RENEWAL,
        });
        history.push('/');
    };
}

export function resetRenewalDashBoardFlag(resetVal) {
    return (dispatch) => {
        sendLog({
            action: 'RESET_RENEWAL_FLAG',
        });

        dispatch({
            type: types.RESET_RENEWAL_FLAG,
            payload: resetVal,
        });
    };
}

export function setCaseCancelFlag(value) {
    return (dispatch) => {
        sendLog({
            action: 'SET_CASE_CANCEL_FLAG_RENEWALS',
        });

        dispatch({
            type: types.SET_CASE_CANCEL_FLAG_RENEWALS,
            payload: value,
        });
    };
}

export function setCaseReinstateFlag(value) {
    return (dispatch) => {
        sendLog({
            action: 'SET_CASE_REINSTATE_FLAG_RENEWALS',
        });

        dispatch({
            type: types.SET_CASE_REINSTATE_FLAG_RENEWALS,
            payload: value,
        });
    };
}

export function setRefreshApisLoader(value) {
    return (dispatch) => {
        sendLog({
            action: 'SET_REFRESH_APIS_LOADER',
        });

        dispatch({
            type: types.SET_REFRESH_APIS_LOADER,
            payload: value,
        });
    };
}
export function updateRenewalCaseStatus(req, memberGroupId) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWALS_CASE_STATUS,
            caseStatus: req.caseStatus,
            memberGroupId,
            prevRenewalDataStatus: req.prevRenewalDataStatus ? req.prevRenewalDataStatus : ''
        })
    }
}
export function updateRenewalSearchCase(req) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_RENEWALS_SEARCH_CASE',
            caseStatus: req.caseStatus
        })
    }
}

export function updateRenewalCaseProgress(req) {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_RL_CASE_PROGRESS',
            caseStatus: req.caseStatus
        })
    }
}

export const getLatestUpdatedCaseData = () => async (dispatch, getState) => {
    const fullProps = getState();
    const memberGroupId = fullProps.review.val.customerNumber;
    const marketType = isUhcRenewals() ? 'UHC' : 'OXFORD';
    const isMMRCase = isMmr()
    const priorYearDataGroup = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
    const effectiveDate = fullProps?.renewals?.renewalCaseData?.effectiveDate
    axios.get(`${apiRoutes.quickQuotes}/getLatestCaseData?memberGroupId=${memberGroupId}&marketType=${marketType}&isMMRCase=${isMMRCase}&priorYearDataGroup=${priorYearDataGroup}&effectiveDate=${effectiveDate}`)
        .then((resp) => {
            dispatch({
                type: types.LATEST_RENEWAL_CASE_DATA,
                payload: resp.data[0],
            });
        }).catch(err => {
            if (isUhcRenewals()) {
                const reqBody = {
                    memberGroupId, marketType, isMMRCase, priorYearDataGroup
                }
                logError(KEY_NAME, UHC_REN_APIS.GET_LATEST_CASE_DATA, 'renewalActions.getLatestUpdatedCaseData', reqBody, err);
            }
        })
}

export function updateRenewalRateReferenceIds(value) {
    return (dispatch) => {
        sendLog({
            action: 'RENEWAL_RATE_REFERENCE_ID',
        });

        dispatch({
            type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
            payload: value,
        });
    };
}

export function updateRenewalRateAPIStatus(value) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RATES_API_SUCCESS,
            payload: value,
        });
    };
}
export function updateTotalProgress(value) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_RENEWAL_PROGRESS',
        });

        dispatch({
            type: types.UPDATE_RENEWAL_PROGRESS,
            payload: value,
        });
    };
}

export function logout() {
    return (dispatch) => {
        sendLog({
            action: 'USER_LOGOUT',
        });

        dispatch({
            type: types.USER_LOGOUT,
        });
    };
}

export function updateRenewalsAcceptedDate(req, memberGroupId) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWALS_ACCEPTED_DATE,
            acceptedDate: req.acceptedDate,
            memberGroupId
        })
    }
}

export function updateRenewalsSubmittedDate(req, memberGroupId) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWALS_SUBMITTED_DATE,
            submittedDate: req.submittedDate,
            memberGroupId
        })
    }
}

export function recieveOnlyUpcomingRenewals(filterData) {
    return (dispatch) => {
        dispatch({
            type: types.RECEIVE_UPCOMING_RENEWALS,
            payload: filterData,
            source: 'upcoming-renewals',
        });
    }
}

export function storeUserIntent(userIntent) {
    return (dispatch) => {
        dispatch({
            type: types.STORE_USER_INTENT,
            payload: userIntent
        });
    }
}

export function setMedicalCensusSummary(data) {
    return (dispatch) => {
        dispatch({
            type:  types.SET_MEDICAL_CENSUS_SUMMARY,
            payload: data
        })
    }
}

/** Save data to DB (renewalUpdate) on click of top header buttons */
export function saveDataToDB(pathName) {
    if (pathName !== '') {
        const { store } = require('../index');
        const fullProps = store.getState();
        let data;
        switch (pathName) {
            case '/reviewDetail':
                data = saveReviewDetailQuery(fullProps);
                break;
            case '/renewalShopping':
                data = saveShoppingHelperDetailQuery(fullProps);
                break;
            default:
                console.log("Empty Payload.");
                break;
        }
        if (data) {
            data.effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate
            data.isPriorYear = fullProps.renewals?.priorYearDataSelected === 'Y'
            axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/renewalUpdateUhc/`, data)
                .then((res) => {
                    //console.log('DATA SAVED');
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }
}

export function saveDataToDBOxfordRL(pathName){

    return (dispatch, getState) => {
        if(pathName === '/enrollmentManager/manager'){
            const store = getState();
            const renewalUpdateRoute = "/renewalUpdate/";
            dispatch(resetReviewFlag(false))
                dispatch({
                    type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                    payload: true
                })
                const { userName } = store.user;
                let cmpProfile = deepCopy(store.companyProfile)
                if (cmpProfile.enrollment) {
                    cmpProfile.enrollment.requiredDocs = store.enrollment.documents.requiredDocuments;
                } else {
                    cmpProfile.enrollment = {
                        requiredDocs: store.enrollment.documents.requiredDocuments,
                    };
                }
                cmpProfile = saveEmployerToCompanyProfile(cmpProfile,store);
                cmpProfile.marketType = getMarketType();

                axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile).catch(err => {
                    throw err;
                })
                const query = setShoppingPlanSelectionExceptDecide(store);
                axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                    .then(res => {
                    }).catch(err => {
                        throw err;
                    });
                //props.history.push('/' + page)
                dispatch(updateStepperAccess('enrollment'))
                dispatch(updatePrevious('/enrollmentManager'))
        }
        
    }
}
export function updateTransactionType (payload) {
    return (dispatch,getState) => { 
    axios.get(`${apiRoutes.quickQuotes}/uhcRenewal/addTranType?memberGroupId=${payload.memberGroupId}&tranType=${payload.tranType}&quoteTrackingNumber=${payload.quoteTrackingNumber}`)
        .then((res) => {
            const fullProps = getState();
            fullProps.renewals.renewalCaseData.samxTransactionType = payload.tranType;
            dispatch({
                type: types.SET_RENEWAL_CASE_DATA,
                payload: {...fullProps.renewals.renewalCaseData}
            })
            dispatch({
                type: types.UPDATE_TRANSACTION_TYPE,
                payload
            });
            console.log('DATA SAVED');
        })
        .catch(err => {
            logError(KEY_NAME, UHC_REN_APIS.ADD_TRAN_TYPE, 'renewalActions.updateTransactionType', payload, err);
            console.log(err)
        });
    }
}

// export function getAllMMRCases(reqDate, urlParameters) {
//     return (dispatch) => {
//         dispatch({
//             type: types.REQUEST_UPCOMING_RENEWALS
//         });
//         let pageNo;
//         if (reqDate && urlParameters) {
//             pageNo = 1;
//         } else if (reqDate) {
//             pageNo = (reqDate / 20) + 1;
//         } else {
//             pageNo = 1;
//         }
//         let urlProps = "";
//         if (urlParameters) {
//             urlParameters.forEach((filter) => {
//                 urlProps += `${"&" + filter.id + "=" + filter.value}`;

//             });
//         }
//         console.log(pageNo, 'harshil pageNo')
//         axios.get(`${apiRoutes.quickQuotes}/mmr/get-all-mmr-cases?pageNo=${pageNo}&size=${20}${urlProps}`)
//             .then((response) => {
//                 dispatch({
//                     type: types.RECEIVE_UPCOMING_RENEWALS,
//                     payload: response.data
//                 });
//                 dispatch({
//                     type: types.RESET_RENEWAL_FLAG,
//                     payload: true,
//                 });
//             })
//             .catch((err) => {
//                 dispatch({
//                     type: types.ERR_UPCOMING_RENEWALS,
//                     payload: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Renewal List is not loading in DB',
//                 });
//             });
//     }
// }

export function addMMRCase(payload) {
    return (dispatch) => {
        dispatch({
            type: types.RECEIVE_UPCOMING_RENEWALS,
            payload
        });
    }
}
export function updatePriorYearDataVal(val) {
    return (dispatch) => {
        dispatch({
            type: types.STORE_PRIOR_YEAR_DATA_VAL,
            payload: val
        });
    }
}

export function updateSelectedMedicalPlansToEnroll(newEnrolledPlans){
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_ENROLLED_PLANS_FROM_DECIDE,
            payload:newEnrolledPlans
        })
    }
}

export function disableTopHeader (flag) {
    return (dispatch) => {
        dispatch({
            type: types.DISABLE_TOP_HEADER,
            payload: flag
        })
    }
}

export function automateMmrUpload(payload){
    return (dispatch) => {
        dispatch({
            type: types.AUTOMATE_MMR_UPLOAD, 
            payload : payload
        })
    }
}

export function isAutomateMmrUploadInProgress(payload){
    return (dispatch) => {
        dispatch({
            type: types.IS_AUTOMATE_MMR_UPLOAD_IN_PROGRESS, 
            payload : payload
        })
    }
}