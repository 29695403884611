import { workSheets, dataTypes, dropDowns, regexTypes, userTypes, userTypesCensusUpload, dependencyNames } from '../../constants';
import * as helper from '../mappingHelper';
import { stringToCoverageCode, isEmpty } from '../../../../utils/formatters/strings';

export const getUHCMapping = (workSheetName) => {
    switch (workSheetName) {
        case workSheets.companyInfo:
            return getCompanyInfoMapping();
        case workSheets.location:
            return getLocationMapping();
        case workSheets.employeeInfo:
            return getEmployeeInfoMapping();
        case workSheets.enrollmentInfo:
            return getEnrollmentInfoMapping();
        case workSheets.enrollmentForm:
            return getEnrollmentFormMapping() //paper case.
        case 'Summary':
            return getMMRMapping();    
    }
}

function getMMRMapping() {
    return {
        metaData: {
            isIterative: true,
            baseRow: 2
        },
        mapping: {
            A: {
                fieldDisplay: 'PCU Notes',
                fieldPath: 'pcuNotes',
                required: false,
                constraints: {

                }
            },
            B: {
                fieldDisplay: 'FUW',
                fieldPath: 'fuw',
                required: false,
                constraints: {
                   
                }
            },
            C: {
                fieldDisplay: 'State',
                fieldPath: 'state',
                required: true,
                constraints: {
                    
                }
            },
            D: {
                fieldDisplay: 'Cirrus ID',
                fieldPath: 'cirrusId',
                required: true,
                constraints: {
                   
                }
            },
            E: {
                fieldDisplay: 'Group Name',
                fieldPath: 'groupName',
                required: true,
                constraints: {
                   
                }
            },
            F: {
                fieldDisplay: 'Current Med Plan',
                fieldPath: 'currentMedPlan',
                required: false,
                constraints: {
                   
                }
            },
            G: {
                fieldDisplay: 'Renewal Plan',
                fieldPath: 'renewalPlan',
                required: false,
                constraints: {
                    
                },
            },
            H: {
                fieldDisplay: 'Renewal RX',
                fieldPath: 'renewalRx',
                required: false,
                constraints: {
         
                }
            },
            I: {
                fieldDisplay: 'Renewal Package ID',
                fieldPath: 'renewalPackageId',
                required: false,
                constraints: {
                   
                }
            },
            J: {
                fieldDisplay: 'Renewal Date',
                fieldPath: 'renewalDate',
                fieldValue: helper.formatToMMDDYYYYFormatSlash,
                required: true,
                constraints: {
                    dataType: [dataTypes.string, dataTypes.object],
                    validDate: helper.isValidDate
                }
            },
            K: {
                fieldDisplay: 'TR/ACR',
                fieldPath: 'trAcr',
                required: false,
                constraints: {

                }
            },
            L: {
                fieldDisplay: 'Force?',
                fieldPath: 'force',
                required: false,
                constraints: {
                   
                }
            },
            M: {
                fieldDisplay: 'Comments',
                fieldPath: 'comments',
                required: false,
                constraints: {
                
                }
            },
            N: {
                fieldDisplay: 'OVR Renewal Life Rate',
                fieldPath: 'ovrRenewalLifeRate',
                required: false,
                constraints: {
                    
                }
            },
            O: {
                fieldDisplay: 'OVR AD&D Ren',
                fieldPath: 'ovrADDRen',
                required: false,
                constraints: {
                  
                }
            },
            P: {
                fieldDisplay: 'OVR deplifesportren',
                fieldPath: 'ovrDepLifeSpoRtRen',
                required: false,
                constraints: {
                   
                }
            },
            Q: {
                fieldDisplay: 'OVR deplifechdrtren',
                fieldPath: 'ovrDepLifeChdRtRen',
                required: false,
                constraints: {
                   
                }
            },
            R: {
                fieldDisplay: 'dentempovrrtren',
                fieldPath: 'dentEmpOvrRtRen',
                required: false,
                constraints: {
             
                }
            },
            S: {
                fieldDisplay: 'dentspoovrrtren',
                fieldPath: 'dentSpoOvrRtRen',
                required: false,
                constraints: {
                  
                }
            },
            T: {
                fieldDisplay: 'dentchdovrrtren',
                fieldPath: 'dentChdOvrRtRen',
                required: false,
                constraints: {
                    
                }
            },
            U: {
                fieldDisplay: 'dentfamovrrtren',
                fieldPath: 'dentFamOvrRtRen',
                required: false,
                constraints: {
                  
                }
            },
            V: {
                fieldDisplay: 'ddentempovrrtren',
                fieldPath: 'ddentEmpOvrRtRen',
                required: false,
                constraints: {
                   
                }
            },
            W: {
                fieldDisplay: 'ddentspoovrrtren',
                fieldPath: 'ddentSpoOvrRtRen',
                required: false,
                constraints: {
                    
                }
            },
            X: {
                fieldDisplay: 'ddentchdovrrtren',
                fieldPath: 'ddentChdOvrRtRen',
                required: false,
                constraints: {
                   
                }
            },
            Y: {
                fieldDisplay: 'ddentchfamovrrtren',
                fieldPath: 'ddentChFamOvrRtRen',
                required: false,
                constraints: {
                    
                }
            },
            Z: {
                fieldDisplay: 'VISIONEMPovrrtREN',
                fieldPath: 'visionEmpOvrRtRen',
                required: false,
                constraints: {
                   
                }
            },
            AA: {
                fieldDisplay: 'VISIONSPOovrrtREN',
                fieldPath: 'visionSpoOvrRtRen',
                required: false,
                constraints: {
                   
                }
            },
            AB: {
                fieldDisplay: 'VISIONCHDovrrtREN',
                fieldPath: 'visionChdOvrRtRen',
                required: false,
                constraints: {
                    
                }
            },
            AC: {
                fieldDisplay: 'VISIONFAMovrrtREN',
                fieldPath: 'visionFamOvrRtRen',
                required: false,
                constraints: {
                    
                }
            }
        }
    }
}

function getCompanyInfoMapping() {
    return {
        metaData: {
            isIterative: false,
            baseRow: 4
        },
        mapping: {
            A: {
                fieldDisplay: 'Company Name',
                fieldPath: 'companyName',
                required: true,
                constraints: {
                    //dataType: [dataTypes.string],
                }
            },
            B: {
                fieldDisplay: 'Effective Date',
                fieldPath: 'effectiveDate',
                fieldValue: helper.formatToMMDDYYYYFormatSlash,
                required: true,
                constraints: {
                    dataType: [dataTypes.string, dataTypes.object],
                    validDate: helper.isValidDate
                }
            },
            C: {
                fieldDisplay: 'First Name',
                fieldPath: 'primaryContact.firstName',
                required: false,
                defaultValue: '',
                constraints: {
                    regex: regexTypes.noSpecialCharacters
                }
            },
            D: {
                fieldDisplay: 'Last Name',
                fieldPath: 'primaryContact.lastName',
                required: false,
                defaultValue: '',
                constraints: {
                    regex: regexTypes.noSpecialCharacters
                }
            },
            E: {
                fieldDisplay: 'Email',
                fieldPath: 'primaryContact.email',
                //fieldValue: (value) => typeof value === dataTypes.object ? value.text : value,
                required: false,
                defaultValue: '',
                constraints: {
                    regex: regexTypes.email
                }
            },
            F: {
                fieldDisplay: 'SIC Code',
                fieldPath: 'sicCode',
                required: true,
                constraints: {
                    regex: regexTypes.number,
                    length: 4,
                }
            },
            G: {
                fieldDisplay: 'Franchise Code',
                fieldPath: 'franchiseCode',
                fieldValue: helper.appendLeadingZeros,
                required: false,
                constraints: {
                    regex: regexTypes.number
                },
            },
            H: {
                fieldDisplay: 'Quote Medical Plans',
                fieldPath: 'selectedProducts.medical',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            },
            I: {
                fieldDisplay: 'Quote Dental Plans',
                fieldPath: 'selectedProducts.dental',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            },
            J: {
                fieldDisplay: 'Quote Vision Plans',
                fieldPath: 'selectedProducts.vision',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            },
            K: {
                fieldDisplay: 'Quote Life Plans',
                fieldPath: 'selectedProducts.life',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            },
            L: {
                fieldDisplay: 'Quote STD Plans',
                fieldPath: 'selectedProducts.std',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            },
            M: {
                fieldDisplay: 'Quote LTD Plans',
                fieldPath: 'selectedProducts.ltd',
                fieldValue: helper.checkQuotingStatus,
                required: true,
                constraints: {
                    dropDownValues: dropDowns.quoteNotQuote
                }
            }
        }
    }
}

function getLocationMapping() {
    return {
        metaData: {
            isIterative: false,
            baseRow: 4
        },
        mapping: {
            A: {
                fieldDisplay: 'Zip Code',
                fieldPath: 'locations.0.zipCode',
                required: true,
                constraints: {
                    length: 5,
                    regex: regexTypes.number
                }
            },
            B: {
                fieldDisplay: 'State',
                fieldPath: 'locations.0.stateCode',
                required: true,
                constraints: {
                    length: 2,
                    regex: regexTypes.capitalCharacter
                }
            },
            C: {
                fieldDisplay: 'Total Employees Applying',
                fieldPath: 'locations.0.totalEmps',
                required: true,
                constraints: {
                    dataType: [dataTypes.number],
                    minValue: 2,
                }
            },
            D: {
                fieldDisplay: 'Total Eligible Employees',
                fieldPath: 'locations.0.totalEligEmps',
                required: true,
                constraints: {
                    dataType: [dataTypes.number],
                    minValue: 2,
                }
            },
            E: {
                fieldDisplay: 'ATNE',
                fieldPath: 'locations.0.atne',
                required: true,
                constraints: {
                    dataType: [dataTypes.number],
                    minValue: 2,
                }
            },
            F: {
                fieldDisplay: 'FTE',
                fieldPath: 'locations.0.fte',
                required: true,
                constraints: {
                    dataType: [dataTypes.number],
                    minValue: 2,
                }
            }
        }
    }
}

function getEmployeeInfoMapping() {
    return {
        metaData: {
            isIterative: true,
            baseRow: 4,
            [userTypes.employee]: [
                dependencyNames.WORKSHEET_DEPENDENCY,
                dependencyNames.SELF_DEPENDENCY,
                dependencyNames.AFTER_PARSE_DEPENDENCY
            ],
            [userTypes.dependent]: [
                dependencyNames.SELF_DEPENDENCY,
                dependencyNames.AFTER_PARSE_DEPENDENCY,
                dependencyNames.PARENT_CHILD_DEPENDENCY
            ],
        },
        mapping: {
            [userTypes.employee]: {
                D: {
                    fieldDisplay: 'Zip Code',
                    fieldPath: 'zipCode',
                    fieldValue: value => value.toString(),
                    required: false,
                    constraints: {
                        length: 5,
                        regex: regexTypes.number
                    }
                },
                E: {
                    fieldDisplay: 'Last Name',
                    fieldPath: ['lastName', 'employeeInfo.lastName'],
                    fieldValue: [value => value, value => value],
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                    }
                },
                F: {
                    fieldDisplay: 'First Name',
                    fieldPath: ['firstName', 'employeeInfo.firstName'],
                    fieldValue: [value => value, value => value],
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                    }
                },
                G: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'employeeInfo.middleInitial',
                    required: false,
                    constraints: {
                        length: 1,
                        regex: regexTypes.capitalCharacter,
                    }
                },
                H: {
                    fieldDisplay: 'Suffix',
                    fieldPath: ['suffix', 'employeeInfo.suffix'],
                    required: false,
                    fieldValue: [helper.mapSuffix, helper.mapSuffix],
                    constraints: {
                        dropDownValues: dropDowns.suffix
                    }
                },
                I: {
                    fieldDisplay: 'Date of Birth',
                    fieldPath: ['dob', 'employeeInfo.dob'],
                    required: false,
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, helper.formatToMMDDYYYYFormatSlash],
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object],
                        validDate: helper.isValidDate
                    }
                },
                J: {
                    fieldDisplay: 'Age',
                    fieldPath: 'age',
                    required: false,
                    constraints: {
                        regex: regexTypes.number
                    }
                },
                K: {
                    fieldDisplay: 'Gender',
                    fieldPath: ['gender', 'employeeInfo.gender'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.gender
                    }
                },
                L: {
                    fieldDisplay: 'Employee Status',
                    fieldPath: ['employeeStatus', 'employeeInfo.employeeStatus'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.empStatus
                    }
                },
                M: {
                    fieldDisplay: 'Special Employee Status',
                    fieldPath: ['specialEmployeeStatus', 'employeeInfo.specialEmployeeStatus'],
                    fieldValue: [value => value, value => value],
                    required: false, //TODO: add logic so this field defaults to 'None' in UI upon template upload
                    constraints: {
                        dropDownValues: dropDowns.specialEmpStatus
                    }
                },
                O: {
                    fieldDisplay: 'Salary',
                    fieldPath: ['salary', 'employeeInfo.salary'],
                    fieldValue: [value => value.toString(), value => value.toString()],
                    required: false,
                    constraints: {
                        regex: regexTypes.number
                    }
                },
                P: {
                    fieldDisplay: 'Out Of Area',
                    fieldPath: 'outOfArea',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                Q: {
                    fieldDisplay: 'Medical Coverage Type',
                    fieldPath: ['coverages.medical', 'medicalCoverageInfo.medicalWaived'],
                    fieldValue: [stringToCoverageCode, helper.isWaived],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.coverageType
                    }
                },
                R: {
                    fieldDisplay: 'Medical Waiver Reason',
                    fieldPath: ['waiveCoverage.medicalWaiverReason', 'medicalCoverageInfo.medicalWaiverReason', 'waiveReason'],
                    fieldValue: [value => value, value => value, value => value],
                    required: false,
                    constraints: {
                        dropDownValues: helper.getMedicalWaiverReasons()
                    }
                },
                S: {
                    fieldDisplay: 'Dental Coverage Type',
                    fieldPath: 'coverages.dental',
                    fieldValue: helper.stringToCoverageCode,
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.coverageType
                    }
                },
                T: {
                    fieldDisplay: 'Vision Coverage Type',
                    fieldPath: 'coverages.vision',
                    fieldValue: helper.stringToCoverageCode,
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.coverageType
                    }
                },
                U: {
                    fieldDisplay: 'Basic Life Coverage Type',
                    fieldPath: 'coverages.basicLife',
                    fieldValue: helper.stringToCoverageCode,
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.lifeCoverage
                    }
                },
                V: {
                    fieldDisplay: 'Short Term Disability Coverage Type',
                    fieldPath: 'coverages.std',
                    fieldValue: helper.stringToCoverageCode,
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.stdLTDCoverage
                    }
                },
                W: {
                    fieldDisplay: 'Long Term Disability Coverage Type',
                    fieldPath: 'coverages.ltd',
                    fieldValue: helper.stringToCoverageCode,
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.stdLTDCoverage
                    }
                },
            },
            [userTypes.dependent]: {
                B: {
                    fieldDisplay: 'Relationship',
                    fieldPath: 'relationship',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.relationship
                    }
                },
                D: {
                    fieldDisplay: 'Zip Code',
                    fieldPath: 'zipCode',
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                E: {
                    fieldDisplay: 'Last Name',
                    fieldPath: 'lastName',
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                F: {
                    fieldDisplay: 'First Name',
                    fieldPath: 'firstName',
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                G: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'middleInitial',
                    required: false,
                    constraints: {
                        regex: regexTypes.capitalCharacter,
                        length: 1
                    }
                },
                H: {
                    fieldDisplay: 'Suffix',
                    fieldPath: 'suffix',
                    required: false,
                    fieldValue: helper.mapSuffix,
                    constraints: {
                        dropDownValues: dropDowns.suffix
                    }
                },
                I: {
                    fieldDisplay: 'Date of Birth',
                    fieldPath: 'dob',
                    required: false,
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object],
                        validDate: helper.isValidDate
                    }
                },
                J: {
                    fieldDisplay: 'Age',
                    fieldPath: 'age',
                    required: false,
                    constraints: {
                        regex: regexTypes.number
                    }
                },
                K: {
                    fieldDisplay: 'Gender',
                    fieldPath: 'gender',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.gender
                    }
                },
            }
        }
    }
}

function getEnrollmentInfoMapping() {
    return {
        metaData: {
            isIterative: true,
            baseRow: 4,
            [userTypes.employee]: [
                dependencyNames.WORKSHEET_DEPENDENCY,
                dependencyNames.SELF_DEPENDENCY,
            ],
            [userTypes.dependent]: [
                dependencyNames.SELF_DEPENDENCY,
                dependencyNames.CHILD_PARENT_DEPENDENCY
            ],
        },
        mapping: {
            [userTypes.employee]: {
                C: {
                    fieldDisplay: 'Employee Status',
                    fieldPath: ['employeeStatus', 'employeeInfo.employeeStatus'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.empStatus
                    }
                },
                D: {
                    fieldDisplay: 'First Name',
                    fieldPath: ['firstName', 'employeeInfo.firstName'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                E: {
                    fieldDisplay: 'Last Name',
                    fieldPath: ['lastName', 'employeeInfo.lastName'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                F: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'employeeInfo.middleInitial',
                    required: false,
                    constraints: {
                        regex: regexTypes.capitalCharacter,
                        length: 1
                    }
                },
                G: {
                    fieldDisplay: 'Suffix',
                    fieldPath: ['suffix', 'employeeInfo.suffix'],
                    fieldValue: [helper.mapSuffix, helper.mapSuffix],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.suffix
                    }
                },
                H: {
                    fieldDisplay: 'Gender',
                    fieldPath: ['gender', 'employeeInfo.gender'],
                    fieldValue: [helper.handleGender, helper.handleGender],
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.gender
                    }
                },
                I: {
                    fieldDisplay: 'Date Of Birth',
                    fieldPath: 'dob',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object],
                    }
                },
                J: {
                    fieldDisplay: 'Waive All Product',
                    fieldPath: 'waiveCoverage.isWaivingAll',
                    fieldValue: helper.isWaivingAll,
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.isWaivingAll
                    }
                },
                K: {
                    fieldDisplay: 'Medical Plan',
                    fieldPath: ['productSelection.0.medical', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isMedicalWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,

                    }
                },
                L: {
                    fieldDisplay: 'Medical Waiver Reason',
                    fieldPath: ['waiveCoverage.medicalWaiverReason', 'medicalCoverageInfo.medicalWaiverReason', 'waiveReason'],
                    fieldValue: [value => value, value => value, value => value],
                    required: false,
                    constraints: {
                        dropDownValues: helper.getMedicalWaiverReasons()
                    }
                },
                M: {
                    fieldDisplay: 'Auto Assign PCP',
                    fieldPath: 'pcpAssignment.0.assignmentMethod',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.pcpAssignment
                    }
                },
                N: {
                    fieldDisplay: 'Medical Plan PCP Id',
                    fieldPath: 'pcpAssignment.0.pcpCode',
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                O: {
                    fieldDisplay: 'Existing Patient',
                    fieldPath: 'pcpAssignment.0.existingPatient',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                P: {
                    fieldDisplay: 'Dental Plan',
                    fieldPath: ['productSelection.0.dental', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isDentalWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                R: {
                    fieldDisplay: 'Vision Plan',
                    fieldPath: ['productSelection.0.vision', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isVisionWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                S: {
                    fieldDisplay: 'Life Plan',
                    fieldPath: ['productSelection.0.life', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isLifeWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                U: {
                    fieldDisplay: 'Supplemental Life Plan',
                    fieldPath: ['productSelection.0.supplife', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isSuppLifeWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                V: {
                    fieldDisplay: 'Supplemental Dependent Life Spouse Plan',
                    fieldPath: ['productSelection.0.suppLife', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isSuppLifeWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                W: {
                    fieldDisplay: 'Supplemental Dependent Life Child Plan',
                    fieldPath: ['productSelection.0.suppLife', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isSuppLifeWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                X: {
                    fieldDisplay: 'Short Term Disability Plan',
                    fieldPath: ['productSelection.0.std', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isSTDWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                Y: {
                    fieldDisplay: 'Long Term Disability Plan',
                    fieldPath: ['productSelection.0.ltd', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isLTDWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                AA: {
                    fieldDisplay: 'Number Of Hours Worked',
                    fieldPath: 'employeeInfo.numberOfHoursWorked',
                    required: true,
                    constraints: {
                        // dataType: [dataTypes.string]
                        regex: regexTypes.number,
                    }
                },
                AB: {
                    fieldDisplay: 'Salary',
                    fieldPath: ['salary', 'employeeInfo.salary'],
                    fieldValue: [value => value.toString(), value => value.toString()],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.number]
                    }
                },
                AC: {
                    fieldDisplay: 'Date Of Hire',
                    fieldPath: 'employeeInfo.dateOfHire',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AD: {
                    fieldDisplay: 'Out Of Area',
                    fieldPath: ['outOfArea', 'contactInfo.outOfArea'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                AE: {
                    fieldDisplay: 'SSNTin',
                    fieldPath: ['employeeInfo.ssnTin', 'employeeInfo.ssnCheck'],
                    fieldValue: [value => value, helper.isEmpty],
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AF: {
                    fieldDisplay: 'No SSN Reason',
                    fieldPath: ['employeeInfo.noSSNReason', 'employeeInfo.ssnCheck'],
                    fieldValue: [value => value, helper.isNotEmpty],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.noSSNReason
                    }
                },
                AG: {
                    fieldDisplay: 'Street Address',
                    fieldPath: 'contactInfo.streetAddress',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AH: {
                    fieldDisplay: 'City',
                    fieldPath: 'contactInfo.city',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AI: {
                    fieldDisplay: 'State',
                    fieldPath: 'contactInfo.state',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AK: {
                    fieldDisplay: 'ZipCode',
                    fieldPath: ['zipCode', 'contactInfo.zipCode'],
                    fieldValue: [value => value, value => value],
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AL: {
                    fieldDisplay: 'County',
                    fieldPath: 'contactInfo.county',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AM: {
                    fieldDisplay: 'Email',
                    fieldPath: 'contactInfo.email',
                    required: false,
                    constraints: {
                        regex: regexTypes.email
                    }
                },
                AN: {
                    fieldDisplay: 'Telephone',
                    fieldPath: 'contactInfo.telephone',
                    required: false,
                    constraints: {
                        regex: regexTypes.telephone
                    }
                },
                AO: {
                    fieldDisplay: 'Telephone Type',
                    fieldPath: 'contactInfo.telephoneType',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.telephoneType
                    }
                },
                AP: {
                    fieldDisplay: 'Any Other Coverage',
                    fieldPath: 'employeeInfo.anyOtherCoverage',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                AQ: {
                    fieldDisplay: 'Name Other Carrier',
                    fieldPath: 'employeeInfo.nameOtherCarrier',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.nameOtherCarrier
                    }
                },
                AR: {
                    fieldDisplay: 'Medicare Primary',
                    fieldPath: 'employeeInfo.medicarePrimary',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                AS: {
                    fieldDisplay: 'Medicare Reason',
                    fieldPath: 'employeeInfo.medicareReason',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.medicareReason
                    }
                },
                AT: {
                    fieldDisplay: 'Medicare Claim Number',
                    fieldPath: 'employeeInfo.medicareClaimNum',
                    required: false,
                    constraints: {
                        dataType: [dataTypes.number]
                    }
                },
                AU: {
                    fieldDisplay: 'Medicare PartA Start Date',
                    fieldPath: ['employeeInfo.medicarePartAStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part A' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AV: {
                    fieldDisplay: 'Medicare PartB Start Date',
                    fieldPath: ['employeeInfo.medicarePartBStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part B' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AW: {
                    fieldDisplay: 'Medicare PartD Start Date',
                    fieldPath: ['employeeInfo.medicarePartDStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part D' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AX: {
                    fieldDisplay: 'COBRA Start Date',
                    fieldPath: 'employeeInfo.cobraStartDate',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AY: {
                    fieldDisplay: 'COBRA End Date',
                    fieldPath: 'employeeInfo.cobraEndDate',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                BH: {
                    fieldDisplay: 'Workers Comp',
                    fieldPath: 'employeeInfo.aoCoverageEmployee',
                    fieldValue: value =>  value,
                    required: helper.isWorkersCompSelected(),
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                }
            },
            [userTypes.dependent]: {
                B: {
                    fieldDisplay: 'Relationship',
                    fieldPath: 'relationship',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.relationship
                    }
                },
                D: {
                    fieldDisplay: 'First Name',
                    fieldPath: 'firstName',
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                E: {
                    fieldDisplay: 'Last Name',
                    fieldPath: 'lastName',
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialCharacters,
                        minLength: 1
                    }
                },
                F: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'middleInitial',
                    required: false,
                    constraints: {
                        regex: regexTypes.capitalCharacter,
                        length: 1
                    }
                },
                G: {
                    fieldDisplay: 'Suffix',
                    fieldPath: 'suffix',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.suffix
                    }
                },
                H: {
                    fieldDisplay: 'Gender',
                    fieldPath: 'gender',
                    fieldValue: helper.handleGender,
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.gender
                    }
                },
                I: {
                    fieldDisplay: 'Date Of Birth',
                    fieldPath: 'dob',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object],
                    }
                },
                J: {
                    fieldDisplay: 'Waive All Products',
                    fieldPath: 'waiveCoverage.isWaivingAll',
                    fieldValue: helper.isWaivingAll,
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.isWaivingAll
                    }
                },
                K: {
                    fieldDisplay: 'Medical Plan',
                    fieldPath: ['productSelection.0.medical', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isMedicalWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                M: {
                    fieldDisplay: 'Auto Assign PCP',
                    fieldPath: ['pcpAssignment.0.assignmentMethod'],
                    fieldValue: [value => value],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.pcpAssignment
                    }
                },
                N: {
                    fieldDisplay: 'Medical Plan PCP Id',
                    fieldPath: ['pcpAssignment.0.pcpCode'],
                    fieldValue: [value => value],
                    required: false,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                O: {
                    fieldDisplay: 'Existing Patient',
                    fieldPath: ['pcpAssignment.0.existingPatient'],
                    fieldValue: [value => value],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                P: {
                    fieldDisplay: 'Dental Plan',
                    fieldPath: ['productSelection.0.dental', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isDentalWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                R: {
                    fieldDisplay: 'Vision Plan',
                    fieldPath: ['productSelection.0.vision', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isVisionWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                T: {
                    fieldDisplay: 'Life Plan',
                    fieldPath: ['productSelection.0.life', 'waiveOption.i'],
                    fieldValue: [helper.isProductWaived, helper.isLifeWaived],
                    required: true,
                    constraints: {
                        regex: regexTypes.noSpecialButNumbersAllowed,
                    }
                },
                AE: {
                    fieldDisplay: 'SSNTin',
                    fieldPath: ['ssnTin', 'ssnCheck'],
                    fieldValue: [value => value, helper.isEmpty],
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AF: {
                    fieldDisplay: 'No SSN Reason',
                    fieldPath: ['noSSNReason', 'ssnCheck'],
                    fieldValue: [value => value, helper.isNotEmpty],
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.noSSNReason
                    }
                },
                AG: {
                    fieldDisplay: 'Street Address',
                    fieldPath: 'streetAddress',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AH: {
                    fieldDisplay: 'City',
                    fieldPath: 'city',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AI: {
                    fieldDisplay: 'State',
                    fieldPath: 'state',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AK: {
                    fieldDisplay: 'ZipCode',
                    fieldPath: 'zipCode',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AL: {
                    fieldDisplay: 'County',
                    fieldPath: 'county',
                    required: true,
                    constraints: {
                        dataType: [dataTypes.string]
                    }
                },
                AN: {
                    fieldDisplay: 'Telephone',
                    fieldPath: 'telephone',
                    required: false,
                    constraints: {
                        regex: regexTypes.telephone
                    }
                },
                AP: {
                    fieldDisplay: 'Any Other Coverage',
                    fieldPath: 'otherCarrier',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                AQ: {
                    fieldDisplay: 'Name Other Carrier',
                    fieldPath: 'nameOtherCarrier',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.nameOtherCarrier
                    }
                },
                AR: {
                    fieldDisplay: 'Medicare Primary',
                    fieldPath: 'medicarePrimary',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                AS: {
                    fieldDisplay: 'Medicare Reason',
                    fieldPath: 'medicareReason',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.medicareReason
                    }
                },
                AT: {
                    fieldDisplay: 'Medicare Claim Number',
                    fieldPath: 'medicareClaimNum',
                    required: false,
                    constraints: {
                        dataType: [dataTypes.number]
                    }
                },
                AU: {
                    fieldDisplay: 'Medicare PartA Start Date',
                    fieldPath: ['medicarePartAStartDate', 'medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part A' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AV: {
                    fieldDisplay: 'Medicare PartB Start Date',
                    fieldPath: ['medicarePartBStartDate', 'medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part B' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AW: {
                    fieldDisplay: 'Medicare PartD Start Date',
                    fieldPath: ['medicarePartDStartDate', 'medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part D' : undefined],
                    required: false,
                    constraints: {
                        dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                BA: {
                    fieldDisplay: 'Is Dependent Permanently Disabled',
                    fieldPath: 'isDisabled',
                    required: true,
                    constraints: {
                        dropDownValues: dropDowns.yesNo
                    }
                },
                BB: {
                    fieldDisplay: 'Disability Type',
                    fieldPath: 'disabilityType',
                    required: false,
                    constraints: {
                        dropDownValues: dropDowns.disabilityType
                    }
                }
            }
        }
    }
}

function getEnrollmentFormMapping() {
    return {
        metaData: {
            isIterative: true,
            baseRow: 7
        },
        mapping: {
            [userTypes.employee]: {
                A: {
                    fieldDisplay: 'SSNTin',
                    fieldPath: ['employeeInfo.ssnTin', 'employeeInfo.ssnCheck'],
                    fieldValue: [helper.formatSSN, helper.isEmpty],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string],
                        // regex: regexTypes.ssnTin
                    }

                },
                D: {
                    fieldDisplay: 'Last Name',
                    fieldPath: ['lastName', 'employeeInfo.lastName'],
                    fieldValue: [value => value, value => value],
                    required: false,
                    constraints: {
                        //regex: regexTypes.noSpecialCharacters,
                    }
                },
                E: {
                    fieldDisplay: 'First Name',
                    fieldPath: ['firstName', 'employeeInfo.firstName'],
                    fieldValue: [value => value, value => value],
                    required: false,
                    constraints: {
                        // regex: regexTypes.noSpecialCharacters,
                    }
                },
                F: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'employeeInfo.middleInitial',
                    required: false,
                    constraints: {
                        //length: 1,
                        //regex: regexTypes.capitalCharacter,
                    }
                },
                G: {
                    fieldDisplay: 'Gender',
                    fieldPath: ['gender', 'employeeInfo.gender'],
                    fieldValue: [helper.handleGender, helper.handleGender],
                    required: false,
                    constraints: {
                        //dropDownValues: dropDowns.gender
                    }
                },
                H: {
                    fieldDisplay: 'Date of Birth',
                    fieldPath: ['dob', 'employeeInfo.dob'],
                    required: false,
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, helper.formatToMMDDYYYYFormatSlash],
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object],
                        //validDate: helper.isValidDate
                    }
                },
                N: {
                    fieldDisplay: 'Street Address',
                    fieldPath: 'contactInfo.streetAddress',
                    required: false,
                    constraints: {
                        // dataType: [dataTypes.string]
                    }
                },
                O: {
                    fieldDisplay: 'City',
                    fieldPath: 'contactInfo.city',
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string]
                    }
                },
                P: {
                    fieldDisplay: 'State',
                    fieldPath: 'contactInfo.state',
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string]
                    }
                },
                Q: {
                    fieldDisplay: 'ZipCode',
                    fieldPath: ['zipCode', 'contactInfo.zipCode'],
                    fieldValue: [helper.zipLeadingZerosToString, helper.zipLeadingZerosToString],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string]
                    }
                },
                R: {
                    fieldDisplay: 'Telephone',
                    fieldPath: 'contactInfo.telephone',
                    required: false,
                    fieldValue: helper.formatPhoneNumber,
                    constraints: {
                        //regex: regexTypes.telephone
                    }
                },

                T: {
                    fieldDisplay: 'Email',
                    fieldPath: 'contactInfo.email',
                    required: false,
                    constraints: {
                        //regex: regexTypes.email
                    }
                },
                V: {
                    fieldDisplay: 'Date Of Hire',
                    fieldPath: 'employeeInfo.dateOfHire',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    // required: true,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                X: {
                    fieldDisplay: 'COBRA Start Date',
                    fieldPath: 'employeeInfo.cobraStartDate',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                Y: {
                    fieldDisplay: 'COBRA End Date',
                    fieldPath: 'employeeInfo.cobraEndDate',
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                AM: {
                    fieldDisplay: 'Salary',
                    fieldPath: ['salary', 'employeeInfo.salary'],
                    fieldValue: [helper.formatNumberToString, helper.formatNumberToString],
                    required: false,
                    constraints: {
                        // regex: regexTypes.number
                    }
                },
                AQ: {
                    fieldDisplay: 'Number Of Hours Worked',
                    fieldPath: 'employeeInfo.numberOfHoursWorked',
                    fieldValue: helper.formatNumberOfHours,
                    //required: true,
                    constraints: {
                        // dataType: [dataTypes.string]
                        // regex: regexTypes.number,
                    }
                },
                BI: {
                    fieldDisplay: 'Medicare PartA Start Date',
                    fieldPath: ['employeeInfo.medicarePartAStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part A' : undefined],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                BK: {
                    fieldDisplay: 'Medicare PartB Start Date',
                    fieldPath: ['employeeInfo.medicarePartBStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part B' : undefined],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                BM: {
                    fieldDisplay: 'Medicare PartD Start Date',
                    fieldPath: ['employeeInfo.medicarePartDStartDate', 'employeeInfo.medicareCoverages.i'],
                    fieldValue: [helper.formatToMMDDYYYYFormatSlash, value => helper.formatToMMDDYYYYFormatSlash(value) ? 'Part D' : undefined],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string, dataTypes.object]
                    }
                },
                BW: {
                    fieldDisplay: 'Medical Waiver Reason',
                    fieldPath: ['waiveCoverage.medicalWaiverReason', 'medicalCoverageInfo.medicalWaiverReason', 'waiveReason'],
                    fieldValue: [value => value, value => value, value => value],
                    required: false,
                    constraints: {
                        //dropDownValues: helper.getMedicalWaiverReasons()
                    }
                },
               

            },
            [userTypes.dependent]: {
                B: {
                    fieldDisplay: 'SSNTin',
                    fieldPath: ['ssnTin', 'ssnCheck'],
                    fieldValue: [helper.formatSSN, helper.isEmpty],
                    required: false,
                    constraints: {
                        //dataType: [dataTypes.string],
                        // regex: regexTypes.ssnTin
                    },
                },

                C: {
                    fieldDisplay: 'Relationship',
                    fieldPath: 'relationship',
                    fieldValue: helper.getRelationship,
                    required: true,
                    constraints: {
                        //dropDownValues: dropDowns.relationship
                    }
                },
                D: {
                    fieldDisplay: 'Last Name',
                    fieldPath: 'lastName',
                    required: false,
                    constraints: {
                        //regex: regexTypes.noSpecialCharacters,
                        //minLength: 1
                    }
                },
                E: {
                    fieldDisplay: 'First Name',
                    fieldPath: 'firstName',
                    required: false,
                    constraints: {
                        // regex: regexTypes.noSpecialCharacters,
                        //minLength: 1
                    }
                },
                F: {
                    fieldDisplay: 'Middle Initial',
                    fieldPath: 'middleInitial',
                    required: false,
                    constraints: {
                        //regex: regexTypes.capitalCharacter,
                        //length: 1
                    }
                },
                G: {
                    fieldDisplay: 'Gender',
                    fieldPath: 'gender',
                    fieldValue: helper.handleGender,
                    required: false,
                    constraints: {
                        //dropDownValues: dropDowns.gender
                    }
                },
                H: {
                    fieldDisplay: 'Date of Birth',
                    fieldPath: 'dob',
                    required: false,
                    fieldValue: helper.formatToMMDDYYYYFormatSlash,
                    constraints: {
                        // dataType: [dataTypes.string, dataTypes.object],
                        //validDate: helper.isValidDate
                    }
                },

            }
        }
    }
}
