import deepCopy from '../../../../../utils/deepCopy';

// primary location is the location with the most Total Eligible Employees
const getPrimaryLocationHelper = (locations, returnIndex = false) => {
    if (locations.length === 0) {
        return returnIndex ? -1 : null;
    }
    const indexedLocations = locations.map((loc, index) => ({ index, ...loc }));

    let primaryLocationArray = indexedLocations.filter((loc) => loc.primaryInd);

    if (primaryLocationArray.length === 0) {
        primaryLocationArray = deepCopy(indexedLocations)
            .sort((loc1, loc2) => parseInt(loc2.totalEligEmps, 10) - parseInt(loc1.totalEligEmps, 10));
    }

    const primaryLocation = primaryLocationArray[0];

    if (returnIndex) {
        return primaryLocation.index;
    }

    delete primaryLocation.index;

    return primaryLocation;
};

export const getPrimaryLocationIdx = (locations) => getPrimaryLocationHelper(locations, true);

export const getPrimaryLocation = (locations) => getPrimaryLocationHelper(locations, false);

export const getPrimaryLocationStateCode = (locations) => {
    const primaryLocation = getPrimaryLocation(locations);
    if (primaryLocation === null) {
        return '';
    }
    if (primaryLocation.zipData && primaryLocation.zipData.stateCode) {
        return primaryLocation.zipData.stateCode;
    }
    return '';
};

// check if primary location matches the stateCode
export const checkPrimaryLoc = (locations, stateCode) => {
    const primaryLocationStateCode = getPrimaryLocationStateCode(locations);
    return primaryLocationStateCode === stateCode;
};
