import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { history } from '../utils/history';
import sendLog from './logActions';
import { auditUserAction } from './actionHelpers/createHistoryHelper';
import {specialtyServices, allNonASOServices, LIFE, LIFE_DEP_BASIC, STD, LTD, SUPPLIFE,SUPP_DEP_LIFE} from '../utils/consts/consts';
import { getEnrollmentRules } from './rulesActions';
import { checkZipObj } from '../utils/checkers/propTypes';
import { isEmpty } from '../utils/formatters/strings';
import {getPlanCodeBatches, checkIsInternalUser} from './fullQuotesActions'
import {updateBodyForOverRideRates} from './fullQuotesActions'
import {updateSupportedAreaFS, getRatingCount} from './companyProfileActions'
import {getLifeRatesInClasses} from '../actions/actionHelpers/lifeClassRatesActionHelper';
import deepCopy from '../utils/deepCopy';
import { getQuickQuoteRatesRenewal, setDisplayOfPreferredPlans, setDisplayOfPreferredPlansToggle } from './renewalShoppingActions';
import { createHistory } from './historyAction';
import { shoppingRequest } from '../components/activities/Renewal/RenewalShopping/ShoppingUtils';
import { identifyPlatform, getExistingCoverageForSpecialty, getARQRatioForSpecialtyUhcRenewals } from '../components/activities/Renewal/Review/reviewUtils';
import {isWasinghtonTwoTierCase, doStopRateCalls} from './actionHelpers/fullQuoteActionHelper';
import {restrictUserIfNotLicensed} from './actionHelpers/fullQuoteActionHelper';
import {  requestOldRatesBatch, getAllRidersRatesQQ } from './benefitRiderRatesActions';
import { getMembersMappingFromIBR } from '../utils/Renewal/uhcRenewalDataHelper';
import { isUhcRenewals, getMarketType, MARKET_TYPES } from '../utils/businessLogic/platformUtils'
import { updateOverridesRequestBody } from '../utils/Renewal/uhcRenewalsOverrides';
import { UHC_OXFORD_MEDICAL_PLAN_ERROR, UHC_OXFORD_MEDICAL_RATE_ERROR, UHC_OXFORD_SPECIALTY_PLAN_ERROR, UHC_OXFORD_SPECIALTY_RATE_ERROR, UHC_BENEFIT_RIDER_RATE_ERROR, KEY_NAME } from '../utils/errorLogging/logKeys';
import { logError } from '../utils/errorLogging/logError';
import { sendClickEventDataAction } from './analyticsAction';
import { updateEnrolledPlansRates } from './enrollmentActions';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { getNBCaseProgress, inSubmittedStatus } from '../reducers/products/helpers/companyProfileConsts';
import { isUndefinedOrNull } from '../utils/validation/commonValidations';
import { displayTieredCoverageTypeViaProducts } from "../components/activities/Renewal/RenewalsCommonComponents/saveDetails";
import { isoStringToMMDDYYYY } from '../utils/formatters/dates';

const coverageMapping = {
    "EO": "EE",
    "EC": "EE/CH",
    "ES": "EE/SP",
    "EF": "EE/FAM",
}

const apiRoutes = require('../utils/api/apiRouteConfig');
// Request the rates for plans, 10 rates at a time, and configure the resulting rates
//
// Parameters
//  ratesRequest:           Request object holding zipCode,
//                          effectiveDate, stateCode, platform, medicalPlanCodes
//  isCirrus:               Boolean determining whether the desired system is
//                          Cirrus (1) or Prime (0)
//  index:                  Keeps tabs on how many rates are left to
//                          gather (due to having to fetch them 10 at a time)
//  groupSize:              Determined by user entry on form
function requestRateBatch(ratesRequest,
    isCirrus, index, groupSize, filteredResultsOnly, otherDetails = null, productType) { // TODO ::: Remove argument
    return (dispatch, getState) => {
        const { service } = ratesRequest;
        const state = getState();
        const { specialtyCriteria, overall, companyProfile, caseTracking } = state
        const { simpleCensus } = otherDetails;
        const { primaryLocationIdx , employees, locations} = companyProfile;

        dispatch({
            type: types.SERVICE[service.typeName].REQUEST_QUICK_QUOTE_RATES,
        });

        let employeeCounts = '';
        if (service.apiKeyName === 'dental') {
            employeeCounts = [specialtyCriteria.dentalCensusSummary.employeeOnly,
            specialtyCriteria.dentalCensusSummary.employeeWithSpouse,
            specialtyCriteria.dentalCensusSummary.employeeWithChild,
            specialtyCriteria.dentalCensusSummary.employeeWithFamily,
            ];
        } 
        else if (service.apiKeyName === 'vision') {
            employeeCounts = [specialtyCriteria.visionCensusSummary.employeeOnly,
            specialtyCriteria.visionCensusSummary.employeeWithSpouse,
            specialtyCriteria.visionCensusSummary.employeeWithChild,
            specialtyCriteria.visionCensusSummary.employeeWithFamily,
            ];
        }
        else if (service.apiKeyName === 'shortTermDis'){
            employeeCounts = [specialtyCriteria.stdCensusSummary.employeeOnly,
                specialtyCriteria.stdCensusSummary.employeeWithSpouse,
                specialtyCriteria.stdCensusSummary.employeeWithChild,
                specialtyCriteria.stdCensusSummary.employeeWithFamily,
                ];
        }
        else if (service.apiKeyName === 'longTermDis'){
            employeeCounts = [specialtyCriteria.ltdCensusSummary.employeeOnly,
                specialtyCriteria.ltdCensusSummary.employeeWithSpouse,
                specialtyCriteria.ltdCensusSummary.employeeWithChild,
                specialtyCriteria.ltdCensusSummary.employeeWithFamily,
                ];
        }
        else if (service.apiKeyName === 'life' && otherDetails && otherDetails.employeeCensus) {
            // Added Platform Check UNtill SW is not ready with BL Cirrus Plans
            const platform = ratesRequest.platform
            // US2988455 Dependant life
            let planCodeDetails = [];
            for (let i = 0; i < ratesRequest.planCodes.length; i++) {
                let newPlan = {
                    planCode: ratesRequest.planCodes[i],
                    depLifeSpouseAmount: ratesRequest.spouseBenefitAmount[i],
                    depLifeChildAmount: ratesRequest.childBenefitAmount[i]
                }
                planCodeDetails.push(newPlan);
            }

            let employeesCopy = deepCopy(otherDetails.employeeCensus);
            if (getMarketType() === MARKET_TYPES.UHC && ratesRequest.stateCode === "CA") {
                employeesCopy = employeesCopy.filter(emp => emp.employeeStatus !== '1099 Independent Contractors');
            }

            const employeesMap = employeesCopy.map((emp, i) => {
                emp.employeeId = emp.memberId ? emp.memberId : emp.employeeId ? emp.employeeId : i + 1;                
                return emp
            });

            // US2988455 Dependant life
            const basicLifeRateRequest = {
                groupSize,
                zipCode: ratesRequest.zipCode,
                stateCode: ratesRequest.stateCode,
                countyCode: ratesRequest.countyFIPSCode,
                effectiveDate: ratesRequest.effectiveDate,
                platform: platform,
                basicLifePlanCodes: planCodeDetails,
                blPlanCode: ratesRequest.blPlanCodes,
                combinedCodes:ratesRequest.combinedCodes,
                empCensus: employeesMap,
                sicCode : ratesRequest.sicCode.toString(),
                ratingCallType : overall.source === 'full' ? 'shopping-full-quote' : 'shopping-quick-quote',
                companyName : overall.source === 'full' ? companyProfile.companyName : 'Quick Quote',
                franchiseCode : overall.source === 'full' ? !isEmpty(companyProfile.franchiseCode) ? companyProfile.franchiseCode.toString() : '' : '0000000',
                quoteStatus: "Shopping",
                caseId: `${caseTracking.caseId}-${caseTracking.currentQuoteId}`,
                parentId: caseTracking.currentQuoteId ? `${caseTracking.currentQuoteId}` : '-'
            };
            updateBodyForOverRideRates(basicLifeRateRequest, getState(), true);

            if (state.overall.quickQuoteRequest.source === "renewal") {
                basicLifeRateRequest.requestType = "renewal";
            }
            
            if (isUhcRenewals()) {
                const overRideRates = updateOverridesRequestBody(basicLifeRateRequest, getState());
                basicLifeRateRequest.overRideRates = overRideRates;
                delete basicLifeRateRequest.ratingCallType;
                basicLifeRateRequest.requestType = "renewal";
                basicLifeRateRequest.existingCoverage = otherDetails.existingCoverage && Array.isArray(otherDetails.existingCoverage) ? otherDetails.existingCoverage.includes('life') : false;;
                basicLifeRateRequest.quoteStatus = "Shopping"
                basicLifeRateRequest.companyName = companyProfile.companyName ? companyProfile.companyName : ''
                basicLifeRateRequest.isUhcRenewals = true
                const renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(state.renewalShoppingHelper && state.renewalShoppingHelper.renewalData ? state.renewalShoppingHelper.renewalData :state.review && state.review.renewalData , 'BASIC_LIFE_EE')
                basicLifeRateRequest.arqRatio = ratesRequest.blPlanCodes.map(_ => renewingPlanARQRatio)
                basicLifeRateRequest.eligibleCount = groupSize
                basicLifeRateRequest.custNum = getState().review.renewalCaseData?.primeCustomerNumber ? getState().review.renewalCaseData?.primeCustomerNumber :  getState().renewalShopping?.renewalCaseData?.primeCustomerNumber
            }
            return new Promise((resolve, reject) => {
                axios.post(`${apiRoutes.quickQuotes}/basic-life-rates`, basicLifeRateRequest)
                    .then((res) => {
                        const rates = res.data.lifeRates;
                        const specialtyCriteria = state.specialtyCriteria;
                        const uhcRenewalFlag = isUhcRenewals();
                        const isSourceRenewal = state.overall.quickQuoteRequest.source === "renewal";
                        const allEmployees = isSourceRenewal ? [state.overall.quickQuoteRequest.otherDetails.employeeCensus] : state.companyProfile.employees;
                        if (rates) {
                            if(productType === LIFE_DEP_BASIC) {
                                dispatch({
                                    type: types.RECEIVE_DEPENDENT_LIFE_RATES,
                                    index,
                                    rates,
                                    filteredResultsOnly,
                                    allEmployees,
                                    specialtyCriteria,
                                    uhcRenewalFlag,
                                    isSourceRenewal
                                })
                                dispatch(updateEnrolledPlansRates(rates, 'dependentLife'))
                            } else {
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_RATES,
                                    index,
                                    rates,
                                    filteredResultsOnly,
                                    employees: state.companyProfile.employees.flat()
                                });
                                dispatch(updateEnrolledPlansRates(rates, 'life'))
                            }
                        }
                        resolve();
                    })
                    .catch((err) => {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE,
                        });

                        dispatch({
                            type: types.SERVICE[service.typeName].ERROR_QUICK_QUOTE_RATES,
                            payload: formatPlanOrRateCallError(err, 'rate', true)
                        });
                        logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'requestRateBatch', { type: 'Life', requestBody: basicLifeRateRequest }, err);
                        reject(err);
                    });
            });
        } else {
            employeeCounts = [specialtyCriteria.employeeOnly, specialtyCriteria.employeeWithSpouse,
            specialtyCriteria.employeeWithChild, specialtyCriteria.employeeWithFamily,
            ];
        }

        let caseId = state.caseTracking && state.caseTracking.caseId && state.caseTracking.currentQuoteId ? `${state.caseTracking.caseId}-${state.caseTracking.currentQuoteId}` : null
        let companyName = companyProfile && companyProfile.companyName ? companyProfile.companyName : null

        if ((service.apiKeyName !== 'life') && (service.apiKeyName === 'dental' || service.apiKeyName === 'vision' || service.apiKeyName === 'shortTermDis' || service.apiKeyName === 'longTermDis'|| service.apiKeyName === 'suppLife')) {
            const reqBody = {
                groupSize,
                zipCode: ratesRequest.zipCode,
                stateCode: ratesRequest.stateCode,
                countyCode: ratesRequest.countyFIPSCode,
                effectiveDate: ratesRequest.effectiveDate,
                platform: ratesRequest.platform,
                sicCode: ratesRequest.sicCode,
                quoteStatus: 'Shopping',
                caseId: caseId,
                companyName: companyName
            };

            // Attach census for full shopping, or QQ group size > 50
            if (overall.source === 'full' || (otherDetails.employeeCensus && groupSize > 50)) {
                // For NY zip codes, if user is using simple census
                if (simpleCensus && (simpleCensus.ee !== '' || simpleCensus.es !== '' || simpleCensus.ec !== '' || simpleCensus.ef !== '')) {
                    employeeCounts = [simpleCensus.ee, simpleCensus.es, simpleCensus.ec, simpleCensus.ef];
                    reqBody.employeeCounts = employeeCounts;
                    // Set the census counts in redux for specialty products
                    const formattedCensusCounts = {
                        employeeOnly: simpleCensus.ee,
                        employeeWithSpouse: simpleCensus.es,
                        employeeWithChild: simpleCensus.ec,
                        employeeWithFamily: simpleCensus.ef,
                    };
                    dispatch(setSpecialtyCriteria({
                        ...specialtyCriteria,
                        dentalCensusSummary: formattedCensusCounts,
                        visionCensusSummary: formattedCensusCounts,
                        lifeCensusSummary: formattedCensusCounts,
                        stdCensusSummary:formattedCensusCounts,
                        ltdCensusSummary:formattedCensusCounts,
                    }));
                } else {
                    let employeesCopy = deepCopy(otherDetails.employeeCensus);
                    if (getMarketType() === MARKET_TYPES.UHC && ratesRequest.stateCode === "CA") {
                        employeesCopy = employeesCopy.filter(emp => emp.employeeStatus !== '1099 Independent Contractors');
                    }

                    const employeesMap = employeesCopy.map((emp, i) => {
                        emp.employeeId = emp.memberId ? emp.memberId : i + 1;
                        return emp
                    });

                    reqBody.empCensus = employeesMap;
                }

                // Add the rating-call type depending upon whether called in quick-quotes or full shopping.
                reqBody.ratingCallType = 'shopping-full-quote' //shopping quick quotes
                reqBody.companyName = companyProfile.companyName;
                reqBody.franchiseCode = !isEmpty(companyProfile.franchiseCode) ? companyProfile.franchiseCode : '';

            } else { // Attach employee counts for QQ group size < 50, since we don't have full census
                reqBody.employeeCounts = employeeCounts;
                reqBody.ratingCallType = 'shopping-quick-quote'
            }

            if (ratesRequest.service.typeName === 'DENTAL') reqBody.dentalPlanCodes = JSON.stringify(ratesRequest.planCodes);
            if (ratesRequest.service.typeName === 'VISION') reqBody.visionPlanCodes = JSON.stringify(ratesRequest.planCodes);
            if (ratesRequest.service.typeName === 'STD') reqBody.shortTermDisPlanCodes = JSON.stringify(ratesRequest.planCodes);
            if (ratesRequest.service.typeName === 'LTD') reqBody.longTermDisPlanCodes = JSON.stringify(ratesRequest.planCodes);
            if (ratesRequest.service.typeName === 'SUPPLIFE') reqBody.suppLifePlanCodes = JSON.stringify(ratesRequest.planCodes);

            updateBodyForOverRideRates(reqBody, getState());

            if (ratesRequest.rateCallTier) {
                reqBody.ratingTier = ratesRequest.rateCallTier
            }

            return new Promise((resolve, reject) => {
                axios.post(`${apiRoutes.quickQuotes}/specialty-rates`, reqBody)
                    .then((res) => {
                        const rates = res.data[`${service.apiKeyName}Rates`];
                        dispatch(createHistory(`Get ${service.apiKeyName} Rates`, 'Quick Quotes Action file', 'Success', reqBody, '', 'NB'));
                        let quotes;

                        if (rates) {
                            if(!(service.typeName === 'STD' || service.typeName === 'LTD' || service.typeName === 'SUPPLIFE') ){
                                quotes = rates.map((rate) => (rate.byCensus.totalMonthlyPremium));
                            }
                            dispatch({
                                type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates,
                                quotes,
                                filteredResultsOnly,
                                isCirrus,
                                rateCallTier: ratesRequest.rateCallTier ? ratesRequest.rateCallTier : '',
                                selectedTier: ratesRequest.selectedTier ? ratesRequest.selectedTier : ''
                            });
                            dispatch(updateEnrolledPlansRates(rates, service.apiKeyName))
                        }
                        resolve();
                    })
                    .catch((err) => {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });

                        dispatch({
                            type: types.SERVICE[service.typeName].ERROR_QUICK_QUOTE_RATES,
                            payload: formatPlanOrRateCallError(err, 'rate', true)
                        });
                        logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'requestRateBatch', { type: 'Dental/Vision', requestBody: reqBody }, err);
                        reject(err);
                    });
                });
            }


            if (service.apiKeyName !== 'life' && service.apiKeyName !== 'dental' && service.apiKeyName !== 'vision' && service.apiKeyName !== 'shortTermDis' && service.apiKeyName !== 'longTermDis'  && service.apiKeyName !== 'suppLife') {
                const requestBody={
                    groupSize:groupSize,
                    zipCode: ratesRequest.zipCode,
                    stateCode: ratesRequest.stateCode,
                    countyCode: ratesRequest.countyFIPSCode,
                    effectiveDate: ratesRequest.effectiveDate,
                    platform:ratesRequest.platform,
                    sicCode:`${ratesRequest.sicCode}`,
                    medicalPlanCodes:ratesRequest.planCodes,
                    employeeCounts:employeeCounts,
                    OISABit: ratesRequest.OISABit ? ratesRequest.OISABit : {},
                    employees: employees ? employees[primaryLocationIdx] : [],
                    location: locations ? locations[primaryLocationIdx] : {},
                    ratingCount: overall.source === 'full' ? getRatingCount(locations,primaryLocationIdx) : groupSize.toString(),
                    companyName: companyName ? companyName : ''
                }
                return new Promise((resolve, reject) => {
                    axios.post(`${apiRoutes.quickQuotes}/rates/startRates`, requestBody)
                        .then(async (res) => {
                            const results = await getTransactionResult(res.data.transactionId);
                            if (results.statusCode === 400) {
                                throw await results;
                            }
                            const rates = results.medicalRates;
                            let quotes;
                            if (rates && !doStopRateCalls()) {
                                if (service.apiKeyName === 'medical') {
                                    quotes = null;
                                } else {
                                    quotes = rates.map((rate) => rate.byCensus.totalMonthlyPremium);
                                }
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_RATES,
                                    index,
                                    rates,
                                    quotes,
                                    filteredResultsOnly,
                                    employees: state.companyProfile.employees.flat()
                                });
                                dispatch(updateEnrolledPlansRates(rates, 'medical'))
                                dispatch(createHistory('Get Medical Rates', 'Quick Quotes Action file', 'Success', requestBody, '', 'NB'));
                            }
                            resolve();
                        })
                        .catch((err) => {
                            dispatch({
                                type: types.DISPLAY_ERROR_MESSAGE,
                            });

                            dispatch({
                                type: types.SERVICE[service.typeName].ERROR_QUICK_QUOTE_RATES,
                                payload: formatPlanOrRateCallError(err, 'rate', false)
                            });
                            logError(KEY_NAME, UHC_OXFORD_MEDICAL_RATE_ERROR, 'requestRateBatch', { type: 'Medical', requestBody: requestBody }, err);
                            reject(err);
                        });
                });
            } else {
                return new Promise((resolve, reject) => {
                    resolve();
                })
            }
    };
}

/**
 *
 * @param {object} plansObj object returned by API including medicalPlans, dentalPlans, etc.
 * @param {number} groupSize number of employees
 * @param {string} zipCode location zip code
 * @param {string} effectiveDate MM-DD-YYYY
 * @param {string} stateCode two letter string
 * @param {string} countyFIPSCode county FIPS code, a 3-digit number
 */
export function getQuickQuoteRates(plansObj, groupSize, zipCode, effectiveDate,
    stateCode, countyFIPSCode, sicCode, otherDetails = null, productType = '') {
    return (dispatch, getState) => {
        const { medicalPlans } = plansObj;
        let { platform } = otherDetails;
        if (medicalPlans) {
            platform = medicalPlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
        }
        const isCirrus = platform === 'CIRRUS';
        const staticRequestInfo = {
            groupSize,
            zipCode,
            effectiveDate,
            countyFIPSCode,
            stateCode,
            platform,
            sicCode,
        };

        // From the list of plans we receive, we assemble a list of batches of size <= 10, to send to the getRates API
        // Assemble our API request batches, passing in a list of medical codes and other info for each plan

        const rateRequestsObj = {};

        // Assemble the rate requests to be sent to the API
        allNonASOServices.forEach((service) => {
            if ((service.apiKeyName === 'medical' && !plansObj[`${service.apiKeyName}Plans`]) || (service.apiKeyName !== 'medical' && !plansObj[`${service.typeName.toLowerCase()}Plans`]))  {
                return;
            }
            
            let rateRequestArrays = [];
            const batchOISABit = [];
            const blRequestArrays = [];
            const combinedRequestArrays = [];
            const childBenefitAmount = [];
            const spouseBenefitAmount = [];
            let productPlanLength = service.apiKeyName === 'medical' ? plansObj[`${service.apiKeyName}Plans`].length : plansObj[`${service.typeName.toLowerCase()}Plans`].length;
            let batchSize = productPlanLength;

            if (service.apiKeyName === 'medical') {
                if (!isCirrus) {
                    batchSize = 10;
                }
            } else {
                batchSize = 30;
            }

            for (let i = 0; i < productPlanLength; i += batchSize) {
                if (service.apiKeyName === 'life') {
                    rateRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.primePlanCode));
                    blRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.code));
                    combinedRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => `${plan.code}--${plan.ADandDCode}`));
                    childBenefitAmount.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan)=> plan.childBenefitAmount != null ? plan.childBenefitAmount : ""));
                    spouseBenefitAmount.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan)=> plan.spouseBenefitAmount != null ? plan.spouseBenefitAmount : ""));
                } else if ((service.apiKeyName === 'shortTermDis' || service.apiKeyName === 'longTermDis') && platform === 'PRIME'){
                    rateRequestArrays.push(plansObj[`${service.typeName.toLowerCase()}Plans`].slice(i, i + batchSize).map((plan) => plan.piaPlanCode));
                } else if (service.apiKeyName === 'suppLife'){
                    rateRequestArrays.push(plansObj[`${service.typeName.toLowerCase()}Plans`].slice(i, i + batchSize).map((plan)=>{  return platform === 'PRIME'? {
                        slEEPlanCode:plan.piaPlanCode,
                        slEEADnDPlanCode: plan.PIASLEEADDPlanCode,
                        slSPPlanCode:plan.PIASLSpPlanCode,
                        slSPADnDPlanCode:plan.PIASLSpADDPlanCode,
                        slCHPlanCode:plan.PIASLChPlanCode,
                        slCHADnDPlanCode:plan.PIASLChADDPlanCode
                    } :  {
                        //US6627183 SGACA to CQS Update for Supp Life
                        
                        slEEPlanCode: plan.code ,
                        slEEADnDPlanCode: plan.SLEEADDPlanCode,
                        slSPPlanCode:plan.SLSpPlanCode,
                        slSPADnDPlanCode:plan.SLSpADDPlanCode,
                        slCHPlanCode:plan.SLChPlanCode,
                        slCHADnDPlanCode:plan.SLChADDPlanCode

                    //     slEEPlanCode:plan.piaPlanCode,
                    //     slEEADnDPlanCode: plan.PIASLEEADDPlanCode,
                    //     slSPPlanCode:plan.PIASLSpPlanCode,
                    //     slSPADnDPlanCode:plan.PIASLSpADDPlanCode,
                    //     slCHPlanCode:plan.PIASLChPlanCode,
                    //     slCHADnDPlanCode:plan.PIASLChADDPlanCode
                    } }))
                } else if (service.apiKeyName === 'medical'){
                    rateRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.code));
                } else{
                    rateRequestArrays.push(plansObj[`${service.typeName.toLowerCase()}Plans`].slice(i, i + batchSize).map((plan) => plan.code)); 
                }
            }
            if(!isCirrus && service.apiKeyName === 'medical'){
                let codes = plansObj[`${service.apiKeyName}Plans`].map((plan) => plan.code)
                if(stateCode === "CA")
                {
                    codes = plansObj[`${service.apiKeyName}Plans`].map((plan) => {return{code:plan.code,platform:plan.platform}})         // for quickQuotes
                }     
                rateRequestArrays = getPlanCodeBatches(getState(), codes, batchOISABit)
            }
            rateRequestsObj[service.typeName] = rateRequestArrays.map((arr, index) => ({
                ...staticRequestInfo,
                service,
                planCodes: arr,
                OISABit: batchOISABit[index],
                blPlanCodes: blRequestArrays.length > 0 ? blRequestArrays[index] : blRequestArrays,
                combinedCodes: combinedRequestArrays.length > 0 ? combinedRequestArrays[index] : combinedRequestArrays,
                childBenefitAmount: childBenefitAmount[index],
                spouseBenefitAmount: spouseBenefitAmount[index]
            }));
        });

        // Conditions for WA 2 Tier:
        const { overall: { quickQuoteRequest}, quotes: { filterCriteria } } = getState();

        if (isWasinghtonTwoTierCase(quickQuoteRequest)) {
            const selectedTier = filterCriteria.ratingMethod.age ? '4 Tier' : '2 Tier';
            const twoTierProducts = ['DENTAL', 'VISION'];
            twoTierProducts.forEach(product => {
                if (rateRequestsObj[product] && rateRequestsObj[product].length > 0) {
                    let newRequestObjList = [];
                    rateRequestsObj[product].forEach(requestObj => {
                        newRequestObjList.push({
                            ...requestObj,
                            rateCallTier: '2 Tier',
                            selectedTier,
                            istwoTierCase: true
                        })
                        newRequestObjList.push({
                            ...requestObj,
                            rateCallTier: '4 Tier',
                            selectedTier,
                            isTwoTierCase: true
                        })
                    })
                    rateRequestsObj[product] = newRequestObjList
                }
            })
        }

        const filteredResultsOnly = !!plansObj.filteredResultsOnly;

        // Call the API using those assembled requests
        allNonASOServices.forEach((service) => {
            if (rateRequestsObj[service.typeName] && rateRequestsObj[service.typeName].length > 0) {
                // We send out the first batch alone, because it is for the plans that will appear at the top of the page
                // if(service.typeName==='life' && this.state.companyProfile.employees!=null){
                dispatch(requestRateBatch(rateRequestsObj[service.typeName][0], isCirrus, 0, groupSize, filteredResultsOnly, otherDetails, productType))
                    .then(() => {
                        for (let i = 1; i < rateRequestsObj[service.typeName].length && !doStopRateCalls(); i++) { // Subsequent batches are all sent out concurrently
                            dispatch(requestRateBatch(rateRequestsObj[service.typeName][i], isCirrus, i * 10, groupSize, filteredResultsOnly, otherDetails, productType));
                        }
                    })
                    .then(() => { // If rates are fetched successfully then request the benefit rider rates of all plans and store in state
                        if (service.apiKeyName === 'medical' && isCirrus === false && !doStopRateCalls()) {
                            dispatch(getAllRidersRatesQQ(rateRequestsObj[service.typeName][0], 0, plansObj.medicalPlans, groupSize))
                                .then(() => {
                                    for (let i = 1; i < rateRequestsObj[service.typeName].length && !doStopRateCalls(); i++) {
                                        dispatch(getAllRidersRatesQQ(rateRequestsObj[service.typeName][i], i * 10, plansObj.medicalPlans, groupSize));
                                    }
                                })
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                        logError(KEY_NAME, UHC_BENEFIT_RIDER_RATE_ERROR, 'getQuickQuoteRates', rateRequestsObj, err);
                    });
            }
        });
    };
}

export function getRatesForSelectedPlans(planType, isRenewals = false) {
    return (dispatch, getState) => {
        const state = getState();
        const qRequest = state.overall.quickQuoteRequest;
        const groupsize = qRequest.groupSize ? qRequest.groupSize : state.companyProfile.groupSize;
        const apiKey = `${planType.toLowerCase()}Plans`;

        let modifiedPlanType = planType;
        
        if (planType === "SUPPLIFE") {
            modifiedPlanType = 'suppLife'
        } else {
            modifiedPlanType = planType.toLowerCase();
        }
        const plans = state[modifiedPlanType]?.visiblePlans?.filter((plan) => !plan.quote && !plan.finalMonthlyPremium);
        const { selectedPlans } = state[modifiedPlanType];

        if (!isRenewals) {
            selectedPlans.filter((plan) => !plan.quote && !plan.finalMonthlyPremium).forEach((plan) => {
                plans.push(plan);
            });
        }

        // If plan type is life and classing is enabled then bring rates for all classes
        if ((planType.toUpperCase() === 'LIFE' || planType.toUpperCase() === 'STD' || planType.toUpperCase() === 'LTD') && state[planType.toLowerCase()].employeeClassing.classingOpted) {
            const staticRequestInfo = {
                groupSize : groupsize,
                zipCode : qRequest.zipObj.zipCode,
                effectiveDate : qRequest.effectiveDate,
                stateCode : qRequest.zipObj.stateCode,
                countyCode: qRequest.zipObj.countyFIPSCode,
                companyProfile : state.companyProfile,
                platform : state.overall.platform,
                platformRatingIDs : {}
            };
            let planCodes=[]
            let stdPlanCodes=[]
            let ltdPlanCodes=[]
        const platform = state.overall.platform

        if (planType.toUpperCase() === 'LIFE') {
            planCodes =  plans.map(plan => plan.code) 
        } 

        if (planType.toUpperCase() === 'STD') {
            stdPlanCodes = plans.map(plan =>  platform === 'PRIME' ? plan.piaPlanCode : plan.code)
        } 
        
        if (planType.toUpperCase() === 'LTD') {
            ltdPlanCodes = plans.map(plan => platform === 'PRIME' ? plan.piaPlanCode : plan.code)
        }

        dispatch(getLifeRatesInClasses(staticRequestInfo, planCodes, stdPlanCodes, ltdPlanCodes, 'Shopping', 'plans'));
            return;
        }

        if (!isRenewals) {
            dispatch(getQuickQuoteRates({
                [apiKey]: plans,
                filteredResultsOnly: true,
            },
                groupsize,
                qRequest.zipObj.zipCode,
                qRequest.effectiveDate,
                qRequest.zipObj.stateCode,
                qRequest.zipObj.countyFIPSCode,
                state.specialtyCriteria.sicCode,
                qRequest.otherDetails));
        } else {
            const renewalShoppingHelperData = state.renewalShoppingHelper.renewalData ? state.renewalShoppingHelper : state.review;
            let identifiedPlatform = identifyPlatform(renewalShoppingHelperData.renewalData)
            let Ny_shop = false
            if (renewalShoppingHelperData && renewalShoppingHelperData.renewalCaseData && renewalShoppingHelperData.renewalCaseData.situsState === "NY")
                Ny_shop = true
            const specialityReq = shoppingRequest(renewalShoppingHelperData, Ny_shop, identifiedPlatform === 'PRIME' || identifiedPlatform === 'DUAL');
            if (specialityReq?.otherDetails != undefined) {
                specialityReq.otherDetails.isPreferredPlans = true;
                specialityReq.otherDetails.includeSpecialty = true;
                // 02/01 Release excluding Basic Life products
                specialityReq.otherDetails.selectedProducts = ['dental', 'vision', 'life','std','ltd'];
                specialityReq.otherDetails.platform = identifiedPlatform === 'DUAL' ? 'PRIME' : identifiedPlatform
                specialityReq.otherDetails.existingCoverage = getExistingCoverageForSpecialty(renewalShoppingHelperData.renewalData)
                dispatch(getQuickQuoteRatesRenewal({
                    [apiKey]: plans,
                    filteredResultsOnly: true
                }, specialityReq.groupSize, specialityReq.zipObj.zipCode,
                    specialityReq.effectiveDate, specialityReq.zipObj.stateCode, specialityReq.zipObj.countyFIPSCode,
                    specialityReq.otherDetails.sicCode, specialityReq.otherDetails, specialityReq.employeeCounts, specialityReq.employeeClasses,
                    specialityReq.employerContribution, specialityReq.employees));
            }
        }
    };
}

function hitAllPlanAPI(groupSize, zipObj, effectiveDate, otherDetails, service, page, last = false, flow = '') {
    return (dispatch, getState) => {
        const fullProps = getState();
        const {
            overall: {
                appConfigDetails : {
                    showAgeBand
                }
            },
            companyProfile: { selectedProducts }
        } = fullProps;
        const salaryRequired = fullProps.specialtyCriteria.salaryRatingMethod.toLowerCase() === "optional" ? false : true;
        let specialtyType = [service.apiKeyName];
        if(service.typeName === STD) {
            specialtyType = ["STD"];
        } else if(service.typeName === LTD) {
            specialtyType = ["LTD"];
        }
        axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
            + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}${showAgeBand === undefined ? '' : `&showAgeBand=${showAgeBand}`}&page=${page}&busType=${ flow === 'renewals' ? 'RL': 'NB'}${service.typeName == "LTD" ? `&salaryRequired=${salaryRequired}` : ''}`)
            .then((res) => {
                const reqDetails= `/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}${showAgeBand === undefined ? '' : `&showAgeBand=${showAgeBand}`}&page=${page}&busType=${ flow === 'renewals' ? 'RL': 'NB'}${service.typeName == "LTD" ? `&salaryRequired=${salaryRequired}` : ''}`
                dispatch(createHistory('All Specialty Plans', 'Quick Quotes Action file', 'Success', reqDetails,' ', flow === 'renewals' ? 'RL': 'NB'));
                const marketType = getMarketType();
                if (res.data[`${service.apiKeyName}Plans`] != null) {
                    flow !== 'renewals'
                        ? dispatch({
                            type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_ALL_PLANS,
                            payload: res.data[`${service.apiKeyName}Plans`],
                        })
                        : dispatch({
                            type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS,
                            payload: res.data[`${service.apiKeyName}Plans`],
                            fullProps,
                        });
                        
                    marketType === MARKET_TYPES.OXFORD ?   
                    dispatch({
                        type: types.SERVICE[service.typeName].SET_ADVANCE_FILTER_DATA,
                        payload: {
                            fetchedAllPlans: last,
                        },
                    }) 
                    :
                    dispatch({
                        type: types.SERVICE[service.typeName].SET_ADVANCE_FILTER_30_PLANS,
                        payload: {
                            fetchedAllPlans: last,
                        },
                    });
                    
                    const selectedPlanCodes = flow !== 'renewals' ? JSON.parse(localStorage.getItem(`selected_specialty_${res.data[`${service.apiKeyName}Plans`].plans[0].planType}`) || '[]') : [];
                    if ((marketType === MARKET_TYPES.OXFORD && selectedPlanCodes.length > 0 ) || (marketType === MARKET_TYPES.UHC)) {
                        setTimeout(() => {
                            dispatch(getRatesForSelectedPlans(service.typeName,flow === "renewals"));
                        }, 2000); 
                    }
                }
            });
    };
}

function requestDependentLifePlans(quickQuoteRequest, isRenewals=false) {
    return (dispatch, getState) => {
        dispatch({
            type: types.REQUEST_DEPENDENT_LIFE_PLANS
        })
        const fullProps = getState();
        let ibrDepLifePlan = isUhcRenewals() && fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.dependentLife && fullProps.renewalShoppingHelper.dependentLife.selectedPlans ? fullProps.renewalShoppingHelper.dependentLife.selectedPlans : [];
        axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${quickQuoteRequest.groupSize}&zipCode=${quickQuoteRequest.zipObj.zipCode}&effectiveDate=${quickQuoteRequest.effectiveDate}`
            + `&stateCode=${quickQuoteRequest.zipObj.stateCode}${quickQuoteRequest.otherDetails.sicCode !== '' ? `&sicCode=${quickQuoteRequest.otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify([LIFE_DEP_BASIC])}&platform=${quickQuoteRequest.otherDetails.platform}&page=1`)
            .then((res) => {
                const dependentLifePlans = res.data.depLifePlans || []
                dispatch(receiveDependentLifePlans(quickQuoteRequest, dependentLifePlans, ibrDepLifePlan))
                .then(res => {
                    if (res) {
                        dispatch(dependentLifeRates(dependentLifePlans.plans || [], quickQuoteRequest))
                    }
                })
            }).catch((err) => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                dispatch({
                    type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', true)
                });
                const errReqBody = {
                    groupSize: quickQuoteRequest.groupSize,
                    zipCode: quickQuoteRequest.zipObj.zipCode,
                    effectiveDate: quickQuoteRequest.effectiveDate,
                    stateCode: quickQuoteRequest.zipObj.stateCode,
                    sicCode: quickQuoteRequest.otherDetails.sicCode !== '' ? quickQuoteRequest.otherDetails.sicCode : '',
                    preferredPlans: false,
                    specialtyTypes: LIFE_DEP_BASIC,
                    platform: quickQuoteRequest.otherDetails.platform
                }
                logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'requestDependentLifePlans', { type: 'Dependent Life', requestBody: errReqBody }, err);
            });
    }
}

function requestDependentSupLifePlans(quickQuoteRequest, isRenewals=false) {
    return (dispatch) => {
        dispatch({
            type: types.REQUEST_DEP_SUPP_LIFE_PLANS
        })
        axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${quickQuoteRequest.groupSize}&zipCode=${quickQuoteRequest.zipObj.zipCode}&effectiveDate=${quickQuoteRequest.effectiveDate}`
            + `&stateCode=${quickQuoteRequest.zipObj.stateCode}${quickQuoteRequest.otherDetails.sicCode !== '' ? `&sicCode=${quickQuoteRequest.otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify([SUPP_DEP_LIFE])}&platform=${quickQuoteRequest.otherDetails.platform}&page=1`)
            .then((res) => {
                const suppDepLifePlans = res.data.suppDepLifePlans || []
                dispatch(receiveSuppLifePlans(quickQuoteRequest, suppDepLifePlans))
                .then(res => {
                    // if (res) {
                    //     dispatch(SuppLifeRates(dependentLifePlans.plans || [], quickQuoteRequest))
                    // }
                })
            }).catch((err) => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                dispatch({
                    type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', true)
                });
                const errReqBody = {
                    groupSize: quickQuoteRequest.groupSize,
                    zipCode: quickQuoteRequest.zipObj.zipCode,
                    effectiveDate: quickQuoteRequest.effectiveDate,
                    stateCode: quickQuoteRequest.zipObj.stateCode,
                    sicCode: quickQuoteRequest.otherDetails.sicCode !== '' ? quickQuoteRequest.otherDetails.sicCode : '',
                    preferredPlans: false,
                    specialtyTypes: LIFE_DEP_BASIC,
                    platform: quickQuoteRequest.otherDetails.platform
                }
                logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'requestDependentSupLifePlans', { type: 'Supp Life', requestBody: errReqBody }, err);
            });
    }
}

function receiveDependentLifePlans(quickQuoteRequest, dependentLifePlans, ibrDepLifePlan) {
    return (dispatch) => new Promise((resolve) => {
        dispatch({
            type: types.RECEIVE_DEPENDENT_LIFE_PLANS,
            payload: dependentLifePlans,
            quickQuoteRequest,
            ibrDepLifePlan
        })
        resolve(true)
    })
}

function receiveSuppLifePlans(quickQuoteRequest, suppDepLifePlans) {
    return (dispatch) => new Promise((resolve) => {
        dispatch({
            type: types.RECEIVE_DEP_SUPP_LIFE_PLANS,
            payload: suppDepLifePlans,
            quickQuoteRequest
        })
        resolve(true)
    })
}

function dependentLifeRates(dependentLifePlans, quickQuoteRequest) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { overall } = fullProps;
        const qRequest = fullProps.overall.quickQuoteRequest;
        const renewalData = fullProps.renewalShoppingHelper.renewalData ? fullProps.renewalShoppingHelper.renewalData : fullProps.review.renewalData;
        // Attach employee census for renewals
        if (isUhcRenewals()) {
            // qRequest.otherDetails.employeeCensus = fullProps.renewals.renewalData.members;
            qRequest.otherDetails.employeeCensus = getMembersMappingFromIBR(renewalData.Ibr);
            qRequest.otherDetails.existingCoverage = quickQuoteRequest.otherDetails.existingCoverage;
        } else if(qRequest.source === "renewal"){
            var employees = [];
            renewalData.members.forEach((x, i) => {
                if (x.relationship === 'Self') {
                    employees.push({
                        employeeId: x.employeeId,
                        firstName: x.firstName,
                        lastName: x.lastName,
                        coverages: {
                            medical: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                            dental: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                            vision: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                            life: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                        },
                        age: x.age,
                        // dob: (new Date(x.birthDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                        dob: isoStringToMMDDYYYY(x.birthDate),
                        salary: x.salary,
                        gender: x.gender,
                        smoker: x.smoker,
                        classId: x.classId,
                        employeeStatus: x.status,
                        zipCode: x.zipCode,
                        dependents: []
                    });
                }
            })
            renewalData.members.forEach((z, i) => {
                if (z.relationship === 'Spouse' || z.relationship === 'Life Partner' || z.relationship === 'Domestic Partner' || z.relationship === 'Civil Union Partner' || z.relationship === 'Child' || z.relationship === 'Dependent Child') {
                    if (employees && employees.length > 0) {
                        employees.forEach((y, i) => {
                            if (z.employeeId === y.employeeId) {
                                employees[i].dependents.push({
                                    employeeId: z.employeeId,
                                    relationship: z.relationship ? z.relationship : '',
                                    // dob: (new Date(z.birthDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                                    dob: isoStringToMMDDYYYY(z.birthDate),
                                    age: z.age,
                                    gender: z.gender,
                                    smoker: z.smoker,
                                });
                            }
                        })
                    }
                }
            })

            qRequest.otherDetails.employeeCensus = employees;
        }


        const groupsize = qRequest.groupSize ? qRequest.groupSize : fullProps.companyProfile.groupSize;
        const sicCode = fullProps.specialtyCriteria && fullProps.specialtyCriteria.sicCode ? fullProps.specialtyCriteria.sicCode : qRequest.otherDetails.sicCode;
        dispatch(getQuickQuoteRates({
            lifePlans: dependentLifePlans,
            filteredResultsOnly: true,
            },
            groupsize,
            qRequest.zipObj.zipCode,
            qRequest.effectiveDate,
            qRequest.zipObj.stateCode,
            qRequest.zipObj.countyFIPSCode,
            sicCode,
            qRequest.otherDetails,
            LIFE_DEP_BASIC));
    }
}

export function getAllQuickQuotePlans(groupSize, zipObj, effectiveDate, otherDetails, flow = '') {
    return (dispatch, getState) => {
        const fullProps = getState();
        const {
            overall: {
                appConfigDetails : {
                    showAgeBand
                }
            },
            companyProfile: { selectedProducts }
        } = fullProps;
        const salaryRequired = fullProps.specialtyCriteria.salaryRatingMethod.toLowerCase() === "optional" ? false : true;

        specialtyServices.forEach((service) => {
            if(!isUhcRenewals() || (isUhcRenewals() && ['DENTAL','VISION','LIFE','LIFE_DEP_BASIC'].includes(service.typeName))){
            const selectedProds =  otherDetails.selectedProducts.includes('life') ? [...otherDetails.selectedProducts, "supplife"] : otherDetails.selectedProducts;
            if (selectedProds.includes(service.typeName.toLowerCase())) {

                if(isUhcRenewals()  && service.apiKeyName==="dependentLifeBasic") {
                    service.apiKeyName = "LIFE_DEP_BASIC"
                }

                let specialtyType = [service.apiKeyName];

                if(service.typeName === STD) {
                    specialtyType = ["STD"];
                } else if(service.typeName === LTD) {
                    specialtyType = ["LTD"];
                }
                else if (service.typeName ===SUPPLIFE ){
                    specialtyType = ["SUPPLIFE"];
                    const quickQuoteRequest = {
                        groupSize,
                        zipObj,
                        effectiveDate,
                        otherDetails,
                    };
                    dispatch(requestDependentSupLifePlans(quickQuoteRequest, flow === "renewals"))
                }
                // if (service.apiKeyName.toUpperCase() === LIFE) {
                //     const quickQuoteRequest = {
                //         groupSize,
                //         zipObj,
                //         effectiveDate,
                //         otherDetails,
                //     };
                //     specialtyType = ["SUPPLIFE"];
                // }
                // Get dependent life plans when life is selected
                if (service.apiKeyName.toUpperCase() === LIFE) {
                    const quickQuoteRequest = {
                        groupSize,
                        zipObj,
                        effectiveDate,
                        otherDetails,
                    };
                    // TODO:: seprate module for dep life
                    dispatch(requestDependentLifePlans(quickQuoteRequest, flow === "renewals"))
                }
                const marketType = getMarketType();
                    dispatch({
                        type: types.SPECIALTY_RATE_CALL_PROGRESS,
                        payload: true,
                    });
                axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                    + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}${showAgeBand === undefined ? '' : `&showAgeBand=${showAgeBand}`}&page=1&busType=${ flow === 'renewals' ? 'RL': 'NB'}${service.typeName == "LTD" ? `&salaryRequired=${salaryRequired}` : ''}`)
                    .then((res) => {
                        const reqDetails=`/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                        + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}${showAgeBand === undefined ? '' : `&showAgeBand=${showAgeBand}`}&page=1&busType=${ flow === 'renewals' ? 'RL': 'NB'}${service.typeName == "LTD" ? `&salaryRequired=${salaryRequired}` : ''}`;
                        dispatch(createHistory('All Specialty Plans', 'Quick Quotes Action file', 'Success', reqDetails,' ', flow === 'renewals' ? 'RL': 'NB'));

                        // Toggling preferred plans for UHC Renewals was failing
                        // This logic is not needed for UHC renewals. Preferred plans should display by default
                        if (!isUhcRenewals()) {
                            if(flow=='renewals' && (res.data[`${service.apiKeyName}Plans`].totalCount==0 || res.data[`${service.apiKeyName}Plans`].plans.length==0)){
                                dispatch(setDisplayOfPreferredPlans(`${service.apiKeyName}`))
                            }else if(flow=='renewals' && (res.data[`${service.apiKeyName}Plans`].totalCount!=0 || res.data[`${service.apiKeyName}Plans`].plans.length!=0)){
                                dispatch(setDisplayOfPreferredPlansToggle(`${service.apiKeyName}`))
                            }
                        }

                        const { totalPageCount } = res.data[`${service.apiKeyName}Plans`];
                        if (totalPageCount) {
                            for (let i = 2; i <= totalPageCount; i++) {
                                flow !== 'renewals'
                                    ? dispatch(hitAllPlanAPI(groupSize, zipObj, effectiveDate, otherDetails, service, i, i === totalPageCount))
                                    : dispatch(hitAllPlanAPI(groupSize, zipObj, effectiveDate, otherDetails, service, i, i === totalPageCount, flow));
                            }
                        }

                        if (res.data[`${service.apiKeyName}Plans`] != null) {
                            flow !== 'renewals'
                                ? dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_ALL_PLANS,
                                    payload: res.data[`${service.apiKeyName}Plans`],
                                })
                                : dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS,
                                    payload: res.data[`${service.apiKeyName}Plans`],
                                    fullProps,
                                });
                            if (res.data[`${service.apiKeyName}Plans`].plans[0] && flow !== 'renewals') {
                                let selectedPlanCodes = JSON.parse(localStorage.getItem(`selected_specialty_${res.data[`${service.apiKeyName}Plans`].plans[0].planType}`) || '[]');

                                if(!selectedPlanCodes.length && fullProps[service.apiKeyName.toLowerCase()] && fullProps[service.apiKeyName.toLowerCase()].selectedPlans){
                                    selectedPlanCodes = fullProps[service.apiKeyName.toLowerCase()].selectedPlans.map(pln => pln.code)
                                }

                                if (selectedPlanCodes.length > 0) {
                                    dispatch(getRatesForSelectedPlans(service.typeName));
                                }
                            }

                            if (res.data[`${service.apiKeyName}Plans`].totalCount > 30) {
                                if (marketType === MARKET_TYPES.UHC) {
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_ADVANCE_FILTER_30_PLANS,
                                        payload: { fetchedAllPlans: totalPageCount === 1 },
                                    });
                                    setTimeout(() => {
                                        dispatch(getRatesForSelectedPlans(service.typeName, flow === "renewals"));
                                    }, 2000);
                                } else {
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_ADVANCE_FILTER_DATA,
                                        payload: { fetchedAllPlans: totalPageCount === 1 },
                                    });
                                }
                            } else {
                                if(flow==="renewals" && service.typeName === "LIFE")
                                {
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_BASIC_FILTERS_DIRECTLY_UHC,
                                        payload: { fetchedAllPlans: totalPageCount === 1, marketType },
                                    });
                                }
                                else
                                {
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_BASIC_FILTERS_DIRECTLY,
                                        payload: { fetchedAllPlans: totalPageCount === 1 },
                                    });
                                }
                                setTimeout(() => {
                                    dispatch(getRatesForSelectedPlans(service.typeName, flow === "renewals"));
                                }, 2000);
                            }
                                dispatch({
                                    type: types.SPECIALTY_RATE_CALL_PROGRESS,
                                    payload: false,
                                })
                            }
                    }).catch((err) => {
                            dispatch({
                                type: types.SPECIALTY_RATE_CALL_PROGRESS,
                                payload: false,
                            })
                        console.log(err);
                    });
            }
        }
        });
    };
}

// Request details on plans that are available in the given zip code,
// on the effective date and configure the returned details
//
// Parameters
//  groupSize:              Determined by user entry on form
//  zipCode:                Determined by user entry on form
//  effectiveDate:          Determined by user entry on form
//  stateCode:              Associated with zipCode
//  countyCode:             Associated with zipCode, user can select which county within a zip code they wish to choose
//  otherDetails:            Contains object of includeSpecialty, Sic code, SelectedProduct list
//  source:                  Determines the page source, helps navigate users from census page to plan selection page

export function getQuickQuotePlans(groupSize, zipObj, effectiveDate, otherDetails, sourcePage = '') {
    return (dispatch, getState) => {
        checkZipObj(zipObj);

        const quickQuoteRequest = {
            groupSize,
            zipObj,
            effectiveDate,
            otherDetails,
        };

        sendLog({
            action: 'SUBMIT PLAN REQUEST',
            ...quickQuoteRequest,
        });

        const includeSpecialty = otherDetails.selectedProducts.includes('dental') || otherDetails.selectedProducts.includes('vision') || otherDetails.selectedProducts.includes('life')|| otherDetails.selectedProducts.includes('std')|| otherDetails.selectedProducts.includes('ltd')
        const isPreferredPlans = otherDetails.isPreferredPlans == null ? 'true' : otherDetails.isPreferredPlans;
        const OISABit = getState().overall.OISABit ? getState().overall.OISABit : [];
        const source = getState().overall.source;
        const {locations, primaryLocationIdx} = getState().companyProfile;
        const fullProps = getState();
        // Calling medical plan API

        if (otherDetails.selectedProducts.includes('medical')) {
            const caseProgress = getNBCaseProgress(fullProps);
            const isCaseSubmitted = inSubmittedStatus(caseProgress);
            const { caseTracking } = fullProps;
            let isDesQuote = false
            const desQuote = caseTracking && caseTracking.caseTrack && Array.isArray(caseTracking.caseTrack) && caseTracking.caseTrack.find((x) => x.source === 'DES' && x.quoteTrackingNumber.startsWith('B2B-NB'));
            if (!isUndefinedOrNull(desQuote)) {
            isDesQuote = true
            }
            const shouldRetainFilters = isDesQuote && sourcePage === 'quoteReview'
            dispatch({
                type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS,
                payload: quickQuoteRequest,
                source: 'quick-quotes',
                isCaseSubmitted,
                shouldRetainFilters
            });

            const platform = getState().overall.platform;

            const medicalPlansRequestBody = {
                groupSize,
                zipCode: zipObj.zipCode,
                effectiveDate,
                stateCode: zipObj.stateCode,
                countyCode: zipObj.countyFIPSCode !== '' ? zipObj.countyFIPSCode : ' ',
                includeNonMedical: false,
                OISABit: OISABit,
                platform,
                ratingCount: source === 'full' ? getRatingCount(locations,primaryLocationIdx) : groupSize.toString(),
                validateQuoteData: fullProps.overall.validateQuoteData || {}
            };

            if(platform === 'PRIME'){
                medicalPlansRequestBody.isInternal = checkIsInternalUser(fullProps.renewals.userRolesPermissions);
            }

            axios.post(`${apiRoutes.quickQuotes}/plans/startSearch`, medicalPlansRequestBody)
                .then(async (res) => {
                    const medicalPlanSearch = await getTransactionResult(res.data.transactionId);

                    if (medicalPlanSearch.statusCode === 400) {
                        throw await medicalPlanSearch;
                    }

                    if(zipObj?.stateCode==="CA" && effectiveDate ){
                        let coverageDate = new Date(effectiveDate).getUTCDate();
                        if(coverageDate === 15){
                            medicalPlanSearch.medicalPlans = medicalPlanSearch.medicalPlans.filter(pln => pln.platform !== 'NICE');
                        }
                    }

                    let platform;
                    if (medicalPlanSearch.medicalPlans.length > 0) {
                        platform = await medicalPlanSearch.medicalPlans[0].platform;
                    }

                    await dispatch({
                        type: types.SERVICE.FI.RECEIVE_QUICK_QUOTE_PLANS,
                        payload: medicalPlanSearch,
                        quickQuoteRequest,
                    });
                    dispatch(createHistory('Get Medical Plans', 'Quick Quotes Action file', 'Success', medicalPlansRequestBody, '', 'NB'));
                    await dispatch(getQuickQuoteRates(medicalPlanSearch, groupSize, zipObj.zipCode, effectiveDate, zipObj.stateCode, zipObj.countyFIPSCode, otherDetails.sicCode, otherDetails));
                    await dispatch(getEnrollmentRules(otherDetails.platform, zipObj, effectiveDate, groupSize));
                    await history.push('/plans');
                })
                .catch((err) => {
                    if (zipObj.stateCode !== 'NY') {
                        if(platform === 'PRIME' && !isUhcRenewals() && err && err.error && err.error.length > 0){
                            dispatch({
                                type: types.DISPLAY_DYNAMIC_ERROR_MESSAGE,
                                payload: err.error,
                            });
                        }
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                    }

                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', false),
                    });
                    logError(KEY_NAME, UHC_OXFORD_MEDICAL_PLAN_ERROR, 'getQuickQuotePlans', medicalPlansRequestBody, err);
                });
        }

        // Calling specialty plan API
        if (includeSpecialty) {
            // Reseting previous data here
            if (!(fullProps.companyProfile.B2BPartner !== undefined && fullProps.companyProfile.B2BPartner === 'Y')) {
                dispatch({
                    type: types.SERVICE.DENTAL.RESET_DATA,
                });
                dispatch({
                    type: types.SERVICE.VISION.RESET_DATA,
                });
            }
            dispatch({
                type: types.SERVICE.LIFE.RESET_DATA,
            });
            dispatch({
                type: types.SERVICE.STD.RESET_DATA,
            });
            dispatch({
                type: types.SERVICE.LTD.RESET_DATA,
            });
            if (!(fullProps.companyProfile.B2BPartner !== undefined && fullProps.companyProfile.B2BPartner === 'Y')) {
                if (otherDetails.selectedProducts.includes('dental')) {
                    dispatch({
                        type: types.SERVICE.DENTAL.REQUEST_QUICK_QUOTE_PLANS,
                        payload: quickQuoteRequest,
                        source: 'quick-quotes',
                    });
                }

                if (otherDetails.selectedProducts.includes('vision')) {
                    dispatch({
                        type: types.SERVICE.VISION.REQUEST_QUICK_QUOTE_PLANS,
                        payload: quickQuoteRequest,
                        source: 'quick-quotes',
                    });
                }
            }
            if (otherDetails.selectedProducts.includes('life')) {
                dispatch({
                    type: types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'quick-quotes',
                });
                dispatch({
                    type: types.SERVICE.SUPPLIFE.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'quick-quotes',
                });
            }
            if (otherDetails.selectedProducts.includes('std')) {
                dispatch({
                    type: types.SERVICE.STD.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'quick-quotes',
                });
            }
            if (otherDetails.selectedProducts.includes('ltd')) {
                dispatch({
                    type: types.SERVICE.LTD.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'quick-quotes',
                });
            }
            axios.get(`${apiRoutes.quickQuotes}/specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=${isPreferredPlans}&specialtyTypes=${JSON.stringify(otherDetails.selectedProducts)}&platform=${otherDetails.platform}`)
                .then((res) => {
                    specialtyServices.forEach((service) => {
                        if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                            if (res.data[`${service.apiKeyName}Plans`] != null) {
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_QUICK_QUOTE_PLANS,
                                    payload: res.data[`${service.apiKeyName}Plans`],
                                    quickQuoteRequest,
                                });
                                res.data[`${service.apiKeyName}Plans`] = res.data[`${service.apiKeyName}Plans`].plans;
                            }
                        }
                    });

                    if(res.data.shortTermDisPlans){
                        res.data.stdPlans = res.data.shortTermDisPlans;
                        delete res.data.shortTermDisPlans;
                    }
                    
                    if(res.data.longTermDisPlans){
                        res.data.ltdPlans = res.data.longTermDisPlans;
                        delete res.data.longTermDisPlans;
                    }

                    dispatch(getQuickQuoteRates(res.data, groupSize, zipObj.zipCode, effectiveDate, zipObj.stateCode, zipObj.countyFIPSCode, otherDetails.sicCode, otherDetails));
                    dispatch(getEnrollmentRules(otherDetails.platform, zipObj, effectiveDate, groupSize));
                    if (!otherDetails.selectedProducts.includes('medical')) {
                        if (getState().overall.platform !== 'CIRRUS' && source !== 'census' && (parseInt(groupSize, 10) > 50 && otherDetails.selectedProducts.includes('dental'))) {
                            history.push('/employee');
                        } else {
                            history.push('/plans');
                        }
                    }
                    // Pull all specialty plans for advance filtering
                    dispatch(getAllQuickQuotePlans(groupSize, zipObj, effectiveDate, otherDetails));
                })
                .catch((err) => {
                    dispatch({
                        type: types.DISPLAY_ERROR_MESSAGE
                    });
                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', true)
                    });
                    const errReqBody = {
                        groupSize: groupSize,
                        zipCode: zipObj.zipCode,
                        effectiveDate: effectiveDate,
                        stateCode: zipObj.stateCode,
                        sicCode: otherDetails.sicCode !== '' ? otherDetails.sicCode : '',
                        preferredPlans: true,
                        specialtyTypes: otherDetails.selectedProducts,
                        platform: otherDetails.platform
                    }
                    logError(KEY_NAME, UHC_OXFORD_SPECIALTY_PLAN_ERROR, 'getQuickQuotePlans', errReqBody, err);
                });
        }
    };
}

export const getTransactionResult = (transactionId) => axios.get(`${apiRoutes.quickQuotes}/transactions/${transactionId}`)
    .then(async (res) => {
        if (res.data.transactionResult.searchResult === null) {
            await pollingInterval(5000);
            const transactionResult = await getTransactionResult(transactionId);
            return await transactionResult;
        }

        return await res.data.transactionResult.searchResult.data;
    })
    .catch((err) => err.response.data.transactionResult.searchResult);

export const pollingInterval = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Filters visible plans based on filter criteria
//
// Parameters
//  field:                  Unclear
//  category:               Unclear
//  value:                  The new value to filter on
//  packageCode:            The package code, should a user be searching for packages
export function changeFilterCriteria(field, category, value, packageCode = null, serviceType = 'FI',type = "") {
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;
        const actionName = 'CHANGE PLAN CRITERIA';

        if (!overall.isRenewals && value !== null) {
            dispatch(sendClickEventDataAction({
                clickName : `${type !== "" ? `${type}` : 'Basic Filter'} : ${category !== null ? `${category} : ` : ''}${field} : ${value} `,
                coverageType : serviceType === 'FI' ? 'MEDICAL' : serviceType,
                clickElement : 'Filter',
                clickType : 'internal',
                planCode:`${type !== "" ? value: ''}`
            }))
            dispatch(auditUserAction(`Plan Type: ${overall.activeTabKey} - Filter panel - ${actionName}${category !== null ? ` - ${category}` : ''}`, field, value));
        }

        sendLog({
            action: actionName,
            field,
            category,
            value,
        });

        dispatch({
            type: types.SERVICE[serviceType].CHANGE_FILTER_CRITERIA,
            field,
            category,
            value,
            packageCode,
        });

        // Update specialty products rating tier on change of medical rating method.
        if (category === 'ratingMethod' && isWasinghtonTwoTierCase(overall.quickQuoteRequest)) {
            dispatch({
                type: types.CHANGE_RATE_TIER_FOR_SPECIALTY,
                ratingMethod : field // age || tier
            })
        }

        if (category === 'ratingMethod') {
            dispatch({
                type: types.CHANGE_ENROLLED_RATING_METHOD,
                ratingMethod : field
            })
        }
    };
}

export function resetFilterCriteria(serviceType = 'FI') {
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;

        const actionName = 'RESET FILTER CRITERIA';

        if (!overall.isRenewals) {
            dispatch(auditUserAction(`Filter panel - ${actionName}`, 'Clear Filters', 'Clicked'));
        }

        sendLog({
            action: actionName,
        });

        dispatch({
            type: types.SERVICE[serviceType].RESET_FILTER_CRITERIA,
        });
    };
}

// Called to reorder how the plans appear on the page
//
// Parameters
//  ascending: Boolean corresponding to the whether
//             plans should be ordered by ascending premiums (1) or decending(2)
export function changeSortOrder(ascending, serviceType) {
    let updatedServiceType = serviceType;
    if (serviceType === 'MEDICAL') updatedServiceType = 'FI';
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;

        const actionName = 'CHANGE SORT ORDER';

        if (!overall.isRenewals) {
            dispatch(auditUserAction(updatedServiceType, `Sort By Premium (${actionName})`, ascending));
        }

        sendLog({
            action: actionName,
            ascending,
            updatedServiceType,
        });

        dispatch({
            type: types.SERVICE[updatedServiceType].CHANGE_SORT_ORDER,
            ascending,
        });
    };
}

export function updatePremiumIfDeselect(plan, field, pkg) {
    return (dispatch, getState) => {
        const state = getState();
        if (state.quotes.isCirrus === true)
            return
        // Need to know that plan was deselected.
        if (plan !== '') { // One plan is deselected
            let isDeselected = false;
            if (field === 'selected' && state.quotes.selectedPlans) {
                isDeselected = state.quotes.selectedPlans
                    .findIndex((selectedPlan) => selectedPlan.code === plan.code && selectedPlan.selectedPackages.includes(pkg)) !== -1 ? true : false;
            }
            // If plan is deselected
            if (isDeselected) {
                // If plan has selected benefit riders then bring the originial rates back for that plan and modify the rates as well as selected benefit riders field
                if (plan.selectedBenefitRiders && plan.selectedBenefitRiders.length > 0) {
                    let planCodes = [];
                    planCodes.push(plan.code);
                    const batchOISABit = [];
                    const planCodeBatches = getPlanCodeBatches(state, planCodes, batchOISABit);
                    dispatch(requestOldRatesBatch(planCodeBatches[0], batchOISABit[0]));
                }
            }
        } else { // Deselect All is clicked

            let visiblePlans = state.quotes.visiblePlans;
            let planCodes = [];
            for (let i = 0; i < visiblePlans.length; i++) {
                if (visiblePlans[i].selectedBenefitRiders && visiblePlans[i].selectedBenefitRiders.length > 0) {
                    planCodes.push(visiblePlans[i].code);
                }
            }

            if (planCodes.length > 0) {
                const batchOISABit = [];
                const planCodeBatches = getPlanCodeBatches(state, planCodes, batchOISABit);
                dispatch(requestOldRatesBatch(planCodeBatches[0], batchOISABit[0]))
                .then(() => {
                    for (let i = 1; i < Object.keys(planCodeBatches).length; i++) {
                        dispatch(requestOldRatesBatch(planCodeBatches[i], batchOISABit[i]));
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }
        }
    }
}

// Called to either select/unselect or favorite/unfavorite and individual plan
//
// Parameters
//  plan:                   Object holding plan information to be updated
//  field:                  Determines whether to update (by toggling)
//                          'selected' or 'favorited' flag
//  pkg:                    The package of plan
//  serviceType             Determines the service type of the action to dispatch
//  callback                Callback function for functions applied after updating the plan
//  actionType              Determines the type of the action.  This value is used only for the Audit.
export function updatePlan(plan, field, pkg, callback, serviceType = 'FI', actionType = '', parentPlanCode = null) {
    if (serviceType === "SUPPLEMENT LIFE") {
        serviceType = "SUPPLIFE"
    }
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;
        const planCode = plan.codeDisplay ? plan.codeDisplay : plan.code;
        const planCodeActual = plan.code ? plan.code : '';

        const productType = plan.planType ?
            (plan.planType.toUpperCase() === 'MEDICAL' ? 
                (plan.medicalProductType ? plan.medicalProductType : plan.medicalPlanType) 
            : plan.productType)
        : '';

        let coverageType = "";
        if (plan.planType) coverageType += plan.planType.toUpperCase();
        if (plan.metal) coverageType += " : " + plan.metal
        const actionName = `TOGGLE PLAN ${field.toUpperCase()}`;
        let fieldDetails = `Plan : ${field} ${field.toLowerCase() !== 'selected' ? 'clicked' : ''}`;
        if (!overall.isRenewals) {
            dispatch(auditUserAction(`Plan Type: ${plan.planType}`, `${planCode}`, `${actionType !== '' ? actionType : fieldDetails} `));
        }

        
        if (field.toLowerCase() === 'selected' && plan.selected) {
            fieldDetails = 'Plan : De-Selected';
        } else if (field.toLowerCase() === 'selected' && !plan.selected) {
            fieldDetails = 'Plan : Selected';
        }

        if (field.toLowerCase() === 'favorited' && plan.favorited) {
            fieldDetails = 'Plan : Favorite : De-Selected';
        } else if (field.toLowerCase() === 'favorited' && !plan.favorited) {
            fieldDetails = 'Plan : Favorite : Selected';
        }

        dispatch(sendClickEventDataAction({
            clickName : fieldDetails,
            coverageType : coverageType,
            clickElement : 'Button',
            clickType : 'internal',
            planName : planCode,
            planCode: planCodeActual,
            productType: productType,
            planFilter: plan.isPreferredPlan || plan.preferredPlan ? 'Preferred' : 'Standard'
        }))

        sendLog({
            action: actionName,
            planCode,
            planType: plan.planType,
            isfilteredPlan: !!plan.filtered,
        });

        // Plan deselected --> Reset It's Premium if benefit Riders are selected for that plan and
        // Remove selectedRiders from its object. or make it empty;
        dispatch(updatePremiumIfDeselect(plan, field, pkg));

        dispatch({
            type: types.SERVICE[serviceType].UPDATE_PLAN,
            plan,
            field,
            pkg,
            parentPlanCode
        });

        if (callback) {
            callback();
        }
    };
}

// Called to succinctly select/unselect all plans
//
// Parameters
//  field:                  Determines whether to update 'selected' or
//                          'favorited' flag (Currently only used for 'selected')
//  value:                  Boolean value corresponding to what the field
//                          should now equal (0=unselected, 1=selected)
//  pkg:                    The package of selected plans
//  serviceType             Determines the service type of the action to dispatch
export function updateAllPlans(field, value, pkg, serviceType = 'FI', lifeClass='') {
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;

        const actionName = 'TOGGLE ALL PLANS';

        if (!overall.isRenewals) {
            dispatch(auditUserAction(`Plan Type: ${overall.activeTabKey} ${actionName}`, field, value));
        }

        sendLog({
            action: actionName,
            field,
            value,
        });

        // If plans are deselected for that package
        if(value === false){
            // Dispatching funtion to update premium if any of selected plans has benefit riders selected.
            // Sending plans as null. We have to make change for all visible plans so fetch that from state
            dispatch(updatePremiumIfDeselect('',field,pkg));
        }
        dispatch({
            type: types.SERVICE[serviceType].UPDATE_ALL_PLANS,
            field,
            value,
            pkg,
            lifeClass
        });
    };
}

export function updateSuppLifeSelectedPlans(serviceType) {
    return (dispatch) => {
        dispatch({
            type: types.SERVICE[serviceType].CLEAR_SELECTED_PLANS
        });
    };
}

export function updateSuppLifeVisiblePlans(serviceType) {
    return (dispatch) => {
        dispatch({
            type: types.SERVICE[serviceType].CLEAR_SELECTED_VISIBLE_PLANS
        });
    };
}

export function updateEmployeeClassFilter(lifeClass, planType) {
    return (dispatch) => {
        dispatch({
            type: `UPDATE_${planType.toUpperCase()}_PLANS_CLASS_FILTER`,
            lifeClass
        });
    };
}

// Updates the 'name' array. Name does not affect rates
//
// Parameters
//  index:                  The value indicating which employee had their name changed
//  name:                   The new name value to assign to the employee
export function updateName(index, name) {
    return (dispatch, getState) => {
        sendLog({
            action: 'UPDATE NAME',
            index,
            name,
        });

        const oldNames = getState().overall.names;
        const newNames = oldNames.slice(0, index).concat(name).concat(oldNames.slice(index + 1));

        dispatch({
            type: types.UPDATE_NAMES,
            newNames,
        });
    };
}

// Updates the 'ages' array, then calculates the new rates for
// 'plans' and 'selectedPlans' arrays (but not 'visiblePlans',
// which is updated in UPDATE_VISIBLE_PLANS.
// The function also automatically updates the filters
// for premiums to allow for the new, and possibly higher, costs.
//
// Parameters
//  index:                  The value indicating which employee had their age changed
//  age:                    The new age value to assign to the employee
export function updateAge(index, age, isFullShopping = true) {
    return (dispatch, getState) => {
        sendLog({
            action: 'UPDATE AGE',
            index,
            age,
        });

        const oldAges = getState().overall.ages;
        const newAges = oldAges.slice(0, index).concat(age).concat(oldAges.slice(index + 1));

        let serviceType;

        if (isFullShopping) {
            serviceType = 'FI';
        } else {
            serviceType = 'RS';
        }

        dispatch({
            type: types.SERVICE[serviceType].UPDATE_AGES,
            newAges,
        });
    };
}

// Updates the 'genders' array. Gender does not affect medical plan premiums but will affect
// specialty plan premiums
//
// Parameters
//  index:                  The value indicating which employee had their gender changed
//  gender:                 The new gender value to assign to the employee
export function updateGender(index, gender) {
    return (dispatch, getState) => {
        sendLog({
            action: 'UPDATE GENDER',
            index,
            gender,
        });

        const oldGenders = getState().overall.genders;
        const newGenders = oldGenders.slice(0, index)
            .concat(gender)
            .concat(oldGenders.slice(index + 1));

        dispatch({
            type: types.UPDATE_GENDERS,
            newGenders,
        });
    };
}

// Sets the criteria entered into the specialty criteria modal, to be used
// for displaying specific product types, plans based on SIC code, and for
// calculating rates
// Parameters
//  specialtyCriteria: the specialty criteria to set

export function setSpecialtyCriteria(specialtyCriteria) {
    return (dispatch) => {
        sendLog({
            action: 'SET SPECIALTY CRITERIA',
            ...specialtyCriteria,
        });

        dispatch({
            type: types.SET_SPECIALTY_CRITERIA,
            specialtyCriteria,
        });
    };
}

export function setActiveTabKey(key) {
    return (dispatch, getState) => {
        const state = getState();
        const { overall } = state;

        if (!overall.isRenewals) {
            dispatch(auditUserAction('Plan Type Tab', key, 'Tab Clicked (Active)'));
        }

        sendLog({
            action: 'SET ACTIVE TAB KEY',
            key,
        });

        dispatch({
            type: types.SET_ACTIVE_TAB_KEY,
            activeTabKey: key,
        });
    };
}

export function zipReturnError(payload) {
    return (dispatch) => {
        sendLog({
            action: 'ZIP RETURN ERROR',
            payload,
        });

        dispatch({
            type: types.ZIP_RETURN_ERROR,
            payload,
        });
    };
}

export function sicReturnError(payload) {
    return (dispatch) => {
        sendLog({
            action: 'SIC RETURN ERROR',
            payload,
        });

        dispatch({
            type: types.SIC_RETURN_ERROR,
            payload,
        });
    };
}
// export function updateSuppLifeSelectedPlans(serviceType) {
//     return (dispatch) => {
//         dispatch({
//             type: types.SERVICE[serviceType].CLEAR_SELECTED_PLANS
//         });
//     };
// }

// Toggles between showing/not showing preferred plans view

export function togglePreferredPlans(serviceType) {
    return (dispatch) => {
        sendLog({
            action: 'TOGGLE PREFERRED PLANS VIEW',
        });

        dispatch({
            type: types.SERVICE[serviceType].TOGGLE_PREFERRED_PLANS,
        });
    };
}
/**
 *
 * @param {string} product indicated which product's contribution should be stored
 * @param {number} newContribution number to indicate
 * @param {boolean} isEmployer true if employer, false if employee
 */
export function changeContribution(product, newContribution, conversionMethod, isDependent) {
    return (dispatch) => {
        dispatch({
            type: types.CHANGE_EMPLOYEE_CONTRIB,
            payload: newContribution,
            product,
            isDependent,
            conversionMethod,
        });
    };
}

export function changeLifeContribution(product, newContribution, conversionMethod, isDependent) {
    return (dispatch) => {
        dispatch({
            type: types.CHANGE_EMPLOYEE_LIFE_CONTRIB,
            payload: newContribution,
            product,
            isDependent,
            conversionMethod,
        });
    };
}

/**
 * @param {boolean} supportedArea true if ares zipcode is supported, false otherwise
\ */
export function updateSupportedAreaQQ(supportedArea) {
    return {
        type: types.UPDATE_SUPPORTED_AREA_QQ,
        supportedArea,
    };
}

export function updateUHCStateConfig(config) {
    return {
        type: types.UPDATE_UHC_STATE_CONFIG,
        payload:{config},
    };
}

export function setCensusComplete(censusComplete) {
    return (dispatch) => {
        sendLog({
            action: 'SET CENSUS STATUS',
            censusComplete,
        });

        dispatch({
            type: types.SET_CENSUS_STATUS,
            censusComplete,
        });
    };
}
export function setQuoteCriteria(payload, source) {
    return (dispatch) => {
        sendLog({
            action: 'SET QUOTE CRITERIA',
        });
        dispatch({
            type: types.SET_QUOTE_CRITERIA,
            payload,
            source,
        });
    };
}

export function setOISABit(payload) {
    return (dispatch) => {
        sendLog({
            action: 'SET OI SA BIT',
        });
        dispatch({
            type: types.SET_OI_SA_BIT,
            payload,
        });
    };
}
/**
 * @param {string} salaryRatingMethodSelection Determines if salary is optional or required for census
 */
export function setSalaryRatingMethod(salaryRatingMethodSelection) {
    return (dispatch) => {
        sendLog({
            action: 'SET CENSUS SALARY RATING METHOD',
        });
        dispatch({
            type: types.SET_CENSUS_SALARY_RATING_METHOD,
            salaryRatingMethodSelection,
        });
    };
}

/** Function that looks at employees in company profile and returns true if any are out of area
 * @param {Array} employees Nested array of employees from companyProfile representing array of employees at each location
 */
export const checkEmployeesForOOA = (employees, platform = '', quoteStatus = '') => {
    let areOOAEmployees = false;
    if (platform === 'PRIME' && quoteStatus === 'Installation') {
        employees.forEach((location) => {
            if (location.some((employee) => employee.contactInfo ? employee.contactInfo.outOfArea === 'Yes' : employee.outOfArea === 'Yes')) {
                areOOAEmployees = true;
            }
        });
    } else {
        employees.forEach((location) => {
            if (location.some((employee) => employee.outOfArea === 'Yes')) {
                areOOAEmployees = true;
            }
        });

    }
    return areOOAEmployees;
}

export function updatePackageLabel(packageLabel) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_PACKAGE_LABEL,
            payload: packageLabel
        })
    }
}

// calculate dependant life rate
export function updateLifeDepPremium(rate, planCode) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_LIFE_DEP_BASIC_PREMIUM,
            rate,
            planCode
        });
    }
}

export function checkForUHCSupport(stateCode, page = 'quickQuote', effectiveDate){
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios.get(`${apiRoutes.quickQuotes}/uhcStateSupport?stateCode=${stateCode}`)
                .then(response => {
                    if (response.data) {
                        if (page === 'quickQuote' && ('allowQuickQuote' in response.data)) dispatch(updateSupportedAreaQQ(response.data.allowQuickQuote))
                        else if (page === 'fullQuote' && ('allowFullQuote' in response.data))dispatch( updateSupportedAreaFS(response.data.allowFullQuote))
                        restrictUserIfNotLicensed(response.data.stateCode, response.data.restrictedState, effectiveDate);
                    } else {
                        dispatch(updateSupportedAreaQQ(false));
                        dispatch(updateSupportedAreaFS(false));
                    }
                    dispatch(updateUHCStateConfig(response.data))
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject(err);
                })
        })
    }
}

export function setSpecialtySelectedProducts(selectedProducts) {
    return (dispatch) => {
        sendLog({
            action: 'SET_SELECTED_PRODUCTS',
        });
        dispatch({
            type: types.SET_SELECTED_PRODUCTS,
            selectedProducts,
        });
    };
}

export function setValidateQuoteData(validateQuoteData) {
    return (dispatch) => {
        dispatch({
            type: types.SET_VALIDATE_QUOTE_DATA,
            validateQuoteData,
        });
    };
}

export function updateParentPlanCode(planType, planCode, parentPlanCode) {
    return (dispatch) => {
        dispatch({
            type: types[`UPDATE_PARENT_PLAN_CODE_${planType.toUpperCase()}`],
            planCode,
            parentPlanCode,
        });
    };
}

// export function updateSuppLifeSelectedPlan(plan,field,planType){
//     return (dispatch) => {
//         dispatch({
//             type: types.SERVICE.SUPPLIFE.UPDATE_SELECTED_PLAN,
//              plan,
//              field,
//              planType   
//         });
//     };
// }
export function updateSelectedMedicalPlans(payload) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_SELECTED_MEDICAL_PLAN,
            payload,
        });
    };
}

export function initializeMedicalFilterCriteria(payload) {
    return (dispatch) => {
        dispatch({
            type: types.INITIALIZE_MEDICAL_FILTER_CRITERIA,
            payload,
        });
    };
}
export function updateSuppLifeSelectedPlan(plan,field,planType) {
    return (dispatch) => {
        dispatch({
            type: types.SERVICE.SUPPLIFE.UPDATE_SELECTED_PLAN,
            plan,
            field,
            planType
        });
    }
}
