import { validateDateString, isValidDate, validateAutoDate, validateName } from '../../../../../utils/formatters/strings';
import {
    validateMoney, validateAge, validateZipCode, getAgeFromDOB, getDOBFromAge,
} from '../../../../../utils/formatters/numbers';
import { validateInput, isValidCoverage } from './validationHelpers';
import deepCopy from '../../../../../utils/deepCopy';

/**
 *
 * @description Validates a value based on the given rule types
 *
 * @param {string} value the value that needs to be validated
 * @param {JSON} validationRules an oject with keys = rule types, and
 * values as true if that rule needs to be enforced
 *
 * @returns {(string|null)} a validated format of the input string, or null if it was a bad input
 */
const validateValue = (value, validationRules) => {
    let newValue = value;
    if (validationRules.autoDate) {
        const validatedAutoDate = validateAutoDate(newValue);
        if (validatedAutoDate === null) {
            return null;
        }

        newValue = validatedAutoDate;
    }
    if (validationRules.date) {
        const validatedDate = validateDateString(newValue);
        if (validatedDate == null) {
            return null;
        }

        newValue = validatedDate;
    } else if (validationRules.currency) {
        const validatedCurrency = validateMoney(newValue);
        if (validatedCurrency === null) {
            return null;
        }

        newValue = validatedCurrency;
    } else if (validationRules.age) {
        const validatedAge = validateAge(newValue);
        if (validatedAge === null) {
            return null;
        }

        newValue = validatedAge;
    } else if (validationRules.zipCode) {
        const validatedZipCode = validateZipCode(newValue);
        if (validatedZipCode === null) {
            return null;
        }

        newValue = validatedZipCode;
    } else if (validationRules.autoDate) {
        const validatedAutoDate = validateAutoDate(newValue);
        if (validatedAutoDate === null) {
            return null;
        }

        newValue = validatedAutoDate;
    }
    else if (validationRules.autoDate) {
        const validatedAutoDate = validateAutoDate(newValue);
        if (validatedAutoDate === null) {
            return null;
        }

        newValue = validatedAutoDate;
    }
    else if (validationRules.name) {
        const validatedName = validateName(newValue)
        if (validatedName === null) {
            return null
        }
        newValue = validatedName
    }
    return newValue;
};

/**
 *
 * @description Pass in the current state, the desired object / value change,
 *  and validation rules to receive a new state
 *
 * @param {JSON} state - the state of the parent component calling this function
 * @param {string} newValue - the new value that will be reflected in the returned state
 * @param {string} objectTypeKey - the key name in state that holds the list of objects
 * @param {number} itemIndex - the index of the object in the array in state
 * @param {string} valueKeyName - they key name of the value we are updating in the object at the given index
 * @param {JSON} validationRules - an object with keys = rule types, and values as true if that rule needs to be enforced
 * @param {string} parentItemIndex - index of the parent object in its array
 * @param {string} grandparentIndex - index of the higher order parent object in its array, only required if this value is for a sub-item
 * @param {string} effectiveDate - the date the user entered to begin coverage. Used for age calculations.
 *
 * @returns {(JSON)|null} an object with key names and values that can update the parent component's state with setState, or null if no changes to be made
 */
export const getNewListStateFromNewValue = (state, newValue, objectTypeKey,
    itemIndex, valueKeyName, validationRules, parentItemIndex, grandparentIndex,
    effectiveDate, item) => {

    // First, we clean up the value (e.g., changing a date string from "10/2/" to "10/02/")
    // If the value is not a valid keystroke and thus returns null,
    // then we return a null state to indicate state should not be updated
    const cleanedValue = validateValue(newValue, validationRules);
    if (cleanedValue == null) {
        return null;
    }

    // Find the version of the object (i.e. either employee or dependent of employee)
    // in state that we want to update
    let oldObjectArray;
    if (grandparentIndex != null && grandparentIndex !== undefined) {
        oldObjectArray = state.employees[grandparentIndex][parentItemIndex].dependents;
    } else {
        oldObjectArray = state.employees[parentItemIndex];
    }

    const oldObject = oldObjectArray[itemIndex];

    // Now we create the updated version of that object
    const updatedObject = {
        ...oldObject,
        [valueKeyName]: newValue,
        errors: {
            ...oldObject.errors,
            [valueKeyName]: validateInput(cleanedValue, validationRules, item),
        },
    };

    // If the employeeStatus is updated from census page than updated that info in enrollment page as well 
    if(valueKeyName === 'employeeStatus' && updatedObject.employeeInfo) {
        updatedObject.employeeInfo.employeeStatus = newValue
        if(newValue === 'COBRA' && (updatedObject.employeeInfo.cobraStartDate === '' || updatedObject.employeeInfo.cobraEndDate === '')) {
            updatedObject.employeeInfo.status = 'In Progress'
            updatedObject.status = 'In Progress'
        } else if(newValue === 'Active' || newValue === 'Retired') {
            updatedObject.employeeInfo.cobraStartDate = ''
            updatedObject.employeeInfo.cobraEndDate = ''
        }
    }

    // if coverage are changed from is waived to any other then update other flags. This scenario can occur mainly for template case.
    if (valueKeyName === 'coverages') {   //&& updatedObject.waiveCoverage?.isWaivingAll === 'Yes') {

        if (checkIfAnyProductContainsNotWaive(cleanedValue)) {
            if (updatedObject.waiveCoverage) {
                updatedObject.waiveCoverage.isWaivingAll = 'No';
                updatedObject.waiveOption = getUpdatedWaiveOption(cleanedValue);
            }
        }

        // Update Waive Option Array :: 
        // Instead of waive all products now add all the produts which are not waived;
        // If medical product goes from waived to unwaived state then reset all the medical waive reason fields
        if (updatedObject.coverages.medical !== 'W') {
            if (updatedObject.waiveCoverage) {
                updatedObject.waiveCoverage.medicalWaiverReason = '';
                updatedObject.waiveReason = '';
            }

            if (updatedObject.medicalCoverageInfo) {
                updatedObject.medicalCoverageInfo.medicalWaiverReason = '';
                updatedObject.medicalCoverageInfo.medicalWaived = false;
            }
        }

        // Check for product selection array and fix it.
        updateProductSelection(cleanedValue, updatedObject.productSelection);
    }   

    // If the DOB was updated, we automatically also update the age to match it
    if (valueKeyName === 'dob' && isValidDate(newValue)) {
        updatedObject.age = getAgeFromDOB(newValue, effectiveDate);
        updatedObject.errors = {
            ...updatedObject.errors,
            age: null,
        };
        updatedObject.dobInput = true;
    }

    // Likewise, if the age was updated, we automatically update DOB to
    // match the age on the given effective date,
    if (valueKeyName === 'age' && validateAge(newValue)) {
        // Don't update the DOB if age was unchanged unless the DOB has an error
        if (newValue !== oldObject.age || oldObject.errors.dob !== null) {
            updatedObject.dob = getDOBFromAge(newValue, effectiveDate, true);
            updatedObject.errors = {
                ...updatedObject.errors,
                dob: null,
            };
            // Update dob input field if age is changed. 
            updatedObject.dobInput = false;
        }
    }

    //For Depedents if the relationship is updated, we automatically update coverges for its employee.
    let isCoverageChanged, currentEmployee;
    if(objectTypeKey === 'dependents' && valueKeyName === 'relationship'){
        currentEmployee = deepCopy(state.employees[grandparentIndex][parentItemIndex]);
        isCoverageChanged = true;
        currentEmployee = updateProductTier(currentEmployee, itemIndex, newValue); 
    }

    // Now we create the state of the edited object sliced into the array
    const newObjectArray = oldObjectArray.slice(0, itemIndex)
        .concat(updatedObject)
        .concat(oldObjectArray.slice(itemIndex + 1));

    // If it is a dependent, we nest it in the parent state and return that state
    if (grandparentIndex != null && grandparentIndex !== undefined) {
        const updatedEE = isCoverageChanged ? currentEmployee : state.employees[grandparentIndex][parentItemIndex];
        let updatedLocation = state.employees[grandparentIndex].slice(0, parentItemIndex).concat({
            //...state.employees[grandparentIndex][parentItemIndex],
            ...updatedEE,
            dependents: newObjectArray,
        }).concat(state.employees[grandparentIndex].slice(parentItemIndex + 1));

        updatedLocation = updatedLocation.slice(0, parentItemIndex).concat({
            ...updatedLocation[parentItemIndex],
            errors: {
                ...updatedLocation[parentItemIndex].errors,
                coverages: isValidCoverage(updatedLocation)
            }
        }).concat(updatedLocation.slice(parentItemIndex + 1));

        return {
            unsavedChanges: true,
            employees: state.employees.slice(0, grandparentIndex).concat([updatedLocation]).concat(state.employees.slice(grandparentIndex + 1)),
        };
    }
    // Else, we just need to return the state for that object
    return {
        unsavedChanges: true,
        employees: state.employees.slice(0, parentItemIndex).concat([newObjectArray]).concat(state.employees.slice(parentItemIndex + 1)),
    };
};

//For Depedents if the relationship is updated, we automatically update coverges for its employee.
// Spouse || Domestic Partner || Civil Union Partner -> EE/SP
// Child -> EE/CH
// (Spouse || Domestic Partner || Civil Union Partner) & Child -> EE/FAM
export const updateProductTier = (currentEmployee, itemIndex, newValue) => {
    const dependents = currentEmployee.dependents;
    let covType = '';
    let depRelationshipList = dependents.map(dep=>dep.relationship.toUpperCase());
    depRelationshipList[itemIndex]=newValue?newValue.toUpperCase():null;
    
    if((depRelationshipList.includes('SPOUSE') || depRelationshipList.includes('DOMESTIC PARTNER') || depRelationshipList.includes('CIVIL UNION PARTNER')) && (depRelationshipList.includes('CHILD') || depRelationshipList.includes('DEPENDENT CHILD'))){
        covType='EE/FAM';
    }else if((depRelationshipList.includes('SPOUSE') || depRelationshipList.includes('DOMESTIC PARTNER') || depRelationshipList.includes('CIVIL UNION PARTNER')) && (depRelationshipList.includes('CHILD') === false || depRelationshipList.includes('DEPENDENT CHILD') ===false)){
        covType='EE/SP';
    }else if(!(depRelationshipList.includes('SPOUSE') && depRelationshipList.includes('DOMESTIC PARTNER') && depRelationshipList.includes('CIVIL UNION PARTNER')) && (depRelationshipList.includes('CHILD') || depRelationshipList.includes('DEPENDENT CHILD'))){
        covType='EE/CH';
    }else{
        covType='EE';
    }
    let newCoverages = deepCopy(currentEmployee.coverages);
    const allowedProducts = ['MEDICAL', 'DENTAL', 'VISION']
    for(const key in newCoverages){
        if(allowedProducts.includes(key.toUpperCase()))
            newCoverages[key]=covType;
    }
    currentEmployee.coverages=newCoverages;
    return currentEmployee;
}

/**
 *
 * @description Pass in the desired value change and validation rules to receive a
 * new state for a component that just tracks that individual value (i.e., EditInput.jsx)
 *
 * @param {string} value the value that needs to be validated
 * @param {JSON} validationRules an oject with keys = rule types, and
 * values as true if that rule needs to be enforced
 *
 * @returns {(JSON|null)} an object with key names and values that can update
 * the parent component's state with setState, or null if no changes to be made
 */
export const getNewValueStateFromNewValue = (newValue, validationRules, item = null) => {
    // Same logic as cleaning up newValue above
    const cleanedValue = validateValue(newValue, validationRules);
    if (cleanedValue === null) {
        return null;
    }

    return {
        value: cleanedValue,
        error: validateInput(newValue, validationRules, item),
    };
};

const getProductKeys = () => {
    return ['medical', 'dental', 'vision', 'basicLife'];
}

const checkIfAnyProductContainsNotWaive = (cleanedValue) => {
    const productKeys = getProductKeys();
    for (let i = 0; i < productKeys.length; i++) {
        if (cleanedValue[productKeys[i]] !== 'W')
            return true;
    }
    return false;
}

const getUpdatedWaiveOption = (cleanedValue) => {
    const waiveOptionMap = {
        medical: 'medical',
        dental: 'dental',
        vision: 'vision',
        basicLife: 'life'
    }
    let waiveOption = [];
    const productKeys = getProductKeys();
    for (let i = 0; i < productKeys.length; i++) {
        if (cleanedValue[productKeys[i]] === 'W')
            waiveOption.push(waiveOptionMap[productKeys[i]]);
    }
    return waiveOption;
}

const updateProductSelection = (coverages, productSelection) => {
    const waiveOptionMap = {
        medical: 'medical',
        dental: 'dental',
        vision: 'vision',
        basicLife: 'life'
    }
    if (productSelection && productSelection[0]) {
        Object.keys(coverages).forEach(key => {
            if (coverages[key] === 'W') {
                if (waiveOptionMap[key]) {
                    productSelection[0][waiveOptionMap[key]] = 'waive'
                }
            } else {
                if (waiveOptionMap[key] && productSelection[0][waiveOptionMap[key]] === 'waive') {
                    productSelection[0][waiveOptionMap[key]] = 'select';
                }
            }
        })
    }
}