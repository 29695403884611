import apiRoutes from './apiRouteConfig';
import { axios } from './axiosConfig';

export const saveCaseTrackingInStateStore = (caseTracking, caseId, currentQuoteId) => {
    return axios.put(`${apiRoutes.mongo}/stateStore/updateCaseTracking/${caseId}/${currentQuoteId}`, caseTracking)
        .then(res => {
            return res
        }).catch(err => {
            throw err;
        })
}