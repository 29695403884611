import deepCopy from '../../../utils/deepCopy';
import { sortPlans } from './filterHelper';
import {filterPlans, findPremiumDomainMax} from './filterHelper';
import {getTierKeyMap} from '../../../actions/actionHelpers/fullQuoteActionHelper';

// Request details on plans that are available in the given zip code, on the effective date
//
// Parameters
//  payload:            Holds the request object for the API, consisting of groupSize, zipCode, stateCode, effectiveDate, countyCode
export const handleRequestPlans = (state, action, initialFilterCriteria, initialfilterTotalsCache) => ({
    ...state,
    isFetchingPlans: true,
    allRatesFetched: false,
    quickQuotePlansError: null,
    ...(action.isCaseSubmitted || action.shouldRetainFilters ? {
        filterCriteria: {
            ...initialFilterCriteria,
            ...state.filterCriteria
        }
    } : {
            filterCriteria: initialFilterCriteria
    }),    // Reset filters when the user requests for a new zip code
    ...(action.isCaseSubmitted || action.shouldRetainFilters ? {
        filterTotalsCache: state.filterTotalsCache
    } : {
            filterTotalsCache: initialfilterTotalsCache
    }),
    favoritedPlans: [],
    displayLeftSideFilter: false,
    oopDomainMax: [0, 9999999],
    dedDomainMax: [0, 9999999],
    premDomainMax: [0, 9999999],
    selectedPlans: state.selectedPlans ? state.selectedPlans : [],
    // selectedPlansMap: {}, commented to retain plans
    selectedPackageLabel: null,
    medicalAndRxPlanCodeSearchError: '', // Reset Medical and Rx plan code search error to none
    ...(state.allPlans && { allPlans: [] }),
    plans: [],
    visiblePlans: [],
});

export const handleRequestPlansRenewals = (state, initialFilterCriteria, initialfilterTotalsCache, fullProps, productType) => {
    const obj = {
        ...state,
        isFetchingPlans: true,
        allRatesFetched: false,
        quickQuotePlansError: null,
        filterCriteria: initialFilterCriteria, // Reset filters when the user requests for a new zip code
        filterTotalsCache: initialfilterTotalsCache,
        selectedPlans: [],
        selectedPlansMap: {},
        favoritedPlans: [],
        displayLeftSideFilter: false,
        oopDomainMax: [0, 9999999],
        dedDomainMax: [0, 9999999],
        premDomainMax: [0, 9999999],
    };
    if (productType === 'dental') {
        obj.selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.dental && fullProps.renewalShoppingHelper.dental.selectedPlans ? fullProps.renewalShoppingHelper.dental.selectedPlans : [];
    } else if (productType === 'vision') {
        obj.selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.vision && fullProps.renewalShoppingHelper.vision.selectedPlans ? fullProps.renewalShoppingHelper.vision.selectedPlans : [];
    } else if (productType === 'life') {
        obj.selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.life && fullProps.renewalShoppingHelper.life.selectedPlans ? fullProps.renewalShoppingHelper.life.selectedPlans : [];
    }
    return obj;
};

export const resetDetails = (state, initialFilterCriteria, initialfilterTotalsCache) => ({
    ...state,
    isFetchingPlans: false,
    allRatesFetched: false,
    quickQuotePlansError: null,
    // Reset filters when the user requests for a new zip code
    filterCriteria: initialFilterCriteria,
    filterTotalsCache: initialfilterTotalsCache,
    filteredResults: [],
    favoritedPlans: [],
    displayLeftSideFilter: false,
    favoritedPlansMap: {},
    oopDomainMax: [0, 9999999],
    dedDomainMax: [0, 9999999],
    premDomainMax: [0, 9999999],
});

// Handle receiving specialty plans, returning a new state
//
// Parameters
//  state: state of the reducer passing in the received plans
//  plans: array of the received plans
export const handleReceiveSpecialtyPlans = (state, action) => {
    let stateToUpdate = { ...state };
    const {
        payload: { plans },
        quickQuoteRequest: { zipObj: { zipCode: currentZip } },
    } = action;
    const {
        selectedPlans: stateSelectedPlans,
        selectedPlansMap: stateSelectedPlansMap,
        favoritedPlans: stateFavoritedPlans,
        quickQuoteRequest: { zipObj: { zipCode: prevZip } },
    } = state;

    const changedZip = prevZip !== currentZip;
    const selectedPlans = changedZip ? [] : stateSelectedPlans;
    const selectedPlansMap = changedZip ? {} : stateSelectedPlansMap;

    const updatedPlans = plans.map((plan) => {
        const selected = selectedPlans.some((pln) => (pln.code === plan.code));
        const favorited = stateFavoritedPlans.some((pln) => (pln.code === plan.code));
        return { ...plan, selected, favorited };
    });

    if (plans[0] === undefined) {
        stateToUpdate = {
            ...state,
            allRatesFetched: true,
        };
    }

    return {
        ...stateToUpdate,
        isFetchingPlans: false,
        quickQuoteRequest: action.quickQuoteRequest,
        plans: updatedPlans,
        visiblePlans: updatedPlans,
        selectedPlans,
        selectedPlansMap,
        numRatesFetched: 0,
        allPlans: plans,
        advancefilterSet: false,
    };
};

export const handleReceiveSpecialtyPlansRenewals = (state, plans, fullProps, productType) => {
    plans = plans.map((plan) => ({ ...plan, selected: false, favorited: false }));
    const visiblePlans = plans;
    const favoritedPlans = [];
    let selectedPlans = [];
    if (productType === 'dental') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.dental && fullProps.renewalShoppingHelper.dental.selectedPlans ? fullProps.renewalShoppingHelper.dental.selectedPlans : [];
    } else if (productType === 'vision') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.vision && fullProps.renewalShoppingHelper.vision.selectedPlans ? fullProps.renewalShoppingHelper.vision.selectedPlans : [];
    } else if (productType === 'life') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.life && fullProps.renewalShoppingHelper.life.selectedPlans ? fullProps.renewalShoppingHelper.life.selectedPlans : [];
    }
    const selectedPlansMap = {};

    if (plans[0] !== undefined) {
        const favoritedPlanCodes = [];
        favoritedPlanCodes.forEach((code) => {
            const planIdx = plans.findIndex((plan) => plan.code === code);
            const visiblePlanIdx = visiblePlans.findIndex((plan) => plan.code === code);
            if (planIdx >= 0) {
                plans[planIdx].favorited = true;
                favoritedPlans.push(plans[planIdx]);
            }
            if (visiblePlanIdx >= 0) {
                visiblePlans[visiblePlanIdx].favorited = true;
            }
        });

        const selectedPlanCodes = [];
        selectedPlanCodes.forEach((code) => {
            const planIdx = plans.findIndex((plan) => plan.code === code);
            if (planIdx >= 0) {
                plans[planIdx].selected = true;
                plans[planIdx].selectedPackages = ['Single'];
                selectedPlans.push(plans[planIdx]);
                selectedPlansMap.Single = selectedPlans;
            }
        });
    } else {
        state = {
            ...state,
            allRatesFetched: true,
        };
    }

    return {
        ...state,
        isFetchingPlans: false,
        plans,
        visiblePlans,
        selectedPlans,
        selectedPlansMap,
        favoritedPlans,
        numRatesFetched: 0,
        allPlans: plans,
        advancefilterSet: false,
        [`renewal${productType}Received`] : true
    };
};

export const handleReceiveSpecialtyAllPlans = (state, payload) => {
    // TODO Delete this if not needed
    const { selectedPlans } = state;
    let allPlans = payload.plans.map((plan) => {
        const selected = selectedPlans.some((pln) => (pln.code === plan.code));
        return { ...plan, selected, favorited: false };
    });
    allPlans = state.allPlans.concat(allPlans);
    // Remove duplicate plans(all plans call happening multiple times)
    allPlans = removeDuplicatePlans(allPlans);
    if (allPlans.length > 0) {
        const newState = {
            ...state,
            allPlans,
            filteredResults: selectedPlans,
        };
        if (payload.totalCount !== 'NA') {
            let preferredPlans = state.visiblePlans.filter(plan => plan.isPreferredPlan);
            newState.totalCount = payload.totalCount + preferredPlans.length;
        }
        return newState;
    }
    return {
        ...state,
        totalCount: 0,
    };
};

export const handleReceiveSpecialtyAllPlansRenewals = (state, payload, fullProps, productType) => {
    let selectedPlans = [];
    if (productType === 'dental') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.dental && fullProps.renewalShoppingHelper.dental.selectedPlans ? fullProps.renewalShoppingHelper.dental.selectedPlans : [];
    } else if (productType === 'vision') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.vision && fullProps.renewalShoppingHelper.vision.selectedPlans ? fullProps.renewalShoppingHelper.vision.selectedPlans : [];
    } else if (productType === 'life') {
        selectedPlans = fullProps.renewalShoppingHelper && fullProps.renewalShoppingHelper.life && fullProps.renewalShoppingHelper.life.selectedPlans ? fullProps.renewalShoppingHelper.life.selectedPlans : [];
    }
    const selectedPlansMap = {};
    let allPlans = payload.plans.map((plan) => ({ ...plan, selected: false, favorited: false }));
    allPlans = state.allPlans.concat(allPlans);
    if (allPlans.length > 0) {
        const selectedPlanCodes = [];
        selectedPlanCodes.forEach((code) => {
            const planIdx = allPlans.findIndex((plan) => plan.code === code);
            if (planIdx >= 0) {
                allPlans[planIdx].selected = true;
                allPlans[planIdx].filtered = !allPlans[planIdx].isPreferredPlan;
                allPlans[planIdx].selectedPackages = ['Single'];
                selectedPlans.push(allPlans[planIdx]);
                selectedPlansMap.Single = selectedPlans;
            }
        });

        const newState = {
            ...state,
            allPlans,
            selectedPlans,
            selectedPlansMap,
            filteredResults: selectedPlans,
        };
        if (payload.totalCount !== 'NA') {
            let preferredPlans = state.visiblePlans.filter(plan => plan.isPreferredPlan);
            newState.totalCount = payload.totalCount + preferredPlans.length;
        }
        return newState;
    }
    return {
        ...state,
        totalCount: 0,
    };
};

// Catch errors when fetching plan details
//
// Parameters
//  payload:        The error object
export const handlePlansError = (state, error) => ({
    ...state,
    isFetchingPlans: false,
    quickQuotePlansError: error,
});

// Request the rates for plans, 10 rates at a time
export const handleRequestRates = (state) => ({
    ...state,
    numRateBatchesFetching: state.numRateBatchesFetching + 1,
    quickQuoteRatesError: null,
});

// Catch errors when fetching rates
//
// Parameters
//  payload:        The error object
export const handleRatesError = (state, error) => ({
    ...state,
    numRateBatchesFetching: state.numRateBatchesFetching - 1,
    quickQuoteRatesError: error,
});

export const handleDecideError = (state, error) => ({
    ...state,
    decideDataError: error,
});

export const handleReceiveSpecialtyRates = (state, action) => {
    let newPlans = deepCopy(state.plans);
    let newSelectedPlans = deepCopy(state.selectedPlans);
    let newAllPlans = deepCopy(state.allPlans);
    const filteredResults = deepCopy(state.filteredResults) || [];
    const numRatesFetched = state.numRatesFetched + action.rates.length;
    let allRatesFetched = state.plans.length === numRatesFetched;

    if (action.filteredResultsOnly && action.type !== 'RECEIVE_QUICK_QUOTE_RATES_LIFE' && action.type !== 'RECEIVE_RENEWAL_RATES_LIFE'
        && action.type !== 'RECEIVE_QUICK_QUOTE_RATES_STD' && action.type !== 'RECEIVE_QUICK_QUOTE_RATES_LTD' && action.type !== 'RECEIVE_QUICK_QUOTE_RATES_SUPPLIFE') {

        const planCodeIndexDict = {};
        const allFilteredPlanCodeIndexDict = {};
        const selectedPlanCodeIndexDict = {};
        const allPlanCodeIndexDict = {};
        newPlans.forEach((plan, idx) => {
            planCodeIndexDict[plan.code] = idx;
        });
        newSelectedPlans.forEach((plan, idx) => {
            selectedPlanCodeIndexDict[plan.code] = idx;
        });
        newAllPlans.forEach((plan, idx) => {
            allPlanCodeIndexDict[plan.code] = idx;
        });
        filteredResults.forEach((plan, idx) => {
            allFilteredPlanCodeIndexDict[plan.code] = idx;
        });

        // If it's a washington 2 Tier Case
        if (action.rateCallTier && action.selectedTier) {

            const plansObj = {
                newPlans,
                newSelectedPlans,
                newAllPlans,
                filteredResults
            }

            const indexes = {
                planCodeIndexDict,
                selectedPlanCodeIndexDict,
                allPlanCodeIndexDict,
                allFilteredPlanCodeIndexDict
            }

            getPlansWithUpdatedRates(action.filteredResultsOnly, plansObj, indexes, action)
        } else {

            action.rates.forEach((rate, i) => {
                const pIndex = planCodeIndexDict[rate.code];
                const spIndex = selectedPlanCodeIndexDict[rate.code];
                const apIndex = allPlanCodeIndexDict[rate.code];
                const filterPlanIndex = allFilteredPlanCodeIndexDict[rate.code];

                if (pIndex >= 0) {
                    newPlans[pIndex] = {
                        ...newPlans[pIndex],
                        ...rate,
                        quote: action.quotes[i],
                        finalMonthlyPremium: action.quotes[i],
                        finalRates: rate.monthlyPremium,
                        byCensus: action.rates[i].byCensus,
                    };
                }

                if (spIndex >= 0) {
                    const shouldRetainPlanRate = newSelectedPlans[spIndex].shouldRetainRate;

                    // DES Integration : if shouldRetainPlanRate is true for the selected record, system should retain the imported rate.
                    //  shouldRetainPlanRate value will be undefined or false for the NB cases and newly selected plans
                    if (!shouldRetainPlanRate) {
                        newSelectedPlans[spIndex] = {
                            ...newSelectedPlans[spIndex],
                            ...rate,
                            quote: action.quotes[i],
                            finalMonthlyPremium: action.quotes[i],
                            finalRates: rate.monthlyPremium,
                            byCensus: action.rates[i].byCensus,
                        };
                    }

                }

                if (apIndex >= 0) {
                    newAllPlans[apIndex] = {
                        ...newAllPlans[apIndex],
                        ...rate,
                        quote: action.quotes[i],
                        finalMonthlyPremium: action.quotes[i],
                        finalRates: rate.monthlyPremium,
                        byCensus: action.rates[i].byCensus,
                    };
                }
                
                if (filterPlanIndex >= 0) {
                    filteredResults[filterPlanIndex] = {
                        ...filteredResults[filterPlanIndex],
                        ...rate,
                        quote: action.quotes[i],
                        finalMonthlyPremium: action.quotes[i],
                        finalRates: rate.monthlyPremium,
                        byCensus: action.rates[i].byCensus,
                    };
                }
            });
        }
        allRatesFetched = true;
    } else if (action.filteredResultsOnly && (action.type === 'RECEIVE_QUICK_QUOTE_RATES_LIFE' || action.type === 'RECEIVE_RENEWAL_RATES_LIFE'
        || action.type === 'RECEIVE_QUICK_QUOTE_RATES_STD' || action.type === 'RECEIVE_QUICK_QUOTE_RATES_LTD' || action.type === 'RECEIVE_QUICK_QUOTE_RATES_SUPPLIFE')) {

        const planCodeIndexDict = {};
        const selectedPlanCodeIndexDict = {};
        const allFilteredPlanCodeIndexDict = {};
        const allPlanCodeIndexDict = {};
        // Changes for STD Rates : Std rates are called by using piaPlanCode
        if(action.isCirrus === true){
        const PlCode = ((action.type === 'RECEIVE_QUICK_QUOTE_RATES_STD' || action.type === 'RECEIVE_QUICK_QUOTE_RATES_LTD') && !(action.isCirrus) && action.type === 'RECEIVE_QUICK_QUOTE_RATES_SUPPLIFE')  ? 'piaPlanCode' : 'code' //US6627183 SGACA to CQS Update for Supp Life
        newPlans.forEach((plan, idx) => {
            planCodeIndexDict[plan[PlCode]]  = idx;
        });
        newSelectedPlans.forEach((plan, idx) => {
            selectedPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        newAllPlans.forEach((plan, idx) => {
            allPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        filteredResults.forEach((plan, idx) => {
            allFilteredPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        action.rates.forEach((rate, i) => {
            const pIndex = planCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const spIndex = selectedPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const apIndex = allPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const filterPlanIndex = allFilteredPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            if (pIndex >= 0) {
                newPlans[pIndex] = {
                    ...newPlans[pIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newPlans[pIndex] ? newPlans[pIndex].code : '',
                };
            }
            if (spIndex >= 0) {
                newSelectedPlans[spIndex] = {
                    ...newSelectedPlans[spIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newSelectedPlans[spIndex] ? newSelectedPlans[spIndex].code : '',
                };
            }
            if (apIndex >= 0) {
                newAllPlans[apIndex] = {
                    ...newAllPlans[apIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newAllPlans[apIndex] ? newAllPlans[apIndex].code : '',
                };
            }
            if (filterPlanIndex >= 0) {
                filteredResults[filterPlanIndex] = {
                    ...filteredResults[filterPlanIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: filteredResults[filterPlanIndex] ? filteredResults[filterPlanIndex].code : '',
                }
            }
        })
        allRatesFetched = true;
    } else {
        const PlCode = ((action.type === 'RECEIVE_QUICK_QUOTE_RATES_STD' || action.type === 'RECEIVE_QUICK_QUOTE_RATES_LTD') || !(action.isCirrus) && action.type === 'RECEIVE_QUICK_QUOTE_RATES_SUPPLIFE')  ? 'piaPlanCode' : 'code' //US6627183 SGACA to CQS Update for Supp Life
        newPlans.forEach((plan, idx) => {
            planCodeIndexDict[plan[PlCode]]  = idx;
        });
        newSelectedPlans.forEach((plan, idx) => {
            selectedPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        newAllPlans.forEach((plan, idx) => {
            allPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        filteredResults.forEach((plan, idx) => {
            allFilteredPlanCodeIndexDict[plan[PlCode]] = idx;
        });
        action.rates.forEach((rate, i) => {
            const pIndex = planCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const spIndex = selectedPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const apIndex = allPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            const filterPlanIndex = allFilteredPlanCodeIndexDict[rate.blCode || rate.stdPlanCode || rate.ltdPlanCode || rate.slEEPlanCode];
            if (pIndex >= 0) {
                newPlans[pIndex] = {
                    ...newPlans[pIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newPlans[pIndex] ? newPlans[pIndex].code : '',
                };
            }
            if (spIndex >= 0) {
                newSelectedPlans[spIndex] = {
                    ...newSelectedPlans[spIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newSelectedPlans[spIndex] ? newSelectedPlans[spIndex].code : '',
                };
            }
            if (apIndex >= 0) {
                newAllPlans[apIndex] = {
                    ...newAllPlans[apIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: newAllPlans[apIndex] ? newAllPlans[apIndex].code : '',
                };
            }
            if (filterPlanIndex >= 0) {
                filteredResults[filterPlanIndex] = {
                    ...filteredResults[filterPlanIndex],
                    ...rate,
                    quote: action.rates[i],
                    finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                    finalRates: action.rates[i],
                    byCensus: action.rates[i].byCensus,
                    code: filteredResults[filterPlanIndex] ? filteredResults[filterPlanIndex].code : '',
                }
            }
        })
        allRatesFetched = true;
    }

    } else if (action.type === "RECEIVE_QUICK_QUOTE_RATES_LIFE" || action.type === "RECEIVE_RENEWAL_RATES_LIFE"
        || action.type === "RECEIVE_QUICK_QUOTE_RATES_STD"  || action.type === "RECEIVE_QUICK_QUOTE_RATES_LTD" || action.type === "RECEIVE_QUICK_QUOTE_RATES_SUPPLIFE") {
        // action.rates = action.rates.lifeRates;
        // TODO: BLCRC Test these changes
        if (action.selectedClass && action.empClass) {

            action.rates.forEach((rate, i) => {
                let pIndex = newPlans.findIndex(plan => (rate.blCode === plan.code));
                let spIndex = newSelectedPlans.findIndex(plan => (rate.blCode === plan.code));

                if (action.type === 'RECEIVE_QUICK_QUOTE_RATES_STD' ) {
                    pIndex = newPlans.findIndex(plan => action.platform === 'PRIME' ? (rate.stdPlanCode === plan.piaPlanCode ) : (rate.stdPlanCode === plan.code ));
                    spIndex = newSelectedPlans.findIndex(plan => action.platform === 'PRIME' ? (rate.stdPlanCode ===  plan.piaPlanCode ): (rate.stdPlanCode ===  plan.code ));
                }

                if (action.type === "RECEIVE_QUICK_QUOTE_RATES_LTD" ) {
                    pIndex = newPlans.findIndex(plan => action.platform === 'PRIME' ?  (rate.ltdPlanCode === plan.piaPlanCode ) : (rate.ltdPlanCode === plan.code ) );
                    spIndex = newSelectedPlans.findIndex(plan => action.platform === 'PRIME' ? (rate.ltdPlanCode === plan.piaPlanCode) : (rate.ltdPlanCode === plan.code) );
                }

                if (pIndex > -1) {

                    newPlans[pIndex] = {
                        ...newPlans[pIndex],
                        classRates: {
                            ...newPlans[pIndex].classRates,
                            [action.empClass]: {
                                quote: action.rates[i],
                                finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                                finalRates: action.rates[i],
                                byCensus: action.rates[i].byCensus,
                                code: newPlans[pIndex] ? newPlans[pIndex].code : ''
                            }
                        }
                    }

                    if (action.selectedClass === action.empClass) {
                        newPlans[pIndex] = {
                            ...newPlans[pIndex],
                            quote: action.rates[i],
                            finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                            finalRates: action.rates[i],
                            byCensus: action.rates[i].byCensus,
                            code: newPlans[pIndex] ? newPlans[pIndex].code : ''
                        }
                    }
                }

                if(spIndex > -1){
                    newSelectedPlans[spIndex] = {
                        ...newSelectedPlans[spIndex],
                        classRates: {
                            ...newSelectedPlans[spIndex].classRates,
                            [action.empClass]: {
                                quote: action.rates[i],
                                finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                                finalRates: action.rates[i],
                                byCensus: action.rates[i].byCensus,
                                code: newSelectedPlans[spIndex] ? newSelectedPlans[spIndex].code : ''
                            }
                        }
                    }

                    if (action.selectedClass === action.empClass) {
                        newSelectedPlans[spIndex] = {
                            ...newSelectedPlans[spIndex],
                            quote: action.rates[i],
                            finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                            finalRates: action.rates[i],
                            byCensus: action.rates[i].byCensus,
                            code: newSelectedPlans[spIndex] ? newSelectedPlans[spIndex].code : ''
                        }
                    }
                }
            })
        } else {
            action.rates.forEach((rate, i) => {

                const pIndex = newPlans.findIndex(plan => ((rate.code && plan.code && (rate.code === plan.code))
                    || (rate.code && plan.primePlanCode && (rate.code === plan.primePlanCode))
                    || (action.platform ==='PRIME' ? (rate.stdPlanCode && plan.piaPlanCode && (rate.stdPlanCode === plan.piaPlanCode)) : (rate.stdPlanCode && plan.code && (rate.stdPlanCode === plan.code)))
                    || (action.platform ==='PRIME' ? (rate.ltdPlanCode && plan.piaPlanCode && (rate.ltdPlanCode === plan.piaPlanCode)) : (rate.ltdPlanCode && plan.code && (rate.ltdPlanCode === plan.code)))
                    || (action.platform === 'PRIME' ? (rate.slEEPlanCode && plan.piaPlanCode && (rate.slEEPlanCode === plan.piaPlanCode)) : (rate.slEEPlanCode && plan.code && (rate.slEEPlanCode === plan.code)))
                ));

                if (pIndex > -1) {
                    newPlans[pIndex] = {
                        ...newPlans[pIndex],
                        quote: action.rates[i],
                        finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                        finalRates: action.rates[i],
                        byCensus: action.rates[i].byCensus,
                        code: newPlans[pIndex] ? newPlans[pIndex].code : '',
                    };
                }

                const spIndex = newSelectedPlans.findIndex(plan =>
                    ((rate.code && plan.code && (rate.code === plan.code))
                        || (rate.code && plan.primePlanCode && (rate.code === plan.primePlanCode))
                        || (action.platform === 'PRIME' ?
                                (rate.stdPlanCode && plan.piaPlanCode && (rate.stdPlanCode === plan.piaPlanCode))
                            :
                                (rate.stdPlanCode && plan.code && (rate.stdPlanCode === plan.code)))
                        || (action.platform === 'PRIME' ?
                                (rate.ltdPlanCode && plan.piaPlanCode && (rate.ltdPlanCode === plan.piaPlanCode))
                            :
                                (rate.ltdPlanCode && plan.code && (rate.ltdPlanCode === plan.code)))
                        || (action.platform === 'PRIME' ?
                                (rate.slEEPlanCode && plan.piaPlanCode && (rate.slEEPlanCode === plan.piaPlanCode))
                            :
                                (rate.slEEPlanCode && plan.code && (rate.slEEPlanCode === plan.code)))
                    ));

                if (spIndex > -1) {
                    newSelectedPlans[spIndex] = {
                        ...newSelectedPlans[spIndex],
                        quote: action.rates[i],
                        finalMonthlyPremium: action.rates[i].totalMonthlyPremium,
                        finalRates: action.rates[i],
                        byCensus: action.rates[i].byCensus,
                        code: newSelectedPlans[spIndex] ? newSelectedPlans[spIndex].code : '',
                    };
                }
            })
        }
    } else {

        // If it's a washington two tier case
        if (action.rateCallTier && action.selectedTier) {

            let planObj = {
                newPlans,
                newSelectedPlans
            }
            const planCodeIndexDict = {};
            const selectedPlanCodeIndexDict = {};
            newPlans.forEach((plan, idx) => {
                planCodeIndexDict[plan.code] = idx;
            });
            newSelectedPlans.forEach((plan, idx) => {
                selectedPlanCodeIndexDict[plan.code] = idx;
            });
            let indexes = {
                planCodeIndexDict,
                selectedPlanCodeIndexDict
            }
            getPlansWithUpdatedRates(false, planObj, indexes, action);

        } else {

            for (let i = 0; i < Math.min(action.rates.length, newPlans.length - action.index); i++) {
                newPlans[i + action.index] = {
                    ...newPlans[i + action.index],
                    ...action.rates[i],
                    quote: action.quotes[i],
                    finalMonthlyPremium: action.quotes[i],
                    finalRates: action.rates[i].monthlyPremium,
                    byCensus: action.rates[i].byCensus,
                    tierStructureId: action.tierStructureId
                };
                const selectedPlanIndex = newSelectedPlans.findIndex((plan) => plan.code === action.rates[i].code);
                if (selectedPlanIndex !== -1) {
                    newSelectedPlans[selectedPlanIndex] = {
                        ...newSelectedPlans[selectedPlanIndex],
                        ...action.rates[i],
                        quote: action.quotes[i],
                        finalMonthlyPremium: action.quotes[i],
                        finalRates: action.rates[i].monthlyPremium,
                        byCensus: action.rates[i].byCensus,
                        tierStructureId: action.tierStructureId
                    };
                }
            }
        }
    }
    allRatesFetched = true;
    newPlans = sortPlans(newPlans, state.ascending);
    newAllPlans = sortPlans(newAllPlans, state.ascending);
    const newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newPlans,
        allPlans: newAllPlans,
        selectedPlans: newSelectedPlans,
        allRatesFetched,
        numRateBatchesFetching: state.numRateBatchesFetching - 1,
        numRatesFetched,
        filteredResults,
    };
    return newState;
};

export const handleReceiveSpecialtyPlanRates = (state, action) => {
    const { planType } = state.visiblePlans[0];
    const newVisiblePlans = deepCopy(state.visiblePlans);
    for (let i = 0; i < newVisiblePlans.length; i++) {
        newVisiblePlans[i] = {
            ...newVisiblePlans[i],
            ...action.rates[i],
            quote: action.quotes[i],
            finalMonthlyPremium: planType !== 'Life' ? action.quotes[i] : action.quotes[i].totalMonthlyPremium,
            finalRates: planType !== 'Life' ? action.rates[i].monthlyPremium : action.rates[i],
            byCensus: action.rates[i].byCensus,
        };
    }
    const newState = {
        ...state,
        visiblePlans: newVisiblePlans,
    };
    return newState;
};

export const handleReceiveSpecialtySelectedPlanRates = (state, action) => {
    let newSelectedPlans = [];

    if (state.selectedPlans && state.selectedPlans.length !== 0) {
        const planType = state.selectedPlans && state.selectedPlans[0].planType;

        newSelectedPlans = deepCopy(state.selectedPlans);

        // Life Classing Case
        if((planType.toLowerCase() === 'life' || planType.toLowerCase() === 'std' || planType.toLowerCase() === 'ltd') &&  action.classingOpted){
            action.rates.forEach((rate) => {
                let planIdx;
                if(planType.toLowerCase() === 'life'){
                planIdx = newSelectedPlans.findIndex(
                    plan => ((plan.code === rate.code || plan.primePlanCode === rate.code) && (plan.selectedClass === action.empClass))
                ) }
                else if (planType.toLowerCase() === 'std'){
                    planIdx = newSelectedPlans.findIndex(
                        plan => ((rate.stdPlanCode === plan.piaPlanCode) && (plan.selectedClass === action.empClass))
                    )
                } else if(planType.toLowerCase() === 'ltd'){
                    planIdx = newSelectedPlans.findIndex(
                        plan => ((rate.ltdPlanCode === plan.piaPlanCode) && (plan.selectedClass === action.empClass))
                    )
                }

                if(planIdx > -1){
                    if(newSelectedPlans[planIdx].classRates ){
                        newSelectedPlans[planIdx].classRates[action.empClass] = {
                            ...newSelectedPlans[planIdx].classRates[action.empClass],
                            quote: rate,
                            finalMonthlyPremium: rate.totalMonthlyPremium ? rate.totalMonthlyPremium : '',
                            finalRates: rate,
                        }
                    }
                    newSelectedPlans[planIdx] = {
                        ...newSelectedPlans[planIdx],
                        quote: rate,
                        finalMonthlyPremium: rate.totalMonthlyPremium ? rate.totalMonthlyPremium : '',
                        finalRates: rate,
                    }
                }
            })

        }else{ // Normal Case
            for (let i = 0; i < newSelectedPlans.length; i++) {
                let rates;
                let quote;
                let finalMonthlyPremium;
                let finalRates;
                if (planType === 'STD') {
                    rates = action.rates && action.rates.find(plan => newSelectedPlans[i].piaPlanCode === plan.stdPlanCode) ? action.rates.find(plan => newSelectedPlans[i].piaPlanCode === plan.stdPlanCode) : action.rates.find(plan => newSelectedPlans[i].code === plan.stdPlanCode) ? action.rates.find(plan => newSelectedPlans[i].code === plan.stdPlanCode) : newSelectedPlans[i].finalRates
                    quote = rates
                    finalMonthlyPremium = rates ? rates.totalMonthlyPremium : 0
                    finalRates = rates
                } else if (planType === 'LTD') {
                    rates = action.rates && action.rates.find(plan => newSelectedPlans[i].piaPlanCode === plan.ltdPlanCode) ? action.rates.find(plan => newSelectedPlans[i].piaPlanCode === plan.ltdPlanCode) : action.rates.find(plan => newSelectedPlans[i].code === plan.ltdPlanCode) ? action.rates.find(plan => newSelectedPlans[i].code === plan.ltdPlanCode) : newSelectedPlans[i].finalRates
                    quote = rates
                    finalMonthlyPremium = rates ? rates.totalMonthlyPremium : 0
                    finalRates = rates
                } else if (planType ==='Life') {
                    rates = action.rates &&
                        action.rates.find(rateObj => (newSelectedPlans[i].code === rateObj.code || newSelectedPlans[i].primePlanCode === rateObj.code)) ?
                        action.rates.find(rateObj => (newSelectedPlans[i].code === rateObj.code || newSelectedPlans[i].primePlanCode === rateObj.code)) : newSelectedPlans[i].finalRates
                    quote = rates
                    finalMonthlyPremium = rates ? rates.totalMonthlyPremium : 0
                    finalRates = rates
                } else {
                    rates = action.rates && action.rates.find(plan => newSelectedPlans[i].code === plan.code) ? action.rates.find(plan => newSelectedPlans[i].code === plan.code) : { "byCensus" : newSelectedPlans[i].byCensus, "code" : newSelectedPlans[i].code, "monthlyPremium" : newSelectedPlans[i].monthlyPremium, "platformRatingID": newSelectedPlans[i].platformRatingID, "quote" : newSelectedPlans[i].quote, "rateUrl" : newSelectedPlans[i].rateUrl }
                    quote = action.quotes && action.rates.find(plan => newSelectedPlans[i].code === plan.code) && action.rates.find(plan => newSelectedPlans[i].code === plan.code).byCensus ? action.rates.find(plan => newSelectedPlans[i].code === plan.code).byCensus.totalMonthlyPremium : newSelectedPlans[i].quote
                    finalMonthlyPremium = quote
                    finalRates = rates.monthlyPremium ? rates.monthlyPremium : {}
                }
                newSelectedPlans[i] = {
                    ...newSelectedPlans[i],
                    ...rates,
                    code: newSelectedPlans[i].code, //  Added for Basic life for consistency, code is diff(primePlanCode) in rates
                    quote,
                    finalMonthlyPremium,
                    finalRates
                }
            }
        }
    }
    const newState = {
        ...state,
        selectedPlans: newSelectedPlans,
    };
    return newState
}

// Handling Get Benefit Riders Request
// Setting the values to default
export const handleRequestBenefitRiderRates = (state) => {
    return {
        ...state,
        numBenRidersRatesFetched : 0,
        allBenRidersRatesFetched : false
    }
}

export const  handleRequestPlanRatesWithSelectedRiders = (state,action) => {
    return {
        ...state,
        numNewRatesToBeFetched : action.numRatesToBeFetched,
        allNewRatesFetched : false
    }
}

export const handleReceivePlanRatesWithSelectedRiders = (state, action) => {

    let newPlans = JSON.parse(JSON.stringify(state.plans));
    let newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
    let numNewRatesToBeFetched = state.numNewRatesToBeFetched - action.rates.length;
    let allNewRatesFetched = numNewRatesToBeFetched === 0;
    // Add the rates from this batch to their corresponding plans
    action.rates.forEach((rate) => {
        const planIdx = newPlans.findIndex(
            (plan) => plan.code === rate.medicalPlanCode,
        );

        const totalPremiumKey = rate.defaultRatingType === 'ageBands'
            ? 'premiumTotalAgeBandedMonthly'
            : 'premiumTotalCompositeMonthly';

        const benefitRiderRates = newPlans[planIdx].benefitRiderRates;
        newPlans[planIdx] = {
            ...newPlans[planIdx],
            quote: rate[totalPremiumKey],
            ...rate,
            benefitRiderRates // Added in last so that we don't override the ben rates of all riders with selected riders
        };

        newSelectedPlans = newSelectedPlans.map(plan => {
            if(plan.code === rate.medicalPlanCode){
                plan = {
                    ...plan,
                    ...rate,
                    benefitRiderRates,
                    quote : rate[totalPremiumKey]
                }
            }
            return plan;
        });
    });

    newPlans = sortPlans(newPlans, state.ascending);
    let newState = {
        ...state,
        plans: newPlans,
        selectedPlans: newSelectedPlans,
        allRatesFetched : state.allRatesFetched,
        ...filterPlans(
            newPlans,
            state.filterCriteria,
            state.isCirrus,
            state.allRatesFetched,
            state.filterTotalsCache,
            state.sortEnabled,
            state.ascending,
        ),
        numNewRatesToBeFetched,
        allNewRatesFetched
    };
    //If we are done fetching rates, then we can find the premDomainMax
    if (allNewRatesFetched) {
        newState.premDomainMax = [0, findPremiumDomainMax(newPlans)];
        newState.filterCriteria.premRange = newState.premDomainMax;
    }
    return newState;
}

export const handleSaveSelectedRidersInPlans = (state,action) => {

    let newPlans = JSON.parse(JSON.stringify(state.plans));
    let newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));

    const selectedBenefitRiders = action.selectedBenefitRiders;

    for(const planCode in selectedBenefitRiders){
        let planIdx = newPlans.findIndex(
            (plan) => plan.code === planCode,
        );
        newPlans[planIdx].selectedBenefitRiders = selectedBenefitRiders[planCode];
        newSelectedPlans = newSelectedPlans.map(plan => {
            if(plan.code === planCode){
                plan.selectedBenefitRiders = selectedBenefitRiders[planCode];
            }
            return plan;
        });
    }

    let newState = {
        ...state,
        plans: newPlans,
        selectedPlans: newSelectedPlans,
    };
    return newState;
}



export const handleRestorePremiumOnDeselect = (state, action) => {

    let newPlans = JSON.parse(JSON.stringify(state.plans));
    let newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
    let deselectedPlanCodes = [];
    // let numNewRatesToBeFetched = state.numNewRatesToBeFetched - action.rates.length;
    // let allNewRatesFetched = numNewRatesToBeFetched === 0;
    // Add the rates from this batch to their corresponding plans
    action.rates.forEach((rate) => {
        const planIdx = newPlans.findIndex(
            (plan) => plan.code === rate.medicalPlanCode,
        );

        const totalPremiumKey = rate.defaultRatingType === 'ageBands'
            ? 'premiumTotalAgeBandedMonthly'
            : 'premiumTotalCompositeMonthly';

        const benefitRiderRates = newPlans[planIdx].benefitRiderRates;

        newPlans[planIdx] = {
            ...newPlans[planIdx],
            quote: rate[totalPremiumKey],
            ...rate,
            benefitRiderRates,
            selectedBenefitRiders : []
        };
        newSelectedPlans = newSelectedPlans.map(plan => {
            if(plan.code === rate.medicalPlanCode){
                plan = {
                    ...plan,
                    ...rate,
                    benefitRiderRates,
                    quote : rate[totalPremiumKey],
                    selectedBenefitRiders : []
                }
            }
            return plan;
        });
        deselectedPlanCodes.push(rate.medicalPlanCode);

    });

    newPlans = sortPlans(newPlans, state.ascending);
    let newState = {
        ...state,
        plans: newPlans,
        selectedPlans: newSelectedPlans,
        allRatesFetched : state.allRatesFetched,
        ...filterPlans(
            newPlans,
            state.filterCriteria,
            state.isCirrus,
            state.allRatesFetched,
            state.filterTotalsCache,
            state.sortEnabled,
            state.ascending,
        ),
        deselectedPlanCodes
    };
    //If we are done fetching rates, then we can find the premDomainMax
    // if (allNewRatesFetched) {
    //     newState.premDomainMax = [0, findPremiumDomainMax(newPlans)];
    //     newState.filterCriteria.premRange = newState.premDomainMax;
    // }
    return newState;
}

/*  For WA 2 Tier Case Only
    Get Updated rates in case of advance filter or  normal rate call    */
const getPlansWithUpdatedRates = (filteredResultsOnly, plansObj, indexes, action) => {

    let { newPlans, newSelectedPlans, newAllPlans, filteredResults } = plansObj;
    let { planCodeIndexDict, selectedPlanCodeIndexDict, allPlanCodeIndexDict, allFilteredPlanCodeIndexDict } = indexes;

    const tierKeyMap = getTierKeyMap();
    const selectedTierIsRateCallTier = action.selectedTier === action.rateCallTier;

    action.rates.forEach((rate, i) => {

        const pIndex = planCodeIndexDict[rate.code];
        const spIndex = selectedPlanCodeIndexDict[rate.code];

        if(pIndex !== undefined && pIndex !== null){
            newPlans[pIndex] = {
                ...newPlans[pIndex],
                tierRates: {
                    ...newPlans[pIndex].tierRates,
                    [tierKeyMap[action.rateCallTier]]: {
                        ...rate
                    }
                }
            };
        }

        if (spIndex >= 0) {
            newSelectedPlans[spIndex] = {
                ...newSelectedPlans[spIndex],
                tierRates: {
                    ...newSelectedPlans[spIndex].tierRates,
                    [tierKeyMap[action.rateCallTier]]: {
                        ...rate
                    }
                }
            }
        }

        if (selectedTierIsRateCallTier) {
            newPlans[pIndex] = {
                ...newPlans[pIndex],
                ...rate,
                quote: action.quotes[i],
                finalMonthlyPremium: action.quotes[i],
                finalRates: rate.monthlyPremium
            };

            if (spIndex >= 0) {
                newSelectedPlans[spIndex] = {
                    ...newSelectedPlans[spIndex],
                    ...rate,
                    quote: action.quotes[i],
                    finalMonthlyPremium: action.quotes[i],
                    finalRates: rate.monthlyPremium
                };
            }
        }
    });


    if(filteredResultsOnly){
        action.rates.forEach((rate, i) => {
            const apIndex = allPlanCodeIndexDict[rate.code];
            const filterPlanIndex = allFilteredPlanCodeIndexDict[rate.code];

            if (apIndex !== undefined && apIndex !== null) {
                newAllPlans[apIndex] = {
                    ...newAllPlans[apIndex],
                    tierRates: {
                        ...newAllPlans[apIndex].tierRates,
                        [tierKeyMap[action.rateCallTier]]: {
                            ...rate
                        }
                    }
                };
            }

            if (filterPlanIndex !== undefined && filterPlanIndex !== null) {
                filteredResults[filterPlanIndex] = {
                    ...filteredResults[filterPlanIndex],
                    tierRates: {
                        ...filteredResults[filterPlanIndex].tierRates,
                        [tierKeyMap[action.rateCallTier]]: {
                            ...rate
                        }
                    }
                };
            }

            if (selectedTierIsRateCallTier) {
                newAllPlans[apIndex] = {
                    ...newAllPlans[apIndex],
                    ...rate,
                    quote: action.quotes[i],
                    finalMonthlyPremium: action.quotes[i],
                    finalRates: rate.monthlyPremium,
                };
                filteredResults[filterPlanIndex] = {
                    ...filteredResults[filterPlanIndex],
                    ...rate,
                    quote: action.quotes[i],
                    finalMonthlyPremium: action.quotes[i],
                    finalRates: rate.monthlyPremium,
                };
            }
        })
    }
}

const removeDuplicatePlans = (duplicatePlans) => {
    const newPlans = [...new Map(duplicatePlans.map(plan => [plan['code'], plan])).values()];
    return newPlans;
}
