/**
 * Function or Method to validate param is undefined or null
 * @param {*} value variable to be verified
 */
export const isUndefinedOrNull = (value) => {
    return value === undefined || value === null;
}

/**
 * Function or Method to validate param is undefined or null or empty ('')
 * @param {*} value variable to be verified
 */
export const isUndefinedOrNullOrEmpty = (value) => {
    if (isUndefinedOrNull(value)) {
        return true;
    }

    // const trimmedValue = (value && value.toString()).trim();
    const trimmedValue = value.toString().trim();
    return trimmedValue === '';
}

/**
 * Function or Method to validate param is undefined or false
 * @param {*} value variable to be verified
 */
export const isUndefinedOrFalse = (value) => {
    return value === undefined || value === 'false';
}

/**
 * Function or Method to get the value of string if it is not undefined or null else '';
 * @param {*} value Value to be verified before returning its value
 * @returns the value of input (value) if it is not undefined or null else ''
 */
export const getValueOrEmptyString = (value) => {
    return !isUndefinedOrNull(value) ? value : '';
}