import { getSpecialtyRate } from "../../../../../../utils/businessLogic/calculateQuotes"
import { calculateDependentTotalSpecialty } from "../../../../../shared/EstEmployerCost/calculateDependentTotal"
import { formatRenewalDataMembers, formatIBRMembers, formatIBRMembersReconciled } from "../../../../Renewal/RenewalsCommonComponents/saveDetails"
import deepCopy from "../../../../../../utils/deepCopy"
import { getMarketType, MARKET_TYPES, isUhcRenewals, isMmr } from '../../../../../../utils/businessLogic/platformUtils';
import { identifyPlatform } from '../../../../Renewal/Review/reviewUtils';

// Full Shopping Helper Functions for Employee Cost Tab in Specialty Plan Details
export const getSpecialtyMembersEnrolledInPlanFullShopping = (page, enrolledFS, companyProfile, product, plan, classingOpted = false, selectedClass = "") => {

    let companyProfileEmployees = [].concat.apply([], companyProfile.employees)
    let result = []

    if (page === 'fullShopping') {
        if (enrolledFS === true) {
            companyProfileEmployees  = classingOpted && ((product === 'life' && plan.planType !== 'life_dep_basic') || product === 'std' || product === 'ltd') ? companyProfileEmployees.filter(employee =>  employee.optedLifeClass === selectedClass) : companyProfileEmployees;
            result = getSpecialtyMembersEnrollmentPage(companyProfileEmployees, product, plan)
        } else {
            companyProfileEmployees  = classingOpted && (product === 'life' || product === 'std' || product === 'ltd') ? companyProfileEmployees.filter(employee =>  employee.optedLifeClass === selectedClass) : companyProfileEmployees;
            result = getSpecialtyMembersFullShopPages(companyProfileEmployees, product, plan)
        }
    }

    return result
}

const getSpecialtyMembersEnrollmentPage = (companyProfileEmployees, product, planDetails, collection = null, flow = 'FS') => {
    const suppLifeSpouse = (product === 'spousesupplife') ? true:false;
    const suppLifeChild = (product === 'childsupplife') ? true:false;
    product = product === 'dependentLife' || product === 'life_dep_basic' ? 'life' : product;
    product = (product === 'supplife' || product === 'spousesupplife' || product === 'childsupplife') ? 'suppLife':product;
    let members = deepCopy(companyProfileEmployees)
    let updatedMembers = []

    members.map((employee, index) => {
        let employeePremium = 0
        let dependentPremium = 0
        let dependentLifeProductSelection = []

        if(planDetails.planType === 'life_dep_basic' || planDetails.planType === 'LIFE_DEP_BASIC') {
            const selectedProducts = employee.productSelection || []
            dependentLifeProductSelection = selectedProducts.filter(product => product.life === planDetails.code || product.depLife === planDetails.code)
            if (isUhcRenewals()) {
                let dependentProductSelection = selectedProducts.slice(1) || []
                dependentLifeProductSelection = dependentProductSelection.filter(product => (product.life !== 'waive' && (product.life === planDetails.code || product.depLife === planDetails.code)))
            }
        }

        if ((employee.productSelection && employee.productSelection.length > 0 && employee.productSelection[0] && employee.productSelection[0][product]) || (isUhcRenewals() && employee.productSelection && employee.productSelection.length > 0 && (planDetails.planType === 'life_dep_basic' || planDetails.planType === 'LIFE_DEP_BASIC'))) {
            if (planDetails.code === employee.productSelection[0][product] || dependentLifeProductSelection.length > 0 || employee.productSelection[0][product] === 'waive') {

                if (planDetails.quoteFinal || (isUhcRenewals()&&planDetails.quote) ) {
                    if (planDetails.planType === 'life_dep_basic' || planDetails.planType === 'LIFE_DEP_BASIC') {
                        if (dependentLifeProductSelection.length > 0) {
                            if (planDetails.finalRates && planDetails.finalRates.depLifeChildRate) {
                                employeePremium = parseFloat(planDetails.finalRates.depLifeChildRate)
                            }
                        }
                    } else {
                        if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
                            let employeeRate = planDetails.byCensus.employeeRates[index]
                            employeePremium = employeeRate && employeeRate.Total ? parseFloat(employeeRate.Total) : 0
                        } else if (planDetails.finalRates && planDetails.finalRates.employeeRate) {
                            let employeeRate = planDetails.finalRates.employeeRate.find(empRate => parseInt(empRate.employeeId) === parseInt(employee.memberId) || parseInt(empRate.employeeId) === index + 1)
                            employeePremium = employeeRate && employeeRate.Total ? parseFloat(employeeRate.Total) : 0
                        } else if(((planDetails.planType === 'STD' || planDetails.planType === 'LTD') && (planDetails.finalRates.employeeRatesComposite || planDetails.finalRates.employeeRatesAgeBanded))){
                            let employeeRate = planDetails.rateType.toLowerCase().includes('age-banded') ? planDetails.finalRates.employeeRatesAgeBanded[index] : planDetails.finalRates.employeeRatesComposite[index]
                            employeePremium = employeeRate ? parseFloat(employeeRate) : 0  
                        } else if((planDetails.planType === 'suppLife') && (planDetails.byCensus.employeePremium)){                          
                            let employeeRate = planDetails.byCensus.employeePremium[index]
                            if(suppLifeSpouse) {
                                employeePremium = employeeRate ? (parseFloat(employeeRate.slSPPremium) + parseFloat(employeeRate.slSPADnDPremium)) : 0  

                            } else if(suppLifeChild) {
                                employeePremium = employeeRate ? (parseFloat(employeeRate.slCHPremium) + parseFloat(employeeRate.slCHADnDPremium)) : 0  

                            } else {
                                employeePremium = employeeRate ? (parseFloat(employeeRate.slEEPremium) + parseFloat(employeeRate.slEEADnDPremium)) : 0  
                            }
                        }
                    }
                }
                else {
                    employeePremium = '-'
                }

                employee['total'] = employeePremium
                employee['premium'] = employeePremium
                employee['memberPremium'] = employeePremium

                updatedMembers.push(employee)
            }
        }
    })

    return updatedMembers
}

const getSpecialtyMembersFullShopPages = (companyProfileEmployees, product, planDetails) => {

    let members = deepCopy(companyProfileEmployees);

    // Filter our dependents that are not needed for selected coverage type

    // Filter dependents based on coverage type if not in enrollment
    members.map((employee) => {
        let covType
        if (product.toLowerCase() === 'life' || product.toLowerCase() === 'basiclife') {
            covType = employee.coverages['basicLife'];
        } else {
            covType = employee.coverages[`${product.toLowerCase()}`];
        }
        const filteredDependents = employee.dependents.filter((dependent) => {
            if (covType === 'EE' || covType === 'EL') {
                return false;
            } else if (covType === 'EE/SP') {
                if (dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                    return false;
                }
            } else if (covType === 'EE/CH') {
                if (dependent.relationship === 'Spouse' || dependent.relationship === "Domestic Partner" || dependent.relationship === "Civil Union Partner" || dependent.relationship === "Life Partner") {
                    return false;
                }
            }
            return true;
        })
        employee.dependents = filteredDependents;
        return employee;
    });

    // Remove after testing
    // let counter = 1
    // members.map((mem, index) => {
    //     if(mem.coverages[product] !== 'W'){
    //         mem.employeeId = counter++;
    //     }
    // });

    members.map((employee, index) => {
        let employeePremium = 0
        if (product === 'life' || product === 'basicLife' || product === 'dependentLife'){
            employeePremium = getSpecialtyRate(product, planDetails, "EE", employee, index);
        }
        else if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
            let employeeRate = planDetails.byCensus.employeeRates.find(empRate => parseInt(empRate.employeeId) === parseInt(employee.memberId) || parseInt(empRate.employeeId) === index + 1)
            employeePremium = employeeRate ? employeeRate.Total : 0
        }
        employee['total'] = employeePremium;
    })
    return members

}

// Renewals Helper Functions for Employee Cost Tab in Specialty Plan Details
export const getSpecialtyMembersEnrolledInPlanRenewals = (page, collection, companyProfile, plan, product, type) => {

    let result = []

    let fullCensusRenewalMembers = []
    let isIbr = false
    let isACASpecialty = false

    if (collection && collection.renewalData && (collection.renewalData.Ibr || collection.renewalData.ibr)) {
        isIbr = true
        if (plan.renewalPlanType === 'alternate' || page === 'shopping' || page === 'decide') {
            fullCensusRenewalMembers = formatIBRMembersReconciled(collection.renewalData)
        }
        else
            fullCensusRenewalMembers = formatIBRMembers(collection.renewalData)
    }
    else {
        fullCensusRenewalMembers = formatRenewalDataMembers(collection.renewalData)
    }
    if (collection && collection.renewalData && collection.renewalData.specialtyProducts) {
        isACASpecialty = true;
    }

    let companyProfileEmployees = [].concat.apply([], companyProfile.employees)
    let count = 0
    let productUpperCase = product.charAt(0).toUpperCase() + product.slice(1)

    if (page === 'decide' && collection && collection[`shopping${productUpperCase}Plans`]) {
        collection[`shopping${productUpperCase}Plans`].map((plan) => {
            if (plan && !plan.renewalPlanType) {
                count += 1;
            }
        });
    }

    let hasAdditionalPlans = count > 0 ? true : false

    if (page === 'review' || (page === 'review' && type === 'popular')) {
        if (!isIbr) {
            result = getSpecialtyMembersShoppingPage(fullCensusRenewalMembers, product, plan)
        }
        else
            result = getSpecialtyMembersReviewPageIBR(fullCensusRenewalMembers, product, plan)
    }

    if (page === 'decide') {
        const platform = identifyPlatform(collection.renewalData);
        if (!isIbr && !isACASpecialty)
            result = getSpecialtyMembersDecidePage(fullCensusRenewalMembers, product, plan)
        else
            result = getSpecialtyMembersDecidePageIBR(hasAdditionalPlans, fullCensusRenewalMembers, product, plan)

        if(platform == 'CIRRUS' && (product =='life'|| product === 'dependentLife')){
            // For OXFORD change field name employeeRate to employeeRates
            const employeeRates = plan?.byCensus?.employeeRate ? plan.byCensus.employeeRate : plan.byCensus.employeeRates;
            plan.byCensus.employeeRates = employeeRates;
            result = getSpecialtyMembersDecidePage(fullCensusRenewalMembers, product, plan);
        }
    }

    if (page === 'shopping') {
        const platform = identifyPlatform(collection.renewalData);
        if (!isIbr && !isACASpecialty)
            result = getSpecialtyMembersShoppingPage(fullCensusRenewalMembers, product, plan)
        else
            result = getSpecialtyMembersShoppingPageIBR(fullCensusRenewalMembers, product, plan)

         if(platform == 'CIRRUS' && (product =='life'|| product === 'dependentLife')){
             // For OXFORD change field name employeeRate to employeeRates
             const employeeRates = plan?.byCensus?.employeeRate;
             plan.byCensus.employeeRates = employeeRates;

             if(employeeRates){
                result = getSpecialtyMembersShoppingPage(fullCensusRenewalMembers, product, plan )
             }else{
                 result = [...fullCensusRenewalMembers];
             }

         }
    }

    if (page === 'enrollment') {
        result = getSpecialtyMembersEnrollmentPage(companyProfileEmployees, product, plan, collection, 'RN')
    }

    return result

}

export const getSpecialtyMembersShoppingPage = (fullCensusRenewalMembers, product, planDetails) => {

    let members = []
    if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
        planDetails.byCensus.employeeRates.map(employeeRate => {
            if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                findMember['total'] = employeeRate.Total
                members.push(findMember);
            }
        })
    }
    else{
        planDetails.byCensus.employeeRate.map(employeeRate1 => {
            if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate1.employeeId))) {
                const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate1.employeeId)));
                findMember['total'] = employeeRate1.Total
                members.push(findMember);
            }
        })
    }
    return members
}

export const getSpecialtyMembersDecidePage = (fullCensusRenewalMembers, product, planDetails) => {
    let members = []

    if (isUhcRenewals() && planDetails.planType.toLowerCase() === 'life_dep_basic') {
        (fullCensusRenewalMembers || []).forEach((employee, index) => {
            const employeePremium = getSpecialtyRate(product, planDetails, "EE", employee, index);
            employee['total'] = employeePremium;
            members.push(employee);
        })
    } else {
        if (planDetails.byCensus && planDetails.byCensus.employeeRates && planDetails.byCensus.employeeRates.length > 0) {
            planDetails.byCensus.employeeRates.map(employeeRate => {
                if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                    const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                    findMember['total'] = employeeRate.Total
                    members.push(findMember);
                }
            })
        } else if (planDetails.byCensus && planDetails.byCensus.employeeRate && planDetails.byCensus.employeeRate.length > 0) {
            planDetails.byCensus.employeeRate.map(rate => {
                if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(rate.employeeId))) {
                    const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(rate.employeeId)));
                    findMember['total'] = rate.Total
                    members.push(findMember);
                }
            })
        }
    }

    return members
}

export const getSpecialtyMembersReviewPageIBR = (fullCensusRenewalMembers, product, planDetails) => {
    let members = []

    if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
        planDetails.byCensus.employeeRates.map(employeeRate => {
            if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                findMember['total'] = employeeRate.Total
                members.push(findMember);
            }
        })
    }
    return members
}

export const getSpecialtyMembersDecidePageIBR = (hasAdditionalPlans, fullCensusRenewalMembers, product, planDetails) => {
    let members = []

    if (hasAdditionalPlans && planDetails.renewalPlanType === 'renewing') {
        if (getMarketType() === MARKET_TYPES.UHC) {
            planDetails.byCensusShopping = planDetails.byCensus;
            planDetails.finalRatesShopping = planDetails.finalRates;
        }
        if (isUhcRenewals() && planDetails.planType.toLowerCase() === 'life_dep_basic') {
            (fullCensusRenewalMembers || []).forEach((employee, index) => {
                const employeePremium = getSpecialtyRate(product, planDetails, "EE", employee, index);
                employee['total'] = employeePremium;
                members.push(employee);
            })
        } else {
            if (planDetails.byCensusShopping && planDetails.byCensusShopping.employeeRates) {
                planDetails.byCensusShopping.employeeRates.map(employeeRate => {
                    if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                        const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                        findMember['total'] = employeeRate.Total
                        findMember['memberPremium'] = product !== 'life' ? planDetails.finalRatesShopping.employee : null
                        members.push(findMember);
                    }
                })
            }
        }
    }
    else {
        if (isUhcRenewals() && planDetails.planType.toLowerCase() === 'life_dep_basic') {
            (fullCensusRenewalMembers || []).forEach((employee, index) => {
                const employeePremium = getSpecialtyRate(product, planDetails, "EE", employee, index);
                employee['total'] = employeePremium;
                members.push(employee);
            })
        } else {
            if(product === 'std' || product === 'ltd'){
                if(isMmr()){
                    planDetails && planDetails.member.map(mem=>{
                        if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(mem.employeeId))) {
                            const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(mem.employeeId)));
                            findMember['total'] = mem.total
                            findMember['memberPremium'] = mem.total
                            members.push(findMember);
                        }
                    })
                }else{
                    planDetails.byCensus = {
                        employeeRates: planDetails.employeeRates
                    }
                }
            }
            if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
                planDetails.byCensus.employeeRates.map(employeeRate => {
                    if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                        const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                        findMember['total'] = employeeRate.Total
                        findMember['memberPremium'] = getMarketType() === MARKET_TYPES.UHC?((product!=="life" || product !== 'dependentLife') ? (product === 'std' || product === 'ltd' || product === 'suppLife') ? employeeRate.Total : planDetails.finalRates?.employee  :null
                        ):((product!=="life" ? planDetails.finalRates.employee : null))
                        members.push(findMember);
                    }
                })
            }
        }
    }

    if (isUhcRenewals()) {
        // Displays employees alphabetically
        members.sort((a, b) => a.lastName.localeCompare(b.lastName));
    }

    return members
}

const covTypes = {
    'Employee Only': 'employee',
    'Employee With Spouse': 'empAndSpouse',
    'Employee With Child': 'empAndChild',
    'Employee With Family': 'empAndFamily',
    'Waived': ''
};

export const getSpecialtyMembersShoppingPageIBR = (fullCensusRenewalMembers, product, planDetails) => {

    let members = []

    if (planDetails.renewalPlanType === 'renewing' && getMarketType() !== MARKET_TYPES.UHC) {
        if (planDetails.byCensusShopping && planDetails.byCensusShopping.employeeRates) {
            planDetails.byCensusShopping.employeeRates.map(employeeRate => {
                if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                    const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                    findMember['total'] = employeeRate.Total
                    members.push(findMember);
                }
            })
        }
    }
    else {
        if (planDetails.planType.toLowerCase() === 'life_dep_basic') {
            (fullCensusRenewalMembers || []).forEach((employee, index) => {
                const employeePremium = getSpecialtyRate(product, planDetails, "EE", employee, index);
                employee['total'] = employeePremium;
                members.push(employee);
            })
        } else {
            if (planDetails.byCensus && planDetails.byCensus.employeeRates) {
                planDetails.byCensus.employeeRates.map(employeeRate => {
                    if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                        const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                        findMember['total'] = employeeRate.Total
                        members.push(findMember);
                    }
                })
            }
        }
    }
    return members
}
