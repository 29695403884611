import { routeConsts } from '../components/activities/Enrollment/shared/EnrollmentDetails/pageConsts';
import { coverageTypes } from '../components/activities/FullShopping/Census/helpers/dropDownConsts';
import { CASE_PROGRESS, isStatusChangeAllowed, getCaseIndexForNB, inSubmittedStatus, getNBCaseProgress } from '../reducers/products/helpers/companyProfileConsts';
import { saveEmployerToCompanyProfile } from '../reducers/products/helpers/renewalCompanyProfileHelper';
import { getStateStore } from '../reducers/products/helpers/stateStoreHelper';
import apiRoutes from '../utils/api/apiRouteConfig';
import { axios } from '../utils/api/axiosConfig';
import { deleteMongoFile, uploadFile } from '../utils/api/fileUploadRequests';
import deepCopy from '../utils/deepCopy';
import { history } from '../utils/history';
import { isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../utils/validation/commonValidations';
import * as types from './actionTypes';
import { saveOrUpdateCaseTracking, saveStateStore, updateNBDashboardActivity, updateAdditionalInfoInCaseTracking, updateStateStoreId } from './caseTrackAction';
import { createHistory } from './historyAction';
import sendLog from './logActions';
import { updateEmployeeProductSelection } from './renewalsEnrollmentActions';
import { isNullOrUndefined } from 'util';
import { getEmployerProgress } from '../components/activities/Enrollment/EnrollManager/helpers/getProgress';
import { getMarketType, isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { resetBreadCrumbAccess, updateBreadCrumbAccess } from './fullQuotesActions';
import { expandStateStorePayload } from '../utils/stateStorePayload/expand/expandPayload';
import { getEnrollmentForm } from './enrollmentFormActions';
import { getRateAdjustmentRules, getEnrollmentRules } from './rulesActions';
import { getPcpAutoAssignemntRules } from '../utils/businessLogic/pcpAutoAssignmentRules';
import { savePcpAutoAssignmentRules } from './enrollmentActions';
import { updateParticipationRules } from './overallActions';

export function saveOrUpdateCompanyProfile(
  companyProfile,
  isRenewals,
  btn = '',
  opts = { useSimple: false, exit: false },
  isAttested = false,
  isErCert = true,
  closePage = true
) {
  const { store } = require('../index');
  const { useSimple, exit } = opts;
  const fullProps = store.getState();
  const { isNewQuote, isCopyQuote, isNewCase } = fullProps.caseTracking
  // Set Company Profile ID to null to make new entry for new quote & Copy Quote
  if (isNewCase || isNewQuote || isCopyQuote) {
    companyProfile = {
      ...companyProfile,
      _id: null,
      caseProgress: CASE_PROGRESS.QUOTING,
      quoteStatus: 'Quoting',
      quoteProgress: 'Shopping'
    };
  }
  // Call and save Remove this call for NB
  if (isRenewals && companyProfile._id === null) {
    return saveCompanyProfile(companyProfile, { useSimple, exit, isRenewals });
  }
  if (!isRenewals) {
    if (!fullProps.caseTracking.stateStoreId || (isNewQuote || isCopyQuote)) {
      return saveCompanyProfile(companyProfile, { useSimple, exit, isRenewals });
    }
    return updateCompanyProfile(companyProfile, useSimple, exit);
  }
  return updateRLCompanyProfile(companyProfile, useSimple, exit, isRenewals, btn, isAttested, isErCert, closePage);
}

export const saveCompanyProfile =
  (
    companyProfile,
    opts = {
      useSimple: false,
      exit: false,
      isRenewals: false,
      lastModifiedPage: 'CompanyProfile',
      // QuoteTrackingNumbers and CaseID are used for the DES - SAMx Integration
      quoteTrackingNumber: '',
      caseId: 0,
    }
  ) =>
    (dispatch, getState) => new Promise(async (resolve, reject) => {
      const { isRenewals } = opts;

      try {
        // save company profile to mongo database
        // post request to email service layer
        const fullProps = getState();

        const otherDetail = {
          ...fullProps.overall.quickQuoteRequest.otherDetails,
          selectedProducts: fullProps.companyProfile.selectedProducts,
        };

        const quickQuoteRequests = {
          ...fullProps.overall.quickQuoteRequest,
          effectiveDate: fullProps.companyProfile.effectiveDate,
          groupSize: fullProps.companyProfile.locations[0].totalEligEmps,
          otherDetails: otherDetail,
          zipObj: fullProps.companyProfile.locations[0].zipData,
          sicObj: fullProps.companyProfile.sicData,
        };
        fullProps.overall.defaultGARemoved = false;

        const overallData = {
          ...fullProps.overall,
          quickQuoteRequest: quickQuoteRequests,
        };

        const { specialtyCriteria, user } = fullProps;
        const SC = {
          ...specialtyCriteria,
          sicObj: fullProps.companyProfile.sicData,
        };

        const fiLFModuleTableData = await axios.get(`${apiRoutes.quickQuotes}/getFILFTableModule?state=${quickQuoteRequests.zipObj.stateCode}&effectiveDate=${quickQuoteRequests.effectiveDate}`);
        let { enableLink } = fiLFModuleTableData.data;
        enableLink = enableLink ? enableLink : 'N';
        if (fullProps.reviewCards.ownerAssignment.producer.length > 0 && fullProps.reviewCards.ownerAssignment.salesRep && fullProps.reviewCards.ownerAssignment.salesRep.length === 0) {
          fullProps.reviewCards.salesRepAvail = true;
        } else {
          fullProps.reviewCards.salesRepAvail = false;
        }
        if (fullProps.reviewCards.ownerAssignment.generalAgent.length > 0 && fullProps.reviewCards.ownerAssignment.gaSalesRep && fullProps.reviewCards.ownerAssignment.gaSalesRep.length === 0) {
          fullProps.reviewCards.gaSalesRepAvail = true;
        } else {
          fullProps.reviewCards.gaSalesRepAvail = false;
        }

        companyProfile = {
          ...companyProfile,
          overall: overallData,
          specialtyCriteria: SC,
          selectedDentalPlans: fullProps.dental ? fullProps.dental.selectedPlans : [],
          selectedVisionPlans: fullProps.dental ? fullProps.vision.selectedPlans : [],
          selectedLifePlans: fullProps.life ? fullProps.life.selectedPlans : [],
          selectedDependentLifePlans: fullProps.lifeDep ? fullProps.lifeDep.selectedPlans : [],
          brokerId: fullProps.renewals.logInData[0].userName,
          reviewCards: fullProps.reviewCards,
        };

        dispatch({ type: types.PROFILE_UPDATING });

        if (isRenewals) {
          const profileResponse = await axios.post(`${apiRoutes.mongo}/profiles?userName=${user.userName}`, companyProfile);
          const { updatedUserCases, insertedProfile } = profileResponse.data;
          dispatch({
            type: types.UPDATED_USER_CASES,
            payload: updatedUserCases,
          });
          if (!exit) {
            dispatch({
              type: types.PROFILE_UPDATED,
              companyProfile: {
                ...companyProfile,
                _id: insertedProfile._id,
              },
              useSimple,
            });
          }
        }

        const { useSimple, exit, lastModifiedPage, quoteTrackingNumber, caseId } = opts;

        if (!exit) {
          if (!isRenewals) {
            dispatch({
              type: types.PROFILE_UPDATED,
              companyProfile: {
                ...companyProfile
              },
              useSimple,
            });
          }
          dispatch({
            type: types.UPDATE_FILF_BUTTON_ENABLE_DISABLE,
            payload: enableLink,
          });
          let caseIndex = 0;
          const caseObj = fullProps && fullProps.caseTracking && fullProps.caseTracking.caseTrack;
          if (caseObj && caseObj.caseId !== '') {
            caseIndex = caseObj.findIndex((obj) => obj.quoteTrackingNumber === fullProps.caseTracking.currentQuoteId);
            if (caseIndex === -1) {
              caseIndex = 0;
            }
          }
          if (!isRenewals) {
            const additionalParams = {
              quoteTrackingNumber: '',
              source: '',
              caseId: 0,
            };

            if (!isUndefinedOrNullOrEmpty(quoteTrackingNumber)) {
              const source = quoteTrackingNumber.split('-')[0];

              const desSourceSystemTypes = ['DES', 'B2B'];

              if (desSourceSystemTypes.indexOf(source) !== -1) {
                additionalParams.quoteTrackingNumber = quoteTrackingNumber;
                additionalParams.source = 'DES';
                additionalParams.caseId = caseId;
              }
            }

            await dispatch(saveOrUpdateCaseTracking(lastModifiedPage || 'CompanyProfile', 'Quoting', caseIndex, additionalParams));
          }
          resolve();
        } else {
          dispatch({ type: types.RESET_FLOW });
          history.push('/');
        }
      } catch (err) {
        console.log('Error', err);
        dispatch({ type: types.PROFILE_UPDATE_ERRORED, error: err });
        if (!isRenewals) {
          dispatch(createHistory('Save Company Profile', 'Company Profile', 'Error', companyProfile, err, 'NB'));
        }
        reject(err);
      }
    });

const updateCompanyProfile = (companyProfile, useSimple, exit) => {
  return (dispatch, getState) => new Promise(async (resolve, reject) => {
    dispatch({ type: types.PROFILE_UPDATING });
    const fullProps = getState();
    const isCopyQuote = fullProps.caseTracking && fullProps.caseTracking.isCopyQuote;
    const isCopyQuoteOriginal = fullProps.caseTracking && fullProps.caseTracking.isCopyQuoteOriginal;
    const { userName } = fullProps.user;
    const memberGroupInstallation = {}
    const employerProgress = getEmployerProgress(fullProps.enrollment.application, false);
    if (fullProps.reviewCards.ownerAssignment.producer.length > 0 && fullProps.reviewCards.ownerAssignment.salesRep && fullProps.reviewCards.ownerAssignment.salesRep.length === 0) {
        fullProps.reviewCards.salesRepAvail = true;
    } else {
      fullProps.reviewCards.salesRepAvail = false;
    }
    if (fullProps.reviewCards.ownerAssignment.generalAgent.length > 0 && fullProps.reviewCards.ownerAssignment.gaSalesRep && fullProps.reviewCards.ownerAssignment.gaSalesRep.length === 0) {
        fullProps.reviewCards.gaSalesRepAvail = true;
    } else {
        fullProps.reviewCards.gaSalesRepAvail = false;
    }
    memberGroupInstallation.memberGroupId = fullProps.enrollment.application.installation.memGroupID ? fullProps.enrollment.application.installation.memGroupID.toString() : ''
    const planPolicyArray = fullProps.enrollment.application.installation?.planPolicyMappingArray?.map(policy => policy.policyNumberAssigned)
    memberGroupInstallation.planPolicyArray = planPolicyArray ? planPolicyArray[0] : [];
    if (employerProgress < 100) {
      if (companyProfile.employerEnrollment && companyProfile.employerEnrollment.producer.generalAgent && fullProps.reviewCards.ownerAssignment.generalAgent && fullProps.reviewCards.ownerAssignment.generalAgent.length > 0) {
        if (!fullProps.overall.defaultGARemoved) {
          companyProfile.employerEnrollment.producer.generalAgent.gaEmail0 = fullProps.reviewCards.ownerAssignment.generalAgent[0].gaEmail;
          companyProfile.employerEnrollment.producer.generalAgent.gaName0 = fullProps.reviewCards.ownerAssignment.generalAgent[0].generalAgentName;
          companyProfile.employerEnrollment.producer.generalAgent.gaPcis0 = fullProps.reviewCards.ownerAssignment.generalAgent[0].PCIS_ID;
        }
      }
    }
    companyProfile = {
      ...companyProfile,
      selectedDentalPlans: fullProps.dental ? fullProps.dental.selectedPlans : [],
      selectedVisionPlans: fullProps.vision ? fullProps.vision.selectedPlans : [],
      reviewCards: fullProps.reviewCards,
      selectedLifePlans: fullProps.life ? fullProps.life.selectedPlans : [],
      selectedDependentLifePlans: fullProps.lifeDep ? fullProps.lifeDep.selectedPlans : [],
      memberGroupInstallation: memberGroupInstallation,
      cannabisEmp:fullProps.companyProfile.cannabisEmp||'no'
    };

    const fiLFModuleTableData = await axios.get(`${apiRoutes.quickQuotes}/getFILFTableModule?state=${companyProfile.locations[0].zipData.stateCode}&effectiveDate=${companyProfile.effectiveDate}`);
    let { enableLink } = fiLFModuleTableData.data;
    enableLink = enableLink ? enableLink : 'N';

    if (!exit) {
      const caseProgress = getNBCaseProgress(fullProps);
      if (!inSubmittedStatus(caseProgress)) {
        dispatch(saveStateStore(true)).then(() => {
          dispatch(createHistory('Update Company Profile', 'Company Profile', 'Success', companyProfile, '', 'NB'));
          dispatch({
            type: types.PROFILE_UPDATED,
            companyProfile: {
              ...companyProfile,
            },
            useSimple,
          });
    
          dispatch({
            type: types.UPDATE_FILF_BUTTON_ENABLE_DISABLE,
            payload: enableLink,
          })
          updateAdditionalInfoInCaseTracking();
          resolve();
        }).catch((err) => {
          console.log('Error', err);
          dispatch({ type: types.PROFILE_UPDATE_ERRORED, error: err });
          dispatch(createHistory('Update Company Profile', 'Company Profile', 'Error', companyProfile, err, 'NB'));
          reject();
        });
      } else {
        resolve();
      }
    } else {
      dispatch({ type: types.RESET_FLOW });
      history.push('/');
    }
  });
}

function updateRLCompanyProfile(companyProfile, useSimple, exit, isRenewals, btn = '', isAttested = false, isErCert, closePage) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch({ type: types.PROFILE_UPDATING });
      // For Renewals, if Save And Return Button clicked
      if (isRenewals && btn === 'saveAndReturnBtn') {
        if(closePage === false){
          dispatch({
            type: types.SET_SSN_CHECK_POPUP,
            payload: true,
          });
          return
        } else {
          dispatch({
            type: types.SET_EMPLOYEE_SAVE_RETURN_LOADER,
            payload: true,
          });
        }
        // For Renewals, if Save Button clicked
      } else if (isRenewals && btn === 'saveBtn') {
        if(closePage === false){
          dispatch({
            type: types.SET_SSN_CHECK_POPUP,
            payload: true,
          });
          return
        } else {
          dispatch({
            type: types.SET_EMPLOYEE_SAVE_LOADER,
            payload: true,
          });
        }
      }
      const fullProps = getState();
      const cmpProfile = deepCopy(fullProps.companyProfile);
      cmpProfile.employerEnrollment = saveEmployerToCompanyProfile(cmpProfile, fullProps).employerEnrollment;
      if (cmpProfile.enrollment) {
        cmpProfile.enrollment.requiredDocs = fullProps.enrollment.documents.requiredDocuments;
      } else {
        cmpProfile.enrollment = {
          requiredDocs: fullProps.enrollment.documents.requiredDocuments,
        };
      }
      let employerCertification;
      let attestationData;

      if (isErCert) {
        employerCertification = companyProfile.employerCertification || {};
        attestationData = companyProfile.employerCertification.attestation || {};
      } else {
        employerCertification = fullProps.enrollment.application.employerCertification;

        if (cmpProfile.locations[0].zipData.stateCode === 'NY') {
          if (cmpProfile.employerCertification) {
            attestationData = fullProps.enrollment.application.attestation.attestation;
          }
        } else {
          attestationData = fullProps.enrollment.application.employerCertification.attestation;
        }
      }
      if (isAttested) {
        attestationData.isAlreadyAttested = true;
      }
      if (JSON.stringify(attestationData) !== '{}' && !isNullOrUndefined(attestationData)) {
        employerCertification.attestation = attestationData;
      }
      cmpProfile.employerCertification = employerCertification;
      cmpProfile.marketType = getMarketType();
      const { userName } = fullProps.user;

      if(isUhcRenewals()) {
        cmpProfile.priorYearDataSelected = fullProps?.renewals?.priorYearDataSelected
      }
      
      axios
        .put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile)
        .then((result) => {
          const { updatedUserCases } = result.data;
          dispatch({
            type: types.UPDATED_USER_CASES,
            payload: updatedUserCases,
          });
          dispatch(createHistory('Update RL Company Profile', 'Company Profile', 'Success', cmpProfile, result.data, 'RL'));
          if (!exit) {
            dispatch({
              type: types.PROFILE_UPDATED,
              companyProfile: {
                ...cmpProfile,
                _id: result.data.updatedProfile._id,
              },
              useSimple,
            });
          } else {
            dispatch({ type: types.RESET_FLOW });
            history.push('/');
          }
          resolve(result.data.updatedProfile._id);

          // Keep this at last to avoid infinite loading
          // For Renewals, if Save And Return Button clicked
          if (isRenewals && btn === 'saveAndReturnBtn') {
            dispatch({
              type: types.SET_EMPLOYEE_SAVE_RETURN_LOADER,
              payload: false,
            });
            history.push('/enrollmentManager/employees-overview');
            // For Renewals, if Save Button clicked
          } else if (isRenewals && btn === 'saveBtn') {
            dispatch({
              type: types.SET_EMPLOYEE_SAVE_LOADER,
              payload: false,
            });
          }
        })
        .catch((err) => {
          console.log('Error', err);
          if(isUhcRenewals()) {
            logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'companyProfileActions.updateRLCompanyProfile', cmpProfile, err);
          }

          dispatch({ type: types.PROFILE_UPDATE_ERRORED, error: err });
          dispatch(createHistory('Update RL Company Profile', 'Company Profile', 'Error', cmpProfile, err, 'RL'));
          reject();
          // For Renewals, if Save And Return Button clicked
          if (isRenewals && btn === 'saveAndReturnBtn') {
            dispatch({
              type: types.SET_EMPLOYEE_SAVE_RETURN_LOADER,
              payload: false,
            });
            history.push('/enrollmentManager/employees-overview');

            // For Renewals, if Save Button clicked
          } else if (isRenewals && btn === 'saveBtn') {
            dispatch({
              type: types.SET_EMPLOYEE_SAVE_LOADER,
              payload: false,
            });
          }
        });
    });
}

export function updateState(companyProfile) {
  return (dispatch) => {
    dispatch({
      type: types.PROFILE_UPDATED,
      companyProfile: {
        ...companyProfile,
      },
    });
  };
}

export function resetProfile() {
  return (dispatch) => {
    sendLog({
      action: 'RESET COMPANY PROFILE',
    });

    dispatch({
      type: types.RESET_PROFILE,
    });
  };
}

export function updateImportStatus(updatedStatus) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE IMPORT STATUS',
    });

    dispatch({
      type: types.UPDATE_IMPORT_STATUS,
      status: updatedStatus,
    });
  };
}

export function updateCaseProgress(caseProgress, type, pendValue) {
  return async (dispatch, getState) => {
    const { caseTracking, companyProfile } = getState();
    const isCopyQuote = caseTracking && caseTracking.isCopyQuote;
    const isNewQuote = caseTracking && caseTracking.isNewQuote;
    const oldCaseStatus = (isCopyQuote || isNewQuote) ? '' : companyProfile.caseProgress
    const statusChangeAllowed = isStatusChangeAllowed(oldCaseStatus, caseProgress)

    if (statusChangeAllowed) {
      dispatch({
        type: types.UPDATE_CASE_PROGRESS,
        caseProgress,
      });

      dispatch({
        type: types.UPDATE_CASE_PEND_REP,
        casePendRep: pendValue
      })

      if (type && type !== 'RENEWAL') {
        const idx = getCaseIndexForNB(caseTracking)
        if (caseProgress !== CASE_PROGRESS.QUOTING) {
          await dispatch(saveOrUpdateCaseTracking('Enrollment', caseProgress, idx, { pendValue }));
          return;
        }
      }
    } else {
      console.error('Status Mismatch - CompanyProfileAction')
      console.error(oldCaseStatus, caseProgress)
      return;
    }
  }
}

export function updateQuoteStatus(quoteStatus) {
  return {
    type: types.UPDATE_QUOTE_STATUS,
    quoteStatus,
  };
}
export function updateQuoteProgress(quoteProgress) {
  return {
    type: coverageTypes.UPDATE_QUOTE_PROGRESS,
    quoteProgress,
  };
}

// for renewals
export function updateRenewalCompanyProfile(companyProfile) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE_RENEWAL_COMPANY_PROFILE,',
    });

    dispatch({
      type: types.UPDATE_RENEWAL_COMPANY_PROFILE,
      companyProfile,
    });
  };
}

export function getRenewalCompanyProfileData(memberGroupId, dispatchAsItIs = false, data) {
  return (dispatch, getState) => {
    sendLog({
      action: 'UPDATE_RENEWAL_COMPANY_PROFILE',
    });
    const fullProps = getState();
    const { userName } = fullProps.user;
    const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
    const marketType = getMarketType();
    const priorYearDataSelected = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected: 'N';
    const effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate;
    axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&marketType=${marketType}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`).then((resp) => {
      const respData = resp && resp.data ? resp.data : '';
      dispatch(createHistory('Renewal - Get Companyprofile', 'Casetrack', 'Success', { memberGroupId, quoteTrackingNumber }, respData, 'RL'));
      if (
        respData &&
        respData.locations &&
        respData.locations[0] &&
        respData.locations[0].zipData &&
        respData.locations[0].zipData.zipCode &&
        (respData.locations[0].zipData.countyFIPSCode == '' ||
          respData.locations[0].zipData.stateFIPSCode == '' ||
          !respData.locations[0].zipData.countyName)
      ) {
        const data = {...respData, marketType: marketType}
        getZipData(dispatch, data, userName, priorYearDataSelected);
      }
      dispatch({
        type: types.UPDATE_RENEWAL_COMPANY_PROFILE,
        payload: respData === undefined || respData === null ? '' : respData,
      });
      dispatch(updateEmployeeProductSelection(respData.employees));
      dispatch({
        type: types.RESET_REVIEW_FLAG,
        payload: true,
      });
    }).catch(err => {
      if (isUhcRenewals()) {
        const reqBody = {
          quoteTrackingNumber, memberGroupId, marketType, priorYearDataSelected
        }
        logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'companyProfileActions.getRenewalCompanyProfileData', reqBody, err);
      }
      throw err;
    })
  };
}
export const getZipData = (dispatch, data, userName, priorYearDataSelected) => {
  axios
    .get(`${apiRoutes.quickQuotes}/zip-mappings/${data.locations[0].zipData.zipCode}`)
    // TOD will remove ones testing done
    // axios.get(`http://xport-project-x.ocp-ctc-core-nonprod.optum.com/api/dev/ben/quick-quotes-sl/v1/zip-mappings/${data.locations[0].zipData.zipCode}`)
    .then((res) => {
      const thisZipObj = res.data[0];
      data.locations.forEach((location) => {
        if (!location.zipData.countyFIPSCode || !location.zipData.countyName || !location.zipData.stateFIPSCode) {
          location.zipData.countyFIPSCode = thisZipObj.countyFIPSCode;
          location.zipData.stateFIPSCode = thisZipObj.stateFIPSCode;
          location.zipData.countyName = thisZipObj.county;
          location.zipData.state = thisZipObj.state;
        }
      });
      data.priorYearDataSelected = priorYearDataSelected;
      axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, data).then((result) => {
        dispatch(createHistory('Update Zipobj Details in zipdata', 'Company Profile', 'Success', data, result, 'RL'));
      }).catch(err => {
        logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'companyProfileActions.getZipData', data, err);
        throw err;
      })
      dispatch(createHistory('ZIP Mappings API in RL', 'Company Profile', 'Success', data.locations[0].zipData.zipCode, thisZipObj, 'RL'));
      dispatch({
        type: types.UPDATE_RENEWAL_COMPANY_PROFILE,
        payload: data === undefined || data === null ? '' : data,
      });
      dispatch({
        type: types.RESET_REVIEW_FLAG,
        payload: true,
      });
    })
    .catch((err) => {
      console.log('Error', err);
      if(isUhcRenewals()) {
        const reqBody = {
          zipCode: data?.locations.length > 0 ? data.locations[0].zipData.zipCode : ''
        }
        logError(KEY_NAME, UHC_REN_APIS.ZIP_MAPPINGS, 'companyProfileActions.getZipData', reqBody, err);
      }
      dispatch({ type: types.ERROR_ZIP_MAPPINGS, error: err });
      dispatch(createHistory('ZIP Mappings API in RL', 'Company Profile', 'Error', data.locations[0].zipData.zipCode, err, 'RL'));
    });
};

export function updateRenewalQuoteProgress(quoteStatus) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_QUOTE_PROGRESS,
      quoteProgress: quoteStatus,
    });
  };
}

const setPrePopulateFields = (data, props, stateCode) => {
  if (stateCode === 'NY') {
    const certificationPrepopulatedFields = {
      eligibleEmployeesGroupHas: '',
      numberOfEmployeesEnrolling: '',
      numberOfRetiredEmployeesEnrolling: '',
      numberOfEmployeesEnrollingCOBRAorSC: '',
    };

    certificationPrepopulatedFields.eligibleEmployeesGroupHas = data.employerEnrollment.eligibility.participation
      ? data.employerEnrollment.eligibility.participation.numEligibleEmployees
      : '';
    const employeeObject = data.employees && data.employees.length > 0 ? data.employees[0] : null;
    if (employeeObject) {
      certificationPrepopulatedFields.numberOfEmployeesEnrolling = `${employeeObject.filter((x) => x.employeeStatus === 'Active').length}`;
      certificationPrepopulatedFields.numberOfRetiredEmployeesEnrolling = `${employeeObject.filter((x) => x.employeeStatus === 'COBRA').length}`;
      certificationPrepopulatedFields.numberOfEmployeesEnrollingCOBRAorSC = `${employeeObject.filter((x) => x.employeeStatus === 'Retired').length}`;
    }

    if (data.employerCertification) {
      certificationPrepopulatedFields.eligibleEmployeesGroupHas =
        data.employerCertification.certification && data.employerCertification.certification.eligibleEmployeesGroupHas !== ''
          ? data.employerCertification.certification.eligibleEmployeesGroupHas
          : certificationPrepopulatedFields.eligibleEmployeesGroupHas;
      certificationPrepopulatedFields.numberOfEmployeesEnrolling =
        data.employerCertification.certification && data.employerCertification.certification.numberOfEmployeesEnrolling !== ''
          ? data.employerCertification.certification.numberOfEmployeesEnrolling
          : certificationPrepopulatedFields.numberOfEmployeesEnrolling;
      certificationPrepopulatedFields.numberOfRetiredEmployeesEnrolling =
        data.employerCertification.certification && data.employerCertification.certification.numberOfRetiredEmployeesEnrolling !== ''
        ? data.employerCertification.certification.numberOfRetiredEmployeesEnrolling
          : certificationPrepopulatedFields.numberOfRetiredEmployeesEnrolling;
      certificationPrepopulatedFields.numberOfEmployeesEnrollingCOBRAorSC =
        data.employerCertification.certification && data.employerCertification.certification.numberOfEmployeesEnrollingCOBRAorSC !== ''
        ? data.employerCertification.certification.numberOfEmployeesEnrollingCOBRAorSC
          : certificationPrepopulatedFields.numberOfEmployeesEnrollingCOBRAorSC;
      return {
        ...data.employerCertification.certification,
        eligibleEmployeesGroupHas: certificationPrepopulatedFields.eligibleEmployeesGroupHas,
        numberOfEmployeesEnrolling: certificationPrepopulatedFields.numberOfEmployeesEnrolling,
        numberOfRetiredEmployeesEnrolling: certificationPrepopulatedFields.numberOfRetiredEmployeesEnrolling,
        numberOfEmployeesEnrollingCOBRAorSC: certificationPrepopulatedFields.numberOfEmployeesEnrollingCOBRAorSC,
      };
    }

    return {
      ...props.employerCertification.certification,
      eligibleEmployeesGroupHas: certificationPrepopulatedFields.eligibleEmployeesGroupHas,
      numberOfEmployeesEnrolling: certificationPrepopulatedFields.numberOfEmployeesEnrolling,
      numberOfRetiredEmployeesEnrolling: certificationPrepopulatedFields.numberOfRetiredEmployeesEnrolling,
      numberOfEmployeesEnrollingCOBRAorSC: certificationPrepopulatedFields.numberOfEmployeesEnrollingCOBRAorSC,
    };
  }
  if (stateCode === 'NJ') {
    const certificationPrepopulatedFields = {
      totalFullTimeEmployees: '',
      totalFullTimeEmployeesEnrollingForHealthBenefits: '',
    };
    const { locations } = data;
    let eligibleCount = 0;
    let atneCount = 0; let
      fteCount = 0;
    locations.forEach((location) => {
      const { fte } = location;
      fteCount += parseInt(fte, 10);
    });
    certificationPrepopulatedFields.totalFullTimeEmployees =
      data.employerEnrollment && data.employerEnrollment.eligibility && data.employerEnrollment.eligibility.participation
        ? data.employerEnrollment.eligibility.participation.numEligibleEmployees
        : fteCount;

    const employeesArray = data.employees && data.employees.length > 0 ? data.employees[0] : null;
    if (employeesArray && employeesArray.length > 0) {
      certificationPrepopulatedFields.totalFullTimeEmployeesEnrollingForHealthBenefits = employeesArray.filter((employee) =>
        employee.productSelection ? employee.productSelection.length > 0 ? employee.productSelection[0].hasOwnProperty('medical') === true : false : false
      ).length;
      certificationPrepopulatedFields.totalFullTimeEmployeesEnrollingForHealthBenefits =
        certificationPrepopulatedFields.totalFullTimeEmployeesEnrollingForHealthBenefits.toString();
    }

    if (data.employerCertification && data.employerCertification.certification) {
      const dataCertificationFields = data.employerCertification.certification;
      return {
        ...props.employerCertification.certification,
        ...data.employerCertification.certification,
        totalFullTimeEmployees: dataCertificationFields.totalFullTimeEmployees || certificationPrepopulatedFields.totalFullTimeEmployees,
        totalFullTimeEmployeesEnrollingForHealthBenefits:
          dataCertificationFields.totalFullTimeEmployeesEnrollingForHealthBenefits ||
          certificationPrepopulatedFields.totalFullTimeEmployeesEnrollingForHealthBenefits,
      };
    }
    return {
      ...props.employerCertification.certification,
      ...certificationPrepopulatedFields,
    };
  }
};

const getCensusEmployeeStatus = (employeeInfo) => {
  switch (employeeInfo.employeeStatus) {
    case 'Active':
      return employeeInfo.numberOfHoursWorked ? (employeeInfo.numberOfHoursWorked >= 25 ? 'Full Time' : 'Part Time') : 'Other';
    case 'COBRA':
      return 'COBRA';
    default:
      return 'Other';
  }
};

const getCensusFieldsForNJCertification = (data) => {
  const employees = data.employees && data.employees.length > 0 ? data.employees[0] : null;
  const censusDetails = [];
  const dataCensusDetails =
    data.employerCertification && data.employerCertification.censusInformation ? data.employerCertification.censusInformation.censusDetails : [];
  employees &&
    employees.forEach((employee, index) => {
      censusDetails.push({
        censusName: employee.firstName || employee.lastName ? `${employee.firstName} ${employee.lastName}` : '',
        censusJobTitle: dataCensusDetails.length > 0 && dataCensusDetails[index] ? dataCensusDetails[index].censusJobTitle : '',
        censusDateOfEmployment: employee.employeeInfo && employee.employeeInfo.dateOfHire ? employee.employeeInfo.dateOfHire : '',
        censusHoursWorkedPerWeek: employee.employeeInfo && employee.employeeInfo.numberOfHoursWorked ? employee.employeeInfo.numberOfHoursWorked : '',
        censusStatus: employee.employeeInfo ? getCensusEmployeeStatus(employee.employeeInfo) : 'Other',
        censusWorkLocation: dataCensusDetails.length > 0 && dataCensusDetails[index] ? dataCensusDetails[index].censusWorkLocation : '',
        censusDOB: employee.employeeInfo && employee.employeeInfo.dob ? employee.employeeInfo.dob : employee && employee.dob ? employee.dob : '',
      });
    });
  return censusDetails;
};
const getAvgNumberOfEmployeesSectionFields = (data) => {
  if (
    data.employerCertification &&
    data.employerCertification.censusInformation &&
    data.employerCertification.censusInformation.avgNumberOfEmployeesSection &&
    data.employerCertification.censusInformation.avgNumberOfEmployeesSection.avgNumberOfEmployees.length > 0
  ) {
    return data.employerCertification.censusInformation.avgNumberOfEmployeesSection;
  }

  const totalAvgNoofEmployeesColumns = {
    jan: '',
    feb: '',
    mar: '',
    apr: '',
    may: '',
    jun: '',
    jul: '',
    aug: '',
    sep: '',
    oct: '',
    nov: '',
    dec: '',
    total: '',
    avg: '',
  };

  const avgNumberOfEmployees = [];
  avgNumberOfEmployees.push({
    month: 'FT EE',
    ...totalAvgNoofEmployeesColumns,
  });
  return { avgNumberOfEmployees };
};

export function getRenewalCompanyProfileEmployerCertification(btn = '', isAttested = false) {
  const isSave = btn === 'saveBtn';
  const isSaveReturn = btn === 'saveAndReturnBtn';
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (isSave) {
        dispatch({
          type: types.SET_EMPLOYERCERTIFICATION_SAVE_LOADER,
          payload: true,
        });
      } else if (isSaveReturn) {
        dispatch({
          type: types.SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER,
          payload: true,
        });
      }

      const fullProps = getState();
      const { userName } = fullProps.user;
      const { stateCode } = fullProps.companyProfile.locations[0].zipData;
      const cmpProfile = deepCopy(fullProps.companyProfile);
      const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
      cmpProfile.quoteTrackingNumber = quoteTrackingNumber;

      if (!isSave && !isSaveReturn) {
        const certificationFields = setPrePopulateFields(cmpProfile, fullProps.enrollment.application, stateCode);
        if (stateCode === 'NJ') {
          let censusInformation = {};

          if (cmpProfile.employerCertification && cmpProfile.employerCertification.censusInformation
            && cmpProfile.employerCertification.censusInformation.censusDetails && cmpProfile.employerCertification.censusInformation.censusDetails.length > 0) {
            censusInformation = cmpProfile.employerCertification.censusInformation;
          } else {
            censusInformation = {
              censusDetails: getCensusFieldsForNJCertification(cmpProfile),
              avgNumberOfEmployeesSection: getAvgNumberOfEmployeesSectionFields(cmpProfile),
            };
          }

          cmpProfile.employerCertification = {
            certification: certificationFields,
            censusInformation,
            attestation:
              cmpProfile.employerCertification &&
                cmpProfile.employerCertification.attestation &&
                Object.keys(cmpProfile.employerCertification.attestation).length > 0
                ? cmpProfile.employerCertification.attestation
                : fullProps.enrollment.application.employerCertification.attestation,
          };
        } else if (stateCode === 'NY') {
          if (cmpProfile.employerCertification) {
            dispatch({
              type: types.REFRESH_ATTESTATION_INFO,
              payload: {
                attestation:
                  cmpProfile.employerCertification && cmpProfile.employerCertification.attestation
                    ? cmpProfile.employerCertification.attestation
                    : fullProps.enrollment.application.attestation.attestation,
              },
            });
          }
          const attestation =
            cmpProfile.employerCertification && cmpProfile.employerCertification.attestation
              ? cmpProfile.employerCertification.attestation
              : fullProps.enrollment.application.attestation.attestation;

          cmpProfile.employerCertification = { certification: certificationFields, attestation };
        }

        dispatch({
          type: types.REFRESH_EMPLOYER_CERTIFICATION_INFO,
          payload: cmpProfile.employerCertification,
        });
        history.push(`/enrollmentManager/${routeConsts.EMPLOYER_CERTIFICATION}`);
      } else if (stateCode === 'NJ') {
        cmpProfile.employerCertification = {
          certification: fullProps.enrollment.application.employerCertification.certification,
          attestation: fullProps.enrollment.application.employerCertification.attestation,
          censusInformation: fullProps.enrollment.application.employerCertification.censusInformation,
        };
      } else if (stateCode === 'NY') {
        const attestationData = fullProps.enrollment.application.attestation.attestation;
        if (isAttested) {
          attestationData.isAlreadyAttested = true;
        }
        cmpProfile.employerCertification = {
          certification: fullProps.enrollment.application.employerCertification.certification,
          attestation: attestationData,
        };
      }
      if (isSave || isSaveReturn) {
        const marketType = getMarketType();
        cmpProfile.marketType = marketType;
        axios
          .put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile)
          .then((resp) => {
            console.log('Employer Certification info  Saved', resp);
            if (!isSave && !isSaveReturn) {
              history.push(`/enrollmentManager/${routeConsts.EMPLOYER_CERTIFICATION}`);
            }
            // To DO code added to send the documents to S3 - Start
            if ((stateCode === 'NJ' || stateCode === 'NY') && (isSave || isSaveReturn)) {
              const { memberGroupName, memberGroupId, address1, address2, city, state, stateCode, zipCode } = fullProps.renewals.renewalCaseData;

              const employerDetails = {
                memberGroupName,
                memberGroupId,
                quoteTrackingNumber,
                address1,
                address2,
                city,
                state,
                stateCode,
                zipCode,
              };

              if (employerDetails.stateCode === undefined) {
                employerDetails.stateCode = fullProps.renewals.renewalCaseData.situsState;
              }

              let { certification } = cmpProfile.employerCertification;
              let attestation = {};
              if (employerDetails.stateCode === 'NY') {
                const { formReceivedSignature: signature, date: signatureDate, title } = cmpProfile.employerCertification.attestation;
                const { fullName: name } = fullProps.companyProfile.primaryContact;

                attestation = {
                  name,
                  signature,
                  title,
                  signatureDate,
                };
              } else if (employerDetails.stateCode === 'NJ') {
                const { avgNumberOfEmployees } = cmpProfile.employerCertification.censusInformation.avgNumberOfEmployeesSection;

                let avgNumberOfEmployeesValues = {};

                if (avgNumberOfEmployees && avgNumberOfEmployees.length === 1) {
                  const feEE = avgNumberOfEmployees[0];
                  // const ptEE = avgNumberOfEmployees[1];
                  // const seasonalEE = avgNumberOfEmployees[2];

                  const ftEETotal = feEE && feEE.total;
                  // const ptEETotal = ptEE && ptEE.total;
                  // const seasonalTotal = seasonalEE && seasonalEE.total;

                  const ftAvg = ftEETotal > 0 ? (ftEETotal / 12).toFixed(2) : '';
                  // const ptAvg = ptEETotal > 0 ? (ptEETotal / 12).toFixed(2) : '';
                  // const seasonalAvg = seasonalTotal > 0 ? (seasonalTotal / 12).toFixed(2) : '';

                  avgNumberOfEmployeesValues = {
                    ftEE: {
                      ...feEE,
                      avg: ftAvg,
                    },
                    // ptEE: {
                    //     ...ptEE,
                    //     ptAvg,
                    // },
                    // seasonalTotal: {
                    //     ...seasonalEE,
                    //     seasonalAvg,
                    // },
                  };
                }

                const { certifySEStateNJSection } = cmpProfile.employerCertification.attestation;
                attestation = { ...certifySEStateNJSection };

                const carriers = [];

                carriers.push({ name: certification.carrierName, noOfEmployeesCovered: certification.carrierValue });

                certification = {
                  ...certification,
                  carriers,
                  avgNumberOfEmployees: avgNumberOfEmployeesValues,
                  ...cmpProfile.employerCertification.censusInformation,
                };
              }

              const payload = {
                employerDetails,
                certificationDetails: certification,
                attestation,
              };

              axios
                .post(`${apiRoutes.email}/pdf/populated/employerAnnualCertification`, payload, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then(
                  async (result) => {
                    const {
                      data: {
                        ContentType,
                        mappedBufferPDF: { data: mappedBufferData },
                        fileName,
                      },
                    } = result;
                    dispatch(createHistory('Renewal - Employer Certification', 'Enrollment', 'Success', payload, fileName, 'RL'));
                    // Apparently s3 sends data back as a nested array that needs to be converted before its converted
                    const unit8ArrayBuffer = new Uint8Array(mappedBufferData);
                    const pdfBlob = new Blob([unit8ArrayBuffer], { type: ContentType });
                    const file = new File([pdfBlob], fileName, { type: ContentType });
                    const category = 'employerCertification';
                    const transactionIndicator = 'R'; // "R" for renewals
                    const cloudFileName = `${transactionIndicator + memberGroupId}_${quoteTrackingNumber}_${fileName}`;
                    let deleteS3Result = 'successfull';
                    deleteS3Result = await deleteMongoFile(cloudFileName);
                    if (deleteS3Result !== 'error') {
                      // TODO: Hit service layer endpoint to remove doc
                      dispatch({
                        type: types.REMOVE_REQUIRED_DOCUMENT,
                        payload: { category, cloudFileName },
                      });
                    }
                    const uploadResult = await uploadFile(file, memberGroupId, 'renewals', null, quoteTrackingNumber);
                    if (uploadResult !== 'error') {
                      dispatch({
                        type: types.UPLOAD_REQUIRED_DOCUMENT,
                        payload: {
                          cloudFileName: uploadResult.cloudFileName,
                          clientFileName: uploadResult.clientFileName,
                          uploadedAt: uploadResult.uploadedAt,
                          mimeType: uploadResult.mimetype,
                          category,
                          size: uploadResult.size,
                        },
                      });
                    }

                    const updatedEnrollmentReqDocs = getState().enrollment.documents.requiredDocuments;
                    const id = fullProps.companyProfile._id;
                    if (id) {
                      axios.patch(`${apiRoutes.mongo}/fullShopping/${id}`, {
                        enrollment: {
                          requiredDocs: updatedEnrollmentReqDocs,
                        },
                        renewal: true,
                      });
                    }
                  },
                  (error) => {
                    // TODO: make better error handling
                    console.log('error', error);
                  }
                );
            }
            // To DO code added to send the documents to S3 - end
            if (isSave) {
              dispatch({
                type: types.SAVE_EMPLOYER_CERTIFICATION_INFO,
                payload: cmpProfile.employerCertification,
              });
              dispatch({
                type: types.SET_EMPLOYERCERTIFICATION_SAVE_LOADER,
                payload: false,
              });
            } else if (isSaveReturn) {
              dispatch({
                type: types.SAVE_EMPLOYER_CERTIFICATION_INFO,
                payload: cmpProfile.employerCertification,
              });
              dispatch({
                type: types.SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER,
                payload: false,
              });
              history.push(`/enrollmentManager/${routeConsts.REQUIRED_DOCUMENTS}`);
            }
            resolve();
          })
          .catch((err) => {
            console.log('EmployerCertification info loading failed', err);
            if (!isSave && !isSaveReturn) {
              history.push(`/enrollmentManager/${routeConsts.EMPLOYER_CERTIFICATION}`);
            }
            if (isSave) {
              dispatch({
                type: types.SET_EMPLOYERCERTIFICATION_SAVE_LOADER,
                payload: false,
              });
            } else if (isSaveReturn) {
              dispatch({
                type: types.SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER,
                payload: false,
              });
              history.push(`/enrollmentManager/${routeConsts.REQUIRED_DOCUMENTS}`);
            }
            reject();
          });
      }
    });
}

export function employerCertificationNB() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const fullProps = getState();
      const { stateCode } = fullProps.companyProfile.locations[0].zipData;
      const cmpProfile = deepCopy(fullProps.companyProfile);

      const certificationFields = setPrePopulateFields(cmpProfile, fullProps.enrollment.application, stateCode);
      if (stateCode === 'NJ') {
        let censusInformation = {};
        let certification = {};
        let attestation = {};

        if (cmpProfile.employerCertification && cmpProfile.employerCertification.certification) {
          certification = cmpProfile.employerCertification.certification;
        } else {
          certification = certificationFields;
        }
        if (cmpProfile.employerCertification && cmpProfile.employerCertification.censusInformation
          && cmpProfile.employerCertification.censusInformation.censusDetails && cmpProfile.employerCertification.censusInformation.censusDetails.length > 0) {
          censusInformation = cmpProfile.employerCertification.censusInformation;
        } else {
          censusInformation = {
            censusDetails: getCensusFieldsForNJCertification(cmpProfile),
            avgNumberOfEmployeesSection: getAvgNumberOfEmployeesSectionFields(cmpProfile),
          };
        }
        if (
          cmpProfile.employerCertification &&
          cmpProfile.employerCertification.attestation &&
          Object.keys(cmpProfile.employerCertification.attestation).length > 0
        ) {
          attestation = cmpProfile.employerCertification.attestation;
        } else {
          attestation = fullProps.enrollment.application.employerCertification.attestation;
        }

        cmpProfile.employerCertification = {
          certification,
          censusInformation,
          attestation,
        };
      }

      dispatch({
        type: types.REFRESH_EMPLOYER_CERTIFICATION_INFO,
        payload: cmpProfile.employerCertification,
      });
      history.push(`/enrollment/${routeConsts.EMPLOYER_CERTIFICATION}`);

      resolve();
    });
}

export function updateRenewalsSelectedProducts(selectedProducts) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE RENEWALS SELECTED PRODUCTS',
    });

    dispatch({
      type: types.UPDATE_RENEWALS_SELECTED_PRODUCTS,
      payload: selectedProducts,
    });
  };
}

export function updateUserCardsSelectedProducts(cardSelectedProducts) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE USER CARDS SELECTED PRODUCTS',
    });

    dispatch({
      type: types.UPDATE_USER_CARDS_SELECTED_PRODUCTS,
      payload: cardSelectedProducts,
    });
  };
}

export function updateRenewalEffectiveDate(effectiveDate) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE_RENEWAL_EFFECTIVE_DATE',
    });
    dispatch({
      type: types.UPDATE_RENEWAL_EFFECTIVE_DATE,
      payload: effectiveDate,
    });
  };
}
export const getCompanyProfile =
  (quoteId, caseId, actionTypes, landingPage = 'full') =>
    async (dispatch, getState) => {
      try {
        const currentCaseId = caseId || getState().caseTracking.caseId;


        const stateStore = await getStateStore(currentCaseId, quoteId);
        const { companyProfile, overall: { b2b = false } } = stateStore;

        let retrievedEnableLink;
        if (companyProfile.locations && companyProfile.locations.length > 0 && companyProfile.locations[0].zipData && companyProfile.locations[0].zipData.stateCode && companyProfile.effectiveDate) {
          const fiLFModuleTableData = await axios.get(`${apiRoutes.quickQuotes}/getFILFTableModule?state=${companyProfile.locations[0].zipData.stateCode}&effectiveDate=${companyProfile.effectiveDate}`);
          let { enableLink } = fiLFModuleTableData.data;
          retrievedEnableLink = enableLink;
        }

        let payload = retrievedEnableLink ? retrievedEnableLink : 'N';

        // Don't save the state store id until case is viewed
        if (actionTypes === 'GET_DATA_WITHOUT_ACTION' || actionTypes === 'REQUEST_NB_NEW_COPY_QUOTE' || actionTypes === 'REQUEST_NB_WITHDRAW_CASE' || actionTypes === 'REQUEST_NB_CLOSE_CASE' || actionTypes === 'REQUEST_NB_REMOVE_FULL_QUOTE') {
          stateStore.caseTracking.stateStoreId = '';
        }

        const expandedStateStore = expandStateStorePayload(stateStore);

        dispatch({
          type: 'STORED_DATA',
          payload: expandedStateStore,
        });

        dispatch({
          type: types.UPDATE_FILF_BUTTON_ENABLE_DISABLE,
          payload: payload,
        })

        if (actionTypes !== '' && actionTypes !== 'GET_DATA_WITHOUT_ACTION') {
          dispatch(updateNBDashboardActivity(actionTypes));
        }

        if (b2b) {
          dispatch(resetBreadCrumbAccess())
          dispatch(updateBreadCrumbAccess('enrollmanager'))
        }

        // get all the rules from the database
        await dispatch(handleAllRuleCalls());   //Rules logic

        const { caseTracking } = stateStore;
        let isDesQuote = false
        const desQuote = caseTracking.caseTrack.find((x) => x.source === 'DES' && x.quoteTrackingNumber.startsWith('B2B-NB'));
        if (!isUndefinedOrNull(desQuote)) {
            isDesQuote = true
        }
        if (isDesQuote && actionTypes !== 'GET_DATA_WITHOUT_ACTION' && actionTypes !== 'REQUEST_NB_NEW_COPY_QUOTE' && actionTypes !== 'REQUEST_NB_WITHDRAW_CASE' && actionTypes !== 'REQUEST_NB_CLOSE_CASE' && actionTypes !== 'REQUEST_NB_REMOVE_FULL_QUOTE') {
          const stateStoreId = stateStore._id || '';
          dispatch(updateStateStoreId(stateStoreId));
        }
        if (actionTypes !== 'GET_DATA_WITHOUT_ACTION' && actionTypes !== 'REQUEST_NB_NEW_COPY_QUOTE' && actionTypes !== 'REQUEST_NB_WITHDRAW_CASE' && actionTypes !== 'REQUEST_NB_CLOSE_CASE' && actionTypes !== 'REQUEST_NB_REMOVE_FULL_QUOTE') {
          history.push(`/${landingPage}`);
        }

      } catch (err) {
        console.log('Error', err);
      }
    };

export function requestEmployeeAssistanceView() {
  return (dispatch) => {
    sendLog({
      action: 'REQUESTED_EMPLOYEE_ASSISTANCE',
    });
    dispatch({ type: types.REQUESTED_EMPLOYEE_ASSISTANCE });
  };
}

export function updateSelectedProducts(enrollment) {
  return (dispatch, getState) => {
    const enrollmentFromState = getState().rules.enrollment;
    sendLog({
      action: 'UPDATE ENROLLMENT SELECTED PRODUCTS',
    });

    const selectedProducts = {
      medical: false,
      dental: false,
      vision: false,
      life: false,
      suppLife: false,
      std: false,
      ltd: false
    };

    const flatMedicalPlans = Object.keys(enrollmentFromState.enrolledPlans)
      .map((key) => enrollmentFromState.enrolledPlans[key])
      .flat();
    if (flatMedicalPlans.length > 0) {
      selectedProducts.medical = true;
    }
    if (enrollmentFromState.enrolledDentalPlans.length > 0) {
      selectedProducts.dental = true;
    }
    if (enrollmentFromState.enrolledVisionPlans.length > 0) {
      selectedProducts.vision = true;
    }
    if (enrollmentFromState.enrolledLifePlans.length > 0) {
      selectedProducts.life = true;
    }
    if (enrollmentFromState.enrolledSuppLifePlans.length > 0) {
      selectedProducts.suppLife = true;
    }
    if (enrollmentFromState.enrolledSTDPlans.length > 0) {
      selectedProducts.std = true;
    }
    if (enrollmentFromState.enrolledLTDPlans.length > 0) {
      selectedProducts.ltd = true;
    }
    dispatch({
      type: types.UPDATE_ENROLLMENT_SELECTED_PRODUCTS,
      payload: selectedProducts,
    });
  };
}

export function updateRenewalsSICCode(sicCode) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE_RENEWAL_SIC_CODE_COMPANY_PROFILE',
    });

    dispatch({
      type: types.UPDATE_RENEWAL_SIC_CODE_COMPANY_PROFILE,
      payload: sicCode === '' || sicCode === null ? '-' : sicCode,
    });
  };
}

export function deleteCompanyProfileLocation(locations) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE_COMPANY_PROFILE_LOCATIONS',
    });

    dispatch({
      type: types.UPDATE_COMPANY_PROFILE_LOCATIONS,
      payload: locations,
    });
  };
}

export function updateCompanyProfileEmployees(employees) {
  return (dispatch) => {
    sendLog({
      action: 'UPDATE_COMPANY_PROFILE_EMPLOYEES',
    });

    dispatch({
      type: types.UPDATE_COMPANY_PROFILE_EMPLOYEES,
      payload: employees,
    });
  };
}

//US3430326 Identify MicroGroups
export function updateMicroGroup(status) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_MICROGROUP_STATUS,
      status,
    });
  };
}

//US3417762 - adding new flag to pause HW installation for MicroGroups
export function updateHWPaused(status) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_HWPAUSED_STATUS,
      status,
    });
  };
}

export function updateHWResume(status) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_HWRESUME_STATUS,
      status,
    });
  };
}

/**
 * @param {boolean} supportedArea true if ares zipcode is supported, false otherwise
\ */
export function updateSupportedAreaFS(supportedArea) {
  return {
    type: types.UPDATE_SUPPORTED_AREA_FS,
    supportedArea,
  };
}

export function redirectRenewalPRIMEAncillaryToNB(companyProfile) {
  return {
    type: types.UPDATE_PRIME_ANCILLARY_RENEWAL_TO_NB,
    payload: companyProfile,
  };
}

export function saveSicDataInCompanyProfileRL(sicData) {
  return (dispatch) => {
    sendLog({
      action: 'SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL',
    });

    dispatch({
      type: types.SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL,
      payload: sicData,
    });
  };
}
export function updateFteRules(locations, index) {
  return (dispatch) => {
    const stateCode = locations[index].zipData.stateCode
    axios.get(`${apiRoutes.mongo}/atne-rules/${stateCode}`)
      .then(res => {
        dispatch({
          type: types.UPDATE_FTERULE_QQ,
          rule: res.data.rule,
          locations,
          index
        });
      }).catch(err => {
        dispatch({
          type: types.UPDATE_FTERULE_QQ_ERROR,
          error: true,
          locations,
          index
        });
      })
  };
}

export function getDependentRules(stateCode, callback) {
  return (dispatch) => new Promise((resolve, reject) => {
    return axios.get(`${apiRoutes.mongo}/dependent-rules/${stateCode}`)
      .then(response => {
        dispatch({
          type: types.UPDATE_DEPENDENT_RULES,
          rules: response.data
        })
        callback(response.data);
        resolve();
      })
      .catch(err => {
        console.log(err.data)
        reject(err);
      })
  })
}

export function getRatingCount(locations, primaryLocationIdx) {
  const primaryLocation = locations[primaryLocationIdx];
  const fteRule = primaryLocation.fteRule;
  let ratingCount;
  switch (fteRule) {
    case 'ATNE':
      ratingCount = primaryLocation.atne;
      break;
    case 'FTE':
      ratingCount = primaryLocation.fte;
      break;
    case 'Eligible':
      ratingCount = primaryLocation.totalEligEmps;
      break;
    default:
      ratingCount = primaryLocation.totalEmps;
      break;
  }

  return ratingCount.toString();
}

export function getFteRules(stateCode) {
  return new Promise((resolve, reject) => {
    if (stateCode) {
      axios.get(`${apiRoutes.mongo}/atne-rules/${stateCode}`)
        .then(res => resolve(res.data.rule))
        .catch(err => reject());
    } else {
      reject();
    }
  });
}

export function setUnsavedEnrollFormChanges(flag) {
  return (dispatch) => {
    dispatch({
      type: types.SET_UNSAVED_ENROLL_FORM_CHANGES,
      payload: flag
    })
  }
}

export const getPackageSavingsEnableIndicator = (state, effectiveDate) => async (dispatch, getState) => {
  const fullProps = getState();
  const { userIntent } = fullProps.renewals;
  let samxModule;
  if (userIntent && userIntent.payload) {
    const moduleObject = userIntent.payload.find(e => (e.name === 'brand' || e.name === 'Brand'));
    samxModule = moduleObject.value;
  }
  let packageSavingsConfigUrl
  if (samxModule) {
    packageSavingsConfigUrl = `${apiRoutes.quickQuotes}/getPackageSavingsConfig?state=${state}&effectiveDate=${effectiveDate}&module=${samxModule}`;
  } else {
    packageSavingsConfigUrl = `${apiRoutes.quickQuotes}/getPackageSavingsConfig?state=${state}&effectiveDate=${effectiveDate}`;
  }
  const packageSavingsConfigData = await axios.get(packageSavingsConfigUrl);
  let { enablePackagedSavings: packageSavingsEnableIndicator } = packageSavingsConfigData.data
  packageSavingsEnableIndicator = packageSavingsEnableIndicator ? packageSavingsEnableIndicator : 'N';
  return packageSavingsEnableIndicator;
}

export function packageSavingsSetProductsFocus(flag) {
  return (dispatch) => {
    dispatch({
      type: types.PACKAGE_SAVINGS_SET_PRODUCT_FOCUS,
      payload: flag
    })
  }
}

export function setCannabisEmp(value){
  return (dispatch)=>{
    dispatch({
      type:types.SET_CANNABIS_EMP,
      payload:value
    })
  }
}

export const setActiveDates = (dates) => {
  return (dispatch)=>{
    dispatch({
      type: types.SET_ACTIVE_DATES,
      payload: dates
    })
  }
}
export function saveMedicalParticipation(value){
  return (dispatch)=>{
    dispatch({
      type:types.SAVE_MEDICAL_PARTICIPATION,
      payload:value
    })
  }
}

export const setTerminatedEmployees = (value) => async (dispatch,getState) => {
    dispatch({
      type : types.UPDATE_TERMINATED_EMPLOYEES_AND_DEPENDENTS,
      payload : value
    })
}
export function isSicEntered(flag){
  return (dispatch)=>{
    dispatch({
      type:types.IS_SIC_ENTERED,
      payload:flag
    })
  }
}

export const getZipDataInFinalize = (data, userName) => async (dispatch, getState) => {
  try {
    if (
      data &&
      data.locations &&
      data.locations[0] &&
      data.locations[0].zipData &&
      data.locations[0].zipData.zipCode &&
      (data.locations[0].zipData.countyFIPSCode == '' ||
        data.locations[0].zipData.stateFIPSCode == '' ||
        !data.locations[0].zipData.countyName)
    ) {
      const apiResult = await axios.get(`${apiRoutes.quickQuotes}/zip-mappings/${data.locations[0].zipData.zipCode}`).catch(err => {
        if(isUhcRenewals()) {
          logError(KEY_NAME, UHC_REN_APIS.ZIP_MAPPINGS, 'companyProfileActions.getZipDataInFinalize', {zipCode: data.locations[0].zipData.zipCode}, err);
        }
        throw err;
      });
      const apiResponse = apiResult.data;
      if (apiResponse && apiResponse.length > 0) {
        const thisZipObj = apiResponse[0];
        data.locations.forEach((location) => {
          if (!location.zipData.countyFIPSCode || !location.zipData.countyName || !location.zipData.stateFIPSCode) {
            location.zipData.countyFIPSCode = thisZipObj.countyFIPSCode;
            location.zipData.stateFIPSCode = thisZipObj.stateFIPSCode;
            location.zipData.countyName = thisZipObj.county;
            location.zipData.state = thisZipObj.state;
          }
        });
        dispatch({
          type: types.UPDATE_RENEWAL_COMPANY_PROFILE,
          payload: data === undefined || data === null ? '' : data,
        });
        const profileRes = await axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, data).catch(err => {
          if(isUhcRenewals()) {
            logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'companyProfileActions.getZipDataInFinalize', data, err);
          }
          throw err;
        });
        if(profileRes) {
          dispatch(createHistory('Update Zipobj Details in zipdata', 'Company Profile', 'Success', data, profileRes, 'RL'));
        }
        dispatch(createHistory('ZIP Mappings API in RL', 'Company Profile', 'Success', data.locations[0].zipData.zipCode, thisZipObj, 'RL'));
        return apiResponse;
      }
    } else {
      return {};
    }
  } catch (err) {
      console.log('Error', err);
  }
};

const handleAllRuleCalls = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { companyProfile: { locations, primaryLocationIdx, effectiveDate }, overall: { platform } } = getState();
  const { zipData , totalEligEmps } = locations[primaryLocationIdx];
  const { stateCode } = zipData;
  const ruleCallPromises = [];

  // Creating actions for parallel calls
  const dispatchPcpAutoAssignmentRules = () => new Promise((resolve, reject) => {   //Just does one call for rules (instead of many), but no changes to logic
    getPcpAutoAssignemntRules(stateCode, totalEligEmps)
    .then(result => {
        dispatch(savePcpAutoAssignmentRules(result));
        resolve();
    }).catch(() => {
        dispatch(savePcpAutoAssignmentRules([]));
        reject();
    })
  })

  // enrollment forms
  ruleCallPromises.push(
    dispatch(getEnrollmentForm(stateCode, 'newBusiness'))
  );
  // rate adjustment rules
  ruleCallPromises.push(
    dispatch(getRateAdjustmentRules(stateCode, totalEligEmps, effectiveDate))
  )
  // dependent rules
  ruleCallPromises.push(
    dispatch(getDependentRules(stateCode, () => {}))
  )
  // enrollment rules for PCR
  ruleCallPromises.push(
    dispatch(getEnrollmentRules(platform, zipData, effectiveDate, totalEligEmps))
  )
  // Pcp auto assignment rules
  ruleCallPromises.push(
    dispatchPcpAutoAssignmentRules()
  )
  // Employee participation rules for UHC only
  if (platform === 'PRIME') {
    ruleCallPromises.push(
      dispatch(updateParticipationRules(stateCode))      
    )
  }
  Promise.all(ruleCallPromises)
    .then(() => {
      resolve();
    }).catch(err => {
      reject(err);
    })
})