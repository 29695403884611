import { toMoney } from '../../../../../../utils/formatters/numbers';

const replaceUndefinedOrEmptyStr = (str) => {
    return str && str !== '' ? str : "0.00";
}

export const finalRates = (type, plan) => {
    let finalRates;

    switch (type) {
        case 'medical':
        case 'dental':
        case 'vision':
            finalRates = {
                ee: plan.finalRates ? replaceUndefinedOrEmptyStr(plan.finalRates.employee) :
                    (plan.monthlyPremium ? replaceUndefinedOrEmptyStr(plan.monthlyPremium.employee) : "0.00"),
                es: plan.finalRates ? replaceUndefinedOrEmptyStr(plan.finalRates.empAndSpouse) :
                    (plan.monthlyPremium ? replaceUndefinedOrEmptyStr(plan.monthlyPremium.empAndSpouse) : "0.00"),
                ec: plan.finalRates ? replaceUndefinedOrEmptyStr(plan.finalRates.empAndChild) :
                    (plan.monthlyPremium ? replaceUndefinedOrEmptyStr(plan.monthlyPremium.empAndChild) : "0.00"),
                ef: plan.finalRates ? replaceUndefinedOrEmptyStr(plan.finalRates.empAndFamily) :
                    (plan.monthlyPremium ? replaceUndefinedOrEmptyStr(plan.monthlyPremium.empAndFamily) : "0.00"),
            }
            break;
        case 'life':
            finalRates = {
                basicLife: plan.finalRates ? plan.finalRates.basicLifeRateVol : (plan.quote ? plan.quote.basicLifeRateVol : "0.00"),
                total: plan.finalRates ? plan.finalRates.totalLifeRateVol : (plan.quote ? plan.quote.totalLifeRateVol : "0.00")
            };
            break;
        case 'std':
            finalRates = {
                std: plan.finalRates ?
                    plan.finalRates.stdRate ?
                        plan.finalRates.stdRate
                    :
                        plan.finalRates.rateOver10WeeklyBenefit 
                :
                    plan.quote ?
                        plan.quote.stdRate ?
                            plan.quote.stdRate
                        :
                            plan.quote.rateOver10WeeklyBenefit 
                    :
                        "0.00",
            };
            break;
        case 'ltd':
            finalRates = {
                ltd: plan.finalRates ?
                    plan.finalRates.ltdRate ?
                        plan.finalRates.ltdRate
                    :
                        plan.finalRates.timesRatesPer100ofMCP
                :
                    plan.quote ?
                        plan.quote.ltdRate ?
                            plan.quote.ltdRate
                        :
                            plan.quote.timesRatesPer100ofMCP
                    :
                        "0.00",
            };
            break;
        default:
            break;
    }

    return finalRates
};

export const rateAdjustments = (type, plan) => {
    let rateAdjustments;

    switch (type) {
        case 'medical':
        case 'dental':
        case 'vision':
            rateAdjustments = {
                ee: plan.rateAdjustments ? plan.rateAdjustments.adjustments.ee : "0.00",
                es: plan.rateAdjustments ? plan.rateAdjustments.adjustments.es : "0.00",
                ec: plan.rateAdjustments ? plan.rateAdjustments.adjustments.ec : "0.00",
                ef: plan.rateAdjustments ? plan.rateAdjustments.adjustments.ef : "0.00"
            }
            break;
        case 'life':
            rateAdjustments = {
                basicLife: plan.rateAdjustments ? plan?.rateAdjustments?.adjustments?.basicLife : "0.00"
            }
            break;
        case 'std':
            rateAdjustments = {
                std: plan.rateAdjustments ? plan?.rateAdjustments?.adjustments?.std : "0.00"
            }
            break;
        case 'ltd':
            rateAdjustments = {
                ltd: plan.rateAdjustments ? plan?.rateAdjustments?.adjustments?.ltd : "0.00"
            }
            break;
        default:
            break;
    }

    return rateAdjustments
};

export const errors = (type) => {
    let errors;

    switch (type) {
        case 'medical':
        case 'dental':
        case 'vision':
            errors = {
                ee: false,
                es: false,
                ec: false,
                ef: false
            }
            break;
        case 'life':
            errors = {
                basicLife: false
            }
            break;
        case 'std':
            errors = {
                std: false
            }
            break;
        case 'ltd':
            errors = {
                ltd: false
            }
        case 'suppLife':
            errors ={
                suppLife :false
            }
            break;
        default:
            break;
    }

    return errors
};

export const errorMessages = (type) => {
    let errorMessages;

    switch (type) {
        case 'medical':
        case 'dental':
        case 'vision':
            errorMessages = {
                ee: '',
                es: '',
                ec: '',
                ef: ''
            }
            break;
        case 'life':
            errorMessages = {
                basicLife: ''
            }
            break;
        case 'std':
            errorMessages = {
                std: ''
            }
            break;
        case 'ltd':
            errorMessages = {
                ltd: ''
            }
            break;
        default:
            break;
    }

    return errorMessages
};

export const rateAdjustmentRenewals = (type, plan,specialtyCriteria) =>{
    const census = specialtyCriteria[`${type}CensusSummary`];
    const { employee, empAndSpouse, empAndChild, empAndFamily } = plan.monthlyPremium;
    const eeCount = census.employeeOnly !== "" ? parseInt(census.employeeOnly) : 0;
    const esCount = census.employeeWithSpouse !== "" ? parseInt(census.employeeWithSpouse) : 0;
    const ecCount = census.employeeWithChild !== "" ? parseInt(census.employeeWithChild) : 0;
    const efCount = census.employeeWithFamily !== "" ? parseInt(census.employeeWithFamily) : 0;
    const totalMonthlyPremiumRenewals = eeCount * parseFloat(employee) +
        esCount * parseFloat(empAndSpouse) +
        ecCount * parseFloat(empAndChild) +
        efCount * parseFloat(empAndFamily);
    return totalMonthlyPremiumRenewals
}

export const setPlanDetails = (prodType, plan) => {
    let planDetails;
    const contributory = plan?.contributionType;
    const rateGuarantee = plan?.rateGuarantee ? `${plan?.rateGuarantee} Months` : '';
    const weeklyBenefitAmount = plan?.weeklyBenefitAmount;
    const benifitDuration = plan?.benifitDuration;
    const eliminationPeriod = plan?.eliminationPeriod;

    if (prodType === 'std') {
        planDetails = {
            tableId: 'std-plan-details-table',
            headerRow: [
                {
                    text: 'Benefit Details',
                    width: 8,
                    id: 'header-benefit',
                },
                {
                    text: 'Plan Details',
                    width: 8,
                    id: 'header-plan',
                },
            ],
            dataRows: [
                { id: 'std-contribution-type', values: ['Contribution Type', contributory] },
                { id: "std-rate-guarantee", values: ["Rate Guarantee", rateGuarantee] },
                { id: 'std-benifit-amount', values: ['Weekly Benefit Amount', weeklyBenefitAmount] },
                { id: 'std-plan-duration', values: [' Benefit Duration', benifitDuration] },
                { id: 'std-elimination-period-accident-illness', values: ['Elimination Period', eliminationPeriod] },
                { id: 'std-pre-existing-conditions-exclusions', values: ['Pre-Existing Conditions Exclusions', plan.preEx ? plan.preEx : 'None'] },
                { id: 'std-rehabilitation-services', values: ['Rehabilitation Services', plan.rehab] },
                { id: 'std-lump-sum-survivor-benefit', values: ['Lump Sum Survivor Benefit', plan.lumpSum.survivorBen] },
                { id: 'std-coverage-type', values: ['Coverage Type', plan.coverageType] },
                { id: 'std-Maternity', values: ['Maternity', plan.maternity] },
                { id: 'std-Rate Type', values: ['Rate Type', plan.rateType] },
            ],
        };
    } else {
        planDetails = {
            tableId: 'ltd-plan-details-table',
            headerRow: [
                {
                    text: 'Benefit Details',
                    width: 8,
                    id: 'header-benefit',
                },
                {
                    text: 'Plan Details',
                    width: 8,
                    id: 'header-plan',
                },
            ],
            dataRows: [
                { id: 'ltd-plan-duration', values: ['Benefit Duration', benifitDuration] },
                { id: 'ltd-monthly-benefit-amount', values: ['Benefit Amount with Max', weeklyBenefitAmount] },
                { id: "ltd-rate-guarantee", values: ["Rate Guarantee", rateGuarantee] },
                { id: 'ltd-elimination-period-accident-illness', values: ['Elimination Period', eliminationPeriod] },
                { id: 'ltd-definition-disabiltiy', values: ['Definition of Disabiltiy', plan.defOfDis] },
                { id: 'ltd-Contributory-Type', values: ['Contribution Type', contributory] },
                { id: 'ltd-pre-existing-conditions-exclusions', values: ['Pre-Existing Conditions Exclusions', plan.preEx ? plan.preEx : 'None'] },
                { id: 'ltd-recurrent-disability', values: ['Recurrent Disability', plan.recurrent] },
                { id: 'ltd-earnings-test', values: ['Earnings Test', plan.earningTest] },
                { id: 'ltd-offset', values: ['Social Security Offset', plan.ssOffset] },
                { id: 'ltd-rehabilitation-services', values: ['Rehabilitation Services', plan.rehab] },
                { id: 'ltd-lump-sum-survivor-benefit', values: ['Lump Sum Survivor Benefit', plan.lumpSum] },
                { id: 'ltd-transplant-benefit', values: ['Transplant Benefit', plan.transplant] },
                { id: 'ltd-work-benefit', values: ['Work Incentive Benefit', plan.workIncentive] },
                { id: 'ltd-Rate Type', values: ['Rate Type', plan.rateType] },
            ],
        };
    }

    return planDetails
}

export const setCardData = (prodType, plan) => {
    let cardData;
    const contributory = plan?.contributionType;
    const weeklyBenefitAmount = plan?.weeklyBenefitAmount;
    const benifitDuration = plan?.benifitDuration;
    const eliminationPeriod = plan?.eliminationPeriod;
    const { code } = plan;
    const planType = prodType === 'std' ? "Short Term Disability" : "Long Term Disability";
    const finalRateVol = prodType === 'std' ?
        plan?.finalRates?.rateOver10WeeklyBenefit ?
            toMoney(plan?.finalRates?.rateOver10WeeklyBenefit)
        :
            'N/A'
    :
        plan?.finalRates?.timesRatesPer100ofMCP ?
            toMoney(plan?.finalRates?.timesRatesPer100ofMCP)
        :
            'N/A'

    if (prodType === 'std') {
        cardData = {
            planCode: code,
            header: planType,
            header1: "Rate/$10 Weekly Benefit : $0.02",
            subHeader: contributory,
            optionalLine: "",
            sections: [
                {
                    header: "Benefits",
                    description: "",
                    attributes: [
                        {
                            attribute: "Weekly Benefit Amount",
                            value: weeklyBenefitAmount,
                            id: "weekly-benefit-amount",
                        },
                        {
                            attribute: "Benefit Duration",
                            value: benifitDuration,
                            id: "benifit-duration",
                        },
                    ],
                },
                {
                    header: "",
                    description: "",
                    attributes: [
                        {
                            attribute: "Rate/$10 Weekly Benefit",
                            value: finalRateVol,
                            id: "rate-vol",
                        },

                    ]
                },
                {
                    header: "",
                    description: "",
                    attributes: [
                        {
                            attribute: "Elimination Period Accident / Illness",
                            value: eliminationPeriod,
                            id: "elimination-period",
                        },
                    ]
                },
            ],
        };
    } else {
        cardData = {
            planCode: code,
            header: planType,
            subHeader: contributory,
            optionalLine: "",
            sections: [
                {
                    header: "Benefits",
                    description: "",
                    attributes: [
                        {
                            attribute: "Benefit Amount with Max",
                            value: weeklyBenefitAmount,
                            id: "benefit-amount-with-max",
                        },
                        {
                            attribute: "Benefit Duration",
                            value: benifitDuration,
                            id: "benifit-duration",
                        },
                    ],
                },
                {
                    header: "",
                    description: "",
                    attributes: [
                        {
                            attribute: "Rate per $100 MCP",
                            value: finalRateVol,
                            id: "rate-vol",
                        },
                    ]
                },
                {
                    header: "",
                    description: "",
                    attributes: [
                        {
                            attribute: "Elimination Period",
                            value: eliminationPeriod,
                            id: "elimination-period",
                        },
                    ]
                }
            ],
        }
    }
}

export const setComparedCardData = (prodType, plan) => {
    let comparedCardData;
    const contributory = plan?.contributionType;
    const weeklyBenefitAmount = plan?.weeklyBenefitAmount;
    const benifitDuration = plan?.benifitDuration;
    const eliminationPeriod = plan?.eliminationPeriod;
    const rateGuarantee = plan?.rateGuarantee ? `${plan?.rateGuarantee} Months` : '';
    const preEx = plan?.preEx;
    const rehab = plan?.rehab;
    const lumpsum = plan?.lumpSum;
    const coverageType = plan?.coverageType;
    const maternity = plan?.maternity;
    const rateType = plan?.rateType;
    const defOfDis = plan?.defOfDis;
    const recurrent = plan?.recurrent;
    const earningTest = plan?.earningTest;
    const lumpSum = plan?.lumpSum;
    const ssOffset = plan?.ssOffset;
    const transplant = plan?.transplant;
    const workIncentive = plan?.workIncentive;

    if (prodType === 'std') {
        comparedCardData = [
            {
                fieldName: 'Contribution Type',
                text: contributory,
                style: 'section-field',
                id: 'contribution-strategy',
            },
            {
                fieldName: "Weekly Benefit Amount",
                text: weeklyBenefitAmount,
                style: 'section-field',
                id: "weekly-benefit-amount",
            },
            {
                fieldName: "Rate Guarantee",
                text: `${rateGuarantee}`,
                style: "section-field",
                id: "rateGuarantee"
            },
            {
                fieldName: "Benefit Duration",
                text: benifitDuration,
                style: 'section-field',
                id: "benifit-duration",
            },
            {
                fieldName: "Elimination Period",
                text: eliminationPeriod,
                style: 'section-field',
                id: "Elimination-Period",
            },
            {
                fieldName: 'Pre-Existing Conditions Exclusions',
                text: preEx,
                style: 'section-field',
                id: 'Pre-Existing-Conditions-Exclusions',
            },
            {
                fieldName: 'Rehabilitation Services',
                text: rehab,
                style: 'section-field',
                id: 'Rehabilitation-Services',
            },
            {
                fieldName: 'Lump Sum Survivor Benefit',
                text: lumpsum?.survivorBen,
                style: 'section-field',
                id: 'lumpSum',
            },
            {
                fieldName: 'Coverage Type',
                text: coverageType,
                style: 'section-field',
                id: 'Coverage Type',
            },
            {
                fieldName: 'Maternity',
                text: maternity,
                style: 'section-field',
                id: 'Maternity',
            },
            {
                fieldName: 'Rate Type',
                text: rateType,
                style: 'section-field',
                id: 'Rate Type',
            },
        ];
    } else {
        comparedCardData = [
            {
                fieldName: "Benefit Duration",
                text: benifitDuration,
                style: 'section-field',
                id: "benifit-duration",
            },
            {
                fieldName: "Benefit Amount with Max",
                text: weeklyBenefitAmount,
                style: 'section-field',
                id: "benefit-amount-with-max",
            },
            {
                fieldName: "Rate Guarantee",
                text: `${rateGuarantee}`,
                style: "section-field",
                id: "rateGuarantee"
            },
            {
                fieldName: "Elimination Period",
                text: eliminationPeriod,
                style: 'section-field',
                id: "Elimination-Period",
            },
            {
                fieldName: 'Definition of Disability',
                text: defOfDis,
                style: 'section-field',
                id: 'Definition-of-Disability',
            },
            {
                fieldName: 'Contribution Type',
                text: contributory,
                style: 'section-field',
                id: 'contribution-strategy',
            },
            {
                fieldName: 'Pre-Existing Conditions Exclusions',
                text: preEx,
                style: 'section-field',
                id: 'Pre-Existing-Conditions-Exclusions',
            },
            {
                fieldName: 'Recurrent Disability',
                text: recurrent,
                style: 'section-field',
                id: 'Recurrent Disability',
            },
            {
                fieldName: 'Earnings Test',
                text: earningTest,
                style: 'section-field',
                id: 'earning-Test',
            },
            {
                fieldName: 'Social Security Offset',
                text: ssOffset,
                style: 'section-field',
                id: 'Social-Security-Offset',
            },
            {
                fieldName: 'Rehabilitation Services',
                text: rehab,
                style: 'section-field',
                id: 'Rehabilitation-Services',
            },
            {
                fieldName: 'Lump Sum Survivor Benefit',
                text: lumpSum,
                style: 'section-field',
                id: 'lumpSum',
            },
            {
                fieldName: 'Transplant Benefit',
                text: transplant,
                style: 'section-field',
                id: 'Transplant Benefit',
            },
            {
                fieldName: 'Work Incentive Benefit',
                text: workIncentive,
                style: 'section-field',
                id: 'Work Incentive Benefit',
            },
            {
                fieldName: 'Rate Type',
                text: rateType,
                style: 'section-field',
                id: 'Rate Type',
            },
        ];
    }

    return comparedCardData;
}