import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { history } from '../utils/history';
import { createHistory } from './historyAction';
const apiRoutes = require('../utils/api/apiRouteConfig');

export const getPermissions = (permissionNames) => (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: types.REQUEST_PERMISSIONS });
    // for Testing purpose added ,once its deploy it in technotuners ,need tpo remove it
    axios.get(`${apiRoutes.mongo}/permissions?permissions=${permissionNames}`)
        .then((resp) => {
            const permissions = resp.data;
            dispatch(createHistory('Get Permissions from DB', 'Home', 'Success', permissionNames, permissions));
            dispatch({
                type: types.RECEIVE_PERMISSIONS,
                ...permissions,
            });
            resolve();
        })
        .catch((err) => {
            console.log(err);
            dispatch(createHistory('Get Permissions from DB', 'Home', 'Error', permissionNames, err));
            dispatch({ type: types.ERROR_PERMISSIONS });
            reject();
        });
});
