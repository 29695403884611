import { CASE_PROGRESS, isStatusChangeAllowed, getCaseIndexForNB, inSubmittedStatus } from '../reducers/products/helpers/companyProfileConsts';
import apiRoutes, { quickQuotes as serviceLayer } from '../utils/api/apiRouteConfig';
import { axios } from '../utils/api/axiosConfig';
import { getValueOrEmptyString, isUndefinedOrNull, isUndefinedOrNullOrEmpty } from '../utils/validation/commonValidations';
import * as types from './actionTypes';
import { updateCaseProgress, updateState } from './companyProfileActions';
import { resetBreadCrumbAccess } from './fullQuotesActions';
import { createHistory } from './historyAction';
import sendLog from './logActions';
import makeHIOSRequestbody from './makeHIOSRequestbody';
import { isUhcRenewals } from '../utils/businessLogic/platformUtils';
import {paperCaseHistoryLog } from '../actions/overallActions';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { setPlanInstallPlatforms } from './enrollmentActions';
import { reduceStateStorePayload } from '../utils/stateStorePayload/reduce/reducePayload';
import { setDate } from '../utils/formatters/dateFieldHelper';
import deepCopy from '../utils/deepCopy';
import { saveCaseTrackingInStateStore } from '../utils/api/stateStoreUpdate';
import { createFavoritePlanCodesBody, saveFavoritePlanCodes } from '../utils/businessLogic/favoritePlanCodes';


function getSelectedProducts(caseObj) {
    let selectedProducts = '';
    if (caseObj.selectedProducts) {
        if (caseObj.selectedProducts.medical) {
            selectedProducts = selectedProducts.concat('M, ');
        }
        if (caseObj.selectedProducts.dental) {
            selectedProducts = selectedProducts.concat('DE, ');
        }
        if (caseObj.selectedProducts.vision) {
            selectedProducts = selectedProducts.concat('V, ');
        }
        if (caseObj.selectedProducts.life) {
            selectedProducts = selectedProducts.concat('BL, ');
        }
        if (caseObj.selectedProducts.suppLife) {
            selectedProducts = selectedProducts.concat('SU, ');
        }
        if (caseObj.selectedProducts.std) {
            selectedProducts = selectedProducts.concat('STD, ');
        }
        if (caseObj.selectedProducts.ltd) {
            selectedProducts = selectedProducts.concat('LTD, ');
        }
        selectedProducts = selectedProducts.slice(0, -2);
    }
    return selectedProducts;
}

export function setEnrollingQuoteId() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const caseStatusList = [
            CASE_PROGRESS.ENROLLING,
            CASE_PROGRESS.ENROLLED,
            CASE_PROGRESS.PENDACCEPT,
            CASE_PROGRESS.PENDING,
            CASE_PROGRESS.PENDED,
            CASE_PROGRESS.PENDRESOLVED,
            CASE_PROGRESS.REPENDED,
            CASE_PROGRESS.RETURNED,
            CASE_PROGRESS.SUBMITTED,
            CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.SUBMITERRORHW,
            CASE_PROGRESS.INTERNAL_WITHDRAW,
        ];

        if (fullProps && fullProps.caseTracking && fullProps.caseTracking.caseTrack) {
            for (let i = 0; i < fullProps.caseTracking.caseTrack.length; i++) {
                if (caseStatusList.includes(fullProps.caseTracking.caseTrack[i].caseStatus)) {
                    dispatch({
                        type: types.SET_ENROLLMENT_QUOTE_ID,
                        enrollingQuoteId: fullProps.caseTracking.caseTrack[i].quoteTrackingNumber,
                    });
                    break;
                }
            }
        }
    };
}

export function clearEnrollingQuoteId() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const caseStatusList = [CASE_PROGRESS.WITHDRAWN, CASE_PROGRESS.CLOSED, CASE_PROGRESS.REMOVE];
        if (fullProps && fullProps.caseTracking && fullProps.caseTracking.caseTrack) {
            for (let i = 0; i < fullProps.caseTracking.caseTrack.length; i++) {
                if (caseStatusList.includes(fullProps.caseTracking.caseTrack[i].caseStatus)) {
                    if (fullProps.caseTracking.caseTrack[i].quoteTrackingNumber === fullProps.caseTracking.enrollingQuoteId) {
                        dispatch({
                            type: types.SET_ENROLLMENT_QUOTE_ID,
                            enrollingQuoteId: '',
                        });
                    }
                    break;
                }
            }
        }
    };
}

export function clearStateStoreEnrollingId(quoteId = 0) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const fullProps = getState();
        const { caseId } = fullProps.caseTracking;
        axios.put(`${apiRoutes.mongo}/setState/${caseId}/${quoteId}`)
            .then(
                (result) => {
                    resolve(result.data);
                    dispatch({
                        type: types.SET_ENROLLMENT_QUOTE_ID,
                        enrollingQuoteId: quoteId === 0 ? '' : quoteId,
                    });
                },
            ).catch((err) => {
                reject(err);
            });
    });
}

export function saveStateStore(isupdate, isEmployer) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const fullProps = getState();
        dispatch(setEnrollingQuoteId());

        const quotesObj = {
            ...fullProps.quotes,
            isFetchingPlans: false,
            numRateBatchesFetching: 0,
        };

        const dentalPlans = {
            ...fullProps.dental,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const visionPlans = {
            ...fullProps.vision,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const lifePlans = {
            ...fullProps.life,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const dependentLifePlans = {
            ...fullProps.lifeDep,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const stdPlans = {
            ...fullProps.std,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const ltdPlans = {
            ...fullProps.ltd,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const suppLifePlans = {
            ...fullProps.suppLife,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };

        const suppLifeDepPlans = {
            ...fullProps.suppLifeDep,
            allPlans: [],
            filteredResults: [],
            advancefilterSet: [],
            basicFilters: [],
            isFetchingPlans: false,
        };
        
        const proposal = {
            ...fullProps.proposal,
            isGenerating: false,
            isSendingEmail: false,
        };

        const compProfile = {
            ...fullProps.companyProfile,
            activeDates: [],
            isUpdatingProfile: false,
            isImporting: false,
        };

        const caseTrackObj = {
            ...fullProps.caseTracking,
            updatingCaseTrack: false,
        };

        const overallObj = {
            ...fullProps.overall,
        };

        delete overallObj.appConfigDetails;
        const addReqInfo = {
            ...fullProps.additionalReqdInfo,
            isUploadingDocument: false,
            isUploadingToEdms: false,
            uploadEdmsError: false,
            isUploadingToDoc360: false,
            uploadDoc360Error: false,
        };

        if (fullProps.enrollment && fullProps.enrollment.application && fullProps.enrollment.application.producer && !fullProps.enrollment.application.producer.completed) {
            if (fullProps.enrollment.application.producer.generalAgent) {
                if (fullProps.companyProfile && fullProps.companyProfile.employerEnrollment && fullProps.companyProfile.employerEnrollment.producer && fullProps.companyProfile.employerEnrollment.producer.generalAgent) {
                    if (fullProps.overall.platform !== 'PRIME') {
                        fullProps.enrollment.application.producer.generalAgent = fullProps.companyProfile.employerEnrollment.producer.generalAgent;
                    }
                }
            }
        }

        let enrollmentObj = {};
        if (isEmployer && isEmployer === 'employerAttestationLW' && fullProps.enrollment) {
            enrollmentObj = {
                ...fullProps.enrollment,
                erCompletedDate : new Date().toISOString()
            }
        } else {
            enrollmentObj = fullProps.enrollment;
        };

        const stateStoreObj = {
            quotes: quotesObj,
            overall: overallObj,
            dental: dentalPlans,
            vision: visionPlans,
            life: lifePlans,
            dependentLife: dependentLifePlans,
            std: stdPlans,
            ltd: ltdPlans,
            suppLife: suppLifePlans,
            suppLifeDep: suppLifeDepPlans,
            companyProfile: compProfile,
            additionalReqdInfo: addReqInfo,
            specialtyCriteria: fullProps.specialtyCriteria,
            rules: fullProps.rules,
            enrollment: enrollmentObj,
            paymentBilling: fullProps.paymentBilling,
            debitForm: fullProps.debitForm,
            proposal,
            caseTracking: caseTrackObj,
            supportedArea: fullProps.supportedArea,
            reviewCards: fullProps.reviewCards,
            solarisReducer: fullProps.solarisReducer,
            packageSavings: fullProps.packageSavings,
        };

        const updatedStateStore = reduceStateStorePayload(stateStoreObj);

        if (!isupdate) {
            axios.post(
                `${apiRoutes.mongo}/stateStore`,
                JSON.parse(JSON.stringify(updatedStateStore)),
            )
                .then(
                    (result) => {
                        resolve(result.data);
                        dispatch(updateStateStoreId(result.data.insertedState._id));
                    },
                ).catch((err) => {
                    reject(err);
                });
        } else {
            const caseId = fullProps.caseTracking.caseId ? fullProps.caseTracking.caseId : '0';
            let currentQuoteId = fullProps.caseTracking.currentQuoteId ? fullProps.caseTracking.currentQuoteId : '0';

            if (
                fullProps.nbDashboardOnQuoteTrackingNumber.cases[0] != undefined
                && fullProps.nbDashboardOnQuoteTrackingNumber.cases[0].B2BPartner != undefined
                && fullProps.nbDashboardOnQuoteTrackingNumber.cases[0].B2BPartner == 'Y'
            ) {
                currentQuoteId = fullProps.nbDashboardOnQuoteTrackingNumber.cases[0].quoteTrackingNumber;
            }

            if (caseId !== '0') {
                axios.put(
                    `${apiRoutes.mongo}/stateStore/${caseId}/${currentQuoteId}`,
                    JSON.parse(JSON.stringify(updatedStateStore)),
                )
                    .then(
                        (result) => {
                            saveFavoritePlanCodesToUserPrefrence(fullProps);
                            resolve(result.data);
                        },
                    )
                    .catch((err) => {
                        reject(err);
                    });
            }
        }
    });
}

/**
 * Function to create or update case tracking record for the current quote
 * @param {*} lastUpdatedPage name of the last modified page
 * @param {*} caseStatus status of the current case
 * @param {*} idxParam index of the current quote under the case.
 * @param {*} additionalParms Additional param includes the quoteTrackingNumber and source.  These two properties are used for the DES - SAMx Integration.
 */
export function saveOrUpdateCaseTracking(lastUpdatedPage, caseStatus, idxParam = 0, additionalParms = { quoteTrackingNumber: '', source: '', caseId: 0 }, isOwnerPage = false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: true })
        const fullProps = getState();

        const isCopyQuote = fullProps.caseTracking && fullProps.caseTracking.isCopyQuote;
        let isNewQuote = fullProps.caseTracking && fullProps.caseTracking.isNewQuote;

        // DES Integration: Additional Parms CaseId verified to map old caseid on DES quote refresh instead of creating new.
        const caseId = +additionalParms.caseId > 0 ? additionalParms.caseId : (fullProps.caseTracking.caseId ? fullProps.caseTracking.caseId : '');
        const idx = (idxParam === 0 && caseStatus !== CASE_PROGRESS.WITHDRAWN) ? getCaseIndexForNB(fullProps.caseTracking) : idxParam;
        const caseTrackObj = fullProps && fullProps.caseTracking && fullProps.caseTracking.caseTrack;
        const objExistsAtIdx = caseTrackObj && caseTrackObj.length > 0 && caseTrackObj[idx];
        let quoteTrackingNumber = !isUndefinedOrNullOrEmpty(additionalParms.quoteTrackingNumber)
            ? additionalParms.quoteTrackingNumber
            : objExistsAtIdx
                ? caseTrackObj[idx].quoteTrackingNumber
                : '';
        getCaseStatus(caseId, quoteTrackingNumber).then((prevCaseStatus) => {
            const oldCaseStatus = (isCopyQuote || isNewQuote) ? '' : prevCaseStatus;
            const statusChangeAllowed = isStatusChangeAllowed(oldCaseStatus, caseStatus);
            if (statusChangeAllowed) {
                dispatch({
                    type: types.CASE_TRACK_UPDATING,
                });
                dispatch(setEnrollingQuoteId());

                const proposalId = objExistsAtIdx ? caseTrackObj[idx].proposalId : '';
                const proposalQuotes = objExistsAtIdx ? caseTrackObj[idx].quotes : [];

                let source = !isUndefinedOrNullOrEmpty(additionalParms.source)
                    ? additionalParms.source
                    : objExistsAtIdx
                        ? caseTrackObj[idx].source
                        : '';

                const createdDate = objExistsAtIdx ? caseTrackObj[idx].createdDate : '';

                const swRateId = objExistsAtIdx ? caseTrackObj[idx].swRateId : '';
                const caseObjId = objExistsAtIdx ? caseTrackObj[idx]._id : '';

                const needsProposalId = fullProps.solarisReducer.shouldGenerateId;

                const caseOwners = fillCaseOwnersData(fullProps);

                if (lastUpdatedPage === '') {
                    lastUpdatedPage = caseTrackObj[idx] && caseTrackObj[idx].lastUpdatedPage ? caseTrackObj[idx].lastUpdatedPage : '';
                }

                let shoppingId = getState().caseTracking.stateStoreId;

                if (fullProps.caseTracking && (isNewQuote || isCopyQuote)) {
                    quoteTrackingNumber = '';
                    caseStatus = CASE_PROGRESS.QUOTING;
                    // Copy quote is a SAMx funcatinality.  Hence, reseting the source to ''.  So that, SAMx source type will be considered.
                    if (isCopyQuote) {
                        source = '';
                    }
                }
                // DES Integration: Additional Parms CaseId verified to create a new case tracking record with the caseid present in the additional params.
                if (+additionalParms.caseId > 0) {
                    isNewQuote = true;
                }
                const selectedProducts = getSelectedProducts(fullProps.companyProfile);
                if (
                    shoppingId === ''
                    || (fullProps.caseTracking
                        && (fullProps.caseTracking.isRemoveQuote || fullProps.caseTracking.isWithdrawCase || fullProps.caseTracking.isCloseCase))
                ) {
                    if (caseTrackObj && caseTrackObj[idx] && caseTrackObj[idx].shoppingId) {
                        shoppingId = caseTrackObj[idx].shoppingId; // need to change index based on the quotetracking number
                    }
                }
                let RlNbCaseMedUpsel = false;
                let renewalCaseId = '';
                let splSubmissionDate = '';
                if (fullProps.overall.medUpsel === true) {
                    if (fullProps.caseTracking && fullProps.caseTracking.caseTrack && fullProps.caseTracking.caseTrack[0] && fullProps.caseTracking.caseTrack[0].caseId && fullProps.caseTracking.caseTrack[0].RlNbCaseMedUpsel) {
                        RlNbCaseMedUpsel = true;
                        renewalCaseId = fullProps.caseTracking.caseTrack[0].renewalCaseId;
                        splSubmissionDate = fullProps.caseTracking.caseTrack[0].splSubmissionDate;
                    } else {
                        RlNbCaseMedUpsel = true;
                        renewalCaseId = fullProps.renewalEnrollment.memberGroupId;
                        splSubmissionDate = fullProps.renewalEnrollment.renewalCaseData.submittedDate;
                    }
                }

                // dualStatus
                const { enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans } = fullProps.rules.enrollment;
                const flatMedicalPlans = (Object.keys(enrolledPlans).length > 0) ? Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat() : [];
                const allEnrolledPlans = flatMedicalPlans.concat(enrolledDentalPlans).concat(enrolledVisionPlans).concat(enrolledLifePlans);
                let planPlatforms = [];
                if (fullProps.caseTracking.platform === "DUAL") {
                for (let plan of allEnrolledPlans){
                        if(plan.installPlatform !== null && plan.installPlatform !== undefined){
                            if (!planPlatforms.includes(plan.installPlatform)){
                                planPlatforms.push(plan.installPlatform)
                            }
                        }
                        else{
                            if (fullProps.caseTracking.platform === "DUAL") {
                                // For DUAL, should be CA DUAL scenarios, we want to evaluate if planPlatforms includes plan.platform (at level of each plan)
                                if (!planPlatforms.includes(plan.platform)) {
                                    planPlatforms.push(plan.platform);
                                }
                            } 
                            else {
                                // For non-dual, planPlatform should only include caseTracking platform
                                planPlatforms.push(fullProps.caseTracking.platform);
                            }
                        } 
                    }
                }else{
                    planPlatforms.push(fullProps.caseTracking.platform);
                }
                let dualStatus = "";
                if (planPlatforms.includes("NICE") && fullProps.finalizeCase.finalizeCaseSummary.summaryData) {
                    const niceCaseAcceptedStage = fullProps.finalizeCase.finalizeCaseSummary.summaryData.find((data) => data.caseStage === 'NCA');
                    if (niceCaseAcceptedStage.stageStatus === "COMPLETED") {
                        dualStatus = "complete";
                    } else {
                        dualStatus = "incomplete";
                    }
                } else {
                    dualStatus = "incomplete";
                }

                const caseObject = {
                    caseId: `${caseId}`,
                    quoteTrackingNumber,
                    swRateId,
                    renewalCaseId,
                    renewalQuoteId: '',
                    proposalId,
                    businessType: 'NB',
                    source: source === '' ? 'BNE' : source,
                    userId: fullProps.renewals.logInData[0].optumId,
                    pcis: fullProps.renewals.val,
                    lastModifiedBy: fullProps.user.userName,
                    createdDate,
                    lastUpdatedDate: '',
                    lastUpdatedPage,
                    caseStatus,
                    shoppingId,
                    selectedProducts,
                    caseData: {},
                    quotes: proposalQuotes,
                    needsProposalId,
                    caseOwners,
                    platform: fullProps.caseTracking.platform,
                    casePendRep: additionalParms.pendValue,
                    pendingAcceptanceReason: (caseStatus === CASE_PROGRESS.ACCEPTED) ? '' : (fullProps.companyProfile.pendingAcceptanceReason || ''),
                    isPaperCase: fullProps.overall.isPaperCase || false,
                    RlNbCaseMedUpsel,
                    splSubmissionDate,
                    isDualPlatform: fullProps.caseTracking.platform === "DUAL",
                    dualStatus: dualStatus
                };
                // Add new fields while saving to caseTracking.
                // This will improve performace in NB Dashboard as this will prevent join from fullShopping collection also reducing dpendency on fullShopping.
                addFieldsToCaseObject(caseObject, fullProps);
                // Set createdBy if this is the first time we are saving caseTracking info (new case)
                if (!objExistsAtIdx || isCopyQuote) {
                    caseObject.createdBy = fullProps.user.userName;
                }
                // Only update these values if we're moving to a new status 
                if (oldCaseStatus !== caseStatus) {
                    if (caseStatus === CASE_PROGRESS.PENDING || caseStatus ===  CASE_PROGRESS.SUBMITTED || caseStatus === CASE_PROGRESS.PENDACCEPT) {
                        caseObject.submittedDate = new Date().toISOString();
                        caseObject.submitterName = fullProps.user.userName;
                        caseObject.submitterRole = fullProps.renewals.roleName;
                        caseObject.submitterId = fullProps.renewals.logInData[0].optumId;
                    } else if (caseStatus === CASE_PROGRESS.ACCEPTED) {
                        caseObject.acceptedDate = new Date().toISOString();
                    }
                }
                if (isCopyQuote || isNewQuote) {
                    caseObject.submittedDate = '';
                    caseObject.submitterName = '';
                    caseObject.submitterRole = '';
                    caseObject.submitterId = '';
                }

                if (caseId !== '' && caseTrackObj && !isNewQuote && !isCopyQuote) {
                    axios.put(`${apiRoutes.mongo}/caseTrack/${caseObjId}`, caseObject)
                        .then(
                            async (result) => {
                                dispatch({
                                    type: types.UPDATE_SAVE_STORE_VALUE,
                                    value: false,
                                });

                                let caseTrack = result.data.updatedCaseTrack[idx];
                                if (needsProposalId) {
                                    const quotes = caseTrack.quotes.concat(caseTrack.proposalId);
                                    caseTrack = {
                                        ...caseTrack,
                                        quotes,
                                    };
                                }
                                const updatedCaseTracking = {
                                    ...fullProps.caseTracking,
                                    caseId: result.data.updatedCaseTrack[0].caseId,
                                    caseTrack: result.data.updatedCaseTrack,
                                }
                                dispatch({
                                    type: types.UPDATED_CASE_TRACK,
                                    caseTracking: updatedCaseTracking,
                                });
                                dispatch({
                                    type: types.SET_CURRENT_QUOTE_ID,
                                    currentQuoteId: quoteTrackingNumber,
                                });
                                if (!inSubmittedStatus(caseStatus)) {
                                    await dispatch(saveStateStore(true));
                                } else {
                                    if (lastUpdatedPage !== 'FinalizeCase') {
                                        const { companyProfile, caseTracking: { caseId, currentQuoteId } } = getState();
                                        const updatedCompanyProfile = deepCopy(companyProfile);
                                        updatedCompanyProfile['caseProgress'] = caseStatus;
                                        dispatch(updateState(updatedCompanyProfile));
                                        updatedCaseTracking['updatingCaseTrack'] = false;
                                        await saveCaseTrackingInStateStore(updatedCaseTracking, caseId, currentQuoteId);
                                    }
                                }
                                // set enrolling QuoteId
                                const caseStatusList = [
                                    CASE_PROGRESS.ENROLLING, CASE_PROGRESS.ENROLLED, 
                                    CASE_PROGRESS.PENDACCEPT, CASE_PROGRESS.PENDING, 
                                    CASE_PROGRESS.PENDED, CASE_PROGRESS.PENDRESOLVED, 
                                    CASE_PROGRESS.REPENDED, CASE_PROGRESS.RETURNED, 
                                    CASE_PROGRESS.SUBMITTED, CASE_PROGRESS.ACCEPTED, 
                                    CASE_PROGRESS.SUBMITERRORHW];
                                if (caseStatusList.includes(oldCaseStatus) && (fullProps.caseTracking && fullProps.caseTracking.enrollingQuoteId === '')) {
                                    dispatch(clearStateStoreEnrollingId(quoteTrackingNumber));
                                }
                                dispatch({
                                    type: types.UPDATE_SAVE_STORE_VALUE,
                                    value: true,
                                });
                                dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
                                resolve(result.data.updatedCaseTrack);
                            },
                        )
                        .catch((err) => {
                            console.log('Error', err);
                            dispatch({
                                type: types.CASE_TRACK_UPDATE_ERRORED,
                                error: err,
                            });
                            dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
                            reject();
                        });
                } else {
                    if (isCopyQuote || isNewQuote) {
                        // Add extra field in caseObj if it is a new quote or copy quote case to bring all such cases in the new bussiness dashboard query
                        caseObject.isMultiQuote = true;
                        // Update old case with with isMultiQuote Flag
                        updateIsMultiQuoteFlag(fullProps.caseTracking);
                    }

                    axios
                        .post(`${apiRoutes.mongo}/caseTrack`, caseObject)

                        .then(async (result) => {
                            dispatch({
                                type: types.UPDATED_CASE_TRACK,
                                caseTracking: {
                                    ...fullProps.caseTracking,
                                    caseId: result.data.insertedCaseTrack[0].caseId,
                                    caseTrack: result.data.insertedCaseTrack,
                                },
                            });
                            dispatch(paperCaseHistoryLog(fullProps.overall.isPaperCase,'Company Information'));

                            const currentQuote = result.data.currentQuoteTrackingNumber;
                            dispatch({
                                type: types.SET_CURRENT_QUOTE_ID,
                                currentQuoteId: currentQuote,
                            });

                            if (isNewQuote) {
                                dispatch({
                                    type: types.RECEIVE_NB_NEW_FULL_QUOTE,
                                });
                            }
                            // Don't remove this check, it will create multiple quotes on next click during shopping
                            if (isCopyQuote) {
                                dispatch({
                                    type: types.RECEIVE_NB_NEW_COPY_QUOTE,
                                });
                            }
                            await dispatch(saveStateStore(false));
                            if (lastUpdatedPage === 'companyProfile' && caseStatus === 'Quoting') {
                                dispatch(createHistory('Save Company Profile', 'Company Profile', 'Success', fullProps.companyProfile, fullProps.companyProfile, 'NB'));
                            }
                            dispatch({
                                type: types.UPDATE_SAVE_STORE_VALUE,
                                value: true,
                            });
                            dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
                            resolve(result.data.insertedCaseTrack);
                        })
                        .catch((err) => {
                            console.log('Error', err);
                            dispatch({
                                type: types.CASE_TRACK_UPDATE_ERRORED,
                                error: err,
                            });
                            dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
                            reject();
                        });
                }
            } else {
                dispatch(updateCaseProgress(oldCaseStatus, 'NB'));
                console.error('Status Mismatch - CaseTrackAction');
                console.error(oldCaseStatus, caseStatus);
                dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
            }
        })
            .catch((err) => {
                console.log('Error getting caseStatus value', err);
                dispatch({
                    type: types.CASE_TRACK_UPDATE_ERRORED,
                    error: err,
                });
                dispatch({ type: types.DISABLE_SAVE_AND_EXIT, payload: false })
                reject();
            });
    });
}

export const saveOrUpdateRLCaseTracking = (lastUpdatedPage, caseStatus, idx = 0, isPlanChange = false, renewalsCaseId = '') => async (dispatch, getState) => {
    dispatch(createHistory('Case Status Update', lastUpdatedPage, 'Success', caseStatus, {}, 'RL'));
    const fullProps = getState();
    dispatch({
        type: types.CASE_TRACK_UPDATING,
    });
    dispatch(setEnrollingQuoteId());
    const { caseTracking } = fullProps;

    const caseId = getValueOrEmptyString(caseTracking.caseId);
    const caseTrackObj = caseTracking && caseTracking.caseTrack && caseTracking.caseTrack.length > 0 && caseTracking.caseTrack[idx];

    let quoteTrackingNumber = '';
    let createdDate = '';
    let caseObjId = '';

    if (caseTrackObj) {
        quoteTrackingNumber = caseTrackObj.quoteTrackingNumber;
        createdDate = caseTrackObj.createdDate;
        caseObjId = caseTrackObj._id;
    }

    if (lastUpdatedPage === '') {
        lastUpdatedPage = caseTrackObj[idx].lastUpdatedPage;
    }

    let shoppingId = fullProps.companyProfile._id;

    if (shoppingId === null || (caseTracking && (caseTracking.isRemoveQuote || caseTracking.isWithdrawCase || caseTracking.isCloseCase))) {
        if (caseTrackObj && caseTrackObj.shoppingId) {
            shoppingId = caseTrackObj.shoppingId;
        }
    }

    const caseDetails = fullProps.review.val;
    const { userName } = fullProps.user;

    const caseObject = {
        caseId: `${getValueOrEmptyString(renewalsCaseId) !== '' ? renewalsCaseId : caseId}`,
        quoteTrackingNumber,
        swRateId: '',
        renewalCaseId: caseDetails.customerNumber,
        renewalQuoteId: caseDetails.renewalQuoteId,
        businessType: fullProps.overall.source === 'mmr' ? 'MMR' : 'RL',
        source: 'BNE',
        userId: fullProps.renewals.logInData[0].optumId,
        pcis: fullProps.renewals.val,
        createdBy: userName,
        lastModifiedBy: userName,
        createdDate,
        lastUpdatedDate: '',
        lastUpdatedPage,
        caseStatus,
        shoppingId,
        caseData: {},
        quotes: [],
        platform: fullProps.caseTracking.receivedPlatformFromIPPS ? fullProps.caseTracking.platform : 
            isUhcRenewals() ? fullProps?.renewals?.renewalCaseData?.uhcPlatformCode === 'P' ? 'PRIME' : 'CIRRUS' : 'CIRRUS',
        isManualInstallation: fullProps.caseTracking.isManualInstallation,
        receivedPlatformFromIPPS: fullProps.caseTracking.receivedPlatformFromIPPS,
        submitterRole: fullProps?.renewals?.roleName
    };

    //Add plan change flag
    if(isPlanChange){
        caseObject.planChange = true;
    }else{
        caseObject.planChange = fullProps.caseTracking && fullProps.caseTracking.caseTrack && fullProps.caseTracking.caseTrack.length>0 && fullProps.caseTracking.caseTrack[0].planChange?fullProps.caseTracking.caseTrack[0].planChange:false;
    }

    const updateCaseTrackAndCurrentQuoteID = (currentCaseTrack) => {
        const updatedCaseTracking = {
            ...caseTracking,
            caseId: currentCaseTrack.caseId,
            caseTrack: [currentCaseTrack],
            currentQuoteId: quoteTrackingNumber,
        };

        dispatch({
            type: types.UPDATED_CASE_TRACK,
            caseTracking: updatedCaseTracking,
        });

        dispatch({
            type: types.SET_CURRENT_QUOTE_ID,
            currentQuoteId: currentCaseTrack.quoteTrackingNumber,
        });

        return caseTracking;
    };

    try {
        if (caseId !== '' && caseTrackObj) {
            const apiResponse = await axios.put(`${apiRoutes.mongo}/caseTrack/${caseObjId}`, caseObject);
            let updatedCaseTrack;
            if(isUhcRenewals()) {
                updatedCaseTrack = apiResponse.data.updatedCaseTrack.filter((item) => item.quoteTrackingNumber === quoteTrackingNumber)[0];
            } else {
                updatedCaseTrack = apiResponse.data.updatedCaseTrack[idx];
            }
            dispatch(createHistory('Renewal - Update Casetracking record', lastUpdatedPage, 'Success', caseObject, apiResponse, caseObject.businessType));
            const currentCaseTracking = updateCaseTrackAndCurrentQuoteID(updatedCaseTrack);
            return currentCaseTracking;
        } if (caseId === '') {
            const apiResponse = await axios.post(`${apiRoutes.mongo}/caseTrack`, caseObject);
            const insertedCaseTrack = apiResponse.data.insertedCaseTrack.sort((a, b) => a.caseId - b.caseId).reverse()[0];
            dispatch(createHistory('Renewal - Create Casetracking record', lastUpdatedPage, 'Success', caseObject, apiResponse, caseObject.businessType));
            const currentCaseTracking = updateCaseTrackAndCurrentQuoteID(insertedCaseTrack);
            if(fullProps.overall.source === 'mmr'){
                return currentCaseTracking
            } else {
                return insertedCaseTrack
            }
        }

        throw new Error('Invalid Request to Save or Update the CaseTracking');
    } catch (err) {
        console.log('Error', err);
        if(isUhcRenewals()){
            logError(KEY_NAME, UHC_REN_APIS.CASE_TRACK, 'caseTrackAction.saveOrUpdateRLCaseTracking', caseObject, err);
        } 
        dispatch({
            type: types.CASE_TRACK_UPDATE_ERRORED,
            error: err,
        });
        throw err;
    }
};

export const saveOrUpdateRLCaseTrackingMMR = (lastUpdatedPage, caseStatus, idx = 0, memberGroupId, renewalsCaseId = '') => async (dispatch, getState) => {
    dispatch(createHistory('Case Status Update', lastUpdatedPage, 'Success', caseStatus, {}, 'RL'));
    const fullProps = getState();
    dispatch({
        type: types.CASE_TRACK_UPDATING,
    });
    dispatch(setEnrollingQuoteId());
    const { caseTracking } = fullProps;

    const caseId = getValueOrEmptyString(caseTracking.caseId);
    const caseTrackObj = caseTracking && caseTracking.caseTrack && caseTracking.caseTrack.length > 0 && caseTracking.caseTrack[idx];

    let quoteTrackingNumber = '';
    let createdDate = '';
    let caseObjId = '';

    if (caseTrackObj) {
        quoteTrackingNumber = caseTrackObj.quoteTrackingNumber;
        createdDate = caseTrackObj.createdDate;
        caseObjId = caseTrackObj._id;
    }

    if (lastUpdatedPage === '') {
        lastUpdatedPage = caseTrackObj[idx].lastUpdatedPage;
    }

    let shoppingId = fullProps.companyProfile._id;

    if (shoppingId === null || (caseTracking && (caseTracking.isRemoveQuote || caseTracking.isWithdrawCase || caseTracking.isCloseCase))) {
        if (caseTrackObj && caseTrackObj.shoppingId) {
            shoppingId = caseTrackObj.shoppingId;
        }
    }

    const caseDetails = fullProps.review.val;
    const { userName } = fullProps.user;

    const caseObject = {
        caseId: `${getValueOrEmptyString(renewalsCaseId) !== '' ? renewalsCaseId : getValueOrEmptyString(caseId) !== '' ? caseId : ''}`,
        quoteTrackingNumber,
        swRateId: '',
        renewalCaseId: memberGroupId,
        renewalQuoteId: '',
        businessType: 'MMR',
        source: 'BNE',
        userId: fullProps.renewals.logInData[0].optumId,
        pcis: fullProps.renewals.val,
        createdBy: userName,
        lastModifiedBy: userName,
        createdDate,
        lastUpdatedDate: '',
        lastUpdatedPage,
        caseStatus,
        shoppingId,
        caseData: {},
        quotes: [],
        platform: 'CIRRUS',
        isManualInstallation: false,
        receivedPlatformFromIPPS: fullProps.caseTracking.receivedPlatformFromIPPS,
        submitterRole: fullProps?.renewals?.roleName
    };

    //Add plan change flag
    caseObject.planChange = false;

    const updateCaseTrackAndCurrentQuoteID = (currentCaseTrack) => {
        const updatedCaseTracking = {
            ...caseTracking,
            caseId: currentCaseTrack.caseId,
            caseTrack: [currentCaseTrack],
            currentQuoteId: currentCaseTrack.quoteTrackingNumber,
        };

        dispatch({
            type: types.UPDATED_CASE_TRACK,
            caseTracking: updatedCaseTracking,
        });

        dispatch({
            type: types.SET_CURRENT_QUOTE_ID,
            currentQuoteId: currentCaseTrack.quoteTrackingNumber,
        });

        return updatedCaseTracking;
    };

    try {

        if (caseId !== '') {
            const apiResponse = await axios.put(`${apiRoutes.mongo}/caseTrack/${caseObjId}`, caseObject);
            let updatedCaseTrack;
            if(isUhcRenewals()) {
                updatedCaseTrack = apiResponse.data.updatedCaseTrack.filter((item) => item.quoteTrackingNumber === quoteTrackingNumber)[0];
            } else {
                updatedCaseTrack = apiResponse.data.updatedCaseTrack[idx];
            }
            dispatch(createHistory('Renewal - Update Casetracking record', lastUpdatedPage, 'Success', caseObject, apiResponse, caseObject.businessType));
            const currentCaseTracking = updateCaseTrackAndCurrentQuoteID(updatedCaseTrack);
            return currentCaseTracking;
        } if (caseId === '') {
            const apiResponse = await axios.post(`${apiRoutes.mongo}/caseTrack`, caseObject);
            const insertedCaseTrack = apiResponse.data.insertedCaseTrack.sort((a, b) => a.caseId - b.caseId).reverse()[0];
            dispatch(createHistory('Renewal - Create Casetracking record', lastUpdatedPage, 'Success', caseObject, apiResponse, caseObject.businessType));
            const currentCaseTracking = updateCaseTrackAndCurrentQuoteID(insertedCaseTrack);
            return currentCaseTracking
        }
    } catch (err) {
        console.log('Error', err);
        if(isUhcRenewals()){
            logError(KEY_NAME, UHC_REN_APIS.CASE_TRACK, 'caseTrackAction.saveOrUpdateRLCaseTracking', caseObject, err);
        } 
        dispatch({
            type: types.CASE_TRACK_UPDATE_ERRORED,
            error: err,
        });
        throw err;
    }
};

export const getRLCaseTracking = (caseId, additionalParams, isNewCase = false, hasClickedViewMmr = false) => async (dispatch, getState) => {
    dispatch({ type: types.RESET_RL_CASE_TRACK });
    const fullProps = getState();
    const isMMRDashboard = fullProps.overall.source === 'mmr'
    try {
        const { value: reqParamLastPageUpdated, quoteTrackingNumber: reqParamQuoteTrackingNumber } = !isUndefinedOrNull(additionalParams) && additionalParams;
        // US4164077: Prior Year Renewals: UI Dashboard Changes
        const query = {
            priorYearData: additionalParams && additionalParams.priorYearData ? additionalParams.priorYearData: 'N',
            quoteTrackingNumber: additionalParams  && additionalParams.quoteTrackingNumber ? additionalParams.quoteTrackingNumber: '1',
        };
        const priorYearDataSelected = fullProps?.renewals?.priorYearDataSelected;
        const effectiveDate = additionalParams?.effectiveDate || fullProps?.renewals?.renewalCaseData?.effectiveDate;
        if(!isNewCase) {
            const caseTrackingResponse = fullProps.overall.platform!=="PRIME"? await axios.get(`${apiRoutes.mongo}/caseTrack/case/${query.priorYearData}/${query.quoteTrackingNumber}/${caseId}`)
            : await axios.get(`${apiRoutes.mongo}/uhcRenewalDb/caseTrack/case/${isMMRDashboard}/${caseId}/${priorYearDataSelected}/${effectiveDate}`);
            
            const rlCaseDataResponse = await axios.get(`${apiRoutes.mongo}/uhcRenewalDb/getRLCaseData/case/${isMMRDashboard}/${caseId}/${priorYearDataSelected}/${effectiveDate}`)
            const currentCaseTrackingData = caseTrackingResponse.data;
            dispatch(createHistory('Renewal - Get Casetracking record', 'Casetrack', 'Success', caseId, currentCaseTrackingData, fullProps.overall.source === 'mmr' ? 'MMR' : 'RL'));
            if (currentCaseTrackingData && currentCaseTrackingData.length > 0) {
                const filterCaseTrack = currentCaseTrackingData.find((caseTrackRec) => caseTrackRec.quoteTrackingNumber === reqParamQuoteTrackingNumber);
                const currCaseTrackingData = rlCaseDataResponse.data[0] ? currentCaseTrackingData.find(currCaseTrackRec => rlCaseDataResponse.data[0].quoteTrackingNumber === currCaseTrackRec.quoteTrackingNumber ) : null
                const currentCaseTrack = !isUndefinedOrNull(filterCaseTrack) ? filterCaseTrack : !isUndefinedOrNull(currCaseTrackingData) ? currCaseTrackingData : currentCaseTrackingData[0];
                const { caseId: currentCaseId, quoteTrackingNumber } = currentCaseTrack;

                const caseTracking = {
                    ...fullProps.caseTracking,
                    caseId: currentCaseId,
                    caseTrack: [currentCaseTrack],
                    currentQuoteId: quoteTrackingNumber,
                    platform: currentCaseTrack.platform,
                    isManualInstallation: currentCaseTrack.isManualInstallation || false,
                    receivedPlatformFromIPPS: currentCaseTrack.receivedPlatformFromIPPS || false,
                };

                dispatch({
                    type: types.GET_CASE_TRACK,
                    caseTracking,
                    isUhcRenewals: isUhcRenewals()
                });

                dispatch({
                    type: types.SET_CURRENT_QUOTE_ID,
                    currentQuoteId: quoteTrackingNumber,
                });

                if(isMMRDashboard && hasClickedViewMmr){
                    let res = await axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/updateCaseDataUhcModel?memberGroupId=${currentCaseTrack.renewalCaseId}`, currentCaseTrack)
                    .then((response) => {
                        return response
                    })
                    .catch((err) => {
                        return err
                    });
                    return res;
                }

                return caseTracking;
            }
        }
        const lastPageUpdated = !isUndefinedOrNullOrEmpty(reqParamLastPageUpdated) ? reqParamLastPageUpdated : 'Review';
        const renewalsCaseId = additionalParams && additionalParams.caseId ? additionalParams.caseId : ''
        let caseTracking;
        if(isNewCase && hasClickedViewMmr) {
            caseTracking = await dispatch(saveOrUpdateRLCaseTracking(lastPageUpdated, 'inProgress', 0, false, ''));
        }
        else {
            caseTracking = dispatch(saveOrUpdateRLCaseTracking(lastPageUpdated, 'inProgress', 0, false, renewalsCaseId));
        }
        if(isMMRDashboard && hasClickedViewMmr){
            await axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/updateCaseDataUhcModel`, caseTracking)
            .then((response) => {
                return caseTracking;
            })
            .catch((err) => {
                return caseTracking;
            });
        }

        return caseTracking;
    } catch (err) {
        if(isUhcRenewals()) {
            const reqBody = {
                isMMRDashboard, caseId
            }
            logError(KEY_NAME, UHC_REN_APIS.CASE_TRACK, 'caseTrackAction.getRLCaseTracking', reqBody, err);
        }
        throw new Error('Error Occured in getRLCaseTracking', err);
    }
};

export const resetRLCaseTrack = () => (dispatch, getState) => new Promise((resolve, reject) => {
    dispatch({ type: types.RESET_RL_CASE_TRACK });
    resolve('Successfully Completed Case Track Reset');
});

export const getNBCaseTracking = (caseId) => async (dispatch, getState) => {
    try {
        const fullProps = getState();
        const apiResult = await axios.get(`${apiRoutes.mongo}/caseTrack/casebyNBCaseId/${caseId}`);
        const apiResponse = apiResult.data;

        if (apiResponse && apiResponse.length > 0) {
            dispatch({
                type: types.GET_CASE_TRACK,
                caseTracking: {
                    ...fullProps.caseTracking,
                    caseId: apiResponse[0].caseId,
                    caseTrack: apiResponse,
                },
            });
        }
        return apiResponse;
    } catch (err) {
        console.log('Error', err);
    }
};

export function updateNBDashboardActivity(dropdownAction) {
    return (dispatch) => {
        switch (dropdownAction) {
            case 'REQUEST_NB_NEW_FULL_QUOTE':
                dispatch({
                    type: types.REQUEST_NB_NEW_FULL_QUOTE,
                });
                dispatch(resetBreadCrumbAccess());
                break;
            case 'RECEIVE_NB_NEW_FULL_QUOTE':
                dispatch({
                    type: types.RECEIVE_NB_NEW_FULL_QUOTE,
                });
                break;
            case 'REQUEST_NB_NEW_COPY_QUOTE':
                dispatch({
                    type: types.REQUEST_NB_NEW_COPY_QUOTE,
                });
                break;
            case 'RECEIVE_NB_NEW_COPY_QUOTE':
                dispatch({
                    type: types.RECEIVE_NB_NEW_COPY_QUOTE,
                });
                break;
            case 'REQUEST_NB_REMOVE_FULL_QUOTE':
                dispatch({
                    type: types.REQUEST_NB_REMOVE_FULL_QUOTE,
                });
                break;
            case 'RECEIVE_NB_REMOVE_FULL_QUOTE':
                dispatch({
                    type: types.RECEIVE_NB_REMOVE_FULL_QUOTE,
                });
                break;
            case 'REQUEST_NB_WITHDRAW_CASE':
                dispatch({
                    type: types.REQUEST_NB_WITHDRAW_CASE,
                });
                break;
            case 'RECEIVE_NB_WITHDRAW_CASE':
                dispatch({
                    type: types.RECEIVE_NB_WITHDRAW_CASE,
                });
                break;
            case 'REQUEST_NB_CLOSE_CASE':
                dispatch({
                    type: types.REQUEST_NB_CLOSE_CASE,
                });
                break;
            case 'RECEIVE_NB_CLOSE_CASE':
                dispatch({
                    type: types.RECEIVE_NB_CLOSE_CASE,
                });
                break;
            case 'SET_CONTINUED_FROM_QQ_CASE':
                dispatch({
                    type: types.SET_CONTINUED_FROM_QQ_CASE,
                });
                break;
            case 'REQUEST_NB_NEW_CASE':
                dispatch({
                    type: types.REQUEST_NB_NEW_CASE,
                });
                break;
            case 'RECEIVE_NB_NEW_CASE':
                dispatch({
                    type: types.RECEIVE_NB_NEW_CASE,
                });
                break;
            case 'UPDATE_COMP_PROFILE_WITHDRAWN':
                dispatch({
                    type: types.UPDATE_QUOTE_STATUS,
                    quoteStatus: 'Withdrawn',
                });
                break;
            case 'UPDATE_COMP_PROFILE_CLOSED':
                dispatch({
                    type: types.UPDATE_QUOTE_STATUS,
                    quoteStatus: 'Closed',
                });
                break;
            case 'UPDATE_COMP_PROFILE_REMOVED':
                dispatch({
                    type: types.UPDATE_QUOTE_STATUS,
                    quoteStatus: 'Deleted',
                });
                break;
            default:
                break;
        }
    };
}
export function setCurrentQuoteId(quoteId) {
    return (dispatch) => {
        dispatch({
            type: types.SET_CURRENT_QUOTE_ID,
            currentQuoteId: quoteId,
        });
    };
}

export function getEnrollingCaseCount(brokerId, platform) {
    return (dispatch) => new Promise((resolve, reject) => {
        axios
            .get(`${apiRoutes.quickQuotes}/get-enrolling-cases-count/${platform}/${brokerId}`)
            .then((res) => {
                dispatch({
                    type: types.GET_NB_ENROLL_CASE_COUNT,
                    payload: res.data,
                });
                resolve(res.data);
            })
            .catch((err) => {
                sendLog({
                    action: 'ERROR_GET_ENROLLING_CASES_COUNT',
                });
                reject(err);
            });
    });
}
export function getEnrollingCases(brokerId, platform) {
    return (dispatch) => new Promise((resolve, reject) => {
        axios
            .get(`${apiRoutes.quickQuotes}/get-enrolling-cases/${platform}/${brokerId}`)
            .then((res) => {
                dispatch({
                    type: types.GET_NB_ENROLL_CASE,
                    payload: res.data,
                });
                resolve(res.data);
            })
            .catch((err) => {
                sendLog({
                    action: 'ERROR_GET_NB_ENROLL_CASE',
                });
                reject(err);
            });
    });
}
export function getRLNotCompletedCases(brokerId) {
    return (dispatch) => new Promise((resolve, reject) => {
        axios
            .get(`${apiRoutes.quickQuotes}/get-rl-notcompleted-cases/${brokerId}`)
            .then((res) => {
                dispatch({
                    type: types.GET_RL_NOT_COMPLETED_CASE,
                    payload: res.data,
                });
                dispatch({
                    type: types.GET_RL_NOT_COMPLETED_CASE_COUNT,
                    payload: res.data ? res.data.length : 0,
                });
                resolve(res.data);
            })
            .catch((err) => {
                sendLog({
                    action: 'ERROR_GET_RL_NOT_COMPLETED_CASE',
                });
                reject(err);
            });
    });
}
export function setUpdatedPlans(denPlans, vsnPlans, medPlans, lifePlans, lifeDepPlans, effDate) {
    return (dispatch, getState) => {
        // Update selected plans on the basis of plan code selected on copy quote popup
        dispatch({ type: types.UPDATE_SELECTED_DENTAL_PLAN, payload: denPlans });
        dispatch({ type: types.UPDATE_SELECTED_VISION_PLAN, payload: vsnPlans });
        dispatch({ type: types.UPDATE_SELECTED_MEDICAL_PLAN, payload: medPlans });
        dispatch({ type: types.UPDATE_SELECTED_LIFE_PLAN, payload: lifePlans });
        dispatch({ type: types.UPDATE_SELECTED_DEPENDENTLIFE_PLAN, payload: lifeDepPlans });

        const { companyProfile } = getState();

        let tmp;
        let dateSections;
        let longDate;
        if (effDate.includes('/')) {
            dateSections = effDate.split('/');
            longDate = new Date(dateSections[2], dateSections[0], dateSections[1]);
            tmp = dateSections[2] + "-" + dateSections[0] + "-" + dateSections[1]
        }
        else if (effDate.includes('-')) {
            tmp = effDate;
            dateSections = effDate.split('-');
            longDate = new Date(dateSections[0], dateSections[1], dateSections[2]);
        }
        const newDate = setDate(longDate, companyProfile.date);
        if (!isUndefinedOrNull(tmp) && !isUndefinedOrNull(newDate.date)) {
            const updatedCompanyProfile = {
                ...companyProfile,
                effectiveDate: tmp,
                date: newDate.date,
            }
            dispatch(updateState(updatedCompanyProfile));
        }
    };
}

export const getNBQuoteTrackingNumber = (caseTrackingNumber, pcisId) => async (dispatch) => {
    dispatch({
        type: types.RECEIVE_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO,
    });

    const apiResponse = await axios.get(
        `${apiRoutes.quickQuotes}/getCaseQuotetrackingResponse/?caseTrackingNumber=${caseTrackingNumber}&pcisId=${pcisId}`,
    );

    dispatch({
        type: types.RECEIVE_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO,
        payload: apiResponse.data,
    });
    dispatch({
        type: types.RESET_QUOTE_TRACKING_NO_API_FLAG,
        payload: true,
    });
};

export function resetQuoteTrackingNumberFlag(resetVal) {
    return (dispatch) => {
        dispatch({
            type: types.RESET_QUOTE_TRACKING_NO_API_FLAG,
            payload: resetVal,
        });
    };
}

export function resetQuoteTrackingNumberlag() {
    return (dispatch) => {
        dispatch({
            type: types.RESET_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO,
            payload: false,
        });
    };
}

/**
 *
 * @param {boolean} val  true-> Owner Assignment data updated from DES Integration flow
 */
export function updateOwnerAssignmentFromDESFlowFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.OWNER_ASSIGNMENT_UPDATE_FROM_DES_FLOW,
            payload: val,
        });
    };
}

export function setSpecialtyCriteria(specialtyCriteria) {
    return (dispatch) => {
        sendLog({
            action: 'SET SPECIALTY CRITERIA',
            ...specialtyCriteria,
        });

        dispatch({
            type: types.SET_SPECIALTY_CRITERIA,
            specialtyCriteria,
        });
    };
}

export function getSicData(result, searchQuery, effectiveDate) {
    return (dispatch) => {
        axios.get(`${apiRoutes.mongo}/sic-codes/${searchQuery}?effectiveDate=${effectiveDate}`).then((res) => {
            const sicOptions = res.data.sicCodes.map((sicObj) => parseSicObj(sicObj, searchQuery));
            dispatch({
                type: types.SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL,
                payload: sicOptions[0],
            });
            dispatch(
                setSpecialtyCriteria({
                    sicCode: result.data.sicCode,
                    sicObj: sicOptions[0],
                    // TODO: handle specialty
                    viewSpecialty: {
                        medical: result.data.selectedProducts.medical,
                        dental: result.data.selectedProducts.dental,
                        vision: result.data.selectedProducts.vision,
                        life: false,
                        ltd: false,
                        std: false,
                        sul: false,
                    },
                }),
            );
        });
    };
}

export function parseSicObj(sicObj, searchedTerm = '') {
    const display = `${sicObj.sicCode} - ${sicObj.description}`;
    sicObj.display = display;
    return sicObj;
}

export function shouldDisableActionsImpactCase() {
    return (dispatch, getState) => {
        const pages = ['reviewDetail', 'decideDetail', 'enrollmentManager'];
        const currentPage = window.location.pathname.split('/')[1];

        if (pages.indexOf(currentPage) === -1) {
            return false;
        }

        const statusToBeUsedToDisableAllActions = ['Accepted', 'Accepted As Is', 'Pending Acceptance', 'Submission Hold for Audit', 'Expired', CASE_PROGRESS.CONVERTED_TO_LF, CASE_PROGRESS.LF_CONVERSION_COMPLETE];

    
        // currentCase track should be fetched as a parameter once case is mapped with the quote track number. (Case can have multiple quote track number)
        const store = getState();

        let caseStatus = '';

        if (store.renewals.globalSearchFlag) {
            caseStatus = store.renewals.renewalSearchCase.caseProgressUI;
        } else {
            if (caseStatus === '' && store.caseTracking && store.caseTracking.caseTrack[0] && store.caseTracking.caseTrack[0].caseStatus !== '') {
                caseStatus = store.caseTracking.caseTrack[0].caseStatus;
            }

            if (caseStatus === '') {
                const currentRenewal = store.renewals.renewals.find((plan) => plan.memberGroupId === store.renewals.memberGroupId);
                caseStatus = currentRenewal ? currentRenewal.caseProgressUI : '';
            }
        }

        if (caseStatus.toLowerCase() === 'inprogress') {
            caseStatus = 'In Progress';
        }

        return statusToBeUsedToDisableAllActions.indexOf(caseStatus) !== -1;
    };
}

export const getRLCaseTrackingCount = (caseId) => async () => {
    try {
        const caseTrackingResponse = await axios.get(`${apiRoutes.mongo}/caseTrack/case/${caseId}`);
        const currentCaseTrackingData = caseTrackingResponse.data;

        if (currentCaseTrackingData && currentCaseTrackingData.length > 0) {
            return currentCaseTrackingData.length;
        }
        return 0;
    } catch (err) {
        console.log('Error', err);
        throw new Error('Error Occured in getRLCaseTrackingCount', err);
    }
};

export function updateStateStoreId(stateStoreId) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_STATE_STORE_ID,
            stateStoreId,
        });
    };
}

export function updatePlatform(platform, isIPPSBypassed) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_INSTALLED_PLATFORM,
            platform,
        });
        dispatch({
            type: types.UPDATE_IS_IPPSBYPASSED,
            isIPPSBypassed,
        });
    };
}

export function HIOSapi(companyProfile, enrollment, overall, caseId, platform) {
    return (dispatch, getState) => new Promise(((resolve, reject) => {
        let hiosapirequest = makeHIOSRequestbody(companyProfile, enrollment, overall);
        hiosapirequest['caseId'] = caseId
        // axios.post(`${apiRoutes.mongo}/hiosapi`, hiosapirequest)
        axios.post(`${serviceLayer}/install/hiosapi`, hiosapirequest)
            .then((resp) => {
                console.log('HIOS API success');
                dispatch(setHiosResponseData(resp, enrollment, platform)).then(
                    resolve(),
                );
                // dispatch(saveStateStore(true));
            })
            .catch((err) => {
                console.log('HIOS API err', err);
                reject();
            });
    }));
}

function setHiosResponseData(data, enrollment, platform) {
    return (dispatch) => new Promise(((resolve, reject) => {
        const { hiosData } = data.data.hiosResponseMsg;
        const { medresTbl, ancResponse } = hiosData;

        const newPlans = {
            ...buildProductMapping(medresTbl, ancResponse, enrollment),
        };
        dispatch({
            type: types.UPDATE_PLANS_HIOS_DATA,
            plans: newPlans,
        }).then(
            resolve(''),
        );
    }));
}

const buildProductMapping = (medresTbl, ancResponse, enrollment) => {
    const {
        enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledDependentLifePlans
    } = enrollment;

    const newMedMapping = {};

    if (medresTbl) { // If HIOS call is made without CIRRUS medical plans, else statement will return non-CIRRUS medical plans back into enrollment
        const planMap = new Map();
        medresTbl.forEach((plan) => {
            const planKey = plan.pgmYr + plan.hlthplnCd;
            planMap.set(planKey, plan);
        });

        for (const pkg in enrolledPlans) {
            newMedMapping[pkg] = enrolledPlans[pkg].map((plan) => {
                if (planMap.has(plan.code)) { // CIRRUS plans returned from HIOS will add HIOS plan attributes into existing plan object
                    const planHiosData = planMap.get(plan.code);
                    return {
                        ...plan,
                        hiosplnID: planHiosData.hiosplnID,
                        innetleCd: planHiosData.innetleCd,
                        innetleNm: planHiosData.innetleNm,
                        innetSellCarID: planHiosData.innetSellCarID,
                        outnetleCd: planHiosData.outnetleCd,
                        outnetleNm: planHiosData.outnetleNm,
                        outnetSellCarID: planHiosData.outnetSellCarID
                    };
                }
                return plan; // For DUAL, non-CIRRUS medical plans are inserted back into enrollment as is
            });
        }
    } else {
        for (let pkg in enrolledPlans) {
            newMedMapping[pkg] = enrolledPlans[pkg].map((plan) => {
                return plan; // Add all non-CIRRUS medical plans back into enrolledPlan object when no CIRRUS medical plans are sent to HIOS (case only contains specialty CIRRUS plans)
            })
        }
    }

    const result = {
        medical: newMedMapping,
        dental: enrolledDentalPlans.map((plan, index) => {
            if (index === 0) {
                return {
                    ...plan,
                    dentleCd: ancResponse.dentleCd,
                    dentleNm: ancResponse.dentleNm,
                    dentSellCarID: ancResponse.dentSellCarID
                };
            }
            if (index === 1) {
                return {
                    ...plan,
                    dentleCd: ancResponse.dualdentleCd,
                    dentleNm: ancResponse.dualdentleNm,
                    dentSellCarID: ancResponse.dualdentSellCarID
                };
            }
        }),
        vision: enrolledVisionPlans.map((plan) => ({
            ...plan,
            visleCd: ancResponse.visleCd,
            visleNm: ancResponse.visleNm,
            visSellCarID: ancResponse.visSellCarID
        })),
        life: enrolledLifePlans.map((plan) => ({
            ...plan,
            lifeleCd: ancResponse.lifeleCd,
            lifeleNm: ancResponse.lifeleNm,
            lifeSellCarID: ancResponse.lifeSellCarID
        })),
        dependentLife: enrolledDependentLifePlans.map((plan) => ({
            ...plan,
            depLifeleCd: ancResponse.lifeleCd,
            depLifeleNm: ancResponse.lifeleNm,
            depLifeSellCarID: ancResponse.lifeSellCarID
        })),
    };

    return result;
};

export function toggleFinalSubmitFlag(value) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_FINAL_SUBMIT_FLAG,
            payload: value,
        });
    };
}

export function IPPSApi(companyProfile, enrollment, overall, currentQuoteId, caseId, flag = '') {
    return (dispatch, getState) => new Promise(((resolve, reject) => {
        const determineGroupSize = () => {
            if (companyProfile.locations[0].fteRule === 'FTE') {
                return companyProfile.locations[0].fte;
            }
            if (companyProfile.locations[0].fteRule === 'ATNE') {
                return companyProfile.locations[0].atne;
            }
            if (companyProfile.locations[0].fteRule === 'Eligible') {
                return companyProfile.locations[0].totalEligEmps;
            }

            return companyProfile.locations[0].atne;
        };

        const mapToYYYYMMDD = (date) => {
            const newDate = date.split("/");
            return `${newDate[2]}-${newDate[0]}-${newDate[1]}`;
        }
        
        const getOISABit = (data) => {
            let oi = null;
            let sa = null;
            let bit = null;
            const {clientDefinedData} = data.renewalData.Ibr.group.primaryLocation
            for (let i = 0; i < clientDefinedData.data.length; i++) {
                if (clientDefinedData.data[i].key === "OI") {
                    oi = clientDefinedData.data[i].value;
                }
                if (clientDefinedData.data[i].key === "SA") {
                    sa = clientDefinedData.data[i].value;
                }
                if (clientDefinedData.data[i].key === "BIT") {
                    bit = clientDefinedData.data[i].value;
                }
            }
            let OISABit = [{
                OI: oi,
                SA: sa,
                BIT: bit
            }]; 
            return OISABit[0].OI + OISABit[0].SA + OISABit[0].BIT;
        }

        const isUhcRenewal = isUhcRenewals();

        const fullProps = getState();

        const isMigratedCase = fullProps.renewals?.renewalCaseData?.samxInternalGrp === 'Y';
        const reqBodyObject = {
            source: 'SamX',
            currentQuoteId,
            caseId,
            effectiveDate: isUhcRenewal ? mapToYYYYMMDD(companyProfile.effectiveDate) :companyProfile.effectiveDate,
            groupCount: determineGroupSize(),
            stateCode: isUhcRenewal ? overall.renewalCaseData.situsState : companyProfile.locations[0].zipData.stateCode,
            enrolledMedicalPlans: (Object.keys(enrollment.enrolledPlans).length > 0)
                ? Object.keys(enrollment.enrolledPlans).map((key) => enrollment.enrolledPlans[key]).flat()
                : [],
            enrolledDentalPlans: (enrollment.enrolledDentalPlans.length > 0)
                ? enrollment.enrolledDentalPlans
                : [],
            enrolledVisionPlans: (enrollment.enrolledVisionPlans.length > 0)
                ? enrollment.enrolledVisionPlans
                : [],
            OISABit: isUhcRenewal? getOISABit(overall) : (overall.OISABit.length > 0)
                ? overall.OISABit[0].OI + overall.OISABit[0].SA + overall.OISABit[0].BIT
                : '0100CG',
            enrolledLifePlans: (enrollment.enrolledLifePlans.length > 0)
                ? enrollment.enrolledLifePlans
                : [],
            enrolledDependentLifePlans: (enrollment.enrolledDependentLifePlans.length > 0)
                ? enrollment.enrolledDependentLifePlans
                : [],
            enrolledSTDPlans: (enrollment.enrolledSTDPlans.length > 0)
                ? enrollment.enrolledSTDPlans
                : [],
            enrolledLTDPlans: (enrollment.enrolledLTDPlans.length > 0)
                ? enrollment.enrolledLTDPlans
                : [],
            enrolledSuppLifePlans: (enrollment.enrolledSuppLifePlans.length > 0)
                ? enrollment.enrolledSuppLifePlans
                : [],
            enrolledSuppLifeDepPlans: (enrollment.enrolledSuppLifeDepPlans.length > 0)
                ? enrollment.enrolledSuppLifeDepPlans
                : [],
            memGrpInstallTxnType: isUhcRenewal? 'SOLDMGREN' : 'NWMGINST',
            migrationStatus: isUhcRenewal && fullProps?.renewals?.rlCaseData?.uhcPlatformCode ? fullProps.renewals.rlCaseData.uhcPlatformCode : '',
            customerType: fullProps.renewals && fullProps.renewals.rlCaseData && fullProps.renewals.rlCaseData.custType ? fullProps.renewals.rlCaseData.custType : '',
            isMigratedCase: isMigratedCase,
            //added for testing purpose to be removed later
        };

        let situsState = isUhcRenewal ? overall.renewalCaseData.situsState : companyProfile.locations[0].zipData.stateCode;
        let platform = "";
        let isTriplePlatform = false;
        axios.post(`${serviceLayer}/ippsApi`, reqBodyObject)
            .then((resp) => {
                //jsaunde - US5095549 Adding logic to set the platform to NICE when necessary
                if(situsState === "CA" && resp.data.responseData.platformName === "PRIME"){
                    platform = checkForNiceGroup(enrollment,false);
                    isTriplePlatform = checkTriplePlatform(resp.data.responseData.productTypeList, enrollment)
                    if(isTriplePlatform){
                        dispatch({
                            type: types.UPDATE_TRIPLE_PLATFORM,
                            payload:true
                        });
                    }
                }else{
                    platform = resp.data.responseData.platformName;
                }

                let isManualInstallation = false;
                let productTypeList = resp.data.responseData?.productTypeList || [];
                if (flag === 'uhcRenewals') {
                    if(situsState !== "CA"){
                        if (platform === 'PRIME') {
                            if (isMigratedCase) {
                                isManualInstallation = true;
                            }
                        } else if (platform === 'CIRRUS') {
                            if (!isMigratedCase) {
                                isManualInstallation = true;
                            }
                        } else if (platform === 'DUAL') {
                            // For day 1, DUAL will be manual installation only
                            productTypeList = resp.data.responseData?.productTypeList || [];
                            isManualInstallation = true;
                        }
                    }  
                    if(situsState === "CA" && !isMigratedCase && (platform === 'CIRRUS' || productTypeList?.find(productType => productType.productPlatformName === 'USP'))){
                        isManualInstallation = true;
                    }
                    dispatch({
                        type: types.SET_PRODUCT_TYPE_LIST,
                        productTypeList,
                    });
                    dispatch(setPlanInstallPlatforms(productTypeList));
                } else {
                    if ((platform === 'DUAL' || platform === 'PRIME') && (reqBodyObject.enrolledSTDPlans.length > 0 || reqBodyObject.enrolledLTDPlans.length > 0 || reqBodyObject.enrolledSuppLifePlans.length > 0)) {
                        // For day 1, DUAL will be manual installation only
                        productTypeList = resp.data.responseData?.productTypeList || [];

                        isManualInstallation = true;
                        // Update platform for disability and supplife
                        reqBodyObject.enrolledSTDPlans.forEach(plan => {
                                dispatch({
                                    type: types.SET_PLAN_INSTALL_PLATFORM,
                                    planCode: plan.code,
                                    product: 'STD',
                                    installPlatform: 'PRIME',
                                });
                        });
                        reqBodyObject.enrolledLTDPlans.forEach(plan => {
                            dispatch({
                                type: types.SET_PLAN_INSTALL_PLATFORM,
                                planCode: plan.code,
                                product: 'LTD',
                                installPlatform: 'PRIME',
                            });
                        });
                        reqBodyObject.enrolledSuppLifePlans.forEach(plan => {
                            dispatch({
                                type: types.SET_PLAN_INSTALL_PLATFORM,
                                planCode: plan.code,
                                product: 'SuppLife',
                                installPlatform: 'PRIME',
                            });
                        });
                        reqBodyObject.enrolledSuppLifeDepPlans.forEach(plan => {
                            dispatch({
                                type: types.SET_PLAN_INSTALL_PLATFORM,
                                planCode: plan.code,
                                product: 'SuppLifeDep',
                                installPlatform: 'PRIME',
                            });
                        });
                    } 
                    dispatch({
                        type: types.SET_PRODUCT_TYPE_LIST,
                        productTypeList,
                    });
                    dispatch(setPlanInstallPlatforms(productTypeList));
                }
                dispatch({
                    type: types.IPPS_PLATFORM_AND_CODE_UPDATE,
                    platform,
                    isManualInstallation,
                    responseCode: resp.data.responseData.responseCode,
                });
                resolve(platform);
            })
            .catch((err) => {
                let platform = 'CIRRUS';
                const enrolledPlans = (Object.keys(enrollment?.enrolledPlans).length > 0) ? Object.keys(enrollment?.enrolledPlans).map((key) => enrollment?.enrolledPlans[key]).flat()
                : [];
                const enrolledDentalPlans = enrollment?.enrolledDentalPlans || [];
                const enrolledVisionPlans = enrollment?.enrolledVisionPlans || [];
                const enrolledLifePlans = enrollment?.enrolledLifePlans || [];
                const enrolledLTDPlans = enrollment?.enrolledLTDPlans || [];
                const enrolledSTDPlans = enrollment?.enrolledSTDPlans || [];
                const enrolledSuppLifePlans = enrollment?.enrolledSuppLifePlans || [];
                let totalEnrolledPlans = [...enrolledPlans,...enrolledDentalPlans,...enrolledVisionPlans,...enrolledLifePlans,...enrolledLTDPlans,...enrolledSTDPlans,...enrolledSuppLifePlans]
                if(situsState == "CA") {
                    platform = checkForNiceGroup(enrollment,true);
                    totalEnrolledPlans.forEach(plan =>{
                        if(plan.planType === "Medical"){
                            if(plan.platform === "NICE"){
                                dispatch({
                                    type: types.SET_PLAN_INSTALL_PLATFORM,
                                    planCode: plan.code,
                                    product: plan.planType,
                                    installPlatform: "NICE",
                                });
                            } else {
                                dispatch({
                                    type: types.SET_PLAN_INSTALL_PLATFORM,
                                    planCode: plan.code,
                                    product: plan.planType,
                                    installPlatform: "CIRRUS",
                                });
                            }

                        } else {
                            dispatch({
                                type: types.SET_PLAN_INSTALL_PLATFORM,
                                planCode: plan.code,
                                product: plan.planType,
                                installPlatform: "CIRRUS",
                            });
                        }
                    })
                } else {
                    if(isUhcRenewals() && !isMigratedCase) {
                        platform = "PRIME";
                    }
                    totalEnrolledPlans.forEach(plan =>{
                        dispatch({
                            type: types.SET_PLAN_INSTALL_PLATFORM,
                            planCode: plan.code,
                            product: plan.planType,
                            installPlatform: platform,
                        });
                    })

                }
                dispatch({
                    type: types.IPPS_PLATFORM_AND_CODE_UPDATE,
                    platform: platform,
                    isManualInstallation: false,
                    responseCode: '',
                });
                if(isUhcRenewal) {
                    logError(KEY_NAME, UHC_REN_APIS.IPPS, 'caseTrackAction.IPPSApi', reqBodyObject, err);
                }
                reject(err);
            });
    }));
}

// Helper Functions
function fillCaseOwnersData(fullProps) {
    let caseOwners = [];
    if (fullProps.renewals && !isUndefinedOrNullOrEmpty(fullProps.renewals.val)) {
        caseOwners = caseOwners.concat(parseInt(fullProps.renewals.val));
    }
    if (fullProps.reviewCards && fullProps.reviewCards.ownerAssignment) {
        if (fullProps.reviewCards.ownerAssignment.agency && fullProps.reviewCards.ownerAssignment.agency.length > 0) {
            if (!isUndefinedOrNullOrEmpty(fullProps.reviewCards.ownerAssignment.agency[0].PCIS_ID)) {
                caseOwners = caseOwners.concat(parseInt(fullProps.reviewCards.ownerAssignment.agency[0].PCIS_ID));
            }
        }
        if (fullProps.reviewCards.ownerAssignment.generalAgent && fullProps.reviewCards.ownerAssignment.generalAgent.length > 0) {
            if (!isUndefinedOrNullOrEmpty(fullProps.reviewCards.ownerAssignment.generalAgent[0].PCIS_ID)) {
                caseOwners = caseOwners.concat(parseInt(fullProps.reviewCards.ownerAssignment.generalAgent[0].PCIS_ID));
            }
        }
        if (fullProps.reviewCards.ownerAssignment.producer && fullProps.reviewCards.ownerAssignment.producer.length > 0) {
            if (!isUndefinedOrNullOrEmpty(fullProps.reviewCards.ownerAssignment.producer[0].PCIS_ID)) {
                caseOwners = caseOwners.concat(parseInt(fullProps.reviewCards.ownerAssignment.producer[0].PCIS_ID));
            }
        }
        // if (fullProps.reviewCards.ownerAssignment.salesRep && fullProps.reviewCards.ownerAssignment.salesRep.length > 0) {
        //     if (!isUndefinedOrNullOrEmpty(fullProps.reviewCards.ownerAssignment.salesRep[0].PCIS_ID)) {
        //         caseOwners = caseOwners.concat(parseInt(fullProps.reviewCards.ownerAssignment.salesRep[0].PCIS_ID));
        //     }
        // }
    }

    return caseOwners;
}

/**  Add the required fields while saving in DB so that while retrieving the data in NB dashboard we don't have to perform join with full shopping collection
 * therby reducing dependency on fullshopping collection.
 */
function addFieldsToCaseObject(caseObj, fullProps) {
    const {
        companyProfile: {
            effectiveDate,
            companyName,
            locations,
            brokerId,
            memberGroupInstallation,
            memberGroupId,
            B2BPartner,
        },
        reviewCards: { ownerAssignment },
        overall: { platform },
    } = fullProps;

    const addInfo = {
        companyName,
        effectiveDate,
        brokerId,
        producer: ownerAssignment && ownerAssignment.producer[0] ? ownerAssignment.producer[0].producerName : '',
        agency: ownerAssignment && ownerAssignment.agency[0] ? ownerAssignment.agency[0].agencyName : '',
        salesRep: ownerAssignment && ownerAssignment.salesRep[0] ? ownerAssignment.salesRep[0].salesRepName : '',
        generalAgent: ownerAssignment && ownerAssignment.generalAgent[0] ? ownerAssignment.generalAgent[0].generalAgentName : '',
        memberGroupId: memberGroupInstallation && !isUndefinedOrNullOrEmpty(memberGroupInstallation.memberGroupId) ? memberGroupInstallation.memberGroupId : (!isUndefinedOrNullOrEmpty(memberGroupId) ? memberGroupId : ''),
        policyNumber: memberGroupInstallation ? memberGroupInstallation.planPolicyArray : '',
        state: locations && locations[0] ? locations[0].zipData.stateCode : '',
        zipCode: locations && locations[0] ? locations[0].zipData.zipCode : '',
        bnePlatform: platform,
        B2BPartner: B2BPartner != undefined ? B2BPartner : '',
        TotalEligibleEmps: locations && locations[0] ? locations[0].totalEligEmps : '',

    };
    caseObj.addInfo = addInfo;
}

function checkTriplePlatform(productTypeList, enrollment) {
    let isCirrus = false, isNice = false, isPrime = false;

    if (productTypeList.length > 2) {
        const enrolledPlans = (Object.keys(enrollment?.enrolledPlans).length > 0) ? Object.keys(enrollment?.enrolledPlans).map((key) => enrollment?.enrolledPlans[key]).flat()
        : [];
        const enrolledDentalPlans = enrollment?.enrolledDentalPlans;
        const enrolledVisionPlans = enrollment?.enrolledVisionPlans;
        const enrolledLifePlans = enrollment?.enrolledLifePlans;
        for (let i = 0; i < productTypeList.length; i++) {
            let planCode = productTypeList[i].uhcBenefitMedicalList[0].uhcBenefitID + (productTypeList[i].uhcBenefitMedicalList[1] ? '/' + productTypeList[i].uhcBenefitMedicalList[1].uhcBenefitID : '')
            let type = productTypeList[i].uhcBenefitMedicalList[0].benefitType
            if (productTypeList[i].productPlatformName === 'LEGACY') {
                if(!(isPrime && isNice)){
                    if (type ==='MD') {
                        let medPlan = enrolledPlans.find(plan => plan.medicalPlanCode === planCode || planCode.includes(plan.medicalPlanCode))
                        if (medPlan?.platform === 'NICE' || medPlan?.installPlatform === 'NICE') {
                            isNice = true
                        }
                        else if (medPlan.platform === 'PRIME' || medPlan.installPlatform === 'PRIME') {
                            isPrime = true
                        }
                    }
                    else if (type === 'DN') {
                        let denPlan = enrolledDentalPlans.find(plan => plan.code === planCode)
                        if (denPlan?.platform === 'PRIME' || denPlan?.installPlatform === 'PRIME') {
                            isPrime = true
                        }
                    }
                    else if (type === 'VS') {
                        let visPlan = enrolledVisionPlans.find(plan => plan.code === planCode)
                        if (visPlan?.platform === 'PRIME' || visPlan?.installPlatform === 'PRIME') {
                            isPrime = true
                        }
                    }
                    else {
                        let lifePlan = enrolledLifePlans.find(plan => plan.code === planCode)
                        if (lifePlan?.platform === 'PRIME' || lifePlan?.installPlatform === 'PRIME') {
                            isPrime = true
                        }
                    }
                }
            }
            else {
                if (productTypeList[i].productPlatformName === 'USP') { isCirrus = true }
            }
        }

        if (isPrime && isNice && isCirrus) {
            return true
        }
        else {
            return false
        }
    }
    else { return false }
       
}

// This function will determine if the a group should have an installation platform of NICE, DUAL or PRIME
function checkForNiceGroup(enrollment,isBypassed = false) {
    let isNice = false;
    let isPrime = false;
    let isCirrus = false;
    let newPlatform = '';
    const enrolledPlans = (Object.keys(enrollment?.enrolledPlans).length > 0) ? Object.keys(enrollment?.enrolledPlans).map((key) => enrollment?.enrolledPlans[key]).flat()
    : [];
    const enrolledDentalPlans = enrollment?.enrolledDentalPlans;
    const enrolledVisionPlans = enrollment?.enrolledVisionPlans;
    const enrolledLifePlans = enrollment?.enrolledLifePlans;

    // Determine which platforms exist by iterating all selected plans
    const enrolledPlansArrays = [enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans];
    for (let array of enrolledPlansArrays) {
        if (array.length > 0) {
            for (let i = 0; i < array.length; i++) {
                if ((array[i].platform && array[i].platform === "NICE") || (array[i].installPlatform && array[i].installPlatform === "NICE")) {
                    isNice = true;
                } else if (!isBypassed && ((array[i].platform && array[i].platform === "PRIME") || (array[i].installPlatform && array[i].installPlatform === "PRIME"))) {
                    isPrime = true;
                } else {
                    isCirrus = true;
                }
            }   
        }
    }

    if (isNice && (isPrime || isCirrus)) {
        newPlatform = "DUAL";
    } else {
        if (isNice) {
            newPlatform = "NICE";
        } else if (isPrime) {
            newPlatform = "PRIME";
        } else {
            newPlatform = "CIRRUS";
        }
    }

    return newPlatform;
}

/**
 *  Axios PATCH call to update caseTracking in Mongo
 */
export const patchUpdateCaseTracking = (updateObj, id) => {
    axios.patch(`${apiRoutes.mongo}/caseTrack/${id}`, updateObj);
};

/**
 *  Patch update the updated 'addInfo' details in the DB.
 *  (Needs to be called when fullShopping in DB was updated but addInfo in caseTracking is not)
 */
export const updateAdditionalInfoInCaseTracking = () => {
    const { store } = require('../index');
    const fullProps = store.getState();
    const { caseTracking } = fullProps;
    const currentQuoteIdx = (caseTracking.caseTrack && Array.isArray(caseTracking.caseTrack) && caseTracking.caseTrack.findIndex((obj) => obj.quoteTrackingNumber === caseTracking.currentQuoteId)) || 0;
    const caseObj = caseTracking.caseTrack[currentQuoteIdx];
    addFieldsToCaseObject(caseObj, fullProps);
    const updateObj = {
        // These three are required field in patch update as per schema defined
        businessType: caseObj.businessType,
        source: caseObj.source,
        userId: caseObj.userId,
        //  ---------------------
        addInfo: {
            ...caseObj.addInfo,
        },
    };
    try {
        patchUpdateCaseTracking(updateObj, caseObj._id);
    } catch (err) {
        console.log(err);
        console.log('Error while updating member group id in case tracking');
    }
};

/** Update all the cases with same caseID with isMultiQuote Flag in case of copy quote or new quote */
export const updateIsMultiQuoteFlag = (caseTracking) => {
    const updateObj = {
        isMultiQuote: true,
    };
    const caseTrackObjects = caseTracking.caseTrack;
    if (caseTrackObjects.length > 0) {
        caseTrackObjects.forEach((obj) => {
            if (!('isMultiQuote' in obj)) {
                patchUpdateCaseTracking(updateObj, obj._id);
            }
        });
    }
};

export const ippsReporting = (quoteId, caseId, req, res, errMsg) => (dispatch, getState) => new Promise(((resolve, reject) => {
    const reqBodyObject = {
        quoteId,
        caseId,
        reqData: req,
        resData: res,
        errMsg,
    };
    axios.post(`${serviceLayer}/ippsReportingApi`, reqBodyObject)
        .then()
        .catch((err) => {
            if(isUhcRenewals()) {
                logError(KEY_NAME, UHC_REN_APIS.IPPS_REPORTING, 'caseTrackAction.ippsReporting', reqBodyObject, err);
            }
        });
}));

export const getCaseStatus = (caseId, quoteTrackingNumber) => new Promise((resolve, reject) => {
    if (caseId && quoteTrackingNumber) {
        axios.get(`${apiRoutes.mongo}/caseTrack/getCaseStatus/${caseId}/${quoteTrackingNumber}`)
            .then(
                (result) => {
                    resolve(result.data);
                },
            ).catch((err) => {
                reject(err);
            });
    } else {
        return resolve(CASE_PROGRESS.QUOTING);
    }
});

export function saveTotalProgress(obj){
    return (dispatch)=>{
      dispatch({
        type:types.SAVE_TOTAL_PROGRESS,
        payload:obj
      })
    }
  }

  export function updateManualFlag(isManual){ 

    return (dispatch)=>{
        dispatch({
            type:types.UPDATE_MANUAL_FLAG,
            payload:isManual
        })
      }
    }


export function getSalesRepFromMemberGroupId(memberGroupId) {
    return (dispatch) => new Promise((resolve, reject) => {
        axios
            .get(`${apiRoutes.quickQuotes}/getSalesRepFromMemberGroupId/${memberGroupId}`)
            .then((res) => {
                sendLog({
                    action: 'GET_SALES_REP_FROM_MEMBERGROUPID',
                });
                if(res.data!=""){
                    dispatch({
                        type: types.ADD_SALES_REP_FROM_MEMBERGROUPID,
                        payload: res.data,
                    });
                }
                
                resolve(res.data);
            })
            .catch((err) => {
                sendLog({
                    action: 'ERROR_ADD_SALES_REP_FROM_MEMBERGROUPID',
                });
                reject(err);
            });
    });
}

export function disableSaveAndExit(flag){ 
    return (dispatch)=>{
        dispatch({
            type: types.DISABLE_SAVE_AND_EXIT,
            payload: flag
        })
    }
}

export const saveFavoritePlanCodesToUserPrefrence = (fullProps) => {

    // Add favorite plan codes in userPrefrence
    const { 
        quotes, 
        dental, 
        vision, 
        life, 
        lifeDep,
        suppLife,
        std,
        ltd,
        overall: { platform },
        companyProfile: { locations },
        renewals: { logInData }
    } = fullProps;

    const {
        favMedicalPlanCodes,
        favDentalPlanCodes,
        favVisionPlanCodes,
        favLifePlanCodes,
        favLifeDepPlanCodes,
        favSuppLifePlanCodes,
        favStdPlanCodes,
        favLtdPlanCodes,
    } = createFavoritePlanCodesBody(
        quotes.plans,
        dental.allPlans,
        vision.allPlans,
        life.allPlans,
        lifeDep.allPlans,
        suppLife.allPlans,
        std.allPlans,
        ltd.allPlans
    );

    const { stateCode } = locations[0].zipData;
    const { optumId, userName } = logInData[0];

    saveFavoritePlanCodes({
        favMedicalPlanCodes,
        favDentalPlanCodes,
        favVisionPlanCodes,
        favLifePlanCodes,
        favLifeDepPlanCodes,
        favSuppLifePlanCodes,
        favStdPlanCodes,
        favLtdPlanCodes,
        stateCode,
        userName,
        platform,
        optumId
    })
}
