/**
 * Checks to see if this is running on localhost, if it is
 * sets the base urls of the endpoints accordingly. If it is not,
 * checks the token and connects to stargate
 */
const _axios = require('axios');

// Returns the formatted header



/**
 * Checks to see if the current token exists, if it does, checks to see if
 * it's expired. If it doesn't exist creates a new one
 */
/*const getHeaders = () => {
    const token = useSelector(state => state.overall.userToken);
    return setHeaders(token);
};*/

const axios = _axios.create();

 /*axios.interceptors.request.use((request) => {
    //request.headers = getHeaders();
    return request;
    });
*/

exports.axios = axios;
