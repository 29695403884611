import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { reduceCreateHistoryPayload } from './actionHelpers/createHistoryHelper';
import { isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { reduceStateStorePayload } from '../utils/stateStorePayload/reduce/reducePayload';

const apiRoutes = require('../utils/api/apiRouteConfig');

export function createHistory(description = '', pageName = '', status = 'Error', payload = '', response = '', businessType = 'RL') {
    return (dispatch, getState) => new Promise((resolve, reject) => {
          if (process.env.REACT_APP_ENV !== 'local') {
            const fullProps = getState();
            const caseId = fullProps && fullProps.caseTracking ? fullProps.caseTracking.caseId : '';
            if (fullProps.overall.source === 'mmr') {
                businessType = 'MMR'
            }
            const quoteTrackingNumber = fullProps && fullProps.caseTracking && fullProps.caseTracking.currentQuoteId ? fullProps.caseTracking.currentQuoteId : '';

            const memberGroupId = fullProps && fullProps.review && fullProps.review.val && fullProps.review.val.customerNumber !== '' ? fullProps.review.val.customerNumber : fullProps && fullProps.companyProfile && fullProps.companyProfile.memberGroupId ? fullProps.companyProfile.memberGroupId : '';
            const brokerPcisId = fullProps && fullProps.renewals && fullProps.renewals.val ? fullProps.renewals.val : '';
            const platform = fullProps && fullProps.overall.platform;

            const req = {};
            req.memberGroupId = memberGroupId;
            req.user = fullProps.user.userName;
            req.description = description;
            req.pageName = pageName;
            req.status = status;
            req.payload = reduceStateStorePayload(payload);
            req.response = response.message || response.data || response;
            req.businessType = businessType;
            req.caseId = caseId;
            req.quoteTrackingNumber = quoteTrackingNumber;
            req.brokerPcisId = brokerPcisId;
            req.platform = platform;
            const pageReq = { req };

            sendLog({
                action: 'REQUEST_CREATE_HISTORY',
                ...pageReq,
            });
            dispatch({
                type: types.SAVE_AUDIT_HISTORY,
                payload: { ...req }
            });

            dispatch({
                type: types.REQUEST_CREATE_HISTORY,
                payload: pageReq,
            });

            // if (fullProps.renewalHistory && fullProps.renewalHistory.currentPage !== pageName || (pageName==="Enrollment Manager ( Eligibility Information )" && fullProps.renewalHistory.saveHistory &&  fullProps.renewalHistory.saveHistory.length > 4)) {
            if (fullProps.renewalHistory && fullProps.renewalHistory.currentPage !== pageName) {
                const auditHistoryArray = fullProps.renewalHistory.saveHistory || [];
                reduceCreateHistoryPayload(auditHistoryArray)
                axios.post(`${apiRoutes.quickQuotes}/save-history`, auditHistoryArray)
                    .then((res) => {
                        dispatch({
                            type: types.RECEIVE_CREATE_HISTORY,
                            payload: res.data,
                            pageReq,
                            source: `${req.businessType}-History`,
                        });
                    })
                    .catch((err) => {
                        console.log('entered into history action catch block', req);
                        dispatch({
                            type: types.ERR_CREATE_HISTORY,
                            payload: err,
                        });
                    });
            }
            dispatch({
                type: types.SAVE_CURRENT_PAGE,
                payload: pageName
            })
        }
    });
}

export function getAuditHistory(businessType = 'RL', caseId, skip = 0, limit = 20, count) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch({
            type: types.REQUEST_AUDIT_HISTORY,
        });
        const fullProps = getState()
        const isMMRCase = fullProps.overall.source === 'mmr'
        if (isMMRCase) {
            businessType = 'MMR'
        }
        let url = `${apiRoutes.quickQuotes}/get-audit-history?caseId=${caseId}&businessType=${businessType}&skip=${skip}&limit=${limit}`
        if (count) {
            url += `&count=${count}`
        }
        axios.get(url)
            .then((res) => {
                if (skip === 0 && limit === 20) {
                    dispatch({
                        type: types.RECEIVE_AUDIT_HISTORY,
                        payload: res.data.result,
                    });
                    resolve(res.data)
                } else {
                    dispatch({
                        type: types.RECEIVE_AUDIT_HISTORY_RANGE,
                        payload: res.data.result,
                    });
                    resolve(res.data)
                }
            })
            .catch((err) => {
                dispatch({
                    type: types.ERROR_AUDIT_HISTORY,
                    payload: err,
                });
                reject(err);
                if(isUhcRenewals()) {
                    const reqBody = {
                        caseId, businessType, skip, limit 
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_AUDIT_HISTORY, 'historyAction.getAuditHistory', reqBody, err);
                }
            });
    })
}

export function getActionHistory(businessType, caseId, skip = 0, limit = 25) {
    return (dispatch, getState) => {
        dispatch({
            type: types.REQUEST_ACTION_HISTORY,
        });

        const fullProps = getState();
        const isMMRCase = fullProps.overall.source === 'mmr'
        if (isMMRCase) {
            businessType = 'MMR'
        }
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;

        axios.get(`${apiRoutes.quickQuotes}/get-action-history?caseId=${caseId}&quoteTrackingNumber=${quoteTrackingNumber}&businessType=${businessType}&skip=${skip}&limit=${limit}`)
            .then((res) => {
                dispatch({
                    type: types.RECEIVE_ACTION_HISTORY,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERROR_ACTION_HISTORY,
                    payload: err,
                });
                if(isUhcRenewals()) {
                    const reqBody = {
                        caseId, quoteTrackingNumber, businessType, skip, limit 
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_ACTION_HISTORY, 'historyAction.getActionHistory', reqBody, err);
                }
            });
    };
}

export function storeActiveHistoryCase(caseId, flow) {
    return (dispatch) => {
        sendLog({
            action: 'STORE_ACTIVE_HISTORY_CASE',
            caseId,
        });

        dispatch({
            type: types.STORE_ACTIVE_HISTORY_CASE,
            caseId,
            flow,
        });
    };
}