import { stringToCoverageCode, isEmpty } from '../../../utils/formatters/strings';
import { handleExcelDate } from '../../../utils/formatters/dates';
import { axios } from '../../../utils/api/axiosConfig';
import {
    verticalEmp, horizontalEmp, verticalDep, horizontalDep, verticalLocation, horizontalLocation, horizontalEnroll, verticalEnroll,
} from './importMap';
import { newEmployee } from '../../activities/FullShopping/Census/helpers/employeeConsts';
import {
    memberType, relationshipType, genderType, employeeStatusType, yesNoType, coverageTypes, suffixType, waivingAllType , noSSNType,
    medicareReasonType, phoneType, pcpAssignmentType, dissabilityType, specialEmployeeStatusType
} from '../../activities/FullShopping/Census/helpers/dropDownConsts';
import { zipReturnError, sicReturnError } from '../../../actions/quickQuotesActions';
import { makeZipObjDisplay } from '../../../utils/formatters/zips';
import { getAgeFromDOB, getDOBFromAge } from '../../../utils/formatters/numbers';
import { checkValidationsOnInput } from '../ConfigurableForms/helpers/validationCheckers';
import { dependent, generateRelationshipTypeOptions } from '../../activities/FullShopping/Census/helpers/dependentConsts';
import { getPrimaryLocationStateCode } from '../../../components/activities/FullShopping/Census/helpers/checkPrimaryLocation';
import { store } from '../../../index';
import moment from "moment";
import { isWaivingAll } from '../../../uhcTemplate/templateParser/mappingHolder/mappingHelper';
const Excel = require('exceljs');

const apiRoutes = require('../../../utils/api/apiRouteConfig');
/**
 * Checks whether the zipcode is a string or number and returns accordingly
 * If the zip is a number that starts with zero, the zero will be removed, this method adds them back in
 * @param {string, number} zip -> zip can either be a string or a number
 */
const checkZipCode = (zip) => {
    if (typeof zip === 'string') {
        return zip;
    }
    const zipString = `${zip}`;
    if (zipString.length === 5) {
        return zipString;
    }
    if (zipString.length === 4) {
        return `0${zip}`;
    } if (zipString.length === 3) {
        return `00${zip}`;
    }
    return '';
};

/**
 * Convert "M" or "F" gender to "Male" or "Female"
 * @param {string} gender -> Contains "M"/"F" or "Male"/"Female" to convert to descriptive gender
 */
export const getGender = (gender) => {
    if (gender.toUpperCase() === 'M') {
        return 'Male';
    }

    if (gender.toUpperCase() === 'F') {
        return 'Female';
    }

    if (gender.toUpperCase() === 'N') {
        return 'Non-Binary';
    }
    return gender;
};

export const checkForUhcTemplate = (marketType, versionHistory) => {
    if (marketType && versionHistory) {
        const identifier = marketType.getCell('A2').value;
        const version = versionHistory.getCell('A6').value;
        if (identifier.includes('UHC') && version === 'v4DD') {
            return true;
        }
        return false;
    }
    return false
}

const determineSelectedProductTypes = (selectedProducts) => {
    let selctdProductTracker = [];

    for (const key in selectedProducts) {
        if (selectedProducts[key]) {
            selctdProductTracker.push(key);
        }
    }

    return selctdProductTracker;
}

const checkIfValueWaived = (productValue, waivedCount) => {
    if (productValue === 'W') {
        waivedCount++;
    }

    return waivedCount;
}

const setCoverageTypeCodes = (coverageType, viewCoverageIndicator) => {
    if (viewCoverageIndicator === true) {
        return (isEmpty(coverageType) || !(coverageTypes.includes(coverageType))) ? '' : stringToCoverageCode(coverageType);
    }

    return 'W';
};

/**
 * Converts the array of a row into the JSON of an employee object
 * @param {array} entry -> an array of values consisting of a row from the uploaded template
 * @param {string} orientation -> the orientation of the sheet, can be vertical or any other value
 */
export const setEmployee = (entry, orientation, effectiveDate, company, errorTracker, location) => {
    const emp = orientation === 'vertical' ? verticalEmp : horizontalEmp;
    const dofBValue = entry.values[emp.dob];
    const dofBCheck = isEmpty(dofBValue);
    const ageCheck = isEmpty(entry.values[emp.age]);
    const ageFormatCheck = !isNaN(entry.values[emp.age]);
    const outOfAreaMissingCheck = (isEmpty(entry.values[emp.outOfArea]) || !yesNoType.includes(entry.values[emp.outOfArea]));

    //Analyzing waiver reason data
    const medicalWaiverReasonValue = valueCheck(entry.values[emp.medicalWaiverReason]).value;
    const validWaiverReasonCheck = generateMedicalWaiverReasons(location[0].state).includes(medicalWaiverReasonValue);

    if (dofBCheck === true && ageCheck === true) {
        errorTracker.dofbAndAgeMissing = true;
        throw errorTracker;
    }

    // Checking date format
    if (!dofBCheck) {
        if (typeof dofBValue !== 'object') {
            if (typeof dofBValue !== 'string' || !(dofBValue.includes('/'))) {
                errorTracker.badDateFormat = true;
                throw errorTracker;
            }
        }
    }

    if (!ageCheck) {
        if (ageFormatCheck === false) {
            errorTracker.innapropriateAgeValue = true;
            throw errorTracker;
        }
    }

    //Check if Out of Area is Provided
    if (outOfAreaMissingCheck) {
        errorTracker.outOfAreaMissing = true;
        throw errorTracker;
    }


    const medicalCoverage = setCoverageTypeCodes(entry.values[emp.medical], company.selectedProducts.medical);
    const dentalCoverage = setCoverageTypeCodes(entry.values[emp.dental], company.selectedProducts.dental);
    const visionCoverage = setCoverageTypeCodes(entry.values[emp.vision], company.selectedProducts.vision);
    let lifeCoverage = setCoverageTypeCodes(entry.values[emp.life], company.selectedProducts.life);
    let suppLifeCoverage = setCoverageTypeCodes(entry.values[emp.life], company.selectedProducts.life);

    if (lifeCoverage != 'W'){
        lifeCoverage = 'EL' 
        suppLifeCoverage = 'EE'
    } 
    else lifeCoverage = 'W' 

    const stdCoverage = setCoverageTypeCodes(entry.values[emp.std], company.selectedProducts.std);
    const ltdCoverage = setCoverageTypeCodes(entry.values[emp.ltd], company.selectedProducts.ltd);

    //Determines which product types the user has selected to view
    let waivedProductCounter = 0;
    const selectedProductTypes = determineSelectedProductTypes(company.selectedProducts);

    //For each product type selected, see if it is waived for this employee and count it in the waivedProductCounter
    if (selectedProductTypes.includes('medical')) {
        waivedProductCounter = checkIfValueWaived(medicalCoverage, waivedProductCounter);
    }

    if (selectedProductTypes.includes('dental')) {
        waivedProductCounter = checkIfValueWaived(dentalCoverage, waivedProductCounter);
    }

    if (selectedProductTypes.includes('vision')) {
        waivedProductCounter = checkIfValueWaived(visionCoverage, waivedProductCounter);
    }

    if (selectedProductTypes.includes('life')) {
        waivedProductCounter = checkIfValueWaived(lifeCoverage, waivedProductCounter);
    }

    if (selectedProductTypes.includes('std')) {
        waivedProductCounter = checkIfValueWaived(stdCoverage, waivedProductCounter);
    }

    if (selectedProductTypes.includes('ltd')) {
        waivedProductCounter = checkIfValueWaived(ltdCoverage, waivedProductCounter);
    }
    
    const fileInputObj = {
        firstName: isEmpty(entry.values[emp.firstName]) ? '' : entry.values[emp.firstName],
        lastName: isEmpty(entry.values[emp.lastName]) ? '' : entry.values[emp.lastName],
        middleInitial:  isEmpty(entry.values[emp.middleInitial]) ? '' : entry.values[emp.middleInitial],
        suffix:  (isEmpty(entry.values[emp.suffix]) || !suffixType.includes(entry.values[emp.suffix])) ? '' : mapSuffix(entry.values[emp.suffix]),
        email: isEmpty(entry.values[emp.email]) ? '' : setEmail(entry.values[emp.email]),
        locationZipCode:  isEmpty(entry.values[emp.locationZipCode]) ? '' : checkZipCode(entry.values[emp.locationZipCode]),
        zipCode: isEmpty(entry.values[emp.zipCode]) ? '' : checkZipCode(entry.values[emp.zipCode]),
        dob: dofBCheck ? getDOBFromAge(entry.values[emp.age], handleExcelDate(effectiveDate)) : handleExcelDate(dofBValue),
        dobInput: !dofBCheck,
        age: ageCheck ? getAgeFromDOB(handleExcelDate(entry.values[emp.dob]), handleExcelDate(effectiveDate)) : entry.values[emp.age],
        coverages: {
            medical: medicalCoverage,
            dental: dentalCoverage,
            vision: visionCoverage,
            basicLife: lifeCoverage,
            suppLife: suppLifeCoverage,
            std: stdCoverage,
            ltd: ltdCoverage,
        },
        salary: (isEmpty(entry.values[emp.salary]) || typeof entry.values[emp.salary] === 'object') ? '' : entry.values[emp.salary],
        gender: (isEmpty(entry.values[emp.gender]) || !genderType.includes(entry.values[emp.gender])) ? '' : getGender(entry.values[emp.gender]),
        employeeStatus: (isEmpty(entry.values[emp.employeeStatus]) || !employeeStatusType.includes(entry.values[emp.employeeStatus])) ? 'Active' : entry.values[emp.employeeStatus],
        specialEmployeeStatus: (isEmpty(entry.values[emp.specialEmployeeStatus]) || !specialEmployeeStatusType.includes(entry.values[emp.specialEmployeeStatus])) ? 'None' : entry.values[emp.specialEmployeeStatus],
        outOfArea: entry.values[emp.outOfArea],
        dependents: [],
        productSelection: [],
        medicalCoverageInfo: {
            medicalWaiverReason: validWaiverReasonCheck ? entry.values[emp.medicalWaiverReason] : null,
            medicalWaived: validWaiverReasonCheck ? true : false,
            pcpTable: '',
        },
        waiveCoverage: {
            isWaivingAll: waivedProductCounter === selectedProductTypes.length ? 'Yes' : 'No',
            medicalWaiverReason: validWaiverReasonCheck ? entry.values[emp.medicalWaiverReason] : null,
        },
        waiveReason: validWaiverReasonCheck ? entry.values[emp.medicalWaiverReason] : null,
        optedLifeClass: ''
    };
    return fileInputObj
};

/**
 * Converts the array of a row into the JSON of an dependent object
 * @param {array} row -> an array of values consisting of a row from the uploaded template
 */
export const setDependent = (entry, orientation, source, effectiveDate, errorTracker) => {
    const dep = orientation === 'vertical' ? verticalDep : horizontalDep;
    const dofBValue = entry.values[dep.dob];
    const dofBCheck = isEmpty(dofBValue);
    const ageCheck =  isEmpty(entry.values[dep.age]);
    const ageFormatCheck = !isNaN(entry.values[dep.age]);

    //Analyzing waiver reason data
    const relationshipTypeValue = valueCheck(entry.values[dep.relationship]).value;
    const relationshipTypeCheck = relationshipType.includes(relationshipTypeValue);

    if (dofBCheck === true && ageCheck === true) {
        errorTracker.dofbAndAgeMissing = true;
        throw errorTracker;
    }

    // Checking date format
    if (!dofBCheck) {
        if (typeof dofBValue !== 'object') {
            if (typeof dofBValue !== 'string' || !(dofBValue.includes('/'))) {
                errorTracker.badDateFormat = true;
                throw errorTracker;
            }
        }
    }

    if (!ageCheck) {
        if (ageFormatCheck === false) {
            errorTracker.innapropriateAgeValue = true;
            throw errorTracker;
        }
    }

    return {
        relationship: relationshipTypeCheck ? convertTempRelationshipType(entry.values[dep.relationship]) : '',
        firstName: isEmpty(entry.values[dep.firstName]) ? '' : entry.values[dep.firstName],
        lastName:  isEmpty(entry.values[dep.lastName]) ? '' : entry.values[dep.lastName],
        middleInitial: isEmpty(entry.values[dep.middleInitial]) ? '' : entry.values[dep.middleInitial],
        suffix: (isEmpty(entry.values[dep.suffix]) || !suffixType.includes(entry.values[dep.suffix])) ? '' : mapSuffix(entry.values[dep.suffix]),
        zipCode: isEmpty(entry.values[dep.zipCode]) ? '' : checkZipCode(entry.values[dep.zipCode]),
        dob: dofBCheck ? getDOBFromAge(entry.values[dep.age], handleExcelDate(effectiveDate)) : handleExcelDate(dofBValue),
        dobInput: !dofBCheck,
        age: ageCheck ? getAgeFromDOB(handleExcelDate(entry.values[dep.dob]), handleExcelDate(effectiveDate)) : entry.values[dep.age],
        gender: (isEmpty(entry.values[dep.gender]) || !genderType.includes(entry.values[dep.gender])) ? '' : getGender(entry.values[dep.gender]),
    };

};

/**
 * Converts the array of a row into the JSON of an dependent object
 * @param {array} row -> an array of values consisting of a row from the uploaded template
 */
export const setCompany = (row, errorTracker) => {
    const companyNameErrorCheck = isEmpty(row[1]);
    const effectiveDateErrorCheck = isEmpty(row[2]);
    const quoteDentalCheck = (row[9] === 'Quote') ? true : false;
    const quoteVisionCheck = (row[10] === 'Quote') ? true : false;
    let quoteLifeCheck = (row[11] === 'Quote') ? true : false;
    const quoteSTDCheck = (row[12] === 'Quote') ? true : false;
    const quoteLTDCheck = (row[13] === 'Quote') ? true : false;
    const sicCodeErrorCheck = isEmpty(row[6]);

    if (companyNameErrorCheck === true || effectiveDateErrorCheck === true) {
        errorTracker.companyNameMissing = companyNameErrorCheck;
        errorTracker.effectiveDateMissing = effectiveDateErrorCheck;
        throw errorTracker;
    }

    // Checking date format
    if (typeof row[2] !== 'object') {
        if (typeof row[2] !== 'string' || !(row[2].includes('/'))) {
            errorTracker.badDateFormat = true;
            throw errorTracker;
        }
    }

    // checks if sic code is provided if the user has selected dental, vision, or life plans
    if ((quoteDentalCheck === true || quoteVisionCheck === true || quoteLifeCheck === true || quoteLTDCheck === true || quoteSTDCheck === true)
        && sicCodeErrorCheck === true) {
        errorTracker.sicCodeMissing = true;
        throw errorTracker;
    }

    const platform = store.getState().overall.platform;
    const blEffectiveDate = store.getState().overall.appConfigDetails.blEffectiveDate;
    const oxfordLifeActive = store.getState().overall.appConfigDetails.oxfordLifeActive;
    const excelDate = handleExcelDate(row[2]);

    if (new Date(excelDate) < new Date(blEffectiveDate) || !oxfordLifeActive) {
        quoteLifeCheck = false;
    }

    return {
        companyName: `${row[1]}`,
        effectiveDate: handleExcelDate(row[2]),
        firstName: isEmpty(row[3]) ? '' : row[3],
        lastName: isEmpty(row[4]) ? '' : row[4],
        email: isEmpty(row[5]) ? '' : setEmail(row[5]),
        sicData: sicCodeErrorCheck === true ? {} : {
            sicCode: row[6],
        },
        franchiseCode: isEmpty(row[7]) ? '' : row[7],
        selectedProducts: {
            medical: (row[8] === 'Quote') ? true : false,
            dental: quoteDentalCheck,
            vision: quoteVisionCheck,
            life: quoteLifeCheck,
            std: quoteSTDCheck,
            ltd: quoteLTDCheck
        },
    };
};

const setEmail = (email) => {
    if (typeof email === 'object') {
        return email.text;
    }

    return email;
};

const formatSSN = (ssn) => {
    const allDigitsRegex = new RegExp('^[0-9]{9}$');
    const ssnDashRegex = new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}$');

     //If SSN provided is all digits, transform to dash format.
    const allDigitsCheck = allDigitsRegex.test(ssn);
    if (allDigitsCheck) {
        const stringSsn = ssn.toString();
        const ssnPt1 = stringSsn.slice(0, 3);
        const ssnPt2 = stringSsn.slice(3, 5);
        const ssnPt3 = stringSsn.slice(5, 9);

        const transformedSsn = ssnPt1 + '-' + ssnPt2 + '-' + ssnPt3;
        return transformedSsn
    }

    const ssnDashTest = ssnDashRegex.test(ssn);
    if (ssnDashTest) {
        return ssn;
    }

    return ''
}

const formatPhoneNumber = (phoneNumber) => {
    const allDigitsRegex = new RegExp('^[0-9]{10}$');
    const phoneNumDashRegex = new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$');

     //If phone number provided is all digits, transform to dash format.
     const allDigitsCheck = allDigitsRegex.test(phoneNumber);
     if (allDigitsCheck) {
         const stringPhoneNumb = phoneNumber.toString();
         const phoneNumPt1 = stringPhoneNumb.slice(0, 3);
         const phoneNumPt2 = stringPhoneNumb.slice(3, 6);
         const phoneNumPt3 = stringPhoneNumb.slice(6, 10);

         const transformedPhoneNum = phoneNumPt1 + '-' + phoneNumPt2 + '-' + phoneNumPt3;
         return transformedPhoneNum
     }

     const phoneNumDashTest = phoneNumDashRegex.test(phoneNumber);
     if (phoneNumDashTest) {
         return phoneNumber;
     }
     return ''
}

export const isLastRow = (rowData) => {
    let lastRow = true;

    if (!(isEmpty(rowData[1]))) {
        lastRow = false;
    } else if (!(typeof rowData[2] === 'object')) {
        lastRow = false;
    } else if (!(isEmpty(rowData[3]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[4]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[5]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[6]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[7]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[8]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[9]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[10]))) {
        lastRow = false;
    } else if (!(isEmpty(rowData[11]))) {
        lastRow = false;
    } else if (!(typeof rowData[12] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[14] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[15] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[16] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[17] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[18] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[19] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[20] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[21] === 'object')) {
        lastRow = false;
    } else if (!(typeof rowData[22] === 'object')) {
        lastRow = false;
    }

    return lastRow;
};

/**
 *
 * @param {JSON} entry -> the array of values corresponding to an employee
 * @param {number} entryIndex -> current index of the loop through the rows/columns of the worksheet
 * @param {number} entryCheck -> The first row/column where employee info starts
 * @param {number} employeeCheck -> The cell where Employee/Dependent is stated
 * @param {Object[]} employees -> an array of created employee objects
 * @param {JSON} employee -> an employee JSON object
 */
export const createEmployeesObjects = (entry, orientation, entryIndex, entryCheck, employeeCheck,
    employees, employee, source, effectiveDate, company, errorTracker) => {
    // If type field not provided, we default it
    checkForSettingDefaultTypeField(entry, entryIndex, employeeCheck);

    if (entryIndex === entryCheck) {
        if (entry.values[employeeCheck] === 'Employee') {
            return setEmployee(entry, orientation, effectiveDate, company.companyProfile, errorTracker, company.locations);
        }
    }
 
    if (entryIndex > entryCheck) {
        if (entry.values[employeeCheck] === 'Employee') {
            const oldEntry = { ...employee };
            employees.push(oldEntry);
            return setEmployee(entry, orientation, effectiveDate, company.companyProfile, errorTracker, company.locations);
        }

        if (!(Object.keys(employee).length === 0 && employee.constructor === Object)) {
            const dep = setDependent(entry, orientation, source, effectiveDate, errorTracker);
            const emp = { ...employee };
            emp.dependents.push(dep);
            return emp;
        }
    }
};

const checkForSettingDefaultTypeField = (entry, entryIndex, employeeCheck) => {
    // If 'Type' not provided for first row we default to 'Employee' type
    if ((isEmpty(entry.values[employeeCheck])  && entryIndex === 4) || (!(memberType.includes(entry.values[employeeCheck])) && entryIndex === 4)) {
        entry.getCell(1).value = 'Employee';
    }

    // Any other empty 'Type' field defaulted to 'Dependent'
    if (isEmpty(entry.values[employeeCheck]) || (!(memberType.includes(entry.values[employeeCheck])) && entryIndex > 4)) {
        entry.getCell(1).value = 'Dependent';
    }
};

/**
 * Calls api to get zip code display object for field
 * @param {string} zipCode - Zip Code string
 */
export const getZipDisplay = (zipCode) => {
    if (!isEmpty(zipCode)) {
        return axios.get(`${apiRoutes.quickQuotes}/zip-mappings/${zipCode}`)
            .then((res) => {
                const thisZipObj = res.data[0];
                const location = {
                    ...thisZipObj,
                    display: makeZipObjDisplay(thisZipObj),
                };
                return location;
            })
            .catch((err) => {
                console.log(err);
                zipReturnError(err.response ? err.response.data.message : 'Something Went Wrong Fetching Zip Codes');
            });
    }
};

/**
 * Converts the array of a row into the JSON of an dependent object
 * @param {array} row -> an array of values consisting of a row from the uploaded template
 */
export const setLocation = (row, orientation, errorTracker) => {
    const loc = orientation === 'horizontal' ? horizontalLocation : verticalLocation;

    const zipCodeErrorCheck = isEmpty(row.values[loc.zipCode]);
    const stateErrorCheck = isEmpty(row.values[loc.state]);
    const totalEmpsErrorCheck = isEmpty(row.values[loc.totalEmps]);
    const totalEligErrorEmpsCheck = isEmpty(row.values[loc.totalEligEmps]);
    const atneErrorCheck = isEmpty(row.values[loc.atne]);
    const fteErrorCheck = isEmpty(row.values[loc.fte]);

    if (zipCodeErrorCheck === true || totalEmpsErrorCheck === true || totalEligErrorEmpsCheck === true || atneErrorCheck === true || fteErrorCheck === true || stateErrorCheck === true) {
        errorTracker.zipCodeMissing = !!zipCodeErrorCheck;
        errorTracker.totalEmployeesMissing = !!totalEmpsErrorCheck;
        errorTracker.totalEligEmployeesMissing = !!totalEligErrorEmpsCheck;
        errorTracker.atneMissing = !!atneErrorCheck;
        errorTracker.fteMissing = !!fteErrorCheck;
        errorTracker.stateMissing = !!stateErrorCheck;
        throw errorTracker;
    }

    return {
        zipData: {
            zipCode: checkZipCode(row.values[loc.zipCode]),
        },
        state: row.values[loc.state],
        totalEmps: row.values[loc.totalEmps],
        totalEligEmps: row.values[loc.totalEligEmps],
        atne: row.values[loc.atne],
        primaryInd: true,
        fte: row.values[loc.fte],
    };
};

/**
 * Calls api to get sic code display for field
 * @param {string} sicCode - Sic Code string
 */
export const getSicObj = (sicCode) => {
    if (!isEmpty(sicCode)) {
        return axios.get(`${apiRoutes.mongo}/sic-codes/${sicCode}`)
            .then((res) => res.data.sicCodes[0])
            .catch((err) => {
                console.log(err);
                sicReturnError(err.response ? err.response.data.message : 'Something Went Wrong With SIC Code Lookup');
            });
    }
};

/**
 * Handles the saving of the imported template to the state
 * @param {object} company - A company profile object
 * @param {array} employees - An array of employee objects with their dependents
 * @param {callback} resolve - callback for import to await getSicObj and getZipDisplay
 * @param  {String} source - determines which census is being updated
 */
export const setCompanyProfile = async (company, employees, resolve, source) => { 
    const employeesArray = company.locations.map((location) => null); // null array with length = num locations

    await Promise.all(company.locations.map(async (location, index) => {
        location.id = index;
        const locationDisplay = await getZipDisplay(location.zipData.zipCode, company.locations);
        if (!isEmpty(locationDisplay) && location.zipData.zipCode === locationDisplay.zipCode) {
            location.zipData = locationDisplay;
        }

        // Add this commented code back in for multiple locations import
        // employees.forEach((employee) => {
        //     if (!isEmpty(employee)) {
        //         if (isEmpty(employee.locationZipCode)) {
        //             employee.locationZipCode = company.locations[0].zipData.zipCode;
        //         }
        //     }
        // });

        // let employeeArray = [];
        // if (!isEmpty(employees[0])) {
        //     employeeArray = employees.filter((employee) => employee.locationZipCode === location.zipData.zipCode);
        // }

        // Remove below code when allowing for multiple locations
        let employeeArray = employees.map((employee) => {
            if (!isEmpty(employee)) {
                employee.locationZipCode = company.locations[0].zipData.zipCode;
                return employee;
            }
        });
        // -------------------------------------------------------

        const numImportedEmployees = employeeArray.length;
        const numExpectedEmployees = parseInt(location.totalEmps);
        const employeeDif = numExpectedEmployees - numImportedEmployees;
        if (employeeDif > 0) {
            for (let i = 0; i < employeeDif; i++) {
                employeeArray.push(newEmployee);
            }
        }
        employeesArray[index] = employeeArray;
    }));

    const emptyLocationsDefault = {
        atne: '',
        atneError: false,
        fte: '',
        fteError: false,
        fteRule: '',
        id: 0,
        primaryInd: false,
        totalEligEmps: '',
        totalEligEmpsError: false,
        totalEmps: '',
        totalEmpsError: false,
        zipData: {},
        zipError: false,
    };

    if (company.locations.length === 0) {
        company.locations.push(emptyLocationsDefault);
    }

    let companyName; let firstName; let lastName; let email; let
        franchiseCode;
    let sicData = {};
    const { locations } = company;
    let finalEffectiveDate = '';
    const dateObj = {};
    let selectedProducts = {
        medical: false,
        dental: false,
        vision: false,
        life: false,
        std: false,
        ltd: false,
        suppLife: false,
    };
    
    if (company.companyProfile) {
        const { companyProfile } = company;

        companyName = companyProfile.companyName;

        firstName = companyProfile.firstName;
        lastName = companyProfile.lastName;
        email = companyProfile.email;

        if (!isEmpty(companyProfile.effectiveDate)) {
            const effectiveDate = new Date(companyProfile.effectiveDate);
            const month = moment(effectiveDate).month() + 1 < 10 ? `0${moment(effectiveDate).month() + 1}` : `${moment(effectiveDate).month() + 1}`;
            const day = moment(effectiveDate).date() < 10 ? `0${moment(effectiveDate).date()}` : `${moment(effectiveDate).date()}`;
            const year = moment(effectiveDate).year();
            finalEffectiveDate = `${year}-${month}-${day}`;
            const monthDayYear = `${month}/${day}/${year}`;
            const dateObj = {
                value: effectiveDate,
                month,
                year,
                day,
                yearMonthDay: finalEffectiveDate,
                monthDayYear,
            };
        }

        if (!isEmpty(companyProfile.sicData.sicCode)) {
            const sicObj = await getSicObj(companyProfile.sicData.sicCode);

            if (isEmpty(sicObj)) {
                sicData = {}
            } else {
                sicData = {
                    ...sicObj,
                    display: `${sicObj.sicCode} - ${sicObj.description}`,
                };
            }
        }

        franchiseCode = companyProfile.franchiseCode;
        selectedProducts = companyProfile.selectedProducts;
    }

    const simpleCensus = {
        ee: '',
        ec: '',
        es: '',
        ef: '',
        nc: '',
        ooa: false,
    };

    const companyProfile = {
        companyName,
        primaryContact: {
            firstName,
            lastName,
            email,
        },
        effectiveDate: finalEffectiveDate,
        date: dateObj,
        sicData,
        franchiseCode,
        locations,
        employees: employeesArray,
        groupSize: employees.length,
        simpleCensus,
        selectedProducts,
        // importData: company.importData
    };
    resolve(companyProfile);
};

const isEditable = (value, validations = [], fullProps = {}) => {
    if (value === '' || value === null || value === undefined || value === 'N/A' || value === 'Not available for Enrollment') {
        return true;
    }
    if (typeof value === 'object') {
        if ((value.formula || value.sharedFormula) && !value.result) {
            return true;
        } if ((value.formula || value.sharedFormula) && value.result) {
            if (value.result !== 'Not available for Enrollment' && value.result !== 'N/A') {
                return false;
            }
            return true;
        }
    }
    if (validations.length > 0) {
        const returnedVal = checkValidationsOnInput(value, validations, fullProps);
        if (returnedVal.error !== null) {
            return true;
        }
    }

    return false;
};

const errorCheck = (value, validations = [], fullProps = {}, required) => {
    if (!required) {
        return { error: null };
    }
    if (validations.length > 0) {
        const returnedVal = checkValidationsOnInput(value, validations, fullProps);
        if (returnedVal.error) {
            return { isError: true, error: returnedVal.error };
        }
    }
    return {};
};

const valueCheck = (value, validations = [], fullProps = {}) => {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value === "N/A" ||
    value === "Not available for Enrollment" ||
    value === "Invalid DateTime"
  ) {
    return { value: "" };
  }

  if (typeof value === 'object') {
    if ((value.formula || value.sharedFormula) && !value.result) {
        return { value: '' };
    } if ((value.formula || value.sharedFormula) && value.result) {
        if (value.result !== 'Not available for Enrollment' && value.result !== 'N/A') {
            return { value: value.result };
        }
        return { value: '' };
    }
  }

  if (validations.length > 0) {
      const returnedVal = checkValidationsOnInput(value, validations, fullProps);
      return { isError: true, error: returnedVal.error, value: returnedVal.value };
  }

  return { value };
}


const generateMedicalPlanCodes = (medicalPlans) => {
    const codes = [];

    if (medicalPlans) {
        for (const plan of medicalPlans) {
            if (plan.customName) {
                codes.push({ value: `${plan.customName} - ${plan.codeDisplay}`, text: `${plan.customName} - ${plan.codeDisplay}` });
            } else {
                codes.push({ value: plan.codeDisplay, text: plan.codeDisplay });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Medical Coverage' });
    
    return codes;
};

const generateDentalPlanCodes = (dentalPlans) => {
    const codes = [];

    if (dentalPlans.length > 0) {
        for (const plan of dentalPlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.code, text: plan.code });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Dental Coverage' });

    return codes;
};

const generateVisionPlanCodes = (visionPlans) => {
    const codes = [];

    if (visionPlans.length > 0) {
        for (const plan of visionPlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.code, text: plan.code });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Vision Coverage' });

    return codes;
};

const generateLifePlanCodes = (lifePlans) => {
    const codes = [];

    if (lifePlans.length > 0) {
        for (const plan of lifePlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.code, text: plan.code });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Life Coverage' });
    return codes;
};

const generateSuppLifePlanCodes = (suppLifePlans) => {
    const codes = [];
    if (suppLifePlans.length > 0) {
        for (const plan of suppLifePlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.code, text: plan.code });
            }
        }
    }
    codes.push({ value: 'waive', text: 'Waive Supplemental Life Coverage' });
    return codes;
};

const generateSpouseSuppLifePlanCodes = (suppLifePlans) => {
    const codes = [];

    if (suppLifePlans.length > 0) {
        for (const plan of suppLifePlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.SLSpPlanCode, text: plan.SLSpPlanCode });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Supplemental Life Spouse Coverage' });
    return codes;
};

const generateChildSuppLifePlanCodes = (suppLifePlans) => {
    const codes = [];

    if (suppLifePlans.length > 0) {
        for (const plan of suppLifePlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.SLChPlanCode, text: plan.SLChPlanCode });
            }
        }
    }
    codes.push({ value: 'waive', text: 'Waive Supplemental Life Child Coverage' });
    return codes;
};

  const generateSTDPlanCodes = (stdPlans) => {
  const codes = [];

  if (stdPlans.length > 0) {
      for (const plan of stdPlans) {
          if (plan.customName) {
              codes.push({ value: plan.customName, text: plan.customName });
          } else {
              codes.push({ value: plan.code, text: plan.code });
          }
      }
  }

  codes.push({ value: 'waive', text: 'Waive Short Term Disability Coverage' });
  return codes;
};

const generateDepLifePlanCodes = (dependentLifePlans) => {
    const codes = [];

    if (dependentLifePlans.length > 0) {
        for (const plan of dependentLifePlans) {
            if (plan.customName) {
                codes.push({ value: plan.customName, text: plan.customName });
            } else {
                codes.push({ value: plan.code, text: plan.code });
            }
        }
    }

    codes.push({ value: 'waive', text: 'Waive Dependent Life Coverage' });
    return codes;
};

const generateLTDPlanCodes = (ltdPlans) => {
  const codes = [];

  if (ltdPlans && ltdPlans.length > 0) {
      for (const plan of ltdPlans) {
          if (plan.customName) {
              codes.push({ value: plan.customName, text: plan.customName });
          } else {
              codes.push({ value: plan.code, text: plan.code });
          }
      }
  }

  codes.push({ value: 'waive', text: 'Waive Long Term Disability Coverage' });
  return codes;
};

const mapSuffix = (suffixValue) => {
    switch (suffixValue) {
        case 'Jr.':
            return 'JR';
        case 'Sr.':
            return 'SR';
        case 'I':
            return '1';
        case 'II':
            return '2';
        case 'III':
            return '3';
        case 'IV':
            return '4';
        case 'V':
            return '5';
        case 'VI':
            return '6';
        case 'VII':
            return '7';
        case 'VIII':
            return '8';
    }
};

const generateMedicalWaiverReasons = (stateCode) => {
    let options = [];

    switch (stateCode) {
        case 'CT':
            options= [
                "At-no-cost government plan",
                "Individual",
                "Individual Exchange",
                "Medicaid",
                "Medicare",
                "Other Federeal or State Sponsored Health Plans",
                "Parents",
                "Spousal",
                "VA",
                "Other (reason not listed)"
            ]
            return options;
        case 'NY':
            options = [
                "Medicaid",
                "Medicare",
                "Other group coverage",
                "Parents",
                "Spousal",
                "VA",
                "Other (reason not listed)"
            ]
            return options;
        case 'NJ':
            options = [
                "Medicaid",
                "Medicare",
                "NJ FamilyCare",
                "Other group coverage",
                "Parents",
                "Spousal",
                "Tricare",
                "Other (reason not listed)"
            ]
            return options;
        case 'NH':
            options = [
                "Champus (Military)",
                "COBRA from previous employer",
                "Medicaid",
                "Medicare",
                "Other group coverage from a different employer",
                "Parents",
                "Religious Beliefs",
                "Spousal",
                "Tricare",
                "VA",
            ]
            return options;
        default:
            return [];
    }
};

const generateOtherCarrierNames = (stateCode) => {
    let options = [];

    switch (stateCode) {
        case 'CT':
            options= [
                'AETNA',
                'ADMINISTAFF',
                'ANTHEM',
                'HEALTHNET',
                'HUMANA',
                'KAISER',
                'STARMARK',
                'MEDICAL MUTUAL',
                'ASSURANT',
                'WELLPATH',
                'CAREFIRST',
                'FEDERATED',
                'REGENCE',
                'TRUSTMARK',
                'FIDELITY',
                'MEDICA',
                'COVENTRY',
                'BLUE CROSS/BLUE SHIELD',
                'BLUE CROSS ONLY',
                'BLUE SHIELD ONLY',
                'CONNECTICARE (CTCARE)',
                'HARVARD PILGRIM'
            ]
            return options;
        case 'NY':
            options = [
                'AETNA',
                'ADMINISTAFF',
                'ANTHEM',
                'HEALTHNET',
                'HUMANA',
                'KAISER',
                'STARMARK',
                'MEDICAL MUTUAL',
                'ASSURANT',
                'WELLPATH',
                'CAREFIRST',
                'FEDERATED',
                'REGENCE',
                'TRUSTMARK',
                'FIDELITY',
                'MEDICA',
                'COVENTRY',
                'BLUE CROSS/BLUE SHIELD',
                'BLUE CROSS ONLY',
                'BLUE SHIELD ONLY',
                'ABEL',
                'ADP',
                'ALCOTT',
                'CDPHP',
                'CLEAR',
                'CO-ADVANTAGE',
                'ENGAGE',
                'EXCELLUS',
                'GOOD DEMOGRAPHICS',
                'HEALTHFIRST',
                'IDILUS',
                'INSPERITY',
                'JUSTWORKS',
                'MVP',
                'OASIS',
                'OSCAR',
                'PERSTIGE',
                'SEQUIOA',
                'TRINET'
            ]
            return options;
        case 'NJ':
            options = [
                'AETNA',
                'ADMINISTAFF',
                'ANTHEM',
                'HEALTHNET',
                'HUMANA',
                'KAISER',
                'STARMARK',
                'MEDICAL MUTUAL',
                'ASSURANT',
                'WELLPATH',
                'CAREFIRST',
                'FEDERATED',
                'REGENCE',
                'TRUSTMARK',
                'FIDELITY',
                'MEDICA',
                'COVENTRY',
                'BLUE CROSS/BLUE SHIELD',
                'BLUE CROSS ONLY',
                'BLUE SHIELD ONLY',
                'AFFILIATED PHYSICIANS AND EMPLOYERS',
                'AMERIHEALTH',
                'HORIZON',
                'OSCAR'
            ]
            return options;
        case 'NH':
            options = [
                'AETNA',
                'ADMINISTAFF',
                'ANTHEM',
                'HEALTHNET',
                'HUMANA',
                'KAISER',
                'STARMARK',
                'MEDICAL MUTUAL',
                'ASSURANT',
                'WELLPATH',
                'CAREFIRST',
                'FEDERATED',
                'REGENCE',
                'TRUSTMARK',
                'FIDELITY',
                'MEDICA',
                'COVENTRY',
                'BLUE CROSS/BLUE SHIELD',
                'BLUE CROSS ONLY',
                'BLUE SHIELD ONLY'
            ]
            return options;
        default:
            return [];
    }
};

const convertTempRelationshipType = (excelRelationshipSelection) => {

    switch (excelRelationshipSelection) {
        case 'Civil Union Partner (CT/NJ Only)':
            return 'Civil Union Partner';
        case 'Dependent Child (NJ/NH Only)':
            return 'Dependent Child';
        case 'Child (CT/NY Only)':
                return 'Child';
        default:
            return excelRelationshipSelection;
    }

}

/**
 * Returns the age of the employee from the field, or calculates
 * it from the DOB
 * @param {number | null} empAge - employee info tab age value
 * @param {string} empDOB - employee info dob value
 * @param {string} effectiveDate - effective date of the quote
 * @returns age of employee
 */
const calculateAge = (empAge, empDOB, effectiveDate) => {
  if (!isEditable(empAge)) {
    return empAge;
  }
  return getAgeFromDOB(
    valueCheck(handleExcelDate(empDOB)).value,
    handleExcelDate(effectiveDate)
  );
};

/**
 * Checks the enrollment row DOB, if Waive All Products is selected, use the
 * employee DOB from the employee info tab
 * @param {object} enrollDOB - DOB excel object from enrollment info tab
 * @param {object} empInfoDOB - DOB excel object from employee info tab
 * @param {number | undefined} empInfoAge - Age excel value from employee info tab
 * @param {object} fullProps - full redux object
 * @returns empty string or date as a string
 */
const getImportEnrollDOB = (enrollDOB, empInfoDOB, empInfoAge, effectiveDate, fullProps) => {
  const enrollDOBValue = valueCheck(
    handleExcelDate(enrollDOB),
    [
      {
        validationType: "date",
      },
    ],
    fullProps
  ).value;
  if (enrollDOBValue === "") {
    if (empInfoDOB === undefined) {
      return getDOBFromAge(empInfoAge, effectiveDate);
    }
    return valueCheck(
      handleExcelDate(empInfoDOB),
      [
        {
          validationType: "date",
        },
      ],
      fullProps
    ).value;
  }
  return enrollDOBValue;
};

/**
 * Retrieves the requested value for a cell from employee info or enrollment info from template
 * @param {Object} empEntry - employee info tab information for a specific row
 * @param {Object} enrollEntry - enrollment info tab information for a specific row
 * @param {number} empMap - column value for the employee info tab
 * @param {number} enrollMap - column value for enrollment info tab
 * @returns requested value as a string
 */
 const getEnrolleeValue = (empEntry, enrollEntry, empMap, enrollMap) => {
  const enrollEntryVal = valueCheck(enrollEntry.values[enrollMap]).value;
  if (enrollEntryVal === "") {
    return valueCheck(empEntry.values[empMap]).value;
  }
  return enrollEntryVal;
};

export const generateImportTableData = (entry, orientation, entryIndex, entryCheck, employeeCheck, effectiveDate, enrollEntry, enrolledPlans, dentalPlans, visionPlans, lifePlans, dependentLifePlans, suppLifePlans, dependentSuppLifePlans, stdPlans, ltdPlans, stateCode, selectedProducts, fullProps) => {
     // If adding or modifying fields/ field locations, make sure to edit the emp and enroll objects directly below this comment.
    const emp = orientation === 'vertical' ? verticalEmp : horizontalEmp;
    const enroll = orientation === 'vertical' ? verticalEnroll : horizontalEnroll;
    const dep = orientation === 'vertical' ? verticalDep : horizontalDep;
    
    if (entryIndex >= entryCheck) {

        const typeValue = valueCheck(entry.values[employeeCheck]).value;
        const typeValueCheck = (typeValue === '' || !memberType.includes(typeValue)) ? '' : typeValue;

        const enrolleeSuffix = valueCheck(enrollEntry.values[enroll.suffix]).value;
        const enrolleeNoSSNReason = valueCheck(enrollEntry.values[enroll.noSSNReason]).value;
        const enrolleeGender = valueCheck(entry.values[emp.gender]).value ? valueCheck(entry.values[emp.gender]).value :
        getEnrolleeValue(
          entry,
          enrollEntry,
          emp.gender,
          enroll.gender
        );
        const enrolleeAnyOtherCoverage = valueCheck(enrollEntry.values[enroll.anyOtherCoverage]).value;
        const enrolleeNameOtherCarrier = valueCheck(enrollEntry.values[enroll.nameOtherCarrier]).value;
        const enrolleeMedicarePrimary = valueCheck(enrollEntry.values[enroll.medicarePrimary]).value;
        const enrolleeMedicareReason = valueCheck(enrollEntry.values[enroll.medicareReason]).value;
        const enrolleeStreetAddress = valueCheck(enrollEntry.values[enroll.streetAddress]).value;
        const enrolleeSsn = valueCheck(enrollEntry.values[enroll.ssnTin]).value;
        const enrolleeCity = valueCheck(enrollEntry.values[enroll.city]).value;
        const enrolleeState = valueCheck(enrollEntry.values[enroll.state]).value;
        const enrolleeCounty = valueCheck(enrollEntry.values[enroll.county]).value;
        const enrolleeEmail = valueCheck(enrollEntry.values[enroll.email]).value;
        const enrolleePhoneNumber = valueCheck(enrollEntry.values[enroll.telephone]).value;
        const enrolleePhoneType = valueCheck(enrollEntry.values[enroll.phoneType]).value;
        // const enrolleeMedicalPlan = valueCheck(enrollEntry.values[enroll.medicalPlanCode]).value;
        // const enrolleeDentalPlan = valueCheck(enrollEntry.values[enroll.dentalPlanCode]).value;
        // const enrolleeVisionPlan = valueCheck(enrollEntry.values[enroll.visionPlanCode]).value;
        const enrolleeRelationshipType = valueCheck(entry.values[dep.relationship]).value;
        const enrolleeLifePlan = valueCheck(enrollEntry.values[enroll.lifePlanCode]).value;
        const enrolleeSuppLifePlan = valueCheck(enrollEntry.values[enroll.suppLifePlanCode]).value;
        const enrolleeMedicalPlan = valueCheck(entry.values[emp.medical]).value === 'Waive' ? 'waive' : valueCheck(enrollEntry.values[enroll.medicalPlanCode]).value;
        const enrolleeDentalPlan = valueCheck(entry.values[emp.dental]).value === 'Waive' ? 'waive' : valueCheck(enrollEntry.values[enroll.dentalPlanCode]).value;
        const enrolleeVisionPlan = valueCheck(entry.values[emp.vision]).value === 'Waive' ? 'waive' : valueCheck(enrollEntry.values[enroll.visionPlanCode]).value;
       
        const enrolleeDependentLifePlan = valueCheck(enrollEntry.values[enroll.dependentLifePlanCode]).value;
        const enrolleeSpouseSuppLifePlan = valueCheck(enrollEntry.values[enroll.suppDepLifeSpousePlanCode]).value;
        const enrolleeChildSuppLifePlan = valueCheck(enrollEntry.values[enroll.suppDepLifeChildPlanCode]).value; 
        let enrolleeDependentSuppLifePlan = '';
        if (enrolleeRelationshipType === 'Child (CT/NY Only)' || enrolleeRelationshipType === 'Dependent Child (NJ/NH Only)'){
            enrolleeDependentSuppLifePlan = valueCheck(enrollEntry.values[enroll.suppDepLifeChildPlanCode]).value;
        } else enrolleeDependentSuppLifePlan = valueCheck(enrollEntry.values[enroll.suppDepLifeSpousePlanCode]).value;
       
        const enrolleeSTDPlan = valueCheck(enrollEntry.values[enroll.stdPlanCode]).value;
        const enrolleeLTDPlan = valueCheck(enrollEntry.values[enroll.ltdPlanCode]).value;
        const enrolleeAssignmentMethod = valueCheck(enrollEntry.values[enroll.assignmentMethod]).value;
        const enroleePcpCode = valueCheck(enrollEntry.values[enroll.pcpCode]).value;
        const enrolleeExistingPatient = valueCheck(enrollEntry.values[enroll.existingPatient]).value;
        //const enrolleeMedicalWaiverReason = valueCheck(enrollEntry.values[enroll.medicalWaiverReason]).value;
        const enrolleeMedicalWaiverReason = enrolleeMedicalPlan === 'waive' ?  valueCheck(entry.values[emp.medicalWaiverReason]).value : valueCheck(enrollEntry.values[enroll.medicalWaiverReason]).value;
        const enrolleeEmployeeStatus = getEnrolleeValue(
          entry,
          enrollEntry,
          emp.employeeStatus,
          enroll.employeeStatus
        );
        const enrolleeSpecialEmployeeStatus = valueCheck(entry.values[emp.specialEmployeeStatus]).value ? valueCheck(entry.values[emp.specialEmployeeStatus]).value :
        getEnrolleeValue(
            entry,
            enrollEntry,
            emp.specialEmployeeStatus,
            enroll.specialEmployeeStatus
        )
        const enrolleeClass = valueCheck(enrollEntry.values[enroll.class]).value;
        const enrolleeOutOfArea = valueCheck(enrollEntry.values[enroll.outOfArea]).value;
        const enrolleeisDisabled = valueCheck(enrollEntry.values[enroll.isDisabled]).value;
        const enrolleeDisabilityType = valueCheck(enrollEntry.values[enroll.disabilityType]).value;

        const enrolleeDateOfHire = handleExcelDate(valueCheck(enrollEntry.values[enroll.dateOfHire]).value);
        const enrolleeMedicarePartAStartDate = handleExcelDate(valueCheck(enrollEntry.values[enroll.medicarePartAStartDate]).value);
        const enrolleeMedicarePartBStartDate = handleExcelDate(valueCheck(enrollEntry.values[enroll.medicarePartBStartDate]).value);
        const enrolleeMedicarePartDStartDate = handleExcelDate(valueCheck(enrollEntry.values[enroll.medicarePartDStartDate]).value);
        const enrolleeCobraStartDate = handleExcelDate(valueCheck(enrollEntry.values[enroll.cobraStartDate]).value);
        const enrolleeCobraEndDate = handleExcelDate(valueCheck(enrollEntry.values[enroll.cobraEndDate]).value);
        const selectedMedicalPlansArray = fullProps.quotes.selectedPlans.length > 0 ? fullProps.quotes.selectedPlans : [];
        const selectedDentalPlansArray = fullProps.dental && fullProps.dental.selectedPlans.length > 0? fullProps.dental.selectedPlans : []
        const selectedVisionPlansArray = fullProps.vision && fullProps.vision.selectedPlans.length > 0 ? fullProps.vision.selectedPlans : []
        const selectedLifePlansArray = fullProps.life && fullProps.life.selectedPlans.length > 0 ? fullProps.life.selectedPlans : []
        const selectedLifeDepPlansArray = fullProps.lifeDep && fullProps.lifeDep.selectedPlans.length > 0 ? fullProps.lifeDep.selectedPlans : []
        const selectedSuppLifePlansArray = fullProps.suppLife && fullProps.suppLife.selectedPlans.length > 0 ? fullProps.suppLife.selectedPlans : []
        const selectedSuppLifeDepPlansArray = fullProps.suppLifeDep && fullProps.suppLifeDep.selectedPlans.length > 0 ? fullProps.suppLifeDep.selectedPlans : []
        const platform = store.getState().overall.platform

        // funcitionizing the required flag for the various products for both employees and dependents
        // product: string, e.g. 'medical'
        // selectedPlanArray: corresponding array containing selectedPlans to determine if we are past quote review page
        // enrolledPlanArray: determining if we are in enrollment

        const isRequiredField = (product, selectedPlanArray, enrolledPlanArray, checkWaivingAll) => {
            if (checkWaivingAll) {
                if (isWaivingAll === 'Yes') {
                    return false
                } else {
                    if (selectedProducts.includes(product)) {
                        if (selectedPlanArray.length > 0) {
                            if (!isEmpty(enrolledPlanArray) && enrolledPlanArray.length > 0) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return true
                        }
                    } else {
                        return false
                    }
                }
            } else {
                if (selectedProducts.includes(product)) {
                    if (selectedPlanArray.length > 0) {
                        if (!isEmpty(enrolledPlanArray) && enrolledPlanArray.length > 0) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            }
        }

        if (typeValueCheck === 'Employee') {
            const isWaivingAll = isEmpty(enrollEntry.values[enroll.isWaivingAll]) ||
                                        enrollEntry.values[enroll.isWaivingAll] === 'No' ||
                                        !waivingAllType.includes(enrollEntry.values[enroll.isWaivingAll]) ? 'No' : 'Yes';
            const fileInputObj = {
                employeeType: {
                    value: 'Employee',
                    
                },
                employeeInfo: {
                    firstName: {
                        value: valueCheck(entry.values[emp.firstName]).value === '' ? '' : valueCheck(entry.values[emp.firstName]).value,
                        required: true,
                    },
                    lastName: {
                        value: valueCheck(entry.values[emp.lastName]).value === '' ? '' : valueCheck(entry.values[emp.lastName]).value,
                        required: true,
                    },
                    middleInitial: {
                        value: valueCheck(enrollEntry.values[enroll.middleInitial]).value === '' ? '' : valueCheck(enrollEntry.values[enroll.middleInitial]).value,
                        required: false,
                    },
                    suffix: {
                        value: (enrolleeSuffix === '' || !suffixType.includes(enrolleeSuffix)) ? '' : mapSuffix(enrolleeSuffix),
                        required: false,
                    },
                    ssnTin: {
                        value: enrolleeSsn === '' ? null : formatSSN(enrolleeSsn),
                        // Adding extra check specific to SSN for LW
                        required: isWaivingAll === 'Yes' ? false : !!isEditable(enrollEntry.values[enroll.noSSNReason]),
                        validations: [
                            {
                                validationType: 'fullSsn',
                            },
                        ],
                        error: {
                            error: errorCheck(formatSSN(enrolleeSsn), [{ validationType: 'fullSsn' }], fullProps, isWaivingAll === 'Yes' ? false : !!isEditable(enrollEntry.values[enroll.noSSNReason])).error
                                ? errorCheck(formatSSN(enrolleeSsn), [{ validationType: 'fullSsn' }], fullProps, isWaivingAll === 'Yes' ? false : !!isEditable(enrollEntry.values[enroll.noSSNReason])).error : null,
                        },
                        // For future use of dynamic required fields
                        // conditionalRequired: {
                        //     condition: '',
                        //     fields: ['noSSNReason']
                        // }
                    },
                    noSSNReason: {
                        value: (enrolleeNoSSNReason === '' || !noSSNType.includes(enrolleeNoSSNReason)) ? '' : enrolleeNoSSNReason,
                        required: false,
                        dropdown: true,
                        dropdownValues: [
                            'Expatriate',
                            'Newborn',
                            'Refusal to Provide',
                            'Personal ID Number Satisfied',
                            'Unknown',
                        ],
                    },
                    dob: {
                      value: getImportEnrollDOB(
                        entry.values[enroll.dob],
                        entry.values[emp.dob],
                        entry.values[emp.age],
                        handleExcelDate(effectiveDate),
                        fullProps
                      ),
                      required: isWaivingAll !== "Yes",
                      validations: [
                        {
                          validationType: "date",
                        },
                      ],
                      error: {},
                    },
                    age: {
                        value: calculateAge(
                          entry.values[emp.age],
                          entry.values[emp.dob],
                          effectiveDate
                        ),
                        required: isWaivingAll !== 'Yes',
                        validations: [
                            {
                                validationType: 'digitsOnly',
                            },
                        ],
                        error: {},
                    },
                    gender: {
                        value: (enrolleeGender === '' || !genderType.includes(enrolleeGender)) ? '' : getGender(enrolleeGender),
                        required: isWaivingAll !== 'Yes',
                        dropdown: true,
                        dropdownValues: stateCode === 'NY' ? ['Male', 'Female', 'Non-Binary'] : ['Male', 'Female'],
                    },
                    numberOfHoursWorked: {
                        value: valueCheck(enrollEntry.values[enroll.numberOfHoursWorked]).value === '' ? null : valueCheck(enrollEntry.values[enroll.numberOfHoursWorked]).value,
                        required: isWaivingAll === 'Yes' ? false : enrolleeEmployeeStatus === 'Active',
                        validations: [
                            {
                                validationType: 'inputRegex',
                                value: '^([0-9]|[1-3][0-9]|40)$',
                                regexDescription: '0 to 40',
                            },
                        ],
                    },
                    salary: {
                        value: valueCheck(enrollEntry.values[enroll.salary]).value === '' ? '' : valueCheck(enrollEntry.values[enroll.salary]).value,
                        required: (lifePlans.length > 0 && lifePlans[0]?.benefitDetails && lifePlans[0]?.benefitDetails?.benefitType === "Multiple of Salary") ? true : false,
                    },
                    optedLifeClass: {
                        value: ''
                    },
                    employeeStatus: {
                        value: (enrolleeEmployeeStatus === '' || !employeeStatusType.includes(enrolleeEmployeeStatus)) ? '' : enrolleeEmployeeStatus
                    },
                    specialEmployeeStatus: { 
                        value: (enrolleeSpecialEmployeeStatus === '' || !specialEmployeeStatusType.includes(enrolleeSpecialEmployeeStatus)) ? 'None' : enrolleeSpecialEmployeeStatus,
                        required: false,
                        dropdown: true,
                        dropdownValues: ['K1', 'Contractor', 'None']
                    },
                    dateOfHire: {
                        value: enrolleeDateOfHire,
                        required: isWaivingAll !== 'Yes',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                        error: {},
                    },
                    anyOtherCoverage: {
                        value: (enrolleeAnyOtherCoverage === '' || !yesNoType.includes(enrollEntry.values[enroll.anyOtherCoverage])) ? '' : enrolleeAnyOtherCoverage,
                        required: isWaivingAll !== 'Yes',
                        dropdown: true,
                    },
                    nameOtherCarrier: {
                        value: (enrolleeNameOtherCarrier === '' || !generateOtherCarrierNames(stateCode).includes(enrolleeNameOtherCarrier)) ? '' : enrolleeNameOtherCarrier,
                        dropdown: true,
                        dropdownValues: generateOtherCarrierNames(stateCode)
                    },
                    medicarePrimary: {
                        value: (enrolleeMedicarePrimary === '' || !yesNoType.includes(enrollEntry.values[enroll.medicarePrimary])) ? '' : enrolleeMedicarePrimary,
                        required: isWaivingAll !== 'Yes',
                        dropdown: true
                    },
                    medicareReason: {
                        value: (enrolleeMedicareReason === '' || !medicareReasonType.includes(enrollEntry.values[enroll.medicareReason])) ? '' : enrolleeMedicareReason,
                        required: enrolleeMedicarePrimary === 'Yes',
                        dropdown: true,
                        dropdownValues: ['Age', 'Disability (not actively at work)', 'End stage renal disease (ESRD)', 'Disabled but actively at work', 'Unknown'],
                    },
                    medicareClaimNum: {
                        value: valueCheck(enrollEntry.values[enroll.medicareClaimNumber]).value === '' ? null : valueCheck(enrollEntry.values[enroll.medicareClaimNumber]).value,
                        required: enrolleeMedicarePrimary === 'Yes',
                    },
                    medicarePartAStartDate: {
                        value: enrolleeMedicarePartAStartDate,
                        required: enrolleeMedicarePrimary === 'Yes',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                    },
                    medicarePartBStartDate: {
                        value: enrolleeMedicarePartBStartDate,
                        required: enrolleeMedicarePrimary === 'Yes',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                    },
                    medicarePartDStartDate: {
                        value: enrolleeMedicarePartDStartDate,
                        required: enrolleeMedicarePrimary === 'Yes',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                    },
                    cobraStartDate: {
                        value: enrolleeCobraStartDate,
                        required: enrolleeEmployeeStatus === 'COBRA',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                    },
                    cobraEndDate: {
                        value: enrolleeCobraEndDate,
                        required: enrolleeEmployeeStatus === 'COBRA',
                        validations: [
                            {
                                validationType: 'date',
                            },
                        ],
                    },
                    // Not needed for Cirrus for now, will need for future state
                    // cobraType: {
                    //     value: typeof enrollEntry.values[enroll.cobraType] === 'undefined' ? '' : enrollEntry.values[enroll.cobraType],
                    //     isEditable: isEditable(enrollEntry.values[enroll.cobraType]),
                    //     dropdown: true,
                    //     dropdownValues: [
                    //         'COBRA',
                    //         'CAL-COBRA',
                    //         'COBRA-AB1401',
                    //         'Extended/Disabled COBRA',
                    //     ],
                    // },
                },

                contactInfo: {
                    streetAddress: {
                        value: enrolleeStreetAddress === '' ? null : enrolleeStreetAddress,
                        required: isWaivingAll !== 'Yes',
                    },
                    city: {
                        value: enrolleeCity === '' ? null : enrolleeCity,
                        required: isWaivingAll !== 'Yes',
                    },
                    state: {
                        value: enrolleeState === '' ? null : enrolleeState,
                        required: isWaivingAll !== 'Yes',
                    },
                    zipCode: {
                        value: valueCheck(enrollEntry.values[enroll.zipCode], [
                            {
                                validationType: 'zip',
                            },
                        ], fullProps).value === '' ? '' : checkZipCode(valueCheck(enrollEntry.values[enroll.zipCode], [
                                {
                                    validationType: 'zip',
                                },
                            ], fullProps).value),
                        required: isWaivingAll !== 'Yes',
                        validations: [
                            {
                                validationType: 'zip',
                            },
                        ],
                    },
                    county: {
                        value: enrolleeCounty === '' ? '' : enrolleeCounty,
                        required: isWaivingAll !== 'Yes',
                    },
                    email: {
                        value: enrolleeEmail === '' ? '' : setEmail(enrolleeEmail),
                        required: false,
                    },
                    telephone: {
                        value: enrolleePhoneNumber === '' ? '' : formatPhoneNumber(enrolleePhoneNumber),
                        required: false,
                    },
                    telephoneType: {
                        value: (enrolleePhoneType === '' || !phoneType.includes(enrollEntry.values[enroll.phoneType])) ? '' : enrolleePhoneType,
                        required: false,
                    },
                },
                medicalPlanCode: {
                    value: enrolleeMedicalPlan === '' ? '' : enrolleeMedicalPlan,
                    required: isRequiredField('medical', selectedMedicalPlansArray, enrolledPlans, true),
                    dropdown: true,
                    dropdownValues: generateMedicalPlanCodes(enrolledPlans),
                },
                dentalPlanCode: {
                    value: enrolleeDentalPlan === '' ? '' : enrolleeDentalPlan,
                    required: isRequiredField('dental', selectedDentalPlansArray, dentalPlans, true),
                    dropdown: true,
                    dropdownValues: generateDentalPlanCodes(dentalPlans),
                },
                visionPlanCode: {
                    value: enrolleeVisionPlan === '' ? '' : enrolleeVisionPlan ,
                    required: isRequiredField('vision', selectedVisionPlansArray, visionPlans, true),
                    dropdown: true,
                    dropdownValues: generateVisionPlanCodes(visionPlans),
                },
                lifePlanCode: {
                    value: enrolleeLifePlan === '' ? '' : enrolleeLifePlan ,
                    required: isRequiredField('life', selectedLifePlansArray, lifePlans, true),
                    dropdown: true,
                    dropdownValues: generateLifePlanCodes(lifePlans),
                },
                suppLifePlanCode: {
                    value: enrolleeSuppLifePlan === '' ? '' : enrolleeSuppLifePlan ,
                    required: isRequiredField('suppLife', selectedSuppLifePlansArray, suppLifePlans, true),
                    dropdown: true,
                    dropdownValues: generateSuppLifePlanCodes(suppLifePlans),
                },
                stdPlanCode: {
                    value: enrolleeSTDPlan === '' ? '' : enrolleeSTDPlan ,
                    required: isWaivingAll === 'Yes' ? false : (selectedProducts.includes('std') && !isEmpty(stdPlans) && stdPlans.length > 0 ? true : false),
                    dropdown: true,
                    dropdownValues: generateSTDPlanCodes(stdPlans),
                },
                ltdPlanCode: {
                    value: enrolleeLTDPlan === '' ? '' : enrolleeLTDPlan ,
                    required: isWaivingAll === 'Yes' ? false : (selectedProducts.includes('ltd') && !isEmpty(ltdPlans) && ltdPlans.length > 0 ? true : false),
                    dropdown: true,
                    dropdownValues: generateLTDPlanCodes(ltdPlans),
                },
                assignmentMethod: {
                    value: (enrolleeAssignmentMethod === '' || !pcpAssignmentType.includes(enrollEntry.values[enroll.assignmentMethod])) ? '' : enrolleeAssignmentMethod,
                    dropdown: true,
                    dropdownValues: ['Auto Assign', 'Enter in a PCP code', 'N/A'],
                },
                pcpCode: {
                    value: enroleePcpCode === '' ? '' : enroleePcpCode,
                },
                existingPatient: {
                    value: (enrolleeExistingPatient === '' || !yesNoType.includes(enrollEntry.values[enroll.existingPatient])) ? '' : enrolleeExistingPatient,
                    required: false,
                },
                waiveCoverage: {
                    isWaivingAll: {
                        value: isWaivingAll,
                    },
                    medicalWaiverReason: {
                        value: (enrolleeMedicalWaiverReason === '' || !generateMedicalWaiverReasons(stateCode).includes(enrolleeMedicalWaiverReason)) ? '' : enrolleeMedicalWaiverReason,
                        required: isWaivingAll === 'Yes' ? true : enrolleeMedicalPlan === 'waive',
                        dropdown: true,
                        dropdownValues: generateMedicalWaiverReasons(stateCode),
                    },
                },

                coverages: {
                    medical: (isEmpty(entry.values[emp.medical]) || !coverageTypes.includes(entry.values[emp.medical])) ? '' : stringToCoverageCode(entry.values[emp.medical]),
                    dental: (isEmpty(entry.values[emp.dental]) || !coverageTypes.includes(entry.values[emp.dental])) ? '' : stringToCoverageCode(entry.values[emp.dental]),
                    vision: (isEmpty(entry.values[emp.vision]) || !coverageTypes.includes(entry.values[emp.vision])) ? '' : stringToCoverageCode(entry.values[emp.vision]),
                    basicLife: (isEmpty(entry.values[emp.basicLife]) || !coverageTypes.includes(entry.values[emp.basicLife])) ? '' : stringToCoverageCode(entry.values[emp.basicLife]),
                    life: (isEmpty(entry.values[emp.life])  || !coverageTypes.includes(entry.values[emp.life])) ? '' : stringToCoverageCode(entry.values[emp.life]),
                    std: (isEmpty(entry.values[emp.std])  || !coverageTypes.includes(entry.values[emp.std])) ? '' : stringToCoverageCode(entry.values[emp.std]),
                    ltd: (isEmpty(entry.values[emp.ltd]) || !coverageTypes.includes(entry.values[emp.ltd])) ? '' : stringToCoverageCode(entry.values[emp.ltd]),
                },
                optedLifeClass: {
                    value: ''

                },
                employeeStatus: {
                    value: (enrolleeEmployeeStatus === '' || !employeeStatusType.includes(enrolleeEmployeeStatus)) ? '' : enrolleeEmployeeStatus,
                    required: isWaivingAll !== 'Yes',
                    dropdown: true,
                    dropdownValues: ['Active', 'COBRA', 'Retired'],
                },
                specialEmployeeStatus: {
                    value: (enrolleeSpecialEmployeeStatus === '' || !specialEmployeeStatusType.includes(enrolleeSpecialEmployeeStatus)) ? 'None' : enrolleeSpecialEmployeeStatus ,
                        required: false,
                        dropdown: true,
                        dropdownValues: ['K1', 'Contractor', 'None']
                },
                outOfArea: {
                    value: (enrolleeOutOfArea === '' || !yesNoType.includes(enrolleeOutOfArea)) ? 'No' : enrolleeOutOfArea,
                    required: isWaivingAll !== 'Yes',
                    dropdown: true,
                },
                dependents: [],
            };
            return fileInputObj
        } if (typeValueCheck === 'Dependent') {

            return {
                employeeType: {
                    value: 'Dependent',
                },
                relationship: {
                    value: (enrolleeRelationshipType === '' || !generateRelationshipTypeOptions(stateCode, platform).includes(convertTempRelationshipType(enrolleeRelationshipType)))? '' : convertTempRelationshipType(enrolleeRelationshipType),
                    required: true,
                    dropdown: true,
                    dropdownValues: generateRelationshipTypeOptions(stateCode, platform)
                },
                firstName: {
                    value: valueCheck(entry.values[emp.firstName]).value === '' ? '' : valueCheck(entry.values[emp.firstName]).value,
                    required: true,
                },
                lastName: {
                    value: valueCheck(entry.values[emp.lastName]).value === '' ? '' : valueCheck(entry.values[emp.lastName]).value,
                    required: true,
                },
                middleInitial: {
                    value: valueCheck(enrollEntry.values[enroll.middleInitial]).value === '' ? '' : valueCheck(enrollEntry.values[enroll.middleInitial]).value,
                    required: false,
                },
                suffix: {
                    value: (enrolleeSuffix === '' || !suffixType.includes(enrolleeSuffix))? '' : mapSuffix(enrolleeSuffix),
                    required: false,
                },
                ssnTin: {
                    value: enrolleeSsn === '' ? null : formatSSN(enrolleeSsn),
                    required: !!isEditable(enrollEntry.values[enroll.noSSNReason]),
                    validations: [
                        {
                            validationType: 'fullSsn',
                        },
                    ],
                    error: {
                        error: errorCheck(formatSSN(enrolleeSsn), [{ validationType: 'fullSsn' }], fullProps, !!isEditable(enrollEntry.values[enroll.noSSNReason])).error
                            ? errorCheck(formatSSN(enrolleeSsn), [{ validationType: 'fullSsn' }], fullProps, !!isEditable(enrollEntry.values[enroll.noSSNReason])).error : null,
                    },
                },
                noSSNReason: {
                    value: (enrolleeNoSSNReason === '' || !noSSNType.includes(enrolleeNoSSNReason)) ? '' : enrolleeNoSSNReason,
                    required: false,
                    dropdown: true,
                    dropdownValues: [
                        'Expatriate',
                        'Newborn',
                        'Refusal to Provide',
                        'Personal ID Number Satisfied',
                        'Unknown',
                    ],
                },
                streetAddress: {
                    value: enrolleeStreetAddress === '' ? null : enrolleeStreetAddress,
                    required: true,
                },
                city: {
                    value: enrolleeCity === '' ? null : enrolleeCity,
                    required: true,
                },
                state: {
                    value: enrolleeState === '' ? null : enrolleeState,
                    required: true,
                },
                zipCode: {
                    value: valueCheck(enrollEntry.values[enroll.zipCode], [
                        {
                            validationType: 'zip',
                        },
                    ], fullProps).value === '' ? '' : checkZipCode(valueCheck(enrollEntry.values[enroll.zipCode], [
                            {
                                validationType: 'zip',
                            },
                        ], fullProps).value),
                    required: true,
                    validations: [
                        {
                            validationType: 'zip',
                        },
                    ],
                },
                county: {
                    value: enrolleeCounty === '' ? null : enrolleeCounty,
                    required: true,
                },
                telephone: {
                    value: enrolleePhoneNumber === '' ? '' : formatPhoneNumber(enrolleePhoneNumber),
                    required: false,
                },
                telephoneType: {
                    value: (enrolleePhoneType === '' || !phoneType.includes(enrollEntry.values[enroll.phoneType])) ? '' : enrolleePhoneType,
                    required: false,
                },
                gender: {
                    value: (enrolleeGender === '' || !genderType.includes(enrolleeGender)) ? '' : getGender(enrolleeGender),
                    required: true,
                    dropdown: true,
                    dropdownValues: stateCode === 'NY' ? ['Male', 'Female', 'Non-Binary'] : ['Male', 'Female'],
                },
                dob: {
                    value: valueCheck(handleExcelDate(entry.values[enroll.dob]), [
                        {
                            validationType: 'date',
                        }
                    ], fullProps).value === '' ? '' : valueCheck(handleExcelDate(entry.values[enroll.dob]), [
                        {
                            validationType: 'date',
                        }
                    ], fullProps).value,
                    required: true,
                    validations: [
                        {
                            validationType: 'date',
                        },
                    ],
                },
                age: {
                    value: isEditable(entry.values[dep.age])
                        ? valueCheck(handleExcelDate(enrollEntry.values[enroll.dob])).value === ''
                            ? ''
                            : getAgeFromDOB(valueCheck(handleExcelDate(enrollEntry.values[enroll.dob])).value, handleExcelDate(effectiveDate))
                        : entry.values[dep.age],
                    required: true,
                    validations: [
                        {
                            validationType: 'digitsOnly',
                        },
                    ],
                },
                medicalPlanCode: {
                    value: enrolleeMedicalPlan === '' ? '' : enrolleeMedicalPlan,
                    required: isRequiredField('medical', selectedMedicalPlansArray, enrolledPlans, false),
                    dropdown: true,
                    dropdownValues: generateMedicalPlanCodes(enrolledPlans),
                },
                dentalPlanCode: {
                    value: enrolleeDentalPlan === '' ? '' : enrolleeDentalPlan,
                    required: isRequiredField('dental', selectedDentalPlansArray, dentalPlans, false),
                    dropdown: true,
                    dropdownValues: generateDentalPlanCodes(dentalPlans),
                },
                visionPlanCode: {
                    value: enrolleeVisionPlan === '' ? '' : enrolleeVisionPlan,
                    required: isRequiredField('vision', selectedVisionPlansArray, visionPlans, false),
                    dropdown: true,
                    dropdownValues: generateVisionPlanCodes(visionPlans),
                },
                dependentLifePlanCode: {
                    value: enrolleeDependentLifePlan === '' ? '' : enrolleeDependentLifePlan ,
                    required: isRequiredField('life', selectedLifeDepPlansArray, dependentLifePlans, false),
                    dropdown: true,
                    dropdownValues: generateDepLifePlanCodes(dependentLifePlans),
                },
                dependentSuppLifePlanCode: {
                    value: enrolleeDependentSuppLifePlan === '' ? '' : enrolleeDependentSuppLifePlan,
                    required: isRequiredField('suppLife', selectedSuppLifeDepPlansArray, suppLifePlans, false),
                    dropdown: true,
                    dropdownValues: (enrolleeRelationshipType === 'Child (CT/NY Only)' || enrolleeRelationshipType === 'Dependent Child (NJ/NH Only)') ? generateChildSuppLifePlanCodes(suppLifePlans) : generateSpouseSuppLifePlanCodes(suppLifePlans),
                },

                stdPlanCode: {
                    value: enrolleeSTDPlan === '' ? '' : enrolleeSTDPlan,
                    required: (selectedProducts.includes('std') && !isEmpty(stdPlans) && stdPlans.length > 0 ? true : false),
                    dropdown: true,
                    dropdownValues: generateSTDPlanCodes(stdPlans),
                },
                ltdPlanCode: {
                    value: enrolleeLTDPlan === '' ? '' : enrolleeLTDPlan,
                    required: (selectedProducts.includes('ltd') && !isEmpty(ltdPlans) && ltdPlans.length > 0 ? true : false),
                    dropdown: true,
                    dropdownValues: generateLTDPlanCodes(ltdPlans),
                },
                assignmentMethod: {
                    value: (enrolleeAssignmentMethod === '' || !pcpAssignmentType.includes(enrollEntry.values[enroll.assignmentMethod])) ? '' : enrolleeAssignmentMethod,
                    dropdown: true,
                    dropdownValues: ['Auto Assign', 'No', 'N/A'],
                },
                pcpCode: {
                    value: enroleePcpCode === '' ? '' : enroleePcpCode,
                },
                existingPatient: {
                    value: (enrolleeExistingPatient === '' || !yesNoType.includes(enrollEntry.values[enroll.existingPatient])) ? '' : enrolleeExistingPatient,
                    required: false,
                },
                otherCarrier: {
                    value: (enrolleeAnyOtherCoverage === '' || !yesNoType.includes(enrollEntry.values[enroll.anyOtherCoverage])) ? '' : enrolleeAnyOtherCoverage,
                    required: true,
                    dropdown: true,
                },
                nameOtherCarrier: {
                    value: (enrolleeNameOtherCarrier === '' || !generateOtherCarrierNames(stateCode).includes(enrolleeNameOtherCarrier)) ? '' : enrolleeNameOtherCarrier,
                    dropdown: true,
                    dropdownValues: generateOtherCarrierNames(stateCode)
                },
                medicarePrimary: {
                    value: (enrolleeMedicarePrimary === '' || !yesNoType.includes(enrollEntry.values[enroll.medicarePrimary])) ? '' : enrolleeMedicarePrimary,
                    required: true,
                    dropdown: true,
                },
                medicareReason: {
                    value: (enrolleeMedicareReason === '' || !medicareReasonType.includes(enrollEntry.values[enroll.medicareReason])) ? '' : enrolleeMedicareReason,
                    required: enrolleeMedicarePrimary === 'Yes',
                    dropdown: true,
                    dropdownValues: ['Age', 'Disability (not actively at work)', 'End stage renal disease (ESRD)', 'Disabled but actively at work', 'Unknown'],
                },
                medicareClaimNum: {
                    value: valueCheck(enrollEntry.values[enroll.medicareClaimNumber]).value === '' ? null : valueCheck(enrollEntry.values[enroll.medicareClaimNumber]).value,
                    required: enrolleeMedicarePrimary === 'Yes',
                },
                medicarePartAStartDate: {
                    value: enrolleeMedicarePartAStartDate,
                    required: enrolleeMedicarePrimary === 'Yes',
                    validations: [
                        {
                            validationType: 'date',
                        },
                    ],
                },
                medicarePartBStartDate: {
                    value: enrolleeMedicarePartBStartDate,
                    required: enrolleeMedicarePrimary === 'Yes',
                    validations: [
                        {
                            validationType: 'date',
                        },
                    ],
                },
                medicarePartDStartDate: {
                    value: enrolleeMedicarePartDStartDate,
                    required: enrolleeMedicarePrimary === 'Yes',
                    validations: [
                        {
                            validationType: 'date',
                        },
                    ],
                },
                isDisabled: {
                    value: (enrolleeisDisabled === '' || !yesNoType.includes(enrollEntry.values[enroll.isDisabled])) ? '' : enrolleeisDisabled,
                    required: true,
                    dropdown: true,
                },
                disabilityType: {
                    value: (enrolleeDisabilityType === '' || !dissabilityType.includes(enrollEntry.values[enroll.disabilityType])) ? '' : enrolleeDisabilityType,
                    required: enrolleeisDisabled === 'Yes',
                    dropdown: true,
                    dropdownValues: [
                        'Mental',
                        'Physical',
                    ],
                },
            };
        }
    }
};

export const structureEnrollmentDataUpload = (workbook, enrollment, enrolldPlans, locations, fullProps) => {
    return new Promise(resolve => {

        // Set table data here based on props.workbook
        const wb = new Excel.Workbook();
        const reader = new FileReader();

        const employees = [];

        const enrolledDentalPlans = enrollment.enrolledDentalPlans;
        const enrolledVisionPlans = enrollment.enrolledVisionPlans;
        const enrolledLifePlans = enrollment.enrolledLifePlans;
        const enrolledDependentLifePlans = enrollment.enrolledDependentLifePlans;
        const enrolledSuppLifePlans = enrollment.enrolledSuppLifePlans;
        const enrolledDependentSuppLifePlans = enrollment.enrolledDependentSuppLifePlans;
        const enrolledSTDPlans = enrollment.enrolledSTDPlans;
        const enrolledLTDPlans = enrollment.enrolledLTDPlans;
        const stateCode = getPrimaryLocationStateCode(locations);

        reader.readAsArrayBuffer(workbook);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer)
                .then(wkbk => {
                    const companyInfo = wkbk.getWorksheet('Company Info')
                    const empInfo = wkbk.getWorksheet('Employee Info');
                    const enrollInfo = wkbk.getWorksheet('Enrollment Info');

                    let medicalQuote = companyInfo.getRow(4).getCell(8).value
                    let dentalQuote = companyInfo.getRow(4).getCell(9).value
                    let visionQuote = companyInfo.getRow(4).getCell(10).value
                    let lifeQuote = companyInfo.getRow(4).getCell(11).value

                    let prods = [];
                    if (medicalQuote === 'Quote') {
                        prods.push('medical')
                    }
                    if (dentalQuote === 'Quote') {
                        prods.push('dental')
                    }
                    if (visionQuote === 'Quote') {
                        prods.push('vision')
                    }
                    if (lifeQuote === 'Quote') {
                        prods.push('life')
                    }

                    const selectedProducts = prods;

                    let effDate = companyInfo.getRow(4).getCell(2).value;

                    const verticalTemplateCheck = wkbk.getWorksheet('Version History').getCell('A1').value.includes('Vertical');

                    const marketType =  wkbk.getWorksheet('Market Type');
                    const versionHistory = wkbk.getWorksheet('Version History');

                    if (checkForUhcTemplate(marketType, versionHistory)) {
                        console.log("INCORRECT TEMPLATE")
                        return;
                    }

                    let employee = {};
                    if (verticalTemplateCheck) {
                        empInfo.columns.forEach((col, index) => {
                            employee = generateImportTableData(col, 'vertical', index, 1, 3, "2020-09-01", enrollInfo.getColumn(index + 1), enrolldPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledDependentLifePlans, enrolledSuppLifePlans, enrolledDependentSuppLifePlans, enrolledSTDPlans, enrolledLTDPlans, stateCode, selectedProducts, fullProps)
                            if (employee) {
                                employees.push(employee)
                            }
                        });
                    } else { 
                        empInfo.eachRow((row, rowNumber) => {
                            employee = generateImportTableData(row, 'horizontal', rowNumber, 4, 1, effDate, enrollInfo.getRow(rowNumber), enrolldPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledDependentLifePlans, enrolledSuppLifePlans, enrolledDependentSuppLifePlans, enrolledSTDPlans, enrolledLTDPlans, stateCode, selectedProducts, fullProps)
                            if (employee && employee.employeeType.value === 'Employee') {
                                employees.push(employee)
                            } else if (employee && employee.employeeType.value === 'Dependent') {
                                // Add to current employee's dependent array
                                employees[employees.length - 1].dependents.push(employee)
                            }
                        });
                    }

                    // Adjust dependent required fields based on employee waiving all products
                    employees.forEach((employee, index) => {
                        const isEmployeeWaivingAll = employee.waiveCoverage.isWaivingAll.value
                        if (employee.dependents.length > 0) {
                            for (let dependent of employee.dependents) {
                                dependent.medicalPlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('medical') ? true : false)
                                dependent.dentalPlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('dental') ? true : false)
                                dependent.visionPlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('vision') ? true : false)
                                dependent.dependentLifePlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('life') ? true : false)
                                // dependent.suppDepLifeSpousePlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('life') ? true : false)
                                dependent.dependentSuppLifePlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('suppLife') ? true : false)
                                dependent.stdPlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('std') ? true : false)
                                dependent.ltdPlanCode.required = isEmployeeWaivingAll === 'Yes' ? false : (selectedProducts.includes('ltd') ? true : false)
                                dependent.ssnTin.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.noSSNReason.value !== '' ? false : true
                                dependent.streetAddress.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.city.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.state.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.county.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.medicarePrimary.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.medicareClaimNum.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.medicarePrimary.value === 'Yes' ? true : false
                                dependent.medicarePartAStartDate.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.medicarePrimary.value === 'Yes' ? true : false
                                dependent.medicarePartBStartDate.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.medicarePrimary.value === 'Yes' ? true : false
                                dependent.medicarePartDStartDate.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.medicarePrimary.value === 'Yes' ? true : false
                                dependent.isDisabled.required = isEmployeeWaivingAll === 'Yes' ? false : true
                                dependent.disabilityType.required = isEmployeeWaivingAll === 'Yes' ? false : dependent.isDisabled.value === 'Yes' ? true : false
                                dependent.otherCarrier.required = isEmployeeWaivingAll === 'Yes' ? false : true
                            }
                        }
                    })

                    resolve(employees);
                })
        }
    })
};

export const convertImportToEmployees = (importData, selectedProducts) => {
    buildProductSelection(importData, selectedProducts)
   
    buildPcpAssignment(importData)

    let tempImportData = JSON.parse(JSON.stringify(importData))
    
    
    let requiredKeys = Object.keys(allFields)
    const newImportData = tempImportData.map((employee, index) => {
        let newEmployee = {}
        Object.entries(employee).forEach((entry) => {
            if (requiredKeys.includes(entry[0])) {
                if (employee[entry[0]].value || employee[entry[0]].value === '' || employee[entry[0]].value === null) {
                    newEmployee[entry[0]] = employee[entry[0]].value
                } else {
                    newEmployee[entry[0]] = employee[entry[0]]
                    for (let field in employee[entry[0]]) {
                        newEmployee[entry[0]][field] = employee[entry[0]][field].value
                    }
                }
            } else {
                newEmployee[entry[0]] = employee[entry[0]]
            }
        })
        newEmployee.firstName = employee['employeeInfo'].firstName
        newEmployee.lastName = employee['employeeInfo'].lastName
        newEmployee.middleInitial = employee.employeeInfo.middleInitial
        newEmployee.suffix = employee.employeeInfo.suffix
        newEmployee.dob = employee.employeeInfo.dob
        newEmployee.dobInput = employee.employeeInfo.dob != null && employee.employeeInfo.dob !== '';
        newEmployee.age = employee.employeeInfo.age
        newEmployee.gender = employee.employeeInfo.gender
        newEmployee.salary = employee.employeeInfo.salary
        const zipString = employee.contactInfo.zipCode
        newEmployee.zipCode = zipString
        newEmployee['contactInfo'].zipCode = zipString;
        newEmployee.email = employee.contactInfo.email
        newEmployee.telephone = employee.contactInfo.telephone
        newEmployee['employeeInfo'].ssnCheck = ssnItinNotAvailableCheck(employee.employeeInfo);
        newEmployee['employeeInfo'].medicareCoverages = medicareCoverageChecks(employee.employeeInfo);
        newEmployee.specialEmployeeStatus = employee['employeeInfo'].specialEmployeeStatus;

        newEmployee.dependents = []

        // Parsing dependent values for redux
        if (employee.dependents.length > 0) {

            for (let dependent of employee.dependents) {
                let newDependent = {};
                Object.entries(dependent).forEach((entry) => {
                    if (entry[0] !== 'status' || entry[0] !== 'iconStatus' || entry[0] !== 'waiveOption') {
                        newDependent[entry[0]] = dependent[entry[0]].value
                    }
                    newDependent.status = dependent.status
                    newDependent.middleInitial = dependent.middleInitial.value
                    newDependent.suffix = dependent.suffix.value
                    newDependent.telephone = dependent.telephone.value
                    newDependent.dobInput = dependent.dob != null && dependent.dob !== '';

                    newDependent.addressDifferentThanEmployee = differentDependentEmployeeAddressCheck(employee, dependent);
                    newDependent.ssnCheck = ssnItinNotAvailableCheck(dependent);
                    newDependent.medicareCoverages = medicareCoverageChecks(dependent);
                })

                newEmployee.dependents.push(newDependent);
            }
        }
        return newEmployee
    })

    return newImportData
}

// Building product selection array for redux
const buildProductSelection = (employeeArray, selectedProducts) => {
    let test = employeeArray.map((employee) => {
        let productArray = []
        let employeeProducts = {}
        let prods = ['medical', 'dental', 'vision', 'life', 'suppLife', 'std', 'ltd']
        let waivedSelectionsNumber = 0;
       
        // If employee chooses to waive all, set individual products to waive in productSelection
        if (employee.waiveCoverage.isWaivingAll === 'Yes') {
            employeeProducts.medical = 'waive'
            employeeProducts.dental = 'waive'
            employeeProducts.vision = 'waive'
            employeeProducts.life = 'waive'
            employeeProducts.suppLife = 'waive'
            employeeProducts.std = 'waive'
            employeeProducts.ltd = 'waive'
            employee.coverages.medical = 'W'
            employee.coverages.dental = 'W'
            employee.coverages.vision = 'W'
            employee.coverages.life = 'W'
            employee.coverages.suppLife = 'W'
            employee.coverages.std = 'W'
            employee.coverages.ltd = 'W'
        } else {
            // Otherwise we set each product to waive or plan code individually
            for (let product of prods) {
                if (employee[product + 'PlanCode'].value === 'waive') {
                    employeeProducts[product] = 'waive';
                    employee.coverages[product] = 'W'
                    waivedSelectionsNumber++;
                } else {
                    employeeProducts[product] = employee[product + 'PlanCode'].value
                    // Update employee.coverages[product] to relevant non-waive value
                    if (product === 'life') {
                        employee.coverages.basicLife = 'EL'
                    } else { // Evaluate dependents and relevant coverage changes for all other products
                        if (!employee.dependents[0]) {
                            employee.coverages[product] = 'EE'
                        } else {
                            let isSpouseCovered = false
                            let isChildCovered = false
                            for (const dep of employee.dependents) {
                                if(product === 'suppLife'){
                                    product = 'dependentSuppLife'
                                }
                                if (dep.relationship.value === 'Child' && !isChildCovered) {
                                    if (dep[product + 'PlanCode'].value !== 'waive') {
                                        isChildCovered = true
                                    }
                                } else if (dep.relationship.value !== 'Child') {
                                    if (dep[product + 'PlanCode'].value !== 'waive') {
                                        isSpouseCovered = true
                                    }
                                }
                            }
                            if (isSpouseCovered && isChildCovered) {
                                employee.coverages[product] = 'EE/FAM'
                            } else if (isSpouseCovered && !isChildCovered) {
                                employee.coverages[product] = 'EE/SP'
                            } else if (!isSpouseCovered && isChildCovered) {
                                employee.coverages[product] = 'EE/CH'
                            } else {
                                employee.coverages[product] = 'EE'
                            }
                        }
                    }  
                }
            }
        }

        if (waivedSelectionsNumber === selectedProducts.length) {
            employee.waiveCoverage.isWaivingAll.value = 'Yes';
        }

        // If employeeProducts.medical in plans has pcp required... set medicalCoverageInfo
        if (employeeProducts.medical !== 'waive') {
            employee.medicalCoverageInfo = { pcpTable: '', medicalWaiverReason: '', medicalWaived: false };
        }

        // Need to try setting waive reason in other spots so it appears on census
        if (employeeProducts.medical && employeeProducts.medical === 'waive' && employee.waiveCoverage.medicalWaiverReason.value !== null) {
            employee.waiveReason = employee.waiveCoverage.medicalWaiverReason.value;
            employee.medicalCoverageInfo = { pcpTable: '', medicalWaiverReason: employee.waiveCoverage.medicalWaiverReason.value, medicalWaived: true };
        }
        productArray.push(employeeProducts)
        employee.productSelection = productArray

        // Building product selection for dependents
        // If employee is waiving, dependent must waive too
        if (employee.dependents.length > 0) {
            employee.dependents.forEach((dependent, index) => {
                let dependentProducts = {}
                dependentProducts.medical = dependent.medicalPlanCode.value
                dependentProducts.dental = dependent.dentalPlanCode.value
                dependentProducts.vision = dependent.visionPlanCode.value
                dependentProducts.life = dependent.dependentLifePlanCode.value
                dependentProducts.suppLife = dependent.dependentSuppLifePlanCode.value
                dependentProducts.std = dependent.stdPlanCode.value
                dependentProducts.ltd = dependent.ltdPlanCode.value
                employee.productSelection.push(dependentProducts)
            })
        }
        return employee
    })
}


// Building pcp assigment array for redux
const buildPcpAssignment = (employeeArray) => {

    let test = employeeArray.map((employee) => {
        let pcpArray = []
        let pcpObj = {}
        pcpObj.assignmentMethod = employee.assignmentMethod.value
        pcpObj.pcpCode = employee.pcpCode.value
        pcpObj.existingPatient = employee.existingPatient.value
        pcpArray.push(pcpObj)

        if (employee.dependents.length) {
            buildPcpAssignmentDependent(employee.dependents, pcpArray);
        }

        employee.pcpAssignment = pcpArray

        return employee
    })
}

const buildPcpAssignmentDependent = (dependentsArray, pcpArray) => {
    let test = dependentsArray.map((dependent) => {

        let pcpObj = {}
        pcpObj.assignmentMethod = dependent.assignmentMethod.value
        pcpObj.pcpCode = dependent.pcpCode.value
        pcpObj.existingPatient = dependent.existingPatient.value
        pcpArray.push(pcpObj)

        return dependent
    })
}

const ssnItinNotAvailableCheck = (enrollee) => {

    const ssnTin = typeof enrollee.ssnTin === 'object' ? (isEmpty(enrollee.ssnTin) ? null : enrollee.ssnTin.value) : enrollee.ssnTin;
    const noSSNReason = typeof enrollee.noSSNReason === 'object' ? (isEmpty(enrollee.noSSNReason) ? null : enrollee.noSSNReason.value) : enrollee.noSSNReason;

    if (isEmpty(ssnTin) && !isEmpty(noSSNReason)) {
        return true
    }

    return false
}

const medicareCoverageChecks = (enrollee) => {

    let medicareCoverageTracker = [];

    const medicarePartAStartDate = enrollee.medicarePartAStartDate.hasOwnProperty('value') ? enrollee.medicarePartAStartDate.value : enrollee.medicarePartAStartDate;
    const medicarePartBStartDate = enrollee.medicarePartBStartDate.hasOwnProperty('value') ? enrollee.medicarePartBStartDate.value : enrollee.medicarePartBStartDate;
    const medicarePartDStartDate = enrollee.medicarePartDStartDate.hasOwnProperty('value') ? enrollee.medicarePartDStartDate.value : enrollee.medicarePartDStartDate;

    if (!isEmpty(medicarePartAStartDate)) {
        medicareCoverageTracker.push("Part A");
    }

    if (!isEmpty(medicarePartBStartDate)) {
        medicareCoverageTracker.push("Part B");
    }

    if (!isEmpty(medicarePartDStartDate)) {
        medicareCoverageTracker.push("Part D");
    }

    return medicareCoverageTracker
}

const differentDependentEmployeeAddressCheck = (employeeData, dependentData) => {

    let differentIndicator = 'No';

    if (!isEmpty(dependentData.city.value) && (employeeData.contactInfo.city !== dependentData.city.value)) {
        differentIndicator = 'Yes';
    } else if (!isEmpty(dependentData.state.value) && (employeeData.contactInfo.state !== dependentData.state.value)) {
        differentIndicator = 'Yes';
    } else if (!isEmpty(dependentData.streetAddress.value) && (employeeData.contactInfo.streetAddress !== dependentData.streetAddress.value)) {
        differentIndicator = 'Yes';
    } else if (!isEmpty(dependentData.zipCode.value) && (employeeData.contactInfo.zipCode !== dependentData.zipCode.value)) {
        differentIndicator = 'Yes';
    } else if (!isEmpty(dependentData.county.value) && (employeeData.contactInfo.county !== dependentData.county.value)) {
        differentIndicator = 'Yes';
    }

    return differentIndicator;
}

// Checks if an employee is complete
export const isComplete = (employeeObj, type) => {
    if (type === 'employee') {
        let status = "Complete"
        for (let property in allFields) {
            if (allFields[property] instanceof Array) {
                for (let field of allFields[property]) {
                    if (employeeObj[property][field.col].required) {
                        if (fieldMissingOrError(employeeObj[property][field.col])) {
                            status = "In Progress"
                            break;
                        }
                    }
                }
            } else {
                if (employeeObj[property].required) {
                    if (fieldMissingOrError(employeeObj[property])) {
                        status = "In Progress"
                        break;
                    }
                }
            }
        }
        return status
    } else {
        let status = "Complete"
        for (const property in employeeObj) {
            if (employeeObj[property].required) {
                if (property !== 'iconStatus' || property !== 'status' || property !== 'waiveOption') {
                    if (fieldMissingOrError(employeeObj[property])) {
                        status = "In Progress"
                    }
                }
            }
        }
        return status
    }
}

const fieldMissingOrError = (obj) => {
    if (obj.value === "" || obj.value === null || obj.value === undefined) {
        return true;
    } else if (typeof obj.value === "object") {
        if (obj.value.formula || obj.value.sharedFormula) {
            return true
        }
    }
    if (obj.error) {
        if ('error' in obj.error) {
            if (obj.error.error !== null) {
                return true
            }
        }
    }
    return false;
}

  // Check if all employees & dependents are complete - for close enrollment button
  export const checkCompleteAllEmp = (employeeArray) => {    //Can make this into utility method
    let status = "Complete"
    for (let employee of employeeArray) {
        if (employee.iconStatus.props.status !== "Complete") {
            status = "Incomplete"
        }
        if (employee.dependents.length > 0) {
            for (let dependent of employee.dependents) {
                if (dependent.status !== "Complete") {
                    status = "Incomplete"
                }
            }
        }
    }
    return status
}

// Object representing all columns to be displayed in the table
// col: string matching the json object generated from import
// title: string to be displayed as column header
// required: boolean to determine if field is included in completion check
// width: column width

export const allFields = {
    employeeType: {
        col: "employeeType",
        title: "Type",
        required: true,
        width: 100
    },
    employeeInfo: [
        {
            col: "firstName",
            title: "First Name",
            required: true,
            width: 125
        },
        {
            col: "lastName",
            title: "Last Name",
            required: true,
            width: 125
        },
        {
            col: "ssnTin",
            title: "SSN (###-##-####)",
            required: true,
            width: 250
        },
        {
            col: "noSSNReason",
            title: "Reason SSN Not Provided (required if no SSN)",
            required: false,
            width: 200
        },
        {
            col: "salary",
            title: "Salary",
            required: true,
            width: 200
        },
        {
            col: "dob",
            title: "Date of Birth",
            required: true,
            width: 125
        },
        {
            col: "age",
            title: "Age",
            required: true,
            width: 125
        },
        {
            col: "gender",
            title: "Gender",
            required: true,
            width: 125
        },
        {
            col: "specialEmployeeStatus",
            title: "Special Employee Status",
            required: false,
            width: 200
        },
        {
            col: "numberOfHoursWorked",
            title: "Number Hours Worked",
            required: true,
            width: 125
        },
        {
            col: "dateOfHire",
            title: "Date of Hire",
            required: true,
            width: 125
        },
        {
            col: "anyOtherCoverage",
            title: "On the day this coverage begins, will you, your spouse or any of your dependents be covered under any other medical health plan or policy including another UnitedHealthcare Plan?",
            required: true,
            width: 400
        },
        {
            col: "nameOtherCarrier",
            title: "Name of Other Carrier (Required if employee or dependent covered by another plan",
            required: false,
            width: 200
        },
        {
            col: "medicarePrimary",
            title: "Medicare Primary",
            required: true,
            width: 100
        },
        {
            col: "medicareReason",
            title: "Medicare Reason (required if Medicare Primary)",
            required: false,
            width: 250
        },
        {
            col: "medicareClaimNum",
            title: "Medicare Claim Number (required if Medicare Primary)",
            required: false,
            width: 200
        },
        {
            col: "medicarePartAStartDate",
            title: "Medicare Part A Start Date (required if Medicare Primary)",
            required: false,
            width: 200
        },
        {
            col: "medicarePartBStartDate",
            title: "Medicare Part B Start Date (required if Medicare Primary)",
            required: false,
            width: 200
        },
        {
            col: "medicarePartDStartDate",
            title: "Medicare Part D Start Date (required if Medicare Primary)",
            required: false,
            width: 200
        },
        {
            col: "cobraStartDate",
            title: "COBRA Start Date (required if COBRA)",
            required: false,
            width: 200
        },
        {
            col: "cobraEndDate",
            title: "COBRA End Date (required if COBRA)",
            required: false,
            width: 200
        },
    ],
    contactInfo: [
        {
            col: "streetAddress",
            title: "Street Address",
            required: true,
            width: 200
        },
        {
            col: "city",
            title: "City",
            required: true,
            width: 200
        },
        {
            col: "state",
            title: "State",
            required: true,
            width: 100
        },
        {
            col: "zipCode",
            title: "Zip Code",
            required: true,
            width: 125
        },
        {
            col: "county",
            title: "County",
            required: true,
            width: 125
        },
    ],
    waiveCoverage: [
        {
            col: "isWaivingAll",
            title: "Waive All Products",
            required: true,
            width: 125
        },
        {
            col: "medicalWaiverReason",
            title: "I am waiving Medical Insurance for the following reason:",
            required: false,
            width: 300
        }
    ],
    medicalPlanCode: {
        col: "medicalPlanCode",
        title: "Medical Plan Code",
        required: true,
        width: 300
    },
    dentalPlanCode: {
        col: "dentalPlanCode",
        title: "Dental Plan Code",
        required: true,
        width: 300
    },
    visionPlanCode: {
        col: "visionPlanCode",
        title: "Vision Plan Code",
        required: true,
        width: 300
    },
    lifePlanCode: {
        col: "lifePlanCode",
        title: "Life Plan Code",
        required: true,
        width: 300
    },
    suppLifePlanCode: {
        col: "suppLifePlanCode",
        title: "Supplemental Life Plan Code",
        required: true,
        width: 300
    },
    stdPlanCode: {
        col: "stdPlanCode",
        title: "Short Term Disability Plan Code",
        width: 300
    },
    ltdPlanCode: {
        col: "ltdPlanCode",
        title: "Long Term Disability Plan Code",
        width: 300
    },
    assignmentMethod: {
        col: "assignmentMethod",
        title: "Does member want to Auto Assign PCP? (required if medical plan requires PCP)",
        required: false,
        width: 200,
    },
    pcpCode: {
        col: "pcpCode",
        title: "Medical Plan PCP ID# (required if medical plan requires PCP)",
        required: false,
        width: 200
    },
    employeeStatus: {
        col: "employeeStatus",
        title: "Employee Status",
        required: true,
        width: 200
    },
    optedLifeClass: {
        col: "optedLifeClass",
        title: "Employee Classes",
        required: true,
        width: 200
    },
    outOfArea: {
        col: "outOfArea",
        title: "Out of Area",
        required: true,
        width: 100
    },
}