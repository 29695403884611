exports.atLeastOneTrue = object => {
    if (Object.keys(object).filter(key => object[key]).length > 0) {
        return true;
    }
    return false;
}

/**
 * Given a list and an index, returns a copy of the list with the entity at that index removed
 * @param {Array} list - a list of anything
 * @param {Number} index - the index of the entity to be removed in the list
 */
exports.removeAtIndex = (list, index) => {
    return list.slice(0, index).concat(list.slice(index + 1, list.length));
}

/**
 * Given a list, an index, and some entity, returns a copy of the list with the entity inserted at that index
 * @param {Array} list - a list of anything
 * @param {Number} index - the index of where to add the entity in the list
 * @param addition - the entity to be added
 */
exports.insertAtIndex = (list, index, addition) => {
    return list.slice(0, index).concat(addition).concat(list.slice(index));
}

/**
 * Given a list, an index, and some entity, returns a copy of the list with the entity at the given index replaced by the new input
 * @param {Array} list - a list of anything
 * @param {Number} index - the index of where to add the entity in the list
 * @param addition - the entity to replace what is at the index
 */
exports.replaceAtIndex = (list, index, replacement) => {
    return list.slice(0, index).concat(replacement).concat(list.slice(index + 1));
}

/**
 * Given a list and some entity, returns a copy of the list with the entity inserted at the end
 * @param {Array} list - a list of anything
 * @param addition - the entity to be added
 */
exports.insertAtEnd = (list, addition) => {
    return list.concat(addition);
}

/**
 * Given a list, returns a copy of the list with the last entity removed
 * @param {Array} list - a list of anything
 */
exports.removeFromEnd = list => {
    return list.slice(0, list.length - 1);
}

exports.getLast = list => {
    return list[list.length - 1];
}