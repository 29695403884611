export const formatPlanOrRateCallError = (error, callType, isSpecialty) => {
    let errorMessage = '';
    if (error && error.message && typeof error.message === 'string') {
        errorMessage = error.message
    } else if (error && error.response && error.response.data && error.response.data.message && typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
    } else if (error && error.error && typeof error.error === 'string') {
        errorMessage = error.error;
    } else if (error && error.error && error.error.message && typeof error.error.message === 'string') {
        errorMessage = error.error.message;
    } else {
        switch (callType) {
            case 'plan':
                errorMessage = isSpecialty ? 'Something Went Wrong Fetching Specialty Plans' : 'Something Went Wrong Fetching Plans';
                break;
            case 'rate':
                errorMessage = isSpecialty ? 'Something Went Wrong Fetching Specialty Rates' : 'Something Went Wrong Fetching Rates';
                break;
            default:
                errorMessage = 'Something Went Wrong';
                break;
        }
    }
    return errorMessage;
}