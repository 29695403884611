
  import React, { useContext, useRef, useState } from "react";
  import IdleTimer from "react-idle-timer";
  import { useDispatch, useSelector } from 'react-redux';
  import { AppStateContext } from '../../../context/AppStateContext';
  import SessionTimeoutDialog from '../../../components/shared/IdleTimer/SessionTimeoutDialog';
  import {setAuthenticData } from '../../../actions/overallActions';
  import  { axios }  from '../../../../src/utils/api/axiosConfig';
  import { history } from '../../../../src/utils/history';
  import apiRoutes from '../../../../src/utils/api/apiRouteConfig';
import { clearLoginData,isLoggedIn } from "../../../actions/renewalActions";
import { createHistory } from "../../../actions/historyAction";
  
  let countdownInterval;
  let timeout;
   
  export default function SessionTimeout() {

    const dispatch = useDispatch();
    const store = useSelector((state) =>state);
    const  authenticated =  sessionStorage.getItem('dn-sm-session') === "true" ? true : false;
    const { isAuthenticated, handleLogoutUser } = useContext(
      AppStateContext
    );
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
   
    const clearSessionTimeout = () => {
      clearTimeout(timeout);
    };
   
    const clearSessionInterval = () => {
      clearInterval(countdownInterval);
    };
   
    const handleLogout = (isTimedOut = false) => {
      console.log('user is idle')
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      dispatch(setAuthenticData({isAuthed:false,isTimedOut:true}))
      handleLogoutUser(isTimedOut);
      axios.post(`${apiRoutes.quickQuotes}/logout`).then((response)=>{
        sessionStorage.removeItem('dn-sm-session');
      }).catch(err=>{
        console.log('error occured during renew', err.message);
      });
      history.replace('/logout');
      dispatch(isLoggedIn(false));
      dispatch(clearLoginData());
      //window.close();
    };
   
    const handleContinue = () => {
      console.log('user did something')
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
       axios.post(`${apiRoutes.quickQuotes}/renew-token`).then((response)=>{
        //extended  samx session
          console.log(response.data); // This will print Success
          dispatch(createHistory('BNE Renew token : Initiated ', '', 'Succes', '', '', 'NB'));
          //extending BnE session in Backend renew token service APi
          axios.post(`${apiRoutes.quickQuotes}/extendBneSession`).then((res) =>{
            dispatch(createHistory('BNE Renew token : Completed ', '', 'Succes', '', '', 'NB'));
            console.log('extend session res', res)
          }).catch((error) =>{
            dispatch(createHistory('BNE Renew token : Failed ', '', 'Error', error.message, '', 'NB'));
            console.log('error occured during extend Bne session', error.message);
          })
        //extending BnE session in Backend renew token service APi
      }).catch(err=>{
        dispatch(createHistory('BNE Renew token : Failed ', '', 'Error', err.message, '', 'NB'));
        console.log('error occured during renew session', err.message);
      });
      
      
    };
   
    const onActive = () => {
      console.log('Active for ');
      if (!timeoutModalOpen) {
        console.log('user is active')
        console.log('countdown',countdownInterval)
        clearSessionInterval();
        clearSessionTimeout();
      }
      if (!authenticated) {
        setTimeoutModalOpen(false);
      }
    };
   
    const onIdle = () => {
      console.log('user is idle - on Idle')
      //  USER Session timeout  - 18 mins 57 sec - external env variable
      const delay = 1000 * 60 * 15; // 10 minute inactive logout
      dispatch(setAuthenticData({isAuthed :authenticated,isTimedOut:false}));
      if (authenticated ) {
      if (!timeoutModalOpen) {       
      timeout = setTimeout(() => {
           //Log out dialog before timeout - 4 * 60
          let countDown = 237;
          setTimeoutModalOpen(true);
          setTimeoutCountdown(countDown);
          countdownInterval = setInterval(() => {
            if (countDown > 0) {
              setTimeoutCountdown(--countDown);
            } else {
              handleLogout(true);
            }
          }, 1000);
        }, delay);
      }
      }else{
        clearSessionInterval();
        clearSessionTimeout();
      }
    };

    return (
      <>
        <IdleTimer
          ref={idleTimer}
          onActive={onActive}
          onIdle={onIdle}
          debounce={250}
          timeout={5000}
        />
        <SessionTimeoutDialog
          countdown={timeoutCountdown}
          onContinue={handleContinue}
          onLogout={() => handleLogout(false)}
          open={timeoutModalOpen}
        />
      </>
    );
   
  }
