import * as types from '../../actions/actionTypes';
import { handleUpdatePlanRenewalShopping } from './helpers/filteringRenewalReducerHelper';
import { formatSalesRepRenewals } from '../../components/activities/Renewal/RenewalsCommonComponents/saveDetails';
import { handleUpdateAllPlans } from './helpers/filteringReducerHelpers';
import deepCopy from '../../utils/deepCopy';
import { setAlternateRatingMethod, setPlanDefaultType } from '../../utils/Renewal/uhcUpdateRates';

const initialMDVContr = {
    medical: {
        eValue: 0,
        dValue: 0,
        activeIndex: 0
    },
    dental: {
        eValue: 0,
        dValue: 0,
        activeIndex: 1
    },
    vision: {
        eValue: 0,
        dValue: 0,
        activeIndex: 2
    },
    life: {
        eValue: 0,
        dValue: 0,
        activeIndex: 3
    }
}

let initialState = {
    mdvContribution: initialMDVContr,
    employerContribution: 0,
    errorMessage: '',
    numOfSelectedRenewPlans: 0,
    premiumToggle: true,
    renewalDataLoaded: false,
    customPlanNames: {
        medical: {},
        dental: {},
        vision: {},
        life: {},
        depLife: {}
    },
    callStatus:false
};

export default function renewalShoppingHelper(state = initialState, action) {
    switch (action.type) {
        case types.RECEIVE_RENEWAL_SHOPPING_HELPER_DETAILS:
            return {
                ...state,
                ...action.payload,
                renewalDataLoaded: true
            }
        case types.RESET_RENEWAL_SHOPPING_HELPER_DETAILS:
        case types.RESET_RENEWALS:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
            return initialState

        case types.UPDATE_PLAN_ISCHECKED_VALUE_SHOPPING:
            const newList = state.renewalData?.planRates?.map((item) => {
                if (item.planCode === action.payload.query.planCode && item.renewalPlanType === "Renewal") {
                    const updatedItem = {
                        ...item,
                        isChecked: !item.isChecked,
                    };
                    return updatedItem;
                }
                return item;
            });
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: newList
                }
            }

        case types.UPDATE_MDV_CONTRIBUTION_SHOPPING:
            const tempMDVCont = initialMDVContr;
            tempMDVCont.medical.eValue = action.payload.medEValue ?
                action.payload.medEValue :
                state.mdvContribution.medical.eValue;
            tempMDVCont.medical.dValue = action.payload.medDValue ?
                action.payload.medDValue :
                state.mdvContribution.medical.dValue;
            tempMDVCont.dental.eValue = action.payload.denEValue ?
                action.payload.denEValue :
                state.mdvContribution.dental.eValue;
            tempMDVCont.dental.dValue = action.payload.denDValue ?
                action.payload.denDValue :
                state.mdvContribution.dental.dValue;
            tempMDVCont.vision.eValue = action.payload.visEValue ?
                action.payload.visEValue :
                state.mdvContribution.vision.eValue;
            tempMDVCont.vision.dValue = action.payload.visDValue ?
                action.payload.visDValue :
                state.mdvContribution.vision.dValue;
            tempMDVCont.life.eValue = action.payload.lifeEValue ?
                action.payload.lifeEValue :
                state.mdvContribution.life.eValue;

            return {
                ...state,
                mdvContribution: tempMDVCont,
                errorMessage: action.payload.errorMessage,
            }

        case types.UPDATE_SHOW_MORE_LESS_SHOPPING:
            return {
                ...state,
                showMoreLessArray: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_MEDICAL_SHOPPING:
            return {
                ...state,
                showMoreLessArrayMedical: action.payload,
            }

        case types.UPDATE_SHOW_MORE_LESS_DENTAL_SHOPPING:
            return {
                ...state,
                showMoreLessArrayDental: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_VISION_SHOPPING:
            return {
                ...state,
                showMoreLessArrayVision: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_LIFE_SHOPPING:
            return {
                ...state,
                showMoreLessArrayLife: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_DEPLIFE_SHOPPING:
            return {
                ...state,
                showMoreLessArrayDepLife: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_STD_SHOPPING:
            return {
                ...state,
                showMoreLessArrayStd: action.payload,
            }
        case types.UPDATE_SHOW_MORE_LESS_LTD_SHOPPING:
            return {
                ...state,
                showMoreLessArrayLTD: action.payload,
            }
        case types.UPDATE_NUM_OF_SELECTED_RENEW_PLANS_SHOPPING:
            return {
                ...state,
                numOfSelectedRenewPlans: action.payload,
            }
        case types.UPDATE_RENEWAL_PLAN_SHOPPING_HELPER_MEDICAL:
            return handleUpdatePlanRenewalShopping(state, action, 'medical');
        case types.SERVICE.DENTAL.UPDATE_RENEWAL_PLAN_SHOPPING_HELPER:
            return handleUpdatePlanRenewalShopping(state, action, 'dental');
        case types.SERVICE.VISION.UPDATE_RENEWAL_PLAN_SHOPPING_HELPER:
            return handleUpdatePlanRenewalShopping(state, action, 'vision');
        case types.SERVICE.LIFE.UPDATE_RENEWAL_PLAN_SHOPPING_HELPER:
            return handleUpdatePlanRenewalShopping(state, action, 'life');
        case types.SERVICE.LIFE_DEP_BASIC.UPDATE_RENEWAL_PLAN_SHOPPING_HELPER:
            return handleUpdatePlanRenewalShopping(state, action, 'LIFE_DEP_BASIC');

        case types.UPDATE_KEEP_MEDICAL_VALUE:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    keepMedical: action.payload
                }
            }
        case types.UPDATE_SALES_REP_RENEWALS_SHOPPING:
            let finalSalesRepData = formatSalesRepRenewals(action.salesRep, action.platform)
            return {
                ...state,
                salesRepData: finalSalesRepData
            }
        case types.SERVICE.DENTAL.UPDATE_ALL_PLANS_FROM_INSIDE:
            const newSelectedPlansDental = handleUpdateAllPlans(action.fullProps.dental, action, 'RS').selectedPlans;
            return {
                ...state,
                dental: {
                    ...state.dental,
                    selectedPlans: newSelectedPlansDental,
                }
            }
        case types.SERVICE.VISION.UPDATE_ALL_PLANS_FROM_INSIDE:
            const newSelectedPlansVision = handleUpdateAllPlans(action.fullProps.vision, action, 'RS').selectedPlans;
            return {
                ...state,
                vision: {
                    ...state.vision,
                    selectedPlans: newSelectedPlansVision,
                }
            }
        case types.SERVICE.LIFE.UPDATE_ALL_PLANS_FROM_INSIDE:
            const newSelectedPlansLife = handleUpdateAllPlans(action.fullProps.life, action, 'RS').selectedPlans;
            if(newSelectedPlansLife.length == 0)
            {
                state.dependentLife.selectedPlans = [];
            }
            return {
                ...state,
                life: {
                    ...state.life,
                    selectedPlans: newSelectedPlansLife,
                }
            }
        case types.SERVICE.LIFE_DEP_BASIC.UPDATE_ALL_PLANS_FROM_INSIDE:
            const newSelectedPlansLifeDep = handleUpdateAllPlans(action.fullProps.lifeDep, action, 'RS').selectedPlans;      
            return {
                ...state,
                dependentLife: {
                    ...state.dependentLife,
                    selectedPlans: newSelectedPlansLifeDep,
                }
            }
        case types.UPDATE_SPECIALTY_RATES_RENEWAL_SHOPPING:
            const selectedPlansRates = deepCopy(action.payload || [])
            const planType = action.planType
            const newState = state[planType].set.map(plan => {
                let temp = plan
                let { byCensusShopping, finalRatesShopping, quoteDisplayShopping } = selectedPlansRates.find(p => p.code === plan[1].code)
                temp[1].byCensusShopping = byCensusShopping
                temp[1].finalRatesShopping = finalRatesShopping
                temp[1].quoteDisplayShopping = quoteDisplayShopping
                return temp
            })
            let newSelectedState = []
            if (state[planType].selectedPlans && state[planType].selectedPlans.length !== 0) {
                newSelectedState = state[planType].selectedPlans.map(plan => {
                    let temp = plan
                    if (selectedPlansRates.find(p => p.code === plan.code)) {
                        let { byCensusShopping, finalRatesShopping, quoteDisplayShopping } = selectedPlansRates.find(p => p.code === plan.code)
                        temp.byCensusShopping = byCensusShopping
                        temp.finalRatesShopping = finalRatesShopping
                        temp.quoteDisplayShopping = quoteDisplayShopping
                    }
                    return temp
                })

            }
            const newPlansArray = {
                set: newState,
                selectedPlans: newSelectedState,

            }
            return {
                ...state,
                [`ratesFetched${planType}`]: true,
                [planType]: newPlansArray
            }

        case types.UPDATE_ALTERNATE_RATES_MEDICAL_REN_SHOP:
            let medicalSelectedPlans = deepCopy(state.medical.selectedPlans);

            for(let i=0; i< medicalSelectedPlans.length; i++) {
                const rateIdx = action.rates.findIndex(rate => rate.medicalPlanCode === medicalSelectedPlans[i].code);

                if(rateIdx > -1) {
                    if(medicalSelectedPlans[i].ibrRatingType) {
                        medicalSelectedPlans[i] = {
                            ...medicalSelectedPlans[i],
                            ...setAlternateRatingMethod(medicalSelectedPlans[i], action.rates[rateIdx])
                        }
                    } else {
                        medicalSelectedPlans[i] = {
                            ...medicalSelectedPlans[i],
                            ...action.rates[rateIdx],
                            defaultRatingType: action.defaultTypeAge ? 'ageBands' : medicalSelectedPlans[i].defaultRatingType
                        }
                    }
                }
            }

            let medicalSet = deepCopy(state.medical.set);

            for(let i=0; i< medicalSet.length; i++) {
                const rateIdx = action.rates.findIndex(rate => rate.medicalPlanCode === medicalSet[i][1].code);

                if(rateIdx > -1) {
                    medicalSet[i][1] = {
                        ...medicalSet[i][1],
                        ...setAlternateRatingMethod(medicalSet[i][1], action.rates[rateIdx]),
                        defaultRatingType: action.defaultTypeAge ? 'ageBands' : 'composite'
                    }
                }
            }

            return {
                ...state,
                medical : {
                    set: medicalSet,
                    selectedPlans: medicalSelectedPlans
                }
            }

        case types.CHANGE_ENROLLED_RATING_METHOD_REN_SHOP:
            const totalPremiumKey = action.field === 'age' ? 'premiumTotalAgeBandedMonthly' : 'premiumTotalCompositeMonthly';
            const employeeRateType = action.field === 'age' ? 'employeeRatesAgeBanded' : 'employeeRatesComposite'

            let medicalSetCopy = deepCopy(state.medical.set), medicalSelectedPlansCopy = deepCopy(state.medical.selectedPlans);

            medicalSetCopy.forEach((plan) => {
                if(plan[1].employeeRatesAgeBanded && plan[1].employeeRatesComposite) {
                    plan[1].employeeRates = plan[1][employeeRateType]
                }
                plan[1].quote = plan[1][totalPremiumKey];
                    // update default rating field for uhc renewals(rate default is determined by these fields)
                    setPlanDefaultType(plan[1], action.field === 'age');
            });

            medicalSelectedPlansCopy.forEach((plan) => {
                if(plan.employeeRatesAgeBanded && plan.employeeRatesComposite) {
                    plan.employeeRates = plan[employeeRateType]
                }
                plan.quote = plan[totalPremiumKey];
                    // update default rating field for uhc renewals(rate default is determined by these fields)
                    setPlanDefaultType(plan, action.field === 'age');
            });

            return {
                ...state,
                medical : {
                    set: medicalSetCopy,
                    selectedPlans: medicalSelectedPlansCopy
                }
            }
        case types.SPECIALTY_RATE_CALL_PROGRESS:
            return {
                ...state,
                callStatus: action.payload,
            }    

        case types.UPDATE_RENEWAL_SHOPPING_PARENT_PLAN_CODE_LIFE_DEP_BASIC:
            const updatedDepLifeSelectedPlans = deepCopy(state.dependentLife && state.dependentLife.selectedPlans||[]);
            const index = updatedDepLifeSelectedPlans.findIndex(plan => plan.code === action.planCode);
            if(index > -1){
                if(updatedDepLifeSelectedPlans[index].parentPlanCode){
                    updatedDepLifeSelectedPlans[index].parentPlanCode = action.parentPlanCode;
                }
            }
            return {
                ...state,
                dependentLife:{
                    selectedPlans : updatedDepLifeSelectedPlans
                }
               
            } 
        default:
            return state;
    }
}