import deepCopy from '../../../utils/deepCopy';
import {
    togglePlanMembership,
    filterPlans,
    sortPlans,
    filterSpecialtyPlans,
    togglePlanMap,
    getSelectedPlansBenefitRiders
} from './filterHelper';
import {
    isEmpty,
} from '../../../utils/formatters/strings';
import { setPlanDefaultType } from '../../../utils/Renewal/uhcUpdateRates';

/**
 * Returns an altered version of the input list of plans, with the desired plan toggled
 * @param {Array} plans Array of plan objects
 * @param {string} code Code of desired plan to change
 * @param {string} field Field of desired plan to change
 */
const findAndTogglePlan = (plans, code, field) => plans.map((plan) => {
    if (plan.code === code) {
        plan[field] = !plan[field];
        return plan;
    }

    return plan;
});

/**
  * 
  * @param {Array} plans Array of Plans Object.
  * @param {String} code  code of the desired plan to change.
  * @param {String} field Field of desired plan to change.
  * @param {String} planType PlanType of desired plan. suppLife or SuppDepLife.
  * @returns Updated Plans Array. 
  */
const findAndToggleSuppLifePlan = (plans, code, field,planType) => plans.map((plan) => {
    if (plan.code === code) {
        const isPreviousEE = plan[field] && !plan.SLSpPlan[field]; // is Supp EE Plan already selected.
        const isPreviousdep = plan[field] && plan.SLSpPlan[field] && plan.SLChPlan[field]; // is Dependent plan already selected.
        /* When toggling the plan from Supp EE to dependent or dependent to EE
        then selected field in the Supp EE Plan should not update.
        */

        if (!(isPreviousEE && !["SUPPLIFE","SUPPLEMENT LIFE"].includes(planType.toUpperCase())) && !(isPreviousdep && ["SUPPLIFE","SUPPLEMENT LIFE"].includes(planType.toUpperCase()))) {
            plan[field] = !plan[field];
        }

        plan.SLSpPlan[field] = ["SUPPLIFE","SUPPLEMENT LIFE"].includes(planType.toUpperCase()) ? false : !plan.SLSpPlan[field];
        plan.SLChPlan[field] = ["SUPPLIFE","SUPPLEMENT LIFE"].includes(planType.toUpperCase()) ? false : !plan.SLChPlan[field];
        return plan;
    }

    return plan;
});

// Called to either select/unselect or favorite/unfavorite and individual plan
//
// Parameters
//  plan:           Object holding plan information to be updated
//  field:          Determines whether to update (by toggling) 'selected' or 'favorited' flag
export const handleUpdatePlan = (state, action, type) => {
    // We must update the favorite/selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans = deepCopy(state.plans);

    let newVisiblePlans = deepCopy(state.visiblePlans);
     //Toggle SuppLife plans Array and Visible plan Array
    if (["SUPPLIFE","SUPPLEMENT LIFE"].includes(action.plan.planType.toUpperCase())) {
        newPlans = findAndToggleSuppLifePlan(newPlans, action.plan.code, action.field, action.plan.checked);
        newVisiblePlans = findAndToggleSuppLifePlan(newVisiblePlans, action.plan.code, action.field, action.plan.checked);
    } else {
        newPlans = findAndTogglePlan(newPlans, action.plan.code, action.field);
        newVisiblePlans = findAndTogglePlan(newVisiblePlans, action.plan.code, action.field);
    }

    let newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans,
    };

    // Toggle specialty adv plans
    let newAllPlans = state.allPlans ? deepCopy(state.allPlans) : [];
    if (["SUPPLIFE","SUPPLEMENT LIFE"].includes(action.plan.planType.toUpperCase())) { // Toggle Supp Life All Plans Array
        newAllPlans = findAndToggleSuppLifePlan(newAllPlans, action.plan.code, action.field, action.plan.checked);   
    } else {
        newAllPlans = findAndTogglePlan(newAllPlans, action.plan.code, action.field);
    }

    if (newAllPlans.length > 0) {
        newState = {
            ...newState,
            allPlans: newAllPlans,
        };
    }

    let newSelectedPlans;
    let newSelectedMap = state.selectedPlansMap;
    if (action.field === 'selected') {
        if (["SUPPLIFE","SUPPLEMENT LIFE"].includes(action.plan.planType.toUpperCase())) {
            // Toggle selected field in the Selected Plan Object.
            const updatedSelectedPlan = findAndToggleSuppLifePlan([action.plan],action.plan.code, action.field, action.plan.checked);
            newSelectedPlans = togglePlanMembership("selected", updatedSelectedPlan[0], state.selectedPlans, action.pkg, action.isUhcRenewals)
        } else {
            newSelectedPlans = togglePlanMembership('selected', action.plan, state.selectedPlans, action.pkg, action.isUhcRenewals);
        }
        
        if (!isEmpty(state.maxAgeBandedPlans) && state.filterCriteria.ratingMethod.age) {
            const numSelected = newSelectedPlans.length;
            if (numSelected > state.maxAgeBandedPlans) {
                return {
                    ...state,
                    maxAgeBandedPlansWarning: true,
                };
            }
        }

        newSelectedMap = togglePlanMap(action.plan, state.selectedPlansMap, action.pkg);

        if (newState.specialty) {
            if (newState.plans[0]?.planType) {
                localStorage.setItem(`selected_specialty_${newState.plans[0].planType}`, JSON.stringify(newSelectedPlans.map((plan) => plan.code)));
            }
        } else {
            let updatedSelectedPlan = [];
            newSelectedPlans.forEach(plan => {
                updatedSelectedPlan.push({
                    code: plan.code,
                    pkg: plan.selectedPackages ? plan.selectedPackages[0] : 'Single'
                })
            })

            localStorage.setItem('selected_medical', JSON.stringify(updatedSelectedPlan));
        }
    } else {
        newState.favoritedPlans = togglePlanMembership('favorited', action.plan, state.favoritedPlans, action.pkg);
        if (newState.favoritedPlans.length === 0) {
            newState.filterCriteria.favorite = false;  
            newState = {
                ...newState,
                ...state.specialty ?
                    filterSpecialtyPlans(newState.plans, newState.allPlans, newState.filterCriteria, state.allRatesFetched,
                    state.filterTotalsCache, state.sortEnabled, state.ascending)
                : 
                    filterPlans(newState.plans, newState.filterCriteria, state.isCirrus, state.allRatesFetched, state.filterTotalsCache,
                    state.sortEnabled, state.ascending),
            } 
        } else {
            newState = {
                ...newState,
            };
        }

        // if (newState.specialty) {
        //     localStorage.setItem(`favorites_specialty_${newState.plans[0].planType}`, JSON.stringify(newState.favoritedPlans.map((plan) => plan.code)));
        // } else if (type === 'RS') {
        //     localStorage.setItem(`favorites_${action.plan.platform}_${state.renewalShoppingRequest.zipObj.zipCode}_${state.renewalShoppingRequest.zipObj.countyFIPSCode}`,
        //         JSON.stringify(newState.favoritedPlans.map((plan) => plan.code)));
        // } else {
        //     localStorage.setItem(`favorites_${action.plan.platform}_${state.quickQuoteRequest.zipCode}_${state.quickQuoteRequest.countyCode}`,
        //         JSON.stringify(newState.favoritedPlans.map((plan) => plan.code)));
        // }

        newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
        for (const plan of newSelectedPlans) {
            if (action.plan.code === plan.code) {
                plan[action.field] = !plan[action.field];
                break;
            }
        }
    }

    // Setting up the benefit riders selected plans have among all the available benfit riders.
    let newSelectedPlansBenefitRiders = [];
    if (state.optionalBenefitRiders) {
        newSelectedPlansBenefitRiders = getSelectedPlansBenefitRiders(newSelectedPlans, state.optionalBenefitRiders);
    }

    return {
        ...newState,
        selectedPlans: newSelectedPlans,
        selectedPlansMap: newSelectedMap,
        selectedPlansBenefitRiders: newSelectedPlansBenefitRiders
    };
};

export const handleUpdateSuppLifeSelectedPlan =(state,action)=>{
    const  selectedPlans = deepCopy(state.selectedPlans);
    const newPlans = deepCopy(state.plans);
    const newVisiblePlans = deepCopy(state.visiblePlans);
    const updatedPlans =  findAndToggleSuppLifePlan(newPlans,action.plan.code,action.field,action.planType);
    const updatedVisiblePlan = findAndToggleSuppLifePlan(newVisiblePlans,action.plan.code,action.field,action.planType);
    const updatedSelectedPlans = findAndToggleSuppLifePlan(selectedPlans,action.plan.code,action.field,action.planType);
    let newAllPlans = state.allPlans ? deepCopy(state.allPlans) : [];
    newAllPlans = findAndToggleSuppLifePlan(newAllPlans,action.plan.code, action.field, action.planType);   

    return {
        ...state,
        selectedPlans: updatedSelectedPlans,
        plans: updatedPlans,
        visiblePlans: updatedVisiblePlan,
        allPlans: newAllPlans,
    } 
}


// Function to return those visible plans that are not currently present in selected Plans array 
/** It checks both plan Code and selected package / selected class 
 * In Non-classing case it also adds selected package to the objects
 * @param {*} visiblePlans visible plans from state
 * @param {*} selectedPlans selected plans from state
 * @param {*} pkg selected package/ selected class in case of life classing
 * @param {*} isLifeClassingCase determines whether it's a life classing case or not
 */
const plansToBeAddedToSelectedPlans = (visiblePlans, existingSelectedPlans, pkg, isLifeClassingCase) => {
    if(isLifeClassingCase){
        return visiblePlans.filter(visiblePlan => {
            return existingSelectedPlans.findIndex(existingSelectedPlan => 
                (existingSelectedPlan.code === visiblePlan.code && existingSelectedPlan.selectedClass === pkg)) === -1
        })
    }else{
        let newSelectedPlans = visiblePlans.filter(visiblePlan => {
            return existingSelectedPlans.findIndex(existingSelectedPlan => 
                (existingSelectedPlan.code === visiblePlan.code && existingSelectedPlan.selectedPackages.includes(pkg))) === -1
        })
        newSelectedPlans.forEach(plan => {
            if(!plan.selectedPackages)
                plan.selectedPackages = [pkg]
        })
        return newSelectedPlans;
    }
}

const getNewSelectedPlansMap = (selectedPlansMap, selectedPlans, pkg, isLifeClassingCase) => {

    let plansInPackage; // Gives the (plans in package) or (plans in selected class in case of classing life)
    if (pkg === 'Single') {
        plansInPackage = selectedPlans;
    } else if(isLifeClassingCase) {
        plansInPackage = selectedPlans.filter(plan => plan.selectedClass === pkg);
    }else{
        plansInPackage = selectedPlans.filter((plan) => plan.selectedPackages.includes(pkg));
    }
    selectedPlansMap[pkg] = plansInPackage;
    return selectedPlansMap;
}

// Called to succinctly select/unselect all plans
//
// Parameters
//  field:          Determines whether to update 'selected' or 'favorited' flag (Currently only used for 'selected')
//  value:          Boolean value corresponding to what the field should now equal (0=unselected, 1=selected)
export const handleUpdateAllPlans = (state, action, type) => {
    // We must update the favorite/selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans = deepCopy(state.plans);
    let newVisiblePlans = deepCopy(state.visiblePlans);
    let newSelectedPlansMap = deepCopy(state.selectedPlansMap)
    // select all and deselect all bug fix
    for (let plan of newVisiblePlans) {
        plan[action.field] = action.value;
        // While deselecting SuppLifePlans Also deselect Dep SuppLife Plans
        if(state.advanceFilterProductType === "supplife" && !action.value){
         plan.SLChPlan[action.field] = action.value;
         plan.SLSpPlan[action.field] = action.value;
        }
    }
    for (let plan of newPlans) {
        if (newVisiblePlans.map(visiblePlan => visiblePlan.code).includes(plan.code)) {
            plan[action.field] = action.value;
            if(state.advanceFilterProductType === "supplife" && !action.value){
                plan.SLChPlan[action.field] = action.value;
                plan.SLSpPlan[action.field] = action.value;
               }
        }
    }
    const newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans,
    };

    let newSelectedPlans = [];
    if (action.field === 'selected') {
        const isLifeClassingCase = (action.type === 'UPDATE_ALL_PLANS_LIFE' || action.type === 'UPDATE_ALL_PLANS_STD' || action.type === 'UPDATE_ALL_PLANS_LTD') && action.pkg !== 'Single';

        if (action.value) { // i.e., if we are selecting all plans
            //A plan will be included in the selectedPlans only when it is present in visible plans and not present in the selected plans under the given package
            newSelectedPlans = [].concat(state.selectedPlans).concat(plansToBeAddedToSelectedPlans(newVisiblePlans, state.selectedPlans, action.pkg, isLifeClassingCase));
            newSelectedPlansMap = getNewSelectedPlansMap(newSelectedPlansMap , newSelectedPlans, action.pkg, isLifeClassingCase);
            
        } else { //deselecting all plans

            let visiblePlanCodes = newVisiblePlans.map(plan => plan.code);
            let selectedPlans = deepCopy(state.selectedPlans);

            let i = selectedPlans.length;

            if(isLifeClassingCase){
                while(i--){
                    if(selectedPlans[i].selectedClass === action.pkg){
                        selectedPlans.splice(i,1);
                    }
                }
            }else{
                while (i--) {
                    if (visiblePlanCodes.includes(selectedPlans[i].code) || selectedPlans[i].planType !== 'Medical') {
                        if (selectedPlans[i].selectedPackages[0] === action.pkg) {
                            selectedPlans.splice(i, 1);
                        }
                    }
                }
            }

            newSelectedPlans = selectedPlans;
            const renewalPlans = [];
            if (type && type === 'RS') {
                state.selectedPlans.map((plan) => {
                    if (plan.renewalPlan === true) {
                        renewalPlans.push(plan);
                    }
                });
                newSelectedPlans = renewalPlans;
            }
            
            delete newSelectedPlansMap[action.pkg];
        }

        if (newState.specialty && newState.plans[0]) {
            localStorage.setItem(`selected_specialty_${newState.plans[0].planType}`, JSON.stringify(newSelectedPlans.map((plan) => plan.code)));
        } else {
            localStorage.setItem('selected_medical', JSON.stringify(newSelectedPlans.map((plan) => plan.code)));
        }
    }
    // Setting up the benefit riders selected plans have among all the available benfit riders.
    let newSelectedPlansBenefitRiders = [];
    if (state.optionalBenefitRiders) {
        newSelectedPlansBenefitRiders = getSelectedPlansBenefitRiders(newSelectedPlans, state.optionalBenefitRiders);
    }

    return {
        ...newState,
        selectedPlans: newSelectedPlans,
        selectedPlansMap: newSelectedPlansMap,
        selectedPlansBenefitRiders: newSelectedPlansBenefitRiders
    };
};

export const handleEmployeeClassFilter = (state, action) => {
    let newPlans = deepCopy(state.plans);
    let newVisiblePlans = deepCopy(state.visiblePlans);

    // if (!state.plans.every(plan => plan.classRates[action.lifeClass])) {
    //     return {
    //         ...state,
    //         visiblePlans: [],
    //     };
    // }

    if (state.visiblePlans.length === 0) {
        newVisiblePlans = newPlans
    }

    for (let plan of newVisiblePlans) {
        if (plan.classRates && plan.classRates[action.lifeClass]) {
            plan.quote = plan.classRates[action.lifeClass].quote
            plan.finalRates = plan.classRates[action.lifeClass].finalRates
            plan.finalMonthlyPremium = plan.classRates[action.lifeClass].finalMonthlyPremium
            plan.selectedClass = action.lifeClass
            if(plan.planType==='Life'){
                plan.byCensus= plan.classRates[action.lifeClass].byCensus
            }
        }
    }
    for (let plan of newPlans) {
        if (newVisiblePlans.map(visiblePlan => visiblePlan.code).includes(plan.code)) {
            if (plan.classRates && plan.classRates[action.lifeClass]) {
                plan.quote = plan.classRates[action.lifeClass].quote
                plan.finalRates = plan.classRates[action.lifeClass].finalRates
                plan.finalMonthlyPremium = plan.classRates[action.lifeClass].finalMonthlyPremium
                plan.selectedClass = action.lifeClass
                if(plan.planType==='Life'){
                    plan.byCensus= plan.classRates[action.lifeClass].byCensus
                } 
            }
        }
    }

    return {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans,
    };
}

// Called to reorder how the plans appear on the page
//
// Parameters
//  ascending:      Boolean corresponding to the whether plans should be ordered by ascending premiums (1) or decending(2)
export const handleChangeSortOrder = (state, action) => ({
    ...state,
    sortEnabled: true,
    ascending: action.ascending,
    visiblePlans: sortPlans(state.visiblePlans, action.ascending),
});

// Filters visible plans based on filter criteria
//
// Parameters
//  field:          Unclear
//  category:       Unclear
//  value:          The new value to filter on
//  packageCode:    The package code, should a user be searching for packages
export const handleChangeFilterCriteria = (state, action, initialFilterCriteria) => {
    let filterCriteria = JSON.parse(JSON.stringify(state.filterCriteria));

    let newPlans = state.plans;
    let newSelectedPlans = state.selectedPlans;

    // If the user is searching for a specific plan code, we discard all the other filter criteria
    if (action.field === 'planCode') {
        if (action.value !== null) {
            filterCriteria = {
                ...initialFilterCriteria,
                ratingMethod: state.filterCriteria.ratingMethod,
                oopRange: state.oopDomainMax,
                dedRange: state.dedDomainMax,
                premRange: state.premDomainMax,
                selectedPackageCode: action.packageCode,
                standaloneSelected: action.packageCode === null,
            };
        }
    } else {      // If the user is filtering by some other criteria, we discard the specific plan code
        filterCriteria.planCode = null;
    }

    if (action.category !== null) {
        // If filter is rating method, we toggle the 2 options (they are 2 radio buttons not checkboxes)
        // However we store them as 2 separate entities rather than a boolean because prior to rates loading we can't sort by them
        // Also, change the "quote" attribute on each plan to use the other rating method, because this attribute is used throughout the app
        if (action.category === 'ratingMethod') {
            filterCriteria = {
                ...filterCriteria,
                ratingMethod: {
                    [action.field === 'age' ? 'age' : 'tier']: action.value,
                    [action.field === 'age' ? 'tier' : 'age']: !action.value,
                },
            };
            newPlans = deepCopy(newPlans);
            newSelectedPlans = deepCopy(newSelectedPlans);
            const totalPremiumKey = action.field === 'age' ? 'premiumTotalAgeBandedMonthly' : 'premiumTotalCompositeMonthly';
            const employeeRateType = action.field === 'age' ? 'employeeRatesAgeBanded' : 'employeeRatesComposite'

            newPlans.forEach((plan) => {
                if(plan.employeeRatesAgeBanded && plan.employeeRatesComposite) {
                    plan.employeeRates = plan[employeeRateType]
                }

                plan.quote = plan[totalPremiumKey];

                if (action.isUhcRenewals) {
                    // update default rating field for uhc renewals(rate default is determined by these fields)
                    setPlanDefaultType(plan, action.field === 'age');
                }
            });

            newSelectedPlans.forEach((plan) => {
                if (plan.employeeRatesAgeBanded && plan.employeeRatesComposite) {
                    plan.employeeRates = plan[employeeRateType]
                }

                plan.quote = plan[totalPremiumKey];

                if (action.isUhcRenewals) {
                    // update default rating field for uhc renewals(rate default is determined by these fields)
                    setPlanDefaultType(plan, action.field === 'age');
                }
            });
        } else {
            filterCriteria = {
                ...filterCriteria,
                [action.category]: {
                    ...filterCriteria[action.category],
                    [action.field]: action.value,
                },
            };
        }
    } else {
        filterCriteria = {
            ...filterCriteria,
            [action.field]: action.value,
        };
    }

    return {
        ...state,
        plans: newPlans, // newPlans are unchanged unless the "rating method" filter was chosen
        selectedPlans: newSelectedPlans,
        selectedPackageLabel: filterCriteria.standaloneSelected ? null : (state.selectedPackageLabel ? filterCriteria.selectedPackageCode : state.selectedPackageLabel),
        ...state.specialty ?
        filterSpecialtyPlans(newPlans, state.allPlans, filterCriteria, state.allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending) : filterPlans(newPlans, filterCriteria, state.isCirrus, state.allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending),
        medicalAndRxPlanCodeSearchError: ''
    }
}

export const handleResetFilterCriteria = (state, initialFilterCriteria) => {
    const newFilterCriteria = {
        ...initialFilterCriteria,
        oopRange: state.oopDomainMax,
        dedRange: state.dedDomainMax,
        premRange: state.premDomainMax,
        selectedPackageCode: state.packages ? (state.packages[0] ? state.packages[0].code : "") : "",
        standaloneSelected: state.filterCriteria.standaloneSelected,
        ratingMethod: state.filterCriteria.ratingMethod
    };

    return {
        ...state,
        ...state.specialty ?
        filterSpecialtyPlans(
            state.plans,
            state.allPlans,
            newFilterCriteria,
            state.allRatesFetched,
            state.filterTotalsCache,
            state.sortEnabled,
            state.ascending) : filterPlans(
            state.plans,
            newFilterCriteria,
            state.isCirrus,
            state.allRatesFetched,
            state.filterTotalsCache,
            state.sortEnabled,
            state.ascending),
            selectedPackageLabel: null
    };
};

// Called to toggle preferred plans view
export const handleTogglePreferredPlans = (state) => ({
    ...state,
    showPreferredPlans: !state.showPreferredPlans,
});

//Handle receive benefit rider rates
// Update the exisiting plans with the benefit rider rates.
export const handleReceiveBenefitRiderRates = (state,action) => {

    let newPlans = JSON.parse(JSON.stringify(state.plans));
    let newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));

    const numBenRidersRatesFetched  = state.numBenRidersRatesFetched + action.rates.length;
    const allBenRidersRatesFetched = numBenRidersRatesFetched === state.plans.length;

    action.rates.forEach((rate) => {
        const planIdx = newPlans.findIndex(
            (plan) => plan.code === rate.medicalPlanCode,
        );
        const selectedPlanIdx = newSelectedPlans.findIndex(
            (plan) => plan.code === rate.medicalPlanCode,
        );
        newPlans[planIdx] = {
            ...newPlans[planIdx],
            benefitRiderRates: rate.benefitRiderRates
        };
        if (selectedPlanIdx > -1) {
            newSelectedPlans[selectedPlanIdx] = {
                ...newSelectedPlans[selectedPlanIdx],
                benefitRiderRates : rate.benefitRiderRates,
            };
        }
    });

    return {
        ...state,
        plans : newPlans,
        selectedPlans: newSelectedPlans,
        ...filterPlans(
            newPlans,
            state.filterCriteria,
            state.isCirrus,
            state.allRatesFetched,
            state.filterTotalsCache,
            state.sortEnabled,
            state.ascending,
        ),
        numBenRidersRatesFetched,
        allBenRidersRatesFetched
    }
}

/**
 * @description Returns an altered version of the input list of plans, with custom name added to desired plan
 * @param {Array} plans Array of plan objects
 * @param {string} code Code of desired plan to change
 * @param {string} customName Custom plan name to add to plan
 */
const findAndUpdatePlanName = (plans, code, customName) => {
    for (const plan of plans) {
        if (code === plan.code) {
            plan.customName = customName;
            break;
        }
    }
    return plans;
};

/**
 * @description Returns an altered version of the input list of plans, with custom name added to desired plan
 * @param {Object} state Current state
 * @param {Object} action Action - which contains the plan to update and customName to add
 */
export const handleChangeCustomPlanName = (state, action, type = 'selected') => {
    let newState;
    if (type === 'selected') {
        // We need to add/update custom name in all places where we store the plan
        let newPlans = deepCopy(state.plans);
        let newVisiblePlans = deepCopy(state.visiblePlans);
        let newSelectedPlans = deepCopy(state.selectedPlans);
        // Find the plan and update the premium for that plan
        newPlans = findAndUpdatePlanName(newPlans, action.plan.code, action.customName);
        newVisiblePlans = findAndUpdatePlanName(newVisiblePlans, action.plan.code, action.customName);
        newSelectedPlans = findAndUpdatePlanName(newSelectedPlans, action.plan.code, action.customName);

        newState = {
            ...state,
            plans: newPlans,
            visiblePlans: newVisiblePlans,
            selectedPlans: newSelectedPlans,
        };
    } else { // Type === "enrolled"
        let newEnrolledPlans;
        let product = action.productType;
        if (product === 'FI') {
            const pkg = action.plan.packages ? action.plan.packages[0] : 'standalone';
            // If enrolled plans is not empty
            if (Object.keys(state.enrolledPlans).length !== 0) {
                let packagePlansArray = deepCopy(state.enrolledPlans[pkg]);
                packagePlansArray = findAndUpdatePlanName(packagePlansArray, action.plan.code, action.customName);

                newEnrolledPlans = {
                    ...deepCopy(state.enrolledPlans),
                    [pkg]: packagePlansArray,
                };
            } else {
                newEnrolledPlans = {
                    ...deepCopy(state.enrolledPlans),
                };
            }

            newState = {
                ...state,
                enrolledPlans: newEnrolledPlans,
            };
        } else {
            product = product.charAt(0).toUpperCase() + product.slice(1);
            newEnrolledPlans = deepCopy(state[`enrolled${product}Plans`]);
            newEnrolledPlans = findAndUpdatePlanName(newEnrolledPlans, action.plan.code, action.customName);

            newState = {
                ...state,
                [`enrolled${product}Plans`]: newEnrolledPlans,
            };
        }
    }

    return {
        ...newState,
    };
};
export const handleUpdateGIAmountInLifePlans = (state, action) => {
    const newEnrolledLifePlans = deepCopy(state.enrolledLifePlans);
    newEnrolledLifePlans.forEach((plan) => {
        if (!plan.guaranteedIssue || plan.guaranteedIssue === 'See Benefit Summary' || plan.guaranteedIssue === '' || !/^\d+$/.test(plan.guaranteedIssueAmount)) {
            plan.guaranteedIssue = action.guaranteedIssue;
        }
    });
    return {
        ...state,
        enrolledLifePlans: newEnrolledLifePlans,
    };
};

/**  When it's a washington two tier, case the medical rating filter change modifies the rates in specialty products(dental and vision)
    Pick rates for corresponding tier and store them in the rates deciding fields. */
export const handleRatingMethodChange = (state, action) => {
    const newPlans = deepCopy(state.plans);
    const newSelectedPlans = deepCopy(state.selectedPlans);
    const newVisiblePlans = deepCopy(state.visiblePlans);
    const rateTierKey = action.ratingMethod === 'age' ? 'fourTier' : 'twoTier';
    for (let i = 0; i < newPlans.length; i++) {
        if (newPlans[i].tierRates && newPlans[i].tierRates[rateTierKey]) {
            newPlans[i] = {
                ...newPlans[i],
                finalRates: newPlans[i].tierRates[rateTierKey].monthlyPremium,
                quote: newPlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                finalMonthlyPremium: newPlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                ...newPlans[i].tierRates[rateTierKey]
            }
        }
    }

    for (let i = 0; i < newSelectedPlans.length; i++) {
        if (newSelectedPlans[i].tierRates && newSelectedPlans[i].tierRates[rateTierKey]) {
            newSelectedPlans[i] = {
                ...newSelectedPlans[i],
                finalRates: newSelectedPlans[i].tierRates[rateTierKey].monthlyPremium,
                quote: newSelectedPlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                finalMonthlyPremium: newSelectedPlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                ...newSelectedPlans[i].tierRates[rateTierKey]
            }
        }
    }

    for (let i = 0; i < newVisiblePlans.length; i++) {
        if (newVisiblePlans[i].tierRates && newVisiblePlans[i].tierRates[rateTierKey]) {
            newVisiblePlans[i] = {
                ...newVisiblePlans[i],
                finalRates: newVisiblePlans[i].tierRates[rateTierKey].monthlyPremium,
                quote: newVisiblePlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                finalMonthlyPremium: newVisiblePlans[i].tierRates[rateTierKey].byCensus.totalMonthlyPremium,
                ...newVisiblePlans[i].tierRates[rateTierKey]
            }
        }
    }

    return {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans,
        selectedPlans: newSelectedPlans
    };
}