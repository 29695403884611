import * as types from '../../actions/actionTypes';	

const initialState = {			
};	

export default function isMemberOnlyCirrusReducer(state = initialState, action) {
    {	
        switch (action.type) {	
            case types.RECEIVE_IS_MEMBER_ONLY_CIRRUS_RESPONSE:	
                return {	
                    ...state,	
                    ...action.payload	
                };	
            case types.RECEIVE_HAS_MEMBER_CHANGE_CIRRUS:
            return{
                    ...state,
                    ...action.payload
            }
            case types.RECEIVE_HAS_EMPLOYER_CHANGE_CIRRUS:
            return{
                    ...state,
                    ...action.payload
            }
            case types.RESET_TO_INITIALSTATE:
                return initialState
            default:	
                return state; 
        }	
    }
}