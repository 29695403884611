import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import rootReducer from '../reducers/rootReducer';
import reduxReset from 'redux-reset';
import { getAppEnv } from '../utils/environmentVariables';

const ENV = getAppEnv();

let reducer;
const REDUX_STORE_ENABLED = ENV === "local";
if (REDUX_STORE_ENABLED) {
    const persistConfig = {
        key: 'root',
        storage,
    };

    reducer = persistReducer(persistConfig, rootReducer);
} else {
    reducer = rootReducer;
}

// const createLogActionStackTraceMiddleware = (actionTypes = []) => {
//     const logActionStackTraceMiddleware = storeAPI => next => action => {
//         if(action.type && actionTypes.includes(action.type)) {
//             console.trace(`Action: ${action.type}`);
//         }

//         return next(action);
//     }

//     return logActionStackTraceMiddleware;
// }


// Use this to provide stack traces for redux calls
// Add the redux action type to the array we are passing into the createLogActionStackTraceMiddleware function call
// e.g. const stackTraceMiddleware = createLogActionStackTraceMiddleware(["PROFILE_UPDATED"]);

// const stackTraceMiddleware = createLogActionStackTraceMiddleware(["SAMPLEACTION1","SAMPLE_ACTION_2"]);

const middleware = [thunk];

if (ENV !== 'prod') {
    middleware.push(logger)
    // middleware.push(stackTraceMiddleware)
}

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25
});

export default () => {
    const store = createStore(
        reducer,
        ENV === "local" ? 
            composeEnhancers(applyMiddleware(...middleware), reduxReset()) 
            : composeWithDevTools(applyMiddleware(...middleware),reduxReset()),
    );
    const persistor = REDUX_STORE_ENABLED ? persistStore(store) : null;
    return { store, persistor };
};