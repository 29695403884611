/**
 * @param  {Object} requestBody rate call request body
 * @param  {Object} store redux store to extract data
 * @return {Object} Override rates object
 */
export const updateOverridesRequestBody = (requestBody, store) => {
    const overRideRates = {};

    const requestDentalPlansCode = requestBody.dentalPlanCodes || [];
    const requestVisionPlansCode = requestBody.visionPlanCodes || [];
    const requestLifePlansCode = requestBody.blPlanCode || [];

    // send renewing plan rates to CQS to receieve updated rates
    updateIBROverRide(store, 'dental', overRideRates, requestDentalPlansCode);
    updateIBROverRide(store, 'vision', overRideRates, requestVisionPlansCode);
    updateIBROverRide(store, 'life', overRideRates, requestLifePlansCode);
    updateIBROverRide(store, 'dependentLife', overRideRates, requestLifePlansCode);
    
    // send user adjusted rates to CQS to receieve updated rates
    updateOverRideRates(store, 'dental', requestDentalPlansCode, overRideRates);
    updateOverRideRates(store, 'vision', requestVisionPlansCode, overRideRates);
    updateOverRideRates(store, 'life', requestLifePlansCode, overRideRates);

    return overRideRates;
}

/**
 * @param  {Object} store redux store to extract data
 * @param  {String} product product name
 * @param  {Object} overRideRates override object to be updated
 * @param  {Array} requestPlansCode plan code array
 */
function updateIBROverRide(store, product, overRideRates, requestPlansCode) {
    let renewingPlans = [];
    const currentPath = window.location.href.split('/').pop();

    if (typeof requestPlansCode === 'string') {
        requestPlansCode = JSON.parse(requestPlansCode);
    }

    switch (currentPath) {
        case 'renewalShopping':
            renewingPlans = store.renewalShoppingHelper[product] && store.renewalShoppingHelper[product].set ? store.renewalShoppingHelper[product].set.flat().filter(plan => plan.renewalPlanType === 'renewing' || plan.renewalPlanType === 'alternate') : [];
            break;
        case 'decideDetail':
            renewingPlans = store.decide[product] && store.decide[product].selectedPlans ? store.decide[product].selectedPlans : [];
            break;
        case 'manager':
            renewingPlans = store.renewalEnrollment[product] && store.renewalEnrollment[product].selectedPlans ? store.renewalEnrollment[product].selectedPlans : [];
            break;
        default:
            renewingPlans = [];
            break;
    }

    const codeType = product === 'life' || product === 'dependentLife' ? 'primePlanCode' : 'code'
    requestPlansCode.forEach(code => {
        const plan = renewingPlans.find(plan => plan.code === code);
        if (plan) {
            const planCode = plan[codeType];
            if (product === 'dependentLife') {
                overRideRates[planCode] = {
                    depLifeChildRate: plan.dependentLifeRate,
                    depLifeSpouseRate: plan.dependentLifeRate
                }
            } else {
                overRideRates[planCode] = plan['finalRates'];
            }
        }
    })
}

/**
 * @param  {Object} store redux store to extract data
 * @param  {String} product product name
 * @param  {Object} overRideRates override object to be updated
 * @param  {Array} requestPlansCode plan code array
 */
function updateOverRideRates(store, product, requestPlansCode, overRideRates) {
    let selectedPlans = [];

    if (typeof requestPlansCode === 'string') {
        requestPlansCode = JSON.parse(requestPlansCode);
    }
    const { previousPage = '' } = store.overall;

    const capitalizeProductKey = product.charAt(0).toUpperCase() + product.slice(1);
    const currentPath = window.location.pathname

    // if current page is shopping get overrides from decide or review
    if (currentPath.includes('renewalShopping')) {
        switch(previousPage) {
            case '/decideDetail':
                selectedPlans = store.decide[product] && store.decide[product].selectedPlans || [];
                break;
            case '/reviewDetail':
                selectedPlans = store.review[product] && store.review[product].selectedPlans || [];
                break;
            default:
                selectedPlans = store.renewalShoppingHelper[product] && store.renewalShoppingHelper[product].selectedPlans || [];
        }
    }

    // if current page is decide(propose enrollment call) get overrides from decide
    if (currentPath.includes('decideDetail')) {
        selectedPlans = store.decide[product] && store.decide[product].selectedPlans || [];
    }

    // if current page is enrollment manager(installation/after close enrollment) get overrides from decide
    if (currentPath.includes('enrollment')) {
        selectedPlans = store.rules.enrollment[`enrolled${capitalizeProductKey}Plans`] || [];
    }

    requestPlansCode.forEach(code => {
        const plan = selectedPlans.find(plan => plan.code === code);
        if (plan && plan.hasOwnProperty('rateAdjustments')) {
            const planCode = product === 'life' ? plan.primePlanCode : plan.code;
            overRideRates[planCode] = plan['finalRates'];
        }
    })
}