// US2988455 dependent life
import * as types from '../../actions/actionTypes';
import { initialFilterCriteria, initialState } from './consts/suppLifeReducerConsts';
import {handleUpdatePlan} from "./helpers/filteringReducerHelpers";
import {handleSelectedVisiblePlans} from "./suppLifeReducer";


const initialStates = { ...initialState, advanceFilterProductType: 'suppLife_dep_basic' }

const requestSuppLifePlans = (state, action) => {
    return {
        ...state,
        isFetchingPlans: true,
        quickQuotePlansError: null,
    }
}

export function receiveSuppLifePlans(state, action) {
    if(!action.payload.plans) {
        return state
    }
    const {
        quickQuoteRequest: { zipObj: { zipCode: currentZip } },
    } = action;

    const plans = action.payload.plans.map(plan => ({ ...plan, selected: false, favorited: false, planType: 'supp_dep_life' }));
    const {
        selectedPlans: stateSelectedPlans,
        selectedPlansMap: stateSelectedPlansMap,
        favoritedPlans: stateFavoritedPlans,
        quickQuoteRequest: { zipObj: { zipCode: prevZip } },
    } = state;

    const changedZip = prevZip !== currentZip;
    const selectedPlans = changedZip ? [] : stateSelectedPlans;
    const selectedPlansMap = changedZip ? {} : stateSelectedPlansMap;

    const updatedPlans = plans.map((plan) => {
        const selected = selectedPlans.some((pln) => (pln.code === plan.code));
        const favorited = stateFavoritedPlans.some((pln) => (pln.code === plan.code));
        return { ...plan, selected, favorited };
    });

    return {
        ...state,
        quickQuoteRequest: action.quickQuoteRequest,
        plans: updatedPlans,
        visiblePlans: updatedPlans,
        selectedPlans,
        selectedPlansMap,
        numRatesFetched: 0,
        allPlans: plans,
        advancefilterSet: false,
        isFetchingPlans: false,
        allRatesFetched: false
    };
}

export default function suppLifeDep(state = initialStates, action) {
    switch (action.type) {
        case types.REQUEST_DEP_SUPP_LIFE_PLANS:
            return requestSuppLifePlans(state, action);
        case types.RECEIVE_DEP_SUPP_LIFE_PLANS:
            return receiveSuppLifePlans(state, action);
        case types.SERVICE.SUPP_DEP_LIFE.UPDATE_PLAN:
            return handleUpdatePlan(state, action);
        case types.SERVICE.SUPP_DEP_LIFE.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };
        case types.SERVICE.SUPP_DEP_LIFE.CLEAR_SELECTED_VISIBLE_PLANS:
            return handleSelectedVisiblePlans(state, action);
        default:
            return state
    }
}
