import { axios } from '../api/axiosConfig';
import apiRoutes from '../api/apiRouteConfig';

export const getPcpAutoAssignemntRules = (stateCode, groupSize) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.mongo}/get-pcp-autoAssignment-rules?stateCode=${stateCode}&groupSize=${groupSize}`)
            .then(res => {
                resolve(res.data);
            }).catch(() => {
                reject();
            })
    })
}

export const shouldDisableAutoAssignment = (pcpAssignmentRules, medicalPlanType) => {
    let disableAutoAssignment = false;
    pcpAssignmentRules.forEach(rule => {
        const planTypeToBeDisabled = rule.planTypes;
        if (rule.isDisableForAll) {
            disableAutoAssignment = true;
        } else {
            if (planTypeToBeDisabled.find(disableType => disableType.toUpperCase() === medicalPlanType.toUpperCase())) {
                disableAutoAssignment = true;
            }
        }
    })
    return disableAutoAssignment;
}

export const uhcDefaultPcpAssignmentOptions = ['Auto Assign', 'Enter in a PCP code'];

export const uhcDisabledAutoAssignOptions = ['Enter in a PCP code'];