import deepCopy from "../../deepCopy";
import { nodesToBeRemoved, nodesToBeKept } from './mapping';
import { removeFromPath, updateValue, getValue } from "../helperFunctions";

export const reduceStateStorePayload = (completeStateStore => {
    const stateStore = deepCopy(completeStateStore);
    // Remove duplicate nodes
    Object.keys(nodesToBeRemoved).forEach(node => {
        if (stateStore[node]) {
            const fieldsToBeRemoved = nodesToBeRemoved[node];
            fieldsToBeRemoved.forEach(field => {
                if (field.includes('.')) {
                    const updatedFields = node + '.' + field;
                    const pathArray = updatedFields.split('.');
                    removeFromPath(stateStore, pathArray, 0);
                } else {
                    delete stateStore[node][field]
                }
            })
        }
    })

    // Nodes that are required to be saved
    Object.keys(nodesToBeKept).forEach(node => {
        if (stateStore[node]) {
            const fieldsToBeSaved = nodesToBeKept[node];
            const nodeCopy = deepCopy(stateStore[node]);
            delete stateStore[node];
            stateStore[node] = {};
            fieldsToBeSaved.forEach(field => {
                const value = getValue(nodeCopy, field);
                updateValue(stateStore[node], field.split('.'), 0, value);
            })
        }
    })

    return stateStore;
})