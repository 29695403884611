import { axios } from '../../utils/api/axiosConfig';
import { history } from '../../utils/history';
import { techErrorEmail } from '../api/proposals';
import { isUhcRenewals, isMmr } from '../businessLogic/platformUtils';
import { UHC_REN_TECH_EMAIL_SUBJECT, UHC_OXFORD_CODE_BREAK_SUBJECT } from './logKeys';
const apiRoutes = require('../../utils/api/apiRouteConfig');

/**
 * @description Save error logs in TechnicalErrors collection
 *
 * @param {String} keyName key name used for wildcard to search error
 * @param {String} errorType type of error in 'MarketType_Function_Action_Message(optional) format'
 * @param {String} functionName name of function responsible for the error
 * @param {String} request request payload for the API call
 * @param {Object} error object that will have info related to the error
 */
export const logError = (keyName, errorType, functionName, request, error) => {
    const { store } = require('../../index');
    const { companyProfile, overall, caseTracking, renewals } = store.getState();
    const isUhcRenewal = isUhcRenewals();
    const isMmrCase = isMmr();
    const dataKey = isUhcRenewal ? renewals.renewalCaseData : companyProfile;
    const memberGroupId =  dataKey.memberGroupId ? dataKey.memberGroupId : '';
    const platform = overall.platform;
    const quoteTrackingNumber = caseTracking && caseTracking.currentQuoteId ? caseTracking.currentQuoteId : '';
    const caseId = isUhcRenewal ? dataKey.caseId : caseTracking.caseId ? caseTracking.caseId : '';
    const companyName = isUhcRenewal ? dataKey.memberGroupName : dataKey.companyName;
    const pathname = history.location.pathname || '';
    const { quickQuoteRequest: { zipObj }, appConfigDetails } = overall;
    const requestBody = {
        [keyName]: errorType,
        functionName: functionName,
        path: pathname,
        request: request,
        error: error,
        timeStamp: new Date(),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        companyName: companyName,
        memberGroupId: memberGroupId,
        quoteTrackingNumber: quoteTrackingNumber,
        caseId: caseId,
        marketType: platform === 'PRIME' ? 'UHC': 'Oxford',
        stateCode: zipObj.stateCode || '',
        zipCode: zipObj.zipCode || '',
        isUhcRenewals: isUhcRenewal,
        isMmrCase
    }

    if((isUhcRenewal || isMmrCase) && !appConfigDetails.logUhcRenErrors) {
        return;
    }

    axios.post(`${apiRoutes.mongo}/save-error-logs`, requestBody).then(res => {
        if((isUhcRenewal || isMmrCase) && appConfigDetails.sendUhcRenErrorsEmail) {
            const reqData = {
                caseId: requestBody.caseId,
                path: requestBody.path,
                functionName: requestBody.functionName,
                docId: res.data.docId,
                companyName: requestBody.companyName,
                memberGroupId: requestBody.memberGroupId
            }

            const subject = UHC_REN_TECH_EMAIL_SUBJECT, 
                msg = generateUhcRenewalsEmailMsg(reqData);
            store.dispatch(techErrorEmail(subject, msg, undefined, undefined, isUhcRenewal));
        }

        if (functionName === 'codebreak' && appConfigDetails.env === 'prod') {
            const messageBody = '<b>Please find the case details below</b>' +
            '<br /> ' +
            `${memberGroupId && `<b>Member Group ID</b>: ${memberGroupId}, `}` +
            `${companyName && `<b>Group Name</b>: ${companyName}, `}` +
            `${caseId && `<b>Case ID</b>: ${caseId}, `}` +
            `<b>Page</b>: ${pathname}`
            store.dispatch(techErrorEmail(UHC_OXFORD_CODE_BREAK_SUBJECT, messageBody));
        }
    }).catch(err => {
        console.log(err);
    })
}

const generateUhcRenewalsEmailMsg = (data) => {
    return `<b>Case Details</b>: <br><br>
        <b>Case Id</b>: ${data.caseId},<br>
        <b>Member Group Id</b>: ${data.memberGroupId},<br>
        <b>Company Name</b>: ${data.companyName},<br>
        <b>Page</b>: ${data.path},<br>
        <b>Function Name</b>: ${data.functionName},<br><br>
        Refer in <b>TechnicalErrorsUhc</b> collection for more information - <br><b>Document ID</b>: ${data.docId}`
}
