import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import './shared.css';
import { axios } from '../../utils/api/axiosConfig';
import apiRoutes from '../../utils/api/apiRouteConfig';
import FeedbackModal from './Feedback/FeedbackModal';
import { sendClickEventDataAction } from '../../actions/analyticsAction';
class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active: '',
            policyURL: '',
            termsURL: '',
            faqURL: '',
            contactURL: '',
            compensationURL: '',
            copyRightText: '2021 United HealthCare Services, Inc.',
            loading:true
        }
    }

    componentDidUpdate(prevProps) {
        // Activate the stle based on screen
        if (prevProps.pathname !== this.props.pathname) {
            if (this.props.pathname !== '/full' && this.props.pathname !== '/login' && this.props.pathname !== '/renewal-dashBoard' && this.props.pathname !== '/employee') {
                this.setState({ active: true });
            } else {
                this.setState({ active: false });
            }
        }
    }
    componentDidMount() {
        axios.get(`${apiRoutes.quickQuotes}/footerURLs`)
            .then((res) => {
                if (res.data && res.data.footerInfo && res.data.footerInfo.length) {
                    res.data.footerInfo.map((url) => {
                        if (url.footerName === "Privacy Policy") {
                            this.setState({ policyURL: url.footerUrl })
                        } else if (url.footerName === "Terms Of Use") {
                            this.setState({ termsURL: url.footerUrl })
                        } else if (url.footerName === "FAQs" || url.footerName === "FAQ") {
                            this.setState({ faqURL: url.footerUrl })
                        } else if (url.footerName === "Contact Us") {
                            this.setState({ contactURL: url.footerUrl })
                        } else if (url.footerName === "UHC Broker Compensation Guide") {
                            this.setState({ compensationURL: url.footerUrl })
                        }
                    })
                }
                if (res.data && res.data.copyright) {
                    this.setState({ copyRightText: res.data.copyright })
                }
                this.setState({ loading:false })
            })
    }
    handleFooterClick = (footerName, footerLink) => {
        this.props.sendClickEventDataAction({
            clickName: footerName,
            clickElement: 'Link',
            clickType: 'exit',
            clickDestination: footerLink,
            clickLocation: "Footer",

        })
        window.open(footerLink, "_blank")
    }
    render() {

        var d = new Date();

        return (
            // <div className='backgroundColorBlue bottomFooter'>
            //     <div className="footerText justifySpaceBetween">
            //         <List horizontal className="noMarginBottom">
            //             <List.Item className="footerTextSize" href={this.state.faqURL} target="_blank">FAQs</List.Item>
            //             <List.Item className="footerTextSize" href={this.state.contactURL} target="_blank">Contact Us</List.Item>
            //         </List>

            //         <List horizontal className="noMarginTop">
            //             <List.Item className="copyRightText">
            //                 &#169; {this.state.copyRightText}
            //             </List.Item>
            //         </List>

            //         <List horizontal className="noMarginTop">
            //             <List.Item className="footerTextSize" href={this.state.termsURL} target="_blank">Terms of Use</List.Item>
            //             <List.Item className="footerTextSize" href={this.state.policyURL} target="_blank">Privacy Policy</List.Item>
            //         </List>
            //     </div>
            // </div>
            this.state.loading? null :
            <>
                {/* {(this.props.pathname === '/quick-quotes')
                    && <FeedbackModal isTop={false} />} */}

                <div className='backgroundColorBlue bottomFooter flexFlowRowWrap justifySpaceAround paddingTopSmall paddingBottomSmall paddingLeftLarge paddingRightLarge ' style={{ maxHeight: '25px !important' }}>
                    <div>
                        <span className='whiteFont strongFont font-size-16  paddingRightMassive'>SAMx{this.props.overall.platform && this.props.overall.platform === 'PRIME' ? '' : ' Oxford'}</span>
                        <span className='whiteFont'>{this.state.copyRightText}</span>
                    </div>
                    <div className='flexFlowRowWrap'>
                        <a className='whiteFont paddingRightMedium' onClick={() => this.handleFooterClick("UHC Broker Compensation Guide", this.state.compensationURL)}>
                            UHC Broker Compensation Guide &nbsp;
                            <Icon name='external alternate' color='white' size='small' />
                        </a>
                        <a className='whiteFont paddingRightMedium' onClick={() => this.handleFooterClick("Terms of Use", this.state.termsURL)}>
                            Terms of Use &nbsp;
                        <Icon name='external alternate' color='white' size='small' />
                        </a>
                        <a className='whiteFont paddingRightMedium' onClick={() => this.handleFooterClick("Privacy Policy", this.state.policyURL)}>
                            Privacy Policy &nbsp;
                        <Icon name='external alternate' color='white' size='small' />
                        </a>
                        <a className='whiteFont paddingRightMedium' onClick={() => this.handleFooterClick("Contact Us", this.state.contactURL)}>
                            Contact Us &nbsp;
                         <Icon name='external alternate' color='white' size='small' />
                        </a>
                        <a className='whiteFont paddingRightMedium' onClick={() => this.handleFooterClick("FAQ", this.state.faqURL)}>
                            FAQs &nbsp;
                        <Icon name='external alternate' color='white' size='small' />
                        </a>
                    </div>

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    overall: state.overall,
});
const mapDispatchToProps = {
    sendClickEventDataAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

