
export const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    planType: {
        ino: false,
        incentive: false,
        indemnity: false,
        passive: false
    }
};

export const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    // TBD
}

export const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        zipCode: null,
        zipObj: {},
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    isFetchingPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    plans: [],
    allPlans:[],
    visiblePlans: [],
    selectedPlans: [],
    favoritedPlans: [],
    selectedPlansMap: {},
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    filterTotalsCache: initialfilterTotalsCache,
    specialty: true,
    sortEnabled: true,
    ascending: true,
    showPreferredPlans: true,
    filteredResults:[],
    appliedFilters:{},
    advanceFilterProductType:'supplife',
    displayLeftSideFilter:false,
    isSendingRateAdjustments: false,
    rateAdjustmentsError: false,
    employeeClassing : {
        classingOpted : false, // when opts for any class from dropdown
        classes : [], // List of opted classes by the user
        selectedClass : '', // Class selected on plan Selection Page
        employeeSelectedClasses: [] // Subset of life classes that have employees tied them on quote census
    },
    classingOptions:["Hourly", "Salaried", "Union", "Non-Union", "Part-Time", "Full-Time", "Management", "Non-Management"],
    classRatesFetched: false,
    isFetchingClassRates: false,
    showDependentLifeComponents: false,
    showSupplementalLifeComponents: false,
    // showSuppDependentLifeComponents:false
};

export const suppLifeFilterSet = [
    {
        filterGroup:'Plan Code',
        filterId:'plancode',
        planField:'code',
        type:'list',
        place:'dropdown',
        display:false,
        values: {}
    },
    {
        filterGroup:'Plan Type',
        filterId:'planType',
        planField:'benefitAmount',
        type:'list',
        place:'basic',
        display:true,
        values: {}
    },
    /**{
        filterGroup:'Guaranteed Issue',
        filterId:'guaranteedIssue',
        planField:'rateGuarantee',
        type:'list',
        place:'basic',
        display:true,
        values: {}
    },
    {
        filterGroup:'Accelerated Death Benefit',
        filterId:'acceleratedDeathBenefit',
        planField:'acceleratedDeathBenefit'  ,
        type:'list',
        place:'basic',
        display:true,
        values: {}
    },

    {
        filterGroup:'AD&D Loss Period',
        filterId:'ADandDLossPeriod',
        planField:'adAndDBenefit.lossPeriod',
        type:'list',
        place:'basic',
        display:true,
        values: {}
    },**/
 
]