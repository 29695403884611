/**
 * Function helps to replicate the deplay in the code execution for the given msec
 * @param {*} msec : time in miliseconds
 * @returns Release the thread after given time if used with the async await.
 */
export const sleep = async(msec) => {
    return new Promise(resolve => setTimeout(resolve, msec));
}

/**
 * Function to verity array has an item or not.
 * @param {*} array An array to verify item present or not
 * @returns true if array has at least one item.
 */
export const hasItemsInArray = (array) => {
    if (array && array.length > 0){
        return true;
    }
    
    return false;
}

export const getReduxState = () => {
    const { store } = require('../index');
    return store.getState()
}

export const getAvailableSelectedPlans = (allPlans, selectedPlans) => {
    const availableSelectedPlan = [];
    const unavailableSelectedPlans = [];
    selectedPlans.forEach(selectedPlan => {
        const availablePlan = allPlans.find(plan => plan.code === selectedPlan.code);
        if (availablePlan) {
            availableSelectedPlan.push(availablePlan);
        } else {
            unavailableSelectedPlans.push(selectedPlan);
        }
    })
    return {availableSelectedPlan, unavailableSelectedPlans};
}

export const getSelectedAndEnrolledPlans = (selectedPlans, enrolledPlans, unavailablePlanCodes) => {
    const updatedSelectedPlans = selectedPlans.filter(selectedPlan => !unavailablePlanCodes.includes(selectedPlan.code));
    const updatedEnrolledPlans = enrolledPlans.filter(selectedPlan => !unavailablePlanCodes.includes(selectedPlan.code));
    return {updatedSelectedPlans, updatedEnrolledPlans};
}

export const getUniqueMedicalPlans = (medicalPlans) => {
    const uniquePlans = [];
    medicalPlans.forEach(medicalPlan => {
        if (!uniquePlans.find(plan => plan.code === medicalPlan.code && plan.standAlone === medicalPlan.standAlone)) {
            uniquePlans.push(medicalPlan);
        }
    })
    return uniquePlans;
}

export const getUniqueMedicalPlansFromPackage = (medicalPlans, pkg) => {
    let uniquePlans = [];
    const isStandAlone = pkg === 'Single' ? true : false
    medicalPlans.forEach(medicalPlan => {
        const foundPlan = uniquePlans.find(plan => plan.code === medicalPlan.code);
        if (!foundPlan) {
            uniquePlans.push(medicalPlan);
        } else {
            if (isStandAlone) {
                if (foundPlan.standAlone !== true) {
                    uniquePlans = uniquePlans.filter(plan => plan.code !== foundPlan.code);
                    uniquePlans.push(medicalPlan);
                }
            } else {
                if (foundPlan.standAlone === true) {
                    uniquePlans = uniquePlans.filter(plan => plan.code !== foundPlan.code);
                    uniquePlans.push(medicalPlan);
                }
            }
        }
    })
    return uniquePlans;
}