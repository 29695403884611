import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { isEqual } from 'lodash';
import { history } from '../utils/history';
import sendLog from './logActions';
import { isEmpty } from '../utils/formatters/strings';
import { getEnrollmentRules, getRateAdjustmentRules } from './rulesActions';
import { specialtyServices, FI_IDENTIFIER, DENTAL, VISION, LIFE } from '../utils/consts/consts';
import {
    getAllQuickQuotePlans,
    checkEmployeesForOOA,
} from './quickQuotesActions';
import { checkZipObj } from '../utils/checkers/propTypes';
import { getPrimaryLocation } from '../components/activities/FullShopping/Census/helpers/checkPrimaryLocation';
// import { adjustRatesAndPremium } from './rateAdjustmentActions';
import { auditUserAction } from './actionHelpers/createHistoryHelper';
import { getLifeRatesInClasses } from './actionHelpers/lifeClassRatesActionHelper';
import { validEmpCensus, removeWaivedAllEmployees } from '../components/activities/Enrollment/EmployeeEnroll/helpers/employeeRateHelper';
import deepCopy from '../utils/deepCopy';
import { updateEmployeeEnrollmentCompletion, updateEnrolledPlansRates } from './enrollmentActions';
import { createHistory } from './historyAction';
import { getRatingCount } from './companyProfileActions';
import { changeFilterCriteria } from './quickQuotesActions';
import { isWasinghtonTwoTierCase, getRatingTier } from './actionHelpers/fullQuoteActionHelper';
import { displayUnsupportedOxfordZipCode, displayUnsupportedOxfordZipCodePopupShow } from './overallActions';
import { getAllRidersRatesFS } from './benefitRiderRatesActions';
import { UHC_OXFORD_MEDICAL_PLAN_ERROR, UHC_OXFORD_MEDICAL_RATE_ERROR, UHC_OXFORD_SPECIALTY_PLAN_ERROR, UHC_OXFORD_SPECIALTY_RATE_ERROR, KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { logError } from '../utils/errorLogging/logError';
import { getSelectedAndEnrolledPlans, getUniqueMedicalPlans } from '../utils/commonUtils'
import { setPlansAsFavorite } from '../utils/businessLogic/favoritePlanCodes'
import { isUhcRenewals, getMarketType, MARKET_TYPES } from '../utils/businessLogic/platformUtils';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { CASE_PROGRESS, getNBCaseProgress, inSubmittedStatus } from '../reducers/products/helpers/companyProfileConsts';
import { isUndefinedOrNull } from '../utils/validation/commonValidations';
const apiRoutes = require('../utils/api/apiRouteConfig');

/**
 *
 * @param {object} staticRequestInfo
 * @param {array} medicalPlanCodes
 * @param {number} index
 * @param {boolean} quoteStatus
 * @param {string} destinationPage one of 'plans', 'quote-review'
 * @param {array} preselectedPlans array of minimal plan objects -- each must have 'code' and 'platformRatingID'
 * @param {object} OISABit
 * @param {object} selectedOptionalRiders
 * @param {number} Used for Medical and Rx Plan Code search, evaluates number of plan codes passed and rated
 */
export function saveRateForProposal(selectedMedicalPlans, ratingMethod, quoteStatus) {
    return (dispatch) => {
        const ratingMethodType = ratingMethod.age ? 'Age Banded' : 'Composite';
        const medicalRateIds = getRateIds(selectedMedicalPlans, ratingMethodType)
        dispatch(saveRateIDsInPrime(medicalRateIds)); // save rate ids in prime
        dispatch(updateRateReferenceId({ medicalRatingId: medicalRateIds }, quoteStatus));
    }
}
function requestRateBatch(
    staticRequestInfo,
    medicalPlanCodes,
    index,
    quoteStatus,
    destinationPage = 'plans',
    preselectedPlans = [],
    OISABit = {},
    selectedOptionalRiders,
    planCodesLength = -1,
    sourcePage = ''
) {
    return (dispatch, getState) => {
        let fullProps = getState()
        let filteredEmployees = staticRequestInfo.employees;
        let counter = 1
        if (isEmpty(staticRequestInfo.simpleCensus)) {
            const employeesCopy = deepCopy(staticRequestInfo.employees);
            filteredEmployees = employeesCopy?.map((employee, i) => {
                const covType = employee.coverages.medical;
                if (staticRequestInfo.platform !== 'PRIME') {
                    const filteredDependents = employee.dependents.filter((dependent) => {
                        if (covType === 'EE') {
                            return false;
                        } else if (covType === 'EE/SP') {
                            if (dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                                return false;
                            }
                        } else if (covType === 'EE/CH') {
                            if (dependent.relationship === 'Spouse' || dependent.relationship === 'Domestic Partner' || dependent.relationship === 'Civil Union Partner' || dependent.relationship === 'Life Partner') {
                                return false;
                            }
                        }
                        return true;
                    })
                    employee.dependents = filteredDependents;
                }
                if (staticRequestInfo.platform !== 'PRIME') {
                    employee.employeeId = employee.memberId ? employee.memberId : i + 1
                } else {
                    employee.employeeId = i + 1
                }
                return employee;
            });
            if (["PRIME", "NHP"].includes(staticRequestInfo.platform.toUpperCase())) {
                let updatedEmployees = removeWaivedAllEmployees(filteredEmployees);
                if (getMarketType() === MARKET_TYPES.UHC && staticRequestInfo.stateCode === 'CA') {
                    updatedEmployees = updatedEmployees.filter(emp => emp.employeeStatus !== '1099 Independent Contractors');
                }
                filteredEmployees = updatedEmployees;
            }
        }

        const requestBody = {
            groupSize: staticRequestInfo.groupSize,
            stateCode: staticRequestInfo.stateCode,
            zipCode: staticRequestInfo.zipCode,
            effectiveDate: staticRequestInfo.effectiveDate,
            countyCode: staticRequestInfo.countyCode,
            employees: filteredEmployees,
            simpleCensus: !isEmpty(staticRequestInfo.simpleCensus) ? staticRequestInfo.simpleCensus : {},
            location: staticRequestInfo.location,
            companyName: staticRequestInfo.companyProfile.companyName,
            sicCode: `${staticRequestInfo.companyProfile.sicCode}`,
            franchiseCode: !isEmpty(staticRequestInfo.companyProfile.franchiseCode) ? `${staticRequestInfo.companyProfile.franchiseCode}` : '',
            exchange: staticRequestInfo.companyProfile.NY_SHOP || false,
            ooa: checkEmployeesForOOA(staticRequestInfo.companyProfile.employees, staticRequestInfo.platform, quoteStatus) || false,
            platform: staticRequestInfo.platform,
            quoteStatus: quoteStatus === 'ProposedEnrolled' ? 'Proposed' : quoteStatus === 'QuoteReview_PlanSelectionPreInstallCensusChange' ? 'PreInstallCensusChange' : quoteStatus,
            platformRatingIDs: staticRequestInfo.platformRatingIDs,
            medicalPlanCodes,
            caseId: `${fullProps.caseTracking.caseId}-${fullProps.caseTracking.currentQuoteId}`,
            memberGroupId: fullProps.companyProfile && fullProps.companyProfile.memberGroupId ? fullProps.companyProfile.memberGroupId.toString() : "",
            OISABit: OISABit,
            selectedOptionalRiders,
            isDirectSale: fullProps.reviewCards.directSale,
            ratingCount: getRatingCount(fullProps.companyProfile.locations, fullProps.companyProfile.primaryLocationIdx)
        };

        dispatch({ type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_RATES });

        return new Promise((resolve, reject) => {
            const { _id: id } = getState().companyProfile;
            const ratingMethod = getState().quotes.filterCriteria.ratingMethod

            if (quoteStatus === 'PreInstallCensusChange' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled' || quoteStatus === 'PostEnrollPreInstall') {
                if (quoteStatus !== 'ProposedEnrolled') {
                    dispatch({
                        type: types.SERVICE.FI.FETCHING_FINALIZED_QUOTE_RATES,
                    });
                }
            }

            axios.post(`${apiRoutes.quickQuotes}/rates/startRates`, requestBody)
                .then(async (res) => {
                    const results = await getTransactionResult(res.data.transactionId);
                    if (results.statusCode === 400) {
                        throw await results;
                    }
                    const rates = results.medicalRates;
                    dispatch({
                        type: types.UPDATE_QUOTE_PROGRESS,
                        quoteProgress: quoteStatus,
                    });
                    dispatch(createHistory(`Get Medical Rates`, 'Full Quotes Action file', 'Success', requestBody, '', 'NB'));
                    // If any of these rates are for preselected plans,
                    // we use the preselected Rating ID instead
                    if (preselectedPlans.length > 0) {
                        rates.forEach((rate, i) => {
                            const preIdx = preselectedPlans
                                .findIndex((plan) => plan.code === rate.medicalPlanCode);
                            if (preIdx > -1) {
                                rates[i].platformRatingID = preselectedPlans[preIdx].platformRatingID;
                                rates[i].savedPlatformRatings = preselectedPlans[preIdx].savedPlatformRatings;
                            }
                        });
                    }

                    if (quoteStatus === 'Proposed' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled') {
                        if (staticRequestInfo.platform === 'CIRRUS') {
                            const platformRatingIDs = {
                                medicalRatingId: (rates && rates[0]) ? rates[0].platformRatingID : null
                            }
                            dispatch(updateRateReferenceId(platformRatingIDs, quoteStatus));
                        } else {
                            // PRIME Platform
                            const ratingMethodType = getState().quotes.filterCriteria.ratingMethod.age ? 'Age Banded' : 'Composite';
                            const rateIDs = getRateIds(rates, ratingMethodType);
                            dispatch(saveRateIDsInPrime(rateIDs)); // save rate ids in prime
                            dispatch(updateRateReferenceId({ medicalRatingId: rateIDs }, quoteStatus));
                        }
                    }
                    // If this action fires off from the finalize flow
                    if (quoteStatus === 'PreInstallCensusChange' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled' || quoteStatus === 'PostEnrollPreInstall' || quoteStatus === 'QuoteReview_PlanSelectionPreInstallCensusChange') {
                        if (quoteStatus !== 'ProposedEnrolled') {
                            // Reduce RECEIVE_FINALIZED_QUOTE_RATES
                            dispatch({
                                type:
                                    types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                rates,
                                dentalRates: [],
                                visionRates: [],
                                isAfterCloseEnrollmentQuoting: quoteStatus === 'QuoteReview_PlanSelectionPreInstallCensusChange',
                                lifeRates: [],
                                ratingMethod
                            });
                            // dispatch(saveStateStore(true))
                        }
                    } else if (quoteStatus === 'Proposed') {
                        dispatch({
                            type: types.SERVICE.FI.RECEIVE_QUICK_QUOTE_RATES,
                            rates,
                            isProposal: true,
                            employees: fullProps.companyProfile.employees.flat(),
                            platform: staticRequestInfo.platform
                        });


                        dispatch(updateEnrolledPlansRates(rates, 'medical'))
                    } else {
                        if (index === 0) {
                            if (destinationPage === 'plans') {
                                history.push('/plans');
                            } else if (destinationPage !== 'afterCloseEnrollment') { // destinationPage = 'quote-review'
                                history.push('/summary');
                                dispatch({ type: types.FINISH_RESUMING_QUOTE });
                            }
                        }
                        dispatch({
                            type: types.SERVICE.FI.RECEIVE_QUICK_QUOTE_RATES,
                            rates,
                            savedUpstream: quoteStatus !== 'Shopping' && quoteStatus !== 'Quoting',
                            planCodesLength,
                            employees: fullProps.companyProfile.employees.flat(),
                            platform: staticRequestInfo.platform

                        });
                        dispatch(updateEnrolledPlansRates(rates, 'medical'))
                    }
                    resolve();
                })
                .catch((err) => {
                    if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                        dispatch(displayUnsupportedOxfordZipCode(true));
                        dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                    }
                    else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                    }
                    else if (sourcePage !== 'employeeCensus') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE,
                        });
                    }

                    if (quoteStatus === 'PreInstallCensusChange' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled' || quoteStatus === 'PostEnrollPreInstall') {
                        if (quoteStatus !== 'ProposedEnrolled') {
                            dispatch({
                                type: types.SERVICE.FI.ERROR_FINALIZED_QUOTE_RATES,
                                payload: formatPlanOrRateCallError(err, 'rate', false),
                            });
                        }
                    } else {
                        dispatch({
                            type: types.SERVICE.FI.ERROR_QUICK_QUOTE_RATES,
                            payload: formatPlanOrRateCallError(err, 'rate', false)
                        });
                    }
                    logError(KEY_NAME, UHC_OXFORD_MEDICAL_RATE_ERROR, 'requestRateBatch', requestBody, err);
                    reject(err);
                });
        });
    };
}

export const appendBasicLifeDetailsInReqBody = (lifePlans, basicLifePlanCodes, reqBody) => {
    const blPlanCodesArray = [];
    const combinedCodesArray = [];
    const basicLifePlanCodesDetails = []
    basicLifePlanCodes.forEach(planCode => {
        const lifePlanObj = lifePlans.filter(plan => plan.code === planCode)[0];
        basicLifePlanCodesDetails.push({
            planCode: lifePlanObj.primePlanCode,
            depLifeSpouseAmount: lifePlanObj.spouseBenefitAmount ? lifePlanObj.spouseBenefitAmount : '',
            depLifeChildAmount: lifePlanObj.childBenefitAmount ? lifePlanObj.childBenefitAmount : ''
        });
        blPlanCodesArray.push(lifePlanObj.code);
        combinedCodesArray.push(`${lifePlanObj.code}--${lifePlanObj.ADandDCode}`)
    });

    reqBody.basicLifePlanCodes = basicLifePlanCodesDetails;
    reqBody.blPlanCode = blPlanCodesArray;
    reqBody.combinedCodes = combinedCodesArray;
}

export const appendSuppLifeDetailsInReqBody = (suppLifePlans, suppLifePlanCodes, reqBody) => {

    const blPlanCodesArray = [];
    const combinedCodesArray = [];
    const suppLifePlanCodesDetails = []
    suppLifePlanCodes.forEach(planCode => {
        const suppLifePlanObj = suppLifePlans.filter(plan => plan.code === planCode)[0];
        suppLifePlanCodesDetails.push({
            planCode: suppLifePlanObj.primePlanCode,
            depLifeSpouseAmount: suppLifePlanObj.spouseBenefitAmount ? suppLifePlanObj.spouseBenefitAmount : '',
            depLifeChildAmount: suppLifePlanObj.childBenefitAmount ? suppLifePlanObj.childBenefitAmount : ''
        });
        blPlanCodesArray.push(suppLifePlanObj.code);
        combinedCodesArray.push(`${suppLifePlanObj.code}--${suppLifePlanObj.ADandDCode}`)
    });

    reqBody.suppLifePlanCodes = suppLifePlanCodesDetails;
    reqBody.blPlanCode = blPlanCodesArray;
    reqBody.combinedCodes = combinedCodesArray;

}

function requestSpecialtyRateBatch(
    staticRequestInfo,
    dentalPlanCodes,
    visionPlanCodes,
    basicLifePlanCodes,
    stdPlanCodes,
    ltdPlanCodes,
    suppLifePlanCodes,
    index,
    quoteStatus,
    destinationPage = 'plans',
    sourcePage = '',
    ratingTier = '',
) {
    return (dispatch, getState) => {
        const fullProps = getState()
        const staticSpecialtyRequestString = `${apiRoutes.quickQuotes}/specialty-rates`;
        let filteredEmployees = staticRequestInfo.employees;
        if (isEmpty(staticRequestInfo.simpleCensus)) {
            const employeesCopy = deepCopy(staticRequestInfo.employees);
            filteredEmployees = employeesCopy.map((emp, i) => {
                emp.employeeId = emp.memberId ? emp.memberId : i + 1;
                return emp
            });
        };

        if (staticRequestInfo.platform.toUpperCase() === 'PRIME') {
            let updatedEmployees = filteredEmployees;

            if (isEmpty(staticRequestInfo.simpleCensus)) {
                updatedEmployees = removeWaivedAllEmployees(filteredEmployees);
            }

            if (getMarketType() === MARKET_TYPES.UHC && staticRequestInfo.stateCode === 'CA' && updatedEmployees) {
                updatedEmployees = updatedEmployees.filter(emp => emp.employeeStatus !== '1099 Independent Contractors');
            }

            filteredEmployees = updatedEmployees;
        }

        let companyName = staticRequestInfo.companyProfile ? staticRequestInfo.companyProfile.companyName : null

        const reqBody = {
            groupSize: staticRequestInfo.groupSize,
            zipCode: staticRequestInfo.zipCode,
            effectiveDate: staticRequestInfo.effectiveDate,
            stateCode: staticRequestInfo.stateCode,
            countyCode: staticRequestInfo.countyCode,
            platform: staticRequestInfo.platform,
            sicCode: staticRequestInfo.companyProfile.sicCode,
            empCensus: filteredEmployees,
            quoteStatus: quoteStatus === 'ProposedEnrolled' ? 'Proposed' : quoteStatus === 'QuoteReview_PlanSelectionQuoting' ? 'PreInstallCensusChange' : quoteStatus,
            platformRatingIDs: staticRequestInfo.platformRatingIDs,
            caseId: `${fullProps.caseTracking.caseId}-${fullProps.caseTracking.currentQuoteId}`,
            memberGroupId: fullProps.companyProfile && fullProps.companyProfile.memberGroupId ? fullProps.companyProfile.memberGroupId.toString() : "",
            companyName: `${companyName}`
        };

        if (staticRequestInfo.platform === 'PRIME' && quoteStatus === 'ProposedEnrolled') {
            reqBody.quoteStatus = quoteStatus;
        }

        if (staticRequestInfo.simpleCensus) {
            const employeeCounts = [staticRequestInfo.simpleCensus.ee, staticRequestInfo.simpleCensus.es, staticRequestInfo.simpleCensus.ec, staticRequestInfo.simpleCensus.ef, staticRequestInfo.simpleCensus.nc];
            reqBody.employeeCounts = employeeCounts;
        }

        if (dentalPlanCodes.length > 0) {
            reqBody.dentalPlanCodes = dentalPlanCodes;
        }

        if (visionPlanCodes.length > 0) {
            reqBody.visionPlanCodes = visionPlanCodes;
        }

        if (basicLifePlanCodes.length > 0) {
            const { life, lifeDep } = getState()
            const lifePlans = life.plans.concat(life.selectedPlans, lifeDep.plans, lifeDep.selectedPlans);
            appendBasicLifeDetailsInReqBody(lifePlans, basicLifePlanCodes, reqBody);
        }

        if (stdPlanCodes.length > 0) {
            reqBody.shortTermDisPlanCodes = stdPlanCodes;
        }

        if (ltdPlanCodes.length > 0) {
            reqBody.longTermDisPlanCodes = ltdPlanCodes;
        }

        if (suppLifePlanCodes.length > 0) {
            reqBody.suppLifePlanCodes = suppLifePlanCodes
        }

        // Append plans with adjusted rates if they exist
        const { dental: adjustedDental, vision: adjustedVision, life: adjustedLife, std: adjustedStd, ltd: adjustedLtd  } = getState().overall.userAdjustedRates;
        const { optumId } = getState().renewals.logInData[0];
        if (adjustedDental) {
            const { enrolledDentalPlans } = getState().rules.enrollment;
            const selectedPlans = fullProps.dental.selectedPlans;
            reqBody.enrolledDentalPlans = enrolledDentalPlans.length > 0 ? enrolledDentalPlans : selectedPlans;
            reqBody.optumId = optumId;
        }

        if (adjustedVision) {
            const { enrolledVisionPlans } = getState().rules.enrollment;
            const selectedPlans = fullProps.vision.selectedPlans;
            reqBody.enrolledVisionPlans = enrolledVisionPlans.length > 0 ? enrolledVisionPlans : selectedPlans;
            reqBody.optumId = optumId;
        }

        if (adjustedLife) {
            const { enrolledLifePlans } = getState().rules.enrollment;
            const selectedPlans = fullProps.life.selectedPlans;
            reqBody.enrolledLifePlans = enrolledLifePlans.length > 0 ? enrolledLifePlans : selectedPlans;
            reqBody.optumId = optumId;
        }

        if (adjustedStd) {
            const { enrolledSTDPlans } = getState().rules.enrollment;
            const selectedPlans = fullProps.std.selectedPlans;
            reqBody.enrolledStdPlans = enrolledSTDPlans.length > 0 ? enrolledSTDPlans : selectedPlans;
            reqBody.optumId = optumId;
        }

        if (adjustedLtd) {
            const { enrolledLTDPlans } = getState().rules.enrollment;
            const selectedPlans = fullProps.ltd.selectedPlans;
            reqBody.enrolledLtdPlans = enrolledLTDPlans.length > 0 ? enrolledLTDPlans : selectedPlans;
            reqBody.optumId = optumId;
        }

        // if (adjustedSuppLife) {
        //     const { enrolledSuppLifePlans } = getState().rules.enrollment;
        //     const selectedPlans = fullProps.suppLife.selectedPlans;
        //     reqBody.enrolledSuppLifePlans = enrolledSuppLifePlans.length > 0 ? enrolledSuppLifePlans : selectedPlans;
        //     reqBody.optumId = optumId;
        // }
        
        reqBody.franchiseCode = getState().companyProfile.franchiseCode;

        if (quoteStatus === 'Proposed' || quoteStatus === 'ProposedEnrolled' || quoteStatus === 'PreInstallCensusChange') reqBody.ratingCallType = 'proposal'
        if (quoteStatus === 'Shopping') reqBody.ratingCallType = 'shopping-full-quote'
        if (quoteStatus === 'Installation') reqBody.ratingCallType = 'finalize-enrollment'
        if (destinationPage === 'afterCloseEnrollment') reqBody.ratingCallType = 'after-close-enrollment'
        updateBodyForOverRideRates(reqBody, getState());

        let selectedTier = ''
        let rateCallTier = ''
        const { overall: { quickQuoteRequest } } = fullProps;
        if (isWasinghtonTwoTierCase(quickQuoteRequest)) {
            reqBody.ratingTier = quoteStatus === 'Shopping' ? ratingTier : getRatingTier(fullProps.quotes.filterCriteria.ratingMethod);
            selectedTier = quoteStatus === 'Shopping' ? '2 Tier' : getRatingTier(fullProps.quotes.filterCriteria.ratingMethod) // TODO Needs to be changed after testing
            rateCallTier = reqBody.ratingTier
        }

        if (reqBody.platform === "NHP") {
            reqBody.platform = "PRIME"
        }

        return new Promise((resolve, reject) => {
            const isDesQuote = getState().caseTracking.caseTrack[0].source
            const companyProfileEmployees = getState().companyProfile
            const flatEmployees = companyProfileEmployees.employees[0] // TODO:: handle for multiple location
            axios
                .post(staticSpecialtyRequestString, reqBody)
                .then((res) => {

                    const dentalRates = res.data.dentalRates ? res.data.dentalRates : [];
                    const visionRates = res.data.visionRates ? res.data.visionRates : [];
                    const lifeRates = res.data.basicLifeRates ? res.data.basicLifeRates : [];
                    const stdRates = res.data.shortTermDisRates ? res.data.shortTermDisRates : [];
                    const ltdRates = res.data.longTermDisRates ? res.data.longTermDisRates : [];
                    const suppLifeRates= res.data.suppLifeRates ? res.data.suppLifeRates : []
                    dispatch({
                        type: types.UPDATE_QUOTE_PROGRESS,
                        quoteProgress: quoteStatus,
                    });
                    dispatch(createHistory(`Get Specialty Rates`, 'Full Quotes Action file', 'Success', reqBody, '', 'NB'));
                    // Update platform rating IDs for Proposed || Installation
                    if (quoteStatus === 'Proposed' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled') {

                        const platformRatingIDs = {
                            dentalRatingId: (dentalRates && dentalRates[0]) ? (staticRequestInfo.platform === 'CIRRUS' ? dentalRates[0].platformRatingID : dentalRates[0].quoteId) : null,
                            visionRatingId: (visionRates && visionRates[0]) ? (staticRequestInfo.platform === 'CIRRUS' ? visionRates[0].platformRatingID : visionRates[0].quoteId) : null,
                            lifeRatingId: (lifeRates && lifeRates[0]) ? (staticRequestInfo.platform === 'CIRRUS' ? lifeRates[0].platformRatingID : lifeRates[0].quoteId) : null,
                            stdRatingId: (stdRates && stdRates[0]) ?  stdRates[0].quoteId : null,
                            ltdRatingId: (ltdRates && ltdRates[0]) ? ltdRates[0].quoteId : null,
                            suppLifeRatingId: (suppLifeRates && suppLifeRates[0]) ? suppLifeRates[0].quoteId : null,
                        };

                        if (platformRatingIDs.dentalRatingId === null) {
                            delete platformRatingIDs.dentalRatingId;
                        }

                        if (platformRatingIDs.visionRatingId === null) {
                            delete platformRatingIDs.visionRatingId;
                        }

                        if (platformRatingIDs['lifeRatingId'] === null) {
                            delete platformRatingIDs['lifeRatingId']
                        }

                        if (platformRatingIDs.stdRatingId === null) {
                            delete platformRatingIDs.stdRatingId;
                        }
                        if (platformRatingIDs.ltdRatingId === null) {
                            delete platformRatingIDs.ltdRatingId;
                        }
                        if (platformRatingIDs.suppLifeRatingId === null) {
                            delete platformRatingIDs.suppLifeRatingId;
                        }

                        dispatch(updateRateReferenceId(platformRatingIDs, quoteStatus));
                    }
                    // If this action fires off from the finalize flow
                    if (quoteStatus === 'PreInstallCensusChange' || quoteStatus === 'Installation' || quoteStatus === 'PostEnrollPreInstall' || quoteStatus === 'QuoteReview_PlanSelectionPreInstallCensusChange') {

                            // Reduce RECEIVE_FINALIZED_QUOTE_RATES
                            dispatch({
                                type:
                                    types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                rates: [],
                                dentalRates,
                                visionRates,
                                isAfterCloseEnrollmentQuoting: quoteStatus === 'QuoteReview_PlanSelectionPreInstallCensusChange',
                                lifeRates,
                                stdRates,
                                ltdRates,
                                suppLifeRates,
                                flatEmployees,
                                empClass: 'standalone'
                            });

                            if (stdRates && stdRates.length > 0) {
                                dispatch({
                                    type: types.UPDATE_ENROLLED_STD_PLANS_RATES,
                                    plans: stdRates
                                });
                            }
                            if (ltdRates && ltdRates.length > 0) {
                                dispatch({
                                    type: types.UPDATE_ENROLLED_LTD_PLANS_RATES,
                                    plans: ltdRates
                                });
                            }
                            if (suppLifeRates && suppLifeRates.length > 0) {
                                dispatch({
                                    type: types.UPDATE_ENROLLED_SUPPLIFE_PLANS,
                                    plans: suppLifeRates
                                });
                            }
                            // dispatch(saveStateStore(true))

                    } else if(quoteStatus === 'ProposedEnrolled') {

                        if (stdRates && stdRates.length > 0) {
                            dispatch({
                                type: types.UPDATE_ENROLLED_STD_PLANS_RATES,
                                plans: stdRates
                            });
                        }
                        if (ltdRates && ltdRates.length > 0) {
                            dispatch({
                                type: types.UPDATE_ENROLLED_LTD_PLANS_RATES,
                                plans: ltdRates
                            });
                        }
                    }
                    else if (quoteStatus === 'Proposed') {

                        if (dentalRates.length) {
                            const quotes = dentalRates.map((rate) => rate.byCensus.totalMonthlyPremium);
                            dispatch({
                                type: types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: dentalRates,
                                quotes,
                            });
                        }

                        if (visionRates.length) {
                            const quotes = visionRates.map((rate) => rate.byCensus.totalMonthlyPremium);
                            dispatch({
                                type: types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: visionRates,
                                quotes,
                            });
                        }

                        if (lifeRates.length) {
                            dispatch({
                                type: types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: lifeRates
                            })
                        }

                        if (stdRates.length) {
                            dispatch({
                                type: types.SERVICE.STD.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: stdRates
                            });
                        }
                        if (ltdRates.length) {
                            dispatch({
                                type: types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: ltdRates
                            });
                        }
                        if (suppLifeRates.length) {
                            dispatch({
                                type: types.SERVICE.SUPPLIFE.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: suppLifeRates
                            });
                        }
                    } else if (quoteStatus === 'Shopping') {

                        if (dentalRates.length) {
                            const quotes = dentalRates.map((rate) => rate.byCensus.totalMonthlyPremium);
                            dispatch({
                                type: types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: dentalRates,
                                quotes,
                                filteredResultsOnly: isDesQuote ? true : false,
                                selectedTier,
                                rateCallTier
                            });
                            dispatch(updateEnrolledPlansRates(dentalRates, 'dental'))
                        }

                        if (visionRates.length) {
                            const quotes = visionRates.map((rate) => rate.byCensus.totalMonthlyPremium);
                            dispatch({
                                type: types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: visionRates,
                                quotes,
                                filteredResultsOnly: isDesQuote ? true : false,
                                selectedTier,
                                rateCallTier
                            });
                            dispatch(updateEnrolledPlansRates(visionRates, 'vision'))
                        }

                        if (lifeRates.length) {
                            dispatch({
                                type: types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: lifeRates
                            })
                            dispatch(updateEnrolledPlansRates(lifeRates, 'life'))
                        }

                        if (stdRates.length) {
                            dispatch({
                                type: types.SERVICE.STD.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: stdRates,
                                platform: staticRequestInfo.platform
                            });
                            dispatch(updateEnrolledPlansRates(stdRates, 'std'))
                        }
                        if (ltdRates.length) {
                            dispatch({
                                type: types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: ltdRates,
                                platform: staticRequestInfo.platform
                            });
                            dispatch(updateEnrolledPlansRates(ltdRates, 'ltd'))
                        }
                        if (suppLifeRates.length) {
                            dispatch({
                                type: types.SERVICE.SUPPLIFE.RECEIVE_QUICK_QUOTE_RATES,
                                index,
                                rates: suppLifeRates
                            })
                            dispatch(updateEnrolledPlansRates(suppLifeRates, 'suppLife'))
                        }
                        const medicalIsSelected = getState().specialtyCriteria.viewSpecialty.medical;
                        // If medical is selected, navigation will be handled by the rates call
                        if (!medicalIsSelected) {
                            history.push('/plans');
                        }
                    } else if (index === 0) {
                        if (destinationPage === 'plans') {
                            history.push('/plans');
                        } else { // destinationPage = 'quote-review'
                            history.push('/summary');
                            dispatch({ type: types.FINISH_RESUMING_QUOTE });
                        }
                    }
                    resolve();
                })
                .catch((err) => {
                    if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                        dispatch(displayUnsupportedOxfordZipCode(true));
                        dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                    }
                    else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                    }
                    else if (sourcePage !== 'employeeCensus') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE,
                        });
                    }

                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_RATES,
                        payload: formatPlanOrRateCallError(err, 'rate', true)
                    });
                    logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'requestSpecialtyRateBatch', reqBody, err);
                    reject(err);
                });
        });
    };
}

function updateOverRideRates(state, productType, rateKey, codes, overRideRates, platform, classingOpted, empClass) {
    let plans;
    let codeKey;
    codes = codes && codes !== "" ? codes : [];
    if (typeof codes === 'string') {
        codes = JSON.parse(codes);
    }
    switch (productType) {
        case 'dental':
            plans = [...state.dental.selectedPlans];
            codeKey = 'code';
            break;
        case 'vision':
            plans = [...state.vision.selectedPlans];
            codeKey = 'code';
            break;
        case 'life':
            plans = [...state.life.selectedPlans, ...state.lifeDep.plans]
            codeKey = 'code';
            break;
        case 'std':
            plans = [...state.std.selectedPlans];
            codeKey = platform === 'PRIME' ? 'piaPlanCode' : 'code';
            break;
        case 'ltd':
            plans = [...state.ltd.selectedPlans];
            codeKey = platform === 'PRIME' ? 'piaPlanCode' : 'code';
            break;
        case 'suppLife':
            plans = [...state.suppLife.selectedPlans];
            codeKey = platform === 'PRIME' ? 'piaPlanCode' : 'slEEPlanCode';
            break;
    }
    codes.forEach((code) => {
        let plan = null;
        if (productType === 'suppLife') {
            const selectedSuppLifePlan = plans.find(plan => isEqual(code, {
                slEEPlanCode: plan.code ,
                slEEADnDPlanCode: plan.SLEEADDPlanCode,
                slSPPlanCode:plan.SLSpPlanCode,
                slSPADnDPlanCode:plan.SLSpADDPlanCode,
                slCHPlanCode:plan.SLChPlanCode,
                slCHADnDPlanCode:plan.SLChADDPlanCode
            }));
            if (selectedSuppLifePlan) {
                plan = selectedSuppLifePlan;
            }
        } else {
            plan = plans.find(p => p[codeKey] === code);
        }

        if (plan) {
            let planCode = productType === 'life' ? plan.primePlanCode : plan[codeKey];
            // If classed life - use class rates for life plans but NOT for dep life plans
            overRideRates[planCode] = classingOpted && plan.selectedClass ? (plan && plan.classRates ? plan.classRates[empClass] && plan.classRates[empClass][rateKey] : undefined) : plan && plan[rateKey];
            if ((productType === 'std' || productType === 'ltd' || productType === 'suppLife') && overRideRates[planCode]) {
                overRideRates[planCode] = { ...overRideRates[planCode], rateType: plan.rateType.split(" ")[0] }
            }
        }
    })
}

export function updateBodyForOverRideRates(reqBody, state, isLife = false, classingOpted = false, empClass = '') {
    let overRideRates = {};
    const caseProgress = getNBCaseProgress(state);
    const { dental: dentalAdjusted, vision: visionAdjusted, life: lifeAdjusted, std: stdAdjusted, ltd: ltdAdjusted, suppLife : suppLifeAdjusted } = state.overall.userAdjustedRates;
    if (!isLife) {
        if (caseProgress !== CASE_PROGRESS.QUOTING || reqBody.quoteStatus === "ProposedEnrolled") {
            updateOverRideRates(state, 'dental', dentalAdjusted ? "finalRates" : "monthlyPremium", reqBody.dentalPlanCodes, overRideRates);
            updateOverRideRates(state, 'vision', visionAdjusted ? "finalRates" : "monthlyPremium", reqBody.visionPlanCodes, overRideRates);
            updateOverRideRates(state, 'life', lifeAdjusted ? "finalRates" : "quote", reqBody.blPlanCode, overRideRates);
            updateOverRideRates(state, 'std', stdAdjusted ? "finalRates" : "quote", reqBody.shortTermDisPlanCodes, overRideRates, reqBody.platform);
            updateOverRideRates(state, 'ltd', ltdAdjusted ? "finalRates" : "quote", reqBody.longTermDisPlanCodes, overRideRates, reqBody.platform);
            updateOverRideRates(state, 'suppLife', suppLifeAdjusted ? "finalRates" : "quote", reqBody.suppLifePlanCodes, overRideRates, reqBody.platform);
        }
    }
    else {
        if (caseProgress !== CASE_PROGRESS.QUOTING || reqBody.quoteStatus === "quoteStatus") {
            updateOverRideRates(state, 'life', lifeAdjusted ? "finalRates" : "quote", reqBody.blPlanCode, overRideRates, '', classingOpted, empClass);
            updateOverRideRates(state, 'std', stdAdjusted ? "finalRates" : "quote", reqBody.shortTermDisPlanCodes, overRideRates, reqBody.platform,classingOpted, empClass);
            updateOverRideRates(state, 'ltd', ltdAdjusted ? "finalRates" : "quote", reqBody.longTermDisPlanCodes, overRideRates, reqBody.platform,classingOpted, empClass);
        }
    }
    reqBody.userAdjustedRates = state.overall.userAdjustedRates;
    reqBody.overRideRates = overRideRates;
    reqBody.quoteId = state.caseTracking.currentQuoteId ? state.caseTracking.currentQuoteId : 'QuickQuote';
    const isEnrollmentCase = reqBody.quoteStatus === 'Installation' || reqBody.quoteStatus === 'PreInstallCensusChange'
    reqBody.rateIds = {
        dentalRatingId: isEnrollmentCase ? state.overall.proposedRateIds && state.overall.proposedRateIds.dentalRatingId ? state.overall.proposedRateIds.dentalRatingId : '' : '',
        visionRatingId: isEnrollmentCase ? state.overall.proposedRateIds && state.overall.proposedRateIds.visionRatingId ? state.overall.proposedRateIds.visionRatingId : '' : '',
        lifeRatingId: isEnrollmentCase ?
            (classingOpted && state.overall.proposedRateIds && state.overall.proposedRateIds.lifeRatingId && state.overall.proposedRateIds.lifeRatingId[empClass] ? state.overall.proposedRateIds.lifeRatingId[empClass] : state.overall.proposedRateIds && state.overall.proposedRateIds.lifeRatingId ? state.overall.proposedRateIds.lifeRatingId : '')
            : '',
        stdRatingId: isEnrollmentCase ? state.overall.proposedRateIds && state.overall.proposedRateIds.stdRatingId ? state.overall.proposedRateIds.stdRatingId : '' : '',
        ltdRatingId: isEnrollmentCase ? state.overall.proposedRateIds && state.overall.proposedRateIds.ltdRatingId ? state.overall.proposedRateIds.ltdRatingId : '' : '',
        suppLifeRatingId: isEnrollmentCase ? state.overall.proposedRateIds && state.overall.proposedRateIds.suppLifeRatingId ? state.overall.proposedRateIds.suppLifeRatingId : '' : '',
    }
}

/**
 *
 * @param {array} planCodes
 * @param {string} platform
 * @param {string | number} groupSize
 * @param {string} zipCode
 * @param {string} effectiveDate
 * @param {string} stateCode
 * @param {string} countyCode
 * @param {boolean} includeSpecialty
 * @param {array} employees
 * @param {object} location
 * @param {object} companyProfile
 * @param {object} simpleCensus
 * @param {Boolean} finalize
 * @param {string} destinationPage one of 'plans', 'quote-review'
 * @param {array} preselectedPlans array of minimal plan objects
 *                -- each must have 'code' and 'platformRatingID'
 */
    export function getCensusRates(
    planCodes,
    dentalPlanCodes,
    visionPlanCodes,
    basicLifePlanCodes,
    stdPlanCodes,
    ltdPlanCodes,
    suppLifePlanCodes,
    platform,
    groupSize,
    zipCode,
    effectiveDate,
    stateCode,
    countyCode,
    includeSpecialty,
    employees,
    location,
    companyProfile,
    simpleCensus,
    quoteStatus,
    destinationPage = 'plans',
    preselectedPlans = [],
    platformRatingIDs = {},
    isEnrollment = false,
    plansOptionalRiders,
    sourcePage = ''
) {
    return (dispatch, getState) => {
        const staticRequestInfo = {
            groupSize,
            platform,
            zipCode,
            effectiveDate,
            stateCode,
            countyCode,
            includeSpecialty,
            location,
            companyProfile,
            platformRatingIDs,
        };

        if (!isEmpty(simpleCensus) && !isEnrollment) {
            // simpleCensus object exists, but counts are empty because regular census was used
            if (isEmpty(simpleCensus.ee) && isEmpty(simpleCensus.es) && isEmpty(simpleCensus.ec) && isEmpty(simpleCensus.ef) && isEmpty(simpleCensus.nc)) {
                staticRequestInfo.employees = employees;
            } else {
                staticRequestInfo.simpleCensus = simpleCensus;
            }
        } else {
            staticRequestInfo.employees = employees;
        }

        if (destinationPage === 'afterCloseEnrollment') {
            dispatch(updateEmployeeEnrollmentCompletion(false));
        }

        if (planCodes && planCodes.length > 0) { // CIRRUS
            let planCodeBatches = [planCodes];
            let batchOISABit = [];
            let selectedOptionalRiders = {};
            // Prime can only take in 10 plans to rate at a time; cirrus takes all at once
            if (platform === 'PRIME' || platform === 'NHP' || platform === "DUAL") {
                planCodeBatches = getPlanCodeBatches(getState(), planCodes, batchOISABit);
                selectedOptionalRiders = quoteStatus !== 'Shopping' ? getSelectedOptionalRiders(getState(), planCodes) : {};
            }

            if (isEnrollment && platform === 'CIRRUS') {
                const updatedEmployees = validEmpCensus(employees, 'medical', effectiveDate, platform);
                staticRequestInfo.employees = updatedEmployees;
            }

            dispatch(
                requestRateBatch(
                    staticRequestInfo,
                    planCodeBatches[0],
                    0,
                    quoteStatus,
                    destinationPage,
                    preselectedPlans,
                    batchOISABit[0],
                    selectedOptionalRiders,
                    planCodes.length,
                    sourcePage
                )
            ).then(() => {
                planCodeBatches
                    .slice(1, planCodeBatches.length)
                    .forEach((planCodeBatch, i) => {
                        dispatch(
                            requestRateBatch(
                                staticRequestInfo,
                                planCodeBatch,
                                i + 1,
                                quoteStatus,
                                destinationPage,
                                preselectedPlans,
                                batchOISABit[i + 1],
                                selectedOptionalRiders,
                                '',
                                sourcePage
                            ),
                        );
                    });
            }).then(() => {
                // If Platform is PRIME ,and we have not called from move to enrollment, proposal generation, finalize enrollment,
                // then we have to bring benefit rider rates of all plans in batches
                if (platform === 'PRIME' && quoteStatus === 'Shopping') {
                    dispatch(getAllRidersRatesFS(planCodeBatches[0], 0, batchOISABit[0], simpleCensus))
                        .then(() => {
                            planCodeBatches
                                .slice(1, planCodeBatches.length)
                                .forEach((planCodeBatch, i) => {
                                    dispatch(getAllRidersRatesFS(planCodeBatch, i + 1, batchOISABit[i + 1], simpleCensus));
                                });
                        })
                }
            })
            .catch((err) => {
                console.log(err);
                if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                    dispatch(displayUnsupportedOxfordZipCode(true));
                    dispatch(displayUnsupportedOxfordZipCodePopupShow(true));
                } else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                    dispatch({
                        type: types.DISPLAY_ERROR_MESSAGE
                    });
                }

                logError(KEY_NAME, UHC_OXFORD_MEDICAL_RATE_ERROR, 'getCensusRates', { type: 'Medical', planCodes: planCodes, staticRequestInfo: staticRequestInfo }, err);
            });
        }

        //This block is the logic for specialty rates
        if ((dentalPlanCodes && dentalPlanCodes.length > 0) || (visionPlanCodes && visionPlanCodes.length > 0)
            || (basicLifePlanCodes && basicLifePlanCodes.length > 0) || (stdPlanCodes && stdPlanCodes.length > 0) || (ltdPlanCodes && ltdPlanCodes.length > 0) ||(suppLifePlanCodes && suppLifePlanCodes.length > 0)) {
            // Variable used to handle classing and non classing case separately in life.
            const classingOpted = getState().life.employeeClassing.classingOpted;

            if (isEnrollment) {
                const updatedDentalEmployees = validEmpCensus(employees, 'dental', effectiveDate, platform);
                const updatedVisionEmployees = validEmpCensus(employees, 'vision', effectiveDate, platform);
                const updatedLifeEmployees = validEmpCensus(employees, 'life', effectiveDate, platform);
                const updatedStdEmployees = validEmpCensus(employees, 'std', effectiveDate, platform);
                const updatedLtdEmployees = validEmpCensus(employees, 'ltd', effectiveDate, platform);
                const updatedSuppLifeEmployees = validEmpCensus(employees, 'suppLife', effectiveDate, platform);

                // DENTAL
                const dentalStaticRequest = deepCopy(staticRequestInfo);
                dentalStaticRequest.employees = updatedDentalEmployees;

                if (dentalPlanCodes && dentalPlanCodes.length > 0) {
                    dispatch(
                        requestSpecialtyRateBatch(
                            dentalStaticRequest,
                            dentalPlanCodes,
                            [],
                            [],
                            [],
                            [],
                            [],
                            0,
                            quoteStatus,
                            destinationPage,
                        ),
                    )
                        .catch((err) => {
                            console.log(err);
                            dispatch({
                                type: types.DISPLAY_ERROR_MESSAGE,
                            });
                            logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Dental', planCodes: dentalPlanCodes, staticRequestInfo: dentalStaticRequest }, err);
                        });
                }

                // VISION
                const visionStaticRequest = deepCopy(staticRequestInfo);
                visionStaticRequest.employees = updatedVisionEmployees;
                if (visionPlanCodes && visionPlanCodes.length > 0) {
                    dispatch(
                        requestSpecialtyRateBatch(
                            visionStaticRequest,
                            [],
                            visionPlanCodes,
                            [],
                            [],
                            [],
                            [],
                            0,
                            quoteStatus,
                            destinationPage,
                        ),
                    )
                        .catch((err) => {
                            console.log(err);
                            dispatch({
                                type: types.DISPLAY_ERROR_MESSAGE,
                            });
                            logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Vision', planCodes: visionPlanCodes, staticRequestInfo: visionStaticRequest }, err);
                        });
                }

                // STD
                const stdStaticRequest = deepCopy(staticRequestInfo);
                stdStaticRequest.employees = updatedStdEmployees;
                if (stdPlanCodes && stdPlanCodes.length > 0) {
                    if (!classingOpted) {
                        dispatch(
                            requestSpecialtyRateBatch(
                                stdStaticRequest,
                                [],
                                [],
                                [],
                                stdPlanCodes,
                                [],
                                [],
                                0,
                                quoteStatus,
                                destinationPage,
                            ),
                        )
                            .catch((err) => {
                                console.log(err);
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE,
                                });
                                logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Std', planCodes: stdPlanCodes, staticRequestInfo: stdStaticRequest }, err);
                            });
                    } else {
                        dispatch(
                            getLifeRatesInClasses(
                                stdStaticRequest,
                                [],
                                stdPlanCodes,
                                [],
                                quoteStatus,
                                destinationPage
                            )
                        )
                    }
                }

                // LTD
                const ltdStaticRequest = deepCopy(staticRequestInfo);
                ltdStaticRequest.employees = updatedLtdEmployees;
                if (ltdPlanCodes && ltdPlanCodes.length > 0) {
                    if (!classingOpted) {
                        dispatch(
                            requestSpecialtyRateBatch(
                                ltdStaticRequest,
                                [],
                                [],
                                [],
                                [],
                                ltdPlanCodes,
                                [],
                                0,
                                quoteStatus,
                                destinationPage,
                            ),
                        )
                            .catch((err) => {
                                console.log(err);
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE,
                                });
                                logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Ltd', planCodes: ltdPlanCodes, staticRequestInfo: ltdStaticRequest }, err);
                            });
                    } else {
                        dispatch(
                            getLifeRatesInClasses(
                                ltdStaticRequest,
                                [],
                                [],
                                ltdPlanCodes,
                                quoteStatus,
                                destinationPage
                            )
                        )
                    }
                }

                const lifeStaticRequest = deepCopy(staticRequestInfo);
                lifeStaticRequest.employees = updatedLifeEmployees;

                if (basicLifePlanCodes && basicLifePlanCodes.length > 0) {
                    if (!classingOpted) {
                        dispatch(
                            requestSpecialtyRateBatch(
                                lifeStaticRequest,
                                [],
                                [],
                                basicLifePlanCodes,
                                [],
                                [],
                                [],
                                0,
                                quoteStatus,
                                destinationPage,
                            ),
                        ).catch((err) => {
                            console.log(err);
                            dispatch({
                                type: types.DISPLAY_ERROR_MESSAGE,
                            });
                            logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Life', planCodes: basicLifePlanCodes, staticRequestInfo: lifeStaticRequest }, err);
                        });

                    } else {
                        dispatch(
                            getLifeRatesInClasses(
                                lifeStaticRequest,
                                basicLifePlanCodes,
                                [],
                                [],
                                quoteStatus,
                                destinationPage
                            )
                        )
                    }

                }

                // SUPPLIFE
                const suppLifeStaticRequest = deepCopy(staticRequestInfo);
                suppLifeStaticRequest.employees = updatedSuppLifeEmployees;
                if (suppLifePlanCodes && suppLifePlanCodes.length > 0) {
                    dispatch(
                        requestSpecialtyRateBatch(
                            suppLifeStaticRequest,
                            [],
                            [],
                            [],
                            [],
                            [],
                            suppLifePlanCodes,
                            0,
                            quoteStatus,
                            destinationPage,
                        ),
                    )
                        .catch((err) => {
                            console.log(err);
                            dispatch({
                                type: types.DISPLAY_ERROR_MESSAGE,
                            });
                            logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'suppLife', planCodes: suppLifePlanCodes, staticRequestInfo: suppLifeStaticRequest }, err);
                        });
                }
            } else {
                // Non Enrollment Case ( Quoting/ Proposal Generation)
                const { overall: { quickQuoteRequest } } = getState();
                // If its a washington case then if rates are brought at the plan selection page and the medical with specialty is selected
                if (quoteStatus === 'Shopping' && isWasinghtonTwoTierCase(quickQuoteRequest)) {
                    if (classingOpted) {
                        if (dentalPlanCodes.length > 0 || visionPlanCodes.length > 0) {
                            // First dispatch call with 2 Tier Rates for Dental and Vision. For life there is no change
                            dispatch(requestSpecialtyRateBatch(staticRequestInfo, dentalPlanCodes, visionPlanCodes, [], [], [],[], 0, quoteStatus, destinationPage, sourcePage, '2 Tier'))
                                .catch(err => {
                                    console.log(err);
                                })

                            // Then Dispatch another call for 4 tier rates for dental and vision. Not sending life this time as life rates are brought in first call itself.
                            dispatch(requestSpecialtyRateBatch(staticRequestInfo, dentalPlanCodes, visionPlanCodes, [], [], [],[], 0, quoteStatus, destinationPage, sourcePage, '4 Tier'))
                                .catch(err => {
                                    console.log(err);
                                })
                        }
                        
                        dispatch(getLifeRatesInClasses(staticRequestInfo, basicLifePlanCodes,[],[], quoteStatus, destinationPage))
                        //TODO: Will have to add classing call for STD and LTD as well
                    } else {
                        // First dispatch call with 2 Tier Rates for Dental and Vision. For life there is no change
                        dispatch(requestSpecialtyRateBatch(staticRequestInfo, dentalPlanCodes, visionPlanCodes, basicLifePlanCodes, stdPlanCodes, [],[], 0, quoteStatus, destinationPage, sourcePage, '2 Tier'))
                            .catch(err => {
                                console.log(err);
                            })

                        // Then Dispatch another call for 4 tier rates for dental and vision. Not sending life this time as life rates are brought in first call itself.
                        dispatch(requestSpecialtyRateBatch(staticRequestInfo, dentalPlanCodes, visionPlanCodes, [], stdPlanCodes, ltdPlanCodes,[], 0, quoteStatus, destinationPage, sourcePage, '4 Tier'))
                            .catch(err => {
                                console.log(err);
                            })
                    }
                } else {
                    if (classingOpted) {
                        if (dentalPlanCodes.length > 0 || visionPlanCodes.length > 0 || suppLifePlanCodes.length > 0) {
                            // First dispatch call for Dental, Vision, STD
                            dispatch(requestSpecialtyRateBatch(staticRequestInfo, dentalPlanCodes, visionPlanCodes, [], [], [],suppLifePlanCodes, 0, quoteStatus, destinationPage, sourcePage)
                            ).catch(err => {
                                console.log(err);
                                if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                                    dispatch(displayUnsupportedOxfordZipCode(true));
                                    dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                                }
                                else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                                    dispatch({
                                        type: types.DISPLAY_ERROR_MESSAGE
                                    });
                                }
                                else if (sourcePage !== 'employeeCensus') {
                                    dispatch({
                                        type: types.DISPLAY_ERROR_MESSAGE
                                    });
                                }
                                logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Dental/Vision/STD', planCodes: { dentalPlanCodes: dentalPlanCodes, visionPlanCodes: visionPlanCodes, stdPlanCodes: stdPlanCodes }, staticRequestInfo: staticRequestInfo }, err);
                            })
                        }

                        // Second dispatch: for life<std,ltd classing plans
                        dispatch(getLifeRatesInClasses(staticRequestInfo, basicLifePlanCodes, stdPlanCodes, ltdPlanCodes, quoteStatus, destinationPage,))
                    } else {         // Normal dispatch for all products
                        dispatch(
                            requestSpecialtyRateBatch(
                                staticRequestInfo,
                                dentalPlanCodes,
                                visionPlanCodes,
                                basicLifePlanCodes,
                                stdPlanCodes,
                                ltdPlanCodes,
                                suppLifePlanCodes,
                                0,
                                quoteStatus,
                                destinationPage,
                                sourcePage
                            ),
                        ).catch((err) => {
                            console.log(err);
                            if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                                dispatch(displayUnsupportedOxfordZipCode(true));
                                dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                            }
                            else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE
                                });
                            }
                            else if (sourcePage !== 'employeeCensus') {
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE
                                });
                            }
                            logError(KEY_NAME, UHC_OXFORD_SPECIALTY_RATE_ERROR, 'getCensusRates', { type: 'Dental/Vision/Life/STD', planCodes: { dentalPlanCodes: dentalPlanCodes, visionPlanCodes: visionPlanCodes, basicLifePlanCodes: basicLifePlanCodes, stdPlanCodes: stdPlanCodes }, staticRequestInfo: staticRequestInfo }, err);
                        });
                    }
                }
            }
        };
    }
}

function getOISABit(state, planCodes) {
    let OISABit = [];
    const medicalPlans = state.quotes.selectedPlans && state.quotes.selectedPlans.length > 0 ? [...state.quotes.plans, ...state.quotes.selectedPlans] : [...state.quotes.plans];
    planCodes.forEach(code => {
        let oisabit = medicalPlans.filter(plan => plan.code === code)[0].OISABit;
        OISABit.push(`${oisabit.OI}_${oisabit.SA}_${oisabit.BIT}`);
    });
    return OISABit;
}

function groupPlanCodes(state, planCodes) {
    let planCodesArr = planCodes
    if (typeof planCodes[0] === "object" && state?.overall?.quickQuoteRequest?.zipObj?.stateCode === "CA") {
        planCodesArr = planCodes.map(plan => plan.code)
    }

    const OISABit = getOISABit(state, planCodesArr);
    let groupedCodes = {};
    for (let i = 0; i < OISABit.length; i++) {
        let key = OISABit[i];
        if (!groupedCodes[key])
            groupedCodes[key] = [];
        groupedCodes[key].push(planCodes[i]);
    }

    return groupedCodes;
}

function getOISABitDetails(state, filteredMedicalPlans) {
    let OISABit = [];
    const medicalPlans = state.quotes.plans;
    filteredMedicalPlans.forEach(data => {
        let oisabit = medicalPlans.filter(plan => plan.code === data.code)[0].OISABit;
        OISABit.push(`${oisabit.OI}_${oisabit.SA}_${oisabit.BIT}`);
    });
    return OISABit;
}
function groupPlanCodesDetails(state, filteredMedicalPlans) {
    const OISABit = getOISABitDetails(state, filteredMedicalPlans);
    let groupedCodes = {};
    for (let i = 0; i < OISABit.length; i++) {
        let key = OISABit[i];
        if (!groupedCodes[key])
            groupedCodes[key] = [];
        groupedCodes[key].push(filteredMedicalPlans[i]);
    }
    return groupedCodes;
}

export function getPlanCodeBatchesDetails(state, filteredMedicalPlans, batchOISABit) {
    const groupedCodes = groupPlanCodesDetails(state, filteredMedicalPlans);
    let planCodeBatches = [];
    Object.keys(groupedCodes).forEach(key => {
        let medicalPlanCodes = groupedCodes[key];
        let OI_SA_BIT = {
            OI: key.split("_")[0],
            SA: key.split("_")[1],
            BIT: key.split("_")[2],
        }
        for (let i = 0; i < medicalPlanCodes.length; i += 10) {
            planCodeBatches.push(medicalPlanCodes.slice(i, i + 10));
            batchOISABit.push(OI_SA_BIT);
        }
    })
    return planCodeBatches;
}

export function getPlanCodeBatches(state, planCodes, batchOISABit) {
    const groupedCodes = groupPlanCodes(state, planCodes);
    let planCodeBatches = [];
    Object.keys(groupedCodes).forEach(key => {
        let medicalPlanCodes = groupedCodes[key];
        let OI_SA_BIT = {
            OI: key.split("_")[0],
            SA: key.split("_")[1],
            BIT: key.split("_")[2],
        }

        for (let i = 0; i < medicalPlanCodes.length; i += 10) {
            planCodeBatches.push(medicalPlanCodes.slice(i, i + 10));
            batchOISABit.push(OI_SA_BIT);
        }
    })

    return planCodeBatches;
}

function getSelectedOptionalRiders(state, planCodes) {
    const selectedBenRiders = {};
    planCodes.forEach(code => {
        code = code && typeof code === 'object' ? code.code : code;
        const plan = state.quotes.selectedPlans.filter(plan => plan.code === code)[0];
        if (plan && plan.selectedBenefitRiders && plan.selectedBenefitRiders.length > 0) {
            selectedBenRiders[code] = plan.selectedBenefitRiders;
        }
    })
    return selectedBenRiders;
}

/**
 * Fetch plans based on Medical and Rx Plan Codes
 *
 * @param {*} medicalPlanCode
 * @param {*} rxPlanCode
 */
export function getMedicalPlansByMedicalAndRxCode(medicalPlanCode, rxPlanCode) {
    return (dispatch, getState) => {
        // Prime requires a boolean - undefined will mess it up
        const fullProps = getState();
        let sourcePage = ''
        // Do not execute search if Medical & Rx Plan plan exists in plans array
        if (fullProps.quotes.plans.some(plan => plan.code === medicalPlanCode && plan.rxPlanCode === rxPlanCode)) { return }

        const { groupSize, zipObj, effectiveDate, otherDetails } = fullProps.overall.quickQuoteRequest;
        const { companyProfile } = fullProps;

        const medicalAndRxCodeSearchRequest = {
            groupSize,
            zipObj,
            effectiveDate,
            otherDetails
        };

        // Maintain previous state in case there is an error
        const prevState = fullProps.quotes;
        const caseProgress = getNBCaseProgress(fullProps);
        const isCaseSubmitted = inSubmittedStatus(caseProgress);
        const { caseTracking } = fullProps;
        let isDesQuote = false
        const desQuote = caseTracking.caseTrack.find((x) => x.source === 'DES' && x.quoteTrackingNumber.startsWith('B2B-NB'));
        if (!isUndefinedOrNull(desQuote)) {
            isDesQuote = true
        }
        const shouldRetainFilters = isDesQuote && sourcePage === 'quoteReview'
        dispatch({
            type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS,
            payload: medicalAndRxCodeSearchRequest,
            source: 'full',
            isCaseSubmitted,
            shouldRetainFilters
        });

        const { employees, NY_SHOP, primaryLocationIdx } = companyProfile;

        const plansRequestBody = {
            groupSize,
            zipCode: zipObj.zipCode,
            effectiveDate,
            stateCode: zipObj.stateCode,
            exchange: (NY_SHOP || checkEmployeesForOOA(employees)) || false,
            OISABit: fullProps.overall.OISABit ? fullProps.overall.OISABit : [],
            platform: fullProps.overall.platform,
            ratingCount: getRatingCount(fullProps.companyProfile.locations, fullProps.companyProfile.primaryLocationIdx),
            medicalPlanCode,
            rxPlanCode,
            validateQuoteData: fullProps.overall.validateQuoteData || {}
        };

        if (!isEmpty(zipObj.countyFIPSCode)) {
            plansRequestBody.countyCode = zipObj.countyFIPSCode;
        }

        if (!isEmpty(companyProfile.sicCode)) {
            plansRequestBody.sicCode = companyProfile.sicCode;
        }

        if (!isEmpty(companyProfile.franchiseCode)) {
            plansRequestBody.franchiseCode = companyProfile.franchiseCode;
        }

        const preselectedPlans = fullProps.quotes.selectedPlans;

        return new Promise((resolve, reject) => {
            axios.post(`${apiRoutes.quickQuotes}/plans/startSearch`, plansRequestBody)
                .then(async (res) => {
                    let planSearch = await getTransactionResult(res.data.transactionId);
                    if (planSearch.statusCode === 400) {
                        throw await planSearch;
                    }

                    if(zipObj?.stateCode==="CA" && effectiveDate ){
                        let coverageDate = new Date(effectiveDate).getUTCDate();
                        if(coverageDate === 15){
                            planSearch.medicalPlans = planSearch.medicalPlans.filter(pln => pln.platform !== 'NICE');
                        }
                    }

                    const updatedMedicalPlans = planSearch.medicalPlans.map(plan => {
                        const updatedPlan = {
                            ...plan,
                            isAdvanceSearchPlan: true
                        }
                        return updatedPlan;
                    })

                    // Add preselected plans to medial plans list
                    planSearch = {
                        ...planSearch,
                        // Unique medical plans only
                        medicalPlans: updatedMedicalPlans
                    }

                    await dispatch({
                        type: types.SERVICE.FI.RECEIVE_QUICK_QUOTE_PLANS,
                        payload: planSearch,
                        quickQuoteRequest: fullProps.overall.quickQuoteRequest,
                        source: 'full',
                        preselectedPlanCodes: preselectedPlans.map((plan) => plan.code),
                        groupSize: getPrimaryLocation(companyProfile.locations).totalEligEmps,
                        medicalPlanCode
                    });

                    dispatch(createHistory('Medical and Rx Plan Code Search Button Clicked', 'Shopping page (Plan Selection)', 'Success', plansRequestBody, planSearch, 'NB'));

                    // Update filter criteria
                    let plan = planSearch.medicalPlans.filter(plan => plan.code === medicalPlanCode)[0];

                    let packageCode;
                    if (!fullProps.quotes.isCirrus) {
                        let currentPackage = fullProps.quotes.filterCriteria.selectedPackageCode;
                        if (plan.packages.indexOf(currentPackage) === -1) {
                            if (plan.packages.length > 1) {
                                this.setState({ planCodeSearchModalOpen: true, desiredSearchedPlan: plan });
                                return;
                            }
                            else if (plan.packages.length === 1) {
                                packageCode = plan.packages[0];
                            }
                            else { // no packages -- standalone plan
                                packageCode = null;
                            }
                        }
                        else {
                            packageCode = currentPackage;
                        }
                    }

                    dispatch(changeFilterCriteria("planCode", null, medicalPlanCode, packageCode));

                    const optionalRiders = plan?.optionalRider?.length ? {
                        [plan.code]: plan.optionalRider.map((rider) => rider.riderCode)
                    } : {};

                    // Pass only Medical Code for rating
                    dispatch(getCensusRates(
                        [medicalPlanCode],
                        [],
                        [],
                        [],
                        [],
                        [],
                        [],
                        fullProps.overall.platform,
                        getPrimaryLocation(companyProfile.locations).totalEligEmps,
                        zipObj.zipCode,
                        effectiveDate,
                        zipObj.stateCode,
                        zipObj.countyCode,
                        false,
                        employees[primaryLocationIdx],
                        companyProfile.locations[primaryLocationIdx].zipData,
                        companyProfile,
                        companyProfile.simpleCensus,
                        'Shopping',
                        'plans',
                        [],
                        {},
                        false,
                        optionalRiders
                    ));

                    resolve();
                })
                .catch((err) => {
                    // Display UI Error when no plans are found
                    dispatch({
                        type: types.SERVICE.FI.DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE,
                        payload: err.error,
                        prevState,
                    });
                    dispatch(createHistory('Medical and Rx Plan Code Search Button Clicked', 'Shopping page (Plan Selection)', 'Error', plansRequestBody, err.error, 'NB'));

                    resolve();
                });
        });
    };
}

/**
 *
 * @param {string | number} groupSize
 * @param {object} zipObj
 * @param {string} effectiveDate
 * @param {boolean} includeSpecialty
 * @param {array} employees
 * @param {object} location
 * @param {object} companyProfile
 * @param {object} simpleCensus
 * @param {object} otherDetails
 * @param {string} destinationPage one of 'plans', 'quote-review'
 * @param {array} preselectedPlans array of minimal plan objects
 *                -- each must have 'code' and 'platformRatingID'
 */
export function getCensusPlans(
    groupSize,
    zipObj,
    effectiveDate,
    includeSpecialty,
    employees,
    location,
    companyProfile,
    simpleCensus,
    otherDetails,
    destinationPage = 'plans',
    preselectedPlans = [],
    sourcePage = ''
) {
    checkZipObj(zipObj);
    return (dispatch, getState) => {
        // Prime requires a boolean - undefined will mess it up
        const fullProps = getState();
        const prevSelectedMedicalPlans = fullProps.quotes.selectedPlans;
        const prevSelectedDentalPlans = fullProps.dental.selectedPlans;
        const prevSelectedVisionPlans = fullProps.vision.selectedPlans;
        const prevSelectedLifePlans = fullProps.life.selectedPlans;
        const {
            overall,
            overall: {
                previousPage,
                appConfigDetails : {
                    showAgeBand
                }
            },
            companyProfile: { selectedProducts },
            specialtyCriteria:{salaryRatingMethod}
        } = fullProps;
        const { stateCode } = fullProps.companyProfile.locations[0].zipData;
        const incSpecialtyChecked = includeSpecialty === undefined ? false : includeSpecialty;
        const quoteStatus = 'Shopping';
        const salaryRequired = salaryRatingMethod.toLowerCase() === "optional" ? false : true
        const quickQuoteRequest = {
            groupSize,
            zipObj,
            effectiveDate,
            otherDetails,
        };
        const { platform } = otherDetails;
        dispatch(getEnrollmentRules(platform, zipObj, effectiveDate, groupSize));
        dispatch(getRateAdjustmentRules(zipObj.stateCode, groupSize, effectiveDate));

        const products = Object.keys(selectedProducts);

        if (destinationPage === 'afterCloseEnrollment') {
            dispatch(updateEmployeeEnrollmentCompletion(false));
        }

        products.forEach(product => {
            if (!selectedProducts[product]) {
                if (product === 'medical') {
                    dispatch({ type: types.SERVICE.FI.CLEAR_SELECTED_PLANS });
                    dispatch({ type: types.SERVICE.FI.CLEAR_ENROLLED_PLANS });
                }
                if (product === 'dental' || product === 'vision' || product === 'life' || product === 'std' || product === 'ltd') {
                    dispatch({ type: types.SERVICE[product.toUpperCase()].CLEAR_SELECTED_PLANS })
                    dispatch({ type: types.SERVICE[product.toUpperCase()].CLEAR_ENROLLED_PLANS })
                }
            }
        })

        if (fullProps.specialtyCriteria.viewSpecialty.medical) {
            const caseProgress = getNBCaseProgress(fullProps);
            const isCaseSubmitted = inSubmittedStatus(caseProgress);
            const { caseTracking } = fullProps;
            let isDesQuote = false
            const desQuote = caseTracking.caseTrack.find((x) => x.source === 'DES' && x.quoteTrackingNumber.startsWith('B2B-NB'));
            if (!isUndefinedOrNull(desQuote)) {
                isDesQuote = true
            }
            const shouldRetainFilters = isDesQuote && sourcePage === 'quoteReview'
            dispatch({
                type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS,
                payload: quickQuoteRequest,
                source: 'full',
                isCaseSubmitted,
                shouldRetainFilters
            });

            const { employees: companyEmployees, NY_SHOP } = companyProfile;

            const plansRequestBody = {
                groupSize,
                zipCode: zipObj.zipCode,
                effectiveDate,
                stateCode: zipObj.stateCode,
                exchange: NY_SHOP || false,
                OISABit: fullProps.overall.OISABit ? fullProps.overall.OISABit : [],
                ooa: checkEmployeesForOOA(companyEmployees) || false,
                platform: platform,
                ratingCount: getRatingCount(fullProps.companyProfile.locations, fullProps.companyProfile.primaryLocationIdx),
                validateQuoteData: fullProps.overall.validateQuoteData || {}
            };

            if (!isEmpty(zipObj.countyFIPSCode)) {
                plansRequestBody.countyCode = zipObj.countyFIPSCode;
            }

            if (!isEmpty(companyProfile.sicCode)) {
                plansRequestBody.sicCode = companyProfile.sicCode;
            }

            if (!isEmpty(companyProfile.franchiseCode)) {
                plansRequestBody.franchiseCode = companyProfile.franchiseCode;
            }

            if (platform === 'PRIME') {
                plansRequestBody.isDirectSale = fullProps.reviewCards.directSale;
                plansRequestBody.pcisId = fullProps.reviewCards.ownerAssignment.producer.length > 0 ? fullProps.reviewCards.ownerAssignment.producer[0].PCIS_ID.toString() : '';
                plansRequestBody.isInternal = checkIsInternalUser(fullProps.renewals.userRolesPermissions);
            }

            axios.post(`${apiRoutes.quickQuotes}/plans/startSearch`, plansRequestBody)
                .then(async (res) => {
                    let planSearch = await getTransactionResult(res.data.transactionId);

                    if (planSearch.statusCode === 400) {
                        throw await planSearch;
                    }
                    if(zipObj?.stateCode==="CA" && effectiveDate ){
                        let coverageDate = new Date(effectiveDate).getUTCDate();
                        if(coverageDate === 15){
                            planSearch.medicalPlans = planSearch.medicalPlans.filter(pln => pln.platform !== 'NICE');
                        }
                    }
                    const advanceSearchPlans = preselectedPlans.filter(plan => plan.isAdvanceSearchPlan === true);
                    const allMedicalPlans = planSearch.medicalPlans.concat(advanceSearchPlans);
                    const medicalPlanSet = getUniqueMedicalPlans(allMedicalPlans);

                    // Add preselected plans to medial plans list
                    planSearch = {
                        ...planSearch,
                        // Unique medical plans only
                        medicalPlans: medicalPlanSet
                    }

                    await dispatch({
                        type: types.SERVICE.FI.RECEIVE_QUICK_QUOTE_PLANS,
                        payload: planSearch,
                        quickQuoteRequest,
                        source: 'full',
                        preselectedPlanCodes: preselectedPlans.map((plan) => plan.code),
                        groupSize: getPrimaryLocation(companyProfile.locations).totalEligEmps,
                    });
                    dispatch(createHistory(`Get Medical Plans`, 'Full Quotes Action file', 'Success', plansRequestBody, '', 'NB'));
                    let { platform } = await otherDetails;
                    if (planSearch.medicalPlans[0]) {
                        platform = await planSearch.medicalPlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
                    } else {
                        platform = 'PRIME';
                    }


                    await dispatch(
                        getCensusRates(
                            (zipObj?.stateCode==="CA" ? (planSearch.medicalPlans.map((plan) => {return {code : plan.code,platform :plan.platform}}))         //for NB when moving census to plan selection
                            :(planSearch.medicalPlans.map((plan) => plan.code))), [], [], [], [],[],[],
                            platform,
                            groupSize,
                            zipObj.zipCode,
                            effectiveDate,
                            zipObj.stateCode,
                            zipObj.countyFIPSCode,
                            incSpecialtyChecked,
                            employees,
                            location,
                            companyProfile,
                            simpleCensus,
                            quoteStatus,
                            destinationPage,
                            preselectedPlans,
                            {},
                            false,
                            {},
                            sourcePage
                        ),
                    );
                    // for Data from Data Externaxlization this should not happen
                    if (!(fullProps.companyProfile.B2BPartner !== undefined && fullProps.companyProfile.B2BPartner === 'Y')) {
                        if (!fullProps.caseTracking.isContinuedFromQQCase) {
                            if (destinationPage !== 'afterCloseEnrollment' && previousPage === 'employee') {
                                for (let i = 0; i < prevSelectedMedicalPlans.length; i++) {
                                    //If coming from NB Dashboard Quote Review or FS plan selection
                                    if ((fullProps.quotes.plans.find(plan => plan.codeDisplay === prevSelectedMedicalPlans[i].codeDisplay).selected === true && overall.previousPage !== '/employee') || (!fullProps.quotes.plans.find(plan => plan.codeDisplay === prevSelectedMedicalPlans[i].codeDisplay).selected && overall.previousPage === '/employee')) {
                                        await dispatch({
                                            type: types.SERVICE.FI.UPDATE_PLAN,
                                            plan: prevSelectedMedicalPlans[i],
                                            field: 'selected',
                                            pkg: 'Single',
                                        });
                                    }
                                }
                            }
                        }
                    }

                })
                .catch((err) => {
                    if (sourcePage === 'employeeCensus' && !fullProps.overall.unsupportedOxfordZipPopupShow && !fullProps.overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                        dispatch(displayUnsupportedOxfordZipCode(true));
                        dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                    }
                    else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                        if(!isUhcRenewals() && err && err.error && err.error.length > 0){
                            dispatch({
                                type: types.DISPLAY_DYNAMIC_ERROR_MESSAGE,
                                payload: err.error,
                            });
                        }
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                    }
                    if (sourcePage !== 'employeeCensus' && stateCode !== 'NY')
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE,
                        });
                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', false)
                    });
                    logError(KEY_NAME, UHC_OXFORD_MEDICAL_PLAN_ERROR, 'getCensusPlans', plansRequestBody, err);
                });
        }

        // Calling specialty plan API if specialty plans are selected
        if (incSpecialtyChecked) {
            // Reseting previous data here
            if (!(fullProps.companyProfile.B2BPartner != undefined && fullProps.companyProfile.B2BPartner === 'Y')) {
                dispatch({
                    type: types.SERVICE.DENTAL.RESET_DATA,
                });
                dispatch({
                    type: types.SERVICE.VISION.RESET_DATA,
                });
            }

            dispatch({
                type: types.SERVICE.LIFE.RESET_DATA,
            });

            dispatch({
                type: types.SERVICE.STD.RESET_DATA,
            });

            dispatch({
                type: types.SERVICE.LTD.RESET_DATA,
            });

            if (!(fullProps.companyProfile.B2BPartner != undefined && fullProps.companyProfile.B2BPartner === 'Y')) {
                if (otherDetails.selectedProducts.includes('dental')) {
                    dispatch({
                        type: types.SERVICE.DENTAL.REQUEST_QUICK_QUOTE_PLANS,
                        payload: quickQuoteRequest,
                        source: 'full',
                    });
                }

                if (otherDetails.selectedProducts.includes('vision')) {
                    dispatch({
                        type: types.SERVICE.VISION.REQUEST_QUICK_QUOTE_PLANS,
                        payload: quickQuoteRequest,
                        source: 'full',
                    });
                }
            }
            if (otherDetails.selectedProducts.includes('life')) {
                dispatch({
                    type: types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'full',
                });
                dispatch({
                    type: types.SERVICE.SUPPLIFE.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'full',
                });
            }

            if (otherDetails.selectedProducts.includes('std')) {
                dispatch({
                    type: types.SERVICE.STD.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'full',
                });
            }
            if (otherDetails.selectedProducts.includes('ltd')) {
                dispatch({
                    type: types.SERVICE.LTD.REQUEST_QUICK_QUOTE_PLANS,
                    payload: quickQuoteRequest,
                    source: 'full',
                });
            }
            axios
                .get(
                    `${apiRoutes.quickQuotes}/specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                    + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== ''
                        ? `&sicCode=${otherDetails.sicCode}`
                        : ''
                    }&preferredPlans=true&specialtyTypes=${JSON.stringify(
                        otherDetails.selectedProducts.includes('life') ? [...otherDetails.selectedProducts, "supplife"] :
                            otherDetails.selectedProducts,
                    )}&platform=${otherDetails.platform}${showAgeBand === undefined ? '' : `&showAgeBand=${showAgeBand}`}${otherDetails.selectedProducts.includes('ltd') ? `&salaryRequired=${salaryRequired}` : ''}`,
                )
                .then(async (res) => {
                    specialtyServices.forEach((service) => {
                        const selectedProds = otherDetails.selectedProducts.includes('life') ? [...otherDetails.selectedProducts, "supplife"] : otherDetails.selectedProducts;
                        if (selectedProds.includes(service.typeName.toLowerCase())) {
                            if (res.data[`${service.apiKeyName}Plans`] != null) {
                                dispatch({
                                    type:
                                        types.SERVICE[service.typeName]
                                            .RECEIVE_QUICK_QUOTE_PLANS,
                                    payload:
                                        res.data[`${service.apiKeyName}Plans`],
                                    quickQuoteRequest,
                                });

                                res.data[`${service.apiKeyName}Plans`] = res.data[`${service.apiKeyName}Plans`].plans;
                            }
                        }
                    });

                    const { platform } = otherDetails;
                    await dispatch(
                        getCensusRates(
                            [],
                            (res.data.dentalPlans || []).map((plan) => plan.code),
                            (res.data.visionPlans || []).map((plan) => plan.code),
                            (res.data.lifePlans || []).map((plan) => plan.code),
                            (platform === 'PRIME' ? (res.data.shortTermDisPlans || []).map((plan) => plan.piaPlanCode) : (res.data.shortTermDisPlans || []).map((plan) => plan.code)),
                            (platform === 'PRIME' ? (res.data.longTermDisPlans || []).map((plan) => plan.piaPlanCode): (res.data.longTermDisPlans || []).map((plan) => plan.code) ),
                            (res.data.suppLifePlans || []).map((plan)=>{return platform === 'PRIME' ? {    
                                slEEPlanCode: plan.piaPlanCode ,
                                slEEADnDPlanCode: plan.PIASLEEADDPlanCode,
                                slSPPlanCode:plan.PIASLSpPlanCode,
                                slSPADnDPlanCode:plan.PIASLSpADDPlanCode,
                                slCHPlanCode:plan.PIASLChPlanCode,
                                slCHADnDPlanCode:plan.PIASLChADDPlanCode
                            } :  {    
                                //US6627183 SGACA to CQS Update for Supp Life
                                
                                slEEPlanCode: plan.code ,
                                slEEADnDPlanCode: plan.SLEEADDPlanCode,
                                slSPPlanCode:plan.SLSpPlanCode,
                                slSPADnDPlanCode:plan.SLSpADDPlanCode,
                                slCHPlanCode:plan.SLChPlanCode,
                                slCHADnDPlanCode:plan.SLChADDPlanCode

                                // slEEPlanCode: plan.piaPlanCode ,
                                // slEEADnDPlanCode: plan.PIASLEEADDPlanCode,
                                // slSPPlanCode:plan.PIASLSpPlanCode,
                                // slSPADnDPlanCode:plan.PIASLSpADDPlanCode,
                                // slCHPlanCode:plan.PIASLChPlanCode,
                                // slCHADnDPlanCode:plan.PIASLChADDPlanCode
                            } }),
                            platform,
                            groupSize,
                            zipObj.zipCode,
                            effectiveDate,
                            zipObj.stateCode,
                            zipObj.countyFIPSCode,
                            incSpecialtyChecked,
                            employees,
                            location,
                            companyProfile,
                            simpleCensus,
                            quoteStatus,
                            destinationPage,
                            preselectedPlans,
                            {},
                            false,
                            {},
                            sourcePage
                        ),
                    );

                    // if (!fullProps.caseTracking.isContinuedFromQQCase) {
                    //     for (let j = 0; j < prevSelectedDentalPlans.length; j++) {
                    //         const dentalRates = prevSelectedDentalPlans[j].finalRates;

                    //         const dentalRate = {
                    //             ee: dentalRates.employee,
                    //             es: dentalRates.empAndSpouse,
                    //             ef: dentalRates.empAndFamily,
                    //             ec: dentalRates.empAndChild,
                    //         };
                    //         await dispatch(adjustRatesAndPremium(prevSelectedDentalPlans[j].planType.toUpperCase(), prevSelectedDentalPlans[j].finalMonthlyPremium, dentalRate, prevSelectedDentalPlans[j].rateAdjustments, prevSelectedDentalPlans[j].code));
                    //     }
                    //     for (let k = 0; k < prevSelectedVisionPlans.length; k++) {
                    //         const visionRates = prevSelectedVisionPlans[k].finalRates;

                    //         const visionRate = {
                    //             ee: visionRates.employee,
                    //             es: visionRates.empAndSpouse,
                    //             ef: visionRates.empAndFamily,
                    //             ec: visionRates.empAndChild,
                    //         };
                    //         if (overall.previousPage !== '/employee') {
                    //         }
                    //         await dispatch(adjustRatesAndPremium(prevSelectedVisionPlans[k].planType.toUpperCase(), prevSelectedVisionPlans[k].finalMonthlyPremium, visionRate, prevSelectedVisionPlans[k].rateAdjustments, prevSelectedVisionPlans[k].code));
                    //     }
                    // }

                    // TODO :: Commented for NOW WA 2 Tier
                    // Pull all specialty plans for advance filtering

                    // If Prefered Plans are not available call all plans API and move to next page
                    // All plan call will be handled by plans.jsx
                    if ((res.data.dentalPlans || []).length + (res.data.visionPlans || []).length + (res.data.lifePlans || []).length + (res.data.shortTermDisPlans || []).length + (res.data.longTermDisPlans || []).length === 0) {
                        history.push('/plans');
                        return;
                    }
                    dispatch(
                        getAllQuickQuotePlans(
                            groupSize,
                            zipObj,
                            effectiveDate,
                            otherDetails,
                        ),
                    );
                })
                .catch((err) => {
                    if (sourcePage === 'employeeCensus' && !fullProps.overall.unsupportedOxfordZipPopupShow && !fullProps.overall.unsupportedOxfordZip && getState().overall.platform === 'CIRRUS') {
                        dispatch(displayUnsupportedOxfordZipCode(true));
                        dispatch(displayUnsupportedOxfordZipCodePopupShow(true))
                    }
                    else if (sourcePage === 'employeeCensus' && !getState().overall.unsupportedOxfordZipPopupShow && !getState().overall.unsupportedOxfordZip && getState().overall.platform === 'PRIME') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE
                        });
                    }
                    else if (sourcePage !== 'employeeCensus') {
                        dispatch({
                            type: types.DISPLAY_ERROR_MESSAGE,
                        });
                    }
                    console.log(err);
                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', true),
                    });
                    const errReqBody = {
                        groupSize: groupSize,
                        zipCode: zipObj.zipCode,
                        effectiveDate: effectiveDate,
                        stateCode: zipObj.stateCode,
                        sicCode: otherDetails.sicCode !== '' ? otherDetails.sicCode : '',
                        preferredPlans: true,
                        specialtyTypes: otherDetails.selectedProducts,
                        platform: otherDetails.platform
                    }
                    logError(KEY_NAME, UHC_OXFORD_SPECIALTY_PLAN_ERROR, 'getCensusPlans', errReqBody, err);
                });
        }
    };
}

const getTransactionResult = (transactionId) => axios.get(`${apiRoutes.quickQuotes}/transactions/${transactionId}`)
    .then(async (res) => {
        if (res.data.transactionResult.searchResult === null) {
            await pollingInterval(5000);
            const transactionResult = await getTransactionResult(transactionId);
            return await transactionResult;
        }

        return await res.data.transactionResult.searchResult.data;
    })
    .catch((err) => err.response.data.transactionResult.searchResult);

const pollingInterval = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function updateBreadCrumbAccess(page) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE BREADCRUMB ACCESS',
            page,
        });

        dispatch({
            type: types.UPDATE_BREADCRUMB_ACCESS,
            page,
        });
    };
}
export function resetBreadCrumbAccess() {
    return (dispatch) => {
        sendLog({
            action: 'RESET BREADCRUMB ACCESS',
        });

        dispatch({
            type: types.RESET_BREADCRUMB_ACCESS,
            page: 'full',
        });
    };
}
export function resetAuditHistory() {
    return (dispatch) => {
        sendLog({
            action: 'RESET AUDIT HISTORY',
        });

        dispatch({
            type: types.RESET_AUDIT_HISTORY,
            page: 'full',
        });
    };
}
export function resetActionHistory() {
    return (dispatch) => {
        sendLog({
            action: 'RESET ACTION HISTORY',
        });

        dispatch({
            type: types.RESET_ACTION_HISTORY,
            page: 'full',
        });
    };
}
export function toggleMonthlyAnnual() {
    return (dispatch, getState) => {
        const state = getState();
        const { overall, quotes } = state;

        if (!overall.isRenewals) {
            dispatch(auditUserAction('Premium Toggle', 'Annual Premium', !quotes.showAnnualPremium));
        }

        dispatch({
            type: types.TOGGLE_MONTHLY_ANNUAL,
        });
    };
}

export function changeCustomPlanName(plan, customName, type = 'FI') {
    return (dispatch, getState) => {
        const state = getState();
        const { overall, quotes } = state;
        const actionName = 'CHANGE CUSTOM PLAN NAME';

        if (!overall.isRenewals) {
            dispatch(auditUserAction(`${plan.planType} (${actionName})`, plan.code, customName));
        }

        sendLog({
            action: actionName,
            plan,
            customName,
            type,
        });

        dispatch({
            type: types.SERVICE[type.toUpperCase()].CHANGE_CUSTOM_PLAN_NAME,
            plan,
            customName,
            productType: type,
        });
    };
}

export function updateRateReferenceId(rateReferenceId, quoteStatus, lifeClass = '') {

    if (quoteStatus === 'Proposed' || quoteStatus === 'ProposedEnrolled') {
        return (dispatch) => {
            dispatch({
                type: types.UPDATE_PROPOSED_RATE_REFERENCE_ID,
                rateReferenceId,
                lifeClass
            })

        };
    } if (quoteStatus === 'Installation') {
        return (dispatch) => {
            dispatch({
                type: types.UPDATE_INSTALLED_RATE_REFERENCE_ID,
                rateReferenceId,
                lifeClass
            });
        };
    }
}

const getRateIdKey = (flow, rateObj, rateIdKey) => {
    if (flow === 'renewals') {
        return rateObj.isAgeBanded ? "platformAgeBandedRatingID" : "platformCompositeRatingID";
    }
    return rateIdKey;
}

export const getRateIds = (medicalRates, ratingMethodType, flow = 'nb') => {
    const rateIds = [];
    const rateIdKey = ratingMethodType === 'Age Banded' ? "platformAgeBandedRatingID" : "platformCompositeRatingID";
    medicalRates.forEach(rateObj => {
        if (rateObj[getRateIdKey(flow, rateObj, rateIdKey)]) {
            rateIds.push({
                rateId: rateObj[getRateIdKey(flow, rateObj, rateIdKey)],
                sequenceNum: rateObj[getRateIdKey(flow, rateObj, rateIdKey)].substring(rateObj[getRateIdKey(flow, rateObj, rateIdKey)].length - 3),
                planCode: rateObj.medicalPlanCode || rateObj.code
            })
        }
    })
    return rateIds;
}

export const primeSaveRateIdCall = (medicalRateIdObj) => new Promise((resolve, reject) => {
    axios.get(`${apiRoutes.quickQuotes}/save-rates?rateId=${medicalRateIdObj.rateId}&sequenceNum=${medicalRateIdObj.sequenceNum}&isUhcRenewals=${isUhcRenewals()}`)
        .then(res => { resolve(200) })
        .catch(err => {
            if (isUhcRenewals()) {
                const reqBody = {
                    rateId: medicalRateIdObj?.rateId,
                    sequenceNum: medicalRateIdObj?.sequenceNum
                }
                logError(KEY_NAME, UHC_REN_APIS.SAVE_RATES, 'fullQuotesActions.primeSaveRateIdCall', reqBody, err);
            }
            reject(400)
        })
})

const saveRateIDsInPrime = (medicalRatingIds) => {
    return (dispatch, getState) => {
        if (medicalRatingIds.length > 0) {
            primeSaveRateIdCall(medicalRatingIds[0]).then(res => {
                if (res === 200 && medicalRatingIds.length > 1) {
                    for (let i = 1; i < medicalRatingIds.length; i++) {
                        primeSaveRateIdCall(medicalRatingIds[i]);
                    }
                }
            }).then(() => {
                // dispatch(saveStateStore(true));
            }).catch(err => {
                console.log(err);
            })
        }
    }
}
export function updateSaveStoreBool(value) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SAVE_STORE_VALUE',
            value
        });

        dispatch({
            type: types.UPDATE_SAVE_STORE_VALUE,
            value,
        });
    };
}

export function clearErrorMessage(payload) {
    return (dispatch) => {
        sendLog({
            action: 'CLEAR_ERROR_MESSAGE',
        });
        dispatch({
            type: types.SERVICE.FI.CLEAR_ERROR_MESSAGE,
            payload,
        });
    };
}

/*
 Returns if association has type equal to 'Internal' in user Role Permissions
 */
export function checkIsInternalUser(userRolesPermissions) {
    return userRolesPermissions ?
        (userRolesPermissions.associations && userRolesPermissions.associations.length > 0 ?
            (userRolesPermissions.associations[0].type === 'Internal' ? true : false)
            : false)
        : false
}

export function updatePreferredPlansPkg(selectedPlans, selectedPlansMap) {
    for (let i = 0; i < selectedPlans.length; i++) {
        let plan = selectedPlans[i];
        if (!plan.packages.includes(plan.selectedPackages[0]) && !plan.standAlone) {
            selectedPlans[i].selectedPackages[0] = plan.packages[0];
        } else if (!plan.packages || plan.packages.length === 0 || plan.standAlone) {
            selectedPlans[i].selectedPackages[0] = 'Single'
        }
    }

    Object.keys(selectedPlansMap).forEach(key => {
        let plans = selectedPlansMap[key];
        for (let i = 0; i < plans.length; i++) {
            let p = plans[i];
            if (p.selectedPackages && !p.packages.includes(p.selectedPackages[0]) && !p.standAlone) {
                selectedPlansMap[key].splice(i, 1);
                let newKey = p.packages[0];
                if (selectedPlansMap[newKey]) selectedPlansMap[newKey].push(p);
                else selectedPlansMap[newKey] = [p];
            } else if (!p.packages || p.packages.length === 0 || p.standAlone) {
                selectedPlansMap[key].splice(i, 1);
                let newKey = 'Single';
                p.selectedPackages = [];
                p.selectedPackages[0] = 'Single';
                if (selectedPlansMap[newKey]) selectedPlansMap[newKey].push(p);
                else selectedPlansMap[newKey] = [p];
            }
        }
    });

    selectedPlans = removeDuplicatePlans(selectedPlans);
    Object.keys(selectedPlansMap).forEach(key => {
        selectedPlansMap[key] = removeDuplicatePlans(selectedPlansMap[key]);
    })

    return dispatch => {
        sendLog({
            action: 'UPDATE_PREFERRED_PLANS_PKG',
        });
        dispatch({
            type: types.UPDATE_PREFERRED_PLANS_PKG,
            selectedPlans,
            selectedPlansMap
        });
    };
}

function removeDuplicatePlans(arr) {
    let result = []
    let indexes = []; // indexes to be deleted
    for (let i = 0; i < arr.length; i++) {
        let plan = arr[i];
        if (!indexes.includes(i))
            for (let j = i + 1; j < arr.length; j++) {
                if (plan.code === arr[j].code && plan.selectedPackages[0] === arr[j].selectedPackages[0])
                    indexes.push(j);
            }
    }
    for (let i = 0; i < arr.length; i++) {
        if (!indexes.includes(i)) {
            result.push(arr[i]);
        }
    }
    return result;
}
export function clearEnrolledPlans() {
    const serviceTypes = [FI_IDENTIFIER, DENTAL, VISION, LIFE];
    return (dispatch) => {
        serviceTypes.forEach(type => {
            dispatch({ type: types.SERVICE[type].CLEAR_ENROLLED_PLANS });
        });
    };
}

export function removeDeselectedEnrolledPlans(selectedPlans, enrolledPlans) {
    const indexesToRemove = {};
    Object.keys(enrolledPlans).forEach(key => {
        indexesToRemove[key] = [];
        let plans = enrolledPlans[key];
        plans.forEach((plan, index) => {
            if (selectedPlans.findIndex(sPlan => {
                if (key === 'Single' || key === 'standalone') {
                    return sPlan.selectedPackages[0] === 'Single' || sPlan.selectedPackages[0] === 'standalone';
                }
                return sPlan.code === plan.code && sPlan.selectedPackages[0] === key;
            }
            ) === -1) indexesToRemove[key].push(index);
        });
    });
    Object.keys(indexesToRemove).forEach(key => {
        for (let i = enrolledPlans[key].length - 1; i >= 0; i--) {
            if (indexesToRemove[key].includes(i)) {
                enrolledPlans[key].splice(i, 1);
            }
        }
    });
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_DESELECTED_ENROLLED_PLANS'
        });
        dispatch({
            type: types.REMOVE_DESELECTED_ENROLLED_PLANS,
            enrolledPlans
        })
    };
}

export const updateUnavailableMedicalPlans = (unavailablePlanCodes) => {
    return (dispatch, getState) => {
        const { quotes: { selectedPlans }, rules: { enrollment: { enrolledPlans } } } = getState();
        let updatedSelectedPlans = [];
        let updatedEnrolledPlans = {};

        updatedSelectedPlans = selectedPlans.filter(selectedPlan => !unavailablePlanCodes.includes(selectedPlan.codeDisplay));

        Object.keys(enrolledPlans).forEach(pkg => {
            const packagePlans = enrolledPlans[pkg];
            const filteredPlans = packagePlans.filter(packagePlan => !unavailablePlanCodes.includes(packagePlan.codeDisplay));
            updatedEnrolledPlans[pkg] = filteredPlans;
        })

        dispatch({
            type: types.UPDATE_SELECTED_MEDICAL_PLAN,
            payload: updatedSelectedPlans
        })

        dispatch({
            type: types.REMOVE_DESELECTED_ENROLLED_PLANS,
            enrolledPlans: updatedEnrolledPlans
        })
    }
}

export const updateUnavailableSpecialtyPlans = (unavailableDentalPlanCodes, unavailableVisionPlanCodes, unavailableLifePlanCodes, unavailableStdPlanCodes, unavailableLtdPlanCodes) => {
    return (dispatch, getState) => {
        const {
            dental: { selectedPlans: selectedDentalPlans },
            vision: { selectedPlans: selectedVisionPlans },
            life: { selectedPlans: selectedLifePlans },
            std: { selectedPlans: selectedStdPlans },
            ltd: { selectedPlans: selectedLtdPlans },
            rules: { enrollment: { enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledSTDPlans, enrolledLTDPlans } }
        } = getState();

        // Handle Dental
        const {
            updatedSelectedPlans: updatedSelectedDentalPlans,
            updatedEnrolledPlans: updatedEnrolledDentalPlans
        } = getSelectedAndEnrolledPlans(selectedDentalPlans, enrolledDentalPlans, unavailableDentalPlanCodes);
        dispatch({
            type: types.UPDATE_SELECTED_DENTAL_PLAN,
            payload: updatedSelectedDentalPlans
        })
        dispatch({
            type: types.UPDATE_ENROLLED_DENTAL_PLANS,
            plans: updatedEnrolledDentalPlans
        })

        // Handle Vision
        const {
            updatedSelectedPlans: updatedSelectedVisionPlans,
            updatedEnrolledPlans: updatedEnrolledVisionPlans
        } = getSelectedAndEnrolledPlans(selectedVisionPlans, enrolledVisionPlans, unavailableVisionPlanCodes);
        dispatch({
            type: types.UPDATE_SELECTED_VISION_PLAN,
            payload: updatedSelectedVisionPlans
        })
        dispatch({
            type: types.UPDATE_ENROLLED_VISION_PLANS,
            plans: updatedEnrolledVisionPlans
        })

        // Handle Life
        const {
            updatedSelectedPlans: updatedSelectedLifePlans,
            updatedEnrolledPlans: updatedEnrolledLifePlans
        } = getSelectedAndEnrolledPlans(selectedLifePlans, enrolledLifePlans, unavailableLifePlanCodes);
        dispatch({
            type: types.UPDATE_SELECTED_LIFE_PLAN,
            payload: updatedSelectedLifePlans
        })
        dispatch({
            type: types.UPDATE_ENROLLED_BASIC_LIFE_PLANS,
            plans: updatedEnrolledLifePlans
        })

        // Handle STD
        const {
            updatedSelectedPlans: updatedSelectedStdPlans,
            updatedEnrolledPlans: updatedEnrolledStdPlans
        } = getSelectedAndEnrolledPlans(selectedStdPlans, enrolledSTDPlans, unavailableStdPlanCodes);
        dispatch({
            type: types.UPDATE_SELECTED_STD_PLAN, 
            payload: updatedSelectedStdPlans
        })
        dispatch({
            type: types.UPDATE_ENROLLED_STD_PLANS,
            plans: updatedEnrolledStdPlans
        })

        // Handle LTD
        const {
            updatedSelectedPlans: updatedSelectedLtdPlans,
            updatedEnrolledPlans: updatedEnrolledLtdPlans
        } = getSelectedAndEnrolledPlans(selectedLtdPlans, enrolledLTDPlans, unavailableLtdPlanCodes);
        dispatch({
            type: types.UPDATE_SELECTED_LTD_PLAN,
            payload: updatedSelectedLtdPlans
        })
        dispatch({
            type: types.UPDATE_ENROLLED_LTD_PLANS,
            plans: updatedEnrolledLtdPlans
        })
    }
}

export const updateFavoritePlansState = (result) => {
    const serviceTypes = ['medical', 'dental', 'vision', 'life', 'lifeDep', 'suppLife','std','ltd'];
    let medicalPlansObject = {};
    let dentalPlansObject = {};
    let visionPlansObject = {};
    let lifePlansObject = {};
    let lifeDepPlansObject = {};
    let suppLifePlansObject = {};
    let stdPlansObject = {};
    let ltdPlansObject = {};
    return (dispatch, getState) => {
        const {
            medicalPlanCodes: favMedicalPlanCodes,
            dentalPlanCodes: favDentalPlanCodes,
            visionPlanCodes: favVisionPlanCodes,
            lifePlanCodes: favLifePlanCodes,
            lifeDepPlanCodes: favLifeDepPlanCodes,
            suppLifePlanCodes: favSuppLifePlanCodes,
            stdPlanCodes: favStdPlanCodes,
            ltdPlanCodes: favLtdPlanCodes,
        } = result;
        const {
            quotes: {
                plans: allMedicalPlans,
                visiblePlans: visibleMedicalPlans,
                selectedPlans: selectedMedicalPlans
            },
            dental: {
                allPlans: allDentalPlans,
                plans: dentalPlans,
                visiblePlans: visibleDentalPlans,
                selectedPlans: selectedDentalPlans
            },
            vision: {
                allPlans: allVisionPlans,
                plans: visionPlans,
                visiblePlans: visibleVisionPlans,
                selectedPlans: selectedVisionPlans
            },
            life: {
                allPlans: allLifePlans,
                plans: lifePlans,
                visiblePlans: visibleLifePlans,
                selectedPlans: selectedLifePlans
            },
            lifeDep: {
                allPlans: allLifeDepPlans,
                plans: lifeDepPlans,
                visiblePlans: visibleLifeDepPlans,
                selectedPlans: selectedLifeDepPlans
            },
            suppLife: {
                allPlans: allSuppLifePlans,
                plans: suppLifePlans,
                visiblePlans: visibleSuppLifePlans,
                selectedPlans: selectedSuppLifePlans
            },
            std: {
                allPlans: allStdPlans,
                plans: stdPlans,
                visiblePlans: visibleStdPlans,
                selectedPlans: selectedStdPlans
            },
            ltd: {
                allPlans: allLtdPlans,
                plans: ltdPlans,
                visiblePlans: visibleLtdPlans,
                selectedPlans: selectedLtdPlans
            },
        } = getState();

        // For Medical
        medicalPlansObject = {
            plans: allMedicalPlans,
            selectedPlans: selectedMedicalPlans,
            visiblePlans: visibleMedicalPlans
        };
        medicalPlansObject = setPlansAsFavorite(medicalPlansObject, favMedicalPlanCodes, false);

        // For Dental
        dentalPlansObject = {
            allPlans: allDentalPlans,
            plans: dentalPlans,
            selectedPlans: selectedDentalPlans,
            visiblePlans: visibleDentalPlans
        };
        dentalPlansObject = setPlansAsFavorite(dentalPlansObject, favDentalPlanCodes, true);

        // For Vision
        visionPlansObject = {
            allPlans: allVisionPlans,
            plans: visionPlans,
            selectedPlans: selectedVisionPlans,
            visiblePlans: visibleVisionPlans
        };
        visionPlansObject = setPlansAsFavorite(visionPlansObject, favVisionPlanCodes, true);

        // For Life
        lifePlansObject = {
            allPlans: allLifePlans,
            plans: lifePlans,
            selectedPlans: selectedLifePlans,
            visiblePlans: visibleLifePlans
        }
        lifePlansObject = setPlansAsFavorite(lifePlansObject, favLifePlanCodes, true);

        // For Dependent Life
        lifeDepPlansObject = {
            allPlans: allLifeDepPlans,
            plans: lifeDepPlans,
            selectedPlans: selectedLifeDepPlans,
            visiblePlans: visibleLifeDepPlans
        }
        lifeDepPlansObject = setPlansAsFavorite(lifeDepPlansObject, favLifeDepPlanCodes, true);

        // supp life
        suppLifePlansObject = {
            allPlans: allSuppLifePlans,
            plans: suppLifePlans,
            visiblePlans: visibleSuppLifePlans,
            selectedPlans: selectedSuppLifePlans
        }
        suppLifePlansObject = setPlansAsFavorite(suppLifePlansObject, favSuppLifePlanCodes, true);

        //STD Fav plan
        stdPlansObject = {
            allPlans: allStdPlans,
            plans: stdPlans,
            selectedPlans: selectedStdPlans,
            visiblePlans: visibleStdPlans
        }
        stdPlansObject = setPlansAsFavorite(stdPlansObject, favStdPlanCodes, true);

        //LTD Fav plan
        ltdPlansObject = {
            allPlans: allLtdPlans,
            plans: ltdPlans,
            selectedPlans: selectedLtdPlans,
            visiblePlans: visibleLtdPlans
        }
        ltdPlansObject = setPlansAsFavorite(ltdPlansObject, favLtdPlanCodes, true);

        serviceTypes.forEach(type => {
            dispatch({
                type: types[`USER_FAVORITE_${type.toUpperCase()}_PLANS`],
                allPlansObject: eval(`${type}PlansObject`)
            })
        });
    }
}

export const clearAllSelectedPlans = (products) => {
    return (dispatch) => {
        Object.keys(products).forEach(product => {
            if (!products[product]) {
                if (product === 'medical') {
                    dispatch({ type: types.SERVICE.FI.CLEAR_SELECTED_PLANS });
                    dispatch({ type: types.SERVICE.FI.CLEAR_ENROLLED_PLANS });
                }
                if (product === 'dental' || product === 'vision' || product === 'life') {
                    dispatch({ type: types.SERVICE[product.toUpperCase()].CLEAR_SELECTED_PLANS })
                    dispatch({ type: types.SERVICE[product.toUpperCase()].CLEAR_ENROLLED_PLANS })
                }
            }
        })
    }
}
