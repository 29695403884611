import * as types from '../../actions/actionTypes';
import {
    handleRequestPlans,
    handleReceiveSpecialtyPlans,
    handleRequestRates,
    handleReceiveSpecialtyRates,
    handleReceiveSpecialtyAllPlans,
    handleReceiveSpecialtyPlanRates,
    handleReceiveSpecialtySelectedPlanRates,
    resetDetails,
    handleRequestPlansRenewals,
    handleReceiveSpecialtyPlansRenewals,
    handleReceiveSpecialtyAllPlansRenewals,
} from './helpers/fetchingReducerHelpers';
import {
    handleUpdatePlan,
    handleUpdateAllPlans,
    handleChangeFilterCriteria,
    handleChangeSortOrder,
    handleResetFilterCriteria,
    handleTogglePreferredPlans,
    handleChangeCustomPlanName,
    handleRatingMethodChange
} from './helpers/filteringReducerHelpers';
import { handleAdjustRatesAndPremium } from './helpers/rateAdjustmentHelper';
import {
    initialFilterCriteria,
    initialState,
    initialfilterTotalsCache,
} from './consts/dentalReducerConsts';
import {
    setAdvanceFilterData,
    setAdvanceFilterWith30Plans,
    handleFilterChange,
    viewFilteredPlans,
    resetFitlers,
    setBasicFilterDirectly,
    setBasicFilterDirectlyUhc
} from './helpers/advanceFilterHelper';

export default function dental(state = initialState, action) {
    switch (action.type) {
        case types.SERVICE.DENTAL.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state,action, initialFilterCriteria, initialfilterTotalsCache);
        case types.SERVICE.DENTAL.REQUEST_RENEWAL_PLANS:
            return handleRequestPlansRenewals(state, initialFilterCriteria, initialfilterTotalsCache, action.fullProps, 'dental');

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action);
        case types.SERVICE.DENTAL.RECEIVE_RENEWAL_PLANS:
            return handleReceiveSpecialtyPlansRenewals(state, action.payload.plans, action.fullProps, 'dental');
        case types.SERVICE.DENTAL.SET_RENEWAL_PLANS_RECEIVED:
            return {
                ...state,
                isFetchingPlans: false,
                [`renewaldentalReceived`]: true
            }

        case types.SERVICE.DENTAL.REQUEST_QUICK_QUOTE_RATES:
        case types.SERVICE.DENTAL.REQUEST_RENEWAL_RATES:
            return handleRequestRates(state);

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_RATES:
        case types.SERVICE.DENTAL.RECEIVE_RENEWAL_RATES:
            return handleReceiveSpecialtyRates(state, action);

        case types.SERVICE.DENTAL.UPDATE_PLAN:
            return handleUpdatePlan(state, action);

        case types.SERVICE.DENTAL.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action);

        case types.SERVICE.DENTAL.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };

        case types.SERVICE.DENTAL.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.CHANGE_RATE_TIER_FOR_SPECIALTY:
            return handleRatingMethodChange(state, action);

        case types.SERVICE.DENTAL.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        case types.SERVICE.DENTAL.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.DENTAL.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_ALL_PLANS:
            return handleReceiveSpecialtyAllPlans(state, action.payload);

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS:
            return handleReceiveSpecialtyAllPlansRenewals(state, action.payload, action.fullProps, 'dental');

        case types.SERVICE.DENTAL.SET_ADVANCE_FILTER_DATA:
            return setAdvanceFilterData(state, state.allPlans);

        case types.SERVICE.DENTAL.SET_ADVANCE_FILTER_30_PLANS:
            return setAdvanceFilterWith30Plans(state, state.allPlans);    

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_PLAN_RATES:
            return handleReceiveSpecialtyPlanRates(state, action);

        case types.SERVICE.DENTAL.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES:
            return handleReceiveSpecialtySelectedPlanRates(state, action);

        case types.SERVICE.DENTAL.APPLY_FILTER:
            return handleFilterChange(state, action.payload);

        case types.SERVICE.DENTAL.VIEW_FILTERED_PLANS:
            return viewFilteredPlans(state, action.payload);

        case types.SERVICE.DENTAL.RESET_FILTERS:
            return resetFitlers(state);

     //   case types.SERVICE.DENTAL.SET_BASIC_FILTERS_DIRECTLY_UHC:
     //       return setBasicFilterDirectlyUhc(state);

        case types.SERVICE.DENTAL.SET_BASIC_FILTERS_DIRECTLY:
            return setBasicFilterDirectly(state);

        case types.SERVICE.DENTAL.RESET_DATA:
            return resetDetails(state, initialFilterCriteria, initialfilterTotalsCache);

        case types.SERVICE.DENTAL.ADJUST_RATE:
            return handleAdjustRatesAndPremium(state, action);

        case types.RESET_FLOW:
        case types.RESET_SHOPPING_FLOW_SPECIALTY:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
            return initialState

        case types.RESET_RENEWAL_FLOW:
            return {
                ...state,
                selectedPlans: [],
            };
        case types.RESET_FLOW_DVL_REVIEW_PAGE: {
            const newVisiblePlans = (state.visiblePlans || []).map((plan) => {
                const item = {
                    ...plan,
                    selected: false,
                };
                return item;
            });

            return {
                ...state,
                selectedPlans: [],
                selectedPlansMap: {
                    Single: [],
                },
                visiblePlans: newVisiblePlans,
            };
        }

        case types.SERVICE.DENTAL.CHANGE_CUSTOM_PLAN_NAME:
            return handleChangeCustomPlanName(state, action);

        case types.UPDATE_SELECTED_DENTAL_PLAN:
            return {
                ...state,
                selectedPlans: action.payload,
                selectedPlansMap: {
                    Single: action.payload,
                },
            };
        case types.USER_FAVORITE_DENTAL_PLANS:
            const { allPlans, plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                allPlans,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        default:
            return state;
    }
}
