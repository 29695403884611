import { checkPlanRatesFilter, checkProductAttribute, getProductPlans } from "./FilterACAHelper";
import { mapVisionPlans, mapDentalPlans, mapBasicLifePlans, mapDepLifePlans } from "./FilterACAPlanMapping";

export const FilterACAPlans = (renewalData) => {

    const { specialtyProducts, planRates, planRateComponents, productAttributes, renewalPlanMaps } = renewalData;
    const validProductAttributeObject = checkProductAttribute(productAttributes)
    const specialtyDentalPlans = [];
    const specialtyVisionPlans = [];
    const specialtyBasicLifePlans = [];
    const specialtyDepLifePlans = [];
    const visionPlanRates = checkPlanRatesFilter(planRates, 'Vision');
    const dentalPlanRates = checkPlanRatesFilter(planRates, 'Dental');
    const basicLifePlanRates = checkPlanRatesFilter(planRates, 'BasicLifeADD');
    const depLifePlanRates = checkPlanRatesFilter(planRates, 'BasicDepLifeADD');

    const visionProducts = getProductPlans(specialtyProducts, 'Vision');
    const dentalProducts = getProductPlans(specialtyProducts, 'Dental');
    const basicLifeProducts = getProductPlans(specialtyProducts, 'BasicLifeADD');
    const depLifeProducts = getProductPlans(specialtyProducts, 'BasicDepLifeADD');

    if (validProductAttributeObject['Dental']) {
        for (let i = 0; i < renewalPlanMaps.length; i++) {
            if (renewalPlanMaps[i].productType === 'Dental') {
                let { currentPlan, renewalPlan } = generatePlanSpecialty('Dental', renewalPlanMaps[i], dentalProducts, dentalPlanRates)
                if (JSON.stringify(currentPlan) !== "{}" && JSON.stringify(renewalPlan) !== "{}") {
                    let tempSetPair = [currentPlan, renewalPlan]
                    specialtyDentalPlans.push(tempSetPair);
                }
            }
        }
    }
    if (validProductAttributeObject['Vision']) {
        for (let i = 0; i < renewalPlanMaps.length; i++) {
            if (renewalPlanMaps[i].productType === 'Vision') {
                let { currentPlan, renewalPlan } = generatePlanSpecialty('Vision', renewalPlanMaps[i], visionProducts, visionPlanRates)
                if (JSON.stringify(currentPlan) !== "{}" && JSON.stringify(renewalPlan) !== "{}") {
                    let tempSetPair = [currentPlan, renewalPlan]
                    specialtyVisionPlans.push(tempSetPair);
                }
            }
        }
    }
    if (validProductAttributeObject['BasicLifeADD']) {
        for (let i = 0; i < renewalPlanMaps.length; i++) {
            if (renewalPlanMaps[i].productType === 'BasicLifeADD') {
                let { currentPlan, renewalPlan } = generatePlanSpecialty('BasicLifeADD', renewalPlanMaps[i], basicLifeProducts, basicLifePlanRates, planRateComponents)
                if (JSON.stringify(currentPlan) !== "{}" && JSON.stringify(renewalPlan) !== "{}") {
                    let tempSetPair = [currentPlan, renewalPlan]
                    specialtyBasicLifePlans.push(tempSetPair);
                }
            }
        }
    }
    if (validProductAttributeObject['BasicDepLifeADD']) {
        for (let i = 0; i < renewalPlanMaps.length; i++) {
            if (renewalPlanMaps[i].productType === 'BasicDepLifeADD') {
                let { currentPlan, renewalPlan } = generatePlanSpecialty('BasicDepLifeADD', renewalPlanMaps[i], depLifeProducts, depLifePlanRates)
                if (JSON.stringify(currentPlan) !== "{}" && JSON.stringify(renewalPlan) !== "{}") {
                    let tempSetPair = [currentPlan, renewalPlan]
                    specialtyDepLifePlans.push(tempSetPair);
                }
            }
        }
    }
    return { specialtyDentalPlans, specialtyVisionPlans, specialtyBasicLifePlans, specialtyDepLifePlans }

}


const generatePlanSpecialty = (productType, renewalPlanMapEntry, specialtyProducts, planRates, planRateComponents) => {
    let currentPlanCode = renewalPlanMapEntry.currentPlanCode
    let renewalPlanCode = renewalPlanMapEntry.renewalPlanCode

    let productPlanCurr = {}
    let productPlanRen = {}
    let checkCurrPlanDiscontinued = false
    let currentPlan = {};
    let renewalPlan = {};

    if(productType === 'BasicLifeADD'){
        const currentPlanCodePart = currentPlanCode.split('--');
        const renewalPlanCodePart = renewalPlanCode.split('--');

        checkCurrPlanDiscontinued = specialtyProducts.find(plan => plan && plan.planCode === currentPlanCodePart[0] && plan.blADnDPairNew === currentPlanCodePart[1])

        productPlanCurr = specialtyProducts.find(plan => plan && plan.planCode === currentPlanCodePart[0] && plan.blADnDPairNew === currentPlanCodePart[1])
        productPlanRen = specialtyProducts.find(plan => plan && plan.planCode === renewalPlanCodePart[0] && plan.blADnDPairNew === renewalPlanCodePart[1])
    } else {
        checkCurrPlanDiscontinued = specialtyProducts.find(plan => plan && plan.planCode === currentPlanCode)

        productPlanCurr = specialtyProducts.find(plan => plan && plan.planCode === currentPlanCode)
        productPlanRen = specialtyProducts.find(plan => plan && plan.planCode === renewalPlanCode)
    }

    if (!productPlanCurr && !productPlanRen)
        return { currentPlan, renewalPlan };

    if (productType === 'Dental') {
        currentPlan = productPlanCurr && mapDentalPlans(true, currentPlanCode, productPlanCurr, planRates, !checkCurrPlanDiscontinued) || {}
        renewalPlan = productPlanRen && mapDentalPlans(false, renewalPlanCode, productPlanRen, planRates) || {}
    }
    else if (productType === 'Vision') {
        currentPlan = productPlanCurr && mapVisionPlans(true, currentPlanCode, productPlanCurr, planRates, !checkCurrPlanDiscontinued) || {}
        renewalPlan = productPlanRen && mapVisionPlans(false, renewalPlanCode, productPlanRen, planRates) || {}
    }
    else if (productType === 'BasicLifeADD') {
        currentPlan = productPlanCurr && mapBasicLifePlans(true, currentPlanCode, productPlanCurr, planRates, planRateComponents, !checkCurrPlanDiscontinued) || {}
        renewalPlan = productPlanRen && mapBasicLifePlans(false, renewalPlanCode, productPlanRen, planRates, planRateComponents) || {}
    }
    else if (productType === 'BasicDepLifeADD') {
        currentPlan = productPlanCurr && mapDepLifePlans(true, currentPlanCode, productPlanCurr, planRates, !checkCurrPlanDiscontinued) || {}
        renewalPlan = productPlanRen && mapDepLifePlans(false, renewalPlanCode, productPlanRen, planRates) || {}
    }
    return { currentPlan, renewalPlan }
}
