const makeVerboseZipObjDisplay = (zipObj) => {
    if (zipObj.county) {
        return `${zipObj.zipCode} - ${zipObj.city}, ${zipObj.stateCode} (${zipObj.county} County)`;
    }
    return `${zipObj.zipCode} - ${zipObj.city}, ${zipObj.stateCode}`;
};

const makeCompactZipObjDisplay = (zipObj) => {
    if (zipObj.county) {
        return `${zipObj.zipCode}, ${zipObj.stateCode} - ${zipObj.county}`;
    }
    return `${zipObj.zipCode}, ${zipObj.stateCode}`;
};

exports.makeZipObjDisplay = (zipObj, isCompact = false) => {
    if (isCompact) {
        return makeCompactZipObjDisplay(zipObj);
    }

    return makeVerboseZipObjDisplay(zipObj);
};

exports.parseZipObj = (zipObj, isCompact = false) => {
    const verbosetext = makeVerboseZipObjDisplay(zipObj);
    const compacttext = makeCompactZipObjDisplay(zipObj);
    const text = isCompact ? compacttext : verbosetext;
    const key = `${zipObj.zipCode}${zipObj.countyFIPSCode ? `-${zipObj.countyFIPSCode}` : ''}`;
    return {
        key,
        verbosetext,
        compacttext,
        text,
        value: text,
        data: zipObj,
    };
};
