import { primeSaveRateIdCall } from '../../actions/fullQuotesActions';

export const medicalSaveRateIDs = (medicalRatingIds) => {
    if (medicalRatingIds.length > 0) {
        primeSaveRateIdCall(medicalRatingIds[0]).then(res => {
            if (res === 200 && medicalRatingIds.length > 1) {
                for (let i = 1; i < medicalRatingIds.length; i++) {
                    primeSaveRateIdCall(medicalRatingIds[i]);
                }
            }
        })
    }
};

// Get rate ids for each product depending on the current quote status
export const getUHCRateIDs = (quoteStatus, rates) => {
    let dentalRatingId = '';
    let visionRatingId = '';
    let lifeRatingId = '';

    switch(quoteStatus) {
        case 'Shopping':
            // TODO: Use IBR rate id if it exists.
            break;
        case 'Proposed':
        case 'MoveToEnroll':
            // Use Proposed rate id if it exists. Otherwise, use IBR rate id if it exists.
            if (rates?.Proposed?.dentalRates) {
                dentalRatingId = rates.Proposed.dentalRates[0].quoteId;
            } else if (false) { // TODO: Use IBR rate id if it exists.
            }
            if (rates?.Proposed?.visionRates) {
                visionRatingId = rates.Proposed.visionRates[0].quoteId;
            } else if (false) { // TODO: Use IBR rate id if it exists.
            }
            if (rates?.Proposed?.lifeRates) {
                lifeRatingId = rates.Proposed.lifeRates[0].quoteId;
            } else if (false) { // TODO: Use IBR rate id if it exists.
            }
            break;
        case 'CloseEnrollment':
            // Use previous CloseEnrollment rate id if it exists. Otherwise, use MoveToEnroll rate id if it exists. 
            if (rates?.CloseEnrollment?.dentalRates) {
                dentalRatingId = rates.CloseEnrollment.dentalRates[0].quoteId;
            } else if (rates?.MoveToEnroll?.dentalRates) {
                dentalRatingId = rates.MoveToEnroll.dentalRates[0].quoteId;
            }
            if (rates?.CloseEnrollment?.visionRates) {
                visionRatingId = rates.CloseEnrollment.visionRates[0].quoteId;
            } else if (rates?.MoveToEnroll?.visionRates) {
                visionRatingId = rates.MoveToEnroll.visionRates[0].quoteId;
            }
            if (rates?.CloseEnrollment?.lifeRates) {
                lifeRatingId = rates.CloseEnrollment.lifeRates[0].quoteId;
            } else if (rates?.MoveToEnroll?.lifeRates) {
                lifeRatingId = rates.MoveToEnroll.lifeRates[0].quoteId;
            }
            break;
        case 'FinalizeEnrollment':
            // Use CloseEnrollment rate id if it exists.
            if (rates?.CloseEnrollment?.dentalRates) {
                dentalRatingId = rates.CloseEnrollment.dentalRates[0].quoteId;
            }
            if (rates?.CloseEnrollment?.visionRates) {
                visionRatingId = rates.CloseEnrollment.visionRates[0].quoteId;
            }
            if (rates?.CloseEnrollment?.lifeRates) {
                lifeRatingId = rates.CloseEnrollment.lifeRates[0].quoteId;
            }
            break;
        default:
            // TODO: Use IBR rate id if it exists.
            break;
    }

    return {
        dentalRatingId,
        visionRatingId,
        lifeRatingId,
    }
};