import { generateSelectedPlansMap, getEmployeeCensus, generateSelectedPlansMapLifeAndDisability } from './expandFunctions';
import { duplicateFieldsToBeAdded } from './mapping';
import { getValue, updateValue } from '../helperFunctions';
import deepCopy from '../../deepCopy';

const getValueFromFunction = (functionName, sourceValue) => {
    switch (functionName) {
        case 'generateSelectedPlansMap':
            return sourceValue ? generateSelectedPlansMap(sourceValue) : null;
        case 'getEmployeeCensus':
            return sourceValue ? getEmployeeCensus(sourceValue) : null;
        case 'generateSelectedPlansMapLifeAndDisability':
            return sourceValue ? generateSelectedPlansMapLifeAndDisability(sourceValue) : null;
        default:
            return null;
    }
}

export const expandStateStorePayload = (minifiedStateStore) => {
    const stateStore = deepCopy(minifiedStateStore)
    Object.keys(duplicateFieldsToBeAdded).forEach(node => {
        const fieldMapping = duplicateFieldsToBeAdded[node];
        fieldMapping.forEach(mapping => {
            const { source, destination, functionName } = mapping;
            let value = null;
            if (functionName !== null) {
                const sourceValue = getValue(stateStore, source);
                value = getValueFromFunction(functionName, sourceValue);
            } else {
                value = getValue(stateStore, source);
            }
            stateStore[node] && updateValue(stateStore[node], destination.split('.'), 0, value);
        })
    })
    return stateStore;
}