import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Accordion, Checkbox, Icon } from 'semantic-ui-react';
import { auditUserAction } from '../../../../actions/actionHelpers/createHistoryHelper';
import { shouldDisableActionsImpactCase, updateNBDashboardActivity, getSalesRepFromMemberGroupId } from '../../../../actions/caseTrackAction';
import { updateDirectSale, updateOARenewalsPage } from '../../../../actions/reviewCardsAction';
import UsersIcon from '../../../../images/icon-users.svg';
import './reviewCards.css';
import { inSubmittedStatus, inClosedWithdrawn } from '../../../../reducers/products/helpers/companyProfileConsts';
import { setDefaultOwner } from '../../../../actions/reviewCardsAction';
import { getMarketType, MARKET_TYPES } from '../../../../utils/businessLogic/platformUtils';

const ReviewCardOwnerAssignmentTop = (props) => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state);
    const renewalsFlow = useSelector((state) => state.overall.isRenewals);
    const producers = useSelector((state) => state.reviewCards.ownerAssignment.producer);
    const agencies = useSelector((state) => state.reviewCards.ownerAssignment.agency);
    const generalAgents = useSelector((state) => state.reviewCards.ownerAssignment.generalAgent);
    const salesReps = useSelector((state) => state.reviewCards.ownerAssignment.salesRep);
    const gaSalesReps = useSelector((state) => state.reviewCards.ownerAssignment.gaSalesRep);
    const role = useSelector((state) => state.renewals.logInData ? state.renewals.logInData[0].role : null);
    const roleName = useSelector((state) => state.renewals.roleName);
    // Assumes value from IBR is stored in same location for all owner types
    const producer = useSelector((state) => state.renewals && state.renewals.renewalData && state.renewals.renewalData.Ibr && state.renewals.renewalData.Ibr.group && state.renewals.renewalData.Ibr.group.owners && state.renewals.renewalData.Ibr.group.owners.producer && state.renewals.renewalData.Ibr.group.owners.producer.xref ? state.renewals.renewalData.Ibr.group.owners.producer.xref : '')
    const directSale = useSelector((state) => state.reviewCards.directSale);
    const caseProgressofNB = useSelector((state) => state.companyProfile.caseProgress);
    const isAreaSupported = useSelector(state => state.supportedArea.supportedAreaFS);
    const isUserRestricted = useSelector(state => state.overall.isUserRestricted);
    let renewalPlan;
    if (store.renewals.globalSearchFlag) {
        renewalPlan = renewalsFlow === true ? store.renewals.renewalSearchCase : null;
    } else {
        renewalPlan = renewalsFlow === true ? store.renewals && store.renewals.renewals && store.renewals.renewals.find(plan => plan.memberGroupId === store.renewals.memberGroupId) : null
    }
    let actualCaseProgress = renewalsFlow === true && renewalPlan ? renewalPlan.caseProgressUI : null
    const caseProgress = renewalsFlow === true ? actualCaseProgress ? actualCaseProgress : 'Not Started' : caseProgressofNB;
    const fullProps = useSelector(state => state)

    const history = useHistory();

    const { pathname } = useLocation();
    const page = pathname.split('/enrollmentManager/')[1];

    let [activeAccordionIndex, setActiveAccordionIndex] = useState(0);
    let producerName = producers[0] ? producers[0].producerName : '-';

    const [actionsToBeDisabled, setActionsToBeDisabled] = useState({
        editOwners: false
    });

    const disableNB = (inSubmittedStatus(caseProgressofNB) || inClosedWithdrawn(fullProps))

    const validateEditOwnerAccess = () => {
        actionsToBeDisabled.editOwners = (props && props.isSubmitted && renewalsFlow) ? (props.isSubmitted || inClosedWithdrawn(fullProps)) : false

        if (dispatch(shouldDisableActionsImpactCase())) {
            actionsToBeDisabled.editOwners = true;
        }

        setActionsToBeDisabled({
            ...actionsToBeDisabled
        });
    }

    useEffect(() => {
        validateEditOwnerAccess();        
    }, []);

    

    useEffect(() => {
        validateEditOwnerAccess();
    }, [page, caseProgress]);

    const firstOwners = (
        <div className="width100Percent">
            <div className="width100Percent flexRow justifySpaceBetween">
                <span className="textCenter width20Percent"><b>Producer:</b></span>
                <span className="textCenter width20Percent"><b>Agency:</b></span>
                <span className="textCenter width20Percent"><b>Sales Rep:</b></span>
                <span className="textCenter width20Percent"><b>General Agent:</b></span>
                <span className="textCenter width20Percent"><b>GA Sales Rep:</b></span>
            </div>
            <div className="width100Percent flexRow justifySpaceBetween">
                <span className="textCenter width20Percent reviewCardItem producerItem">{producerName}</span>
                <span className="textCenter width20Percent reviewCardItem agencyItem">{agencies[0] ? agencies[0].agencyName : '-'}</span>
                <span className="textCenter width20Percent reviewCardItem salesRepItem">{salesReps[0] ? salesReps[0].salesRepName : '-'}</span>
                <span className="textCenter width20Percent reviewCardItem gaItem">{generalAgents[0] ? generalAgents[0].generalAgentName : '-'}</span>
                <span className="textCenter width20Percent reviewCardItem salesRepItem">{gaSalesReps[0] ? gaSalesReps[0].salesRepName : '-'}</span>
            </div>
        </div>
    );

    const additionalOwners = () => {
        const ownerLengths = [producers.length, agencies.length, generalAgents.length, salesReps.length, gaSalesReps.length];
        // We want to iterate over the maximum length of all owner assignment arrays, since the lengths may vary
        const maxLength = Math.max(...ownerLengths);

        let producerList = [];
        let agencyList = [];
        let salesRepList = [];
        let generalAgentList = [];
        let generalAgentSalesRepList = [];

        for (let i = 1; i < maxLength; i++) {
            producerList.push(<span key={i} className="reviewCardItem producerItem">{producers[i] ? producers[i].producerName : '-'}</span>)
            agencyList.push(<span key={i} className="reviewCardItem agencyItem">{agencies[i] ? agencies[i].agencyName : '-'}</span>)
            salesRepList.push(<span key={i} className="reviewCardItem salesRepItem">{salesReps[i] ? salesReps[i].salesRepName : '-'}</span>)
            generalAgentList.push(<span key={i} className="reviewCardItem gaItem">{generalAgents[i] ? generalAgents[i].generalAgentName : '-'}</span>)
            generalAgentSalesRepList.push(<span key={i} className="reviewCardItem salesRepItem">{gaSalesReps[i] ? gaSalesReps[i].salesRepName : '-'}</span>)
        }

        return (
            <div className="ownerAccordionContent flexRow">
                <div className="width20Percent flexColumn justifySpaceBetween textCenter">{producerList}</div>
                <div className="width20Percent flexColumn justifySpaceBetween textCenter">{agencyList}</div>
                <div className="width20Percent flexColumn justifySpaceBetween textCenter">{salesRepList}</div>
                <div className="width20Percent flexColumn justifySpaceBetween textCenter">{generalAgentList}</div>
                <div className="width20Percent flexColumn justifySpaceBetween textCenter">{generalAgentSalesRepList}</div>
            </div>
        );
    };

    const handleEditOwnerClick = () => {
        if (!renewalsFlow) {
            dispatch(auditUserAction('Page Header', `Edit Owner`, `Button Clicked`));
            dispatch(updateNBDashboardActivity('RECEIVE_NB_NEW_CASE'));
        } else {
            const pathParts = props.pathname.split('/');
            const lastPart = pathParts[pathParts.length - 1];

            if (lastPart === 'reviewDetail')
                dispatch(updateOARenewalsPage('review'))
            else if (lastPart === 'decideDetail')
                dispatch(updateOARenewalsPage('decide'))
            else if (lastPart === 'renewalShopping')
                dispatch(updateOARenewalsPage('renewalShopping'))
            else if (props.pathname.includes('/enrollmentManager/manager'))
                dispatch(updateOARenewalsPage('renewalEnrollment'))
            else if (lastPart === 'finalize')
                dispatch(updateOARenewalsPage('renewalFinalize'))
        }

        history.push('/owner-assignment');
    };

    const handleAccordionClick = () => {
        activeAccordionIndex === 0 ? setActiveAccordionIndex(activeAccordionIndex = '') : setActiveAccordionIndex(activeAccordionIndex = 0)
    };

    const handleDirectSaleChange = () => {
        dispatch(updateDirectSale(!directSale));
    };

    return (
        props.collapsed === true
            ? (
                <span className="flexRow marginTopSmall">
                    <h3 className="blueFont marginLeftSmall marginRightLarge marginBottomExtraSmall">
                        <img src={UsersIcon} className="smallIcon marginRightMedium noPaddingTop" alt="Icon" />
                    Owner Assignment
                    </h3>
                    <h5 className="actionFont paddingTopExtraSmall noMarginTop  marginBottomExtraSmall" onClick={() => props.toggleUserCards()}>Collapsed</h5>
                </span>
            )
            : (
                <div className="width100Percent marginTopSmall">
                    <div className="justifySpaceBetween">
                        <span className="flexRow">
                            <h3 className="blueFont marginLeftSmall marginRightLarge marginBottomExtraSmall">
                                <img src={UsersIcon} className="smallIcon marginRightMedium noPaddingTop" alt="Icon" />
                            Owner Assignment
                        </h3>
                            {
                                (props.editPermissionsOwnerAssign || !renewalsFlow)
                                    ? (
                                        <>
                                            <div disabled={(renewalsFlow && actionsToBeDisabled.editOwners) || (!renewalsFlow && disableNB) || !isAreaSupported || isUserRestricted}>
                                                <h5 className="noMarginTop actionFont paddingTopExtraSmall marginBottomExtraSmall" onClick={() => handleEditOwnerClick()} >
                                                    <Icon name="edit" />
                                                    Edit Owners
                                                </h5>
                                            </div>

                                            {
                                                role !== "External" && role !== "Broker" && !renewalsFlow
                                                && <Checkbox className="marginLeftMedium marginTopExtraSmall" label='Direct Sale' checked={directSale} onClick={handleDirectSaleChange} />
                                            }
                                        </>
                                    )
                                    : null
                            }
                        </span>
                    </div>
                    <Accordion id="ownerAccordion">
                        <Accordion.Title
                            active={activeAccordionIndex === 0}
                            index={0}
                            onClick={() => handleAccordionClick()}>
                            <span className="ownerAccordionTitle flexRow width100Percent">
                                <Icon name='dropdown' />
                                {firstOwners}
                            </span>
                        </Accordion.Title>
                        <Accordion.Content active={activeAccordionIndex === 0}>
                            <div>
                                {
                                    additionalOwners()
                                }
                            </div>
                        </Accordion.Content>
                    </Accordion>
                </div>
            )
    );
};

ReviewCardOwnerAssignmentTop.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired
};

export default ReviewCardOwnerAssignmentTop;
