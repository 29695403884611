import { FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS, FI_LF_CONVERSION_COMPLETE_CASE_STATUS } from '../../../utils/consts/consts';
import { isUndefinedOrNull } from '../../../utils/validation/commonValidations';

export const CASE_PROGRESS = {
    QUOTING: 'Quoting',
    ENROLLING: 'Enrolling',
    ENROLLED: 'Enrolled',
    PENDING: 'Pending Submission',
    SUBMITERRORHW: 'Failed To Submit- Error in HW ',
    SUBMITTED: 'Case Submitted - Under Review',
    PENDACCEPT: 'Pending Acceptance',
    ACCEPTED: 'Case Accepted',
    PENDED: 'Pended',
    PENDRESOLVED: 'Pend Resolved - Under Review',
    REPENDED: 'Re-pended',
    RETURNED: 'Returned',
    WITHDRAWN: 'Withdrawn',
    CLOSED: 'Closed',
    REMOVE: 'Deleted',
    INTERNAL_WITHDRAW: 'Internally Withdrawn',
    CONVERTED_TO_LF: FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS,
    LF_CONVERSION_COMPLETE: FI_LF_CONVERSION_COMPLETE_CASE_STATUS
};

export const QUOTE_STATUS = {
    QUOTING: 'Quoting',
    ENROLLED: 'Enrolled',
};

export const QUOTE_PROGRESS_STATUS = {
    SHOPPING: 'Shopping',
    PROPOSED: 'PROPOSED',
    PREINSTALLCENSUSCHANGE: 'PreInstallCensusChange',
    POSTENROLLPREINSTALL: 'PostEnrollPreInstall',
    INSTALLATION: 'Installation',
};

export const SAMX_HEAVYWEIGHT_STATUS = {
    MEMBERGROUP_INITIATED: 'SAMX- Mem Group Installtion Inititated',
    MEMBERGROUP_FAILED: 'SAMX- Mem Group Installtion Failed',
    MEMBERGROUP_SUCCESS: 'SAMX- Mem Group Installtion successfull',
    MEMBER_HW_SUCCESS: 'SAMX- Member HW Installtion successfull',
    MEMBER_HW_FAILED: 'SAMX- Member HW Installtion Failed',
    'COMPLETED - active': 'USP : Complete & Active',
    'COMPLETED - errors resolved': 'USP : Complete with Error Resolved',
    'PROCESSED - with errors': 'USP : PROCESSED - with errors',
    RECEIVED: 'USP: RECEIVED',
    SENT: 'USP: SENT',
    NO_STATUS_FOUND: ' SAMX - Status Mismatch',
};

export const SAMX_LIGHTWEIGHT_STATUS = {
    MEMBER_LW_SUCCESS: 'SAMX- Lw Member Installtion Successfull',
    MEMBER_LW_FAILED: 'SAMX- Member Installtion Failed',
    RETRY: 'USP- Member Retry',
    RECEIVED: 'USP- Member Recieved',
    PROCESSING: 'USP- Member Proccessing',
    SENT: 'USP- Member Sent',
    COMPLETE: 'USP- Member Complete',
    ERROR: 'USP- Member ERROR',
    NO_STATUS_FOUND: ' SAMX - Status Mismatch',
};
export const SUBMITTED_STATUSES = [
    CASE_PROGRESS.PENDING,
    CASE_PROGRESS.SUBMITTED,
    CASE_PROGRESS.PENDACCEPT,
    CASE_PROGRESS.ACCEPTED,
    CASE_PROGRESS.PENDED,
    CASE_PROGRESS.PENDRESOLVED,
    CASE_PROGRESS.REPENDED,
    CASE_PROGRESS.RETURNED,
    CASE_PROGRESS.INTERNAL_WITHDRAW,
];

export const PEND_ACCEPTANCE_REASON = {
    NOT_STARTED: '',
    IN_PROGRESS: '',
    COMPLETED: '',
    MICRO_GROUP: 'Micro Group',
    GROUP_RETRY: 'Group Processing in Retry',
    GROUP_FAILED: 'Group Processing Failed',
    GROUP_ERROR: 'Group Processing Error',
    MEMBER_RETRY: 'Member Processing in Retry',
    MEMBER_FAILED: 'Member Processing Failed',
    ZERO_MEMBERSHIP: 'Prime has Zero Membership'
};

export let EMPLOYEES_SSN = [];
export const SAMX_PRIME_HEAVYWEIGHT_STATUS = {
    '01': 'New Request. Processed GroupECMM data successfully',
    '03': 'Duplicate Request',
    '05': 'Technical error. Please contact administrator.',
    '07': 'Error: Customer Case with TIN "+param.TIN+" and Customer Number: "+param.CustomerNumber+" does not exist',
    '': 'SAMX - PRIME Status Mismatch',
};

/**
 * Returns a boolean representing if the caseProgress is in a submitted state
 * @param {String} caseProgress Case progress in companyProfile representing the groups progress through x
 */
export const inSubmittedStatus = (caseProgress) => SUBMITTED_STATUSES.includes(caseProgress);

export const inClosedWithdrawn = (fullProps) => {

    if (!fullProps) {
        const { store } = require('../../../index');
        fullProps = store.getState()
    }

    const idx = getCaseIndexForNB(fullProps.caseTracking);
    if (fullProps.caseTracking?.caseTrack[idx]?.caseStatus) {
        return ["Withdrawn", "Closed", "Deleted"].includes(fullProps.caseTracking.caseTrack[idx].caseStatus)
    } else {
        return false
    }
}

export const isStatusChangeAllowed = (currentStatus, nextStatus) => {
    let allowedStatuses = [];
    switch (currentStatus) {

        case '':
        case undefined:
            allowedStatuses = [CASE_PROGRESS.QUOTING];
            break;
        case CASE_PROGRESS.QUOTING:
            allowedStatuses = [CASE_PROGRESS.QUOTING, CASE_PROGRESS.PROPOSED,
            CASE_PROGRESS.ENROLLING, CASE_PROGRESS.WITHDRAWN,
            CASE_PROGRESS.CLOSED, CASE_PROGRESS.REMOVE];
            break;
        case CASE_PROGRESS.PROPOSED:
            allowedStatuses = [SUBMITTED_STATUSES.PROPOSED, CASE_PROGRESS.ENROLLING,
            CASE_PROGRESS.WITHDRAWN, CASE_PROGRESS.CLOSED, CASE_PROGRESS.REMOVE];
            break;
        case CASE_PROGRESS.ENROLLING:
            allowedStatuses = [CASE_PROGRESS.ENROLLING, CASE_PROGRESS.PENDACCEPT,
            CASE_PROGRESS.PENDING, CASE_PROGRESS.SUBMITTED, CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.WITHDRAWN, CASE_PROGRESS.CLOSED, CASE_PROGRESS.REMOVE];
            break;
        case CASE_PROGRESS.PENDING:
            allowedStatuses = [CASE_PROGRESS.PENDING, CASE_PROGRESS.SUBMITTED,
            CASE_PROGRESS.ACCEPTED, CASE_PROGRESS.PENDED];
            break;
        case CASE_PROGRESS.SUBMITTED:
            allowedStatuses = [CASE_PROGRESS.SUBMITTED, CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.PENDED, CASE_PROGRESS.INTERNAL_WITHDRAW,
            CASE_PROGRESS.RETURNED, CASE_PROGRESS.REPENDED];
            break;
        case CASE_PROGRESS.PENDED:
            allowedStatuses = [CASE_PROGRESS.PENDED, CASE_PROGRESS.PENDRESOLVED,
            CASE_PROGRESS.REPENDED, CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.RETURNED, CASE_PROGRESS.INTERNAL_WITHDRAW];
            break;
        case CASE_PROGRESS.PENDACCEPT:
            allowedStatuses = [CASE_PROGRESS.PENDACCEPT, CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.PENDED]
            break;
        case CASE_PROGRESS.PENDRESOLVED:
            allowedStatuses = [CASE_PROGRESS.PENDRESOLVED, CASE_PROGRESS.REPENDED,
            CASE_PROGRESS.ACCEPTED, CASE_PROGRESS.RETURNED, CASE_PROGRESS.INTERNAL_WITHDRAW]
            break;

        case CASE_PROGRESS.REPENDED:
            allowedStatuses = [CASE_PROGRESS.PENDRESOLVED, CASE_PROGRESS.ACCEPTED,
            CASE_PROGRESS.REPENDED, CASE_PROGRESS.RETURNED, CASE_PROGRESS.INTERNAL_WITHDRAW]
            break;

        case CASE_PROGRESS.RETURNED:
            allowedStatuses = [CASE_PROGRESS.PENDRESOLVED, CASE_PROGRESS.REPENDED,
            CASE_PROGRESS.ACCEPTED, CASE_PROGRESS.RETURNED, CASE_PROGRESS.INTERNAL_WITHDRAW];
            break;

        case CASE_PROGRESS.INTERNAL_WITHDRAW:
            allowedStatuses = [CASE_PROGRESS.PENDED, CASE_PROGRESS.RETURNED,
            CASE_PROGRESS.REPENDED, CASE_PROGRESS.PENDRESOLVED, CASE_PROGRESS.ACCEPTED];
            break;

        case CASE_PROGRESS.WITHDRAWN:
            allowedStatuses = [CASE_PROGRESS.WITHDRAWN];
            break;
        case CASE_PROGRESS.CLOSED:
            allowedStatuses = [CASE_PROGRESS.CLOSED];
            break;
        case CASE_PROGRESS.REMOVE:
            allowedStatuses = [CASE_PROGRESS.REMOVE];
            break;
        case CASE_PROGRESS.ACCEPTED:
            allowedStatuses = [CASE_PROGRESS.ACCEPTED];
            break;
        default:
            allowedStatuses = [];
            break;

    }
    return allowedStatuses.includes(nextStatus)

}

export const getNBCaseProgress = (fullProps) => {
    const companyProfileCaseProgress = fullProps.companyProfile.caseProgress;
    const idx = getCaseIndexForNB(fullProps.caseTracking);
    const caseTrackProgress = fullProps.caseTracking && fullProps.caseTracking.caseTrack && fullProps.caseTracking.caseTrack[idx] && fullProps.caseTracking.caseTrack[idx].caseStatus ? fullProps.caseTracking.caseTrack[idx].caseStatus : null
    const caseProgress = caseTrackProgress ? caseTrackProgress : companyProfileCaseProgress;
    return caseProgress
}

export const MIN_AGE = 14
export const UNDER_AGE_MODAL_HEADER = "Please Review"

export const primeServiceErrorMessage = 'The UHG service has timed-out. Please try again later.';
export const producerMissingErrorMsg = 'At least one Producer must be assigned to continue quote.';
export const salesRepMissingErrorMsg = 'At least one Sales Rep must be assigned to continue quote.';
export const restrictedUserErrorMsg = 'You are unable to quote in this zip code. For questions, please reach out to your sales representative.';
export const ineligibleEffectiveDateErrorMsg = 'Not eligible to quote effective date selected for this zip code. Please select the 1st, or the 15th of the month.'
export const underAgeMemberMsg = (underAgeMembers) => {
    return underAgeMembers.length > 1 ? 'You have entered an invalid date of birth for more than 2 members. In order to move forward, Employees and Spouses must be older than 14. Please review and correct the Date of Birth/Age for the Employee/Spouse.' :
        underAgeMembers[0].type === 'Employee' ? `You have entered an invalid date of birth for ${underAgeMembers[0].type} ${underAgeMembers[0].name}. In order to move forward, Employees and Spouses must be older than 14. Please correct the Date of Birth/Age.` :
            `You have entered an invalid date of birth for ${underAgeMembers[0].type}. In order to move forward, Employees and Spouses must be older than 14. Please correct the Date of Birth/Age.`
}
export const producerGeneralAgentMissingErrorMsg = 'At least one producer or GA must be assigned to continue.';

export const getCaseIndexForNB = (caseTracking) => {
    const currentQuoteIdIdx = caseTracking && caseTracking.caseTrack && caseTracking.caseTrack.length > 0 && caseTracking.caseTrack.findIndex((obj) => obj.quoteTrackingNumber === caseTracking.currentQuoteId);
    const idx = !isUndefinedOrNull(currentQuoteIdIdx) && currentQuoteIdIdx > -1 ? currentQuoteIdIdx : 0;
    return idx
}

export const employeeClassesNotOptedMsg = (classes) => {
    let classList = ""
    classes.forEach((notSelectedclass) => (
        classList += `<li>${notSelectedclass}</li>`
    ))
    const message = `All classes must have at least one employee in order to proceed. Please correct the census or remove the class
                        <br/>
                        <ol>
                        ${classList}
                        </ol>                
    `;
    return message;
}

export const EMPLOYEE_CLASS_NOT_OPTED_HEADER = "Please Review"