export const formatBrokers = (brokers) => {
    const formattedBrokers = [];
    brokers.forEach((broker) => {
        const formattedBroker = {
            name: broker.producerName,
            phone: broker.producerPhone ? broker.producerPhone : 'N/A',
        };
        formattedBrokers.push(formattedBroker);
    });
    return formattedBrokers;
};

export const formatGeneralAgents = (generalAgents) => {
    const formattedGeneralAgents = [];
    generalAgents.forEach((ga) => {
        const formattedGeneralAgent = {
            name: ga.generalAgentName,
            phone: 'N/A', // N/A for now since we do not have phone # in API response
        };
        formattedGeneralAgents.push(formattedGeneralAgent);
    });
    return formattedGeneralAgents;
};

export const formatSalesReps = (salesReps) => {
    const formattedSalesReps = [];
    salesReps.forEach((salesRep) => {
        const formattedSalesRep = {
            name: salesRep.salesRepName,
            phone: salesRep.salesRepPhone ? salesRep.salesRepPhone : 'N/A',
        };
        formattedSalesReps.push(formattedSalesRep);
    });
    return formattedSalesReps;
};

export const formatAgencies = (agencies) => {
    const formattedAgencies = [];
    agencies.forEach((agency) => {
        const formattedAgency = {
            name: agency.agencyName,
            phone: 'N/A', // N/A for now since we do not have phone # in API response
        };
        formattedAgencies.push(formattedAgency);
    });
    return formattedAgencies;
};
