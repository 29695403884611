import { axios } from '../utils/api/axiosConfig';
import { sessionId } from '../utils/api/session';
import { SOLARIS } from './actionTypes';

const apiRoutes = require('../utils/api/apiRouteConfig');

const sendLog = (actionInfo) => {
    const { store } = require('../index');
    if (process.env.REACT_APP_ENV !== 'local' && (actionInfo.action==='LOGGING_IN' || actionInfo.type=== SOLARIS.SEND_ERROR)) {
        const fullProps = store.getState();
        let marketType = fullProps.overall.appConfigDetails && fullProps.overall.appConfigDetails.marketType ? fullProps.overall.appConfigDetails.marketType : '';
        if(marketType && marketType!=''){
            if(marketType.toUpperCase()=="CIRRUS")
                marketType = 'Oxford'
            else marketType = 'UHC'
        }
        const userIDSamx = fullProps.renewals && fullProps.renewals.loginRequest && fullProps.renewals.loginRequest.reqLogin && fullProps.renewals.loginRequest.reqLogin.optumId
        const log = {
            sessionId,
            timestamp: new Date().toISOString(),
            userId: userIDSamx || 'unknown', // 
            environment: process.env.REACT_APP_ENV || 'local',
            source: sessionStorage.getItem(`SAMx-${marketType}`) || 'unknown',
            marketType,
            quickQuoteType:process.env.SAMx || 'SpecialtyOnly',
            ...actionInfo,
        };
        axios.post(`${apiRoutes.logging}/activity-logs`, log)
            .then((result) => {
                // Log was successful
            })
            .catch((err) => {
                // Log was unsuccessful
            });
    }
};

export default sendLog;
