import { formatRenewalDataMembers, getMedicalPlansRenewalMembers } from "../../../../activities/Renewal/RenewalsCommonComponents/saveDetails"
import { getAgeBandedRate, getCompositeRate, upToThreeDependentsUnder21 } from "../../../../../utils/businessLogic/calculateQuotes"
import { calculateDependentTotal } from "../../../EstEmployerCost/calculateDependentTotal"
import deepCopy from "../../../../../utils/deepCopy"
import { isoStringToMMDDYYYY } from "../../../../../utils/formatters/dates"
import { getMarketType, MARKET_TYPES, isUhcRenewals } from "../../../../../utils/businessLogic/platformUtils"

//Renewals Helper Functions for Employee Cost Tab
export const getMembersEnrolledInPlanRenewals = (page, collection, companyProfile, plan) => {

    let result = []
    
    let fullCensusRenewalMembers = formatRenewalDataMembers(collection.renewalData)
    let companyProfileEmployees = [].concat.apply([], companyProfile.employees)
    let effectiveDate = collection.renewalCaseData && collection.renewalCaseData.effectiveDate ? isoStringToMMDDYYYY(collection.renewalCaseData.effectiveDate) : null

    let count = 0

    if (page === 'decide' && collection && collection.shoppingPlans) {
        collection.shoppingPlans.map((plan) => {
            if (plan && !plan.renewalPlan) {
                count += 1;
            }
        });
    }

    let hasAdditionalPlans = count > 0 ? true : false

    let filteredPlanSets = getMedicalPlansRenewalMembers(collection)

    if (page === 'review') {
        result = getMembersReviewPage(filteredPlanSets, plan)
    }

    if (page === 'decide') {
        result = getMembersDecidePage(hasAdditionalPlans, fullCensusRenewalMembers, plan, filteredPlanSets, effectiveDate)
    }

    if (page === 'shopping') {
        result = getMembersShoppingPage(fullCensusRenewalMembers, filteredPlanSets, plan)
    }

    if (page === 'enrollment') {
        result = getMembersEnrollmentPage(companyProfileEmployees, plan, effectiveDate, collection)
    }

    return result


}

const getMembersReviewPage = (planSets, plan) => {

    let members = []

    planSets && planSets.map(planSet => {
        if ((planSet.renewing && planSet.renewing.code === plan.code) || (planSet.current && planSet.current.code === plan.code)) {
            if (planSet.renewing.code === plan.code)
                members = planSet.renewing.employees
            else
                members = planSet.current.employees
        }
    })

    return members

}

const getMembersShoppingPage = (fullCensusRenewalMembers, planSets, plan) => {
    const marketType = getMarketType();
    let members = [];
    let isCurrent = false;
    
    planSets && planSets.map(planSet => {
        if (planSet.current && planSet.current.code === plan.code) {
            members = planSet.current.employees
            isCurrent = true
        }
        else if (planSet.renewing && planSet.renewing.code === plan.code && (!plan.hasOwnProperty("totalEligibleQuoteDisplay") && plan.renewalPlan) ){
            members = planSet.renewing.employees
            isCurrent = true
        }
    })

    if (!isCurrent) {
        plan.employeeRates && plan.employeeRates.map((employeeRate, i) => {
            if (marketType === MARKET_TYPES.OXFORD) {
                if (fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId))) {
                    const findMember = deepCopy(fullCensusRenewalMembers.find((member) => parseInt(member.employeeId) === parseInt(employeeRate.employeeId)));
                    findMember['total'] = employeeRate.total;
                    members.push(findMember);
                }
            } else { // UHC
                // TODO: may need to add logic for waived employees
                const member = fullCensusRenewalMembers.find(emp=>{
                    return parseInt(emp.employeeId) === parseInt(employeeRate.employeeId)
                });
                if(member){
                    member['total'] = employeeRate.total;
                    members.push(member);
                } 
            }
        })

    }

    return members;
}

const getMembersDecidePage = (hasAdditionalPlans, fullCensusRenewalMembers, plan, planSets, effectiveDate=null) => {

    const uhcRenewalFlag = isUhcRenewals();
    let members = uhcRenewalFlag ?  getMembersShoppingPage(fullCensusRenewalMembers, planSets, plan) : [];
    
    // TO REMOVE AFTER WE HAVE INDIVIDUAL DEPENDENT RATES
    let isAgeBanded = plan.defaultRatingType === 'ageBands' ? true : false

    if(!isUhcRenewals()) {
        if (hasAdditionalPlans) {
            plan.employeeRates && plan.employeeRates.map(employeeRate => {
                for (const member of fullCensusRenewalMembers){
                    //fullCensusRenewalMembers are Employees (not dependents)
                    if(parseInt(member.employeeId) === parseInt(employeeRate.employeeId) || (parseInt(member.memberId) === employeeRate.employeeId)){
                        const findMember = deepCopy(member);
                        findMember['total'] = employeeRate.total
                        members.push(findMember);
                    }
                } 
            })
            members = upToThreeDependentsUnder21(members, effectiveDate);
        } else {

            planSets && planSets.map(planSet => {
                if ((planSet.renewing && planSet.renewing.code === plan.code) || (planSet.current && planSet.current.code === plan.code)) {
                    if (planSet.renewing.code === plan.code)
                        members = planSet.renewing.employees
                    else
                        members = planSet.current.employees
                }
            })
            members = upToThreeDependentsUnder21(members, effectiveDate);
        }

        if(!isAgeBanded) {
            isAgeBanded = plan.isAgeBanded ? true : false;
        }
    }

        members && members.length !== 0 && members.map(employee => {

        let dependentTotalPremium = 0
        let memberPremium = 0

        if (isAgeBanded) {
            memberPremium = getAgeBandedRate(employee.age, plan.ageBands, employee.coverages.medical);
            dependentTotalPremium = calculateDependentTotal(employee, plan, dependentTotalPremium, true);
        } else {
            if (employee.coverages.medical !== '') {
               memberPremium = getCompositeRate(plan.compositeRates, plan.compositeTypeName, 'EE', employee.age);
                dependentTotalPremium = calculateDependentTotal(employee, plan, dependentTotalPremium, false);
            }
        }

        if(uhcRenewalFlag) {
            if(memberPremium) {
                memberPremium = Number(memberPremium);
            }
            if(dependentTotalPremium) {
                dependentTotalPremium = Number(dependentTotalPremium);
            }
            employee['total'] = memberPremium + dependentTotalPremium;
        }

        employee['memberPremium'] = memberPremium
        employee['dependentPremium'] = dependentTotalPremium

    })


    return members
}

export const isCompositeRates = (plan) => {
    return plan?.defaultRatingType === 'composite'
}

export const isTier2Plan = (plan) => {
    if (plan.tierStructureId === 'B' || plan.tierStructureId === '6' || plan.tierStructureId === '7' || plan.compositeTypeName === '2 TIER COMPOSITE ACR') {
        return true
    }
    return false
}

export const findTier2CovType = (age = 0) => {
    if (parseInt(age) < 21) {
        return 'UNDER 21'
    }
    return '21 & OVER'
}

export const findTier2EmployeePremium = (employee, compositeRates) => {
    let premium = 0
    let empCovType = findTier2CovType(employee?.age)
    compositeRates.forEach(rate => {
        if (rate.employeeType && rate.employeeType.toUpperCase() === empCovType) {
            premium = rate.rate ? parseFloat(rate.rate) : 0
        }
    })
    return premium
}

export const findTier2TotalPremium = (employee, employeeCoverage = 'waive', compositeRates, planCode) => {
    let premium = 0
    const productSelection = employee.productSelection || []
    if (employeeCoverage === 'waive') {
        return '0.00'
    }
    premium = findTier2EmployeePremium(employee, compositeRates)
    if (employee.dependents) {
        employee.dependents.forEach((dependent, dependentIdx) => {
            if (productSelection.length > dependentIdx+1 && productSelection[dependentIdx+1].medical === planCode) {
                premium += findTier2EmployeePremium(dependent, compositeRates)
            }
        })
    }
    return premium?.toFixed(2) || '0.00'
}

export const findEmployeePremium = (employee, planDetails) => {
    const coverageMapping = {
        'EE': 'EMPLOYEE',
        'EE/CH': 'EE+CH',
        'ECH': 'EE+CH',
        'EE/SP': 'EE+SP',
        'ESP': 'EE+SP',
        'EE/FAM': 'EE+FAM',
        'FAM': 'EE+FAM'
    }
    const empCoverage = employee.coverages.medical
    const compositeRates = planDetails.compositeRates || []
    if (isTier2Plan(planDetails)) {
        return findTier2TotalPremium(employee, coverageMapping[empCoverage], compositeRates, planDetails.codeDisplay)
    }
    let premium = '0.00'
    compositeRates.forEach(rate => {
        if (rate.employeeType === coverageMapping[empCoverage]) {
            premium = rate.rate || '0.00'
        }
    })
    return premium
}

const getMembersEnrollmentPage = (companyProfileEmployees, planDetails, effectiveDate, collection = null, flow = 'RN', ratingMethod) => {

    let members = companyProfileEmployees && companyProfileEmployees.length > 0 ? upToThreeDependentsUnder21(companyProfileEmployees, effectiveDate) : []
    let updatedMembers = []
    members.map((employee, index) => {
        let employeePremium = 0
        let dependentPremium = 0

        if (employee.productSelection && employee.productSelection[0] && employee.productSelection[0]['medical']) {

            if (planDetails.codeDisplay === employee.productSelection[0]['medical']) {


                if (isUhcRenewals() && isCompositeRates(planDetails)) {
                    employeePremium = findEmployeePremium(employee, planDetails)
                } else if (planDetails.quoteFinal && planDetails.employeeRates || (isUhcRenewals() &&planDetails.quote && planDetails.employeeRates)) {
                    let employeeRate = planDetails.employeeRates.find(empRate => empRate.employeeId === parseInt(employee.memberId) || empRate.employeeId === index + 1 || (isUhcRenewals() && empRate.employeeId === employee.memberId))
                    employeePremium = employeeRate && employeeRate.total ? employeeRate.total : 0
                }
                else {
                    employeePremium = '-'
                }

                employee['premium'] = employeePremium
                employee['memberPremium'] = employeePremium
                employee['total'] = employeePremium
                employee['ooaPremium'] = planDetails.areaCoverage == "In" ? "0.00" : employeePremium;

                updatedMembers.push(employee)

            }
        }
    })

    return updatedMembers
}



//Full Shopping Helper Functions for Employee Cost Tab
export const getMembersEnrolledInPlanFullShopping = (page, enrolledFS, companyProfile, plan, ratingMethod) => {

    let companyProfileEmployees = [].concat.apply([], companyProfile.employees)
    let result = []
    let effectiveDate = companyProfile && companyProfile.effectiveDate ? companyProfile.effectiveDate : null

    if (page === 'fullShopping') {
        if (enrolledFS === true) {
            result = getMembersEnrollmentPage(companyProfileEmployees, plan, effectiveDate, null, 'FS', ratingMethod)
        } else {
            result = getMembersFullShopPages(companyProfileEmployees, plan, ratingMethod)
        }
    }

    return result
}

const getMembersFullShopPages = (companyProfileEmployees, planDetails, ratingMethod) => {

    let members = deepCopy(companyProfileEmployees);

    members.map((employee) => {
        const covType = employee.coverages.medical;
        const filteredDependents = employee.dependents.filter((dependent) => {
            if (covType === 'EE') {
                return false;
            } else if (covType === 'EE/SP') {
                if (dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                    return false;
                }
            } else if (covType === 'EE/CH') {
                if (dependent.relationship === 'Spouse' || dependent.relationship === 'Civil Union Partner' || dependent.relationship === 'Domestic Partner' || dependent.relationship === 'Life Partner' ) {
                    return false;
                }
            }
            return true;
        })
        employee.dependents = filteredDependents;
        return employee;
    });

    // Remove after testing
    // let counter = 1
    // members.map((mem, index) => {
    //     if (mem.coverages.medical !== 'W') {
    //         mem.employeeId = counter++;
    //     }
    // });

    members.map((employee, index) => {
        let employeePremium = 0;
        if (planDetails.employeeRates) {
            let employeeRate = planDetails.employeeRates.find(empRate => parseInt(empRate.employeeId) === parseInt(employee.memberId) || parseInt(empRate.employeeId) === index + 1);
            employeePremium = employeeRate ? employeeRate.total : 0;
        }
        employee['total'] = employeePremium;
        employee['ooaPremium'] = planDetails.areaCoverage == "In" ? "0.00" : employeePremium;
    })

    return members;
}