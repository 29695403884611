const SEE_BENEFIT_SUMMARY = "See Benefit Summary";

export const formatCompositeRates = (rates) => {
    let compositeRates = {
        employee: 0,
        empAndSpouse: 0,
        empAndChild: 0,
        empAndFamily: 0
    }

    if (rates) {
        rates.forEach(rate => {
            if (rate.tier === 'EO') {
                compositeRates.employee = rate.total
            }
            else if (rate.tier === 'ES') {
                compositeRates.empAndSpouse = rate.total
            }
            else if (rate.tier === 'EC') {
                compositeRates.empAndChild = rate.total
            }
            else if (rate.tier === 'EF') {
                compositeRates.empAndFamily = rate.total
            }
        })
    }
    return compositeRates
}

export const formatEmployeeRates = (employeeRates) => {
    const tempEmployeeRates = (employeeRates || []).map(rate => {
        const obj = {
            employeeId: rate.employeeId.toString(),
            eeTotal: rate.ee.toString(),
            erTotal: rate.er.toString(),
            Total: rate.total.toString(),
        }
        return obj;
    })
    return tempEmployeeRates
}

export const checkProductAttribute = (productAttribute) => {
    const obj = {};
    (productAttribute || []).filter(attr => {
        if (attr.processingType === 'Renewal' && attr.product !== 'Medical') {
            obj[attr.product] = { ...attr }
        }
    })
    return obj;
}

export const checkPlanRatesFilter = (planRates, productType) => {
    return (planRates || []).filter(plan => plan.productType === productType)
}

export const getProductPlans = (specialtyProducts, productType) => {
    const plans = [];
    specialtyProducts.forEach(prod => {
        if (prod.type === productType) {
            prod.plans.forEach(plan => {
                plans.push(plan)
            })
        }
    })
    return plans;
}

export const getINODetails = (plan) => {
    let inoResult = { isINO: false };
    if (plan.productType === 'INO' && plan.planType !== 'INO Coinsurance') {
        inoResult = {
            isINO: true,
            inoOrthodontic: plan.ortho ? plan.ortho.inOrthodontia : '',
            inoOrthoEligibility: plan.ortho ? plan.ortho.inOrthoEligibility : '',
            inoAnnualMax: plan.annualMax ? plan.annualMax.inAnnualMax : '',
            inoOrthoMax: plan.ortho ? plan.ortho.inOrthoLifetimeMax : '',
        };
    }
    return inoResult;
}

export const getCopayDetails = (plan) => {
    let copResult = { isCopay: false };
    const { copayDetails } = plan;
    if (Object.keys(copayDetails).length === 0) return copResult;
    if (plan.productType === 'DHMO/Managed Care' || (plan.productType === 'INO' && plan.style === 'Copay')) {
        copResult = {
            isCopay: true,
            restorative: {
                in: {
                    restorationsAmalgamIn: copayDetails.restorative.in.D2140,
                    restorationsResinCompositeIn: copayDetails.restorative.in.D2330,
                    restorationsCompositePosteriorIn: copayDetails.restorative.in.D2392,
                    crownsIn: copayDetails.restorative.in.D2751,
                    crownsFullCastIn: copayDetails.restorative.in.D2791,
                },
                out: {
                    restorationsAmalgamOut: copayDetails.restorative.oon.D2140,
                    restorationsResinCompositeOut: copayDetails.restorative.oon.D2330,
                    restorationsCompositePosteriorOut: copayDetails.restorative.oon.D2392,
                    crownsOut: copayDetails.restorative.oon.D2740,
                    crownsFullCastOut: copayDetails.restorative.oon.D2791,
                },
            },
            diagnostic: {
                in: {
                    officeVisitFeeIn: copayDetails.diagnostic.in.D0999,
                    oralEvalIn: copayDetails.diagnostic.in.D0120,
                    completeSeriesIn: copayDetails.diagnostic.in.D0210,
                    panoramicImageIn: copayDetails.diagnostic.in.D0330,
                },
                out: {
                    officeVisitFeeOut: copayDetails.diagnostic.oon.D0999,
                    oralEvalOut: copayDetails.diagnostic.oon.D0120,
                    completeSeriesOut: copayDetails.diagnostic.oon.D0210,
                    panoramicImageOut: copayDetails.diagnostic.oon.D0330,
                },
            },
            preventative: {
                in: {
                    sealantsIn: copayDetails.preventative.in.D1351,
                    adultProphylaxisIn: copayDetails.preventative.in.D1110,
                    childProphylaxisIn: copayDetails.preventative.in.D1120,
                },
                out: {
                    sealantsOut: copayDetails.preventative.oon.D1351,
                    adultProphylaxisOut: copayDetails.preventative.oon.D1110,
                    childProphylaxisOut: copayDetails.preventative.oon.D1120,
                },
            },
            periodontic: {
                in: {
                    periodontalScalingAndRootPlaningIn: copayDetails.periodontic.in.D4341,
                    periodontalMaintenanceIn: copayDetails.periodontic.in.D4910,
                },
                out: {
                    periodontalScalingAndRootPlaningOut: copayDetails.periodontic.oon.D4341,
                    periodontalMaintenanceOut: copayDetails.periodontic.oon.D4910,
                },
            },
            oralSurgery: {
                in: {
                    copayOralSurgeryIn: copayDetails.oralSurgery.in.D7140,
                    copayOralSurgerySurgicalExtractionIn: copayDetails.oralSurgery.in.D7230,
                },
                out: {
                    copayOralSurgeryOut: copayDetails.oralSurgery.oon.D7140,
                    copayOralSurgerySurgicalExtractionOut: copayDetails.oralSurgery.oon.D7230,
                },
            },
            adjunctiveGeneral: {
                in: {
                    palliativeTreatmentIn: copayDetails.adjunctiveGeneral.in.D9110,
                    generalAnesthesiaIn: copayDetails.adjunctiveGeneral.in.D9230,
                },
                out: {
                    palliativeTreatmentOut: copayDetails.adjunctiveGeneral.oon.D9110,
                    generalAnesthesiaOut: copayDetails.adjunctiveGeneral.oon.D9230,
                },
            },
            endodontic: {
                in: {
                    endodonticMolarIn: copayDetails.endodontic.in.D3330,
                    endodonticPulpotomyIn: copayDetails.endodontic.in.D3222,
                },
                out: {
                    endodonticMolarOut: copayDetails.endodontic.oon.D3330,
                    endodonticPulpotomyOut: copayDetails.endodontic.oon.D3222,
                },
            },
            removableProsthedontics: {
                in: {
                    periodontalDentureMaxillaryIn: copayDetails.removableProsthedontics.in.D5110,
                    periodontalDentureMandibularIn: copayDetails.removableProsthedontics.in.D5120,
                    periodontalPartialDentureMaxillaryIn: copayDetails.removableProsthedontics.in.D5211,
                    periodontalPartialDentureMandibularIn: copayDetails.removableProsthedontics.in.D5212,
                },
                out: {
                    periodontalDentureMaxillaryOut: copayDetails.removableProsthedontics.oon.D5110,
                    periodontalDentureMandibularOut: copayDetails.removableProsthedontics.oon.D5120,
                    periodontalPartialDentureMaxillaryOut: copayDetails.removableProsthedontics.oon.D5211,
                    periodontalPartialDentureMandibularOut: copayDetails.removableProsthedontics.oon.D5212,
                },
            },
            orthodontic: {
                in: {
                    childOrthoIn: copayDetails.orthodontic.in.D8080,
                    adultOrthoIn: copayDetails.orthodontic.in.D8090,
                },
                out: {
                    childOrthoOut: copayDetails.orthodontic.oon.D8080,
                    adultOrthoOut: copayDetails.orthodontic.oon.D8090,
                },
            },
            implant: {
                in: copayDetails.implant.in.D6010,
                out: copayDetails.implant.oon.D6010,
            },
            rateGuarantee: 'Guaranteed', // DNE, hard-coded value
            restorations: copayDetails.restorative ? copayDetails.restorative.in.D2140 : 'N/A',
        };
    }
    return copResult;
}
export const mapInstallOption = (installAvailability, stateCode, platform) =>{
    const electronicOrManualInstall = 'Available via SAMx Submission';
    const manualInstall = 'Available via Manual Submission';
    if (platform === 'cirrus') {
        return electronicOrManualInstall
    } else {
        if (installAvailability.available && installAvailability.states.includes(stateCode)) {
            return electronicOrManualInstall;
        }
        return manualInstall;
    }
}

export const mapPlanType = (planType) => {
    // if (planType === 'DHMO/Managed Care' || planType === 'DHMO') {
    //     return 'SMC';
    // }
    // if (planType === 'Direct Compensation (DC)') {
    //     return 'SMC-DC';
    // }
    return planType;
}

export const dollarFormat = (value) => {
    if (!isNaN(value)) {
        return `$${value}`;
    }

    return value;
}
