import { checkProducersPresent } from '../../components/shared/ConfigurableForms/helpers/conditionCheckers';

// Accepts numbers from 0 to 999, with no leading zeros
export const isUpToThreeDigitNumber = (str) => /^(0|[1-9][0-9]{0,2})$/.test(str);

// Accepts a zip code, or any subset thereof (including empty string)
export const isPartialZip = (str) => /^\d{0,5}$/.test(str);

// Accepts a zip code (5-digit number)
export const isZip = (str) => /^\d{5}$/.test(str);

// Accepts a zip code +4, or any subset thereof (including empty string)
export const isPartialZip4 = (str) => /^\d{0,4}$/.test(str);

// Accepts a zip code +4 (4-digit number)
export const isZip4 = (str) => /^\d{4}$/.test(str);

// Accepts a string that is only digits 0 - 9
export const isDigitsOnly = (str) => /^[0-9]*\.?[0-9]?$/.test(str);

// Accepts a string that is only letters
export const isLettersOnly = (str) => /^[a-zA-Z ]{0,30}$/.test(str)

// Accepts a string that is letters and special characters with space
export const isLettersandSpecialCharacterWithSpace = (str) => /^[a-zA-Z\s\-\']{0,30}$/.test(str)

// Accepts a string that is letters and space
export const hasNoSpecialCharacter = (str) => /^[_A-z0-9]*((-|\s)*[_A-z0-9])*(\s)*$/.test(str);

// Accepts a string with no invalid characters on Group Name
export const hasNoInvalidCharactersOnGroupName = (str) => /^[ A-Za-z0-9,.&-]*$/.test(str);

// Accepts a string with no special char but a period
export const hasNoSpecialCharacterButPeriod = (str) => /^[A-Za-z0-9.]*$/.test(str);

// Accepts a Franchise code (7-digit number)
export const isFranchiseCode = (str) => /^\d{7}$/.test(str);

/**
 *
 * @param {string} str subset of a 3-part numeric string separated by hyphens
 * @param {number} length1 num chars allowed in the first part of the string
 * @param {number} length2 num chars allowed in the second part of the string
 * @param {number} length3 num chars allowed in the third part of the string
 *
 * @returns unmodified string if it is valid; modified string if it can be salvaged, e.g., adding a missing hyphen; null if it is totally wrong
 */
const validatePartialThreePartHyphenatedNumber = (str, length1, length2, length3) => {
    if (!/^[0-9-]*$/.test(str)) { // string must contain only digits and hyphens
        return null;
    }
    let valid;
    if (str.length <= length1) { // check part 1 is valid
        valid = new RegExp(`^\\d{0,${length1}}$`).test(str);
    }
    if (str.length > length1 && str.length <= length1 + length2 + 1) { // check part 1 is valid, hyphen, and part 2 is valid
        valid = new RegExp(`^\\d{0,${length1}}-\\d{0,${length2}}$`).test(str);
    }
    if (str.length > length1 + length2 + 1) { // check part 1 is valid, hyphen, part 2 is valid, hyphen, and part 3 is valid
        valid = new RegExp(`^\\d{1,${length1}}-\\d{0,${length2}}-\\d{0,${length3}}$`).test(str);
    }
    if (valid) {
        return str;
    }

    if (str.length === length1 + 1 && new RegExp(`^\\d{${length1 + 1}}$`).test(str)) { // add missing hyphen between parts 1 and 2
        return `${str.slice(0, length1)}-${str.slice(length1)}`;
    }
    if (str.length === length1 + length2 + 2 && new RegExp(`^\\d{${length1}}-\\d{${length2 + 1}}$`).test(str)) { // add missing hyphen between parts 2 and 3
        return `${str.slice(0, length1 + length2 + 1)}-${str.slice(length1 + length2 + 1)}`;
    }
    if (str.length === length1 + length2 + length3 && new RegExp(`^\\d{${length1 + length2 + length3}}$`).test(str)) { // add all missing hyphens (could have gotten here by pasting into the field)
        return `${str.slice(0, length1)}-${str.slice(length1, length1 + length2)}-${str.slice(length1 + length2)}`;
    }
    return null;
};

/**
 *
 * @param {string} str partial phone string
 *
 * @returns unmodified string if it is a valid partial phone; modified string if it can be salvaged, e.g., adding a missing hyphen; null if it is totally wrong
 */
export const validatePartialPhone = (str) => validatePartialThreePartHyphenatedNumber(str, 3, 3, 4);

/**
 *
 * @param {string} str partial SSN (or ITIN)
 *
 * @returns unmodified string if it is a valid partial SSN/ITIN; modified string if it can be salvaged, e.g., adding a missing hyphen; null if it is totally wrong
 */
export const validatePartialSSN = (str) => validatePartialThreePartHyphenatedNumber(str, 3, 2, 4);

export const validateSSN = (str) => /^(?!(?:(\d)\1{8})|(?:(\d)\2{2}-\2{2}-\2{4}))(?!123-45-6789|219-09-9999|078-05-1120|987-65-4320|987-65-4329|234-56-7891)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(str) || /^(?!(?:(\d)\1{8})|(?:(\d)\2{2}\2{2}\2{4}))(?!123456789|219099999|078051120|987654320|987654329|234567891)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(str);

export const validateFullSSN = (str) => {
    const regexResult = /^(?!(?:(\d)\1{8})|(?:(\d)\2{2}-\2{2}-\2{4}))(?!123-45-6789|219-09-9999|078-05-1120|987-65-4320|987-65-4329|234-56-7891)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/.test(str) || /^(?!(?:(\d)\1{8})|(?:(\d)\2{2}\2{2}\2{4}))(?!123456789|219099999|078051120|987654320|987654329|234567891)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(str);
    if (!regexResult) {
        return false;
    }
    return str;
};

export const validateSpecifiedDuration = (value, type, fullProps) => {
    const [num, daysOrMonths] = value.split(' ');
    if (type === 'halfYear') {
        if (num > 180) {
            return false;
        } else if (daysOrMonths === 'Months' && num > 6) {
            return false;
        } else if (daysOrMonths === 'Days' && num > 180) {
            return false;
        }
    }
    if (type === 'oneYear') {
        if (num > 365) {
            return false;
        } else if (daysOrMonths === 'Months' && num > 12) {
            return false;
        } else if (daysOrMonths === 'Days' && num > 365) {
            return false;
        }
    }
    return true;
}

export const validateTotalCommission = (str, fullProps) => {
    const producers = fullProps.enrollment.application.producer.producer;
    const producerCount = checkProducersPresent(producers);
    let producerTotalCommission = 0;
    for (let i = 0; i < producerCount; i++) {
        let pTotal = producers['producerCommissionPercent' + i] ? producers['producerCommissionPercent' + i] : 0;
        producerTotalCommission = parseFloat(producerTotalCommission) + parseFloat(pTotal);
        if (pTotal == 0 && pTotal == "") {
            return 100;
            break;
        }
    }
    return producerTotalCommission;
};

export const atLeastOneWiritingAgent = (str, fullProps) => {
    const producers = fullProps.enrollment.application.producer.producer;
    const producerCount = checkProducersPresent(producers);
    let flag = false;
    let counter = 0;
    for (let i = 0; i < producerCount; i++) {
        let producerFlag = producers['writingProducerInd' + i] ? producers['writingProducerInd' + i] : false;
        if (producerFlag)
            counter = counter + 1;
        if (counter >= 2 || !str)
            flag = true;
    }
    return flag;
};
