import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { specialtyServices, allNonASOServices } from '../utils/consts/consts';
import { isEmpty } from '../utils/formatters/strings';
import { getAllQuickQuotePlans, setSpecialtyCriteria } from './quickQuotesActions';
import { setQuickRequest } from './overallActions';
import { createHistory } from './historyAction';
import { toRlYYYYMMDDFormat } from '../utils/formatters/dates';
import { getARQRatioForSpecialty, getExistingPlanSelectedVal, identifyMedicalProductInCirrus, identifySpecialtyProductInCirrus, getExistingRatesedVal, getARQRatioForSpecialtyUhcRenewals } from '../components/activities/Renewal/Review/reviewUtils';
import { determineMinContribLevel } from '../utils/businessLogic/calculateContributions';
import { getMarketType, MARKET_TYPES, isUhcRenewals, isMmr } from '../utils/businessLogic/platformUtils';
import { getPlansOldRatesRenewals, doMedicalRateCall } from './renewalRateActions';
import { updateOverridesRequestBody } from '../utils/Renewal/uhcRenewalsOverrides'
import { filterReconciledCensus } from '../components/activities/Renewal/Review/reviewComponents/FilterIBR';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { isPlanTwoTiered, isPlanThreeTiered } from '../utils/businessLogic/isWashingtonTwoTier';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { sendClickEventDataAction } from './analyticsAction';
import {getPlanCodeBatches} from './fullQuotesActions'
import { getEligibleCounts } from '../components/activities/Renewal/RenewalShopping/ShoppingUtils'
const apiRoutes = require('../utils/api/apiRouteConfig');

export function setShoppingReq(val) {
    return (dispatch) => {
        dispatch({
            type: types.SHOPPING_REQUEST,
            payload: val,
        });
    };
}
export function setRenewalCaseData(payload) { return (dispatch) => { dispatch({ type: 'SET_RENEWAL_CASE_DATA', payload }); }; }

export function setDefaultRatingMethod(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_DEFAULT_RATING_METHOD,
            payload: val
        })
    }
}

function requestRateBatch(staticRequestInfo, medicalPlanCodes, index) {
    return (dispatch) => {
        const staticRequestString = {
            "memberGroupId": staticRequestInfo.memberGroupId,
            "parentId": staticRequestInfo.parentId,
            "zipCode": staticRequestInfo.zipCode,
            "effectiveDate": staticRequestInfo.effectiveDate,
            "stateCode": staticRequestInfo.stateCode,
            "countyCode": !isEmpty(staticRequestInfo.countyCode) ? staticRequestInfo.countyCode : '',
            "includeNonMedical": staticRequestInfo.includeSpecialty,
            "employeeCounts": staticRequestInfo.employeeCounts,
            "employeeClass": staticRequestInfo.employeeClasses,
            "employerContribution": staticRequestInfo.employerContribution,
            "employees": !isEmpty(staticRequestInfo.employees) ? staticRequestInfo.employees : '',
            "companyName": staticRequestInfo.companyProfile.companyName,
            "sicCode": !isEmpty(staticRequestInfo.companyProfile.sicCode) ? staticRequestInfo.companyProfile.sicCode : '',
            "franchiseCode": !isEmpty(staticRequestInfo.companyProfile && staticRequestInfo.companyProfile.franchiseCode) ? staticRequestInfo.companyProfile.franchiseCode : '0000000',
            "exchange": staticRequestInfo.Ny_shop,
            "platform": staticRequestInfo.platform,
            "medicalPlanCodes": medicalPlanCodes,
            "quoteStatus": staticRequestInfo.quoteStatus,
        }

        dispatch({ type: types.SERVICE.RS.REQUEST_REN_ADDITIONAL_RATES });

        return new Promise((resolve, reject) => {
            axios.post(`${apiRoutes.quickQuotes}/renewalPlanRates`, staticRequestString)
                .then((res) => {
                    const rates = res.data.medicalRates;
                    dispatch(createHistory('RENEWAL Medical Rates', 'Renewal Shopping Page', 'Success', staticRequestString, rates, 'RL'));
                    if (staticRequestInfo.platform === "CIRRUS") {
                        let payload = {
                            quoteStatus: staticRequestInfo.quoteStatus,
                            product: 'medical',
                            platformRatingId: rates[0].platformRatingID,
                            rateUrl: rates[0].rateUrl
                        }
                        dispatch({
                            type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                            payload
                        })
                    }
                    dispatch({
                        type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_RATES,
                        index: index * 10,
                        rates,
                    });
                    //TODO Ones Cross check with QA will enable or delete
                    dispatch({
                        type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                        payload: true
                    });
                    resolve();
                })
                .catch((err) => {
                    dispatch(createHistory('RENEWAL Medical Rates', 'Renewal Shopping Page', 'Error', staticRequestString, err, 'RL'));
                    dispatch({
                        type: types.SERVICE.RS.ERROR_RENEWAL_RATES,
                        payload: formatPlanOrRateCallError(err, 'rate', false),
                        source: 'RS',
                    });
                    reject();
                });
        });
    };
}

const getCensusRates = (planCodes, platform, groupSize, zipCode,
    effectiveDate, stateCode, countyCode, employeeCounts,
    employeeClasses, employerContribution, includeSpecialty,
    employees, companyProfile, NyShop) => (dispatch, getState) => {
        const staticRequestInfo = {
            groupSize,
            platform,
            zipCode,
            effectiveDate,
            stateCode,
            countyCode,
            employeeCounts,
            employeeClasses,
            employerContribution,
            includeSpecialty,
            companyProfile,
            Ny_shop: NyShop,
        };
        staticRequestInfo.employees = employees;
        const stateProps = getState();
        staticRequestInfo.memberGroupId = stateProps.review.val.customerNumber;
        staticRequestInfo.parentId = stateProps.caseTracking.currentQuoteId;
        staticRequestInfo.quoteStatus = 'Shopping';

        const planCodeBatches = [planCodes];
        dispatch(requestRateBatch(staticRequestInfo, planCodeBatches[0], 0))
            .then(() => {
                planCodeBatches.slice(1, planCodeBatches.length).forEach((planCodeBatch, i) => {
                    dispatch(requestRateBatch(staticRequestInfo, planCodeBatch, i + 1));
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

export function shopAdditionalPlans() {

    return (dispatch, getState) => {
        const reqProps = getState().renewalShopping.shoppingReq;
        const fullProps = getState();
        dispatch({
            type: types.RESET_SHOPPING_DATA,
        });
        dispatch({
            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
            payload: false
        });
        // Prime requires a boolean - undefined will mess it up
        const includeSpecialty = (reqProps && reqProps.includeSpecialty) ? reqProps.includeSpecialty : false;
        const renewalShoppingRequest = {
            groupSize: reqProps.groupSize,
            zipObj: reqProps.zipObj,
            effectiveDate: reqProps.effectiveDate,
            otherDetails: reqProps.otherDetails,
        };
        const stringifyEmployeeCounts = JSON.stringify(reqProps.employeeCounts)

        dispatch({
            type: types.SERVICE.RS.REQUEST_REN_ADDITIONAL_PLANS,
            payload: renewalShoppingRequest,
            source: 'RS',
        });
        axios.get(`${`${apiRoutes.quickQuotes}/renewalAvailablePlans?`
            + `groupSize=${reqProps.groupSize}&`
            + `zipCode=${reqProps.zipObj.zipCode}&`
            + `effectiveDate=${reqProps.effectiveDate}&`
            + `stateCode=${reqProps.zipObj.stateCode}&`}${!isEmpty(reqProps.zipObj.countyFIPSCode) ? `countyCode=${reqProps.zipObj.countyFIPSCode}&` : ''
            }${!isEmpty(reqProps.companyProfile.sicCode) ? `sicCode=${reqProps.companyProfile.sicCode}&` : ''
            }${!isEmpty(reqProps.companyProfile.franchiseCode) ? `franchiseCode=${reqProps.companyProfile.franchiseCode}&` : ''
            }exchange=${reqProps.Ny_shop}&`
            + `includeNonMedical=${includeSpecialty}&`
            + `employeeCounts=${stringifyEmployeeCounts}`)
            .then((res) => {
                dispatch(createHistory('RENEWAL Available Plans', 'Renewal Shopping Page', 'Success', reqProps, res.data, 'RL'));
                let platform;
                if (res && res.data && res.data[0].medicalPlans && res.data[0].medicalPlans.length) {
                    if (res.data[0].medicalPlans.length > 0) {
                        platform = res.data[0].medicalPlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
                    }
                    dispatch({
                        type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_PLANS,
                        payload: res.data[0],
                        renewalShoppingRequest,
                        fullProps: fullProps
                    });
                    // dispatch({
                    //     type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                    //     payload: true
                    // });
                    if (platform === "CIRRUS" && !identifyMedicalProductInCirrus(fullProps.renewalShoppingHelper.renewalCaseData.product)) {
                        reqProps.employerContribution = {
                            ...reqProps.employerContribution,
                            "productType": "Medical",
                            "classId": 1,
                            "subscriberValue": determineMinContribLevel(reqProps.zipObj.stateCode),
                        }


                    }
                    dispatch(getCensusRates(
                        res.data[0].medicalPlans.map((plan) => plan.code), platform,
                        reqProps.groupSize, reqProps.zipObj.zipCode,
                        reqProps.effectiveDate, reqProps.zipObj.stateCode,
                        reqProps.zipObj.countyFIPSCode, reqProps.employeeCounts,
                        reqProps.employeeClasses,
                        reqProps.employerContribution, includeSpecialty, reqProps.employees,
                        reqProps.companyProfile, reqProps.Ny_shop,
                    ));
                } else if (res && res.data && res.data[0].medicalPlans && res.data[0].medicalPlans.length === 0) {
                    dispatch({
                        type: types.SERVICE.RS.RECEIVE_REN_ADDITIONAL_PLANS,
                        payload: res.data,
                        renewalShoppingRequest,
                        fullProps: fullProps
                    });
                }
            })
            .catch((err) => {
                dispatch(createHistory('RENEWAL Available Plans', 'Renewal Shopping Page', 'Error', reqProps, err, 'RL'));
                dispatch({
                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                    payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something Went Wrong in Fetching Plans,Please contact Administration',
                    source: 'RS',
                });
                dispatch({
                    type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                    payload: true
                });
            });
    };
}

const formatLifeRates = (rate,life) =>{
    rate.ADDTotVolAmount = life.finalRates.ADDTotVolAmount;
    rate.BasicLifeTotVolAmount = life.finalRates.BasicLifeTotVolAmount;
    rate.aDnDLifeRateVol = life.finalRates.aDnDLifeRateVol;
    rate.aDndTotalPremium = life.finalRates.aDndTotalPremium;
    rate.basicLifeRateVol = life.finalRates.basicLifeRateVol;
    rate.basicLifeTotalPremium = life.finalRates.basicLifeTotalPremium;
    rate.blCode =  rate.code === life.code && rate.blCode !== life.code ? rate.blCode : life.code;
    rate.byCensus = life.byCensus;
    rate.code = life.finalRates.code;
    rate.employeeRate = life.byCensus.employeeRates;
    rate.totalAnnualPremium = life.finalRates.totalAnnualPremium;
    rate.totalLifeRateVol = life.finalRates.totalLifeRateVol;
    rate.totalMonthlyPremium = life.finalRates.totalMonthlyPremium;
    return rate;
}

const formatDentalVisonRates = (rate,spl) => {
   // rate.byCensus = spl.byCensus;
    rate.byCensus.employeeRates = spl.byCensus.employeeRates;
    rate.byCensus.totalMonthlyPremium = spl.byCensus.finalMonthlyPremium;
    rate.code = spl.code;
    rate.monthlyPremium = spl.monthlyPremium;
    return rate;
}
export function specialtyRatesBatch(ratesRequest, isCirrus, index, groupSize,
    filteredResultsOnly, otherDetails = null, employeeCounts,
    employeeClasses, employerContribution, employees, adjustRates,mmrQuoteStatus=null) {
    return (dispatch, getState) => {
        const stateProps1 = getState();
        const { service } = ratesRequest;
        const memberGroupId = stateProps1.review.val.customerNumber;  // Check for MMR
        const quoteStatus = mmrQuoteStatus||'Shopping';
        const products = stateProps1.review.renewalCaseData && stateProps1.review.renewalCaseData.product ? stateProps1.review.renewalCaseData.product : stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalCaseData ? stateProps1.renewalShoppingHelper.renewalCaseData.product : "";

        const isUhcRenewal = isUhcRenewals();
        // const { specialtyCriteria } = getState();
        let ibrPlanKey = 'review';
        if (isUhcRenewal  && stateProps1.overall.source !== 'mmr') {
             if(stateProps1.review.medical) {
                 ibrPlanKey = 'review';
             } else if(stateProps1.decide.medical) {
                 ibrPlanKey = 'decide';
             }
         }
        if (service.apiKeyName === 'life' && employees) {
            //Added Platform Check UNtill SW is not ready with BL Cirrus Plans
            if (stateProps1.overall.source === 'mmr') {
                dispatch(
                    {
                        type: types.REQUEST_MMR_ADDITIONAL_RATES,
                        product: ratesRequest.productLineType[0] === 'Basic Dep Life' ? "dependentLife" : service.apiKeyName
                    });
            }
            let planCodeDetails = [];
            for (let i = 0; i < ratesRequest.planCodes.length; i++) {
                let newPlan = {
                    planCode: ratesRequest.planCodes[i],
                    depLifeSpouseAmount: ratesRequest.spouseBenefitAmount[i],
                    depLifeChildAmount: ratesRequest.childBenefitAmount[i]
                }
                planCodeDetails.push(newPlan)
            }

            const platform = isUhcRenewal ? "PRIME" : ['CT', 'NY', 'NJ'].includes(ratesRequest.stateCode) ? "CIRRUS" : ratesRequest.platform
            const basicLifeRateRequest = {
                groupSize,
                zipCode: ratesRequest.zipCode,
                stateCode: ratesRequest.stateCode,
                countyCode: ratesRequest.countyFIPSCode,
                effectiveDate: ratesRequest.effectiveDate,
                platform: platform,
                basicLifePlanCodes: planCodeDetails,
                blPlanCode: ratesRequest.blPlanCodes,
                empCensus: employees,
                employees,
                combinedCodes: ratesRequest.combinedCodes,
                adjustRates: adjustRates,
                sicCode: ratesRequest.sicCode,
                parentId: stateProps1.caseTracking.currentQuoteId,
            };
            basicLifeRateRequest.memberGroupId = memberGroupId;
            basicLifeRateRequest.quoteStatus = quoteStatus;
            basicLifeRateRequest.enrolledLifePlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledLifePlans ? stateProps1.rules.enrollment.enrolledLifePlans : [];
            basicLifeRateRequest.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('life') : false;
            if (isUhcRenewals()) {
                basicLifeRateRequest.companyName = ratesRequest.companyName || '';
                basicLifeRateRequest.eligibleCount = ratesRequest.eligibleCount;
                let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'BASIC_LIFE_EE')
                basicLifeRateRequest.arqRatio = ratesRequest.blPlanCodes.map(_ => renewingPlanARQRatio)
            } else {
                basicLifeRateRequest.companyName = ratesRequest.companyName || '';
                basicLifeRateRequest.arqRatio = ratesRequest.blPlanCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData, 'life', plan) })
            }

            basicLifeRateRequest.requestType = 'renewal';
            if (isUhcRenewal) {
                 const overRideRates = updateOverridesRequestBody(basicLifeRateRequest, stateProps1);
                 basicLifeRateRequest.overRideRates = overRideRates ? overRideRates : {};
                //basicLifeRateRequest.overRideRates = {}
                basicLifeRateRequest.isUhcRenewals = true;
                basicLifeRateRequest.custNum = stateProps1.review?.renewalCaseData?.primeCustomerNumber ? stateProps1.review?.renewalCaseData?.primeCustomerNumber : stateProps1.renewalShopping?.renewalCaseData?.primeCustomerNumber;
                //basicLifeRateRequest.platform = ratesRequest.platform;
                basicLifeRateRequest.groupSize = ratesRequest.eligibleCount ? ratesRequest.eligibleCount : groupSize;
            } else {
                const renewalDataArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData: stateProps1.review && stateProps1.review.renewalData ? stateProps1.review.renewalData: {};
                const renewalCaseDataObj = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalCaseData ? stateProps1.renewalShoppingHelper.renewalCaseData: stateProps1.review && stateProps1.review.renewalCaseData ? stateProps1.review.renewalCaseData: {};
                const lifeArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.life && stateProps1.renewalShoppingHelper.life.set  ? stateProps1.renewalShoppingHelper.life.set: stateProps1.review && stateProps1.review.life && stateProps1.review.life.set ? stateProps1.review.life.set: [];
                const basicLifeAddService = 'BASICLIFEADD';
                if (ratesRequest.platform !== 'PRIME' && (ratesRequest.service.typeName === 'LIFE' && stateProps1.rules.enrollment.enrolledLifePlans.length > 0)) {
                    const { optumId } = stateProps1.renewals.logInData[0];
                    basicLifeRateRequest.optumId = optumId;
                    const isExistingPlanSelected = ratesRequest.platform !== 'PRIME' ? getExistingPlanSelectedVal(renewalDataArray, stateProps1.rules && stateProps1.rules.enrollment ? stateProps1.rules.enrollment : {}, basicLifeAddService) : null;
                    basicLifeRateRequest.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
                    basicLifeRateRequest.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : null;
                    basicLifeRateRequest.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : null;
                    if (isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
                        basicLifeRateRequest.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
                        basicLifeRateRequest.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
                        basicLifeRateRequest.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded : '';
                    } else {
                        const identifiedProduct = identifySpecialtyProductInCirrus(products, ratesRequest.service.typeName.toUpperCase());
                        if (identifiedProduct) {
                            const getProductAttribute = renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                            basicLifeRateRequest.existingProductSelected = 'true';
                            basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq : '0.0'
                        } else if (!identifiedProduct) {
                            basicLifeRateRequest.newProductAdded = 'true';
                        }
                    }
                } else if (ratesRequest.platform !== 'PRIME' && (ratesRequest.service.typeName === 'LIFE' && lifeArray.length > 0)) {
                    const { optumId } = stateProps1.renewals.logInData[0];
                    basicLifeRateRequest.optumId = optumId;
                    const plansSet = lifeArray && lifeArray.length > 0 ? lifeArray : [];
                    const planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
                    
                    const identifiedProduct = identifySpecialtyProductInCirrus(renewalCaseDataObj.product, ratesRequest.service.typeName.toUpperCase());
                    const isExistingPlanAvailable = getExistingRatesedVal(renewalDataArray, {}, basicLifeAddService, planRates);
                    if(identifiedProduct) {
                        const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                        basicLifeRateRequest.existingProductSelected = 'true';
                        basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                    }else if(!identifiedProduct) {
                        basicLifeRateRequest.newProductAdded = 'true';
                    }
                    basicLifeRateRequest.existingPlanSelected = isExistingPlanAvailable && isExistingPlanAvailable.selectedExistingPlan ? isExistingPlanAvailable.selectedExistingPlan : false;
                    basicLifeRateRequest.rateGuarBegin = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarBegin) : null;
                    basicLifeRateRequest.rateGuarEnd = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarEnd) : null;
                    basicLifeRateRequest.existingPlanRates = planRates;
                }
                else if (ratesRequest.platform !== 'PRIME' && ratesRequest.service.typeName === 'LIFE') {
                    const { optumId } = stateProps1.renewals.logInData[0];
                    basicLifeRateRequest.optumId = optumId;
                    const identifiedProduct = identifySpecialtyProductInCirrus(products, ratesRequest.service.typeName.toUpperCase());
                    if (identifiedProduct) {
                        const getProductAttribute = renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => basicLifeAddService === productType.product.toUpperCase())
                        basicLifeRateRequest.existingProductSelected = 'true';
                        basicLifeRateRequest.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq : '0.0'
                    } else if (!identifiedProduct) {
                        basicLifeRateRequest.newProductAdded = 'true';
                    }
                }
            }

            return new Promise((resolve, reject) => {
                axios.post(`${apiRoutes.quickQuotes}/basic-life-rates`, basicLifeRateRequest)
                    .then((res) => {
                        const rates = res.data.lifeRates;
                        if(isUhcRenewal && stateProps1.overall.source !== 'mmr'){
                            let lifeIBRPlans = [];
                            if(stateProps1[ibrPlanKey].life && stateProps1[ibrPlanKey].life.set && stateProps1[ibrPlanKey].life.set.length) {
                                lifeIBRPlans = stateProps1[ibrPlanKey].life.set.map(planSet => planSet[1])
                             }
                             if(stateProps1[ibrPlanKey].alternate) {
                                lifeIBRPlans.push(...stateProps1[ibrPlanKey].alternate.filter(x =>x.planType === 'Life')) ;
                             }
                             lifeIBRPlans && lifeIBRPlans.forEach(life =>{
                                rates.map(rate =>{
                                    if(rate.code === life.code || rate.blCode === life.code) {
                                        rate = formatLifeRates(rate,life)
                                    }
                                })
                            })
                        }
                        if (rates) {
                            if (isUhcRenewal) {
                                let payload = {
                                    quoteStatus: quoteStatus,
                                    product: 'life',
                                    platformRatingId: rates[0].quoteId,
                                    rateUrl: '',
                                    rates: rates,
                                    isUhcRenewals: true,
                                }
                                dispatch({
                                    type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                                    payload
                                })
                            } else if (basicLifeRateRequest.platform === "CIRRUS") {
                                let payload = {
                                    quoteStatus: quoteStatus,
                                    product: 'life',
                                    platformRatingId: rates[0].platformRatingID,
                                    rateUrl: rates[0].rateUrl
                                }
                                dispatch({
                                    type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                                    payload
                                })
                            }
                            //while dispatching action  RECEIVE_MMR_ADDITIONAL_RATES, productLineType should be passed for rates
                            if(stateProps1.overall.source === 'mmr' && rates.length > 0){
                                dispatch({
                                    type: types.RECEIVE_MMR_ADDITIONAL_RATES,
                                    rates,
                                    product: ratesRequest.productLineType[0] === 'Basic Dep Life'? "dependentLife" : service.apiKeyName,
                                    tierStructureId:""
                                })
                            }
                            else{
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_RENEWAL_RATES,
                                    index,
                                    rates,
                                    filteredResultsOnly,
                                });
                            }
                            
                        }
                      
                        dispatch(createHistory('RENEWAL Basic Life Rates', 'Renewal Shopping page', 'Success', basicLifeRateRequest, res.data, 'RL'));
                        resolve();
                    })
                    .catch((err) => { 
                        if (stateProps1.overall.source === 'mmr'){
                            //if rateCall fails then remove plans from Life and Dependent Life
                            if(ratesRequest.productLineType[0] === 'Basic EE Life')
                            {
                                dispatch({
                                    type: types.ERROR_MMR_ADDITIONAL_RATES,
                                    planCode: basicLifeRateRequest.basicLifePlanCodes.length > 0 ? basicLifeRateRequest.blPlanCode[0] : '',
                                    product: service.apiKeyName
                                })
                            }                           
                            if(ratesRequest.productLineType[0] === 'Basic Dep Life')
                            {
                                dispatch({
                                    type: types.ERROR_MMR_ADDITIONAL_RATES,
                                    planCode: basicLifeRateRequest.basicLifePlanCodes.length > 0 ? basicLifeRateRequest.blPlanCode[0] : '',
                                    product: ratesRequest.productLineType[0] === 'Basic Dep Life'? "dependentLife" : service.apiKeyName
                                })

                                //if rateCall for depLife gets fail, then life plan should also be removed
                                dispatch({
                                    type: types.ERROR_MMR_ADDITIONAL_RATES,
                                    planCode: stateProps1?.review?.life?.selectedPlans.length > 0 ? stateProps1.review.life.selectedPlans[0].code : '',
                                    product: 'life'
                                })
                            }                          
                        }
                        else
                        {
                            dispatch({
                                type: types.SERVICE[service.typeName].ERROR_RENEWAL_RATES,
                                payload: formatPlanOrRateCallError(err, 'rate', true)
                            });
                        }
                        if(isUhcRenewal) {
                            logError(KEY_NAME, UHC_REN_APIS.BASIC_LIFE_RATES, 'renewalShoppingActions.specialtyRatesBatch', basicLifeRateRequest, err);
                        }
                        dispatch(createHistory('RENEWAL Basic Life Rates', 'Renewal Shopping Page', 'Error', basicLifeRateRequest, err, 'RL'));               
                        reject();
                    });
            });
        }
        if (service.apiKeyName !== 'life' && ['dental', 'vision', 'suppLife', 'shortTermDis', 'longTermDis'].includes(service.apiKeyName)) {
            const reqBody = {
                groupSize,
                zipCode: ratesRequest.zipCode,
                stateCode: ratesRequest.stateCode,
                countyCode: ratesRequest.countyFIPSCode,
                effectiveDate: ratesRequest.effectiveDate,
                platform: ratesRequest.platform,
                sicCode: ratesRequest.sicCode,
            };

            let tierStructureId;

            if (ratesRequest.service.typeName === 'DENTAL') {
                reqBody.enrolledDentalPlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledDentalPlans ? stateProps1.rules.enrollment.enrolledDentalPlans : [];
                reqBody.dentalPlanCodes = ratesRequest.planCodes;
                reqBody.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('dental') : false;
                if (stateProps1.overall.source === 'mmr') {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.review && stateProps1.review.renewalData, 'dental', plan) })
                } else if (isUhcRenewals()) {
                    let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'DENTAL')
                    reqBody.arqRatio = ratesRequest.planCodes.map(_ => renewingPlanARQRatio)
                    reqBody.eligibleCount = ratesRequest.eligibleCount;
                    reqBody.groupSize = ratesRequest.eligibleCount
                } else {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'dental', plan) })
                }

                if(isUhcRenewal) {
                    const source = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.dental ? 
                        stateProps1.renewalShoppingHelper : 
                        stateProps1.review && stateProps1.review.dental ? stateProps1.review : 
                        stateProps1.decide

                    if(source.groupTierStructureId) {
                        tierStructureId = source.groupTierStructureId;
                    } else {
                        const dentalCurrentPlanExists = source.dental && source.dental.set[0] && source.dental.set[0][0]
        
                        if(dentalCurrentPlanExists) {
                            const planType = isMmr() ? 'current' : 'renewing';
                            const renewingPlan = source.dental.set[0].filter(plan => plan.renewalPlanType === planType)
                            if(renewingPlan && planType.length > 0) {
                                tierStructureId = renewingPlan[0].tierStructureId;
                            }
                        }
                    }
                }
            }
            if (ratesRequest.service.typeName === 'VISION') {
                reqBody.enrolledVisionPlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledVisionPlans ? stateProps1.rules.enrollment.enrolledVisionPlans : [];
                reqBody.visionPlanCodes = ratesRequest.planCodes;
                reqBody.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('vision') : false;
                if (stateProps1.overall.source === 'mmr') {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.review && stateProps1.review.renewalData, 'vision', plan) })
                } else if (isUhcRenewals()) {
                    let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData, 'VISION')
                    reqBody.groupSize = ratesRequest.eligibleCount;
                    reqBody.arqRatio = ratesRequest.planCodes.map(_ => renewingPlanARQRatio)
                } else {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'vision', plan) })
                }

                if(isUhcRenewal) {
                    const source = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.vision ? 
                        stateProps1.renewalShoppingHelper : 
                        stateProps1.review && stateProps1.review.vision ? stateProps1.review : 
                        stateProps1.decide

                    if(source.groupTierStructureId) {
                        tierStructureId = source.groupTierStructureId;
                    } else {
                        const visionCurrentPlanExists = source.vision && 
                            source.vision.set[0] && 
                            source.vision.set[0][0]
                        
                        if(visionCurrentPlanExists) {
                            const planType = isMmr() ? 'current' : 'renewing';
                            const renewingPlan = source.vision.set[0].filter(plan => plan.renewalPlanType === planType)
                            if(renewingPlan && planType.length > 0) {
                                tierStructureId = renewingPlan[0].tierStructureId;
                            }
                        }
                    }
                }
            }
            if (ratesRequest.service.typeName === 'SUPPLIFE') {
                reqBody.enrolledSuppLifePlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledSuppLifePlans ? stateProps1.rules.enrollment.enrolledSuppLifePlans : [];
                reqBody.suppLifePlanCodes = ratesRequest.planCodes;
                reqBody.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('sul') : false;
                if (stateProps1.overall.source === 'mmr') {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.review && stateProps1.review.renewalData, 'sul', plan) })
                } else if (isUhcRenewals()) {
                    let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData, 'SUL')
                    reqBody.arqRatio = ratesRequest.planCodes.map(_ => renewingPlanARQRatio)
                } else {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'Sul', plan) })
                }

                if(isUhcRenewal) {
                    const temp= stateProps1.review;

                    if(temp.groupTierStructureId) {
                        tierStructureId = temp.groupTierStructureId;
                    } else {
                        const suppLifeCurrentPlanExists = temp.suppLife && 
                            temp.suppLife.set[0] && 
                            temp.suppLife.set[0][0]
                        
                        if(suppLifeCurrentPlanExists) {
                            const planType1 = isMmr() ? 'current' : 'renewing';
                            const renewingPlans = temp.suppLife.set[0].filter(plan => plan.renewalPlanType === planType1)
                            if(renewingPlans && planType1.length > 0) {
                                tierStructureId = renewingPlans[0].tierStructureId;
                            }
                        }
                    }
                }
            }
            if (ratesRequest.service.typeName === 'STD') {
                reqBody.enrolledSTDPlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledSTDPlans ? stateProps1.rules.enrollment.enrolledSTDPlans : [];
                reqBody.stdPlanCodes = ratesRequest.planCodes;
                reqBody.shortTermDisPlanCodes = ratesRequest.planCodes;
                reqBody.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('std') : false;
                if (stateProps1.overall.source === 'mmr') {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.review && stateProps1.review.renewalData, 'std', plan) })
                } else if (isUhcRenewals()) {
                    let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData, 'STD')
                    reqBody.arqRatio = ratesRequest.planCodes.map(_ => renewingPlanARQRatio)
                } else {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'std', plan) })
                }

                if(isUhcRenewal) {
                    const source = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.std ? 
                        stateProps1.renewalShoppingHelper : 
                        stateProps1.review && stateProps1.review.std ? stateProps1.review : 
                        stateProps1.decide

                    if(source.groupTierStructureId) {
                        tierStructureId = source.groupTierStructureId;
                    } else {
                        const stdCurrentPlanExists = source.std && 
                            source.std.set[0] && 
                            source.std.set[0][0]
                        
                        if(stdCurrentPlanExists) {
                            const planType = isMmr() ? 'current' : 'renewing';
                            const renewingPlan = source.std.set[0].filter(plan => plan.renewalPlanType === planType)
                            if(renewingPlan && planType.length > 0) {
                                tierStructureId = renewingPlan[0].tierStructureId;
                            }
                        }
                    }
                }
            }
            if (ratesRequest.service.typeName === 'LTD') {
                reqBody.enrolledLTDPlans = stateProps1.rules.enrollment && stateProps1.rules.enrollment.enrolledLTDPlans ? stateProps1.rules.enrollment.enrolledLTDPlans : [];
                reqBody.ltdPlanCodes = ratesRequest.planCodes;
                reqBody.longTermDisPlanCodes = ratesRequest.planCodes;
                reqBody.existingCoverage = ratesRequest.existingCoverage && Array.isArray(ratesRequest.existingCoverage) ? ratesRequest.existingCoverage.includes('ltd') : false;
                if (stateProps1.overall.source === 'mmr') {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.review && stateProps1.review.renewalData, 'ltd', plan) })
                } else if (isUhcRenewals()) {
                    let renewingPlanARQRatio = getARQRatioForSpecialtyUhcRenewals(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData : stateProps1.review && stateProps1.review.renewalData, 'LTD')
                    reqBody.arqRatio = ratesRequest.planCodes.map(_ => renewingPlanARQRatio)
                } else {
                    reqBody.arqRatio = ratesRequest.planCodes.map(plan => { return getARQRatioForSpecialty(stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData :stateProps1.review && stateProps1.review.renewalData , 'ltd', plan) })
                }

                if(isUhcRenewal) {
                    const source = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.ltd ? 
                        stateProps1.renewalShoppingHelper : 
                        stateProps1.review && stateProps1.review.ltd ? stateProps1.review : 
                        stateProps1.decide

                    if(source.groupTierStructureId) {
                        tierStructureId = source.groupTierStructureId;
                    } else {
                        const ltdCurrentPlanExists = source.ltd && 
                            source.ltd.set[0] && 
                            source.ltd.set[0][0]
                        
                        if(ltdCurrentPlanExists) {
                            const planType = isMmr() ? 'current' : 'renewing';
                            const renewingPlan = source.ltd.set[0].filter(plan => plan.renewalPlanType === planType)
                            if(renewingPlan && planType.length > 0) {
                                tierStructureId = renewingPlan[0].tierStructureId;
                            }
                        }
                    }
                }
            }

            if(isUhcRenewal) {
                if(isPlanTwoTiered(tierStructureId)) {
                    reqBody.ratingTier = "2 Tier";
                }

                if (isPlanThreeTiered(tierStructureId)) {
                    reqBody.ratingTier = "3 Tier";
                }
            }

            reqBody.requestType = 'renewal';
            reqBody.employees = employees;
            reqBody.empCensus = employees;
            reqBody.employeeClasses = employeeClasses;
            reqBody.employerContribution = employerContribution;
            reqBody.memberGroupId = memberGroupId;
            reqBody.quoteStatus = quoteStatus;
            reqBody.companyName = ratesRequest.companyName || '';
            if (isUhcRenewal) {
                 const overRideRates = updateOverridesRequestBody(reqBody, stateProps1);
                 reqBody.overRideRates = overRideRates ? overRideRates : {};
                //reqBody.overRideRates = {}
                reqBody.requestType = 'renewal';
                reqBody.isUhcRenewals = true
                reqBody.custNum = stateProps1.review?.renewalCaseData?.primeCustomerNumber ? stateProps1.review?.renewalCaseData?.primeCustomerNumber : stateProps1.renewalShopping?.renewalCaseData?.primeCustomerNumber;
            }
            if(isMmr()){
                reqBody.isMmr = true;
            }
            reqBody.parentId = stateProps1.caseTracking.currentQuoteId;
            if (reqBody.platform === "CIRRUS" && !identifyMedicalProductInCirrus(products)) {
                reqBody.employerContribution = {
                    ...reqBody.employerContribution,
                    "productType": ratesRequest.service.typeName,
                    "classId": 1,
                    "subscriberValue": determineMinContribLevel(ratesRequest.stateCode),
                }
            }
            const renewalDataArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalData ? stateProps1.renewalShoppingHelper.renewalData: stateProps1.review && stateProps1.review.renewalData ? stateProps1.review.renewalData: {};
            const renewalCaseDataObj = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.renewalCaseData ? stateProps1.renewalShoppingHelper.renewalCaseData: stateProps1.review && stateProps1.review.renewalCaseData ? stateProps1.review.renewalCaseData: {};
            const dentalArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.dental && stateProps1.renewalShoppingHelper.dental.set  ? stateProps1.renewalShoppingHelper.dental.set: stateProps1.review && stateProps1.review.dental && stateProps1.review.dental.set ? stateProps1.review.dental.set: [];
            const visionArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.vision && stateProps1.renewalShoppingHelper.vision.set  ? stateProps1.renewalShoppingHelper.vision.set: stateProps1.review && stateProps1.review.vision && stateProps1.review.vision.set ? stateProps1.review.vision.set: [];
            const suppLifeArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.sul && stateProps1.renewalShoppingHelper.sul.set  ? stateProps1.renewalShoppingHelper.sul.set: stateProps1.review && stateProps1.review.suppLife && stateProps1.review.suppLife.set ? stateProps1.review.suppLife.set: [];
            const stdArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.std && stateProps1.renewalShoppingHelper.std.set  ? stateProps1.renewalShoppingHelper.std.set : stateProps1.review && stateProps1.review.std && stateProps1.review.std.set ? stateProps1.review.std.set : [];
            const ltdArray = stateProps1.renewalShoppingHelper && stateProps1.renewalShoppingHelper.ltd && stateProps1.renewalShoppingHelper.ltd.set  ? stateProps1.renewalShoppingHelper.ltd.set : stateProps1.review && stateProps1.review.ltd && stateProps1.review.ltd.set ? stateProps1.review.ltd.set : [];
            
            if (reqBody.platform !== 'PRIME' && (ratesRequest.service.typeName === 'DENTAL' && stateProps1.rules.enrollment.enrolledDentalPlans.length > 0 || ratesRequest.service.typeName === 'VISION' && stateProps1.rules.enrollment.enrolledVisionPlans.length > 0 || ratesRequest.service.typeName === 'Sul' && stateProps1.rules.enrollment.enrolledSuppLifePlans.length > 0 || ratesRequest.service.typeName === 'STD' && stateProps1.rules.enrollment.enrolledSTDPlans.length > 0 || ratesRequest.service.typeName === 'LTD' && stateProps1.rules.enrollment.enrolledLTDPlans.length > 0)) {
                // US3947255: Rate Guarantee - Get Rates API Updates
                const { optumId } = stateProps1.renewals.logInData[0];
                reqBody.optumId = optumId;
                const isExistingPlanSelected = reqBody.platform !== 'PRIME' ? getExistingPlanSelectedVal(renewalDataArray, stateProps1.rules && stateProps1.rules.enrollment ? stateProps1.rules.enrollment : {}, service.apiKeyName.toUpperCase()) : null;
                reqBody.existingPlanSelected = isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan ? isExistingPlanSelected.selectedExistingPlan : false;
                reqBody.rateGuarBegin = isExistingPlanSelected && isExistingPlanSelected.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarBegin) : null;
                reqBody.rateGuarEnd = isExistingPlanSelected && isExistingPlanSelected.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanSelected.rateGuarEnd) : null;
                if (isExistingPlanSelected && isExistingPlanSelected.selectedExistingPlan) {
                    reqBody.arq = isExistingPlanSelected && isExistingPlanSelected.arq ? isExistingPlanSelected.arq : '';
                    reqBody.existingProductSelected = isExistingPlanSelected && isExistingPlanSelected.existingProductSelected ? isExistingPlanSelected.existingProductSelected : '';
                    reqBody.newProductAdded = isExistingPlanSelected && isExistingPlanSelected.newProductAdded ? isExistingPlanSelected.newProductAdded : '';
                } else {
                    const identifiedProduct = identifySpecialtyProductInCirrus(products, service.typeName.toUpperCase());
                    if (identifiedProduct) {
                        const getProductAttribute = renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => service.typeName === productType.product.toUpperCase())
                        reqBody.existingProductSelected = 'true';
                        reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq : '0.0'
                    } else if (!identifiedProduct) {
                        reqBody.newProductAdded = 'true';
                    }
                }
            } else if (reqBody.platform !== 'PRIME' && (ratesRequest.service.typeName === 'DENTAL' && dentalArray.length > 0 || ratesRequest.service.typeName === 'VISION' && visionArray.length > 0 || ratesRequest.service.typeName === 'SUPPLIFE' && suppLifeArray.length > 0 || ratesRequest.service.typeName === 'STD' && stdArray.length > 0 || ratesRequest.service.typeName === 'LTD' && ltdArray.length > 0)) {
                const { optumId } = stateProps1.renewals.logInData[0];
                reqBody.optumId = optumId;
                let plansSet = [];
                if(ratesRequest.service.typeName === 'DENTAL' && dentalArray){
                    plansSet = dentalArray;
                } else if(ratesRequest.service.typeName === 'VISION' && visionArray) {
                    plansSet = visionArray;
                } else if(ratesRequest.service.typeName === 'SUPPLIFE' && suppLifeArray) {
                    plansSet = suppLifeArray;
                } else if(ratesRequest.service.typeName === 'STD' && stdArray) {
                    plansSet = stdArray;
                } else if(ratesRequest.service.typeName === 'LTD' && ltdArray) {
                    plansSet = ltdArray;
                }

                const planRates = plansSet ? (plansSet.length > 0 ? plansSet.map(plan => { return {planCode: plan[1].planCode, rates: plan[1].finalRates} }) : []) : [];
                const identifiedProduct = identifySpecialtyProductInCirrus(renewalCaseDataObj.product, ratesRequest.service.typeName.toUpperCase());
                const isExistingPlanAvailable = getExistingRatesedVal(renewalDataArray, {}, ratesRequest.service.typeName.toUpperCase(), planRates);
                if(identifiedProduct) {
                    const getProductAttribute =  renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => ratesRequest.service.typeName.toUpperCase() === productType.product.toUpperCase())
                    reqBody.existingProductSelected = 'true';
                    reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: '0.0'
                }else if(!identifiedProduct) {
                    reqBody.newProductAdded = 'true';
                }
                reqBody.existingPlanSelected = isExistingPlanAvailable && isExistingPlanAvailable.selectedExistingPlan ? isExistingPlanAvailable.selectedExistingPlan : false;
                reqBody.rateGuarBegin = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarBegin ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarBegin) : null;
                reqBody.rateGuarEnd = isExistingPlanAvailable && isExistingPlanAvailable.rateGuarEnd ? toRlYYYYMMDDFormat(isExistingPlanAvailable.rateGuarEnd) : null;
                reqBody.existingPlanRates = planRates;
            }
            else if (reqBody.platform !== 'PRIME' && (ratesRequest.service.typeName === 'DENTAL' || ratesRequest.service.typeName === 'VISION' || ratesRequest.service.typeName === 'SUPPLIFE' || ratesRequest.service.typeName === 'STD' || ratesRequest.service.typeName === 'LTD')) {
                const { optumId } = stateProps1.renewals.logInData[0];
                reqBody.optumId = optumId;
                const identifiedProduct = identifySpecialtyProductInCirrus(products, ratesRequest.service.typeName);
                if (identifiedProduct) {
                    const getProductAttribute = renewalDataArray && renewalDataArray.productAttributes && renewalDataArray.productAttributes.find((productType) => service.typeName === productType.product.toUpperCase())
                    reqBody.existingProductSelected = 'true';
                    reqBody.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq : '0.0'
                } else if (!identifiedProduct) {
                    reqBody.newProductAdded = 'true';
                }
            }
            if(stateProps1.overall.source === 'mmr'){
                dispatch({
                    type: types.REQUEST_MMR_ADDITIONAL_RATES,
                    product : service.apiKeyName
                });
            }
            else{
                dispatch({
                    type: types.SERVICE[service.typeName].REQUEST_RENEWAL_RATES,
                });
            }
            return new Promise((resolve, reject) => {
                reqBody.priorYearDataSelected = stateProps1.renewals.priorYearDataSelected;
                axios.post(`${apiRoutes.quickQuotes}/renewal-speciality-rates`, reqBody)
                    .then((res) => {
                        const rates = res.data[`${service.apiKeyName}Rates`];
                        if(isUhcRenewal && stateProps1.overall.source !== 'mmr'){
                            let dentalIBRPlans = [];let visionIBRPlans = [];
                            if(stateProps1[ibrPlanKey][`${service.apiKeyName}`] && stateProps1[ibrPlanKey][`${service.apiKeyName}`].set && stateProps1[ibrPlanKey][`${service.apiKeyName}`].set.length) {
                                if (service.apiKeyName === 'dental') {
                                    dentalIBRPlans = stateProps1[ibrPlanKey][`${service.apiKeyName}`].set.map(planSet => planSet[1])
                                } else if (service.apiKeyName === 'vision') {
                                    visionIBRPlans = stateProps1[ibrPlanKey][`${service.apiKeyName}`].set.map(planSet => planSet[1])
                                }
                            }
                            service.apiKeyName === 'dental' && dentalIBRPlans.push(...stateProps1[ibrPlanKey].alternate.filter(x =>x.planType === 'Dental'));
                            service.apiKeyName === 'vision' && visionIBRPlans.push(...stateProps1[ibrPlanKey].alternate.filter(x =>x.planType === 'Vision'))
            
                            let ibrRates = service.apiKeyName === 'dental' ? dentalIBRPlans :service.apiKeyName === 'vision' ?visionIBRPlans : []
                            ibrRates && ibrRates.forEach(spl =>{
                                rates.map(rate =>{
                                    if(rate.code === spl.code) {
                                        rate = formatDentalVisonRates(rate,spl)
                                    }
                                })
                            })
                        }
                        let quotes;
                        if (rates) {
                            if (service.apiKeyName === 'medical') {
                                quotes = null;
                            } else {
                                if(['shortTermDis','longTermDis','suppLife'].includes(service.apiKeyName)){
                                    quotes = rates.map((rate) => rate.totalMonthlyPremium);
                                }else{
                                    quotes = rates.map((rate) => rate.byCensus.totalMonthlyPremium);
                                }
                                if (isUhcRenewal) {
                                    let payload = {
                                        quoteStatus: isMmr() ? 'Shopping' : quoteStatus,
                                        product: service.apiKeyName.toLowerCase(),
                                        platformRatingId: rates[0].quoteId,
                                        rateUrl: '',
                                        rates: rates,
                                        isUhcRenewals: true,
                                    }
                                    dispatch({
                                        type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                                        payload
                                    })
                                } else if (reqBody.platform === "CIRRUS") {
                                    let payload = {
                                        quoteStatus: quoteStatus,
                                        product: service.apiKeyName.toLowerCase(),
                                        platformRatingId: rates[0].platformRatingID,
                                        rateUrl: rates[0].rateUrl
                                    }
                                    dispatch({
                                        type: types.UPDATE_RENEWAL_RATE_REFERENCE_ID,
                                        payload
                                    })
                                }
                                
                            }
                            dispatch({
                                type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                                payload: true
                            });
                            if (stateProps1.overall.source === 'mmr' && rates.length > 0){
                                let productType1=service.typeName.toLowerCase()
                                if(productType1==="supplife")productType1="suppLife"
                                dispatch({
                                    type: types.RECEIVE_MMR_ADDITIONAL_RATES,
                                    rates,
                                    product: service.apiKeyName,
                                    tierStructureId,
                                    productType: productType1,
                                    allEmployees: reqBody.employees
                                })
                                const latestState = getState()
                                const coverageCounts = filterReconciledCensus(latestState.review);
                                dispatch(setSpecialtyCriteria({
                                    ...latestState.specialtyCriteria,
                                    dentalCensusSummary: coverageCounts.dentalCensus,
                                    visionCensusSummary: coverageCounts.visionCensus,
                                    suppLifeCensusSummary: coverageCounts.supplifeCensus,
                                    stdCensusSummary: coverageCounts.stdCensus,
                                    ltdCensusSummary: coverageCounts.ltdCensus,
                                    lifeCensusSummary: coverageCounts.lifeCensus,
                                    dependentLifeCensusSummary: coverageCounts.dependentLifeCensus,
                                    platform: 'Prime',
                                }));
                            } else {
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_RENEWAL_RATES,
                                    index,
                                    rates,
                                    quotes,
                                    filteredResultsOnly,
                                    tierStructureId
                                });
                            }
                        }
                        dispatch(createHistory('RENEWAL Speciality Rates', 'Renewal Shopping page', 'Success', reqBody, res.data, 'RL'));
                        resolve();
                    })
                    .catch((err) => {
                        
                        dispatch(createHistory('RENEWAL Speciality Rates', 'Renewal Shopping page', 'Success', reqBody, err, 'RL'));
                        if (stateProps1.overall.source === 'mmr'){
                            dispatch({
                                type: types.ERROR_MMR_ADDITIONAL_RATES,
                                planCode: reqBody[`${service.apiKeyName}PlanCodes`].length > 0 ? reqBody[`${service.apiKeyName}PlanCodes`][0] : '',
                                product: service.apiKeyName
                            })
                        } else {
                            dispatch({
                                type: types.SERVICE[service.typeName].ERROR_RENEWAL_RATES,
                                payload: formatPlanOrRateCallError(err, 'rate', true)
                            });
                            dispatch({
                                type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                                payload: true
                            });
                        }

                        if (isUhcRenewal) {
                            logError(KEY_NAME, UHC_REN_APIS.SPECIALITY_RATE, 'renewalShoppingActions.specialtyRatesBatch', reqBody, err);
                        }
                        reject();
                    });
            });

        }
        return null;
    };
}

export function getQuickQuoteRatesRenewal(plansObj, groupSize, zipCode,
    effectiveDate, stateCode, countyFIPSCode, sicCode,
    otherDetails = null, employeeCounts, employeeClasses, employerContribution, employees, adjustRates = false, companyNameReq = '') {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { medicalPlans } = plansObj;
        let { platform } = otherDetails;
        if (medicalPlans) {
            platform = medicalPlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
        }
        const isCirrus = platform === 'CIRRUS';
        const companyName = fullProps.companyProfile && fullProps.companyProfile.companyName && fullProps.companyProfile.companyName!== '' ? fullProps.companyProfile.companyName : companyNameReq!=='' ?companyNameReq : "Test"
        const staticRequestInfo = {
            groupSize,
            zipCode,
            effectiveDate,
            countyFIPSCode,
            stateCode,
            platform,
            sicCode,
            existingCoverage: otherDetails.existingCoverage,
            companyName,
        };

        if(isUhcRenewals()) {
            staticRequestInfo.eligibleCount = getEligibleCounts(fullProps, employeeCounts);
        }

        // From the list of plans we receive, we assemble a list of batches of
        // size <= 10, to send to the getRates API
        // Assemble our API request batches, passing in a list of medical codes
        // and other info for each plan

        const rateRequestsObj = {};

        // Assemble the rate requests to be sent to the API
        allNonASOServices.forEach((service) => {
            if (!plansObj[`${service.apiKeyName}Plans`]) {
                return;
            }
            const rateRequestArrays = [];
            const blRequestArrays = [];
            const combinedRequestArrays = [];
            const childBenefitAmount = [];
            const spouseBenefitAmount = [];
            let batchSize = plansObj[`${service.apiKeyName}Plans`].length;
            if (service.apiKeyName === 'medical') {
                if (!isCirrus) {
                    batchSize = 10;
                }
            } else {
                batchSize = 30;
            }
            for (let i = 0; i < plansObj[`${service.apiKeyName}Plans`].length; i += batchSize) {
                if (service.apiKeyName === 'life') {
                    rateRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.primePlanCode));
                    blRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.code));
                    combinedRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.code + "--" + plan.ADandDCode));
                    childBenefitAmount.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.childBenefitAmount != null ? plan.childBenefitAmount : ""));
                    spouseBenefitAmount.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.spouseBenefitAmount != null ? plan.spouseBenefitAmount : ""));
                } else {
                    rateRequestArrays.push(plansObj[`${service.apiKeyName}Plans`].slice(i, i + batchSize).map((plan) => plan.code));
                }
            }
            rateRequestsObj[service.typeName] = rateRequestArrays.map((arr, index) => ({
                ...staticRequestInfo,
                service,
                planCodes: arr,
                blPlanCodes: blRequestArrays.length > 0 ? blRequestArrays[index] : blRequestArrays,
                combinedCodes: combinedRequestArrays.length > 0 ? combinedRequestArrays[index] : combinedRequestArrays,
                childBenefitAmount: childBenefitAmount[index],
                spouseBenefitAmount: spouseBenefitAmount[index]
            }));
        });
        const filteredResultsOnly = !!plansObj.filteredResultsOnly
        // Call the API using those assembled requests
        allNonASOServices.forEach((service) => {
            if (rateRequestsObj[service.typeName] && rateRequestsObj[service.typeName].length > 0) {
                // We send out the first batch alone, because it is for the plans that
                // will appear at the top of the page
                dispatch(specialtyRatesBatch(rateRequestsObj[service.typeName][0],
                    isCirrus, 0, groupSize, filteredResultsOnly, otherDetails,
                    employeeCounts, employeeClasses, employerContribution, employees, adjustRates))
                    .then(() => {
                        for (let i = 1; i < rateRequestsObj[service.typeName].length; i++) {
                            // Subsequent batches are all sent out concurrently
                            dispatch(specialtyRatesBatch(rateRequestsObj[service.typeName][i],
                                isCirrus, i * 10, groupSize, filteredResultsOnly, otherDetails,
                                employeeCounts, employeeClasses, employerContribution, employees, adjustRates));
                        }
                    })
                    .catch((err) => {
                        if (isUhcRenewals()) {
                            logError(KEY_NAME, UHC_REN_APIS.CODE_BREAK, 'renewalShoppingActions.getQuickQuoteRatesRenewal', null, err);
                        }
                        console.log(err);
                    });
                // }
            }
        });
    };
}

export function shopSpecialityPlans(groupSize, zipObj, effectiveDate,
    otherDetails, employeeCounts, employeeClasses, employerContribution, employees, companyName = '') {
    return (dispatch, getState) => {
        const fullProps = getState();
        const renewalShoppingRequest = {
            groupSize,
            zipObj,
            effectiveDate,
            otherDetails,
            employeeCounts,
        };
        const includeSpecialty = otherDetails == null
            ? process.env.REACT_APP_SPECIALTY : otherDetails.includeSpecialty;
        const isPreferredPlans = otherDetails.isPreferredPlans == null ? 'true' : otherDetails.isPreferredPlans;
        const isUhcRenewal = isUhcRenewals()
        if (includeSpecialty) {
            if (otherDetails.selectedProducts.includes('dental')) {
                dispatch({
                    type: types.SERVICE.DENTAL.REQUEST_RENEWAL_PLANS,
                    payload: renewalShoppingRequest,
                    fullProps: fullProps,
                    source: 'RS',
                });
            }

            if (otherDetails.selectedProducts.includes('vision')) {
                dispatch({
                    type: types.SERVICE.VISION.REQUEST_RENEWAL_PLANS,
                    payload: renewalShoppingRequest,
                    fullProps: fullProps,
                    source: 'RS',
                });
            }

            if (otherDetails.selectedProducts.includes('life') || (!isUhcRenewal && !otherDetails.selectedProducts.includes('life') && otherDetails.existingCoverage && otherDetails.existingCoverage.includes('life'))) {
                dispatch({
                    type: types.SERVICE.LIFE.REQUEST_RENEWAL_PLANS,
                    payload: renewalShoppingRequest,
                    fullProps: fullProps,
                    source: 'RS',
                });
            }

            if (otherDetails.selectedProducts.includes('life_dep_basic')) {
                dispatch({
                    type: types.SERVICE.LIFE.REQUEST_RENEWAL_PLANS,
                    payload: renewalShoppingRequest,
                    fullProps: fullProps,
                    source: 'RS',
                });
            }
            // let totalEligible = isUhcRenewal ? getEligibleCounts(fullProps, employeeCounts) : groupSize;
             let totalEligible = isUhcRenewal ? employeeCounts.eligiblesCount:groupSize;
            axios.get(`${apiRoutes.quickQuotes}/specialty-plans?groupSize=${totalEligible}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=${isPreferredPlans}&specialtyTypes=${JSON.stringify(otherDetails.selectedProducts)}&platform=${otherDetails.platform}&busType=RL`)
                .then((res) => {
                    dispatch(createHistory('RENEWAL Specialty Plans', 'Renewal Shopping Page', 'Success', renewalShoppingRequest, res, 'RL'));
                    specialtyServices.forEach((service) => {
                        if (otherDetails.selectedProducts
                            .includes(service.typeName.toLowerCase())) {
                            if (res.data[`${service.apiKeyName}Plans`] && res.data[`${service.apiKeyName}Plans`].plans && res.data[`${service.apiKeyName}Plans`].plans.length > 0) {
                                dispatch({
                                    type: types.SERVICE[service.typeName].RECEIVE_RENEWAL_PLANS,
                                    payload: res.data[`${service.apiKeyName}Plans`],
                                    renewalShoppingRequest,
                                    fullProps: fullProps,
                                });
                                res.data[`${service.apiKeyName}Plans`] = res.data[`${service.apiKeyName}Plans`].plans;
                            } else if (isUhcRenewal) {
                                dispatch({
                                    type: types.SERVICE[service.typeName].SET_RENEWAL_PLANS_RECEIVED
                                });
                            }
                        } else if (isUhcRenewal) {
                            dispatch({
                                type: types.SERVICE[service.typeName].SET_RENEWAL_PLANS_RECEIVED
                            });
                        }
                    });

                    // Set quick quote request to get the specility rates
                    const state = getState();
                    const quickQuoteRequest = {
                        groupSize: totalEligible,
                        zipObj: zipObj,
                        effectiveDate: effectiveDate,
                        otherDetails: otherDetails,
                        source: fullProps.overall.source
                    };

                    dispatch(setQuickRequest(quickQuoteRequest));
                    dispatch(getQuickQuoteRatesRenewal(res.data, totalEligible, zipObj.zipCode,
                        effectiveDate, zipObj.stateCode, zipObj.countyFIPSCode,
                        otherDetails.sicCode, otherDetails, employeeCounts, employeeClasses,
                        employerContribution, employees, false, companyName));

                    dispatch(getAllQuickQuotePlans(totalEligible, zipObj, effectiveDate, otherDetails, 'renewals'));

                })
                .catch((err) => {
                    if (isUhcRenewals()) {
                        logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'renewalShoppingActions.shopSpecialityPlans', renewalShoppingRequest, err);
                    }
                    dispatch(createHistory('RENEWAL Specialty Plans', 'Renewal Shopping Page', 'Error', renewalShoppingRequest, err, 'RL'));
                    dispatch({
                        type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                        payload: err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Something Went Wrong in Fetching Plans,Please contact Administration',
                        source: 'RS',
                    });
                    dispatch({
                        type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                        payload: true
                    });
                });
        }
    };
}

export function shoppingUpdatePlan(plan, field, pkg, callback, serviceType = 'RS') {
    return (dispatch) => {
        sendLog({
            action: `TOGGLE PLAN ${field.toUpperCase()}`,
            planCode: plan.codeDisplay,
        });

        // If market type is UHC and plan is deselected and benefit rider was selected for that plan than restore the prev premium.
        if (getMarketType() === MARKET_TYPES.UHC) {
            dispatch(restorePremiumOnDeselect(plan, field, pkg));
        }

        dispatch({
            type: types.SERVICE[serviceType].UPDATE_PLAN,
            plan,
            field,
            pkg,
            isUhcRenewals: isUhcRenewals()
        });
        if (callback) {
            callback();
        }
    };
}
export function shoppingUpdateAllPlans(field, value, pkg, serviceType = 'RS') {
    return (dispatch) => {
        sendLog({
            action: 'TOGGLE ALL PLANS',
            field,
            value,
        });

        // If market type is UHC and plan is deselected and benefit rider was selected for that plan than restore the prev premium.
        if (getMarketType() === MARKET_TYPES.UHC) {
            dispatch(restorePremiumOnDeselect('', field, pkg));
        }

        dispatch({
            type: types.SERVICE[serviceType].UPDATE_ALL_PLANS,
            field,
            value,
            pkg,
        });
    };
}
export function shoppingUpdateAllPlansFromInside(field, value, pkg, serviceType = 'RS') {
    return (dispatch, getState) => {
        sendLog({
            action: 'TOGGLE ALL PLANS FROM INSIDE',
            field,
            value,
        });

        dispatch({
            type: types.SERVICE[serviceType].UPDATE_ALL_PLANS_FROM_INSIDE,
            field,
            value,
            pkg,
            fullProps: getState()
        });
    };
}

export function setFetchingAlternateRatingMethod(val) {
    return (dispatch, getState) => {
        dispatch({
            type: types.SET_FETCHING_ALTERNATE_RATING_METHOD,
            payload: val
        })
    }
}

export function shoppingChangeFilterCriteria(field, category, value, packageCode = null, serviceType = 'RS') {
    return (dispatch, getState) => {
        const state = getState();
        if(field === "alternateRatingMethod") {
            try {
                const plans = state.renewalShopping.plans;
                const situsState = state?.companyProfile?.locations[0]?.zipData?.stateCode || ""
                dispatch({
                    type: types.SET_FETCHING_ALTERNATE_RATING_METHOD,
                    payload: true
                })
                if(situsState==="CA")
                {
                    dispatch(doMedicalRateCall(
                        {
                            medicalPlans: plans.map((plan) => {
                                return { code: plan.code, packages: plan.packages, platform: plan.platform};
                            }),
                            getAlternateRatingMethod: true,
                            allMedicalPlans : plans
                        }
                    ));
                }
                else
                {
                    dispatch(doMedicalRateCall(
                        {
                            medicalPlans: plans.map((plan) => {
                                return { code: plan.code, packages: plan.packages };
                            }),
                            getAlternateRatingMethod: true,
                            allMedicalPlans : plans
                        }
                    ));
                }
                
                return;
            } catch(err) {
                console.log(err);
            }
        }

        sendLog({
            action: 'CHANGE PLAN CRITERIA',
            field,
            category,
            value,
        });

        dispatch({
            type: types.SERVICE[serviceType].CHANGE_FILTER_CRITERIA,
            field,
            category,
            value,
            packageCode,
            isUhcRenewals: isUhcRenewals()
        });

        //update enrolled plans rates
        if (category === 'ratingMethod') {
            dispatch({
                type: types.CHANGE_ENROLLED_RATING_METHOD,
                ratingMethod: field,
                isUhcRenewals: isUhcRenewals()
            })

            const { numAltRatesFetched } = state.renewalShopping;

            if(numAltRatesFetched && numAltRatesFetched > 0) {
                dispatch({
                    type: types.CHANGE_ENROLLED_RATING_METHOD_REN_SHOP,
                    field,
                })
            }
        }

        const { specialtyCriteria, companyProfile } = state;
        const { renewalShoppingRequest } = state.renewalShopping;
        const quoteStatus = "Shopping";

        if ((!renewalShoppingRequest.otherDetails.selectedProducts)
            || (renewalShoppingRequest.otherDetails.selectedProducts.length === 1
                && renewalShoppingRequest.otherDetails.selectedProducts[0] === 'medical')) {
            return; // no need to do subsequent API calls for just medical
        }

        let employeeCounts = '';
        if (serviceType === 'DENTAL') {
            employeeCounts = [specialtyCriteria.dentalCensusSummary.employeeOnly,
            specialtyCriteria.dentalCensusSummary.employeeWithSpouse,
            specialtyCriteria.dentalCensusSummary.employeeWithChild,
            specialtyCriteria.dentalCensusSummary.employeeWithFamily];
        } else if (serviceType === 'VISION') {
            employeeCounts = [specialtyCriteria.visionCensusSummary.employeeOnly,
            specialtyCriteria.visionCensusSummary.employeeWithSpouse,
            specialtyCriteria.visionCensusSummary.employeeWithChild,
            specialtyCriteria.visionCensusSummary.employeeWithFamily];
        } else {
            employeeCounts = [specialtyCriteria.employeeOnly, specialtyCriteria.employeeWithSpouse,
            specialtyCriteria.employeeWithChild, specialtyCriteria.employeeWithFamily];
        }

        if (value !== null) {
            dispatch(sendClickEventDataAction({
                clickName : `Basic Filter : ${category !== null ? `${category} : ` : ''}${field} : ${value} `,
                coverageType : serviceType ==='DENTAL' || serviceType ==='VISION'?serviceType:'MEDICAL',
                clickElement : 'Filter',
                clickType : 'internal',
            }))
            const planCodes = [];
            planCodes.push(value);

            const identifiedPlatform = identifiedPlatform(state.renewalShoppingHelper.renewalData);
            const platform = identifiedPlatform === 'PRIME' ? 'PRIME' : ''
            let memberGroupId = state.renewalShoppingHelper.renewalData && state.renewalShoppingHelper.renewalData.memberGroupId ? state.renewalShoppingHelper.renewalData.memberGroupId : null
            let companyName = companyProfile && companyProfile.companyName ? companyProfile.companyName : null

            axios.get(`${apiRoutes.quickQuotes}/rates?
                groupSize=${renewalShoppingRequest.groupSize}
                &zipCode=${renewalShoppingRequest.zipObj.zipCode}
                &stateCode=${renewalShoppingRequest.zipObj.stateCode}
                &countyCode=${renewalShoppingRequest.zipObj.stateFIPSCode}
                &effectiveDate=${renewalShoppingRequest.effectiveDate}
                &platform=${platform}&${serviceType.toLowerCase()}PlanCodes=+${planCodes}+
                &employeeCounts=${employeeCounts}
                &sicCode=${renewalShoppingRequest.otherDetails.sicCode}
                &quoteStatus=${quoteStatus}
                &memberGroupId=${memberGroupId}
                &companyName=${companyName}
                &caseId=${memberGroupId}`
            )
                .then((res) => {
                    const rates = res.data[`${serviceType.toLowerCase()}Rates`];
                    let quotes;
                    if (rates) {
                        quotes = rates.map((rate) => rate.byCensus.totalMonthlyPremium);
                        dispatch({
                            type: types.SERVICE[serviceType].RECEIVE_RENEWAL_PLAN_RATES,
                            rates,
                            quotes,
                        });
                    }
                    dispatch(createHistory('RENEWAL Plan Rates', 'Renewal Shopping page', 'Success', renewalShoppingRequest, res.data, 'RL'));
                })
                .catch((err) => {
                    dispatch(createHistory('RENEWAL Plan Rates', 'Renewal Shopping page', 'Error', renewalShoppingRequest, err, 'RL'));
                    dispatch({
                        type: types.SERVICE.RENEWAL_SHOPPING.ERROR_RENEWAL_RATES,
                        payload: formatPlanOrRateCallError(err, 'rate', false),
                        source: 'RS',
                    });
                });
        }
    };
}

export function resetFilterCriteria(serviceType = 'RS') {
    return (dispatch) => {
        sendLog({
            action: 'RESET FILTER CRITERIA',
        });

        dispatch({
            type: types.SERVICE[serviceType].RESET_FILTER_CRITERIA,
        });
    };
}
export function setShoppingRateBand(val) {
    return (dispatch) => {
        sendLog({
            action: 'SET SHOPPING RATE BAND',
        });

        dispatch({
            type: types.SET_SHOPPING_RATE_BAND,
            payload: val,
        });
    };
}

export function updateReviewShopPlans(plansets) {
    return dispatch => {
        sendLog({
            action: `UPDATE_REVIEW_SHOP_PLANS`,
            plansets
        });
        dispatch({
            type: types.UPDATE_REVIEW_SHOP_PLANS,
            payload: plansets
        });
    }
}

export function resetSpecialtyRenewalsFlow() {
    return dispatch => {
        sendLog({
            action: "RESET RENEWALFLOW",
        })
        dispatch({
            type: types.RESET_RENEWAL_FLOW,
        })
    }
}

export function setDisplayOfPreferredPlans(value) {
    return dispatch => {
        sendLog({
            action: "SET_PREFERRED_PLANS_DISPLAY",
            value
        })
        dispatch({
            type: types.SET_PREFERRED_PLANS_DISPLAY,
            payload: value
        })
    }
}

export function setDisplayOfPreferredPlansToggle(value) {
    return dispatch => {
        sendLog({
            action: "SET_PREFERRED_PLANS_DISPLAY_TO_DEFAULT",
            value
        })
        dispatch({
            type: types.SET_PREFERRED_PLANS_DISPLAY_TO_DEFAULT,
            payload: value
        })
    }
}

export function updateRenewalMedicalAllowed(value) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWAL_MEDICAL_ALLOWED,
            status: value
        });
    };
}

export function updateRenewalPackageLabel(packageLabel) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWAL_PACKAGE_LABEL,
            payload: packageLabel
        })
    }
}

/** Recover medical plan/plans premium on deselection of plan (Required if plan includes premium of selected benefit rider) 

*/
function restorePremiumOnDeselect(plan, field, pkg) {
    return (dispatch, getState) => {
        const state = getState();
        const checkCond = (selectedBenefitRiders) => {
            return selectedBenefitRiders.findIndex(rider => rider !== 'RAP' && rider !== 'SPO' && rider !== 'SEO') > -1;
        }
        const { situsState } = state && state.renewalShopping && state.renewalShopping.renewalCaseData ? state.renewalShopping.renewalCaseData : state.review.renewalCaseData;
        if (plan !== '') {
            let isDeselected = false;
            if (field === 'selected' && state.renewalShopping.selectedPlans) {
                isDeselected = state.renewalShopping.selectedPlans
                    .findIndex((selectedPlan) => selectedPlan.code === plan.code && selectedPlan.selectedPackages.includes(pkg)) !== -1 ? true : false;
            }
            // If plan is deselected
            if (isDeselected) {
                // If plan has selected benefit riders then bring the originial rates back for that plan and modify the rates as well as selected benefit riders field
                if (plan.selectedBenefitRiders && plan.selectedBenefitRiders.length > 0 && checkCond(plan.selectedBenefitRiders)) {
                    let planCodes = [];
                    planCodes.push(plan.code);
                    let isCirrus = plan.platform === 'CIRRUS' ? true : false;
                    let OISABit = {};
                    if(isUhcRenewals() && situsState && ['MD','WV','VA','DE'].includes(situsState) && !isCirrus && plan.OISABit){
                        OISABit = plan.OISABit;
                    }
                    dispatch(getPlansOldRatesRenewals(planCodes, OISABit));
                }
            }
        } else {
            // Deselect All is clicked
            let visiblePlans = state.renewalShopping.visiblePlans;
            let planCodes = []; 
            let allBenefitRiderPlans = [];
            for (let i = 0; i < visiblePlans.length; i++) {
                if (visiblePlans[i].selectedBenefitRiders && visiblePlans[i].selectedBenefitRiders.length > 0 && checkCond(visiblePlans[i].selectedBenefitRiders)) {
                    planCodes.push(visiblePlans[i].code);
                    allBenefitRiderPlans.push(visiblePlans[i]);
                }
            }
            if (planCodes.length > 0) {
                const batchSize = 10;
                let batches = [];
                let platform = visiblePlans[0].platform === 'CIRRUS' ? 'CIRRUS' : 'PRIME';
                const isCirrus = platform === 'CIRRUS';
                const batchOISABit = [];
                if(isUhcRenewals() && situsState && ['MD','WV','VA','DE'].includes(situsState) && !isCirrus){
                    const renewalData = state.renewalShopping.renewalData ? state.renewalShopping.renewalData : state.review.renewalData;
                    let IBROISABit = renewalData.memberGroup && renewalData.memberGroup.locations && renewalData.memberGroup.locations.location && renewalData.memberGroup.locations.location.OISABit && renewalData.memberGroup.locations.location.OISABit[0] ? renewalData.memberGroup.locations.location.OISABit[0] : {};
                    allBenefitRiderPlans.forEach(x=>{
                        if(!x.OISABit || Object.keys(x.OISABit).length === 0){
                            x.OISABit = IBROISABit;
                        }
                    });

                    let medicalPlansObj = {
                        quotes: {
                            plans: allBenefitRiderPlans
                        },
                    }
                    batches = getPlanCodeBatches(medicalPlansObj,planCodes,batchOISABit);
                }else{
                    for (let i = 0; i < planCodes.length; i += batchSize) {
                        batches.push(planCodes.slice(i, i + batchSize));
                    }
                }
                
                let OISABit = batchOISABit && batchOISABit[0] ? batchOISABit[0] : {}
                dispatch(getPlansOldRatesRenewals(batches[0], OISABit ))
                    .then(() => {
                        for (let i = 1; i < batches.length; i++) {
                            OISABit = batchOISABit && batchOISABit[i] ? batchOISABit[i] : {}
                            dispatch(getPlansOldRatesRenewals(batches[i], OISABit));
                        }
                    })
            }
        }

    }
}

export function updateRenewalShoppingEligibilityCounts(eligibilityCounts, enableMedical) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_RENEWAL_SHOPPING_ELIGIBILITY_COUNTS,
            eligibilityCounts,
            enableMedical
        })
    }
}
