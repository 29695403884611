import merge from 'lodash/merge';
import deepCopy from "../deepCopy";
export const combineStateStoreWithInitialState = (stateStore, initialState) => {
    // Rename the key from dependentLife to lifeDep as redux store takes lifeDep for the reducer
    stateStore['lifeDep'] = stateStore['dependentLife'];
    delete stateStore['dependentLife'];

    const { caseTracking } = stateStore;

    // Preset states for caseTracking
    const updatedCaseTracking = {
        ...caseTracking,
        isCopyQuote: false,
        isNewCase: false,
        isNewQuote: false,
        isRemoveQuote: false,
        isWithdrawCase: false,
        isCloseCase: false,
        enrollingQuoteId: initialState.caseTracking.enrollingQuoteId
    }

    stateStore['caseTracking'] = updatedCaseTracking;

    const combinedStore = {};

    // combine initial states and stateStore
    Object.keys(initialState).forEach(state => {
        if (stateStore.hasOwnProperty(state)) {
            combinedStore[state] = merge(deepCopy(initialState[state]),deepCopy(stateStore[state]))
        } else {
            combinedStore[state] = {
                ...initialState[state]
            }
        }
    })

    return combinedStore;
}