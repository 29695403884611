export const getOrUpdateValue = (dataObj, fieldPath, fieldValue = undefined, actionType = 'None') => {
    let pathArr = fieldPath.split(".");
    let i = 0;
    while(i < pathArr.length - 1)
        dataObj = dataObj[pathArr[i++]];
    if(fieldValue !== undefined) {
        if(Array.isArray(dataObj)) {
            switch(actionType) {
                case 'Add':
                    if(!dataObj.includes(fieldValue)) {
                        dataObj.push(fieldValue)
                    }
                    break
                case 'Remove':
                    dataObj = dataObj.filter(value => value !== fieldValue)
                    break
                case 'None':
                    console.log(fieldPath, 'No action type specified')
                    break;
            }
        } else {
            dataObj[pathArr[i]] = fieldValue
        }
    } else
        return dataObj[pathArr[i]];
}

export const updateSelectedProducts = (employees, selectedProducts) => {
    employees.forEach(employee => {
        employee.productSelection.forEach((products, productSelectionIndex) => {
            Object.keys(products).forEach(product => {
                if(!selectedProducts.includes(product)) {
                    delete employee.productSelection[productSelectionIndex][product]
                } else if(products[product] === '') {
                    employee.productSelection[productSelectionIndex][product] = 'select'
                }
            })
        })
    })
}