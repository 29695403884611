import {
    INITIALIZE_EMPLOYER_CERTIFICATION_INFO, REFRESH_EMPLOYER_CERTIFICATION_INFO,
    COMPLETED_EMPLOYER_CERTIFICATION, RESET_TO_INITIALSTATE, UPDATE_EMPLOYER_CERTIFICATION,
    UPDATE_EMPLOYER_CERTIFICATION_BULK_ERR
} from "../../../actions/actionTypes";
import deepCopy from "../../../utils/deepCopy";
import { initializeEnrollmentPageState } from "./helper";
import { getRelevantAppState, makeAppStateNested } from "../../../components/shared/ConfigurableForms/helpers/getRelevantAppState";
import { checkIfObjectMeetsConditions } from "../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions";
import {  checkIfRequiredTabelCompleted, checkIfRequiredTableSectionAttestationCompleted, checkIfRequiredAvgNumberEmployeesCompleted } from "../../../components/shared/ConfigurableForms/helpers/conditionCheckers";

const initialState = {
    attestation: {},
    certification: {},
    censusInformation: {},
    errors: {}
};

export default function employerCertificationReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_EMPLOYER_CERTIFICATION_INFO:
            const initializedState = initializeEnrollmentPageState(action.payload, action.conditionEvaluators, action.employerCertification);
            return {
                ...state,
                ...initializedState,
                errors:{
                    ...state.errors,
                    ...initializedState
                }
            }
        case REFRESH_EMPLOYER_CERTIFICATION_INFO:
            const newState = action.payload;
            return {
                ...state,
                ...newState,
            }

        case UPDATE_EMPLOYER_CERTIFICATION:
            {
                let { field, value, group, isError } = action;
                const update = {
                    [field]: value
                };

                // Will be removed later
                // if (isError) {
                //     const resolvedErrorGroup = state.errors[group] || {};

                //     return {
                //         ...state,
                //         errors: {
                //             ...state.errors,
                //             [group]: {
                //                 ...resolvedErrorGroup,
                //                 ...update,
                //             },
                //         },
                //     };
                // }


                const groupInfo = group.split('.');
                if (groupInfo.length > 1) {
                    const firstGroupName = groupInfo[0];

                    let firstGroupObject = isError ? deepCopy(state.errors) : deepCopy(state);
                    const updatedGroupObject = updateValueByKey(firstGroupObject, group, field, value);
                    if(isError){
                        return {
                            ...state,
                            errors: {
                                ...state.errors,
                                [firstGroupName]: {
                                     ...updatedGroupObject[firstGroupName] 
                                },
                            }
                            
                        };
                    }else{
                        return {
                            ...state,
                            [firstGroupName]: { ...updatedGroupObject[firstGroupName] },
                        };
                    }
                    
                } else {
                    const resolvedGroup = isError ? state.errors[group] : state[group] || {};

                    const fieldObjectValue = resolvedGroup && resolvedGroup[field];
                    if (value && (value.toString()).toLowerCase() === "removerow" && Array.isArray(fieldObjectValue)) {
                        update[field] = fieldObjectValue.slice(0, fieldObjectValue.length - 1);
                    }
                    if(isError){
                        return{
                            ...state,
                            errors: {
                                ...state.errors,
                                [group]: {
                                    ...resolvedGroup,
                                    ...update,
                                },
                            }
                        }
                    }else{
                        return {
                            ...state,
                            [group]: {
                                ...resolvedGroup,
                                ...update,
                            },
                        }
                    }
                }
            }
        case UPDATE_EMPLOYER_CERTIFICATION_BULK_ERR: {
            return {
                ...state,
                errors:{
                    ...state.errors,
                    certification:{
                        ...state.errors.certification,
                        ...action.obj
                    }
                }
            }
        }
        case COMPLETED_EMPLOYER_CERTIFICATION: {
            const {
                application, enrollmentForm, conditionEvaluators, appStateProps,
            } = action;
            let completed = true;
            const { employerCertification } = enrollmentForm;
            employerCertification && employerCertification.every((rulesSection) => {
                const { fields } = rulesSection;
                fields && fields.every((ruleField) => {
                    if(ruleField.fieldName === 'workLocations'){
                        const {tableOptions} = ruleField
                        const {columns} = tableOptions
                        const isColCompleted = checkIfRequiredTabelCompleted(columns, rulesSection, application.employerCertification, false)
                        completed = completed && isColCompleted
                    }
                    else if(ruleField.fieldName === 'censusDetails'){
                        const {tableOptions} = ruleField
                        const {columns} = tableOptions
                        const isColCompleted = checkIfRequiredTabelCompleted(columns, rulesSection, application.employerCertification, true)
                        completed = completed && isColCompleted
                    }
                    else if(ruleField.fieldName === 'certifySEStateNJSection'){
                        const {sectionOptions} = ruleField
                        const isColCompleted = checkIfRequiredTableSectionAttestationCompleted(sectionOptions, rulesSection, application.employerCertification, ruleField.sectionOptions)
                        completed = completed && isColCompleted
                    }
                    else if(ruleField.fieldName === 'avgNumberOfEmployeesSection'){
                        const {sectionOptions} = ruleField
                        const {tableOptions} = sectionOptions.fields[0]
                        const thirdFieldName = sectionOptions.fields[0]
                        const {columns} = tableOptions
                        const isColCompleted = checkIfRequiredAvgNumberEmployeesCompleted(columns, rulesSection, application.employerCertification, ruleField.sectionOptions, thirdFieldName)
                        completed = completed && isColCompleted
                    }
                    else
                    if (ruleField.isRequired) {
                        if (ruleField.showIf) {
                            let appState = getRelevantAppState(application, 'employerCertification', rulesSection.sectionName, ruleField);
                            appState = makeAppStateNested(appState);
                            const shouldShow = checkIfObjectMeetsConditions(
                                rulesSection.sectionName,
                                ruleField.showIf, {
                                ...conditionEvaluators,
                                appState,
                            },
                            );
                            if (ruleField.fieldType !== 'label' && application.employerCertification[rulesSection.sectionName][ruleField.fieldName] === '' && shouldShow === true) {
                                completed = false;
                            }
                            completed = completed && true;
                        } else if (application.employerCertification[rulesSection.sectionName][ruleField.fieldName] === '') {
                            completed = false;
                        } else {
                            completed = completed && true;
                        }
                    }
                    return true;
                });
                return true;
            });
            // }
            return {
                ...state,
                completed,
                formSaved: true
            };
        }
        case RESET_TO_INITIALSTATE:
            return initialState

        default:
            return state;
    }

    function updateValueByKey(groupObject, fieldObjectPath, field, value) {
        let fieldObjectValue = {};

        const fields = fieldObjectPath.split('.');
        const currentFieldName = fields[0];

        fieldObjectValue = groupObject[currentFieldName];

        if (groupObject.hasOwnProperty(currentFieldName) && fields.length > 1) {
            fieldObjectValue = updateValueByKey(fieldObjectValue, fields.slice(1).join('.'), field, value);
        }

        if (groupObject.hasOwnProperty(currentFieldName) && fields.length == 1) {
            if (Array.isArray(fieldObjectValue)) {
                const fieldInfo = field.split('.');
                const rowIndex = fieldInfo[0] - 1;

                const filedName = fieldInfo[1];

                fieldObjectValue[rowIndex] = {
                    ...fieldObjectValue[rowIndex],
                    ... {
                        [filedName]: value
                    }
                };

                //  Below code block is specific to the 'NJ State' AvgNumberOfEmployees table 'avg' column 
                if (currentFieldName.toLowerCase() === 'avgnumberofemployees') {
                    const currentAVGNoofEmployees = fieldObjectValue[rowIndex];

                    let total = 0;

                    const columnToIgnore = ['total', 'avg', 'month'];
                    Object.keys(currentAVGNoofEmployees).forEach(column => {
                        if (!columnToIgnore.includes(column)) {
                            if (!isNaN(+currentAVGNoofEmployees[column])) {
                                total += +currentAVGNoofEmployees[column];
                            }
                        }
                    });

                    fieldObjectValue[rowIndex] = {
                        ...fieldObjectValue[rowIndex],
                        ... {
                            'total': total > 0 ? total : '',
                            'avg': total > 0 ? (total / 12).toFixed(2) : ''
                        }
                    };
                }
            } else {
                fieldObjectValue = {
                    ...fieldObjectValue,
                    ... {
                        [field]: value
                    }
                };
            }
        }

        return {
            ...groupObject,
            [currentFieldName]: fieldObjectValue
        }
    }
}