// Mongodb Key
export const KEY_NAME = 'logKey';

// Code Break
export const UHC_OXFORD_CODE_BREAK = 'UHC_OXFORD_CODE_BREAK';

// Benefit Rider
export const UHC_BENEFIT_RIDER_RATE_ERROR = 'UHC_BENEFIT_RIDER_RATE_ERROR';
export const UHC_BENEFIT_RIDER_SELECTED_RATE_ERROR = 'UHC_BENEFIT_RIDER_SELECTED_RATE_ERROR';

// Plan and Rate call
export const UHC_OXFORD_MEDICAL_PLAN_ERROR = 'UHC_OXFORD_MEDICAL_PLAN_ERROR';
export const UHC_OXFORD_MEDICAL_RATE_ERROR = 'UHC_OXFORD_MEDICAL_RATE_ERROR';
export const UHC_OXFORD_SPECIALTY_PLAN_ERROR = 'UHC_OXFORD_SPECIALTY_PLAN_ERROR';
export const UHC_OXFORD_SPECIALTY_RATE_ERROR = 'UHC_OXFORD_SPECIALTY_RATE_ERROR';

// Quote Review
export const UHC_EXISTING_TIN_PRIME_ERROR = 'UHC_EXISTING_TIN_PRIME_ERROR';
export const UHC_EXISTING_TIN_CIRRUS_ERROR = 'UHC_EXISTING_TIN_CIRRUS_ERROR';

// Export HRF Group and Member XML
export const UHC_OXFORD_GENERATE_HRF_ERROR = 'UHC_OXFORD_GENERATE_HRF_ERROR';
export const UHC_OXFORD_GENERATE_GROUP_XML_ERROR = 'UHC_OXFORD_GENERATE_GROUP_XML_ERROR';
export const UHC_OXFORD_GENERATE_MEMBER_XML_ERROR = 'UHC_OXFORD_GENERATE_MEMBER_XML_ERROR';

//UHC Ren APIS
export const UHC_REN_APIS = {
    CODE_BREAK: 'CODE_BREAK',
    GET_REN_COMPANY_PROFILES: 'REN_COMPANY_PROFILES',
    ATNE_RULES: 'ATNE_RULES',
    DEPENDENT_RULES: 'DEPENDENT_RULES',
    PROFILES: 'PROFILES',
    GET_ZIP_CONFIG: 'GET_ZIP_CONFIG',
    ZIP_MAPPINGS: 'ZIP_MAPPINGS',
    SPECIALTY_PLAN: 'SPECIALTY_PLAN',
    MEDICAL_PLAN_CHECK: 'MEDICAL_PLAN_CHECK',
    SPECIALITY_RATE: 'SPECIALITY_RATE',
    BASIC_LIFE_RATES: 'BASIC_LIFE_RATES',
    START_RATES: 'START_RATES',
    RENEWAL_REVIEW: 'RENEWAL_REVIEW',
    DECIDE_PAGE_DATA: 'DECIDE_PAGE_DATA',
    RENEWAL_UPDATE: 'RENEWAL_UPDATE',
    CURRENT_CASE_STATUS: 'CURRENT_CASE_STATUS',
    CASE_STATUS_CHANGE: 'CASE_STATUS_CHANGE',
    VALIDATE_QUOTE: 'VALIDATE_QUOTE',
    CASE_TRACK: 'CASE_TRACK',
    GET_LATEST_CASE_DATA: 'GET_LATEST_CASE_DATA',
    ENROLLMENT_FORM: 'ENROLLMENT_FORM',
    SHOPPING_RULES: 'SHOPPING_RULES',
    RATE_ADJUSTMENT_RULES: 'RATE_ADJUSTMENT_RULES',
    IPPS: 'IPPS',
    IPPS_REPORTING: 'IPPS_REPORTING',
    SAVE_RATES: 'SAVE_RATES',
    ENROLL_EMPLOYEE: 'ENROLL_EMPLOYEE',
    GROUP_ENROLLMENT_DETAILS: 'GROUP_ENROLLMENT_DETAILS',
    CENSUS_REFRESH: 'CENSUS_REFRESH',
    GROUP_REFRESH: 'GROUP_REFRESH',
    CONTRACT_OPTIONS: 'CONTRACT_OPTIONS',
    STANDARD_HRA: 'STANDARD_HRA',
    PARTICIPATION_RULES_DATA: 'PARTICIPATION_RULES_DATA',
    DENTAL_PLAN: 'DENTAL_PLAN',
    BASIC_LIFE_PLAN: 'BASIC_LIFE_PLAN',
    ADD_TRAN_TYPE: 'ADD_TRAN_TYPE',
    PRIME_HW_MEMBER_INSTALL: 'PRIME_HW_MEMBER_INSTALL',
    PRIME_HW_GROUP_INSTALL: 'PRIME_HW_GROUP_INSTALL',
    PRIME_LW_ALT_IDS: 'PRIME_LW_ALT_IDS',
    PRIME_LW_POLICY_NUMBERS: 'PRIME_LW_POLICY_NUMBERS',
    PRIME_INSTALLTION_PAYLOADS: 'PRIME_INSTALLTION_PAYLOADS',
    PRIME_FINAL_RATE_INSTALL:'PRIME_FINAL_RATE_INSTALL',
    ADD_UHC_RENEWAL_LOGS: 'ADD_UHC_RENEWAL_LOGS',
    SAVE_PAYLOAD_RENEWALS: 'SAVE_PAYLOAD_RENEWALS',
    LW_LOGS: 'LW_LOGS',
    HIOS_API: 'HIOS_API',
    CIRRUS_MEMBER_INSTALL: 'CIRRUS_MEMBER_INSTALL',
    CIRRUS_CANCELLATION: 'CIRRUS_CANCELLATION',
    SEND_RENEWAL_TERM_DATA: 'SEND_RENEWAL_TERM_DATA',
    RENEWAL_DATA_MMR: 'RENEWAL_DATA_MMR',
    GET_IBR: 'GET_IBR',
    GET_RENEWAL_FINALIZE_CASE_SUMMARY: 'GET_RENEWAL_FINALIZE_CASE_SUMMARY',
    SEND_QUOTE_TO_SOLARIS: 'SEND_QUOTE_TO_SOLARIS',
    EFFECTIVE_DATE_RULES: 'EFFECTIVE_DATE_RULES',
    SEND_CIRRUS_CANCELLATION: 'SEND_CIRRUS_CANCELLATION',
    PRIME_GROUP_TERM: 'PRIME_GROUP_TERM',
    PROPOSAL: 'PROPOSAL',
    RENEWAL_PACKAGE_DOC: 'RENEWAL_PACKAGE_DOC',
    GET_ACTION_HISTORY: 'GET_ACTION_HISTORY',
    GET_AUDIT_HISTORY: 'GET_AUDIT_HISTORY',
    SET_RENEWAL_CHANGE_TYPE: 'SET_RENEWAL_CHANGE_TYPE',
    EMAIL_MEMBER_ONLY_GROUP_MANUAL: 'EMAIL_MEMBER_ONLY_GROUP_MANUAL',
    IS_MEMBER_ONLY_CIRRUS: 'IS_MEMBER_ONLY_CIRRUS',
    HAS_MEMBER_CHANGE_CIRRUS: 'HAS_MEMBER_CHANGE_CIRRUS',
    EMAIL_HRA_GROUP_MANUAL:'EMAIL_HRA_GROUP_MANUAL'
}

export const UHC_REN_TECH_EMAIL_SUBJECT = 'UHC Renewals Case Error';

export const UHC_OXFORD_CODE_BREAK_SUBJECT = 'SAMx UI Code Break';