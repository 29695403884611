import { toMMDDYYYYFormatSlash } from "../formatters/dates";

/**
 * Add guranteedPeriod(platform CIRRUS)/guranteedPeriodUhc(platform PRIME) in section participation and
 * contribution -> medicalEmployerContribution for validation
 * 
 * for PRIME 
    "guaranteedPeriodUhc": {
        "enable": true/false,
        "startDate": "MM/DD/YYYY",
        "endDate": "MM/DD/YYYY"
    }

    for CIRRUS
    "guaranteedPeriod": {
        "enable": true/false,
        "effectiveDate": [eff1, eff2,...],
        "startDate": "MM/DD/YYYY",
        "endDate": "MM/DD/YYYY"
    }
*/

export const isGuaranteedRule = (guaranteedPeriod, effectiveDate, platform, appConfigGuaranteedPeriod) => {
    if (appConfigGuaranteedPeriod.enable === true){
        const effectDate = toMMDDYYYYFormatSlash(effectiveDate);
        for (const giEffectDate of appConfigGuaranteedPeriod.effectiveDate){
            if (effectDate === giEffectDate){
                return true;
            }
        }
    }

    if (platform === 'PRIME') {
        let isGuaranteedPeriod = false;
        if (guaranteedPeriod) {
            if (guaranteedPeriod.enable) {
                const effDate = toMMDDYYYYFormatSlash(effectiveDate);
                if (new Date(effDate) >= new Date(guaranteedPeriod.startDate) && new Date(effDate) <= new Date(guaranteedPeriod.endDate)) {
                    isGuaranteedPeriod = true;
                }
            }
        }

        return isGuaranteedPeriod
    } else {
        let isGuaranteedPeriod = false;
        if (guaranteedPeriod) {
            if (guaranteedPeriod.enable) {
                const effDate = toMMDDYYYYFormatSlash(effectiveDate);
                for (const giEffDate of guaranteedPeriod.effectiveDate) {
                    if (effDate === giEffDate) {
                        const currentDate = toMMDDYYYYFormatSlash(new Date().toISOString());
                        if (new Date(currentDate) >= new Date(guaranteedPeriod.startDate) && new Date(currentDate) <= new Date(guaranteedPeriod.endDate)) {
                            isGuaranteedPeriod = true;
                            break;
                        }
                    }
                }
            }
        }

        return isGuaranteedPeriod
    }
}