import {
    INITIALIZE_STANDARD_HRA, RESET_TO_INITIALSTATE, UPDATE_STANDARD_HRA, POPULATE_STANDARD_HRA
} from '../../../actions/actionTypes';
import { initializeEnrollmentPageState } from './helper';

const initialState = {
    errors: {},
    formSaved: false,
    clientDemographicInformation: {
        city: "",
        companyName: "",
        policyNumber: "",
        requestedEffectiveDate: "",
        state: "",
        streetAddress: "",
        zipCode: ""
    },
    employerHRABenefitContactInformation: {
        city: "",
        employerHRABenefitContactInformation: "",
        hraFundingAndBenefitsContact: "",
        standardHRAEmailAddress: "",
        standardHRAFax: "",
        standardHRAFirstName: "",
        standardHRALastName: "",
        standardHRATelephone: "",
        state: "",
        streetAddress: "",
        streetAddressln2: "",
        zipCode: "",
    },
    hraBankingInformation: {
        bankAccountNumber: "",
        bankName: "",
        bankTelephone: "",
        city: "",
        state: "",
        streetAddress: "",
        streetAddressln2: "",
        transitNumber: "",
        zipCode: ""
    },
    hraPlanInformation: {
        enrollmentAdditions: "",
        familyDeductibleAmount: "",
        familyHRA: "",
        individualDeductibleAmount: "",
        individualHRA: "",
        unusedHRAFunds: ""
    }
};

export default function StandardHRAReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_STANDARD_HRA: {
            const initializedState = initializeEnrollmentPageState(
                action.payload, action.conditionEvaluators, action.companyProfile,
            );

            return {
                ...state,
                ...initializedState,
            };
        }

        case UPDATE_STANDARD_HRA: {
            const { update, group, isError } = action;

            if (isError) {
                const resolvedErrorGroup = state.errors[group] || {};
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [group]: {
                            ...resolvedErrorGroup,
                            ...update,
                        },
                    },
                };
            }

            const resolvedGroup = state[group] || {};
            return {
                ...state,
                [group]: {
                    ...resolvedGroup,
                    ...update,
                },
            };
        }

        case POPULATE_STANDARD_HRA:
            const populatedStateFromDB = action.payload;
            return {
                ...state,
                 ...populatedStateFromDB,
        };

        case RESET_TO_INITIALSTATE:
            return initialState;

        default:
            return state;
    }
}
