

export const  getOrUpdateValue = (dataObj, fieldPath, fieldValue = undefined) => {
    let pathArr = fieldPath.split(".");
    let i = 0;
    while(i < pathArr.length - 1)
        dataObj = dataObj[pathArr[i++]];
    if(fieldValue !== undefined) {
        if(Array.isArray(dataObj)) {
            dataObj.push(fieldValue)
        } else {
            dataObj[pathArr[i]] = fieldValue
        }
    } else
        return dataObj[pathArr[i]];
}

export const  updateArgs = (dataObj, args) => {
    if(Array.isArray(args)){
        args.forEach((arg, index) => {
            let key = Object.keys(arg)[0]
            let value = getOrUpdateValue(dataObj, arg[key]);
            args[index][key] = value
        });
    }
    else{
        Object.keys(args).forEach(key => {
            args[key] = getOrUpdateValue(dataObj, args[key]);
        })
    }
}

export const deleteValueFromPath = (dataObj, fieldPath) => {
    let pathArr = fieldPath.split(".");
    let i = 0;
    while(i < pathArr.length - 1)
        dataObj = dataObj[pathArr[i++]];
    if(Array.isArray(dataObj) && dataObj[pathArr[i]]) {
        dataObj.splice(parseInt(pathArr[i]), 1)
    } else {
        delete dataObj[pathArr[i]]
    }
}

function generateRelationshipTypes(relationshipTypes, relationship = []) {
    relationship.forEach(type => {
        if(!relationshipTypes.includes(type)) {
            relationshipTypes.push(type)
        }
    })
}

function getRelationshipTypes(coverages) {
    let relationshipTypes = [];
    Object.keys(coverages).forEach(coverageKey => {
        let relationship
        switch(coverages[coverageKey]) {
            case 'EE/SP':
                relationship = ['Spouse', 'Domestic Partner', 'Civil Union Partner'];
                break;
            case 'EE/CH':
                relationship = ['Child'];
                break;
            case 'EE/FAM':
                relationship = ['Spouse', 'Domestic Partner', 'Civil Union Partner', 'Child'];
                break;
            case 'EL':
                relationship = ['Spouse', 'Domestic Partner', 'Civil Union Partner', 'Child']
                break;
        }
        generateRelationshipTypes(relationshipTypes, relationship)
    })
    return relationshipTypes
}

function updateObjectMetadata(metaData, errorObj) {
    metaData.isError = errorObj.isError;
    metaData.errors = [...metaData.errors, ...errorObj.errors]
}

function getRelationshipTypesError(relationshipTypes, fieldName, cell) {
    if(relationshipTypes.length === 0) {
        return `Employee for dependent at row - ${cell.row} should have coverage other than Employee Only`;
    } else {
        return `<b>${fieldName}</b> should have the value from [${relationshipTypes}] (row: ${cell.row}, col: ${cell.col})`;
    }
}

export const validateParentChildDependency = (parentObj, childObj, fieldName, metaData, cell) => {
    switch(fieldName) {
        case 'Relationship':
            validateRelationship()
            break;
    }

    function validateRelationship() {
        const companyInfo = parentObj.companyInfo;
        delete parentObj.companyInfo;
        let relationshipTypes = getRelationshipTypes(parentObj);
        let error = getRelationshipTypesError(relationshipTypes, fieldName, cell)
        if(!relationshipTypes.includes(childObj) && !isWaiveAll(parentObj, companyInfo.selectedProducts)) {
            updateObjectMetadata(metaData, {
                isError: true,
                errors: [{error}]
            });
        }
    }

    function isWaiveAll(coverages, selectedProducts) {
        const products = [
            'medical',
            'dental',
            'vision',
            'basicLife'
        ]
        selectedProducts['basicLife'] = selectedProducts['life'];
        let isWaived = true;
        products.forEach(product => {
            if (selectedProducts[product] && coverages[product] !== 'W') {
                isWaived = false
            }
        })
        return isWaived;
    }
}