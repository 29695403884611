import {dependencyNames} from '../../constants';
import * as dependencyHelper from '../dependencyHelper';
import { getOrUpdateValue } from '../../../utils/importSummaryHelpers';

/**
 * 
 * @param {String} dependencyName name of dependecy that needs to be resolved.
 * @param {object} dependencyList | list/object of dependencies to be resolved
 * @param {object} dependencyParams object of dependecy params that are required to resolve the dependency
 *                  dataObj: data that is needed to resolve the dependency like companyInfo is needed to resolve the dependency in employeeInfo sheet
 *                  mapping: mapping of the current sheet that is being parsed
 *                  worksheetRow: object of worksheet row. It is required while resolving the self dependency.
 */
export const resolveUHCDependency = (dependencyName, dependencyList,  dependencyParams) => {
    switch(dependencyName){
        case dependencyNames.WORKSHEET_DEPENDENCY:
            resolveWorkSheetDependencies(dependencyParams.dataObj, dependencyParams.mapping, dependencyList);
            break;
        case dependencyNames.SELF_DEPENDENCY:
            resolveSelfDependencies(dependencyParams.worksheetRow, dependencyParams.mapping, dependencyList);
            break;
        case dependencyNames.AFTER_PARSE_DEPENDENCY:
            resolveAfterParseDependencies(dependencyParams.dataObj, dependencyList);
            break;
        case dependencyNames.CHILD_PARENT_DEPENDENCY:
            resolveChildParentDependencies(dependencyParams.dataObj, dependencyList)
            break;
        case dependencyNames.PARENT_CHILD_DEPENDENCY:
            resolveParentChildDependencies(dependencyParams.dataObj, dependencyList)
            break;
    }
}

/**
 * 
 * @param {object} dataObj | dataObject that is required to resolve the dependency e.g. companyInfo is required to resolve the employeeInfo dependency
 * @param {object} mapping | mapping of the current sheet that is being parsed
 * @param {object} dependencyList | list of dependencies to be resolved
 */
function resolveWorkSheetDependencies(dataObj, mapping, dependencyList){
    Object.keys(dependencyList).forEach(key => {
        let dependencyArr = dependencyList[key];
        dependencyArr.forEach(dependency => {
            dependencyHelper.updateArgs(dataObj, dependency.args);
            let numOfCols = dependency.cols.length;
            for(let i = 0; i < numOfCols; i++){
                let fieldPath = `${dependency.cols[i]}.${dependency.depField}`
                let args = dependency.args[i]
                let fieldValue = dependency.fieldValues[i](args);
                dependencyHelper.getOrUpdateValue(mapping, fieldPath, fieldValue);
            }
        })
    })
}

/**
 * 
 * @param {object} worksheetRow | object of worksheet row.
 * @param {object} mapping | mapping of the current sheet that is being parsed
 * @param {array} dependencyList | list of dependencies to be resolved
 */
function resolveSelfDependencies(worksheetRow, mapping, dependencyList){
    dependencyList.forEach(dependency => {
        let value = worksheetRow.getCell(dependency.col).value;
        let numOfCols = dependency.depCols.length;
        for(let i = 0; i < numOfCols; i++){
            let fieldPath = `${dependency.depCols[i]}.${dependency.depFields[i]}`
            let fieldValue = dependency.fieldValues[i](value);
            dependencyHelper.getOrUpdateValue(mapping, fieldPath, fieldValue);
        }
    })
}

/**
 * 
 * @param {object} dataObj | dataObject that is required to resolve the dependency e.g. companyInfo is required to resolve the employeeInfo dependency
 * @param {array} dependencyList | list of dependencies to be resolved
 */
function resolveAfterParseDependencies(dataObj, dependencyList){
    dependencyList.forEach(dependency => {
        dependencyHelper.updateArgs(dataObj, dependency.args);
        let fieldPath = dependency.fieldPath;
        let fieldValue = dependency.fieldValue(dependency.args);
        dependencyHelper.getOrUpdateValue(dataObj, fieldPath, fieldValue);
    });
}

function resolveChildParentDependencies(dataObj, dependencyList) {
    dependencyList.forEach(dependency => {
        switch(dependency.type) {
            case 'move':
                const {pathArr} = dependency
                pathArr.forEach((elem) => {
                    const {srcPath, destPath, deletePath} = elem
                    if(dependency.src === 'child' && dependency.dest === 'parent') {
                        srcPath.replace('.i.', `.${dataObj.childIndex}.`)
                        destPath.replace('.i.', `.${dataObj.parentIndex}.`)
                        deletePath.replace('.i.', `.${dataObj.childIndex}.`)
                        let fieldValue = dependencyHelper.getOrUpdateValue(dataObj.childObj, srcPath)
                        dependencyHelper.deleteValueFromPath(dataObj.childObj, deletePath)
                        dependencyHelper.getOrUpdateValue(dataObj.parentObj, destPath, fieldValue)

                    } else {
                        srcPath.replace('.i.', `.${dataObj.parentIndex}.`)
                        destPath.replace('.i.', `.${dataObj.childIndex}.`)
                        deletePath.replace('.i.', `.${dataObj.parentIndex}.`)
                        let fieldValue = dependencyHelper.getOrUpdateValue(dataObj.parentObj, srcPath)
                        dependencyHelper.deleteValueFromPath(dataObj.parentObj, deletePath)
                        dependencyHelper.getOrUpdateValue(dataObj.childObj, destPath, fieldValue)
                    }
                })
        }
    })
}

function resolveParentChildDependencies(dataObj, dependencyList) {
    dependencyList.forEach(dependency => {
        let parentValue = getOrUpdateValue(dataObj.parentObj, dependency.parentFieldPath);
        let childValue = getOrUpdateValue(dataObj.childObj, dependency.childFieldPath);
        let cell = {
            row: dataObj.row,
            col: dependency.col
        }
        parentValue = {...parentValue, companyInfo: dataObj.companyInfo};
        dependency.validateValue(parentValue, childValue, dependency.fieldName, dataObj.metaData, cell);
    });
}