import * as types from '../../actions/actionTypes';


const initialState = {
    // Maximum rate adjustment by product
    APP: "0",
    CIP: "0",
    HIIP: "0",
    LTD: "0",
    STD: "0",
    basicLife: "0",
    dental: "0",
    suppLife: "0",
    vision: "0",
    // Async rule fetch status
    isFetchingRateAdjustmentRules: false,
    rateAdjustmentRulesFetchError: false
};

export default function rateAdjustmentRules(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_RATE_ADJUSTMENT_RULES:
            return {
                ...state,
                isFetchingRateAdjustmentRules: true,
                rateAdjustmentRulesFetchError: false
            };
        case types.RECEIVE_RATE_ADJUSTMENT_RULES:
            return {
                ...state,
                isFetchingRateAdjustmentRules: false,
                ...action.rules
            };
        case types.ERROR_RATE_ADJUSTMENT_RULES:
            return {
                ...state,
                isFetchingRateAdjustmentRules: false,
                rateAdjustmentRulesRulesFetchError: true
            };
        case types.RESET_FLOW:
            return initialState;
        default:
            return state;
    }
}
