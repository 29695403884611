import React from "react";
import { Loader, Modal } from "semantic-ui-react";

const LoaderPopup = ({ message = "Loading..." }) => {
  return (
    <Modal open closeOnDimmerClick={false} style={{ height: "fit-content" }}>      
        <Loader inverted>{message}</Loader>      
    </Modal>
  );
};

export default LoaderPopup;
