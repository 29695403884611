import * as types from '../../actions/actionTypes';

const initialState = {
    isFetchingCaseSummaryData: false,
    finalizeCaseSummary: {}
};

export default function finalizeCaseSummary(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_RENEWAL_FINALIZE_CASE_SUMMARY:
            return {
                ...state,
                isFetchingCaseSummaryData: true,
            };

        case types.RECEIVE_RENEWAL_FINALIZE_CASE_SUMMARY:
            return {
                ...state,
                finalizeCaseSummary: action.payload,
                isFetchingCaseSummaryData: false,
            };

        case types.RESET_TO_INITIALSTATE:
            return initialState;

        case types.SET_RENEWAL_NICE_CASE_SUMMARY:
            return {
                ...state,
                finalizeCaseSummary: {
                    summaryData: action.payload
                },
                isFetchingCaseSummaryData: false
             }
    

        default:
            return state;
    }
}