import { SOLARIS, RESET_TO_INITIALSTATE, RESET_RENEWALS, RESET_FLOW } from '../../actions/actionTypes';

const initialState = {
    isSending: false,
    isSent: false,
    sendingError: false,
    shouldGenerateId: false,
    firstTimeEnroll: true,
    solarisError: '',
    status: '',
    medicalCodes: [],
    dentalCodes: [],
    visionCodes: [],
    lifeCodes: [],
    lifeDepCodes: [],
    mmrSent: true
};

export default function sendToSolaris(state = initialState, action) {
    switch (action.type) {
        case SOLARIS.SEND_TO_SOLARIS:
            return {
                ...state,
                isSending: true,
                isSent: false,
                sendingError: false,
            };
        case SOLARIS.SEND_SUCCESS:
            return {
                ...state,
                isSending: false,
                isSent: true,
                sendingError: false,
            };
        case SOLARIS.SEND_ERROR:
            return {
                ...state,
                isSending: false,
                isSent: false,
                sendingError: true,
                solarisError: action.errorMessage,
            };
        case SOLARIS.UPDATE_QUOTED_PLANS: {
            const { medicalCodes, dentalCodes, visionCodes, lifeCodes, lifeDepCodes, suppLifeCodes } = action;
            return {
                ...state,
                medicalCodes,
                dentalCodes,
                visionCodes,
                lifeCodes,
                lifeDepCodes,
                suppLifeCodes,
            };
        }
        case SOLARIS.SHOULD_GENERATE_ID:
            return {
                ...state,
                shouldGenerateId: action.val,
            };
        case SOLARIS.FIRST_TIME_ENROLL:
            return {
                ...state,
                firstTimeEnroll: action.val,
            };
        case SOLARIS.COPY_QUOTE:
            return {
                ...state,
                firstTimeEnroll: true,
                shouldGenerateId: true,
            };
        case SOLARIS.UPDATE_STATUS: {
            const { status } = action;
            return {
                ...state,
                status,
            };
        }
        case SOLARIS.UPDATE_MMR_STATUS:
            return {
                ...state,
                mmrSent: action.val,
            };
        case RESET_TO_INITIALSTATE:
        case RESET_RENEWALS:
        case RESET_FLOW:
            return initialState
        default:
            return state;
    }
}