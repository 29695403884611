export const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    planType: {
        sPlan: false,
        vlPlan: false,
    },

    contributionType: {
        employerPaid: false,
        fiftyFifty: false,
        employeeCore: false,
        Voluntary: false,
    },
    lensOptions: {
        sscP19: false,
        sscP19Pa: false,
        sscPaSp: false,
    },
    freqency: {
        ttt: false,
        ttf: false,
        tff: false,
        fff: false,
    },
    appliedFilters: {},
};

export const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    // TBD
};

export const initialSpecialtyCriteria = {
    sicCode: '',
    viewSpecialty: {
        medical: false,
        dental: false,
        vision: false,
        life: false,
        std: false,
        ltd: false,
    },
    employeeOnly: '',
    employeeWithSpouse: '',
    employeeWithChild: '',
    employeeWithFamily: '',
};

export const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        zipCode: null,
        zipObj: {},
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    isFetchingPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    plans: [],
    allPlans: [],
    visiblePlans: [],
    selectedPlans: [],
    selectedPlansMap: {},
    favoritedPlans: [],
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    filterTotalsCache: initialfilterTotalsCache,
    specialty: true,
    sortEnabled: true,
    ascending: true,
    showPreferredPlans: true,
    filteredResults: [],
    appliedFiltersAdvance: {},
    appliedFiltersBasics: {},
    advanceFilterProductType: 'vision',
    displayLeftSideFilter: false,
    isSendingRateAdjustments: false,
    rateAdjustmentsError: false,
};

export const visionFilterSet = [
    {
        filterGroup: 'Plan Code',
        filterId: 'plancode',
        planField: 'code',
        type: 'list',
        place: 'dropdown',
        display: false,
        values: {},
    },
    {
        filterGroup: 'Contribution',
        filterId: 'contribution',
        planField: 'visionPlanTypeDescription',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Frequency (Exam/Lens/Frame)',
        filterId: 'frequency',
        planField: 'frequency',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Copays (Exam/Materials)',
        filterId: 'copays',
        planField: 'copays',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'CL Allowance',
        filterId: 'clAllowance',
        planField: 'contactLenses.allowanceIn',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'CL Fit Allowance',
        filterId: 'clFitAllowance',
        planField: 'allowance.contactLensFittingAllowance',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    // {
    //     filterGroup:'Necessary CL Allowance',
    //     filterId:'necessaryClAllowance',
    //     planField:'necessaryContactLenses',
    //     type:'list',
    //     place:'basic',
    //     display:true,
    //     values: {}
    // },
    {
        filterGroup: 'Frame Allowance',
        filterId: 'frameAllowance',
        planField: 'frames.allowanceRetail',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Lens Options',
        filterId: 'lensOptions',
        planField: 'lenses.lensOption',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Plan Type',
        filterId: 'visionPlanType',
        planField: 'visionPlanTypeCode',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Installation Options',
        filterId: 'installoptions',
        planField: 'installationType',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
];
