import * as types from '../../actions/actionTypes';
import { handleAdjustRenewalsRatesAndPremium } from './helpers/filteringRenewalReducerHelper';
import deepCopy from '../../utils/deepCopy';
import { formatSalesRepRenewals } from '../../components/activities/Renewal/RenewalsCommonComponents/saveDetails';
import { calculatePercentage } from '../../actions/actionHelpers/mmrHelper';

const initialMDVContr = {
    medical: {
        eValue: 0,
        dValue: 0,
        activeIndex: 0
    },
    dental: {
        eValue: 0,
        dValue: 0,
        activeIndex: 1
    },
    vision: {
        eValue: 0,
        dValue: 0,
        activeIndex: 2
    },
    life: {
        eValue: 0,
        dValue: 0,
        activeIndex: 3
    }
}

let initialState = {
    premiumToggle: true,
    errorMessage: '',
    rateAdjustAllow: true,
    mdvContribution: initialMDVContr,
    enrolledPlansMed: {},
    enrolledPlans: [],
    enrolledDentalPlans: [],
    enrolledVisionPlans: [],
    enrolledLifePlans: [],
    enrolledDependentLifePlans: [],
    shoppingDentalPlans: [],
    shoppingVisionPlans: [],
    shoppingLifePlans: [],
    customPlanNames: {
        medical: {},
        dental: {},
        vision: {},
        life: {},
        depLife: {}
    },
    hwTriggerError: false
};

export default function decideReducer(state = initialState, action) {

    switch (action.type) {
        case types.REQUEST_PAGE_TYPE:
            return {
                ...state,
                ...action.payload
            }
        case types.RESET_DECIDE_FLAG:
            return {
                ...state,
                renewalDecideFlag: action.payload
            }
        case types.RECEIVE_DECIDE_DETAILS:
            return {
                ...state,
                ...action.payload,
                showRemoveModal: true
            }
        case types.ERR_DECIDE_DETAILS:
            // return handleDecideError(state, action.payload);
            return {
                ...state,
                decideDataError: action.payload
            }

        case types.UPDATED_DECIDE_DETAILS:
            //save review details to state
            return {
                ...state
            }
        case types.UPDATE_PREMIUM_TOGGLE_VALUE:

            return {
                ...state,
                premiumToggle: action.payload
            }


        case types.REMOVE_DECIDE_PLAN:

            const newList = state.renewalData.planRates.map((item) => {
                if (item.planCode === action.payload.query.code) {
                    const updatedItem = {
                        ...item,
                        isChecked: false,
                    };
                    return updatedItem;
                }
                return item;
            });
            const shopList = []
            state.shoppingPlans.map((item) => {
                if (item.code !== action.payload.query.code) {
                    shopList.push(item)
                }
            });
            return {
                ...state,
                shoppingPlans: shopList,
                renewalData: {
                    ...state.renewalData,
                    planRates: newList
                }
            }

        case types.UPDATE_MDV_CONTRIBUTION_DECIDE:
            const tempMDVCont = initialMDVContr

            if (tempMDVCont.medical){
            tempMDVCont.medical.eValue = action.payload.medEValue ?
                action.payload.medEValue :
                state.mdvContribution.medical.eValue;
            tempMDVCont.medical.dValue = action.payload.medDValue ?
                action.payload.medDValue :
                state.mdvContribution.medical.dValue;
            }
            if (tempMDVCont.dental){
            tempMDVCont.dental.eValue = action.payload.denEValue ?
                action.payload.denEValue :
                state.mdvContribution.dental.eValue;
            tempMDVCont.dental.dValue = action.payload.denDValue ?
                action.payload.denDValue :
                state.mdvContribution.dental.dValue;
            }
            if (tempMDVCont.vision){
            tempMDVCont.vision.eValue = action.payload.visEValue ?
                action.payload.visEValue :
                state.mdvContribution.vision.eValue;
            tempMDVCont.vision.dValue = action.payload.visDValue ?
                action.payload.visDValue :
                state.mdvContribution.vision.dValue;
            }
            if (tempMDVCont.life && (state.mdvContribution.life || action.payload.lifeEValue)) {
                tempMDVCont.life.eValue = action.payload.lifeEValue ?
                    action.payload.lifeEValue :
                    state.mdvContribution.life.eValue;
            }

            return {
                ...state,
                mdvContribution: tempMDVCont,
                errorMessage: action.payload.errorMessage
            }

        case types.REMOVE_MODAL_PREFERENCE:
            return {
                ...state,
                showRemoveModal: !action.payload
            }
        case types.SET_ENROLLED_PLANS_DECIDE:
            return {
                ...state,
                enrolledPlansMed: {}
            }
        case types.ADD_ENROLLED_PLANS:

            let updatedItem = {}


            /**Start US2728406: "Renew Plans Selected" function on the Shopping page */

            updatedItem = {
                planCode: action.payload.planCode,
                isEnrolled: action.payload.isEnrolled,
                planOption: action.payload.optionPlan,
                isAdditionalPlan: action.payload.isAdditionalPlan
            };

            /**End  US2728406: "Renew Plans Selected" function on the Shopping page */

            return {
                ...state,
                enrolledPlansMed: {
                    ...state.enrolledPlansMed,
                    [action.payload.planCode]: updatedItem

                }
            }

        case types.UPDATE_ENROLLED_PLANS:

            let updatedEnrollState = state.enrolledPlans && state.enrolledPlans[action.payload.planCode] ? state.enrolledPlans[action.payload.planCode] : {}
            updatedEnrollState.planCode = action.payload.planCode
            updatedEnrollState.isEnrolled = action.payload.isEnrolled

            return {
                ...state,
                enrolledPlansMed: {
                    ...state.enrolledPlansMed,
                    [action.payload.planCode]: updatedEnrollState
                }
            }


        case types.ADD_MAX_PLAN_SELECTION_LIMIT:
            return {
                ...state,
                error: {
                    ...state.error,
                    maxSelectionLimit: action.payload
                }

            }

        case types.UPDATE_ENROLLED_PLANS_SELECTED_COUNT:
            const newState = {
                ...state.error,
                selectedEnrolledPlansCount: action.payload
            }
            return {
                ...state,
                error: newState

            }

        case types.UPDATE_ENROLL_ADDITIONAL_PLANS_SELCETED_COUNT:
            return {
                ...state,
                error: {
                    ...state.error,
                    selectedEnrolledAdditionalPlansCount: action.payload
                }

            }

        case types.IS_ERROR_ENROLL_PLANS_COUNT:
            return {
                ...state,
                error: {
                    ...state.error,
                    isErrorEnrollPlansCount: action.payload
                }

            }

        case types.DAYS_LEFT_FOR_RENEWAL:
            return {
                ...state,
                error: {
                    ...state.error,
                    daysLeft: action.payload
                }
            }

        case types.IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    isEligibleToFinalizeFromDecide: action.value
                }

            }

        case types.UPDATE_BEEN_TO_FINALIZE:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    beenToFinalize: action.payload
                }
            }
        case types.ADD_SHOPPING_PLANS_TO_DECIDE:
            return {
                ...state,
                shoppingPlans: action.payload
            }
        case types.UPDATE_SELECTED_PLAN_DECIDE:

            const newshopList = state.shoppingPlans.map((item) => {
                if (item.code === action.payload.query) {
                    let updatedItem = {
                        ...item,
                        selected: action.payload.value,
                    };
                    return updatedItem;
                }
                return item;
            });
            return {
                ...state,
                shoppingPlans: newshopList
            }

        case types.UPDATE_PLAN_SELECTION_DECIDE:
            const newshoppingList = state.shoppingPlans.map((item) => {

                let updatedItem = {
                    ...item,
                    selected: false,
                };
                return updatedItem;
            });
            return {
                ...state,
                shoppingPlans: newshoppingList,
            }
        case types.SERVICE.DENTAL.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'dental');

        case types.SERVICE.VISION.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'vision');

        case types.SERVICE.LIFE.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'life');

        case types.SERVICE.LIFE_DEP_BASIC.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'dependentLife');

        case types.SERVICE.LTD.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'ltd');
            
        case types.SERVICE.STD.ADJUST_RENEWALS_RATE:
            return handleAdjustRenewalsRatesAndPremium(state, action, 'std');    

        case types.SERVICE.FI.TOGGLE_PLAN_ENROLL_RENEWAL:
            let pkg = action.withinPackage;
            let packageKeyExists = state.enrolledPlans[pkg] !== undefined;
            let enrolledIdx = -1;
            if (packageKeyExists) {
                enrolledIdx = state.enrolledPlans[pkg].findIndex(
                    plan => plan.code === action.plan.code
                );
            }

            let ep = deepCopy(state.enrolledPlans);
            let newNumEnrolled = state.numEnrolledPlans;
            let enrolledPlanscount = state.error.selectedEnrolledPlansCount
            // Add or remove the plan from enrolledPlans array
            if (enrolledIdx === -1) {
                newNumEnrolled += 1;
                enrolledPlanscount += 1;
                if (!packageKeyExists) {
                    ep[pkg] = [action.plan];
                } else {
                    ep[pkg] = ep[pkg].concat(action.plan);
                }
            } else {
                newNumEnrolled -= 1;
                enrolledPlanscount -= 1;
                ep[pkg] = ep[pkg]
                    .slice(0, enrolledIdx)
                    .concat(ep[pkg].slice(enrolledIdx + 1));
            }
            let newErrorState = {
                ...state.error,
                selectedEnrolledPlansCount: enrolledPlanscount
            }
            return {
                ...state,
                error: newErrorState,
                enrolledPlans: ep,
                numEnrolledPlans: newNumEnrolled
            };
        case types.SERVICE.DENTAL.TOGGLE_PLAN_ENROLL_RENEWAL:
        case types.SERVICE.VISION.TOGGLE_PLAN_ENROLL_RENEWAL:
        case types.SERVICE.LIFE.TOGGLE_PLAN_ENROLL_RENEWAL:
        case types.SERVICE.LIFE_DEP_BASIC.TOGGLE_PLAN_ENROLL_RENEWAL:
            const type = action.plan.planType;
            let enrolledIndex = state[`enrolled${type}Plans`].findIndex(plan => plan.code === action.plan.code);

            let enrolledPlans = deepCopy(state[`enrolled${type}Plans`]);
            let numEnrolledPlans = state.numEnrolledPlans;
            let enrolledPlanscountSpec = state.error.selectedEnrolledPlansCount
            // Add or remove the plan from enrolledPlans array
            if (enrolledIndex === -1 && !action.remove) {
                numEnrolledPlans += 1;
                enrolledPlanscountSpec += 1;
                enrolledPlans = enrolledPlans.concat(action.plan);
            } else if (enrolledIndex !== -1) {
                numEnrolledPlans -= 1;
                enrolledPlanscountSpec -= 1;
                enrolledPlans = enrolledPlans
                    .slice(0, enrolledIndex)
                    .concat(enrolledPlans.slice(enrolledIndex + 1));
            }
            let newErrorStateSpec = {
                ...state.error,
                selectedEnrolledPlansCount: enrolledPlanscountSpec
            }
            return {
                ...state,
                error: newErrorStateSpec,
                [`enrolled${type}Plans`]: enrolledPlans,
                numEnrolledPlans: numEnrolledPlans,
            };

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
        case types.RESET_RENEWALS:
            return initialState

        case types.UPDATE_RENEWALS_CUSTOM_PLAN_NAME:
            return {
                ...state,
                customPlanNames: {
                    ...state.customPlanNames,
                    [`${action.productType}`]: {
                        ...state.customPlanNames[`${action.productType}`],
                        [action.planCode]: action.customName
                    }
                }
            }

        case types.UPDATE_SALES_REP_RENEWALS_DECIDE:
            let finalSalesRepData = formatSalesRepRenewals(action.salesRep, action.platform)
            return {
                ...state,
                salesRepData: finalSalesRepData
            }


        case types.UPDATE_EMPLOYEES_ENROLL_STATUS:
            const { caseSubmitted } = state;

            // Don't update enroll status to In Progress, if the case is submitted status.
            if (caseSubmitted && action.payload === 'In Progress') {
                return {
                    ...state
                }
            }

            return {
                ...state,
                employeesEnrollStatus: action.payload
            }

        case types.RECEIVE_MMR_OVERRIDE_RATES:
            let updatedProductPlanSet = JSON.parse(JSON.stringify(state[action.product].set));
            let updatedProductSelectedPlans = JSON.parse(JSON.stringify(state[action.product].selectedPlans));

            // Add the rates from this batch to their corresponding plans
            for (let i = 0; i < updatedProductPlanSet.length; i++) {
                if (updatedProductPlanSet[i].length > 1) {
                    const rate = action.rates.find(planRate => {
                        let planCode = planRate.code
                        return updatedProductPlanSet[i][1].code === planCode
                    })
                    if (rate) {
                        let selectedPlanIndex = updatedProductSelectedPlans.findIndex(plan => plan.code === rate.code)
                        rate.quote = rate.byCensus.totalMonthlyPremium
                        rate.finalMonthlyPremium = rate.quote
                        rate.finalRates = rate.monthlyPremium
                        updatedProductPlanSet[i][1] = {
                            ...rate,
                            ...updatedProductPlanSet[i][1],
                            member: updatedProductPlanSet[i][1].member.map((employee, employeeIndex) => ({
                                ...employee,
                                total: rate.byCensus.employeeRates.find(emp => employee.employeeId === emp.employeeId).Total
                            })),
                            isFetchingRates: false,
                            renewalChangePercent: calculatePercentage(updatedProductPlanSet[i][0].quote, rate.quote)
                        }
                        if (selectedPlanIndex >= 0) {
                            updatedProductSelectedPlans[selectedPlanIndex] = updatedProductPlanSet[i][1]
                        }
                    }
                }
            }
            return {
                ...state,
                [action.product]: {
                    selectedPlans: updatedProductSelectedPlans,
                    set: updatedProductPlanSet
                },
                mmr: {
                    ...state.mmr,
                    [`isFetching${action.product[0].toUpperCase() + action.product.slice(1)}Rates`]: false
                }
            };

        case types.UPDATE_DECIDE_SELECTED_PLANS_MMR:
            return {
                ...state,
                [action.product]: {
                    ...state[action.product],
                    selectedPlans: action.payload
                }

            }
        
        case types.SET_PRODUCT_TYPE_LIST:
            return {
                ...state,
                productTypeList: action.productTypeList
            }

        case types.SET_HW_TRIGGER_ERROR:
            return {
                ...state,
                hwTriggerError: action.payload
            }
            
        default:
            return state;
    }

}