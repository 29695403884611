import { getCompositeRate, getAgeBandedRate, getSpecialtyRate } from '../businessLogic/calculateQuotes';
import { calculateDependentTotal } from '../../components/shared/EstEmployerCost/calculateDependentTotal.js';
import { getSpecialtyMembersDecidePageIBR, getSpecialtyMembersShoppingPage, getSpecialtyMembersDecidePage } from '../../components/activities/QuickQuotes/Specialty/SpecialtyPlanDetails/SpecialtyPlanDetailsTabs/SpecialtyEmployeeCostHelper';
import { isMmr } from '../../utils/businessLogic/platformUtils';

export const determineMinContribLevel = (stateCode, employees = null, platform) => {
    switch (stateCode ? stateCode.toUpperCase() : '') {
        case 'CT':
        case 'IL':
        case 'WA':
        case 'OR':
        case 'AZ':
        case 'IN':
        case 'KY':
        case 'NM':
        case 'ME':
        case 'NH':
        case 'OK':
        case 'AL':
        case 'PA':
        case 'RI':
        case 'TN':
        case 'UT':
        case 'VI':
        case 'MN':
        case 'MS':
        case 'NE':
        case 'OH':
        case 'AK':
        case 'AR':
        case 'CO':
        case 'GA':
        case 'HI':
        case 'IA':
        case 'ID':
        case 'KS':
        case 'LA':
        case 'MA':
        case 'MO':
        case 'NE':
        case 'WI':
        case 'WY':
        case 'TX':
        case 'NV':
            return 50;
        case 'NJ':
            return 10;
        case 'MT':
            return 75;
        case 'NC':
        case 'SC':
            return ((employees !== null && employees <= 3 ) ? 75 : 50);
        case 'NY':
            if (platform === "PRIME") {
                return 25;
            } else {
                return 0;
            }
        default:
            return 0;
    }
}

export const minLifeContribLevel = (stateCode, employees = null) => {
    switch (stateCode ? stateCode.toUpperCase() : '') {
        case 'AK':
        case 'AZ':
        case 'AR':
        case 'CO':
        case 'CT':
        case 'GA':
        case 'IA':
        case 'IL':
        case 'IN':
        case 'ID':
        case 'KS':
        case 'LA':
        case 'ME':
        case 'MA':
        case 'MO':
        case 'MS':
        case 'MT':
        case 'KY':
        case 'NE':
        case 'NV':
        case 'NH':
        case 'NM':
        case 'NJ':
        case 'OH':
        case 'OR':
        case 'PA':
        case 'RI':
        case 'TN':
        case 'WI':
            return 25;
        case 'OK':
            return 50;
        default:
            return 0;
    }
}

/**
 * Create an object that contains all plan and employee data including contributions in dollar amounts
 * @param {string} product Determines product selected
 * @param {object} plans Cotains all plans and benefits
 * @param {object} contributions Contains employee and dependent contributions and methods
 * @param {object} employees Contains all employee and coverage data
 * @param {string} isAgeBanded Determine rating method for medical plans
 * @param {string} pageType Determine which user flow is accessing contribution window from
 */

export const calculateContributions = (product, plans, contributions, employees, isAgeBanded = false, pageType = null, page = null, depLife, life, stateCode, selectedProducts, enrolledLifeDep, lifeDepIbrPlans, std, ltd, suppLife, depSuppLife) => {
    const isWashingtonTwoTier = () => {
        return stateCode === 'WA' && selectedProducts.medical
    }

    const washingtonMedicalCompositeRate = (age, compositeRates) => {
        if (typeof age === "string") {
            age = parseInt(age.split(" ")[0]);
        }
        return age < 21 ? compositeRates[0].rate || compositeRates[0].total : compositeRates[1].rate || compositeRates[1].total
    }

    // Premiums for all plans for all employees
    let allPlanTotals = [];


    let {
        employeeContributionMethod,
        dependentContributionMethod,
        employeeContrib,
        dependentContrib
    } = contributions;

    plans.map((plan) => {
        let index = 0
        let lifeElectedIndex = 0
        let suppLifeElectedIndex = 0
        let stdLtdIndex = 0
        let singlePlanData = {
            planName: plan.codeDisplay || plan.code,
            planMetal: plan.metal,
            employeeTotal: 0,
            employerTotal: 0,
            // Employee totals for each plan
            employees: []
        }
        if (pageType === 'nb') {
            if (product === "life" && life.employeeClassing.classingOpted) {
                singlePlanData.selectedClass = plan.selectedClass
            } else if (product === "std" && std.employeeClassing.classingOpted) {
                singlePlanData.selectedClass = plan.selectedClass
            } else if (product === "ltd" && ltd.employeeClassing.classingOpted) {
                singlePlanData.selectedClass = plan.selectedClass
            }
        }

        if (pageType === 'renewals') {

            let finalEmployees = employees
            const isMMRCase = isMmr()
            if (product === 'dental' || product === 'vision' || product === 'life' || product === 'std' || product === 'ltd' || product === 'suppLife') {
                let fullCensusRenewalMembers = []

                if (employees && employees.length > 1) {

                    fullCensusRenewalMembers = employees[0]

                    let count = 0
                    plans.map((plan) => {
                        if (plan && !plan.renewalPlanType) {
                            count += 1;
                        }
                    });

                    let hasAdditionalPlans = count > 0 ? true : false
                    finalEmployees = getSpecialtyMembersDecidePageIBR(hasAdditionalPlans, fullCensusRenewalMembers, product, plan)

                }
                else {
                    fullCensusRenewalMembers = employees[0]
                    if (page === 'review') {
                        finalEmployees = getSpecialtyMembersShoppingPage(fullCensusRenewalMembers, product, plan)
                    }
                    if (page === 'decide') {
                        finalEmployees = getSpecialtyMembersDecidePage(fullCensusRenewalMembers, product, plan)
                    }
                    if (page === 'ren-shopping') {
                        finalEmployees = getSpecialtyMembersShoppingPage(fullCensusRenewalMembers, product, plan)
                    }
                }

            } else if (employees.length === 1) finalEmployees = employees[0]

            finalEmployees.map((emp, i) => {

                let premiumTotal = 0; // Total employee dollars owed
                let dependentsTotal = 0; // Total dependent dollars owed
                let employerTotal = 0; // Total dollars owed by employer

                let eePremiumBeforeContribution, eePremiumAfterContribution;
                let depPremiumBeforeContribution = 0;
                let depPremiumAfterContribution = 0;

                let employeeEnrolled = false
                
                switch (product) {
                    case 'medical':

                        let isAgeBanded = plan.defaultRatingType === 'ageBands'

                        if (plan.employeeRates && plan.employeeRates.length > 0) {
                            let employeeRate = plan.employeeRates.find(empRate => parseInt(empRate.employeeId) === parseInt(emp.memberId) || parseInt(empRate.employeeId) === parseInt(emp.employeeId) || (parseInt(empRate.employeeId) === i + 1))

                            let employeePremium = employeeRate ? employeeRate.total : null

                            if (employeePremium) {
                                employeeEnrolled = true
                                if (isAgeBanded) {
                                    eePremiumBeforeContribution = getAgeBandedRate(emp.age, plan.ageBands, emp.coverages.medical);
                                    depPremiumBeforeContribution = employeePremium - eePremiumBeforeContribution
                                } else {
                                    eePremiumBeforeContribution = getCompositeRate(plan.compositeRates, plan.compositeTypeName, 'EE', emp.age);
                                    depPremiumBeforeContribution = employeePremium - eePremiumBeforeContribution
                                }

                                if (employeeContributionMethod === '%') {
                                    eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);
                                    premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                    employerTotal += eePremiumAfterContribution;
                                }
                                if (dependentContributionMethod === '%') {
                                    depPremiumAfterContribution = depPremiumBeforeContribution * (dependentContrib / 100);
                                    dependentsTotal += depPremiumBeforeContribution - depPremiumAfterContribution;
                                    employerTotal += depPremiumAfterContribution;
                                }
                            }
                        }

                        break

                    case 'dental':
                    case 'vision':
                    case 'life':
                    case 'suppLife':
                    case 'std':
                    case 'ltd':

                        let employeePremium = emp.total ? emp.total : null

                        if (employeePremium) {
                            eePremiumBeforeContribution = product === 'life' ? employeePremium : (emp.memberPremium ? emp.memberPremium : getSpecialtyRate(product, plan, 'employee', emp, 0));

                            depPremiumBeforeContribution = employeePremium - eePremiumBeforeContribution
                            employeeEnrolled = true



                            if (employeeContributionMethod === '%') {
                                eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);
                                premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                employerTotal += eePremiumAfterContribution;
                            }
                            if (emp.dependents.length > 0) {
                                if (dependentContributionMethod === '%') {
                                    depPremiumAfterContribution = depPremiumBeforeContribution * (dependentContrib / 100);
                                    dependentsTotal += (depPremiumBeforeContribution - depPremiumAfterContribution);
                                    employerTotal += depPremiumAfterContribution;
                                }
                            }
                        }

                        break

                    default:
                        break
                }
                if (depLife && depLife.length > 0 && product === 'life') {

                    let hasDependentlife = !['Employee Only', 'EE', 'Waived', 'EO'].includes(emp.coverages[product])

                    let depLifeRate;
                    if (page === 'review' && lifeDepIbrPlans && lifeDepIbrPlans.length > 0 ) {
                        depLifeRate = lifeDepIbrPlans.length > 0 ? lifeDepIbrPlans[0][1].dependentLifeRate : 0;
                    }
                    else {
                        let sum = 0;
                        for (let i = 0; i < depLife.length; i++) {
                            sum = sum + (depLife[i].finalRates ? parseFloat(depLife[i].finalRates.depLifeChildRate) : 0);
                        }
                        depLifeRate = sum;
                    }

                    const specialtyDependentTotal1 = hasDependentlife ? (parseFloat(depLifeRate)) : 0

                    depPremiumBeforeContribution = specialtyDependentTotal1;
                    dependentsTotal = depPremiumBeforeContribution
                }

                if (employeeEnrolled === true) {
                    const employeeTotal = premiumTotal + dependentsTotal;
                    employerTotal = employerTotal;

                    singlePlanData.employeeTotal += employeeTotal;
                    singlePlanData.employerTotal += employerTotal;
                    eePremiumBeforeContribution = +eePremiumBeforeContribution;
                    const contributionTotals = {
                        employeeName: `${emp.firstName} ${emp.lastName}`,
                        dependentsTotal,
                        premiumTotal,
                        employeeTotal,
                        employerTotal,
                        eePremiumBeforeContribution,
                        depPremiumBeforeContribution
                    };

                    singlePlanData.employees.push(contributionTotals);
                }

            })


        } else {
            const isOOAPlan = plan.areaCoverage === 'Out' || plan.areaCoverage === 'All' || plan.areaCoverage=='Out - Live and Travel'
            employees.map((location) => {
                location.map((employee) => {

                    let premiumTotal = 0; // Total employee dollars owed
                    let dependentsTotal = 0; // Total dependent dollars owed
                    let employerTotal = 0; // Total dollars owed by employer

                    // Premiums for Employees and Dependents before and after contributions are calculated
                    let eePremiumBeforeContribution, eePremiumAfterContribution;
                    let depPremiumBeforeContribution = 0;
                    let depPremiumAfterContribution = 0;
                    const isOOAEmployee = employee.outOfArea === 'Yes' ? true : false;
                    let hasDependent = !['EO', 'EE', 'W'].includes(employee.coverages[product])
                    switch (product) {
                        case 'medical':

                            // Determine initial rate for Employee and Dependent before contributions
                            if(!isOOAPlan && isOOAEmployee){
                                eePremiumBeforeContribution = 0
                                depPremiumBeforeContribution = 0
                            }
                            else{
                                if (isAgeBanded) {
                                    eePremiumBeforeContribution = getAgeBandedRate(employee.age, plan.ageBands, employee.coverages.medical);
                                    depPremiumBeforeContribution = calculateDependentTotal(employee, plan, 0, true);
                                } else {
                                    if (employee.coverages.medical === 'W') {
                                        eePremiumBeforeContribution = 0
                                        depPremiumBeforeContribution = 0
                                    } else {
                                        if (isWashingtonTwoTier()) {
                                            eePremiumBeforeContribution = washingtonMedicalCompositeRate(employee.age, plan.compositeRates)
                                            const dependents = employee && employee.dependents || []
                                            dependents.forEach(dependent => {
                                                depPremiumBeforeContribution += washingtonMedicalCompositeRate(dependent.age, plan.compositeRates)
                                            })
                                        } else {
                                            eePremiumBeforeContribution = getCompositeRate(plan.compositeRates, plan.compositeTypeName, 'EE', employee.age);
                                            depPremiumBeforeContribution = calculateDependentTotal(employee, plan, 0, false);
                                        }
                                    }
                                }
                            }

                            // Determine dollar amount out of total employee premium to be
                            // paid by employee and employer based on contribution and method
                            if (employeeContributionMethod === '$') {
                                // Total dollars owed by employee after contribution is subtracted from premium
                                eePremiumAfterContribution = isOOAEmployee ? 0 : eePremiumBeforeContribution - employeeContrib;

                                // Attempted employer contribution $ amount may exceed age-banded rate, only needs to contribute up to rate
                                if (eePremiumAfterContribution >= 0) {
                                    // Add total dollars owed by employee and employer to totals
                                    premiumTotal += eePremiumAfterContribution;
                                    employerTotal += employeeContrib;
                                } else {
                                    // If attempted employer contribution exceeds employee premium,
                                    // only require employer to contribute up to premium dollar amount
                                    employerTotal += eePremiumBeforeContribution;
                                }
                            } else {
                                // Total employer dollars owed after contribution percentage is applied
                                eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);

                                // Add total dollars owed by employee and employer to totals
                                premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                employerTotal += eePremiumAfterContribution;
                            }

                            // Determine dollar amount out of total dependent premium to be
                            // paid by employee and employer based on contribution and method
                            if (dependentContributionMethod === '$') {

                                // Total dollars owed by employee after contribution is subtracted from premium
                                // depPremiumAfterContribution = depPremiumBeforeContribution - (dependentContrib * employee.dependents.length);
                                depPremiumAfterContribution = isOOAEmployee ? 0 : depPremiumBeforeContribution - (dependentContrib);

                                // Attempted dependent contribution $ amount may exceed age-banded rate, only needs to contribute up to rate
                                if (depPremiumAfterContribution >= 0) {
                                    // Add total dollars owed by dependent and employer to totals
                                    dependentsTotal += depPremiumAfterContribution;
                                    /* 
                                        TODO: If rates for dependents are $90, $100, contributions of $95
                                        must subtract difference for single dependents from dependent total
                                    */
                                    // employerTotal += dependentContrib * employee.dependents.length;
                                    employerTotal += dependentContrib;
                                } else {
                                    // If attempted employer contribution exceeds employee premium,
                                    // only require employer to contribute up to premium dollar amount
                                    employerTotal += depPremiumBeforeContribution;
                                }
                            } else {
                                // Total employer dollars owed after contribution percentage is applied
                                depPremiumAfterContribution = depPremiumBeforeContribution * (dependentContrib / 100);

                                // Add total dollars owed by dependent and employer to totals
                                dependentsTotal += depPremiumBeforeContribution - depPremiumAfterContribution;
                                employerTotal += depPremiumAfterContribution;
                            }
                            break
                        case 'dental':
                        case 'vision':
                            // check if has any dependent
                            //let hasDependent = !['EO', 'EE', 'W'].includes(employee.coverages[product])

                            // Costs to Employees and Dependents before and after contributions are applied 
                            let specialtyDependentTotal = 0;
                            if (employee.coverages[product] === 'W') {
                                eePremiumBeforeContribution = 0
                                specialtyDependentTotal = 0
                            } else {
                                if (isWashingtonTwoTier() && !isAgeBanded) {
                                    eePremiumBeforeContribution = getSpecialtyRate(product, plan, employee.coverages[product], employee, 0, true);
                                    // const specialtyDependentTotal = employee.dependents.length > 0 ? calculateDependentTotalSpecialty(product, employee, plan, 0) : 0;
                                    specialtyDependentTotal = getSpecialtyRate(product, plan, employee.coverages[product], employee, 0, true) - getSpecialtyRate(product, plan, 'EE', employee, 0, true);
                                } else {
                                    eePremiumBeforeContribution = getSpecialtyRate(product, plan, employee.coverages[product], employee, 0, false);
                                    // const specialtyDependentTotal = employee.dependents.length > 0 ? calculateDependentTotalSpecialty(product, employee, plan, 0) : 0;
                                    specialtyDependentTotal = getSpecialtyRate(product, plan, employee.coverages[product], employee, 0, false) - getSpecialtyRate(product, plan, 'EE', employee, 0, false);
                                }
                            }

                            //let hasDependent = !['EO', 'EE', 'W'].includes(employee.coverages[product])


                            // depPremiumBeforeContribution = employee.dependents.length > 0 ? specialtyDependentTotal / employee.dependents.length : 0;
                            depPremiumBeforeContribution = specialtyDependentTotal;

                            // Employee tiered rate must subtract difference between dependent and employee coverages
                            eePremiumBeforeContribution = eePremiumBeforeContribution - specialtyDependentTotal;

                            // Determine dollar amount out of total employee premium to be
                            // paid by employee and employer based on contribution and method
                            if (employeeContributionMethod === '$') {
                                // Total dollars owed by employee after contribution is subtracted from premium
                                eePremiumAfterContribution = eePremiumBeforeContribution - employeeContrib;

                                // Attempted employer contribution $ amount may exceed age-banded rate, only needs to contribute up to rate
                                if (eePremiumAfterContribution >= 0) {
                                    // Add total dollars owed by employee and employer to totals
                                    premiumTotal += eePremiumAfterContribution;
                                    employerTotal += employeeContrib;
                                } else {
                                    // If attempted employer contribution exceeds employee premium,
                                    // only require employer to contribute up to premium dollar amount
                                    employerTotal += eePremiumBeforeContribution;
                                }
                            } else {
                                // Total employer dollars owed after contribution percentage is applied
                                eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);

                                // Add total dollars owed by employee and employer to totals
                                premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                employerTotal += eePremiumAfterContribution;
                            }

                            // Determine dollar amount out of total dependent premium to be
                            // paid by employee and employer based on contribution and method
                            if (employee.dependents.length > 0 || hasDependent) {
                                if (dependentContributionMethod === '$') {
                                    // Total dollars owed by employee after contribution is subtracted from premium
                                    depPremiumAfterContribution = depPremiumBeforeContribution - dependentContrib;

                                    // Attempted dependent contribution $ amount may exceed age-banded rate, only needs to contribute up to rate
                                    if (depPremiumAfterContribution >= 0) {
                                        // Add total dollars owed by dependent and employer to totals
                                        // dependentsTotal += depPremiumAfterContribution * employee.dependents.length;
                                        dependentsTotal += depPremiumAfterContribution
                                        /* 
                                            TODO: If rates for dependents are $90, $100, contributions of $95
                                            must subtract difference for single dependents from dependent total
                                        */
                                        // employerTotal += dependentContrib * employee.dependents.length;
                                        employerTotal += dependentContrib
                                    } else {
                                        // If attempted employer contribution exceeds employee premium,
                                        // only require employer to contribute up to premium dollar amount
                                        // employerTotal += depPremiumBeforeContribution * employee.dependents.length;
                                        employerTotal += depPremiumBeforeContribution
                                    }
                                } else {
                                    // Total employer dollars owed after contribution percentage is applied
                                    depPremiumAfterContribution = depPremiumBeforeContribution * (dependentContrib / 100);

                                    // Add total dollars owed by dependent and employer to totals
                                    // dependentsTotal += (depPremiumBeforeContribution - depPremiumAfterContribution) * employee.dependents.length;
                                    dependentsTotal += (depPremiumBeforeContribution - depPremiumAfterContribution);
                                    // employerTotal += depPremiumAfterContribution * employee.dependents.length;
                                    employerTotal += depPremiumAfterContribution;
                                }
                            }

                            break
                        case 'suppLife':
                            let product2 = 'suppLife'
                                if (employee.coverages[product2] === 'W' || (employee.employeeStatus === 'COBRA' && stateCode != "MN")) {
                                    eePremiumBeforeContribution = 0
                                    depPremiumBeforeContribution = 0
                                    if (employee.employeeStatus === 'COBRA' && stateCode != "MN") {
                                        index = index - 1
                                    }
                                    if(( plan.platform =='PRIME' && (employee.employeeStatus != 'COBRA' && stateCode == "MN")) || employee.coverages[product2] === 'W') {
                                        suppLifeElectedIndex++;
                                    }
                                    else {
                                        suppLifeElectedIndex;
                                    }
                                    
                                }
                                else {
                                    let eeSuppLifeEEPremiumTotal = parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slEEADnDPremium) + parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slEEPremium)

                                    eePremiumBeforeContribution = plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex] && plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slEEADnDPremium >= 0 ? eeSuppLifeEEPremiumTotal: plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex] 
                                    depPremiumBeforeContribution = 0

                                    if (employee.dependents.length > 0 || hasDependent) {

                                        let depSuppLifePremiumTotal = 0;
                                        employee.dependents.forEach((dependents , i)=> {
                                            depSuppLifePremiumTotal +=  dependents.relationship ==='Child' ? ( parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slCHADnDPremium)  
                                            +  parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slCHPremium))
                                            : dependents.relationship ==='Spouse' ? (parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slSPADnDPremium) 
                                            +  parseFloat(plan.finalRates.byCensus.employeePremium[suppLifeElectedIndex].slSPPremium)) : parseFloat(0.0)
                                        }) 

                                        const specialtyDependentTotal1 = (employee.coverages[product2] === 'EE' && employee.dependents.length > 0) ? depSuppLifePremiumTotal : 0

                                        depPremiumBeforeContribution = specialtyDependentTotal1;
                                        dependentsTotal = depPremiumBeforeContribution
                                    }
                                    if (employeeContributionMethod === '$') {
                                        eePremiumAfterContribution = eePremiumBeforeContribution - employeeContrib;
                                        if (eePremiumAfterContribution >= 0) {
                                            // Add total dollars owed by employee and employer to totals
                                            premiumTotal += eePremiumAfterContribution;
                                            employerTotal += employeeContrib;
                                        } else {
                                            // If attempted employer contribution exceeds employee premium,
                                            // only require employer to contribute up to premium dollar amount
                                            employerTotal += eePremiumBeforeContribution;
                                        }
                                    } else {
                                        // Total employer dollars owed after contribution percentage is applied
                                        eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);

                                        // Add total dollars owed by employee and employer to totals
                                        premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                        employerTotal += eePremiumAfterContribution;
                                    }
                                    suppLifeElectedIndex++;
                                }
                            index = index + 1;
                            break
                        case 'life':
                            let product1 = 'basicLife'
                            // Costs to Waived and COBRA Members will be zero for Cost link on Quote review
                            if (life.employeeClassing && life.employeeClassing.classingOpted && plan.selectedClass !== employee.optedLifeClass) {
                                index = 0
                                break
                            }
                            else {
                                if (employee.coverages[product1] === 'W' || (employee.employeeStatus === 'COBRA' && stateCode != "MN")) {

                                    eePremiumBeforeContribution = 0
                                    depPremiumBeforeContribution = 0
                                    if ((employee.employeeStatus === 'COBRA' && stateCode != "MN")) {
                                        index = index - 1;
                                    }
                                    if( (plan.platform =='PRIME' && (employee.employeeStatus != 'COBRA' && stateCode == "MN"))|| (employee.coverages[product1] === 'W' && plan.platform==='PRIME')) {
                                        lifeElectedIndex++
                                    }
                                    else {
                                        lifeElectedIndex;
                                    }
                                }
                                else {
                                    eePremiumBeforeContribution = plan.finalRates.employeeRate[lifeElectedIndex] && plan.finalRates.employeeRate[lifeElectedIndex].Total >= 0 ? plan.finalRates.employeeRate[lifeElectedIndex].Total : plan.finalRates.employeeRate[lifeElectedIndex]

                                    depPremiumBeforeContribution = 0

                                    if (depLife.length > 0) {

                                        //let hasDependentlife = !['EO', 'EE', 'W'].includes(employee.coverages[product1])

                                        const specialtyDependentTotal1 = (employee.coverages[product1] ==='EL' && employee.dependents.length > 0) ? parseFloat(depLife[0].quote.depLifeChildRate) : 0

                                        depPremiumBeforeContribution = specialtyDependentTotal1;
                                        dependentsTotal = depPremiumBeforeContribution
                                    }

                                    if (employeeContributionMethod === '$') {

                                        eePremiumAfterContribution = eePremiumBeforeContribution - employeeContrib;


                                        if (eePremiumAfterContribution >= 0) {
                                            // Add total dollars owed by employee and employer to totals
                                            premiumTotal += eePremiumAfterContribution;
                                            employerTotal += employeeContrib;
                                        } else {
                                            // If attempted employer contribution exceeds employee premium,
                                            // only require employer to contribute up to premium dollar amount
                                            employerTotal += eePremiumBeforeContribution;
                                        }
                                    } else {
                                        // Total employer dollars owed after contribution percentage is applied
                                        eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);

                                        // Add total dollars owed by employee and employer to totals
                                        premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                        employerTotal += eePremiumAfterContribution;
                                    }
                                    lifeElectedIndex++;
                                }
                            }
                            index = index + 1;

                            break

                        case 'ltd':
                        case 'std':
                
                            let tempProductObj = product === 'ltd' ? ltd : std
                            if ((std.employeeClassing && std.employeeClassing.classingOpted && plan.selectedClass !== employee.optedLifeClass) || (ltd.employeeClassing && ltd.employeeClassing.classingOpted && ltd.selectedClass !== ltd.optedLifeClass)) {
                                index = 0
                                break
                            } else {
                                if (employee.coverages[product] === 'W' || (employee.employeeStatus === 'COBRA')) {
                                    eePremiumBeforeContribution = 0
                                    depPremiumBeforeContribution = 0
                                    if (employee.employeeStatus === 'COBRA') {
                                        index = index - 1
                                        }
                                        
                                    if( plan.platform =='PRIME' && employee.employeeStatus != 'COBRA') {
                                        stdLtdIndex++
                                    }
                                    else {
                                        stdLtdIndex;
                                    }
                                } else {
                                    let rateType = plan.rateType.split(' ')[0].toLowerCase()

                                    if (tempProductObj.employeeClassing && tempProductObj.employeeClassing.classingOpted) {
                                        eePremiumBeforeContribution = rateType === "age-banded" ? parseFloat(plan.classRates[plan.selectedClass].finalRates.employeeRatesAgeBanded[stdLtdIndex], 10) : parseFloat(plan.classRates[plan.selectedClass].finalRates.employeeRatesComposite[stdLtdIndex], 10)
                                    } else {
                                        eePremiumBeforeContribution = rateType === "age-banded" ? parseFloat(plan.finalRates.employeeRatesAgeBanded[stdLtdIndex], 10) : parseFloat(plan.finalRates.employeeRatesComposite[stdLtdIndex], 10)
                                    }
        
                                    // Determine dollar amount out of total employee premium to be
                                    // paid by employee and employer based on contribution and method
                                    if (employeeContributionMethod === '$') {
                                        // Total dollars owed by employee after contribution is subtracted from premium
                                        eePremiumAfterContribution = eePremiumBeforeContribution - employeeContrib;
                                        // Attempted employer contribution $ amount may exceed age-banded rate, only needs to contribute up to rate
                                        if (eePremiumAfterContribution >= 0) {
                                            // Add total dollars owed by employee and employer to totals
                                            premiumTotal += eePremiumAfterContribution;
                                            employerTotal += employeeContrib;
                                        } else {
                                            // If attempted employer contribution exceeds employee premium,
                                            // only require employer to contribute up to premium dollar amount
                                            employerTotal += eePremiumBeforeContribution;
                                        }
                                    } else {
                                        // Total employer dollars owed after contribution percentage is applied
                                        eePremiumAfterContribution = eePremiumBeforeContribution * (employeeContrib / 100);
        
                                        // Add total dollars owed by employee and employer to totals
                                        premiumTotal += eePremiumBeforeContribution - eePremiumAfterContribution;
                                        employerTotal += eePremiumAfterContribution;
                                    }
                                    stdLtdIndex++
                                }
                            }
                            index = index + 1;
                            break
                            
                        default:
                            break
                    }

                    const employeeTotal = premiumTotal + dependentsTotal;
                    employerTotal = employerTotal;

                    singlePlanData.employeeTotal += employeeTotal;
                    singlePlanData.employerTotal += employerTotal;

                    const contributionTotals = {
                        employeeName: `${employee.firstName} ${employee.lastName}`,
                        dependentsTotal,
                        premiumTotal,
                        employeeTotal,
                        employerTotal,
                        eePremiumBeforeContribution,
                        depPremiumBeforeContribution
                    };

                    if (contributionTotals.eePremiumBeforeContribution >= 0) {
                        singlePlanData.employees.push(contributionTotals);
                    }

                });
            });
        }


        allPlanTotals.push(singlePlanData);

    });

    return allPlanTotals

}
