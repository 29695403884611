
import {
    appendBasicLifeDetailsInReqBody,
    updateBodyForOverRideRates,
    updateRateReferenceId
} from '../fullQuotesActions';
import * as types from '../actionTypes';
import { axios } from '../../utils/api/axiosConfig';
import { history } from '../../utils/history';
import { updateEmployeeClassFilter } from '../quickQuotesActions';
import { formatPlanOrRateCallError } from '../../utils/formatters/errorFormat';
const apiRoutes = require('../../utils/api/apiRouteConfig');

export const getLifeRatesInClasses = (staticRequestInfo, basicLifePlanCodes,stdPlanCodes,ltdPlanCodes, quoteStatus, destinationPage) => {
    return (dispatch, getState) => {

        dispatch({ type: types.SET_IS_FETCHING_CLASS_RATES, bool: true })

        const empCensus = staticRequestInfo.companyProfile.employees.flat();  // TODO :: Verify in case of multi-location employees
        const employeesMap = empCensus.map((emp, i) => {
            emp.employeeId = emp.memberId ? emp.memberId : i + 1;
            return emp
        });

        const {life,std,ltd}= getState().specialtyCriteria.viewSpecialty
        let classes = []
        if(life){
            classes = getState().life.employeeClassing.classes;
        }else if(std){
            classes = getState().std.employeeClassing.classes;
        }else if(ltd){
            classes = getState().ltd.employeeClassing.classes;
        }

        const censusGroupedByClass = getCensusGroupedByClass(employeesMap, classes);

        // Batch Size : 30
        const lifePlanCodeBatches = getPlanCodesInBatches(basicLifePlanCodes, 30);
        const stdPlancodeBatches = getPlanCodesInBatches(stdPlanCodes, 30);
        const ltdPlancodeBatches = getPlanCodesInBatches(ltdPlanCodes, 30);

        //const planCodesArray= [...lifePlanCodeBatches,...stdPlancodeBatches,...ltdPlancodeBatches]
       if(stdPlancodeBatches.length > 0){
        staticRequestInfo.shortTermDisPlanCodes = stdPlancodeBatches[0]
       }
       if(ltdPlancodeBatches.length > 0){
        staticRequestInfo.longTermDisPlanCodes = ltdPlancodeBatches[0]
       }

        let batchPromises = []
        if(lifePlanCodeBatches.length > 0){
            for (let i = 0; i < lifePlanCodeBatches.length; i++) {
                let batch = classes.map((lifeClass) => {
                    if (censusGroupedByClass[lifeClass].length > 0) {
                        return dispatch(requestLifeRateBatch(lifePlanCodeBatches[i], staticRequestInfo, quoteStatus, lifeClass, censusGroupedByClass[lifeClass]))
                    }
                    else {
                        return
                    }
                });
                batchPromises = batchPromises.concat(batch)
            }
        } else if(stdPlancodeBatches.length > 0){
            for (let i = 0; i < stdPlancodeBatches.length; i++) {
                let batch = classes.map((lifeClass) => {
                    if (censusGroupedByClass[lifeClass].length > 0) {

                        return dispatch(requestLifeRateBatch([], {...staticRequestInfo,shortTermDisPlanCodes: stdPlancodeBatches[i]}, quoteStatus, lifeClass, censusGroupedByClass[lifeClass]))
                    }
                    else {
                        return
                    }
                });
                batchPromises = batchPromises.concat(batch)
            }
        } else if(ltdPlancodeBatches.length > 0){
            for (let i = 0; i < ltdPlancodeBatches.length; i++) {
                let batch = classes.map((lifeClass) => {
                    if (censusGroupedByClass[lifeClass].length > 0) {

                        return dispatch(requestLifeRateBatch([], {...staticRequestInfo,longTermDisPlanCodes: ltdPlancodeBatches[i]}, quoteStatus, lifeClass, censusGroupedByClass[lifeClass]))
                    }
                    else {
                        return
                    }
                });
                batchPromises = batchPromises.concat(batch)
            }
        }
        Promise.all(batchPromises).then(() => {
            dispatch({type: types.SET_IS_FETCHING_CLASS_RATES, bool: false})
            dispatch(updateEmployeeClassFilter(getState().life.employeeClassing.selectedClass, 'life'))
            dispatch(updateEmployeeClassFilter(getState().std.employeeClassing.selectedClass, 'std'))
            dispatch(updateEmployeeClassFilter(getState().ltd.employeeClassing.selectedClass, 'ltd'))
        })

    }
}

const getCensusGroupedByClass = (employees, classes) => {
    const mapObj = {};
    for (let i = 0; i < classes.length; i++) {
        mapObj[classes[i]] = employees.filter(employee => employee.optedLifeClass === classes[i]);
    }
    return mapObj;
}


const getPlanCodesInBatches = (planCodes, batchSize) => {
    let planCodeBatchesArray = [];
    for (let i = 0; i < planCodes.length; i += batchSize) {
        planCodeBatchesArray.push(planCodes.slice(i, i + batchSize));
    }
    return planCodeBatchesArray;
}


const requestLifeRateBatch = (planCodes,staticRequestInfo, quoteStatus, empClass, empCensus)  => {
    return (dispatch, getState) => {

        const staticSpecialtyRequestString = `${apiRoutes.quickQuotes}/specialty-rates`;
        const reqBody = {
            groupSize: staticRequestInfo.groupSize,
            zipCode: staticRequestInfo.zipCode,
            effectiveDate: staticRequestInfo.effectiveDate,
            stateCode: staticRequestInfo.stateCode,
            countyCode: staticRequestInfo.countyCode,
            platform: staticRequestInfo.platform,
            sicCode: staticRequestInfo.companyProfile.sicCode,
            empCensus: empCensus,
            quoteStatus: quoteStatus,
            platformRatingIDs: staticRequestInfo.platformRatingIDs,
            franchiseCode : staticRequestInfo.companyProfile.franchiseCode,
            companyName: getState().companyProfile.companyName ? getState().companyProfile.companyName : ''
        };

        // Length check is not required.
        if (planCodes.length > 0) {
            const { life, lifeDep } = getState()
            const lifePlans = life.plans.concat(life.selectedPlans, lifeDep.plans, lifeDep.selectedPlans);
            appendBasicLifeDetailsInReqBody(lifePlans, planCodes, reqBody);
        }

        if (staticRequestInfo.shortTermDisPlanCodes) {
            reqBody.shortTermDisPlanCodes = staticRequestInfo.shortTermDisPlanCodes;
        }
        if (staticRequestInfo.longTermDisPlanCodes) {
            reqBody.longTermDisPlanCodes = staticRequestInfo.longTermDisPlanCodes;
        }


        // reqBody.franchiseCode = getState().companyProfile.franchiseCode;
        if(quoteStatus === 'Proposed' || quoteStatus === 'ProposedEnrolled' || quoteStatus === 'PreInstallCensusChange')reqBody.ratingCallType = 'proposal'
        if(quoteStatus === 'Shopping') reqBody.ratingCallType = 'shopping-full-quote'
        if(quoteStatus === 'Installation') reqBody.ratingCallType = 'finalize-enrollment'

        updateBodyForOverRideRates(reqBody, getState(), true, true, empClass);

        //dispatch({ type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_RATES });

        const selectedClass = getState().life.employeeClassing.selectedClass;
        const selectedStdClass = getState().std.employeeClassing.selectedClass;
        const selectedLtdClass = getState().ltd.employeeClassing.selectedClass;

        dispatch({ type: types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_RATES });
        dispatch({ type: types.SERVICE.STD.REQUEST_QUICK_QUOTE_RATES });
        dispatch({ type: types.SERVICE.LTD.REQUEST_QUICK_QUOTE_RATES });

        return new Promise((resolve, reject) => {
            const { _id: id } = getState().companyProfile;
            axios
                .post(staticSpecialtyRequestString, reqBody)
                .then((res) => {
                    const lifeRates = res.data.basicLifeRates ? res.data.basicLifeRates : [];
                    const stdRates = res.data.shortTermDisRates ? res.data.shortTermDisRates : [];
                    const ltdRates = res.data.longTermDisRates ? res.data.longTermDisRates : [];
                    dispatch({
                        type: types.UPDATE_QUOTE_PROGRESS,
                        quoteProgress: quoteStatus,
                    });

                    if (quoteStatus === 'Proposed' || quoteStatus === 'Installation' || quoteStatus === 'ProposedEnrolled') {
                        const platformRatingIDs = {
                            lifeRatingId: (lifeRates && lifeRates[0]) ? (lifeRates[0].quoteId) : null ,
                            stdRatingId: (stdRates && stdRates[0]) ? (stdRates[0].quoteId) : null ,
                            ltdRatingId: (ltdRates && ltdRates[0]) ? (ltdRates[0].quoteId) : null
                        };
                        if (platformRatingIDs['lifeRatingId'] === null) {
                            delete platformRatingIDs['lifeRatingId']
                        }
                        if (platformRatingIDs.stdRatingId === null) {
                            delete platformRatingIDs.stdRatingId;
                        }
                        if (platformRatingIDs.ltdRatingId === null) {
                            delete platformRatingIDs.ltdRatingId;
                        }
                        dispatch(updateRateReferenceId(platformRatingIDs, quoteStatus, empClass));
                    }

                    if(quoteStatus === 'Shopping'){
                        if(lifeRates){
                            dispatch({
                                type : types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_RATES,
                                index: 0, // TODO: BLCRC
                                rates : lifeRates,
                                selectedClass : selectedClass,
                                empClass : empClass,
                                filteredResultsOnly: false,
                            })
                        }
                        if(stdRates){
                            dispatch({
                                type : types.SERVICE.STD.RECEIVE_QUICK_QUOTE_RATES,
                                index: 0, // TODO: BLCRC
                                rates : stdRates,
                                selectedClass : selectedStdClass,
                                empClass : empClass,
                                filteredResultsOnly: false,
                                platform: staticRequestInfo.platform,
                            })
                        }
                        if(ltdRates){
                            dispatch({
                                type : types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_RATES,
                                index: 0, // TODO: BLCRC
                                rates : ltdRates,
                                selectedClass : selectedLtdClass,
                                empClass : empClass,
                                filteredResultsOnly: false,
                                platform: staticRequestInfo.platform,
                            })
                        }
                        history.push('/plans');
                    }else if (quoteStatus === 'PreInstallCensusChange' || quoteStatus === 'Installation' || quoteStatus === 'PostEnrollPreInstall') {
                        if (quoteStatus !== 'ProposedEnrolled') {
                            dispatch({
                                type:types.SERVICE.FI.RECEIVE_FINALIZED_QUOTE_RATES,
                                rates: [],
                                dentalRates: [],
                                visionRates: [],
                                lifeRates: lifeRates,
                                stdRates:stdRates,
                                ltdRates:ltdRates,
                                flatEmployees : empCensus,
                                classingOpted : true,
                                empClass: empClass
                            });
                        }
                    }else if(quoteStatus === 'ProposedEnrolled') {
                        if(stdRates){
                            dispatch({
                                type : types.SERVICE.STD.RECEIVE_QUICK_QUOTE_RATES,
                                index: 0, // TODO: BLCRC
                                rates : stdRates,
                                selectedClass : selectedStdClass,
                                empClass : empClass,
                                filteredResultsOnly: false,
                                platform: staticRequestInfo.platform,
                            })
                        }
                        if(ltdRates){
                            dispatch({
                                type : types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_RATES,
                                index: 0, // TODO: BLCRC
                                rates : ltdRates,
                                selectedClass : selectedLtdClass,
                                empClass : empClass,
                                filteredResultsOnly: false,
                                platform: staticRequestInfo.platform,
                            })
                        }
                    }
                    else if (quoteStatus === 'Proposed') {
                        if (lifeRates) {
                            dispatch({
                                type: types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: lifeRates,
                                classingOpted : true,
                                empClass : empClass
                            })
                        }
                        if (stdRates.length) {
                            dispatch({
                                type: types.SERVICE.STD.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: stdRates,
                                classingOpted : true,
                                empClass : empClass,
                                platform: staticRequestInfo.platform,
                            })
                        }
                        if (ltdRates.length) {
                            dispatch({
                                type: types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES,
                                rates: ltdRates,
                                classingOpted : true,
                                empClass : empClass,
                                platform: staticRequestInfo.platform,
                            })
                        }
                    }
                    resolve();
                })
                .catch((err) => {

                    dispatch({
                        type: types.SERVICE.FI.ERROR_QUICK_QUOTE_RATES,
                        payload: formatPlanOrRateCallError(err, 'rate', true),
                    });
                    reject(err);
                });
        })
    }

}


