import * as types from '../../actions/actionTypes';

const initialState = {
    isUpdating: true,
    isCompleted: false,
    submissionError: false,
    CompanyInformation: {
        companyName: {
            value: '',
            error: null,
        },
        taxId: {
            value: '',
            error: null,
        },
        employerEmail: {
            value: '',
            error: null,
        },
    },
    AccountInformation: {
        accountHolder: {
            value: '',
            error: null,
        },
        holderTitle: {
            value: '',
            error: false,
        },
        finInstitute: {
            value: '',
            error: null,
        },
        phone: {
            value: '',
            error: false,
        },
        routing: {
            value: '',
            error: null,
        },
        account: {
            value: '',
            error: null,
        },
        ownerPcisId : {
            value :'',
            error : false
        }
    },
    Understanding: {
        termsAgreement: {
            value: false,
            error: null,
        },
        recordRetainedAcknowledgement: {
            value: false,
            error: null,
        },
        firstSignature: {
            value: '',
            error: null,
        },
        firstDate: {
            value: '',
            error: null,
        },
        secondSignature: {
            value: '',
            error: null,
        },
        secondDate: {
            value: '',
            error: null,
        },
    },
};

function debitAuthReducer(state = initialState, action) {
    const { section, field } = action;
    // Todo: maybe add case to retrieve form from Mongo
    switch (action.type) {
        // Debit Input, changes to input
        case types.DEBIT_ACTIONS.DEBIT_INPUT:
            return {
                ...state,
                [section]: {
                    ...state[section],
                    [field]: {
                        value: action.value,
                        error: action.error,
                    },
                },
                isUpdating: true,
            };
        // Submit/Save the form
        case types.DEBIT_ACTIONS.DEBIT_UPDATED:
            return {
                ...state,
                ...action.debitForm,
                isCompleted: true,
                isUpdating: false,
                submissionError: false,
            };
        case types.DEBIT_ACTIONS.DEBIT_UPDATE_ERROR:
            return {
                ...state,
                isUpdating: true,
                submissionError: true,
            };
        case types.DEBIT_ACTIONS.DEBIT_CANCEL:
            return {
                ...state,
                ...action.debitForm,
            };
        case types.DEBIT_ACTIONS.DEBIT_UPDATE_OWNER_ID:
            return {
                ...state,
                AccountInformation :{
                    ...state.AccountInformation,
                    ownerPcisId : {
                        error:false,
                        value : action.val
                    }
                }
            }
        // Deleting the values, ie when debit form is removed as payment method
        case types.DEBIT_ACTIONS.DEBIT_RESET:
        case types.RESET_FLOW:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
            return initialState
        default:
            return state;
    }
}

export default debitAuthReducer;
