import * as types from '../../actions/actionTypes';
import {
    handleRequestPlans, handleReceiveSpecialtyPlans, handleRequestRates, handleReceiveSpecialtyRates, handleReceiveSpecialtyAllPlans, handleReceiveSpecialtyPlanRates, handleReceiveSpecialtySelectedPlanRates, resetDetails,
    handleRequestPlansRenewals, handleReceiveSpecialtyPlansRenewals, handleReceiveSpecialtyAllPlansRenewals,
} from './helpers/fetchingReducerHelpers';
import {
    handleUpdatePlan, handleUpdateAllPlans, handleChangeFilterCriteria, handleChangeSortOrder, handleResetFilterCriteria, handleTogglePreferredPlans,
    handleRatingMethodChange
} from './helpers/filteringReducerHelpers';
import { handleAdjustRatesAndPremium } from './helpers/rateAdjustmentHelper';
import { initialFilterCriteria, initialState, initialfilterTotalsCache } from './consts/visionReducerConsts';
import {
    setAdvanceFilterData, setAdvanceFilterWith30Plans, handleFilterChange, viewFilteredPlans, resetFitlers, setBasicFilterDirectly, setBasicFilterDirectlyUhc
} from './helpers/advanceFilterHelper';

export default function vision(state = initialState, action) {
    switch (action.type) {
        case types.SERVICE.VISION.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state,action, initialFilterCriteria, initialfilterTotalsCache);
        case types.SERVICE.VISION.REQUEST_RENEWAL_PLANS:
            return handleRequestPlansRenewals(state, initialFilterCriteria, initialfilterTotalsCache, action.fullProps, 'vision');

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action);
        case types.SERVICE.VISION.RECEIVE_RENEWAL_PLANS:
            return handleReceiveSpecialtyPlansRenewals(state, action.payload.plans, action.fullProps, 'vision');
        case types.SERVICE.VISION.SET_RENEWAL_PLANS_RECEIVED:
            return {
                ...state,
                isFetchingPlans: false,
                [`renewalvisionReceived`]: true
            }

        case types.SERVICE.VISION.REQUEST_QUICK_QUOTE_RATES:
        case types.SERVICE.VISION.REQUEST_RENEWAL_RATES:
            return handleRequestRates(state);

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_RATES:
        case types.SERVICE.VISION.RECEIVE_RENEWAL_RATES:
            return handleReceiveSpecialtyRates(state, action);

        case types.SERVICE.VISION.UPDATE_PLAN:
            return handleUpdatePlan(state, action);

        case types.SERVICE.VISION.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlans: [],
                selectedPlansMap: {},
            };

        case types.SERVICE.VISION.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action);

        case types.SERVICE.VISION.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.CHANGE_RATE_TIER_FOR_SPECIALTY:
            return handleRatingMethodChange(state, action);

        case types.SERVICE.VISION.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        case types.SERVICE.VISION.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.VISION.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_ALL_PLANS:
            return handleReceiveSpecialtyAllPlans(state, action.payload);

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS:
            return handleReceiveSpecialtyAllPlansRenewals(state, action.payload, action.fullProps, 'vision');

        case types.SERVICE.VISION.SET_ADVANCE_FILTER_DATA:
            return setAdvanceFilterData(state, state.allPlans);

        case types.SERVICE.VISION.SET_ADVANCE_FILTER_30_PLANS:
            return setAdvanceFilterWith30Plans(state, state.allPlans);    

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_PLAN_RATES:
            return handleReceiveSpecialtyPlanRates(state, action);

        case types.SERVICE.VISION.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES:
            return handleReceiveSpecialtySelectedPlanRates(state, action);

        case types.SERVICE.VISION.APPLY_FILTER:
            return handleFilterChange(state, action.payload);

        case types.SERVICE.VISION.VIEW_FILTERED_PLANS:
            return viewFilteredPlans(state, action.payload);

        case types.SERVICE.VISION.RESET_FILTERS:
            return resetFitlers(state);

     //   case types.SERVICE.VISION.SET_BASIC_FILTERS_DIRECTLY_UHC:
     //       return setBasicFilterDirectlyUhc(state);

        case types.SERVICE.VISION.SET_BASIC_FILTERS_DIRECTLY:
            return setBasicFilterDirectly(state);

        case types.SERVICE.VISION.RESET_DATA:
            return resetDetails(state, initialFilterCriteria, initialfilterTotalsCache);

        case types.SERVICE.VISION.ADJUST_RATE:
            return handleAdjustRatesAndPremium(state, action);

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
        case types.RESET_SHOPPING_FLOW_SPECIALTY:
            return initialState;
            
        case types.RESET_RENEWAL_FLOW:
            return {
                ...state,
                selectedPlans: [],
            };
        case types.RESET_FLOW_DVL_REVIEW_PAGE: {
            const newVisiblePlans = (state.visiblePlans || []).map((plan) => {
                const item = {
                    ...plan,
                    selected: false,
                };
                return item;
            });

            return {
                ...state,
                selectedPlans: [],
                selectedPlansMap: {
                    Single: [],
                },
                visiblePlans: newVisiblePlans,
            };
        }
        case types.UPDATE_SELECTED_VISION_PLAN:
            return {
                ...state,
                selectedPlans: action.payload,
                selectedPlansMap: {
                    Single: action.payload,
                },
            };
        case types.USER_FAVORITE_VISION_PLANS:
            const { allPlans, plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                allPlans,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        default:
            return state;
    }
}
