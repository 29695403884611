import { isEmpty } from "../../../../utils/formatters/strings";
import { getAgeBandedRate, getCompositeRate } from "../../../../utils/businessLogic/calculateQuotes";
import { toMoney } from "../../../../utils/formatters/numbers";
import { formatRenewalDataMembers, formatIBRMembersMapping } from "../RenewalsCommonComponents/saveDetails";
import { FilterRenewalsMedicalData } from "../RenewalsCommonComponents/FilterRenewalsMedicalData";
import { calculateDependentTotal } from "../../../shared/EstEmployerCost/calculateDependentTotal";
import { isUndefinedOrNullOrEmpty } from "../../../../utils/validation/commonValidations";
import { displayTieredCoverageTypeViaProducts } from "../RenewalsCommonComponents/saveDetails";
import { isoStringToMMDDYYYY } from '../../../../utils/formatters/dates';
import { isUhcRenewals } from "../../../../utils/businessLogic/platformUtils";
import { getZipObjUhcRenewals } from "../../../../utils/Renewal/getZipObjUhc";

const coverageMapping = {
    "EO": "EE",
    "EC": "EE/CH",
    "ES": "EE/SP",
    "EF": "EE/FAM",
}

export const getEligibleCounts = (fullProps, employeeCounts) => {
    const {companyProfile} = fullProps;
    if(isUhcRenewals()){
        return employeeCounts.eligiblesCount;
    } else if(companyProfile && companyProfile.locations && companyProfile.locations[0]) {
        if(companyProfile.locations[0].fteRule === 'FTE') {
            return employeeCounts.fteCount;
        } else if(companyProfile.locations[0].fteRule === 'ATNE') {
            return employeeCounts.atneCount;
        } else if(companyProfile.locations[0].fteRule === 'Eligible') {
            return employeeCounts.eligiblesCount;
        }
    }
    return employeeCounts.fteCount;
}

export const shoppingRequest = (queryData, Ny_shop, isPrimeIbr = false) => {
    let location;
    let groupSize;
    let employerContribution = {};
    let fteCount;
    let atneCount;
    let eligiblesCount;
    let employeeClasses = {};
    let employeeCounts = {};

    if (queryData && queryData.renewalData && queryData.renewalData.memberGroup) {
        location = queryData.renewalData.memberGroup.locations.location;

        if (queryData.renewalData.memberGroup.locations.employeeCounts) {
            if(isUhcRenewals()){
                queryData.renewalData.memberGroup.locations.employeeCounts.map(empType => {
                    if(empType.type.toLowerCase() === "fte") fteCount = parseInt(empType.count);
                    else if (empType.type.toLowerCase() === "atne") atneCount = parseInt(empType.count);
                    else if (empType.type.toLowerCase() === "eligibles" || empType.type.toLowerCase() === "eligible") eligiblesCount = parseInt(empType.count);
                })
            }else{
                queryData.renewalData.memberGroup.locations.employeeCounts.map(empType => {
                    fteCount = (empType.type.toLowerCase() === "fte") ? empType.count : "";
                    atneCount = (empType.type.toLowerCase() === "atne") ? empType.count : "";
                    eligiblesCount = (empType.type.toLowerCase() === "eligibles" || empType.type.toLowerCase() === "eligible") ? empType.count : "";
    
                })
            }
            employeeCounts.fteCount = fteCount;
            employeeCounts.atneCount = atneCount;
            employeeCounts.eligiblesCount = eligiblesCount;
        }
        employeeClasses.id = queryData.renewalData.memberGroup.employeeClasses ? queryData.renewalData.memberGroup.employeeClasses[0]._id : ""
        employeeClasses.name = queryData.renewalData.memberGroup.employeeClasses ? queryData.renewalData.memberGroup.employeeClasses[0].name : "";
        if (queryData.renewalData.memberGroup.employerContribution) {
            employerContribution.productType = queryData.renewalData.memberGroup.employerContribution.productType;
            employerContribution.classId = queryData.renewalData.memberGroup.employerContribution.classId;
            employerContribution.subscriberType = queryData.renewalData.memberGroup.employerContribution.subscriber.type;
            employerContribution.subscriberValue = queryData.mdvContribution?.medical?.eValue ? queryData.mdvContribution.medical.eValue : queryData.renewalData.memberGroup.employerContribution.subscriber.value;
            employerContribution.dependentType = queryData.renewalData.memberGroup.employerContribution.dependent.type;
            employerContribution.dependentValue = queryData.mdvContribution?.medical?.dValue? queryData.mdvContribution.medical.dValue : queryData.renewalData.memberGroup.employerContribution.dependent.value;
        } else {
            employerContribution.productType = 'Medical';
            employerContribution.classId = '1';
            employerContribution.subscriberType = 'Percent';
            employerContribution.subscriberValue = queryData.mdvContribution?.medical?.eValue ? queryData.mdvContribution.medical.eValue :0;
            employerContribution.dependentType = 'Percent';
            employerContribution.dependentValue = queryData.mdvContribution?.medical?.dValue? queryData.mdvContribution.medical.dValue :0;
        }
    }

    let renewalEmployees = queryData && queryData.renewalData ? formatRenewalDataMembers(queryData.renewalData) : {}
    groupSize = renewalEmployees && renewalEmployees.length !== 0 ? renewalEmployees.length : 0;


    if (queryData && queryData.renewalData && queryData.renewalData.members) {
        var employees = [];
        let newArray = [];
        if (!isPrimeIbr) {
            queryData.renewalData.members.forEach((x, i) => {
                if (x.relationship === 'Self') {
                    employees.push({
                        employeeId: x.employeeId,
                        firstName: x.firstName,
                        lastName: x.lastName,
                        coverages: {
                            medical: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                            dental: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                            vision: coverageMapping[x.coverageLevel ? x.coverageLevel : displayTieredCoverageTypeViaProducts(x, 'Medical')],
                        },
                        age: x.age,
                        // dob: (new Date(x.birthDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                        dob: isoStringToMMDDYYYY(x.birthDate),
                        salary: x.salary,
                        gender: x.gender,
                        smoker: x.smoker,
                        classId: x.classId,
                        employeeStatus: x.status,
                        zipCode: x.zipCode,
                        dependents: []
                    });
                }
            })
            queryData.renewalData.members.forEach((z, i) => {
                if (z.relationship === 'Spouse' || z.relationship === 'Life Partner' || z.relationship === 'Domestic Partner' || z.relationship === 'Civil Union Partner' || z.relationship === 'Child' || z.relationship === 'Dependent Child') {
                    if (employees && employees.length > 0) {
                        employees.forEach((y, i) => {
                            if (z.employeeId === y.employeeId) {
                                employees[i].dependents.push({
                                    employeeId: z.employeeId,
                                    relationship: z.relationship ? z.relationship : '',
                                    // dob: (new Date(z.birthDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })),
                                    dob: isoStringToMMDDYYYY(z.birthDate),
                                    age: z.age,
                                    gender: z.gender,
                                    smoker: z.smoker,
                                });
                            }
                        })
                    }
                }
            })
        }
        else {
            employees = formatIBRMembersMapping(queryData.renewalData)
        }
    }

    const companyName = (queryData.renewalCaseData && queryData.renewalCaseData.memberGroupName) ? queryData.renewalCaseData.memberGroupName : "";
    // TO DO will remove this line once everything works fine.
    // const brokerID = (queryData.agentData && queryData.agentData[0].brokerPcisId) ? queryData.agentData[0].brokerPcisId.toString() : "";
    let zipObj = isUhcRenewals() ? getZipObjUhcRenewals(queryData.renewalCaseData) : {
        zipCode: (location && location.zipCode) ? location.zipCode : '',
        stateCode: (location && location.state) ? location.state : '',
        countyFIPSCode: (location && !isUndefinedOrNullOrEmpty(location.county)) ? location.county.slice(-3) : ' ',
        county: (location && !isUndefinedOrNullOrEmpty(location.county)) ? location.county : ' ',
        isPrimary: (location && location.isPrimary) ? location.isPrimary : ''
    }
        

    const validRequest = groupSize > 0 && !isEmpty(zipObj.zipCode) && !isEmpty(queryData.renewalCaseData.effectiveDate);
    if (validRequest) {
        const includeSpecialty = false;
        const selectedProducts = ["medical"];
        const viewSpecialty = {
            dental: false,
            life: false,
            ltd: false,
            medical: true,
            std: false,
            sul: false,
            vision: false
        }

        const originalSIC = queryData.renewalData && queryData.renewalData.memberGroup && queryData.renewalData.memberGroup.industryCode && queryData.renewalData.memberGroup.industryCode !== '' ? queryData.renewalData.memberGroup.industryCode : null
        const updatedSIC = queryData.renewalData && queryData.renewalData.userEnteredSic && queryData.renewalData.userEnteredSic !== '' ? queryData.renewalData.userEnteredSic : null
        const sicCode = originalSIC ? originalSIC : updatedSIC ? updatedSIC : '9900'

        const otherDetails = {
            includeSpecialty: includeSpecialty,
            selectedProducts: selectedProducts,
            viewSpecialty: viewSpecialty,
            sicCode: sicCode
        };
        const companyProfile = {
            companyName: companyName,
            sicCode: sicCode
        }

        var shoppingReq = {
            groupSize: !isPrimeIbr ? groupSize : employees.length,
            zipObj: zipObj,
            //TODO :  WIll remove the hardcoded date ones we get the correct info
            effectiveDate: queryData.renewalCaseData.effectiveDate ? queryData.renewalCaseData.effectiveDate : '',
            // effectiveDate: '2021-02-01T00:00:00.000Z',
            employeeCounts: employeeCounts,
            employeeClasses: employeeClasses,
            employees: employees,
            // TO DO will remove this line once everything works fine.
            // brokerID: brokerID,
            includeSpecialty: false,
            companyProfile: companyProfile,
            Ny_shop: Ny_shop,
            otherDetails: otherDetails,
            memberGroupId: queryData.renewalCaseData.memberGroupId,
        }
        if(!isPrimeIbr)
            shoppingReq.employerContribution = employerContribution
    }
    return shoppingReq;

};

export const getRenewingMedicalPlanRates = (data, shoppingReq) => {

    let premiumObj = {}
    data.renewalData && data.renewalData.planRates.map(planInfo => {
        if (planInfo.renewalPlanType === 'Renewal') {
            // shoppingReq.employees.forEach(employee => {
            //     let premiumRate = 0
            //     for (let i = 1; i < planInfo.rateTable.rate.length; i++) {
            //         if (employee.age.toString() === planInfo.rateTable.rate[i].band) {
            //             premiumRate = planInfo.rateTable.rate[i].total
            //         }
            //     }
            //     if (premiumRate === 0) {
            //         premiumRate = planInfo.rateTable.rate[0].total
            //     }
            //     premium = premium + premiumRate
            // })
            premiumObj[planInfo.planCode] = planInfo.rateTotals[0].total
        }
    })

    return premiumObj
}