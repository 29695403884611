// Employees is the array of employee groups defined in the company profile
const hasAtLeastOneOOA = employees => {
    let hasOOA = false;
    outerLoop:
    for (let i = 0; i < employees.length; i++) {
        let employeeArray = employees[i];
        for (let j = 0; j < employeeArray.length; j++) {
            let employee = employeeArray[j];
            if (employee && employee.outOfArea === "Yes") {
                hasOOA = true;
                break outerLoop;
            }
        }
    }
    return hasOOA;
}

export default hasAtLeastOneOOA;