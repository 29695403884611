import deepCopy from "../deepCopy";

export const removeFromPath = (obj, keys, index) => {
    const path = keys[index];
    if (obj.hasOwnProperty(path) && index < keys.length - 1) {
        removeFromPath(obj[path], keys, ++index);
    } else {
        delete obj[path];
    }
}

export const updateValue = (obj, keys, index, value) => {
    const path = keys[index];
    if (index < keys.length - 1) {
        if (!obj.hasOwnProperty(path)) {
            obj[path] = {};
        }
        updateValue(obj[path], keys, ++index, value);
    } else {
        obj[path] = value;
    }
}

export const getValue = (obj, dataPath) => {
    let objCopy = deepCopy(obj);
    const pathArray = dataPath.split(".");
    for (let i = 0; i < pathArray.length; i++) {
        objCopy = objCopy && objCopy[pathArray[i]];
    }
    return objCopy;
}