const showFranchiseCode = (primaryLocation) => {
    if (!primaryLocation
        || !primaryLocation.zipData
        || !primaryLocation.zipData.stateCode) {
        return false;
    }
    if (['CT', 'NY', 'NJ'].includes(primaryLocation.zipData.stateCode)) {
        return false;
    }
    return true;
};

export default showFranchiseCode;
