import { isUndefinedOrNull } from "../../../../utils/validation/commonValidations";
import { isUhcRenewals } from "../../../../utils/businessLogic/platformUtils";

export function checkForChangesInProductsReviewPage(reviewData, isDecidePage = false) {
    let totalNumSelectedPlans = 0;
    let medicalChanged = false;
    let dentalChanged = false;
    let visionChanged = false;
    let lifeChanged = false;
    let depLifeChanged = false;

    // MEDICAL
    let medicalTotalRenewingPlans = 0;
    let medicalSelectedRenewingPlans = 0;

    let boolNoEnrolledRemoved = false;

    if(isUhcRenewals()) {
        if(reviewData.medical) {
            const medicalPlans = reviewData.medical;
            medicalTotalRenewingPlans = medicalPlans.set ? medicalPlans.set.length : 0;
            medicalSelectedRenewingPlans = medicalPlans.selectedPlans ? medicalPlans.selectedPlans.length : 0;
            totalNumSelectedPlans += medicalSelectedRenewingPlans;
        }
    } else {
        if (reviewData.renewalData && reviewData.renewalData.planRates) {
            reviewData.renewalData.planRates.map((plan) => {
            if (plan.productType === 'Medical' && plan.renewalPlanType === 'Renewal') {
                    medicalTotalRenewingPlans += 1;
                    if (plan.isChecked === null || plan.isChecked === true || !plan.hasOwnProperty('isChecked')) {
                        medicalSelectedRenewingPlans += 1;
                    }
                    if ((plan.isChecked === null || plan.isChecked !== true) && !plan.employeeRates) {
                        boolNoEnrolledRemoved = true;
                    }
                }
            });

            totalNumSelectedPlans += medicalSelectedRenewingPlans;

            if (isDecidePage) {
                let additionalBool = false;
                reviewData.shoppingPlans.map((shopPlan) => {
                    if (!shopPlan.hasOwnProperty('renewalPlan') && !shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
                });
                medicalChanged = additionalBool || medicalChanged;

                if (medicalTotalRenewingPlans !== medicalSelectedRenewingPlans) {
                    if (!additionalBool) {
                        medicalChanged = !boolNoEnrolledRemoved;
                    } else {
                        medicalChanged = true;
                    }
                }
            }

            if (medicalTotalRenewingPlans !== medicalSelectedRenewingPlans && !isDecidePage) {
                medicalChanged = true;
            }
        }
    }

    // DENTAL
    let dentalTotalRenewingPlans;
    let dentalSelectedRenewingPlans;
    let dentalSelectedAlternatePlans;
    let dentalSelectedPreferredPlans;

    if (reviewData.dental && reviewData.dental.selectedPlans) {
        dentalTotalRenewingPlans = reviewData.dental.set ? reviewData.dental.set.length : 0;
        dentalSelectedPreferredPlans = 0;
        dentalSelectedRenewingPlans = 0;
        dentalSelectedAlternatePlans = 0;
        reviewData.dental.selectedPlans.map((dentalPlan) => {
            if (dentalPlan.renewalPlanType && dentalPlan.renewalPlanType == 'renewing') {
                dentalSelectedRenewingPlans += 1;
            } else if (dentalPlan.renewalPlanType && dentalPlan.renewalPlanType == 'alternate') {
                dentalSelectedAlternatePlans += 1;
            } else {
                dentalSelectedPreferredPlans += 1;
            }
        });
        totalNumSelectedPlans += reviewData.dental.selectedPlans.length;

        if (isDecidePage) {
            let additionalBool = false;
            reviewData.shoppingDentalPlans.map((shopPlan) => {
                if (!shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
            });
            dentalChanged = additionalBool || dentalChanged;
        }

        if (dentalTotalRenewingPlans !== dentalSelectedRenewingPlans || (dentalTotalRenewingPlans == 0 && dentalSelectedAlternatePlans > 0) || (dentalTotalRenewingPlans == 0 && dentalSelectedPreferredPlans > 0)) {
            dentalChanged = true;
        }
    }

    // VISION
    let visionTotalRenewingPlans;
    let visionSelectedRenewingPlans;
    let visionSelectedAlternatePlans;
    let visionSelectedPreferredPlans;

    if (reviewData.vision && reviewData.vision.selectedPlans) {
        visionTotalRenewingPlans = reviewData.vision.set ? reviewData.vision.set.length : 0;
        visionSelectedRenewingPlans = 0;
        visionSelectedAlternatePlans = 0;
        visionSelectedPreferredPlans = 0;
        reviewData.vision.selectedPlans.map((visionPlan) => {
            if (visionPlan.renewalPlanType && visionPlan.renewalPlanType == 'renewing') {
                visionSelectedRenewingPlans += 1;
            } else if (visionPlan.renewalPlanType && visionPlan.renewalPlanType == 'alternate') {
                visionSelectedAlternatePlans += 1;
            } else {
                visionSelectedPreferredPlans += 1;
            }
        });
        totalNumSelectedPlans += reviewData.vision.selectedPlans.length;

        if (isDecidePage) {
            let additionalBool = false;
            reviewData.shoppingVisionPlans.map((shopPlan) => {
                if (!shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
            });
            visionChanged = additionalBool || visionChanged;
        }

        if ((visionTotalRenewingPlans > 0 && visionTotalRenewingPlans !== visionSelectedRenewingPlans) || (visionTotalRenewingPlans == 0 && visionSelectedAlternatePlans > 0) || (visionTotalRenewingPlans == 0 && visionSelectedPreferredPlans > 0)) {
            visionChanged = true;
        }
    }

    // LIFE
    let lifeTotalRenewingPlans;
    let lifeSelectedRenewingPlans;
    let lifeSelectedAlternatePlans;
    let lifeSelectedPreferredPlans;

    if (reviewData.life && reviewData.life.selectedPlans) {
        lifeTotalRenewingPlans = reviewData.life.set ? reviewData.life.set.length : 0;
        lifeSelectedRenewingPlans = 0;
        lifeSelectedAlternatePlans = 0;
        lifeSelectedPreferredPlans = 0;
        reviewData.life.selectedPlans.map((lifePlan) => {
            if (lifePlan.renewalPlanType && lifePlan.renewalPlanType == 'renewing') {
                lifeSelectedRenewingPlans += 1;
            } else if (lifePlan.renewalPlanType && lifePlan.renewalPlanType == 'alternate') {
                lifeSelectedAlternatePlans += 1;
            } else {
                lifeSelectedPreferredPlans += 1;
            }
        });
        totalNumSelectedPlans += reviewData.life.selectedPlans.length;

        if (isDecidePage) {
            let additionalBool = false;
            reviewData.shoppingLifePlans.map((shopPlan) => {
                if (!shopPlan.hasOwnProperty('renewalPlanType')) { additionalBool = true; }
            });
            lifeChanged = additionalBool || lifeChanged;
        }

        if (lifeTotalRenewingPlans !== lifeSelectedRenewingPlans || (lifeTotalRenewingPlans == 0 && lifeSelectedAlternatePlans > 0) || (lifeTotalRenewingPlans == 0 && lifeSelectedPreferredPlans > 0)) {
            lifeChanged = true;
        }
    }

    // DEP LIFE
    let depLifeTotalRenewingPlans;
    let depLifeSelectedRenewingPlans;
    let depLifeSelectedAlternatePlans;

    if (reviewData.dependentLife && reviewData.dependentLife.set && reviewData.dependentLife.selectedPlans) {
        depLifeTotalRenewingPlans = reviewData.dependentLife.set.length;
        depLifeSelectedRenewingPlans = 0;
        depLifeSelectedAlternatePlans = 0;
        reviewData.dependentLife.selectedPlans.map((depLifePlan) => {
            if (depLifePlan.renewalPlanType && depLifePlan.renewalPlanType == 'renewing') {
                depLifeSelectedRenewingPlans += 1;
            } else if (depLifePlan.renewalPlanType && depLifePlan.renewalPlanType == 'alternate') {
                depLifeSelectedAlternatePlans += 1;
            }
        });
        totalNumSelectedPlans += reviewData.dependentLife.selectedPlans.length;
        if (depLifeTotalRenewingPlans !== depLifeSelectedRenewingPlans || (depLifeTotalRenewingPlans == 0 && depLifeSelectedAlternatePlans > 0)) {
            depLifeChanged = true;
        }
    }

    // LTD
    if (reviewData.ltd && reviewData.ltd.selectedPlans) {
        totalNumSelectedPlans += reviewData.ltd.selectedPlans.length;
    }

    // STD
    if (reviewData.std && reviewData.std.selectedPlans) {
        totalNumSelectedPlans += reviewData.std.selectedPlans.length;
    }
    // Check if any changes in any product
    return [(medicalChanged || dentalChanged || visionChanged || lifeChanged || depLifeChanged), totalNumSelectedPlans];
}

export const identifyPlatform = (renewalData,  isFinalizePage=false) => {
    let platform = '';
    const { store } = require('../../../../index');
    const { renewals: { renewalCaseData } } = store.getState();

    // TODO: WHEN RATE ID ISSUE IS FIXED
    // if(renewalData && renewalData.rateId && renewalData.hasOwnProperty('Ibr')===true){
    //     platform='DUAL'
    // }else if(renewalData && renewalData.rateId && renewalData.hasOwnProperty('Ibr')===false){
    //     platform='CIRRUS'
    // }else if(renewalData && renewalData.rateId===false && renewalData.hasOwnProperty('Ibr')===true ){
    //     platform='PRIME'
    // }
    // else{
    //     platform='CIRRUS'
    // }

    if (renewalData && renewalData.renewalPlanMaps && (renewalData.hasOwnProperty('Ibr') === true || renewalData.hasOwnProperty('Ibr') === true)) {
        if(renewalCaseData && renewalCaseData.splitPlatformGroup && (renewalCaseData.splitPlatformGroup===true ||renewalCaseData.splitPlatformGroup==="Y" ) && isFinalizePage ===true){
            platform = 'CIRRUS'
        }else{
            platform = 'DUAL';
        }
    
    } else if (renewalData && renewalData.renewalPlanMaps && (renewalData.hasOwnProperty('Ibr') === false || renewalData.hasOwnProperty('Ibr') === false)) {
        platform = 'CIRRUS';
    } else if (renewalData && renewalData.hasOwnProperty('renewalPlanMaps') === false && (renewalData.hasOwnProperty('Ibr') === true || renewalData.hasOwnProperty('Ibr') === true)) {
        platform = 'PRIME';
    } else {
        platform = 'CIRRUS';
    }
    return platform;
};

export const isIbrCase = (renewalData) => {
    if(renewalData && renewalData.hasOwnProperty('Ibr') === true){
        return true;
    }
    return false;
}

export const identifyMedicalProductInCirrus = (products) => {

   if(products && products.includes('M')){
       return true;
   }
    
    else 
    return false
};

export const identifySpecialtyProductInCirrus = (products, type) => {
    if(type === 'DENTAL' && products && products.includes('D')){
        return true;
    } else if(type === 'VISION' && products && products.includes('V')){
        return true;
    } else if(type === 'LIFE' && products && (products.includes('L') || products.includes('B'))){
        return true;
    } else 
        return false
 };
export const identifyPlatformCaseData = (caseData) => {
    let platform = '';
    const memberGroupId = caseData && caseData.memberGroupId ? caseData.memberGroupId : null;
    const primeCustomerNumber = caseData && caseData.primeCustomerNumber ? caseData.primeCustomerNumber : null;

    if (memberGroupId && primeCustomerNumber && memberGroupId == primeCustomerNumber) platform = 'PRIME';
    else if (memberGroupId && primeCustomerNumber && memberGroupId != primeCustomerNumber) platform = 'DUAL';
    else platform = 'CIRRUS';

    return platform;
};

export const getExistingCoverageForSpecialty = (renewalData) => {
    const coverageArray = [];
    let hasDental = false; let hasVision = false; let
        hasLife = false;

    if (renewalData && renewalData.Ibr) {
        const { planOfferingData } = renewalData.Ibr.quote;
        for (const planOfferings of planOfferingData) {
            for (const item in planOfferings) {
                if (item === 'existingPlanPkg') {
                    planOfferings[item].plans.plan.map((plan) => {
                        if (plan.productLineType === 'DENTAL' && !hasDental) {
                            coverageArray.push('dental');
                            hasDental = true;
                        }
                        if (plan.productLineType === 'VISION' && !hasVision) {
                            coverageArray.push('vision');
                            hasVision = true;
                        }
                        if (plan.productLineType === 'BASIC_LIFE_EE' && !hasLife) {
                            coverageArray.push('life');
                            hasLife = true;
                        }
                    });
                }
            }
        }
    }
    return coverageArray;
};

export const getQuoteIdForSpecialty = (queryData, service) => {
    let quoteId = '';
    const renewalData = queryData.renewalData;

    if (renewalData && renewalData.Ibr) {
        const { planOfferingData } = renewalData.Ibr.quote;
        for (const planOfferings of planOfferingData) {
            for (const item in planOfferings) {
                if (item === 'newPlanPkg' || item === 'alternatePlanPkg') {
                    planOfferings[item].plans.plan.map((plan) => {
                        if (plan.productLineType === service.toUpperCase() || plan.productLineType === 'BASIC_LIFE_EE') {
                            if (plan.clientDefinedData && plan.clientDefinedData.data && plan.clientDefinedData.data.length > 0) {
                                quoteId = plan.clientDefinedData.data.find((data) => data.key === 'StepwiseQuoteID') || '';
                            }
                        }
                    });
                }
            }
        }
    } else{
        const plat= identifyPlatform(renewalData);
        if(plat.toUpperCase()=="CIRRUS"){
            quoteId = queryData.quoteTrackingNumber ? queryData.quoteTrackingNumber : '';
        }
    }
    return (quoteId.value) 
            ? (quoteId.value).toString() 
            : quoteId
                ? quoteId.toString()
                : queryData?.quoteTrackingNumber?.toString();
};

export const getARQRatioForSpecialty = (renewalData, service, planCode) => {
    let arqRatio = '1';
    if (renewalData && renewalData.Ibr) {
        const { planOfferingData } = renewalData.Ibr.quote;
        for (const planOfferings of planOfferingData) {
            for (const item in planOfferings) {
                if (item === 'newPlanPkg') {
                    planOfferings[item].plans.plan.map((plan) => {
                        if ((plan.productLineType === service.toUpperCase() || plan.productLineType === 'BASIC_LIFE_EE') && planCode === plan.planID) {
                            if (plan.clientDefinedData && plan.clientDefinedData.data && plan.clientDefinedData.data.length > 0) {

                                arqRatio = plan.clientDefinedData.data.find((data) => data.key === 'DiscountRatio') || '';
                            }
                        }
                    });
                }
            }
        }
    }
    else{
       const plat= identifyPlatform(renewalData);
       if(plat.toUpperCase()=="CIRRUS"){
            let isProductExists=false;
            renewalData.specialtyProducts.map((product)=>{
                if(product.type.toLowerCase()==service || (service == "life" && product.type.toLowerCase()=='basiclifeadd')){
                    isProductExists=true;
                }
            })
            if(isProductExists){
                renewalData.productAttributes.map((attr)=>{
                    if(attr.product.toLowerCase()==service || (service == "life" && attr.product.toLowerCase()=='basiclifeadd')){
                        arqRatio=attr.arq;
                    }
                })
            }
       }
    }
    return (arqRatio.value) ? (arqRatio.value).toString() : arqRatio.toString();
};

export const getARQRatioForSpecialtyUhcRenewals = (renewalData, service) => {
    let arqRatio = '';
    if (renewalData && renewalData.Ibr) {
        const { planOfferingData } = renewalData.Ibr.quote;
        for (const planOfferings of planOfferingData) {
            for (const item in planOfferings) {
                if (item === 'newPlanPkg') {
                    planOfferings[item].plans.plan.map((plan) => {
                        if (plan.productLineType === service) {
                            if (plan.clientDefinedData && plan.clientDefinedData.data && plan.clientDefinedData.data.length > 0) {
                                arqRatio = plan.clientDefinedData.data.find((data) => data.key === 'DiscountRatio') || arqRatio;
                            }
                        }
                    });
                }
            }
        }
    }
    return (arqRatio.value) ? (arqRatio.value).toString() : '1';
}

/**
 * Method to get the enrolled plan Name if it is available else return ''
 * @param {String}  planId planId coressponding to each Employee wrt to each product
 * @param {Object} contractOptions contracctOptions object 
 * @returns enrolled plan Name if it is available else return ''
 */
const getPlanNameFromContractOptionsID = (planId, contractOptions) => {
    try {
        const { contractList } = contractOptions;
        const { contractOptionsList } = contractList[0];
        // const { coverageOptionList } = contractOptionsList[0];
        // const { coverageOptionPlansList } = coverageOptionList[0];
        let planName = ''
        let bool = false;
        for (let k = 0; k < contractOptionsList.length; k++) {
            let contractOptionsListArray = contractOptionsList[k];
            for (let i = 0; i < contractOptionsListArray.coverageOptionList.length; i++) {
                const coverageOptionPlansList = contractOptionsListArray.coverageOptionList[i].coverageOptionPlansList;
                for (let j = 0; j < coverageOptionPlansList.length; j++) {
                    let covOpsPlanList = coverageOptionPlansList[j];
                    if (covOpsPlanList.planID === planId) {
                        if (contractOptionsListArray.contractOptType === "MD")
                            planName = contractOptionsListArray.coverageOptionList[i].externalMarketingName;
                        else planName = covOpsPlanList.planID || contractOptionsListArray.coverageOptionList[i].externalMarketingName;
                        bool = true;
                        break;
                    }
                }
                if (bool)
                    break;
            }
            if (bool)
                break;
        }
        return planName;
    }
    catch (err) {
        return '';
    }
}

/**
 * Method to get the enrolled plans object if it is available
 * @param {*} memberCoverageOptionDescription memberCoverageOptionDescription array from IndividualMemberList
 * @param {Object} contractOptions contracctOptions object 
 * @returns enrolled plan Name if it is available else return ''
 */
const getDefaultPlans = (memberCoverageOptionDescription, contractOptions) => {
    let plans = {
        MD: '',
        DN: '',
        VS: ''
    }
    memberCoverageOptionDescription.forEach(memCovList => {
        let planId = memCovList.planID;
        plans[memCovList.benefitType] = getPlanNameFromContractOptionsID(planId, contractOptions, plans[memCovList.benefitType]);
    })
    return plans;
}
/**
 * Method to return default Employee Renewing Plans Array 
 */
export const defaultEmployeeRenewingPlansMedical = (data, memberId) => {
    try {
        let plan = {
            MD: '',
            DN: '',
            VS: ''
        }
        /// will remove once testing from Census Refresh is successfull

        // let employeeId = "";
        // let renewalData = data.renewalData;
        // if (renewalData.members && renewalData.members.length > 0) {
        //     let tempMember = renewalData.members.find(emp => emp.memberId === memberId)
        //     if (tempMember)
        //         employeeId = tempMember.employeeId
        // }
        // if (renewalData && renewalData.planRates && renewalData.planRates.length > 0) {
        //     for (let i = 0; i < renewalData.planRates.length; i++) {
        //         let planRate = renewalData.planRates[i]
        //         if (planRate.renewalPlanType === "Renewal" && planRate.employeeRates && planRate.employeeRates.length > 0) {
        //             let tempPlan = planRate.employeeRates.find(emp => emp.employeeId === parseInt(employeeId))
        //             if (tempPlan) {
        //                 if (renewalData.products && renewalData.products.plans && renewalData.products.plans.length > 0) {
        //                     let planCode = renewalData.products.plans.find(plan => plan.code === planRate.planCode)
        //                     if (planCode) {
        //                         plan = planCode.name
        //                     }
        //                 }
        //                 break;
        //             }
        //         }
        //     }
        // }
        if (plan.MD === "" || plan.DN === "" || plan.VS === "") {
            let defaultPlanForIndividualMemberList = ""
            let individualMemberList = data.individualMemberList || []
            let contractOptions = data.contractOptions || {}
            let employeeMemberCoverageList = {};
            individualMemberList.forEach(employee => {
                if (employee.memberAffiliation && employee.memberAffiliation.relationshipCodeDesc === "Self") {
                    if (employee.demographics && employee.demographics.memberID && employee.demographics.memberID === memberId) {
                        employeeMemberCoverageList = employee.memberCoverageList || {};
                    }
                }
            })
            if (!isUndefinedOrNull(employeeMemberCoverageList) && Array.isArray(employeeMemberCoverageList) && employeeMemberCoverageList.length > 0) {
                // const { coverageOptionPlans } = employeeMemberCoverageList[0];
                const coverageOptionPlans = [];
                employeeMemberCoverageList.forEach(covList => {
                    const benefitType = covList.coverageOptionType;
                    if (benefitType) {
                        const mdCoverageIndMemberList = covList.coverageOptionPlans && covList.coverageOptionPlans.find(covPlan => covPlan.benefitType === benefitType)
                        if (mdCoverageIndMemberList) {
                            coverageOptionPlans.push(mdCoverageIndMemberList)
                        }
                    }
                })
                defaultPlanForIndividualMemberList = getDefaultPlans(coverageOptionPlans, contractOptions)
            }
            return defaultPlanForIndividualMemberList;
        }

        return plan;
    }
    catch (err) {
        return {
            "medical": '',
            "dental": '',
            "vision": ''
        };
    }
}
/**
 * Method to return default Renewing Plans Array
 */
export const defaultRenewingPlansMedicalArray = (renewalData) => {
    const plans = [];
    if (renewalData && renewalData.renewalPlanMaps && renewalData.renewalPlanMaps.length > 0) {
        renewalData.renewalPlanMaps.map(renPlan => {
            if (renewalData.products && renewalData.products.plans && renewalData.products.plans.length > 0) {
                let planCode = renewalData.products.plans.find(plan => plan.code === renPlan.renewalPlanCode)
                if (planCode) {
                    plans.push(planCode.name)
                }
            }
        })
    }
    return plans;
}

/**
 * Method to return if member is in groups coverage
 */
export const checkCurrentMember = (data, member) => {
    let memberFound;
    if (data && data.renewalData && data.renewalData.members && data.renewalData.members.length > 0) {
        memberFound = data.renewalData.members.find(mem => mem.memberId === member.memberId)
    }
    if (memberFound)
        return true;
    else return false;
}

export const mapPlansCode = (plans, specialty = false) => {
    if (!specialty) {
        return plans.map(plan => plan.codeDisplay)
    }
    else return plans.map(plan => plan.code)
}

export const findIfArrayDifferent = (existingPlans, newPlans) => {

    for (let i = 0; i < newPlans.length; i++) {
        let planFound = existingPlans.find(existMed => existMed === newPlans[i])
        if (!planFound)
            return true;
    }
    return false;
}

/**
 * Method to return true if plans have changed
 */
export const checkIfPlansChanged = (data, enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, existingEnrolledPlansObj) => {
    let newMedPlans = [];
    let newDenPlans = [];
    let newVisPlans = [];
    let existingMedPlans = [];
    let existingDenPlans = [];
    let existingVisPlans = [];
    let plansChangedObj = {
        medical: false,
        dental: false,
        vision: false
    }

    const { existEnrolledPlans,
        existEnrolledDentalPlans,
        existEnrolledVisionPlans } = existingEnrolledPlansObj;

    /** Medical Plan Changed */
    if (enrolledPlans && enrolledPlans.length > 0) {
        newMedPlans = mapPlansCode(enrolledPlans)
    }
    if (existEnrolledPlans && existEnrolledPlans.length > 0) {
        existingMedPlans = mapPlansCode(existEnrolledPlans)
    }
    if (existingMedPlans.length !== newMedPlans.length)
        plansChangedObj.medical = true
    else {
        plansChangedObj.medical = findIfArrayDifferent(existingMedPlans, newMedPlans)
    }
    /** */

    /** Dental Plan Changed */
    if (enrolledDentalPlans && enrolledDentalPlans.length > 0) {
        newDenPlans = mapPlansCode(enrolledDentalPlans,true)
    }
    if (existEnrolledDentalPlans && existEnrolledDentalPlans.length > 0) {
        existingDenPlans = mapPlansCode(existEnrolledDentalPlans,true)
    }
    if (existingDenPlans.length !== newDenPlans.length)
        plansChangedObj.dental = true
    else {
        plansChangedObj.dental = findIfArrayDifferent(existingDenPlans, newDenPlans)
    }
    /** */

    /** Vision Plan Changed */
    if (enrolledVisionPlans && enrolledVisionPlans.length > 0) {
        newVisPlans = mapPlansCode(enrolledVisionPlans,true)
    }
    if (existEnrolledVisionPlans && existEnrolledVisionPlans.length > 0) {
        existingVisPlans = mapPlansCode(existEnrolledVisionPlans,true)
    }
    if (existingVisPlans.length !== newVisPlans.length)
        plansChangedObj.vision = true
    else {
        plansChangedObj.vision = findIfArrayDifferent(existingVisPlans, newVisPlans)
    }
    /** */
    return plansChangedObj
}

/**
 * Method to return company Profile employees with updated product Selection based on number of Renewal Plans.
 */

export const defaultRenewalPlanElections = (data, companyProfile, newEnrolledPlansObj, existingEnrolledPlansObj = {}) => {
    const members = companyProfile && companyProfile.employees && companyProfile.employees[0];
    
    const { enrolledPlans : enrolledMedicalPlans,
        enrolledDentalPlans,
        enrolledVisionPlans } = newEnrolledPlansObj;
    
    const productPlansObject = {
        'medical': {
            additionalCount: 0,
            currentCount: 0,
            currentRenewalPlanCode: [],
            additionalRenewalPlanCode: []
        },
        'dental': {
            additionalCount: 0,
            currentCount: 0,
            currentRenewalPlanCode: [],
            additionalRenewalPlanCode: []
        },
        'vision': {
            additionalCount: 0,
            currentCount: 0,
            currentRenewalPlanCode: [],
            additionalRenewalPlanCode: []
        }
    }

    let totalRenewalPlans = [];

    const { medical: checkIfPlansChangedBoolMedical,
            dental: checkIfPlansChangedBoolDental,
        vision: checkIfPlansChangedBoolVision } = checkIfPlansChanged(data, enrolledMedicalPlans, enrolledDentalPlans, enrolledVisionPlans, existingEnrolledPlansObj)

    const ACADentalPresent = data.renewalData.specialtyProducts && data.renewalData.specialtyProducts.find(prod => prod.type === "Dental")
    const ACAVisionPresent = data.renewalData.specialtyProducts && data.renewalData.specialtyProducts.find(prod => prod.type === "Vision")

    if (enrolledMedicalPlans && enrolledMedicalPlans.length > 0) {
        enrolledMedicalPlans.map((plan) => {
            if (!plan.hasOwnProperty('renewalPlan') && !plan.hasOwnProperty('renewalPlanType')) {
                productPlansObject.medical.additionalCount += 1
                productPlansObject.medical.additionalRenewalPlanCode.push(plan.codeDisplay)
            }
            else {
                productPlansObject.medical.currentRenewalPlanCode.push(plan.codeDisplay)
                productPlansObject.medical.currentCount += 1
            }
            totalRenewalPlans.push(plan.codeDisplay)
        });
    }

    if (enrolledDentalPlans && enrolledDentalPlans.length > 0) {
        enrolledDentalPlans.map((plan) => {
            if (!plan.hasOwnProperty('renewalPlanType')) {
                productPlansObject.dental.additionalCount += 1
                productPlansObject.dental.additionalRenewalPlanCode.push(plan.code)
            }
            else {
                productPlansObject.dental.currentRenewalPlanCode.push(plan.code)
                productPlansObject.dental.currentCount += 1
            }
            totalRenewalPlans.push(plan.code)
        });
    }

    if (enrolledVisionPlans && enrolledVisionPlans.length > 0) {
        enrolledVisionPlans.map((plan) => {
            if (!plan.hasOwnProperty('renewalPlanType')) {
                productPlansObject.vision.additionalCount += 1
                productPlansObject.vision.additionalRenewalPlanCode.push(plan.code)
            }
            else {
                productPlansObject.vision.currentRenewalPlanCode.push(plan.code)
                productPlansObject.vision.currentCount += 1
            }
            totalRenewalPlans.push(plan.code)
        });
    }

    const newMembers = members.map(member => {
        let productSelectionMedical = '';
        let productSelectionDental = '';
        let productSelectionVision = '';

        let defaultMemberPlan = defaultEmployeeRenewingPlansMedical(data, member.memberId);

        /** Medical Plan Elections Starts */
        let medicalCurrentCount = productPlansObject.medical.currentCount
        let medicalAddtionalCount = productPlansObject.medical.additionalCount
        let medicalProductObj = productPlansObject.medical

        if (medicalCurrentCount === 1 && medicalAddtionalCount === 0)
            productSelectionMedical = medicalProductObj.currentRenewalPlanCode[0];
        /// Customer Keeps a renewing plan and Adds a one new plan:
        else if (medicalCurrentCount === 1 && medicalAddtionalCount === 1) {
            productSelectionMedical = medicalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.MD) ? defaultMemberPlan.MD : productPlansObject.medical.additionalRenewalPlanCode[0];
        }
        /// Customer Keeps a renewing plan and Adds more than one new plan:
        else if (medicalCurrentCount === 1 && medicalAddtionalCount > 1) {
            productSelectionMedical = medicalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.MD) ? defaultMemberPlan.MD : "select";
        }
        /// Customer not select any of the renewing plans – adds new plan(s)
        else if (medicalCurrentCount === 0 && medicalAddtionalCount === 1) {
            productSelectionMedical = productPlansObject.medical.additionalRenewalPlanCode[0];
        }
        else if (medicalCurrentCount === 0 && medicalAddtionalCount > 1) {
            productSelectionMedical = "select";
        }
        /// Customer add more than 1 renewing plans && more than 1 / 0 additional Plans
        else if (medicalCurrentCount > 1 && (medicalAddtionalCount == 0 || medicalAddtionalCount > 1)) {
            if (medicalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.MD))
                productSelectionMedical = defaultMemberPlan.MD;
            else productSelectionMedical = "select";
        }
        /// Customer add more than 1 renewing plans && 1 additional Plans
        else if (medicalCurrentCount > 1 && medicalAddtionalCount == 1) {
            if (medicalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.MD))
                productSelectionMedical = defaultMemberPlan.MD;
            else productSelectionMedical = productPlansObject.medical.additionalRenewalPlanCode[0];
        }
        /** Medical Plan Elections Ends */


        if (ACADentalPresent) {
            /** Dental Plan Elections Starts */
            let dentalCurrentCount = productPlansObject.dental.currentCount
            let dentalAddtionalCount = productPlansObject.dental.additionalCount
            let dentalProductObj = productPlansObject.dental

            if (defaultMemberPlan.DN === "") {
                productSelectionDental = "waive";
            }
            else if (dentalCurrentCount === 1 && dentalAddtionalCount === 0) {
                productSelectionDental = dentalProductObj.currentRenewalPlanCode[0];
            }
            /// Customer Keeps a renewing plan and Adds a one new plan:
            else if (dentalCurrentCount === 1 && dentalAddtionalCount === 1) {
                productSelectionDental = dentalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.DN) ? defaultMemberPlan.DN : dentalProductObj.additionalRenewalPlanCode[0];
            }
            /// Customer not select any of the renewing plans – adds new plan(s)
            else if (dentalCurrentCount === 0 && dentalAddtionalCount === 1) {
                productSelectionDental = dentalProductObj.additionalRenewalPlanCode[0];
            }
            else if (dentalCurrentCount === 0 && dentalAddtionalCount > 1) {
                productSelectionDental = "select";
            }
            /// Customer add more than 1 renewing plans && 0 additional Plans
            else if (dentalCurrentCount > 1 && (dentalAddtionalCount == 0)) {
                if (dentalProductObj.currentRenewalPlanCode.includes(defaultMemberPlan.DN))
                    productSelectionDental = defaultMemberPlan.DN;
                else productSelectionDental = "select";
            }

            /** Dental Plan Elections Ends */
        }
        if (ACAVisionPresent) {
            /** Vision Plan Elections Starts */
            let visionCurrentCount = productPlansObject.vision.currentCount
            let visionAddtionalCount = productPlansObject.vision.additionalCount
            let visionProductObj = productPlansObject.vision

            if (defaultMemberPlan.VS === "") {
                productSelectionVision = 'waive'
            }
            else {
                if (visionProductObj.currentRenewalPlanCode.length > 0) {
                    productSelectionVision = visionProductObj.currentRenewalPlanCode[0]
                }
                else if (visionProductObj.additionalRenewalPlanCode.length > 0) {
                    productSelectionVision = visionProductObj.additionalRenewalPlanCode[0]
                }
                else productSelectionVision = ""
            }
            /** Vision Plan Elections Ends */
        }

        const newProductSelection = member.productSelection.map((product) => {
            if (!checkIfPlansChangedBoolMedical) {
                productSelectionMedical = product.medical
            }
            if (!checkIfPlansChangedBoolDental || !ACADentalPresent ) {
                productSelectionDental = product.dental
            }
            if (!checkIfPlansChangedBoolVision || !ACAVisionPresent ) {
                productSelectionVision = product.vision
            }
            return {
                ...product,
                medical: productSelectionMedical,
                dental: productSelectionDental,
                vision: productSelectionVision,
            }
        });


        return {
            ...member,
            productSelection: newProductSelection
        }
    })
    return newMembers;

}

/**
 * Method to return company Profile employees with updated product Selection based on number of Renewal Plans.
 */

export const defaultRenewalPlanElectionsRenewPlanAsIs = (data, companyProfile) => {
    const members = companyProfile && companyProfile.employees && companyProfile.employees[0];
    const defaultRenewalPlansSet = defaultRenewingPlansMedicalArray(data.renewalData);
    try {
        const newMembers = members.map(member => {
            let defaultMemberPlan;
            defaultMemberPlan = defaultEmployeeRenewingPlansMedical(data, member.memberId)

            let productSelectionMedical = defaultMemberPlan.MD
            let productSelectionDental = defaultMemberPlan.DN
            let productSelectionVision = defaultMemberPlan.VS

            if (defaultRenewalPlansSet.length === 1)
                productSelectionMedical = defaultRenewalPlansSet[0];

            let newProductSelection = []
            if (data.renewalData && data.renewalData.specialtyProducts && data.renewalData.specialtyProducts.length > 0) {
                newProductSelection = member.productSelection.map((product) => {
                    return {
                        ...product,
                        medical: productSelectionMedical,
                        dental: productSelectionDental,
                        vision: productSelectionVision
                    }
                });
            }
            else {
                newProductSelection = member.productSelection.map((product) => {
                    return {
                        ...product,
                        medical: productSelectionMedical
                    }
                });
            }

            return {
                ...member,
                productSelection: newProductSelection
            }
        })
        return newMembers;
    }
    catch (err) {
        console.log(err)
    }


}

export const getExistingPlanSelectedVal = (renewalData, enrollment, service, plans) => {
    const enrolledPlans = service === 'DENTAL' && enrollment.enrolledDentalPlans ? enrollment.enrolledDentalPlans: service === 'VISION' && enrollment.enrolledVisionPlans ? enrollment.enrolledVisionPlans: service === 'BASICLIFEADD' && enrollment.enrolledLifePlans ? enrollment.enrolledLifePlans: plans && plans.length > 0 ? plans: [];
    let rateGuaranteeObj = {};
    rateGuaranteeObj.newProductAdded = 'false';
    const exProduct = renewalData.renewalPlanMaps.find((rlPlan) => rlPlan.productType.toUpperCase() === service && enrolledPlans.length > 0)
    if(!exProduct) {
        rateGuaranteeObj.newProductAdded = 'true';
    }
    enrolledPlans && enrolledPlans.every((plan) => {
        const findPlan = service == "BASICLIFEADD" ? renewalData && renewalData.planRates && renewalData.planRates.find((eachPlan) => eachPlan.productType.toUpperCase() === service && eachPlan.renewalPlanType === 'Renewal' && eachPlan.planCode.split('--')[0] === plan.code ) : renewalData && renewalData.planRates && renewalData.planRates.find((eachPlan) => eachPlan.productType.toUpperCase() === service && eachPlan.renewalPlanType === 'Renewal' && eachPlan.planCode === plan.code );
        const getProductAttribute = renewalData && renewalData.productAttributes && renewalData.productAttributes.find((productType) => service === productType.product.toUpperCase())
        rateGuaranteeObj.selectedExistingPlan = findPlan ? true: false;
        rateGuaranteeObj.rateGuarBegin = findPlan && findPlan.rateGuarBegin ? findPlan.rateGuarBegin: "";
        rateGuaranteeObj.rateGuarEnd = findPlan && findPlan.rateGuarEnd ? findPlan.rateGuarEnd: "";
        rateGuaranteeObj.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: "";
        rateGuaranteeObj.existingProductSelected = findPlan ? 'true': 'false';
        if(findPlan) {
            return;
        };
    })
    return rateGuaranteeObj;
}

export const getExistingRatesedVal = (renewalData, enrollment, service, existingPlanRates) => {
    let rateGuaranteeObj = {};
    existingPlanRates && existingPlanRates.every((plan) => {
    const findPlan = service == "BASICLIFEADD" ? renewalData && renewalData.planRates && renewalData.planRates.find((eachPlan) => eachPlan.productType.toUpperCase() === service && eachPlan.renewalPlanType === 'Renewal' && eachPlan.planCode.split('--')[0] === plan.planCode) : renewalData && renewalData.planRates && renewalData.planRates.find((eachPlan) => eachPlan.productType.toUpperCase() === service && eachPlan.renewalPlanType === 'Renewal' && eachPlan.planCode === plan.planCode);
        const getProductAttribute = renewalData && renewalData.productAttributes && renewalData.productAttributes.find((productType) => service === productType.product.toUpperCase())
        rateGuaranteeObj.selectedExistingPlan = findPlan ? true: false;
        rateGuaranteeObj.rateGuarBegin = findPlan && findPlan.rateGuarBegin ? findPlan.rateGuarBegin: "";
        rateGuaranteeObj.rateGuarEnd = findPlan && findPlan.rateGuarEnd ? findPlan.rateGuarEnd: "";
        rateGuaranteeObj.arq = getProductAttribute && getProductAttribute.arq ? getProductAttribute.arq: "";
        rateGuaranteeObj.existingProductSelected = findPlan ? 'true': 'false';
        if(findPlan) {
            return;
        };
    });
    return rateGuaranteeObj;
}