import { INITIALIZE_ATTESTATION_INFO, UPDATE_ATTESTATION_INFO, COMPLETED_ATTESTATION_INFO, REFRESH_ATTESTATION_INFO, RESET_FLOW, RESET_TO_INITIALSTATE, RESET_EMPLOYER_ATTESTATION } from '../../../actions/actionTypes';
import { initializeEnrollmentPageState } from './helper';
import { checkIfObjectMeetsConditions } from '../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions';
import { isUndefinedOrNullOrEmpty } from '../../../utils/validation/commonValidations';
import { checkCompletionEmployerAttestation } from '../../../components/shared/ConfigurableForms/helpers/conditionCheckers';
import { checkValidationsOnBlur } from '../../../components/shared/ConfigurableForms/helpers/validationCheckers';
import { getRelevantAppState, makeAppStateNested } from '../../../components/shared/ConfigurableForms/helpers/getRelevantAppState';

const initialState = {
    attestation: {},
    employeeAttestation: {},
    errors: {},
};

export default function attestationReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_ATTESTATION_INFO: {
            const initializedState = initializeEnrollmentPageState(action.payload, action.conditionEvaluators);
            return {
                ...state,
                ...initializedState,
            };
        }
        case REFRESH_ATTESTATION_INFO:
            const newState = action.payload;
            return {
                ...state,
                ...newState,
            };
        case UPDATE_ATTESTATION_INFO: {
            const { update, group, isError } = action;
            if (isError) {
                const resolvedErrorGroup = state.errors[group] || {};
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [group]: {
                            ...resolvedErrorGroup,
                            ...update,
                        },
                    },
                };
            }

            const resolvedGroup = state[group] || {};
            return {
                ...state,
                [group]: {
                    ...resolvedGroup,
                    ...update,
                },
            };
        }

        case COMPLETED_ATTESTATION_INFO: {
            const {
                group, application, enrollmentForm, conditionEvaluators, appStateProps, fullProps
            } = action;
            //for employer attestation
            //new updates
            if (group === 'attestation') {
                let completed = true;
                if(enrollmentForm.attestation.employer){
                    if(!application.installation.memGroupID || (application.attestation.attestation && !application.attestation.attestation.completed)){
                        for (const rulesSection of enrollmentForm.attestation.employer) {
                            let showSection = true
                            if (rulesSection.showIf) {
                                let appState = getRelevantAppState(application, 'attestation', 'attestation', rulesSection);
                                appState = makeAppStateNested(appState);
                                showSection = checkIfObjectMeetsConditions(
                                    rulesSection.sectionName,
                                    rulesSection.showIf,
                                    {
                                        ...conditionEvaluators,
                                        appState,
                                    },
                                );
                            }

                            if (showSection) {
                                if (rulesSection.sectionName === 'attestation') {
                                    let erAttestationCompletionData = checkCompletionEmployerAttestation(
                                        'employer-attestation', application, conditionEvaluators, appStateProps, fullProps
                                    );
                                    if (erAttestationCompletionData.inCompleteSectionNames.length > 0) {
                                        completed = false;
                                    }
                                }
                            }
                        }
                    }
                    
                }
                const resolvedGroup = state[group] || {};
                return {
                    ...state,
                    [group]: {
                        ...resolvedGroup,
                        completed,
                    },
                };
            }
            //for employee attestation
            else if (group === 'employeeAttestation') {
                let completed = true;
                if(enrollmentForm.attestation.employee){
                    if(application.attestation.employeeAttestation && !application.attestation.employeeAttestation.completed){
                        for (const rulesSection of enrollmentForm.attestation.employee) {
                            let showSection = true
                            if (rulesSection.showIf) {
                                let appState = getRelevantAppState(application, 'attestation', 'employeeAttestation', rulesSection);
                                appState = makeAppStateNested(appState);
                                showSection = checkIfObjectMeetsConditions(
                                    rulesSection.sectionName,
                                    rulesSection.showIf,
                                    {
                                        ...conditionEvaluators,
                                        appState,
                                    },
                                    fullProps.companyProfile,
                                    fullProps.rules.enrollment
                                );
                            }

                            if (showSection) {
                                if (rulesSection.sectionName === 'employeeAttestation') {
                                    let eeAttestationCompletionData = checkCompletionEmployerAttestation(
                                        'employee-attestation', application, conditionEvaluators, appStateProps, fullProps
                                    );
                                    if (eeAttestationCompletionData.inCompleteSectionNames.length > 0) {
                                        completed = false;
                                    }
                                }
                            }
                        }
                    }
                    
                }
                const resolvedGroup = state[group] || {};
                return {
                    ...state,
                    [group]: {
                        ...resolvedGroup,
                        completed,
                    },
                };
            }

        }

        case RESET_EMPLOYER_ATTESTATION:
            return {
                ...state,
                attestation: {
                    completed: false,
                    //date: '',
                    termsSignature: '',
                    termsAgreement: false,
                    completedFormReceived: false,
                    formReceivedSignature: '',
                    formReceivedSignaturePrintedName: '',
                    title: '',
                    isAlreadyAttested: false,
                }
            }
        case RESET_TO_INITIALSTATE:
            return initialState
        default:
            return state;
    }
}
