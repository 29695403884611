import * as types from '../../../actions/actionTypes';
const initialState = {
    auditHistory: [],
    actionHistory: [],
    activeCase: "",
    flow: "",
    saveHistory: [],
    currentPage: ""

}

export default function renewalHistory(state = initialState, action) {

    switch (action.type) {
        case types.REQUEST_AUDIT_HISTORY:
            return state;

        case types.RECEIVE_AUDIT_HISTORY:
            return {
                ...state,
                auditHistory: action.payload
            };
        case types.RECEIVE_AUDIT_HISTORY_RANGE:
            return {
                ...state,
                //auditHistory: state.auditHistory
                // auditHistory: state.auditHistory.concat(action.payload)
                auditHistory: action.payload
            };
        case types.ERROR_AUDIT_HISTORY:
            return {
                ...state,
                error: true
            }
        case types.REQUEST_ACTION_HISTORY:
            return state;
            
        case types.SAVE_AUDIT_HISTORY:
            const newState = state.saveHistory || []
            newState.push(action.payload)
            return {
                ...state,
                saveHistory: newState
            }

        case types.SAVE_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            }
        case types.RECEIVE_CREATE_HISTORY:
            return {
                ...state,
                saveHistory: []
            };


        case types.RECEIVE_ACTION_HISTORY:
            return {
                ...state,
                actionHistory: state.actionHistory.concat(action.payload)
            };

        case types.ERROR_ACTION_HISTORY:
            return {
                ...state,
                error: true
            }

        case types.STORE_ACTIVE_HISTORY_CASE:
            return {
                ...state,
                activeCase: action.caseId,
                flow: action.flow
            }
        case types.RESET_AUDIT_HISTORY:
            return {
                ...state,
                auditHistory: [],
            }
        case types.RESET_ACTION_HISTORY:
            return {
                ...state,
                actionHistory: [],
            }
        default:
            return state
    }
}