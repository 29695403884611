import React, { useEffect } from 'react';
import { Modal, Header, Icon, Button } from "semantic-ui-react";
import { sendErrorTrackingDataAction } from '../../actions/analyticsAction';

const IneligibleCaseModal = (props) => {
    useEffect(() => {
        if(props.open===true)
        sendErrorTrackingDataAction({
            activityName : `Technical/Ineligible Error`,
            errorType :"Ui Error",
            errorFields :`Details: ${props.message}`,
        })
    }, [props.open])
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}>
            { props.header ?  < Header color='white' > { props.header } </Header> :  
                              < Header icon='warning circle' color='red' /> }
            <Modal.Content>
                <div dangerouslySetInnerHTML={{__html:props.message}}></div>
            </Modal.Content>
            <Modal.Actions>
                <Button color='blue' onClick={props.onClose}>
                    <Icon name='checkmark' /> Ok
                </Button>
            </Modal.Actions>
        </Modal>
    )
};
export default IneligibleCaseModal;