import { axios } from '../api/axiosConfig';
import apiRoutes from '../api/apiRouteConfig';

export const storeAllCustomerFilesToEdms = async (
    transactionId,
    enrollmentData,
    transactionType,
    paymentBilling
) => {

    const memberGroupId = enrollmentData.application.installation.memGroupID;
    const memberGroupName =
        enrollmentData.application.employer.generalInformation.groupLegalName;
    const memberGroupTin =
        enrollmentData.application.employer.generalInformation.taxID;

    let s3FileObject = null;
    if (transactionType === 'newBusiness') {
        s3FileObject = await getS3CloudFileNames(null, 'newBusiness', enrollmentData, paymentBilling);
    } else {
        s3FileObject = await getS3CloudFileNames(transactionId);
    }

    if (s3FileObject) {
        for (const s3Files of s3FileObject) {
            for (const s3File of s3Files.files) {
                await submitS3File(memberGroupId, memberGroupName, memberGroupTin, s3Files.templateType, s3File.clientFileName, s3File.cloudFileName, transactionType);
            }
        }
    
        return;
    }
};

const getS3CloudFileNames = (transactionId, transactionType, enrollmentData, paymentBilling) => {
    return new Promise(async (resolve) => {
        let s3Files = [];
        let enrollmentDocs;
        let paymentBillingDocs;
        if (transactionType === 'newBusiness') {
            enrollmentDocs = enrollmentData.documents.requiredDocuments;
            paymentBillingDocs = paymentBilling.payment.binder && paymentBilling.payment.binder.clientFileName && paymentBilling.payment.binder.cloudFileName ? paymentBilling.payment.binder : undefined;
        } else {
            const {data: { enrollment, paymentBilling }} = await axios.get(`${apiRoutes.mongo}/fullShopping/${transactionId}`)
            enrollmentDocs = enrollment.requiredDocs;
            paymentBillingDocs = paymentBilling && paymentBilling.payment && paymentBilling.payment.paymentConfirmation ? paymentBilling.payment.paymentConfirmation : undefined;
        }
        for (const key in enrollmentDocs) {
            if (enrollmentDocs[key].files.length > 0) {
                let s3SearchObject;

                s3SearchObject = addFilestos3SearchObject(key, enrollmentDocs);

                s3Files.push(s3SearchObject);
            }
        }

        if (paymentBillingDocs !== undefined) {
            let binderChecks3SearchObject = {
                templateType: 'BINDER CHECK COPY',
                files: [paymentBillingDocs],
            };
            s3Files.push(binderChecks3SearchObject);
        }

        resolve(s3Files)
    })
};

export default getS3CloudFileNames;

// Sets the template type of the file needed for EDMS submission, and adds cloud file names to be searched for in s3
const addFilestos3SearchObject = (key, enrollmentDocs) => {
    let s3SearchObject = { templateType: '', files: [] };
    const wageAndTaxTemplate = 'WAGE AND TAX FORM';
    const religiousExemptionTemplate = 'RELIGIOUS EXEMPTION FORM';
    const miscellaneousTemplate = 'MISCELLANEOUS';

    if (key === 'wagesAndTax') {
        s3SearchObject.templateType = wageAndTaxTemplate;
    } else if (key === 'religiousExemption') {
        s3SearchObject.templateType = religiousExemptionTemplate;
    } else {
        s3SearchObject.templateType = miscellaneousTemplate;
    }

    for (const file of enrollmentDocs[key].files) {
        s3SearchObject.files.push(file);
    }

    return s3SearchObject;
};


const submitS3File = (memberGroupId, memberGroupName, memberGroupTin, templateName, fileName, cloudFileName, transactionType) => {

    const requestBody = {
        memberGroupId: memberGroupId.toString(),
        memberGroupName: memberGroupName,
        memberGroupTin: memberGroupTin,
        templateName: templateName,
        fileName: fileName.split(".")[0],
        cloudFileName: cloudFileName,
        transactionType: transactionType,
    }

    return axios
        .post(`${apiRoutes.quickQuotes}/file/submission/startSubmission`, requestBody)
        .then( async result => {
            const submissionResult = await checkSubmission(result.data.transactionId)
            return submissionResult.data.fileSubmissionResult
        })
        .catch( err => {
            if (err === "checkSubmission") {
                console.log('Issue with checking the file submission process')
            } else if (err === "backGroundProcess") {
                console.log('Issue with submission background process')
            }
            else {
                console.log('Issue with starting file upload process')
            }
    
            //Replace this when plan in place for failed upload
            return "error"
    
        })
};

/**
 * Function that submits additional info documents to edms
 * @param {Object} enrollmentData Object representing current group being enrolled through project x
 * @param {Array} documents Array of additional info documents that were uploaded by the user
 */
export const storeAdditionalInfoDocsToEdms = async (enrollmentData, documents) => {
    try {
        const memberGroupId = enrollmentData.application.installation.memGroupID;
        const memberGroupName = enrollmentData.application.employer.generalInformation.groupLegalName;
        const memberGroupTin = enrollmentData.application.employer.generalInformation.taxID;
        documents.forEach(async (doc) => {
            const s3Res = await submitS3File(
                memberGroupId,
                memberGroupName,
                memberGroupTin,
                'MISCELLANEOUS',
                doc.clientFileName.split(".")[0],
                doc.cloudFileName
            );
            if (s3Res.status === 200) {
                console.log("success");
            }
        });
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
    
}

const checkSubmission = async (transactionId, attemptsCount = 0) => {
    if (attemptsCount == 10) {
        throw "backGroundProcess"
    }

    return await axios.get(`${apiRoutes.quickQuotes}/file/submission/checkSubmission?transactionId=${transactionId}`)
    .then(async result => {
        if (result.data.fileSubmissionResult == null) {
            attemptsCount++;
            await pollingInterval(3000);
            return await checkSubmission(result.data._id, attemptsCount)
        }

        return result
    })
    .catch( err => {

        if (err === "backGroundProcess") {
            throw "backGroundProcess"
        }
        
        throw "checkSubmission"
    })
}

const pollingInterval = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}