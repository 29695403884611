// Remove specific nodes to reduce size of the payload
export const nodesToBeRemoved = {
    companyProfile: [
        'selectedMedicalPlans',
        'selectedDentalPlans',
        'selectedVisionPlans',
        'selectedLifePlans',
        'selectedDependentLifePlans',
        'selectedStdPlans',
        'selectedLTDPlans',
        'selectedSuppLifePlans',
        'selectedSuppLifeDepPlans',
        'overall',
        'reviewCards',
        'quickQuoteRequest',
        'specialtyCriteria',
        'addInfo',
        'enrollment',
        'dependentRules',
        'fiLFButtonEnable'
    ],
    overall: [
        'quickQuoteRequest.otherDetails.employeeCensus',
        'appConfigDetails',
        'participationRules'
    ],
    quotes: [
        'quickQuoteRequest'
    ],
    dental: [
        'quickQuoteRequest'
    ],
    vision: [
        'quickQuoteRequest'
    ],
    life: [
        'quickQuoteRequest'
    ],
    dependentLife: [
        'quickQuoteRequest'
    ],
    std: [
        'quickQuoteRequest'
    ],
    ltd: [
        'quickQuoteRequest'
    ],
    suppLife: [
        'quickQuoteRequest'
    ],
    suppLifeDep: [
        'quickQuoteRequest'
    ],
    specialtyCriteria: [
        'sicObj'
    ],
    rules: [
        'enrollment.specialRules',
        'enrollment.pcpAutoAssignemntRules',
        'enrollment.isFetchingEnrollmentRules',
        'enrollment.enrollmentRulesFetchError',
        'enrollment.maxSingleChoicePlans',
        'enrollment.maxSingleChoicePlansArray',
        'enrollment.maxAgeBandedPlans',
        'enrollment.maxRateDifferential',
        'enrollment.maxDentalPlans',
        'enrollment.maxVisionPlans',
        'enrollment.maxLifePlans',
        'enrollment.maxDependentLifePlans',
        'enrollment.maxSTDPlans',
        'enrollment.maxLTDPlans',
        'enrollment.maxSuppLifePlans',  //? Review
        'enrollment.maxSuppLifeDepPlans',  //? Review
        'enrollment.platform'
    ]
}

// Remove all the nodes and only keep the mentioned
export const nodesToBeKept = {
    quotes: [
        'selectedPlans',
        'breadCrumbAccess',
        'contributions',
        'packages',
        'filterCriteria',
        'optionalBenefitRiders',
        'selectedPlansBenefitRiders'
    ],
    dental: [
        'selectedPlans'
    ],
    vision: [
        'selectedPlans'
    ],
    life: [
        'selectedPlans',
        'employeeClassing',
        'classingOptions'
    ],
    dependentLife: [
        'selectedPlans'
    ],
    std: [
        'selectedPlans',
        'employeeClassing',
        'classingOptions'
    ],
    ltd: [
        'selectedPlans',
        'employeeClassing',
        'classingOptions'
    ],
    suppLife: [
        'selectedPlans'
    ],
    suppLifeDep: [
        'selectedPlans'
    ],
    rules: [
        'enrollment',
        'enrollmentForm.isComplete',
        'enrollmentForm.updateFuncsRun',
        'enrollmentForm.shouldInitializeForm'
    ]
}