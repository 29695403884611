import { checkCompletionAdditionalContacts, checkCompletionProducers, checkCompletionGeneralAgents, checkCompletionEmployerAttestation } from "../../../shared/ConfigurableForms/helpers/conditionCheckers";
import { getRelevantAppState, makeAppStateNested } from "../../../shared/ConfigurableForms/helpers/getRelevantAppState";
import { checkIfObjectMeetsConditions, findObjectThatMeetsConditions, isMedicalCoverageInfoVisible } from "../../../shared/ConfigurableForms/helpers/findObjectThatMeetsConditions";
import { isUndefinedOrNullOrEmpty } from "../../../../utils/validation/commonValidations";
import { checkValidationsOnBlur } from "../../../shared/ConfigurableForms/helpers/validationCheckers";
import validatePhoneNumber from '../../../../utils/validation/phone';
import { validateEmail } from '../../../../utils/validation/emailRegex';
import { validateMoney, parseMoneyString } from '../../../../utils/formatters/numbers';
import { isDigitsOnly } from '../../../../utils/validation/regex';
import { isUhcRenewals, getMarketType, MARKET_TYPES  } from "../../../../utils/businessLogic/platformUtils";
import deepCopy from "../../../../utils/deepCopy";

export const enrollmentFormCompletionHandler = (page, application, enrollmentForm, conditionEvaluators, appStateProps, fullProps, dispatch = "", flow = "") => {

    let completed = true;
    let inCompletePageNames = [];
    let inCompleteSectionNames = [];
    let formRules;
    let pageName;
    let isCaseSubmitted =false;

    const isUhcRenewal = isUhcRenewals()
    const selectedLifePlans = isUhcRenewal && fullProps?.rules?.enrollment?.enrolledLifePlans ? fullProps.rules.enrollment.enrolledLifePlans : fullProps.life.selectedPlans;
    const isMNState = fullProps.companyProfile?.locations[0]?.zipData?.stateCode=== "MN";

    
    {/*
    page can be :
        employer-info
        eligibility-info
        producer-info
        employer-attestation
        employee-info
        employee-attestation
    */}

    switch (page) {
        case "employer":
        case "employer-info":
            formRules = enrollmentForm.employer || [];
            pageName = 'employer'
            break;
        case "eligibility":
        case "eligibility-info":
            formRules = enrollmentForm.eligibility || [];
            pageName = 'eligibility'
            break;
        case "producer":
        case "producer-info":
            formRules = enrollmentForm.producer || [];
            pageName = 'producer'
            break;
        case "attestation":
        case "employer-attestation":
            formRules = enrollmentForm.attestation.employer || [];
            pageName = 'attestation'
            break;
        case "employee":
        case "employee-info":
            formRules = enrollmentForm.employee || [];
            pageName = 'employee'
            break;
        case "employee-attestation":
            formRules = enrollmentForm.attestation.employee || [];
            pageName = 'employeeAttestation'
            break;
        case "standard-hra":
            formRules = enrollmentForm.standardHRA || [];
            pageName = 'standardHRA';
            break;
        default:
            break;
    }
    
    for (const rulesSection of formRules) {
        let showSection = true;
        if (rulesSection.showIf) {
            let appState = getRelevantAppState(application, pageName, rulesSection.sectionName, rulesSection);
            appState = makeAppStateNested(appState);
            showSection = checkIfObjectMeetsConditions(
                rulesSection.sectionName,
                rulesSection.showIf,
                {
                    ...conditionEvaluators,
                    appState,
                },
                fullProps.companyProfile,
                fullProps.rules.enrollment
            );
        }

        if (showSection) {
            if (rulesSection.sectionName === 'additionalContacts') {
                let additionalContactsCompletionData = checkCompletionAdditionalContacts(
                    application, conditionEvaluators, appStateProps, fullProps
                );
                if (additionalContactsCompletionData.inCompleteSectionNames.length > 0) {
                    completed = false;
                    inCompletePageNames = inCompletePageNames.concat(additionalContactsCompletionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(additionalContactsCompletionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'producer') {
                let producerCompletionData = checkCompletionProducers(
                    application, conditionEvaluators, appStateProps, fullProps
                );
                if (producerCompletionData.inCompleteSectionNames.length > 0) {
                    completed = false;
                    inCompletePageNames = inCompletePageNames.concat(producerCompletionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(producerCompletionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'generalAgent') {
                let producerCompletionData = checkCompletionGeneralAgents(
                    application, conditionEvaluators, appStateProps, fullProps
                );
                if (producerCompletionData.inCompleteSectionNames.length > 0) {
                    completed = false;
                    inCompletePageNames = inCompletePageNames.concat(producerCompletionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(producerCompletionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'attestation') {
                let employerAttestationCompletionData;
                if (!application.installation.memGroupID || (application.attestation.attestation && !application.attestation.attestation.completed)) {
                    employerAttestationCompletionData = checkCompletionEmployerAttestation(
                        'employer-attestation', application, conditionEvaluators, appStateProps, fullProps
                    );
                }

                if (employerAttestationCompletionData && employerAttestationCompletionData.inCompleteSectionNames.length > 0) {
                    completed = false;
                    inCompletePageNames = inCompletePageNames.concat(employerAttestationCompletionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(employerAttestationCompletionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'employeeAttestation') {
                let employeeAttestationCompletionData;
                if (application.attestation.employeeAttestation && !application.attestation.employeeAttestation.completed) {
                    employeeAttestationCompletionData = checkCompletionEmployerAttestation(
                        'employee-attestation', application, conditionEvaluators, appStateProps, fullProps
                    );
                }

                if (employeeAttestationCompletionData && employeeAttestationCompletionData.inCompleteSectionNames.length > 0) {
                    completed = false;
                    inCompletePageNames = inCompletePageNames.concat(employeeAttestationCompletionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(employeeAttestationCompletionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'productAndPlanSelection') {
                const selectedProducts = fullProps.companyProfile.selectedProducts;
                const productSelection = application.employee.productSelection || [];
                const employeeStatus = application.employee.employeeInfo && application.employee.employeeInfo.employeeStatus && (JSON.stringify(application.employee.employeeInfo.employeeStatus)!== '{}')? application.employee.employeeInfo.employeeStatus: 'ACTIVE' ;
                const { enrolledPlans, enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledDependentLifePlans, enrolledSTDPlans, enrolledLTDPlans, enrolledSuppLifePlans} = fullProps.rules.enrollment;
    
                const flatMedicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
                const stateCode = flow ==="renewals" ? fullProps.renewalEnrollment?.renewalCaseData?.situsState : fullProps.companyProfile?.locations[0]?.zipData?.stateCode;
                if(getMarketType() === MARKET_TYPES.UHC && employeeStatus === '1099 Independent Contractors' &&  ['CA'].includes(stateCode)){

                }else if (productSelection.length === 0 || JSON.stringify(productSelection) === '{}' ) {
                    inCompletePageNames.push('employee');
                    inCompleteSectionNames.push('productAndPlanSelection')
                } else {
                   productSelection.map((employeeProductSelection, index) => {
                        Object.keys(selectedProducts).forEach((product) => {
                            //When the selected product is dependentLife we are setting it to depLife to be able to match 
                            //the product selected by the employee. This will allow the dep life plan to be added to enrolledPlans
                            if(product === "dependentLife"){
                                product = "depLife"
                            }
                            const selectedplanCode = employeeProductSelection[product] || '';
                            let enrolledPlans = [];
                            switch (product) {
                                case 'medical':
                                    enrolledPlans = flatMedicalPlans;
                                    break;
                                case 'dental':
                                    enrolledPlans = enrolledDentalPlans;
                                    break;
                                case 'vision':
                                    enrolledPlans = enrolledVisionPlans;
                                    break;
                                case 'life':
                                    if (index === 0) {
                                        enrolledPlans = enrolledLifePlans;
                                    } else {
                                        enrolledPlans = enrolledDependentLifePlans;
                                    }
                                    break;
                                case 'depLife':
                                    enrolledPlans = enrolledDependentLifePlans;
                                    break;
                                case 'std':
                                    enrolledPlans = enrolledSTDPlans;
                                    break;
                                case 'ltd':
                                    enrolledPlans = enrolledLTDPlans;
                                    break;
                                case 'suppLife':
                                    if(index == 0){
                                        enrolledPlans = enrolledSuppLifePlans;
                                    }
                                    else{
                                        enrolledPlans =  enrolledSuppLifePlans.filter(plan=> plan.SLSpPlan.enrolled);
                                    }
                                    break;
                                    default:
                                    enrolledPlans = [];
                                    break;
                            }
                            const isPlanEnrolled = checkIfPlanIsEnrolled(enrolledPlans, selectedplanCode, product);
                            let isSuppLifeEnrolled = false;
                            if(product === 'suppLife' && index !==0){
                                isSuppLifeEnrolled=true
                                if(enrolledPlans.length > 0){
                                    isSuppLifeEnrolled =  enrolledPlans.filter(plan => (plan.SLSpPlan.code == selectedplanCode || plan.SLChPlan.code == selectedplanCode ) ).length >0
                                }
                            }
                            if (!((product === 'life' || product ==='suppLife') && employeeStatus === 'COBRA' && !isMNState) && !(product === 'life' && index !== 0 && (enrolledDependentLifePlans || []).length === 0) && !((product === "std" || product === "ltd") && index !== 0 ) && !((product === "std" || product === "ltd") &&  employeeStatus === 'COBRA' ) && !isSuppLifeEnrolled && selectedProducts[product] && employeeProductSelection && !isPlanEnrolled) {
                                //currentStatus = 'In Progress';
                                inCompletePageNames.push('employee');
                                inCompleteSectionNames.push('productAndPlanSelection')
                            }
                        });
                    })
                }

            } else if (rulesSection.sectionName === 'medicalCoverageInfo') {
                const isVisible = isMedicalCoverageInfoVisible(fullProps, application.employee);
                const pcpAssignment = application.employee.pcpAssignment ? application.employee.pcpAssignment : [];
                const isMedicalWaived = application.employee.medicalCoverageInfo && application.employee.medicalCoverageInfo.medicalWaived ? application.employee.medicalCoverageInfo.medicalWaived : false;
                const medicalWaiverReason = application.employee.medicalCoverageInfo && application.employee.medicalCoverageInfo.medicalWaiverReason ? application.employee.medicalCoverageInfo.medicalWaiverReason : '';
                const productSelection = application.employee.productSelection || [];
                const stateCode = flow ==="renewals" ? fullProps.renewalEnrollment?.renewalCaseData?.situsState : fullProps.companyProfile?.locations[0]?.zipData?.stateCode;
                let enrolledMedicalPlans = []
                let enrolledPlans = {}
                if(isUhcRenewal)
                {
                    enrolledPlans = fullProps?.rules?.enrollment?.enrolledPlans 
                }
                enrolledMedicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
                
                if(isVisible){
                    if((!isUhcRenewals() && isMedicalWaived) || (isUhcRenewals() && isMedicalWaived && productSelection[0].medical === 'waive' )){
                        if(!medicalWaiverReason){
                            inCompletePageNames.push('employee');
                            inCompleteSectionNames.push('medicalCoverageInfo') 
                        }
                    }else{
                        if(pcpAssignment.length>0){
                            pcpAssignment.forEach((pcp, index) => {
                                // Incomplete if: no pcp assignment method chosen or need PCP code and PCP code DNE or (PCP code exists && is less than 14 chars long)
                                if(!pcp.assignmentMethod || (pcp.assignmentMethod==='Enter in a PCP code' && (!pcp.pcpCode || (pcp.pcpCode && !/^(\d{11})(\s{0,1})(\d{3})$/.test(pcp.pcpCode))))){
                                    if (productSelection[index] && productSelection[index].medical && productSelection[index].medical !== 'waive') {
                                        inCompletePageNames.push('employee');
                                        inCompleteSectionNames.push('medicalCoverageInfo')
                                    }
                                }
                                // //checking shouln't be waived and assignmentMethod should be fixed,midAtlantic and planType should be OCI
                                if(isUhcRenewal && productSelection[index] && productSelection[index].medical && productSelection[index].medical !== 'waive' &&
                                    pcp?.assignmentMethod==='Enter in a PCP code' && (isUndefinedOrNullOrEmpty(pcp?.pcpCode)) && ["MD","DE","WV","VA","FL","VI"].includes(stateCode) )
                                {
                                    const selectedMedicalPlanCode = productSelection[index].medical;
                                    const selectedMedicalPlan = enrolledMedicalPlans.length > 0 ? enrolledMedicalPlans.find(plan => plan.code === selectedMedicalPlanCode || plan.codeDisplay === selectedMedicalPlanCode) : {};
                                    
                                    if((selectedMedicalPlan?.medicalPlanType === "OCI" || selectedMedicalPlan?.medicalPlanType === "OCI PREFERRED") && selectedMedicalPlan?.installPlatform === "CIRRUS")
                                    {
                                        inCompletePageNames = inCompletePageNames.filter(pageName => pageName !== 'employee')
                                        inCompleteSectionNames = inCompleteSectionNames.filter(sectionName => sectionName !== 'medicalCoverageInfo')
                                    }
                                } 
                            })
                        }else{
                            inCompletePageNames.push('employee');
                            inCompleteSectionNames.push('medicalCoverageInfo')
                        }
                    }
                }
            } else if (rulesSection.sectionName === 'dependentInfo') {
                const numOfDependents = application.employee.numDependents ? application.employee.numDependents : (application.employee.dependents ? application.employee.dependents.length : null);
                for (let i = 0; i < numOfDependents; i++) {
                    let updatedApplication = deepCopy(application);
                    if (updatedApplication.employee && !updatedApplication.employee[rulesSection.sectionName + i]) {
                        updatedApplication = {
                            ...updatedApplication,
                            employee: {
                                ...updatedApplication.employee,
                                [rulesSection.sectionName + i]: updatedApplication.employee.dependents[i]
                            }
                        }
                    }
                    const eachSectionData = checkEachSection(pageName, rulesSection, rulesSection.sectionName + i, updatedApplication, conditionEvaluators, fullProps)
                    completed = eachSectionData.completed;
                    inCompletePageNames = inCompletePageNames.concat(eachSectionData.inCompletePageNames);
                    inCompleteSectionNames = inCompleteSectionNames.concat(eachSectionData.inCompleteSectionNames);
                }
            } else if (rulesSection.sectionName === 'clientDemographicInformation') { // No validation needs to take place, values are all prepopulated
            } else if (rulesSection.sectionName === 'employerHRABenefitContactInformation') {
                // If existing contact is selected, all other fields can ignore validation
                if (application.standardHRA.employerHRABenefitContactInformation.hraFundingAndBenefitsContact === "New Contact") {
                    for (let field of Object.keys(application.standardHRA.employerHRABenefitContactInformation)) {
                        // If Phone or Email is invalid, section is not complete
                        const emailIsInvalid = application.standardHRA.employerHRABenefitContactInformation.standardHRAEmailAddress !== "" && !validateEmail(application.standardHRA.employerHRABenefitContactInformation.standardHRAEmailAddress);
                        const phoneNumberIsInvalid = application.standardHRA.employerHRABenefitContactInformation.standardHRATelephone !== "" && !validatePhoneNumber(application.standardHRA.employerHRABenefitContactInformation.standardHRATelephone);
                        if (emailIsInvalid || phoneNumberIsInvalid) {
                            inCompleteSectionNames.push('employerHRABenefitContactInformation');
                        }

                        // Either Phone or Email is required, not both
                        if (field === "standardHRATelephone") {
                            if (application.standardHRA.employerHRABenefitContactInformation[field] === "" && application.standardHRA.employerHRABenefitContactInformation.standardHRAEmailAddress === "") {
                                inCompleteSectionNames.push('employerHRABenefitContactInformation');
                            }
                        }
                        if (field === "standardHRAEmailAddress") {
                            if (application.standardHRA.employerHRABenefitContactInformation[field] === "" && application.standardHRA.employerHRABenefitContactInformation.standardHRATelephone === "") {
                                inCompleteSectionNames.push('employerHRABenefitContactInformation');
                            }
                        }

                        // Blank values makes section incomplete (Except standardHRATelephone, standardHRAEmailAddress, hraBenefitContactExistingContacts, and streetAddressln2)
                        if (field !== "standardHRATelephone" && field !== "standardHRAEmailAddress" && field !== "hraBenefitContactExistingContacts" && field !== "streetAddressln2" && application.standardHRA.employerHRABenefitContactInformation[field] === "") {
                            inCompleteSectionNames.push('employerHRABenefitContactInformation');
                        }
                    }
                } else if (application.standardHRA.employerHRABenefitContactInformation.hraFundingAndBenefitsContact === "") {
                    inCompleteSectionNames.push('employerHRABenefitContactInformation');
                } else if (application.standardHRA.employerHRABenefitContactInformation.hraFundingAndBenefitsContact === "Existing Contact" && application.standardHRA.employerHRABenefitContactInformation.hraBenefitContactExistingContacts === "") {
                    inCompleteSectionNames.push('employerHRABenefitContactInformation');
                }
            } else if (rulesSection.sectionName === 'hraBankingInformation') {
                for (let field of Object.keys(application.standardHRA.hraBankingInformation)) {
                    if (field !== "streetAddressln2" && application.standardHRA.hraBankingInformation[field] === "") {
                        inCompleteSectionNames.push('hraBankingInformation');
                    }
                    if (field === "transitNumber" || field === "bankAccountNumber") {
                        let valueIsValid = isDigitsOnly(application.standardHRA.hraBankingInformation[field]);
                       
                        if (!valueIsValid) {
                            inCompleteSectionNames.push('hraBankingInformation');
                        }
                    }
                }
            } else if (rulesSection.sectionName === 'hraPlanInformation') {
                for (let field of Object.keys(application.standardHRA.hraPlanInformation)) {
                    if (application.standardHRA.hraPlanInformation[field] === "") {
                        inCompleteSectionNames.push('hraPlanInformation');
                    }
                    if (field === "individualHRA" || field === "familyHRA") {
                        const hraIsInvalid = !validateMoney(application.standardHRA.hraPlanInformation[field])
                        if (hraIsInvalid) {
                            inCompleteSectionNames.push('hraPlanInformation');
                        }
                        // Family HRA must be greater than or equal to Individual HRA AND Family HRA may not exceed 3x Individual HRA
                        if ((application.standardHRA.hraPlanInformation.familyHRA > application.standardHRA.hraPlanInformation.individualHRA * 3) || (parseInt(application.standardHRA.hraPlanInformation.individualHRA) > parseInt(application.standardHRA.hraPlanInformation.familyHRA))) {
                            inCompleteSectionNames.push('hraPlanInformation');
                        }
                        // Individual HRA cannot exceed 50% Individual Deductible Amount
                        if ((application.standardHRA.hraPlanInformation.individualHRA * 2) > parseMoneyString(application.standardHRA.hraPlanInformation.individualDeductibleAmount)) {
                            inCompleteSectionNames.push('hraPlanInformation');
                        }
                        // Family HRA cannot exceed 50% Family Deductible Amount
                        if ((application.standardHRA.hraPlanInformation.familyHRA * 2) > parseMoneyString(application.standardHRA.hraPlanInformation.familyDeductibleAmount)) {
                            inCompleteSectionNames.push('hraPlanInformation');
                        }
                    }
                }
            } else if (rulesSection.sectionName === 'employeeInfo') {
                if (application.employee?.waiveCoverage?.isWaivingAll !== 'Yes') {
                    if (isUhcRenewal || application.employee.lifePlan || !(application.employee.waiveOption && application.employee.waiveOption.includes('life'))) {
                        const productSelection = application.employee.productSelection || [];
                        let lifePlanData = (selectedLifePlans.filter(plan => {
                            return plan.code === (isUhcRenewal && productSelection[0]?.life ? productSelection[0].life : application.employee.lifePlanCode)
                        }))
                        if (lifePlanData[0] && lifePlanData[0].benefitDetails && lifePlanData[0].benefitDetails.benefitType || (isUhcRenewals() && lifePlanData[0] && lifePlanData[0].lifeBenefitType)) {
                            let isMultipleOfSalary = !isUhcRenewals() && lifePlanData[0].benefitDetails.benefitType === "Multiple of Salary" ? true : false;
                            if(isUhcRenewals()){
                                isMultipleOfSalary = (lifePlanData[0].lifeBenefitType === 'SAL MULT' || lifePlanData[0].lifeBenefitType.includes('SAL') || lifePlanData[0].benefitDetails?.benefitType === "Multiple of Salary")
                            }
                            if (application.employee.employeeInfo.salary === '' && isMultipleOfSalary) {
                                inCompleteSectionNames.push('employeeInfo')
                            }
                        }
                    }
                    const employeeClassing = fullProps.life.employeeClassing.classes
                    if ((employeeClassing.length > 0 || application.employee?.employeeInfo?.classingOpted === true) && application.employee?.employeeInfo?.optedLifeClass === ''){
                        inCompleteSectionNames.push('employeeInfo')
                    }
                }
                const eachSectionData = checkEachSection(pageName, rulesSection, rulesSection.sectionName, application, conditionEvaluators, fullProps)
                completed = eachSectionData.completed;
                inCompletePageNames = inCompletePageNames.concat(eachSectionData.inCompletePageNames);
                inCompleteSectionNames = inCompleteSectionNames.concat(eachSectionData.inCompleteSectionNames);
            } else {
                const eachSectionData = checkEachSection(pageName, rulesSection, rulesSection.sectionName, application, conditionEvaluators, fullProps)
                completed = eachSectionData.completed;
                inCompletePageNames = inCompletePageNames.concat(eachSectionData.inCompletePageNames);
                inCompleteSectionNames = inCompleteSectionNames.concat(eachSectionData.inCompleteSectionNames);
            }
        }
    }
    if(flow=="renewals" ){
        isCaseSubmitted= fullProps.renewalEnrollment.caseSubmitted? fullProps.renewalEnrollment.caseSubmitted:false;
    }

    if(isCaseSubmitted){
        inCompletePageNames=[];
        inCompleteSectionNames=[]
    }
    return {
        inCompletePageNames,
        inCompleteSectionNames
    };
}

export const checkEachSection = (pageName, rulesSection, sectionName, application, conditionEvaluators, fullProps) => {
    let completed = true;
    let inCompletePageNames = [];
    let inCompleteSectionNames = [];

    const { overall: { platform } } = fullProps;

    for (const ruleField of rulesSection.fields) {
        let fieldValue;

        if (rulesSection.sectionName === 'dependentInfo') {
            if (application[pageName].hasOwnProperty(sectionName)) {
                fieldValue = application[pageName][sectionName][ruleField.fieldName];
            } else {
                const dependentIndex = sectionName[sectionName.length - 1];
                fieldValue = application[pageName]['dependents'][dependentIndex][ruleField.fieldName];
            }
        } else if (ruleField.fieldName === 'firstName' || ruleField.fieldName === 'lastName' || ruleField.fieldName === 'salary') {
            if (application[pageName]['employeeInfo']) {
                fieldValue = application[pageName][sectionName][ruleField.fieldName];
            } else {
                fieldValue = application[pageName][ruleField.fieldName];
            }
        } else if (application[pageName][sectionName]) {
            if (ruleField.fieldName === 'employeeStatus' || ruleField.fieldName === 'outOfArea') {
                fieldValue = application[pageName][ruleField.fieldName] || application[pageName][sectionName][ruleField.fieldName];
            } 
            // else if (rulesSection.sectionParentPage === 'Employee Information' 
            //     && rulesSection.sectionName === 'contactInfo' 
            //     && ruleField.fieldName === 'zipCode' 
            //     && platform === 'PRIME') {
            //     fieldValue = checkEmployeeZipCodeData(application[pageName][sectionName][ruleField.fieldName] || '') ? application[pageName][sectionName][ruleField.fieldName] : '';
            // } 
            else {
                fieldValue = application[pageName][sectionName][ruleField.fieldName];
            }
        } else {
            fieldValue = ''
        }
         
        let isFieldRequired = ruleField.isRequired ? ruleField.isRequired : false
        let isFieldValidationsError = false
        let fieldHasShowIf = ruleField.showIf ? ruleField.showIf : false
        let isFieldVisible = true
        let validationErrors = null
        let newValidation = ruleField.validations
        const dependentInfo = sectionName.includes('dependentInfo') ? application.employee[sectionName] : undefined
        let isValidationRequired = true;
        if (sectionName === 'contribution') {
            let appState = getRelevantAppState(fullProps.enrollment.application, pageName, sectionName, ruleField);
            appState = makeAppStateNested(appState);
            let newConditionEvaluators = {
                ...conditionEvaluators,
                appState
            }
            if (ruleField.autoValue) {
                const autoValueObj = findObjectThatMeetsConditions(
                    sectionName,
                    ruleField.autoValue,
                    newConditionEvaluators,
                );
                if (autoValueObj.type === 'dynamic') {
                    switch (autoValueObj.value) {
                        case 'medicalEmployerContribution':
                            if (fullProps.companyProfile.selectedProducts.medical) {
                                isValidationRequired = false;
                            }
                            break;
                        default:
                            isValidationRequired = true;
                    }
                }
            }
            if (isValidationRequired) {
                let extraValidationsObj = {}
                if (ruleField.validationsIf) {
                    extraValidationsObj = findObjectThatMeetsConditions(
                        sectionName,
                        ruleField.validationsIf,
                        conditionEvaluators,
                    );
                    if (extraValidationsObj.validations) {
                        newValidation = newValidation.concat(extraValidationsObj.validations)

                    }
                }
            }
        }

        if (!isUndefinedOrNullOrEmpty(fieldValue) && ruleField.validations && isValidationRequired) {
            validationErrors = checkValidationsOnBlur(fieldValue, newValidation, isFieldRequired, fullProps, undefined, dependentInfo);
        }
        if (ruleField.fieldName === 'isDisabled') {
            if (!isUndefinedOrNullOrEmpty(fieldValue) && isValidationRequired) {
                validationErrors = checkValidationsOnBlur(fieldValue, [{ validationType: "isDisabled" }], isFieldRequired, fullProps, undefined, dependentInfo);
            }
        }
        if (validationErrors !== null) {
            isFieldValidationsError = true
        }

        if (fieldHasShowIf) {
            let appState = getRelevantAppState(application, pageName, sectionName, ruleField);
            appState = makeAppStateNested(appState);
            isFieldVisible = checkIfObjectMeetsConditions(
                sectionName,
                ruleField.showIf, {
                ...conditionEvaluators,
                appState,
            },
                fullProps.companyProfile,
                fullProps.rules.enrollment
            )
        }
        if (isFieldVisible) {
            if (isFieldRequired) {
                if (fieldHasShowIf) {
                    if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                        completed = false;
                        inCompletePageNames.push(pageName);
                        inCompleteSectionNames.push(sectionName);
                    }
                } else {
                    // Exception for aoCoverageEmployee field since its a configurableRadio component and does not have a showIf
                    if (ruleField.fieldName === 'aoCoverageEmployee') {
                        // Fix is to make sure that validation happens when the field is there and skips it when the field isnt
                        if (fullProps?.companyProfile?.hasOwnProperty('employerEnrollment')
                        && ( isUhcRenewals() ? 
                        (fullProps?.companyProfile?.employerEnrollment?.employer?.additionalGeneralInformationRenewal?.workersComp !== 'Yes') :
                        (fullProps?.companyProfile?.employerEnrollment?.employer?.additionalGeneralInformation?.workersComp !== 'Yes') // if it is not 'Yes' pass completed since it wont be validated
                        && (isUhcRenewals() ||!['MS', 'TN'].includes(fullProps.companyProfile.locations[0].zipData.stateCode)))) { // these two states always have the ao field show up regardless of workers comp value
                            completed = true;
                        } else if (!fullProps.companyProfile.hasOwnProperty('employerEnrollment')) {
                            completed = true;
                        } else{
                            if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                            completed = false;
                            inCompletePageNames.push(pageName);
                            inCompleteSectionNames.push(sectionName);
                        }
                        }
                    }
                    else if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                        completed = false;
                        inCompletePageNames.push(pageName);
                        inCompleteSectionNames.push(sectionName);
                    }
                }
            } else {
                // Exception for aoCoverageEmployee field since its a configurableRadio component and does not have a showIf
                if (ruleField.fieldName === 'aoCoverageEmployee') {
                    // Fix is to make sure that validation happens when the field is there and skips it when the field isnt
                    if (fullProps?.companyProfile?.hasOwnProperty('employerEnrollment')
                        && ( isUhcRenewals() ? 
                        (fullProps?.companyProfile?.employerEnrollment?.employer?.additionalGeneralInformationRenewal?.workersComp !== 'Yes') :
                        (fullProps?.companyProfile?.employerEnrollment?.employer?.additionalGeneralInformation?.workersComp !== 'Yes')) // if it is not 'Yes' pass completed since it wont be validated
                        && (isUhcRenewals() || !['MS', 'TN'].includes(fullProps.companyProfile.locations[0].zipData.stateCode))) { // these two states always have the ao field show up regardless of workers comp value
                          completed = true;
                    } else if (!fullProps.companyProfile.hasOwnProperty('employerEnrollment')) {
                        completed = true;
                    } else{
                        if (isUndefinedOrNullOrEmpty(fieldValue) || isFieldValidationsError === true) {
                          completed = false;
                          inCompletePageNames.push(pageName);
                          inCompleteSectionNames.push(sectionName);
                      }
                    }
                }
                else if (isUndefinedOrNullOrEmpty(fieldValue) && isFieldValidationsError === true) {
                    completed = false;
                    inCompletePageNames.push(pageName);
                    inCompleteSectionNames.push(sectionName);
                }
            }
        }
    }
    return {
        completed,
        inCompletePageNames,
        inCompleteSectionNames
    }
}

const checkEmployeeZipCodeData = (value) => {
    if (typeof value === 'object' && value.hasOwnProperty('data')) {
        return true;
    }
    return false;
}

const checkIfPlanIsEnrolled = (enrolledPlans, selectedplanCode, productType) => {
    const enrolledPlan = enrolledPlans.find(plan => {
        if (productType === 'medical') {
            if (plan.code === selectedplanCode || plan.codeDisplay === selectedplanCode) {
                return true;
            }
            return false;
        }
        if (plan.code === selectedplanCode || (isUhcRenewals() && (productType === 'std' || productType === 'ltd' || productType === 'suppLife'|| (productType === 'life' && plan.selectedClass)))) {
            return true;
        }
        return false;
    });

    if (enrolledPlan || selectedplanCode === 'waive') {
        return true;
    }
    return false;
}
