import { combineReducers } from 'redux';
import additionalReqdInfo from './products/additionalReqdInfoReducer';
import caseTracking from './products/caseTrackReducer';
import companyProfile from './products/companyProfileReducer';
import debitAuthReducer from './products/debitAuthReducer';
import decide from './products/decideReducer';
import dental from './products/dentalReducer';
import employeeCensus from './products/employeeCensus';
import attestationReducer from './products/enrollment/attestationReducer';
import eligibilityReducer from './products/enrollment/eligibilityReducer';
import employeeReducer from './products/enrollment/employeeReducer';
import employerCertificationReducer from './products/enrollment/employerCertificationReducer';
import employerReducer from './products/enrollment/employerReducer';
import installationReducer from './products/enrollment/installationReducer';
import producerReducer from './products/enrollment/producerReducer';
import enrollmentDocsReducer from './products/enrollment/requiredDocsReducer';
import standardHRAReducer from './products/enrollment/standardHRAReducer';
import quotes from './products/fullyInsReducer'; // TODO: change to medicalFullyIns
import renewalHistory from './products/history/historyReducer';
import proposal from './proposals/proposalReducer';
import life from './products/lifeReducer';
import limitedDistributionReducer from './products/limitedDistributionReducer';
import ltd from './products/ltdReducer';
import nbDashboard from './products/newBusiness/newBusinessDashboardReducer';
import overall from './products/overallReducer'; // TODO: change to medicalOverall
import paymentBillingReducer from './products/paymentBillingReducer';
import permissions from './products/permissionsReducer';
import premium from './products/premiumReducer';
import renewals from './products/renewalReducer';
import renewalEnrollment from './products/renewalsEnrollmentReducer';
import renewalShoppingHelper from './products/renewalShoppingHelperReducer';
import renewalShopping from './products/renewalShoppingReducer';
import reviewCards from './products/reviewCardsReducer';
import review from './products/reviewReducer';
import specialtyCriteria from './products/specialtyReducer';
import std from './products/stdReducer';
import sul from './products/sulReducer';
import supportedArea from './products/supportedAreaReducer';
import vision from './products/visionReducer';
import enrollmentForm from './rules/enrollmentFormReducer';
import enrollmentRules from './rules/enrollmentRulesReducer';
import rateAdjustmentRules from './rules/rateAdjustmentRulesReducer';
import errorHandlerReducer from './shared/errorHandlerReducer';
import nbDashboardOnQuoteTrackingNumber from './products/newBusiness/nbDashboardBasedOnTrackingNumberReducer';
// import lifeEmp from './products/lifeEmpReducer';
import lifeDep from './products/lifeDepReducer';
import suppLife from './products/suppLifeReducer';
import suppLifeDep from './products/suppDepLifeReducer';
import size from './shared/sizeReducer';
import user from './shared/userReducer';
import solarisReducer from './solaris/solarisReducer';
import finalizeCase from './products/finalizeCaseReducer';
import renewalFinalizeCase from './products/renewalFinalizeCaseReducer';
import internalPendManager from './products/internalPendManagerReducer';
import packageSavings from './products/packageSavingsReducer'
import isMemberOnlyCirrus from './products/isMemberOnlyCirrusReducer'
import { combineStateStoreWithInitialState } from '../utils/stateStorePayload/stateStoreInitialize';

const rulesReducer = combineReducers({
    enrollment: enrollmentRules,
    enrollmentForm,
    rateAdjustment: rateAdjustmentRules,
});

const enrollmentApplicationReducer = combineReducers({
    eligibility: eligibilityReducer,
    employer: employerReducer,
    producer: producerReducer,
    attestation: attestationReducer,
    employee: employeeReducer,
    installation: installationReducer,
    census: employeeCensus,
    employerCertification: employerCertificationReducer,
    standardHRA: standardHRAReducer
});

const enrollmentReducer = combineReducers({
    application: enrollmentApplicationReducer,
    documents: enrollmentDocsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'STORED_DATA') {
        const combinedStore = combineStateStoreWithInitialState(action.payload, state);
        return rootReducerMain(combinedStore, action);
    }
    // Clear entire redux state & local storage when user logs out
    if (action.type === 'USER_LOGOUT') {
        localStorage.clear();
        state = undefined;
    }
    return rootReducerMain(state, action);
};

const rootReducerMain = combineReducers({
    quotes,
    size,
    overall,
    dental,
    vision,
    life,
    lifeDep,
    std,
    ltd,
    sul,
    suppLife,
    suppLifeDep,
    // TODO: figure out if we want to separate out basic and supplemental into their own reducers
    // lifeEmp,
    // lifeDep,
    user,
    companyProfile,
    additionalReqdInfo,
    renewals,
    review,
    decide,
    premium,
    renewalShopping,
    renewalShoppingHelper,
    specialtyCriteria,
    rules: rulesReducer,
    enrollment: enrollmentReducer,
    permissions,
    paymentBilling: paymentBillingReducer,
    debitForm: debitAuthReducer,
    renewalEnrollment,
    nbDashboard,
    reviewCards,
    renewalHistory,
    proposal,
    caseTracking,
    limitedDistributionReducer,
    supportedArea,
    solarisReducer,
    errorHandler: errorHandlerReducer,
    nbDashboardOnQuoteTrackingNumber,
    finalizeCase,
    renewalFinalizeCase,
    internalPendManager,
    packageSavings,
    isMemberOnlyCirrus,
});

export default rootReducer;
