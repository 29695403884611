const environmentVariables = {
    isLocal: process.env.REACT_APP_ISLOCAL,
    apiUrls: {
        xport: process.env.REACT_APP_SAMX_XPORT_API_ENDPOINT,
        email: process.env.REACT_APP_SAMX_EMAIL_API_ENDPOINT,
        mongo: process.env.REACT_APP_SAMX_QL_API_ENDPOINT,
        logging: process.env.REACT_APP_SAMX_LOG_API_ENDPOINT,
        batch: process.env.REACT_APP_SAMX_BATCH_API_ENDPOINT,
        redis: process.env.REACT_APP_SAMX_REDIS_API_ENDPOINT,
        fileRetrieval: process.env.REACT_APP_SAMX_FILE_RETRIEVAL_ENPOINT,
    },
};

module.exports = {
    environmentVariables,
};
