import { axios } from '../utils/api/axiosConfig';

import { publicIpv4 } from "public-ip";
const apiRoutes = require('../utils/api/apiRouteConfig');
const {
    getCurrentProcessTitle,
    getCurrentProcessID,
} = require('@ness/nodejs-logger-core/src/main/util/NessLogUtil');
const sendNessLog = async (actionInfo) => {
    const { store } = require('../index');
    if (process.env.REACT_APP_ENV !== 'local') {
        const ipAddress = await publicIpv4();
        const fullProps = store.getState();
        let marketType = fullProps.overall.appConfigDetails && fullProps.overall.appConfigDetails.marketType ? fullProps.overall.appConfigDetails.marketType : '';
        const userIDSamx = fullProps.renewals && fullProps.renewals.loginRequest && fullProps.renewals.loginRequest.reqLogin && fullProps.renewals.loginRequest.reqLogin.optumId;
        const memberGroupId= fullProps.companyProfile.memberGroupId;
        const log = {
            ipAddress: ipAddress,
            hostName: window.location.hostname,
            processTitle: getCurrentProcessTitle(),
            appName: "SAMx",
            product: "Fully Insured",
            process: sessionStorage.getItem(`SAMx-${marketType}`) || 'unknown',
            message: actionInfo.message,
            logClass: actionInfo.logClass,
            severity: actionInfo.severity,
            userId: userIDSamx || 'unknown',
            externalId: memberGroupId || '',
            userAgent: window.navigator.userAgent,
            processId: getCurrentProcessID()
        };
        axios.post(`${apiRoutes.logging}/ness-logging`, log)
            // eslint-disable-next-line no-unused-vars
            .then((result) => {
                // Log was successful
            })
            // eslint-disable-next-line no-unused-vars
            .catch((err) => {
                // Log was unsuccessful
            });
    }
};

export default sendNessLog;
