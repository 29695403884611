import * as types from './actionTypes';

const updateScreenSize = (width) => (dispatch, getState) => {
    const state = getState().size;
    const mobile = width <= 1044;
    const tablet = width > 1044 && width <= 1364;
    const tabletOrLarger = width > 1044;
    const desktop = width > 1364;
    const largeScreen = width > 855;
    const smallScreen = width <= 855;
    const mobileScreen = width <= 988;
    const semanticMobile = width < 768;
    const globalCssBreakpoint = width >= 1400;
    const newState = {};

    if (mobileScreen !== state.mobileScreen) {
        newState.mobileScreen = mobileScreen
    }
    if (smallScreen !== state.smallScreen) {
        newState.smallScreen = smallScreen;
    }
    if (mobile !== state.mobile) {
        newState.mobile = mobile;
    }
    if (tablet !== state.tablet) {
        newState.tablet = tablet;
    }
    if (largeScreen !== state.largeScreen) {
        newState.largeScreen = largeScreen;
    }
    if (tabletOrLarger !== state.tabletOrLarger) {
        newState.tabletOrLarger = tabletOrLarger;
    }
    if (desktop !== state.desktop) {
        newState.desktop = desktop;
    }
    if (semanticMobile !== state.semanticMobile) {
        newState.semanticMobile = semanticMobile;
    }
    if (globalCssBreakpoint !== state.globalCssBreakpoint) {
        newState.globalCssBreakpoint = globalCssBreakpoint;
    }
    if (Object.keys(newState).length > 0) {
        dispatch({
            type: types.UPDATE_SCREEN_SIZE,
            payload: newState,
        });
    }
};

export default updateScreenSize;
