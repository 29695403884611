export const ENROLLMENT_FORM_PATH = [
    'employer-info',
    'eligibility-info',
    'producer-info',
    'employer-attestation',
    //'employees-overview',
    'employee-info',
    'employee-attestation',
    //'payment',
    //'required-documents',
    'employerCertification',
    'manager'

]

export const HIDDEN_BREADCRUMB_PATHS = [
    'employer-info',
    'eligibility-info',
    'producer-info',
    'employer-attestation',
    'employees-overview',
    'employee-info',
    'employee-attestation',
    'payment',
    'required-documents',
    'employerCertification'
]

export const EMPLOYER_APP_PATH = {
    EMPLOYER_INFO: 'employer-info',
    ELIGIBILITY_INFO: 'eligibility-info',
    PRODUCER_INFO: 'producer-info',
    EMPLOYER_ATTESTATION: 'employer-attestation'
}

export const EMPLOYEE_ENROLLMENT_PATH = {
    CENSUS_TABLE: 'employees-overview',
    EMPLOYEE_INFO: 'employee-info',
    EMPLOYEE_ATTESTATION: 'employee-attestation'
}

export const PAYMET_AND_BILLING = {
    PAYMENT: 'payment'
}

export const REQUIRED_DOCS = {
    REQUIRED_DOCUMENTS: 'required-documents'
}