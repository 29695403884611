import { MARKET_TYPES,getMarketType } from "../utils/businessLogic/platformUtils";
const makeHIOSRequestbody = (companyProfile, enrollment, overall) => {
    const canonicalObj= {
        hiosRequestMsg : {
            hiosRequest: {
                reqData : buildRequestBody(companyProfile, enrollment, overall)
            }
        }
    };

    return {
        reqBody: canonicalObj
    };
};
    
export default makeHIOSRequestbody;
     


/**
 * @returns Hios request object
 */
const buildRequestBody = (companyProfile, enrollment, overall) => {
    const { enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans } = enrollment
    let packages = Object.keys(enrollment.enrolledPlans)
    let medicalPlans = []

    packages.forEach(pkg => {
        let plans = enrollment.enrolledPlans[pkg]
        medicalPlans.push(...plans)
    })
    if(getMarketType() === MARKET_TYPES.UHC) {
        medicalPlans = medicalPlans.filter(plan => plan.installPlatform === 'CIRRUS' || plan.installPlatform === null || plan.installPlatform === undefined);
    }
    let medicalPlansLength = medicalPlans.length
    let medicalObj = {}
    if (medicalPlansLength > 0) {
        const medicalPlan = medicalPlans.find((plan) => plan.OISABit)
        if (medicalPlan) {
            medicalObj.oiCd = medicalPlan.OISABit.OI
            medicalObj.saCd = medicalPlan.OISABit.SA
            medicalObj.bitCd = medicalPlan.OISABit.BIT
        }
        else {
            medicalObj.oiCd = '01'
            medicalObj.saCd = '00'
            medicalObj.bitCd = 'CG'
        }
    }

    else{
        medicalObj.oiCd = '01'
        medicalObj.saCd = '00'
        medicalObj.bitCd = 'CG'
    }
        medicalObj.effDate = companyProfile.effectiveDate
        medicalObj.grpSize = parseInt(companyProfile.locations[0].totalEligEmps)
        medicalObj.stateCd = companyProfile.locations[0].zipData.stateCode
        medicalObj.zipCd =  parseInt(companyProfile.locations[0].zipData.zipCode)
        medicalObj.acrInd = 'Y'
        medicalObj.mktTyp = 0
        medicalObj.networkNbr = 0
        // Can only have 2 dental plans in enrollment
        medicalObj.dentPlan = enrolledDentalPlans.length > 0 ? enrolledDentalPlans[0].code : ''
        medicalObj.dualdentPlan = enrolledDentalPlans.length > 1 ? enrolledDentalPlans[1].code : ''
        medicalObj.visnInd = enrolledVisionPlans.length > 0 ? 'Y' : 'N'
        medicalObj.lifeInd = enrolledLifePlans.length > 0 ? 'Y' : 'N'
    if(medicalPlansLength > 0) {
            medicalObj.medreqTbl = []
            var medTbl = []
            medicalPlans.forEach(plan => {
            let riderCd = []
            var selectedRider = []
                    
            riderCd = plan.selectedBenefitRiders
            if (riderCd != undefined)
            {
                riderCd.forEach(rideCode =>{
                selectedRider.push({"riderCd" : rideCode}) 
                })  
                medTbl.push({"pgmYr" : plan.code.slice(0,2),
                                "hlthplnCd" : plan.code.substr(2,2),
                                "rxCd" : plan.rxPlanCode,
                                "metalLvl" : plan.metal.slice(0,1),
                                "ridertbl" : selectedRider
                            })
                }
                else
                {
                medTbl.push({"pgmYr" : plan.code.slice(0,2),
                                "hlthplnCd" : plan.code.substr(2,2),
                                "rxCd" : plan.rxPlanCode,
                                "metalLvl" : plan.metal.slice(0,1)
                                })

                }
                    
        
            })
    
        medicalObj.medreqTbl = medTbl   
    }
    return medicalObj
}