import React, { useMemo } from "react";
import _ from "lodash";
import { Button, Modal } from "semantic-ui-react";
import {
  isUndefinedOrNull,
  isUndefinedOrNullOrEmpty,
} from "../../../utils/validation/commonValidations";

const ModalPopUp = ({
  type,
  title = "",
  message,
  size,
  onButtonClick,
  actionButtons,
}) => {
  const popupType = type ? type.toLowerCase() : type;

  const getButtons = () => {
    if (!isUndefinedOrNull(actionButtons) && actionButtons.length > 0) {
      return actionButtons.map(
        ({ text, cssClasses, disabled, onClick }, index) => {
          return (
            <>
              <Button
                key={index + 1}
                content={text}
                disabled={!isUndefinedOrNull(disabled) ? disabled : false}
                className={
                  !isUndefinedOrNull(cssClasses) ? cssClasses : "primary"
                }
                style={
                  !isUndefinedOrNull(cssClasses) && cssClasses === "whiteButton"
                    ? { lineHeight: "0.8" }
                    : {}
                }
                onClick={() => onClick(text)}
              />
            </>
          );
        }
      );
    } else if (popupType === "confirm") {
      return (
        <>
          <Button
            key="Yes"
            primary
            content="Yes"
            onClick={() => {
              onButtonClick("yes");
            }}
          />
          <Button
            key="No"
            content="No"
            onClick={() => {
              onButtonClick("no");
            }}
          />
        </>
      );
    }

    return (
      <Button
        primary
        content="Ok"
        onClick={() => {
          onButtonClick("yes");
        }}
      />
    );
  };

  const modalTitle = useMemo(() => {
    if (!isUndefinedOrNullOrEmpty(title)) {
      return typeof title === "string" ? <h3>{title}</h3> : title;
    }

    return <h3>{_.capitalize(popupType)}</h3>;
  }, [title]);

  const modalBody = useMemo(() => {
    return typeof message === "string" ? (
      <span dangerouslySetInnerHTML={{ __html: message }}></span>
    ) : (
      message
    );
  }, [message]);

  return (
    !isUndefinedOrNullOrEmpty(message) && (
      <Modal
        size={size ? size : "tiny"}
        open
        onClose={onButtonClick}
        closeOnDimmerClick={false}
        style={{ height: "fit-content" }}
      >
        <Modal.Header className="strongFont" style={{ color: "white" }}>
          {modalTitle}
        </Modal.Header>
        <Modal.Content>{modalBody}</Modal.Content>
        <Modal.Actions>{getButtons()}</Modal.Actions>
      </Modal>
    )
  );
};

export default ModalPopUp;
