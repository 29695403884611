import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import { isUhcRenewals } from '../../../utils/businessLogic/platformUtils';
import { saveStateStore } from '../../../actions/caseTrackAction';
import * as types from '../../../actions/actionTypes';

const UnsavedDataPopup = (props) => {
    const { navName, navPath, open, handleClose, handleLogoClick, handleNavClick, handleLogout } = props;
    const dispatch = useDispatch();
    const caseId = useSelector((state) => state.caseTracking.caseId);
    const isRenewals = useSelector((state) => state.overall.isRenewals);
    const [loading, setLoading] = useState(false);

    const handleSaveClick = async () => {
        try {
            if (!isUhcRenewals() && !isRenewals) {
                if (caseId) {
                    setLoading(true);
                    await dispatch(saveStateStore(true));
                    setLoading(false);
                }
            }
            handleLeaveThisPage();
        } catch {
            setLoading(false);
            dispatch({
                type: types.DISPLAY_ERROR_MESSAGE
            });
        }
    }

    const handleLeaveThisPage = () => {
        switch (navName) {
            case 'Logo':
                handleLogoClick(navPath);
                break;
            case 'Dashboard':
            case 'Quick Quotes':
            case 'Full Shopping':
            case 'Off Season Shopping':
            case 'Member Maintenance':
            case 'Admin Settings':
                handleNavClick(navPath);
                break;
            case 'Logout':
                handleLogout();
                break;
            default:
                break;
        }
    }

    return (
        <Modal onClose={() => handleClose()} open={open} size="tiny">
            <Modal.Header as="h3">Save Changes</Modal.Header>
            <Modal.Content>Do you wish to save the changes and proceed to the {navName === 'Logo' ? 'Home' : navName}?</Modal.Content>
            <Modal.Actions className='paddingBottomSmall'>
                <Button
                    className="whiteButton floatLeft"
                    onClick={() => handleClose()}
                >
                    Cancel
                </Button>
                <Button
                    className='whiteButton'
                    onClick={() => handleLeaveThisPage()}
                >
                    Discard & Exit
                </Button>
                <Button
                    primary
                    loading={loading}
                    disabled={loading}
                    onClick={() => handleSaveClick()}
                >
                    Save & Exit
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default UnsavedDataPopup;
