import { axios } from '../api/axiosConfig';
import apiRoutes from '../api/apiRouteConfig';

export const createFavoritePlanCodesBody = (medicalPlans, dentalPlans, visionPlans, lifePlans, lifeDepPlans, suppLifePlans, stdPlans, ltdPlans) => {
    const favMedicalPlanCodes = extractFavoritePlanCode(medicalPlans);
    const favDentalPlanCodes = extractFavoritePlanCode(dentalPlans);
    const favVisionPlanCodes = extractFavoritePlanCode(visionPlans);
    const favLifePlanCodes = extractFavoritePlanCode(lifePlans);
    const favLifeDepPlanCodes = extractFavoritePlanCode(lifeDepPlans);
    const favSuppLifePlanCodes = extractFavoritePlanCode(suppLifePlans);
    const favStdPlanCodes = extractFavoritePlanCode(stdPlans);
    const favLtdPlanCodes = extractFavoritePlanCode(ltdPlans);

    return {
        favMedicalPlanCodes,
        favDentalPlanCodes,
        favVisionPlanCodes,
        favLifePlanCodes,
        favLifeDepPlanCodes,
        favSuppLifePlanCodes,
        favStdPlanCodes,
        favLtdPlanCodes,
    };
}

const extractFavoritePlanCode = (plans) => {
    if (plans.length == 0) {
        return false
    }

    const planCodes = [];
    plans.forEach(plan => {
        if (plan.favorited) {
            planCodes.push(plan.code)
        }
    })

    return planCodes;
}

export const saveFavoritePlanCodes = (requestBody) => {
    const {
        favMedicalPlanCodes,
        favDentalPlanCodes,
        favVisionPlanCodes,
        favLifePlanCodes,
        favLifeDepPlanCodes,
        favSuppLifePlanCodes,
        favStdPlanCodes,
        favLtdPlanCodes,
        stateCode,
        userName,
        platform,
        optumId
    } = requestBody

    getFavoritePlanCodes(userName, platform, stateCode, optumId)
        .then(res => {
            requestBody.favMedicalPlanCodes = favMedicalPlanCodes ? favMedicalPlanCodes : res.medicalPlanCodes
            requestBody.favDentalPlanCodes = favDentalPlanCodes ? favDentalPlanCodes : res.dentalPlanCodes
            requestBody.favVisionPlanCodes = favVisionPlanCodes ? favVisionPlanCodes : res.visionPlanCodes
            requestBody.favLifePlanCodes = favLifePlanCodes ? favLifePlanCodes : res.lifePlanCodes
            requestBody.favLifeDepPlanCodes = favLifeDepPlanCodes ? favLifeDepPlanCodes : res.lifeDepPlanCodes
            requestBody.favSuppLifePlanCodes = favSuppLifePlanCodes ? favSuppLifePlanCodes : res.suppLifePlanCodes
            requestBody.favStdPlanCodes = favStdPlanCodes ? favStdPlanCodes : res.stdPlanCodes
            requestBody.favLtdPlanCodes = favLtdPlanCodes ? favLtdPlanCodes : res.ltdPlanCodes

            axios.put(`${apiRoutes.mongo}/save-user-favorite-plans`, requestBody)
                .catch(err => {
                    console.log(err.message)
                })
        }).catch(err => {
            console.log(err.message)
        })
}

export const getFavoritePlanCodes = (userName, platform, stateCode, optumId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.mongo}/get-user-favorite-plans?userName=${userName}&platform=${platform}&stateCode=${stateCode}&optumId=${optumId}`)
            .then(res => {
                resolve(res.data);
            }).catch(() => {
                reject();
            })
    })
}

export const setPlansAsFavorite = (allPlansObject, favPlanCode, isSpecialty) => {
    const updateType = ['allPlans', 'plans', 'selectedPlans', 'visiblePlans'];
    const favoritedPlans = [];
    updateType.forEach(type => {
        if(allPlansObject[type] && allPlansObject[type].length > 0) {
            allPlansObject[type].forEach(plan => {
                if (favPlanCode.includes(plan.code)) {
                    plan.favorited = true;
                    if ((isSpecialty && type === 'allPlans') || (!isSpecialty && type === 'plans')) {
                        favoritedPlans.push(plan);
                    }
                }
            })
        }
    })
    return {
        ...allPlansObject,
        favoritedPlans
    }
}