const { mapInstallOption, formatCompositeRates, formatEmployeeRates, getCopayDetails, getINODetails, mapPlanType, dollarFormat } = require("./FilterACAHelper");
import { getMonthDiffInDates } from '../../../../../../utils/formatters/dates';

export const mapVisionPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let visionPlanTypeDescription = productPlan.contributionLevel;
    if (visionPlanTypeDescription === '100% Employer Paid') {
        visionPlanTypeDescription = '100% ER Paid';
    }
    if (visionPlanTypeDescription === 'Employee Core') {
        visionPlanTypeDescription = 'EE Core/Vol Dep';
    }
    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        planName: productPlan.planName ? productPlan.planName : '',
        visionPlanTypeCode: (productPlan.planCode.charAt(0) === 'V' || productPlan.planCode.charAt(0) === 'L') ? 'V/L Plan' : 'S Plan',
        visionPlanTypeDescription,
        renewalPlanType,
        exam: {
            coveredMonthsInterval: productPlan.frequency.examFrequency,
            copayIn: productPlan.copay && productPlan.copay.exam
                ? productPlan.copay.exam : 0,
            allowanceOut: productPlan.allowance.oon.examAllowance,
            secondExamForDiabeticsCopay: productPlan.copay.secondExamForDiabetics,
        },
        lenses: {
            coveredMonthsInterval: productPlan.frequency.lensFrequency,
            allowanceOut: productPlan.allowance.oon.contactLensAllowance, // Need to check
            lensOption: productPlan.lensOptions.lensOptionsText,
        },
        frames: {
            coveredMonthsInterval: productPlan.frequency.frameFrequency,
            allowanceOut: productPlan.allowance.oon.frameAllowance,
            allowanceRetail: productPlan.retailFrameAllowance,
        },
        materialCopayIn: productPlan.copay && productPlan.copay.materials
            ? productPlan.copay.materials : 0,
        contactLenses: {
            allowanceIn: productPlan.allowance.contactLensAllowance === null
                ? 0 : productPlan.allowance.contactLensAllowance,
            allowanceOut: productPlan.allowance.oon.contactLensAllowance,
            fittingAllowance: productPlan.allowance.clFitAllowance === null
                ? 0 : productPlan.allowance.clFitAllowance,
            formularyContactLensesFittingEvaluation: productPlan.copay.formularyContactLensesFittingEvaluation,
        },
        planType: 'Vision',
        platform: 'CIRRUS',
        orderofPreference: productPlan.orderOfPref,
        isPreferredPlan: productPlan.isPrefPlan,
        allowance: productPlan.allowance,
        copayDiabeticsRetinalScreening: productPlan.copay && productPlan.copay.retinalScreeningForDiabetics ? productPlan.copay.retinalScreeningForDiabetics : 0,
        necessaryContactLenses: productPlan.necessaryContactLenses == null ? 0 : productPlan.necessaryContactLenses,
        frequency: productPlan.frequencyCombined,
        copays: productPlan.copay && productPlan.copay.examAndMaterials ? productPlan.copay.examAndMaterials : '0',
        installationType: mapInstallOption(productPlan.availability['ues/SAM'], 'CT', 'cirrus'),
        isMultiStatePlan: productPlan.availability['cirrus'].states.length > 1,
        rateGuarantee: (productPlan && productPlan.rateGuarantee && productPlan.rateGuarantee.rateGuaranteeValue) ? productPlan.rateGuarantee.rateGuaranteeValue : '',
        productSuite: productPlan.productSuite,
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType)

    if (planRate) {
        let monthlyPremium = formatCompositeRates(planRate.rateTable.rate);
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        obj.monthlyPremium = monthlyPremium;
        obj.finalRates = monthlyPremium;

        obj.quote = premiumMonthly;
        obj.finalMonthlyPremium = premiumMonthly;

        obj.enrolledCount = planRate.empCount
        obj.renewalChangePercent = null
        let census = formatEmployeeRates(planRate.employeeRates || [])

        obj.byCensus = {
            finalMonthlyPremium: premiumMonthly,
            employeeRates: census
        }

        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;
}



export const mapDentalPlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false) => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: planCode,
        renewalPlanType: renewalPlanType,
        platform: 'CIRRUS',
        planName: productPlan.planName ? productPlan.planName : '',
        dentalPlanTypeCode: productPlan.productType,
        deductible: {
            individualIn: productPlan.deductible.inDeductibleIndiv,
            individualOut: productPlan.deductible.oonDeductibleIndiv,
            // productPlan.deductibleAmount.family.in,
            familyIn: productPlan.deductible.inDeductibleFamily,
            // productPlan.deductibleAmount.family.out
            familyOut: productPlan.deductible.oonDeductibleFamily,
            // indDeductibleText: `$${productPlan.deductible.inDeductibleIndiv} / $${productPlan.deductible.oonDeductibleIndiv}`,
            // familyDeductibleText: `$${productPlan.deductible.inDeductibleFamily} / $${productPlan.deductible.oonDeductibleFamily}`,
            indDeductibleText: `${dollarFormat(productPlan.deductible.inDeductibleIndiv)} / ${dollarFormat(productPlan.deductible.oonDeductibleIndiv)}`,
            familyDeductibleText: `${dollarFormat(productPlan.deductible.inDeductibleFamily)} / ${dollarFormat(productPlan.deductible.oonDeductibleFamily)}`,
        },
        deductibleWaiver: 'N/A', // productPlan.indicator.deductibleWaiver === "Y",
        coinsurance: {
            preventiveIn: productPlan.services['preventiveIn/Out'].split(' / ')[0],
            preventiveOut: productPlan.services['preventiveIn/Out'].split(' / ')[1],
            basicIn: productPlan.services['basicIn/Out'].split(' / ')[0],
            basicOut: productPlan.services['basicIn/Out'].split(' / ')[1],
            majorIn: productPlan.services['majorIn/Out'].split(' / ')[0],
            majorOut: productPlan.services['majorIn/Out'].split(' / ')[1],
            minorIn: 'N/A', // productPlan.minorRestorativeInNetCoInsurancePct,
            minorOut: 'N/A', // productPlan.minorRestorativeOutNetCoInsurancePct,
            orthodonticsIn: productPlan.services['orthoIn/Out'] !== ' ' && productPlan.services['orthoIn/Out'] !== '' ? productPlan.services['orthoIn/Out'].split(' / ')[0] : '0%',
            orthodonticsOut: productPlan.services['orthoIn/Out'] !== ' ' && productPlan.services['orthoIn/Out'] !== '' ? productPlan.services['orthoIn/Out'].split(' / ')[1] : '0%',
            implantIn: productPlan.services['implantsIn/Out'] !== ' ' ? productPlan.services['implantsIn/Out'].split(' / ')[0] : 0,
            implantOut: productPlan.services['implantsIn/Out'] !== ' ' ? productPlan.services['implantsIn/Out'].split(' / ')[1] : 0,
            periodonticsIn: productPlan.services['perioIn/Out'].split(' / ')[0],
            periodonticsOut: productPlan.services['perioIn/Out'].split(' / ')[1],
            coinsuranceOralIn: productPlan.services['oralSurgeryIn/Out'] !== ' ' && productPlan.services['oralSurgeryIn/Out'] !== '' ? productPlan.services['oralSurgeryIn/Out'].split(' / ')[0] : 0,
            coinsuranceOralOut: productPlan.services['oralSurgeryIn/Out'] !== ' ' && productPlan.services['oralSurgeryIn/Out'] !== '' ? productPlan.services['oralSurgeryIn/Out'].split(' / ')[1] : 0,
        },
        maxBenefit: {
            in: productPlan.annualMax.inAnnualMax,
            out: productPlan.annualMax.oonAnnualMax,
            // maxBenefitText: `${productPlan.annualMax.inAnnualMax} / ${productPlan.annualMax.oonAnnualMax}`,
            maxBenefitText: `${dollarFormat(productPlan.annualMax.inAnnualMax)} / ${dollarFormat(productPlan.annualMax.oonAnnualMax)}`,
        },
        ucr: productPlan.ucrPct,
        orthodontics: {
            inCovered: productPlan.ortho.inOrthoEligibility,
            inLifetimeBenefit: productPlan.ortho.inOrthoLifetimeMax,
            outCovered: productPlan.ortho.oonOrthoEligibility,
            outLifetimeBenefit: productPlan.ortho.oonOrthoLifetimeMax,
        },
        waitingPeriodMonths: 'N/A', // productPlan.waitingPeriodMonths,
        fundingType: productPlan.contributionStrategy !== undefined ? productPlan.contributionStrategy.toUpperCase() : '', // productPlan.planFundingDescription,
        planType: 'Dental',
        endodonticServices: {
            inEndo: productPlan.endo.inEndo,
            inEndoPulpotomy: productPlan.endo.inEndoPulpotomy,
            oonEndo: productPlan.endo.oonEndo,
            oonEndoPulpotomy: productPlan.endo.oonEndoPulpotomy,
        },
        simpleExtractions: {
            inSimpleExtractions: productPlan.simpleExtractions.inSimpleExtractions,
            oonSimpleExtractions: productPlan.simpleExtractions.oonSimpleExtractions,
        },
        orderofPreference: productPlan.orderOfPref,
        isPreferredPlan: productPlan.isPrefPlan,
        dentalPlanType: mapPlanType(productPlan.planType),
        classShiftDescription: productPlan.classShiftDesc,
        waitingPeriodMajor: productPlan.waitingPeriodMajorServices,
        dedicatedLine: productPlan.dedicateLine ? productPlan.dedicateLine : '**standard**',
        includeOrtho: productPlan.includeOrtho,
        includeImplants: productPlan.includeImplants,
        includeCMM: productPlan.includeCMM,
        includePMM: productPlan.includePMM,
        inCoins: productPlan.coinsurance['inCoins(P&D/Basic/Major/Ortho)'],
        outCoins: productPlan.coinsurance['outCoins(P&D/Basic/Major/Ortho)'],
        installationType: mapInstallOption(productPlan.stateAvailability['ues/SAM'], 'CT','cirrus'),
        dentalNetworkType: productPlan.dentalNetworkType,
        style: productPlan.style,
        productType: productPlan.productType,
        copayDetails: getCopayDetails(productPlan),
        inoDetails: getINODetails(productPlan),
        isMultiStatePlan: productPlan.stateAvailability['cirrus'].states.length > 1,
        rateGuarantee: (productPlan && productPlan.rateGuarantee && productPlan.rateGuarantee.rateGuaranteeValue) ? productPlan.rateGuarantee.rateGuaranteeValue : '',
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType)

    if (planRate) {
        let monthlyPremium = formatCompositeRates(planRate.rateTable.rate);
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        obj.monthlyPremium = monthlyPremium;
        obj.finalRates = monthlyPremium;

        obj.quote = premiumMonthly;
        obj.finalMonthlyPremium = premiumMonthly;

        obj.enrolledCount = planRate.empCount
        obj.renewalChangePercent = null
        let census = formatEmployeeRates(planRate.employeeRates || [])

        obj.byCensus = {
            finalMonthlyPremium: premiumMonthly,
            employeeRates: census
        }

        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;

}



export const mapBasicLifePlans = (isCurrent, planCode, productPlan, planRates, planRateComponents, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        ADandDCode: productPlan.blADnDPairNew,
        depLifeCode: productPlan.blBdlPairNew,
        benefitAmount: productPlan.blBenefitLevel,
        productType: productPlan.productType,
        planType: productPlan.product,
        fundingType: productPlan.ContributionType,
        minimumParticipationPercentage: productPlan.participationRequirement,
        ADandDBenefitAmount: productPlan.pairedAdnDPlanDetails.blBenefitLevel ? productPlan.pairedAdnDPlanDetails.blBenefitLevel : productPlan.blBenefitLevel,
        spouseBenefitAmount: productPlan.coverageType.toUpperCase() === 'DEPENDANT' ? productPlan.benefitDetails.FlatAmount : productPlan.benefitDetails.childBenefit2,
        childBenefitAmount: productPlan.benefitDetails.childBenefit2,
        planMax: productPlan.maxAmt,
        lifeBenefitType: productPlan.coverageType,
        ADandDBenefitType: productPlan.pairedAdnDPlanDetails.coverageType,
        ageBenefitReductionSchedule: productPlan.benefitReduction,
        coverageTermination: productPlan.coverageTerm,
        suicideLimitBenefit: productPlan.lifeBenefits.suicideLimitBenefit,
        acceleratedDeathBenefit: productPlan.lifeBenAccelDeathBenComined,
        lifeExpectancy: productPlan.lifeBenefits.accidentalDeathBenefit.lifeExpectancy,
        eliminationPeriod: productPlan.lifeBenefits.waiverofPremium.eliminationPeriod,
        conversion: productPlan.blConversion,
        portability: productPlan.blPortability,
        waiverOfPremium: productPlan.LifeBenWOPCombined,
        primePlanCode: productPlan.primePlanCode,
        adAndDBenefit: productPlan.pairedAdnDPlanDetails.aDnDBenefits,
        benefitDetails: productPlan.benefitDetails,
        lifeBenefits: productPlan.lifeBenefits,
        rateType: productPlan.rateType,
        isPreferredPlan: true, 
        platform: 'CIRRUS',
        pairedAdnDDetails: {
            code: productPlan.pairedAdnDPlanDetails.planCode,
            benefitAmount: productPlan.pairedAdnDPlanDetails.blBenefitLevel,
            productType: productPlan.pairedAdnDPlanDetails.productType,
            planType: productPlan.pairedAdnDPlanDetails.product,
            fundingType: productPlan.pairedAdnDPlanDetails.ContributionType,
            minimumParticipationPercentage: productPlan.pairedAdnDPlanDetails.participationRequirement,
            spouseBenefitAmount: productPlan.pairedAdnDPlanDetails.benefitDetails ? productPlan.pairedAdnDPlanDetails.benefitDetails.FlatAmount : '',
            childBenefitAmount: productPlan.pairedAdnDPlanDetails.benefitDetails ? productPlan.pairedAdnDPlanDetails.benefitDetails.childBenefit2 : '',
            planMax: productPlan.pairedAdnDPlanDetails.maxAmt,
            lifeBenefitType: productPlan.pairedAdnDPlanDetails.coverageType,
            ageBenefitReductionSchedule: productPlan.pairedAdnDPlanDetails.benefitReduction,
            coverageTermination: productPlan.pairedAdnDPlanDetails.coverageTerm,
            suicideLimitBenefit: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.suicideLimitBenefit : '',
            acceleratedDeathBenefit: productPlan.pairedAdnDPlanDetails.lifeBenAccelDeathBenComined,
            lifeExpectancy: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.accidentalDeathBenefit.lifeExpectancy : '',
            eliminationPeriod: productPlan.pairedAdnDPlanDetails.lifeBenefits ? productPlan.pairedAdnDPlanDetails.lifeBenefits.waiverofPremium.eliminationPeriod : '',
            conversion: productPlan.pairedAdnDPlanDetails.blConversion,
            portability: productPlan.pairedAdnDPlanDetails.blPortability,
            waiverOfPremium: productPlan.pairedAdnDPlanDetails.LifeBenWOPCombined,
            primePlanCode: productPlan.pairedAdnDPlanDetails.primePlanCode,
            adAndDBenefit: productPlan.pairedAdnDPlanDetails.aDnDBenefits,
            benefitDetails: productPlan.pairedAdnDPlanDetails.benefitDetails,
            lifeBenefits: productPlan.pairedAdnDPlanDetails.lifeBenefits,
            rateType: productPlan.pairedAdnDPlanDetails.rateType,
            name: productPlan.pairedAdnDPlanDetails.planName,
        },
        isMultiStatePlan: productPlan.stateAvailability.isUHCMultiStatePlan,
        name: productPlan.planName,

        renewalPlanType,
        isDiscontinued
    }

    let blPlanCode = planCode && planCode.split('--')[0];
    let addPlanCode = planCode && planCode.split('--')[1];
    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    let blPlanRateComp = planRateComponents.find(planRateComp => planRateComp.planCode && planRateComp.planCode === blPlanCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    let addPlanRateComp = planRateComponents.find(planRateComp => planRateComp.planCode && planRateComp.planCode === addPlanCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    
    if (planRate && blPlanRateComp && addPlanRateComp) {
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        let census = formatEmployeeRates(planRate.employeeRates || []);
        let byCensus = {
            totalMonthlyPremium: premiumMonthly,
            employeeRates: census,
            employeeRate: census
        };

        let rateObj = {
            basicLifeRateVol: blPlanRateComp.planRate.rate,
            aDnDLifeRateVol: addPlanRateComp.planRate.rate,
            totalLifeRateVol: planRate.rateTable.rate[0].total,

            BasicLifeTotVolAmount: blPlanRateComp.rateTotals[0].totalVolume,
            ADDTotVolAmount: addPlanRateComp.rateTotals[0].totalVolume,

            basicLifeTotalPremium: blPlanRateComp.rateTotals[0].total,
            aDndTotalPremium: addPlanRateComp.rateTotals[0].total,
            totalMonthlyPremium: premiumMonthly
        };

        obj.byCensus = byCensus;
        obj.finalMonthlyPremium = premiumMonthly;
        obj.finalRates = {
            byCensus,
            ...rateObj,
            employeeRates: census,
            employeeRate: census
        };
        obj.quote = {
            byCensus,
            ...rateObj,
            employeeRates: census,
            employeeRate: census
        };
        obj.enrolledCount = planRate.empCount;
        obj.guaranteedIssue = planRate.guarIssueAmt;
        obj.pairedAdnDDetails.guaranteedIssue = planRate.guarIssueAmt;
        obj.rateGuarantee = getMonthDiffInDates(new Date(planRate.rateGuarBegin), new Date(planRate.rateGuarEnd));
        obj.renewalChangePercent = premiumMonthlyRenewalChange
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange
    }

    return obj;
}



export const mapDepLifePlans = (isCurrent, planCode, productPlan, planRates, isDiscontinued = false)  => {

    const renewalPlanType = isCurrent ? "current" : "renewing";
    const planRateCheckPlanType = isCurrent ? "current" : "renewal";

    let obj = {
        ...productPlan,
        code: productPlan.planCode,
        ADandDCode: productPlan.blADnDPairNew,
        depLifeCode: productPlan.blBdlPairNew,
        benefitAmount: productPlan.blBenefitLevel,
        productType: productPlan.productType,
        planType: productPlan.productType == "Basic Dep Life" ? "LIFE_DEP_BASIC" : productPlan.product,
        fundingType: productPlan.ContributionType,
        minimumParticipationPercentage: productPlan.participationRequirement,
        spouseBenefitAmount: productPlan.coverageType.toUpperCase() === 'DEPENDANT' ? productPlan.benefitDetails.FlatAmount : productPlan.benefitDetails.childBenefit2,
        childBenefitAmount: productPlan.benefitDetails.childBenefit2,
        planMax: productPlan.maxAmt,
        lifeBenefitType: productPlan.coverageType,
        ageBenefitReductionSchedule: productPlan.benefitReduction,
        coverageTermination: productPlan.coverageTerm,
        suicideLimitBenefit: productPlan.lifeBenefits.suicideLimitBenefit,
        acceleratedDeathBenefit: productPlan.lifeBenAccelDeathBenComined,
        lifeExpectancy: productPlan.lifeBenefits.accidentalDeathBenefit.lifeExpectancy,
        eliminationPeriod: productPlan.lifeBenefits.waiverofPremium.eliminationPeriod,
        conversion: productPlan.blConversion,
        portability: productPlan.blPortability,
        waiverOfPremium: productPlan.LifeBenWOPCombined,
        primePlanCode: productPlan.primePlanCode,
        benefitDetails: productPlan.benefitDetails,
        lifeBenefits: productPlan.lifeBenefits,
        rateType: productPlan.rateType,
        isPreferredPlan: true, 
        platform: 'CIRRUS',
        isMultiStatePlan: productPlan.stateAvailability.isUHCMultiStatePlan,
        name: productPlan.planName,

        renewalPlanType,
        isDiscontinued
    }

    let planRate = planRates.find(planRate => planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType.toLowerCase() === planRateCheckPlanType);
    
    if (planRate) {
        let premiumMonthly = planRate.rateTotals[0].total;
        let premiumAnnual = planRate.rateTotals[1].total;
        let premiumMonthlyRenewalChange = planRate.rateTotals[0].renewalChg_pct;
        let premiumMonthlyAnnualChange = planRate.rateTotals[1].renewalChg_pct;

        let census = formatEmployeeRates(planRate.employeeRates || []);
        let byCensus = {
            totalMonthlyPremium: premiumMonthly,
            employeeRates: census
        };

        let rateObj = {
            depLifeChildRate: planRate.rateTable.rate[0].total,
            depLifeSpouseRate: planRate.rateTable.rate[0].total,
            depLifeTotPremium: premiumMonthly,
            totalMonthlyPremium: premiumMonthly,
            blCode: productPlan.planCode,
            code: productPlan.planCode,
            combinedCodes: productPlan.planCode+"--null"
        };

        obj.byCensus = byCensus;
        obj.finalMonthlyPremium = premiumMonthly;
        obj.finalRates = {
            byCensus,
            ...rateObj,
            employeeRates: census,
        };
        obj.quote = premiumMonthly;
        obj.enrolledCount = planRate.depCount - planRate.empCount;
        obj.dependentLifeRate = planRate.rateTable.rate[0].total;
        obj.rateGuarantee = getMonthDiffInDates(new Date(planRate.rateGuarBegin), new Date(planRate.rateGuarEnd));
        obj.renewalChangePercent = premiumMonthlyRenewalChange;
        obj.renewalChangePercentAnnual = premiumMonthlyAnnualChange;
    }

    return obj;
}
