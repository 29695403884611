import React from 'react'
import { connect } from 'react-redux';
import { Image, Message, Button } from 'semantic-ui-react'
import './errorBoundary.css'
import { UHC_OXFORD_CODE_BREAK, KEY_NAME } from '../utils/errorLogging/logKeys'
import { logError } from '../utils/errorLogging/logError';
import { history } from '../utils/history';
import ErrorLogo from './error_img.png';
import uhcLogo from '../images/logo-2020.svg';
import squares from './squares.svg';
import { sendErrorTrackingDataAction } from '../actions/analyticsAction';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: props.error,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: true,
            errorInfo: errorInfo
        })
        // log error messages to an error reporting service here
        sendErrorTrackingDataAction({
            activityName : `Page Break : Oops! Something went wrong`,
            errorFields :`${error?error.toString():''}`,
            errorType :"Ui Error"
        })
        logError(KEY_NAME, UHC_OXFORD_CODE_BREAK, 'codebreak', {}, { error: error.toString(), errorInfo: errorInfo });
    }

    handleClick = () => {
        this.setState({
            error: false,
            errorInfo: null
        })
        history.push('/home');
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <div className='error-background'>
                    <img src={uhcLogo} className="uhc-logo" alt="UnitedHealthcare" />
                    <img src={squares} className="squares" alt="" />
                    <div className='error-container'>
                        <div className='justifyCenter'>
                            <Image src={ErrorLogo} size="tiny" />
                        </div>
                        <h1 className='error-heading'>Oops! Something went wrong.</h1>
                        <p className='error-paragraph'>There is a technical issue preventing this activity at this time. Please contact us at {this.props.platform === 'PRIME' ? '1-866-764-7736' : '888-201-4216'}.</p>
                        <p className='error-paragraph'>The SAMx Support Team is available Monday through Friday 8 a.m. - 5 p.m. EST.</p>
                        <p className='error-paragraph'>For technical issues related specifically to the NH UnitedHealthcare Freedom Plan, please contact us at 1-866-764-7735.</p>
                        {(this.props.memberGroupId || this.props.companyName || this.props.caseId) &&
                            (
                                <div className='justifyCenter'>
                                    <Message className="error-company-info-container" info>
                                        {this.props.memberGroupId && (
                                            <>
                                                <Message.Header>Member Group ID:</Message.Header>
                                                <p>{this.props.memberGroupId}</p>
                                            </>
                                        )}
                                        {this.props.companyName && (
                                            <>
                                                <Message.Header>Employer Name:</Message.Header>
                                                <p>{this.props.companyName}</p>
                                            </>
                                        )}
                                        {this.props.caseId && (
                                            <>
                                                <Message.Header>Case ID:</Message.Header>
                                                <p>{this.props.caseId}</p>
                                            </>
                                        )}
                                    </Message>
                                </div>
                            )
                        }
                        {this.props.logInData && Array.isArray(this.props.logInData) && this.props.logInData.length > 0 &&
                            <div className='justifyCenter marginTopMedium'>
                                <Button
                                    className="returnToHomeButton"
                                    onClick={this.handleClick}
                                    icon='home'
                                    labelPosition='left'
                                    content='Return To Home'
                                />
                            </div>
                        }
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}


const mapStateToProps = (state) => ({
    memberGroupId: state.companyProfile.memberGroupId,
    caseId: state.caseTracking.caseId,
    companyName: state.companyProfile.companyName,
    platform: state.overall.platform,
    logInData: state.renewals.logInData
});
const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);