import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Icon, Label } from 'semantic-ui-react';
import { getCensusRates, getCensusPlans } from '../../../../actions/fullQuotesActions';
import { createHistory } from '../../../../actions/historyAction';
import { getEnrollmentStateRefined } from '../../../../reducers/products/enrollment/helper';
import { getNBCaseProgress, inSubmittedStatus } from '../../../../reducers/products/helpers/companyProfileConsts';
import deepCopy from '../../../../utils/deepCopy';
import { isUndefinedOrNull } from '../../../../utils/validation/commonValidations';
import ModalPopUp from '../../ModalPopUp/ModalPopUp';
import './breadCrumb.css';
import { setCensusComplete } from '../../../../actions/quickQuotesActions';
import producerHandleMessage from '../../ProducerHandlerMessage';
import IneligibleCaseModal from '../../IneligibleCaseModal';
import { sendClickEventDataAction } from '../../../../actions/analyticsAction';
import LoaderPopup from '../../ModalPopUp/LoaderPopup';


const BreadCrumb = (props) => {
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [modalPoupDetails, setModalPoupDetails] = useState({
    type: '',
    message: '',
    actions: undefined,
  });

 const [ineligibleOwnerModal, setIneligibleOwnerModal] = useState(false);
 const [ineligibleOwnerModalMessage, setIneligibleOwnerModalMessage] = useState('');

  const dispatch = useDispatch();

  const breadCrumbAccess = useSelector((state) => state.quotes.breadCrumbAccess);
  const size = useSelector((state) => state.size);
  const permissions = useSelector((state) => state.permissions);

  const overall = useSelector((state) => state.overall);
  const companyProfile = useSelector((state) => state.companyProfile);
  const employeeEnrollment = useSelector((state) => state.enrollment.application.employee);
  const viewSpecialty = useSelector((state) => state.specialtyCriteria.viewSpecialty);
  const sicCode = useSelector((state) => state.specialtyCriteria.sicCode);
  const b2b = useSelector((state) => state.overall.b2b) || false;
  const store = useSelector((state) => state);

  const displayErrorMessage = useSelector((state) => state.overall.displayErrorMessage);

  const { pathname, roleName } = props;
  const pathParts = pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];
  const penultimatePart = pathParts[pathParts.length - 2];

  const disableBreadcrumbBeyond = useSelector((state) => state.overall.disableBreadcrumbBeyond);

  const revokeEmployeeCensusStep = disableBreadcrumbBeyond === 'full';
  const revokePlanSelectionStep = disableBreadcrumbBeyond === 'employee' || revokeEmployeeCensusStep;
  const revokeQuoteReviewStep = disableBreadcrumbBeyond === 'plans' || revokePlanSelectionStep;
  const revokeEnrollmentStep = disableBreadcrumbBeyond === 'summary' || revokeQuoteReviewStep;
  const revokeFinalizeCaseStep = disableBreadcrumbBeyond === 'enrollment' || revokeEnrollmentStep;

  const companyInfo = lastPart === 'full';
  const employeeInfo = lastPart === 'employee' || penultimatePart === 'employee';
  const planSelection = lastPart === 'plans';
  const quoteSummary = lastPart === 'summary' || penultimatePart === 'summary';
  const enrollManager = lastPart === 'manager' || penultimatePart === 'enrollment';
  const finalizeCase = lastPart === 'finalize-case';

  useEffect(() => {
    setShowLoader(false);
  }, [lastPart])

  useEffect(() => {
    if (displayErrorMessage) {
      setShowLoader(false);
    }
  }, [displayErrorMessage])

  const isBreadCrumbPage = companyInfo || employeeInfo || planSelection || quoteSummary || enrollManager || finalizeCase;  

  if (!isBreadCrumbPage) {
    return null;
  }

  const shouldInitializePlanCall = () => {
    const { quotes, dental, vision, life } = store;
    const { viewSpecialty: selectedProducts } = store.specialtyCriteria;
    if (selectedProducts.medical && (quotes.plans || []).length === 0
      || selectedProducts.dental && (dental.allPlans || []).length === 0
      || selectedProducts.vision && (vision.allPlans || []).length === 0
      || selectedProducts.life && (life.allPlans || []).length === 0
    ) {
      return true;
    }
    return false;
  }
 

  const navigate = (page, includeID = true) => {
    dispatch(createHistory('Breadcrumb clicked', lastPart + ' ' + penultimatePart, 'Success', getEnrollmentStateRefined(store), page, 'NB'));
    props.history.push(`/${page}`);
  };

  const { full, employee, plans, summary, enrollmanager, finalizecase } = breadCrumbAccess;

  const { mobile } = size;
  //allow user to navigate to Company Information page if they've already completed the page and have permissions
  const companyInfoStep = () => {
    if (permissions.fullShoppingCompanyInfo.viewCompanyInformation && full && !b2b) {
      return navigate('full', false);
    }
  };

  const employeeCensusStep = () => {
    if (
      (permissions.fullShoppingCompanyInfo.moveToEmployeeCensus || permissions.fullShoppingEmployeeCensus.viewCensus) &&
      employee &&
      !revokeEmployeeCensusStep
    ) {
      return navigate('employee');
    }
  };

  const planSelectionStep = () => {
    if (
      (permissions.fullShoppingEmployeeCensus.moveToPlanSelection || permissions.fullShoppingPlanSelection.viewAllPlans) &&
      plans &&
      !revokePlanSelectionStep
    ) {
      if (shouldInitializePlanCall()) {
        dispatch(setCensusComplete(true));
        getPlansForPS('employeeCensus'); 
        setShowLoader(true);
      } else {
        rateCallsQR_PS(false);
        return navigate('plans');
      }
    }
  };

  const quoteReviewStep = () => {
    if (summary && !revokeQuoteReviewStep) {
      rateCallsQR_PS(true);
      return navigate('summary');
    }
  };

  const enrollmentStep = () => {
    if (permissions.fullShoppingQuoteReview.moveToEnrollment && enrollmanager && !revokeEnrollmentStep) {
      return navigate('enrollment/manager');
    }
  };

  // rate call when user has completed the employee enrollment and moves to previous pages
  // QR -> Quote Review
  // PS -> Plan Selection
  const rateCallsQR_PS = (isQuoteReview) => {
    if (employeeEnrollment.employeeEnrollmentCompletion && !inSubmittedStatus(getNBCaseProgress(store))) {
      const groupSize = companyProfile.employees.flat().length;
      const totalEmployees = deepCopy(companyProfile.employees.flat());
      const includeSpecialty =
        viewSpecialty.dental || viewSpecialty.vision || viewSpecialty.life || viewSpecialty.std || viewSpecialty.ltd || viewSpecialty.sul || false;
      const selectedProducts = Object.keys(viewSpecialty).filter((product) => viewSpecialty[product] === true);
      const otherDetails = {
        includeSpecialty,
        selectedProducts,
        viewSpecialty: viewSpecialty,
        sicCode: sicCode,
        employeeCensus: totalEmployees,
        platform: overall.platform,
        simpleCensus: null,
      };
      // remove after PRIME testing
      // dispatch(getCensusPlans(
      //     groupSize,
      //     companyProfile.locations[0].zipData,
      //     companyProfile.effectiveDate,
      //     includeSpecialty,///
      //     totalEmployees,///
      //     companyProfile.location,///
      //     companyProfile,
      //     null,
      //     otherDetails,///
      //     'afterCloseEnrollment'
      // ));
      const planCodes = store.quotes.plans.map((plan) => plan.code);
      const dentalPlanCodes = store.dental.plans.map((plan) => plan.code);
      const visionPlanCodes = store.vision.plans.map((plan) => plan.code);
      const basicLifePlanCodes = store.life.plans.map((plan) => plan.code);
      const stdPlanCodes = store.std.plans.map((plan) => plan.code);
      const ltdPlanCodes = store.ltd.plans.map((plan) => plan.code);


      dispatch(
        getCensusRates(
          planCodes,
          dentalPlanCodes,
          visionPlanCodes,
          basicLifePlanCodes,
          stdPlanCodes,
          ltdPlanCodes,
          [],
          otherDetails.platform,
          groupSize,
          companyProfile.locations[0].zipData.zipCode,
          companyProfile.effectiveDate,
          companyProfile.locations[0].zipData.stateCode,
          companyProfile.locations[0].zipData.countyFIPSCode,
          otherDetails.includeSpecialty,
          totalEmployees,
          companyProfile.locations[0].zipData,
          companyProfile,
          null,
          'Shopping',
          'afterCloseEnrollment',
          [],
          {},
          false,
          {}
        )
      );
    }
  };

  const getPlansForPS = (sourcePage) => {
    const groupSize = companyProfile.employees.flat().length;
    const totalEmployees = deepCopy(companyProfile.employees.flat());
    const includeSpecialty = viewSpecialty.dental || viewSpecialty.vision || viewSpecialty.life || viewSpecialty.std || viewSpecialty.ltd || viewSpecialty.sul || false;
    const selectedProducts = Object.keys(viewSpecialty).filter((product) => viewSpecialty[product] === true);
    const otherDetails = {
      includeSpecialty,
      selectedProducts,
      viewSpecialty: viewSpecialty,
      sicCode: sicCode,
      employeeCensus: totalEmployees,
      platform: overall.platform,
      simpleCensus: null,
    };
    dispatch(getCensusPlans(
        groupSize,
        companyProfile.locations[0].zipData,
        companyProfile.effectiveDate,
        includeSpecialty,
        totalEmployees,
        companyProfile.locations[0],
        companyProfile,
        null,
        otherDetails,
        'plans',
        store.quotes.selectedPlans,
        sourcePage
    ));
  }
  
  const finalizeCaseStep = () => {
    if (finalizecase && !revokeFinalizeCaseStep) {
      dispatch(createHistory('Finalize Details Stepper clicked', lastPart + ' ' + penultimatePart, 'Success', getEnrollmentStateRefined(store), 'finalize-case', 'NB'));
      props.history.push('/finalize-case');
      //return navigate('finalize-case');
    }
  };

  // Only certain roles should display Finalize Case breadcrumb
  // TODO: Add additional roles
  const displayFinalizeCaseRoles = [
    'Production Support Admin',
    'UHC Virtual Ops',
    'OXHP Virtual Ops',
    'OXHP Install Rep',
    'UHC Install Rep',
    'Install Supervisor',
  ];
  const displayFinalizeCase = displayFinalizeCaseRoles.includes(roleName) && (overall.appConfigDetails.marketType === 'PRIME' || overall.appConfigDetails.marketType === 'CIRRUS') ;



  const handleBreadcrumbClick = (pageName) => {
    const { caseTracking } = store;
    const currentCaseTrack = caseTracking.caseTrack[0];

    const pagesToBeNotify = ['Company Information', 'Census Information', 'Plan Selection'];

    const notifyRateChangeOnDeSelectPlan =
      !isUndefinedOrNull(currentCaseTrack) &&
      currentCaseTrack.source === 'DES' &&
      // currentCaseTrack.caseStatus === 'Quoting' &&
      lastPart.toLowerCase() === 'summary' &&
      pagesToBeNotify.indexOf(pageName) !== -1;
      dispatch(sendClickEventDataAction({
        clickName : 'BreadCrumb : '+pageName,
        clickElement : 'Button',
        clickType : 'internal'
      }))
    const clickHandler = {
      'Company Information': companyInfoStep,
      'Census Information': employeeCensusStep,
      'Plan Selection': planSelectionStep,
      'Quote Review': quoteReviewStep,
      Enroll: enrollmentStep,
      'Finalize Details': finalizeCaseStep,
    };
   
    if (notifyRateChangeOnDeSelectPlan) {
      const data = producerHandleMessage(store.reviewCards, null, overall.dashboardCheckPlatform);
           
      if (data[0]) {
        setIneligibleOwnerModal(true);
        setIneligibleOwnerModalMessage(data[1])       
        return;
      }
      
      setShowModalPopup(true);
      setModalPoupDetails({
        type: 'Confirm',
        message: 'Please be aware if you choose to de-select plans rates may be subject to change.  Do you wish to continue?',
        actions: [
          {
            text: 'Yes',
            cssClasses: 'whiteButton',
            onClick: () => {
              setShowModalPopup(false);
              if (pageName === 'Plan Selection') {                
                dispatch(setCensusComplete(true));                
                getPlansForPS('quoteReview'); 
                setShowLoader(true);
              }
              else {
                clickHandler[pageName]();
              }
            },
          },
          {
            text: 'No',
            onClick: () => {
              setShowModalPopup(false);
            },
          },
        ],
      });
    } else {
      clickHandler[pageName]();
    }
  };

  const getBreadcrumb = ({ pageName, breadcrumbStyle, labelStyle, isPageCompleted, isPageActive, showDivider = true }) => {
    const pageNumber = {
      'Company Information': 1,
      'Census Information': 2,
      'Plan Selection': 3,
      'Quote Review': 4,
      'Enroll': 5,
      'Finalize Details': 6,
    };

    if (b2b && !(pageName === 'Enroll' || pageName === 'Finalize Details')) {
      breadcrumbStyle = breadcrumbStyle + ' default-pointer'
    }

    return (
      <>
        <Breadcrumb.Section className={breadcrumbStyle} onClick={() => handleBreadcrumbClick(pageName)} active={isPageActive}>
          {!mobile && pageName}
          <div className="marginTopExtraSmall">
            <Label className={labelStyle} size="small">
              {isPageCompleted ? <Icon className="check whiteFont marginRightSmall" /> : pageNumber[pageName]}
            </Label>
          </div>
        </Breadcrumb.Section>
        {showDivider && (
          <Breadcrumb.Divider className="breadCrumbDivider">{size.mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>
        )}
      </>
    );
  };

  return (
    <div className="justifyCenter paddingTopExtraSmall paddingBottomExtraSmall">
      <Breadcrumb id="breadCrumb">
        {getBreadcrumb({
          pageName: 'Company Information',
          breadcrumbStyle: b2b ? 'blueFont textCenter' : 'textCenter',
          labelStyle: full && !b2b ? 'completed stepperLabel' : 'stepperLabel',
          isPageCompleted: full && !b2b,
          isPageActive: companyInfo,
        })}

        {getBreadcrumb({
          pageName: 'Census Information',
          breadcrumbStyle: employee ? 'textCenter' : 'blueFont textCenter',
          labelStyle: employee ? 'completed stepperLabel' : 'stepperLabel',
          isPageCompleted: employee,
          isPageActive: employeeInfo,
        })}

        {getBreadcrumb({
          pageName: 'Plan Selection',
          breadcrumbStyle: plans ? 'textCenter' : 'blueFont textCenter',
          labelStyle: plans ? 'completed stepperLabel' : 'stepperLabel',
          isPageCompleted: plans,
          isPageActive: planSelection,
        })}

        {getBreadcrumb({
          pageName: 'Quote Review',
          breadcrumbStyle: summary ? 'textCenter' : 'blueFont textCenter',
          labelStyle: summary ? 'completed stepperLabel' : 'stepperLabel',
          isPageCompleted: summary,
          isPageActive: quoteSummary,
        })}

        {getBreadcrumb({
          pageName: 'Enroll',
          breadcrumbStyle: enrollmanager ? 'textCenter' : 'blueFont textCenter',
          labelStyle: enrollmanager && displayFinalizeCase ? 'completed stepperLabel' : 'stepperLabel',
          isPageCompleted: enrollmanager && displayFinalizeCase,
          isPageActive: enrollManager,
          showDivider: displayFinalizeCase,
        })}

        {displayFinalizeCase &&
          getBreadcrumb({
            pageName: 'Finalize Details',
            breadcrumbStyle: finalizecase ? 'textCenter' : 'blueFont textCenter',
            labelStyle: b2b ? 'completed stepperLabel' : 'stepperLabel',
            isPageCompleted: b2b ? true : false,
            isPageActive: finalizeCase,
            showDivider: false,
          })}
      </Breadcrumb>

      {showModalPopup && <ModalPopUp type={modalPoupDetails.type} message={modalPoupDetails.message} actionButtons={modalPoupDetails.actions} />}
      {showLoader && <LoaderPopup />}
      { ineligibleOwnerModal && 
        <IneligibleCaseModal
          open={ineligibleOwnerModal}
          onClose={() => {
            setIneligibleOwnerModal(false);
            setIneligibleOwnerModalMessage('');
          }}
          message={ineligibleOwnerModalMessage}
        />
      }
    </div>
    
  );
};

BreadCrumb.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default BreadCrumb;
