import { axios } from '../../../utils/api/axiosConfig';
import apiRoutes from '../../../utils/api/apiRouteConfig'
import { isUhcRenewals } from '../../../utils/businessLogic/platformUtils';

const dummydata = {
    "renewalCompanyProfileUpdated": true,
    "isUpdatingProfile": false,
    "updateError": null,
    "_id": "5f1957d5d5e04f62815bc442",
    "companyName": "Acme3",
    "primaryContact": {
        "firstName": "Michael",
        "lastName": "MacPherson",
        "email": "michael.macpherson"
    },
    "locations": [{
        "zipData": {
            "zipCode": "06010",
            "city": "Bristol",
            "county": "Hartford County",
            "countyFIPSCode": "003",
            "stateCode": "CT",
            "state": "Connecticut",
            "stateFIPSCode": "09",
            "display": "06010 - Bristol, CT (Hartford County)"
        },
        "totalEmps": 2,
        "totalEligEmps": 2,
        "atne": 2,
        "fte": 2,
        "id": 0,
        "zipError": false,
        "fteRule": "FTE",
        "zipLoading": false,
        "primaryInd": true
    }],
    "primaryLocationIdx": 0,
    "groupSize": 2,
    "effectiveDate": "2020-07-14",
    "sicCode": "3211",
    "franchiseCode": 1234567,
    "NY_SHOP": false,
    "employees": [
        [{
                "firstName": "Kane",
                "lastName": "Williamson",
                "zipCode": "06010",
                "dob": "04/15/1980",
                "age": 40,
                "coverages": {
                    "medical": "EE",
                    "dental": "EE",
                    "vision": "EE",
                    "basicLife": "EE",
                    "std": "EE",
                    "ltd": "W"
                },
                "salary": "80000",
                "noSSNReason": "reason",
                "nameOtherCarrier": "carrier name",
                "ssnTin": "123431",
                "numberOfHoursWorked": "4",
                "dateOfHire": "01/01/2019",
                "anyOtherCoverage": "No",
                "medicarePrimary": "No",
                "memberId": "112211",
                "status": "Not Started",
                "streetAddress": "306AY E GENESEE ST STE 1",
                "city": "ENFIELD",
                "state": "CT",
                "telephone": "12345678",

                "gender": "Male",
                "employeeStatus": "Active",
                "outOfArea": "No",
                "dependents": [],
                "errors": {
                    "firstName": null,
                    "lastName": null,
                    "dob": null,
                    "age": null,
                    "gender": null,
                    "employeeStatus": null,
                    "coverages": null,
                    "outOfArea": null,
                    "salary": null
                }
            },
            {
                "firstName": "Shaun",
                "lastName": "Marsh",
                "zipCode": "06010",
                "dob": "04/15/1985",
                "age": 35,
                "coverages": {
                    "medical": "EE/FAM",
                    "dental": "EE/FAM",
                    "vision": "EE/FAM",
                    "basicLife": "EE/SP",
                    "std": "EE/SP",
                    "ltd": "W"
                },
                "salary": "80000",
                "memberId": "334433",
                "noSSNReason": "reason",
                "nameOtherCarrier": "carrier name",
                "ssnTin": "123431",
                "numberOfHoursWorked": "4",
                "dateOfHire": "01/01/2019",
                "anyOtherCoverage": "No",
                "medicarePrimary": "No",
                "status": "Not Started",
                "streetAddress": "306AY E GENESEE ST STE 1",
                "city": "ENFIELD",
                "state": "CT",
                "telephone": "12345678",
                "gender": "Female",
                "employeeStatus": "COBRA",
                "outOfArea": "No",
                "dependents": [],
                "errors": {
                    "firstName": null,
                    "lastName": null,
                    "dob": null,
                    "age": null,
                    "gender": null,
                    "employeeStatus": null,
                    "coverages": null,
                    "outOfArea": null,
                    "salary": null
                }
            }
        ]
    ],
    "savedPlans": [],
    "notes": "",
    "brokerId": "",
    "lastTouchedPage": "",
    "memberGroupId": null,
    "isInstallingLw": false,
    "installLwError": null,
    "selectedProducts": {
        "medical": true,
        "dental": false,
        "vision": false,
        "life": false,
        "sul": false,
        "std": false,
        "ltd": false
    },
    "date": {
        "value": "",
        "fieldActive": false,
        "error": false,
        "errorMessage": "",
        "display": ""
    },
    "companyNameError": false,
    "dateError": false,
    "sicCodeError": false,
    "sicFieldPristine": false,
    "franchiseCodeError": false,
    "openConfirm": false,
    "nextConfirm": false,
    "uploadError": null,
    "unsavedChanges": true,
    "waitingToGoNext": false,
    "productSelectionError": false,
    "sicCodeRequired": false,
    "disableDental": false,
    "disableVision": false,
    "disableLife": false,
    "sicData": {
        "_id": "5dde9a7dde5d50dd9db9fe15",
        "division": "D",
        "majorGroup": "32",
        "industryGroup": "321",
        "sicCode": "3211",
        "description": "Flat Glass",
        "divisionDescription": "Manufacturing",
        "majorGroupDescription": "Stone, Clay, Glass, And Concrete Products",
        "industryGroupDescription": "Flat Glass",
        "productAvailability": {
            "dental": true,
            "vision": true,
            "life": true,
            "ltd": true,
            "std": true
        },
        "display": "3211 - Flat Glass"
    },
    "simpleCensus": {
        "ee": 1,
        "ec": 0,
        "es": 0,
        "ef": 1,
        "nc": 0,
        "ooa": false
    },
    "coverageInformation": {
        "requestedEffectiveDate": "2020-07-14"
    }
}
export const populateRenewalEmployeeData = () => {
        return dummydata;

    }
    //Save Employer to company profile before saving in contract change
export const saveEmployerToCompanyProfile = (companyProfile, fullProps) => {
    const enrollmentEmployer = fullProps.enrollment.application.employer
    const enrollment = fullProps.enrollment
    let newEmployer = "";
    if(enrollmentEmployer.consumerDrivenHealthPlans?.standardOrSelectHRA && enrollmentEmployer.consumerDrivenHealthPlans?.standardOrSelectHRA === "Standard"){
        newEmployer = {
            employer: {
                generalInformation: enrollmentEmployer.generalInformation ? enrollmentEmployer.generalInformation : {},
                primaryLocation: enrollmentEmployer.primaryLocation ? enrollmentEmployer.primaryLocation : {},
                billingAddress: enrollmentEmployer.billingAddress ? enrollmentEmployer.billingAddress : {},
                primaryContactInformation: enrollmentEmployer.primaryContactInformation ? enrollmentEmployer.primaryContactInformation : {},
                additionalContacts: enrollmentEmployer.additionalContacts ? enrollmentEmployer.additionalContacts : {},
                additionalGeneralInformationRenewal: enrollmentEmployer.additionalGeneralInformationRenewal ? enrollmentEmployer.additionalGeneralInformationRenewal : {},
                contribution: enrollmentEmployer.contribution ? enrollmentEmployer.contribution : {},
                consumerDrivenHealthPlans: enrollmentEmployer.consumerDrivenHealthPlans ? enrollmentEmployer.consumerDrivenHealthPlans : {},
                participation: enrollmentEmployer.participation ? enrollmentEmployer.participation : {}, // copied from below
                currentCarrierInformation: enrollmentEmployer.currentCarrierInformation ? enrollmentEmployer.currentCarrierInformation : {},
                waitingPeriod: enrollmentEmployer.waitingPeriod ? enrollmentEmployer.waitingPeriod : {}
            },
            eligibility: {
                waitingPeriod: enrollment.application.eligibility.waitingPeriod ? enrollment.application.eligibility.waitingPeriod : {},
                consumerDrivenHealthPlans: enrollmentEmployer.consumerDrivenHealthPlans ? enrollmentEmployer.consumerDrivenHealthPlans : {},
                contribution: enrollmentEmployer.contribution ? enrollmentEmployer.contribution : {}, // copy to above
                participation: enrollmentEmployer.participation ? enrollmentEmployer.participation : {}, // copy to above
                eligibilityInformation : enrollmentEmployer.eligibilityInformation ? enrollmentEmployer.eligibilityInformation : {},
                additionalEligibilityInformation : enrollmentEmployer.additionalEligibilityInformation ? enrollmentEmployer.additionalEligibilityInformation : {}
            },
            producer: enrollment.application.producer ? enrollment.application.producer : {},
            standardHRA: {
                clientDemographicInformation: enrollment.application.standardHRA.clientDemographicInformation ? enrollment.application.standardHRA.clientDemographicInformation : {},
                employerHRABenefitContactInformation: enrollment.application.standardHRA.employerHRABenefitContactInformation ? enrollment.application.standardHRA.employerHRABenefitContactInformation : {},
                hraBankingInformation: enrollment.application.standardHRA.hraBankingInformation ? enrollment.application.standardHRA.hraBankingInformation : {},
                hraPlanInformation: enrollment.application.standardHRA.hraPlanInformation ? enrollment.application.standardHRA.hraPlanInformation : {}
            }
        }
    }else{
        newEmployer = {
            employer: {
                generalInformation: enrollmentEmployer.generalInformation ? enrollmentEmployer.generalInformation : {},
                primaryLocation: enrollmentEmployer.primaryLocation ? enrollmentEmployer.primaryLocation : {},
                billingAddress: enrollmentEmployer.billingAddress ? enrollmentEmployer.billingAddress : {},
                primaryContactInformation: enrollmentEmployer.primaryContactInformation ? enrollmentEmployer.primaryContactInformation : {},
                additionalContacts: enrollmentEmployer.additionalContacts ? enrollmentEmployer.additionalContacts : {},
                additionalGeneralInformationRenewal: enrollmentEmployer.additionalGeneralInformationRenewal ? enrollmentEmployer.additionalGeneralInformationRenewal : {},
                contribution: enrollmentEmployer.contribution ? enrollmentEmployer.contribution : {},
                consumerDrivenHealthPlans: enrollmentEmployer.consumerDrivenHealthPlans ? enrollmentEmployer.consumerDrivenHealthPlans : {},
                participation: enrollmentEmployer.participation ? enrollmentEmployer.participation : {}, // copied from below
                currentCarrierInformation: enrollmentEmployer.currentCarrierInformation ? enrollmentEmployer.currentCarrierInformation : {},
                waitingPeriod: enrollmentEmployer.waitingPeriod ? enrollmentEmployer.waitingPeriod : {},
            },
            eligibility: {
                waitingPeriod: enrollment.application.eligibility.waitingPeriod ? enrollment.application.eligibility.waitingPeriod : {},
                consumerDrivenHealthPlans: enrollmentEmployer.consumerDrivenHealthPlans ? enrollmentEmployer.consumerDrivenHealthPlans : {},
                contribution: enrollmentEmployer.contribution ? enrollmentEmployer.contribution : {},
                participation: enrollmentEmployer.participation ? enrollmentEmployer.participation : {}, // copy to above
                eligibilityInformation : enrollmentEmployer.eligibilityInformation ? enrollmentEmployer.eligibilityInformation : {},
                additionalEligibilityInformation : enrollmentEmployer.additionalEligibilityInformation ? enrollmentEmployer.additionalEligibilityInformation : {}


            },
            producer: enrollment.application.producer ? enrollment.application.producer : {},
        }
    }
    if (isUhcRenewals()) {
        newEmployer.eligibility.eligibilityInformation = enrollment.application.eligibility.eligibilityInformation ? enrollment.application.eligibility.eligibilityInformation : {}
        newEmployer.eligibility.additionalEligibilityInformation = enrollment.application.eligibility.additionalEligibilityInformation ? enrollment.application.eligibility.additionalEligibilityInformation : {}
    }
    companyProfile.employerEnrollment = newEmployer;
    companyProfile.sicCode = newEmployer.employer.generalInformation.sicCode || '';
    companyProfile.sicData = fullProps.companyProfile.sicData || {};
    companyProfile.reviewCards = fullProps.reviewCards

    return companyProfile;
}

export let updateDBEmployerToCompanyProfile = async(companyProfile, memberGroupId) => {
    let cmpProfile = companyProfile
    axios.get(`${apiRoutes.mongo}/renewalCompanyProfiles?memberGroupId=${memberGroupId}`)
        .then((res) => {
            cmpProfile.employerEnrollment = res.data.employerEnrollment;
        })
    return cmpProfile;
}