/** Page Load event for Adobe Data Layer */
export const sendPageLoadData = (data) => {

    window.adobeDataLayer.push({
        "event": "pageLoadEvent",
        "data": data
    });
}

/** Login Data for Adobe Data Layer */
export const sendPageSuccessData = (data) => {

    window.adobeDataLayer.push({
        "event": "loginSuccessEvent",
        "data": {
            "loginStatus" : data
        }
    });
}

/** Button Click event for Adobe Data Layer */
export const sendClickEventData =(data) => {
    window.adobeDataLayer.push({
        "event": "clickEvent",
        "data": data
    });
}

/** Error Tracking event for Adobe Data Layer */
export const sendErrorTrackingData =(data) => {
    window.adobeDataLayer.push({
        "event": "errorTracking",
        "data": data
   });
  
}