import { store } from "../../index";
export const getTokenDefault = () => {
  const {
    overall: {
      appConfigDetails: { jwtSignKey = "" },
    },
  } = store.getState();
  return jwtSignKey;
};
export const getEncryptKey = () => {
  const {
    overall: {
      appConfigDetails: { encryptKey = "" },
    },
  } = store.getState();
  return encryptKey;
};
