import * as types from '../../actions/actionTypes';

const initialState = {
    supportedAreaQQ: true,
    supportedAreaFS: true,
};

export default function supportedArea(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_SUPPORTED_AREA_QQ:
            return {
                ...state,
                supportedAreaQQ: action.supportedArea,
            };
        case types.UPDATE_SUPPORTED_AREA_FS:
            return {
                ...state,
                supportedAreaFS: action.supportedArea,
            };
        default:
            return state;
    }
}
