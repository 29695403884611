export const newPcdAssignmentObject = {
    assignmentMethod: '',
    pcdCode: '',
    dependentsAssigned: [],
    existingPatient: ''
};

export const makeNewPcdAssignmentArray = (members = []) => {
    const pcdAssignment = [];
    for (let i = 0; i < members.length; i++) {
        pcdAssignment.push(newPcdAssignmentObject);
    }
    return pcdAssignment;
};
