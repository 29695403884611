import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Icon, Grid, Card, Checkbox } from 'semantic-ui-react';
import OfficeIcon from '../../../../images/icon-office.svg';
import { 
    CASE_PROGRESS, 
    inSubmittedStatus,
    getNBCaseProgress,
    getCaseIndexForNB, 
} from '../../../../reducers/products/helpers/companyProfileConsts';
import { getPrimaryLocation } from '../../../activities/FullShopping/Census/helpers/checkPrimaryLocation';
import showFranchiseCode from '../../../../utils/businessLogic/showFranchiseCode';
import { findDaysTillEffDate, toMMDDYYYYFormatSlash } from '../../../../utils/formatters/dates';
import { toggleInternalCase,paperCaseHistoryLog } from '../../../../actions/overallActions';
import { saveOrUpdateCaseTracking } from '../../../../actions/caseTrackAction';
import { isUhcRenewals, isMmr } from '../../../../utils/businessLogic/platformUtils';
import './reviewCards.css';


const ReviewCardCompanyInfoSide = ({
    history,
}) => {
    const dispatch = useDispatch();
    const nbsp = '\u00a0';
    const fullProps = useSelector((state) => state);
    const renewalsFlow = useSelector((state) => state.overall.isRenewals || state.overall.source === 'renewal');
    const isUhcRenewalFlow = isUhcRenewals();
    const isMMRCase = isMmr();
    const products = useSelector((state) => (renewalsFlow
        ? state.companyProfile.userCardsSelectedProducts
        : state.companyProfile.selectedProducts),
        shallowEqual);

    const productsDisplay = [];
    for (const product in products) {
        if (products[product]) {
            if(product !== 'std' && product !== 'ltd' && product !== 'suppLife') {
                productsDisplay.push(product.substring(0, 1).toUpperCase());
            }if(product === 'std' || product === 'ltd'){
                productsDisplay.push(product.toUpperCase());
            } else if(product === 'suppLife'){
                productsDisplay.push("SL");
            } 
        }
    }
    const locations = useSelector((state) => state.companyProfile.locations, shallowEqual);
    const companyName = useSelector((state) => state.companyProfile.companyName, shallowEqual);
    const priLocation = getPrimaryLocation(locations);
    const zipCodeofFullShopping = getPrimaryLocation(locations).zipData.zipCode;
    const zipCodeofRenewals = useSelector((state) => ((state.companyProfile && state.companyProfile.locations && state.companyProfile.locations[0] && state.companyProfile.locations[0].zipData.zipCode)
        ? state.companyProfile.locations[0].zipData.zipCode : '-----'), shallowEqual);
    const renewalData = useSelector((state) => state.renewals.renewalData || {});
    const renewalCaseData = useSelector((state) => state.renewals.renewalCaseData || {});
    const employeeCountofFullShopping = useSelector((state) => state.enrollment.application.eligibility.participation.numEligibleEmployees);
    const employeeCountofRenewals = renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '';
    const eligEmployees = priLocation && priLocation.totalEligEmps > 0 ? priLocation.totalEligEmps : '0';
    const sicCode = useSelector((state) => (state.companyProfile.sicCode ? state.companyProfile.sicCode : ''), shallowEqual);
    const franchiseCode = useSelector((state) => state.companyProfile.franchiseCode, shallowEqual);
    const effectiveDate = useSelector((state) => state.companyProfile.effectiveDate, shallowEqual);
    const dateObj = useSelector((state) => state.companyProfile.date, shallowEqual);
    const memberGroupIdNB = useSelector((state) => state.enrollment.application.installation.memGroupID, shallowEqual);
    const memberGroupIdRenewals = useSelector((state) => state.companyProfile?.memberGroupId || '-');
    const memberGroupId = (renewalsFlow === true || !memberGroupIdNB) ? memberGroupIdRenewals : memberGroupIdNB;
    const isMigratedOrCirrusCase = useSelector((state) => state.renewals?.renewalCaseData?.samxInternalGrp === 'Y' || state.renewals?.rlCaseData?.platform === 'CIRRUS');
    const platform = useSelector((state) => renewalsFlow === true ? state.renewals.renewalCaseData.platform ? state.renewals.renewalCaseData.platform.toUpperCase() : "CIRRUS" : "CIRRUS")
    const store = useSelector((state) => state);

    const caseProgressofNB = useSelector((state) => state.companyProfile.caseProgress);
    let renewalPlan;
    if (store.renewals.globalSearchFlag) {
        renewalPlan = renewalsFlow === true ? store.renewals.renewalSearchCase : null;
    } else {
        renewalPlan = renewalsFlow === true ? store.renewals && store.renewals.renewals &&  store.renewals.renewals.find(plan => plan.memberGroupId === store.renewals.memberGroupId) : null
    }
    let actualCaseProgress = renewalsFlow === true && renewalPlan ? renewalPlan.caseProgressUI : null
    const updateRLcaseProgress = useSelector((state) => state.renewals.rlCaseProgress);
    let renewalsCaseProgress = renewalsFlow === true ?  store.caseTracking && store.caseTracking.caseTrack && store.caseTracking.caseTrack[0] && store.caseTracking.caseTrack[0].caseStatus ? store.caseTracking.caseTrack[0].caseStatus  :updateRLcaseProgress ? updateRLcaseProgress :  null:null;
    let idx = getCaseIndexForNB(store.caseTracking);
    const caseProgress = renewalsFlow === true ? updateRLcaseProgress ? updateRLcaseProgress : actualCaseProgress : store.caseTracking && store.caseTracking.caseTrack && store.caseTracking.caseTrack[idx] && store.caseTracking.caseTrack[idx].caseStatus ? store.caseTracking.caseTrack[idx].caseStatus : caseProgressofNB;
    const caseId = useSelector((state) => renewalsFlow ? (store.renewals && store.renewals.renewalCaseData && store.renewals.renewalCaseData.caseId ? store.renewals.renewalCaseData.caseId : '') : state.caseTracking.caseId);
    const primaryLocation = getPrimaryLocation(locations);
    const displayFranchiseCode = showFranchiseCode(primaryLocation) && !isUhcRenewalFlow;
    const zipCode = isUhcRenewalFlow
        ? store?.renewals?.renewalCaseData?.zipCode
            ? store.renewals.renewalCaseData.zipCode
            : ""
        : renewalsFlow === true ? zipCodeofRenewals : zipCodeofFullShopping;
    // If continuing a withdrawn case from NB Dashboard, value is stored in companyProfile and should be reused when available
    const daysLeft = renewalsFlow === true ? (findDaysTillEffDate(effectiveDate)) : null;
    const sign = renewalsFlow === true ? daysLeft && daysLeft < 0 ? 'past' : 'left' : null;
    const updateStatusClick = () => {
        history.push(`/enrollment/additional-required-information`, {
            title: 'Additional Required Information',
        });
    };
    const statusSubmitted = inSubmittedStatus(caseProgressofNB);
    const state = isUhcRenewalFlow ? (store?.renewals?.renewalCaseData?.situsState ? store.renewals.renewalCaseData.situsState: '') : locations && locations.length > 0 ? getPrimaryLocation(locations)?.zipData?.stateCode : '';
    const roleName = useSelector((state) => state.renewals.roleName);
    const isPaperCase = useSelector((state) => state.overall.isPaperCase);
    const overallPlatform = useSelector((state) => state.overall.platform);
    const isInternalUser = useSelector((state) => state.user.userType) === 'Internal';
    const internalRoles = ['Production Support Admin', 'UHC Virtual Ops', 'OXHP Virtual Ops', 'OXHP Install Rep', 'UHC Install Rep', 'Install Supervisor'];
    const hideUpdateProgress = isInternalUser && overallPlatform == 'PRIME';
    const customerNo =  renewalsFlow === true && renewalCaseData?.primeCustomerNumber || 'N/A';
    const policyNo = renewalsFlow === true && renewalCaseData?.primePolicyNumber && renewalCaseData?.primePolicyNumber.length > 0 
        ? renewalCaseData?.primePolicyNumber[0] : 'N/A';
    const installationPlatform = useSelector((state) => state.caseTracking.platform);
    const stepperAccess = useSelector((state) => state.renewals.stepperAccess);
    const internalRolesRenewal = ['Production Support Admin', 'UW Representative', 'OXHP Install Rep', 'UHC Install Rep', 'Install Supervisor'];
    const displayInstallationPlatformRenewal = internalRolesRenewal.includes(roleName) && isUhcRenewalFlow && (stepperAccess.decide || stepperAccess.enrollment || stepperAccess.renewalFinalizeCase);

    return (
        <Card className="sidebarReviewCard">
            <Card.Content>
                <Grid>
                    <Grid.Row>
                        <img src={OfficeIcon} className="smallIcon marginLeftMedium marginRightMedium" alt="Icon"/>
                        <p className="blueFont paddingTopMedium">
                            {' '}
                            <strong>
                                {' '}
                                {companyName || 'Group Information'}
                                {' '}
                            </strong>
                        </p>
                    </Grid.Row>

                    {/* TODO : THIS NEEDS TO BE REVISITED */}
                    {/* <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Case Id:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {caseId}
                        </Grid.Column>
                    </Grid.Row> */}

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Case Id:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {caseId}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Case Progress:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                        {caseProgress.toLowerCase() === "inprogress" ? "In Progress" : caseProgress}
                           {renewalsFlow==true?null:statusSubmitted && !hideUpdateProgress && caseProgress !== CASE_PROGRESS.PENDING && (
                                <Icon className="actionFont cursorPointer marginLeftSmall" name="edit" onClick={() => updateStatusClick()} />
                            )}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Products:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft overFlowWrapClass" width="6">
                            {productsDisplay.join(nbsp) || 'M'}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Effective Date:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {/* REMOVE AFTER TESTING
                            {dateObj ? dateObj.monthDayYear : ''} */}
                            {effectiveDate && effectiveDate.includes('-') ? toMMDDYYYYFormatSlash(effectiveDate) : effectiveDate}
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Zip Code:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {zipCode}
                        </Grid.Column>
                    </Grid.Row>

                    {!renewalsFlow || isUhcRenewalFlow &&
                        <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                            <Grid.Column className="noPaddingRight" width="10">
                                <strong>State:</strong>
                            </Grid.Column>
                            <Grid.Column className="noPaddingLeft" width="6">
                                {state}
                            </Grid.Column>
                        </Grid.Row>}

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Eligible Employees:</strong>
                        </Grid.Column>
                        {renewalsFlow === true ?
                            <Grid.Column className="noPaddingLeft" width="6">
                                {employeeCountofRenewals}
                            </Grid.Column> :
                            <Grid.Column className="noPaddingLeft" width="6">
                                {employeeCountofFullShopping || eligEmployees}
                            </Grid.Column>
                        }
                    </Grid.Row>

                    <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>SIC Code:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {sicCode}
                        </Grid.Column>
                    </Grid.Row>

                    {isUhcRenewalFlow && isMigratedOrCirrusCase && <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Member Group ID:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {memberGroupId}
                        </Grid.Column>
                    </Grid.Row>}

                    {isUhcRenewalFlow && <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Customer No.:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {customerNo}
                        </Grid.Column>
                    </Grid.Row>}

                    {isUhcRenewalFlow && <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Policy No.:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {policyNo}
                        </Grid.Column>
                    </Grid.Row>}

                    {displayInstallationPlatformRenewal && <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                            <strong>Installation Platform:</strong>
                        </Grid.Column>
                        <Grid.Column className="noPaddingLeft" width="6">
                            {isMmr() ? 'CIRRUS' : installationPlatform}
                        </Grid.Column>
                    </Grid.Row>}

                    {isUhcRenewalFlow && <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                        <Grid.Column className="noPaddingRight" width="10">
                        <strong>{`${daysLeft} Days ${sign}`}</strong>
                        </Grid.Column>
                    </Grid.Row>}

                    {displayFranchiseCode
                        && (
                            <Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                                <Grid.Column className="noPaddingRight" width="10">
                                    <strong>Franchise Code:</strong>
                                </Grid.Column>
                                <Grid.Column className="noPaddingLeft" width="6">
                                    {franchiseCode}
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    {
                        platform != "PRIME" && renewalsFlow ?
                            (<Grid.Row columns="2" className="noPaddingTop paddingBottomExtraSmall">
                                <Grid.Column className="noPaddingRight" width="10">
                                    <strong>Member Group ID:</strong>
                                </Grid.Column>
                                <Grid.Column className="noPaddingLeft" width="6">
                                    {memberGroupId}
                                </Grid.Column>
                            </Grid.Row>) : null
                    }
                    <Grid.Row columns="1">
                        <Grid.Column>

                            {renewalsFlow !== true
                                && (
                                    <>
                                        {!statusSubmitted && <h5 className="noMarginTop actionFont paddingTopExtraSmall cursorPointer" onClick={() => history.push('/full')}>
                                            <Icon className="actionFont cursorPointer" name="edit" />
                                            {' '}
                                            Edit Group Info
                                        </h5>}
                                        {internalRoles.includes(roleName) && 
                                            <Checkbox 
                                                className="marginTopExtraSmall" 
                                                checked={isPaperCase} label='Paper Case' 
                                                disabled={statusSubmitted}
                                                onChange={() => {
                                                    dispatch(toggleInternalCase(!isPaperCase));
                                                    dispatch(paperCaseHistoryLog(!isPaperCase));
                                                    dispatch(saveOrUpdateCaseTracking(fullProps.caseTracking.caseTrack[getCaseIndexForNB(fullProps.caseTracking)].lastPage, getNBCaseProgress(fullProps)));
                                                }}
                                            />
                                        }
                                    </>
                                )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>

    );
};

ReviewCardCompanyInfoSide.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default ReviewCardCompanyInfoSide;
