import * as types from '../../actions/actionTypes';
import deepCopy from '../../utils/deepCopy';

const initialState = {

    ownerAssignment: {
        producer: [],
        generalAgent: [],
        agency: [],
        salesRep: [],
        gaSalesRep: [],
    },
    flow: 'renewals',
    directSale: false,
    salesRepAvail:false,
    gaSalesRepAvail: false,
    licenseCheck:[]
};

export default function reviewCardReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_OWNER_ASSIGNMENT_DETAILS: {
            const newProducer = deepCopy(state.ownerAssignment.producer);
            // Only add producer if they do not already exist in the array
            const indexOfProducer = newProducer.findIndex((x) => x.PCIS_ID === action.payload.responseData.PCIS_ID);
            if (indexOfProducer === -1) {
                newProducer.push(action.payload.responseData);
            }

            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    producer: newProducer,
                },

            };
        }

        case types.ADD_SALES_REP_DETAILS: {
            const newSalesRep = deepCopy(state.ownerAssignment.salesRep);
            // Only add sales rep if one has not already been assigned from Member Group ID
            if (newSalesRep && newSalesRep.length === 1 && newSalesRep[0].assignedFromMemberGroupID === true) {
                return {
                    ...state,
                    ownerAssignment: {
                        ...state.ownerAssignment,
                        salesRep: newSalesRep,
                    },
                };
            } else { // Only add sales rep if they do not already exist in the array
                const indexOfSalesRep = newSalesRep.findIndex((x) => x.salesRepName === action.payload.responseData.salesRepName);
                if (indexOfSalesRep === -1) {
                    newSalesRep.push(action.payload.responseData);
                }
                return {
                    ...state,
                    ownerAssignment: {
                        ...state.ownerAssignment,
                        salesRep: newSalesRep,
                    },
                };
            }
        }

        case types.ADD_SALES_REP_FROM_MEMBERGROUPID: {
            const salesRep = {}
            salesRep.salesRepName = `${action.payload.assignedAccountManagerFN ?action.payload.assignedAccountManagerFN :'-'} ${action.payload.assignedAccountManagerLN ? action.payload.assignedAccountManagerLN : '-'}`;
            salesRep.PCIS_ID = '-';
            salesRep.city = '-';
            salesRep.state = '-';
            salesRep.zipCode = '-';
            salesRep.employeeNumber = '-';
            salesRep.payeeCode = '-';
            salesRep.rewardAccountNumber = '-';
            salesRep.salesRepPrimeCode = '-';
            salesRep.employeeNumber = '-';
            salesRep.streetAddress = '-';
            salesRep.assignedFromMemberGroupID = true;
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    salesRep: [salesRep]
                },
            }
        }

        case types.ADD_GA_SALES_REP_DETAILS: {
            const newGASalesRep = deepCopy(state.ownerAssignment.gaSalesRep);
            // Only add sales rep if they do not already exist in the array
            const indexOfGASalesRep = newGASalesRep.findIndex((x) => x.salesRepName === action.payload.responseData.salesRepName);
            if (indexOfGASalesRep === -1) {
                newGASalesRep.push(action.payload.responseData);
            }
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    gaSalesRep: newGASalesRep,
                },

            };
        }

        case types.SET_SALESREP_AVAIL_FLAG: {
            return {
                ...state,
                salesRepAvail:action.payload
            }
        }
        case types.SET_GASALESREP_AVAIL_FLAG: {
            return {
                ...state,
                gaSalesRepAvail:action.payload
            }
        }
        case types.UPDATE_SALES_REP_DATA: {
            const updatedSalesRep = [];
            updatedSalesRep.push(action.payload.responseData);
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    salesRep: updatedSalesRep,
                },
            };
        }

        case types.ADD_GENERAL_AGENT_DETAILS: {
            const newGeneralAgent = deepCopy(state.ownerAssignment.generalAgent);
            // Only add sales rep if they do not already exist in the array
            const indexOfGeneralAgent = newGeneralAgent.findIndex((x) => x.PCIS_ID === action.payload.responseData.PCIS_ID);
            if (indexOfGeneralAgent === -1) {
                newGeneralAgent.push(action.payload.responseData);
            }
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    generalAgent: newGeneralAgent,
                },

            };
        }

        case types.ADD_AGENCY_DETAILS: {
            const newAgency = deepCopy(state.ownerAssignment.agency);
            // Only add agency if they do not already exist in the array
            const indexOfAgency = newAgency.findIndex((x) => x.PCIS_ID === action.payload.responseData.PCIS_ID);
            if (indexOfAgency === -1) {
                newAgency.push(action.payload.responseData);
            }
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    agency: newAgency,
                },

            };
        }

        case types.REMOVE_OWNER_ASSIGNMENT: {
            // Mappings from owner type to Redux attribute
            const typeMappings = {
                'Sales Rep': 'salesRep',
                'GA Sales Rep': 'gaSalesRep',
                Producers: 'producer',
                Agency: 'agency',
                'General Agent': 'generalAgent',
            };
            const type = typeMappings[action.ownerType];
            const owners = deepCopy(state.ownerAssignment[type]);
            let indexOfOwner;
            if (action.ownerType === 'Sales Rep' || action.ownerType === 'GA Sales Rep') { // Use salesRepName as identifier for sales reps
                indexOfOwner = owners.findIndex((x) => x.salesRepName.trim() === action.owner.trim());
            } else { // Use PCIS_ID as identifier for all other owner types
                indexOfOwner = owners.findIndex((x) => x.PCIS_ID === action.pcis);
            }
            // Remove owner from array
            if (indexOfOwner > -1) {
                owners.splice(indexOfOwner, 1);
            }
            return {
                ...state,
                ownerAssignment: {
                    ...state.ownerAssignment,
                    [type]: owners,
                },
            };
        }

        case types.RESET_OWNER_ASSIGNMENT_DETAILS:
            return {
                ...state,
                ownerAssignment: action.payload,
            };

        case types.UPDATE_DIRECT_SALE:
            // Default state is null if certain roles are not filled
            return {
                ...state,
                ownerAssignment: initialState.ownerAssignment,
                directSale: action.payload === null ? !state.directSale : action.payload,
            };
        case types.UPDATE_OWNER_ASSIGNMENT_RENEWALS_PAGE:
            return {
                ...state,
                page: action.payload
            };
        case types.UPDATE_LICENSE_CHECK:
            return {
                ...state,
                licenseCheck: action.payload
            }
        case types.RESET_TO_INITIALSTATE:
            return{
                ...initialState,
                licenseCheck: state.licenseCheck
            }
        case types.RESET_FLOW:
            return{
                ...initialState,
                licenseCheck: state.licenseCheck
            }
        case types.RESET_RENEWALS:
            return{
                ...initialState,
                licenseCheck: state.licenseCheck
            }
        default:
            return state;
    }
}
