export const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    planType: {
        ino: false,
        incentive: false,
        indemnity: false,
        passive: false,
    },

    // unique to Dental

    contributionType: {
        contributory: false,
        voluntary: false,
    },
    planOptions: {
        includeImplants: false,
        includeCMM: false,
        includePMM: false,
    },
    orthoOptions: {
        adultAndChild: false,
        childOnly: false,
    },
    ucr: {
        70: false,
        80: false,
        85: false,
        90: false,
        95: false,
        MAC: false,
    },
    indDeductible: [0, 9999999],
    familyDeductible: [0, 9999999],
    wpMajor: [0, 9999999],
    annualMax: [0, 9999999],
    inCoins: [0, 9999999],
    outCoins: [0, 9999999],
};

export const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    // TBD
};

export const initialSpecialtyCriteria = {
    sicCode: '',
    viewSpecialty: {
        medical: false,
        dental: false,
        vision: false,
        life: false,
        std: false,
        ltd: false,
    },
    elected: '',
    employeeOnly: '',
    employeeWithSpouse: '',
    employeeWithChild: '',
    employeeWithFamily: '',
    censusCompleted: false,
    dentalCensusSummary: {
        elected : '',
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    visionCensusSummary: {
        elected : '',
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    lifeCensusSummary: {
        elected: '',
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    dependentLifeCensusSummary: {
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    ltdCensusSummary: {
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },
    stdCensusSummary: {
        employeeOnly: '',
        employeeWithSpouse: '',
        employeeWithChild: '',
        employeeWithFamily: '',
    },

    salaryRatingMethod: '',
};

export const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        zipCode: null,
        zipObj: {},
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    isFetchingPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    plans: [],
    allPlans: [],
    visiblePlans: [],
    selectedPlans: [],
    selectedPlansMap: {},
    favoritedPlans: [],
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    filterTotalsCache: initialfilterTotalsCache,
    specialty: true,
    sortEnabled: true,
    ascending: true,
    showPreferredPlans: true,
    filteredResults: [],
    filteredResultsBasic: [],
    appliedFiltersAdvance: {},
    appliedFiltersBasics: {},
    advanceFilterProductType: 'dental',
    displayLeftSideFilter: false,
    isSendingRateAdjustments: false,
    rateAdjustmentsError: false,
};

export const dentalFilterSet = [
    {
        filterGroup: 'Plan Code',
        filterId: 'plancode',
        planField: 'code',
        type: 'list',
        place: 'dropdown',
        display: false,
        values: {},
    },
    {
        filterGroup: 'Plan Type',
        filterId: 'dentalPlanType',
        planField: 'dentalPlanType',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Ind Ded (INN/OON)',
        filterId: 'individualdeductible',
        planField: 'deductible.indDeductibleText',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Annual Max (INN/OON)',
        filterId: 'annualmax',
        planField: 'maxBenefit.maxBenefitText',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'INN Coins (P&D/Basic/Major/Ortho)',
        filterId: 'incoins',
        planField: 'inCoins',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'OON Coins (P&D/Basic/Major/Ortho)',
        filterId: 'outcoins',
        planField: 'outCoins',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'OON Reimbursement',
        filterId: 'ucr',
        planField: 'ucr',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Contribution Type',
        filterId: 'contributiontype',
        planField: 'fundingType',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Family Ded (INN/OON)',
        filterId: 'familydeductible',
        planField: 'deductible.familyDeductibleText',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Include Ortho',
        filterId: 'incluedeOrtho',
        planField: 'includeOrtho',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'WP for Major',
        filterId: 'wpformajor',
        planField: 'waitingPeriodMajor',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Class Shift Description',
        filterId: 'classshiftdescription',
        planField: 'classShiftDescription',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Dedicated Lines',
        filterId: 'dedicatedline',
        planField: 'dedicatedLine',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Include Implants',
        filterId: 'includeImplants',
        planField: 'includeImplants',
        type: 'boolean',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Include CMM',
        filterId: 'includeCMM',
        planField: 'includeCMM',
        type: 'boolean',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Include PMM',
        filterId: 'includePMM',
        planField: 'includePMM',
        type: 'boolean',
        place: 'basic',
        display: true,
        values: {},
    },
    {
        filterGroup: 'Installation Options',
        filterId: 'installationoptions',
        planField: 'installationType',
        type: 'list',
        place: 'basic',
        display: true,
        values: {},
    },
];
