import * as types from './actionTypes';
import sendLog from './logActions';
import { axios } from '../utils/api/axiosConfig';
import apiRoutes from '../utils/api/apiRouteConfig';
import { isEmpty } from '../utils/formatters/strings';
import { MARKET_TYPES, getMarketType, isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { history } from '../utils/history';
import { createHistory } from '../actions/historyAction';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';

export const updateSource = (newSource) => (dispatch) => {
    sendLog({
        action: 'UPDATE NAME',
        newSource,
    });

    dispatch({
        type: types.UPDATE_SOURCE,
        newSource,
    });
};
export const setMedicalUpsell = (medUpsel) => (dispatch) => {
    dispatch({
        type: types.SET_MED_UPSEL,
        payload: medUpsel
    })
}

export const resetRedux = () => (dispatch) => {
    sendLog({
        action: 'RESET',
    });

    dispatch({
        type: 'RESET',
    });
};

export const updatePrevious = (previousPage) => (dispatch) => {
    sendLog({
        action: 'UPDATE PREVIOUS PAGE',
        previousPage,
    });

    dispatch({
        type: types.UPDATE_PREVIOUS,
        previousPage,
    });
};
export const updateNext = (nextPage) => (dispatch) => {
    sendLog({
        action: 'UPDATE NEXT PAGE',
        nextPage,
    });

    dispatch({
        type: types.UPDATE_NEXT,
        nextPage,
    });
};

export const initializeAppConfig = () => (dispatch) => new Promise((resolve, reject) => {
    const appConfigPath = process.env.REACT_APP_ISLOCAL === 'true' ? '/app-config-non-sso' : '/app-config';
    axios.get(`${apiRoutes.quickQuotes}${appConfigPath}?isSpecialtyOnly=false`)
        .then((res) => {
            dispatch({
                type: types.INITIALIZE_APP_CONFIG,
                appConfigDetails: res.data[0],
            });
            resolve();
        })
        .catch((err) => {
            console.log('error initializing app config ', err)
            dispatch({
                type: types.INITIALIZE_APP_CONFIG_ERROR,
                error: err,
            });
            reject();
        });
});
export const getZipConfig = (zipObj, isRenewals = false) => (dispatch, getState) => new Promise((resolve, reject) => {
    const { overall } = getState();

    if (isEmpty(zipObj.zipCode) && isEmpty(zipObj.county)) {
        return;
    }
    let county = '';
    if (!isEmpty(zipObj.county)) {
        county = zipObj.county.replace('County', '');
    }
    let platform = overall.platform;
    if (isRenewals) {
        platform = getMarketType() === MARKET_TYPES.UHC ? 'PRIME' : 'CIRRUS';
    }

    axios.get(`${apiRoutes.quickQuotes}/get-zip-config?zipCode=${zipObj.zipCode}&county=${county}&platform=${platform}`)
        .then((res) => {
            dispatch({
                type: types.GET_ZIP_CONFIG,
                zipConfigDetails: res.data,
            });

            resolve(res.data);
        })
        .catch((err) => {
            console.log('Error', err);
            if(isUhcRenewals()) {
                const reqBody = {
                    zipCode: zipObj.zipCode, county, platform
                }
                logError(KEY_NAME, UHC_REN_APIS.GET_ZIP_CONFIG, 'overallActions.getZipConfig', reqBody, err);
            }
            dispatch({
                type: types.GET_ZIP_CONFIG_ERROR,
                error: err,
            });
            reject();
        });
});
export function setRenShoppingIndicator(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_REN_SHOPPING,
            payload: val,
            source: 'renewal',
        });
    };
}
export function setMMRIndicator(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_MMR_INDICATOR,
            payload: val,
            source: 'mmr',
        });
    };
}
export function setZipAndState(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_ZIP_AND_STATE,
            payload: val,
        });
    };
}
export function setQuickRequest(quickQuoteRequest) {
    return (dispatch) => {
        dispatch({
            type: types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS,
            payload: quickQuoteRequest,
            source: quickQuoteRequest.source,
        });
    };
}
export function setPlatForm(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_PLATFORM,
            payload: val,
        });
    };
}
export function setUserToken(token) {
    return (dispatch) => {
        dispatch({
            type: types.SET_USERTOKEN,
            payload: token,
        });
    };
}

export function updateSelectedProductsInRenewals(val) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE SELECTED PRODUCTS RENEWALS',
            val,
        });
        dispatch({
            type: types.UPDATE_SELECTED_PRODUCTS_RENEWALS,
            payload: val,
        });
    };
}

export function resetFlowAfterRenewals() {
    return (dispatch) => {
        sendLog({
            action: 'RESET ALL REDUCERS',
        });
        dispatch({
            type: types.RESET_FLOW,
        });
    };
}

export function resetToInitialState() {
    return (dispatch) => {
        localStorage.clear();
        sendLog({
            action: 'RESET_TO_INITIALSTATE',
        });
        dispatch({
            type: types.RESET_TO_INITIALSTATE,
        });
    };
}

export function closeErrorMessage() {
    return (dispatch) => {
        dispatch({
            type: types.CLOSE_ERROR_MESSAGE,
        });
    };
}

export function setAuthenticData(payload) {
    return (dispatch) => {
        dispatch({
            type: 'SET_AUTH_DATA',
            payload,
        });
    };
}

export function resetToSelectedNBStateForRenewalPrimeRedirect() {
    return (dispatch) => {
        sendLog({
            action: 'RESET_TO_INITIALSTATE_REN_PRIME_ANC',
        });
        dispatch({
            type: types.RESET_TO_INITIALSTATE_REN_PRIME_ANC,
        });
    };
}

/**
 * Revokes access from future FS breadcrumb steps if anything significant has changed
 * @param currentPage string - defines which page is being edited, breadcrumb links beyond this page should be disabled
 */
export function revokeFullShoppingBreadcrumbAccess(currentPage) {
    return (dispatch) => {
        dispatch({
            type: types.DISABLE_FULL_SHOPPING_BREADCRUMB,
            payload: currentPage,
        });
    };
}

export function ineligibleCaseNotif(showIf) {
    return (dispatch) => {
        dispatch({
            type: types.INELIGIBLE_CASE_MODAL_POPUP,
            payload: showIf,
        });
    };
}

export function isEligibleToFinalize(value) {
    return (dispatch) => {
        dispatch({
            type: types.IS_ELIGIBLE_TO_FINALIZE,
            payload: value,
        });
    };
}

export function setPrepopulatedFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.SET_PREPOPULATED_FLAG,
            payload: val,
        });
    };
}

export function defaultGaRemoved(val) {
    return (dispatch) => {
        dispatch({
            type: types.DEFAULT_GA_REMOVED,
            payload: val,
        });
    };
}

export function getDateRules() {
    return (dispatch) => {
        axios.get(`${apiRoutes.mongo}/effectiveDate-rules`)
            .then((res) => {
                dispatch({
                    type: types.INITIALIZE_EFFECTIVE_DATE_RULES,
                    payload: res.data.dateRules,
                });
            })
            .catch((err) => {
                console.log('Error', err);
                dispatch({
                    type: types.INITIALIZE_EFFECTIVE_DATE_RULES,
                    error: err,
                });
                if(isUhcRenewals()) {
                    logError(KEY_NAME, UHC_REN_APIS.EFFECTIVE_DATE_RULES, 'overallActions.getDateRules', null, err);
                }
            });
    };
}

export const duplicateTinCheck = (args) => (dispatch) => {
    dispatch({
        type: types.SET_DUPLICATE_TIN,
        payload: args,
    });
}

export const displayErrorMessage = () => (dispatch) => {
    dispatch({
        type: types.DISPLAY_ERROR_MESSAGE
    });
}

export const displayUnsupportedOxfordZipCode = (args) => (dispatch) => {
    dispatch({
        type: types.UNSUPPORTED_OXFORD_ZIP_ERROR,
        payload: args
    });
}

export const displayUnsupportedOxfordZipCodePopupShow = (args) => (dispatch) => {
    dispatch({
        type: types.UNSUPPORTED_OXFORD_ZIP_ERROR_POPUP_SHOW,
        payload: args
    });
}

export const clearSuppLife = () => (dispatch) => {
    dispatch({
        type: types.SERVICE.SUPPLIFE.CLEAR_SELECTED_PLANS,
    })
}

export const updatePdfCsvLoader = (field, value) => (dispatch) => {
    dispatch({
        type: types.UPDATE_PDF_CSV_LOADER,
        field,
        value
    })
}

export function updateParticipationRules(stateCode) {
    return (dispatch) => new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.mongo}/participation-rules-data?state=${stateCode}`)
            .then(response => {
                dispatch({
                    type: types.UPDATE_PARTICIPATION_RULES,
                    payload: response.data,
                });
                resolve();
            })
            .catch((err) => {
                dispatch({
                    type: types.UPDATE_PARTICIPATION_RULES,
                    payload: null,
                });
                if(isUhcRenewals()) {
                    const reqBody = {
                        state: stateCode
                    }
                    logError(KEY_NAME, UHC_REN_APIS.PARTICIPATION_RULES_DATA, 'overallActions.updateParticipationRules', reqBody, err);
                }
                reject(err);
            });
    });
}

/**
 * Toggle paper case true or false
 * @param {boolean} isPaperCase boolean for whether paper case checkbox is checked
 */
export function toggleInternalCase(isPaperCase) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_PAPER_CASE,
            isPaperCase,
        });
    };
}

export const paperCaseHistoryLog = (isPaperCase, pageName = '') => (dispatch) => {
    dispatch({
        type: types.SAVE_CURRENT_PAGE,
        payload: ''
    })
    const pathname = history.location.pathname;
    let pathNamePaperCase = pathname;
    if (pageName) {
        pathNamePaperCase = pageName;
    } else {
        switch (pathname) {
            case '/full':
                pathNamePaperCase = 'Company Information';
                break;
            case '/employee':
                pathNamePaperCase = 'Employee Census';
                break;
            case '/plans':
                pathNamePaperCase = 'Plan Selection';
                break;
            case '/summary':
                pathNamePaperCase = 'Quote Review';
                break;
            case '/enrollment/manager':
                pathNamePaperCase = 'Enrollment Manager';
                break;
            case '/finalize-case':
                pathNamePaperCase = 'Finalize Details';
                break;
            default:
                pathNamePaperCase = 'Enrollment Details';
        }
    }

    if (isPaperCase) {
        dispatch(createHistory('Paper Case checkbox checked', pathNamePaperCase, 'Success', '', '', 'NB'))
    } else if (!isPaperCase) {
        dispatch(createHistory('Paper Case checkbox unchecked', pathNamePaperCase, 'Success', '', '', 'NB'))
    }
}


export const addRenewalNewEmployeeFlag = (value) => (dispatch) => {
    sendLog({
        action: 'ADD_RENEWAL_NEW_EMPLOYEE',
    });
    dispatch({
        type: types.ADD_RENEWAL_NEW_EMPLOYEE,
        payload: value,
    })
}

//to retrieve the dates based on year from submission_template table
export function retrieveeffectiveDates(year) {
    console.log('year', year);

    return (dispatch) => {
        axios.get(`${apiRoutes.mongo}/checkyear/${year}`)
            .then(response => {
                dispatch({
                    type: types.GET_RETRIEVE_DATES,
                    payload: response.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: types.GET_RETRIEVE_DATES,
                    payload: null,
                });
            });
    };
}

//to delete the submission end date  based on effective date from submission_template table

export function deleteeffectiveDates(effectiveDate) {
    console.log('effectiveDate', effectiveDate);

    return (dispatch) => {
        axios.get(`${apiRoutes.mongo}/delete/${effectiveDate}`)
            .then(response => {
                dispatch({
                    type: types.DELETE_EFFECTIVE_DATES,
                    payload: response.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: types.DELETE_EFFECTIVE_DATES,
                    payload: null,
                });
            });
    };
}



//to update the submission end date  based on effective date from submission_template table

export function saveorupdatedeadlineDate(effectiveDate, deadlineDate, createdBy, createdDate, lastupdatedBy, lastupdatedDate) {
    let value = '';
    let vdeadline = '';
    value = (effectiveDate.includes('/')) ? effectiveDate.substring(6, 10) + '-' + effectiveDate.substring(0, 2) + '-' + effectiveDate.substring(3, 5) : effectiveDate;

    vdeadline = (deadlineDate.includes('/')) ? deadlineDate.substring(6, 10) + '-' + deadlineDate.substring(0, 2) + '-' + deadlineDate.substring(3, 5) : deadlineDate;

    console.log('effectiveDate in update', effectiveDate);
    console.log('deadlineDate in update', deadlineDate);

    const dates = {
        effectiveDate: value,
        deadlineDate: vdeadline,
        createdBy: createdBy,
        createdDate: createdDate,
        lastupdatedBy: lastupdatedBy,
        lastupdatedDate: lastupdatedDate

    }
    return (dispatch) => {
        axios.post(`${apiRoutes.mongo}/savedate?effectiveDate = ${effectiveDate}`, dates)
            .then(response =>
                dispatch({
                    type: types.SAVE_UPDATE_DATE,
                    payload: response.data,
                })
            )
            .catch(() => {
                dispatch({
                    type: types.SAVE_UPDATE_DATE,
                    payload: null,
                });
            });
    };
}


export const addDateRules = () => (dispatch, getState) => {
    const { user } = getState();
    dispatch({
        type: types.ADD_DATE_RULES,
        payload: user.userName,
    })
}

export const deleteDateRules = (value) => (dispatch, getState) => {
    const { overall } = getState();

    const deleteDateRules = overall.dateRules;
    const dateRules = deleteDateRules.dateRules.filter(x => x.effectiveDate !== value.effectiveDate);;
    const finalDeletedRules = { dateRules };

    dispatch({
        type: types.DELETE_DATE_RULES,
        payload: finalDeletedRules,

    })
}

export const isparticipationBypassed = (value) => (dispatch) => {
    dispatch({
        type: types.IS_PARTICIPATION_BYPASSED,
        payload: value,
    })
}

export const isDesQuote = (value) => (dispatch) => {
    dispatch({
        type: types.IS_DES_QUOTE,
        isDesQuote : value,
    })
}

export const disableFinalizeRenewals=(value)  => (dispatch) =>{
    dispatch({
        type: types.IS_ELIGIBLE_TO_FINALIZE,
        payload: value,
    });

}
