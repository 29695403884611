export const generateSelectedPlansMap = (selectedPlans) => {
    const selectedPlansMap = {};
    selectedPlans.forEach(plan => {
        const pkg = plan.selectedPackages[0] || 'Single';
        if (selectedPlansMap.hasOwnProperty(pkg)) {
            selectedPlansMap[pkg].push(plan);
        } else {
            selectedPlansMap[pkg] = [plan];
        }
    })
    return selectedPlansMap;
}

export const getEmployeeCensus = (employees) => employees.flat()

export const generateSelectedPlansMapLifeAndDisability = (plan) => {
    const { employeeClassing: { classingOpted }, selectedPlans } = plan;
    if (classingOpted) {
        const selectedPlansMap = {};
        selectedPlans.forEach(plan => {
            const selectedClass = plan.selectedClass;
            if (selectedPlansMap.hasOwnProperty(selectedClass)) {
                selectedPlansMap[selectedClass].push(plan);
            } else {
                selectedPlansMap[selectedClass] = [plan];
            }
        })
        return selectedPlansMap;
    }
    return generateSelectedPlansMap(selectedPlans);
}