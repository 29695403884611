/**
 * Reduces the size of the specialty object when sending for proposal generation
 * @param {Object} specialtyObj - a specialty object from redux; dental, vision, etc
 * @param {Object} enrolledPlans - list of enrolled plans for a specialty product; dental, vision, etc
 * @param {Boolean} useEnrolledPlansm - true/false value for whether or not to use enrolled plans instead of selected plans
 * @returns a curated specialty object
 */
const curateSpecialtyPayload = (specialtyObj, enrolledPlans, useEnrolledPlans) => {
    const { selectedPlans, selectedPlansBenefitRiders, selectedPlansMap } = specialtyObj;
    return {
        selectedPlans: useEnrolledPlans ? enrolledPlans : selectedPlans,
        selectedPlansBenefitRiders,
        selectedPlansMap,
    };
};

export default curateSpecialtyPayload;
