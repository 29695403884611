import React from 'react';
import { Grid, Segment } from 'semantic-ui-react'

const isSpecialtyOnly = false;

const Expired = (props) => {
    return (
        <div className="formWrapper">
            <Grid className="formGrid" padded centered >
                <Grid.Row className="formRow" columns={1}>
                    <Grid.Column width={12}>
                        <Segment>
                            <h2 className="justifyCenter">Not Authorized</h2>
                            <div className="justifyCenter">
                                {isSpecialtyOnly ? <p style={{ padding: 20 }}>We're sorry, but your session has expired. To use Quick Quotes, please navigate here via your UES portal.</p> :
                                    <p style={{ padding: 20 }}>We're sorry, but your session has expired. To use SAMx, please navigate here via your external broker portal.</p>}
                            </div>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
};
export default Expired;