import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Icon, Label } from 'semantic-ui-react';
import { getRenewalCompanyProfileData, updateRenewalsSelectedProducts } from '../../../actions/companyProfileActions';
import { decideRenewalDetails, resetDecideFlag, updateDecideRenewalDetails } from '../../../actions/decideActions';
import { updatePrevious, resetToInitialState } from '../../../actions/overallActions';
import { updateStepperAccess, setRefreshApisLoader } from '../../../actions/renewalActions';
import { createHistory } from '../../../actions/historyAction';
import { getEnrollmentDetails, resetEnrollmentLoader, updateMDVEnrollmentInitial, updateRenewalEmployerInfoFromDB } from '../../../actions/renewalsEnrollmentActions';
import { resetReviewFlag, resetStepperFlag, reviewRenewalDetails,renewalStepperFlagChange } from '../../../actions/reviewActions';
import { getEnrolledPlansRenewals } from '../../../actions/rulesActions';
import { axios } from '../../../utils/api/axiosConfig';
import { toMoney } from '../../../utils/formatters/numbers';
import { addToShoppingPlans, getDecideDetailAfterFinalizeQuery, getDecideDetailFromEnrollment, getDecideDetailQuery, getMemberGroupIdDataDecide, getMemberGroupIdDataRenewalShoppingHelper, getMemberGroupIdDataReview, getRenewalShoppingQuery, saveDecideDetailQueryRenewalStepper, saveReviewDetailQueryRenewalStepper, saveShoppingHelperDetailQueryRenewalStepper, setShoppingPlanSelection, setShoppingPlanSelectionExceptDecide } from '../../activities/Renewal/RenewalsCommonComponents/saveDetails';
import './renewalStepper.css';
import { getRenewalCensusRates } from '../../../actions/renewalsEnrollmentActions';
import { callRefreshApis } from '../../activities/Renewal/RenewalEnrollment/RenewalEnrollmentUtils';
import { Tooltip } from '@material-ui/core';
import deepCopy from '../../../utils/deepCopy';
import { getMarketType, MARKET_TYPES, isMmr, isUhcRenewals } from '../../../utils/businessLogic/platformUtils';
import * as types from '../../../actions/actionTypes';
import { resetFinalizeFlag } from '../../../actions/finalizeCaseSummaryActions';
import { logError } from '../../../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../../../utils/errorLogging/logKeys';
import { isCaseNotFinalized } from '../../../utils/Renewal/uhcRenewalCaseStatus';
import { saveEmployerToCompanyProfile } from '../../../reducers/products/helpers/renewalCompanyProfileHelper';
import { sendClickEventDataAction } from '../../../actions/analyticsAction';
import { setFetchingAlternateRatingMethod } from '../../../actions/renewalShoppingActions';
import { updateContribution } from '../../activities/Renewal/RenewalsCommonComponents/contributionHelper';
import { isUndefinedOrNull } from '../../../utils/validation/commonValidations';

const apiRoutes = require('../../../utils/api/apiRouteConfig');

const RenewalStepper = (props) => {

    const stepperAccess = useSelector((state) => state.renewals.stepperAccess);
    const size = useSelector((state) => state.size);
    const decideData = useSelector((state) => state.decide)
    const store = useSelector((state) => state)
    const enrollPermissions = useSelector((state) => state.permissions.renewalEnrollment)
    const dispatch = useDispatch();
    const [showEnrollNoPremPopup, setShowEnrollNoPremPopup] = useState(false);
    const [showDecidePremPopup, setShowDecidePremPopup] = useState(false);
    const stepperFlag = useSelector((state) => state.review.renewalStepperFlag);
    const { pathname } = props;
    const pathParts = pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    const penultimatePart = pathParts[pathParts.length - 2];

    const dashboard = lastPart === 'renewal-dashBoard';
    const reviewPage = lastPart === 'reviewDetail';
    const renewalShopping = lastPart === 'renewalShopping';
    const decidePage = lastPart === 'decideDetail';
    const enrollManagerPage = penultimatePart === 'enrollmentManager';
    const finalizePage = lastPart === 'finalize';
    const renewalFinalizeCasePage = lastPart === 'renewal-finalize-case';
    const isMMRCase = isMmr();
    const isUhcRenewal = isUhcRenewals();
    const roleName = useSelector((state) => state.renewals.roleName);
    const displayRenewalFinalizeCaseRoles = [
        'Production Support Admin',
        'UW Representative',
        'OXHP Install Rep',
        'UHC Install Rep',
        'Install Supervisor'
    ];
    const displayRenewalFinalizeCase = displayRenewalFinalizeCaseRoles.includes(roleName);

    const { mobile } = size;

    const { renewaldashboard, reviewDetail, decide, enrollment, finalize,renewalFinalizeCase} = stepperAccess

    let caseStatus = ''
    if (store.renewals.globalSearchFlag) {
        caseStatus = store.renewals.renewalSearchCase.caseProgressUI;
    } else {
        const currentRenewal = store.renewals.renewals.find((plan) => plan.memberGroupId === store.renewals.memberGroupId);
        caseStatus = currentRenewal ? currentRenewal.caseProgressUI : "";
    }
    if (store.caseTracking && store.caseTracking.caseTrack[0] && store.caseTracking.caseTrack[0].caseStatus !== '') {
        caseStatus = store.caseTracking.caseTrack[0].caseStatus;
    }

    const revokeEnrollmentStep = (decidePage || reviewPage || renewalShopping) && caseStatus && (caseStatus === 'In Progress' || caseStatus === 'Not Started')

    const beenToFinalize = decideData.renewalData ? decideData.renewalData.beenToFinalize : false;

    const toggleEnrollmentNoPremPopup = () => {
        setShowEnrollNoPremPopup(showEnrollNoPremPopup => !showEnrollNoPremPopup);
    }
    const toggleDecideNoPremPopup = () => {
        setShowDecidePremPopup(showDecidePremPopup => !showDecidePremPopup);
    }

    const updateSelectedProducts = (store, page = '') => {
        let selectedProducts = {
            medical: false,
            dental: false,
            vision: false,
            life: false
        }
        if(isUhcRenewals()){
            selectedProducts.std =false;
            selectedProducts.ltd = false;
            selectedProducts.std = false;
        }

        //TODO
        // if (res.data.renewalData && res.data.renewalData.Ibr) {
        //     const planOfferingData = res.data.renewalData.Ibr.quote.planOfferingData
        //     for (const planOffering of planOfferingData) {
        //         for (const existingPlan of planOffering.existingPlanPkg.plans.plan) {
        //             if (existingPlan.productLineType === 'DENTAL') {
        //                 selectedProducts.dental = true;
        //             }
        //             if (existingPlan.productLineType === 'VISION') {
        //                 selectedProducts.vision = true;
        //             }
        //             if (existingPlan.productLineType === 'BASIC_LIFE_EE') {
        //                 selectedProducts.life = true;
        //             }
        //         }
        //     }
        // }
        if (store && page !== 'decide') {
            if (store && store.renewalData && store.renewalData.renewAsIs) {
                if (store.shoppingPlans && store.shoppingPlans.length !== 0) {
                    selectedProducts.medical = true;
                } else if (store.renewalData.planRates) {
                    store.renewalData.planRates.map((item) => {
                        if (item.renewalPlanType === 'Renewal') {
                            selectedProducts.medical = true;
                        }
                    });
                }
            }else if(isUhcRenewal){
                if (store.enrolledPlans && store.enrolledPlans.length !== 0) {
                    selectedProducts.medical = true;
                }
            }
            if (store.enrolledDentalPlans && store.enrolledDentalPlans.length !== 0) {
                selectedProducts.dental = true;
            }
            if (store.enrolledVisionPlans && store.enrolledVisionPlans.length !== 0) {
                selectedProducts.vision = true;
            }
            if (store.enrolledLifePlans && store.enrolledLifePlans.length !== 0) {
                selectedProducts.life = true;
            }
            if (isUhcRenewals() && store.enrolledSTDPlans && store.enrolledSTDPlans.length !== 0) {
                selectedProducts.std = true;
            }
            if (isUhcRenewals() && store.enrolledLTDPlans && store.enrolledLTDPlans.length !== 0) {
                selectedProducts.ltd = true;
            }
            if (isUhcRenewals() && store.enrolledSuppLifePlans && store.enrolledSuppLifePlans.length !== 0) {
                selectedProducts.suppLife = true;
            }
        } else if (store && page === 'decide') {
            if(isUhcRenewal) {
                let medicalPlans = [];
                if(store.enrollment && store.enrollment.enrolledPlans) {
                    Object.keys(store.enrollment.enrolledPlans).map((item) => {
                        medicalPlans.push(store.enrollment.enrolledPlans[item]);
                    })
                    medicalPlans = medicalPlans.flat();
                }

                if(medicalPlans.length > 0) {
                    selectedProducts.medical = true;
                }
            } else {
                if (store.enrollment && store.enrollment.enrolledPlans && store.enrollment.enrolledPlans.standalone && store.enrollment.enrolledPlans.standalone.length !== 0) {
                    selectedProducts.medical = true;
                }
            }
            if (store.enrollment && store.enrollment.enrolledDentalPlans && store.enrollment.enrolledDentalPlans.length !== 0) {
                selectedProducts.dental = true;
            }
            if (store.enrollment && store.enrollment.enrolledVisionPlans && store.enrollment.enrolledVisionPlans.length !== 0) {
                selectedProducts.vision = true;
            }
            if (store.enrollment && store.enrollment.enrolledLifePlans && store.enrollment.enrolledLifePlans.length !== 0) {
                selectedProducts.life = true;
            }
            if (isUhcRenewals() && store.enrollment && store.enrollment.enrolledSTDPlans && store.enrollment.enrolledSTDPlans.length !== 0) {
                selectedProducts.std = true;
            }
            if (isUhcRenewals() && store.enrollment && store.enrollment.enrolledLTDPlans && store.enrollment.enrolledLTDPlans.length !== 0) {
                selectedProducts.ltd = true;
            }
            if (isUhcRenewals() && store.enrollment && store.enrollment.enrolledSuppLifePlans && store.enrollment.enrolledSuppLifePlans.length !== 0) {
                selectedProducts.suppLife = true;
            }
        }
        return selectedProducts
    }

    const navigation = async(page) => {
        let selectedPage = '';
        switch (page) {
            case 'renewal-dashBoard':
                selectedPage = 'Dashboard';
                break;
            case 'reviewDetail':
                selectedPage = 'Review';
                break;
            case 'renewalShopping':
                selectedPage = 'Renewal Shopping';
                break;
            case 'decideDetail':
                selectedPage = 'Decide';
                break;
            case 'enrollmentManager':
                selectedPage = 'Enrollment Manager';
                break;
            case 'finalize':
                selectedPage = 'Finalize';
                break;
            case 'finalize-case-renewal':
                selectedPage = 'Finallize Details Renewal';
                break;
            default:
                selectedPage = '';
        }

        const marketType = getMarketType();
        const renewalUpdateRoute = marketType === MARKET_TYPES.UHC ? "/uhcRenewal/renewalUpdateUhc/" : "/renewalUpdate/";
        dispatch(sendClickEventDataAction({
            clickName : `BreadCrumb : ${selectedPage!==''?selectedPage:page==='renewal-dashboard'?'Dashboard':''}`,
            clickElement : 'Button',
            clickType : 'internal'
          }))
        dispatch(createHistory('Renewal Stepper', selectedPage, 'Success', {}, `Moving to ${selectedPage}`, 'RL'));
        dispatch(resetStepperFlag(false));
        if (reviewPage) {
            dispatch(resetEnrollmentLoader());
            dispatch(resetReviewFlag(false))
            let shoppingPlans = addToShoppingPlans(store);
            let query = saveReviewDetailQueryRenewalStepper(store);
            if (!isUhcRenewal) {
                query.shoppingPlans = shoppingPlans ? shoppingPlans : []
            }
            if (isMMRCase && page === 'finalize') {
                dispatch(resetFinalizeFlag(false))
            }
            if(isMMRCase)
            {
                const caseId = store.renewals.renewalData.memberGroup.memberGroupId
                const priorYearDataSelected = store?.renewals?.priorYearDataSelected;
                const effectiveDate = store?.renewals?.renewalCaseData?.effectiveDate;
                let caseTrackingResponse = await axios.get(`${apiRoutes.mongo}/uhcRenewalDb/caseTrack/case/true/${caseId}/${priorYearDataSelected}/${effectiveDate}`)
                let reqParamQuoteTrackingNumber = store.caseTracking.currentQuoteId;
                let rlCaseDataResponse =  await  axios.get(`${apiRoutes.mongo}/uhcRenewalDb/getRLCaseData/case/true/${caseId}/${priorYearDataSelected}/${effectiveDate}`)
                const currentCaseTrackingData = caseTrackingResponse.data;
                dispatch(createHistory('Renewal - Get Casetracking record', 'Casetrack', 'Success', caseId, currentCaseTrackingData, store.overall.source === 'mmr' ? 'MMR' : 'RL'));
                if (currentCaseTrackingData && currentCaseTrackingData.length > 0) {
                    const filterCaseTrack = currentCaseTrackingData.find((caseTrackRec) => caseTrackRec.quoteTrackingNumber === reqParamQuoteTrackingNumber);
                    const currCaseTrackingData = rlCaseDataResponse.data[0] ? currentCaseTrackingData.find(currCaseTrackRec => rlCaseDataResponse.data[0].quoteTrackingNumber === currCaseTrackRec.quoteTrackingNumber ) : null
                    const currentCaseTrack = !isUndefinedOrNull(filterCaseTrack) ? filterCaseTrack : !isUndefinedOrNull(currCaseTrackingData) ? currCaseTrackingData : currentCaseTrackingData[0];
                    const { caseId: currentCaseId, quoteTrackingNumber } = currentCaseTrack;

                    const caseTracking = {
                        ...store.caseTracking,
                        caseId: currentCaseId,
                        caseTrack: [currentCaseTrack],
                        currentQuoteId: quoteTrackingNumber,
                        platform: currentCaseTrack.platform,
                        isManualInstallation: currentCaseTrack.isManualInstallation || false,
                        receivedPlatformFromIPPS: currentCaseTrack.receivedPlatformFromIPPS || false,
                    };

                    dispatch({
                        type: types.GET_CASE_TRACK,
                        caseTracking,
                        isUhcRenewals: isUhcRenewals()
                    });
    
                    dispatch({
                        type: types.SET_CURRENT_QUOTE_ID,
                        currentQuoteId: quoteTrackingNumber,
                    });

                    let res =  await axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/updateCaseDataUhcModel?memberGroupId=${currentCaseTrack.renewalCaseId}`, currentCaseTrack)
                    if(res?.data === 'Success')
                    {
                      console.log("Successfully update in renewalCaseDataUhc ")
                    }  
                }
            }
            axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                .then(res => {
                    if (res) {
                        if (page === 'decideDetail') {
                            dispatch(decideRenewalDetails(getDecideDetailQuery(store)))
                        }
                        if (page === 'enrollmentManager/manager') {
                            if (isCaseNotFinalized()) {
                                dispatch(setRefreshApisLoader(true));
                                const quoteTrackingNumber = store.caseTracking.currentQuoteId;
                                const priorYearDataSelected = store.renewals && store.renewals.priorYearDataSelected ? store.renewals.priorYearDataSelected : 'N';
                                callRefreshApis(priorYearDataSelected, store.rules, quoteTrackingNumber, store.review, store.companyProfile, store.user.userName, store.premium, false , '','','', store.overall.appConfigDetails?.allowPrimeRefreshCall)
                                    .then(response => {
                                        const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                                        const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                                        if (queryData && queryData.renewalCaseData && queryData.renewalCaseData.platform && queryData.renewalCaseData.platform !== 'PRIME') {
                                            const enrolMedPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledPlans && store.rules.enrollment.enrolledPlans.standalone && store.rules.enrollment.enrolledPlans.standalone.length > 0 ? store.rules.enrollment.enrolledPlans.standalone : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledPlans && store.renewalShoppingHelper.enrolledPlans.standalone && store.renewalShoppingHelper.enrolledPlans.standalone.length > 0 ? store.renewalShoppingHelper.enrolledPlans.standalone : store && store.decide && store.decide.enrolledPlans && store.decide.enrolledPlans.standalone && store.decide.enrolledPlans.standalone.length > 0 ? store.decide.enrolledPlans.standalone : [];
                                            const enrolDenPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledDentalPlans && store.rules.enrollment.enrolledDentalPlans.length > 0 ? store.rules.enrollment.enrolledDentalPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledDentalPlans && store.renewalShoppingHelper.enrolledDentalPlans.length > 0 ? store.renewalShoppingHelper.enrolledDentalPlans : store && store.decide && store.decide.enrolledDentalPlans && store.decide.enrolledDentalPlans.length > 0 ? store.decide.enrolledDentalPlans : [];
                                            const enrolVisPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledVisionPlans && store.rules.enrollment.enrolledVisionPlans.length > 0 ? store.rules.enrollment.enrolledVisionPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledVisionPlans && store.renewalShoppingHelper.enrolledVisionPlans.length > 0 ? store.renewalShoppingHelper.enrolledVisionPlans : store && store.decide && store.decide.enrolledVisionPlans && store.decide.enrolledVisionPlans.length > 0 ? store.decide.enrolledVisionPlans : [];
                                            // Proposed Rate Call
                                            dispatch(getRenewalCensusRates(enrolMedPlans, enrolDenPlans, enrolVisPlans, [], 'Proposed', queryData, 'Dashboard'));
                                        }
                                        dispatch(setRefreshApisLoader(false));
                                        dispatch(getEnrollmentDetails('review'))
                                        if (store.review.renewalData.renewAsIs) {
                                            dispatch(getEnrolledPlansRenewals(true, 'review'))
                                        } else {
                                            dispatch(getEnrolledPlansRenewals(false, 'review'))
                                        }
                                        dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataReview(store)))
                                        dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataReview(store), null, caseSubmitted))
                                    })
                                    .catch(err => {
                                        dispatch(setRefreshApisLoader(false));
                                    });
                            } else if (isUhcRenewal) {
                                dispatch(setRefreshApisLoader(true));
                                const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                                const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                                dispatch(setRefreshApisLoader(false));
                                const selectedProducts = updateSelectedProducts(store.review)
                                dispatch(updateRenewalsSelectedProducts(selectedProducts));
                                dispatch(getEnrollmentDetails('review'))
                                if (store.review.renewalData.renewAsIs) {
                                    dispatch(getEnrolledPlansRenewals(true, 'review'))
                                } else {
                                    dispatch(getEnrolledPlansRenewals(false, 'review'))
                                }
                                dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataReview(store)))
                                dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataReview(store), null, caseSubmitted))
                            }
                        }
                        if (page === 'finalize') {
                            let query = getDecideDetailQuery(store)
                            query.pageType = 'finalize'
                            dispatch(decideRenewalDetails(query,false,true))
                        }
                    }
                }).catch(err => {
                    if(isUhcRenewal) {
                        logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalStepper.navigation', query, err);
                    }
                    throw err;
                });
            props.history.push('/' + page)
            dispatch(updateStepperAccess('reviewDetail'))
            dispatch(updatePrevious('/reviewDetail'))
        }
        else if (renewalShopping) {
            dispatch(resetEnrollmentLoader());
            dispatch(resetReviewFlag(false))
            dispatch(setFetchingAlternateRatingMethod(null));
            const query = saveShoppingHelperDetailQueryRenewalStepper(store)
            axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                .then(res => {
                    if (res) {
                        if (page === 'reviewDetail') {
                            dispatch(reviewRenewalDetails())
                            //dispatch(getReviewShoppingDetails())
                        }
                        if (page === 'decideDetail') {
                            dispatch(decideRenewalDetails(getRenewalShoppingQuery(store)))
                        }
                        if (page === 'enrollmentManager/manager') {
                            if (isCaseNotFinalized()) {
                                dispatch(setRefreshApisLoader(true));
                                const quoteTrackingNumber = store.caseTracking.currentQuoteId;
                                const priorYearDataSelected = store.renewals && store.renewals.priorYearDataSelected ? store.renewals.priorYearDataSelected : 'N';
                                callRefreshApis(priorYearDataSelected, store.rules, quoteTrackingNumber, store.renewalShoppingHelper, store.companyProfile, store.user.userName, store.premium, false , '','','', store.overall.appConfigDetails?.allowPrimeRefreshCall)
                                    .then(response => {
                                        const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                                        const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                                        if (queryData && queryData.renewalCaseData && queryData.renewalCaseData.platform && queryData.renewalCaseData.platform !== 'PRIME') {
                                            const enrolMedPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledPlans && store.rules.enrollment.enrolledPlans.standalone && store.rules.enrollment.enrolledPlans.standalone.length > 0 ? store.rules.enrollment.enrolledPlans.standalone : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledPlans && store.renewalShoppingHelper.enrolledPlans.standalone && store.renewalShoppingHelper.enrolledPlans.standalone.length > 0 ? store.renewalShoppingHelper.enrolledPlans.standalone : store && store.decide && store.decide.enrolledPlans && store.decide.enrolledPlans.standalone && store.decide.enrolledPlans.standalone.length > 0 ? store.decide.enrolledPlans.standalone : [];
                                            const enrolDenPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledDentalPlans && store.rules.enrollment.enrolledDentalPlans.length > 0 ? store.rules.enrollment.enrolledDentalPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledDentalPlans && store.renewalShoppingHelper.enrolledDentalPlans.length > 0 ? store.renewalShoppingHelper.enrolledDentalPlans : store && store.decide && store.decide.enrolledDentalPlans && store.decide.enrolledDentalPlans.length > 0 ? store.decide.enrolledDentalPlans : [];
                                            const enrolVisPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledVisionPlans && store.rules.enrollment.enrolledVisionPlans.length > 0 ? store.rules.enrollment.enrolledVisionPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledVisionPlans && store.renewalShoppingHelper.enrolledVisionPlans.length > 0 ? store.renewalShoppingHelper.enrolledVisionPlans : store && store.decide && store.decide.enrolledVisionPlans && store.decide.enrolledVisionPlans.length > 0 ? store.decide.enrolledVisionPlans : [];
                                            // Proposed Rate Call
                                            dispatch(getRenewalCensusRates(enrolMedPlans, enrolDenPlans, enrolVisPlans, [], 'Proposed', queryData, 'Dashboard'));
                                        }
                                        dispatch(setRefreshApisLoader(false));
                                        dispatch(getEnrollmentDetails('review'))
                                        if (store.renewalShoppingHelper.renewalData.renewAsIs) {
                                            dispatch(getEnrolledPlansRenewals(true, 'renewalShoppingHelper'))
                                        } else {
                                            dispatch(getEnrolledPlansRenewals(false, 'renewalShoppingHelper'))
                                        }
                                        dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataRenewalShoppingHelper(store)))
                                        dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataRenewalShoppingHelper(store), null, caseSubmitted))
                                    })
                                    .catch(err => {
                                        dispatch(setRefreshApisLoader(false));
                                    })
                            } else if (isUhcRenewal) {
                                dispatch(setRefreshApisLoader(true));
                                const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                                const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                                dispatch(setRefreshApisLoader(false));
                                const selectedProducts = updateSelectedProducts(store.renewalShoppingHelper)
                                dispatch(updateRenewalsSelectedProducts(selectedProducts));
                                dispatch(getEnrollmentDetails('review'))
                                if (store.renewalShoppingHelper.renewalData.renewAsIs) {
                                    dispatch(getEnrolledPlansRenewals(true, 'renewalShoppingHelper'))
                                } else {
                                    dispatch(getEnrolledPlansRenewals(false, 'renewalShoppingHelper'))
                                }
                                dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataRenewalShoppingHelper(store)))
                                dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataRenewalShoppingHelper(store), null, caseSubmitted))
                            }
                        }
                    }
                }).catch(err => {
                    if(isUhcRenewal) {
                        logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalStepper.navigation', query, err);
                    }
                    throw err;
                });
            props.history.push('/' + page)
            dispatch(updateStepperAccess('reviewDetail'))
            dispatch(updatePrevious('/reviewDetail'))
        }
        else if (decidePage) {
            dispatch(resetEnrollmentLoader());
            dispatch(resetDecideFlag(false))
            dispatch(resetReviewFlag(false))
            if (isMMRCase && page === 'finalize') {
                dispatch(resetFinalizeFlag(false))
            }
            if(isMMRCase)
                {
                    const caseId = store.renewals?.renewalData?.memberGroup?.memberGroupId
                    const priorYearDataSelected = store?.renewals?.priorYearDataSelected;
                    const effectiveDate = store?.renewals?.renewalCaseData?.effectiveDate;
                    let caseTrackingResponse = await axios.get(`${apiRoutes.mongo}/uhcRenewalDb/caseTrack/case/true/${caseId}/${priorYearDataSelected}/${effectiveDate}`)
                    let reqParamQuoteTrackingNumber = store.caseTracking.currentQuoteId;
                    let rlCaseDataResponse =  await  axios.get(`${apiRoutes.mongo}/uhcRenewalDb/getRLCaseData/case/true/${caseId}/${priorYearDataSelected}/${effectiveDate}`)
                    const currentCaseTrackingData = caseTrackingResponse.data;
                    dispatch(createHistory('Renewal - Get Casetracking record', 'Casetrack', 'Success', caseId, currentCaseTrackingData, store.overall.source === 'mmr' ? 'MMR' : 'RL'));
                    if (currentCaseTrackingData && currentCaseTrackingData.length > 0) {
                        const filterCaseTrack = currentCaseTrackingData.find((caseTrackRec) => caseTrackRec.quoteTrackingNumber === reqParamQuoteTrackingNumber);
                        const currCaseTrackingData = rlCaseDataResponse.data[0] ? currentCaseTrackingData.find(currCaseTrackRec => rlCaseDataResponse.data[0].quoteTrackingNumber === currCaseTrackRec.quoteTrackingNumber ) : null
                        const currentCaseTrack = !isUndefinedOrNull(filterCaseTrack) ? filterCaseTrack : !isUndefinedOrNull(currCaseTrackingData) ? currCaseTrackingData : currentCaseTrackingData[0];
                        const { caseId: currentCaseId, quoteTrackingNumber } = currentCaseTrack;
    
                        const caseTracking = {
                            ...store.caseTracking,
                            caseId: currentCaseId,
                            caseTrack: [currentCaseTrack],
                            currentQuoteId: quoteTrackingNumber,
                            platform: currentCaseTrack.platform,
                            isManualInstallation: currentCaseTrack.isManualInstallation || false,
                            receivedPlatformFromIPPS: currentCaseTrack.receivedPlatformFromIPPS || false,
                        };
    
                        dispatch({
                            type: types.GET_CASE_TRACK,
                            caseTracking,
                            isUhcRenewals: isUhcRenewals()
                        });
        
                        dispatch({
                            type: types.SET_CURRENT_QUOTE_ID,
                            currentQuoteId: quoteTrackingNumber,
                        });
    
                        let res =  await axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/updateCaseDataUhcModel?memberGroupId=${currentCaseTrack.renewalCaseId}`, currentCaseTrack)
                        if(res?.data === 'Success')
                        {
                          console.log("Successfully update in renewalCaseDataUhc ")
                        }  
                    }
                }
            const query = saveDecideDetailQueryRenewalStepper(store, '', page);
            axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                .then(res => {
                    if (page === 'reviewDetail') {
                        dispatch(reviewRenewalDetails())
                        //dispatch(getReviewShoppingDetails())
                    }
                    if (page === 'enrollmentManager/manager') {
                        if (isCaseNotFinalized()) {
                            dispatch(setRefreshApisLoader(true));
                            const quoteTrackingNumber = store.caseTracking.currentQuoteId;
                            const priorYearDataSelected = store.renewals && store.renewals.priorYearDataSelected ? store.renewals.priorYearDataSelected : 'N';
                            callRefreshApis(priorYearDataSelected, store.rules, quoteTrackingNumber, store.decide, store.companyProfile, store.user.userName, store.premium, false , '','','', store.overall.appConfigDetails?.allowPrimeRefreshCall)
                                .then(response => {
                                    const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                                    const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                                    if (queryData && queryData.renewalCaseData && queryData.renewalCaseData.platform && queryData.renewalCaseData.platform !== 'PRIME') {
                                        const enrolMedPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledPlans && store.rules.enrollment.enrolledPlans.standalone && store.rules.enrollment.enrolledPlans.standalone.length > 0 ? store.rules.enrollment.enrolledPlans.standalone : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledPlans && store.renewalShoppingHelper.enrolledPlans.standalone && store.renewalShoppingHelper.enrolledPlans.standalone.length > 0 ? store.renewalShoppingHelper.enrolledPlans.standalone : store && store.decide && store.decide.enrolledPlans && store.decide.enrolledPlans.standalone && store.decide.enrolledPlans.standalone.length > 0 ? store.decide.enrolledPlans.standalone : [];
                                        const enrolDenPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledDentalPlans && store.rules.enrollment.enrolledDentalPlans.length > 0 ? store.rules.enrollment.enrolledDentalPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledDentalPlans && store.renewalShoppingHelper.enrolledDentalPlans.length > 0 ? store.renewalShoppingHelper.enrolledDentalPlans : store && store.decide && store.decide.enrolledDentalPlans && store.decide.enrolledDentalPlans.length > 0 ? store.decide.enrolledDentalPlans : [];
                                        const enrolVisPlans = store.rules && store.rules.enrollment && store.rules.enrollment.enrolledVisionPlans && store.rules.enrollment.enrolledVisionPlans.length > 0 ? store.rules.enrollment.enrolledVisionPlans : store && store.renewalShoppingHelper && store.renewalShoppingHelper.enrolledVisionPlans && store.renewalShoppingHelper.enrolledVisionPlans.length > 0 ? store.renewalShoppingHelper.enrolledVisionPlans : store && store.decide && store.decide.enrolledVisionPlans && store.decide.enrolledVisionPlans.length > 0 ? store.decide.enrolledVisionPlans : [];
                                        // Proposed Rate Call
                                        dispatch(getRenewalCensusRates(enrolMedPlans, enrolDenPlans, enrolVisPlans, [], 'Proposed', queryData, 'Dashboard'));
                                    }
                                    dispatch(setRefreshApisLoader(false));
                                    const mdvContribution = decideData.mdvContribution ? decideData.mdvContribution : {}
                                    const contributionQuery = updateContribution(decideData.renewalData.memberGroup, mdvContribution);
                                    dispatch(updateMDVEnrollmentInitial(contributionQuery));
                                    dispatch(getEnrollmentDetails('decide'))
                                    dispatch(getEnrolledPlansRenewals(false, 'decide'))
                                    dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataDecide(store)))
                                    dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataDecide(store), null, caseSubmitted))
                                })
                                .catch(err => {
                                    dispatch(setRefreshApisLoader(false));
                                })
                        } else if (isUhcRenewal) {
                            dispatch(setRefreshApisLoader(true));
                            const queryData = store && store.decide && store.decide.renewalCaseData ? store.decide : store && store.renewalShopping && store.renewalShopping.renewalCaseData ? store.renewalShopping : store && store.renewalShoppingHelper && store.renewalShoppingHelper.renewalCaseData ? store.renewalShoppingHelper : store && store.review && store.review.renewalCaseData ? store.review : store && store.renewals && store.renewals.renewalCaseData ? store.renewals : {};
                            const caseSubmitted = queryData.caseSubmitted ? queryData.caseSubmitted : false;
                            dispatch(setRefreshApisLoader(false));
                            const selectedProducts = updateSelectedProducts(store.rules, 'decide')
                            dispatch(updateRenewalsSelectedProducts(selectedProducts));
                            const mdvContribution = decideData.mdvContribution ? decideData.mdvContribution : {}
                            const contributionQuery = updateContribution(decideData.renewalData.memberGroup, mdvContribution);
                            dispatch(updateMDVEnrollmentInitial(contributionQuery));
                            dispatch(getEnrollmentDetails('decide'))
                            dispatch(getEnrolledPlansRenewals(false, 'decide'))
                            dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataDecide(store)))
                            dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataDecide(store), null, caseSubmitted))
                        }
                    }
                    if (page === 'finalize') {
                        dispatch(reviewRenewalDetails(false, page,true))
                    }
                }).catch(err => {
                    if(isUhcRenewal) {
                        logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalStepper.navigation', query, err);
                    }
                    throw err;
                });
            props.history.push('/' + page)
            dispatch(updateStepperAccess('decide'))
            dispatch(updatePrevious('/decideDetail'))
        }
        else if (enrollManagerPage) {
            dispatch(resetReviewFlag(false))
            dispatch({
                type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                payload: true
            })
            const { userName } = store.user;
            let cmpProfile = deepCopy(store.companyProfile)
            if (cmpProfile.enrollment) {
                cmpProfile.enrollment.requiredDocs = store.enrollment.documents.requiredDocuments;
            } else {
                cmpProfile.enrollment = {
                    requiredDocs: store.enrollment.documents.requiredDocuments,
                };
            }
            cmpProfile = saveEmployerToCompanyProfile(cmpProfile,store);
            cmpProfile.marketType = marketType;

            if(isUhcRenewals()) {
                cmpProfile = saveEmployerToCompanyProfile(cmpProfile , store);
                cmpProfile.priorYearDataSelected = store.renewals.priorYearDataSelected
            }

            axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile).catch(err => {
                if(isUhcRenewal) {
                    logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'RenewalStepper.navigation', cmpProfile, err);
                }
                throw err;
            })

            if (page === 'decideDetail') {
                dispatch(resetDecideFlag(false));
                const query = setShoppingPlanSelection(store);
                axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                    .then(res => {
                        if (res) {
                            //dispatch(getReviewShoppingDetails())
                            if (page === 'decideDetail') {
                                dispatch(decideRenewalDetails(getDecideDetailFromEnrollment(store), true))
                            }

                        }
                    }).catch(err => {
                        if(isUhcRenewal) {
                            logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalStepper.navigation', query, err);
                        }
                        throw err;
                    });
            }
            else {
                const query = setShoppingPlanSelectionExceptDecide(store);
                axios.put(`${apiRoutes.quickQuotes}${renewalUpdateRoute}`, query)
                    .then(res => {
                        if (res) {
                            if (page === 'reviewDetail')
                                //needed for further testing
                                dispatch(reviewRenewalDetails())
                        }
                    }).catch(err => {
                        if(isUhcRenewal) {
                            logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'RenewalStepper.navigation', query, err);
                        }
                        throw err;
                    });
            }
            props.history.push('/' + page)
            dispatch(updateStepperAccess('enrollment'))
            dispatch(updatePrevious('/enrollmentManager'))
        }
        else if (finalizePage) {
            dispatch(resetEnrollmentLoader());
            dispatch(resetReviewFlag(false))
            if (page === 'reviewDetail') {
                dispatch(reviewRenewalDetails())
                dispatch(updateDecideRenewalDetails(saveDecideDetailQueryRenewalStepper(store)))
            }
            else if (page === 'decideDetail') {
                dispatch(decideRenewalDetails(getDecideDetailAfterFinalizeQuery(store)))
            }
            else if (page === 'enrollmentManager/manager') {
                const mdvContribution = store.renewalEnrollment.mdvContribution ? store.renewalEnrollment.mdvContribution : {}
                const contributionQuery = updateContribution(store.renewalEnrollment.renewalData.memberGroup, mdvContribution);
                dispatch(updateMDVEnrollmentInitial(contributionQuery));
                dispatch(getEnrollmentDetails(decide ? 'decide' : 'review'))
                if(store.renewals.renewAsIs === true){
                    dispatch(getEnrolledPlansRenewals(true,decide ? 'decide' : reviewDetail ? 'review' : 'renewalFinalizeCase' ))
                }
                else{
                    dispatch(getEnrolledPlansRenewals(false,decide ? 'decide' : 'review'))
                }
                dispatch(getRenewalCompanyProfileData(getMemberGroupIdDataDecide(store)))
                dispatch(updateRenewalEmployerInfoFromDB(getMemberGroupIdDataDecide(store), null, true))
            }
            props.history.push('/' + page)
            dispatch(updateStepperAccess('finalize'))
            dispatch(updatePrevious('/finalize'))
        }
        else if(renewalFinalizeCasePage) {
            dispatch(updateStepperAccess('renewalFinalizeCase'))
            dispatch(renewalStepperFlagChange(true));
            dispatch(updatePrevious('/renewal-finalize-case'))
            if (page === 'reviewDetail') {
                if (isUhcRenewals() || isMMRCase) {
                    dispatch(reviewRenewalDetails())
                }
                dispatch(updateDecideRenewalDetails(saveDecideDetailQueryRenewalStepper(store)))
            }
            else if (page === 'decideDetail') {
                dispatch(decideRenewalDetails(getDecideDetailAfterFinalizeQuery(store)))
            }
            else if (page === 'enrollmentManager/manager') {
                const mdvContribution = store.renewalEnrollment.mdvContribution ? store.renewalEnrollment.mdvContribution : {}
                const contributionQuery = updateContribution(store.renewalEnrollment.renewalData.memberGroup, mdvContribution); // not sure correct, taking reference from above
                dispatch(updateMDVEnrollmentInitial(contributionQuery));
                dispatch(getEnrollmentDetails(decide ? 'decide' : 'review'))
                dispatch(getEnrolledPlansRenewals(false, 'renewalFinalizeCase'))
                dispatch(getRenewalCompanyProfileData(store.renewals.renewalData.memberGroup.memberGroupId))
                dispatch(updateRenewalEmployerInfoFromDB(store.renewals.renewalData.memberGroup.memberGroupId, null, true))
            }
            props.history.push('/' + page)
           

        }else  {
            props.history.push('/' + page)
        }

        if(page === 'renewal-dashboard' && isUhcRenewal){
            dispatch(resetToInitialState());
        }
    }


    return (
        <div className="justifyCenter paddingTopExtraSmall paddingBottomExtraSmall">
            <Breadcrumb id="breadCrumbRenewals">
                <Breadcrumb.Section
                    onClick={() => isMMRCase ? navigation('mmr-dashboard') : renewaldashboard && stepperFlag && !dashboard ? navigation('renewal-dashboard') : null}
                    active={dashboard}
                    className="textCenter"
                >
                    {renewaldashboard && stepperFlag ? <Icon name="check" color="green" /> : null}
                    {!mobile && 'Dashboard'}
                    <div className="marginTopExtraSmall">
                        <Label circular className={dashboard ? 'activeCircleRen' : renewaldashboard ? 'beenToCircleRen' : ''}>1</Label>
                    </div>
                </Breadcrumb.Section>

                <Breadcrumb.Divider className="breadCrumbDivider">{mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>

                <Breadcrumb.Section
                    onClick={() => reviewDetail && stepperFlag ? !reviewPage ? navigation('reviewDetail') : null : null}
                    active={reviewPage || renewalShopping}
                    className="textCenter"
                >
                    {reviewDetail && stepperFlag ? <Icon name="check" color="green" /> : null}
                    {!mobile && 'Review'}
                    <div className="marginTopExtraSmall">
                        <Label circular className={reviewPage || renewalShopping ? 'activeCircleRen' : reviewDetail ? 'beenToCircleRen' : ''}>2</Label>
                    </div>
                </Breadcrumb.Section>

                <Breadcrumb.Divider className="breadCrumbDivider">{mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>

                <Breadcrumb.Section
                    onClick={() => decide && stepperFlag && !decidePage ? navigation('decideDetail') : null}
                    active={decidePage}
                    className="textCenter"
                >
                    {decide && stepperFlag ? <Icon name="check" color="green" /> : null}
                    {!mobile && 'Decide'}
                    <div className="marginTopExtraSmall">
                        <Label circular className={decidePage ? 'activeCircleRen' : decide ? 'beenToCircleRen' : ''}>3</Label>
                    </div>
                </Breadcrumb.Section>

                {!isMMRCase &&
                    <>
                        <Breadcrumb.Divider className="breadCrumbDivider">{mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>
                        <Breadcrumb.Section
                            onClick={() => enrollment && stepperFlag && !enrollManagerPage && !revokeEnrollmentStep ? navigation('enrollmentManager/manager') : null}
                            active={enrollManagerPage}
                            className="textCenter">
                            {enrollment && stepperFlag && !revokeEnrollmentStep ? <Icon name="check" color="green" /> : null}
                            {!mobile && 'Enrollment'}
                            <div className="marginTopExtraSmall">
                                <Label circular className={enrollManagerPage ? 'activeCircleRen' : enrollment ? 'beenToCircleRen' : ''}>4</Label>
                            </div>
                        </Breadcrumb.Section>
                    </>
                }
                
                {!isUhcRenewal &&
                    <>
                        <Breadcrumb.Divider className="breadCrumbDivider">{mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>
                        <Breadcrumb.Section
                            onClick={() => finalize && stepperFlag && !finalizePage ? navigation('finalize') : null}
                            active={finalizePage}
                            className="textCenter"
                        >
                            {finalize && stepperFlag ? <Icon name="check" color="green" /> : null}
                            {!mobile && 'Finalize'}
                            <div className="marginTopExtraSmall">
                                <Label circular className={finalizePage ? 'activeCircleRen' : finalize ? 'beenToCircleRen' : ''}>{isMMRCase ? 4 : 5}</Label>
                            </div>
                        </Breadcrumb.Section>
                    </>
                }

                {isUhcRenewal && displayRenewalFinalizeCase &&
                    <>
                        <Breadcrumb.Divider className="breadCrumbDivider">{mobile ? '\u2014'.repeat(2) : '\u2014'.repeat(8)}</Breadcrumb.Divider>
                        <Breadcrumb.Section
                            onClick={() =>  renewalFinalizeCase ? navigation('renewal-finalize-case') : null}
                            active={renewalFinalizeCasePage}
                            className="textCenter"
                        >
                            {renewalFinalizeCase && stepperFlag ? <Icon name="check" color="green" /> : null}
                            {!mobile && 'Finalize Details'}
                            <div className="marginTopExtraSmall">
                                <Label circular className={renewalFinalizeCasePage ? 'activeCircleRen' : renewalFinalizeCase ? 'beenToCircleRen' : ''}>{isMMRCase ? 4 : 5}</Label>
                            </div>
                        </Breadcrumb.Section>
                    </>
                }
            </Breadcrumb>
        </div>
    )
}

export default RenewalStepper