export const getAppEnv = () => {
    const { hostname } = window.location;
    if (hostname.includes("localhost")) {
        return "local";
    }
    if (hostname.includes("dev")) {
        return "dev";
    }
    if (hostname.includes("test")) {
        return "test";
    }
    if (hostname.includes("uat")) {
        return "uat";
    }
    if (hostname.includes("stage")) {
        return "stage";
    }
    return "prod"
}