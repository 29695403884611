import { getAgeFromDOB } from "../formatters/numbers";
import { toMoney } from "../formatters/numbers";
import { isMmr, isUhcRenewals } from "./platformUtils";

// Find age-banded rate that applies to an employee age
export const getAgeBandedRate = (age, ageBands, employeeType) => {
    if (employeeType === "W" || (isMmr() && employeeType === "")) {
        return 0;
    }

    if (typeof age === "string") {
        age = parseInt(age.split(" ")[0]);
    }

    let lowestAgeBand = ageBands[0];
    let highestAgeBand = ageBands[ageBands.length - 1];

    if (age && age !== '') {
        if (age <= lowestAgeBand.maxAge) {
            return lowestAgeBand.rate || lowestAgeBand.total;
        } else if (age >= highestAgeBand.minAge) {
            return highestAgeBand.rate || highestAgeBand.total;
        } else {
            let ageBand = ageBands.find(ageBand => ageBand.minAge <= age && ageBand.maxAge >= age)
            return ageBand.rate ? ageBand.rate : ageBand.total
        }
    } else {
        return ageBands[0].rate || ageBands[0].total
    }
}

/**
 * 
 * @param {Array} compositeRates list of rate objects
 * @param {string} compositeTypeName composite name, e.g. "4 TIER COMPOSITE ACR"
 * @param {string} employeeType EE, EE/SP, EE/CH, EE/FAM, or W
 * @param {*} age number, or string e.g. "15 or younger"
 */
export const getCompositeRate = (compositeRates, compositeTypeName = 'default', employeeType, age) => {
    if (employeeType === "W") { // waive coverage
        return 0;
    }

    let canonicalEmployeeType;
    // TODO: We really need to make these employee type flags consistent across different services, components, etc.
    switch (employeeType) {
        case "EE":
            canonicalEmployeeType = "EMPLOYEE";
            break;
        case "EE/SP":
            canonicalEmployeeType = "EE+SP";
            break;
        case "EE/CH":
            canonicalEmployeeType = "EE+CH";
            break;
        case "EE/FAM":
            canonicalEmployeeType = "EE+FAM";
            break;
        case "EO":
            canonicalEmployeeType = "EO";
            break;
        case "ES":
            canonicalEmployeeType = "ES";
            break;
        case "EC":
            canonicalEmployeeType = "EC";
            break;
        case "EF":
            canonicalEmployeeType = "EF";
            break;
        default:
            console.log("Unexpected employee coverage type");
            canonicalEmployeeType = "W";
    }

    const coverageMapping = {
        "EE": "EO",
        "EE/SP": "ES",
        "EE/CH": "EC",
        "EE/FAM": "EF",
        "EO": "EO",
        "ES": "ES",
        "EC": "EC",
        "EF": "EF"
    }

    const coverageMappingUHC = {
        "EE": "EMPLOYEE",
        "EE/SP": "EE+SP",
        "EE/CH": "EE+CH",
        "EE/FAM": "EE+FAM",
        "EO": "EMPLOYEE",
        "ES": "EE+SP",
        "EC": "EE+CH",
        "EF": "EE+FAM"
    }

    if (compositeTypeName === "4 TIER COMPOSITE ACR") {
        if (isUhcRenewals()) {
            if (compositeRates.find((compRate) => compRate.employeeType === coverageMappingUHC[employeeType]))
                return compositeRates.find((compRate) => compRate.employeeType === coverageMappingUHC[employeeType]).rate || compositeRates.find(compRate => compRate.employeeType === canonicalEmployeeType).total
            else if (compositeRates[0]) {
                return compositeRates[0].rate || compositeRates[0].total
            }
        } else {
            if (compositeRates.find((compRate) => compRate.employeeType === canonicalEmployeeType)) {
                return compositeRates.find(compRate => compRate.employeeType === canonicalEmployeeType).rate || compositeRates.find(compRate => compRate.employeeType === canonicalEmployeeType).total
            } else if (compositeRates[0]) {
                return compositeRates[0].rate || compositeRates[0].total
            }
        }
    } else {
        if (isUhcRenewals()) {
            if (compositeRates.find((compRate) => compRate.employeeType === coverageMappingUHC[employeeType]))
            return compositeRates.find((compRate) => compRate.employeeType === coverageMappingUHC[employeeType]).rate
        }

        if (compositeRates.find((compRate) => compRate.employeeType === coverageMapping[employeeType]))
            return compositeRates.find((compRate) => compRate.employeeType === coverageMapping[employeeType]).rate

        // For now, the only other composite type we know of is in Washington State, where it is under 21 vs. over 21
        if (typeof age === "string") {
            age = parseInt(age.split(" ")[0]);
        }

        return age < 21 ? compositeRates[0].rate || compositeRates[0].total : compositeRates[1].rate || compositeRates[1].total;
    }
}

//Calculates a plan's estimated cost by comparing the ages array to their associated rates under the plan
//
//Parameters
//  rates:                  The array of rate values under the plan, either age banded or tiered
//  ages:                   The array holding the simulated employees' ages (all defaulted to 30)
//  isCirrus:               Boolean determining whether the desired system is Cirrus (1) or Prime (0)
export function calculateQuickQuotesFromRates(rates, ages, isCirrus) {
    //Array holding totals for every plan
    const quotes = [];
    //Iterate through every plan
    for (let i = 0; i < rates.length; i++) {
        //Initialize running total
        let thisRateObj = rates[i];
        let total = 0;
        //For every employee in group
        for (let j = 0; j < ages.length; j++) {
            let thisAge = ages[j];
            // Check rating method
            if (thisRateObj.isAgeBanded) {
                let theseAgeBands = thisRateObj.ageBands;
                total += getAgeBandedRate(thisAge, theseAgeBands);
            }
            else if (thisRateObj.isComposite) {
                let theseCompositeRates = thisRateObj.compositeRates;
                let thisCompositeTypeName = thisRateObj.compositeTypeName;
                total += getCompositeRate(theseCompositeRates, thisCompositeTypeName, "EMPLOYEE", thisAge);
            }
        }
        //Push running total for individual plan to quotes array, then start with next plan
        quotes.push(total);
    }
    return quotes;
}

const specialtyCoverageMap = {
    "EO": "employee",
    "ES": "empAndSpouse",
    "EC": "empAndChild",
    "EF": "empAndFamily",
    "EE": "employee",
    "EE+SP": "empAndSpouse",
    "EE+CH": "empAndChild",
    "EE+FAM": "empAndFamily",
    "EE/SP": "empAndSpouse",
    "EE/CH": "empAndChild",
    "EE/FAM": "empAndFamily",
    "employee": "employee",
    "empAndSpouse": "empAndSpouse",
    "empAndChild": "empAndChild",
    "empAndFamily": "empAndFamily",
    "W":"waive"
}

export function getSpecialtyRate(product, plan, employeeType, employee, index, isWATwoTier = false) {
    let coverageType = specialtyCoverageMap[employeeType]
    
    if(coverageType === 'waive'){
        return 0;
    }
    
    if (product === 'life') {
        // Additional logic added to handle employee classing rates
        let employeeRate = plan.finalRates.employeeRate.find(empRate => parseInt(empRate.employeeId) === parseInt(employee.memberId) || parseInt(empRate.employeeId) === index + 1 || (plan.selectedClass && parseInt(empRate.employeeId)))
        return employeeRate ? employeeRate.Total : 0
    }
    else if (product === 'dependentLife') {
        product = 'basicLife'
        if(employee.employeeStatus === 'COBRA'){
            return 0;
        }
        switch (employee.coverages[product]) {
            case "EE":
            case "W":
                return 0
            default:
                if(employee.dependents.length > 0){
                    return plan.quote && plan.quote.depLifeChildRate ? parseFloat(plan.quote.depLifeChildRate) 
                        : plan.dependentLifeRate ? parseFloat(plan.dependentLifeRate) : 0
                } else{
                    return 0
                }
        }
    }
    else {
        if(isWATwoTier) {
            switch (coverageType) {
                case 'employee':
                    return plan.finalRates ? parseFloat(plan.finalRates.employee) : plan.monthlyPremium ? parseFloat(plan.monthlyPremium.employee) : 0
                case 'empAndChild':
                case 'empAndSpouse':
                case 'empAndFamily':
                    return plan.finalRates ? parseFloat(plan.finalRates.empAndFamily) : plan.monthlyPremium ? parseFloat(plan.monthlyPremium.empAndFamily) : 0
                case "W":
                    return 0;
                default:
                    return 0;
            }
        } else {
            switch (coverageType) {
                case 'employee':
                case 'empAndSpouse':
                case 'empAndChild':
                case 'empAndFamily':
                    return plan.finalRates ? parseFloat(plan.finalRates[coverageType]) : plan.monthlyPremium ? parseFloat(plan.monthlyPremium[coverageType]) : 0
                case "W":
                    return 0;
                default:
                    return 0;
            }
        }
    }
}
const calcBasicLifeRate = (lifePlan) => {
    const totalRate = parseFloat(lifePlan.basicLifeRateVol) + parseFloat(lifePlan.aDnDLifeRateVol);
    const totalBasicLifeVolume = lifePlan.BasicLifeTotVolAmount;
    const totalMonthlyPremium = parseFloat(totalRate) * parseFloat(totalBasicLifeVolume) / 1000;
    return totalMonthlyPremium
}
export function getSpecialtyRateFinalizedRenewals(product, rates, employeeType, lifePlan) {
    let coverageType = specialtyCoverageMap[employeeType]

    if (product === 'life') {
        return rates ? calcBasicLifeRate(lifePlan) : 0
    }
    else {
        switch (employeeType) {
            case 'EE':
                return rates ? parseFloat(rates[coverageType]) : 0
            case 'EE+SP':
            case 'EE/SP':
                return rates ? parseFloat(rates[coverageType]) : 0
            case 'EE+CH':
            case 'EE/CH':
                return rates ? parseFloat(rates[coverageType]) : 0
            case 'EE+FAM':
            case 'EE/FAM':
                return rates ? parseFloat(rates[coverageType]) : 0
            default:
                return 0;
        }
    }
}

export function getMedicalCompositeRateFinalizedRenewals(compositeRates, coverage) {
    let premium = 0
    const medicalCoverageMap = {
        "EO": "EO",
        "EE": "EO",
        "EE+SP": "ES",
        "EE+CH": "EC",
        "EE+FAM": "EF",
        "EE/SP": "ES",
        "EE/CH": "EC",
        "EE/FAM": "EF",
    }
    premium = compositeRates.find(rate => rate.employeeType === medicalCoverageMap[coverage]) ? compositeRates.find(rate => rate.employeeType === medicalCoverageMap[coverage]).rate : 0

    return parseFloat(premium) || 0
}



export function upToThreeDependentsUnder21(employees, effectiveDate) {

    const result  = employees.length > 0 && employees.map((employee) => {
        let under21Count = 0;
        const threeDependentsUnder21 = [];

        if((!employee.age || employee.age==='') && employee.dob){
            employee.age = getAgeFromDOB(employee.dob, effectiveDate)
        }

        employee.dependents.map(dep=>{
            if((!dep.age || dep.age==='') && dep.dob){
                dep.age = getAgeFromDOB(dep.dob, effectiveDate)
            }
        })

        employee.dependents.sort((a, b) => parseInt(b.age) - parseInt(a.age)).forEach((dep) => {
            if (parseInt(dep.age) < 21) {
                if (under21Count < 3) {
                    threeDependentsUnder21.push(dep);
                    under21Count += 1;
                }
            } else {
                threeDependentsUnder21.push(dep);
            }
        });
        return { ...employee, dependents: threeDependentsUnder21 };
    })

    return result ? result : [] ;
}