import React from 'react';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';

const PersistGateWrapper = (props) => {
    const { persistor, children } = props;
    if (persistor === null) {
        return children;
    }
    return (
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    );
};

PersistGateWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    persistor: PropTypes.shape({}), // persistor is from the redux-persist NPM library
};

PersistGateWrapper.defaultProps = {
    persistor: null,
};

export default PersistGateWrapper;
