import apiRoutes from './apiRouteConfig';
import { axios } from './axiosConfig';

/**
 * Generic send email to trigger a mail to the given reciepients with the subject and msg.
 * @param {*} recipientEmails Recipient email id(s) (separted by comma) to whom mail to be triggered.
 * @param {*} subject Subject of the Email
 * @param {*} msg Message to be send as Email Body.
 */
export const sendEmail = (subject, msg, recipientEmails, from) => new Promise((resolve, reject) => {
    const payload = {
        from: from,
        to: recipientEmails,
        subject,
        msg,
    };

    axios.post(`${apiRoutes.email}/emails/sendGenericEmail`, payload, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(
        (result) => {
            resolve(result);
        },
        (error) => {
            reject(error);
        },
    );
});