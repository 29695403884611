export function isWATwoTierSelected(overall, quotes) {
    if(!overall || !quotes)
        return false
    const { quickQuoteRequest } = overall;
    const { zipObj: { stateCode } } = quickQuoteRequest;
    const { otherDetails: { selectedProducts }} = quickQuoteRequest;
    const { filterCriteria: { ratingMethod }} = quotes;

    return stateCode === 'WA' && selectedProducts && selectedProducts.includes('medical') && ((ratingMethod.tier || ratingMethod.age) ? ratingMethod.tier : true)
}

export const isWATwoTierSpecialtyForRenewals = (tierStructreId, stateCode) => {
    return (tierStructreId === 'B' || tierStructreId === '6' || tierStructreId === '7') && stateCode === 'WA' ? true : false;
}

export const isPlanTwoTiered = (tierStructreId) => {
    return tierStructreId === 'B' || tierStructreId === '6' || tierStructreId === '7';
}

export const isPlanThreeTiered = (tierStructreId) => {
    return tierStructreId === 'C' || tierStructreId === '3' || tierStructreId === '9';
}