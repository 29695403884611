import * as types from '../../actions/actionTypes';
import { handleRequestPlans, handleReceiveSpecialtyPlans, handleRequestRates, handleReceiveSpecialtyRates, handleReceiveSpecialtyAllPlans, handleReceiveSpecialtyPlanRates, handleReceiveSpecialtySelectedPlanRates, resetDetails,
    handleRequestPlansRenewals, handleReceiveSpecialtyPlansRenewals, handleReceiveSpecialtyAllPlansRenewals } from './helpers/fetchingReducerHelpers';
import { handleUpdatePlan, handleUpdateAllPlans, handleChangeFilterCriteria, handleChangeSortOrder, handleResetFilterCriteria, handleTogglePreferredPlans, handleEmployeeClassFilter } from './helpers/filteringReducerHelpers';
import { handleAdjustRatesAndPremium } from './helpers/rateAdjustmentHelper';
import { initialFilterCriteria, initialState, initialfilterTotalsCache } from './consts/lifeReducerConsts';
import { setAdvanceFilterData, setAdvanceFilterWith30Plans, handleFilterChange, viewFilteredPlans, resetFitlers, setBasicFilterDirectly, setBasicFilterDirectlyUhc } from './helpers/advanceFilterHelper'

export default function life(state = initialState, action) {

    switch (action.type) {
        case types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state, action, initialFilterCriteria, initialFilterCriteria);
        case types.SERVICE.LIFE.REQUEST_RENEWAL_PLANS:
        return handleRequestPlansRenewals(state, initialFilterCriteria, initialFilterCriteria, action.fullProps, 'life');

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action);
        case types.SERVICE.LIFE.RECEIVE_RENEWAL_PLANS:
            return handleReceiveSpecialtyPlansRenewals(state, action.payload.plans, action.fullProps, 'life');
        case types.SERVICE.LIFE.SET_RENEWAL_PLANS_RECEIVED:
            return {
                ...state,
                isFetchingPlans: false,
                [`renewallifeReceived`]: true
            }

        case types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_RATES:
        case types.SERVICE.LIFE.REQUEST_RENEWAL_RATES:
            return handleRequestRates(state);

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_RATES:
        case types.SERVICE.LIFE.RECEIVE_RENEWAL_RATES:
            return handleReceiveSpecialtyRates(state, action);

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_PLAN_RATES:
            return handleReceiveSpecialtyPlanRates(state, action);

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES:
            return handleReceiveSpecialtySelectedPlanRates(state, action);

        case types.SERVICE.LIFE.UPDATE_PLAN:
            return handleUpdatePlan(state, action);

        case types.SERVICE.LIFE.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action);

        case types.UPDATE_LIFE_PLANS_CLASS_FILTER:
            return handleEmployeeClassFilter(state, action)

        case types.SERVICE.LIFE.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.SERVICE.LIFE.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        case types.SERVICE.LIFE.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.LIFE.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_ALL_PLANS:
            return handleReceiveSpecialtyAllPlans(state, action.payload);

        case types.SERVICE.LIFE.RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS:
            return handleReceiveSpecialtyAllPlansRenewals(state, action.payload, action.fullProps, 'life');

        case types.SERVICE.LIFE.SET_ADVANCE_FILTER_DATA:
            return setAdvanceFilterData(state, state.allPlans);

        case types.SERVICE.LIFE.SET_ADVANCE_FILTER_30_PLANS:
            return setAdvanceFilterWith30Plans(state, state.allPlans);            

        case types.SERVICE.LIFE.APPLY_FILTER:
            return handleFilterChange(state, action.payload);

        case types.SERVICE.LIFE.VIEW_FILTERED_PLANS:
            return viewFilteredPlans(state, action.payload);

        case types.SERVICE.LIFE.RESET_FILTERS:
            return resetFitlers(state);

        case types.SERVICE.LIFE.SET_BASIC_FILTERS_DIRECTLY:
            return setBasicFilterDirectly(state);

        case types.SERVICE.LIFE.SET_BASIC_FILTERS_DIRECTLY_UHC:
            return setBasicFilterDirectlyUhc(state, action.payload);

        case types.SERVICE.LIFE.RESET_DATA:
            return resetDetails(state, initialFilterCriteria, initialfilterTotalsCache);

        case types.SERVICE.LIFE.ADJUST_RATE:
            return handleAdjustRatesAndPremium(state, action);

        case types.SET_LIFE_CLASS:
            return {
                ...state,
                employeeClassing: {
                    ...state.employeeClassing,
                    selectedClass: action.lifeClass
                }
            }
        case types.SET_LIFE_CLASS_RATES_FETCHED:
            return {
                ...state,
                classRatesFetched: true
            }
        case types.SET_IS_FETCHING_CLASS_RATES:
            return {
                ...state,
                isFetchingClassRates: action.bool
            }
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
        case types.RESET_SHOPPING_FLOW_SPECIALTY:
            return initialState;

        case types.RESET_RENEWAL_FLOW:
            return {
                ...state,
                selectedPlans:[]
            }
        case types.RESET_FLOW_DVL_REVIEW_PAGE:
            let newVisiblePlans = (state.visiblePlans || []).map(plan=>{
                const item = {
                    ...plan,
                    selected : false
                }
                return item;
            })

            return {
                ...state,
                selectedPlans:[],
                selectedPlansMap:{
                    Single:[]
                },
                visiblePlans:newVisiblePlans
            }

        case types.UPDATE_BASIC_CLASSES:
            return {
              ...state,
              employeeClassing : {
                classingOpted: (action.payload.length > 0),
                classes: action.payload,
                selectedClass: action.selectedClass,
              }
            }

        case types.CLEAR_SELECTED_CLASSES:
            return {
              ...state,
              employeeClassing : {
                classingOpted: false,
                classes: [],
                selectedClass: "",
              }
            }

        case types.ADD_CLASSING_OPTION:
            return {
              ...state,
              classingOptions: [action.payload, ...state.classingOptions]
            }

        case types.SET_EMPLOYEE_SELECTED_CLASSES:
            return {
                ...state,
                employeeClassing: {
                    ...state.employeeClassing,
                    employeeSelectedClasses: action.payload
                }
            }

        case types.UPDATE_SELECTED_LIFE_PLAN:
            return {
                ...state,
                selectedPlans: action.payload,
                selectedPlansMap: {
                    Single: action.payload,
                },
            };
        case types.SERVICE.LIFE.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };
        case types.USER_FAVORITE_LIFE_PLANS:
            const { allPlans, plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                allPlans,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        case types.SET_DEPENDENT_LIFE_COMPONENTS:
            return {
                ...state,
                showDependentLifeComponents: action.showDependentLifeComponents
            }
        case types.SET_SUPP_LIFE_COMPONENTS:
            return {
                ...state,
                showSupplementalLifeComponents: action.showSupplementalLifeComponents
            }
        case types.SET_DEPENDENT_SUPPLIFE_COMPONENTS:
            return {
                ...state,
                showSuppDependentLifeComponents: action.showSuppDependentLifeComponents
            }        
            
        default:
            return state
    }
}
