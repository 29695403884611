import * as types from '../../actions/actionTypes';

const initialState = {
    isFetchingCaseSummaryData: false,
    finalizeCaseSummary: {},
    renewalFinalizeFlag: true
};

export default function finalizeCaseSummary(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_FINALIZE_CASE_SUMMARY:
            return {
                ...state,
                isFetchingCaseSummaryData: true,
            };

        case types.RECEIVE_FINALIZE_CASE_SUMMARY:
            // Preserve existing NICE stage statuses for DUAL cases 
            const niceDualStages = [];
            if (state.finalizeCaseSummary && state.finalizeCaseSummary.summaryData) {
                state.finalizeCaseSummary.summaryData.forEach(stage => {
                    if (stage.order > 100) {
                        niceDualStages.push(stage);
                    }
                })
            }

            // Preserve existing PRIME or CIRRUS statuses - when storing CIRRUS, preserve PRIME and vice versa
            const primeCirrusDualStages = [];
            if (state.finalizeCaseSummary && state.finalizeCaseSummary.summaryData) {
                state.finalizeCaseSummary.summaryData.forEach(stage => {
                    // When cirrus is been updated store only PRIME stage status
                    if (action.platform === 'CIRRUS' && stage.platform && stage.platform === "PRIME") {
                        primeCirrusDualStages.push(stage);
                    }
                    // When prime is been updated store only CIRRUS stage status
                    if (action.platform === 'PRIME' && stage.platform && stage.platform === "CIRRUS") {
                        primeCirrusDualStages.push(stage);
                    }
                })
            }


            // Update DUAL cases with PRIME stage statuses
            let updatedPrimeOrCirrusSummary = action.payload;
            updatedPrimeOrCirrusSummary.summaryData = updatedPrimeOrCirrusSummary.summaryData.concat(niceDualStages);
            updatedPrimeOrCirrusSummary.summaryData = updatedPrimeOrCirrusSummary.summaryData.concat(primeCirrusDualStages);

            return {
                ...state,
                finalizeCaseSummary: updatedPrimeOrCirrusSummary,
                isFetchingCaseSummaryData: false,
            };

        case types.RESET_TO_INITIALSTATE:
            return initialState;

        case types.RESET_FINALIZE_FLAG:
            return {
                ...state,
                renewalFinalizeFlag: action.payload
            }

        case types.SET_NICE_CASE_SUMMARY:
            // Preserve existing PRIME & CIRRUS stage  statuses for DUAL cases
            const cirrusPrimeDualStages = [];
            if (state.finalizeCaseSummary && state.finalizeCaseSummary.summaryData) {
                state.finalizeCaseSummary.summaryData.forEach(stage => {
                    if (stage.order < 100 ) {
                        cirrusPrimeDualStages.push(stage);
                    }
                })
            }

            // Update DUAL cases with NICE stage statuses
            let updatedNiceSummary = action.payload;
            updatedNiceSummary = updatedNiceSummary.concat(cirrusPrimeDualStages);

            return {
                ...state,
                finalizeCaseSummary: {
                    ...state.finalizeCaseSummary,
                    summaryData: updatedNiceSummary
                },
                isFetchingCaseSummaryData: false
            }

        default:
            return state;
    }
}