import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Grid, Card, Checkbox, Accordion } from 'semantic-ui-react';
import UsersIcon from '../../../../images/icon-users.svg'
import './reviewCards.css';
import { updateOARenewalsPage } from '../../../../actions/reviewCardsAction';
import { useLocation } from 'react-router-dom';
import { shouldDisableActionsImpactCase } from '../../../../actions/caseTrackAction';
import { inClosedWithdrawn, inSubmittedStatus } from '../../../../reducers/products/helpers/companyProfileConsts';

const ReviewCardOwnerAssignmentSide = (props) => {
    const dispatch = useDispatch();
    const { history } = props
    const store = useSelector((state) => state);

    const renewalsFlow = useSelector((state) => state.overall.isRenewals)
    const dataToDisplay = useSelector((state) => state.reviewCards.ownerAssignment)
    const producers = useSelector((state) => state.reviewCards.ownerAssignment.producer);
    const agencies = useSelector((state) => state.reviewCards.ownerAssignment.agency);
    const generalAgents = useSelector((state) => state.reviewCards.ownerAssignment.generalAgent);
    const salesReps = useSelector((state) => state.reviewCards.ownerAssignment.salesRep);
    const gaSalesReps = useSelector((state) => state.reviewCards.ownerAssignment.gaSalesRep);
    const directSale = useSelector((state) => state.reviewCards.directSale);
    const fullProps = useSelector(state => state)

    const caseProgressofNB = useSelector((state) => state.companyProfile.caseProgress);
    let renewalPlan;
    if (store.renewals.globalSearchFlag) {
        renewalPlan = renewalsFlow === true ? store.renewals.renewalSearchCase : null;
    } else {
        renewalPlan = renewalsFlow === true ? store.renewals && store.renewals.renewals && store.renewals.renewals.find(plan => plan.memberGroupId === store.renewals.memberGroupId) : null
    }
    let actualCaseProgress = renewalsFlow === true && renewalPlan ? renewalPlan.caseProgressUI : null
    const caseProgress = renewalsFlow === true ? actualCaseProgress ? actualCaseProgress : 'Not Started' : caseProgressofNB;

    const disableNB = (inSubmittedStatus(caseProgressofNB) || inClosedWithdrawn(fullProps))

    let [activeAccordionIndexofProducers, setActiveAccordionIndexofProducers] = useState('');
    let [activeAccordionIndexofSalesRep, setActiveAccordionIndexofSalesRep] = useState('');
    let [activeAccordionIndexofGASalesRep, setActiveAccordionIndexofGASalesRep] = useState('');
    let [activeAccordionIndexofAgencies, setActiveAccordionIndexofAgencies] = useState('');
    let [activeAccordionIndexofGeneralAgent, setActiveAccordionIndexofGeneralAgent] = useState('');
    let [isCollapsed, setIsCollapsed] = useState(true);

    const readOnly = (props && (props.isSubmitted || inClosedWithdrawn(fullProps)) && renewalsFlow) ? (props.isSubmitted || inClosedWithdrawn(fullProps)) : false;

    const [actionsToBeDisabled, setActionsToBeDisabled] = useState({
        editOwners: false
    });

    const { pathname } = useLocation();
    const page = pathname.split('/enrollmentManager/')[1];

    const validateEditOwnerAccess = () => {
        if (dispatch(shouldDisableActionsImpactCase())) {
            actionsToBeDisabled.editOwners = true;
        }

        setActionsToBeDisabled({
            ...actionsToBeDisabled
        });
    }


    useEffect(() => {
        validateEditOwnerAccess();
    }, []);

    useEffect(() => {
        validateEditOwnerAccess();
    }, [page, caseProgress]);

    const handleAccordionClickofProducers = () => {
        activeAccordionIndexofProducers === 0 ? setActiveAccordionIndexofProducers(activeAccordionIndexofProducers = '') : setActiveAccordionIndexofProducers(activeAccordionIndexofProducers = 0);
    }
    const handleAccordionClickofSalesRep = () => {
        activeAccordionIndexofSalesRep === 0 ? setActiveAccordionIndexofSalesRep(activeAccordionIndexofSalesRep = '') : setActiveAccordionIndexofSalesRep(activeAccordionIndexofSalesRep = 0);
    }
    const handleAccordionClickofGASalesRep = () => {
        activeAccordionIndexofGASalesRep === 0 ? setActiveAccordionIndexofGASalesRep(activeAccordionIndexofGASalesRep = '') : setActiveAccordionIndexofGASalesRep(activeAccordionIndexofGASalesRep = 0);
    }
    const handleAccordionClickofAgencies = () => {
        activeAccordionIndexofAgencies === 0 ? setActiveAccordionIndexofAgencies(activeAccordionIndexofAgencies = '') : setActiveAccordionIndexofAgencies(activeAccordionIndexofAgencies = 0);
    }
    const handleAccordionClickofGeneralAgent = () => {
        activeAccordionIndexofGeneralAgent === 0 ? setActiveAccordionIndexofGeneralAgent(activeAccordionIndexofGeneralAgent = '') : setActiveAccordionIndexofGeneralAgent(activeAccordionIndexofGeneralAgent = 0);
    }

    const additionalProducers = () => {
        const producerLengths = [producers.length];

        const additionalProducers = [];
        for (let i = 0; i < producerLengths; i++) {
            additionalProducers.push(
                <div className="ownerAccordionContent flexRow">
                    <span className="reviewCardItem borderRight marginRightMedium producerItem"> {i + 1}: {producers[i] ? producers[i].producerName : '-'} </span>
                </div>
            )
        }
        return additionalProducers;
    }

    const additionalAgencies = () => {
        const agenciesLengths = [agencies.length];

        const additionalAgencies = [];
        for (let i = 0; i < agenciesLengths; i++) {
            additionalAgencies.push(
                <div className="ownerAccordionContent flexRow">
                    <span className="reviewCardItem borderRight marginRightMedium producerItem"> {i + 1}:   {agencies[i] ? agencies[i].agencyName : '-'} </span>
                </div>
            )
        }
        return additionalAgencies;
    }

    const additionalGeneralAgent = () => {
        const generalAgentLengths = [generalAgents.length];

        const additionalgeneralAgent = [];
        for (let i = 0; i < generalAgentLengths; i++) {
            additionalgeneralAgent.push(
                <div className="ownerAccordionContent flexRow">
                    <span className="reviewCardItem borderRight marginRightMedium producerItem">{i + 1}:  {generalAgents[i] ? generalAgents[i].generalAgentName : '-'} </span>
                </div>
            )
        }
        return additionalgeneralAgent;
    }

    const additionalSalesRep = () => {
        const salesRepLengths = [salesReps.length];

        const additionalsalesRep = [];
        for (let i = 0; i < salesRepLengths; i++) {
            additionalsalesRep.push(
                <div className="ownerAccordionContent flexRow">
                    <span className="reviewCardItem borderRight marginRightMedium producerItem"> {i + 1}: {salesReps[i] ? salesReps[i].salesRepName : '-'}  </span>
                </div>
            )
        }
        return additionalsalesRep;
    }

    const additionalGASalesRep = () => {
        const gaSalesRepLengths = [gaSalesReps.length];

        const additionalGASalesRep = [];
        for (let i = 0; i < gaSalesRepLengths; i++) {
            additionalGASalesRep.push(
                <div className="ownerAccordionContent flexRow">
                    <span className="reviewCardItem borderRight marginRightMedium producerItem"> {i + 1}: {gaSalesReps[i] ? gaSalesReps[i].salesRepName : '-'}  </span>
                </div>
            )
        }
        return additionalGASalesRep;
    }

    const expandCollapseOption = (
        (!isCollapsed) ?
            <b onClick={() => setIsCollapsed(!isCollapsed)} className="collapseOption backgroundColorBlue whiteFont">
                <Icon className="caret up whiteFont noMarginRight" />
            </b>
            :
            <b onClick={() => setIsCollapsed(!isCollapsed)} className="expandOption backgroundColorBlue whiteFont">
                <Icon className="caret down whiteFont noMarginRight" />
            </b>
    )

    const handleEditOwnerClick = () => {
        if (renewalsFlow) {
            const pathParts = props.pathname.split('/');
            const lastPart = pathParts[pathParts.length - 1];
            if (props.pathname.includes('/enrollmentManager/employees-overview') || props.pathname.includes('/enrollmentManager/employee-info'))
                dispatch(updateOARenewalsPage('renewalEmployeeEnrollment'))
            else if (props.pathname.includes('/enrollmentManager/employer-info'))
                dispatch(updateOARenewalsPage('renewalEmployerInfo'))
            else if (props.pathname.includes('/enrollmentManager/required-documents') || props.pathname.includes('/enrollmentManager/employerCertification'))
                dispatch(updateOARenewalsPage('renewalRequiredDocuments'))
            else
                dispatch(updateOARenewalsPage('renewalEnrollment'))
        }

        history.push('/owner-assignment');
    }

    return (
        <Card className="sidebarReviewCard">
            <Card.Content>
                <Grid>
                    <Grid.Row>
                        <img src={UsersIcon} className="smallIcon marginLeftMedium marginRightMedium" alt="Icon"/>
                        <span className="blueFont paddingTopMedium">
                            <strong>Owner Assignment</strong>
                        </span>
                    </Grid.Row>
                    {
                        !isCollapsed
                            ? null
                            : (
                                <Grid.Row className="noPaddingTop paddingBottomSmall">
                                    {/* <img src={UsersIcon} className="smallIcon marginLeftMedium marginRightMedium" /> */}
                                    <span className="actionFont marginLeftMedium marginRightMedium" onClick={() => setIsCollapsed(!isCollapsed)}>
                                        <strong>Collapsed</strong>
                                    </span>
                                </Grid.Row>
                            )
                    }
                    {
                        !isCollapsed
                            ? (
                                <>
                                    {/* PRODUCER */}
                                    <Grid.Row className="noPaddingTop paddingBottomSmall">
                                        <Grid.Column className="paddingRightSmall " width="16">
                                            <Accordion>
                                                <Accordion.Title
                                                    active={activeAccordionIndexofProducers === 0}
                                                    index={0}
                                                    onClick={() => handleAccordionClickofProducers()}
                                                    className="multipleOwnerTitle">
                                                    <span className="fontSize13 displayInline marginRightExtraLarge"><strong> Producer: </strong></span>
                                                    <span className="fontSize13 displayInline marginLeftLarge blueFont">
                                                        <strong>
                                                            {
                                                                dataToDisplay.producer.length !== 0
                                                                    ? dataToDisplay.producer.length == 1
                                                                        ? producers[0]
                                                                            ? producers[0].producerName
                                                                            : '-'
                                                                        : dataToDisplay.producer.length
                                                                    : '-'
                                                            }
                                                        </strong>
                                                    </span>
                                                    {
                                                        dataToDisplay.producer.length > 1
                                                            ? <Icon name='dropdown' />
                                                            : null
                                                    }
                                                </Accordion.Title>
                                                {
                                                    dataToDisplay.producer.length !== 0 && dataToDisplay.producer.length !== 1
                                                        ? (
                                                            <Accordion.Content active={activeAccordionIndexofProducers === 0}>
                                                                <ol>
                                                                    {additionalProducers()}
                                                                </ol>
                                                            </Accordion.Content>)
                                                        : null
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {/* AGENCY */}
                                    <Grid.Row className="noPaddingTop paddingBottomSmall">
                                        <Grid.Column className="paddingRightSmall " width="16">
                                            <Accordion>
                                                <Accordion.Title
                                                    active={activeAccordionIndexofAgencies === 0}
                                                    index={0}
                                                    onClick={() => handleAccordionClickofAgencies()}
                                                    className="multipleOwnerTitle">
                                                    <span className="fontSize13 displayInline marginRightExtraLarge"><strong> Agencies: </strong></span>
                                                    <span className="fontSize13 displayInline marginLeftLarge blueFont">
                                                        <strong>
                                                            {
                                                                dataToDisplay.agency.length !== 0
                                                                    ? dataToDisplay.agency.length === 1
                                                                        ? agencies[0]
                                                                            ? agencies[0].agencyName
                                                                            : '-'
                                                                        : dataToDisplay.agency.length
                                                                    : '-'
                                                            }
                                                        </strong>
                                                    </span>
                                                    {
                                                        dataToDisplay.agency.length > 1
                                                            ? <Icon name='dropdown' />
                                                            : null
                                                    }
                                                </Accordion.Title>
                                                {
                                                    dataToDisplay.agency.length !== 0 && dataToDisplay.agency.length !== 1
                                                        ? (
                                                            <Accordion.Content active={activeAccordionIndexofAgencies === 0}>
                                                                <ol>
                                                                    {additionalAgencies()}
                                                                </ol>
                                                            </Accordion.Content>)
                                                        : null
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {/* SALES REP */}
                                    <Grid.Row className="noPaddingTop paddingBottomSmall">
                                        <Grid.Column className="paddingRightSmall " width="16">
                                            <Accordion>
                                                <Accordion.Title
                                                    active={activeAccordionIndexofSalesRep === 0}
                                                    index={0}
                                                    onClick={() => handleAccordionClickofSalesRep()}
                                                    className="multipleOwnerTitle">
                                                    <span className="fontSize13 displayInline marginRightExtraLarge"><strong> Sales Rep: </strong></span>
                                                    <span className="fontSize13 displayInline marginLeftLarge blueFont">
                                                        <strong>
                                                            {
                                                                dataToDisplay.salesRep.length !== 0
                                                                    ? dataToDisplay.salesRep.length === 1
                                                                        ? salesReps[0]
                                                                            ? salesReps[0].salesRepName
                                                                            : '-'
                                                                        : dataToDisplay.salesRep.length
                                                                    : '-'
                                                            }
                                                        </strong>
                                                    </span>
                                                    {
                                                        dataToDisplay.salesRep.length > 1
                                                            ? <Icon name='dropdown' />
                                                            : null
                                                    }
                                                </Accordion.Title>
                                                {
                                                    dataToDisplay.salesRep.length !== 0 && dataToDisplay.salesRep.length !== 1
                                                        ? (
                                                            <Accordion.Content active={activeAccordionIndexofSalesRep === 0}>
                                                                <ol>
                                                                    {additionalSalesRep()}
                                                                </ol>

                                                            </Accordion.Content>)
                                                        : null
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>

                                    {/* GENERAL AGENT */}
                                    <Grid.Row className="noPaddingTop paddingBottomSmall">
                                        <Grid.Column className="paddingRightSmall " width="16">
                                            <Accordion>
                                                <Accordion.Title
                                                    active={activeAccordionIndexofGeneralAgent === 0}
                                                    index={0}
                                                    onClick={() => handleAccordionClickofGeneralAgent()}
                                                    className="multipleOwnerTitle">
                                                    <span className="fontSize13 displayInline marginRightExtraLarge"><strong> General Agent: </strong></span>
                                                    <span className="fontSize13 displayInline marginLeftLarge blueFont">
                                                        <strong>
                                                            {
                                                                dataToDisplay.generalAgent.length !== 0
                                                                    ? dataToDisplay.generalAgent.length === 1
                                                                        ? generalAgents[0]
                                                                            ? generalAgents[0].generalAgentName
                                                                            : '-'
                                                                        : dataToDisplay.generalAgent.length
                                                                    : '-'
                                                            }
                                                        </strong>
                                                    </span>
                                                    {
                                                        dataToDisplay.generalAgent.length > 1
                                                            ? <Icon name='dropdown' />
                                                            : null
                                                    }
                                                </Accordion.Title>
                                                {
                                                    dataToDisplay.generalAgent.length !== 0 && dataToDisplay.generalAgent.length !== 1
                                                        ? (
                                                            <Accordion.Content active={activeAccordionIndexofGeneralAgent === 0}>
                                                                <ol>
                                                                    {additionalGeneralAgent()}
                                                                </ol>
                                                            </Accordion.Content>)
                                                        : null
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* GA SALES REP */}
                                    <Grid.Row className="noPaddingTop paddingBottomSmall">
                                        <Grid.Column className="paddingRightSmall " width="16">
                                            <Accordion>
                                                <Accordion.Title
                                                    active={activeAccordionIndexofGASalesRep === 0}
                                                    index={0}
                                                    onClick={() => handleAccordionClickofGASalesRep()}
                                                    className="multipleOwnerTitle">
                                                    <span className="fontSize13 displayInline marginRightExtraLarge"><strong> GA Sales Rep: </strong></span>
                                                    <span className="fontSize13 displayInline marginLeftLarge blueFont"><strong>
                                                        {
                                                            dataToDisplay.gaSalesRep.length !== 0
                                                                ? dataToDisplay.gaSalesRep.length === 1
                                                                    ? gaSalesReps[0]
                                                                        ? gaSalesReps[0].salesRepName
                                                                        : '-'
                                                                    : dataToDisplay.gaSalesRep.length
                                                                : '-'
                                                        }
                                                    </strong>
                                                    </span>
                                                    {
                                                        dataToDisplay.gaSalesRep.length > 1
                                                            ? <Icon name='dropdown' />
                                                            : null
                                                    }
                                                </Accordion.Title>
                                                {
                                                    dataToDisplay.gaSalesRep.length !== 0 && dataToDisplay.gaSalesRep.length !== 1
                                                        ? (
                                                            <Accordion.Content active={activeAccordionIndexofGASalesRep === 0}>
                                                                <ol>
                                                                    {additionalGASalesRep()}
                                                                </ol>
                                                            </Accordion.Content>
                                                        )
                                                        : null
                                                }
                                            </Accordion>
                                        </Grid.Column>
                                    </Grid.Row>
                                </>
                            ) : null
                    }

                    {/* LAST ROW */}
                    <Grid.Row columns="2" className="noPaddingTop paddingBottomSmall">
                        {
                            !renewalsFlow
                            && (
                                <Grid.Column className="noPaddingRight" width="9">
                                    <Checkbox disabled checked={directSale} label="Direct Sale" />
                                </Grid.Column>
                            )
                        }
                        <Grid.Column className="noPaddingLeft" width="7">
                            {
                                props.editPermissionsOwnerAssign && !readOnly
                                && (
                                    <div disabled={(renewalsFlow && actionsToBeDisabled.editOwners) || (!renewalsFlow && disableNB)}>
                                        <h5 className="noMarginTop actionFont paddingTopExtraSmall cursorPointer floatRight"
                                            // onClick={() => history.push('/owner-assignment')}
                                            onClick={() => handleEditOwnerClick()}>
                                            <Icon className="actionFont cursorPointer" name="edit" />
                                            Edit
                                        </h5>
                                    </div>
                                )
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered className="paddingBottomSmall justifyCenter alignCenter marginBottomSmall">
                        {expandCollapseOption}
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
};

ReviewCardOwnerAssignmentSide.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};
export default ReviewCardOwnerAssignmentSide;
