import * as types from '../../actions/actionTypes';

const initialState = {
    mainDashboard: {},
    fullShoppingDashboard: {},
    renewalDashboard: {},
    reportingDashboard: {},
    memberMaintenanceDashboard: {},
    renewalReviewAndConsider: {},
    renewalShopping: {},
    renewalDecide: {},
    renewalEnrollment: {},
    fullShoppingCompanyInfo: {},
    fullShoppingEmployeeCensus: {},
    fullShoppingPlanSelection: {},
    fullShoppingQuoteReview: {},
    fullShoppingEnrollment: {},
    quickQuoteStartAQuote: {},
    quickQuotePlanSelection: {}
}

export default function permissions(state = initialState, action) {

    switch (action.type) {
        case types.REQUEST_PERMISSIONS:
            return {
                ...state,
                isFetchingPermissions: true,
                permissionsFetchError: false
            };
        case types.RECEIVE_PERMISSIONS:
            return {
                ...initialState,
                isFetchingPermissions: false,
                ...action.permissions
            };
        case types.ERROR_PERMISSIONS:
            return {
                ...state,
                isFetchingPermissions: false,
                permissionsFetchError: true
            };

        default:
            return state
    }


}