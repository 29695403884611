import {
    REQUEST_PROPOSAL,
    RECEIVE_PROPOSAL_TRANSACTION_ID,
    ERROR_PROPOSAL_TRANSACTION_ID,
    RECEIVE_PROPOSAL,
    ERROR_PROPOSAL,
    REQUEST_EMAIL,
    RECEIVE_EMAIL_TRANSACTION_ID,
    ERROR_EMAIL_TRANSACTION_ID,
    RECEIVE_EMAIL,
    ERROR_EMAIL,
    RESET_TO_INITIALSTATE, RESET_RENEWALS, RESET_FLOW 
} from '../../actions/actionTypes';

const initialState = {
    isGenerating: false,
    generateError: null,
    transactionID: null,

    isSendingEmail: false,
    emailSendError: null,
    emailTransactionID: null,
};

export default function user(state = initialState, action) {
    switch (action.type) {
        // Proposal (PDF, Excel) logic
        case REQUEST_PROPOSAL:
            return {
                ...state,
                isGenerating: true,
                generateError: null,
            };
        case RECEIVE_PROPOSAL_TRANSACTION_ID:
            return {
                ...state,
                transactionID: action.payload,
            };
        case ERROR_PROPOSAL_TRANSACTION_ID:
            return {
                ...state,
                isGenerating: false,
                generateError: action.error,
            };
        case RECEIVE_PROPOSAL:
            return {
                ...state,
                isGenerating: false,
                transactionID: null,
            };
        case ERROR_PROPOSAL:
            return {
                ...state,
                isGenerating: false,
                generateError: action.error,
                transactionID: null,
            };


        // Email logic
        case REQUEST_EMAIL:
            return {
                ...state,
                isSendingEmail: true,
                emailSendError: null,
            };
        case RECEIVE_EMAIL_TRANSACTION_ID:
            return {
                ...state,
                emailTransactionID: action.payload,
            };
        case ERROR_EMAIL_TRANSACTION_ID:
            return {
                ...state,
                isSendingEmail: false,
                emailSendError: action.error,
            };
        case RECEIVE_EMAIL:
            return {
                ...state,
                isSendingEmail: false,
                emailTransactionID: null,
            };
        case ERROR_EMAIL:
            return {
                ...state,
                isSendingEmail: false,
                emailSendError: action.error,
                emailTransactionID: null,
            };
        case RESET_TO_INITIALSTATE:
        case RESET_RENEWALS:
        case RESET_FLOW:
            return initialState
        default:
            return state;
    }
}
