import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { isAuthed } from '../../../utils/jwt/authEnv';
import { AppStateContext } from '../../../context/AppStateContext';
import SessionTimeout from '../../../components/shared/IdleTimer/SessionTimeout';
import Expired from '../../../components/messages/Expired';
const { useSelector } = require('react-redux');

// If the user is not authorized, we redirect them without letting them see the protected route
const PrivateRoute = ({ component: Component, ...rest }) => {
    //const authorized = isAuthed();
    const  authenticated =  sessionStorage.getItem('dn-sm-session') === "true" ? true : false;

    //Manage isAuthenticated setting it based on valid tocken and setting it back to AppContext 
    //let isAuthenticated = false;
    
    const token = useSelector(state => state.overall.userToken);
    if (token) {
       // isAuthenticated = true;
    }
    //Global user logged in timer to trigger Heart bral url after 25 mins
    const { isAuthenticated } = React.useContext(AppStateContext);

    window.dispatchEvent(new CustomEvent("navigationhandler"));
    return (
        <Route {...rest} render={(props) => (
            authenticated //&& isAuthenticated
                ? <span> 
                    <Component {...props} />
                    {process.env.REACT_APP_ISLOCAL ? null :<SessionTimeout />}
                  </span>
                : <Expired  {...props}/>
        )} />
    );
}
export default PrivateRoute;
