import {
    UPLOAD_REQUIRED_DOCUMENT,
    VIEW_REQUIRED_DOCUMENT,
    REMOVE_REQUIRED_DOCUMENT,
    LOAD_UPLOADED_REQUIRED_DOCUMENTS,
    RESET_REQUIRED_DOCUMENT,
    RESET_FLOW,
    RESET_TO_INITIALSTATE,
    RESET_RENEWALS,
    LOAD_RENEWAL_UPLOADED_REQUIRED_DOCUMENTS,
    SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT,
} from '../../../actions/actionTypes';

const createDocumentSection = (
    sectionName,
    {
        files = [],
    } = {},
    externallyUploaded = false
) => ({
    sectionName,
    files,
    externallyUploaded
});

const initalState = {
    loading: true,
    requiredDocuments: {
        wagesAndTax: createDocumentSection('Wages and Tax'),
        participation: createDocumentSection('10+ Participation'),
        religiousExemption: createDocumentSection('Religious Exemption'),
        peoElcAttestation: createDocumentSection('PEO/ELC Attestation'),
        writtenAssurance: createDocumentSection('Written Assurance'),
        commonOwnership: createDocumentSection('Common Ownership'),
        priorDental: createDocumentSection('Proof of Prior Dental'),
        priorLife: createDocumentSection('Proof of Prior Life'),
        HSACertificateOfUnderstanding: createDocumentSection('HSA Certificate of Understanding'),
        certificationForm: createDocumentSection('Certification Form'),
        enrollmentForm: createDocumentSection('Enrollment Form'),
        groupTaxForm: createDocumentSection('Group Tax Form'), // Might need to change since this is piece that needs to be in S3, but is not uploaded actively
        additionalInfo: createDocumentSection('Additional Info'),
        employeeEnrollmentForms: createDocumentSection('Employee Enrollment Form(s)'),
        employerCertification: createDocumentSection('Employer Certification'),
        selectHRAForm: createDocumentSection('Select HRA Form'),
        limitedAgencyAgreement: createDocumentSection('Limited Agency Agreement'),
        smallEmployerGroupDisclosureForm: createDocumentSection('Small Group Form')
    },

};

function enrollmentReducer(state = initalState, action) {
    switch (action.type) {
        case UPLOAD_REQUIRED_DOCUMENT: {
            const {
                category,
                clientFileName,
                cloudFileName,
                uploadedAt,
                mimeType,
                size,
            } = action.payload;
            return {
                ...state,
                requiredDocuments: {
                    ...state.requiredDocuments,
                    [category]: {
                        ...state.requiredDocuments[category],
                        files: [...state.requiredDocuments[category].files, {
                            clientFileName,
                            cloudFileName,
                            uploadedAt,
                            mimeType,
                            size,
                        }],
                    },
                },
            };
        }
        case LOAD_UPLOADED_REQUIRED_DOCUMENTS: {
            const { files } = action.payload;
            const newState = {
                loading: false,
                requiredDocuments: { ...state.requiredDocuments },
            };
            Object.keys(files).forEach((category) => {
                newState.requiredDocuments[category] = {
                    ...state.requiredDocuments[category],
                    files: files[category],
                };
            });
            return newState;
        }
        case REMOVE_REQUIRED_DOCUMENT: {
            const { category, cloudFileName } = action.payload;
            return {
                ...state,
                requiredDocuments: {
                    ...state.requiredDocuments,
                    [category]: {
                        ...state.requiredDocuments[category],
                        files: state.requiredDocuments[category].files
                            .filter((doc) => doc.cloudFileName !== cloudFileName),
                    },
                },
            };
        }
        case RESET_REQUIRED_DOCUMENT: {
            return initalState;
        }
        case RESET_TO_INITIALSTATE:
            return initalState
        case RESET_RENEWALS:
            return initalState;
        case LOAD_RENEWAL_UPLOADED_REQUIRED_DOCUMENTS: {
            const { files } = action.payload;
            const newState = {
                loading: false,
                requiredDocuments: { ...state.requiredDocuments },
            };
            Object.keys(files).forEach((category) => {
                newState.requiredDocuments[category] = {
                    ...state.requiredDocuments[category],
                    files: files[category].files,
                    externallyUploaded:files[category].externallyUploaded
                };
            });
            return newState;
        }
        case SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT:
            return {
                ...state,
                requiredDocuments: {
                    ...state.requiredDocuments,
                    [action.docType]: {
                        ...state.requiredDocuments[action.docType],
                        externallyUploaded: action.externallyUploaded
                    }
                }
            }
        case VIEW_REQUIRED_DOCUMENT:
        default:
            return state;
    }
}

export default enrollmentReducer;
