import * as types from '../../actions/actionTypes';

const initialState = {
    caseId: '',
    currentQuoteId: '',
    caseTrack: { // TODO: Figure out if this is supposed to be an array or object, b/c most of the time it's referenced as an array
        caseId: '',
        quoteTrackingNumber: [],
        swRateId: '',
        renewalCaseId: '',
        renewalQuoteId: '',
        businessType: '',
        source: '',
        userId: '',
        createdBy: '',
        lastModifiedBy: '',
        createdDate: '',
        lastUpdatedDate: '',
        lastUpdatedPage: '',
        proposalId: '',
        caseStatus: '',
        caseData: '',
        quotes: [],
        shoppingId: '',
        selectedProducts: '',
        caseOwners:[]
    },
    isNewCase: false,
    isNewQuote: false,
    isCopyQuote: false,
    isWithdrawCase: false,
    isCloseCase: false,
    isRemoveQuote: false,
    isContinuedFromQQCase: false,
    enrollingQuoteId: '',
    isCopyQuoteOriginal: false,
    updatingCaseTrack: false,
    installPlatformId: null,
    platform: 'CIRRUS',
    isManualInstallation: false,
    receivedPlatformFromIPPS: false,
    installationErrorCode: '',
    stateStoreId: '',
    isIPPSBypassed: false,
    isTriplePlatform: false,
    disableSaveAndExit: false,
};

export default function caseTracking(state = initialState, action) {
    switch (action.type) {
        case types.CASE_TRACK_UPDATING:
            return {
                ...state,
                updatingCaseTrack: true,
            };
        case types.DISABLE_SAVE_AND_EXIT:
            return {
                ...state,
                disableSaveAndExit: action.payload,
            };
        case types.UPDATED_CASE_TRACK:
            return {
                ...state,
                caseId: action.caseTracking.caseId,
                caseTrack: action.caseTracking.caseTrack,
                updatingCaseTrack: false,
            };
        case types.CASE_TRACK_UPDATE_ERRORED:
            return {
                ...state,
                caseTrackError: action.error,
            };
        case types.GET_CASE_TRACK:
            if (action.isUhcRenewals) {
                return {
                    ...state,
                    caseId: action.caseTracking.caseId,
                    caseTrack: action.caseTracking.caseTrack,
                    platform: action.caseTracking.platform,
                    isManualInstallation: action.caseTracking.isManualInstallation,
                    receivedPlatformFromIPPS: action.caseTracking.receivedPlatformFromIPPS
                };
            } else {
                return {
                    ...state,
                    caseId: action.caseTracking.caseId,
                    caseTrack: action.caseTracking.caseTrack,
                };
            }
        case types.REQUEST_NB_NEW_FULL_QUOTE:
            return {
                ...state,
                isNewQuote: true,
            };
        case types.RECEIVE_NB_NEW_FULL_QUOTE:
            return {
                ...state,
                isNewQuote: false,
            };
        case types.REQUEST_NB_NEW_COPY_QUOTE:
            return {
                ...state,
                isCopyQuote: true,
                isCopyQuoteOriginal: true,
            };
        case types.RECEIVE_NB_NEW_COPY_QUOTE:
            return {
                ...state,
                isCopyQuote: false,
            };
        case types.REQUEST_NB_REMOVE_FULL_QUOTE:
            return {
                ...state,
                isRemoveQuote: true,
            };
        case types.RECEIVE_NB_REMOVE_FULL_QUOTE:
            return {
                ...state,
                isRemoveQuote: false,
            };
        case types.REQUEST_NB_WITHDRAW_CASE:
            return {
                ...state,
                isWithdrawCase: true,
            };
        case types.RECEIVE_NB_WITHDRAW_CASE:
            return {
                ...state,
                isWithdrawCase: false,
            };
        case types.REQUEST_NB_CLOSE_CASE:
            return {
                ...state,
                isCloseCase: true,
            };
        case types.RECEIVE_NB_CLOSE_CASE:
            return {
                ...state,
                isCloseCase: false,
            };
        case types.RESET_RL_CASE_TRACK:
            return {
                ...initialState
            };    
        case types.SET_ENROLLMENT_QUOTE_ID:
            return {
                ...state,
                enrollingQuoteId: action.enrollingQuoteId,
            };
        case types.REQUEST_NB_NEW_CASE:
            return {
                ...initialState,
                isNewCase: true,
            };
        case types.RESET_RL_CASE_TRACK:
            return {
                ...initialState
            };    
        case types.RECEIVE_NB_NEW_CASE:
            return {
                ...state,
                isNewCase: false,
            };
        case types.SET_ENROLLMENT_QUOTE_ID:
            return {
                ...state,
                enrollingQuoteId: action.enrollingQuoteId,
            };
        case types.SET_CURRENT_QUOTE_ID:
            return {
                ...state,
                currentQuoteId: action.currentQuoteId,
            };
        case types.SET_CONTINUED_FROM_QQ_CASE:
            return {
                ...state,
                isContinuedFromQQCase: true,
            };
        case types.UPDATE_STATE_STORE_ID:
            return {
                ...state,
                stateStoreId: action.stateStoreId
            }
        case types.SET_UPDATED_PLANS:
            return {
                ...state,
                copyDentalPlans: action.payloadD,
                copyVisionPlans: action.payloadV,
                copyMedicalPlans: action.payloadM,
                copyLifePlans: action.payloadL,
                copyDependentLifePlans: action.payloadDL,
                updatedEffDate: action.payloadE,
            };
        case types.RESET_COPY_FLAG:
            return {
                ...state,
                isCopyQuoteOriginal: false,
            };
        case types.IPPS_PLATFORM_AND_CODE_UPDATE:
            return {
                ...state,
                platform: action.platform,
                isManualInstallation: action.isManualInstallation,
                installationErrorCode: action.responseCode,
                receivedPlatformFromIPPS: true,
            }
        case types.UPDATE_INSTALLED_PLATFORM:
            return {
                ...state,
                platform: action.platform
            }
        case types.SET_MANUAL_INSTALLATION:
            return {
                ...state,
                isManualInstallation: action.payload,
                
            }
        case types.UPDATE_IS_IPPSBYPASSED:
            return {
                ...state,
                isIPPSBypassed : action.isIPPSBypassed
            }
        case types.SAVE_TOTAL_PROGRESS:
            return{
                ...state,
                totalProgress:action.payload
            }
        case types.UPDATE_MANUAL_FLAG:
            return{
                ...state,
                isManualInstallation : action.payload
            }
        case types.UPDATE_TRIPLE_PLATFORM:
            return{
                ...state,
                isTriplePlatform: action.payload
            }
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
            return initialState
        default:
            return state;
    }
}
