import saveAs from 'file-saver';
import { newEmployee } from '../components/activities/FullShopping/Census/helpers/employeeConsts';
import {
    addDependent,
    addEmployee,
    removeDependent,
    addEmployeeEnrollment
} from '../components/activities/FullShopping/Census/helpers/handleAddEmployee';
import { getCoverageTypeFromSelectedProducts, validateDependentStatus, validateEmployeeStatus } from '../components/activities/FullShopping/Census/helpers/validationHelpers';
import { checkSelectedProducts, getConditionEvaluators } from '../components/shared/ConfigurableForms/helpers/conditionCheckers';
import apiRoutes from '../utils/api/apiRouteConfig';
import { axios } from '../utils/api/axiosConfig';
// import { makeLightweightCall } from '../utils/api/installationRequests';
import { deleteMongoFile, downloadFile, uploadFile } from '../utils/api/fileUploadRequests';
import { storeAdditionalInfoDocsToEdms } from '../utils/edmsDocumentStorageAPI/edmsDocumentStorageAPI';
import { getAgeFromDOB } from '../utils/formatters/numbers';
import { isEmpty } from '../utils/formatters/strings';
import { parseZipObj } from '../utils/formatters/zips';
import { createHistory } from './historyAction';
import deepCopy from '../utils/deepCopy';
import { auditUserAction } from './actionHelpers/createHistoryHelper';
import * as types from './actionTypes';
import {
    updateState as updateCompanyProfileState, updateRenewalsSICCode, saveSicDataInCompanyProfileRL, updateState, updateCompanyProfileEmployees
} from './companyProfileActions';
import sendLog from './logActions';
import { saveStateStore } from './caseTrackAction';
import { updateSICCodeToRenewalData } from './renewalsEnrollmentActions';
import { identifyPlatform } from '../components/activities/Renewal/Review/reviewUtils';
import { saveEmployerToCompanyProfile } from '../reducers/products/helpers/renewalCompanyProfileHelper';
import { MMDDYYYYtoIsoString } from '../utils/formatters/dates';
import { getEnrollmentStateRefined } from '../reducers/products/enrollment/helper';
import { isUhcToUsp } from '../utils/businessLogic/primeUspHW';
import { getMarketType, isOxfordRenewals, isUhcRenewals, MARKET_TYPES } from '../utils/businessLogic/platformUtils';
import { sendClickEventDataAction } from './analyticsAction';
import { calculateDependentLifeRate } from '../utils/businessLogic/dependentLifeRateLogic';
import { enrollmentFormCompletionHandler } from '../components/activities/Enrollment/EmployerAppForms/EnrollmentFormCompletionHandler';

import { getReduxStoreReference } from '../uhcTemplate/templateParser/mappingHolder/mappingHelper';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { updateEnrollPlans,updateSelectedDecidePlan } from './decideActions';

// import { routeConsts } from '../components/activities/Enrollment/shared/EnrollmentDetails/pageConsts';

// Called to add/remove an already-selected plan to the subset of plans a user wants to ENROLL
//
// Parameters
//  plan:                   Object holding plan information to be updated
//  withinPackage:          The string name of the package within which the plan was chosen to
//                          enroll ("standalone" if it's a standalone plan), also used
//                          for distinguishing life classes
//  serviceType:            Service type for action to dispatch, e.g. dental, vision, etc.
//  remove:                 Flag for if we want to remove the plan from enrolled plans array.
export function togglePlanEnrollment(plan, withinPackage, serviceType = 'FI', remove = false, isRenewalsFlow = false, isRenewalsAdditionalMedical = false) {
    return (dispatch, getState) => {
        const state = getState();
        const { overall, quotes } = state;
        const { companyProfile } = state;
        let totalEmp, stateCode;
        let employeeClassing;

        if (getMarketType() === MARKET_TYPES.UHC) {
            if (serviceType === "LIFE_DEP_BASIC") {
                employeeClassing = state.lifeDep.employeeClassing;
            } else {
                employeeClassing = state.life.employeeClassing;
            }

            if (!overall.isRenewals) {
                totalEmp = companyProfile.locations[0].totalEmps
                stateCode = companyProfile.locations[0].zipData.stateCode
            }

            if (isUhcRenewals()) {
                totalEmp = (companyProfile.locations[0].fteRule === 'ATNE' || companyProfile.locations[0].fteRule === 'FTE')
                    ? companyProfile.locations[0].fte
                    : companyProfile.locations[0].totalEligEmps
                stateCode = companyProfile.locations[0].zipData.stateCode
            }
        } else {
            employeeClassing = state.life.employeeClassing
        }

        // for std and ltd 
        if (serviceType === "STD" || serviceType === "LTD") {
            employeeClassing = state[serviceType.toLowerCase()].employeeClassing;
        }

        if(serviceType.toLowerCase() !== "std" || serviceType.toLowerCase() !== "ltd" || serviceType.toLowerCase() !== 'life')
        {
            employeeClassing = state.life.employeeClassing?.employeeSelectedClasses?.length > 0 ? state.life.employeeClassing : state.std.employeeClassing?.employeeSelectedClasses?.length > 0 ? state.std.employeeClassing : state.ltd.employeeClassing?.employeeSelectedClasses?.length > 0 ? state.ltd.employeeClassing : []
        }

        if (!overall.isRenewals) {
            dispatch(auditUserAction(plan.planType, `Plan: ${plan.code}`, 'Selected or Un-Selected for the Enrollment.'));
        }

        const planName = plan.codeDisplay ? plan.codeDisplay : plan.code;
        const planCode = plan.code ? plan.code : '';
        const productType = plan.planType ? (plan.planType.toUpperCase() === 'MEDICAL' ?
                (plan.medicalProductType ? plan.medicalProductType : plan.medicalPlanType)
            : plan.productType)
        : '';

        let coverageType = "";

        if (plan.planType) { 
            if (plan.planType === "life_dep_basic") {
                coverageType += "DEPENDENT LIFE"
            } else {
                coverageType += plan.planType.toUpperCase()
            }
        }

        if (plan.metal) coverageType += " : " + plan.metal

        dispatch(sendClickEventDataAction({
            clickName :'Plan : Selected/ De-Selected for Enrollment ',
            coverageType : coverageType,
            clickElement : 'Button',
            clickType : 'internal',
            planName : planName,
            planCode: planCode,
            productType: productType,
            planFilter:plan.isPreferredPlan || plan.preferredPlan?'Preferred':'Standard'
        }))

        sendLog({
            action: 'TOGGLE PLAN ENROLLMENT',
            planCode: plan.codeDisplay,
        });

        const usingAgeBandedRating = quotes.filterCriteria.ratingMethod.age || (plan.defaultRatingType === 'ageBands' ? true : false);
        let uhcRenewalsFlag = isUhcRenewals();
        const groupSize = companyProfile.groupSize;

        if(isUhcRenewals() && serviceType == 'SUPPLEMENT LIFE'){
            serviceType = 'SUPPLIFE';
        }
        let renewals = isUhcRenewals() || isOxfordRenewals();
        if(isOxfordRenewals() && plan.planType === "Medical"){
            dispatch(updateEnrollPlans(planCode, !remove));
            dispatch(updateSelectedDecidePlan(planCode,!remove))
        }

        dispatch({
            type: types.SERVICE[serviceType]?.TOGGLE_PLAN_ENROLL ? types.SERVICE[serviceType]?.TOGGLE_PLAN_ENROLL : null ,
            plan,
            withinPackage, // Also used for life classes
            usingAgeBandedRating,
            remove,
            isRenewalsFlow : renewals ? true : false,
            isRenewalsAdditionalMedical,
            serviceType,
            employeeClassing,
            uhcRenewalsFlag,
            totalEmp, 
            stateCode,
            groupSize
        });
    };
}

export function togglePackageEnrollment(packageCode) {
    return (dispatch, getState) => {
        sendLog({
            action: 'TOGGLE PACKAGE ENROLLMENT',
            packageCode,
        });

        const state = getState();
        const { selectedPlans } = state.quotes;
        const usingAgeBandedRating = state.quotes.filterCriteria.ratingMethod.age;
        const { employeeClassing } = state.life;

        dispatch({
            type: types.SERVICE.FI.TOGGLE_PACKAGE_ENROLL,
            packageCode,
            selectedPlans,
            usingAgeBandedRating,
            employeeClassing,
            uhcRenewalsFlag: isUhcRenewals()
        });
    };
}

export function updateQuoteEnrollErrors(errors) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE QUOTE REVIEW ERRORS',
            errors,
        });

        dispatch({
            type: types.UPDATE_QUOTE_REVIEW_ERRORS,
            errors
        });
    };
}

export const saveSubmitAdditionalInfoToEdms = (enrollmentData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: types.IS_UPLOADING_ADDITIONAL_INFO_EDMS,
        });
        const { docs: additionalInfoDocs } = getState().additionalReqdInfo;
        const successfulUpload = await storeAdditionalInfoDocsToEdms(
            enrollmentData, additionalInfoDocs,
        );
        if (!successfulUpload) {
            dispatch(createHistory('Save and submit upload Additional Info Doc errors while submitting', 'Additional Required Information', 'Error', enrollmentData, '', 'NB'));
            dispatch({ type: types.ERROR_UPLOADING_ADDITIONAL_INFO_EDMS });
        } else {
            dispatch(createHistory('Save Additional Info Doc', 'Additional Required Information', 'Success', enrollmentData, '', 'NB'));
            dispatch({ type: types.UPLOADED_ADDITIONAL_INFO_EDMS });
        }
    } catch (error) {
        dispatch(createHistory('Save and submit upload Additional Info Doc errors while submitting', 'Additional Required Information', 'Error', enrollmentData, '', 'NB'));
        dispatch({
            type: types.ERROR_UPLOADING_ADDITIONAL_INFO_EDMS,
        });
    }
};

export function uploadRequiredDocument(file, category, pageType, enrollmentData, caseId = '') {
    return async (dispatch, getState) => {
        const fullProps = getState();
        const uploadResult = await uploadFile(file, enrollmentData, pageType, caseId,'',category,deepCopy(fullProps.companyProfile),fullProps.caseTracking.caseId,fullProps.overall.platform,fullProps.overall.appConfigDetails.env);
        if (uploadResult !== 'error') {
            dispatch(createHistory('Required Doc file uploaded', 'Enrollment Manager ( Required Document )', 'Success', `${category} CloudFileName : ${uploadResult.cloudFileName} clientFileName: ${uploadResult.clientFileName}`, uploadResult, pageType === 'renewals' ? 'RL': 'NB'));
            dispatch({
                type: types.UPLOAD_REQUIRED_DOCUMENT,
                payload: {
                    cloudFileName: uploadResult.cloudFileName,
                    clientFileName: uploadResult.clientFileName,
                    uploadedAt: uploadResult.uploadedAt,
                    mimetype: uploadResult.mimetype,
                    category,
                    size: uploadResult.size,
                },
            });
            if (pageType !== 'renewals') {
                dispatch(saveStateStore(true));
            }
            if (pageType === 'renewals') {
                
                const { userName } = fullProps.user;
                const cmpProfile = deepCopy(fullProps.companyProfile);
                cmpProfile.employerEnrollment = saveEmployerToCompanyProfile(cmpProfile, fullProps).employerEnrollment;
                cmpProfile.enrollment = {
                    requiredDocs: fullProps.enrollment.documents.requiredDocuments,
                };
                cmpProfile.marketType = getMarketType();
                axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile);
            }
        } else {
            dispatch(createHistory('Required Doc file upload failed', 'Enrollment Manager ( Required Document )', 'Error', '', uploadResult,  pageType === 'renewals' ? 'RL': 'NB'));
        }
    };
}

export function viewRequiredDocument(file, pageType = '') {
    return async (dispatch) => {
        dispatch(downloadFile(file, pageType));
        dispatch({
            type: types.VIEW_REQUIRED_DOCUMENT,
            payload: { cloudFileName: file.cloudFileName },
        });
    };
}

export const removeRequiredDocument = (category, cloudFileName, pageType = '') => async (dispatch, getState) => {
    const deleteS3Result = await deleteMongoFile(cloudFileName);

    if (deleteS3Result !== 'error') {
        // TODO: Hit service layer endpoint to remove doc
        dispatch(createHistory('Required Docs file removed', 'Enrollment Manager ( Required Document )', 'Success', `${category} :${cloudFileName}`, '', pageType === 'renewals' ? 'RL': 'NB'));
        dispatch({
            type: types.REMOVE_REQUIRED_DOCUMENT,
            payload: { category, cloudFileName },
        });
        if (pageType !== 'renewals') {
            dispatch(saveStateStore(true));
        }
        if (pageType === 'renewals') {
            const fullProps = getState();
            const { userName } = fullProps.user;
            const cmpProfile = deepCopy(fullProps.companyProfile);
            cmpProfile.enrollment = {
                requiredDocs: fullProps.enrollment.documents.requiredDocuments,
            };
            cmpProfile.marketType = getMarketType();
            axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile);
        }
    } else {
        dispatch(createHistory('Required Docs file removing failed', 'Enrollment Manager ( Required Document )', 'Error', '', '', pageType === 'renewals' ? 'RL': 'NB'));  
    }
};

// TODO: call from service layer
const getUploadedDocuments = () => ({});

export function loadUploadedRequiredDocuments() {
    return (dispatch) => {
        const uploaded = getUploadedDocuments();
        dispatch({
            type: types.LOAD_UPLOADED_REQUIRED_DOCUMENTS,
            payload: {
                files: uploaded,
            },
        });
    };
}

export function downloadForm(state, docName, docKey, pageType = '') {
    return async (dispatch) => {
        const file = (
            await axios.get(
                `${apiRoutes.quickQuotes}/enrollment/document/${docKey}?state=${state}`, { responseType: 'blob' },
            )
        ).data;
        saveAs(file, docName);
        dispatch(createHistory(`View/Download ${docName}`, 'Enrollment Manager ( Required Document )', 'Success', `DocKey : ${docKey}`, '', (pageType === 'newBuisness' || pageType === 'newBusiness') ? 'NB': 'RL'));
        dispatch({
            type: types.DOWNLOAD_REQUIRED_DOC_FORM,
            payload: { state, doc: docKey },
        });
    };
}

export function downloadPaymentBillingForm(docName, docKey) {
    return async (dispatch) => {
        const file = (
            await axios.get(
                `${apiRoutes.quickQuotes}/enrollment/paymentBilling/?doc=${docKey}`, { responseType: 'blob' },
            )
        ).data;
        saveAs(file, docName);

        dispatch({
            type: types.DOWNLOAD_PAYMENT_BILLING_FORM,
            payload: { docKey },
        });
    };
}

export function removeEnrolledPlan(index) {
    return {
        type: types.REMOVE_ENROLLED_PLAN,
        index,
    };
}

export function dispatchUpdateEmployerInfo(group, field, value, isError, isFromCompanyProfile = false) {
    return async (dispatch) => {
        dispatch(updateEmployerInfo(group, field, value, isError, isFromCompanyProfile));
    };
}

export function updateEmployerInfo(group, field, value, isError, isFromCompanyProfile) {
    return (dispatch, getState) => {
        const {companyProfile}=getState();
        const checkType=companyProfile.locations[0].fteRule;
        let disableField1="",disableField2="";
        if(isUhcRenewals() && checkType === 'ATNE' && field==="priorCalendarYearATNE")
        {
            disableField1="priorCalendarYearFTE"
        }
        else if(isUhcRenewals() && checkType === 'FTE'&& field==="priorCalendarYearFTE")
        {
            disableField1="priorCalendarYearATNE"
        }
        else if(isUhcRenewals() && checkType === 'Eligible'  && field==="numEligibleEmployees")
        {
            disableField1="priorCalendarYearATNE"
            disableField2="priorCalendarYearFTE"
        }
        dispatch({
            type: types.UPDATE_EMPLOYER_INFO,
            group,
            update: field === 'billingZipCode' ? {
                billingCity: value?.data?.city,
                billingState: value?.data?.stateCode,
                billingZipCode: value?.data?.zipCode,
                county: value?.data?.county,
                countyFIPSCode: value?.data?.countyFIPSCode,
                stateFIPSCode:value?.data?.stateFIPSCode
            } : group === 'additionalContacts' && field.includes('additionalContactZipCode') ? 
                {
                    [field]: value?.data?.zipCode || ''
                }
            : {
                [field]: value,
                [disableField1]: disableField1 === "" ? "" :value,
                [disableField2]: disableField2 === "" ? "" :value
            },
            isError,
        });
        if (field === 'streetAddress'){
            dispatch(updateStandardHRA( 'clientDemographicInformation', field, value, isError))
        }
        
        if (field === 'sicCode' && !isError) {
            dispatch(updateRenewalsSICCode(value));
            dispatch(updateSICCodeToRenewalData(value));
        }
        if (field === 'requestedEffectiveDate' && !isError && !isFromCompanyProfile) {
            const updatedEffectiveDate = MMDDYYYYtoIsoString(value);
            const { companyProfile } = getState();
            dispatch(updateState({ ...companyProfile, effectiveDate: updatedEffectiveDate }));
        }
        if (field === 'workersComp') {
            const { companyProfile } = getState();
            const { enrollment } = getState();
            const { employees } = companyProfile;

            // check if statecode is either MS or TN --- if it is we do not need to reset the value
            if (!['MS', 'TN'].includes(companyProfile.locations[0].zipData.stateCode)) {
              // iterate over each employee and if workers comp goes from yes to no, clear each employees aoCoverageEmployee value
              // if workers comp goes from no to yes, change employee to inprogress from completed so user needs to go in and fill out the aoCoverage field
              for (const employeeArray of employees) {
                for (let employee of employeeArray) {
                  if ( enrollment.application.employer.additionalGeneralInformation.workersComp !== "Yes" && !companyProfile.isTemplateUploaded) {
                    if (employee.employeeInfo && employee.employeeInfo.aoCoverageEmployee) {
                      employee.employeeInfo.aoCoverageEmployee = null;
                    } 
                  } else if (enrollment.application.employer.additionalGeneralInformation.workersComp === "No" && companyProfile.isTemplateUploaded === true){
                    employee.employeeInfo.aoCoverageEmployee = null;
                  }
                  else if (value === "Yes") {
                    employee.status = "In Progress"
                  }
                }
              }
              dispatch(updateCompanyProfileEmployees(employees))
            }
        }
    };
}

function updateEligibilityInfo(group, field, value, isError, isOverride = false) {
    if ((field === 'waitingPeriodForNewHires' && value === 'First of the Following Month') || (isUhcToUsp() && field === 'waitingPeriodForNewHires' && value === 'First of the Following Month after Specified Duration' )) {
        return {
            type: types.UPDATE_ELIGIBILITY_INFO,
            group,
            update: {
                [field]: value,
            },
            isError,
            updateAnotherField: {
                terminationLag: 'End of the month of Termination',
            },
        };
    } if (field === 'waitingPeriodForNewHires' && value !== 'First of the Following Month') {
        return {
            type: types.UPDATE_ELIGIBILITY_INFO,
            group,
            update: {
                [field]: value,
            },
            isError: false,
            updateAnotherField: {
                terminationLag: 'Date of Termination',
            },
        };
    }
    if (isOverride) {
        return (dispatch) => {
            dispatch({
                type: types.UPDATE_ELIGIBILITY_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
            })
            dispatch({
                type: types.OVERRIDE_VALIDATIONS,
                payload: isOverride
            })
        }
    }
    else {
        return {
            type: types.UPDATE_ELIGIBILITY_INFO,
            group,
            update: {
                [field]: value,
            },
            isError,
        }
    };
}

function updateProducerInfo(group, field, value, isError) {
    return {
        type: types.UPDATE_PRODUCER_INFO,
        group,
        field,
        value,
        isError,
    };
}

export function updateAttestationInfo(group, field, value, isError) {
    return {
        type: types.UPDATE_ATTESTATION_INFO,
        group,
        update: {
            [field]: value,
        },
        isError,
    };
}

function updateEmployeeInfo(group, field, value, isError) {
    return (dispatch, getState) => {
        const { companyProfile: { effectiveDate } } = getState();
        // set ssntin -> '' when ssn is not available
        if (field === 'ssnCheck' && value === true) {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    ssnTin: '',
                },
            })
        }
        // set no ssn reason -> '' when ssn is available
        else if (field === 'ssnCheck' && value === false) {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    noSSNReason: '',
                },
            })
        }
        else if (field === 'medicarePrimary' && value === 'No') {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    medicareCoverages: [],
                    medicareClaimNum: '',
                    medicareReason: [],
                    medicarePartAStartDate: '',
                    medicarePartBStartDate: '',
                    medicarePartCStartDate: '',
                    medicarePartDStartDate: '',
                },
            })
        }
        else if (field === 'isDisabled' && value === 'No') {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    disabilityType: '',
                },
            })
        }
        else if (field === 'employeeStatus' && value !== 'COBRA') {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    cobraStartDate: '',
                    cobraEndDate: '',
                },
            })
        }
        else if (field === 'anyOtherCoverage' && value === 'No') {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
                makeHiddenFieldsNull: {
                    nameOtherCarrier: '',
                },
            })
        }
        else if (field === 'dob' && value) {
            let age = '';
            if (!isError) {
                age = getAgeFromDOB(value, effectiveDate)
            }
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                    age: age,
                    isDisabled: null
                },
                isError,
            })
        }
        else if (field === 'relationship' && value) {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                    isDisabled: null
                },
                isError,
            })
        }
        else {
            dispatch({
                type: types.UPDATE_EMPLOYEE_INFO,
                group,
                update: {
                    [field]: value,
                },
                isError,
            })
        }
    }
}

export function updateEmployeeTable(tableName, newState, stateKey = null, memberIndex = null) {
    if (newState.length > 0) {
    const empPcpCode = newState[0] ? newState[0].pcpCode:'';
    const empDepAssigned = newState[0] &&  newState[0].dependentsAssigned ? newState[0].dependentsAssigned[0] : '';
    const empAssignmentMethod = newState[0] ? newState[0].assignmentMethod :' ';
        for (const state of newState) {
            if (state.assignmentMethod && state.assignmentMethod === 'Auto Assign') {
                state.pcpCode = '';
            }
            if(empDepAssigned==='all'){
                state.assignmentMethod=empAssignmentMethod;
                if(empAssignmentMethod==='Auto Assign'){
                    state.pcpCode='';

                }else if(empAssignmentMethod==='Enter in a PCP code' && empPcpCode){
                    state.pcpCode = empPcpCode;
                }
            }
        }
        if (stateKey === 'dependentsAssigned')
            updatePcp(memberIndex, newState);
    }
    return {
        type: types.UPDATE_EMPLOYEE_TABLE,
        tableName,
        newState,
    };
}

function updatePcp(memberIndex, newState) {
    const member = newState[memberIndex];
    const pcpCode = member.pcpCode ? member.pcpCode : '';
    const assignmentMethod = member.assignmentMethod ? member.assignmentMethod : '';
    const { dependentsAssigned } = member;
    if (dependentsAssigned.includes('all')) {
        for (let i = 0; i < newState.length; i++) {
            if (i !== 0)
                assignPcpAndMethod(pcpCode, assignmentMethod, newState[i]);
            if (i !== memberIndex) {
                newState[i].dependentsAssigned = [];
            }
        }
    }
    else {
        for (const index of dependentsAssigned) {
            removeDependentsAssigned(index, newState, memberIndex);
            assignPcpAndMethod(pcpCode, assignmentMethod, newState[index]);
        }
    }
}

function assignPcpAndMethod(pcpCode, assignmentMethod, member) {
    member.pcpCode = pcpCode;
    member.assignmentMethod = assignmentMethod;
}

function removeDependentsAssigned(index, newState, memberIndex) {
    if (newState.length > 0) {
        for (let i = 0; i < newState.length; i++) {
            let { dependentsAssigned = [] } = newState[i];
            if (dependentsAssigned.length > 0) {
                let j = dependentsAssigned.findIndex(ele => ele === index);
                if (j > -1 && i !== memberIndex) {
                    dependentsAssigned.splice(j, 1);
                }
            }
            newState[i].dependentsAssigned = dependentsAssigned;
        }
    }
}

export function resetEnrollmentForm() {
    return (dispatch) => {
      sendLog({
        action: 'RESET_ENROLLMENT_FORM',
      });
      dispatch({ type: types.RESET_ENROLLMENT_FORM });
    };
  }

export function updateEmployeeTableRow(
    tableName,
    memberIndex,
    productsToWaive,
) {
    return {
        type: types.UPDATE_EMPLOYEE_TABLE_ROW,
        tableName,
        memberIndex,
        productsToWaive,
    };
}

export function updateEmployerCertification(group, field, value, isError) {
    return {
        type: types.UPDATE_EMPLOYER_CERTIFICATION,
        group,
        field,
        value,
        isError,
    };
}

export function updateEmployerCertificationBulkErr(obj){
    return {
        type: types.UPDATE_EMPLOYER_CERTIFICATION_BULK_ERR,
        obj
    }
}

export function updateStandardHRA(group, field, value, isError) {
    return {
        type: types.UPDATE_STANDARD_HRA,
        group,
        field,
        update: {
            [field]: value,
        },
        isError,
    };
}

export function getStandardHRAFromDB() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { renewalCaseId, quoteTrackingNumber } = fullProps.caseTracking.caseTrack[0];

        axios.get(`${apiRoutes.mongo}/standardHRA?memberGroupId=${renewalCaseId}&quoteTrackingNumber=${quoteTrackingNumber}`)
            .then((result) => {
                if (result.data.standardHRA.length > 0) {
                    const existingStandardHRAForm = result.data.standardHRA[0];
                    if (existingStandardHRAForm !== {}) {
                        dispatch({
                            type: "RECEIVE_STANDARD_HRA",
                            payload: existingStandardHRAForm
                        })
                    }
                }
            }).catch(err => {
                if(isUhcRenewals()) {
                    const reqBody = {
                        memberGroupId: renewalCaseId,
                        quoteTrackingNumber
                    }
                    logError(KEY_NAME, UHC_REN_APIS.GET_REN_COMPANY_PROFILES, 'enrollmentActions.getStandardHRAFromDB', reqBody, err);
                }
                throw err;
            })
    };
}

export function getEnrollmentUpdateFunction(reducerName) {
    switch (reducerName) {
        case 'employer':
            return updateEmployerInfo;
        case 'eligibility':
            return updateEligibilityInfo;
        case 'producer':
            return updateProducerInfo;
        case 'attestation':
            return updateAttestationInfo;
        case 'employee':
            return updateEmployeeInfo;
        case 'employerCertification':
            return updateEmployerCertification;
        case 'standardHRA':
            return updateStandardHRA;
        default:
            return () => { };
    }
}

export function getEnrollmentFormSaveFunction(reducerName) {
    switch (reducerName) {
        case 'employer':
        case 'employer-info':
            return saveEmployerForm;
        case 'eligibility':
        case 'eligibility-info':
            return saveEligibilityForm;
        case 'producer':
        case 'producer-info':
            return saveProducerForm;
        default:
            return null;
    }
}

export function completedEmployerInfo(conditionEvaluators, appStateProps) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { enrollment, rules } = fullProps;
        const { application } = enrollment;
        const { enrollmentForm } = rules;

        dispatch({
            type: types.COMPLETED_EMPLOYER_INFO,
            application,
            enrollmentForm,
            conditionEvaluators,
            appStateProps,
            fullProps
        });
    };
}

export function completedEmployerCertification(conditionEvaluators, appStateProps) {
    return (dispatch, getState) => {
        const { enrollment, rules } = getState();
        const { application } = enrollment;
        const { enrollmentForm } = rules;

        dispatch({
            type: types.COMPLETED_EMPLOYER_CERTIFICATION,
            application,
            enrollmentForm,
            conditionEvaluators,
            appStateProps,
        });
        dispatch(saveStateStore(true))
    };
}

function saveEmployerForm() {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;

        dispatch({
            type: types.SAVE_EMPLOYER_FORM,
            application,
        });
    };
}

function saveEligibilityForm() {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;

        dispatch({
            type: types.SAVE_ELIGIBILITY_FORM,
            application,
        });
    };
}

function saveProducerForm() {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;

        dispatch({
            type: types.SAVE_PRODUCER_FORM,
            application,
        });
    };
}

export function completedEligibilityInfo(conditionEvaluators, appStateProps) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { enrollment, rules } = fullProps
        const { application } = enrollment;
        const { enrollmentForm } = rules;
        const platform = getState().overall.platform

        dispatch({
            type: types.COMPLETED_ELIGIBILITY_INFO,
            application,
            enrollmentForm,
            conditionEvaluators,
            appStateProps,
            platform,
            fullProps
        });
    };
}

export function completedProducerInfo(conditionEvaluators, appStateProps) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { enrollment, rules, reviewCards } = fullProps
        const { application } = enrollment;
        const { enrollmentForm } = rules;

        dispatch({
            type: types.COMPLETED_PRODUCER_INFO,
            application,
            enrollmentForm,
            conditionEvaluators,
            appStateProps,
            reviewCards,
            fullProps
        });
        dispatch(saveStateStore(true));
    };
}

export function completedAttestationInfo(
    group,
    conditionEvaluators,
    appStateProps,
) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { enrollment, rules } = fullProps;
        const { application } = enrollment;
        const { enrollmentForm } = rules;

        dispatch({
            type: types.COMPLETED_ATTESTATION_INFO,
            group,
            application,
            enrollmentForm,
            conditionEvaluators,
            appStateProps,
            fullProps
        });
        dispatch(saveStateStore(true))
        dispatch(createHistory('Attestation Submitted', 'Employer Attestation', 'Success', getEnrollmentStateRefined(fullProps), '', 'NB'));
    };
}

export function openIndividualEmployeeForm(locationIndex, employeeIndex, flow = '') {
    return (dispatch, getState) => {
        const { companyProfile, life, overall, rules: { enrollment } } = getState();
        const { employees, groupSize, locations } = companyProfile;
        const isDesQuote = getState().caseTracking.caseTrack[0].source
        let employee;
        let finalEmployeeIndex = employeeIndex;
        if (employeeIndex === -1) {
            employee = newEmployee;
            if(isUhcRenewals())
            {               
                employee.medicalCoverageInfo.medicalWaived=''
                
            }
            finalEmployeeIndex = employees && employees[locationIndex] ? employees[locationIndex].length : 0; // TODO: handle multi-site
            // const {
            //     employees: newEmployees,
            //     groupSize: newGroupSize,
            //     locations: newLocations,
            // } = addEmployee(employees, groupSize, locationIndex, locations);
            const {
                employees: newEmployees,
                locations: newLocations,
            } = addEmployeeEnrollment(employees, locationIndex, locations);
            const updatedCompanyProfile = {
                ...companyProfile,
                employees: newEmployees,
                // groupSize: newGroupSize,
                locations: newLocations,
            };
            dispatch(updateCompanyProfileState(updatedCompanyProfile));
        } else {
            employee = employees[locationIndex][employeeIndex];
            if (employee && employee.waiveCoverage) {
                employee.waiveCoverage.isWaivingAll = employee.waiveCoverage.isWaivingAll ? employee.waiveCoverage.isWaivingAll : 'No';
            } else {
                employee.waiveCoverage = { isWaivingAll: 'No', medicalWaiverReason: '' };
            }
        }
        dispatch({
            type: types.OPEN_INDIVIDUAL_EMPLOYEE,
            employee,
            locationIndex,
            employeeIndex: finalEmployeeIndex,
            flow,
            classingOpted: isUhcRenewals() ? employee.classingOpted : life.employeeClassing.classingOpted,
            products: checkSelectedProducts(overall, enrollment),
            desQuote: isDesQuote === "DES" ? true : false
        });
    };
}

function isEmployeeEqual(emp, employees, overall, employeeIndex) {
    const remainingEmps = employees && employees[0] && employees[0].length > 0 ? employees[0].filter((_, i) => employeeIndex !== i): {}
    if(emp && emp.ssnTin && !emp.ssnCheck && overall && overall.addNewEmployee) {    
        const ssnMatched = employees && employees[0] && employees[0].length > 0 ? employees[0].find((employee) => emp.ssnTin && employee.employeeInfo && employee.employeeInfo.ssnTin && emp.ssnTin === employee.employeeInfo.ssnTin): {};
        return ssnMatched;
    } else if(emp && emp.ssnTin === '' && emp.ssnCheck && overall && overall.addNewEmployee) {
        const ssnMatched = employees && employees[0] && employees[0].length > 0 ? employees[0].find((employee) => employee.firstName && employee.lastName && emp.firstName === employee.employeeInfo.firstName && emp.lastName === employee.employeeInfo.lastName && emp.dob === employee.dob): {};
        return ssnMatched;
    } else if(overall && !overall.addNewEmployee && emp && emp.ssnTin && !emp.ssnCheck) {
        const ssnMatched = remainingEmps && remainingEmps.length > 0 ? remainingEmps.find((employee) => emp.ssnTin && employee.employeeInfo && employee.employeeInfo.ssnTin && emp.ssnTin === employee.employeeInfo.ssnTin): {};
        return ssnMatched;
    } else if(overall && !overall.addNewEmployee && emp && emp.ssnTin === '' && emp.ssnCheck) {
        const ssnMatched = remainingEmps && remainingEmps.length > 0 ? remainingEmps.find((employee) => employee.firstName && employee.lastName && emp.firstName === employee.employeeInfo.firstName && emp.lastName === employee.employeeInfo.lastName && emp.dob === employee.dob): {};
        return ssnMatched;
    }
}

export function saveIndividualEmployeeForm(closePage = false, inCompleteEmployeeData={}, btn = '') {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { overall, companyProfile } = fullProps;
        const flow = overall && overall.isRenewals ? 'renewals' : '';
        const enrollState = getState().enrollment.application.employee;
        const { numHoursPerWeekToBeEligible } = getState().enrollment.application.eligibility.participation;
        const { selectedProducts, employees } = getState().companyProfile;
        const { platform } = fullProps.caseTracking;
        const {
            medicalCoverageInfo,
            waiveCoverage,
            employeeInfo,
            contactInfo,
            locationIndex,
            employeeIndex,
            productSelection,
            pcpAssignment,
            outOfArea,
            benefitAmount,
        } = enrollState;

        const enrolledLifePlans = getState().rules.enrollment.enrolledLifePlans;
        const isEmployeeMatched = flow === 'renewals' && employees && employees[0] && employees[0].length > 1 ? isEmployeeEqual(employeeInfo, employees, overall, employeeIndex): '';
        if(flow === 'renewals' && isEmployeeMatched) {
            return false;
        }
        // When we save an employee, do number of hours worked check here to automatically waive for active employees
        let employeeProdSelection;
        let employeeProductSelection = productSelection.map((productObj, index) => {
            // If there is an active employee annd flow is not renewals
            if (employeeInfo.employeeStatus === 'Active' && productSelection.length >= 1) {
                if (index === 0) {
                    employeeProdSelection = {
                        ...productObj,
                        medical: flow !== 'renewals' && employeeInfo.numOfHoursWorked && Number(employeeInfo.numberOfHoursWorked) < Number(numHoursPerWeekToBeEligible) ? 'waive' : productSelection[0] && productSelection[0].medical ? productSelection[0].medical : '',
                    };
                    // If num hours worked < num eligible and flow is not renewals, automatically waive upon save
                    return {
                        ...productObj,
                        medical: flow !== 'renewals' && employeeInfo.numOfHoursWorked && Number(employeeInfo.numberOfHoursWorked) < Number(numHoursPerWeekToBeEligible) ? 'waive' : productSelection[0] && productSelection[0].medical ? productSelection[0].medical : '',
                    };
                }
                // Dependents (index > 0 in prod selection)
                if (productObj.medical === 'waive') {
                    return {
                        ...productObj,
                    };
                    // if dependent has chosen a plan but employee gets auto waived, also waive dependent
                } if (productObj.medical !== 'waive' && employeeProdSelection.medical === 'waive') {
                    return {
                        ...productObj,
                        medical: 'waive',
                    };
                }
                return {
                    ...productObj,
                };
            }
            return { ...productObj };
        });

        // convert the dependents as they are stored in the enroll reducer
        // (objects called "dependentInfo0" , "dependentInfo1", etc.)
        // into the array used by companyProfile reducer
        const dependents = Object.keys(enrollState)
            .filter(
                (keyName) => keyName.indexOf('dependentInfo') === 0
                    && keyName !== 'dependentInfo',
            ) // we only want the specific objects - dependentInfo0, dependentInfo1, etc.
            .map((keyName) => ({
                sequence: parseInt(keyName.split('dependentInfo')[1]),
                state: enrollState[keyName],
            }))
            .sort((obj1, obj2) => obj1.sequence - obj2.sequence);

        dependents.forEach(async (dep) => {
            const testData = await getDependentZipData(dep.state.zipCode);
            dep.state.zipData = await testData;
        });

        // If the employee isnt waiving coverage check the fields
        const status = [];
        let { isWaivingAll, medicalWaiverReason } = waiveCoverage;

        // Actual user dropdown selection (not coerced based on number of selected products)
        // Used to determine waiver reason on form save
        const isWaivingAllSelection = isWaivingAll

        const { medicalWaiverReason: medicalCoverageWaiverReason } = medicalCoverageInfo;

        let waivedProductsCount = 0;
        let numSelectedProducts = 0;
        const employeeOnlyPS = employeeProductSelection ? employeeProductSelection[0] : {};

        if (isWaivingAll !== 'Yes') {
            Object.keys(selectedProducts).map((product) => {
                if (selectedProducts[product] && employeeOnlyPS && employeeOnlyPS[product] === 'waive') { waivedProductsCount++; }
                if (selectedProducts[product]) { numSelectedProducts++; }
            });
            isWaivingAll = employeeInfo.employeeStatus === '1099 Independent Contractors' ? isWaivingAll : waivedProductsCount === numSelectedProducts  ? 'Yes' : 'No';
        } else if (isWaivingAll === 'Yes') {
            const productsWaived = {};
            Object.keys(selectedProducts).map((product) => {
                productsWaived[product] = 'waive';
            });
            employeeProductSelection = employeeProductSelection.map((ps) => productsWaived);
        }
        isWaivingAll = employeeInfo.employeeStatus === '1099 Independent Contractors' ? isWaivingAll : waivedProductsCount === numSelectedProducts ? 'Yes' : 'No';
        let renewalPlatform = '';
        if (flow === 'renewals') {
            renewalPlatform = identifyPlatform(fullProps.renewalEnrollment.renewalData);
        }

        let currentStatus = validateEmployeeStatus(waiveCoverage, employeeInfo, contactInfo, medicalCoverageInfo, selectedProducts, productSelection, flow, renewalPlatform, enrolledLifePlans);
        status.push(currentStatus);

        dependents.forEach((dep, index) => {
            currentStatus = selectedProducts.medical ?
                isWaivingAll === 'Yes' && medicalWaiverReason ? 'Complete' : validateDependentStatus(dep.state, employeeInfo, index, selectedProducts, productSelection, companyProfile.dependentRules)
                : isWaivingAll === 'Yes' ? 'Complete' : validateDependentStatus(dep.state, employeeInfo, index, selectedProducts, productSelection, companyProfile.dependentRules)
            status.push(currentStatus);
        });

        const coverages = employees.length > 0 && employees[locationIndex][employeeIndex] && employees[locationIndex][employeeIndex].coverages && !employees[locationIndex][employeeIndex].coverages.coverages
            ? employees[locationIndex][employeeIndex].coverages : employees.length > 0 && employees[locationIndex][employeeIndex] && employees[locationIndex][employeeIndex].coverages.coverages ? employees[locationIndex][employeeIndex].coverages.coverages : {};

        const productCoverages = getCoverageTypeFromSelectedProducts(dependents, productSelection, selectedProducts, coverages, waiveCoverage, isWaivingAll);

        // Using user elected dropdown option to determine which waiver reason to pull from
        // Scenario: If user selects waive all no for employee but waives medical in a medical only case
        // We take the waiver reason from the medicalCoverageInfo
        // This function should keep all waiver reasons in sync when saving/exiting employee form
        const determineWaiverReason = () => {
            if (isWaivingAllSelection === 'No') {
                // medicalCoverageInfo object value
                return medicalCoverageWaiverReason
            }
            else {
                // waiveCoverage object value
                return medicalWaiverReason
            }
        }

        const employee = {
            firstName: employeeInfo.firstName,
            lastName: employeeInfo.lastName,
            middleInitial: employeeInfo.middleInitial,
            middleName: employeeInfo.middleInitial,
            gender: employeeInfo.gender,
            salary: employeeInfo.salary,
            dob: employeeInfo.dob,
            suffix:employeeInfo.suffix,
            optedLifeClass: employeeInfo.optedLifeClass,
            dobInput: true,
            zipCode: contactInfo.zipCode && contactInfo.zipCode.data ? contactInfo.zipCode.data.zipCode : contactInfo.zipCode,
            //status using validation helper
            //status: status.includes('In Progress') ? 'In Progress' : 'Complete',
            //status using inCompleteEmployeeData
            status:inCompleteEmployeeData && inCompleteEmployeeData.inCompletePageNames && inCompleteEmployeeData.inCompletePageNames.includes('employee') ?
                    'In Progress' : 'Complete',
            waiveCoverage: {
                ...waiveCoverage,
                isWaivingAll: employeeInfo.employeeStatus === '1099 Independent Contractors' ? isWaivingAll : isWaivingAll === 'No' ? waivedProductsCount === numSelectedProducts ? 'Yes' : 'No' : isWaivingAll,
                medicalWaiverReason: determineWaiverReason(),
            },
            waiveReason: determineWaiverReason(),
            medicalCoverageInfo: {
                ...medicalCoverageInfo,
                medicalWaiverReason: determineWaiverReason()
            },
            employeeInfo,
            contactInfo,
            dependents: dependents.map((dep) => ({
                ...dep.state,
                dobInput: true,
                suffix:dep.state ? dep.state.suffix : dep.suffix,
                age: fullProps.companyProfile.effectiveDate && !isEmpty(dep.state ? dep.state.dob : dep.dob) ? getAgeFromDOB(dep.state ? dep.state.dob : dep.dob, fullProps.companyProfile.effectiveDate) : '',
            })),
            productSelection: employeeProductSelection,
            pcpAssignment,
            age: fullProps.companyProfile.effectiveDate && !isEmpty(employeeInfo.dob) ? getAgeFromDOB(employeeInfo.dob, fullProps.companyProfile.effectiveDate) : '',
            employeeStatus: employeeInfo.employeeStatus,
            coverages: productCoverages,
            outOfArea: contactInfo && contactInfo.outOfArea ? contactInfo.outOfArea : outOfArea ? outOfArea : '',
            relationship : "Self",
            benefitAmount
        };

        if(isUhcRenewals() && platform && platform === 'PRIME'){
            if(employee.employeeInfo.newEmployee !== false && employeeInfo.ssnTin && employeeInfo.ssnTin.length > 0) {
                employee.memberId = employeeInfo.ssnTin.replaceAll('-','')+'00';
            }
    
            let count = 2;
            employee.dependents.map(dep =>{
                if(dep.newDependent && dep.ssnTin && dep.ssnTin.length > 0) {
                    if(dep.relationship === 'Spouse') {
                        dep.memberId = dep.ssnTin.replaceAll('-','')+'01';
                    } else if (dep.relationship === 'Child') {
                        dep.memberId = dep.ssnTin.replaceAll('-','')+'0'+count;
                        count++;    
                    }
                }
            })
        }

        dispatch({
            type: types.SAVE_INDIVIDUAL_EMPLOYEE,
            employee,
            locationIndex,
            employeeIndex,
        });
        dispatch(createHistory('Employee information saved', 'Enrollment Manager ( Employee Enrollment )', 'Success', employee, '', flow === 'renewals' ? 'RL': 'NB'));

        if (closePage && btn !== 'saveBtn') {
            const { overall, quotes, rules: { enrollment, enrollmentForm: { employee: employeeSections } } } = getState();

            const conditionEvaluators = getConditionEvaluators(overall, enrollment, fullProps);

            dispatch({
                type: types.CLOSE_INDIVIDUAL_EMPLOYEE,
                employee,
                locationIndex,
                employeeIndex,
                employeeSections,
                conditionEvaluators,
            });
        }
    };
}

export function addEnrolledDependent(locationIndex, employeeIndex) {
    return (dispatch, getState) => {
        const { companyProfile, overall, rules: { enrollment } } = getState();
        const products = checkSelectedProducts(overall, enrollment);
        const { employees } = companyProfile;
        const newEmployees = addDependent(
            employees,
            locationIndex,
            employeeIndex,
        );
        const newCompanyProfile = {
            ...companyProfile,
            employees: newEmployees,
        };
        dispatch(updateCompanyProfileState(newCompanyProfile));
        dispatch({ type: types.ADD_ENROLLED_DEPENDENT, products });
    };
}

export function removeEnrolledDependent(
    locationIndex,
    employeeIndex,
    dependentIndex,
) {
    return (dispatch, getState) => {
        const { companyProfile } = getState();
        const { employees } = companyProfile;
        const newEmployees = removeDependent(
            employees,
            dependentIndex,
            employeeIndex,
            locationIndex,
        );
        const newCompanyProfile = {
            ...companyProfile,
            employees: newEmployees,
        };
        dispatch(updateCompanyProfileState(newCompanyProfile));
        dispatch({ type: types.REMOVE_ENROLLED_DEPENDENT, dependentIndex });
    };
}

export function saveEmployerInfoToDB() {
    return (dispatch, getState) => {
        const state = getState();
        const { companyProfile } = state;
        const enrollmentApplication = getState().enrollment.application;
        const { employer, eligibility, producer } = enrollmentApplication;

        const employerEnrollment = {
            employer,
            eligibility,
            producer,
        };
        dispatch({
            type: types.PROFILE_UPDATED,
            companyProfile: {
                ...companyProfile,
                employerEnrollment
            },
        });
    };
}

export function updateCompanyProfile(cmpProfile) {
    return (dispatch, getState) => {
        dispatch({
            type: types.PROFILE_UPDATED,
            companyProfile: {
              ...cmpProfile,
            },
        });
    }
}

export function saveEmployerCertificationToDB() {
    return (dispatch, getState) => {
        const state = getState();
        const { companyProfile } = state;
        const { employerCertification } = state.enrollment.application;
        const { errors, ...restErCert } = employerCertification;
        dispatch({
            type: types.PROFILE_UPDATED,
            companyProfile: {
                ...companyProfile,
                employerCertification: restErCert,
            },
        });
    };
}

export function resetEnrollmentRulesReducer() {
    return (dispatch) => {
        dispatch({ type: types.RESET_ENROLLMENT_RULES });
    };
}

export function hardResetEnrollmentRules() {
    return (dispatch) => {
        dispatch({ type: types.HARD_RESET_ENROLLMENT_RULES });
    };
}

export function saveIndividualEmployee(employee, employeeIndex, locationIndex) {
    return (dispatch, getState) => {
        dispatch({
            type: types.SAVE_INDIVIDUAL_EMPLOYEE,
            employee,
            employeeIndex,
            locationIndex,
        });
    };
}

export function removeAdditionalContact(contactIndex) {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;
        dispatch({
            type: types.REMOVE_ADDITIONAL_CONTACT,
            application,
            contactIndex,
        });
    };
}

export function removeProducer(producerIndex) {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;
        const isUhcRenewal = isUhcRenewals();
        dispatch({
            type: types.REMOVE_PRODUCER,
            application,
            producerIndex,
            isUhcRenewal,
        });
    };
}

export function removeGeneralAgent(generalAgentIndex) {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;
        dispatch({
            type: types.REMOVE_GENERAL_AGENT,
            application,
            generalAgentIndex,
        });
    };
}

export function addAdditionalContactRl(contacts) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_ADDITIONAL_CONTACTS_RL',
            contacts,
        });
        dispatch({
            type: types.ADD_ADDITIONAL_CONTACTS_RL,
            payload: contacts,
        });
    };
}

export function setGeneralAgents(agents) {
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;
        dispatch({
            type: types.SET_GENERAL_AGENTS,
            application,
            agents,
        });
    };
}

export function setProducers(producers) {
    return (dispatch, getState) => {
        const { enrollment,overall } = getState();
        const { application } = enrollment;
        dispatch({
            type: types.SET_PRODUCERS,
            application,
            producers,
            overall
        });
    };
}

export function setCommissionPayableFields(commissionPayableFields){
    return (dispatch, getState) => {
        const { enrollment } = getState();
        const { application } = enrollment;
        dispatch({
            type: types.SET_COMMISSION_PAYABLE_FIELDS,
            application,
            commissionPayableFields
        })
    }
}

export function setGroupNotEligible(bool) {
    return (dispatch) => {
        dispatch({
            type: types.TOGGLE_GROUP_NOT_ELIGIBLE,
            bool,
        });
    };
}

export function resetEmployerAttestation() {
    return (dispatch) => {
        dispatch({
            type: types.RESET_EMPLOYER_ATTESTATION,
        });
    };
}

const getDependentZipData = (zipCode) => {
    if (!isEmpty(zipCode)) {
        return axios.get(`${apiRoutes.quickQuotes}/zip-mappings/${zipCode}`)
            .then((res) => {
                const zipData = res.data[0];
                const parsedData = parseZipObj(zipData);
                return parsedData;
            })
            .catch((err) => {
                console.log(err);
                console.log('Error fetching zip code information');
            });
    }
};
export function updateEmployerEnrollmentValuesFromRenewalToNB(employerEnrollment) {
    return (dispatch) => {
        const updateERArray = Object.keys(employerEnrollment).map((key) => employerEnrollment[key]);
        if (updateERArray && updateERArray.length > 0) {
            for (const [sectionName, sectionNameValue] of Object.entries(updateERArray[0])) {
                for (const [sectionFieldName, sectionFieldValue] of Object.entries(sectionNameValue)) {
                    dispatch({
                        type: types.UPDATE_EMPLOYER_INFO,
                        group: sectionName,
                        update: {
                            [sectionFieldName]: sectionFieldValue,
                        },
                        isError: false,

                    });
                }
            }
            for (const [sectionName, sectionNameValue] of Object.entries(updateERArray[1])) {
                for (const [sectionFieldName, sectionFieldValue] of Object.entries(sectionNameValue)) {
                    dispatch({
                        type: types.UPDATE_ELIGIBILITY_INFO,
                        group: sectionName,
                        update: {
                            [sectionFieldName]: sectionFieldValue,
                        },
                        isError: false,

                    });
                }
            }
        }
    };
}

export const updateNumEnrolledDetail = (numEnrolledPlan, numReviewConsiderPlans, numAdditionalPlans) => (dispatch) => {
    sendLog({
        action: 'UPDATE_NO_OF_ENROLLED_PLANS_DECIDE',
    });
    const numOfPlans = { numEnrolledPlan, numReviewConsiderPlans, numAdditionalPlans };
    dispatch({
        type: types.UPDATE_NO_OF_ENROLLED_PLANS_DECIDE,
        payload: numOfPlans,
    });
};

export const updateEmployeeEnrollmentCompletion = (value) => (dispatch) => {
    sendLog({
        action: 'UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION',
    });
    dispatch({
        type: types.UPDATE_EMPLOYEE_ENROLLMENT_COMPLETION,
        payload: value,
    })
}

// Set external upload boolean for required document, which allows internal
// users to bypass upload if document has already been uploaded/received externally
export const setExternalUploadForRequiredDoc = (docType, externallyUploaded,page='') => {
    return (dispatch,getState) => {
        sendLog({
            action: 'SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT',
            docType,
            externallyUploaded
        });
        dispatch({
            type: types.SET_EXTERNAL_UPLOAD_REQUIRED_DOCUMENT,
            docType,
            externallyUploaded
        });
        if (page === 'renewals'){
            const fullProps = getState();
            const { userName } = fullProps.user;
            const cmpProfile = deepCopy(fullProps.companyProfile);
            cmpProfile.employerEnrollment = saveEmployerToCompanyProfile(cmpProfile, fullProps).employerEnrollment;
            cmpProfile.enrollment = {
                requiredDocs: fullProps.enrollment.documents.requiredDocuments,
            };
            cmpProfile.marketType = getMarketType();
            axios.put(`${apiRoutes.mongo}/profiles?userName=${userName}`, cmpProfile);
        }
    };
};

export const updateEmployerTin = (args) => (dispatch) => {
    dispatch({
        type: types.UPDATE_EMPLOYER_TIN,
        payload: args,
    });
};

export const updateGroupLegalName = (groupLegalName) => {
    return (dispatch) => {
        sendLog({
            action: types.UPDATE_GROUP_LEGAL_NAME,
            groupLegalName
        });
        dispatch({
            type: types.UPDATE_GROUP_LEGAL_NAME,
            payload: groupLegalName
        });
    }
}

export const setMedicalProduct = (product) => {
    return (dispatch) => {
        sendLog({
            action: types.SET_MEDICAL_PRODUCT,
            product
        });
        dispatch({
            type: types.SET_MEDICAL_PRODUCT,
            payload: product
        })
    }
}
export const closeIndividualEmployeeRenewal = () => {
    return (dispatch, getState) => {
        const fullProps = getState();
        const { overall, quotes, rules: { enrollment, enrollmentForm: { employee: employeeSections } } } = getState();

        const conditionEvaluators = getConditionEvaluators(overall, enrollment, fullProps);

        dispatch({
            type: types.CLOSE_INDIVIDUAL_EMPLOYEE,
            employeeSections,
            conditionEvaluators,
        });
    }
}
export const setNumEligEmpChanged = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.NUM_ELIG_EMP_CHANGED,
            payload: data,
        });
    }
};

export const setNumEligibleEmployees = (count) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_NUM_ELIGIBLE_EMPLOYEES,
            payload: count
        })
    }
}

export const updateMedicalEmployerContribution = (value) => {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_MEDICAL_EMPLOYER_CONTRIBUTION,
            payload: value
        })
    }
}

export function updateEnrollmentContribution(query) {
    const contribution ={
        medicalEmployerContribution: query.medEValue.toString(),
        medicalDependentContribution: query.medDValue.toString(),
        dentalEmployerContribution: query.denEValue.toString(),
        dentalDependentContribution: query.denDValue.toString(),
        visionEmployerContribution: query.visEValue.toString(),
        visionDependentContribution: query.visDValue.toString(),
        basicLifeEmployerContribution: query.lifeEValue.toString(),
    }
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_ENROLLMENT_CONTRIBUTION,
            payload: contribution,
        });
    };
}

export const updateEnrolledPlansRates = (rates, productType) => {
    return (dispatch, getState) => {
        const { quotes: { filterCriteria: { ratingMethod } }, rules: { enrollment }, companyProfile: { employees }, specialtyCriteria, overall: { platform } } = getState();
        if (platform === 'PRIME') {
            switch (productType) {
                case 'medical':
                    const updatedMedicalPlans = {};
                    const { enrolledPlans } = enrollment;
                    Object.keys(enrolledPlans).forEach(pkg => {
                        const packagePlans = enrolledPlans[pkg]
                        const updatedPackagePlans = [];
                        packagePlans.forEach(plan => {
                            let updatedPlan = plan;
                            rates.forEach(rate => {
                                if (plan.code === rate.medicalPlanCode) {
                                    const savedPlatformRatings = rate.savedPlatformRatings || [];
                                    const employeeRateType = ratingMethod.age || ratingMethod.tier
                                        ? ratingMethod.age
                                            ? 'employeeRatesAgeBanded'
                                            : 'employeeRatesComposite'
                                        : rate.defaultRatingType === 'ageBands'
                                            ? 'employeeRatesAgeBanded'
                                            : 'employeeRatesComposite';

                                    const totalPremiumKey = ratingMethod.age || ratingMethod.tier
                                        ? ratingMethod.age
                                            ? 'premiumTotalAgeBandedMonthly'
                                            : 'premiumTotalCompositeMonthly'
                                        : rate.defaultRatingType === 'ageBands'
                                            ? 'premiumTotalAgeBandedMonthly'
                                            : 'premiumTotalCompositeMonthly';

                                    const benefitRiderRates = plan.benefitRiderRates ? plan.benefitRiderRates : [];
                                    const updatedEmployeeRates = rate.employeeRatesAgeBanded && rate.employeeRatesComposite ? rate[employeeRateType] : rate.employeeRates
                                    updatedPlan = {
                                        ...updatedPlan,
                                        ...rate,
                                        employeeRates: updatedEmployeeRates,
                                        savedPlatformRatings,
                                        quote: rate[totalPremiumKey],
                                        benefitRiderRates
                                    };
                                }
                            })
                            updatedPackagePlans.push(updatedPlan);
                        })
                        updatedMedicalPlans[pkg] = updatedPackagePlans;
                    })
                    dispatch({
                        type: types.UPDATE_MEDICAL_ENROLLED_PLANS,
                        enrolledPlans: updatedMedicalPlans
                    })
                    break;
                case 'dental':
                case 'vision':
                case 'life':
                case 'std':
                    let capitalizeProductType = productType.charAt(0).toUpperCase() + productType.slice(1);

                    if (capitalizeProductType === 'Std' || capitalizeProductType === 'Ltd') {
                        capitalizeProductType = capitalizeProductType.toUpperCase();
                    }
                    
                    const updatedSpecialtyPlans = [];
                    const enrolledSpecialtyPlans = enrollment[`enrolled${capitalizeProductType}Plans`];
                    enrolledSpecialtyPlans.forEach(plan => {
                        let updatedPlan = plan;
                        rates.forEach(rate => {
                            if (plan.code === rate.code || plan.code === rate.blCode) {
                                if (productType === 'life') {
                                    updatedPlan = {
                                        ...updatedPlan,
                                        ...rate,
                                        quote: rate,
                                        finalMonthlyPremium: rate.totalMonthlyPremium,
                                        finalRates: rate,
                                        code: updatedPlan.code
                                    }
                                } else {
                                    updatedPlan = {
                                        ...updatedPlan,
                                        ...rate,
                                        quote: rate.byCensus.totalMonthlyPremium,
                                        finalMonthlyPremium: rate.byCensus.totalMonthlyPremium,
                                        finalRates: rate.monthlyPremium
                                    }
                                }
                            }
                        })
                        updatedSpecialtyPlans.push(updatedPlan);
                    })
                    dispatch({
                        type: types[`UPDATE_ENROLLED_${productType === 'life' ? 'BASIC_LIFE' : productType.toUpperCase()}_PLANS`],
                        plans: updatedSpecialtyPlans
                    })
                    break;
                case 'dependentLife':
                    const updatedDependentLifePlans = [];
                    const { enrolledDependentLifePlans } = enrollment;
                    enrolledDependentLifePlans.forEach(plan => {
                        let updatedPlan = plan;
                        rates.forEach(rate => {
                            if (rate.blCode === plan.code || rate.code === plan.primePlanCode) {
                                const calculatedFinalMonthlyPremium = calculateDependentLifeRate(employees, specialtyCriteria, rate);
                                updatedPlan = {
                                    ...updatedPlan,
                                    quote: rate,
                                    finalMonthlyPremium: calculatedFinalMonthlyPremium,
                                    prevFinalMonthlyPremium: calculatedFinalMonthlyPremium,
                                    finalRates: rate
                                }
                            }
                        })
                        updatedDependentLifePlans.push(updatedPlan);
                    })
                    dispatch({
                        type: types.UPDATE_DEPENDENT_LIFE_ENROLLED_PLANS,
                        plans: updatedDependentLifePlans
                    })
                    break;
            }
        }
    }
}

export const savePcpAutoAssignmentRules = (rules) => {
    return (dispatch) => {
        dispatch({
            type: types.SAVE_PCP_AUTO_ASSIGNMENT_RULES,
            rules
        })
    }
}


export const enableFinalizeEnrollment = (value, product) => {
    return (dispatch) => {
        dispatch({
            type: types.ENABLE_FINALIZE_ENROLLMENT,
            payload: value,
            product
        })
    }
}

export const setPlanInstallPlatforms = (productTypeList) => {
    return (dispatch) => {
        const productMap = {
            'MD': 'Medical',
            'DN': 'Dental',
            'VS': 'Vision',
        };
        productTypeList.forEach(productType => {
            const installPlatform = productType.productPlatformName === 'LEGACY' ? 'PRIME' : 'CIRRUS';
            let plans = [];
            let product = '';
            // Build list of plans and their associated install platform
            if (productType.uhcBenefitMedicalList) { // M/D/V
                plans = productType.uhcBenefitMedicalList;
                product = productMap[plans[0].benefitType];
            } else if (productType.uhcFinancialProtectionList) { // BL
                plans = productType.uhcFinancialProtectionList;
                product = 'Life';
            }
            // Set install platform for each plan
            plans.forEach(plan => {
                if (plan.externalBenefitPlanType !== 'RX') {
                    dispatch({
                        type: types.SET_PLAN_INSTALL_PLATFORM,
                        planCode: plan.uhcBenefitID,
                        product,
                        installPlatform,
                    });
                }
            });
        });
    };
};

export const setEmployerEnrollmentComplete = (isComplete) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_EMPLOYER_ENROLLMENT_COMPLETE,
            isComplete
        })
    }
}

export const resetSuppLifeEnrolledPlan = () => {
  return (dispatch) => {
    dispatch({
      type: types.SERVICE.SUPPLIFE.CLEAR_ENROLLED_PLANS,
    });
  };
};