import * as types from '../../actions/actionTypes';

// Todo: Complete integration with req docs.
// dep: Employer App Completion
const initialState = {
    isFetchingForm: false,
    isFormError: false,
    formFetched: false,
    attestation: [],
    employer: [],
    eligibility: [],
    producer: [],
    standardHRA: [],
    isComplete: false,
    updateFuncsRun: false,
    shouldInitializeForm: true
};

export default function resize(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_ENROLLMENT_FORM:
            return {
                ...state,
                formFetched: false,
                isFetchingForm: true,
                isFormError: false,
            };

        case types.RECEIVE_ENROLLMENT_FORM:
            return {
                ...state,
                isFetchingForm: false,
                formFetched: true,
                employer: action.employer,
                eligibility: action.eligibility,
                producer: action.producer,
                attestation: {
                    employer: action.attestation,
                    employee: action.employeeAttestation,
                },
                employee: action.employee,
                employerCertification: action.employerCertification,
                standardHRA: action.standardHRA
            };
        case types.ERROR_ENROLLMENT_FORM:
            return {
                ...state,
                isFetchingForm: false,
                isFormError: true,
            };

        case types.UPDATE_ENROLLMENT_FORM_FUNCS:
            return {
                ...state,
                updateFuncsRun: true,
            };
        case types.SET_SHOULD_INITIALIZE_ENROLLMENT_FORM:
            return {
                ...state,
                shouldInitializeForm: action.flag
            }
        case types.RESET_ENROLLMENT_FORM:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
            return initialState;

        default:
            return state;
    }
}
