export const dependent = (isNewYork, state, employee = null, platform = null) => {

    let dependentJSON = {
        columns: [
            {
                name: 'Relationship',
                keyName: 'relationship',
                type: 'dropdown',
                options: generateRelationshipTypeOptions(state, platform),
                validationRules: {
                    required: isRelationshipRequired(employee),
                    coverages: true
                },
            },
            {
                name: 'Date of Birth',
                keyName: 'dob',
                type: 'text',
                validationRules: {
                    required: true, //Earlier required for all except Oxford Cirrus NY, now for it too as a part of US5493103
                    autoDate: true,
                    date: true,
                    validAge: true
                },
                placeholder: 'MM/DD/YYYY',
                width: 2,
            },
            {
                name: 'Age',
                keyName: 'age',
                type: 'text',
                validationRules: {
                    required: true, //Earlier required for all except Oxford Cirrus NY, now for it too as a part of US5493103
                    age: true,
                },
                width: 2,
            },
            {
                name: 'Gender',
                keyName: 'gender',
                type: 'dropdown',
                options: isNewYork ? ['Male', 'Female', 'Non-Binary'] : ['Male', 'Female'],
                validationRules: {
                    required: true, //Earlier required for all except Oxford Cirrus NY, now for it too as a part of US5493103
                },
                width: 2,
            },
        ],
        itemNames: {
            singular: 'Dependent',
            plural: 'Dependents',
            keyName: 'dependents',
        },
    }

    return dependentJSON;
};

const isRelationshipRequired = (employee) => {
    let nonRequiredVals = ['EE', "W"]

    if (!employee) {
        return false
    }

    let result = false

    for (const val of Object.values(employee.coverages)) {
        if (!nonRequiredVals.includes(val)) {
            result = true
        }
    }

    return result
}

export const qqDependent = {
    columns: [
        {
            name: 'Relationship',
            keyName: 'relationship',
            type: 'dropdown',
            options: ['Spouse', 'Child'],
            validationRules: {
                required: true,
            },
            width: 2,
        },
        {
            name: 'Date of Birth',
            keyName: 'dob',
            type: 'text',
            validationRules: {
                required: true,
                date: true,
            },
            placeholder: 'MM/DD/YYYY',
            width: 2,
        },
        {
            name: 'Age',
            keyName: 'age',
            type: 'text',
            validationRules: {
                required: true,
                age: true,
            },
            width: 2,
        },
        {
            name: 'Gender',
            keyName: 'gender',
            type: 'dropdown',
            options: ['Male', 'Female'],
            validationRules: {
                required: true,
            },
            width: 2,
        },
    ],
    itemNames: {
        singular: 'Dependent',
        plural: 'Dependents',
        keyName: 'dependents',
    },
};

export const generateRelationshipTypeOptions = (state, platform) => {
    switch (state) {
        case 'CA':
        case 'NY':
            return ['Spouse', 'Child', 'Domestic Partner'];
        case 'CT':
            return ['Spouse', 'Child', 'Domestic Partner', 'Civil Union Partner'];
        case 'NJ':
            if (platform && platform.toUpperCase() === 'PRIME') {
                return ['Spouse', 'Child', 'Domestic Partner', 'Civil Union Partner'];
            } else {
                return ['Spouse', 'Dependent Child', 'Domestic Partner', 'Civil Union Partner'];
            }
        case 'NH':
            if (platform && platform.toUpperCase() === 'PRIME') {
                return ['Spouse', 'Domestic Partner', 'Child'];
            }
            else {
                return ['Spouse', 'Domestic Partner', 'Dependent Child'];
            }
        default:
            return ['Spouse', 'Child', 'Domestic Partner', 'Civil Union Partner'];  //Temp unitl Prime figures out what they need for particular states
    }

}

export const stateCheck = (isNY, isCT, isNJ, isNH) => {

    if (isNY) {
        return 'NY'
    } else if (isCT) {
        return 'CT'
    } else if (isNJ) {
        return 'NJ'
    } else if (isNH) {
        return 'NH'
    }

}
