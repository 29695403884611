import FormData from 'form-data';
import saveAs from 'file-saver';
import { axios } from './axiosConfig';
import apiRoutes from './apiRouteConfig';
import { createHistory } from '../../actions/historyAction';
import { buildWagesAndTaxRequest } from '../../reducers/products/enrollment/helper';
import sendNessLog from '../../actions/logSecurityAudits';

const pollingInterval = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const checkUpload = async (transactionId, attemptsCount = 0) => {
    if (attemptsCount === 15) {
        throw new Error('backGroundProcess');
    }

    return axios.get(`${apiRoutes.quickQuotes}/file/checkUpload?transactionId=${transactionId}`)
        .then(async (result) => {
            if (!result.data.fileMetadata) {
                const updatedAttempt = attemptsCount + 1;
                await pollingInterval(3000);
                return checkUpload(transactionId, updatedAttempt);
            }

            return result;
        })
        .catch((err) => {
            throw err;
        });
};

/**
 * Upload a file to S3 through the service layer
 * @param {File} file The file data to be uploaded
 */
export const uploadFile = async (file, enrollmentData, transactionType, caseId, quoteTrackingNumber, category, companyProfile, caseTrackingCaseId , platform,env) => {
    const data = new FormData();
    data.append('file', file);
    data.append('fileName', file.name);

    // Unique case identifier is the memberGroupId for renewals, and the caseId for full shopping
    data.append('uniqueCaseId', (transactionType === 'renewals' ? enrollmentData : caseId));
    data.append('transactionType', transactionType);
    data.append('quoteTrackingNumber', quoteTrackingNumber);

    return axios.post(`${apiRoutes.quickQuotes}/file/startUpload`, data)
        .then(async (result) => {
            console.log(result);
            const uploadResult = await checkUpload(result.data.transactionId);
            sendNessLog({
                message: "Document Upload success",
                logClass:"SECURITY_SUCCESS",
                severity: "INFO"
            })
            
            if (category && platform && env && category === "wagesAndTax" && platform==="CIRRUS" && (env =='prod' || env=='stage' ) && transactionType !== 'renewals' && (companyProfile && companyProfile.locations && companyProfile.locations.length > 0 && companyProfile.locations[0].zipData && companyProfile.locations[0].zipData.stateCode && companyProfile.locations[0].zipData.stateCode === "NY")) {
                data.append('enrollmentData', JSON.stringify(buildWagesAndTaxRequest(enrollmentData, companyProfile, caseTrackingCaseId)))

                axios.post(`${apiRoutes.quickQuotes}/file/validateWagesAndTax`, data)
                    .then(validationResult => {
                        console.log("Validation Result : ", validationResult.data);
                        
                        if(validationResult.data.docType != "Invalid Document" ){
                            data.append('id', validationResult.data.id)
                            data.append('docType', validationResult.data.docType)
                            data.append('pageNumber', validationResult.data.pageNumber)

                            axios.post(`${apiRoutes.quickQuotes}/file/extractFields`, data)
                                .then(extractionResult => {
                                    console.log("Extraction Field Result : ", extractionResult.data);
                                })
                                .catch((err) => {
                                    console.log('Error occured while extracting field values from Wages and Tax document : ',err);
                                });
                        }
                    })
                    .catch((err) => {
                        console.log('Error occured while validating Wages and Tax document : ', err);
                    })
            }
            return uploadResult.data.fileMetadata;
        })
        .catch((err) => {
            if (err === 'checkUpload') {
                console.log('Issue with checking the file upload process');
            } else if (err === 'backGroundProcess') {
                console.log('Issue with upload background process');
            } else {
                console.log('Issue with starting file upload process');
            }

            // Replace this when plan in place for failed upload
            return 'error';
        });
};

/**
 * Download a previously uploaded file from S3 through the service layer
 * @param {Object} file The file object should contain 'clientFileName' and 'cloudFileName' fields
 */
export const downloadFile = (file, pageType = '') => async (dispatch) => {
    let blob;
    await axios.get(`${apiRoutes.quickQuotes}/file/${file.cloudFileName}`, { responseType: 'blob' })
        .then((result) => {
            if (result.data) { blob = result.data; }
            if (pageType === 'newBuisness' || pageType === 'newBusiness') { dispatch(createHistory('Required Doc file Viewed', 'Enrollment Manager ( Required Document )', 'Success', file.cloudFileName, '', 'NB')); }
            saveAs(blob, file.clientFileName);
            sendNessLog({
                message: "Document download success",
                logClass:"SECURITY_SUCCESS",
                severity: "INFO"
            })
        })
        .catch((error) => {
            if (pageType === 'newBuisness' || pageType === 'newBusiness') { dispatch(createHistory('Required Doc file Viewed', 'Enrollment Manager ( Required Document )', 'Error', error, '', 'NB')); }
        });
};

export const deleteMongoFile = async (s3File) => axios.put(`${apiRoutes.quickQuotes}/file/${s3File}`)
    .catch((err) => {
        console.log(err, ` Error deleting file ${s3File} from s3`);
        return 'error';
    });
