import { axios } from '../../../utils/api/axiosConfig';

const apiRoutes = require('../../../utils/api/apiRouteConfig');

export function getStateStore(caseId, quoteId) {
    return new Promise((resolve, reject) => {
        axios.get(`${apiRoutes.mongo}/stateStore/${caseId}/${quoteId}`)
            .then((resp) => {
                resolve(resp.data);
            }).catch((err) => {
                console.log('Error', err);
                reject();
            });
    });
}
