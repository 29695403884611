export const newPcpAssignmentObject = {
    assignmentMethod: '',
    pcpCode: '',
    dependentsAssigned: [],
    existingPatient: ''
};

export const makeNewPcpAssignmentArray = (members = []) => {
    const pcpAssignment = [];
    for (let i = 0; i < members.length; i++) {
        pcpAssignment.push(newPcpAssignmentObject);
    }
    return pcpAssignment;
};
