import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { initializeAppConfig, resetRedux } from "./actions/overallActions";
import { getPermissions } from "./actions/permissionsActions";
import updateScreenSize from "./actions/screenSizeActions";
import ContentContainer from "./components/shared/ContentContainer";
import Footer from "./components/shared/Footer";
import TopHeader from "./components/shared/Header/TopHeader";
import ErrorHandlingModalPopUp from "./components/shared/ModalPopUp/ErrorHandlingModalPopup";
import "./components/shared/shared.css";
import "./components/shared/Styles/global.css";
import { history } from "./utils/history";
// import { isAuthed } from './utils/jwt/authEnv';
// import { verifyJWT } from './utils/jwt/checkSAMtoken';
import Cookies from "js-cookie";
// TODO: need more confirmation
import ErrorBoundary from "./errorBoundary/ErrorBoundary"; // Error boundery to handle page break
import { Dimmer, Loader } from "semantic-ui-react";
// import AxiosMiddleware from './hoc/AxiosConfiguration/AxiosMiddleware';

let TopHeaderLocal;
if (process.env.REACT_APP_ENV === "local") {
  TopHeaderLocal = require("./components/shared/Header/TopHeaderLocal").default;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    const userProfile =
      Cookies.get("userProfile") != undefined
        ? JSON.parse(Cookies.get("userProfile"))
        : undefined;
    if (userProfile && userProfile.token) {
      sessionStorage.setItem("dn-sm-session", "true");
    }

    if (process.env.REACT_APP_ENV === "local") {
      sessionStorage.setItem("dn-sm-session", "true");
    }
    this.state = {
      isAppConfigInitialized: false,
      isAppConfigError: false,
    };

    /*
        const urlParams = window.location.search.split('?token=');
        sessionStorage.setItem('dn-sm-session',true);
        if (urlParams.length > 1) {
            const token = window.location.search.split('?token=')[1];
            verifyJWT(token);
        } else if (!isAuthed) {
            // token has expired
            history.replace('/expired');
        }*/

    // FOR TESTING: uncomment the line below to see what the app would look like if user only has view permissions
    // this.props.getPermissions('createCopyQuotes,viewQuotes,editWithdrawQuotes,editEnrollment,viewManagerAndReqDocs,viewPendManager,editPendManager');

    // Viewing Enrollment
    // this.props.getPermissions('createCopyQuotes,viewQuotes,editWithdrawQuotes,viewManagerAndReqDocs');

    // Edit Enrollment
    // this.props.getPermissions('createCopyQuotes,viewQuotes,editWithdrawQuotes,viewManagerAndReqDocs,editEnrollment,viewPendManager,editPendManager');

    // Disabled Enrollment
    // this.props.getPermissions('createCopyQuotes,editWithdrawQuotes');

    // To test disabling on Case Progress Submitted
    // this.props.updateCaseProgress(CASE_PROGRESS.SUBMITTED);
  }

  componentDidMount() {
    if (window.location.href.indexOf("/login") > -1) {
      this.props.resetRedux();
    }
    
    const { initializeAppConfig: propsInitializeAppConfig } = this.props;   //Call here to get that data

    if (sessionStorage.getItem("dn-sm-session") === "true") {
      propsInitializeAppConfig()    //Seems to be first call that utilizes the authorization pieces
        .then(() => {
          this.setState({
            isAppConfigInitialized: true,
            isAppConfigError: false,
          });
        })
        .catch(() => {
          this.setState({
            isAppConfigInitialized: false,
            isAppConfigError: true,
          });
        });
    }
    
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
    localStorage.clear();
  }

  componentDidUpdate(prevProps, prevState) {
    // Initialize app config again when user logs out (since entire redux state gets cleared)
    if (
      this.props.loginData === "" &&
      this.props.loginData !== prevProps.loginData
    ) {
      const { initializeAppConfig: propsInitializeAppConfig } = this.props;
      if (sessionStorage.getItem("dn-sm-session") === "true") {
        propsInitializeAppConfig();
      }
    }
    if (
      prevState.isAppConfigInitialized === false &&
      this.state.isAppConfigInitialized === true &&
      this.props.appConfigDetails.isAppDown === true
    ) {
      history.replace("/app-down-maintainence");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    const { updateScreenSize: propsUpdateScreenSize } = this.props;
    propsUpdateScreenSize(window.innerWidth);
  }

  bindTopHeader(pathname) {
    let topHeader;
    if (process.env.REACT_APP_ENV === "local") {
      topHeader =
        process.env.REACT_APP_ISLOCAL === "true" ? (
          <TopHeaderLocal pathname={pathname} history={history} />
        ) : (
          <TopHeader pathname={pathname} history={history} />
        );
    } else {
      topHeader = <TopHeader pathname={pathname} history={history} />;
    }
    return topHeader;
  }

  loading = (
    <Dimmer active inverted>
      <Loader inverted>Loading...</Loader>
    </Dimmer>
  );

  render() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;
    const disableFooter =
      pathname === "/plans" ||
      pathname === "/employee" ||
      pathname === "/owner-assignment" ||
      this.props.appConfigDetails.isAppDown;
    if (this.state.isAppConfigInitialized) {
      return (
        <ErrorBoundary error={false}>
          {/* <AxiosMiddleware> */}
          <div className="app-container">
            {!this.props.appConfigDetails.isAppDown &&
              this.bindTopHeader(pathname)}
            <div className="contentWrapper">
              <ContentContainer />
            </div>
            {!disableFooter && (
              <Footer
                className="bottomFooter"
                pathname={pathname}
                history={history}
              />
            )}
            <ErrorHandlingModalPopUp />
          </div>
          {/* </AxiosMiddleware> */}
        </ErrorBoundary>
      );
    } else if (this.state.isAppConfigError) {
      return <ErrorBoundary error={true} />;
    }
    return this.loading;
  }
}

const mapStateToProps = (state) => ({
  loginData: state.renewals.logInData,
  appConfigDetails: state.overall.appConfigDetails,
});

const mapDispatchToProps = {
  updateScreenSize,
  initializeAppConfig,
  getPermissions,
  resetRedux,
};

App.propTypes = {
  updateScreenSize: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  initializeAppConfig: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
