import { isValidDate, isEmpty } from '../../../../../utils/formatters/strings';
import { isValidCurrency, getAge } from '../../../../../utils/formatters/numbers';
import { atLeastOneTrue } from '../../../../../utils/formatters/objects';
import { isUndefinedOrNullOrEmpty } from '../../../../../utils/validation/commonValidations';
import { isUhcRenewals } from '../../../../../utils/businessLogic/platformUtils';

/**
 * @description Validates employee information fields before saving status to redux
 *
 * @param {Object} employeeInfo Information related to employee
 * @param {Object} contactInfo Information related to employee's address
 * @param {Object} medicalCoverageInfo Information related to employee's medicalCoverage
 * @param {Object} selectedProducts Object representing the products that the group is enrolling in
 * @param {Object} productSelection Object representing the products that this employee and dependents are enrolling in
 * @returns {String} String representing the status of employee based on fields in employeeInfo and contactInfo objects
 */
export const validateEmployeeStatus = (waiveCoverage, employeeInfo, contactInfo, medicalCoverageInfo, selectedProducts, productSelection, flow = '', renewalPlatform = '', enrolledLifePlans = []) => {
    let currentStatus = 'Complete';
    const { isWaivingAll, medicalWaiverReason: medicalWaiverReasonWhenWaiveAll } = waiveCoverage || {};
    const { medicalWaiverReason, medicalWaived } = medicalCoverageInfo || {};
    const {
        anyOtherCoverage, dateOfHire, dob, gender, firstName, lastName, medicarePrimary, nameOtherCarrier, noSSNReason,
        numberOfHoursWorked, salary, ssnCheck, ssnTin, employeeStatus, cobraStartDate, cobraEndDate, errors, optedLifeClass,
    } = employeeInfo;

    if (isWaivingAll === 'Yes') {
        if (selectedProducts.medical) {
            currentStatus = !medicalWaiverReasonWhenWaiveAll || firstName === '' || lastName === '' ? 'In Progress' : currentStatus;
        } else {
            currentStatus = isWaivingAll === 'Yes' && (firstName === '' || lastName === '') ? 'In Progress' : currentStatus;
        }
        return currentStatus;
    }
    // validate medicalCoverageInfo
    if (selectedProducts.medical) {
        currentStatus = medicalWaived && !medicalWaiverReason ? 'In Progress' : currentStatus;
    }
    // validate employeeInfo section
    currentStatus = firstName === '' || lastName === '' || gender === '' || employeeStatus === '' || (employeeStatus === 'Active' && numberOfHoursWorked === '' && flow !== 'renewals') ? 'In Progress' : currentStatus;
    currentStatus = dob === null || (dob && !isValidDate(dob)) ? 'In Progress' : currentStatus;
    // TODO: Refactor validation checks as part of future US
    currentStatus = isUndefinedOrNullOrEmpty(dateOfHire) || (new Date(dateOfHire) < new Date(dob)) || (dateOfHire && (!isValidDate(dateOfHire) || (errors && errors.employeeInfo && errors.employeeInfo.dateOfHire !== null))) ? 'In Progress' : currentStatus;
    // validate ssn
    if (ssnCheck) {
        currentStatus = noSSNReason === '' ? 'In Progress' : currentStatus;
    } else {
        currentStatus = ssnTin === '' ? 'In Progress' : currentStatus;
    }
    // only make salary required when they have selected life
    const isSalaryRequired = checkIfSalaryIsMandatory(selectedProducts, enrolledLifePlans, employeeStatus, optedLifeClass);
    currentStatus = isSalaryRequired && employeeInfo.salary === '' ? 'In Progress' : currentStatus;

    if (selectedProducts.medical) {
        currentStatus = anyOtherCoverage === '' || (anyOtherCoverage === 'Yes' && nameOtherCarrier === '') ? 'In Progress' : currentStatus;
    }

    currentStatus = medicarePrimary === '' ? 'In Progress' : currentStatus;
    if (medicarePrimary === 'Yes') {
        const {
            medicareClaimNum, medicareCoverages, medicarePartAStartDate, medicarePartBStartDate, medicarePartDStartDate,
        } = employeeInfo;
        currentStatus = medicareClaimNum === '' ? 'In Progress' : currentStatus;
        if (medicareCoverages && medicareCoverages.includes('Part A')) {
            currentStatus = !isValidDate(medicarePartAStartDate) ? 'In Progress' : currentStatus;
        }
        if (medicareCoverages && medicareCoverages.includes('Part B')) {
            currentStatus = !isValidDate(medicarePartBStartDate) ? 'In Progress' : currentStatus;
        }
        if (medicareCoverages && medicareCoverages.includes('Part D')) {
            currentStatus = !isValidDate(medicarePartDStartDate) ? 'In Progress' : currentStatus;
        }
    }

    // Check for Cobra start & end date if employee status is Cobra
    if (employeeStatus === 'COBRA') {
        currentStatus = cobraStartDate === '' || cobraStartDate === null ? 'In Progress' : currentStatus;
        currentStatus = cobraEndDate === '' || cobraEndDate === null ? 'In Progress' : currentStatus;
    }
    // Check the contact info required fields
    const {
        city, state, streetAddress, zipCode, outOfArea, county,
    } = contactInfo;
    if (flow === 'renewals') {
        currentStatus = city === '' || state === '' || streetAddress === '' || JSON.stringify(zipCode) === '{}' || outOfArea === '' ? 'In Progress' : currentStatus;
    } else {
        currentStatus = city === '' || state === '' || streetAddress === '' || !(typeof zipCode === 'object' && zipCode.hasOwnProperty('data')) || outOfArea === '' || county === '' ? 'In Progress' : currentStatus;
    }
    const employeeProductSelection = productSelection[0];
    Object.keys(selectedProducts).forEach((product) => {
        if (!(product === 'life' && employeeStatus === 'COBRA') && selectedProducts[product] && employeeProductSelection && (isUndefinedOrNullOrEmpty(employeeProductSelection[product]) || employeeProductSelection[product] === 'select')) {
            currentStatus = 'In Progress';
        }
    });
    return currentStatus;
};
/**
 * @description Validates employee information fields before saving status to redux
 *
 * @param {Object} dependentInfo Information related to dependent
 * @param {Object} employeeInfo Information related to employee
 * @param {Number} dependentIndex Information related to employee
 * @param {Object} productSelection Object representing the products that this employee and dependents are enrolling in
 *
 * @returns {String} String representing the status of employee based on fields in employeeInfo and contactInfo objects
 */
export const validateDependentStatus = (dependentInfo, employeeInfo, dependentIndex, selectedProducts, productSelection, dependentRules) => {
    const {
        addressDifferentThanEmployee,
        age,
        dob,
        firstName,
        gender,
        lastName,
        medicarePrimary,
        noSSNReason,
        relationship,
        ssnCheck,
        ssnTin,
        isDisabled,
        otherCarrier,
    } = dependentInfo;
    const { store } = require('../../../../../index');
    const fullProps = store.getState();
    const { enrolledDependentLifePlans } = fullProps.rules.enrollment;
    let currentStatus = 'Complete';
    // if the dependent address is different
    // TODO figure out why state field not poping up for dependents
    if (addressDifferentThanEmployee === 'Yes') {
        const {
            city, state, streetAddress, zipCode,
        } = dependentInfo;
        currentStatus = city === '' || state === '' || streetAddress === '' || zipCode === '' ? 'In Progress' : currentStatus;
    }
    currentStatus = !isValidDate(dob) ? 'In Progress' : currentStatus;
    currentStatus = firstName === '' || lastName === '' || gender === '' || relationship === '' ? 'In Progress' : currentStatus;
    // if the ssn is not provided make sure there is a reason
    if (ssnCheck) {
        currentStatus = noSSNReason === '' ? 'In Progress' : currentStatus;
    } else {
        currentStatus = ssnTin === '' ? 'In Progress' : currentStatus;
    }
    if (medicarePrimary === 'Yes') {
        const {
            medicareClaimNum, medicareReason, medicareCoverages, medicarePartAStartDate, medicarePartBStartDate, medicarePartDStartDate,
        } = dependentInfo;
        currentStatus = medicareClaimNum === '' ? 'In Progress' : currentStatus;
        currentStatus = medicareReason === '' ? 'In Progress' : currentStatus;
        if (medicareCoverages) {
            if (medicareCoverages.includes('Part A')) {
                currentStatus = !isValidDate(medicarePartAStartDate) ? 'In Progress' : currentStatus;
            }
            if (medicareCoverages.includes('Part B')) {
                currentStatus = !isValidDate(medicarePartBStartDate) ? 'In Progress' : currentStatus;
            }
            if (medicareCoverages.includes('Part D')) {
                currentStatus = !isValidDate(medicarePartDStartDate) ? 'In Progress' : currentStatus;
            }
        }
    }
    if (isDisabled === 'No') {
        const adultDependent = fullProps.enrollment.application.eligibility.participation.adultDependent
        const effectiveMaxAge = adultDependent === 'Yes' ? "29" : fullProps.companyProfile.dependentRules.maxAge;
        if ((relationship === 'Child' || relationship === 'Dependent Child') && age > parseInt(effectiveMaxAge)) {
            currentStatus = 'In Progress';
        }
    }
    if (isDisabled === 'Yes') {
        const { disabilityType } = dependentInfo;
        currentStatus = disabilityType === '' ? 'In Progress' : currentStatus;
    }
    if (employeeInfo.anyOtherCoverage === 'Yes') {
        const { nameOtherCarrier } = dependentInfo;
        currentStatus = otherCarrier === '' ? 'In Progress' : currentStatus;
        if (otherCarrier === 'Yes') {
            currentStatus = nameOtherCarrier === '' ? 'In Progress' : currentStatus;
        }
    }

    const dependentProductSelection = productSelection[dependentIndex + 1];
    if (dependentProductSelection) {
        Object.keys(selectedProducts).forEach((product) => {
            if (!(product === 'life' && employeeInfo.employeeStatus === 'COBRA')) {
                if (!(product === 'life' && (enrolledDependentLifePlans || []).length === 0) && selectedProducts[product] && (!dependentProductSelection[product] || dependentProductSelection[product] === '' || dependentProductSelection[product] === 'select')) {
                    currentStatus = 'In Progress';
                }
            }
        });
    } else {
        currentStatus = 'In Progress';
    }

    return currentStatus;
};

export const isValidCoverage = (item) => {
    let coverageError = null;

        const { store } = require('../../../../../index');
        const selectedProducts = store.getState().specialtyCriteria.viewSpecialty;

        // Indicates user has selected coverage other that EE
        let containsSpouse = false;
        let containsChild = false;

        // Evaluate each selected product
        Object.keys(selectedProducts).forEach((product) => {
            if (selectedProducts[product] === true && item.coverages) {
                // Convert life product format
                if (product === 'life') { product = 'basicLife'; }

                // Evaluate employee's product coverages
                if (item.coverages[product] !== 'EE' && item.coverages[product] !== 'W' && item.coverages[product] !== 'EL' && item.coverages[product] !== 'NA' ) {
                    // Indicates user has selected coverage other that EE
                    if (!item.dependents?.length) {
                        coverageError = 'This EE does not contain dependents';
                    } else {
                        // Return validation error message if EE coverage requires dependents
                        for (const dependent of item.dependents) {
                            if (dependent.relationship === 'Spouse' || dependent.relationship === 'Life Partner'
                                || dependent.relationship === 'Civil Union Partner' || dependent.relationship === 'Domestic Partner') {
                                containsSpouse = true;
                                // Temporarily not necessary, can add dependents without coverage
                                // if (!item.coverages[product].includes("EE/SP") && !item.coverages[product].includes("EE/FAM")) {
                                //     error = "This EE contains a Spouse that is not covered under one or more products"
                                // }
                            }
                            if (dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                                containsChild = true;
                                // Temporarily not necessary, can add dependents without coverage
                                // if (!item.coverages[product].includes("EE/CH") && !item.coverages[product].includes("EE/FAM")) {
                                //     error = "This EE contains a Child that is not covered under one or more products"
                                // }
                            }
                        }

                        // Employee has coverage for dependents that have not been specified
                        if (item.coverages[product] === 'EE/FAM' && (containsSpouse === false || containsChild === false)) {
                            coverageError = 'One or more products indicates EE/FAM coverage without either a Child or Spouse';
                        }
                        if (item.coverages[product] === 'EE/SP' && containsSpouse === false) {
                            coverageError = 'One or more products indicates EE/SP coverage without a Spouse';
                        }
                        if (item.coverages[product] === 'EE/CH' && containsChild === false) {
                            coverageError = 'One or more products indicates EE/CH coverage without a Child';
                        }
                    }
                }
            }
        });
    return coverageError;
};

// Returns null if no errors, or a string error message if validation is failed
// ensures constrained field is logically correct
export const validateInput = (value, validationRules, enrolleeData = null) => {
    if (validationRules.required && (value === null || value === '')) {
        return 'Required field';
    }
    if (validationRules.date) {
        if (!isValidDate(value)) {
            if (validationRules.required) {
                return 'Please enter a valid date';
            }
            if (!validationRules.required && value !== '') {
                return 'Please enter a valid date or leave blank';
            }
        }
    }
    if (validationRules.currency) {
        if (!isValidCurrency(value)) {
            if (validationRules.required) {
                return 'Please enter a valid dollar amount';
            }
            if (!validationRules.required && value !== '') {
                return 'Please enter a valid dollar amount or leave blank';
            }
        }
    }
    if (validationRules.atLeastOne) {
        if (!atLeastOneTrue(value)) {
            return 'Please select at least one';
        }
    }

    if (validationRules.coverages) {
        const coverageError = isValidCoverage(value);
        return coverageError;
    }

    if (validationRules.validAge) {
        if (!isEmpty(enrolleeData)) {
            if (isValidDate(value)) {
                if (new Date(value) > new Date()) {
                    return 'Must not be a future date';
                }

                if (enrolleeData.relationship && (enrolleeData.relationship === 'Child' || enrolleeData.relationship === 'Dependent Child')) {
                    if (getAge(new Date(value)) < 0 || getAge(new Date(value)) > 115) {
                        return 'Must be between 0 - 115 years';
                    }
                } else {
                    if (getAge(new Date(value)) < 14 || getAge(new Date(value)) > 115) {
                        return 'Must be between 14 - 115 years';
                    }
                }
            }
        }
    }

    if (validationRules.zipCode) {
        if (!isValidZipcode(value) && value !== '') {
            return 'Please enter correct zipcode';
        }
    }

    return null;
};

const isValidZipcode = (value) => {
    const zipCodeRegex = /\d{5}/;
    if (zipCodeRegex.test(value)) {
        return true;
    } else {
        return false;
    }
}

// Returns a map with keys for each field in an object whose values are the result of validateInput()
const validateAllFields = (object, objectType) => {
    const errors = {};
    objectType.columns.forEach((column) => {
        if (column.keyName === 'coverages') {
            errors[column.keyName] = validateInput(object, column.validationRules);
        } else {
            errors[column.keyName] = validateInput(object[column.keyName], column.validationRules, object);
        }
    });

    return errors;
};

// Returns a list of objects where each object has been validated to add the error map as a result of validateAllFields()
// If an object has subarray(s) of more objects, pass in their key names and consts to have them checked as well
export const validateAllObjects = (objectList, objectType, subObjectKeyNames = [], subObjectTypes = []) => objectList.map((object) => {
    const newObject = {
        ...object,
        errors: validateAllFields(object, objectType),
    };
    subObjectKeyNames.forEach((key, i) => {
        newObject[key] = validateAllObjects(object[key], subObjectTypes[i]);
    });
    return newObject;
});

// Returns true if there is at least one error in an objectList
export const areErrors = (objectList) => {
    for (const i in objectList) {
        for (const j in objectList[i]) {
            for (const k in objectList[i][j].errors) {
                if (objectList[i][j].errors[k] !== null) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const employeeAndDepErrors = (employees) => {
    const primaryLocEmployees = employees[0];
    for (const employee of primaryLocEmployees) {
        for (const error in employee.errors) {
            if (employee.errors[error] !== null) {
                return true;
            }
        }
        for (const dependent of employee.dependents) {
            for (const error in dependent.errors) {
                if (dependent.errors[error] !== null) {
                    return true;
                }
            }
        }
    }
    return false;
};

const changeEmptyStringToZero = (str) => {
    if (str === '') {
        return '0';
    }
    return str;
};

export const validateSimpleCensus = (simpleCensus, locations, quickQuoteRequest = {}) => {
    let expectedEmployeeTotal = 0;
    locations.forEach((loc) => {
        expectedEmployeeTotal += parseInt(loc.totalEligEmps || quickQuoteRequest.groupSize);
    });
    let actualEmployeeTotal = 0;
    ['ee', 'es', 'ec', 'ef', 'nc'].forEach((type) => {
        if (simpleCensus[type] !== '') {
            actualEmployeeTotal += parseInt(simpleCensus[type]);
        }
    });
    if (actualEmployeeTotal !== expectedEmployeeTotal) {
        return {
            ...simpleCensus,
            error: `Expected <strong>${expectedEmployeeTotal}</strong> employees based on the Eligible Counts`
                + ` provided in the Company Profile. You have entered <strong>${actualEmployeeTotal}</strong>. Please`
                + ' update the counts to match.',
        };
    }

    return {
        ...simpleCensus,
        ee: changeEmptyStringToZero(simpleCensus.ee),
        es: changeEmptyStringToZero(simpleCensus.es),
        ec: changeEmptyStringToZero(simpleCensus.ec),
        ef: changeEmptyStringToZero(simpleCensus.ef),
        nc: changeEmptyStringToZero(simpleCensus.nc),
        error: null,
    };
};

export const getCoverageTypeFromSelectedProducts = (dependents, productSelection, selectedProducts, origCoverage, waiveCoverage, isWaivingAll = null) => {
    const selectProducts = Object.keys(selectedProducts).map((product) => {
        if (selectedProducts[product] === true) {
            return product.charAt(0).toUpperCase();
        } return '-';
    });

    const existingCoverage = {};
    const originalCoverage = origCoverage;

    Object.keys(originalCoverage).map((product) => {
        existingCoverage[product.charAt(0).toUpperCase()] = originalCoverage[product];
    });

    const coverage = {
        M: 'W',
        D: 'W',
        V: 'W',
        L: 'W',
        STD: 'W',
        LTD: 'W',
        SL:'W'
    };

    const childCount = {
        M: 0,
        D: 0,
        V: 0,
        L: 0,
        STD: 0,
        LTD: 0,
    };
    const spouseCount = {
        M: 0,
        D: 0,
        V: 0,
        L: 0,
        STD: 0,
        LTD: 0,
    };

    // TODO
    if ((waiveCoverage && waiveCoverage.isWaivingAll && waiveCoverage.isWaivingAll === 'Yes') || (isWaivingAll && isWaivingAll === 'Yes')) {
        if(isUhcRenewals()){
            return {
                life: 'W',
                dental: 'W',
                ltd: 'W',
                medical: 'W',
                std: 'W',
                vision: 'W',
            };
        }
        return {
            basicLife: 'W',
            dental: 'W',
            ltd: 'W',
            medical: 'W',
            std: 'W',
            vision: 'W',
            suppLife: 'W'
        };
    }

    if (dependents && dependents.length > 0) {
        dependents.map((dependent, i) => {
            if ((dependent.state && (dependent.state.relationship === 'Child' || dependent.state.relationship === 'Dependent Child')) || dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                if (productSelection && productSelection[i + 1] && (productSelection[i + 1].medical || productSelection[i + 1].dental || productSelection[i + 1].vision || productSelection[i + 1].life)) {
                    if (productSelection[i + 1].medical && productSelection[i + 1].medical.toLowerCase() !== 'waive') childCount.M += 1;
                    if (productSelection[i + 1].dental && productSelection[i + 1].dental.toLowerCase() !== 'waive') childCount.D += 1;
                    if (productSelection[i + 1].vision && productSelection[i + 1].vision.toLowerCase() !== 'waive') childCount.V += 1;
                    if (productSelection[i + 1].life && productSelection[i + 1].life.toLowerCase() !== 'waive') childCount.L += 1;
                } else {
                    for (const product in selectProducts) {
                        if (product !== '-' && !['EE', 'ES', 'EO', 'EE/SP'].includes(existingCoverage[selectProducts[product]])) {
                            childCount[selectProducts[product]] = childCount[selectProducts[product]] + 1;
                        }
                    }
                }
            } else if (productSelection && productSelection[i + 1] && (productSelection[i + 1].medical || productSelection[i + 1].dental || productSelection[i + 1].vision || productSelection[i + 1].life)) {
                if (productSelection[i + 1].medical && productSelection[i + 1].medical.toLowerCase() !== 'waive') spouseCount.M += 1;
                if (productSelection[i + 1].dental && productSelection[i + 1].dental.toLowerCase() !== 'waive') spouseCount.D += 1;
                if (productSelection[i + 1].vision && productSelection[i + 1].vision.toLowerCase() !== 'waive') spouseCount.V += 1;
                if (productSelection[i + 1].life && productSelection[i + 1].life.toLowerCase() !== 'waive') spouseCount.L += 1;
            } else {
                for (const product in selectProducts) {
                    if (product !== '-' && !['EE', 'EC', 'EO', 'EE/CH'].includes(existingCoverage[selectProducts[product]])) {
                        spouseCount[selectProducts[product]] = spouseCount[selectProducts[product]] + 1;
                    }
                }
            }
        });
    }

    const allProduct = ['M', 'D', 'V', 'L', 'STD', 'LTD','SL'];
    const productMapping = {
        M: 'medical',
        D: 'dental',
        V: 'vision',
        L: 'life',
        STD: 'std',
        LTD: 'ltd',
        SL:'suppLife'
    };

    for (const product in allProduct) {
        if (productSelection[0] && productSelection[0][productMapping[allProduct[product]]] && productSelection[0][productMapping[allProduct[product]]].toLowerCase() === 'waive') {
            coverage[allProduct[product]] = 'W';
        } else if(allProduct[product] === 'L' ) {
            coverage[allProduct[product]] = 'EL';
        } else if(allProduct[product] === 'SL' ) {
            coverage[allProduct[product]] = 'EE';
        } else if (childCount[allProduct[product]] > 0 && spouseCount[allProduct[product]] > 0) {
            coverage[allProduct[product]] = 'EE/FAM';
        } else if (childCount[allProduct[product]] > 0 && spouseCount[allProduct[product]] == 0) {
            coverage[allProduct[product]] = 'EE/CH';
        } else if (childCount[allProduct[product]] == 0 && spouseCount[allProduct[product]] > 0) {
            coverage[allProduct[product]] = 'EE/SP';
        } else if (childCount[allProduct[product]] == 0 && spouseCount[allProduct[product]] == 0) {
            coverage[allProduct[product]] = 'EE';
        }
    }
    if(isUhcRenewals()){
        return {
            life: coverage.L,
            dental: coverage.D,
            ltd: 'EE',
            medical: coverage.M,
            std: 'EE',
            vision: coverage.V,
        };
    }

    return {
        basicLife: coverage.L,
        dental: coverage.D,
        ltd: coverage.LTD,
        medical: coverage.M,
        std: coverage.STD,
        vision: coverage.V,
        suppLife:coverage.SL
    };
};

export const isDepAllCoverageWaived =(productSelection, selectedProducts)=>{
    let isWaivingAll = 'Yes'
    Object.keys(selectedProducts).map((product) => {
        if (selectedProducts[product] === true && productSelection[product] !== 'waive') {
            isWaivingAll ='No'
        } 
    });
    return isWaivingAll
}

// Get coverage counts for D, V, BL based on selected speciality and census
export const getCoverageCounts = (viewSpecialty, employees , location = null ) => {
    // Convert company profile coverage type strings into summary census
    // coverage type strings
    const covTypes = {
        EE: 'employeeOnly',
        EL:'elected',
        'EE/SP': 'employeeWithSpouse',
        'EE/CH': 'employeeWithChild',
        'EE/FAM': 'employeeWithFamily',
    };

    const { dental: hasDental, vision: hasVision, life: hasLife, std: hasStd, ltd: hasLTd } = viewSpecialty;
    const { store } = require('../../../../../index');
    const stateCheck = location === null ? store.getState().companyProfile.locations[0].zipData.stateCode != "MN" : location !='MN'
    // objects to keep track of each product's census counts
    const dentalCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    const visionCensus = {
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    const lifeCensus = {
        elected : 0,
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    const stdCensus = {
        elected : 0,
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };
    const ltdCensus = {
        elected : 0,
        employeeOnly: 0,
        employeeWithSpouse: 0,
        employeeWithChild: 0,
        employeeWithFamily: 0,
    };

    // Update the census counts for each product
    for (const location of employees) {
        location.forEach((employee) => {
            const { dental, vision, basicLife, std, ltd } = employee.coverages;
            const BLempStatusCheck = employee.employeeStatus

            if (hasDental && dental !== 'W') {
                dentalCensus[covTypes[dental]] += 1;
            }
            if (hasVision && vision !== 'W') {
                visionCensus[covTypes[vision]] += 1;
            }
            if (hasLife && basicLife !== 'W' && !(BLempStatusCheck === 'COBRA' && stateCheck)) {
                lifeCensus[covTypes[basicLife]] += 1;
            }
            if (hasStd && std !== 'W' && BLempStatusCheck != 'COBRA' ) {
                stdCensus[covTypes[std]] += 1;
            }
            if (hasLTd && ltd !== 'W' && BLempStatusCheck != 'COBRA' ) {
                ltdCensus[covTypes[ltd]] += 1;
            }
        });
    }

    return {
        dentalCensus,
        visionCensus,
         lifeCensus,
        stdCensus,
        ltdCensus
    };
};

export const getNumEnrollingEmployees = (employees, selectedProducts) => {
    let numEnrolling = 0;

    const selectProducts = [];
    Object.keys(selectedProducts).map((product) => {
        if (selectedProducts[product] === true) {
            selectProducts.push(product);
        }
    });

    employees.map((emp) => {
        if (emp.coverages) {
            let waivedProductsCount = 0;

            selectProducts.map((product) => {
                if (emp.coverages[product] === 'W' || isUndefinedOrNullOrEmpty(emp.coverages[product])) {
                    waivedProductsCount += 1;
                }
            });

            if (waivedProductsCount !== selectProducts.length) {
                numEnrolling += 1;
            }
        }
    });

    return numEnrolling;
};

const checkIfSalaryIsMandatory = (selectedProducts, enrolledLifePlans, employeeStatus, optedLifeClass) => {
    let isPlanMultipleOfSalary = false;
    let isLifeSelected = selectedProducts.life;
    if (isLifeSelected) {
        enrolledLifePlans.forEach((plan) => {
            // Check if classing && the employee's class has a Multiple of Salary plan
            if (plan.selectedClass && optedLifeClass && (optedLifeClass === plan.selectedClass)) {
                if (plan.benefitDetails && plan.benefitDetails.benefitType === 'Multiple of Salary') {
                    isPlanMultipleOfSalary = true;
                }
            } else if (!plan.selectedClass && plan.benefitDetails && plan.benefitDetails.benefitType === 'Multiple of Salary') {
                isPlanMultipleOfSalary = true;
            }
            if(isUhcRenewals() && (plan.lifeBenefitType === 'FLAT EE' ||  plan.lifeBenefitType.includes('FLAT') ||  plan.benefitDetails?.benefitType === "Flat Amount")){
                isPlanMultipleOfSalary = false;
            } else if(isUhcRenewals() && (plan.lifeBenefitType === 'SAL MULT' ||  plan.lifeBenefitType.includes('SAL') || plan.benefitDetails?.benefitType === "Multiple of Salary")){
                isPlanMultipleOfSalary = true;
            }
        });
    }
    return isLifeSelected && employeeStatus !== 'COBRA' && isPlanMultipleOfSalary;
};
