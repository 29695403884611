export const makeNewProductSelectionObject = (products) => {
    const obj = {};
    products.forEach((product) => {
        if (typeof product === 'object'){
            obj[product.nameKey] = 'select';
        } else {
            obj[product] = 'select'
        }
        
    });
    return obj;
};

export const makeNewProductSelectionArray = (members = [], products = []) => {
    const productSelection = [];
    for (let i = 0; i < members.length; i++) {
        productSelection.push(makeNewProductSelectionObject(products));
    }
    return productSelection;
};


export const makeNewPopulatedObject = (products , productSelectionArray = []) => {
    const obj = {};
    products.forEach((product) => {
        if(typeof product === 'object'){
            obj[product.nameKey] = product.enrolledPlans[0].code;
        }
        else{
            obj[product] = productSelectionArray[0]?.product
        }
    });
    return obj;
};

export const makeNewPopulatedArray = (members = [], products = [] , productSelectionArray = []) => {
    const productSelection = [];

    for (let i = 0; i < members.length; i++) {
        productSelection.push(makeNewPopulatedObject(products , productSelectionArray));
    }
    return productSelection;
};