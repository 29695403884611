import * as types from '../../../actions/actionTypes';

const initialState = {
    memGroupID: null,
    LWMemGroupInstalling: false,
    LWMemGroupError: null,
    LWMemGroupTechError: false,
    HWMemGroupInstalling: false,
    gettingTransactionStatus: false,
    HWMemGroupTransactionID: null,
    HWMemGroupError: null,
    HWMemGroupTechError: false,
    memIDs: [],
    numLWMemsInstalling: 0,
    numHWMemsInstalling: 0,
    LWMemSoftErrors: [],
    LWMemTechError: false,
    HWMemSoftErrors: [],
    LWMemGroupPrimeError: false,
    HWMemTechError: false,
    LWMemErrors: [],
    HWMemErrors: [],
    closeEnrollLoader: false,
    HWLoading: false,
    HWPrimeMemGroupInstalling: false,
    HWPrimeMemGroupError: null,
    HWPrimeMemErrors: [],
    altIdInstalled: false,
    planPolicyMappingArray: [],
    HWPaused: false,
    HWResume: false,
    showPrimeServicePopup: false,
    productCancellationError: false,
    memTermError: false,
};

const initializeNestedArray = (employeeGroupSizes, defaultValue) => {
    const array = [];
    employeeGroupSizes.forEach((groupSize) => {
        const subarray = [];
        for (let i = 0; i < groupSize; i++) {
            subarray.push(defaultValue);
        }
        array.push(subarray);
    });
    return array;
};

const updateNestedArray = (nestedArray, locationIndex, employeeIndex, newValue) => {
    const newArray = nestedArray.slice();
    const newSubArray = nestedArray[locationIndex].slice();
    newSubArray[employeeIndex] = newValue;
    newArray[locationIndex] = newSubArray;
    return newArray;
};

const getMemberID = (messageList) => messageList[0].data.memberID;

const makeSoftErrorStrings = (messageObjs) => {
    const strs = [];
    messageObjs.forEach((messageObj) => {
        const {
            context, fieldValue, message, type,
        } = messageObj;
        if (type === 'ERROR') {
            const contextParts = context.split('.');
            const lastContextPart = contextParts[contextParts.length - 1];
            strs.push(`${lastContextPart} - ${message} ${fieldValue ? `(${fieldValue})` : ''}`);
        }
    });
    return strs;
};

const mapMemberSoftErrors = (messageList) => ({
    employee: makeSoftErrorStrings(messageList[0].messages),
    dependents: messageList.slice(1).map((messageListObj) => makeSoftErrorStrings(messageListObj.messages)),
});

function extractPlanPolicyArray (planPolicyArray){
    let newPolicyArray = []
    planPolicyArray.forEach(plan => {
        let planPolicyObj = {}
        if(plan.HealthPlanCode !== "")
        {
            planPolicyObj.healthPlanCode = plan.HealthPlanCode
            planPolicyObj.policyNumberAssigned = plan.PolicyNumberAssigned
            newPolicyArray.push(planPolicyObj)
        }
    })
    return newPolicyArray
}

export default function installationReducer(state = initialState, action) {
    switch (action.type) {
        case types.INSTALL_MEM_GROUP_LW_REQUEST:
            return {
                ...state,
                LWMemGroupInstalling: true,
                LWMemGroupError: null,
            };
        case types.INSTALL_MEM_GROUP_LW_SUCCESS:
            let newPlanPolicyMappingArray = []
            if(action.payload.LightweightOutputMessage)
            {
                newPlanPolicyMappingArray = extractPlanPolicyArray(action.payload.LightweightOutputMessage.LightweightOutput.PlanPolicyMappingArray)
            }
            return {
                ...state,
                LWMemGroupInstalling: false,
                planPolicyMappingArray: newPlanPolicyMappingArray,
                installationLogObjId: action.payload.installationLogObjId,
                memGroupID: action.payload.LightweightOutputMessage ? action.payload.LightweightOutputMessage.LightweightOutput.CustomerNumber : action.payload.responseData.memGroupID,
                LWMemGroupTechError: false,
            };
        case types.INSTALL_MEM_GROUP_LW_ERROR:
            return {
                ...state,
                LWMemGroupInstalling: false,
                LWMemGroupError: action.error.metaInformation ? action.error.metaInformation.responseMessageList.map((messageObj) => messageObj.message).join(' ') : action.error.errorMessage || action.error
            };
        case types.INSTALL_MEM_GROUP_LW_TECH_ERROR:
            return {
                ...state,
                LWMemGroupInstalling: false,
                LWMemGroupTechError: true,
                LWTechErrors:action.errors
            };
        case types.INSTALL_MEM_GROUP_HW_REQUEST:
            return {
                ...state,
                HWMemGroupInstalling: true,
                HWMemGroupError: null,
            };
        case types.INSTALL_MEM_GROUP_HW_SUCCESS:
            return {
                ...state,
                HWMemGroupInstalling: false,
                gettingTransactionStatus: true,
                HWMemGroupTransactionID: action.transactionID,
                HWMemGroupTechError: false,
            };
        case types.GOT_TRANSACTION_STATUS:
            return {
                ...state,
                gettingTransactionStatus: false,
            };
        case types.GOT_TRANSACTION_STATUS:
            return {
                ...state,
                gettingTransactionStatus: false,
            };
        case types.INSTALL_MEM_GROUP_HW_ERROR:
            return {
                ...state,
                HWMemGroupInstalling: false,
                HWMemGroupError: action.error,
            };
        case types.INSTALL_MEM_GROUP_HW_TECH_ERROR:
            return {
                ...state,
                HWMemGroupInstalling: false,
                HWMemGroupTechError: true,
            };
        case types.INSTALL_MEM_LW_REQUEST: {
            const { employeeGroupSizes } = action;
            let newLWMemErrors = state.LWMemErrors;
            let newLWMemSoftErrors = state.LWMemSoftErrors;
            let newMemIDs = state.memIDs;
            if (state.numLWMemsInstalling) {
                newLWMemErrors = initializeNestedArray(employeeGroupSizes, null);
                newLWMemSoftErrors = initializeNestedArray(employeeGroupSizes, []);
                newMemIDs = initializeNestedArray(employeeGroupSizes, null);
            }
            return {
                ...state,
                numLWMemsInstalling: state.numLWMemsInstalling + 1,
                LWMemErrors: newLWMemErrors,
                LWMemSoftErrors: newLWMemSoftErrors,
                memIDs: newMemIDs,
                closeEnrollLoader: true,
            };
        }
        case types.INSTALL_MEM_HW_REQUEST: {
            const { employeeGroupSizes } = action;
            let newHWMemErrors = state.HWMemErrors;
            let newHWMemSoftErrors = state.HWMemSoftErrors;
            let newMemIDs = state.memIDs;
            if (state.numHWMemsInstalling) {
                newHWMemErrors = initializeNestedArray(employeeGroupSizes, null);
                newHWMemSoftErrors = initializeNestedArray(employeeGroupSizes, []);
                newMemIDs = initializeNestedArray(employeeGroupSizes, null);
            }
            return {
                ...state,
                numHWMemsInstalling: state.numHWMemsInstalling + 1,
                HWMemErrors: newHWMemErrors,
                HWMemSoftErrors: newHWMemSoftErrors,
                memIDs: newMemIDs,
            };
        }
        case types.INSTALL_MEM_LW_SUCCESS: {
            const { locationIndex, employeeIndex, payload } = action;
            return {
                ...state,
                numLWMemsInstalling: state.numLWMemsInstalling - 1,
                //TODO Need to check
                // memIDs: updateNestedArray(state.memIDs, locationIndex, employeeIndex, getMemberID(payload.messageList)),
                // LWMemSoftErrors: updateNestedArray(state.LWMemSoftErrors, locationIndex, employeeIndex, mapMemberSoftErrors(payload.messageList)),
                closeEnrollLoader: false,
                LWMemTechError: false,
            };
        }
        case types.INSTALL_MEM_HW_SUCCESS: {
            const { locationIndex, employeeIndex, payload } = action;
            return {
                ...state,
                numHWMemsInstalling: state.numHWMemsInstalling - 1,
                // memIDs: updateNestedArray(state.memIDs, locationIndex, employeeIndex, getMemberID(payload.messageList)),
                // HWMemSoftErrors: updateNestedArray(state.HWMemSoftErrors, locationIndex, employeeIndex, mapMemberSoftErrors(payload.messageList)),
                HWMemTechError: false,
            };
        }
        case types.INSTALL_MEM_LW_ERROR: {
            const { locationIndex, employeeIndex, error } = action;
            return {
                ...state,
                numLWMemsInstalling: state.numLWMemsInstalling - 1,
                LWMemErrors: updateNestedArray(state.LWMemErrors, locationIndex, employeeIndex, error),
                closeEnrollLoader: false,
            };
        }
        case types.INSTALL_MEM_LW_TECH_ERROR: {
            return {
                ...state,
                numLWMemsInstalling: 0,
                LWMemTechError: true,
            };
        }
        case types.PRODUCT_CANCELLATION_TECH_ERROR: {
            return {
                ...state,
                productCancellationError: true,
            };
        }
        case types.MEMBER_TERMINATION_TECH_ERROR: {
            return {
                ...state,
                memTermError: true,
            };
        }
        case types.INSTALL_MEM_HW_ERROR: {
            const { locationIndex, employeeIndex, error } = action;
            return {
                ...state,
                numHWMemsInstalling: state.numHWMemsInstalling - 1,
                HWMemErrors: updateNestedArray(state.HWMemErrors, locationIndex, employeeIndex, error),
            };
        }
        case types.INSTALL_MEM_HW_TECH_ERROR: {
            return {
                ...state,
                HWMemTechError: true,
            };
        }
        case types.CLEAR_TECH_ERROR: {
            return {
                ...state,
                HWMemTechError: false,
                LWMemTechError: false,
                HWMemGroupTechError: false,
                LWMemGroupTechError: false,
                productCancellationError: false,
                memTermError: false,
            };
        }
        case types.HW_LOADING: {
            const { bool } = action;
            return {
                ...state,
                HWLoading: bool
            };
        }
        case types.INSTALL_PRIME_MEM_GROUP_HW_REQUEST:
        return {
            ...state,
            HWPrimeMemGroupInstalling: true,
            HWPrimeMemGroupError: null,
        };
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_TO_INITIALSTATE_REN_PRIME_ANC:
            return {
                ...initialState,
                memGroupID: null
            }
        case types.INSTALL_MEM_LW_PRIME_REQUEST:
            return {
                ...state,
                numLWMemsInstalling : 1
            }
        case types.INSTALL_MEM_LW_PRIME_SUCCESS:
            return {
                ...state,
                LWMemGroupPrimeError: action.error,
                numLWMemsInstalling : 0,
                altIdInstalled: action.installed
            }

        case types.INSTALL_MEM_LW_PRIME_ERROR:
            return {
                ...state,
                LWMemGroupPrimeError: action.error,
                numLWMemsInstalling: 0,
                altIdInstalled: action.installed
            }

        case types.UPDATE_HWPAUSED_STATUS:
            return {
                ...state,
                HWPaused: action.status,
            };
        
        case types.UPDATE_HWRESUME_STATUS:
            return {
                ...state,
                HWResume: action.status,
            };

        case types.PRIME_SERVICE_DOWN_ERROR_POPUP:
            return {
                ...state,
                showPrimeServicePopup: action.status,
                primeServicePopupError: action.error,
                LWMemGroupInstalling: false
            } 
        case types.SET_HW_LOADING_FALSE:
            const { bool } = action;
            return {
                ...state,
                HWLoading: bool,
                HWMemGroupInstalling : bool

            }
        case types.RESET_MEM_GROUP_LW_ERROR:
            return {
                ...state,
                LWMemGroupError: null
            }
        default:
            return state;
    }
}
