
import * as types from '../../actions/actionTypes';

// overall

const isSpecialtyOnly = false;

const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        effectiveDate: null,
        zipObj: {},
        otherDetails: {
            selectedProducts: [],
            OISABit: []
        },
    },
    names: [],
    previousPage: '',
    members: [],
    ages: [],
    genders: [],
    appConfigDetails: {
        _id: '',
        products: {
            medical: true,
            dental: true,
            vision: true,
            basicLife: true,
            STD: true,
            LTD: true,
            SUL: true,
        },
        isSpecialtyOnly: false,
        marketType: 'PRIME',
        otherDetails: {
            sendFeedbackEmail: 'mailto:orangehat@optum.com',
        },
        headerMenu: {
            quickQuote: true,
            fullQuote: false,
            dashboard: false,
            offshoreSeason: false,
            memberMaintanance: false,
        },
        footerMenu: {
            fullShopping: false,
            saveRates: false,
            exportPlans: false,
        },
        postEnrollPreInstall: false,
        preInstallCensusChange: true,
        feedbackId: '',
        carousel: {
            welcome: {
                title: "Welcome to Sales Automation Management (SAMx)",
                subtitle: "You can expect the following:",
                listItems: ["Flexibility to allow you to work the way you work best", "A dependable, end-to-end experience to Quote, Enroll, and Renew your business", "Side-by-side offerings of Medical, Dental, and Vision plans.  Now includes renewals with Dental and Vision effective 5/1/22", null],
                showInternal: true,
                showExternal: true,
                pageFooter: "Click here to learn more about SAMx - https://www.uhceservices.com/en/prelogin/articles/samx-training-resources-for-brokers"
            },
            help: {
                title: "Getting Help",
                subtitle: "Dedicated Client Service Manager or Broker & Services",
                listItems: ["Oxford Fully Insured – 888-201-4216", "UnitedHealthcare Freedom Plans – 866-764-7735"],
                showInternal: true,
                showExternal: true,
                pageFooter: ""
            },
            upcomingDeployments: {
                title: "Upcoming Maintenance",
                subtitle: "SAMx will be unavailable the following dates/times to continue enhancing your experience:",
                listItems: ["Friday, 4/22 9:00 PM – 11:59 PM ET", null, null, null],
                showInternal: true,
                showExternal: false,
                pageFooter: "Please contact the SAMx Production Support team for deployment-specific questions."
            },
            announcements: {
                title: "UnitedHealthcare Motion®",
                subtitle: "Move more, make more! ",
                listItems: ["Employees with UnitedHealthcare Coverage, that register and activate their UnitedHealthcare Motion® account, could earn over $1,000 in rewards.", "Members earn rewards for meeting program targets for certain types of activities, and those rewards are deposited quarterly based on the plan’s reward design.", "Members could receive a fitness tracker at no cost by visiting unitedhealthcaremotion.com or may use their own compatible device.", "Motion is available with UnitedHealthcare Medical Plans at no extra cost and rewards vary by plan."],
                showInternal: true,
                showExternal: true,
                pageFooter: "Visit https://unitedhealthcaremotion.com for specific program requirements."
            }
        },

    },
    zipConfigDetails: {
        _id: '5dd292b7cba587078c2faf9f',
        zipCode: '',
        config: {
            fi_medical: true,
            fi_dental: true,
            fi_vision: true,
            fi_life: true,
            fi_ld: true,
            aso_medical: true,
            aso_dental: true,
            aso_vision: true,
            aso_life: true,
            aso_ld: true,
        },
        countyName: '',
        isExcluded: false,
        marketNo: '',
        platform: '',
        stateCode: '',
        stateName: '',
        vision: true,
        dental: true,
        life: true,
        std:true,
        ltd:true
    },
    userAdjustedRates: {
        dental: false,
        vision: false,
        life: false,
        std: false,
        ltd: false,
    },
    displayErrorMessage: false,
    disableBreadcrumbBeyond: '', // Breadcrumb should not be accessible beyond FS CI page
    isElgibleToFinalize: true,
    OISABit: [],
    platform: 'PRIME',
    producerPopulatedOnce: false,
    defaultGARemoved: false,
    duplicateTinObj: {
        tinNumber: '',
        isExistingTIN: null,
        memberGroupId: '',
    },
    medUpsel: false,
    pdfCsvLoading: {
        hrf: false,
        groupxml: false,
        memberxml: false
    },
    uhcStateConfig: {},
    dashboardCheckPlatform: "PRIME", // This platform is added just for the check on dashboard page. Don't use this platform anywhere or modify it.
    participationRules: null,
    isUserRestricted: false,
    isPaperCase: false,
    addNewEmployee: false,
    validateQuoteData: {
        PlanAvalInd_P00: '',
        PlanAvalInd_P50: '',
        MSOSQuoteAllwInd: ''
    },
    dateRules: '',
    deleteDate: '',
    updateDate: '',

    deleteType:'',
    updateVal:'',
    b2b: false,
    isparticipationBypassed: false,
    isDesQuote: false
};

export default function quotesOverall(state = initialState, action) {
    switch (action.type) {
        case types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };

        case types.SERVICE.DENTAL.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };

        case types.SERVICE.VISION.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };

        case types.SERVICE.LIFE.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };

        case types.SERVICE.SUPPLIFE.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };    
         
        case types.SERVICE.STD.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };
            
        case types.SERVICE.LTD.REQUEST_QUICK_QUOTE_PLANS:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };
    
            // Set all employees to age 30 and half female, half male
        case types.SERVICE.FI.RECEIVE_QUICK_QUOTE_PLANS:
            {
                const gendersArray = [];
                const size = parseInt(state.quickQuoteRequest.groupSize || action.groupSize, 10);

                for (let i = 0; i < size; i++) {
                    gendersArray[i] = (i % 2 === 0 ? 'Female' : 'Male');
                }

                return {
                    ...state,
                    ages: Array(size).fill(30),
                    genders: gendersArray,

                };
            }

            // If employee age is changed, display changes in all plans
        case types.UPDATE_NAMES:
            return {
                ...state,
                names: action.newNames,
            };
            // If employee age is changed, display changes in all plans
        case types.SERVICE.FI.UPDATE_AGES:
            return {
                ...state,
                ages: action.newAges,
            };

            // if employee gender is changed, display changes in all plans
        case types.UPDATE_GENDERS:
            return {
                ...state,
                genders: action.newGenders,
            };

        case types.UPDATE_SOURCE:
            return {
                ...state,
                source: action.newSource,
            };

        case types.SET_MED_UPSEL:
            return {
                ...state,
                medUpsel: action.payload,
            };
        case types.SET_PREPOPULATED_FLAG:
            return {
                ...state,
                producerPopulatedOnce: action.payload
            };
        case types.DEFAULT_GA_REMOVED:
            return {
                ...state,
                defaultGARemoved: action.payload
            };

        case types.UPDATE_PREVIOUS:
            return {
                ...state,
                previousPage: action.previousPage,
            };
        case types.UPDATE_NEXT:
            return {
                ...state,
                nextPage: action.nextPage,
                };

        case types.SET_ACTIVE_TAB_KEY:
            return {
                ...state,
                activeTabKey: action.activeTabKey,
            };

        case types.PROFILE_UPDATED:
            return {
                ...state,
                quickQuoteRequest: {
                    ...state.quickQuoteRequest,
                    groupSize: action.companyProfile.groupSize,
                },
            };
        case types.INITIALIZE_APP_CONFIG:
            // Set app config that matches the market type coming from BNE payload
            // platform is set on the basis of intent from BNE payload

            // FOR PRIME, setting these values irrespective of DB value because everything is coded wrt to 'preInstallCensusChange' as the selected configuration
            if (action.appConfigDetails.marketType === 'PRIME') {
                action.appConfigDetails.postEnrollPreInstall = false;
                action.appConfigDetails.preInstallCensusChange = true;
            }

            return {
                ...state,
                appConfigDetails: action.appConfigDetails,
                platform: action.appConfigDetails.marketType ? action.appConfigDetails.marketType : state.platform,
                dashboardCheckPlatform: action.appConfigDetails.marketType ? action.appConfigDetails.marketType : state.platform
            };

        case types.GET_ZIP_CONFIG:
            return {
                ...state,
                zipConfigDetails: action.zipConfigDetails,
            };
        case types.SET_REN_SHOPPING:
            return {
                ...state,
                isRenewals: action.payload,
            };
        case types.SET_MMR_INDICATOR:
            return {
                ...state,
                isMMR: action.payload,
            };
        case types.SET_QUOTE_CRITERIA:
            return {
                ...state,
                quickQuoteRequest: action.payload,
                source: action.source,
            };
        case types.SET_ZIP_AND_STATE:
            return {
                ...state,
                zipState: action.payload,
            };
        case types.SET_PLATFORM:
            return {
                ...state,
                platform: action.payload,
            };
        case types.SET_USERTOKEN:
            return {
                ...state,
                userToken: action.payload,
            };
        case types.RESET_FLOW:
            return {
                ...initialState,
                appConfigDetails: state.appConfigDetails, // these are not reset because they came from an API call on first loading the page and will never change
                platform: state.platform,
                dashboardCheckPlatform: state.dashboardCheckPlatform
            };

        case types.UPDATE_SELECTED_PRODUCTS_RENEWALS:
            return {
                ...state,
                quickQuoteRequest: {
                    ...state.quickQuoteRequest,
                    otherDetails: {
                        ...state.quickQuoteRequest.otherDetails,
                        selectedProducts: action.payload,
                    },
                },
            };
        case types.SERVICE.DENTAL.ADJUST_RATE:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    dental: true,
                },
            };
        case types.SERVICE.VISION.ADJUST_RATE:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    vision: true,
                },
            };
        case types.SERVICE.LIFE.ADJUST_RATE:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    life: true,
                },
            };
        case types.SERVICE.STD.ADJUST_RATE:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    std: true,
                },
            };
        case types.SERVICE.LTD.ADJUST_RATE:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    ltd: true,
                },
            };
        case types.UPDATE_PROPOSED_RATE_REFERENCE_ID:
            let stateProposedRateIds = state.proposedRateIds;
            if (action.lifeClass != '') { // Life Classing Case
                let classingRateRefIds;
                if(action.rateReferenceId.lifeRatingId){
                    let lifeRatingId;
                    if (state.proposedRateIds && state.proposedRateIds.lifeRatingId) {
                        lifeRatingId = {
                            ...stateProposedRateIds.lifeRatingId,
                            [action.lifeClass]: action.rateReferenceId.lifeRatingId
                        }
                    } else {
                        lifeRatingId = {
                            [action.lifeClass]: action.rateReferenceId.lifeRatingId
                        }
                    }

                    classingRateRefIds = {
                        ...classingRateRefIds,
                        lifeRatingId: lifeRatingId
                    }
                }

                if(action.rateReferenceId.stdRatingId){
                    let stdRatingId;
                    if (state.proposedRateIds && state.proposedRateIds.stdRatingId) {
                        stdRatingId = {
                            ...stateProposedRateIds.stdRatingId,
                            [action.lifeClass]: action.rateReferenceId.stdRatingId
                        }
                    } else {
                        stdRatingId = {
                            [action.lifeClass]: action.rateReferenceId.stdRatingId
                        }
                    }

                    classingRateRefIds = {
                        ...classingRateRefIds,
                        stdRatingId: stdRatingId
                    }
                }

                if(action.rateReferenceId.ltdRatingId){
                    let ltdRatingId;
                    if (state.proposedRateIds && state.proposedRateIds.ltdRatingId) {
                        ltdRatingId = {
                            ...stateProposedRateIds.ltdRatingId,
                            [action.lifeClass]: action.rateReferenceId.ltdRatingId
                        }
                    } else {
                        ltdRatingId = {
                            [action.lifeClass]: action.rateReferenceId.ltdRatingId
                        }
                    }

                    classingRateRefIds = {
                        ...classingRateRefIds,
                        ltdRatingId: ltdRatingId
                    }
                }

                return {
                    ...state,
                    proposedRateIds: {
                        ...stateProposedRateIds,
                        ...classingRateRefIds
                    }
                }

            }
            else {
                return {
                    ...state,
                    proposedRateIds: {
                        ...stateProposedRateIds,
                        ...action.rateReferenceId
                    }
                }
            }

        case types.UPDATE_INSTALLED_RATE_REFERENCE_ID:
            let stateinstallationRateIds = state.installationRateIds;
            if (action.lifeClass != '') { // Life Classing Case
                let classingRateRefIds;
                if(action.rateReferenceId.lifeRatingId){
                    let lifeRatingId;
                    if (state.installationRateIds && state.installationRateIds.lifeRatingId) {
                        lifeRatingId = {
                            ...stateinstallationRateIds.lifeRatingId,
                            [action.lifeClass]: action.rateReferenceId.lifeRatingId
                        }
                    } else {
                        lifeRatingId = {
                            [action.lifeClass]: action.rateReferenceId.lifeRatingId
                        }
                    }

                    classingRateRefIds = {
                        ...classingRateRefIds,
                        lifeRatingId: lifeRatingId
                    }
                }

                if(action.rateReferenceId.stdRatingId){
                    let stdRatingId;
                    if (state.installationRateIds && state.installationRateIds.stdRatingId) {
                        stdRatingId = {
                            ...stateinstallationRateIds.stdRatingId,
                            [action.lifeClass]: action.rateReferenceId.stdRatingId
                        }
                    } else {
                        stdRatingId = {
                            [action.lifeClass]: action.rateReferenceId.stdRatingId
                        }
                    }
                    
                    classingRateRefIds = {
                        ...classingRateRefIds,
                        stdRatingId: stdRatingId
                    }
                }

                if(action.rateReferenceId.ltdRatingId){
                    let ltdRatingId;
                    if (state.installationRateIds && state.installationRateIds.ltdRatingId) {
                        ltdRatingId = {
                            ...stateinstallationRateIds.ltdRatingId,
                            [action.lifeClass]: action.rateReferenceId.ltdRatingId
                        }
                    } else {
                        ltdRatingId = {
                            [action.lifeClass]: action.rateReferenceId.ltdRatingId
                        }
                    }
                    
                    classingRateRefIds = {
                        ...classingRateRefIds,
                        ltdRatingId: ltdRatingId
                    }
                }

                return {
                    ...state,
                    installationRateIds: {
                        ...stateinstallationRateIds,
                        ...classingRateRefIds
                    }
                }

            }
            else {
                return {
                    ...state,
                    installationRateIds: {
                        ...stateinstallationRateIds,
                        ...action.rateReferenceId
                    }
                }
            }

        case types.DISPLAY_ERROR_MESSAGE:
            {
                return {
                    ...state,
                    displayErrorMessage: true,
                };
            }

        case types.DISPLAY_DYNAMIC_ERROR_MESSAGE:
            {
                return {
                    ...state,
                    displayDynamicErrorMessage: action.payload,
                };
            }    

        case types.CLOSE_ERROR_MESSAGE:
            {
                return {
                    ...state,
                    displayErrorMessage: false,
                    displayDynamicErrorMessage: false
                };
            }

        case types.SET_OI_SA_BIT:
            {
                return {
                    ...state,
                    OISABit: action.payload
                }
            }

        case types.DISABLE_FULL_SHOPPING_BREADCRUMB:
            return {
                ...state,
                disableBreadcrumbBeyond: action.payload,
            };
        case types.INELIGIBLE_CASE_MODAL_POPUP:
            return {
                ...state,
                ineligibleCaseNotif: action.payload,
            };
        case types.IS_ELIGIBLE_TO_FINALIZE:
            return {
                ...state,
                isElgibleToFinalize: action.payload,
            };

        case types.SET_DUPLICATE_TIN:
            return {
                ...state,
                duplicateTinObj: action.payload,
            };
        case types.UPDATE_PDF_CSV_LOADER:
            const updatePdfCsvLoading = state.pdfCsvLoading
            updatePdfCsvLoading[action.field] = action.value
            return {
                ...state,
                pdfCsvLoading: updatePdfCsvLoading
            };

        case types.UPDATE_UHC_STATE_CONFIG:
            return {
                ...state,
                uhcStateConfig: action.payload.config,
            };

        case types.UPDATE_UHC_STATE_CONFIG:
            return {
                ...state,
                uhcStateConfig: action.payload.config,
            };
        case types.UPDATE_PARTICIPATION_RULES:
            return {
                ...state,
                participationRules: action.payload
            }
        case types.UPDATE_RESTRICTED_USER_FLAG:
            return {
                ...state,
                isUserRestricted: action.isUserRestricted
            }
        case types.UNSUPPORTED_OXFORD_ZIP_ERROR:
            return {
                ...state,
                unsupportedOxfordZip: action.payload
            }
        case types.UNSUPPORTED_OXFORD_ZIP_ERROR_POPUP_SHOW:
            return {
                ...state,
                unsupportedOxfordZipPopupShow: action.payload
            }
        case types.TOGGLE_PAPER_CASE:
            return {
                ...state,
                isPaperCase: action.isPaperCase
            }
        case types.UHC_RENEWALS_UPDATE_USER_ADJUST_FLAG:
            return {
                ...state,
                userAdjustedRates: {
                    ...state.userAdjustedRates,
                    [action.product]: true,
                },
            };  
        case types.ADD_RENEWAL_NEW_EMPLOYEE:
            return {
                ...state,
                addNewEmployee: action.payload
            }
        case types.SET_VALIDATE_QUOTE_DATA:
            return {
                ...state,
                validateQuoteData: {
                    ...state.validateQuoteData,
                    ...action.validateQuoteData
                }
            }
        case types.GET_RETRIEVE_DATES:
            return {
                ...state,
                dateRules: action.payload
            }
           
        case types.ADD_DATE_RULES:
            const dateRules = state.dateRules;
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;

            dateRules.dateRules.push({
                effectiveDate: '',
                deadlineDate: '',
                createdBy: action.payload,
                createdDate: today,
                lastupdatedBy: '',
                lastupdatedDate: ''
            });
            console.log(dateRules)
            return {
                ...state,

                dateRules: dateRules

            }
        case types.DELETE_DATE_RULES:
            console.log('action.payload in reducer', action.payload)


            return {
                ...state,
                dateRules: action.payload
            }

        case types.DELETE_EFFECTIVE_DATES:
            console.log('action.payload in delete', action.payload)

            return {
                ...state,
                deleteDate: action.payload
            }

        case types.SAVE_UPDATE_DATE:
            console.log('action.payload in update', action.payload)

            return {
                ...state,
                updateDate: action.payload
            }

            case types.GET_RETRIEVE_PLANTYPES:
                return {
                    ...state,
                    pcpAutoAssignmentRules: action.payload
                }
               
            case types.ADD_DATE_VALUES:
                const pcpAutoAssignmentRules = state.pcpAutoAssignmentRules;
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();
               
    
                today = yyyy + '-' + mm + '-' + dd;
    
                pcpAutoAssignmentRules.pcpAutoAssignmentRules.push({
                    planTypes:'',
                    size:'',
                    stateCode:'',
                    isDisableForAll:'',
                    createdBy: action.payload,
                    createdDate: today,
                    lastupdatedBy: '',
                    lastupdatedDate: '',
                   lastisDisableForAll:'',
                    laststateCode:'',
                    lastsize:'',
                });
                console.log( pcpAutoAssignmentRules)
                return {
                    ...state,
    
                    pcpAutoAssignmentRules:  pcpAutoAssignmentRules
    
                }
            case types.DELETE_DATE_VALUES:
                console.log('action.payload in reducer', action.payload)
    
    
                return {
                    ...state,
                    pcpAutoAssignmentRules: action.payload
                }
    
            case types.DELETE_PLAN_TYPES:
                console.log('action.payload in delete', action.payload)
    
                return {
                    ...state,
                    deleteType: action.payload
                }
    
            case types.SAVE_UPDATE_TYPE:
                console.log('action.payload in update', action.payload)
    
                return {
                    ...state,
                    updateVal: action.payload
                }

            case types.IS_PARTICIPATION_BYPASSED:
            return {
                ...state,
                isparticipationBypassed: action.payload,
            };
            
            case types.IS_DES_QUOTE:
            return {
                ...state,
                isDesQuote: action.isDesQuote
            }

            default:
            return state;
    }
}