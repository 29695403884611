import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewCardCompanyInfoSide from './ReviewCardCompanyInfoSide';
import ReviewCardOwnerAssignmentSide from './ReviewCardOwnerAssignmentSide';
import ReviewCardCompanyInfoTop from './ReviewCardCompanyInfoTop';
import ReviewCardOwnerAssignmentTop from './ReviewCardOwnerAssignmentTop';
import './reviewCards.css';
import OfficeIcon from '../../../../images/icon-office.svg';
import UsersIcon from '../../../../images/icon-users.svg';

const ReviewCards = ({ history, pathname, permissions, displayUserCards = true, toggleUserCards, top = false}) => {
    const pathParts = pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    
    const companyInfo = lastPart === 'full';
    const employeeInfo = lastPart === 'employee';
    const planSelection = lastPart === 'plans';
    const quoteReview = lastPart === 'summary';
    const enrollment = lastPart === 'manager';
    const ownerAssignment = lastPart === 'owner-assignment';
    const finalizeCase = lastPart === 'finalize-case';
    const renewalFinalizeCase = lastPart === 'renewal-finalize-case';

    const review = lastPart === 'reviewDetail';
    const decide = lastPart === 'decideDetail';
    const renewalShopping = lastPart === 'renewalShopping';
    const renewalEnrollment = pathname.includes('/enrollmentManager/manager');
    const finalize = lastPart === 'finalize';
    const renewalsFlow = review || decide || renewalShopping || finalize || renewalEnrollment;
    
    const companyName = useSelector((state) => state.companyProfile.companyName, shallowEqual);
    
    const reviewData = useSelector((state) => state.review);
    const decideData = useSelector((state) => state.decide);
    let isSubmitted =  false;
    if(renewalsFlow && reviewData && reviewData.caseSubmitted && reviewData.caseSubmitted === true) {
        isSubmitted= reviewData.caseSubmitted;
    } else if(renewalsFlow && decideData && decideData.caseSubmitted && decideData.caseSubmitted === true) {
        isSubmitted= decideData.caseSubmitted;
    }
    const enrollmentManagerOfRenewals = pathParts[pathParts.length - 2]
    // check if Owner Assignment Box should be displayed based on user permissions

    const expandCollapseOption = (
        displayUserCards ?
            <b onClick={() => toggleUserCards()} className="collapseOption justifyCenter backgroundColorBlue whiteFont marginTopSmall paddingRightMedium">
                <Icon className="caret up large whiteFont noMarginRight"/> Collapse
            </b>
            :
            <b onClick={() => toggleUserCards()} className="expandOption justifyCenter marginLeftExtraLarge marginRightExtraLarge backgroundColorBlue whiteFont marginTopSmall paddingRightMedium">
                <Icon className="caret down large whiteFont noMarginRight"/> Expand
            </b>
    )
    
    const shouldDisplayOwnerAssignment = () => 
        (companyInfo && permissions.fullShoppingCompanyInfo.viewOwnerAssignmentBox)
        || (employeeInfo && permissions.fullShoppingEmployeeCensus.viewOwnerAssignmentBox)
        || (planSelection && permissions.fullShoppingPlanSelection.viewOwnerAssignmentBox)
        || (quoteReview && permissions.fullShoppingQuoteReview.viewOwnerAssignmentBox)
        || (ownerAssignment && permissions.fullShoppingQuoteReview.viewOwnerAssignmentBox)
        || (enrollment && permissions.fullShoppingEnrollment.viewOwnerAssignmentBox)
        || (review && permissions.renewalReviewAndConsider.viewOwnerAssignmentBox)
        || (decide && permissions.renewalDecide.viewOwnerAssignmentBox)
        || (renewalShopping && permissions.renewalShopping.viewOwnerAssignmentBox)
        || (finalize && permissions.renewalDecide.viewOwnerAssignmentBox)
        || (enrollmentManagerOfRenewals && permissions.renewalEnrollment.viewOwnerAssignmentBox)
        || finalizeCase || renewalFinalizeCase;

    const shouldEnableEditOwnerAssignment = () => (companyInfo
        && (permissions.fullShoppingCompanyInfo.addSalesRepAsOwner || permissions.fullShoppingCompanyInfo.editSalesRepAsOwner))
        || (employeeInfo && (permissions.fullShoppingEmployeeCensus.addSalesRepAsOwner || permissions.fullShoppingEmployeeCensus.editSalesRepAsOwner))
        || (planSelection && (permissions.fullShoppingPlanSelection.addSalesRepAsOwner || permissions.fullShoppingPlanSelection.editSalesRepAsOwner))
        || (quoteReview && (permissions.fullShoppingQuoteReview.addSalesRepAsOwner || permissions.fullShoppingQuoteReview.editSalesRepAsOwner))
        || (ownerAssignment && (permissions.fullShoppingQuoteReview.addSalesRepAsOwner || permissions.fullShoppingQuoteReview.editSalesRepAsOwner))
        || (enrollment && (permissions.fullShoppingEnrollment.addSalesRepAsOwner || permissions.fullShoppingEnrollment.editSalesRepAsOwner))
        || (review && (permissions.renewalReviewAndConsider.addSalesRepAsOwner || permissions.renewalReviewAndConsider.editSalesRepAsOwner))
        || (decide && (permissions.renewalDecide.addSalesRepAsOwner || permissions.renewalDecide.editSalesRepAsOwner))
        || (renewalShopping && (permissions.renewalShopping.addSalesRepAsOwner || permissions.renewalShopping.editSalesRepAsOwner))
        || (finalize && (permissions.renewalDecide.addSalesRepAsOwner || permissions.renewalDecide.editSalesRepAsOwner))
        || (enrollmentManagerOfRenewals && (permissions.renewalEnrollment.addSalesRepAsOwner || permissions.renewalEnrollment.editSalesRepAsOwner)
        || finalizeCase || renewalFinalizeCase);

    const topReviewCards = (
        <Segment className='flexColumn noMarginBottom marginTopSmall reviewCards'>

            <ReviewCardCompanyInfoTop history={history} pathname={pathname} />
            {displayUserCards === false && <ReviewCardOwnerAssignmentTop collapsed toggleUserCards={() => toggleUserCards()} isSubmitted={isSubmitted} pathname={pathname} history={history}/>}
            {(shouldDisplayOwnerAssignment() && displayUserCards === true) && <ReviewCardOwnerAssignmentTop isSubmitted={isSubmitted} history={history} pathname={pathname} editPermissionsOwnerAssign={shouldEnableEditOwnerAssignment()} />}

            {/* {displayUserCards && <ReviewCardCompanyInfoTop history={history} pathname={pathname} />}
            {displayUserCards && (shouldDisplayOwnerAssignment()) && <ReviewCardOwnerAssignmentTop history={history} editPermissionsOwnerAssign={shouldEnableEditOwnerAssignment()} />}
            {!displayUserCards &&
                <div className="justifySpaceAround negativeMarginBottom">
                    <div className="flexRow">
                        <h3 className="blueFont noMarginTop marginLeftSmall marginRightLarge marginBottomSmall">
                            <img src={OfficeIcon} className="smallIcon marginRightMedium"/>
                            {companyName || 'Group Information'}
                        </h3>
                    </div>
                    <div className="flexRow">
                        <h3 className="blueFont marginLeftSmall marginRightLarge">
                            <img src={UsersIcon} className="smallIcon marginRightMedium noPaddingTop"/>
                            Owner Assignment
                        </h3>
                    </div>
                </div>
            } */}
            
            <div className="justifyCenter">{expandCollapseOption}</div>
        </Segment>
    )

    const sideReviewCards = (
        <React.Fragment>
            <ReviewCardCompanyInfoSide history={history} />
            {shouldDisplayOwnerAssignment() && <ReviewCardOwnerAssignmentSide isSubmitted={isSubmitted} history={history} pathname={pathname} editPermissionsOwnerAssign={shouldEnableEditOwnerAssignment()}/>}
        </React.Fragment>
    )

    return (
        <React.Fragment>
            {top ? topReviewCards : sideReviewCards}
        </React.Fragment>
    );
};

ReviewCards.propTypes = {
    history: PropTypes.shape(PropTypes.object).isRequired,
    pathname: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
        fullShoppingCompanyInfo: PropTypes.shape({
            viewOwnerAssignmentBox: PropTypes.bool,
        }),
        fullShoppingEmployeeCensus: PropTypes.shape({
            viewOwnerAssignmentBox: PropTypes.bool,
        }),
        fullShoppingPlanSelection: PropTypes.shape({
            viewOwnerAssignmentBox: PropTypes.bool,
        }),
    }),
};

ReviewCards.defaultProps = {
    permissions: null
};

export default ReviewCards;
