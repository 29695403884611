import { isUhcRenewals } from '../../../../utils/businessLogic/platformUtils';
import { isEmpty } from '../../../../utils/formatters/strings';
import { getPrimaryLocationStateCode } from '../../../activities/FullShopping/Census/helpers/checkPrimaryLocation';

/**
 *
 * @param {object} moreTextIf field data object containing 'fieldValueLogic', 'fieldValue', 'textValue'
 * @param {*} currentFieldValue the current value of the field
 *
 * @returns {*} null if the conditions for the text appearing are not met, otherwise the additional text that should be displayed
 */
export const getMoreText = (moreTextIf, currentFieldValue) => {
    if (!moreTextIf) {
        return null;
    }
    let showText = false;
    switch (moreTextIf.fieldValueLogic) {
        case 'is':
            if (currentFieldValue === moreTextIf.fieldValue) {
                showText = true;
            }
            break;
        case 'is not':
            if (currentFieldValue !== moreTextIf.fieldValue) {
                showText = true;
            }
            break;
        case 'is one of':
            if (moreTextIf.fieldValue.includes(currentFieldValue)) {
                showText = true;
            }
            break;

        default:
            break;
    }
    if (showText) {
        return moreTextIf.textValue;
    }
    return null;
};

/**
 *
 * @param {string} currentSectionName the sectionName of the section that contains the field with this condition
 *
 * @param {object} condition object containing these attributes:
 *                              conditionType ("platforms", "appState", "selectedProducts", "quotedHSAorHRA", "dentalContributionType", "visionContributionType")
 *                              1 more attribute whose key name matches the value of conditionType
 *
 * @param {object} conditionEvaluators object with the following attributes:
 *                                      platform: string ("PRIME" or "CIRRUS")
 *                                      selectedProducts: array of strings (from "medical", "dental", "vision", "basicLife", "std", "ltd")
 *                                      quotedHSAorHRA: boolean
 *                                      appState: object (app state initialized by loading from configurable form DB)
 *                                      dentalContributionType: string
 *                                      visionContributionType: string
 *
 * @returns {boolean} true if condition is met, false otherwise
 */
const checkSingleCondition = (currentSectionName, condition, conditionEvaluators, companyProfile = null, enrollmentRules = null) => {
    switch (condition.conditionType) {
        case 'platforms':
            const fieldPlatform = conditionEvaluators.platform;
            return condition.platforms.includes(fieldPlatform);
        case 'installPlatform':
            const fieldInstallPlatform = conditionEvaluators.installPlatform;
            return condition.installPlatform.includes(fieldInstallPlatform);
        case 'appState':
        {
            let appStateValue = '';
            let { stateKey } = condition.appState;
            if (stateKey === '__this__') {
                stateKey = currentSectionName;
            }
            if (!isEmpty(conditionEvaluators.appState)
                    && !isEmpty(conditionEvaluators.appState[stateKey])
                    && !isEmpty(conditionEvaluators.appState[stateKey][condition.appState.stateSubKey])) {
                appStateValue = conditionEvaluators.appState[stateKey][condition.appState.stateSubKey];
            }
            const conditionStateValue = condition.appState.stateValue;
            switch (condition.appState.logic) {
                case 'is':
                    return appStateValue === conditionStateValue;
                case 'is not':
                    return appStateValue !== conditionStateValue;
                case 'is one of':
                    return conditionStateValue.includes(appStateValue);
                case 'is in array':
                    return appStateValue.includes(conditionStateValue);
                case 'is gte':
                    return parseInt(appStateValue) >= parseInt(conditionStateValue);
                default:
                    return false;
            }
        }
        case 'selectedProducts':
        {

            const appSelectedProducts = conditionEvaluators.selectedProducts;
            for (let i = 0; i < condition.selectedProducts.length; i++) {
                const conditionSelectedProduct = condition.selectedProducts[i];
                if (appSelectedProducts.includes(conditionSelectedProduct)) {
                    return true;
                }
            }
            return false;
        }
        case 'notInSelectedProducts':
        {
            const appSelectedProducts = conditionEvaluators.selectedProducts;
            for (let i = 0; i < condition.notSelectedProducts.length; i++) {
                const conditionSelectedProduct = condition.notSelectedProducts[i];
                if (appSelectedProducts.includes(conditionSelectedProduct)) {
                    return false;
                }
            }
            return true;
        }
        case 'newProducts':
        {
            const appNewProducts = conditionEvaluators.newProducts;
            if (Array.isArray(condition.newProducts)) {
                for (let i = 0; i < condition.newProducts.length; i++) {
                    const conditionNewProduct = condition.newProducts[i];
                    if (appNewProducts && appNewProducts.includes(conditionNewProduct)) {
                        return true;
                    }
                }
            } else if (Array.isArray(condition.newProducts) === false) {
                if (condition.newProducts === false && conditionEvaluators.newProducts && !conditionEvaluators.newProducts.includes('dental') && !conditionEvaluators.newProducts.includes('vision') && !conditionEvaluators.newProducts.includes('basicLife')) {
                    return true;
                }
            } else {
                return false;
            }
        }
        case 'quotedHSA':
            return condition.quotedHSA === conditionEvaluators.quotedHSA;
        case 'quotedHRA':
            return condition.quotedHRA === conditionEvaluators.quotedHRA;
        case 'hsaOrHraAdd':
            return condition.hsaOrHraAdd === conditionEvaluators.hsaOrHraAdd;
        case 'quotedHSAorHRA':
            return condition.quotedHSAorHRA === conditionEvaluators.quotedHSAorHRA;
        case 'hasCannabisEmp':
            return condition.hasCannabisEmp === conditionEvaluators.hasCannabisEmp;
        case 'isHNY':
            return condition.isHNY === conditionEvaluators.isHNY;
        case 'hasSIC':
            return condition.hasSIC === conditionEvaluators.hasSIC;
        case 'dhmoDentalPlan':
            return condition.dhmoDentalPlan === conditionEvaluators.dhmoDentalPlan;
        case 'dentalContributionType':
            return condition.dentalContributionType === conditionEvaluators.dentalContributionType;
        case 'visionContributionType':
            return condition.visionContributionType === conditionEvaluators.visionContributionType;
        case 'stdContributionType':
            return condition.stdContributionType === conditionEvaluators.stdContributionType;
        case 'ltdContributionType':
            return condition.ltdContributionType === conditionEvaluators.ltdContributionType;
        case 'lifeContributionType':
            return condition.lifeContributionType === conditionEvaluators.lifeContributionType;
        case 'isDentalContributionTypeChanged':
            return condition.isDentalContributionTypeChanged === conditionEvaluators.isDentalContributionTypeChanged;
        case 'isVisionContributionTypeChanged':
            return condition.isVisionContributionTypeChanged === conditionEvaluators.isVisionContributionTypeChanged;
        case 'isLifeContributionTypeChanged':
            return condition.isLifeContributionTypeChanged === conditionEvaluators.isLifeContributionTypeChanged;
        case 'hasPermission':
            return !(condition.permission in conditionEvaluators.permissions);
        case 'companyProfile':
        {
            let appStateValue = '';
            let { stateKey } = condition.appState;
            if (stateKey === '__this__') {
                stateKey = currentSectionName;
            }
            if (!isEmpty(conditionEvaluators.appState)
                    && !isEmpty(conditionEvaluators.appState[stateKey])
                    && !isEmpty(conditionEvaluators.appState[stateKey][condition.appState.stateSubKey])) {
                appStateValue = conditionEvaluators.appState[stateKey][condition.appState.stateSubKey];
            }
            if (companyProfile[condition.appState.companyProfileKey]) {
                let companyProfileValue = companyProfile ? parseInt(companyProfile[condition.appState.companyProfileKey][condition.appState.companyProfileSubKey]) : null;
                const stateCode = getPrimaryLocationStateCode(companyProfile.locations);

                if (stateKey.includes('dependentInfo') && stateCode === "NY") {
                    let enrolledPlans = enrollmentRules && enrollmentRules.enrolledPlans && enrollmentRules.enrolledPlans.standalone
                    if (enrolledPlans && enrolledPlans.length > 0) {
                        for (let i = 0; i < enrolledPlans.length; i++) {
                            if (enrolledPlans[i] && enrolledPlans[i].riderAge29) {
                                companyProfileValue = 29
                            }
                        }
                    }
                }
                switch (condition.appState.logic) {
                    case 'greater than or equal to':
                        if (companyProfileValue) {
                            return appStateValue >= companyProfileValue;
                        }
                        return false;

                    default:
                        return false;
                }
            } else return false;
        }
        case "isUhcRenewals":
            return isUhcRenewals(); 
        case 'employerWorkersComp':
            return condition.stateKey === conditionEvaluators.employerWorkersComp;      
        default:
            return false;
    }
};

/**
 *
 * @param {string} currentSectionName the sectionName of the section that contains the field with conditions
 *
 * @param {object} object object containing these attributes:
 *                          requiredConditions ("none", "all")
 *                          conditions (array of condition objects)
 *
 * the "dropdownOptions" and "defaultValue" attributes of a field in the configurable form DB are arrays of instances of this object
 * the "showIf" attribute of a field in the configurable form DB is an instance of this object
 *
 * @param {object} conditionEvaluators object with the following attributes:
 *                                      platform: string ("PRIME" or "CIRRUS")
 *                                      selectedProducts: array of strings (from "medical", "dental", "vision", "basicLife")
 *                                      quotedHSAorHRA: boolean
 *                                      appState: object (app state initialized by loading from configurable form DB)
 *
 * @returns {boolean} true if object meets conditions, false otherwise
 */
export const checkIfObjectMeetsConditions = (currentSectionName, object, conditionEvaluators, companyProfile = null, enrollmentRules = null) => {
    if (!object.conditions || object.conditions.length === 0) {
        return true;
    }
    let numConditionsMet = 0;
    for (let i = 0; i < object.conditions.length; i++) {
        const condition = object.conditions[i];
        const conditionMet = checkSingleCondition(currentSectionName, condition, conditionEvaluators, companyProfile, enrollmentRules);
        if (conditionMet) {
            numConditionsMet += 1;
        }
    }
    switch (object.requiredConditions) {
        case 'none':
            return true;
        case 'anyone':
            return numConditionsMet >= 1;
        // case "some": // no need for this in our DB yet, but who knows what the future holds?
        // return numConditionsMet > 0;
        case 'all':
            return numConditionsMet === object.conditions.length;
        default:
            return null;
    }
};

/**
 *
 * @param {string} currentSectionName the sectionName of the section that contains the field with conditions
 *
 * @param {array} objects array of objects, each of which should have these attributes:
 *                          requiredConditions ("none", "all"),
 *                          conditions (array of condition objects)
 *
 *  the "dropdownOptions" and "defaultValue" attributes of a field in the configurable form DB are such arrays
 *
 * @param {object} conditionEvaluators object with the following attributes:
 *                                      platform: string ("PRIME" or "CIRRUS")
 *                                      selectedProducts: array of strings (from "medical", "dental", "vision", "basicLife")
 *                                      quotedHSAorHRA: boolean
 *                                      appState: object (app state initialized by loading from configurable form DB)
 *
 * @returns {object} the first object that meets conditions (DB should be designed in such a way that exactly one object matches)
 */
export const findObjectThatMeetsConditions = (currentSectionName, objects, conditionEvaluators) => {
    if (!objects) {
        return {};
    }
    for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];
        const objectMeetsConditions = checkIfObjectMeetsConditions(currentSectionName,
            obj, conditionEvaluators);
        if (objectMeetsConditions) {
            return obj;
        }
    }
    return {};
};

export const isMedicalCoverageInfoVisible = (fullProps, employee) => {
    const numHoursToBeEligible = fullProps.enrollment.application.eligibility.participation.numHoursPerWeekToBeEligible;
    const { enrolledPlans } = fullProps.rules.enrollment;
    const overallPlatform = fullProps.overall.platform;
    const isRenewals = fullProps.overall.isRenewals;
    const stateCode = isRenewals ? fullProps.renewalEnrollment?.renewalCaseData?.situsState : fullProps.companyProfile?.locations && fullProps.companyProfile.locations[0] && fullProps.companyProfile.locations[0].zipData?.stateCode ? fullProps.companyProfile.locations[0].zipData.stateCode : "";
    const flatMedicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
    const planDisplayCode = employee.productSelection && employee.productSelection[0] ? employee.productSelection[0].medical : '';
    if (
        (planDisplayCode === '' || planDisplayCode === 'select'
            || flatMedicalPlans.find((plans) => plans.codeDisplay === planDisplayCode && (!plans.pcpRequired || plans.pcpRequired==='FALSE'))
            || (employee.employeeInfo && employee.employeeInfo.numberOfHoursWorked && Number(employee.employeeInfo.numberOfHoursWorked < Number(numHoursToBeEligible)))
            || (employee.employeeInfo && employee.employeeInfo.employeeStatus === '1099 Independent Contractors' && stateCode === 'CA' && overallPlatform === 'PRIME' )
        )
    ) {
        return null;
    }
    return true;
};
