import {
    INITIALIZE_EMPLOYER_INFO, UPDATE_EMPLOYER_INFO, PAYMENT_ACTIONS,
    COMPLETED_EMPLOYER_INFO, UPDATE_RENEWAL_EMPLOYER_INFO,
    INITIALIZE_ELIGIBILITY_INTO_EMPLOYER_INFO,
    REMOVE_ADDITIONAL_CONTACT, ADD_ADDITIONAL_CONTACTS_RL,
    RESET_TO_INITIALSTATE, UPDATE_NUM_OF_ADDITIONAL_CONTACTS, SAVE_EMPLOYER_FORM,
    TOGGLE_GROUP_NOT_ELIGIBLE, CONTAINS_NEWLY_ADDED_CONTACTS, UPDATE_EMPLOYER_TIN,
    UPDATE_GROUP_LEGAL_NAME,
    UPDATE_ENROLLMENT_CONTRIBUTION,
    UPDATE_ENROLL_APP_EMP_CONTRIBUTION,
    SET_EMPLOYER_ENROLLMENT_COMPLETE
} from '../../../actions/actionTypes';
import {
    initializeEnrollmentPageState, populateEnrollmentPageStateFromContractChange,
    updateNullFieldsToDefault,
} from './helper';
import { getRelevantAppState, makeAppStateNested } from '../../../components/shared/ConfigurableForms/helpers/getRelevantAppState';
import { checkIfObjectMeetsConditions } from '../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions';
import { checkCompletionAdditionalContacts } from '../../../components/shared/ConfigurableForms/helpers/conditionCheckers';
import { checkValidationsOnBlur } from '../../../components/shared/ConfigurableForms/helpers/validationCheckers';
import { isUndefinedOrNullOrEmpty } from '../../../utils/validation/commonValidations';
import { checkEachSection } from '../../../components/activities/Enrollment/EmployerAppForms/EnrollmentFormCompletionHandler';

const deepCopy = require('../../../utils/deepCopy');

const initialState = {
    errors: {},
    additionalGeneralInformation: {},
    generalInformation: {},
    primaryContactInformation: {},
    primaryLocation: {},
    billingAddress: {},
    additionalContacts: {},
    contribution: {}
};

export default function employerReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_EMPLOYER_INFO: {
            const initializedState = initializeEnrollmentPageState(
                action.payload, action.conditionEvaluators, action.companyProfile,
            );
            return {
                ...state,
                ...initializedState,
            };
        }

        case UPDATE_ENROLLMENT_CONTRIBUTION: {
            return {
                ...state,
                contribution: {
                    ...state.contribution,
                    medicalEmployerContribution: action.payload.medicalEmployerContribution,
                    medicalDependentContribution: action.payload.medicalDependentContribution,
                    dentalEmployerContribution: action.payload.dentalEmployerContribution,
                    dentalDependentContribution: action.payload.dentalDependentContribution,
                    visionEmployerContribution: action.payload.visionEmployerContribution,
                    visionDependentContribution: action.payload.visionDependentContribution,
                    basicLifeEmployerContribution: action.payload.basicLifeEmployerContribution,                    
                }
            }
        }

        case UPDATE_EMPLOYER_INFO: {
            const { update, group, isError } = action;
            if (isError) {
                const resolvedErrorGroup = state.errors[group] || {};
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [group]: {
                            ...resolvedErrorGroup,
                            ...update,
                        },
                    },
                };
            }
            //removing billing address errors if billing address is the same as primary location
            if (update && update.isBillingAddressSame) {
                if (state.errors.billingAddress)
                    Object.keys(state.errors.billingAddress).forEach(function (error) { state.errors.billingAddress[error] = null })
            }

            if (update && update.additionalContactSameAsEmployerPrimary0 === 'Yes') {
                if (state.errors.additionalContacts)
                    state.errors.additionalContacts.additionalContactCity0 = null
                state.errors.additionalContacts.additionalContactStreetAddress0 = null
                state.errors.additionalContacts.additionalContactCity0 = null
                state.errors.additionalContacts.additionalContactZipCode0 = null

            }

            if (update && update.additionalContactSameAsEmployerPrimary1 === 'Yes') {
                if (state.errors.additionalContacts)
                    state.errors.additionalContacts.additionalContactCity1 = null
                state.errors.additionalContacts.additionalContactStreetAddress1 = null
                state.errors.additionalContacts.additionalContactCity1 = null
                state.errors.additionalContacts.additionalContactZipCode1 = null

            }

            if (update && update.additionalContactSameAsEmployerPrimary2 === 'Yes') {
                if (state.errors.additionalContacts)
                    state.errors.additionalContacts.additionalContactCity2 = null
                state.errors.additionalContacts.additionalContactStreetAddress2 = null
                state.errors.additionalContacts.additionalContactCity2 = null
                state.errors.additionalContacts.additionalContactZipCode2 = null

            }

            if (update && update.additionalContactSameAsEmployerPrimary3 === 'Yes') {
                if (state.errors.additionalContacts)
                    state.errors.additionalContacts.additionalContactCity3 = null
                state.errors.additionalContacts.additionalContactStreetAddress3 = null
                state.errors.additionalContacts.additionalContactCity3 = null
                state.errors.additionalContacts.additionalContactZipCode3 = null

            }

            //removing organizationTypeOther error if organizationType equals Other
            if (update && update.organizationType && update.organizationType !== 'Other') {
                if (state.errors && state.errors.additionalGeneralInformation && state.errors.additionalGeneralInformation.organizationTypeOther)
                    state.errors.additionalGeneralInformation.organizationTypeOther = null
            }

            const resolvedGroup = state[group] || {};
            return {
                ...state,
                [group]: {
                    ...resolvedGroup,
                    ...update,
                },
            };
        }
        // For Renewals
        // TODO
        // case POPULATE_EMPLOYER_INFO:
        //     const populatedState = populateEnrollmentPageState(action.payload);
        //     return {
        //         ...state,
        //         ...populatedState,
        //     };
        case INITIALIZE_ELIGIBILITY_INTO_EMPLOYER_INFO: {
            const initializedEligibilityState = initializeEnrollmentPageState(
                action.payload, action.conditionEvaluators, {companyProfile: {...action.companyProfile},overall:{...action.overall}}
            );
            const participation = updateNullFieldsToDefault(
                state.participation, initializedEligibilityState.participation,
            );
            return {
                ...state,
                participation: participation || state.participation,
            };
        }

        case UPDATE_RENEWAL_EMPLOYER_INFO: {
            const populatedStateFromDB = populateEnrollmentPageStateFromContractChange(
                action.payload, action.employerContribution, action.caseSubmitted
            );
            return {
                ...state,
                ...populatedStateFromDB,
            };
        }

        case PAYMENT_ACTIONS.ADD_CONTACT:
            return {
                ...state,
                additionalContacts: {
                    ...state.additionalContacts,
                    contactsTable: [
                        action.contact,
                    ],
                },
            };

        case COMPLETED_EMPLOYER_INFO: {
            const {
                application, enrollmentForm, conditionEvaluators, appStateProps, fullProps
            } = action;
            let completed = true;

            for (const rulesSection of enrollmentForm.employer) {
                let showSection = true
                if (rulesSection.showIf) {
                    let appState = getRelevantAppState(application, 'employer', rulesSection.sectionName, rulesSection);
                    appState = makeAppStateNested(appState);
                    showSection = checkIfObjectMeetsConditions(
                        rulesSection.sectionName,
                        rulesSection.showIf,
                        {
                            ...conditionEvaluators,
                            appState,
                        },
                        fullProps.companyProfile,
                        fullProps.rules.enrollment
                    );
                }

                if (showSection) {
                    if (rulesSection.sectionName === 'additionalContacts') {
                        // Call custom method
                        let additionalContactsCompletionData = checkCompletionAdditionalContacts(
                            application, conditionEvaluators, appStateProps, fullProps
                        );
                        if (additionalContactsCompletionData.inCompleteSectionNames.length > 0) {
                            completed = false
                        }
                    } else {
                        const eachSectionIncompletionData = checkEachSection('employer', rulesSection, rulesSection.sectionName, application, conditionEvaluators, fullProps);
                        completed = eachSectionIncompletionData.completed===false?false:completed;
                    }
                }
            }

            return {
                ...state,
                completed,
            };
        }

        case SAVE_EMPLOYER_FORM: {
            return {
                ...state,
                formSaved: true,
            };
        }
        case REMOVE_ADDITIONAL_CONTACT:
            const { application, contactIndex } = action;
            const { additionalContacts, errors } = application.employer;
            //Removing any stored errors from deleted additional contact
            for (const property in errors.additionalContacts) {
                if (property.slice(-1) == contactIndex) {
                    delete errors.additionalContacts[property];
                }
            }
            for (const property in additionalContacts) {
                // Checking if the object property belongs to the additional contact being removed
                // Object properties need to be removed for the completion check to work
                if (property.slice(-1) == contactIndex) {
                    delete additionalContacts[property];
                }
            }
            return {
                ...state,
                additionalContacts,
            };

        case ADD_ADDITIONAL_CONTACTS_RL: {
            const newAdditionalContacts = Object.assign(state.additionalContacts, action.payload);
            return {
                ...state,
                additionalContacts: newAdditionalContacts,
            };
        }
        case UPDATE_NUM_OF_ADDITIONAL_CONTACTS:
            return {
                ...state,
                numOfAdditionalContacts: action.payload,
            };
        case CONTAINS_NEWLY_ADDED_CONTACTS:
            return {
                ...state,
                additionalContacts: {
                    ...state.additionalContacts,
                    containsNewlyAddedContacts: action.payload,
                },
            };
        case TOGGLE_GROUP_NOT_ELIGIBLE:
            return {
                ...state,
                groupNotEligible: action.bool,
            };

        case UPDATE_EMPLOYER_TIN:
            const newGeneralInformation = deepCopy(state.generalInformation)
            newGeneralInformation.taxID = action.payload ? action.payload : ''
            return {
                ...state,
                generalInformation: newGeneralInformation
            };
        case UPDATE_GROUP_LEGAL_NAME:
            const newGnrlInfo = deepCopy(state.generalInformation);
            newGnrlInfo.groupLegalName = action.payload;
            return {
                ...state,
                generalInformation: newGnrlInfo
            }
        case UPDATE_ENROLL_APP_EMP_CONTRIBUTION:
            return{
                ...state,
                contribution: action.payload
            }
        case SET_EMPLOYER_ENROLLMENT_COMPLETE:
            return {
                ...state,
                completed: action.isComplete
            }
        case RESET_TO_INITIALSTATE:
            return initialState;

        default:
            return state;
    }
}
