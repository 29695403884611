import moment from 'moment';
import * as types from './actionTypes';
import { sendEmail } from '../utils/api/notifier';
import sendLog from './logActions';
import { environmentVariables } from '../utils/consts/consts';
import { getValueOrEmptyString, isUndefinedOrNull } from '../utils/validation/commonValidations';
import { createHistory } from './historyAction';
import {  sendErrorTrackingDataAction } from './analyticsAction';
/**
 * Reducer event to update the error handler.
 * @param {*} errorDetails Contains the type of the actionType, notifyError status (that can be used to show the user friendly message to the user and trigger error details to the support team) and error details object.
 */
export const notifyTechnicalError = (errorDetails) => (dispatch, getState) => {
    if (errorDetails) {
        const { actionType, notifyError, error } = errorDetails;

        if (actionType === 'apiRequest') {
            dispatch({
                type: types.UPDTE_FAILED_API_REQUEST,
                payload: {
                    notifyError,
                },
            });

            if (notifyError) {
                const currentDateTime = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');
                const { renewals, user } = getState();
                const { roleName, renewalCaseData, req } = renewals;

                let { memberGroupName, memberGroupId } = '';

                if (!isUndefinedOrNull(renewalCaseData)) {
                    memberGroupName = renewalCaseData.memberGroupName;
                    memberGroupId = renewalCaseData.memberGroupId;
                }

                sendEmail(
                    `SAMx Error <${error.message}> ${currentDateTime}`,
                    `A customer service error was received, please review: <br /><br /> 
                    Case Name: ${getValueOrEmptyString(memberGroupName)} <br />
                    Case Id: ${getValueOrEmptyString(memberGroupId)} <br /><br />
                    User Name: ${user.userName} <br />
                    Role: ${roleName} <br /><br />
                    Screen / Navigational point: <br />
                    Page Name: ${req.pageName ? req.pageName : ''} (${window.location.href}) <br />
                    Action Name: ${req.description} <br /><br />                                              
                    Error Deails: <br />
                    ${error.config !== undefined
        ? `URL:${error.config.baseURL}${error.config.url}<br />`
        : ''}
                    ${error.stack}<br /><br />
                    <b>For escalations, please see the boss man - Rico Burritt.</b>`,
                    environmentVariables.itSupportEmails,
                );

                const logObj = {
                    pageName: req.pageName,
                    actionName: req.description,
                    error: 'API Request Paid',
                    url: error.config !== undefined ? error.config.baseURL + error.config.url : '',
                    errorDesc: JSON.stringify(error.message),
                    errorStack: error.stack,
                };
                dispatch(createHistory('Error Handler action', req.pageName, 'Success', logObj));

                sendErrorTrackingDataAction({
                    activityName : `Technical Error :${JSON.stringify(error.message)}`,
                    errorFields :`${error.config !== undefined ? 'URL:'+ error.config.baseURL + error.config.url +'|': ''} Details: ${error.stack}`,
                    errorType :"Ui Error"
                })
                sendLog(logObj);
            }
        }
    }
};
