import { fiftyStates } from './JSONconsts';

const json = {
    generalInformation: {
        requestedEffectiveDate: {
            fieldNameDisplay: 'Requested Effective Date',
            fieldType: 'date',
            isRequired: true,
            validations: [
                {
                    validationType: 'effectiveDate',
                },
            ],
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'effectiveDate',
                },
            ],
            readOnly: [
                {
                    requiredConditions: 'all',
                    conditions: [
                        {
                            conditionType: 'hasPermission',
                            permission: 'changeEffectiveDate',
                        },
                    ],
                },
            ],
            helpContent: {
                question: 'What are the Eligible Effective Dates?',
                content: 'eligibleEffectiveDates',
            },
        },
        groupLegalName: {
            fieldNameDisplay: "Group's Legal Name",
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [ // Value from FS CI Page
                {
                    valueType: 'custom',
                    value: 'groupLegalName',
                },
            ],
            validations: [
                {
                    validationType: 'maxLength',
                    value: 120,
                },
            ],
        },
        taxID: {
            fieldNameDisplay: 'Tax ID',
            fieldType: 'textbox',
            isRequired: true,
            validations: [
                {
                    validationType: 'maxLength',
                    value: 9,
                },
                {
                    validationType: 'digitsOnly',
                },
                {
                    validationType: 'maxLength',
                    value: '9'
                }
            ],
            readOnly: [{ value: true }],
        },
    },
    primaryLocation: {
        streetAddress: {
            fieldNameDisplay: 'Street Address',
            fieldType: 'textbox',
            isRequired: true,
            validations: [
                {
                    validationType: 'maxLength',
                    value: 55,
                },
                {
                    validationType: 'poBoxAddress'
                }
            ],
            helpContent: {
                question: 'Are there invalid Street Addresses?',
                content: 'Yes, the Primary Location for the employer cannot be a PO Box, Canadian and/or any other foreign addresses.',
            },
        },
        streetAddressln2: {
            fieldNameDisplay: 'Street Address Line 2',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'maxLength',
                    value: 55,
                },
                {
                    validationType: 'poBoxAddress'
                }
            ],
        },
        city: {
            fieldNameDisplay: 'City',
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryCity',
                },
            ],
            validations: [
                {
                    validationType: 'maxLength',
                    value: 50,
                },
            ],
            readOnly: [{ value: true }],
        },
        state: {
            fieldNameDisplay: 'State',
            fieldType: 'dropdown',
            dropdownOptions: [
                {
                    requiredConditions: 'none',
                    conditions: [],
                    options: fiftyStates,
                },
            ],
            isRequired: true,
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryState',
                },
            ],
            readOnly: [{ value: true }],
        },
        zipCode: {
            fieldNameDisplay: 'Zip Code',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'zip',
                },
            ],
            isRequired: true,
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryZip',
                },
            ],
            readOnly: [{ value: true }],
        },
        zipCode4: {
            fieldNameDisplay: 'Zip Code + 4',
            fieldType: 'textbox',
            isRequired: false,
            validations: [
                {
                    validationType: 'zip4',
                },
            ],
        },
        isBillingAddressSame: {
            fieldNameDisplay: 'Is Billing Address the same as Primary Address?',
            fieldType: 'checkbox',
            isRequired : true,
            defaultValue: [
                {
                    valueType: 'boolean',
                    value: true,
                },
            ],
        },
    },
    billingAddress: {
        billingStreetAddress: {
            fieldNameDisplay: 'Street Address',
            fieldType: 'textbox',
            isRequired: true,
            validations: [
                {
                    validationType: 'maxLength',
                    value: 55,
                },
            ],
        },
        billingStreetAddressln2: {
            fieldNameDisplay: 'Street Address Line 2',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'maxLength',
                    value: 55,
                },
            ],
        },
        billingCity: {
            fieldNameDisplay: 'City',
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryCity',
                },
            ],
            validations: [
                {
                    validationType: 'maxLength',
                    value: 50,
                },
            ],
        },
        billingState: {
            fieldNameDisplay: 'State',
            fieldType: 'dropdown',
            dropdownOptions: [
                {
                    options: fiftyStates,
                },
            ],
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryState',
                },
            ],
            isRequired: true,
        },
        billingZipCode: {
            fieldNameDisplay: 'Zip Code',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'zip',
                },
            ],
            isRequired: true,
            defaultValue: [
                {
                    valueType: 'custom',
                    value: 'primaryZip',
                },
            ],
        },
        billingZipCode4: {
            fieldNameDisplay: 'Zip Code + 4',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'zip4',
                },
            ],
        },
    },
    primaryContactInformation: {
        primaryFirstName: {
            fieldNameDisplay: 'First Name',
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [ // Value from FS CI Page if available
                {
                    valueType: 'custom',
                    value: 'primaryFirstName',
                },
            ],
            validations: [
                {
                    validationType: 'maxLength',
                    value: 35,
                },
                {
                    validationType: 'lettersOnly',
                },
            ],
        },
        primaryLastName: {
            fieldNameDisplay: 'Last Name',
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [ // Value from FS CI Page if available
                {
                    valueType: 'custom',
                    value: 'primaryLastName',
                },
            ],
            validations: [
                {
                    validationType: 'maxLength',
                    value: 60,
                },
                {
                    validationType: 'lettersOnly',
                },
            ],
        },
        primaryTitle: {
            fieldNameDisplay: 'Title Name',
            fieldType: 'dropdown',
            dropdownOptions: [
                {
                    requiredConditions: 'none',
                    conditions: [],
                    options: [
                        'Mr',
                        'Miss',
                        'Ms',
                    ],
                },
            ],
        },
        primaryEmailAddress: {
            fieldNameDisplay: 'Email',
            fieldType: 'textbox',
            isRequired: true,
            defaultValue: [ // Value from FS CI Page if available
                {
                    valueType: 'custom',
                    value: 'primaryEmailAddress',
                },
            ],
            validations: [
                {
                    validationType: 'email',
                },
                {
                    validationType: 'maxLength',
                    value: 254,
                },
            ],
        },
        primaryTelephone: {
            fieldNameDisplay: 'Telephone',
            fieldType: 'textbox',
            isRequired: true,
            validations: [
                {
                    validationType: 'phone',
                },
            ],
        },
        primaryExt: {
            fieldNameDisplay: 'Ext.',
            fieldType: 'textbox',
            validations: [
                {
                    validationType: 'maxLength',
                    value: 4,
                },
                {
                    validationType: 'digitsOnly',
                },
            ],
        },
    },
    additionalContacts: {
        contactsTable: {
            fieldType: 'contactsTable',
        },
    },
    additionalGeneralInformation: {
        numYrsInBusiness: {
            fieldNameDisplay: 'How many years has the company been in business?',
            fieldType: 'dropdown',
            isRequired: true,
            dropdownOptions: [
                {
                    requiredConditions: 'none',
                    conditions: [],
                    options: [
                        '1 Year or More',
                        'Less than 1 Year',
                    ],
                },
            ],
        },
        organizationType: {
            fieldNameDisplay: 'Organization Type?',
            isRequired: true,
            fieldType: 'dropdown',
            dropdownOptions: [
                {
                    requiredConditions: 'none',
                    conditions: [],
                    options: [
                        'Partnership',
                        'C-Corp',
                        'S-Corp',
                        'LLC',
                        'LLP',
                        'Sole Proprietor',
                        'Other',
                    ],
                },
            ],
        },
        organizationTypeOther: {
            fieldNameDisplay: 'Please enter Organization Type',
            fieldType: 'textbox',
            isRequired: true,
            showIf: {
                requiredConditions: 'all',
                conditions: [
                    {
                        conditionType: 'appState',
                        appState: {
                            stateKey: 'additionalGeneralInformation',
                            stateSubKey: 'organizationType',
                            logic: 'is',
                            stateValue: 'Other',
                        },
                    },
                ],
            },
            validations: [
                {
                    validationType: 'maxLength',
                    value: 120,
                },
            ],
        },
        employeesOtherThanSpouse: {
            fieldNameDisplay: 'Did you have any employees other than yourself and your spouse during the preceding calendar year?',
            isRequired: false,
            fieldType: 'dropdown',
            dropdownOptions: [
                {
                    requiredConditions: 'none',
                    conditions: [],
                    options: [
                        'Yes',
                        'No',
                    ],
                },
            ],
        },
    },
};

export default json;
