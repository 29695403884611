import { isUndefinedOrNullOrEmpty } from "../validation/commonValidations";

export const calculateDependentLifeRate = (allEmployees, specialtyCriteria, rate, isUhcRenewalFlag, isSourceRenewal=false) => {
    let totalRate = 0;
    let empWithCobra = getEmpWithCobra(allEmployees);
    let familyCount=0,employeeAndSpouseCount=0

    if (isUhcRenewalFlag) {
        familyCount = specialtyCriteria.dependentLifeCensusSummary.employeeWithFamily - empWithCobra.empWithFamily
        employeeAndSpouseCount = specialtyCriteria.dependentLifeCensusSummary.employeeWithChild + specialtyCriteria.dependentLifeCensusSummary.employeeWithSpouse - empWithCobra.empWithChildOrSpouse
        if (familyCount == 0 && employeeAndSpouseCount == 0) {
            familyCount = specialtyCriteria.lifeCensusSummary.employeeWithFamily - empWithCobra.empWithFamily
            employeeAndSpouseCount = specialtyCriteria.lifeCensusSummary.employeeWithChild + specialtyCriteria.lifeCensusSummary.employeeWithSpouse - empWithCobra.empWithChildOrSpouse
            totalRate = rate.totalMonthlyPremium;
        }
        else {
            let childRate = rate.depLifeChildRate ? rate.depLifeChildRate : 0
            let spouseRate = rate.depLifeSpouseRate ? rate.depLifeSpouseRate : 0
            if (familyCount > 0) {
                totalRate += childRate * familyCount
            }
            if (employeeAndSpouseCount > 0) {
                totalRate += spouseRate * employeeAndSpouseCount
            }
        }
        
    } else {
        let depLifeEligibleEmp = 0;
        let childRate = !isUndefinedOrNullOrEmpty(rate.depLifeChildRate) ? rate.depLifeChildRate : 0

        allEmployees.forEach(employees => {
            employees.forEach(emp => {
                if (isSourceRenewal && emp.employeeStatus === 'Active' && emp.dependents.length > 0){
                    depLifeEligibleEmp +=1;
                } else if (emp.employeeStatus === 'Active' && emp.coverages['basicLife'] === 'EL' && emp.dependents.length > 0) {
                    depLifeEligibleEmp +=1;
                }
            })
        })

        totalRate = childRate * depLifeEligibleEmp;
        totalRate = totalRate.toFixed(2)
    }

    return totalRate
}

export const getEmpWithCobra = (allEmployees) => {
    let empWithChildOrSpouse = 0;
    let empWithFamily = 0;
    allEmployees.forEach(employees => {
        employees.forEach(emp => {
            if (emp.employeeStatus === 'COBRA') {
                switch (emp.coverages['basicLife']) {
                    case 'EE/SP':
                    case 'EE/CH':
                        empWithChildOrSpouse += 1;
                        break;
                    case 'EE/FAM':
                        empWithFamily += 1;
                        break;
                }
            }
        })
    })

    return {
        empWithChildOrSpouse: empWithChildOrSpouse,
        empWithFamily,
    }
}