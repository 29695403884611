export default {
    PAYMENT_METHODS: {
        DIRECT_DEBIT: 'DIRECT_DEBIT',
        ONETIME_BINDER: 'ONETIME_BINDER',
        UPLOAD_BINDER: 'UPLOAD_BINDER',
        EXTERNALLY_UPLOADED: 'EXTERNALLY_UPLOADED',
    },
    BILLING_METHODS: {
        ELECTRONIC: 'ELECTRONIC',
        PAPER: 'PAPER',
    },
    TAB_INDICES: {
        ONETIME_BINDER: 0,
        UPLOAD_BINDER: 1,
        DIRECT_DEBIT: 2,

        ELECTRONIC: 0,
        PAPER: 1,
    },
    ONETIME_BINDER_CHECK_LINK: 'https://usapayx.com/UHG_Oxford',
};
