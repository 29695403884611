import { getLast } from '../../utils/formatters/objects';
import deepCopy from '../../utils/deepCopy';
import { isEmpty } from '../../utils/formatters/strings';
import planCoversOOA from '../../utils/businessLogic/ooaChecker';
import { percentDifference } from '../../utils/formatters/numbers';
import { getTotalNumberCurrPlans } from '../products/enrollment/helper';
import { isUhcRenewals } from '../../utils/businessLogic/platformUtils'

const findPriceDiff = (plans, maxRateDifferential) => {
    if (isEmpty(maxRateDifferential) || plans.length < 2) {
        return {
            maxRateDifferential: false,
        };
    }

    const _plans = deepCopy(plans);
    _plans.sort((plan1, plan2) => plan1.quote - plan2.quote);
    const lowestPricePlan = _plans[0];
    const highestPricePlan = getLast(_plans);
    const priceDiff = 1 - (lowestPricePlan.quote / highestPricePlan.quote);
    if (priceDiff > maxRateDifferential) {
        return {
            maxRateDifferential: true,
            lowPlan: lowestPricePlan,
            highPlan: highestPricePlan,
        };
    }

    return {
        maxRateDifferential: false,
    };
};

export const findSelectionErrors = (reducerState, selectedPlans, selectedDentalPlans, selectedVisionPlans, selectedLifePlans, usingAgeBandedRating, usingSHOP,
    employeeClassing, employeeSTDClassing, employeeLTDClassing, enrolledLifePlans, parentPage,renewalShoppingHelperRenewalData,flow='  ',stateCode=null,
    totalEmp=null, enrolledStdPlans, selectedStdPlans, enrolledLtdPlans, selectedLtdPlans,product="") => {
    let errors = {};
    let numSinglesSelected;
    if (selectedPlans.length > 0 && (selectedPlans[0].platform === 'CIRRUS' || selectedPlans[0].platform === 'cirrus')) {
        numSinglesSelected = selectedPlans.length;
    } else {
        numSinglesSelected = selectedPlans.filter((sp) => sp.standAlone).length;
        const packagesSelected = {};
        let morePackagesThanMax = false;
        // outerLoop:
        for (let i = 0; i < selectedPlans.length; i++) {
            const sp = selectedPlans[i];
            const planSelectedPackage = sp.selectedPackages[0];
            if (planSelectedPackage) {
                packagesSelected[planSelectedPackage] = true;
            }
        }
        if (Object.keys(packagesSelected).length > reducerState.maxMultiChoicePackages) {
            morePackagesThanMax = true;
        }
        errors.maxMultiChoicePackages = morePackagesThanMax;
    }
    let isError=false;
    let isCurrGt=false;
    if(flow==='renewals'){
        const { maxSingleChoicePlans } = reducerState;
        const totalCurrentMedicalPlans = getTotalNumberCurrPlans(renewalShoppingHelperRenewalData)
        if(selectedPlans.length<= totalCurrentMedicalPlans && totalCurrentMedicalPlans>maxSingleChoicePlans){
            isCurrGt=true
            isError =false;
        }
        else if(selectedPlans.length> totalCurrentMedicalPlans && totalCurrentMedicalPlans>maxSingleChoicePlans){
            isCurrGt=true
            isError=true;
        }
    }
    if(flow==='renewals'){
        errors.maxSingleChoicePlans = ( isCurrGt?((numSinglesSelected > reducerState.maxSingleChoicePlans) && isError):(numSinglesSelected > reducerState.maxSingleChoicePlans))
    }
    else{
        errors.maxSingleChoicePlans = (numSinglesSelected > reducerState.maxSingleChoicePlans);
    }
    //updating maxSingleChoicePlans if there is no multi choice plans are selected
    let platform = selectedPlans[0] ? selectedPlans[0].platform : ''
    if (platform === 'PRIME') {
        let packages = Object.keys(selectedPlans).filter(pkg => pkg != 'standalone')
        let isSingle = true
        packages.forEach(element => {
            if (selectedPlans[element].length > 0) {
                isSingle = false
            }
        })
        if (isSingle) {
            const finalMaxStandalonePlans = isUhcRenewals()
                ? reducerState &&
                    reducerState.specialRules &&
                    reducerState.specialRules.maxStandalonePlans !== null
                    ? reducerState.specialRules.maxStandalonePlans
                    : numSinglesSelected
                : reducerState?.specialRules?.maxStandalonePlans;
            errors.maxSingleChoicePlans = numSinglesSelected > finalMaxStandalonePlans;
        }
    }
    if (stateCode && (stateCode === 'WV' || stateCode === 'VA' || stateCode === 'MD')) {
        if (totalEmp && totalEmp < 5) {
            if (selectedPlans.length > 1) {
                errors.maxSingleChoicePlans = true
            }
            else {
                errors.maxSingleChoicePlans = false
            }
        }
        else {
            if (selectedPlans.length > 3) {
                errors.maxSingleChoicePlans = true
            }
            else {
                errors.maxSingleChoicePlans = false
            }
        }
    }else if(stateCode && stateCode === 'FL'){
        if (totalEmp && totalEmp < 5) {
            if (selectedPlans.length > 1) {
                errors.maxSingleChoicePlans = true
            }
            else {
                errors.maxSingleChoicePlans = false
            }
        }else{
            if(product.includes("M") && selectedPlans.length > 2){
                errors.maxSingleChoicePlans = true
            }else{
                errors.maxSingleChoicePlans = false
            }
        }
    }
    errors = {
        ...errors,
        ...findPriceDiff(selectedPlans, reducerState.maxRateDifferential),
    };

    if (reducerState.atLeastOneOutOfAreaEmployee) {
        const ooaPlans = selectedPlans.filter((plan) => planCoversOOA(plan));
        const shopPlans = selectedPlans.filter((plan) => plan.isExchange);
        errors.atLeastOneOutOfAreaEmployee = ooaPlans.length === 0;
        errors.shopOOA = shopPlans.length > 0;
    }

    if (!isEmpty(reducerState.maxAgeBandedPlans)) {
        if (usingAgeBandedRating) {
            errors.maxAgeBandedPlans = selectedPlans.length > reducerState.maxAgeBandedPlans;
        } else {
            errors.maxAgeBandedPlans = false;
        }
    }

    errors.maxDentalPlans = selectedDentalPlans && selectedDentalPlans.length > reducerState.maxDentalPlans;
    errors.maxVisionPlans = selectedVisionPlans && selectedVisionPlans.length > reducerState.maxVisionPlans;
    if (selectedPlans && selectedPlans.length > 0 && selectedVisionPlans && selectedVisionPlans.length > 0){
        if (selectedPlans[0].platform === 'CIRRUS' || selectedPlans[0].platform === 'cirrus') {
            let foundVisionRider = selectedPlans.find(sel =>(sel.visionRider === true));
            if(foundVisionRider){
                errors.visionRider = true;
            } else {
                errors.visionRider = false;
            }
        } else {
            errors.visionRider = false;
        }
    } else {
        errors.visionRider = false;
    }

    const checkRenewalsClassing = (plans) =>{
        if(isUhcRenewals() && plans && plans.length > 0 && plans[0].selectedClass !== undefined && plans[0].selectedClass !== ""){
            return true;
        }else{
            return false;
        }
    }

    const lifeStdLtdErrorChecker = (enrolledPlans, selectedPlans, maxAllowedPlans, productEmployeeClassing) => {
        // Generalizing classed specialty products into a reusable function
        // {PARAM} enrolledPlans : array of enrolled plans for specific product
        // {PARAM} selectedPlans : array of selected plans for specific product
        // {PARAM} maxAllowedPlans : Int of max allowed plans for specific product coming from enrollmentRulesReducer.js
        // {PARAM} productEmployeeClassing: the classing object for the specific product
        // If plans are classed, only one plan can be selected for each class
        // Note: there is functionality in place to prevent selection of multiple plans within a class
        let maxPlanError = false;
        if (productEmployeeClassing && productEmployeeClassing.classingOpted) {
            // Quote review should evaluate enrolledPlans Array for specific product
            if (parentPage === "Quote Review") {
                let enrolledClasses = [];
                for (let plan of enrolledPlans) {
                    if (enrolledClasses.includes(plan.selectedClass)) {
                        maxPlanError = true;
                    } else {
                        enrolledClasses.push(plan.selectedClass);
                    }
                }
                if (enrolledPlans?.length > 0 && productEmployeeClassing.employeeSelectedClasses.length !== enrolledClasses.length) {
                    maxPlanError = true;
                }
            } else { // Plan Selection should evaluate selectedPlans Array for specific product
                let selectedClasses = [];
                for (let plan of selectedPlans) {
                    if (selectedClasses.includes(plan.selectedClass)) {
                        maxPlanError = true;
                    } else {
                        selectedClasses.push(plan.selectedClass);
                    }
                }
                if (selectedPlans?.length > 0 && productEmployeeClassing.employeeSelectedClasses.length !== selectedClasses.length) {
                    maxPlanError = true;
                }
            }
        } else {
            maxPlanError = selectedPlans && selectedPlans.length > maxAllowedPlans;
        }
        return maxPlanError;
    }
    errors.maxLifePlans = checkRenewalsClassing(selectedLifePlans) ? false : lifeStdLtdErrorChecker(enrolledLifePlans, selectedLifePlans, reducerState.maxLifePlans, employeeClassing);
    errors.maxSTDPlans = checkRenewalsClassing(selectedStdPlans) ? false : lifeStdLtdErrorChecker(enrolledStdPlans, selectedStdPlans, reducerState.maxSTDPlans, employeeSTDClassing);
    errors.maxLTDPlans = checkRenewalsClassing(selectedLtdPlans) ? false : lifeStdLtdErrorChecker(enrolledLtdPlans, selectedLtdPlans, reducerState.maxLTDPlans, employeeLTDClassing);
    errors.incompatibleSTDLTDPlans = employeeClassing && employeeClassing.classingOpted ? checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledLtdPlans) : false


    const numErrors = Object.keys(errors).filter((keyName) => errors[keyName] === true).length;

    return {
        errors,
        numErrors,
    };
};

export const findErrors = (reducerState, enrolledPlansMap, usingAgeBandedRating, type = "medical",
    enrolledSpecialtyPlans = [], employeeClassing, uhcRenewalsFlag = false, stateCode = null, totalEmp = null,
    groupSize = null) => {

    let errors = reducerState.errors || {}
    const numSinglesEnrolled = enrolledPlansMap.standalone ? enrolledPlansMap.standalone.length : 0;
    const packagesEnrolled = Object.keys(enrolledPlansMap)
        .filter((keyName) => keyName !== 'standalone' && keyName !== 'Single') // We don't care if standalone plans are also selected
        .filter((keyName) => enrolledPlansMap[keyName].length > 0); // Ignore empty lists

    const numPackagesEnrolled = packagesEnrolled.length;

    const finalMaxSingleChoicePlans = uhcRenewalsFlag ? (reducerState.maxSingleChoicePlans !== null ? reducerState.maxSingleChoicePlans : numSinglesEnrolled) : reducerState.maxSingleChoicePlans;
    const finalMaxMultiChoicePackages = uhcRenewalsFlag ? (reducerState.maxMultiChoicePackages !== null ? reducerState.maxMultiChoicePackages : numPackagesEnrolled) : reducerState.maxMultiChoicePackages;
    const selectedMedicalPlans = [].concat(...Object.values(enrolledPlansMap));

    errors.maxSingleChoicePlans = numSinglesEnrolled > finalMaxSingleChoicePlans;
    errors.maxMultiChoicePackages = numPackagesEnrolled > finalMaxMultiChoicePackages;
    errors.multiNames = packagesEnrolled;
    errors.multiMedCompRating = enrolledPlansMap.standalone && enrolledPlansMap.standalone.length > 1 && !usingAgeBandedRating && (reducerState.stateName === 'Maryland' || stateCode === 'MD');

    // updating maxSingleChoicePlans if there is no multi choice plans are selected
    let platform
    if (enrolledPlansMap && Object.keys(enrolledPlansMap).includes('standalone')) {
        platform = enrolledPlansMap.standalone[0] ? enrolledPlansMap.standalone[0].platform : ''
    }

    if (platform === 'PRIME') {
        let packages = Object.keys(enrolledPlansMap).filter(pkg => pkg != 'standalone')
        let isSingle = true

        packages.forEach(element => {
            if (enrolledPlansMap[element].length > 0) {
                isSingle = false
            }
        })

        if (isSingle) {
            const finalMaxStandalonePlans = uhcRenewalsFlag ? (reducerState?.specialRules?.maxStandalonePlans ? reducerState.specialRules.maxStandalonePlans : numSinglesEnrolled) : reducerState.specialRules.maxStandalonePlans;
            errors.maxSingleChoicePlans = numSinglesEnrolled > finalMaxStandalonePlans;
        }
    }
    
    if (stateCode && (stateCode === 'WV' || stateCode === 'VA' || stateCode === 'MD')) {
        if (totalEmp && totalEmp < 5) {
            let selectedPlansLength = selectedMedicalPlans.length;
            if ((stateCode === 'FL' && selectedPlansLength > 1 ) || (numSinglesEnrolled > 1)) {
                errors.maxSingleChoicePlans = true
            } else {
                errors.maxSingleChoicePlans = false
            }
        } else {
            if (numSinglesEnrolled > 3) {
                errors.maxSingleChoicePlans = true
            } else {
                errors.maxSingleChoicePlans = false
            }
        }
    }else if(stateCode && stateCode === 'FL'){
        let selectedPlansLength = selectedMedicalPlans.length;
        if (totalEmp && totalEmp < 5) {
            if ((selectedPlansLength > 1 ) || (numSinglesEnrolled > 1)) {
                errors.maxSingleChoicePlans = true
            } else {
                errors.maxSingleChoicePlans = false
            }
        } else {
            if (selectedPlansLength === 0 ) {
                errors.maxSingleChoicePlans = true
            } else {
                errors.maxSingleChoicePlans = false
            }
        }
    }

    let allEnrolledPlans = [];
    Object.keys(enrolledPlansMap).forEach((pkg) => {
        allEnrolledPlans = allEnrolledPlans.concat(enrolledPlansMap[pkg]);
    });

    errors = {
        ...errors,
        ...findPriceDiff(allEnrolledPlans, reducerState.maxRateDifferential),
    };

    if (reducerState.atLeastOneOutOfAreaEmployee) {
        const ooaPlans = allEnrolledPlans.filter((plan) => planCoversOOA(plan));
        const shopPlans = allEnrolledPlans.filter((plan) => plan.isExchange);
        errors.atLeastOneOutOfAreaEmployee = ooaPlans.length === 0;
        errors.shopOOA = shopPlans.length > 0;
    }

    if (!isEmpty(reducerState.maxAgeBandedPlans)) {
        if (usingAgeBandedRating) {
            errors.maxAgeBandedPlans = allEnrolledPlans.length > (reducerState.maxAgeBandedPlans !== null ? reducerState.maxAgeBandedPlans : allEnrolledPlans.length);
        } else {
            errors.maxAgeBandedPlans = false;
        }
    }

    //Handling rule scenario that STD Plans must be selected with at least one medical, dental, vision, etc. plan
    //(With 2-50 employees), but just medical and STD plan selected/unselected
    const enrolledDentalPlans = uhcRenewalsFlag && type.toLowerCase() === "dental" ? enrolledSpecialtyPlans : reducerState.enrolledDentalPlans;
    const enrolledVisionPlans = uhcRenewalsFlag && type.toLowerCase() === "vision" ? enrolledSpecialtyPlans : reducerState.enrolledVisionPlans;
    const enrolledLifePlans = uhcRenewalsFlag && type.toLowerCase() === "life" ? enrolledSpecialtyPlans : reducerState.enrolledLifePlans;
    const enrolledStdPlans = uhcRenewalsFlag && type.toLowerCase() === "std" ? enrolledSpecialtyPlans : reducerState.enrolledSTDPlans;
    const enrolledLtdPlans = uhcRenewalsFlag && type.toLowerCase() === "ltd" ? enrolledSpecialtyPlans : reducerState.enrolledLTDPlans;
    const enrolledSuppLifePlans = uhcRenewalsFlag && type.toLowerCase() === "supplife" ? enrolledSpecialtyPlans : reducerState.enrolledSuppLifePlans;

    errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, selectedMedicalPlans,
        enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

    errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, selectedMedicalPlans,
        enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

    errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, selectedMedicalPlans,
        enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledStdPlans, groupSize);

    // Check for enrolled specialty plan errors
    if (type !== 'medical') {
        errors = {
            ...errors,
            ...findSpecialtyErrors(enrolledSpecialtyPlans, type ? type.toLowerCase() : 'medical', reducerState,
                employeeClassing, uhcRenewalsFlag, groupSize, selectedMedicalPlans)
        }
    }
    const numErrors = Object.keys(errors).filter((keyName) => errors[keyName] === true).length;

    return {
        errors,
        numErrors,
    };
};

const dualDentalCheck = (plans) => {
    if ((plans || []).length !== 2) return false

    const planA = plans[0]
    const planB = plans[1]
    // Checking rule #1, percent difference between plan cost > 20%
    if (percentDifference(planA.byCensus.totalMonthlyPremium, planB.byCensus.totalMonthlyPremium) > 20) {
        // Checking rule #2
        // More than one of these things must be different in order for this to return true
        // [true, true, false] - can be quoted
        // [true, false, false] - cannot be quoted
        // true means a difference has been found
        let differences = []

        differences.push(planA.maxBenefit.in !== planB.maxBenefit.in && planA.maxBenefit.out !== planB.maxBenefit.out)
        differences.push(planA.ucr !== planB.ucr)
        differences.push(planA.orthodontics.inCovered !== planB.orthodontics.inCovered && planA.orthodontics.outCovered !== planB.orthodontics.outCovered)

        return differences.filter(val => val === true).length >= 2
    }
    else {
        return false
    }
}

const checkLifeOrStdOrLtdRequire = (employeeClassing, enrolledStdOrLtdPlans, maxLifeOrStdOrLtdPlans) => {
    let maxError = false;
    if (employeeClassing && employeeClassing.classingOpted) {
        let enrolledClasses = [];
        for (let plan of enrolledStdOrLtdPlans) {
            if (enrolledClasses.includes(plan.selectedClass)) {
                maxError = true;
            } else {
                enrolledClasses.push(plan.selectedClass);
            }
        }

        if (enrolledStdOrLtdPlans?.length > 0 && employeeClassing.employeeSelectedClasses?.length !== enrolledClasses.length) {
            maxError = true;
        }

    } else {
        maxError = enrolledStdOrLtdPlans.length > (maxLifeOrStdOrLtdPlans !== null ? maxLifeOrStdOrLtdPlans :  enrolledStdOrLtdPlans.length);
    }

    return maxError;
}

const checkStdOrLtdRequireOtherProd = (enrolledStdOrLtdPlans, enrolledMedicalPlans, enrolledDentalPlans, enrolledVisionPlans,
    enrolledLifePlans, groupSize) => {

    if (enrolledStdOrLtdPlans.length > 0 && (groupSize >= 2 && groupSize <= 50)) {
        if (!(enrolledMedicalPlans.length > 0 || enrolledDentalPlans.length > 0 || enrolledVisionPlans.length > 0 ||
            enrolledLifePlans.length > 0)) {
                return true;
        }

        return false;
    }

    return false;
}

const checkLtdRequireOtherProdWithMed = (enrolledLtdPlans, enrolledMedicalPlans, enrolledDentalPlans, enrolledVisionPlans,
    enrolledLifePlans, enrolledStdPlans, groupSize) => {

    if (enrolledLtdPlans.length > 0 && (groupSize >= 2 && groupSize <= 50)) {
        if (enrolledMedicalPlans.length > 0) {
            if (!(enrolledDentalPlans.length > 0 || enrolledVisionPlans.length > 0 || enrolledLifePlans.length > 0 ||
                enrolledStdPlans.length > 0)) {
                return true;
            }

            return false;
        }

        return false;
    }

    return false;
}

const checkStdLtdBenefitIncompatible = (employeeClassing, enrolledStdPlans, enrolledLtdPlans) => {
    // STD Benefit Duration of 13 Weeks needs LTD Elimination Period of 90 Days or 3 Months
    // STD Benefit Duration of 26 Weeks needs LTD Elimination Period of 180 Days
    // If plans are classes, only the first selected of STD/LTD for each class is evaluated for compatibility
    // {PARAM} enrolledStdPlans : array of enrolled plans for STD
    // {PARAM} enrolledLtdPlans : array of enrolled plans for LTD
    // {PARAM} employeeClassing: the classing object for the specific product enrolledPlans

    if (employeeClassing && employeeClassing.classingOpted) {
        // enrolledSTDClasses to track STD classes evaluated
        let enrolledSTDClasses = [];
        for (let stdPlan of enrolledStdPlans) {
            if (!enrolledSTDClasses.includes(stdPlan.selectedClass)) {
                for (let ltdPlan of enrolledLtdPlans) { // can change this to a .find?
                    if (stdPlan.selectedClass === ltdPlan.selectedClass && stdPlan?.benifitDuration === '13 weeks' && (ltdPlan?.eliminationPeriod !== '90 days' && ltdPlan?.eliminationPeriod !== '3 months')) {
                        return true
                    }

                    if (stdPlan.selectedClass === ltdPlan.selectedClass && stdPlan?.benifitDuration === '26 weeks' && ltdPlan?.eliminationPeriod !== '180 days') {
                        return true
                    }
                }

                enrolledSTDClasses.push(stdPlan.selectedClass);
            }
        }

        return false     
    } else { // Only check first STD and LTD plans enrolled since only one of each can move to Enrollment step
        if (enrolledStdPlans?.length > 0 && enrolledLtdPlans?.length > 0) {
            if (enrolledStdPlans[0]?.benifitDuration === '13 weeks' && (enrolledLtdPlans[0]?.eliminationPeriod !== '90 days' && enrolledLtdPlans[0]?.eliminationPeriod !== '3 months')) {
                return true
            }

            if (enrolledStdPlans[0]?.benifitDuration === '26 weeks' && enrolledLtdPlans[0]?.eliminationPeriod !== '180 days') {
                return true
            }
        } else {
            return false
        }
    }
}

const findSpecialtyErrors = (enrolledPlans, type, reducerState, employeeClassing, uhcRenewalsFlag, groupSize, medicalPlans) => {

    const enrolledMedicalPlans = medicalPlans;
    const enrolledDentalPlans = uhcRenewalsFlag && type === "dental" ? enrolledPlans : reducerState.enrolledDentalPlans;
    const enrolledVisionPlans = uhcRenewalsFlag && type === "vision" ? enrolledPlans : reducerState.enrolledVisionPlans;
    const enrolledLifePlans = uhcRenewalsFlag && type === "life" ? enrolledPlans : reducerState.enrolledLifePlans;
    const enrolledDependentLifePlans = uhcRenewalsFlag && type === "dependentlife" ? enrolledPlans : reducerState.enrolledDependentLifePlans;
    const enrolledStdPlans = uhcRenewalsFlag && type === "std" ? enrolledPlans : reducerState.enrolledSTDPlans;
    const enrolledLtdPlans = uhcRenewalsFlag && type === "ltd" ? enrolledPlans : reducerState.enrolledLTDPlans;
    const enrolledSuppLifePlans = uhcRenewalsFlag && type === "supplife" ? enrolledPlans : reducerState.enrolledSuppLifePlans.filter(plan => plan.enrolled); // Supp EE Plan;
    const enrolledSuppDepLifePlans = uhcRenewalsFlag && type === "suppdeplife" ? enrolledPlans : reducerState.enrolledSuppLifePlans.filter(plan => plan.SLSpPlan.enrolled); // Supp Dep Plan;

    let errors = {};

    switch (type) {
        case 'dental':
            if (enrolledPlans.length === 2) {
                if (!dualDentalCheck(enrolledPlans)) {
                    errors.maxDentalPlans = enrolledPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans :  enrolledPlans.length);
                } else {
                    errors.maxDentalPlans = false
                }
            } else {
                errors.maxDentalPlans = enrolledPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans :  enrolledPlans.length);
            }
            errors.maxVisionPlans = enrolledVisionPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans :  enrolledVisionPlans.length);

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class

            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLifePlans, reducerState.maxLifePlans);
            errors.maxDependentLifePlans = (enrolledDependentLifePlans || []).length > reducerState.maxDependentLifePlans;
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;

            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, enrolledMedicalPlans,
                enrolledPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledPlans, enrolledVisionPlans, enrolledLifePlans, enrolledStdPlans, groupSize);
            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledStdPlans, reducerState.maxSTDPlans);
            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLtdPlans, reducerState.maxLTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledLtdPlans);
            break;
        case 'vision':
            errors.maxDentalPlans = enrolledDentalPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans : enrolledDentalPlans.length);
            errors.maxVisionPlans = enrolledPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans : enrolledPlans.length);

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class

            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLifePlans, reducerState.maxLifePlans);
            errors.maxDependentLifePlans = (enrolledDependentLifePlans || []).length > reducerState.maxDependentLifePlans;
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;

            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledPlans, enrolledLifePlans, enrolledStdPlans, groupSize);

            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledStdPlans, reducerState.maxSTDPlans);
            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLtdPlans, reducerState.maxLTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledLtdPlans);
            break;
        case 'life':
            errors.maxDentalPlans = enrolledDentalPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans : enrolledDentalPlans.length);
            errors.maxVisionPlans = enrolledVisionPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans : enrolledVisionPlans.length);

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class
            errors.depLifeWithoutLife = false
            if ((enrolledLifePlans.length === 0 || enrolledPlans.length === 0) && enrolledDependentLifePlans.length > 0) {
                errors.depLifeWithoutLife = true
            } else if (enrolledLifePlans.length === 0 && enrolledDependentLifePlans.length === 0) {
                errors.depLifeWithoutLife = false
            }

            if (enrolledPlans.length > 0 && enrolledDependentLifePlans.length > 0) {
                errors.depLifeWithoutLife = false;
            }

            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledPlans, reducerState.maxLifePlans);
            errors.maxDependentLifePlans = (enrolledDependentLifePlans || []).length > reducerState.maxDependentLifePlans;
            // Supp Life Rules
            errors.suppLifeWithoutLife = false;

            if ((enrolledLifePlans.length === 0 || enrolledPlans.length === 0) && enrolledSuppLifePlans.length > 0) {
                errors.suppLifeWithoutLife = true;
            } else if (enrolledLifePlans.length === 0 && enrolledSuppLifePlans.length === 0) {
                errors.suppLifeWithoutLife = false;
            }

            if (enrolledPlans.length > 0 && enrolledSuppLifePlans.length > 0) {
                errors.suppLifeWithoutLife = false;
            }

            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;
            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledPlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledPlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledPlans, enrolledStdPlans, groupSize);
            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledStdPlans, reducerState.maxSTDPlans);
            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLtdPlans, reducerState.maxLTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledLtdPlans);
            break;
        case "dependentlife":
            errors.maxDentalPlans = enrolledDentalPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans : enrolledDentalPlans.length);
            errors.maxVisionPlans = enrolledVisionPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans : enrolledVisionPlans.length);
            errors.depLifeWithoutLife = false

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class

            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLifePlans, reducerState.maxLifePlans);
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;

            if (enrolledLifePlans.length === 0 && enrolledDependentLifePlans.length > 0) {
                errors.depLifeWithoutLife = true
            } else if (enrolledLifePlans.length === 0 && enrolledDependentLifePlans.length === 0) {
                errors.depLifeWithoutLife = false
            }

            if (enrolledPlans.length == 0) {
                errors.depLifeWithoutLife = false
            }

            errors.maxDependentLifePlans = enrolledPlans.length > (reducerState.maxDependentLifePlans !== null ? reducerState.maxDependentLifePlans : enrolledPlans.length);

            if ((enrolledPlans.length > 0) && !(enrolledLifePlans.length > 0)) {
                errors.maxDependentLifePlans = true;
            }

            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledStdPlans, groupSize);

            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledStdPlans, reducerState.maxSTDPlans);
            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLtdPlans, reducerState.maxLTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledLtdPlans);
            break;
        case "std":
            errors.maxDentalPlans = enrolledDentalPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans : enrolledDentalPlans.length);
            errors.maxVisionPlans = enrolledVisionPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans : enrolledVisionPlans.length);

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class
            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLifePlans, reducerState.maxLifePlans);
            errors.maxDependentLifePlans = (enrolledDependentLifePlans || []).length > reducerState.maxDependentLifePlans;
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;

            //If std product selected, within the groupsize range of 2-50 employees, another product must be selected with it
            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledLtdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledPlans, groupSize);

            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledPlans, reducerState.maxSTDPlans);
            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLtdPlans, reducerState.maxLTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledPlans, enrolledLtdPlans);
            break;
        case "ltd":
            errors.maxDentalPlans = enrolledDentalPlans.length > (reducerState.maxDentalPlans !== null ? reducerState.maxDentalPlans : enrolledDentalPlans.length);
            errors.maxVisionPlans = enrolledVisionPlans.length > (reducerState.maxVisionPlans !== null ? reducerState.maxVisionPlans : enrolledVisionPlans.length);

            // If life plans are being classes, only one plan can be selected for each class
            // Note: there is functionality in place to prevent selection of multiple plans within a class
            errors.maxLifePlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledLifePlans, reducerState.maxLifePlans);
            errors.maxDependentLifePlans = (enrolledDependentLifePlans || []).length > reducerState.maxDependentLifePlans;
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;

            //If std product selected, within the groupsize range of 2-50 employees, another product must be selected with it
            errors.stdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledStdPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProd = checkStdOrLtdRequireOtherProd(enrolledPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, groupSize);

            errors.ltdRequireOtherProdWithMed = checkLtdRequireOtherProdWithMed(enrolledPlans, enrolledMedicalPlans,
                enrolledDentalPlans, enrolledVisionPlans, enrolledLifePlans, enrolledStdPlans, groupSize);

            errors.maxLTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledPlans, reducerState.maxLTDPlans);
            errors.maxSTDPlans = checkLifeOrStdOrLtdRequire(employeeClassing, enrolledStdPlans, reducerState.maxSTDPlans);
            errors.incompatibleSTDLTDPlans = uhcRenewalsFlag ? false : checkStdLtdBenefitIncompatible(employeeClassing, enrolledStdPlans, enrolledPlans);
            break;
        case "supplife":
            errors.suppLifeWithoutLife = false;
            if (
                enrolledLifePlans.length === 0 &&
                !(enrolledPlans.filter((plan) => plan.enrolled).length > 0)
            ) {
                errors.suppLifeWithoutLife = false;
            }

            if (
                enrolledPlans.filter((plan) => plan.enrolled).length > 0 &&
                enrolledLifePlans.length == 0
            ) {
                errors.suppLifeWithoutLife = true;
            }

            errors.maxSuppLifePlans =
                enrolledPlans.filter((plan) => plan.enrolled).length >
                (reducerState.maxSuppLifePlans !== null
                ? reducerState.maxSuppLifePlans
                : enrolledPlans.filter((plan) => plan.enrolled).length);
                
            errors.suppDepLifeWithSuppLife = false;

            if (enrolledPlans.length > 0) {
                const suppDepLifePlan = enrolledPlans.filter(
                    (plan) => !plan.enrolled && plan.SLSpPlan.enrolled
                );

                if (suppDepLifePlan.length > 0) {
                    errors.suppDepLifeWithSuppLife = true;
                } else {
                    errors.suppDepLifeWithSuppLife = false;
                }
            }

            if (enrolledPlans.length == 0) {
                errors.suppLifeWithoutLife = false;
            }

            break;
        case "suppdeplife":
            errors.maxSuppLifePlans = (enrolledSuppLifePlans || []).length > reducerState.maxSuppLifePlans;
            if (enrolledPlans.length > reducerState.maxSuppLifePlans) {
                errors.maxSuppLifePlans = true
            }
            errors.suppDepLifeWithSuppLife = false;
            if (
                enrolledSuppLifePlans.length == 0 &&
                enrolledSuppDepLifePlans.length > 0
            ) {
                errors.suppDepLifeWithSuppLife = true;
            }

            if (enrolledPlans.length > 0) {
                const suppDepLifePlan = enrolledPlans.filter(
                    (plan) => !plan.enrolled && plan.SLSpPlan.enrolled
                );

                if (suppDepLifePlan.length > 0) {
                    errors.suppDepLifeWithSuppLife = true;
                } else {
                    errors.suppDepLifeWithSuppLife = false;
                }
            }

            if (enrolledPlans.length == 0) {
                errors.suppDepLifeWithSuppLife = false;
            }

            if (enrolledLifePlans.length === 0 && enrolledPlans.length == 0) {
                errors.suppLifeWithoutLife = false;
            }
            
            errors.maxSuppDepLifePlans =
                enrolledPlans.filter((plan) => plan.SLSpPlan.enrolled).length >
                (reducerState.maxDependentSuppLifePlans !== null
                ? reducerState.maxDependentSuppLifePlans
                : enrolledPlans.filter((plan) => plan.SLSpPlan.enrolled).length);

            errors.maxSuppLifePlans =
                enrolledPlans.filter((plan) => plan.enrolled).length >
                (reducerState.maxSuppLifePlans !== null
                ? reducerState.maxSuppLifePlans
                : enrolledPlans.filter((plan) => plan.enrolled).length);
                
            break;
            default:
            errors.depLifeWithoutLife = false;
            errors.maxDentalPlans = false;
            errors.maxVisionPlans = false;
            errors.maxLifePlans = false;
            errors.maxSTDPlans = false;
            errors.maxLTDPlans = false;
            errors.maxDependentLifePlans = false;
            errors.stdRequireOtherProd = false;
            errors.ltdRequireOtherProd = false;
            errors.ltdRequireOtherProdWithMed = false;
            errors.incompatibleSTDLTDPlans = false;
            errors.suppLifeWithoutLife = false;
            errors.suppDepLifeWithSuppLife = false;
            errors.maxSuppLifePlans = false;
            errors.maxSuppDepLifePlans = false;
    }

    return errors;
};
