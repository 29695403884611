import * as types from './actionTypes';
import sendLog from './logActions';
import { axios } from '../utils/api/axiosConfig';
import { identifyPlatform } from '../components/activities/Renewal/Review/reviewUtils';

const apiRoutes = require('../utils/api/apiRouteConfig');

export function addOwnerAssignmentData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'ADD_OWNER_ASSIGNMENT_DETAILS',
        });

        dispatch({
            type: types.ADD_OWNER_ASSIGNMENT_DETAILS,
            payload: data,
        });
    };
}

export function addSalesRepData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'ADD_SALES_REP_DETAILS',
        });

        dispatch({
            type: types.ADD_SALES_REP_DETAILS,
            payload: data,
        });
    };
}

export function setSalesRepAvailFlag(val) {
    return (dispatch) => { 
        dispatch({
            type: types.SET_SALESREP_AVAIL_FLAG,
            payload: val,
        });
    }
}

export function setGASalesRepAvailFlag(val) {
    return (dispatch) => { 
        dispatch({
            type: types.SET_GASALESREP_AVAIL_FLAG,
            payload: val,
        });
    }
}

export function updateSalesRepData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'UPDATE_SALES_REP_DATA',

        });
        dispatch({
            type: types.UPDATE_SALES_REP_DATA,
            payload: data,
        });
    };
}

export function addGASalesRepData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'ADD_GA_SALES_REP_DETAILS',

        });

        dispatch({
            type: types.ADD_GA_SALES_REP_DETAILS,
            payload: data,
        });
    };
}

/**
 * @description Adds a general agent to the corresponding owner assignment array in Redux
 *
 * @param {Object} responseData - Object containing the data for the agent to be added
 */
export function addGeneralAgentData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'ADD_GENERAL_AGENT_DETAILS',

        });

        dispatch({
            type: types.ADD_GENERAL_AGENT_DETAILS,
            payload: data,
        });
    };
}

/**
 * @description Adds a agency to the corresponding owner assignment array in Redux
 *
 * @param {Object} responseData - Object containing the data for the agency to be added
 */
export function addAgencyData(responseData) {
    return (dispatch) => {
        const data = {
            responseData,
        };
        sendLog({
            action: 'ADD_AGENCY_DETAILS',
        });

        dispatch({
            type: types.ADD_AGENCY_DETAILS,
            payload: data,
        });
    };
}

/**
 * @description Removes an owner from the corresponding owner assignment array in Redux
 * @param {String} owner - Name of the owner to be removed (needed for sales rep since sales reps are not guaranteed to have PCIS)
 * @param {String} pcis - PCIS ID of the owner to be removed
 * @param {String} ownerType - type of owner to be removed i.e. Sales Rep, Producer, General Agent, Agency
 */
export function removeOwnerAssignment(owner, pcis, ownerType) {
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_OWNER_ASSIGNMENT',
            owner,
            pcis,
            ownerType,
        });

        dispatch({
            type: types.REMOVE_OWNER_ASSIGNMENT,
            owner,
            pcis,
            ownerType,
        });
    };
}

export const getRelatedSalesRepFromOwner = (pcis, ownerType) => (dispatch) => {
    const type = ownerType === 'General Agent' ? 'generalagent' : 'producer';
    axios.get(`${apiRoutes.quickQuotes}/getRelatedSalesRep?pcis=${pcis}&type=${type}`)
        .then((res) => {
            const data = res.data.salesReps;
            if (data !== undefined && data.length !== 0) {
                // TODO: ask if producer can have multiple sales reps
                const salesRep = data[0];
                switch (ownerType) {
                    case 'Producers':
                        dispatch(setSalesRepAvailFlag(false));
                        dispatch(addSalesRepData(salesRep));
                        break;
                    case 'Agency':
                        dispatch(addSalesRepData(salesRep));
                        break;
                    case 'General Agent':
                        dispatch(setGASalesRepAvailFlag(false));
                        dispatch(addGASalesRepData(salesRep));
                        break;
                    default:
                }
            } else { 
                switch (ownerType) {
                    case 'Producers':
                        dispatch(setSalesRepAvailFlag(true));
                        break;
                    case 'General Agent':
                        dispatch(setGASalesRepAvailFlag(true));
                        break;
                    default:
                }
            }
        }).catch((err) => {
            console.log(err);
        });
};

/**
 * @desciption Sets the default owner for a new case based on login data from SSO payload
 */
export const setDefaultOwner = (role, pcis, email, isRenewals = false) => (dispatch) => {
    const validRoles = ['Broker Admin', 'Agency Admin', 'General Agent Admin', 'GA Admin', 'Sales Manager', 'Sales Rep',
        'Specialty Sales', 'Broker Delegate', 'Agency Delegate', 'General Agent Delegate', 'GA Delegate'];
    const ownerTypes = ['producer', 'agency', 'generalagent', 'salesrep'];
    // For renewals, irregardless of role the owners should be set based on IBR data
    if (validRoles.includes(role) || isRenewals) {
        // Hit all collections and assign appropriate owners
        ownerTypes.forEach((ownerType) => {
            // If ownerType is salesrep, we pass email in place of pcis
            axios.get(`${apiRoutes.quickQuotes}/searchOwnerDataByPCIS?pcis=${ownerType === 'salesrep' ? email : pcis}&ownerType=${ownerType}&workflow=${isRenewals ? 'renewal' : 'fullshopping'}`)
                .then((res) => {
                    const { owner } = res.data;
                    if (owner !== undefined && owner.length !== 0) {
                        switch (ownerType) {
                            case 'producer':
                                dispatch(addOwnerAssignmentData(owner[0]));
                                dispatch(getRelatedSalesRepFromOwner(pcis, 'Producers'));
                                break;
                            case 'agency':
                                dispatch(addAgencyData(owner[0]));
                               // dispatch(getRelatedSalesRepFromOwner(pcis, 'Agency'));
                                break;
                            case 'generalagent':
                                dispatch(addGeneralAgentData(owner[0]));
                                dispatch(getRelatedSalesRepFromOwner(pcis, 'General Agent'));
                                break;
                            case 'salesrep':
                                dispatch(addSalesRepData(owner[0]));
                                break;
                            default:
                                break;
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });
        });
    }
};

export function resetOwnerAssignment(previousOwnerAssignment) {
    return (dispatch) => {
        sendLog({
            action: 'RESET_OWNER_ASSIGNMENT_DETAILS',
        });

        dispatch({
            type: types.RESET_OWNER_ASSIGNMENT_DETAILS,
            payload: previousOwnerAssignment,
        });
    };
}

/**
 * Toggle Direct Sale indicator on Owner Assignment Review Card for NB cases based on user role
 * @param {boolean} isDirectSale true if direct sale
 */
export function updateDirectSale(isDirectSale) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_DIRECT_SALE,
            payload: isDirectSale,
        });
    };
}

export function updateOARenewalsPage(page) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_OWNER_ASSIGNMENT_RENEWALS_PAGE',
        });

        dispatch({
            type: types.UPDATE_OWNER_ASSIGNMENT_RENEWALS_PAGE,
            payload: page,
        });
    };
}

export function updateRenewalsSalesRepData(page, salesRep) {
    return (dispatch,getState) => {
        let fullProps= getState()
        let platform=null
        if (page === 'review') {
            platform=identifyPlatform(fullProps.review.renewalData)
            dispatch({
                type: types.UPDATE_SALES_REP_RENEWALS_REVIEW,
                salesRep,
                platform
            });
        } else if (page === 'renewalShopping') {
            platform=identifyPlatform(fullProps.renewalShoppingHelper.renewalData)
            dispatch({
                type: types.UPDATE_SALES_REP_RENEWALS_SHOPPING,
                salesRep,
                platform
            });
        } else if (page === 'decide' || page === 'renewalFinalize') {
            platform=identifyPlatform(fullProps.decide.renewalData)
            dispatch({
                type: types.UPDATE_SALES_REP_RENEWALS_DECIDE,
                salesRep,
                platform
            });
        } else if (page === 'renewalEnrollment') {
            platform=identifyPlatform(fullProps.renewalEnrollment.renewalData)
            dispatch({
                type: types.UPDATE_SALES_REP_RENEWALS_ENROLLMENT,
                salesRep,
                platform
            });
        }
    };
}

export function updateLicenseCheck(licenseCheck) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_LICENSE_CHECK',
        });

        dispatch({
            type: types.UPDATE_LICENSE_CHECK,
            payload: licenseCheck,
        });
    };
}
