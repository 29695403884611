import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import { getTransactionResult } from './quickQuotesActions';
import { formatPlan } from './actionHelpers/mmrHelper';
import { doMedicalRateCall } from './renewalRateActions';
import { specialtyRatesBatch } from './renewalShoppingActions'
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { formatPlanOrRateCallError } from '../utils/formatters/errorFormat';
import { checkIsInternalUser } from './fullQuotesActions';
import { isMmr } from '../utils/businessLogic/platformUtils';
import deepCopy from '../utils/deepCopy';
import { isAutomateMmrUploadInProgress } from './renewalActions';
const apiRoutes = require('../utils/api/apiRouteConfig');
const LIFE_DEP_BASIC = 'LIFE_DEP_BASIC';
const DENTAL = 'DENTAL';
const VISION = 'VISION';
const LIFE = 'LIFE'; 
const STD = 'STD'; 
const LTD = 'LTD'; 
const SUL = 'SUL'; 
const SUPPLIFE = 'SUPPLIFE';
const SUPPDEPLIFE = 'SUPPDEPLIFE';


export let specialtyServices = [{
    typeName: DENTAL,
    apiKeyName: 'dental',
}, {
    typeName: VISION,
    apiKeyName: 'vision',
}, {
    typeName: LIFE,
    apiKeyName: 'life',
},
{
    typeName: STD,
    apiKeyName: 'shortTermDis',
}, {
    typeName: LTD,
    apiKeyName: 'longTermDis',
}, {
    typeName: SUL,
    apiKeyName: 'sul',
},
{
    typeName: SUPPLIFE,
    apiKeyName: 'suppLife',
},
{
    typeName: SUPPDEPLIFE,
    apiKeyName: 'suppDepLife',
},
];

//ind to store resp of apis
let receivedLifePreferrredPlans =  false
let receivedLifeAllSpecialityPlans = false
let receivedLifeHitAllPlanAPI = false
let receivedDentalPreferrredPlans =  false
let receivedDentalAllSpecialityPlans = false
let receivedDentalHitAllPlanAPI = false
let receivedVisionPreferrredPlans =  false
let receivedVisionAllSpecialityPlans = false
let receivedVisionHitAllPlanAPI = false

export const getMedicalPlansUhc = (setRenewingPlanFlag = false, medPlanCode = '', rxPlanCode = '', isAutomated = false,props={}) => (dispatch, getState) =>  {
    const fullProps = getState();
    const { zipCode, situsState, effectiveDate } = fullProps.review.renewalCaseData;
    const { data } = fullProps.review.renewalData.Ibr.group.clientDefinedData;
    const { totalEligible, totalEmployed, fullTimeEquivalent, clientDefinedData, industryCode } = fullProps.review.renewalData.Ibr.group.primaryLocation;
    const { primeCustomerNumber } = fullProps.review.val;
    let renewalMedPlanCode = medPlanCode;
    let renewalRxPlanCode = rxPlanCode;  
    
    let oi = null;
    let sa = null; 
    let bit = null;
    let franchiseCode = "0000000";
    for (let i = 0; i < data.length; i++) {
        if(data[i].key === "FRANCHISE_CODE"){
            franchiseCode = data[i].value;
            break;
        }
    }
    for (let i = 0; i < clientDefinedData.data.length; i++) {
        if(clientDefinedData.data[i].key === "OI"){
            oi = clientDefinedData.data[i].value;
        }
        if(clientDefinedData.data[i].key === "SA"){
            sa = clientDefinedData.data[i].value;
        }
        if(clientDefinedData.data[i].key === "BIT"){
            bit = clientDefinedData.data[i].value;
        }
    }
    let OISABit = [{
        OI : oi,
        SA : sa,
        BIT : bit   
    }]; 
    if(['MD','WV','VA','DE'].includes(situsState) && fullProps.overall.OISABit && fullProps.overall.OISABit.length > 0){
        OISABit = fullProps.overall.OISABit;
    }
    const plansRequestBody = {
        groupSize: totalEligible,
        effectiveDate,
        stateCode: situsState,
        zipCode,
        exchange: false,
        OISABit,
        platform: fullProps.overall.platform,
        ratingCount: fullTimeEquivalent.toString(),
        sicCode: industryCode.sicCode,
        franchiseCode,
        medicalPlanCode: renewalMedPlanCode,
        rxPlanCode: renewalRxPlanCode,
        customerNumber: primeCustomerNumber,
        source: fullProps.overall.source,
        validateQuoteData: {},
        isMMRSearchCall: true,
        isInternal: checkIsInternalUser(fullProps.renewals.userRolesPermissions)
    };
    dispatch(callGetPlansUhc(plansRequestBody, setRenewingPlanFlag, isAutomated,props));
}

const setRenewingPlans = (fullProps, planSearch, dispatch, product, groupSize, otherDetails,quoteStatus=null, isAutomated,props={}) => {
    const selectedProductPlansProperty = `selected${product[0].toUpperCase() + product.slice(1)}Plans`
    const selectedPlans = fullProps.review.mmr[selectedProductPlansProperty]
    const productSet = fullProps.review[['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(product) ?product==="SUPPLIFE"? "suppLife":product.toLowerCase() : product].set
    const finalMembers = {}
    const allMembers = []

    // merge common plans and find if need to do rate call or not based on if already rate call is done for that plan
    if(!quoteStatus){
        selectedPlans.forEach((productPlan, planIndex) => {
            if(!isMmr() || productPlan.planCode !== '' || !isAutomated || product !== "dependentLife"){
                allMembers.push(...fullProps.review[['LTD', 'STD', 'SUL', 'SUPPLIFE', 'SUPPDEPLIFE'].includes(product) ? product === "SUPPLIFE" ? "suppLife" : product.toLowerCase() : product].set[planIndex][0].member)
                if (!finalMembers.hasOwnProperty(productPlan.planCode)) {
                    finalMembers[productPlan.planCode] = {
                        members: JSON.parse(JSON.stringify(fullProps.review[['LTD', 'STD', 'SUL', 'SUPPLIFE', 'SUPPDEPLIFE'].includes(product) ? product === "SUPPLIFE" ? "suppLife" : product.toLowerCase() : product].set[planIndex][0].member)),
                        doRateCall: fullProps.review[['LTD', 'STD', 'SUL', 'SUPPLIFE', 'SUPPDEPLIFE'].includes(product) ? product === "SUPPLIFE" ? "suppLife" : product.toLowerCase() : product].set[planIndex].length === 1 || (fullProps.review.mmr.plansToBeRateCalled.length > 0 && fullProps.review.mmr.plansToBeRateCalled.find(code => code.split('/')[0] === productPlan.planCode)) || isAutomated,
                        ratingMethod: product === 'medical' ?
                            productSet[planIndex][0].isAgeBanded ? 'AGE BANDED FOR ACR' :
                                productSet[planIndex][0].compositeRates.length == 2 ? '2 TIER COMPOSITE ACR' : '4 TIER COMPOSITE ACR' : ''
                    }
                } else {
                    finalMembers[productPlan.planCode] = {
                        members: [
                            ...finalMembers[productPlan.planCode].members,
                            ...JSON.parse(JSON.stringify(fullProps.review[['LTD', 'STD', 'SUL', 'SUPPLIFE', 'SUPPDEPLIFE'].includes(product) ? product === "SUPPLIFE" ? "suppLife" : product.toLowerCase() : product].set[planIndex][0].member))
                        ],
                        doRateCall: finalMembers[productPlan.planCode].doRateCall || fullProps.review[product].set[planIndex].length === 1,
                        ratingMethod: product === 'medical' ?
                            productSet[planIndex][0].isAgeBanded ? 'AGE BANDED FOR ACR' :
                                productSet[planIndex][0].compositeRates?.length == 2 ? '2 TIER COMPOSITE ACR' : '4 TIER COMPOSITE ACR' : ''
                    }
                }
            }
        })
    } else {
        const selectedPlans = fullProps.review[product].selectedPlans
        for (let i = 0; i < planSearch.length; i++) {
            const temp = selectedPlans.filter(plan => plan.code === planSearch[i].code)
            if (temp.length > 0) {
                finalMembers[planSearch[i].code] = {
                    members: JSON.parse(JSON.stringify(temp[0].member)),
                    doRateCall: true
                }
            }
        }
    }

    // arrange plans in the correct order
    if(!quoteStatus){
        let productPlans = {}
        let formattedProductPlans = []
        Object.keys(finalMembers).forEach((planCode,index) => {
            if (finalMembers[planCode].doRateCall) {
                let productPlan = planSearch.find(plan => plan.code === planCode)
                if (productPlan) {
                    productPlan.member = finalMembers[productPlan.code].members
                    if((product.toLowerCase() === "life" || product.toLowerCase() === "std" || product.toLowerCase() === "ltd") && (productSet[index].length > 0  && productSet[index][0] && productSet[index][0]?.selectedClass !== undefined))
                        productPlan.selectedClass = productSet[index][0].selectedClass;
                    productPlans[productPlan.code] = formatPlan(productPlan, product)
                }
            }
        })

        selectedPlans.forEach(selectedPlan => {
            if(productPlans[selectedPlan.planCode]) {
                formattedProductPlans.push(productPlans[selectedPlan.planCode])
            } else {
                formattedProductPlans.push(null)
            }
        })
        dispatch({
            type: types.SET_RENEWING_PLANS_MMR,
            payload: formattedProductPlans,
            product: ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(product) ? product.toLowerCase() : product
        });
    }

    // do rate call
    Object.keys(finalMembers).forEach((planCode) => {
        if (finalMembers[planCode].doRateCall) {
            let productPlan = planSearch.find(plan => plan.code === planCode)
            if(productPlan) {
                if (product === 'medical') {
                    if(isMmr() && allMembers && allMembers.length === 0 && fullProps?.review?.members?.length > 0){
                        allMembers.push(deepCopy(fullProps.review.members[0]));
                        allMembers[0].coverages['medical'] = 'EE';
                    }
                    if(isMmr() && allMembers && allMembers.length === 0 && isAutomated){
                        fullProps.review['medical'].set.forEach(setPlan =>{
                            if(setPlan[0].member && setPlan[0].member.length > 0 &&  allMembers.length === 0){
                                allMembers.push(deepCopy(setPlan[0].member[0]));
                                allMembers[0].coverages['medical'] = 'EE';
                            }
                        })
                    }
                    dispatch(doMedicalRateCall({
                        medicalPlans: [productPlan],
                        members: allMembers,
                        ratingMethod: finalMembers[planCode].ratingMethod,
                        allMedicalPlans: planSearch,
                        isAutomated,
                        props
                    }));
                } else {
                    let ratesRequest = {
                        service: otherDetails.service[0],
                        zipCode: fullProps.review.renewalData.Ibr.group.primaryLocation.address.zip,
                        stateCode: fullProps.review.renewalData.Ibr.group.primaryLocation.address.state,
                        countyCode: '',
                        effectiveDate: fullProps.review.renewalCaseData.effectiveDate,
                        platform: fullProps.review.renewalCaseData.platform,
                        sicCode: fullProps.review.renewalData.Ibr.group.primaryLocation.industryCode.sicCode,
                        planCodes: [productPlan.code], // fullProps.review.mmr[selectedProductPlansProperty].map(plan => plan.planCode),
                        existingCoverage: ['dental', 'vision', 'life', 'std', 'ltd', 'sul'],
                        companyName: fullProps.review.renewalData.Ibr.group.employer.name
                    }
                    
                    if(ratesRequest.service.apiKeyName === 'life' )
                    {
                        const primeProductPlan=[productPlan].map((plan) => plan.primePlanCode)
                        const blPlanCodes=[productPlan].map((plan) => plan.code)
                        const combinedCodes=[productPlan].map((plan) => plan.code + "--" + plan.ADandDCode)
                        const childBenefitAmount=[productPlan].map((plan) => plan.childBenefitAmount != null ? plan.childBenefitAmount : "")
                        const spouseBenefitAmount=[productPlan].map((plan) => plan.spouseBenefitAmount != null ? plan.spouseBenefitAmount : "")
                        const productLineType=[productPlan].map((plan)=> plan.productType != null ? plan.productType : "")

                        ratesRequest.planCodes = primeProductPlan,
                        ratesRequest.blPlanCodes = blPlanCodes,
                        ratesRequest.combinedCodes=combinedCodes,
                        ratesRequest.childBenefitAmount=childBenefitAmount,
                        ratesRequest.spouseBenefitAmount=spouseBenefitAmount,
                        ratesRequest.productLineType = productLineType

                    }
        
                    if(ratesRequest.service.apiKeyName==='suppLife')
                    {
                        ratesRequest.planCodes=[productPlan].map((plan)=>{
                            return{
                                code:plan.code,
                                slEEPlanCode: plan.code,
                                slEEADnDPlanCode: plan.PIASLEEADDPlanCode,
                                slSPPlanCode:plan.PIASLSpPlanCode,
                                slSPADnDPlanCode:plan.PIASLSpADDPlanCode,
                                slCHPlanCode:plan.PIASLChPlanCode,
                                slCHADnDPlanCode:plan.PIASLChADDPlanCode
                            }
                        })
                    }
                    if(isMmr() && finalMembers[productPlan.code].members?.length === 0 && allMembers && allMembers.length === 0 && fullProps?.review?.members?.length > 0){
                        allMembers.push({...fullProps.review.members[0]});
                        allMembers[0].coverages[ratesRequest.service.apiKeyName] = 'EE';
                    }
                    let members = isMmr() && finalMembers[productPlan.code].members?.length === 0 ? allMembers : finalMembers[productPlan.code].members
                    dispatch(specialtyRatesBatch(ratesRequest,
                        false, 0, groupSize, {}, otherDetails,
                        {}, {}, {}, members, false, quoteStatus))
                }
            }
        }
    })
}

export const setMMRVisionPlan = (formattedProductPlans)=>{
    return (dispatch) => {
        dispatch({

            type: types.SET_RENEWING_PLANS_MMR,
            payload: formattedProductPlans,
            product: 'vision'
        });
    }
}
export const setMMRDentalPlan = (formattedProductPlans)=>{
    return (dispatch) => {
        dispatch({

            type: types.SET_RENEWING_PLANS_MMR,
            payload: formattedProductPlans,
            product: 'dental'
        });
    }
}

export const setMMRLifePlan = (formattedProductPlans)=>{
    return (dispatch) => {
        dispatch({

            type: types.SET_RENEWING_PLANS_MMR,
            payload: formattedProductPlans,
            product: 'life'
        });
    }
}

export const setMMRDepLifePlan = (formattedProductPlans)=>{
    return (dispatch) => {
        dispatch({

            type: types.SET_RENEWING_PLANS_MMR,
            payload: formattedProductPlans,
            product: 'dependentLife'
        });
    }
}

export const callGetPlansUhc = (plansRequestBody, setRenewingPlanFlag, isAutomated = false,props={}) => { 
    return (dispatch, getState) => {
        const fullProps = getState();
        const { totalEmployed, industryCode } = fullProps.review.renewalData.Ibr.group.primaryLocation;
        const { zipCode, effectiveDate, situsState } = fullProps.review.renewalCaseData;
        const includeSpecialty = false;
        const selectedProducts = ["medical"];
        const viewSpecialty = {
            dental: false,
            life: false,
            ltd: false,
            medical: true,
            std: false,
            sul: false,
            vision: false
        }
        const otherDetails = {
            includeSpecialty: includeSpecialty,
            selectedProducts: selectedProducts,
            viewSpecialty: viewSpecialty,
            sicCode: industryCode.sicCode
        };
        const renewalShoppingRequest = {
            groupSize: totalEmployed,
            zipObj: zipCode,
            effectiveDate: effectiveDate,
            otherDetails: otherDetails,

        };
        const setErrorModal = (props, errorTracker) => {
            props.setUploadErrorTracker(errorTracker);
            props.setUploadErrorModal(true);
            return;
        }
        dispatch({
            type: types.SET_MMR_IS_FETCHING_MEDICAL,
            payload: true,
        });
        const prevState = fullProps.quotes;
                dispatch({
                    type: types.SERVICE.FI.DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE,
                    payload: '',
                    prevState,
                });
        axios.post(`${apiRoutes.quickQuotes}/plans/startSearch`, plansRequestBody)
            .then(async (res) => {
                const planSearch = await getTransactionResult(res.data.transactionId);
                if (planSearch.statusCode === 400) {
                    throw await planSearch;
                }
                if(situsState==="CA" && effectiveDate ){
                    let coverageDate = new Date(effectiveDate).getUTCDate();
                    if(coverageDate === 15){
                        planSearch.medicalPlans = planSearch.medicalPlans.filter(pln => pln.platform !== 'NICE');
                    }
                }
                if(fullProps.review.mmr && fullProps.review.mmr.installOnlyPlansDetails && fullProps.review.mmr.installOnlyPlansDetails.length > 0 && planSearch.medicalPlans.length > 0 ) {
                    planSearch.medicalPlans = [...planSearch.medicalPlans, ...fullProps.review.mmr.installOnlyPlansDetails];
                }
                if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                    setRenewingPlans(fullProps, planSearch.medicalPlans, dispatch, 'medical',null,null,null, isAutomated,props)
                } else { // function is called to get plan codes for MMR
                    const medicalPlans = planSearch.medicalPlans.map(plan => ({code: plan.code,packageId: plan.packages, rxPlanCode : plan.rxPlanCode}));
                    await dispatch({
                        type: types.SET_MMR_MEDICAL_PLANS,
                        payload: medicalPlans,
                    });
                }
                if(plansRequestBody.medicalPlanCode && plansRequestBody.medicalPlanCode.length > 0){
                    let planIndex = fullProps.review?.mmr?.installOnlyPlans?.planIndex ? fullProps.review.mmr.installOnlyPlans.planIndex : 0
                    const requiredMedicalPlans = planSearch.medicalPlans.filter(plan => plan.code === plansRequestBody.medicalPlanCode);
                    await dispatch({
                        type: types.SET_MMR_INSTALL_ONLY_PLANS_DETAILS,
                        payload: requiredMedicalPlans,
                    });
                    if(requiredMedicalPlans && requiredMedicalPlans.length > 0 && !isAutomated){
                        let packageId = requiredMedicalPlans[0].packages && requiredMedicalPlans[0].packages.length > 0 ? requiredMedicalPlans[0].packages[0] : '';
                        dispatch(updateSelectedPlans({planCode: requiredMedicalPlans[0].code, packageId: packageId}, 'medical', planIndex))
                    }
                }
                await dispatch({
                    type: types.SET_MMR_IS_FETCHING_MEDICAL,
                    payload: false,
                });
            })
            .catch((err) => {
                if(isAutomated){
                    dispatch(isAutomateMmrUploadInProgress(false));
                    if(props && props.setUploadErrorTracker && props.setUploadErrorModal)
                    {
                        let errorsList = [];
                        errorsList.push({
                            heading:"Plan Call Getting failed",
                            errors:[{error:err?.message || "Please check the plans in uploaded excel"}]
                        })
                        let errorTracker = {}
                        errorTracker.validationError = true;
                        errorTracker.errorList = errorsList;
                        setErrorModal(props, errorTracker);
                    }
                }
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE,
                });
                logError(KEY_NAME, UHC_REN_APIS.MEDICAL_PLAN_CHECK, 'mmrActions.callGetPlansUhc', plansRequestBody, err);
                dispatch({
                    type: types.SET_MMR_IS_FETCHING_MEDICAL,
                    payload: false,
                });
                dispatch({
                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', false),
                });
                const prevState = fullProps.quotes;
                dispatch({
                    type: types.SERVICE.FI.DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE,
                    payload: err.error,
                    prevState,
                });
            });
    }

}

export const getSpecialtyPlansUhc = (productServices, setRenewingPlanFlag = false, isAutomated = false) => (dispatch, getState) => {
    const fullProps = getState();
    let selectedProducts = [];
    if (fullProps.review && (fullProps.review.dental && fullProps.review.dental.set && fullProps.review.dental.set.length !== 0)) {
        selectedProducts.push('dental');
    }
    if (fullProps.review && (fullProps.review.vision && fullProps.review.vision.set && fullProps.review.vision.set.length !== 0)) {
        selectedProducts.push('vision');
    }
    if (fullProps.review && (fullProps.review.life && fullProps.review.life.set && fullProps.review.life.set.length !== 0)) {
        selectedProducts.push('life');
    }
    if (fullProps.review && (fullProps.review.std && fullProps.review.std.set && fullProps.review.std.set.length !== 0)) {
        selectedProducts.push('std');
    }
    if (fullProps.review && (fullProps.review.ltd && fullProps.review.ltd.set && fullProps.review.ltd.set.length !== 0)) {
        selectedProducts.push('ltd');
    }
    if (fullProps.review && (fullProps.review.suppLife && fullProps.review.suppLife.set && fullProps.review.suppLife.set.length !== 0)) {
        selectedProducts.push('supplife');
    }

    let otherDetails = {
        service: productServices,
        platform: 'PRIME',
        selectedProducts,
        sicCode: fullProps.review.renewalData.Ibr.group.primaryLocation.industryCode.sicCode
    }
    let zipObj = {
        zipCode: fullProps.review.renewalCaseData.zipCode,
        stateCode: fullProps.review.renewalCaseData.situsState
    }
    dispatch(getAllSpecialtyPlans(fullProps.review.renewalData.Ibr.group.primaryLocation.totalEligible,
        zipObj,
        fullProps.review.renewalCaseData.effectiveDate,
        otherDetails, '', productServices, setRenewingPlanFlag, isAutomated));

}

export function getAllSpecialtyPlans(groupSize, zipObj, effectiveDate, otherDetails, flow = '', productServices = null, setRenewingPlanFlag = false, isAutomated) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const productArrays = {}                //obj to store planCodes
        let currentLifePlanCode = ''
        let currentAdAndDCode = ''
        let currentDentalPlanCode = ''
        let currentVisionPlanCode = ''
        
        if (fullProps.review && (fullProps.review.life && fullProps.review.life.set && fullProps.review.life.set.length !== 0)) {
            fullProps.review.life.set.forEach((setPlan) => {
                currentLifePlanCode = setPlan[0].code;
                currentAdAndDCode = setPlan[0].ADandDCode
            });          
        }

        if(fullProps.review && (fullProps.review.dental && fullProps.review.dental.set && fullProps.review.dental.set.length !== 0)){
            currentDentalPlanCode = fullProps.review.dental.set[0][0].code
        }

        if(fullProps.review && (fullProps.review.vision && fullProps.review.vision.set && fullProps.review.vision.set.length !== 0)){
            currentVisionPlanCode = fullProps.review.vision.set[0][0].code
            // currentVisionPlanCode = 'V0010'
        }

        if (!productServices) {
            productServices = specialtyServices
        }
        productServices.forEach((service) => {
            if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                dispatch({
                    type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                    payload: true,
                    fullProps,
                });
            }
        })
        dispatch(getPrefferedPlans(groupSize, zipObj, effectiveDate, otherDetails, flow, productServices, setRenewingPlanFlag, isAutomated))
            .then(resp => {
                productServices.forEach((service) => {
                    if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                        const specialtyType = ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(service.typeName) ? [service.typeName] : [service.apiKeyName];
                        if(service.apiKeyName==="supplife"){
                            service.apiKeyName="suppLife"
                        }

                        if(!productArrays[`${service.apiKeyName}PlanCodes`])       
                        {
                            productArrays[`${service.apiKeyName}PlanCodes`]=[]
                        }    
                        productArrays[`${service.apiKeyName}PlanCodes`].push(resp.data[`${service.apiKeyName}Plans`].plans.map(plan=>plan.code))    
                        // Get dependent life plans when life is selected
                        if (service.apiKeyName.toUpperCase() === LIFE) {
                            const quickQuoteRequest = {
                                groupSize,
                                zipObj,
                                effectiveDate,
                                otherDetails,
                            };
                            dispatch(requestDependentLifePlans(quickQuoteRequest, fullProps, setRenewingPlanFlag, isAutomated))
                        }
                        // productServices[3].apiKeyName= 'STD'; 
                        // productServices[4].apiKeyName= 'LTD';
                        const isMMR= isMmr()
                        axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}`
                            + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}&isMmr=${isMMR}&page=1&busType=RL`)
                            .then(async (res) => {
                                if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                                    if(service.apiKeyName==="supplife"){
                                        service.apiKeyName="suppLife"
                                    }
                                    setRenewingPlans(fullProps, res.data[`${service.apiKeyName}Plans`].plans, dispatch, ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(service.typeName) ? service.typeName : service.apiKeyName, groupSize, otherDetails, null,isAutomated)
                                } else { // function is called to get plan codes for MMR
                                    // productServices[3].apiKeyName= 'shortTermDis';
                                    // productServices[4].apiKeyName= 'longTermDis';

                                    let planCodes = res.data[`${service.apiKeyName}Plans`].plans.map((plan) => plan.code)

                                    
                                    productArrays[`${service.apiKeyName}PlanCodes`].push(res.data[`${service.apiKeyName}Plans`].plans.map(plan=>plan.code)) //pushing all specialityPlans
                    
                                    if(service.apiKeyName === 'life')
                                    {
                                        receivedLifeAllSpecialityPlans = true
                                    }
                                    if(service.apiKeyName === 'dental')
                                    {
                                        receivedDentalAllSpecialityPlans = true
                                    }
                                    if(service.apiKeyName === 'vision')
                                    {
                                        receivedVisionAllSpecialityPlans = true
                                    }
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_MMR_PLANS,
                                        payload: planCodes,
                                        fullProps,
                                    });

                                }

                                const { totalPageCount } = res.data[`${service.apiKeyName}Plans`];
                                for(const key in productArrays)
                                {
                                    if(productArrays.hasOwnProperty(key))
                                    {
                                        productArrays[key]=productArrays[key].flat()
                                    }
                                }
                                if (totalPageCount && totalPageCount == 1) {
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                        payload: false,
                                        fullProps,
                                    });
                                }
                                else {
                                    let promises = []
                                    // productServices[3].apiKeyName= 'STD';
                                    // productServices[4].apiKeyName= 'LTD';

                                    for (let i = 2; i <= totalPageCount; i++) {
                                        promises.push(dispatch(hitAllPlanAPI(groupSize, zipObj, effectiveDate, otherDetails, service, i, totalPageCount, flow, setRenewingPlanFlag)));
                                    }
                                    Promise.all(promises).
                                        then(responses => {

                                            productArrays[`${service.apiKeyName}PlanCodes`].push(responses[0].data[`${service.apiKeyName}Plans`].plans.map(plan=>plan.code)) //pushing all specialityPlans
                                            // //repeating because it will be a sometime scenario
                                            for(const key in productArrays)
                                            {
                                                if(productArrays.hasOwnProperty(key))
                                                {
                                                    productArrays[key]=productArrays[key].flat()
                                                }
                                            }
                                            
                                            if(service.apiKeyName === 'life')
                                            {
                                                receivedLifeHitAllPlanAPI = true
                                            }
                                            if(service.apiKeyName === 'dental')
                                            {
                                                receivedDentalHitAllPlanAPI = true
                                            }
                                            if(service.apiKeyName === 'vision')
                                            {
                                                receivedVisionHitAllPlanAPI = true
                                            }
                                            if(service.apiKeyName === "life"  && productArrays.hasOwnProperty(`${service.apiKeyName}PlanCodes`) &&currentLifePlanCode !== '' && receivedLifePreferrredPlans && receivedLifeAllSpecialityPlans && receivedLifeHitAllPlanAPI )
                                            {
                                                if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentLifePlanCode))
                                                {
                                                    //no change required on dropdown list
                                                    console.log("In Life Renewing Plan dropDown, current plan is already present i.e ",currentLifePlanCode)
                                                }
                                                else
                                                {
                                                    dispatch({
                                                        type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                        payload: true,
                                                        fullProps,
                                                    });
                                                    dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode))
                                                    // dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,'BL0013','ADD10040'))
                                                    .then(res=>{
                                                        dispatch({
                                                            type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                            payload: false,
                                                            fullProps,
                                                        });
                                                    })
                                                    .catch(err=>{
                                                        console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunction")
                                                    })
                                                }
                                            }
                                            if(service.apiKeyName === "dental"  && productArrays.hasOwnProperty(`${service.apiKeyName}PlanCodes`) &&currentDentalPlanCode !== '' && receivedDentalPreferrredPlans && receivedDentalAllSpecialityPlans && receivedDentalHitAllPlanAPI )
                                                {
                                                    if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentDentalPlanCode))
                                                    {
                                                        //no change required on dropdown list
                                                        console.log("In Dental Renewing Plan dropDown, current plan is already present i.e ",currentDentalPlanCode)
                                                    }
                                                    else
                                                    {
                                                        dispatch({
                                                            type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                            payload: true,
                                                            fullProps,
                                                        });
                                                        dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode,currentDentalPlanCode))
                                                        .then(res=>{
                                                            dispatch({
                                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                                payload: false,
                                                                fullProps,
                                                            });
                                                        })
                                                        .catch(err=>{
                                                            console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunctionForDental")
                                                        })
                                                    }
                                                }
                                            if(service.apiKeyName === "vision"  && productArrays.hasOwnProperty(`${service.apiKeyName}PlanCodes`) &&currentVisionPlanCode !== '' && receivedVisionPreferrredPlans && receivedVisionAllSpecialityPlans && receivedVisionHitAllPlanAPI )
                                                {
                                                    if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentVisionPlanCode))
                                                    {
                                                        //no change required on dropdown list
                                                        console.log("In Vision Renewing Plan dropDown, current plan is already present i.e ",currentVisionPlanCode)
                                                    }
                                                    else
                                                    {
                                                        dispatch({
                                                            type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                            payload: true,
                                                            fullProps,
                                                        });
                                                        dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode,currentDentalPlanCode,currentVisionPlanCode))
                                                        .then(res=>{
                                                            dispatch({
                                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                                payload: false,
                                                                fullProps,
                                                            });
                                                        })
                                                        .catch(err=>{
                                                            console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunctionForVision")
                                                        })
                                                    }
                                                }
                                            dispatch({
                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                payload: false,
                                                fullProps,
                                            });

                                        })
                                }
                               
                                if((service.apiKeyName === "life" && currentLifePlanCode !== '' && receivedLifePreferrredPlans && receivedLifeAllSpecialityPlans && totalPageCount == 1))
                                {
                                    if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentLifePlanCode))   
                                    {
                                        //no change required on dropdown list
                                        console.log("In Life Renewing Plan dropDown, current plan is already present in dropdown i.e ",currentLifePlanCode)
                                    }
                                    else
                                    {
                                        //need to call DB and fetch the plan on planCode
                                        dispatch({
                                            type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                            payload: true,
                                            fullProps,
                                        });
                                        dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode))
                                        // dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,'BL0013','ADD10040'))
                                        .then(res=>{
                                            dispatch({
                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                payload: false,
                                                fullProps,
                                            });
                                        })
                                        .catch(err=>{
                                            console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunction")
                                        })
                                    }
                                }

                                if((service.apiKeyName === "dental" && currentDentalPlanCode !== '' && receivedDentalPreferrredPlans && receivedDentalAllSpecialityPlans && totalPageCount == 1))
                                    {
                                        if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentDentalPlanCode))   
                                        {
                                            //no change required on dropdown list
                                            console.log("In Dental Renewing Plan dropDown, current plan is already present in dropdown i.e ",currentDentalPlanCode)
                                        }
                                        else
                                        {
                                            //need to call DB and fetch the plan on planCode
                                            dispatch({
                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                payload: true,
                                                fullProps,
                                            });
                                            dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode,currentDentalPlanCode))
                                            .then(res=>{
                                                dispatch({
                                                    type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                    payload: false,
                                                    fullProps,
                                                });
                                            })
                                            .catch(err=>{
                                                console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunctionForDental")
                                            })
                                        }
                                    }

                                if((service.apiKeyName === "vision" && currentVisionPlanCode !== '' && receivedVisionPreferrredPlans && receivedVisionAllSpecialityPlans && totalPageCount == 1))
                                    {
                                        if(productArrays[`${service.apiKeyName}PlanCodes`].includes(currentVisionPlanCode))   
                                        {
                                            //no change required on dropdown list
                                            console.log("In Vision Renewing Plan dropDown, current plan is already present in dropdown i.e ",currentVisionPlanCode)
                                        }
                                        else
                                        {
                                            //need to call DB and fetch the plan on planCode
                                            dispatch({
                                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                payload: true,
                                                fullProps,
                                            });
                                            dispatch(requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate,otherDetails,flow,productServices,setRenewingPlanFlag,currentLifePlanCode,currentAdAndDCode,currentDentalPlanCode,currentVisionPlanCode))
                                            .then(res=>{
                                                dispatch({
                                                    type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                                    payload: false,
                                                    fullProps,
                                                });
                                            })
                                            .catch(err=>{
                                                console.log("Error in mmrActions.requestPlanDependingOnPlanCodeFunctionForVision")
                                            })
                                        }
                                    }

                            }).catch((err) => {
                                console.log(err);
                                dispatch({
                                    type: types.DISPLAY_ERROR_MESSAGE,
                                });
                                const reqBody = {
                                    groupSize,
                                    zipCode: zipObj.zipCode,
                                    effectiveDate,
                                    stateCode: zipObj.stateCode,
                                    sicCode: otherDetails.sicCode ? otherDetails.sicCode : '',
                                    preferredPlans: false,
                                    specialtyTypes: JSON.stringify(specialtyType),
                                    platform: otherDetails.platform, 
                                    page: 1,
                                    busType: 'RL'
                                }
                                logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'mmrActions.getAllSpecialtyPlans', reqBody, err);
                                dispatch({
                                    type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                    payload: false,
                                    fullProps,
                                });
                                dispatch({
                                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                                    payload: formatPlanOrRateCallError(err, 'plan', true)
                                });

                            });
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                dispatch({
                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', true),
                });
            });
    };
}
export const doMmrReviewRateCall = () => {
    return (dispatch, getState) => {
        const fullProps = getState()

        let productServices = [{
            typeName: DENTAL,
            apiKeyName: 'dental',
        }, {
            typeName: VISION,
            apiKeyName: 'vision',
        }];

        productServices.map(product => {
            const selectedProductPlansProperty = `selected${product.apiKeyName[0].toUpperCase() + product.apiKeyName.slice(1)}Plans`
            const selectedPlans = fullProps.review.mmr[selectedProductPlansProperty]
            let temp = []
            for (let selectedPlan of selectedPlans) {
                temp.push({
                    code: selectedPlan.planCode
                })
            }
            const groupSize = fullProps.review.renewalData.Ibr.group.primaryLocation.totalEligible
            let otherDetails = {
                service: [product],
                platform: 'PRIME',
                //selectedProducts,
                sicCode: fullProps.review.renewalData.Ibr.group.primaryLocation.industryCode.sicCode
            }
            setRenewingPlans(fullProps, temp, dispatch, product.apiKeyName, groupSize, otherDetails, 'mmrReview')
        })
    }
}
function getPrefferedPlans(groupSize, zipObj, effectiveDate, otherDetails, flow = '', productServices, setRenewingPlanFlag = false, isAutomated) {
    return (dispatch, getState) => {
        const fullProps = getState();
        if (!productServices) {
            productServices = specialtyServices
        }
        const isMMR= isMmr()

        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${apiRoutes.quickQuotes}/specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}&isMmr=${isMMR}`
                    + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== ''
                        ? `&sicCode=${otherDetails.sicCode}`
                        : ''
                    }&preferredPlans=true&specialtyTypes=${JSON.stringify(
                        otherDetails.selectedProducts,
                    )}&platform=${otherDetails.platform}`,
                )
                .then(async (res) => {
                    // productServices[3].apiKeyName= 'shortTermDis';
                    // productServices[4].apiKeyName= 'longTermDis';

                    productServices.forEach((service) => {
                        if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                            if (res.data[`${service.apiKeyName}Plans`] != null) {
                                if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                                    if(service.apiKeyName==="supplife"){
                                        service.apiKeyName="suppLife"
                                    }
                                    setRenewingPlans(fullProps, res.data[`${service.apiKeyName}Plans`].plans, dispatch, ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(service.typeName) ? service.typeName : service.apiKeyName, groupSize, otherDetails, null, isAutomated)
                                } else { // function is called to get plan codes for MMR
                                    let planCodes = res.data[`${service.apiKeyName}Plans`].plans.map((plan) => plan.code)
                                    if(service.apiKeyName === 'life')
                                    {
                                        receivedLifePreferrredPlans = true
                                    }
                                    if(service.apiKeyName === 'dental')
                                    {
                                        receivedDentalPreferrredPlans = true
                                    }
                                    if(service.apiKeyName === 'vision')
                                    {
                                        receivedVisionPreferrredPlans = true
                                    }
                                    dispatch({
                                        type: types.SERVICE[service.typeName].SET_MMR_PLANS,
                                        payload: planCodes,
                                        fullProps,
                                    });
                                
                                }
                            }
                        }
                    })
                    resolve(res);
                })
                .catch((err) => {
                    dispatch({
                        type: types.DISPLAY_ERROR_MESSAGE
                    });
                    const reqBody = {
                        groupSize,
                        zipCode: zipObj.zipCode,
                        effectiveDate,
                        stateCode: zipObj.stateCode,
                        sicCode: otherDetails.sicCode ? otherDetails.sicCode : ''
                    }
                    logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'mmrActions.getPrefferedPlans', reqBody, err);
                    productServices.forEach((service) => {
                        if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                            dispatch({
                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                payload: false,
                                fullProps,
                            });
                        }
                    })
                    dispatch({
                        type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', true),
                    });
                });
        })
    }
}
function hitAllPlanAPI(groupSize, zipObj, effectiveDate, otherDetails, service, page, totalPageCount, flow = '', setRenewingPlanFlag = false) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const specialtyType = ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(service.typeName) ? [service.typeName] : [service.apiKeyName];
        const isMMR= isMmr()
        return new Promise((resolve, reject) => {

            axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}&isMmr=${isMMR}`
                + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== '' ? `&sicCode=${otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify(specialtyType)}&platform=${otherDetails.platform}&page=${page}&busType=RL`)
                .then((res) => {
                    if (res.data[`${service.apiKeyName}Plans`] != null) {
                        if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                            setRenewingPlans(fullProps, res.data[`${service.apiKeyName}Plans`].plans, dispatch, ['LTD','STD','SUL','SUPPLIFE','SUPPDEPLIFE'].includes(service.typeName) ? service.typeName : service.apiKeyName, groupSize, otherDetails)
                        } else { // function is called to get plan codes for MMR
                            let planCodes = res.data[`${service.apiKeyName}Plans`].plans.map((plan) => plan.code)
                            dispatch({
                                type: types.SERVICE[service.typeName].SET_MMR_PLANS,
                                payload: planCodes,
                                fullProps,
                            });
                        }

                    }
                    resolve(res);

                }).catch((err) => {
                    dispatch({
                        type: types.DISPLAY_ERROR_MESSAGE
                    });
                    const reqBody = {
                        groupSize,
                        zipCode: zipObj.zipCode,
                        effectiveDate,
                        stateCode: zipObj.stateCode,
                        sicCode: otherDetails.sicCode ? otherDetails.sicCode : '',
                        preferredPlans: false,
                        specialtyTypes: JSON.stringify(specialtyType),
                        platform: otherDetails.platform, 
                        page,
                        busType: 'RL'
                    }
                    logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'mmrActions.hitAllPlanAPI', reqBody, err);
                    dispatch({
                        type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                        payload: false,
                        fullProps,
                    });
                    dispatch({
                        type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                        payload: formatPlanOrRateCallError(err, 'plan', true),
                    });
                });
        })
    };
}

function requestDependentLifePlans(quickQuoteRequest, fullProps, setRenewingPlanFlag, isAutomated) {
    return (dispatch) => {
        dispatch({
            type: types.SERVICE.LIFE_DEP_BASIC.SET_MMR_IS_FETCHING,
            payload: true,
        });
        const isMMR= isMmr()
        axios.get(`${apiRoutes.quickQuotes}/all-specialty-plans?groupSize=${quickQuoteRequest.groupSize}&zipCode=${quickQuoteRequest.zipObj.zipCode}&effectiveDate=${quickQuoteRequest.effectiveDate}&isMmr=${isMMR}`
            + `&stateCode=${quickQuoteRequest.zipObj.stateCode}${quickQuoteRequest.otherDetails.sicCode !== '' ? `&sicCode=${quickQuoteRequest.otherDetails.sicCode}` : ''}&preferredPlans=false&specialtyTypes=${JSON.stringify([LIFE_DEP_BASIC])}&platform=${quickQuoteRequest.otherDetails.platform}&page=1`)
            .then((res) => {
                if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                    setRenewingPlans(fullProps, res.data.depLifePlans.plans, dispatch, 'dependentLife', quickQuoteRequest.groupSize, quickQuoteRequest.otherDetails, null, isAutomated)
                } else { // function is called to get plan codes for MMR
                    let planCodes = res.data.depLifePlans.plans.map((plan) => plan.code)
                    dispatch({
                        type: types.SERVICE.LIFE_DEP_BASIC.SET_MMR_PLANS,
                        payload: planCodes,
                        fullProps,
                    });
                }
                dispatch({
                    type: types.SERVICE.LIFE_DEP_BASIC.SET_MMR_IS_FETCHING,
                    payload: false,
                });
                
            }).catch((err) => {
                dispatch({
                    type: types.DISPLAY_ERROR_MESSAGE
                });
                const reqBody = {
                    groupSize: quickQuoteRequest.groupSize,
                    zipCode: quickQuoteRequest.zipObj.zipCode,
                    effectiveDate: quickQuoteRequest.effectiveDate,
                    stateCode: quickQuoteRequest.zipObj.stateCode,
                    sicCode: quickQuoteRequest.otherDetails.sicCode ? quickQuoteRequest.otherDetails.sicCode : '',
                    preferredPlans: false,
                    specialtyTypes: JSON.stringify([LIFE_DEP_BASIC]),
                    platform: quickQuoteRequest.otherDetails.platform, 
                    page: 1,
                    busType: 'RL'
                }
                logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'mmrActions.requestDependentLifePlans', reqBody, err);
                dispatch({
                    type: types.SERVICE.LIFE_DEP_BASIC.SET_MMR_IS_FETCHING,
                    payload: false,
                });
                dispatch({
                    type: types.SERVICE.RS.ERR_REN_ADDITIONAL_PLANS,
                    payload: formatPlanOrRateCallError(err, 'plan', true),
                });
            });
    }
}
function requestPlanDependingOnPlanCode(groupSize,zipObj,effectiveDate = '',otherDetails,flow = '',productServices,setRenewingPlanFlag = false,planCode,currentAdAndDCode = '',dentalPlanCode = '',visionPlanCode =''){
    return (dispatch,getState) => {
        const fullProps = getState()
        if (!productServices) {
            productServices = specialtyServices
        }
        const isMMR= isMmr()
        return new Promise((resolve,reject)=>{
            axios
                .get(
                `${apiRoutes.quickQuotes}/specialty-plans-planCodeSpecific?groupSize=${groupSize}&zipCode=${zipObj.zipCode}&effectiveDate=${effectiveDate}&isMmr=${isMMR}`
                + `&stateCode=${zipObj.stateCode}${otherDetails.sicCode !== ''
                    ? `&sicCode=${otherDetails.sicCode}`
                    : ''
                }&preferredPlans=false&planCode=${planCode}`+`&dentalPlanCode=${dentalPlanCode !== '' ? dentalPlanCode : ''}`+`&productType=${(dentalPlanCode !== '' || visionPlanCode!=='') ? 'DV' : ''}`+`&visionPlanCode=${visionPlanCode !== '' ? visionPlanCode : ''}`+`&currentAdAndDCode=${currentAdAndDCode !== '' ? currentAdAndDCode : ''}&specialtyTypes=${JSON.stringify(
                    otherDetails.selectedProducts,
                )}&platform=${otherDetails.platform}&busType=RL`,
                )
               .then(async(res)=>{
                productServices.forEach((service) => {
                    if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                        if (res.data[`${service.apiKeyName}Plans`] != null) {
                            if (setRenewingPlanFlag) { // function is called on plan selection from MMR
                                setRenewingPlans(fullProps, res.data[`${service.apiKeyName}Plans`].plans, dispatch, service.apiKeyName, groupSize, otherDetails)
                            } else { // function is called to get plan codes for MMR
                                let planCodes = res.data[`${service.apiKeyName}Plans`].plans.map((plan) => plan.code)
                                dispatch({
                                    type: types.SERVICE[service.typeName].SET_MMR_PLANS,
                                    payload: planCodes,
                                    fullProps,
                                });
                            }
                        }
                    }
                })
                resolve(res);
                 console.log("Got response")
                })
               .catch((err)=>{
                console.log("Some error occured in requestPlanDependingOnPlanCode")
                const reqBody = {
                    productServices : otherDetails.selectedProducts,
                    planCode : planCode
                }
                logError(KEY_NAME, UHC_REN_APIS.SPECIALTY_PLAN, 'mmrActions.requestPlanDependingOnPlanCode', reqBody, err);
                    otherDetails.productServices.forEach((service) => {
                        if (otherDetails.selectedProducts.includes(service.typeName.toLowerCase())) {
                            dispatch({
                                type: types.SERVICE[service.typeName].SET_MMR_IS_FETCHING,
                                payload: false,
                                fullProps,
                            });
                        }
                    })
               })
            
        })
    }
}
export const addRenewingPlanMmr = (product, isAutomated = false) => (dispatch, getState) => {
    switch (product) {
        case 'Medical':
            const fullProps = getState();
            // let medicalPlanCode = fullProps?.review?.mmr?.installOnlyPlans?.medicalPlanCode ? fullProps.review.mmr.installOnlyPlans.medicalPlanCode : '';
            // let rxPlanCode = fullProps?.review?.mmr?.installOnlyPlans?.rxPlanCode ? fullProps.review.mmr.installOnlyPlans.rxPlanCode : '';;
            dispatch(getMedicalPlansUhc(true))
            break
        case 'Dental':
            dispatch(getSpecialtyPlansUhc([{
                typeName: DENTAL,
                apiKeyName: 'dental',
            }], true, isAutomated))
            break
        case 'Vision':
            dispatch(getSpecialtyPlansUhc([{
                typeName: VISION,
                apiKeyName: 'vision',
            }], true, isAutomated))
            break
        case 'Basic Life':
            dispatch(getSpecialtyPlansUhc([{
                typeName: LIFE,
                apiKeyName: 'life',
            }], true, isAutomated))
            break
        case 'Std':
        case 'STD':
            dispatch(getSpecialtyPlansUhc([{
                typeName: STD,
                apiKeyName: 'shortTermDis',
            }], true))
            break
        case 'Ltd':
        case 'LTD':
            dispatch(getSpecialtyPlansUhc([{
                typeName: LTD,
                apiKeyName: 'longTermDis',
            }], true))
            break
        case 'Sul':
            dispatch(getSpecialtyPlansUhc([{
                typeName: SUL,
                apiKeyName: 'sul',
            }], true))
            break
            case 'Supp Life':
            dispatch(getSpecialtyPlansUhc([{
                typeName: SUPPLIFE,
                apiKeyName: 'supplife',
            }], true))
            break
        default:
            break
    }
}

export const updateSelectedPlans = (selectedPlan, product, planIndex) => (dispatch) => {
    dispatch({
        type: types.UPDATE_SELECTED_PLAN_MMR,
        payload: selectedPlan,
        product,
        planIndex
    })
}
export const updateSuppLifeDepIndicator =(selectedPlans)=>(dispatch)=>{
    dispatch({
        type: types.UPDATE_SUPPLIFEDEP_INDICATOR_MMR,
        payload: selectedPlans,
        
       
    })
}

export const removeRenewingPlanMMR = (planIndexes, planCode, product) => (dispatch) => {
    dispatch({
        type: types.REMOVE_RENEWING_PLANS_MMR,
        planIndexes,
        planCode,
        product
    })
}
export function updateMMRSolarisStatus(status) {
    return (dispatch) => {
        dispatch({
            type: types.SOLARIS.UPDATE_MMR_STATUS,
            val: status
        })
    }
}