import * as types from '../../../actions/actionTypes';

const initialState = {
    cases : [],
    ownerAssignmentUpdatedFromDESFlow : false
};


export default function newBusinessOnQuoteTrackingNumber(state = initialState , action) {

    switch (action.type) {
        case types.REQUEST_NEW_BUSINESS_CASE:
            return state;
            
        case types.RECEIVE_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO:
            return {
                ...state,
                cases : action.payload
            };
            case types.RESET_QUOTE_TRACKING_NO_API_FLAG:
                return {
                    ...state,
                    payload : action.payload
                };
            case types.RESET_NEW_BUSINESS_CASE_BASED_ON_QUOTE_TRACKING_NO:
                return {
                    ...state,
                    cases : action.payload
                };
            case types.OWNER_ASSIGNMENT_UPDATE_FROM_DES_FLOW:
                return {
                    ...state,
                    ownerAssignmentUpdatedFromDESFlow : true
                };     
            case types.ERROR_NEW_BUSINESS_CASE:
                return {
                    ...state,
                    error : true
                };
            case types.CLEAR_B2B_DES_DATA:
                return {
                    ...state,
                    cases : action.payload
                }    
            

        default:
            return state
    }
}