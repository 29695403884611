const getReduxStoreReference = () => {
    const { store } = require('../../index');
    return store;
}

export const MARKET_TYPES = {
    UHC: 'UHC',
    OXFORD: 'OXFORD'
}
/** Function to get market type UHC/OXFORD */
export const getMarketType = () => {
    const { overall } = getReduxStoreReference().getState();
    if (overall.appConfigDetails && overall.appConfigDetails.marketType === 'PRIME') {
        return MARKET_TYPES.UHC;
    } else {
        return MARKET_TYPES.OXFORD;
    }
}

export const isUhcRenewals = () => {
    const { overall } = getReduxStoreReference().getState();
    if (
        overall.appConfigDetails &&
        overall.appConfigDetails.marketType === "PRIME" &&
        (overall.isRenewals || overall.source === "renewal" || overall.source === "mmr")
    ) {
        return true;
    }
    return false;
}

export const getEligibleCountFromIbr = (renewalData) =>{
    if(renewalData){
        return renewalData.Ibr.group.primaryLocation.totalEligible;
    }
}

export const isOxfordRenewals = () => {
const { overall } = getReduxStoreReference().getState();
    if (
        overall.appConfigDetails &&
        overall.appConfigDetails.marketType === "CIRRUS" &&
        (overall.isRenewals || overall.source === "renewal")
    ) {
        return true;
    }
    return false;
}

export const isMmr = () => {
    const { overall } = getReduxStoreReference().getState();
    if (overall.source === 'mmr') {
        return true;
    }
    return false;
}