import deepCopy from '../deepCopy';

export const updateRateForInAreaPlans = (rate, employees, plans) => {
    const updatedRate = deepCopy(rate);
    const plan = plans.find(plan => plan.code === updatedRate.medicalPlanCode);
    // if in area plan update the rates
    if (plan && plan.areaCoverage === "In") {
        // update employee rates
        if (updatedRate.employeeRatesAgeBanded) {
            updatedRate.employeeRatesAgeBanded = updateEmployeeRates(updatedRate.employeeRatesAgeBanded, employees);
        }
        if (updatedRate.employeeRatesComposite) {
            updatedRate.employeeRatesComposite = updateEmployeeRates(updatedRate.employeeRatesComposite, employees);
        }
        updatedRate.employeeRates = updateEmployeeRates(updatedRate.employeeRates, employees);

        // update monthly/annual prem
        if (updatedRate.premiumTotalAgeBandedMonthly) {
            if (updatedRate.employeeRatesAgeBanded) {
                updatedRate.premiumTotalAgeBandedMonthly = updateMonthlyPrem(updatedRate.employeeRatesAgeBanded);
            } else {
                updatedRate.premiumTotalAgeBandedMonthly = updateMonthlyPrem(updatedRate.employeeRates);
            }
            updatedRate.premiumTotalAgeBandedAnnual = 12 * updatedRate.premiumTotalAgeBandedMonthly;
        }
        if (updatedRate.premiumTotalCompositeMonthly) {
            if (updatedRate.employeeRatesComposite) {
                updatedRate.premiumTotalCompositeMonthly = updateMonthlyPrem(updatedRate.employeeRatesComposite);
            } else {
                updatedRate.premiumTotalCompositeMonthly = updateMonthlyPrem(updatedRate.employeeRates);
            }
            updatedRate.premiumTotalCompositeAnnual = 12 * updatedRate.premiumTotalCompositeMonthly;
        }
    }
    return updatedRate;
}

const updateEmployeeRates = (employeeRates, employees) => {
    const updatedEmployeeRates = deepCopy(employeeRates);
    employees.forEach((employee, index) => {
        updatedEmployeeRates.forEach(employeeRate => {
            if (employeeRate.employeeId === parseInt(employee.memberId) || employeeRate.employeeId === index + 1) {
                if (employee.outOfArea == "Yes") {
                    employeeRate.total = 0;
                    if (employeeRate.hasOwnProperty('ee') && employeeRate.hasOwnProperty('er')) {
                        employeeRate.ee = 0;
                        employeeRate.er = 0;
                    }
                }
            }
        })
    })
    return updatedEmployeeRates;
}

const updateMonthlyPrem = (employeeRates) => {
    let totalMonthlyPrem = 0;
    employeeRates.forEach(employeeRate => {
        totalMonthlyPrem += parseFloat(employeeRate.total);
    })
    return totalMonthlyPrem;
}