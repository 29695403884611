const producerHandleMessage = (reviewCards, renewals, platform) => {

    const ownerAssignment = reviewCards.ownerAssignment

    // const logInData = renewals.logInData[0];
    // if (platform === "PRIME") {
    //     if ((logInData.role === "External" || logInData.role === "Broker")
    //         && (renewals.roleName === "Agency Admin" || renewals.roleName === "Agency Delegate" || renewals.roleName === "GA Admin")
    //         && ownerAssignment.producer.length < 1
    //     ) {
    //         return [true, "At least one Producer must be assigned to continue quote."]
    //     }
    //     else if (logInData.role === "Internal" && reviewCards.directSale && ownerAssignment.salesRep.length < 1) {
    //         return [true, "At least one Sales Rep must be assigned to continue quote."]
    //     }
    //     else if (logInData.role === "Internal" && !reviewCards.directSale && ownerAssignment.producer.length < 1) {
    //         return [true, "At least one Producer must be assigned to continue quote."]
    //     }
    //     else if (ownerAssignment.producer.length < 1 && ownerAssignment.salesRep.length < 1 && ownerAssignment.agency.length < 1 && ownerAssignment.gaSalesRep.length < 1 && ownerAssignment.generalAgent.length < 1) {
    //         return [true, "At least one producer or GA must be assigned to continue."]
    //     } else {
    //         return [false,'']
    //     } 
    // } else {
        if (ownerAssignment.producer.length < 1 && ownerAssignment.salesRep.length < 1 && ownerAssignment.agency.length < 1 && ownerAssignment.gaSalesRep.length < 1 && ownerAssignment.generalAgent.length < 1 && !reviewCards.directSale) {
            return [true, "At least one producer or GA must be assigned to continue."]
        } else if (ownerAssignment.producer.length >= 1 && ownerAssignment.generalAgent.length >= 1) {
            return [false, '']
        } else if (ownerAssignment.producer.length >= 1 && ownerAssignment.agency.length >= 1 && ownerAssignment.generalAgent.length >= 1) {
           return [false, '']
        } else if (ownerAssignment.producer.length >= 1 && ownerAssignment.agency.length >= 1) {
            return [false, '']
        } else if (ownerAssignment.agency.length >= 1 && ownerAssignment.generalAgent.length >= 1) {
            return [false, '']
        } else if (reviewCards.directSale) {
            if (ownerAssignment.salesRep.length < 1) {
                return [true, "At least one Sales Rep must be assigned to continue quote."];
            } else {
                return [false, '']
            }
        } else if (ownerAssignment.producer.length >= 1) {
            return [false, '']
        } else if (ownerAssignment.generalAgent.length >= 1) {
            return [false, '']
        } else {
            return [true, "At least one producer or GA must be assigned to continue."];
        }
    // }

    //else return [false, '']

};

export default producerHandleMessage;