exports.parseRxString = str => {

    let specialty = false;
    if (str.indexOf(" -Value Rx Network") >= 0) {
        specialty = true;
        str = str.split(" -Value Rx Network")[0];
    }
    let strParts, deductible, tiers;
    if (str.indexOf(";") > -1) {
        strParts = str.split(";");
        deductible = strParts[0];
        tiers = strParts[1];
    }
    else if (str.indexOf("D ") > -1) {
        strParts = str.split("D ");
        deductible = strParts[0];
        tiers = strParts[1];
    }
    else {
        deductible = "None";
        tiers = str;
    }
    let tiersParts = tiers.split("/");
    let tier1Copay, tier2Copay, tier3Copay, tier4Copay;
    if (tiersParts.length === 4) {
        tier1Copay = tiersParts[0];
        tier2Copay = tiersParts[1];
        tier3Copay = tiersParts[2];
        tier4Copay = tiersParts[3];
    }
    else {
        tier1Copay = tiersParts[0] ? tiersParts[0] : "N/A"
        tier2Copay = tiersParts[1] ? tiersParts[1] : "N/A"
        tier3Copay = tiersParts[2] ? tiersParts[2] : "N/A"
        tier4Copay = tiersParts[3] ? tiersParts[3] : "N/A"
    }
    return {
        specialty,
        deductible,
        tier1Copay,
        tier2Copay,
        tier3Copay,
        tier4Copay
    }
}

exports.negateStringInd = str => {
    if (str === "Y") {
        return "N"
    } else if (str === "N") {
        return "Y"
    } else {
        console.log("Unexpected string!")
    }
}

exports.stringIndToWord = str => {
    if (str === "Y") {
        return "Yes"
    } else if (str === "N") {
        return "No"
    } else {
        console.log("Unexpected string!")
    }
}

exports.boolToWord = bool => {
    if (bool) {
        return "Yes";
    }
    return "No";
}

/**
 * @description Returns a valid MM/DD/YYYY version or subset of a user-inputted string, or null if the attempted input cannot be salvaged
 * For example, it would change "12/1/" to "12/01/" but it would return null for "12/50" since that isn't a valid date
 * 
 * @param {string} str date or subset of date
 */
exports.validateDateString = str => {
    if (!str.match(/^[0-9/]*$/) || str.indexOf("//") > -1 || str === "/") { // if the string doesn't consist only of numbers and the "/" character
        return null;
    }
    let numSlashes = (str.match(/\//g) || []).length;
    if (numSlashes > 2) {
        return null;
    }
    let parts = str.split("/");
    let month = parts[0]; let day; let year;
    if (month !== "0" && parseInt(month) === 0) {
        return "";
    }
    if (parseInt(month) > 12) {
        return null;
    }
    if (parts.length > 1) {
        day = parts[1];
        // Change single digit month to 2-digit
        if (month.length === 1) {
            if (parseInt(month) === 0) return null;
            str = "0" + str;
        }
        if (parseInt(day) > 31) {
            return null;
        }
    }
    if (parts.length > 2) {
        // Change single digit day to 2-digit
        if (day.length === 1) {
            if (parseInt(day) === 0) return null;
            str = str.slice(0, 3) + "0" + str.slice(3);
        }
        year = parts[2];
        let yearRegex = /^$|^(1|2)$|^(19|20)[0-9]{0,2}$/;
        if (!yearRegex.test(year) || year.length > 4) {
            return null;
        }
    }
    return str;
};

exports.validateAutoDate = str => {
    const length1 = 2
    const length2 = 2
    const length3 = 4

    // if the string doesn't consist only of numbers and the "/" character
    if (!str.match(/^[0-9/]*$/) || str.indexOf("//") > -1 || str === "/") {
        return null;
    }
    let numSlashes = (str.match(/\//g) || []).length;
    if (numSlashes > 2) {
        return null;
    }

    let parts = str.split("/");
    let month = parts[0]; let day; let year;
    if (month !== "0" && parseInt(month) === 0) {
        return "";
    }
    if (parseInt(month) > 12 || month.length === 3) {
        // add missing slash between parts 1 and 2
        if (str.length === length1 + 1 && new RegExp(`^\\d{${length1 + 1}}$`).test(str)) {
            str = `${str.slice(0, length1)}/${str.slice(length1)}`;
        } else if (str.length === length1 + length2 + length3 && new RegExp(`^\\d{${length1 + length2 + length3}}$`).test(str)) {
            str = `${str.slice(0, length1)}/${str.slice(length1, length1 + length2)}/${str.slice(length1 + length2)}`;
        }
        else {
            return null;
        }
    }
    if (parts.length > 1) {
        day = parts[1];
        // Change single digit month to 2-digit
        if (month.length === 1) {
            if (parseInt(month) === 0) return null;
            str = "0" + str;
        }
        if (parseInt(day) > 31 || day.length === 3) {
            // add missing slash between parts 2 and 3
            if (str.length === length1 + length2 + 2 && new RegExp(`^\\d{${length1}}/\\d{${length2 + 1}}$`).test(str)) {
                str = `${str.slice(0, length1 + length2 + 1)}/${str.slice(length1 + length2 + 1)}`;
            } else {
                return null;
            }
        }
    }
    if (parts.length > 2) {
        // Change single digit day to 2-digit
        if (day.length === 1) {
            if (parseInt(day) === 0) return null;
            str = str.slice(0, 3) + "0" + str.slice(3);
        }
        year = parts[2];
        let yearRegex = /^$|^(1|2)$|^(19|20)[0-9]{0,2}$/;
        if (!yearRegex.test(year) || year.length > 4) {
            return null;
        }
    }
    return str;
}

exports.isValidDate = str => {
    let MMDDYYYYRegex = /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
    if (!(MMDDYYYYRegex.test(str))) {
        return false;
    }
    let parts = str.split("/");
    let month = parts[0]; let day = parts[1]; let year = parts[2];
    let date = new Date(str);
    // Check it creates a valid date. E.g., a string of "02/29/2019" would create a Date of Mar 1, 2019, because it is not a leap year
    // Or a string of "01/32/2019" would create a null object since it is totally invalid
    if (parseInt(month) !== date.getMonth() + 1 ||
        parseInt(day) !== date.getDate() ||
        parseInt(year) !== date.getFullYear()) {
        return false;
    };
    return true;
}

/**
 * @description Changes a letter into a number, e.g., 1 to "a", 2 to "b"
 * 
 * @param {number} number 1-indexed number
 * 
 * @returns {number} number
 */
exports.numberToLetter = number => {
    if (!(typeof number === "number")) {
        return number;
    }
    // TODO: Handle numbers greater than 26
    return String.fromCharCode(number + 96);
}

exports.isEmpty = something => {
    if (something === "" || something === null || something === undefined) {
        return true;
    }
    if (typeof something === 'string' && something.trim() === "") {
        return true;
    }
    return false;
}

exports.coverageCodeToString = coverageCode => {
    switch (coverageCode) {
        case "EE":
        case "EO":
            return "Employee Only";
        case "EE/SP":
        case "EE+SP":
        case "ES":
            return "Employee & Spouse"
        case "EE/CH":
        case "EE+CH":
        case "EC":
            return "Employee & Child";
        case "EE/FAM":
        case "EE+FAM":
        case "FAM":
        case "EF":
            return "Employee & Family";
        case "W":
            return "Waive";
        case 'EL':
            return "Elected";
        default:
            return "Custom";
    }
}

exports.stringToCoverageCode = codeString => {
    switch (codeString) {
        case 'EE':
        case 'Employee Only':
        case 'EL':
        case 'Elected':
            return 'EE';
        case 'ES':
        case 'Employee & Spouse':
            return 'EE/SP';
        case 'EC':
        case 'Employee & Child':
            return 'EE/CH';
        case 'FAM':
        case 'Employee & Family':
            return 'EE/FAM'
        case 'W':
        case 'Waive':
            return 'W'
        case 'EL':
        case 'Elected':
            return 'EL'
        default:
            return 'W';
    }
}

// TODO: we should find everywhere that we are making "EE/SP", "EE/CH", etc., and change them so that this function becomes obsolete
exports.longCoverageCodeToShortCoverageCode = coverageCode => {
    switch (coverageCode) {
        case 'EE':
            return 'EE';
        case 'EE/SP':
            return 'ES';
        case 'EE/CH':
            return 'EC';
        case 'EE/FAM':
            return 'F';
        case 'W':
            return 'W';
        default:
            return coverageCode;
    }
}

exports.pluralize = num => {
    if (num !== 1) return "s";
    return "";
}

exports.isZeroString = str => {
    str = str.replace("$", "");
    return parseFloat(str) === 0;
}

exports.capitalizeFirstLetter = str => {
    let firstLetter = str.slice(0, 1);
    const rest = str.slice(1);
    firstLetter = firstLetter.toUpperCase();
    return firstLetter + rest;
}

exports.formatRelationship = relation => {
    switch (relation) {
        case 'Self':
            return 'Self';
        case 'Life Partner':
            return 'Life Partner'
        case 'Spouse':
            return 'Spouse';
        case 'Child':
            return 'Child';
        case 'Civil Union Partner':
            return 'Civil Union Partner';
        case 'Domestic Partner':
            return 'Domestic Partner';
        default:
            return relation;
    }
}

exports.getStateName = (stateCode) => {
    switch (stateCode) {
        case 'CT':
            return 'Connecticut';
        case 'NY':
            return 'New York';
        case 'NJ':
            return 'New Jersey';
    }
}


exports.getSuffixMapping = (suffix) => {
    switch (suffix) {
        case 'JR':
            return 'Jr.';
        case 'SR':
            return 'Sr.';
        case '1':
            return 'I';
        case '2':
            return 'II';
        case '3':
            return 'III';
        case '4':
            return 'IV';
        case '5':
            return 'V';
        case '6':
            return 'VI';
        case '7':
            return 'VII';
        case '8':
            return 'VIII';
        default:
            return '';
    }
}

exports.validateName = (str) => {
    const regex = new RegExp('^[a-zA-Z ]{0,30}$')
    if (!regex.test(str)) {
        return null
    }
    return str
}
