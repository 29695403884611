import * as types from '../../actions/actionTypes';
import {
    handleRequestPlans,
    handleReceiveSpecialtyPlans,
    handleReceiveSpecialtyAllPlans,
    handleRequestPlansRenewals,
    handleReceiveSpecialtyPlansRenewals,
    handleRequestRates,
    handleReceiveSpecialtyRates,
    handleReceiveSpecialtyAllPlansRenewals,
    handleReceiveSpecialtyPlanRates,
    handleReceiveSpecialtySelectedPlanRates, resetDetails
} from './helpers/fetchingReducerHelpers';
import {initialFilterCriteria, initialfilterTotalsCache, initialState} from './consts/ltdReducerConsts';
import {
    handleChangeCustomPlanName,
    handleChangeFilterCriteria, handleChangeSortOrder,
    handleRatingMethodChange, handleResetFilterCriteria, handleTogglePreferredPlans,
    handleUpdateAllPlans,
    handleUpdatePlan,
    handleEmployeeClassFilter
} from "./helpers/filteringReducerHelpers";
import {
    handleFilterChange,
    resetFitlers,
    setAdvanceFilterData,
    setBasicFilterDirectly,
    setAdvanceFilterWith30Plans,
    viewFilteredPlans
} from "./helpers/advanceFilterHelper";
import {handleAdjustRatesAndPremium} from "./helpers/rateAdjustmentHelper";

export default function ltd(state = initialState, action) {

    switch (action.type) {
        case types.SERVICE.LTD.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state, action, initialFilterCriteria, initialFilterCriteria);
        case types.SERVICE.LTD.REQUEST_RENEWAL_PLANS:
            return handleRequestPlansRenewals(state, initialFilterCriteria, initialFilterCriteria, action.fullProps, 'LTD');

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action);
        case types.SERVICE.LTD.RECEIVE_RENEWAL_PLANS:
            return handleReceiveSpecialtyPlansRenewals(state, action.payload.plans, action.fullProps, 'LTD');

        case types.SERVICE.LTD.REQUEST_QUICK_QUOTE_RATES:
        case types.SERVICE.LTD.REQUEST_RENEWAL_RATES:
            return handleRequestRates(state);

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_RATES:
        case types.SERVICE.LTD.RECEIVE_RENEWAL_RATES:
            return handleReceiveSpecialtyRates(state, action);

        case types.SERVICE.LTD.UPDATE_PLAN:
            return handleUpdatePlan(state, action);

        case types.SERVICE.LTD.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action);

        case types.UPDATE_LTD_PLANS_CLASS_FILTER:
            return handleEmployeeClassFilter(state, action)
        
        case types.SERVICE.LTD.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };

        case types.SERVICE.LTD.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.CHANGE_RATE_TIER_FOR_SPECIALTY:
            return handleRatingMethodChange(state, action);

        case types.SERVICE.LTD.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        case types.SERVICE.LTD.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.LTD.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_ALL_PLANS:
            return handleReceiveSpecialtyAllPlans(state, action.payload);

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_ALL_PLANS_RENEWALS:
            return handleReceiveSpecialtyAllPlansRenewals(state, action.payload, action.fullProps, 'LTD');

        case types.SERVICE.LTD.SET_ADVANCE_FILTER_DATA:
            return setAdvanceFilterData(state, state.allPlans);

        case types.SERVICE.LTD.SET_ADVANCE_FILTER_30_PLANS:
            return setAdvanceFilterWith30Plans(state, state.allPlans);

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_PLAN_RATES:
            return handleReceiveSpecialtyPlanRates(state, action);

        case types.SERVICE.LTD.RECEIVE_QUICK_QUOTE_SELECTED_PLAN_RATES:
            return handleReceiveSpecialtySelectedPlanRates(state, action);

        case types.SERVICE.LTD.APPLY_FILTER:
            return handleFilterChange(state, action.payload);

        case types.SERVICE.LTD.VIEW_FILTERED_PLANS:
            return viewFilteredPlans(state, action.payload);

        case types.SERVICE.LTD.RESET_FILTERS:
            return resetFitlers(state);

        case types.SERVICE.LTD.SET_BASIC_FILTERS_DIRECTLY:
            return setBasicFilterDirectly(state);

        case types.SERVICE.LTD.RESET_DATA:
            return resetDetails(state, initialFilterCriteria, initialfilterTotalsCache);

        case types.SERVICE.LTD.ADJUST_RATE:
            return handleAdjustRatesAndPremium(state, action);

        case types.RESET_FLOW:
        case types.RESET_SHOPPING_FLOW_SPECIALTY:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
            return initialState

        case types.RESET_RENEWAL_FLOW:
            return {
                ...state,
                selectedPlans: [],
            };
        case types.RESET_FLOW_DVL_REVIEW_PAGE: {
            const newVisiblePlans = (state.visiblePlans || []).map((plan) => {
                const item = {
                    ...plan,
                    selected: false,
                };
                return item;
            });

            return {
                ...state,
                selectedPlans: [],
                selectedPlansMap: {
                    Single: [],
                },
                visiblePlans: newVisiblePlans,
            };
        }

        case types.SERVICE.LTD.CHANGE_CUSTOM_PLAN_NAME:
            return handleChangeCustomPlanName(state, action);

        case types.UPDATE_BASIC_LTD_CLASSES:
            return {
              ...state,
              employeeClassing : {
                classingOpted: (action.payload.length > 0),
                classes: action.payload,
                selectedClass: action.selectedClass,
              }
            }

        case types.CLEAR_SELECTED_LTD_CLASSES:
            return {
              ...state,
              employeeClassing : {
                classingOpted: false,
                classes: [],
                selectedClass: "",
              }
            }
            case types.SET_LTD_CLASS:
                return {
                    ...state,
                    employeeClassing: {
                        ...state.employeeClassing,
                        selectedClass: action.lifeClass
                    }
                }
            case types.SET_LTD_CLASS_RATES_FETCHED:
                return {
                    ...state,
                    classRatesFetched: true
                }
            case types.SET_IS_FETCHING_CLASS_RATES:
                return {
                    ...state,
                    isFetchingClassRates: action.bool
                }
        /*case types.ADD_LTD_CLASSING_OPTION:
            return {
              ...state,
              classingOptions: [action.payload, ...state.classingOptions]
            }*/

        case types.SET_EMPLOYEE_SELECTED_LTD_CLASSES:
            return {
                ...state,
                employeeClassing: {
                    ...state.employeeClassing,
                    employeeSelectedClasses: action.payload
                }
            }

        case types.UPDATE_SELECTED_LTD_PLAN:
            return {
                ...state,
                selectedPlans: action.payload,
                selectedPlansMap: {
                    Single: action.payload,
                },
            };
        case types.USER_FAVORITE_LTD_PLANS:
            const { allPlans, plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                allPlans,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        default:
            return state;
    }
}
