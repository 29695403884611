import * as types from '../../actions/actionTypes';

const initialState = {
    pends: [],
    editPend: {},
    showAddPendModal: false,
    docs: [],
    uploadErrors: false,
    isUploadingDocument: false,
    isUploadingToEdms: false,
    uploadEdmsError: false,
    uploadedToEdms: false,
    emails:[],
    requestedAssistance: false,
    pendTabIndex: 0,
    isUploadingToDoc360: false,
    uploadDoc360Error: false,
    uploadedToDoc360: false,
    pendManagers:[],
    isPendManagerListUpdated : false
};

export default function additionalReqdInfo(state = initialState, action) {
    switch (action.type) {
        case types.PUSH_TO_PENDS_ARRAY:
            return {
                ...state,
                pends: [...state.pends, action.pend],
            };
        case types.EDIT_PENDS_ARRAY:
            return {
                ...state,
                pends: state.pends.map((item, index) => {
                    if (index !== action.index) {
                        return item;
                    }

                    return {
                        ...action.pend,
                    };
                }),
            };
        case types.UPDATE_PEND:
            return {
                ...state,
                editPend: action.editPend,
            };
        case types.UPLOAD_ADDITIONAL_INFO:
        case types.REMOVE_UPLOAD_ADDITIONAL_INFO:
            return {
                ...state,
                docs: action.payload.docs,
                uploadErrors: false,
            };
        case types.UPLOAD_ADDITIONAL_INFO_ERROR:
        case types.REMOVE_UPLOAD_ADDITIONAL_INFO_ERROR:
            return {
                ...state,
                uploadErrors: true,
            };
        case types.IS_UPLOADING_ADDITIONAL_INFO:
            return {
                ...state,
                isUploadingDocument: true,
            };
        case types.DONE_UPLOADING_ADDITIONAL_INFO:
            return {
                ...state,
                isUploadingDocument: false,
            };
        case types.IS_UPLOADING_ADDITIONAL_INFO_EDMS:
            return {
                ...state,
                isUploadingToEdms: true,
                uploadEdmsError: false,
                uploadedToEdms: false
            };
        case types.DONE_UPLOADING_ADDITIONAL_INFO_EDMS:
            return {
                ...state,
                isUploadingToEdms: false,
            };
        case types.ERROR_UPLOADING_ADDITIONAL_INFO_EDMS:
            return {
                ...state,
                uploadEdmsError: true,
                uploadedToEdms: false,
                isUploadingToEdms: false
            };
        case types.UPLOADED_ADDITIONAL_INFO_EDMS:
            return {
                ...state,
                isUploadingToEdms: false,
                uploadEdmsError: false,
                uploadedToEdms: true
            };
        case types.PUSH_TO_EMAILS_ARRAY:
            return {
                ...state,
                emails: [...state.emails, action.email],
            };
        case types.SET_REQUESTED_ASSISTANCE:
            return {
                ...state,
                requestedAssistance: true
            }
        case types.SET_PEND_TAB_INDEX:
            return {
                ...state,
                pendTabIndex: action.pendTabIndex
            }
        case types.IS_UPLOADING_ADDITIONAL_INFO_DOC360:
            return {
                ...state,
                isUploadingToDoc360: true,
                uploadDoc360Error: false,
                uploadedToDoc360: false
            };
        case types.UPLOADED_ADDITIONAL_INFO_DOC360:
            return {
                ...state,
                isUploadingToDoc360: false,
                uploadDoc360Error: false,
                uploadedToDoc360: true
            };
        case types.ERROR_UPLOADING_ADDITIONAL_INFO_DOC360:
            return {
                ...state,
                uploadDoc360Error: true,
                uploadedToDoc360: false,
                isUploadingToDoc360: false
            };
        case types.PEND_MANAGERS_DATA:
            return { 
                ...state,
                pendManagers : action.payload,
                isPendManagerListUpdated: true
            };
        case types.RESET_TO_INITIALSTATE:
                return initialState
        case types.RESET_RENEWALS:
                return initialState
        case types.RESET_FLOW:
                return initialState
        default:
            return state;
    }
}
