import {
  COMPLETED_ELIGIBILITY_INFO,
  INITIALIZE_ELIGIBILITY_INFO,
  RESET_TO_INITIALSTATE,
  SAVE_ELIGIBILITY_FORM,
  UPDATE_ELIGIBILITY_INFO,
  NUM_ELIG_EMP_CHANGED,
  SET_NUM_ELIGIBLE_EMPLOYEES,
  UPDATE_MEDICAL_EMPLOYER_CONTRIBUTION,
  OVERRIDE_VALIDATIONS,
  POPULATE_ELIGIBILITY_INFO,
  SET_EMPLOYER_ENROLLMENT_COMPLETE
} from '../../../actions/actionTypes';
import { checkIfObjectMeetsConditions } from '../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions';
import { getRelevantAppState, makeAppStateNested } from '../../../components/shared/ConfigurableForms/helpers/getRelevantAppState';
import { initializeEnrollmentPageState, populateEnrollmentEligibilityStateFromContractChangeUhc } from './helper';
import { getValueOrEmptyString, isUndefinedOrNullOrEmpty } from '../../../utils/validation/commonValidations';
import { checkValidationsOnBlur } from '../../../components/shared/ConfigurableForms/helpers/validationCheckers';
import { checkEachSection } from '../../../components/activities/Enrollment/EmployerAppForms/EnrollmentFormCompletionHandler';

const initialState = {
  errors: {},
  participation: {
    numEligibleEmployees: null,
  },
  eligibilityInformation: {},
  waitingPeriod: {},
  contribution: {},
  additionalEligibilityInformation: {},
  currentCarrierInformation: {},
  consumerDrivenHealthPlanOption: {},
};

export default function eligibilityReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_ELIGIBILITY_INFO: {
      const initializedState = initializeEnrollmentPageState(action.payload, action.conditionEvaluators, action.eligibilityInfo);

      // DES Integration:  Contributions to be considered from the DES.
      // (Contibution in the state are going to be Empty for the quote created in the SAMx)
      const existingContribution = Object.keys(state.contribution);
      if (existingContribution.length > 0) {
        existingContribution.forEach((key) => {
          const contibutionValue = getValueOrEmptyString(state.contribution[key]);

          if (+contibutionValue > 0) {
            initializedState.contribution[key] = contibutionValue;
          }
        });
      }

      return {
        ...state,
        ...initializedState,
      };
    }

    case POPULATE_ELIGIBILITY_INFO:
      const populatedStateFromDB = populateEnrollmentEligibilityStateFromContractChangeUhc(action.payload);
      populatedStateFromDB.participation.numHoursPerWeekToBeEligible = populatedStateFromDB.participation.numHoursPerWeekToBeEligible === "" ? state.participation.numHoursPerWeekToBeEligible : populatedStateFromDB.participation.numHoursPerWeekToBeEligible;
      populatedStateFromDB.participation.numHoursPerWeekToBeEligibleDisability  = populatedStateFromDB.participation.numHoursPerWeekToBeEligibleDisability === "" ? state.participation.numHoursPerWeekToBeEligibleDisability : populatedStateFromDB.participation.numHoursPerWeekToBeEligibleDisability;
      return {
          ...state,
          ...populatedStateFromDB,
      };

    case UPDATE_ELIGIBILITY_INFO: {
      const { update, group, isError, updateAnotherField = {} } = action;
      if (isError) {
        const resolvedErrorGroup = state.errors[group] || {};
        return {
          ...state,
          errors: {
            ...state.errors,
            [group]: {
              ...resolvedErrorGroup,
              ...update,
              ...updateAnotherField,
            },
          },
        };
      }

      const resolvedGroup = state[group] || {};
      return {
        ...state,
        [group]: {
          ...resolvedGroup,
          ...update,
          ...updateAnotherField,
        },
      };
    }

    case COMPLETED_ELIGIBILITY_INFO: {
      const {
        application, enrollmentForm, conditionEvaluators, appStateProps, fullProps
      } = action;
      let completed = true;

      for (const rulesSection of enrollmentForm.eligibility) {
        let showSection = true
        if (rulesSection.showIf) {
          let appState = getRelevantAppState(application, 'eligibility', rulesSection.sectionName, rulesSection);
          appState = makeAppStateNested(appState);
          showSection = checkIfObjectMeetsConditions(
            rulesSection.sectionName,
            rulesSection.showIf,
            {
              ...conditionEvaluators,
              appState,
            },
            fullProps.companyProfile,
            fullProps.rules.enrollment
          );
        }

        if (showSection) {
          const eachSectionIncompletionData = checkEachSection('eligibility', rulesSection, rulesSection.sectionName, application, conditionEvaluators, fullProps);
          completed = eachSectionIncompletionData.completed===false?false:completed;
        }
      }

      return {
        ...state,
        completed,
      };
    }

    case SAVE_ELIGIBILITY_FORM: {
      return {
        ...state,
        formSaved: true,
      };
    }

    case NUM_ELIG_EMP_CHANGED:{
      return{
        ...state,
        numEligEmpChangedData : action.payload
      }
    }
    case SET_NUM_ELIGIBLE_EMPLOYEES:{
      return{
        ...state,
        participation:{
          ...state.participation,
          numEligibleEmployees:action.payload
        }
      }
    }

    case UPDATE_MEDICAL_EMPLOYER_CONTRIBUTION:{
      return{
        ...state,
        contribution:{
          ...state.contribution,
          medicalEmployerContribution:action.payload
        }
      }
    }
    case RESET_TO_INITIALSTATE:
      return initialState;
    case OVERRIDE_VALIDATIONS:
      return {
        ...state,
        contribution: {
          ...state.contribution,
          overrideValidations: action.payload
        }
      }
    case SET_EMPLOYER_ENROLLMENT_COMPLETE:
      return {
          ...state,
          completed: action.isComplete
      }
    default:
      return state;
  }
}
