import { isUndefinedOrNullOrEmpty } from '../utils/validation/commonValidations';
import * as types from './actionTypes';
import sendLog from './logActions';

/**
 * @description Calculates and returns the total monthly premium based on final adjusted rates
 * @param {String} productType type for the product, e.g. 'DENTAL'
 * @param {} plan Plan object should contain the details of the plan
 * @param {} finalRates Final plan rates
 */
export const calculateProductTotalMonthlyPremium = (productType, plan, finalRates, isMMRCase = false) => (dispatch, getState) => {
  const fullProps = getState();
  const isClassing = plan.hasOwnProperty('classRates');

  if (productType === 'life' || productType === 'std' || productType === 'ltd') {
    const { aDnDLifeRateVol, BasicLifeTotVolAmount: totalBasicLifeVolume, censusRates = [] } = plan.quote;

    let fnalRates;
    let rateVol;
    let totalVol;
    let totalMonthlyPremium;
    let individualRates;

    if (isClassing) {
      const slcClass = plan.selectedClass;
      individualRates = plan.classRates[slcClass].quote.censusRates;
    } else {
      individualRates = censusRates;
    }

    if (productType === 'life') {
      fnalRates = finalRates.basicLife;
      rateVol = aDnDLifeRateVol;
      totalVol = totalBasicLifeVolume //TotalLife volume is 100000 (only for life calculation)
    } else if (productType === 'std') {
      fnalRates = finalRates.std;
    } else if (productType === 'ltd') {
      fnalRates = finalRates.ltd;
    }

    if (productType === 'life') {
      const totalRate = parseFloat(fnalRates) + parseFloat(rateVol);
      totalMonthlyPremium = (parseFloat(totalRate) * parseFloat(totalVol)) / 1000;
    } else if (productType === 'std') {
      let stdTotalMonthlyPremium = 0;
      // if(isMMRCase){
      //   const { employeeRatesComposite, maximumWeeklyBenefit } = plan;
      //   employeeRatesComposite && employeeRatesComposite.forEach(emp => {
      //     stdTotalMonthlyPremium += (parseFloat(maximumWeeklyBenefit) / 10) * parseFloat(fnalRates);
      //   })
      // }else{
        for (const censusRate of individualRates) {
          stdTotalMonthlyPremium += (parseFloat(censusRate.maximumWeeklyBenefit) / 10) * parseFloat(fnalRates);
        } 
      //}

      totalMonthlyPremium = stdTotalMonthlyPremium;
    } else {
      let ltdTotalMonthlyPremium = 0;
      // if(isMMRCase){
      //   const { employeeRatesComposite, maximumMonthlyBenefit } = plan;
      //   employeeRatesComposite && employeeRatesComposite.forEach(emp => {
      //     ltdTotalMonthlyPremium += (parseFloat(maximumMonthlyBenefit) / 100) * parseFloat(fnalRates);
      //   })
      // }else{
        for (const censusRate of individualRates) {
          ltdTotalMonthlyPremium += (parseFloat(censusRate.maximumMonthlyBenefit) / 100) * parseFloat(fnalRates);
        } 
      //}

      totalMonthlyPremium = ltdTotalMonthlyPremium;
    }

    return totalMonthlyPremium
  }

  const { employeeOnly, employeeWithSpouse, employeeWithChild, employeeWithFamily } = fullProps.specialtyCriteria[`${productType}CensusSummary`];
  var { ee, es, ec, ef } = finalRates;

  ee = isNaN(ee) ? 0 : ee;
  es = isNaN(es) ? 0 : es;
  ec = isNaN(ec) ? 0 : ec;
  ef = isNaN(ef) ? 0 : ef;

  const eeCount = !isUndefinedOrNullOrEmpty(employeeOnly) ? parseInt(employeeOnly) : 0;
  const esCount = !isUndefinedOrNullOrEmpty(employeeWithSpouse) ? parseInt(employeeWithSpouse) : 0;
  const ecCount = !isUndefinedOrNullOrEmpty(employeeWithChild) ? parseInt(employeeWithChild) : 0;
  const efCount = !isUndefinedOrNullOrEmpty(employeeWithFamily) ? parseInt(employeeWithFamily) : 0;
  // Dental or Vision total monthly premium
  return eeCount * parseFloat(ee) + esCount * parseFloat(es || 0) + ecCount * parseFloat(ec || 0) + efCount * parseFloat(ef);
};

/**
 * @description Dispatches action to update rates and premium for a plan
 * @param {String} serviceType Service type for the product, e.g. 'DENTAL'
 * @param {number} premium New premium to set for the plan
 * @param {Object} rates Object containing all the new final rates after adjustment
 * @param {Object} adjustments Object containing all rate adjustments made by the user
 * @param {String} planCode Plan code for the plan to update
 */
export const adjustRatesAndPremium = (serviceType, premium, rates,
  adjustments, planCode, isAgeBanded = false, selectedClass = null) => (dispatch, getState) => {
    
    const state = getState();

    const source = state.overall.source;
    const platform = state.overall.appConfigDetails.marketType;

    sendLog({
      action: 'ADJUST RATES AND PREMIUMS',
    });

    dispatch({
      type: types.SERVICE[serviceType].ADJUST_RATE,
      premium,
      rates,
      adjustments,
      planCode,
      planType: serviceType.toLowerCase(),
      isAgeBanded,
      selectedClass,
      source,
      platform
    });
};