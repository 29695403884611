import { parseRxString } from '../../../../../utils/formatters/strings';
import { extractCompositeRatesShopping, extractCompositeRates } from '../RenewalsPlanDetailsExtract';

// * Formed the Object to re use the full shopping component. Formated the  Renewal Obj into full shopping Obj*/

/** US2700080: Compare , Save Rates, and Selected Plan counter bottom sticky bar common utility in shopping */
const temp_isUhcRewardsValid = (effectiveDate) => {
    const toBeComparedDate = new Date('2023-01-01');
    const newEffDate = effectiveDate.split('T')
    return new Date(newEffDate[0]) >= toBeComparedDate;
}
export const updateExchangeIndicator=(plans)=>{
    if(plans && plans.length>0){
        plans.map(plan =>{
            if(plan && plan.hiosID && plan.renewalPlan){
                plan.isExchange=findExchangeIndicator(plan.hiosID)
            }
        })
    }
    
    return plans

}
const findExchangeIndicator = (hiosID) => {
    let exchange = hiosID.split('-')
    if(exchange[1] && exchange[1].length==2 && exchange[1]=='01')
        return true
    else
        return false
}

const formComparePlanObject = (renPlan, renewalData) => {
    const productsPlanData = renewalData.products.plans;
    const planRatesData = renewalData.planRates;
    let code; let codeDisplay; let rxPlanCode; let areaCoverage; let combinedMedRxDeductible; let copaysHaveTwoTiers; let defaultRatingType; let compositeTypeName;
    let emergencyRoomCopay; let emergencyRoomCopayOut; let favorited; let hsa; let hra; let licenseType; let medicalPlanCategory; let medicalPlanCode;
    let medicalPlanType; let medicalProductType; let metal; let nonEmbeddedDeductible; let pcpCopay; let pcpCopayOut; let pcpRequired;
    let physicianCoinsurance; let physicianCoinsuranceOut; let planType; let platform; let selected; let specialistCopay; let specialistCopayOut;
    let urgentCareCopay; let urgentCareCopayOut; let platformRatingID; let HIOSID; let hiosID; let planGroup; let riderAge29; let
        riderDomesticPartner; let careCash = null; let uhcRewards = null; let visionRider = false;

    const deductible = {};
    let isComposite;
    const hospitalCoinsurance = {};
    const hraContribution = {};
    const oopMax = {};
    const rx = {};

    if (productsPlanData) {
        productsPlanData.forEach((plan) => {
            if (renPlan === plan.code) {
                const benifitSummery = plan.benefitValues.find(({ code }) => code === 'benefitSummary');
                const keyBenefits = plan.benefitValues.find(({ code }) => code === 'keyBenefits');
                const planHighlight = plan.benefitValues.find(({ code }) => code === 'planHighlightsTiered');
                const rxPlancodeObj = benifitSummery.benefitValue.find(({ code }) => code === 'rxPlanCode');
                const planMetaData = plan.benefitValues.find(({ code }) => code === 'planMetadata');
                const ridersData = plan.benefitValues.find(({ code }) => code === 'riders');

                code = plan.code;
                codeDisplay = plan.name;
                rxPlanCode = benifitSummery && benifitSummery.benefitValue.find(({ code }) => code === 'rxPlanCode') ? benifitSummery.benefitValue[benifitSummery.benefitValue.findIndex(({ code }) => code === 'rxPlanCode')].value : '';
                areaCoverage = areaCoverageType(benifitSummery && benifitSummery.benefitValue.find(({ code }) => code === 'OOANetworkAccessType') ? benifitSummery.benefitValue[benifitSummery.benefitValue.findIndex(({ code }) => code === 'OOANetworkAccessType')].value : '');
                combinedMedRxDeductible = textToWord(keyBenefits && keyBenefits.benefitValue.find(({ code }) => code === 'MEDRXDED') ? keyBenefits.benefitValue[keyBenefits.benefitValue.findIndex(({ code }) => code === 'MEDRXDED')].value : '');
                deductible.familyIn = planHighlight.benefitValue.find(({ code, tier }) => code === 'FAMDED' && tier === 'IN').value.replace(/,/g, '');
                deductible.familyOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'FAMDED' && tier === 'OUT').value.replace(/,/g, '');
                deductible.individualIn = planHighlight.benefitValue.find(({ code, tier }) => code === 'INDDED' && tier === 'IN').value.replace(/,/g, '');
                deductible.individualOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'INDDED' && tier === 'OUT').value.replace(/,/g, '');
                emergencyRoomCopay = planHighlight.benefitValue.find(({ code, tier }) => code === 'EMRCOPAY' && tier === 'IN').value;
                emergencyRoomCopayOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'EMRCOPAY' && tier === 'OUT').value;
                favorited = false;
                hospitalCoinsurance.in = planHighlight.benefitValue.find(({ code, tier }) => code === 'HOSCOINS' && tier === 'IN').value;
                hospitalCoinsurance.out = planHighlight.benefitValue.find(({ code, tier }) => code === 'HOSCOINS' && tier === 'OUT').value;
                hsa = benifitSummery.benefitValue.find(({ code }) => code === 'isHSAEligible').value ? benifitSummery.benefitValue.find(({ code }) => code === 'isHSAEligible').value : '';
                hra = benifitSummery.benefitValue.find(({ code }) => code === 'isHRAEligible') ? benifitSummery.benefitValue.find(({ code }) => code === 'isHRAEligible').value : '';
                hraContribution.min = '';
                hraContribution.max = '';
                licenseType = benifitSummery.benefitValue.find(({ code }) => code === 'LT') ? benifitSummery.benefitValue.find(({ code }) => code === 'LT').value : '';
                medicalPlanCategory = '';
                medicalPlanCode = plan.code;
                medicalPlanType = plan.networkCode;
                medicalProductType = benifitSummery.benefitValue.find(({ code }) => code === 'productType').value;
                metal = benifitSummery.benefitValue.find(({ code }) => code === 'metallicLevel').value;
                nonEmbeddedDeductible = textToWord(keyBenefits.benefitValue.find(({ code }) => code === 'EMDED').value);
                oopMax.familyIn = planHighlight.benefitValue.find(({ code, tier }) => code === 'FAMOOM' && tier === 'IN').value.replace(/,/g, '');
                oopMax.familyOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'FAMOOM' && tier === 'OUT').value.replace(/,/g, '');
                oopMax.individualIn = planHighlight.benefitValue.find(({ code, tier }) => code === 'INDOOM' && tier === 'IN').value.replace(/,/g, '');
                oopMax.individualOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'INDOOM' && tier === 'OUT').value.replace(/,/g, '');
                pcpCopay = planHighlight.benefitValue.find(({ code, tier }) => (code === 'PCPCOPAY' || code === 'PCPCOPAY_T2') && tier === 'IN').value;
                pcpCopayOut = planHighlight.benefitValue.find(({ code, tier }) => (code === 'PCPCOPAY' || code === 'PCPCOPAY_T2') && tier === 'OUT').value;
                pcpRequired = benifitSummery.benefitValue.find(({ code }) => code === 'pcpRequired').value;
                if (benifitSummery.benefitValue.find(({ code }) => code === "CareCash")) {
                    careCash = benifitSummery.benefitValue.find(({ code }) => code === "CareCash").value === 'X' ? true : null
                }
                if (benifitSummery.benefitValue.find(({ code }) => code === "uhcRewardsPremium") && temp_isUhcRewardsValid(renewalData.memberGroup.renewalEffectiveDate)) {
                    uhcRewards = benifitSummery.benefitValue.find(({ code }) => code === "uhcRewardsPremium").value === "" ? 'uhcRewardsCore' : 'uhcRewardsPremium'
                }
                HIOSID = planMetaData.benefitValue.find(({ code }) => code === 'HIOS').value;
                hiosID = planMetaData.benefitValue.find(({ code }) => code === 'HIOS').value;
                planGroup = keyBenefits && keyBenefits.benefitValue.find(({ code }) => code === 'planType') ? keyBenefits.benefitValue[keyBenefits.benefitValue.findIndex(({ code }) => code === 'planType')].value : '';
                physicianCoinsurance = planHighlight.benefitValue.find(({ code, tier }) => code === 'PHYCOINS' && tier === 'IN').value;
                physicianCoinsuranceOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'PHYCOINS' && tier === 'OUT').value;
                platform = 'cirrus';
                platformRatingID = renewalData.rateId ? renewalData.rateId : '';
                const rx1 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX1') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX1').copay : 'N/A';
                const rx2 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX2') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX2').copay : 'N/A';
                const rx3 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX3') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX3').copay : 'N/A';
                const rx4 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX4') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX4').copay : 'N/A';
                const formRxPlanCode = `${rx1}/${rx2}/${rx3}/${rx4}`;

                const rxVal = parseRxString(rxPlancodeObj && rxPlancodeObj.value !== '' ? rxPlancodeObj.value : formRxPlanCode);
                rx.deductible = rxVal.deductible;
                rx.specialty = rxVal.specialty;
                rx.tiers = [
                    { 
                        tierNum: 1, 
                        copay: planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX1').value
                    },
                    { 
                        tierNum: 2, 
                        copay: planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX2').value
                    },
                    { 
                        tierNum: 3, 
                        copay: planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX3').value
                    },
                    { 
                        tierNum: 4, 
                        copay: planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX4').value
                    },
                ];
                specialistCopay = planHighlight.benefitValue.find(({ code, tier }) => (code === 'SPECCOPAY' || code === 'SPECCOPAY_T2') && tier === 'IN').value;
                specialistCopayOut = planHighlight.benefitValue.find(({ code, tier }) => (code === 'SPECCOPAY' || code === 'SPECCOPAY_T2') && tier === 'OUT').value;
                urgentCareCopay = planHighlight.benefitValue.find(({ code, tier }) => code === 'UCCOPAY' && tier === 'IN').value;
                urgentCareCopayOut = planHighlight.benefitValue.find(({ code, tier }) => code === 'UCCOPAY' && tier === 'OUT').value;

                if (plan.name && plan.name[0] === 'N' && plan.name[1] === 'Y' && ridersData) {
                    riderAge29 = ridersData.benefitValue.find(({ code }) => code === 'age29').value;
                    riderAge29 = riderAge29.toUpperCase() !== 'FALSE';
                    riderDomesticPartner = ridersData.benefitValue.find(({ code }) => code === 'dp').value;
                    riderDomesticPartner = riderDomesticPartner.toUpperCase() !== 'FALSE';
                    if (ridersData.benefitValue.find(({ code }) => code === "specialtyVisionRider"))
                        visionRider = ridersData.benefitValue.find(({ code }) => code === "specialtyVisionRider").value.toUpperCase() === "X"
                    else visionRider = false
                    
                } else {
                    riderAge29 = false;
                    riderDomesticPartner = false;
                }

            }
        });
    }
    let ageBands; let compositeRates; let premiumTotalAgeBandedAnnual; let premiumTotalAgeBandedMonthly; let premiumTotalCompositeMonthly; let premiumTotalCompositeAnnual;
    let isAgeBanded; let isExchange; let quote; let
        employeeRates;
    if (planRatesData) {
        planRatesData.forEach((plan) => {
            if (renPlan === plan.planCode) {
                ageBands = plan.rateTable.ratingMethod === 'AgeBanded' ? formateAgeBand(plan.rateTable.rate) : null;
                compositeRates = plan.rateTable.ratingMethod !== 'AgeBanded' ? formatCompositeRates(plan.rateTable.rate) : null;
                defaultRatingType = plan.rateTable.ratingMethod === 'AgeBanded' ? 'ageBands' : 'composite';
                planType = plan.productType;
                if (plan.rateTable.ratingMethod === 'AgeBanded') {
                    premiumTotalAgeBandedAnnual = plan.rateTotals[1].total;
                    premiumTotalAgeBandedMonthly = plan.rateTotals[0].total;
                    premiumTotalCompositeMonthly = 0;
                    premiumTotalCompositeAnnual = 0;
                } else {
                    premiumTotalCompositeMonthly = plan.rateTotals[0].total;
                    premiumTotalCompositeAnnual = plan.rateTotals[1].total;
                    premiumTotalAgeBandedAnnual = 0;
                    premiumTotalAgeBandedMonthly = 0;
                }
                isAgeBanded = plan.rateTable.ratingMethod === 'AgeBanded';
                isComposite = plan.rateTable.ratingMethod !== 'AgeBanded';
                // compositeTypeName = defaultRatingType === "composite" ? "" : ""
                compositeTypeName = '';
                isExchange = hiosID && hiosID !== '' ? findExchangeIndicator(hiosID) : false;
                quote = plan.rateTotals[0].total;
                employeeRates = plan.employeeRates ? plan.employeeRates : [];
            }
        });
    }
    const renewalPlan = true;
    //* * Object formation*/
    const plan = {
        careCash,
        uhcRewards,
        employeeRates,
        ageBands,
        compositeRates,
        areaCoverage,
        isComposite,
        code,
        codeDisplay,
        combinedMedRxDeductible,
        copaysHaveTwoTiers,
        deductible,
        defaultRatingType,
        compositeTypeName,
        emergencyRoomCopay,
        emergencyRoomCopayOut,
        favorited,
        hospitalCoinsurance,
        hra,
        hraContribution,
        hsa,
        isAgeBanded,
        isExchange,
        licenseType,
        medicalPlanCategory,
        medicalPlanCode,
        medicalPlanType,
        medicalProductType,
        metal,
        nonEmbeddedDeductible,
        oopMax,
        pcpCopay,
        pcpCopayOut,
        pcpRequired,
        physicianCoinsurance,
        physicianCoinsuranceOut,
        planType,
        planGroup,
        platform,
        platformRatingID,
        premiumTotalAgeBandedAnnual,
        premiumTotalAgeBandedMonthly,
        premiumTotalCompositeMonthly,
        premiumTotalCompositeAnnual,
        quote,
        rx,
        HIOSID,
        hiosID,
        rxPlanCode,
        renewalPlan,
        selected,
        specialistCopay,
        specialistCopayOut,
        urgentCareCopay,
        urgentCareCopayOut,
        riderAge29,
        riderDomesticPartner,
        visionRider
    };
    return plan;
};
export { formComparePlanObject }
const formateAgeBand = (rates) => {
    const ageBands = [];
    let minAge; let maxAge; let
        total;
    if (rates) {
        rates.forEach((rate) => {
            if (rate.band === '0-14') {
                ageBands.push(
                    { minAge: '<', maxAge: '14', total: rate.total },
                );
            } else if (rate.band === '64-99') {
                ageBands.push(
                    { minAge: '64', maxAge: '+', total: rate.total },
                );
            } else {
                ageBands.push(
                    { minAge: rate.band, maxAge: rate.band, total: rate.total },
                );
            }
        });
    }
    return ageBands;
};

const areaCoverageType = (ooaType) => {
    switch (ooaType) {
        case 'No Access':
            return 'In';
        case 'Living and Traveling':
            return 'Out - Live and Travel';
        case 'Traveling Only':
            return 'Out - Travel';
        default:
            console.log('Unexpected OOA Type: ', ooaType);
            return ooaType;
    }
};
export { areaCoverageType };

const textToWord = (val) => {
    if (val === 'Seperate') {
        return true;
    }
    if (val === 'Embedded') {
        return true;
    }
    return false;
};

const formatCompositeRates = (rates) => {
    let compositeRates = []

    if (rates) {
        rates.forEach((rate) => {
            compositeRates.push({
                employeeType: rate.tier,
                employeeTypeDisplay: rate.tier,
                rate: rate.total,
            });
        });
    }
    return compositeRates;
};
