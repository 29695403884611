import * as types from '../../actions/actionTypes';

export default function limitedDistributionReducer(state = { limitedDstrPlanCodes:[] } , action) {

    switch (action.type) {
        case types.REQUEST_LIMITED_DISTRIBUTION_API:
            return {
                ...state,
                limitedDstrPlanCodes : action.payload
            };
        case types.RESET_LIMITED_DISTRIBUTION_API_FLAG:
            return {
                ...state,
                payload : action.payload
            };    
        default:
            return state
    }
}