/**
 * Information for the cell number of the imported employee census,
 * either vertical or horizontal
 */
export const verticalEmp = {
    firstName: 8,
    lastName: 7,
    locationZipCode: 5,
    zipCode: 6,
    dob: 10,
    age: 11,
    medical: 20,
    dental: 22,
    vision: 23,
    basicLife: 23,
    std: 24,
    ltd: 25,
    salary: 16, 
    gender: 12,
    employeeStatus: 12,
    outOfArea: 16
};

export const horizontalEmp = {
    locationZipCode: 3,
    zipCode: 4,
    lastName: 5,
    firstName: 6,
    middleInitial: 7,
    suffix: 8,
    dob: 9,
    age: 10,
    gender: 11,
    employeeStatus: 12,
    specialEmployeeStatus: 13,
    email: 14,
    salary: 15,
    outOfArea: 16,
    medical: 17,
    medicalWaiverReason: 18,
    dental: 19,
    vision: 20,
    life: 21,
    std: 22,
    ltd: 23,
  
};

export const verticalDep = {
    relationship: 4,
    firstName: 6,
    lastName: 7,
    zipCode: 5,
    dob: 9,
    age: 10,
    gender: 11
};

export const horizontalDep = {
    relationship: 2,
    firstName: 6,
    lastName: 5,
    middleInitial: 7,
    suffix: 8,
    zipCode: 4,
    dob: 9,
    age: 10,
    gender: 11
};

export const horizontalLocation = {
    zipCode: 1,
    state: 2,
    totalEmps: 3,
    totalEligEmps: 4,
    atne: 5,
    fte: 6
};

export const verticalLocation = {
    zipCode: 3,
    state: 4,
    totalEmps: 5,
    totalEligEmps: 6,
    atne: 7,
    fte: 8
};

export const horizontalEnroll = { 
    relationship: 2,
    employeeStatus: 3,
    firstName: 4,
    lastName: 5,
    middleInitial: 6,
    suffix: 7,
    gender: 8,
    dob: 9,
    isWaivingAll: 10,
    medicalPlanCode: 11,
    medicalWaiverReason: 12,
    assignmentMethod: 13,
    pcpCode: 14,
    existingPatient: 15,
    dentalPlanCode: 16,
    visionPlanCode: 18,
    lifePlanCode: 19,
    dependentLifePlanCode: 20,
    suppLifePlanCode: 21,
    suppDepLifeSpousePlanCode: 22,
    suppDepLifeChildPlanCode: 23,
    stdPlanCode: 24,
    ltdPlanCode: 25,
    numberOfHoursWorked: 27,
    salary: 28,
    dateOfHire: 29,
    outOfArea: 30,
    ssnTin: 31,
    noSSNReason: 32,
    streetAddress: 33,
    city: 34,
    state: 35,
    zipCode: 37,
    county: 38,
    email: 39,
    telephone: 40,
    phoneType: 41,
    anyOtherCoverage: 42,
    nameOtherCarrier: 43,
    medicarePrimary: 44,
    medicareReason: 45,
    medicareClaimNumber: 46,
    medicarePartAStartDate: 47,
    medicarePartBStartDate: 48,
    medicarePartDStartDate: 49,
    cobraStartDate: 50,
    cobraEndDate: 51,
    isDisabled: 53,
    disabilityType: 54
};

export const verticalEnroll = {
    isWaivingAll: 8,
    medicalPlanCode: 9,
    medicalWaiverReason: 10,
    assignmentMethod: 11,
    pcpCode: 12,
    ssnTin: 24,
    noSSNReason: 25,
    streetAddress: 26,
    city: 27,
    state: 28,
    anyOtherCoverage: 33,
    nameOtherCarrier: 34,
    medicarePrimary: 35,
    medicareReason: 36,
    medicareClaimNumber: 37,
    medicarePartAStartDate: 38,
    medicarePartBStartDate: 39,
    cobraStartDate: 40,
    cobraEndDate: 41,
    cobraType: 42,
};