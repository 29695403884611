import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { history } from '../utils/history';
import { shoppingRequest } from '../components/activities/Renewal/RenewalShopping/ShoppingUtils';
import { shopSpecialityPlans } from './renewalShoppingActions';
import { createHistory } from './historyAction';
import { updateInitialStepperAccess, updateRenewalCaseStatus, updateRenewalSearchCase, setRefreshApisLoader, updateRenewalCaseProgress, setMedicalCensusSummary } from './renewalActions';
import { updateCaseProgress, getRenewalCompanyProfileData } from './companyProfileActions';
import { updateSelectedProductsInRenewals } from './overallActions';
import {
    generateRenewalCompanyProfileNewFormat,
    saveReviewDetailContractChange,
    generateRenewalEmployerEnrollmentNewFormat,
    generateRenewalCompanyProfileIntial,
    formatIBRMembersMapping,
    makeCirrusSpecialyACAQuery,
} from '../components/activities/Renewal/RenewalsCommonComponents/saveDetails';
import { formatMembers } from './overallActions';
import { isEmpty } from '../utils/formatters/strings';
import { filterReconciledCensus } from '../components/activities/Renewal/Review/reviewComponents/FilterIBR';
import { setSpecialtyCriteria, setSpecialtySelectedProducts } from './quickQuotesActions';
import { identifyPlatform, getExistingCoverageForSpecialty, identifyMedicalProductInCirrus } from '../components/activities/Renewal/Review/reviewUtils';
import {
    addSalesRepData,
    addOwnerAssignmentData,
    addAgencyData,
    addGeneralAgentData,
    addGASalesRepData,
} from './reviewCardsAction';
import { getEnrollmentForm } from './enrollmentFormActions';
import { MARKET_TYPES, getMarketType, isMmr, isUhcRenewals } from '../utils/businessLogic/platformUtils';
import { getMembersMappingFromIBR } from '../utils/Renewal/uhcRenewalDataHelper';
import { resetFinalizeFlag } from './finalizeCaseSummaryActions';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import deepCopy from '../utils/deepCopy';
import { defaultContributions } from '../components/activities/Renewal/RenewalsCommonComponents/contributionHelper';
import { CASE_PROGRESS } from '../reducers/products/helpers/companyProfileConsts';
import { FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS, FI_LF_CONVERSION_COMPLETE_CASE_STATUS } from '../utils/consts/consts';

const apiRoutes = require('../utils/api/apiRouteConfig');

/** Save member details */
export function rateIdAndGroupId(val) {
    return (dispatch) => {
        const memberGroupId = {
            val,
        };
        dispatch({
            type: types.REQUEST_MEMBER_ID,
            payload: memberGroupId,
        });
    };
}

export const setAlternatePlansIndicator = (val) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_ALTERNATE_PLANS_IND,
            payload: val
        })
    }
}

/** get review page details */
export const reviewRenewalDetails = (isPlanChange = false, page, isFinalizeStepper = false) => async (dispatch, getState) => {
    const fullProps = getState();
    try {
        const memberGroupId = fullProps.review?.val?.customerNumber || fullProps.review?.renewalCaseData?.memberGroupId;
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
        const priorYearDataSelected = fullProps?.renewals?.priorYearDataSelected;

        let FULL_URL;
        if (getMarketType() === MARKET_TYPES.OXFORD) {
            FULL_URL = `${apiRoutes.quickQuotes}/currentCaseStatus?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&priorYearDataSelected=${priorYearDataSelected}`
        } else {
            const effectiveDate = fullProps?.renewals?.renewalCaseData?.effectiveDate;
            FULL_URL = `${apiRoutes.quickQuotes}/uhcRenewal/currentCaseStatusUhc?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`
        }

        const currentCaseStatusReqResponse = await axios.get(FULL_URL).catch(err => {
            if (getMarketType() === MARKET_TYPES.UHC) {
                const reqBody = {
                    memberGroupId, quoteTrackingNumber
                }
                logError(KEY_NAME, UHC_REN_APIS.CURRENT_CASE_STATUS, 'reviewActions.reviewRenewalDetails', reqBody, err);
            }
            throw err;
        })

        let currentCaseProgress = '';
        let renewalPlan;

        if (fullProps.renewals.globalSearchFlag) {
            currentCaseProgress = fullProps.renewals.renewalSearchCase.caseProgressUI;
        } else {
            if (currentCaseProgress === '' && fullProps.caseTracking && fullProps.caseTracking.caseTrack[0] && fullProps.caseTracking.caseTrack[0].caseStatus !== '') {
                currentCaseProgress = fullProps.caseTracking.caseTrack[0].caseStatus;
            } else {
                renewalPlan = fullProps.renewals.renewals.find((plan) => plan.memberGroupId === fullProps.renewals.memberGroupId);
                currentCaseProgress = renewalPlan ? renewalPlan.caseProgressUI : '';
            }
        }
        if (
            currentCaseProgress === "" &&
            fullProps.caseTracking &&
            fullProps.caseTracking.caseTrack[0] &&
            fullProps.caseTracking.caseTrack[0].caseStatus !== ""
        ) {
            currentCaseProgress = fullProps.caseTracking.caseTrack[0].caseStatus;
        }
        let bool = true;

        if ((currentCaseStatusReqResponse && currentCaseStatusReqResponse.data && currentCaseStatusReqResponse.data.renewalCaseData)) {
            bool = false;
        }

        if ((currentCaseStatusReqResponse && currentCaseStatusReqResponse.data && currentCaseStatusReqResponse.data.renewalCaseData) && ((currentCaseProgress === 'Not Started' || currentCaseProgress == '') &&
            (currentCaseProgress !== 'Accepted As Is' && currentCaseProgress !== 'Accepted' && currentCaseProgress !== 'Pending Acceptance' &&
                currentCaseProgress !== 'Pending Cancellation' && currentCaseProgress !== 'Cancelled')) || isPlanChange) {

            const query = {
                caseStatus: 'In Progress',
            };

            dispatch(updateCaseStatus(query, false, '', false, true));
        } else {
            try {
                if (isUhcRenewals() && bool && currentCaseProgress != FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS && currentCaseProgress != FI_LF_CONVERSION_COMPLETE_CASE_STATUS ) {
                    dispatch(updateCaseStatus({ caseStatus: 'In Progress' }, false, '', false, false));
                }
                await reviewDetailMethod(fullProps, bool)(dispatch, getState);
            } catch (err) {
                return;
            }

            if (fullProps.overall.source === 'mmr' && page === 'finalize') {
                dispatch(resetFinalizeFlag(true))
            } else if (fullProps.overall.source !== 'mmr' && isFinalizeStepper) {
                history.push('/finalize')
            } else {
                history.push('/reviewDetail');
            }
        }
    } catch (err) {
        if (isUhcRenewals()) {
            dispatch({
                type: types.DISPLAY_ERROR_MESSAGE,
            });
        }
        
        console.log('Error', err);
    }
};

export function getReviewShoppingDetails() {
    return (dispatch, getState) => {
        const fullProps = getState();
        try {
            reviewDetailMethod(fullProps)(dispatch, getState);
        } catch (err) {
            return;
        }
    };
}

export const startNewRenewalQuote = () => async (dispatch, getState) => {
    const fullProps = getState();
    try {
        await reviewDetailMethod(fullProps, false, true)(dispatch, getState);
    } catch (err) {
        return;
    }

    const query = {
        caseStatus: 'In Progress'
    };

    await dispatch(updateCaseStatus(query, false, '', true, false));
}

const reviewDetailMethod = (fullProps, updateCaseStatusBool = false, isPlanChange = false) => async (dispatch, getState) => {

    dispatch({
        type: types.RESET_REVIEW_FLAG,
        payload: false,
    });
    dispatch({
        type: types.ENABLE_RENEWAL_STEPPER_FLAG,
        payload: false,
    });

    const { caseTracking, review, user, renewals } = fullProps;

    const { customerNumber, renewalQuoteId, primeCustomerNumber } = review.val;

    const reviewRequest = {
        memberGroupId: customerNumber,
        renewalQuoteId: renewalQuoteId
    };

    const quoteTrackingNumber = caseTracking.currentQuoteId;
    const platform = renewalQuoteId ? 'CIRRUS' : 'PRIME';
    const marketType = getMarketType();
    const priorYearDataSelected = renewals && renewals.priorYearDataSelected ? renewals.priorYearDataSelected : 'N';
    const effectiveDate = renewals?.renewalCaseData?.effectiveDate
    const uhcPlatformCode = renewals && renewals.renewalCaseData && renewals.renewalCaseData.uhcPlatformCode ? renewals.renewalCaseData.uhcPlatformCode : '';

    try {
        const res = marketType === MARKET_TYPES.OXFORD ? await axios.get(`${apiRoutes.quickQuotes}/renewalReview?memberGroupId=${customerNumber}&renewalQuoteId=${renewalQuoteId}&quoteTrackingNumber=${quoteTrackingNumber}&platform=${platform}&priorYearDataSelected=${priorYearDataSelected}`)
            : await axios.get(`${apiRoutes.quickQuotes}/uhcRenewal/renewalReviewUhc?memberGroupId=${customerNumber}&primeCustomerNumber=${primeCustomerNumber}&uhcPlatformCode=${uhcPlatformCode}&quoteTrackingNumber=${quoteTrackingNumber}&platform=PRIME&isMMRCase=${fullProps.overall.source === 'mmr'}&effectiveDate=${effectiveDate}&priorYearDataSelected=${priorYearDataSelected}`).catch(err => {
                const reqBody = {
                    memberGroupId: customerNumber,
                    quoteTrackingNumber,
                    platform: 'PRIME',
                    isMMRCase: fullProps.overall.source === 'mmr'
                }
                logError(KEY_NAME, UHC_REN_APIS.RENEWAL_REVIEW, 'reviewActions.reviewDetailMethod', reqBody, err);
            })
        let employees = [];
        if (marketType === MARKET_TYPES.UHC && !res.data.members) {
            employees = getMembersMappingFromIBR(res.data.renewalData.Ibr)
            res.data.members = employees;
        }
        if (res.data && res.data.stepperAccess) {
            dispatch(updateInitialStepperAccess(res.data.stepperAccess));
        } else {
            const initialStepperAccess = {
                renewaldashboard: true,
                reviewDetail: false,
                decide: false,
                enrollment: false,
                finalize: false,
            };
            dispatch(updateInitialStepperAccess(initialStepperAccess));
        }
        let zipCodeValue;
        if (res.data.renewalData.memberGroup.locations.location.zipCode.length > 5) {
            zipCodeValue = res.data.renewalData.memberGroup.locations.location.zipCode.substring(0, 5);
        } else {
            zipCodeValue = res.data.renewalData.memberGroup.locations.location.zipCode
        }

        const dataCopy = {
            ...res.data,
            priorYearDataSelected,
            renewalData: {
                ...res.data.renewalData,
                memberGroup: {
                    ...res.data.renewalData.memberGroup,
                    locations: {
                        ...res.data.renewalData.memberGroup.locations,
                        location: {
                            ...res.data.renewalData.memberGroup.locations.location,
                            zipCode: zipCodeValue
                        }
                    }
                }

            }
        }
        const reviewData = dataCopy;
        if (fullProps?.renewals?.rlCaseProgress === CASE_PROGRESS.CONVERTED_TO_LF || fullProps?.renewals?.rlCaseProgress === CASE_PROGRESS.LF_CONVERSION_COMPLETE) {
            reviewData.renewalCaseData = {
                ...reviewData.renewalCaseData,
                caseProgressUI: fullProps.renewals.rlCaseProgress,
                renewalDataStatusUI: fullProps.renewals.rlCaseProgress
            }
        }
        if (res.data.renewalData && !res.data.renewalData.isCompanyProfileUpdated) {
            if (!isPlanChange)
                dispatch(setRefreshApisLoader(true));
            const query = dataCopy.renewalData && dataCopy.renewalData.specialtyProducts ? makeCirrusSpecialyACAQuery(dataCopy) : await saveReviewDetailContractChange(dataCopy, true);
            query.quoteTrackingNumber = quoteTrackingNumber;
            let stateValue = "";
            if (isUhcRenewals()) {
                stateValue = dataCopy.renewalCaseData.situsState;
            }
            let rulesResponse = "";
            if (marketType === MARKET_TYPES.OXFORD) {
                query.priorYearDataSelected = priorYearDataSelected;
                await axios.put(`${apiRoutes.quickQuotes}/renewalUpdate/`, query)
            } else {
                await axios.put(`${apiRoutes.quickQuotes}/uhcRenewal/renewalUpdateUhc/`, query).catch(err => {
                    logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'reviewActions.reviewDetailMethod', query, err);
                    throw err;
                })
                rulesResponse = isUhcRenewals() ? await axios.get(`${apiRoutes.mongo}/atne-rules/${stateValue}`).catch(err => {
                    logError(KEY_NAME, UHC_REN_APIS.ATNE_RULES, 'reviewActions.reviewDetailMethod', { stateValue }, err);
                    throw err;
                }) : ""
            }
            let stateRule = rulesResponse !== "" ? rulesResponse.data.rule : "";
            const renewalCompanyProfileNewFormat = generateRenewalCompanyProfileIntial(reviewData, "", stateRule);
            if (isUhcRenewals()) {
                axios.get(`${apiRoutes.mongo}/dependent-rules/${stateValue}`)
                    .then(response => {
                        dispatch({
                            type: types.UPDATE_DEPENDENT_RULES,
                            rules: response.data
                        })
                    })
                    .catch(error => {
                        console.log(error);
                        logError(KEY_NAME, UHC_REN_APIS.DEPENDENT_RULES, 'reviewActions.reviewDetailMethod', { stateValue }, error);
                    })
            } else {
                renewalCompanyProfileNewFormat.priorYearDataSelected = priorYearDataSelected;
            }
            renewalCompanyProfileNewFormat.quoteTrackingNumber = quoteTrackingNumber;
            renewalCompanyProfileNewFormat.marketType = marketType;
            const profileRequestResponse = await axios.post(`${apiRoutes.mongo}/profiles?userName=${user.userName}`, renewalCompanyProfileNewFormat).catch(err => {
                logError(KEY_NAME, UHC_REN_APIS.PROFILES, 'reviewActions.reviewDetailMethod', renewalCompanyProfileNewFormat, err);
                throw err;
            })

            if (!isPlanChange)
                dispatch(setRefreshApisLoader(false));
            dispatch(getRenewalCompanyProfileData(review.val.customerNumber, true, profileRequestResponse.data.insertedProfile));
            dispatch({
                type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                payload: true,
            });

            reviewData.renewalData.isCompanyProfileUpdated = true;
        } else {
            dispatch(getRenewalCompanyProfileData(review.val.customerNumber));
            dispatch({
                type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                payload: true,
            });
        }
        dispatch({
            type: types.RECEIVE_REVIEW_DETAILS,
            payload: !res.data.renewalData.isCompanyProfileUpdated ? reviewData : dataCopy,
            source: 'review-details',
        });
        if (res.data.renewalData && res.data.renewalCaseData) {
            const coverageCounts = filterReconciledCensus(res.data);
            dispatch(setSpecialtyCriteria({
                ...fullProps.specialtyCriteria,
                dentalCensusSummary: coverageCounts.dentalCensus,
                visionCensusSummary: coverageCounts.visionCensus,
                lifeCensusSummary: coverageCounts.lifeCensus,
                dependentLifeCensusSummary: coverageCounts.dependentLifeCensus,
                stdCensusSummary: coverageCounts?.stdCensus,
                platform: isUhcRenewals() ? "Prime" : ['CT', 'NY', 'NJ'].includes(res.data.renewalCaseData.situsState) ? 'Cirrus' : 'Prime',
            }));
            if (marketType === MARKET_TYPES.UHC) {
                dispatch(setMedicalCensusSummary({
                    medicalCensusSummary: coverageCounts.medicalCensus
                }));
            }
        }
        let { medEValue, medDValue, denEValue, denDValue, visEValue, visDValue, lifeEValue } = defaultContributions;
        if (res.data.mdvContribution && Object.keys(res.data.mdvContribution).length !== 0) {
            const mdvContribution = res.data.mdvContribution;
            medEValue = mdvContribution.medical.eValue;
            medDValue = mdvContribution.medical.dValue;
            denEValue = mdvContribution.dental.eValue;
            denDValue = mdvContribution.dental.dValue;
            visEValue = mdvContribution.vision.eValue;
            visDValue = mdvContribution.vision.dValue;
            lifeEValue = mdvContribution.life.eValue;
        }
        else if (res.data?.renewalData?.memberGroup) {
            const employerContribution = res.data.renewalData.memberGroup.employerContribution;
            medEValue = employerContribution?.subscriber?.value
                ? employerContribution.subscriber.value.toString()
                : '50';
            medDValue = employerContribution?.dependent?.value
                ? employerContribution.dependent.value.toString()
                : '0';
            if (marketType === MARKET_TYPES.OXFORD) {
                if (res.data.renewalData.memberGroup.specialtyEmployerContribution && res.data.renewalData.memberGroup.specialtyEmployerContribution.length > 0) {
                    const specialtyContribution = res.data.renewalData.memberGroup.specialtyEmployerContribution;
                    if (specialtyContribution) {
                        const dental = specialtyContribution.find(contr => contr.productType === "Dental");
                        if (dental) {
                            denEValue = dental.subscriber.value.toString();
                            denDValue = dental.dependent.value.toString();
                        }
                        const vision = specialtyContribution.find(contr => contr.productType === "Vision");
                        if (vision) {
                            visEValue = vision.subscriber.value.toString();
                            visDValue = vision.dependent.value.toString();
                        }
                    }
                }
            }
        }

        const queryMDV = {
            medEValue,
            medDValue,
            denEValue,
            denDValue,
            visEValue,
            visDValue,
            lifeEValue,
            errorMessage: '',
        };
        dispatch({
            type: types.UPDATE_MDV_CONTRIBUTION,
            payload: queryMDV,
        });
        let selectedProds = {};
        const specialityReq = shoppingRequest(res.data, false);
        let identifiedPlatform = identifyPlatform(res.data.renewalData);

        if (marketType === MARKET_TYPES.OXFORD) {
            if (specialityReq.groupSize > 1 && identifiedPlatform === 'CIRRUS') {
                specialityReq.otherDetails.isPreferredPlans = true;
                specialityReq.otherDetails.includeSpecialty = true;
                // 02/01 Release excluding Basic Life products
                //specialityReq.otherDetails.selectedProducts = ['dental', 'vision', 'life'];
                specialityReq.otherDetails.selectedProducts = ['dental', 'vision'];
                if (fullProps.overall.appConfigDetails.oxfordRenewalLifeActive) {
                    specialityReq.otherDetails.selectedProducts = [...specialityReq.otherDetails.selectedProducts, 'life'];
                }
                specialityReq.otherDetails.platform = identifiedPlatform === 'DUAL' ? 'PRIME' : 'CIRRUS'
                dispatch(shopSpecialityPlans(specialityReq.groupSize, specialityReq.zipObj, specialityReq.effectiveDate, specialityReq.otherDetails,
                    specialityReq.employeeCounts, specialityReq.employeeClasses, specialityReq.employerContribution, specialityReq.employees, specialityReq.companyProfile.companyName));
                dispatch(updateSelectedProductsInRenewals(['medical', 'dental', 'vision', 'life']));
                selectedProds = {
                    medical: identifyMedicalProductInCirrus(res.data.renewalCaseData.product),
                    dental: true,
                    vision: true,
                    life: true,
                    ltd: false,
                    std: false,
                    sul: false,
                };
            } else if (identifiedPlatform === 'PRIME') {
                selectedProds = {
                    medical: false,
                    dental: true,
                    vision: true,
                    life: true,
                    ltd: false,
                    std: false,
                    sul: false,
                };
            } else if (identifiedPlatform === 'DUAL') {
                selectedProds = {
                    medical: true,
                    dental: true,
                    vision: true,
                    life: true,
                    ltd: false,
                    std: false,
                    sul: false,
                };
            } else {
                selectedProds = {
                    medical: true,
                    dental: false,
                    vision: false,
                    life: false,
                    ltd: false,
                    std: false,
                    sul: false,
                };
            }
            dispatch(setSpecialtySelectedProducts(selectedProds));
            dispatch(updateSelectedProductsInRenewals(['medical']));
        } else {
            selectedProds = {
                medical: true,
                dental: true,
                vision: true,
                life: true,
                depLife: true,
                ltd: true,
                std: true,
                sul: true,
            }
            dispatch(setSpecialtySelectedProducts(selectedProds));
            dispatch(updateSelectedProductsInRenewals(['medical', 'dental', 'vision', 'life', 'depLife', 'std','ltd']));
        }
        dispatch(createHistory('Renewal review request', 'Review & Consider', 'Success', reviewRequest, res.data, 'RL'));

        if (updateCaseStatusBool) {
            let renewalPlan;
            let currentCaseProgress;
            if (fullProps.renewals.globalSearchFlag) {
                currentCaseProgress = fullProps.renewals.renewalSearchCase.caseProgressUI;
            } else {
                renewalPlan = fullProps.renewals.renewals.find((plan) => plan.memberGroupId === fullProps.renewals.memberGroupId);
                currentCaseProgress = renewalPlan ? renewalPlan.caseProgressUI : '';
            }
            if (
                currentCaseProgress === "" &&
                fullProps.caseTracking &&
                fullProps.caseTracking.caseTrack[0] &&
                fullProps.caseTracking.caseTrack[0].caseStatus !== ""
            ) {
                currentCaseProgress = fullProps.caseTracking.caseTrack[0].caseStatus;
            }
            if (((currentCaseProgress === 'Not Started' || currentCaseProgress == '') &&
                (currentCaseProgress !== 'Accepted As Is' && currentCaseProgress !== 'Accepted' && currentCaseProgress !== 'Pending Acceptance' &&
                    currentCaseProgress !== 'Pending Cancellation' && currentCaseProgress !== 'Cancelled'))) {
                const query = {
                    caseStatus: 'In Progress',
                };

                dispatch(updateCaseStatus(query, false, '', false, false));
            }
        }
        dispatch({
            type: types.RECEIVE_SALESREP_DETAILS,
            payload: reviewData,
        });

        if (reviewData && !reviewData.errors) {
            //TODO -  Need to confirm and remove if we r not using in review page
            dispatch(getEnrollmentForm(reviewData.renewalCaseData.situsState, 'renewals'))
            dispatch(formatSalesRepData(reviewData, getState()));
            if (reviewData.gaSalesRepData && reviewData.gaSalesRepData.length > 0) {
                dispatch(formatGaSalesRepData(reviewData, getState()))
            }
        };
        dispatch(addReviewCardsDetails(getState()))
    } catch (err) {
        dispatch(createHistory('Renewal review request', 'Review & Consider', reviewRequest, err, 'RL'));
        dispatch({
            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
            payload: false,
        });

        dispatch({
            type: types.RESET_REVIEW_FLAG,
            payload: true,
        });

        dispatch({
            type: types.ERR_REVIEW_DETAILS,
            payload: err,
        });

        throw err;
    };
};
export function reviewDetailsForRenewAsis(res) {
    const data = {
        res,
    };
    return (dispatch) => {
        sendLog({
            action: 'SUBMIT_REVIEW_REQUEST',
            ...data,
        });
        dispatch({
            type: types.RECEIVE_REVIEW_DETAILS,
            payload: data.res,
            data,
            source: 'review-details',
        });
    };
}
export function updateReviewRenewalDetails(reviewData) {
    return (dispatch) => {
        let reqUrl;
        if (getMarketType() === MARKET_TYPES.OXFORD) {
            reqUrl = `${apiRoutes.quickQuotes}/renewalUpdate/`
        } else {
            reviewData.isMMRCase = isMmr()
            reqUrl = `${apiRoutes.quickQuotes}/uhcRenewal/renewalUpdateUhc/`
        }

        let query = deepCopy(reviewData);

        if (isUhcRenewals()) {
            delete query.renewalData.Ibr;
            delete query.renewalData.members;
            delete query.alternate;
        }

        axios.put(reqUrl, query)
            .then(() => {
                dispatch({
                    type: types.UPDATED_REVIEW_DETAILS,
                    review: {
                        ...reviewData,
                    },
                });
            })
            .catch((err) => {
                console.log('Error', err);
                dispatch({
                    type: types.ERR_UPDATE_REVIEW_DETAILS,
                    payload: err,
                });
            });
    };
}

export function addPlanChangeField(memberGroupId) {
    return (dispatch, getState) => {
        const fullProps = getState();
        const marketType = getMarketType();
        const FULL_URL = `${apiRoutes.quickQuotes}/addPlanChangeFieldInCaseData?memberGroupId=${fullProps.review.val.customerNumber}&marketType=${marketType}`;
        axios.get(FULL_URL)
            .then((res) => {
                dispatch(createHistory('Renewal - Add Plan Change', 'On Plan Change Btn', 'Success', fullProps.review.val.customerNumber, true, 'RL'));
                dispatch({
                    type: types.PLAN_CHANGE_FIELD,
                    payload: 'true',
                    source: 'addPlanChangeFieldInCaseData',
                });
            })
            .catch((err) => {
                dispatch(createHistory('Renewal - Add Plan Change', 'On Plan Change Btn', 'Error', fullProps.review.val.customerNumber, err, 'RL'));
                dispatch({
                    type: types.ERROR_PLAN_CHANGE_FIELD,
                    payload: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Plan Change field Add Api Failed',
                });
            });
    }
}

export function updateReviewRenewalStore(reviewData) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATED_REVIEW_DETAILS',
            reviewData,
        });

        dispatch({
            type: types.UPDATED_REVIEW_DETAILS,
            review: {
                ...reviewData,
            },
        });
    };
}

export function updateMDVcontribution(query) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE MDV CONTRIBUTION',
            query,
        });
        dispatch({
            type: types.UPDATE_MDV_CONTRIBUTION,
            payload: query,
        });
    };
}

export function updateNumOfSelectedRenewPlans(count) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE NUM OF SELECTED_RENEW_PLANS',
            count,
        });
        dispatch({
            type: types.UPDATE_NUM_OF_SELECTED_RENEW_PLANS,
            payload: count,
        });
    };
}

export function activateShoppingTab(index) {
    return (dispatch, getState) => {
        const fullProps = getState();
        sendLog({
            action: 'ACTIVATE SHOPPING TAB',
            index,
        });
        dispatch({
            type: types.ACTIVATE_SHOPPING_TAB,
            payload: index,
        });
        const updateShoppingTab = {
            ...fullProps.renewalShoppingHelper,
            activeShoppingTab: index,
        };
        dispatch({
            type: types.RECEIVE_RENEWAL_SHOPPING_HELPER_DETAILS,
            payload: updateShoppingTab,
        });
    };
}

export function updatePlanIsChecked(query) {
    const data = {
        query,
    };
    return (dispatch) => {
        sendLog({
            action: 'UPDATE PLAN ISCHECKED VALUE',
            query,
        });
        dispatch({
            type: types.UPDATE_PLAN_ISCHECKED_VALUE,
            payload: data,
        });
    };
}
export function updatePremiumToggle(toggleData) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_PREMIUM_TOGGLE_VALUE_REVIEW',
            toggleData,
        });
        dispatch({
            type: types.UPDATE_PREMIUM_TOGGLE_VALUE_REVIEW,
            payload: toggleData,
        });
    };
}
/** get Renewal Plans for SBC popup */
export function plansRelatedToRateId(val) {
    return (dispatch) => {
        const sbcPlansRateIdRequest = {
            val,
        };
        sendLog({
            action: 'SUBMIT_RENEWAL_PLAN_CODE',
            ...sbcPlansRateIdRequest,
        });

        dispatch({
            type: types.REQUEST_RENEWAL_PLAN_CODE,
            payload: sbcPlansRateIdRequest,
        });

        axios.get(`${apiRoutes.quickQuotes}/sbcPlans?renewalQuoteId=${val.renewalQuoteId}`)
            .then((res) => {
                dispatch(createHistory('Renewal SBC plans request', 'Renewal Dashboard', 'Success', val.renewalQuoteId, res, 'RL'));
                dispatch({
                    type: types.RECEIVE_RENEWAL_PLAN_CODE,
                    payload: res.data,
                    sbcPlansRateIdRequest,
                    source: 'renewal_plan_code',
                });
            })
            .catch((err) => {
                dispatch(createHistory('Renewal SBC plans request', 'Renewal Dashboard', 'Error', val.renewalQuoteId, err, 'RL'));
                dispatch({
                    type: types.ERR_RENEWAL_PLAN_CODE,
                    payload: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Sbc plans api Failed',
                });
            });
    };
}

export function updatedNumOfAllowedSelectedRenewPlansCount(count) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATED_ALLOWED_PLANS_COUNT',
            count,
        });

        dispatch({
            type: types.UPDATED_ALLOWED_PLANS_COUNT,
            payload: count,
        });
    };
}

export function updateShowMoreLess(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS,
            payload: showMoreLessArray,
        });
    };
}

export function reviewInitIsEligibleToFinalizeFromDecide(isEligibleBool) {
    return (dispatch) => {
        sendLog({
            action: 'REVIEW_INIT_IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE',
            isEligibleBool,
        });
        dispatch({
            type: types.REVIEW_INIT_IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE,
            value: isEligibleBool,
        });
    };
}

export function reviewInitUpdateBeenToFinalize(val) {
    return (dispatch) => {
        sendLog({
            action: 'REVIEW_INIT_UPDATE_BEEN_TO_FINALIZE',
            val,
        });
        dispatch({
            type: types.REVIEW_INIT_UPDATE_BEEN_TO_FINALIZE,
            payload: val,
        });
    };
}
export function addShoppingPlansToReview() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const shoppingPlans = fullProps.renewalShopping && fullProps.renewalShopping.selectedPlans && fullProps.renewalShopping.selectedPlans.length > 0 ?
            fullProps.renewalShopping.selectedPlans : [];
        sendLog({
            action: 'ADD_SHOPPING_PLANS_TO_REVIEW',
            shoppingPlans,
        });
        dispatch({
            type: types.ADD_SHOPPING_PLANS_TO_REVIEW,
            payload: shoppingPlans,
        });
    };
}
export function updateMedicalPlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_MEDICAL_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_MEDICAL_PLANS_TO_RENEW,
            payload: val,
        });
    };
}

export function updateDentalPlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_DENTAL_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_DENTAL_PLANS_TO_RENEW,
            payload: val,
        });
    };
}
export function updateLifePlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_LIFE_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_LIFE_PLANS_TO_RENEW,
            payload: val,
        });
    };
}

export function updateRenewalClassingInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_RENEWAL_CLASS_IN_REVIEW',
            val,
        });
        dispatch({
            type: types.ADD_RENEWAL_CLASS_IN_REVIEW,
            payload: val,
        });
    };
}

export function updateDependentLifePlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_DEPENDENT_LIFE_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_DEPENDENT_LIFE_PLANS_TO_RENEW,
            payload: val,
        });
    };
}
export function updateVisionPlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_VISION_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_VISION_PLANS_TO_RENEW,
            payload: val,
        });
    };
}

export function updateStdPlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_STD_PLANS_TO_RENEW',
            val
        });
        dispatch({
            type: types.ADD_STD_PLANS_TO_RENEW,
            payload: val
        })
    }
}
export function updateLTDPlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_LTD_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_LTD_PLANS_TO_RENEW,
            payload: val,
        });
    };
}

export function updateSuppLifePlansInReview(val) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_SUPP_LIFE_PLANS_TO_RENEW',
            val,
        });
        dispatch({
            type: types.ADD_SUPP_LIFE_PLANS_TO_RENEW,
            payload: val,
        });
    };
}

export function updateAlternatePlansInReview(val, alternatePlanCodes = [], medicalPlans = null) {
    return (dispatch) => {
        sendLog({
            action: 'ADD_ALTERNATE_PLANS_TO_RENEW',
            val,
        });

        if (medicalPlans) {
            let selectedPlans = medicalPlans.selectedPlans;
            if (selectedPlans && selectedPlans.length > 0) {
                for (let i = 0; i < val.length; i++) {
                    const isPlanSelected = selectedPlans.find(
                        (plan) => {
                            if (!plan.packageId || plan.packageId.toLowerCase() === 'single') {
                                return plan.code === val[i].code;
                            } else {
                                return plan.code === val[i].code && plan.packageId === val[i].packageId;
                            }
                        }
                    );
                    if (isPlanSelected) val[i].selected = true;
                    else val[i].selected = false;
                }
            }
        }

        dispatch({
            type: types.ADD_ALTERNATE_PLANS_TO_RENEW,
            payload: val,
            alternatePlanCodes
        });
    };
}

export function updateGroupTierStructureId(tierStructureId) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_GROUP_TIER_STRUCTURE_ID',
            tierStructureId,
        });
        dispatch({
            type: types.UPDATE_GROUP_TIER_STRUCTURE_ID,
            payload: tierStructureId
        })
    }
}

export function updateRenewalPlan(plan, field, pkg, callback, serviceType = 'FI', marketType) {
    return (dispatch) => {
        sendLog({
            action: `TOGGLE RENEWAL PLAN ${field.toUpperCase()}`,
            planCode: plan.codeDisplay,
        });
        dispatch(createHistory('Renewal - Update Plan', 'Review Action file', 'Success', { plan, field, pkg, callback, serviceType }, true, serviceType));
        dispatch({
            type: marketType === MARKET_TYPES.UHC && plan.planType === 'Medical' ? types.UPDATE_RENEWAL_PLAN_MEDICAL : types.SERVICE[serviceType].UPDATE_RENEWAL_PLAN,
            plan,
            field,
            pkg,
            isUhcRenewals: isUhcRenewals()
        });

        if (callback) {
            callback();
        }
    };
}

/** Get Current Case status */
export const getCurrentCaseStatus = () => async (dispatch, getState) => {

    const fullProps = getState();
    const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
    let FULL_URL;
    !isUhcRenewals() ?
        (
            FULL_URL = `${apiRoutes.quickQuotes}/currentCaseStatus?memberGroupId=${fullProps.review.val.customerNumber}&quoteTrackingNumber=${quoteTrackingNumber}`
        ) :
        (
            FULL_URL = `${apiRoutes.quickQuotes}/uhcRenewal/currentCaseStatusUhc?memberGroupId=${fullProps.review.val.customerNumber}&quoteTrackingNumber=${quoteTrackingNumber}`
        )
    const apiResponse = await axios.get(FULL_URL).catch(err => {
        if (isUhcRenewals()) {
            const reqBody = {
                memberGroupId: fullProps.review.val.customerNumber,
                quoteTrackingNumber
            }

            logError(KEY_NAME, UHC_REN_APIS.CURRENT_CASE_STATUS, 'reviewActions.getCurrentCaseStatus', reqBody, err);
        }
    })
    dispatch({
        type: types.CURRENT_CASE_STATUS,
        payload: (apiResponse && apiResponse.data && apiResponse.data.renewalCaseData) ? apiResponse.data.renewalCaseData : '',
    });
}

/** Update Case status */
export const updateCaseStatus = (req, cancelGroupAsIs = false, customerNumber, userIntiated = false, reviewDirect = false) => async (dispatch, getState) => {
    const fullProps = getState();

    const memberGroupId = cancelGroupAsIs ? customerNumber : fullProps.renewals.memberGroupId;

    // TO DO will remove this line once everything works fine.
    // let renewalPlan = fullProps.renewals.renewals.filter(plan => plan.customerNumber === memberGroupId)
    let currentCaseProgress;
    let renewalPlan;
    if (fullProps.renewals.globalSearchFlag) {
        currentCaseProgress = fullProps.renewals.renewalSearchCase.caseProgressUI;
    } else {
        renewalPlan = fullProps.renewals.renewals.find((plan) => plan.memberGroupId === memberGroupId);
        currentCaseProgress = renewalPlan ? renewalPlan.caseProgressUI : '';
    }
    if (
        currentCaseProgress === "" &&
        fullProps.caseTracking &&
        fullProps.caseTracking.caseTrack[0] &&
        fullProps.caseTracking.caseTrack[0].caseStatus !== ""
    ) {
        currentCaseProgress = fullProps.caseTracking.caseTrack[0].caseStatus;
    }

    const marketType = getMarketType();
    const isMMRCase = isMmr()

    const FULL_URL = `${apiRoutes.quickQuotes}/caseStatusChange?memberGroupId=${memberGroupId}&quoteTrackingNumber=${fullProps.caseTracking.currentQuoteId}&caseStatus=${req.caseStatus}` +
        `${!isEmpty(req.prevRenewalDataStatus) ? `&prevRenewalDataStatus=${req.prevRenewalDataStatus}` : ''}&marketType=${marketType}&isMMRCase=${isMMRCase}&userName=${fullProps.user.userName}`;

    if (userIntiated || (currentCaseProgress !== 'Accepted As Is' && currentCaseProgress !== 'Accepted' && currentCaseProgress !== 'Pending Cancellation' && currentCaseProgress !== 'Cancelled')) {
        const res = await axios.put(FULL_URL).catch(err => {
            if (isUhcRenewals()) {
                const reqBody = {
                    memberGroupId,
                    quoteTrackingNumber: fullProps.caseTracking?.currentQuoteId,
                    caseStatus: req.caseStatus,
                    prevRenewalDataStatus: req.prevRenewalDataStatus,
                    marketType,
                    isMMRCase,
                    userName: fullProps.user?.userName
                }
                logError(KEY_NAME, UHC_REN_APIS.CASE_STATUS_CHANGE, 'reviewActions.updateCaseStatus', reqBody, err);
            }
            throw err;
        })
        if (req.caseStatus === 'Accepted' || req.caseStatus === 'Accepted As Is') {
            console.log('caseStatusChange api call was made when caseStatus is accepted', { response: res, memberGroupId: memberGroupId });
        }

        if (fullProps.renewals.globalSearchFlag) {
            dispatch(updateRenewalSearchCase(req));
        }
        dispatch(updateRenewalCaseProgress(req));
        const { decide, review, renewals } = getState();
        const stepperAccessCopy = {
            ...renewals.stepperAccess,
            finalize: true
        }
        const renewalsCaseDataCopy = {
            ...renewals.renewalCaseData,
            caseProgressUI: req.caseStatus,
        };
        const decideCaseStatusCopy = {
            ...decide.renewalCaseData,
            caseProgressUI: req.caseStatus,
        };
        const reviewCaseStatusCopy = {
            ...review.renewalCaseData,
            caseProgressUI: req.caseStatus,
        };
        const renewalsQuery = {
            agentData: renewals.agentData,
            logInData: renewals.logInData,
            loginRequest: renewals.loginRequest,
            renewalCaseData: renewalsCaseDataCopy,
            renewals: renewals.renewals,
            renewalsRequest: renewals.renewalsRequest,
            salesRepData: renewals.salesRepData,
            stepperAccess: req.caseStatus === "Pending Acceptance" || req.caseStatus === "Accepted" ? stepperAccessCopy : renewals.stepperAccess,
        };
        const query = {
            agentData: review.agentData,
            // TO DO will remove this line once everything works fine.
            // isId: (review.agentData) && (review.agentData[0].brokerPcisId).toString(),
            memberGroupId: (review.renewalCaseData) && review.renewalCaseData.memberGroupId,
            renewalCaseData: reviewCaseStatusCopy,
            renewalData: (review.renewalData) && review.renewalData,
            salesRepData: review.salesRepData,
            dental: review.dental,
            vision: review.vision,
            life: review.life,
            suppLife: review.suppLife,
            shoppingPlans: review.shoppingPlans,
            stepperAccess: req.caseStatus === "Pending Acceptance" || req.caseStatus === "Accepted" ? stepperAccessCopy : renewals.stepperAccess,
        };
        const decideQuery = {
            agentData: decide.agentData,
            // TO DO will remove this line once everything works fine.
            // isId: (decide.agentData) && (decide.agentData[0].brokerPcisId).toString(),
            memberGroupId: (decide.renewalCaseData) && decide.renewalCaseData.memberGroupId,
            renewalCaseData: decideCaseStatusCopy,
            renewalData: decide.renewalData,
            salesRepData: decide.salesRepData,
            shoppingPlans: decide.shoppingPlans,
            stepperAccess: req.caseStatus === "Pending Acceptance" || req.caseStatus === "Accepted" ? stepperAccessCopy : renewals.stepperAccess,
        };
        dispatch(createHistory('Renewal CaseProgress update', '', 'Success', memberGroupId, `Update case progress to ${req.caseStatus}`, 'RL'));
        dispatch(updateCaseProgress(req.caseStatus));
        //if (!fullProps.renewals.globalSearchFlag) {
        // }
        dispatch({
            type: types.RECEIVE_SALESREP_DETAILS,
            payload: renewalsQuery,
        });
        dispatch(updateRenewalCaseStatus(req, memberGroupId));
        dispatch({
            type: types.UPDATE_CASE_STATUS,
            payload: req.caseStatus,
        });
        dispatch({
            type: types.RECEIVE_REVIEW_DETAILS,
            payload: query,
        });
        dispatch({
            type: types.RECEIVE_DECIDE_DETAILS,
            payload: decideQuery,
        });

        if (req.caseStatus === 'In Progress' && reviewDirect) {
            try {
                await reviewDetailMethod(fullProps)(dispatch, getState);
            } catch (err) {
                return;
            }
            history.push('/reviewDetail');
        }
    }
};

/** Adding case accepted date */
export function updateAcceptedDate(memberGroupId, date) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            type: types.UPDATE_RENEWALS_ACCEPTED_DATE,
            acceptedDate: date,
            memberGroupId,
        });
        const marketType = getMarketType()
        const URL = `${apiRoutes.quickQuotes}/saveAcceptedDate?memberGroupId=${memberGroupId}&acceptedDate=${date}&marketType=${marketType}`;
        axios.get(URL)
            .then((response) => {
                dispatch(createHistory('Renewal - Save Accepted Date', 'Enrollment', 'Success', { memberGroupId, date }, response, 'RL'));
                resolve(response);
            })
            .catch((err) => {
                dispatch(createHistory('Renewal - Save Accepted Date', 'Enrollment', 'Error', { memberGroupId, date }, err, 'RL'));
                reject(err);
            });
    });
}

/** Adding case submitted date */
export function updateSubmittedDate(memberGroupId, date) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch({
            type: types.UPDATE_RENEWALS_SUBMITTED_DATE,
            submittedDate: date,
            memberGroupId,
        });
        const marketType = getMarketType()
        const URL = `${apiRoutes.quickQuotes}/saveSubmittedDate?memberGroupId=${memberGroupId}&submittedDate=${date}&marketType=${marketType}`;
        axios.get(URL)
            .then((response) => {
                dispatch(createHistory('Renewal - Save Case Submitted Date', 'Enrollment', 'Success', { memberGroupId, date }, response, 'RL'));
                resolve(response);
            })
            .catch((err) => {
                dispatch(createHistory('Renewal - Save Case Submitted Date', 'Enrollment', 'Error', { memberGroupId, date }, err, 'RL'));
                reject(err);
            });
    });
}

/** Adding Submitter Data */
export function updateSubmitterData(query) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const fullProps = getState();
        const caseId = fullProps.caseTracking && fullProps.caseTracking.caseId ? fullProps.caseTracking.caseId : '';
        const currentQuoteId = fullProps.caseTracking && fullProps.caseTracking.currentQuoteId ? fullProps.caseTracking.currentQuoteId : '';
        dispatch({
            type: types.UPDATE_RENEWALS_SUBMITTER_DATA,
            req: query
        });
        const objVal = { caseId, currentQuoteId, userName: fullProps.user.userName, roleName: fullProps.renewals.roleName, submittedDate: new Date().toISOString(), submitterId: fullProps.renewals.logInData[0].optumId, submitterEmail: fullProps.renewals.emailAddress };
        const URL = `${apiRoutes.quickQuotes}/saveSubmitterDetails?caseId=${caseId}&quoteTrackingNumber=${currentQuoteId}&submitterName=${fullProps.user.userName}&submitterRole=${fullProps.renewals.roleName}&submittedDate=${new Date().toISOString()}&submitterId=${fullProps.renewals.logInData[0].optumId}&submitterEmail=${fullProps.renewals.emailAddress}`;
        axios.get(URL)
            .then((response) => {
                dispatch(createHistory('Renewal - Save Case Submitter Details', 'Enrollment', 'Success', objVal, response, 'RL'));
                resolve(response);
            })
            .catch((err) => {
                dispatch(createHistory('Renewal - Save Case Submitter Details', 'Enrollment', 'Error', objVal, err, 'RL'));
                reject(err);
            });
    });
}

// TODO - Seems we r not using this method check and need to remove this
export function saveOrUpdateCaseTracking() {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const fullProps = getState();
        const { companyProfile } = fullProps;
        const renewals = fullProps.review.val;
        dispatch({ type: types.CASE_TRACK_UPDATING });
        const caseId = companyProfile.caseId ? companyProfile.caseId : '';
        const caseObject = `{
            "caseId": "${caseId}",
            "quoteTrackingNumber": "",
            "swRateId": "",
            "renewalCaseId":"${renewals.customerNumber}", 
            "renewalQuoteId":"${renewals.renewalQuoteId}", 
            "businessType": "RL",
            "source": "BNE",
            "userId": "${fullProps.user.userName}",
            "createdBy": "${fullProps.user.userName}",
            "lastModifiedBy": "${fullProps.user.userName}",
            "createdDate": "",
            "lastUpdatedDate": "",
            "lastUpdatedPage": "Review",
            "caseStatus": "inProgress",
            "caseData": "{}",
            "quotes": []
        }`;
        if (caseId !== '' && companyProfile.caseTrack) {
            axios.put(`${apiRoutes.mongo}/caseTrack/${companyProfile.caseTrack._id}`, JSON.parse(caseObject))
                .then(
                    (result) => {
                        dispatch({
                            type: types.UPDATED_CASE_TRACK,
                            companyProfile: {
                                ...companyProfile,
                                caseId: result.data ? caseId : result.data.updatedCaseTrack.caseId,
                                caseTrack: result.data ? fullProps.companyProfile.caseTrack : result.data.updatedCaseTrack,
                            },
                        });
                        resolve(result.data ? fullProps.companyProfile.caseTrack._id : result.data.updatedCaseTrack._id);
                    },
                ).catch((err) => {
                    console.log('Error', err);
                    dispatch({ type: types.CASE_TRACK_UPDATE_ERRORED, error: err });
                    reject();
                });
        } else {
            axios.post(`${apiRoutes.mongo}/caseTrack`, JSON.parse(caseObject))
                .then(
                    (result) => {
                        dispatch({
                            type: types.UPDATED_CASE_TRACK,
                            companyProfile: {
                                ...companyProfile,
                                caseId: result.data.insertedCaseTrack.caseId,
                                caseTrack: result.data.insertedCaseTrack,
                            },

                        });
                        resolve(result.data.insertedCaseTrack._id);
                    },
                ).catch((err) => {
                    console.log('Error', err);
                    dispatch({ type: types.CASE_TRACK_UPDATE_ERRORED, error: err });
                    reject();
                });
        }
    });
}

export function resetReviewFlag(resetVal) {
    return (dispatch) => {
        sendLog({
            action: 'RESET_REVIEW_FLAG',
        });

        dispatch({
            type: types.RESET_REVIEW_FLAG,
            payload: resetVal,
        });
    };
}
export function updateShowMoreLessMedical(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_MEDICAL',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_MEDICAL,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessDental(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_DENTAL',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_DENTAL,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessVision(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_VISION',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_VISION,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessLife(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_LIFE',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_LIFE,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessSuppLife(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_SUPP_LIFE',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_SUPP_LIFE,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessStd(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_STD',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_STD,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessLTD(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_LTD',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_LTD,
            payload: showMoreLessArray,
        });
    };
}

export function updateShowMoreLessDepLife(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_DEP_LIFE',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_DEP_LIFE,
            payload: showMoreLessArray,
        });
    };
}
export function updateShowMoreLessSuppDepLife(showMoreLessArray) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SHOW_MORE_LESS_SUPP_DEP_LIFE',
            showMoreLessArray,
        });
        dispatch({
            type: types.UPDATE_SHOW_MORE_LESS_SUPP_DEP_LIFE,
            payload: showMoreLessArray,
        });
    };
}

export function renewalStepperFlagChange(val) {
    return (dispatch) => {
        dispatch({
            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
            payload: val,
        });
    };
}

export function resetStepperFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
            payload: val,
        });
    };
}
export function emptyDVLreducerReviewPage() {
    return (dispatch) => {
        dispatch({
            type: types.RESET_FLOW_DVL_REVIEW_PAGE,
        });
    };
}

export function saveAuditStatusFailedCase(id, payload, marketType) {
    return (dispatch) => new Promise((resolve, reject) => {
        const URL = marketType && marketType.toUpperCase() === "UHC" ? `${apiRoutes.mongo}/uhcRenewalDb/save-group-audit-status-openOrPending-uhc/${id}`
            : `${apiRoutes.mongo}/save-group-audit-status-openOrPending/${id}`
        axios.post(URL, payload)
            .then((response) => {
                dispatch(createHistory('Renewal - Save Group Audit Status', 'Review', 'Success', payload, response, 'RL'));
                resolve(response);
            })
            .catch((err) => {
                dispatch(createHistory('Renewal - Save Group Audit Status', 'Review', 'Error', payload, err, 'RL'));
                reject(err);
            });
    });
}

export function updateAuditFlag(auditFlag, noChange = "", currentQuoteId = '') {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_AUDIT_STATUS_FLAG,
            payload: auditFlag,
        });
        if (noChange === 'true') {
            const payload = { auditStatus: { status: 'Submission Hold for Audit' }, businessType: 'RENEWAL' }
            const marketType = getMarketType();
            dispatch(saveAuditStatusFailedCase(currentQuoteId, payload, marketType));
            return;
        }
    };
}

export function updateDisableFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.RENEWAL_BTN_DISABLE_FLAG,
            payload: val,
        });
    };
}

export function updateRenewalHWCheck(val) {
    return (dispatch) => {
        dispatch({
            type: types.RENEWAL_HW_DISABLE,
            payload: val,
        });
    };
}

const formatSalesRepData = (props, store) => (dispatch) => {
    const fullProps = store;
    if ((props.salesRepData && props.salesRepData.length > 0) || props.renewalCaseData.salesRepData) {
        const dataForSalesRep = fullProps.reviewCards.ownerAssignment.salesRep;
        const identifiedPlatform = fullProps.overall.platform ? fullProps.overall.platform.toUpperCase() : "CIRRUS"
        let salesRepExists = false;
        let salesRepData = props.salesRepData.length > 0 ? props.salesRepData : props.renewalCaseData.salesRepData
        salesRepData.map((data) => {
            dataForSalesRep.map((existingData) => {
                if (existingData.PCIS_ID == data.assignedAccountManagerProducerId) {
                    salesRepExists = true;
                }
            });
            if (salesRepExists === false) {
                const salesRepData = {
                    // salesRepName: `${data.assignedAccountManagerFN} ${data.assignedAccountManagerLN}`,
                    salesRepName: (data.assignedAccountManagerFN != undefined ? data.assignedAccountManagerFN : '') + ' ' + (data.assignedAccountManagerLN != undefined ? data.assignedAccountManagerLN : ''),
                    streetAddress: '-',
                    city: '-',
                    state: '-',
                    zipCode: '-',
                    PCIS_ID: data.assignedAccountManagerProducerId,
                    payeeCode: null,
                    isAssignedofsalesRep: true,
                    salesRepNameFN: data.assignedAccountManagerFN,
                    salesRepNameLN: data.assignedAccountManagerLN,
                    platform: identifiedPlatform
                };
                dispatch(addSalesRepData(salesRepData));
                salesRepExists = false;
            }
        });
    }
};

const formatGaSalesRepData = (props, store) => (dispatch) => {
    const fullProps = store;
    if (props.gaSalesRepData) {
        const dataForGaSalesRep = fullProps.reviewCards.ownerAssignment.gaSalesRep;
        let gaSalesRepExists = false;
        props.gaSalesRepData.map((data) => {
            dataForGaSalesRep.map((existingData) => {
                if (existingData.PCIS_ID == data.generalAgentPCISCode) {
                    gaSalesRepExists = true;
                }
            });
            if (gaSalesRepExists === false) {
                const gaSalesRepData = {
                    salesRepName: (data.salesRepFirstName != undefined ? data.salesRepFirstName : '') + ' ' + (data.salesRepLastName != undefined ? data.salesRepLastName : ''),
                    streetAddress: '-',
                    city: '-',
                    state: '-',
                    zipCode: '-',
                    PCIS_ID: data.generalAgentPCISCode,
                    payeeCode: null,
                    isAssignedofsalesRep: true,
                    salesRepNameFN: data.salesRepFirstName,
                    salesRepNameLN: data.salesRepLastName
                };
                dispatch(addGASalesRepData(gaSalesRepData));
                gaSalesRepExists = false;
            }
        });
    }
};
const addReviewCardsDetails = (store, memberGroupId) => async (dispatch) => {
    const fullProps = store
    let agentDataIndex = []
    let uhcPlatformCode = fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.uhcPlatformCode
    if (isUhcRenewals() && uhcPlatformCode === "U") {
        fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.map(memberGroup => {
            if (memberGroup.memberGroupContractId == fullProps.renewals.renewalCaseData.memberGroupContractId) {
                agentDataIndex = memberGroup;
            }
        })
        // agentDataIndex = fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData
        // && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.length> 0 ? fullProps.renewals.renewalCaseData.agentData.memberGroupContractList[0]: [];
    }
    else {
        fullProps.renewals && fullProps.renewals.renewalCaseData && fullProps.renewals.renewalCaseData.agentData && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList && fullProps.renewals.renewalCaseData.agentData.memberGroupContractList.map(memberGroup => {
            if (isUhcRenewals()) {
                if (fullProps.renewals.renewalCaseData.primePolicyNumber.includes(memberGroup.memberGroupContractId)) {
                    agentDataIndex = memberGroup;
                } else if (memberGroup.memberGroupContractId == fullProps.renewals.renewalCaseData.memberGroupContractId) {
                    agentDataIndex = memberGroup;
                }
            } else {
                if (memberGroup.memberGroupContractId == fullProps.renewals.renewalData.memberGroup.memberGroupContractId) {
                    agentDataIndex = memberGroup;
                }
            }
        })
    }
    if (agentDataIndex.producerCompensationList && agentDataIndex.producerCompensationList.length > 0) {
        for (let j = 0; j < agentDataIndex.producerCompensationList.length; j++) {
            if (agentDataIndex.producerCompensationList[j].producerList && agentDataIndex.producerCompensationList[j].producerList.length > 0) {
                let producerData;
                let maxCommission = 0;
                let isProducer = false;
                let finalProducerData = [];
                let finalAgencyData = [];
                let finalGeneralAgency = [];

                // agentDataIndex.producerCompensationList[0].producerList.map((producer) => {
                for (let k = 0; k < agentDataIndex.producerCompensationList[j].producerList.length > 0; k++) {
                    let producer = agentDataIndex.producerCompensationList[j].producerList[k];
                    if (producer.producerType === "1" || producer.producerType === "3") {
                        if (producer.producerExternalId != 999999 && producer.writingAgentExternalId != 999999) {
                            let isExpired = false;
                            if (producer.expirationDate) {
                                //let noOfDays = findDaysTillEffDate(producer.expirationDate)
                                //if (noOfDays < 0) {
                                isExpired = true;
                                //}
                            }

                            isProducer = true;
                            // if (producer.pctOfCommission > maxCommission) {
                            //     maxCommission = producer.pctOfCommission;
                            if (!isExpired) {
                                const pcisId = producer.writingAgentExternalId ? producer.writingAgentExternalId : '';
                                let producerInfo = pcisId ? await axios.get(`${apiRoutes.quickQuotes}/searchOwnerDataByPCIS?pcis=${pcisId}&ownerType=producer&workflow=renewal`) : null;
                                let prodcer = producerInfo ? producerInfo?.data?.owner && producerInfo.data.owner[0] : {};
                                producerData = {
                                    producerName: producer.writingAgentName,
                                    producerPhone: producer.writingAgentPhoneNumber
                                        ? producer.writingAgentPhoneNumber
                                        : "N/A",
                                    PCIS_ID: producer.writingAgentExternalId
                                        ? producer.writingAgentExternalId
                                        : "N/A",
                                    pctOfCommission: producer.pctOfCommission
                                        ? producer.pctOfCommission
                                        : 0,
                                    city: prodcer.city ? prodcer.city : "",
                                    state: prodcer.state ? prodcer.state : "",
                                    email: prodcer.email ? prodcer.email : "",
                                    streetAddress: prodcer.streetAddress ? prodcer.streetAddress : "",
                                    zipCode: prodcer.zipCode ? prodcer.zipCode.substring(0, 5) : ""
                                };
                                finalProducerData.push(producerData);
                                finalProducerData.sort((a, b) =>
                                    a.pctOfCommission < b.pctOfCommission ? 1 : -1
                                );

                                if (producer.producerType === "1") {
                                    const agencyData = {
                                        agencyName: producer.producerName,
                                        PCIS_ID: producer.writingAgentExternalId
                                            ? producer.writingAgentExternalId
                                            : "N/A",
                                        pctOfCommission: producer.pctOfCommission
                                            ? producer.pctOfCommission
                                            : 0,
                                    };
                                    finalAgencyData.push(agencyData);
                                    finalAgencyData.sort((a, b) =>
                                        a.pctOfCommission < b.pctOfCommission
                                            ? 1
                                            : -1
                                    );
                                } else {
                                    const agencyData = {
                                        agencyName: "-",
                                        PCIS_ID: producer.writingAgentExternalId
                                            ? producer.writingAgentExternalId
                                            : "N/A",
                                        pctOfCommission: producer.pctOfCommission
                                            ? producer.pctOfCommission
                                            : 0,
                                    };
                                    finalAgencyData.push(agencyData);
                                    finalAgencyData.sort((a, b) =>
                                        a.pctOfCommission < b.pctOfCommission
                                            ? 1
                                            : -1
                                    );
                                }
                            }
                        }
                    }
                    if (producer.producerType === "2") {
                        let isExpired = false
                        if (producer.expirationDate) {
                            //let noOfDays = findDaysTillEffDate(producer.expirationDate)
                            //if (noOfDays < 0) {
                            isExpired = true
                            //}
                        }
                        if (!isExpired) {
                            const pcisId = producer.writingAgentExternalId ? producer.writingAgentExternalId : '';
                            let generalAgentInfo = pcisId ? await axios.get(`${apiRoutes.quickQuotes}/searchOwnerDataByPCIS?pcis=${pcisId}&ownerType=generalagent&workflow=renewal`) : null;
                            let agentInfo = generalAgentInfo ? generalAgentInfo?.data?.owner && generalAgentInfo.data.owner[0] : {};
                            const generalAgentData = {
                                generalAgentName: producer.writingAgentName,
                                PCIS_ID: producer.writingAgentExternalId ? producer.writingAgentExternalId : 'N/A',
                                pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0,
                                gaEmail: agentInfo && agentInfo.gaEmail || ''
                            };
                            finalGeneralAgency.push(generalAgentData)
                            finalGeneralAgency.sort((a, b) => (a.pctOfCommission < b.pctOfCommission) ? 1 : -1)
                            if (fullProps.reviewCards.ownerAssignment.gaSalesRep.length == 0 && finalGeneralAgency.length > 0) {
                                let resp = await axios.get(`${apiRoutes.quickQuotes}/getGaSalesRep?memberGroupId=${memberGroupId}&pcisId=${generalAgentData.PCIS_ID}`);
                                if (resp.data.gaSalesRepData && resp.data.gaSalesRepData.length > 0 && resp.data.gaSalesRepData[0].salesRepFirstName && resp.data.gaSalesRepData[0].salesRepLastName) {
                                    const gaSalesRepData = {
                                        salesRepName: (resp.data.gaSalesRepData[0].salesRepFirstName ? resp.data.gaSalesRepData[0].salesRepFirstName : '') + ' ' + (resp.data.gaSalesRepData[0].salesRepLastName ? resp.data.gaSalesRepData[0].salesRepLastName : ''),
                                        streetAddress: '-',
                                        city: '-',
                                        state: '-',
                                        zipCode: '-',
                                        PCIS_ID: resp.data.gaSalesRepData[0].generalAgentPCISCode,
                                        salesRepNameFN: resp.data.gaSalesRepData[0].salesRepFirstName,
                                        salesRepNameLN: resp.data.gaSalesRepData[0].salesRepLastName
                                    };
                                    dispatch(addGASalesRepData(gaSalesRepData));

                                }
                                // })
                            }
                        }
                        //   dispatch(addGeneralAgentData(generalAgentData));
                    }
                    // if (producer.producerType == 3) {
                    //     const agencyData = {
                    //         agencyName: producer.writingAgentName,
                    //     };
                    //     dispatch(addAgencyData(agencyData));
                    // }
                    // });
                }
                finalProducerData.map(producer => {
                    producerData = {
                        producerName: producer.producerName,
                        producerPhone: producer.producerPhone ? producer.producerPhone : 'N/A',
                        PCIS_ID: producer.PCIS_ID ? producer.PCIS_ID : 'N/A',
                        pctOfCommission: producer.pctOfCommission ? producer.pctOfCommission : 0,
                        city: producer.city ? producer.city : "",
                        state: producer.state ? producer.state : "",
                        email: producer.email ? producer.email : "",
                        streetAddress: producer.streetAddress ? producer.streetAddress : "",
                        zipCode: producer.zipCode ? producer.zipCode : ""

                    };
                    dispatch(addOwnerAssignmentData(producerData));

                })
                finalAgencyData.map(agency => {
                    const agencyData = {
                        agencyName: agency.agencyName,
                        PCIS_ID: agency.PCIS_ID ? agency.PCIS_ID : 'N/A',
                        pctOfCommission: agency.pctOfCommission ? agency.pctOfCommission : 0

                    };
                    dispatch(addAgencyData(agencyData));

                })
                finalGeneralAgency.map(agent => {
                    const generalAgentData = {
                        generalAgentName: agent.generalAgentName,
                        PCIS_ID: agent.PCIS_ID ? agent.PCIS_ID : 'N/A',
                        pctOfCommission: agent.pctOfCommission ? agent.pctOfCommission : 0,
                        gaEmail: agent.gaEmail ? agent.gaEmail : ''
                    }
                    dispatch(addGeneralAgentData(generalAgentData));
                })
                if (isUhcRenewals()) {
                    const reqbody = {
                        memberGroupId: fullProps.renewals.renewalCaseData.memberGroupId,
                        quoteTrackingNumber: fullProps.caseTracking.currentQuoteId,
                        finalAgencyData,
                        finalGeneralAgency,
                        finalProducerData,
                        priorYearDataSelected: fullProps.renewals.priorYearDataSelected,
                        effectiveDate: fullProps.renewals.renewalCaseData.effectiveDate
                    }
                    await axios.post(`${apiRoutes.quickQuotes}/storeOwnerdata`, reqbody);
                }
                // if (isProducer) {
                //     dispatch(addOwnerAssignmentData(producerData));
                // }
            }
        }
    }

};

export function updateRenewalParentPlanCode(planType, planCode, parentPlanCode) {
    return (dispatch) => {
        dispatch({
            type: types[`UPDATE_RENEWAL_PARENT_PLAN_CODE_${planType.toUpperCase()}`],
            planCode,
            parentPlanCode,
        });
    };
}

export function adjustRenewalsRatesAndPremiumReview(serviceType, premium, rates, adjustments, planCode,isAllowed = true, isAgeBanded = false) {
    return (dispatch, getState) => {
        const fullProps = getState();

        sendLog({
            action: 'ADJUST RENEWALS RATES AND PREMIUMS REVIEW',
        });

        dispatch({
            type: types.SERVICE[serviceType].ADJUST_RENEWALS_RATE_REVIEW,
            premium,
            rates,
            adjustments,
            planCode,
            serviceType,
            isUhcRenewals: isUhcRenewals(),
            isMMRCase: isMmr(),
            isAllowed,
            groupSize: fullProps.companyProfile.groupSize,
            isAgeBanded
        });
        dispatch({
            type: types.UHC_RENEWALS_UPDATE_USER_ADJUST_FLAG,
            product: serviceType.toLowerCase()
        })
    };
}