import {
  validatePartialPhone,
  isDigitsOnly,
  isPartialZip4,
  isZip4,
  validateSSN,
  validatePartialSSN,
  validateFullSSN,
  hasNoSpecialCharacter,
  validateTotalCommission,
  atLeastOneWiritingAgent,
  validateSpecifiedDuration,
  isLettersOnly,
  isFranchiseCode,
  hasNoInvalidCharactersOnGroupName,
  hasNoSpecialCharacterButPeriod,
  isLettersandSpecialCharacterWithSpace,
} from "../../../../utils/validation/regex";
import {
  validateDateString,
  isValidDate,
} from "../../../../utils/formatters/strings";
import {
  getActiveDatesForUHC,
  toMMDDYYYYFormatSlash,
  MMDDYYYYtoIsoString,
} from "../../../../utils/formatters/dates";
import validatePhoneNumber from "../../../../utils/validation/phone";
import { validateEmail } from "../../../../utils/validation/emailRegex";
import {
  validateMoney,
  isValidCurrency,
  getAge,
} from "../../../../utils/formatters/numbers";
import { getPrimaryLocationStateCode } from "../../../activities/FullShopping/Census/helpers/checkPrimaryLocation";
import { isOneLife } from "../../../../utils/businessLogic/oneLifeChecker";
import { isGuaranteedRule } from "../../../../utils/businessLogic/guaranteedPeriod";
import moment from "moment";
import { checkIfLifePlanIsNonContributory } from "./conditionCheckers";
import {getNBCaseProgress, CASE_PROGRESS} from '../../../../reducers/products/helpers/companyProfileConsts';
const getValidEffectiveDates = (role, uhcStateConfig, roleName) => {
  return getActiveDatesForUHC(true, role, uhcStateConfig, roleName);
};
//const validEffectiveDates = getActiveDates('firstAndFifteenth', true);

/**
 *
 * @param {string} value the value that was entered into the textbox
 * @param {array} validations list of validation objects from the configurable field -- see configurableFormPropTypes for a definition of "validations"
 *
 * @returns object with keys 'value' and 'error' - if 'error' is not null, ignore 'value' and prevent an update. else, update field to show 'value'
 */

// let cobraStartDate = '', cobraEndDate = ''
// const checkCobraDate = (errorStrings, cobraStartDate='', cobraEndDate='') => {

//     if(new Date(cobraStartDate.toString()) > new Date(cobraEndDate.toString())) {
//         errorStrings.push('Please input a valid Date Range');
//         return;
//     }
// }

export const checkValidationsOnInput = (
  value,
  validations,
  fullProps = {},
  selectedProducts = {},
  dependentInfo = {},
  oldValue
) => {
  const dob =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.employee &&
    fullProps.enrollment.application.employee.employeeInfo &&
    fullProps.enrollment.application.employee.employeeInfo.dob
      ? fullProps.enrollment.application.employee.employeeInfo.dob
      : "";
  const cobraStartDate =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.employee &&
    fullProps.enrollment.application.employee.employeeInfo &&
    fullProps.enrollment.application.employee.employeeInfo.cobraStartDate
      ? fullProps.enrollment.application.employee.employeeInfo.cobraStartDate
      : "";
  const bnePlatform =
    fullProps.overall &&
    fullProps.overall.platform &&
    fullProps.overall.platform === "PRIME"
      ? "PRIME"
      : "CIRRUS";
  const currentCarrierEffectiveDate =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.eligibility &&
    fullProps.enrollment.application.eligibility.currentCarrierInformation &&
    fullProps.enrollment.application.eligibility.currentCarrierInformation
      .currentCarrierEffectiveDate
      ? fullProps.enrollment.application.eligibility.currentCarrierInformation
          .currentCarrierEffectiveDate
      : "";
  const effectiveDate =
    fullProps.companyProfile && fullProps.companyProfile.effectiveDate
      ? fullProps.companyProfile.effectiveDate
      : "";
  const { fteRule } = fullProps.companyProfile.locations[0];
  const enrollmentRules = fullProps.rules ? fullProps.rules.enrollment : null;
  const stateCode = getPrimaryLocationStateCode(
    fullProps.companyProfile.locations
  );
  const roleName = fullProps.renewals.roleName;
  const currentCarrierCoverageBeginDate = 
  fullProps.enrollment &&
  fullProps.enrollment.application &&
  fullProps.enrollment.application.eligibility &&
  fullProps.enrollment.application.eligibility.currentCarrierInformation &&
  fullProps.enrollment.application.eligibility.currentCarrierInformation
    .coverageBeginDate
    ? fullProps.enrollment.application.eligibility.currentCarrierInformation
      .coverageBeginDate
    :"";

  const currentCarrierCoverageEndDate = 
  fullProps.enrollment &&
  fullProps.enrollment.application &&
  fullProps.enrollment.application.eligibility &&
  fullProps.enrollment.application.eligibility.currentCarrierInformation &&
  fullProps.enrollment.application.eligibility.currentCarrierInformation
    .coverageEndDate
    ? fullProps.enrollment.application.eligibility.currentCarrierInformation
      .coverageEndDate
    :"";
  const isDesQuote = fullProps && fullProps.overall && fullProps.overall.isDesQuote;

  const classingOpted = fullProps.life.employeeClassing.classingOpted;
  const enrolledLifePlans = fullProps.rules.enrollment.enrolledLifePlans;

  if (value === "") {
    return {
      value,
      error: null,
    };
  }
  const errorStrings = [];
  let modifiedValue = value;

  validations.forEach((validation) => {
    switch (validation.validationType) {
      case "maxLength": {
        if (value && value.length > validation.value) {
          errorStrings.push(`Maximum length of ${validation.value} characters`);
        }
        break;
      }
      case "minLength": {
        if (value.length < validation.value) {
          errorStrings.push(`Minimum length of ${validation.value} characters`);
        }
        break;
      }
      case "exactLength": {
        if (value.length !== validation.value) {
          errorStrings.push(
            `Must have length of exactly ${validation.value} characters`
          );
        }
        break;
      }
      case "digitsOnly": {
        if (!isDigitsOnly(value)) {
          errorStrings.push("Must be digits only");
        }
        break;
      }
      case "lettersOnly": {
        if (!isDesQuote && !isLettersOnly(value)) {
          errorStrings.push("Must be letters only");
        }
        break;
      }
      case "lettersandSpecialCharacterWithSpace": {
        if (!isDesQuote && !isLettersandSpecialCharacterWithSpace(value)) {
          errorStrings.push("Must be letters or '/' or '-'");
        } 
        break;  
      }
      case "max":
        if (parseFloat(value) > validation.value) {
          errorStrings.push(`Maximum of ${validation.value}`);
        }
        break;
      case "inputRegex": {
        const regex = new RegExp(validation.value);
        if (!regex.test(value)) {
          errorStrings.push(validation.regexDescription);
        }
        break;
      }
      case "zip4":
        if (!isPartialZip4(value)) {
          errorStrings.push("Must enter 4 digits");
        }
        break;
      case "dob":
        if (new Date(value) > new Date()) {
          errorStrings.push("Must not be a future date");
        }
        break;
      case "validDOB":
        if (getAge(new Date(value)) < 14 || getAge(new Date(value)) > 115) {
          errorStrings.push("Must be between 14 - 115 years");
        }
        break;
      case "validSpouseDOB":
        const minAgeSpouse = bnePlatform === "PRIME" ? 16 : 14;

        if (
          dependentInfo.relationship &&
          (dependentInfo.relationship === "Spouse" ||
            dependentInfo.relationship === "Civil Union Partner" ||
            dependentInfo.relationship === "Domestic Partner" ||
            dependentInfo.relationship === "Life Partner")
        ) {
          if (
            getAge(new Date(value)) < minAgeSpouse ||
            getAge(new Date(value)) > 115
          ) {
            errorStrings.push(`Must be between ${minAgeSpouse} - 115 years`);
          }
        }
        break;
      case "validChildDOB":
        if (
          dependentInfo.relationship && dependentInfo.relationship === "Child"
        ) {
          if (getAge(new Date(value)) > (validation.value || 26)) {
            errorStrings.push(`Must be less than ${validation.value} years`);
          }
        }
        break;
      case "date":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
        }
        break;
      case "effectiveDate": {
        modifiedValue = validateDateString(value);
        if (modifiedValue === null) {
          errorStrings.push("Please enter a valid date");
        }
        break;
      }
      case "validDateOfHire": {
        if (dob !== "") {
          if (moment(value) <= moment(dob)) {
            errorStrings.push("Must be greater than Date of Birth");
          }
          let dateOfHireValue = MMDDYYYYtoIsoString(value);
          if (moment(value) > moment(effectiveDate)) {
            errorStrings.push("Date of Hire must not be after effective date");
          }
        }
        break;
      }
      case "validCobraEndDate":
        {
          modifiedValue = validateDateString(value);
          if (modifiedValue === null) {
            errorStrings.push("Please enter a valid date");
            break;
          }
          if (cobraStartDate !== "") {
            if (new Date(value) <= new Date(cobraStartDate)) {
              errorStrings.push("Must be after COBRA Start Date");
            }
          }
        }
        break;
      case "validCurrentCarrierEffectiveDate":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
          break;
        }
        break;
      case "validCurrentCarrierTerminationDate":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
          break;
        }
        if (currentCarrierEffectiveDate !== "") {
          if (new Date(value) <= new Date(currentCarrierEffectiveDate)) {
            errorStrings.push("Must be after Effective Date");
          }
        }
        break;
        case "validCoverageBeginDate":
        if(currentCarrierCoverageEndDate!==""){
          if(new Date(value) > new Date(currentCarrierCoverageEndDate)){
              errorStrings.push("Cannot be After the Coverage End Date")
            }
        }
        break;
        case "validCoverageEndDate":
          if(currentCarrierCoverageBeginDate!==""){
            if(new Date(value) < new Date(currentCarrierCoverageBeginDate)){
                errorStrings.push("Cannot be before the Coverage Begin Date")
              }
          }
          break;
      case "phone":
        modifiedValue = validatePartialPhone(value);
        if (modifiedValue === null) {
          errorStrings.push("Please enter a valid phone number");
        }
        break;
      case "ssn":
        modifiedValue = validatePartialSSN(value);
        if (modifiedValue === null) {
          errorStrings.push("Please enter a valid SSN / ITIN");
        }
        break;
      case "salary":
        if (validateMoney(value) === null) {
          errorStrings.push("Please enter a valid dollar amount");
        }
        break;
      case "fullSsn":
        modifiedValue = validateFullSSN(value);
        if (!modifiedValue) {
          errorStrings.push("Please enter a valid SSN / ITIN (123-45-6789)");
        }
        break;
      case "noSpecialCharacter":
        if (!hasNoSpecialCharacter(value)) {
          errorStrings.push("No Special Character");
        }
        break;
      case "atLeastOneWiritingAgent":
        const atLeastOne = atLeastOneWiritingAgent(value, fullProps);
        if (!atLeastOne) {
          errorStrings.push("Please keep at least one writing agent");
        }
        break;
      case "validSpecifiedDuration":
        const isValid = validateSpecifiedDuration(
          value,
          validation.type,
          fullProps
        );
        if (!isValid) {
          errorStrings.push(validation.errorMessage);
        }
        break;
      // case 'validCobraStartDate':
      //     cobraStartDate = value
      //     checkCobraDate(errorStrings, cobraStartDate, cobraEndDate)
      //     break;
      // case 'validCobraEndDate':
      //     cobraEndDate = value
      //     checkCobraDate(errorStrings, cobraStartDate, cobraEndDate)
      //     break;
      case "isDisabled":
        const adultDependent = fullProps.enrollment.application.eligibility.participation.adultDependent
        const effectiveMaxAge = adultDependent === 'Yes' ? "29" : fullProps.companyProfile.dependentRules.maxAge;
        if (bnePlatform === "PRIME") {
          if (
            (value === "No" || (adultDependent && adultDependent === 'No')) &&
            (dependentInfo.relationship === "Child" ||
              dependentInfo.relationship === "Dependent Child") &&
            fullProps.companyProfile.dependentRules &&
            dependentInfo.age >=
              parseInt(effectiveMaxAge)
          ) {
            errorStrings.push(
              "This dependent exceeds the age limit per federal/state guidelines and will not be eligible for coverage, please remove dependent."
            );
          }
        }
        if (bnePlatform === "CIRRUS") {
          let maxAge = parseInt(
            fullProps.companyProfile.dependentRules?.maxAge || "26"
          );
          let enrolledPlans =
            enrollmentRules &&
            enrollmentRules.enrolledPlans &&
            enrollmentRules.enrolledPlans.standalone;
          if (enrolledPlans && enrolledPlans.length > 0 && stateCode === "NY") {
            for (let i = 0; i < enrolledPlans.length; i++) {
              if (enrolledPlans[i] && enrolledPlans[i].riderAge29) {
                maxAge = 30;
                break;
              }
            }
          }
          if (
            value === "No" &&
            (dependentInfo.relationship === "Child" ||
              dependentInfo.relationship === "Dependent Child") &&
            fullProps.companyProfile.dependentRules &&
            dependentInfo.age >= maxAge
          ) {
            errorStrings.push(
              "You have entered a dependent child that exceeds the state max age on the census and must be disabled for coverage. Please validate the census and continue"
            );
          }
        }
        break;
      case "SC_NC_MedicalContribution":
        let totalEligEmps;
        // Specific rule for NC and SC states
        const { primaryLocationIdx, locations } = fullProps.companyProfile;
        const { enrollment: { application: { eligibility } } } = fullProps;
        const { totalEligEmps: locationEligEmps } = locations[primaryLocationIdx];
        const participationEligEmps = eligibility && eligibility.participation && eligibility.participation.numEligibleEmployees ? eligibility.participation.numEligibleEmployees : '';
        totalEligEmps = participationEligEmps || locationEligEmps;
        const regex = new RegExp("^([0-9]|([1-9][0-9])|100)$");
        // 1-3 eligible, minimum 75%
        if (totalEligEmps <= 3) {
          if (!regex.test(value)) {
            errorStrings.push("75% to 100%");
          }
        }
        // 4+ eligible, minimum 50%
        else {
          if (!regex.test(value)) {
            errorStrings.push("50% to 100%");
          }
        }
        break;
      case "employeeParticipationEligible":
        if (fteRule === "Eligible") {
          const minCount = bnePlatform === 'PRIME' && isOneLife(roleName, stateCode) ? 1 : validation.min;
          if (parseFloat(value) < minCount) {
            errorStrings.push(`Minimum of ${minCount}`);
            break;
          }
          if (parseFloat(value) > validation.max) {
            errorStrings.push(`Maximum of ${validation.max}`);
            break;
          }
        }
        break;
      case "noSpecialCharacterAddress":
        if (bnePlatform === "PRIME") {
          if (!hasNoSpecialCharacter(value)) {
            errorStrings.push(
              'Street Address cannot have symbols like #@&%^*,?."{}[]|'
            );
          }
        }
        break;
      case "noSpecialCharacterButPeriod":
        if (bnePlatform === "PRIME") {
          if (!hasNoInvalidCharactersOnGroupName(value)) {
            errorStrings.push(
              'Group Name on ID Card cannot have special characters'
            );
          } 
        }
        break;
      case "basicLifeEmployerContributionMax":
        const maxCount = checkIfLifePlanIsNonContributory(enrolledLifePlans, classingOpted) ? 100 : 99;
        if (parseInt(value) > maxCount) {
          errorStrings.push(`Maximum of ${maxCount}`);
          break;
        }
        break;
      default:
        break;
    }
  });
  // if we pushed nothing to errorStrings, that means there are no errors
  if (errorStrings.length === 0) {
    return {
      value: modifiedValue,
      error: null,
    };
  }
  // return a single string describing all the errors
  return {
    value: oldValue ? oldValue : null, //  THis is done to allow the incorrect value to be updated while uploading template it has no impact. Don;t make any change without discussion to this.
    error: errorStrings.join("; "),
  };
};

/**
 *
 * @param {string} value the value that was entered into the textbox
 * @param {array} validations list of validation objects from the configurable field -- see configurableFormPropTypes for a definition of "validations"
 * @param {boolean} isRequired value from the configurable field -- see configurableFormPropTypes
 */
export const checkValidationsOnBlur = (
  value,
  validations,
  isRequired,
  fullProps = {},
  selectedProducts = {},
  dependentInfo = {}
) => {
  const dob =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.employee &&
    fullProps.enrollment.application.employee.employeeInfo &&
    fullProps.enrollment.application.employee.employeeInfo.dob
      ? fullProps.enrollment.application.employee.employeeInfo.dob
      : "";
  const cobraStartDate =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.employee &&
    fullProps.enrollment.application.employee.employeeInfo &&
    fullProps.enrollment.application.employee.employeeInfo.cobraStartDate
      ? fullProps.enrollment.application.employee.employeeInfo.cobraStartDate
      : "";
  const currentCarrierEffectiveDate =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.eligibility &&
    fullProps.enrollment.application.eligibility.currentCarrierInformation &&
    fullProps.enrollment.application.eligibility.currentCarrierInformation
      .currentCarrierEffectiveDate
      ? fullProps.enrollment.application.eligibility.currentCarrierInformation
          .currentCarrierEffectiveDate
      : "";
  const platform = fullProps.overall.platform;
   const appConfigGuaranteedPeriod = fullProps.overall.appConfigDetails.guaranteedPeriod;
  const numHoursPerWeekToBeEligible =
    fullProps.enrollment &&
    fullProps.enrollment.application &&
    fullProps.enrollment.application.eligibility &&
    fullProps.enrollment.application.eligibility.participation &&
    fullProps.enrollment.application.eligibility.participation
      .numHoursPerWeekToBeEligible &&
    fullProps.enrollment.application.eligibility.participation
      .numHoursPerWeekToBeEligible !== ""
      ? fullProps.enrollment.application.eligibility.participation
          .numHoursPerWeekToBeEligible
      : "";
  const effectiveDate =
    fullProps.companyProfile && fullProps.companyProfile.effectiveDate
      ? fullProps.companyProfile.effectiveDate
      : "";

  const currentCarrierCoverageBeginDate =
    fullProps.enrollment &&
      fullProps.enrollment.application &&
      fullProps.enrollment.application.eligibility &&
      fullProps.enrollment.application.eligibility.currentCarrierInformation &&
      fullProps.enrollment.application.eligibility.currentCarrierInformation
        .coverageBeginDate
      ? fullProps.enrollment.application.eligibility.currentCarrierInformation
        .coverageBeginDate
      : "";

  const currentCarrierCoverageEndDate =
    fullProps.enrollment &&
      fullProps.enrollment.application &&
      fullProps.enrollment.application.eligibility &&
      fullProps.enrollment.application.eligibility.currentCarrierInformation &&
      fullProps.enrollment.application.eligibility.currentCarrierInformation
        .coverageEndDate
      ? fullProps.enrollment.application.eligibility.currentCarrierInformation
        .coverageEndDate
      : "";
  const { fteRule } = fullProps.companyProfile.locations[0];
  const enrollmentRules = fullProps.rules.enrollment;
  const stateCode = getPrimaryLocationStateCode(
    fullProps.companyProfile.locations
  );
  const roleName = fullProps.renewals.roleName;
  const contrType = fullProps.enrollment.application.eligibility.contribution?.medicalEmployerContributionType;

  const classingOpted = fullProps.life.employeeClassing.classingOpted;
  const enrolledLifePlans = fullProps.rules.enrollment.enrolledLifePlans;
  const isparticipationBypassed = fullProps.overall.isparticipationBypassed;
  const caseProgress = getNBCaseProgress(fullProps);
  const caseStatusList = [
    CASE_PROGRESS.QUOTING,
    CASE_PROGRESS.ENROLLING,
  ];
  
  if (value === "" || !value) {
    if (isRequired) {
      return "Required field";
    }
    return null;
  }
  const errorStrings = [];
  validations.forEach((validation) => {
    switch (validation.validationType) {
      case "maxLength": {
        if (value.length > validation.value) {
          errorStrings.push(`Maximum length of ${validation.value} characters`);
        }
        break;
      }
      case "minLength": {
        if (value.length < validation.value) {
          errorStrings.push(`Minimum length of ${validation.value} characters`);
        }
        break;
      }
      case "exactLength": {
        if (value.length !== validation.value) {
          errorStrings.push(
            `Must have length of exactly ${validation.value} characters`
          );
        }
        break;
      }
      case "digitsOnly": {
        if (!isDigitsOnly(value)) {
          errorStrings.push("Must be digits only");
        }
        break;
      }
      case "lettersOnly": {
        if (!isLettersOnly(value)) {
          errorStrings.push("Must be letters only");
        }
        break;
      }
      case "lettersandSpecialCharacterWithSpace":{
        if (!isLettersandSpecialCharacterWithSpace(value)) {
          errorStrings.push("Must be letters or '/' or '-'");
        }   
        break;
      }
      case "blurRegex": {
        const regex = new RegExp(validation.value);
        if (!regex.test(value)) {
          errorStrings.push(validation.regexDescription);
        }
        break;
      }
      case "min": {
        let guaranteedPeriod = null;
        if (platform === 'PRIME') {
          guaranteedPeriod = validation.guaranteedPeriodUhc ? validation.guaranteedPeriodUhc : null;
        } else {
          guaranteedPeriod = validation.guaranteedPeriod ? validation.guaranteedPeriod : null;
        }
         let validated = isGuaranteedRule(guaranteedPeriod, effectiveDate, platform, appConfigGuaranteedPeriod)
        if ((caseStatusList.includes(caseProgress)) && !validated) {
          if (parseFloat(value) < validation.value) {
            errorStrings.push(`Minimum of ${validation.value}`);
          }
          break;
        } 
        else if((!caseStatusList.includes(caseProgress)) && !isparticipationBypassed){
          if (parseFloat(value) < validation.value) {
            errorStrings.push(`Minimum of ${validation.value}`);
          }
          break;
        }
        break;
      }

      case "max":
        if (parseFloat(value) > validation.value) {
          if(contrType === "$")
            break;
          else
          errorStrings.push(`Maximum of ${validation.value}`);
        }
        break;
      case "inputRegex": {
        const regex = new RegExp(validation.value);
        if (!regex.test(value)) {
          if(contrType === "$")
            break;
          else
          errorStrings.push(validation.regexDescription);
        }
        break;
      }
      // case 'zip':
      //     if (!isZip(value)) {
      //         errorStrings.push('Must be a 5-digit Zip Code');
      //     }
      //     break;
      case "zip4":
        if (!isZip4(value)) {
          errorStrings.push("Must be 4 digits");
        }
        break;
      case "dob":
        if (new Date(value) > new Date()) {
          errorStrings.push("Must not be a future date");
        }
        break;
      case "validDOB":
        if (getAge(new Date(value)) < 14 || getAge(new Date(value)) > 115) {
          errorStrings.push("Must be between 14 - 115 years");
        }
        break;

      case "validSpouseDOB":
        if (
          dependentInfo.relationship &&
          (dependentInfo.relationship === "Spouse" ||
            dependentInfo.relationship === "Civil Union Partner" ||
            dependentInfo.relationship === "Domestic Partner" ||
            dependentInfo.relationship === "Life Partner")
        ) {
          if (getAge(new Date(value)) < 14 || getAge(new Date(value)) > 115) {
            errorStrings.push("Must be between 14 - 115 years");
          }
        }
        break;
      case "validChildDOB":
        if (
          dependentInfo.relationship && dependentInfo.relationship === "Child"
        ) {
          if (getAge(new Date(value)) > (validation.value || 26)) {
            errorStrings.push(`Must be less than ${validation.value} years`);
          }
        }
        break;
      case "date":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
        }
        break;
      case "validDateOfHire": {
        if (dob !== "") {
          if (moment(value) <= moment(dob)) {
            errorStrings.push("Must be greater than Date of Birth");
          }
          let dateOfHireValue = MMDDYYYYtoIsoString(value);
          if (moment(value) > moment(effectiveDate)) {
            errorStrings.push("Date of Hire must not be after effective date");
          }
        }

        break;
      }
      case "validCobraEndDate":
        {
          if (!isValidDate(value)) {
            errorStrings.push("Please enter a valid date");
            break;
          }
          if (cobraStartDate !== "") {
            if (new Date(value) <= new Date(cobraStartDate)) {
              errorStrings.push("Must be after COBRA Start Date");
            }
          }
        }
        break;
      case "effectiveDate":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
          break;
        }
        if (platform === "PRIME") {
          const uhcStateConfig = fullProps.overall.uhcStateConfig;
          const role =
            fullProps.renewals &&
            fullProps.renewals.logInData &&
            fullProps.renewals.logInData.length > 0
              ? fullProps.renewals.logInData[0].role
              : null;
          const roleName = fullProps.renewals.roleName;
          const validEffectiveDates = getValidEffectiveDates(
            role,
            uhcStateConfig,
            roleName
          );
          if (!validEffectiveDates.includes(value)) {
            errorStrings.push(
              "Must be a date within 90 days on the first or fifteenth of the month"
            );
          }
        }

        break;
      case "validCurrentCarrierEffectiveDate":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
          break;
        }
        break;
      case "validCurrentCarrierTerminationDate":
        if (!isValidDate(value)) {
          errorStrings.push("Must be a valid date");
          break;
        }
        if (currentCarrierEffectiveDate !== "") {
          if (new Date(value) <= new Date(currentCarrierEffectiveDate)) {
            errorStrings.push("Must be after Effective Date");
          }
        }
        break;
      case "phone":
        if (!validatePhoneNumber(value)) {
          errorStrings.push("Must enter valid phone number");
        }
        if (value[0] === "0" || value[0] === "1") {
          errorStrings.push("Area codes cannot start with a 0 or 1");
        }
        break;
      case "validCoverageBeginDate":
        if (currentCarrierCoverageEndDate !== "") {
          if (new Date(value) > new Date(currentCarrierCoverageEndDate)) {
            errorStrings.push("Cannot be After the Coverage End Date")
          }
        }
        break;
      case "validCoverageEndDate":
        if (currentCarrierCoverageBeginDate !== "") {
          if (new Date(value) < new Date(currentCarrierCoverageBeginDate)) {
            errorStrings.push("Cannot be before the Coverage Begin Date")
          }
          
        }
        break;
      case "ssn":
        if (!validateSSN(value)) {
          errorStrings.push("Must be valid SSN / ITIN");
        }
        break;
      case "salary":
        if (!isValidCurrency(value)) {
          errorStrings.push("Must be valid dollar amount");
        } else if (selectedProducts.life) {
          errorStrings.push("Required Field");
        }
        break;
      case "email":
        if (!validateEmail(value)) {
          errorStrings.push("Must enter valid email");
        }
        break;
      case "totalCommission":
        const totalValue = validateTotalCommission(value, fullProps);
        if (parseFloat(totalValue) != validation.value) {
          errorStrings.push(
            `Total Commission across all producers must be ${validation.value} %`
          );
        }
        break;
      case "MinCommission":
        if (value <1){
          errorStrings.push(
            `Must be greater than 0`
          );
        }
        break;
      case "validSpecifiedDuration":
        const isValid = validateSpecifiedDuration(
          value,
          validation.type,
          fullProps
        );
        if (!isValid) {
          errorStrings.push(validation.errorMessage);
        }
        break;
      case "SC_NC_MedicalContribution":
        let guaranteedPeriod = null;
        let validated = isGuaranteedRule(guaranteedPeriod, effectiveDate, platform, appConfigGuaranteedPeriod)
        if (!validated) {
          let totalEligEmps;
          // Specific rule for NC and SC states
          const { primaryLocationIdx, locations } = fullProps.companyProfile;
          const { enrollment: { application: { eligibility } } } = fullProps;
          const { totalEligEmps: locationEligEmps } = locations[primaryLocationIdx];
          const participationEligEmps = eligibility && eligibility.participation && eligibility.participation.numEligibleEmployees ? eligibility.participation.numEligibleEmployees : '';
          totalEligEmps = participationEligEmps || locationEligEmps;
          // 1-3 eligible, minimum 75%
          if (totalEligEmps <= 3) {
            if (parseFloat(value) < 75) {
              errorStrings.push("Minimum of 75%");
            }
          }
          // 4+ eligible, minimum 50%
          else {
            if (parseFloat(value) < 50) {
              errorStrings.push("Minimum of 50%");
            }
          }
        }
        break;
      case "MA_Num_Hours_Eligible":
        if (parseFloat(value) < 20) {
          errorStrings.push(`Minimum of 30`);
        }
        break;
      case "isDisabled":
        const adultDependent = fullProps.enrollment.application.eligibility.participation.adultDependent
        const effectiveMaxAge = adultDependent === 'Yes' ? "29" : fullProps.companyProfile.dependentRules.maxAge;
        if (platform === "PRIME") {
          if (
            (value === "No" || (adultDependent && adultDependent === 'No')) &&
            (dependentInfo.relationship === "Child" ||
              dependentInfo.relationship === "Dependent Child") &&
            fullProps.companyProfile.dependentRules &&
            dependentInfo.age >=
              parseInt(effectiveMaxAge)
          ) {
            errorStrings.push(
              "This dependent exceeds the age limit per federal/state guidelines and will not be eligible for coverage, please remove dependent."
            );
          }
        }
        if (platform === "CIRRUS") {
          let maxAge = parseInt(
            fullProps.companyProfile.dependentRules?.maxAge || "26"
          );
          let enrolledPlans =
            enrollmentRules &&
            enrollmentRules.enrolledPlans &&
            enrollmentRules.enrolledPlans.standalone;
          if (enrolledPlans && enrolledPlans.length > 0 && stateCode === "NY") {
            for (let i = 0; i < enrolledPlans.length; i++) {
              if (enrolledPlans[i] && enrolledPlans[i].riderAge29) {
                maxAge = 30;
                break;
              }
            }
          }
          if (
            value === "No" &&
            (dependentInfo.relationship === "Child" ||
              dependentInfo.relationship === "Dependent Child") &&
            fullProps.companyProfile.dependentRules &&
            dependentInfo.age >= maxAge
          ) {
            errorStrings.push(
              "You have entered a dependent child that exceeds the state max age on the census and must be disabled for coverage. Please validate the census and continue"
            );
          }
        }
        break;
      case "numberOfHours":
        if (numHoursPerWeekToBeEligible && numHoursPerWeekToBeEligible !== "") {
          if (value && parseInt(value) < parseInt(numHoursPerWeekToBeEligible))
            errorStrings.push(`Minimum of ${numHoursPerWeekToBeEligible}. Maximum of 40`);
        } else if (!numHoursPerWeekToBeEligible) {
          if (value) errorStrings.push(`Enter # of hours in ER App`);
        }
        break;
      case "franchiseCode":
        if (!isFranchiseCode(value)) {
          errorStrings.push("Must be a 7-digit Franchise Code");
        }
        break;
      case "employeeParticipationEligible":
        if (fteRule === "Eligible") {
          const minCount = platform === 'PRIME' && isOneLife(roleName, stateCode) ? 1 : validation.min;
          if (parseFloat(value) < minCount) {
            errorStrings.push(`Minimum of ${minCount}`);
            break;
          }
          if (parseFloat(value) > validation.max) {
            errorStrings.push(`Maximum of ${validation.max}`);
            break;
          }
        }
        break;
      case "franchiseCode":
        if (!isFranchiseCode(value)) {
          errorStrings.push("Must be a 7-digit Franchise Code");
        }
        break;
      case "poBoxAddress":
        // Throw error if value contains PO Box
        let updatedValue = value.toLowerCase(); // covert to lower case
        updatedValue = updatedValue.replace(/\s+/g, ""); // remove spaces
        updatedValue = updatedValue.replace(/\./g, ""); // remove .
        if (updatedValue.includes("pobox")) {
          errorStrings.push("Primary Location cannot be a PO Box");
        }
        break;
      case "noSpecialCharacterAddress":
        if (platform === "PRIME") {
          if (!hasNoSpecialCharacter(value)) {
            errorStrings.push(
              'Street Address cannot have symbols like @&%^*,?."{}[]|'
            );
          }
        }
        break;
       case 'employeeParticipationFteAtne':
          const minCount = platform === 'PRIME' && isOneLife(roleName, stateCode) ? 1 : validation.min;
            if (parseFloat(value) < minCount) {
               errorStrings.push(`Minimum of ${minCount}`);
               break;
            }
            if (parseFloat(value) > validation.max) {
               errorStrings.push(`Maximum of ${validation.max}`);
               break;
            }
           break;
      case "noSpecialCharacterButPeriod":
        if (platform === "PRIME") {
          const lastChar = value.slice(-1)
          if (!hasNoInvalidCharactersOnGroupName(value)) {
            errorStrings.push(
              'Group Name on ID Card cannot have special characters'
            );
          } else {
            if (!hasNoSpecialCharacterButPeriod(lastChar)) {
              errorStrings.push(
                'Group Name on ID Card cannot have special characters'
              );
            }
          }
        }
        break;
      case 'validTaxID':
        let stringifiedValue = value.toString();
        if(stringifiedValue === "123456789" && platform === "CIRRUS")
        {
          errorStrings.push(`invalid tax ID`);
        }
        let count = 1;
        let firstChar = stringifiedValue.charAt(0);
        for (let i = 1; i < stringifiedValue.length; i++) {
          if (firstChar === stringifiedValue[i]) {
            count++;
          }
        }
        if (count === 9 && platform === "CIRRUS") {
          errorStrings.push(`invalid tax ID`);
        }
        break;
      case "basicLifeEmployerContributionMax":
        const maxCount = checkIfLifePlanIsNonContributory(enrolledLifePlans, classingOpted) ? 100 : 99;
        if (parseInt(value) > maxCount) {
          errorStrings.push(`Maximum of ${maxCount}`);
          break;
        }
        break;
      default:
        console.log("Unexpected validation type");
        break;
    }
  });
  // if we pushed nothing to errorStrings, that means there are no errors
  if (errorStrings.length === 0) {
    return null;
  }
  // return a single string describing all the errors
  return errorStrings.join("; ");
};
