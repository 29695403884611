import _ from 'lodash';
import * as types from '../../actions/actionTypes';

const initialState = [];

export default function premiumReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_MEDICAL_PLAN:

            const premiumPlan = state[action.payload.plan] && state[action.payload.plan].isChecked;
            return {
                ...state,
                [action.payload.plan]: {
                    ...action.payload,
                },
            };

        case types.REMOVE_ALL_MEDICAL_PLANS:
            return {
                ...state,
                keepMedical: false,
            };

        case types.ADD_ALL_MEDICAL_PLANS:
            return {
                ...state,
                keepMedical: true,
            };

        case types.ADD_ALL_VISION_PLANS:
            return {
                ...state,
                keepVision: true,
            };

        case types.REMOVE_ALL_VISION_PLANS:
            return {
                ...state,
                keepVision: false,
            };

        case types.ADD_ALL_DENTAL_PLANS:
            return {
                ...state,
                keepDental: true,
            };

        case types.REMOVE_ALL_DENTAL_PLANS:
            return {
                ...state,
                keepDental: false,
            };
        case types.ADD_ALL_LIFE_PLANS:
            return {
                ...state,
                keepLife: true,
            };

        case types.REMOVE_ALL_LIFE_PLANS:
            return {
                ...state,
                keepLife: false,
            };

        case types.ADD_ALL_DEPLIFE_PLANS:
            return {
                ...state,
                keepDepLife: true,
            };

        case types.REMOVE_ALL_DEPLIFE_PLANS:
            return {
                ...state,
                keepDepLife: false,
            };


        case types.ADD_ALL_SUPP_LIFE_PLANS:
            return {
                ...state,
                keepSuppLife: true,
            };
        case types.REMOVE_ALL_SUPP_LIFE_PLANS:
            return {
                ...state,
                keepSuppLife: false,
            };
        case types.ADD_ALL_STD_PLANS:
            return {
                ...state,
                keepStd: true,
            };
        case types.REMOVE_ALL_STD_PLANS:
            return {
                ...state,
                keepStd: false,

            };
        case types.ADD_ALL_LTD_PLANS:
            return {
                ...state,
                keepLtd: true,
            };
        case types.REMOVE_ALL_LTD_PLANS:
            return {
                ...state,
                keepLtd: false,

            };    
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
        case types.RESET_RENEWALS:
            return initialState;

        default:
            return state;
    }
}
