import {marketTypes} from '../constants';
import {getUHCDependency} from './uhc/dependencyHolder';
import {resolveUHCDependency} from './uhc/dependencyResolver';

/** data = {
*    marketType: 'UHC/OXFORD',
*    currentWorkSheetName,
* }
*/
let data = null
/**
 * 
 * @param {String} marketType UHC/OXFORD
 * @param {String} dependencyName name of dependecy that needs to be resolved.
 * @param {object} dependencyParams object of dependecy params that are required to resolve the dependency
 *                 { userType: Employee/Dependent
 *                  dataObj: data that is needed to resolve the dependency like companyInfo is needed to resolve the dependency in employeeInfo sheet
 *                  mapping: mapping of the current sheet that is being parsed
 *                  worksheetRow: object of worksheet row. It is required while resolving the self dependency.}
 */
export const resolve = (dependencyName, dependencyParams) => {
    if(data.marketType == marketTypes.UHC){
        dependencyParams.currentWorkSheetName = data.currentWorkSheetName;
        let dependencyList = getUHCDependency(dependencyName, dependencyParams.userType, dependencyParams.currentWorkSheetName);
        resolveUHCDependency(dependencyName, dependencyList, dependencyParams);
    }
    else{

    }
}

export const setData = (fieldName, fieldValue) => {
    if(data === null)
        data = {}
    data[fieldName] = fieldValue;
}

export const clearMemory = () => {
    data = null;
}