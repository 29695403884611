import * as types from '../../actions/actionTypes';

const initialState = {
    mobile: false, // <=1044
    tablet: false, // > 1044 && <= 1364
    desktop: true, // > 1364
    tabletOrLarger: true, // > 1044

    smallScreen: false, // <= 800
    largeScreen: true, // > 800
    
    mobileScreen: false,
    semanticMobile: false, // < 768,
    memberCompletePercentage: 0,
    globalCssBreakpoint: true, // > 1400
    showWaitingModal: false
}

export default function resize(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_SCREEN_SIZE:
            return {
                ...state,
                ...action.payload
            }

        case types.TOOGLE_LW_LOADING:
            return {
                ...state,
                memberCompletePercentage: action.payload
            }
        default:
            return state;
    }

}