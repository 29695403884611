import { dentalFilterSet } from '../consts/dentalReducerConsts';
import { visionFilterSet } from '../consts/visionReducerConsts';
import { lifeFilterSet } from '../consts/lifeReducerConsts';
import { stdFilterSet } from '../consts/stdReducerConsts';
import { ltdFilterSet } from '../consts/ltdReducerConsts';
import { suppLifeFilterSet } from '../consts/suppLifeReducerConsts';
import deepCopy from '../../../utils/deepCopy';
import {  MARKET_TYPES } from '../../../utils/businessLogic/platformUtils'

export const handleFilterChange = (state, payload) => {
    const isAdvance = payload.filterType === 'advance';
    const appliedFilters = isAdvance ? deepCopy(state.appliedFiltersAdvance) : deepCopy(state.appliedFiltersBasics);
    const FilterSetCopy = getFilterSet(state.advanceFilterProductType);
    if (!appliedFilters.hasOwnProperty(payload.filterID)) {
        appliedFilters[payload.filterID] = FilterSetCopy.find((filter) => filter.filterId === payload.filterID);
        appliedFilters[payload.filterID].selected = [];
    }

    if (payload.isSelected) {
        appliedFilters[payload.filterID].selected.push(payload.filterValue);
        if (appliedFilters[payload.filterID].filterType !== 'advance') appliedFilters[payload.filterID].filterType = payload.filterType;
    } else {
        const fIndex = appliedFilters[payload.filterID].selected.findIndex((fValue) => fValue === payload.filterValue);
        appliedFilters[payload.filterID].selected.splice(fIndex, 1);
    }
    if (!appliedFilters[payload.filterID].selected.length) {
        delete appliedFilters[payload.filterID];
    }

    let plansToFilter = isAdvance ? state.allPlans : state.filteredResults;

    const newPlans = plansToFilter.filter((plan) => {
        let isFind = true;
        Object.keys(appliedFilters).forEach((appliedFilter) => {
            // Use non-formulary contact lens allowance for SF vision plans
            let { planField } = appliedFilters[appliedFilter];
            if (planField === 'contactLenses.allowanceIn' && plan.productSuite === 'None') {
                planField = 'allowance.nonformularyCLAllowance';
            }
            const planValue = String(getPropByString(plan, planField));
            if (isFind && !appliedFilters[appliedFilter].selected.includes(planValue)) {
                isFind = false;
            }
        });
        return isFind;
    });
    // const newSelectedPlans = deepCopy(state.selectedPlans).filter((plan) => plan.isPreferredPlan);
    // const selectedPlansMapCopy = deepCopy(state.selectedPlansMap);
    // const newSelectedPlansMap = { ...selectedPlansMapCopy, Single: selectedPlansMapCopy.Single ? selectedPlansMapCopy.Single.filter((plan) => plan.isPreferredPlan) : null };

    if (newPlans.length === 0) {
        return {
            ...state,
            zeroPlansAvailable: true,
            filteredResultsBasic: newPlans,
            appliedFiltersBasics: appliedFilters,
            // selectedPlans: newSelectedPlans,
            // selectedPlansMap: newSelectedPlansMap,
        };
    }
    const newState = isAdvance ? {
        ...state,
        filteredResults: newPlans,
        advancefilterSet: updateAdvanceFilterData(newPlans, state.advancefilterSet, payload.filterValue),
        appliedFiltersAdvance: appliedFilters,
        // selectedPlans: newSelectedPlans,
        // selectedPlansMap: newSelectedPlansMap,
    } : {
        ...state,
        basicFilters: updateAdvanceFilterData(newPlans, state.basicFilters, payload.filterValue),
        filteredResultsBasic: newPlans,
        appliedFiltersBasics: appliedFilters,
        zeroPlansAvailable: false,
        isBasicFilterDirty: true,
        // appliedFiltersAdvance: appliedFilters,
        // selectedPlans: newSelectedPlans,
        // selectedPlansMap: newSelectedPlansMap,
    };
    return newState;
};

export const setBasicFilterDirectlyUhc = (state,payload) => {
        const { allPlans } = state;
       
        const {marketType} = payload 
        const allPlansUhc = allPlans.filter((plan) => marketType === MARKET_TYPES.OXFORD ? plan : plan.fundingType === "Contributory" )
        const filteredPlans = allPlansUhc.map((plan) => ({ ...plan, filtered: true }));
        // TODO: BLCRC Merge plans with all plans
        
        return {
            ...state,
            allPlans: allPlansUhc,
            filteredResults: allPlansUhc,
            plans: filteredPlans,
            visiblePlans: filteredPlans,
            noAdvanceSearch: true,
            displayLeftSideFilter: true,
            appliedFiltersAdvance: {},
            appliedFiltersBasics: {},
            basicFilters: getFilterData(allPlansUhc, state.advanceFilterProductType),
        };
};

export const setBasicFilterDirectly = (state) => {
    const { allPlans } = state;
    const filteredPlans = allPlans.map((plan) => ({ ...plan, filtered: true }));
    // TODO: BLCRC Merge plans with all plans
    
    return {
        ...state,
        filteredResults: filteredPlans,
        plans: filteredPlans,
        visiblePlans: filteredPlans,
        noAdvanceSearch: true,
        displayLeftSideFilter: true,
        appliedFiltersAdvance: {},
        appliedFiltersBasics: {},
        basicFilters: getFilterData(state.allPlans, state.advanceFilterProductType),
    };
};

export const setAdvanceFilterWith30Plans = (state, plans) => {
    if (state.totalCount !== state.allPlans.length) {
        return {
            ...state,
        };
    }
    //change done to retain preferrred plans also and to remove duplicate plans
    let visiblePlans = state.visiblePlans.concat(plans.slice(0, 30));
    visiblePlans = removeDuplicatePlans(visiblePlans);
    const filteredPlans = visiblePlans.slice(0, 30).map((plan) => ({ ...plan, filtered: true }));
    return {
        ...state,
        filteredResults: filteredPlans,
        plans: filteredPlans,
        visiblePlans: filteredPlans,
        noAdvanceSearch: false,
        displayLeftSideFilter: false,
        appliedFiltersAdvance: {},
        appliedFiltersBasics: {},
        advancefilterSet: getFilterData(plans, state.advanceFilterProductType),
   };
};

export const setAdvanceFilterData = (state, plans) => {
    if (state.totalCount !== state.allPlans.length) {
        return {
            ...state,
        };
    }
    return {
        ...state,
        filteredResults: plans,
        noAdvanceSearch: false,
        displayLeftSideFilter: false,
        appliedFiltersAdvance: {},
        appliedFiltersBasics: {},
        advancefilterSet: getFilterData(plans, state.advanceFilterProductType),
    };
};

export const resetFitlers = (state) => {
    const visiblePlans = deepCopy(state.visiblePlans);
    for (let vcount = 0; vcount < visiblePlans.length; vcount++) {
        if (visiblePlans[vcount].isPreferredPlan && visiblePlans[vcount].filtered) {
            visiblePlans[vcount].filtered = false;
        }
    }
    const oldFilterPlans = visiblePlans.map((elm, idx) => (elm.filtered ? idx : '')).filter(String);

    for (let i = oldFilterPlans.length - 1; i >= 0; i--) {
        visiblePlans.splice(oldFilterPlans[i], 1);
    }

    return {
        ...state,
        visiblePlans,
        plans: visiblePlans,
        filteredResults: state.allPlans,
        filteredResultsBasic: [],
        appliedFiltersAdvance: {},
        appliedFiltersBasics: {},
        zeroPlansAvailable: false,
        displayLeftSideFilter: false,
        advancefilterSet: getFilterData(state.allPlans, state.advanceFilterProductType),
        isBasicFilterDirty: false,
    };
};

export const viewFilteredPlans = (state, payload) => {
    const { visiblePlans } = state;
    for (let vcount = 0; vcount < visiblePlans.length; vcount++) {
        if (visiblePlans[vcount].isPreferredPlan && visiblePlans[vcount].filtered) {
            visiblePlans[vcount].filtered = false;
        }
    }
    const oldFilterPlans = visiblePlans.map((elm, idx) => (elm.filtered ? idx : '')).filter(String);

    for (let i = oldFilterPlans.length - 1; i >= 0; i--) {
        visiblePlans.splice(oldFilterPlans[i], 1);
    }
    const planCodeIndexDict = {};
    visiblePlans.forEach((plan, idx) => {
        planCodeIndexDict[plan.code] = idx;
    });
    const plansTofilter = payload.filterType === 'basic' ? state.filteredResultsBasic : state.filteredResults;
    plansTofilter.forEach((plan) => {
        if (planCodeIndexDict.hasOwnProperty(plan.code)) {
            visiblePlans[planCodeIndexDict[plan.code]] = { ...plan, filtered: true };
        } else {
            visiblePlans.push({ ...plan, filtered: true });
        }
    });

    let newState = {
        ...state,
        visiblePlans,
        plans: visiblePlans,
        displayLeftSideFilter: true,

    };

    if (payload.filterType !== 'basic') {
        newState = {
            ...newState,
            searchedPlans: visiblePlans,
            zeroPlansAvailable: false,
            isBasicFilterDirty: false,
            appliedFiltersBasics: state.appliedFiltersAdvance,
            basicFilters: getFilterData(visiblePlans.filter((plan) => plan.filtered), state.advanceFilterProductType),
        };
    }
    return newState;
};

const getFilterData = (plans, productType) => {
    const advancefilterSet = {};
    const filterSetCopy = getFilterSet(productType);

    plans.forEach((plan) => {
        filterSetCopy.forEach((filterGroup) => {
            let { planField } = filterGroup;
            // Use non-formulary contact lens allowance for SF vision plans
            if (planField === 'contactLenses.allowanceIn' && plan.productSuite === 'None') {
                planField = 'allowance.nonformularyCLAllowance';
            }
            advancefilterSet[filterGroup.filterId] = filterGroup;
            const value = getPropByString(plan, planField);
            if (!advancefilterSet[filterGroup.filterId].values.hasOwnProperty(value)) {
                advancefilterSet[filterGroup.filterId].values[value] = 1;
            } else {
                advancefilterSet[filterGroup.filterId].values[value] = advancefilterSet[filterGroup.filterId].values[value] + 1;
            }
        });
    });

    return advancefilterSet;
};

const updateAdvanceFilterData = (plans, advanceFilterSet, filterValue) => {
    const updatedAdvancefilterSet = {};
    Object.keys(advanceFilterSet).forEach((filterGroupKey) => {
        const filterGroup = advanceFilterSet[filterGroupKey];
        Object.keys(filterGroup.values).forEach((value) => {
            if (value !== filterValue) {
                filterGroup.values[value] = 0;
            }
        });
    });

    plans.forEach((plan) => {
        Object.keys(advanceFilterSet).forEach((filterGroupKey) => {
            const filterGroup = advanceFilterSet[filterGroupKey];
            let { planField } = filterGroup;
            // Use non-formulary contact lens allowance for SF vision plans
            if (planField === 'contactLenses.allowanceIn' && plan.productSuite === 'None') {
                planField = 'allowance.nonformularyCLAllowance';
            }
            const value = getPropByString(plan, planField);

            if (value !== filterValue) {
                filterGroup.values[value] = filterGroup.values.hasOwnProperty(value) ? filterGroup.values[value] + 1 : 1;
            }
            updatedAdvancefilterSet[filterGroupKey] = filterGroup;
        });
    });

    // Object.keys(appliedFilters).forEach(appliedFilter => {
    //     appliedFilters[appliedFilter].selected.forEach((selectedKey,index) =>{
    //         if(updatedAdvancefilterSet[appliedFilter].values[selectedKey] ===0){
    //             appliedFilters[appliedFilter].selected.splice(index, 1);
    //         }
    //     })
    // })

    return updatedAdvancefilterSet;
};

function getPropByString(plan, propString) {
    if (!propString) return plan;

    let prop; const
        props = propString.split('.');

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
        prop = props[i];

        const candidate = plan[prop];
        if (candidate !== undefined) {
            plan = candidate;
        } else {
            break;
        }
    }
    return plan[props[i]];
}

function getFilterSet(productType) {
    switch (productType) {
        case 'dental':
            return deepCopy(dentalFilterSet);
        case 'vision':
            return deepCopy(visionFilterSet);
        case 'life':
            return deepCopy(lifeFilterSet);
        case 'std':
            return deepCopy(stdFilterSet);
        case 'ltd':
            return deepCopy(ltdFilterSet);
        case 'supplife':
            return deepCopy(suppLifeFilterSet);    
        default:
            return null;
    }
}
const removeDuplicatePlans = (duplicatePlans) => {
    let uniquePlans = new Map();
    duplicatePlans.map((plan) => {
        const isPreferredPlan = duplicatePlans.some((pln) => (pln.code === plan.code && pln.isPreferredPlan));
        plan.isPreferredPlan = isPreferredPlan;
        uniquePlans.set(plan.code, plan);
    });
    return [...uniquePlans.values()];
}