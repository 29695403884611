// Adding nodes back to statestore to maintain dependency on client redux structure. Some duplicate nodes are required to be kept
// TODO:: Refactor frontend redux structure to remove such dependencies
export const duplicateFieldsToBeAdded = {
    companyProfile: [
        {
            source: 'quotes.selectedPlans',
            destination: 'selectedMedicalPlans',
            functionName: null
        },
        {
            source: 'dental.selectedPlans',
            destination: 'selectedDentalPlans',
            functionName: null
        },
        {
            source: 'vision.selectedPlans',
            destination: 'selectedVisionPlans',
            functionName: null
        },
        {
            source: 'life.selectedPlans',
            destination: 'selectedLifePlans',
            functionName: null
        },
        {
            source: 'dependentLife.selectedPlans',
            destination: 'selectedDependentLifePlans',
            functionName: null
        },
        {
            source: 'std.selectedPlans',
            destination: 'selectedStdPlans',
            functionName: null
        },
        {
            source: 'ltd.selectedPlans',
            destination: 'selectedLTDPlans',
            functionName: null
        },
        {
            source: 'suppLife.selectedPlans',
            destination: 'selectedSuppLifePlans',
            functionName: null
        },
        {
            source: 'suppLifeDep.selectedPlans',
            destination: 'selectedSuppLifeDepPlans',
            functionName: null
        },
        {
            source: 'overall',
            destination: 'overall',
            functionName: null
        },
        {
            source: 'reviewCards',
            destination: 'reviewCards',
            functionName: null
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        },
        {
            source: 'specialtyCriteria',
            destination: 'specialtyCriteria',
            functionName: null
        },
        {
            source: 'enrollment',
            destination: 'enrollment',
            functionName: null
        }
    ],
    overall: [
        {
            source: 'companyProfile.employees',
            destination: 'quickQuoteRequest.otherDetails.employeeCensus',
            functionName: 'getEmployeeCensus'
        }
    ],
    quotes: [
        {
            source: 'quotes.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    dental: [
        {
            source: 'dental.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    vision: [
        {
            source: 'vision.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    life: [
        {
            source: 'life',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMapLifeAndDisability'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    dependentLife: [
        {
            source: 'dependentLife.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    std: [
        {
            source: 'std',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMapLifeAndDisability'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    ltd: [
        {
            source: 'ltd',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMapLifeAndDisability'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    suppLife: [
        {
            source: 'suppLife.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    suppLifeDep: [
        {
            source: 'suppLifeDep.selectedPlans',
            destination: 'selectedPlansMap',
            functionName: 'generateSelectedPlansMap'
        },
        {
            source: 'overall.quickQuoteRequest',
            destination: 'quickQuoteRequest',
            functionName: null
        }
    ],
    specialtyCriteria: [
        {
            source: 'companyProfile.sicData',
            destination: 'sicObj',
            functionName: null
        }
    ]
}