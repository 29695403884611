import * as types from '../../actions/actionTypes';	

const initialState = {	
    discount: null,	
    suggestion: null	
};	

export default function packageSavingsReducer(state = initialState, action) {	
    switch (action.type) {	
        case types.RECEIVE_PACKAGE_SAVINGS_RESPONSE:	
            return {	
                ...state,	
                ...action.response	
            };	
        default:	
            return state;	
    }	
}