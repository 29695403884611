// import "react-app-polyfill/ie9";
import "core-js/features/array/includes";
import "core-js/features/string/includes";
import "core-js/features/function/bind";
import "core-js/features/function/virtual/bind";
import "core-js/features/array/find";
import "core-js/features/array/virtual/find";
import "core-js/features/array/find-index";
import "core-js/features/array/virtual/find-index";
import "core-js/features/array/fill";
import "core-js/features/array/virtual/fill";
import "core-js/features/string/repeat";
import "core-js/features/string/virtual/repeat";
import "core-js/features/array/reduce";
import "core-js/features/array/virtual/reduce";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from 'react-redux';
import PersistGateWrapper from './PersistGateWrapper';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import { history } from './utils/history';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import { SAMxThemeProvider } from "@optum-samx-shared-component/samx-shared";

require('dotenv').config();

const { store: _store, persistor } = configureStore();

export const store = _store;


ReactDOM.render(
    <Provider store={_store}>
        <PersistGateWrapper persistor={persistor}>
            <Router history={history}>
                <SAMxThemeProvider>
                    <App />
                </SAMxThemeProvider>
            </Router>
        </PersistGateWrapper>
    </Provider>,
    document.getElementById('root') || document.createElement('root'), // document.createElement is for testing with React Testing Library
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
