import { DateTime } from "luxon";
import { isEmpty } from "./strings";
import moment from "moment-timezone";
import { isUhcRenewals } from "../businessLogic/platformUtils";

const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const convertNumbertoMonth = (month) => fullMonths[month - 1];

export const toMMDDYYYYFormat = (date) => {
  const [year, month, day] = date.substr(0, 10).split("-");
  return `${month}-${day}-${year}`;
};
export const toYYYYMMDDFormat = (date) => {
  const [year, month, day] = date.substr(0, 10).split("-");
  return `${year}-${month}-${day}`;
};

export const toYYYYMMDDFormatFilters = (date) => {
  const [month, day, year] = date.substr(0, 10).split("-");
  return `${year}-${month}-${day}`;
};

export const toRlYYYYMMDDFormat = (date) => {
  const [month, day, year] = date.substr(0, 10).split("/");
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

export const toMMDDYYYYFormatSlash = (date) => {
  const [year, month, day] = date.substr(0, 10).split("-");
  return `${month}/${day}/${year}`;
};

export const toUSNumeric = (date) => {
  try {
    // 01, 02, 03, ... 29, 30, 31
    const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    const yyyy = date.getFullYear();

    // create the format you want
    return `${MM}-${dd}-${yyyy}`;
  } catch (err) {
    // Don't try to format invalid input
    return date;
  }
};

export const toUSNumericSlashes = (date) => {
  try {
    // 01, 02, 03, ... 29, 30, 31
    const dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    const MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    const yyyy = date.getFullYear();

    // create the format you want
    return `${MM}/${dd}/${yyyy}`;
  } catch (err) {
    // Don't try to format invalid input
    return date;
  }
};

export const dateAfterMonths = (months) => {
  const date = new Date();
  const d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return toUSNumericSlashes(date);
};

export const getZeroPaddedString = (value) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

const checkDate = (month, day, year, leapYearCheck) => {
  let checkedDate = "";
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
      if (day >= 31) {
        checkedDate = `${month + 1}/${day % 31 === 0 ? 1 : day % 31}/${year}`;
      }
      break;
    case 12:
      if (day > 31) {
        checkedDate = `${1}/${day % 31 === 0 ? 1 : day % 31}/${year + 1}`;
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      if (day >= 30) {
        checkedDate = `${month + 1}/${day % 30 === 0 ? 1 : day % 30}/${year}`;
      }
      break;
    case 2:
      if (leapYearCheck % 4 === 0) {
        if (day >= 29) {
          checkedDate = `${month + 1}/${day % 29 === 0 ? 1 : day % 29}/${year}`;
        }
      } else if (day >= 28) {
        checkedDate = `${month + 1}/${day % 28 === 0 ? 1 : day % 28}/${year}`;
      }
      break;
    default:
      return "";
  }

  if (checkedDate === "") {
    if (day === 31 && month === 12) {
      return `01/01/${year + 1}`;
    }
    return `${getZeroPaddedString(month)}/${getZeroPaddedString(
      day + 1
    )}/${year}`;
  }
  const splitDate = checkedDate.split("/");
  const zeroPaddedDate = `${getZeroPaddedString(
    splitDate[0]
  )}/${getZeroPaddedString(splitDate[1])}/${splitDate[2]}`;
  return zeroPaddedDate;
};

/**
 * Takes the incoming date object and returns a string in the MM/DD/YYYY style
 * @param {Date} defaultDate - a date object
 */
export const defaultDateToMMDDYYYY = (defaultDate) => {
  const dateString = defaultDate.toLocaleDateString("en-US");
  const dateArray = dateString.split("/");
  const parsedMonth = parseInt(dateArray[0]);
  const parsedDay = parseInt(dateArray[1]);
  const parsedYear = parseInt(dateArray[2]);
  const leapYearCheck = dateArray[2].substr(2);
  const finishedDate = checkDate(
    parsedMonth,
    parsedDay,
    parsedYear,
    leapYearCheck
  );
  return finishedDate;
};

export const getTimestampFromMongoID = (mongoID) =>
  new Date(parseInt(mongoID.toString().substring(0, 8), 16) * 1000);

export const isoStringToMMDDYYYY = (iso) => {
  const dateParts = iso.split("T")[0].split("-");
  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
};

export const isoStringToMMDDYYYYFormat = (iso) => {
  const dateParts = iso ? iso.split("T")[0].split("-") : "";
  return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
};
export const YYYYMMDDtoIsoString = (iso) => {
  const dateParts = iso ? iso.split("T")[0].split("-") : "";
  return `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`;
};
export const MMDDYYYYtoIsoString = (MMDDYYYY) => {
  const dateParts = MMDDYYYY.split("/");
  return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
};

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (date1, date2) => {
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const findDaysTillEffDate = (effectiveDate) => {
  const today = new Date();
  const effDate = new Date(effectiveDate);
  return dateDiffInDays(today, effDate);
};

export const findDaysUsingMomentTZ = (date, isDeadlineDate) => {
  let newDeadlineDate;
  if (!isDeadlineDate) {
    let neweDate;
    if (date.includes("T")) {
      let tempDate = date.split("T");
      neweDate = isUhcRenewals() ? moment.tz(tempDate[0], "America/New_York").endOf("month").endOf("day") 
      : moment.tz(tempDate[0], "America/New_York").endOf("day");
    } else {
      let tempDate = date.split("T");
      neweDate = isUhcRenewals() ? moment.tz(tempDate[0], "America/New_York").endOf("month").endOf("day") 
      :moment.tz(date, "America/New_York").endOf("day");
    }
    const newCurrDate = moment.tz("America/New_York");

    return { neweDate, newCurrDate };
  } else {
    if (date.includes("T")) {
      let deadlineDate = date.split("T");
      newDeadlineDate = moment
        .tz(deadlineDate[0], "America/New_York")
        .endOf("day");
    } else {
      newDeadlineDate = moment.tz(date, "America/New_York").endOf("day");
    }
    return { newDeadlineDate };
  }
};

export const findValueFromMaxSinglePlanArray = (
  maxSingleChoicePlansArray,
  daysTilEffDate
) => {
  for (let i = 0; i < maxSingleChoicePlansArray.length; i++) {
    const ruleObj = maxSingleChoicePlansArray[i];
    let min = ruleObj.minDaysBeforeEffective;
    let max = ruleObj.maxDaysBeforeEffective;
    if (min === "<") {
      min = -45;
    } else if (max === "+") {
      max = 9999;
    }
    if (daysTilEffDate >= min && daysTilEffDate < max) {
      return ruleObj.value;
    }
  }
  return null;
};

/** Bellow Method to convert 01-Dec-2019 formate */
export const monthDateFormate = (defaultDate) => {
  const date = new Date(defaultDate);
  const parsedMonth = shortMonths[date.getMonth()];
  const parsedDay = (date.getDate() < 10 ? "0" : "") + date.getDate();
  const parsedYear = date.getFullYear();
  const finishedDate = `${parsedDay}-${parsedMonth}-${parsedYear}`;
  return finishedDate;
};

// used by getActiveDates
const makeDate = (year, month, day) => new Date(`${month}/${day}/${year}`);
const makeMomentDate = (year, month, day) => {
    let date= moment.tz(`${month}/${day}/${year}`, 'America/New_York')
    date=date.utcOffset(0)
    return date
}

const checkForInternalfirstAndFifteenthDates = (isInternal, minDate, currentDate, rule, dates, dateRules,source="") => {
    const datesTempArray = dates || [];
    const newToday = moment.tz('America/New_York')

    for (let i = 0; i < dateRules.length; i++) {
        let newDeadlineDate
        if (dateRules[i].deadlineDate.includes('T')) {
            let deadlineDate = dateRules[i].deadlineDate.split("T")
            newDeadlineDate = moment.tz(deadlineDate[0], 'America/New_York').endOf('day');
        }
        else {
            newDeadlineDate = moment.tz(dateRules[i].deadlineDate, 'America/New_York').endOf('day');
        }
        const effectiveDate = moment.tz(dateRules[i].effectiveDate, 'America/New_York').startOf('day')
        let splitEffDate=dateRules[i].effectiveDate.split('-')
        
        if (newToday.isAfter(effectiveDate) && newDeadlineDate.isAfter(newToday)) {
            if(source && source === "qq")
              datesTempArray.push(new Date(DateTime.fromISO(dateRules[i].effectiveDate).get('year'), DateTime.fromISO(dateRules[i].effectiveDate).get('month') - 1, DateTime.fromISO(dateRules[i].effectiveDate).get('day')))
            else
            datesTempArray.push(makeMomentDate(splitEffDate[0], splitEffDate[1], splitEffDate[2]))
        }
    }
    return {
      datesArray: datesTempArray,
      includeDate: true,
    };
};

const fillDatesArray = (
  dates,
  today,
  startDate,
  maxDate,
  asStrings,
  increment = 14,
  maxDayAllowedInMonth = 15,
  isNoPriorState = false,
  roleName = ""
) => {
  const startYear = startDate.getFullYear();
  const startMonth = Math.min(startDate.getMonth() + 1, 12);
  const startDay = startDate.getDate();

  const todayMonth = Math.min(today.getMonth() + 1, 12);
  const todayDay = today.getDate();
  const todayYear = today.getFullYear();

  const seventyFiveDaysBackDate = new Date();
  seventyFiveDaysBackDate.setDate(seventyFiveDaysBackDate.getDate() - 75);

  for (let year = startYear; year <= maxDate.getFullYear(); year++) {
    month: for (let month = 1; month <= 12; month++) {
      for (let day = 1; day <= maxDayAllowedInMonth; day += increment) {
        if (year === startYear) {
          if (month < startMonth) {
            continue;
          }
          if (month === startMonth && day < startDay) {
            continue;
          }
        }
        // If role is UHC Install Rep allow 75 days retro quote.
        if (roleName === "UHC Install Rep") {
          const dateFormed = new Date(year, month - 1, day);
          if (dateFormed < seventyFiveDaysBackDate) {
            continue;
          }
        } else {
          if (year < todayYear) {
            continue;
          }

          if (year == todayYear && month < todayMonth) {
            continue;
          }
        }

        // Check if month (or date) is greater than Max Date when the year is greater or equal to Max Year
        if (
          ((month === maxDate.getMonth() + 1 && day > maxDate.getDate()) ||
            month > maxDate.getMonth() + 1) &&
          year >= maxDate.getFullYear()
        ) {
          break month;
        }
        if (asStrings) {
          dates.push(
            `${month < 10 ? `0${month}` : month}/${
              day < 10 ? `0${day}` : day
            }/${year}`
          );
        } else {
          dates.push(makeDate(year, month, day));
        }
      }
    }
  }
};

export const getActiveDatesForUHC = (
  asStrings = false,
  isInternal = true,
  uhcStateConfig = {},
  roleName = ""
) => {
  const dates = [];

  let startDate = new Date();
  let isNoPriorState = false;

  let offSet = 120; // Default offset until state is not entered.

  if (Object.keys(uhcStateConfig).length > 0) {
    let startDateStringValue = "";
    if (isInternal) {
      offSet = uhcStateConfig.internalOffset;
      startDateStringValue = uhcStateConfig.internalStartDate;
    } else {
      offSet = uhcStateConfig.externalOffset;
      startDateStringValue = uhcStateConfig.externalStartDate;
    }
    startDate = new Date(startDateStringValue);

    // NJ and CT doesn't suppport prior date than today.
    // isNoPriorState = ['NJ', 'CT'].includes(uhcStateConfig.stateCode);
    // No prior state logic is to be removed as per latest information.
    isNoPriorState = false;
  } else {
    // For now start date is 9/1/2021 if no state is entered.
    startDate = new Date("9/1/2021");
  }

  const today = new Date();

  // Max date is taken from today's date
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + offSet);

  const increment = 14;
  const maxDayAllowedInMonth = 15;
  fillDatesArray(
    dates,
    today,
    startDate,
    maxDate,
    asStrings,
    increment,
    maxDayAllowedInMonth,
    isNoPriorState,
    roleName
  );
  return dates;
};

/**
 * @param {string} rule one of 'firstAndFifteenth'
 * @param {boolean} asStrings if false, return acceptable dates as Date() objects; if true, return them as "MM/DD/YYYY" strings
 */
export const getActiveDates = (
  rule,
  asStrings = false,
  isInternal = true,
  dateRules = [],
  role = "",
  accessRoles = [],
  source=""
) => {
  let findedRole = false;
  if (accessRoles && accessRoles.length > 0) {
    findedRole = accessRoles.includes(role);
  }

    let dates = [];
    if (rule === 'firstAndFifteenth') {
        let today = moment.tz('America/New_York')
        const mayFirst = moment.tz('05/01/2021', 'America/New_York').endOf('day');
        if (mayFirst.isAfter(today)) {
            today = mayFirst;
        }

        let currentYear = today.get('year');
        const currentMonth = Math.min(today.get('month') + 1, 12);
        const currentDay = today.get('date');
        const maxDate = moment.tz('America/New_York')
        const minDate = moment.tz('America/New_York')
        if (findedRole) {
            minDate.subtract(365, 'days')
            currentYear = minDate.get('year');
            maxDate.add(130, 'days')
        }
        if (isInternal) {
          maxDate.add(90, 'days')
        }
        else {
            maxDate.add(60, 'days')
        }

        for (let year = currentYear; year <= maxDate.get('year'); year++) {
            month:
            for (let month = 1; month <= 12; month++) {
                for (let day = 1; day <= 15; day += 14) {
                    if (year === currentYear) {
                        const { datesArray, includeDate } = checkForInternalfirstAndFifteenthDates(isInternal, minDate, new Date(year, month - 1, day), 'firstAndFifteenth', dates, dateRules,source);
                        dates = datesArray;
                        if (includeDate) {
                            if (month < currentMonth) {
                                continue;
                            }
                            if (month === currentMonth && day < currentDay) {
                                continue;
                            }
                        }
                    }
                    // Check if month (or date) is greater than Max Date when the year is greater or equal to Max Year
                    if (((month === maxDate.get('month') + 1 && day > maxDate.get('date')) || month > maxDate.get('month') + 1) && year >= maxDate.get('year')) {
                        break month;
                    }
                    if (asStrings) {
                        dates.push(`${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`);
                    } else {
                      if(source && source==="qq")
                      dates.push(makeDate(year, month, day))
                     else
                        dates.push(makeMomentDate(year, month, day));
                    }
                }
            }
        }
    } else if (rule === 'upToThirtyFirst') {
        let today = moment.tz('America/New_York')
        const mayFirst = moment.tz('05/01/2021', 'America/New_York').endOf('day');
        if (mayFirst.isAfter(today)) {
            today = mayFirst;
        }

        let currentYear = today.get('year');
        const currentMonth = Math.min(today.get('month') + 1, 12);
        const currentDay = today.get('date');
        const maxDate = moment.tz('America/New_York')
        const minDate = moment.tz('America/New_York')
        if (findedRole) {
            minDate.subtract(365, 'days')
            currentYear = minDate.get('year');
            maxDate.add(130, 'days')
         }
        if (isInternal) {
          maxDate.add(90, 'days')
        }
        else {
            maxDate.add(60, 'days')
        }

        for (let year = currentYear; year <= maxDate.get('year'); year++) {
            month:
            for (let month = 1; month <= 12; month++) {
                for (let day = 1; day <= 31; day++) {
                    if (year === currentYear) {
                        const { datesArray, includeDate } = checkForInternalfirstAndFifteenthDates(isInternal, minDate, moment.tz(`${year}/${month - 1}/${day}`, 'America/New_York'), 'upToThirtyFirst', dates, dateRules,source);
                        dates = datesArray;
                        if (includeDate) {
                            if (month < currentMonth) {
                                continue;
                            }
                            if (month === currentMonth && day < currentDay) {
                                continue;
                            }
                        }
                    }
                    // Check if month (or date) is greater than Max Date when the year is greater or equal to Max Year
                    if (((month === maxDate.get('month') + 1 && day > maxDate.get('date')) || month > maxDate.get('month') + 1) && year >= maxDate.get('year')) {
                        break month;
                    }
                    if (asStrings) {
                        dates.push(`${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`);
                    } else {
                      if(source && source==="qq")
                        dates.push(makeDate(year, month, day))
                       else
                        dates.push(makeMomentDate(year, month, day));
                    }
                }
            }
          
      
    }
  }

  return dates;
};

/**
 * @param {string} dateString date string
 * @param {string} currentSeparator char used in input string to separate day, month, year
 * @param {string} currentFormat one of 'YYYYMMDD', 'MMDDYYYY' - format of input string
 * @param {string} newSeparator character desired to separate output string
 * @param {string} newFormat one of 'YYYYMMDD', 'MMDDYYYY' - format desired for output string
 */
export const convertDateString = (
  dateString,
  currentSeparator,
  currentFormat,
  newSeparator,
  newFormat
) => {
  const dateParts = dateString.split(currentSeparator);
  let year;
  let month;
  let day;
  switch (currentFormat) {
    case "YYYYMMDD":
      [year, month, day] = dateParts;
      break;
    case "MMDDYYYY":
    default:
      [month, day, year] = dateParts;
      break;
  }
  switch (newFormat) {
    case "YYYYMMDD":
      return [year, month, day].join(newSeparator);
    case "MMDDYYYY":
    default:
      return [month, day, year].join(newSeparator);
  }
};

export const handleExcelDate = (date) => {
  if (isEmpty(date)) {
    return "";
  }

  if (typeof date === "object") {
    if ((date.formula || date.sharedFormula) && !date.result) {
      return "";
    }

    if ((date.formula || date.sharedFormula) && date.result) {
      return handleExcelDate(date.result);
    }

    const dateValue = DateTime.fromJSDate(date)
      .toUTC()
      .toFormat("MM/dd/yyyy")
      .toString();

    return dateValue;
  }

  if (typeof date === "string") {
    const dateSlashRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
    const dateDashRegex = /^[0-9]{4}-[0-1][0-9]-[0-3][0-9]$/

    const dashFormatTest = dateDashRegex.test(date);

    if (dashFormatTest) {
      const splitDate = date.split("-");
      date = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    }

    const stringSlashFormatTest = dateSlashRegex.test(date);

    if (stringSlashFormatTest) {
      return date;
    }

    return "";
  }

  return "";
};

/** Function to check if case is eligible to be finalized based on Effective date.
 *  For UHC the case is elgible if today date is less than or month of effective date month */
export const isEligibleToFinalizeCaseUHC = (effectiveDateStr, role) => {
  let effDate;
  let dateEligible = true;
  if (effectiveDateStr.includes("-")) {
    const dateSplit = effectiveDateStr.split("-");
    effDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
  } else if (effectiveDateStr.includes("/")) {
    const dateSplit = effectiveDateStr.split("/");
    effDate = new Date(dateSplit[2], dateSplit[0] - 1, dateSplit[1]);
  }

  const currentDate = new Date();

  if (role === "UHC Install Rep") {
    let maxDate = effDate;
    maxDate.setDate(maxDate.getDate() + 75);
    if (currentDate >= maxDate) {
      dateEligible = false;
    }
  }
  if (currentDate.getFullYear() > effDate.getFullYear()) {
    dateEligible = false;
  }
  if (
    currentDate.getFullYear() === effDate.getFullYear() &&
    currentDate.getMonth() > effDate.getMonth()
  ) {
    dateEligible = false;
  }
  return dateEligible;
};

/** Method to convert date from IBR in MM/DD/YYYY format and append zeros in month and year if required.*/
export const formatIbrDOB = (month, day, year) => {
  const appendZeroIfSingleDigit = (number) => {
    if (parseInt(number) < 10) {
      return "0" + number;
    }
    return number;
  };
  return (
    appendZeroIfSingleDigit(month) +
    "/" +
    appendZeroIfSingleDigit(day) +
    "/" +
    year
  );
};

export const findNYPlanCutoffDate = (effDate) => {
  let effectiveDate = "";
  let month = "";
  if (effDate && effDate.includes("T")) {
    let tempDate = "";
    tempDate = effDate.split("T");
    effectiveDate = moment.tz(tempDate[0], "America/New_York");
  } else {
    effectiveDate = moment.tz(effDate, "America/New_York");
  }
  let year=effectiveDate.year()
  month = effectiveDate.format("M") - 1;
  if(month==0){
    month=12
    year--
  }
  let cutoffDate = "";
  if (month < 10) cutoffDate += "0" + month + "/16/" + year;
  else cutoffDate += month + "/16/" + year;
  let newCutoffDate = moment.tz(cutoffDate, "America/New_York").endOf("day");
  const currentDate = moment.tz("America/New_York");
  return { newCutoffDate, currentDate };
};

export const getMonthDiffInDates = (date1, date2) => {
  let months;
  months = (date2.getFullYear() - date1.getFullYear()) * 12 + (date2.getMonth() - date1.getMonth()) + 1;
  return months <= 0 ? 0 : months;
};