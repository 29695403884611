export const ASO_IDENTIFIER = 'ASO';
export const FI_IDENTIFIER = 'FI';
export const MEDICAL = 'MEDICAL';
export const DENTAL = 'DENTAL';
export const VISION = 'VISION';
export const LIFE = 'LIFE'; // EMP Basic Life
export const LIFE_EMP_SUPP = 'LIFE_EMP_SUPP';
export const LIFE_DEP_BASIC = 'LIFE_DEP_BASIC';
export const LIFE_DEP_SUPP_SPOUSE = 'LIFE_DEP_SUPP_SPOUSE';
export const LIFE_DEPP_SUPP_CHILD = 'LIFE_DEPP_SUPP_CHILD';
export const STD = 'STD';
export const LTD = 'LTD';
export const SUL = 'SUL';
export const SUPPLIFE = 'SUPPLIFE';
export const SUPP_DEP_LIFE = 'SUPP_DEP_LIFE';
export const SUPPDEPLIFE = 'SUPPDEPLIFE';
export const RENEWAL_SHOPPING = 'RS';
export const miniCobraStates = ['CA', 'FL', 'KS', 'KY', 'MS', 'NH', 'OK', 'OR', 'RI', 'WV'];
export const cobraOnlyStates = ['AL', 'AK', 'ID', 'MI', 'NV', 'IN', 'VI' ];
export const aoCoverageNonOptionalStates = ['AZ','CA','CO','IA','NM','OR','WI'];
export const aoCoverageOptionalStates= ['IN','KY','LA','MI','MS','NE','NY','OH','TN','TX','UT','WA','WY','AR'];
export const tempIdErrMsg = "Health Plan ID card generation is temporarily unavailable, please check the dashboard in 48 hours to receive your groups Health Plan ID cards";
export const midAtlanticStates = ["MD","DE","WV","VA","FL","VI"]
export const medicalService = [{
    typeName: FI_IDENTIFIER,
    apiKeyName: 'medical',
}];

export const specialtyServices = [{
    typeName: DENTAL,
    apiKeyName: 'dental',
}, {
    typeName: VISION,
    apiKeyName: 'vision',
}, {
    typeName: LIFE,
    apiKeyName: 'life',
}, {
    typeName: LIFE_EMP_SUPP,
    apiKeyName: 'employeeLifeSupp',
}, {
    typeName: LIFE_DEP_BASIC,
    apiKeyName: 'dependentLifeBasic',
}, {
    typeName: LIFE_DEP_SUPP_SPOUSE,
    apiKeyName: 'dependentLifeSpouse',
}, {
    typeName: LIFE_DEPP_SUPP_CHILD,
    apiKeyName: 'dependentLifeChild',
}, {
    typeName: STD,
    apiKeyName: 'shortTermDis',
}, {
    typeName: LTD,
    apiKeyName: 'longTermDis',
}, {
    typeName: SUL,
    apiKeyName: 'sul',
},
{
    typeName: SUPPLIFE,
    apiKeyName: 'suppLife',
},
{
    typeName: SUPP_DEP_LIFE,
    apiKeyName: 'suppDepLife',
},
];

const {
    REACT_APP_IT_SUPPORT_EMAILS, REACT_APP_SHOW_DE_QUOTE_SEARCH_TOGGLE,
    REACT_APP_DES_IMPORT_CREATED_DATE_LIMIT, REACT_APP_IT_SUPPORT_EMAILS_UHC_REN,
    REACT_APP_LOG_UHC_ERRORS, REACT_APP_SEND_UHC_ERRORS_EMAIL, REACT_APP_GROUP_HW_EMAIL, REACT_APP_SAMX_FILE_TYPE
} = process.env;

export const environmentVariables = {
    itSupportEmails: REACT_APP_IT_SUPPORT_EMAILS,
    itSupportEmailsUhcRen: REACT_APP_IT_SUPPORT_EMAILS_UHC_REN,
    samxFileType: REACT_APP_SAMX_FILE_TYPE,
    logUhcErrors: REACT_APP_LOG_UHC_ERRORS,
    sendUhcErrorsEmail: REACT_APP_SEND_UHC_ERRORS_EMAIL,
    showQuoteSearchToggle: REACT_APP_SHOW_DE_QUOTE_SEARCH_TOGGLE || false,
    desQuote: {
        noofDaysCreatedDateLimit: REACT_APP_DES_IMPORT_CREATED_DATE_LIMIT || 30,
    },
    groupHWEmails: REACT_APP_GROUP_HW_EMAIL
};

export const allNonASOServices = medicalService.concat(specialtyServices);

export const getTypeName = (apiKeyName) => allNonASOServices.filter(
    (service) => service.apiKeyName === apiKeyName,
)[0].typeName;

export const enrollmentSectionNameMapping = {
    wagesAndTax: 'Wages and Tax',
    participation: '10+ Participation',
    religiousExemption: 'Religious Exemption',
    peoElcAttestation: 'PEO/ELC Attestation',
    writtenAssurance: 'Written Assurance',
    commonOwnership: 'Common Ownership',
    priorDental: 'Proof of Prior Dental',
    priorLife: 'Proof of Prior Life',
    HSACertificateOfUnderstanding: 'HSA Certificate of Understanding',
    employeeEnrollmentForms: 'Employee Enrollment Form(s)',
    selectHRAForm: 'Select HRA Form',
    limitedAgencyAgreement: 'Limited Agency Agreement',
    smallEmployerGroupDisclosureForm: 'Small Employer Group Disclosure Form'
};

// Derived from the above to provide the opposite functionality
const keyMapping = {};
const keys = Object.keys(enrollmentSectionNameMapping);
keys.forEach((key) => {
    const value = enrollmentSectionNameMapping[key];
    keyMapping[value] = key;
});

export const enrollmentSectionKeyMapping = keyMapping;

export const DOC_TECHNICAL_ERROR = 'There was some technical error while fetching the document. Please try again later.';
export const DOC_NOT_PRESENT_ERROR = 'The document is not currently available.'

export const qualifyingEventTypeMapping = {
    'Termed Employment': '1',
    'Reduction work hours': '2',
    'Medicare': '3',
    'Death': '4',
    'Divorce': '5',
    'Separation': '6',
    'Ineligible Child': '7',
    'Bankruptcy of Retire': '8',
    'Layoff': '9',
    'Leave of Absence': '10',
    'Unknown': 'ZZ'
}

export const FI_LF_FUNDING_CONVERSION_RENEWAL_CASE_STATUS = "Converted to LF";

export const FI_LF_CONVERSION_COMPLETE_CASE_STATUS = "LF Conversion Complete";