import * as types from '../../actions/actionTypes';

const initialState = {
    isSavingQuote: false,
    saveQuoteError: null,
    shouldReloadSavedQuotes: true,
    userName: 'unknown',
    currentQuoteId: null,
    savedCases: [],
    isResumingQuote: false,
    resumeQuoteError: false,
    displayGoldWarning: true
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case types.LOGGED_IN: {
            if (!action.payload || action.payload.length === 0) {
                console.log('Warning -- empty user payload');
                return state;
            }
            const payloadUser = action.payload[0];
            return {
                ...state,
                userName: payloadUser.userName || 'unknown',
                userType: payloadUser.category || 'unknown',
                savedCases: payloadUser.fullShoppingProfiles || [],
            };
        }
        case types.UPDATED_USER_CASES:
            return {
                ...state,
                savedCases: action.payload,
            };
        case types.BEGIN_SAVING_QUOTE:
            return { ...state, isSavingQuote: true, saveQuoteError: null };
        case types.FINISH_SAVING_QUOTE:
            return {
                ...state, isSavingQuote: false, shouldReloadSavedQuotes: true, currentQuoteId: action.payload,
            };
        case types.FINISH_UPDATING_QUOTE: {
            const updateCaseIndex = state.savedCases.findIndex((caseRow) => caseRow._id === action.payload._id);
            const newCases = state.savedCases;
            newCases[updateCaseIndex] = action.payload;
            return {
                ...state, isSavingQuote: false, savedCases: newCases, shouldReloadSavedQuotes: true, currentQuoteId: action.payload._id,
            };
        }
        case types.ERROR_SAVING_QUOTE:
            return { ...state, isSavingQuote: false, saveQuoteError: action.error };
        case types.BEGIN_RESUMING_QUOTE:
            return {
                ...state,
                isResumingQuote: true,
                resumeQuoteError: false,
            };
        case types.FINISH_RESUMING_QUOTE:
            return {
                ...state,
                isResumingQuote: false,
            };
        case types.ERROR_RESUMING_QUOTE:
            return {
                ...state,
                isResumingQuote: false,
                resumeQuoteError: true,
            };
        case types.TOGGLE_DISPLAY_GOLD_WARNING:
            return {
                ...state,
                displayGoldWarning: action.displayGoldWarning
            }
        default:
            return state;
    }
}
