// US2988455 dependent life
import * as types from '../../actions/actionTypes';
// import { handleRequestPlans, handleReceiveSpecialtyPlans } from './fetchingReducerHelpers';
import { initialFilterCriteria, initialState } from './consts/lifeReducerConsts';
import { sortPlans } from './helpers/filterHelper'
import deepCopy from '../../utils/deepCopy'
import {
    togglePlanMembership,
    filterPlans,
    filterSpecialtyPlans,
    togglePlanMap,
    getSelectedPlansBenefitRiders
} from './helpers/filterHelper';
import {
    isEmpty
} from '../../utils/formatters/strings';
import { TrendingUpTwoTone } from '@material-ui/icons';
import { calculateDependentLifeRate } from '../../utils/businessLogic/dependentLifeRateLogic';

const findAndTogglePlan = (plans, code, field) => {
    for (let plan of plans) {
        if (code === plan.code) {
            plan[field] = !plan[field];
            break;
        }
    }
    return plans;
}

const initialStates = { ...initialState, advanceFilterProductType: 'life_dep_basic' }
function receiveLifeDepPlans(state, action) {
    if(!action.payload.plans) {
        return state
    }
    const {
        quickQuoteRequest: { zipObj: { zipCode: currentZip } },
        ibrDepLifePlan
    } = action;
    const plans = action.payload.plans.map(plan => ({ ...plan, selected: false, favorited: false, planType: 'life_dep_basic' }));
    const {
        selectedPlans: stateSelectedPlans,
        selectedPlansMap: stateSelectedPlansMap,
        favoritedPlans: stateFavoritedPlans,
        quickQuoteRequest: { zipObj: { zipCode: prevZip } },
    } = state;

    const changedZip = prevZip !== currentZip;
    const selectedPlans = changedZip ? [] : stateSelectedPlans;
    const selectedPlansMap = changedZip ? {} : stateSelectedPlansMap;
 
    const updatedPlans = plans.map((plan) => {
        if(ibrDepLifePlan && ibrDepLifePlan.length > 0){
            const reqIbrDepLifePlan = ibrDepLifePlan.find(pln=>plan.code === pln.code);
            plan.fundingType = reqIbrDepLifePlan && reqIbrDepLifePlan.fundingType ? reqIbrDepLifePlan.fundingType : plan.fundingType;
        }
        const selected = selectedPlans.some((pln) => (pln.code === plan.code));
        const favorited = stateFavoritedPlans.some((pln) => (pln.code === plan.code));
        return { ...plan, selected, favorited };
    });

    return {
        ...state,
        quickQuoteRequest: action.quickQuoteRequest,
        plans: updatedPlans,
        visiblePlans: updatedPlans,
        selectedPlans,
        selectedPlansMap,
        numRatesFetched: 0,
        allPlans: plans,
        advancefilterSet: false,
        isFetchingPlans: false,
        allRatesFetched: false
    };
}

function receiveLifeDepRates(state, action) {
    let newPlans;
    if (state.plans && state.plans.length > 0) {
        newPlans = deepCopy(state.plans)
    }
    else {
        newPlans = deepCopy(state.allPlans)
    }

    let newSelectedPlans = deepCopy(state.selectedPlans);
    let filteredResults = deepCopy(state.filteredResults);

    let ratesPlans = [];
    for (let plan of newPlans) {
        let rateIndex = action.rates.findIndex(rate => rate.blCode === plan.code || rate.code === plan.primePlanCode)
        if (rateIndex > -1) {
            action.rates[rateIndex]["totalMonthlyPremium"] = action.rates[rateIndex].depLifeTotPremium
            const calculatedFinalMonthlyPremium = calculateDependentLifeRate(action.allEmployees, action.specialtyCriteria, action.rates[rateIndex], action.uhcRenewalFlag, action.isSourceRenewal)
            plan = {
                ...plan, quote: action.rates[rateIndex],
                finalMonthlyPremium: calculatedFinalMonthlyPremium,
                prevFinalMonthlyPremium: calculatedFinalMonthlyPremium,
                finalRates: action.rates[rateIndex],
                byCensus: action.rates[rateIndex].byCensus
            }
        }
        ratesPlans.push(plan)
    }
    if (ratesPlans.length > 0) {
        newPlans = ratesPlans
    }

    (action.rates || []).forEach(rate => {
        const selectedPlanIndex = newSelectedPlans.findIndex(plan => plan.code === rate.blCode || plan.primePlanCode === rate.code);
        if (selectedPlanIndex > -1) {
            const calculatedFinalMonthlyPremium = calculateDependentLifeRate(action.allEmployees, action.specialtyCriteria, rate, action.uhcRenewalFlag, action.isSourceRenewal)
            newSelectedPlans[selectedPlanIndex] = {
                ...newSelectedPlans[selectedPlanIndex],
                quote: rate,
                finalMonthlyPremium: calculatedFinalMonthlyPremium,
                prevFinalMonthlyPremium: calculatedFinalMonthlyPremium,
                finalRates: rate,
                byCensus: rate.byCensus
            }
        }
    })

    let numRatesFetched = ratesPlans.length; //US2988525 Dependant life
    let allRatesFetched = ratesPlans.length === newPlans.length; //US2988525 Dependant life
    let filterCriteria = deepCopy(state.filterCriteria)
    filterCriteria.favorite = false
    newPlans = sortPlans(newPlans, state.ascending);

    let newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newPlans,
        selectedPlans: newSelectedPlans,
        allRatesFetched,
        filterCriteria,
        numRateBatchesFetching: state.numRateBatchesFetching - 1,
        numRatesFetched,
        filteredResults
    }
    return newState

}

export const handleTogglePreferredPlans = (state) => {
    return {
        ...state,
        showPreferredPlans: !state.showPreferredPlans
    };
}

export const handleChangeSortOrder = (state, action) => {
    return {
        ...state,
        sortEnabled: true,
        ascending: action.ascending,
        visiblePlans: sortPlans(state.visiblePlans, action.ascending)
    };
}

export const handleUpdateAllPlans = (state, action, type) => {
    // We must update the favorite/selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans = deepCopy(state.plans);
    let newVisiblePlans = deepCopy(state.visiblePlans);
    let newSelectedPlansMap = deepCopy(state.selectedPlansMap)
    for (let plan of newVisiblePlans) {
        if (action.type === "UPDATE_ALL_PLANS_FI") {
            plan[action.field] = !plan[action.field];
        }
        plan[action.field] = action.value;
    }
    for (let plan of newPlans) {
        if (newVisiblePlans.map(visiblePlan => visiblePlan.code).includes(plan.code)) {
            if (action.type === "UPDATE_ALL_PLANS_FI") {
                plan[action.field] = !plan[action.field];
            }
            plan[action.field] = action.value;
        }
    }
    let newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans
    };

    let newSelectedPlans = [];
    if (action.field === "selected") {
        if (action.value) { // i.e., if we are selecting all plans
            let selectedPlanCodes = state.selectedPlans.map(plan => plan.code);
            newSelectedPlans = [].concat(state.selectedPlans).concat(newVisiblePlans.filter(plan => !selectedPlanCodes.includes(plan.code)));
            let plansInPackage
            if (action.pkg === 'Single') {
                plansInPackage = newSelectedPlans
            } else {
                plansInPackage = newSelectedPlans.filter(plan => plan.packages.includes(action.pkg));
            }
            plansInPackage.forEach((plan) => {
                if (plan.selectedPackages) {
                    plan.selectedPackages.forEach(pkg => {
                        if (pkg !== action.pkg) {
                            plan.selectedPackages.push(action.pkg)
                        }
                    })
                } else {
                    plan.selectedPackages = [action.pkg]
                }
            })
            newSelectedPlansMap[action.pkg] = plansInPackage
        } else { //deselecting all plans
            let visiblePlanCodes = newVisiblePlans.map(plan => plan.code);
            let selectedPlans = deepCopy(state.selectedPlans);

            var i = selectedPlans.length
            while (i--) {
                if (visiblePlanCodes.includes(selectedPlans[i].code) || selectedPlans[i].planType !== 'Medical') {
                    let packageIndex
                    //when selectedpackages array is more than one, just remove the package
                    if (selectedPlans[i].selectedPackages.length > 1) {
                        packageIndex = selectedPlans[i].selectedPackages.indexOf(action.pkg)
                        selectedPlans[i].selectedPackages = selectedPlans[i].selectedPackages.splice(packageIndex - 1, 1)
                        newSelectedPlans.push(selectedPlans[i]);
                    } else if (selectedPlans[i].selectedPackages.length === 1) { //remove the plan if the package is the only selectedPackage
                        if (selectedPlans[i].selectedPackages[0] === action.pkg) {
                            selectedPlans.splice(i, 1)
                        }
                    }
                }
            }

            newSelectedPlans = selectedPlans
            let renewalPlans = [];
            if (type && type === 'RS') {
                state.selectedPlans.map(plan => {
                    if (plan.renewalPlan === true) {
                        renewalPlans.push(plan);
                    }
                })
            }
            newSelectedPlans = renewalPlans;
            delete newSelectedPlansMap[action.pkg]

        }

        if (newState.specialty && newState.plans[0]) {
            localStorage.setItem(`selected_specialty_` + newState.plans[0].planType, JSON.stringify(newSelectedPlans.map(plan => plan.code)));
        }
    }

    return {
        ...newState,
        selectedPlans: newSelectedPlans,
        selectedPlansMap: newSelectedPlansMap,
    };
}

export const handleUpdatePlan = (state, action, type) => {
    if(!state.plans || state.plans.length === 0) {
        return state
    }
    // We must update the favorite/selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans = deepCopy(state.plans);
    newPlans = findAndTogglePlan(newPlans, action.plan.code, action.field);

    let newVisiblePlans = deepCopy(state.visiblePlans);
    newVisiblePlans = findAndTogglePlan(newVisiblePlans, action.plan.code, action.field);

    let newState = {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans
    };

    // Toggle specialty adv plans
    let newAllPlans = state.allPlans ? deepCopy(state.allPlans) : [];
    newAllPlans = findAndTogglePlan(newAllPlans, action.plan.code, action.field);
    if (newAllPlans.length > 0) {
        newState = {
            ...newState,
            allPlans: newAllPlans
        }
    }

    let newSelectedPlans
    let newSelectedMap = state.selectedPlansMap;

    if (action.field === "selected") {

        newSelectedPlans = togglePlanMembership('selected', action.plan, state.selectedPlans, action.pkg, false, action.parentPlanCode);
        if (!isEmpty(state.maxAgeBandedPlans) && state.filterCriteria.ratingMethod.age) {
            const numSelected = newSelectedPlans.length;
            if (numSelected > state.maxAgeBandedPlans) {
                return {
                    ...state,
                    maxAgeBandedPlansWarning: true
                }
            }
        }
        newSelectedMap = togglePlanMap(action.plan, state.selectedPlansMap, action.pkg)

        if (newState.specialty) {
            localStorage.setItem(`selected_specialty_` + newState.plans[0].planType, JSON.stringify(newSelectedPlans.map(plan => plan.code)));
        }

    } else { // action.field === "favorited"
        newState.favoritedPlans = togglePlanMembership('favorited', action.plan, state.favoritedPlans, action.pkg);
        if (newState.favoritedPlans.length === 0) {
            newState.filterCriteria.favorite = false;
            if (!newState.specialty) {
                newState = {
                    ...newState,
                    ...state.specialty ?
                        filterSpecialtyPlans(newState.plans, newState.allPlans, newState.filterCriteria, state.allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending) : filterPlans(newState.plans, newState.filterCriteria, state.isCirrus, state.allRatesFetched, state.filterTotalsCache, state.sortEnabled, state.ascending)
                };
            } else {
                newState = {
                    ...newState,
                };
            }
        }

        if (newState.specialty) {
            localStorage.setItem(`favorites_specialty_` + newState.plans[0].planType, JSON.stringify(newState.favoritedPlans.map(plan => plan.code)));
        } else if (type === 'RS') {
            localStorage.setItem(`favorites_${action.plan.platform}_${state.renewalShoppingRequest.zipObj.zipCode}_${state.renewalShoppingRequest.zipObj.countyFIPSCode}`,
                JSON.stringify(newState.favoritedPlans.map(plan => plan.code)));
        } else {
            localStorage.setItem(`favorites_${action.plan.platform}_${state.quickQuoteRequest.zipCode}_${state.quickQuoteRequest.countyCode}`,
                JSON.stringify(newState.favoritedPlans.map(plan => plan.code)));
        }


        newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
        for (let plan of newSelectedPlans) {
            if (action.plan.code === plan.code) {
                plan[action.field] = !plan[action.field];
                break;
            }
        }
    }
    // Setting up the benefit riders selected plans have among all the available benfit riders.
    let newSelectedPlansBenefitRiders = [];
    if (state.optionalBenefitRiders) {
        newSelectedPlansBenefitRiders = getSelectedPlansBenefitRiders(newSelectedPlans, state.optionalBenefitRiders);
    }

    return {
        ...newState,
        selectedPlans: newSelectedPlans,
        selectedPlansMap: newSelectedMap,
        selectedPlansBenefitRiders: newSelectedPlansBenefitRiders
    };

}

const handleUpdatePremium = (state, action) => {
    let newPlans = deepCopy(state.plans)
    let newVisiblePlans = deepCopy(state.visiblePlans)
    newPlans.forEach(plan => {
        if (plan.code == action.planCode) {
            plan.finalMonthlyPremium = action.rate
            plan.prevFinalMonthlyPremium = action.rate
        }
    })
    newVisiblePlans.forEach(plan => {
        if (plan.code == action.planCode) {
            plan.finalMonthlyPremium = action.rate
            plan.prevFinalMonthlyPremium = action.rate
        }
    })
    return {
        ...state,
        plans: newPlans,
        visiblePlans: newVisiblePlans
    }
}

const handleLifeDepFilter = (state, action) => {
    let newPlans = deepCopy(state.plans)
    let newVisiblePlans;
    newVisiblePlans = newPlans.filter(plan => plan.code === action.planCode)
    return {
        ...state,
        visiblePlans: newVisiblePlans
    }
}

const handleChangeFilterLifeDep = (state, action) => {
    let newPlans = deepCopy(state.plans)
    let newVisiblePlans;
    let newStates;
    let filterCriteria = deepCopy(state.filterCriteria)
    if (action.field === 'favorite') {
        if (action.value === true) {
            newVisiblePlans = newPlans.filter(plan => plan.favorited === true)
            filterCriteria.favorite = true
            newStates = {
                ...state,
                filterCriteria,
                visiblePlans: newVisiblePlans
            }
        }
        else {
            newVisiblePlans = newPlans
            filterCriteria.favorite = false
            newStates = {
                ...state,
                filterCriteria,
                visiblePlans: newVisiblePlans
            }
        }
    }
    if (action.field === 'default') {
        let planCount = newPlans.filter(plan => plan.favorited === true)
        if (planCount.length < 1) {
            newVisiblePlans = newPlans
            filterCriteria.favorite = false
            newStates = {
                ...state,
                filterCriteria,
                visiblePlans: newVisiblePlans
            }
        }
        else{
            newStates = state
        }
    }
    return newStates
}

const requestLifeDepPlans = (state, action) => {
    return {
        ...state,
        isFetchingPlans: true,
        quickQuotePlansError: null,
    }
}

export default function lifeDep(state = initialStates, action) {

    switch (action.type) {
        // case types.SERVICE.LIFE_DEP.REQUEST_QUICK_QUOTE_PLANS:
        //     return handleRequestPlans(state, initialFilterCriteria, initialFilterCriteria);

        // case types.SERVICE.LIFE_DEP.RECEIVE_QUICK_QUOTE_PLANS:
        //     return handleReceiveSpecialtyPlans(state, action.payload);
        case types.REQUEST_DEPENDENT_LIFE_PLANS:
            return requestLifeDepPlans(state, action)
        case types.RECEIVE_DEPENDENT_LIFE_PLANS:
            return receiveLifeDepPlans(state, action)

        case types.RECEIVE_DEPENDENT_LIFE_RATES:
            return receiveLifeDepRates(state, action)

        case types.SERVICE.LIFE_DEP_BASIC.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state)

        case types.SERVICE.LIFE_DEP_BASIC.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action)

        case types.SERVICE.LIFE_DEP_BASIC.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action)

        case types.SERVICE.LIFE_DEP_BASIC.UPDATE_PLAN:
            return handleUpdatePlan(state, action)

        case types.UPDATE_LIFE_DEP_BASIC_PREMIUM:
            return handleUpdatePremium(state, action)

        case types.FILTER_LIFE_DEP_PLANS:
            return handleLifeDepFilter(state, action)

        case types.CHANGE_FILTER_LIFE_DEP_PLANS:
            return handleChangeFilterLifeDep(state, action)

        case types.UPDATE_SELECTED_DEPENDENTLIFE_PLAN:
            const newSelectedPlans = deepCopy(action.payload)
            return {
                ...state,
                selectedPlans: newSelectedPlans,
                selectedPlansMap: {
                    Single: newSelectedPlans,
                },
            }; 
        case types.SERVICE.LIFE.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };
        case types.SERVICE.LIFE_DEP_BASIC.RESET_FILTERS:
            const newPlans = deepCopy(state.plans)
            return {
                ...state,
                visiblePlans: newPlans
            };
        case types.USER_FAVORITE_LIFEDEP_PLANS:
            const { allPlans, plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                allPlans,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        case types.UPDATE_PARENT_PLAN_CODE_LIFE_DEP_BASIC:
            const updatedSelectedPlans = deepCopy(state.selectedPlans);
            const index = updatedSelectedPlans.findIndex(plan => plan.code === action.planCode);
            if(index > -1){
                if(updatedSelectedPlans[index].parentPlanCode){
                    updatedSelectedPlans[index].parentPlanCode = action.parentPlanCode;
                }
            }
            return {
                ...state,
                selectedPlans : updatedSelectedPlans
            }
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
            return initialState
            
        default:
            return state
    }
}
