const appStateFilter = (condition) => (condition.conditionType === 'appState' || condition.conditionType === 'companyProfile');

/**
 *
 * @param {object} enrollmentAppState - from redux: state.enrollment.application
 * @param {string} currentSectionName - the sectionName of the section containing
 *                                      the field in question
 * @param {string} reducerName - should have been passed as a prop to
 *                               the component calling this function:
 *                               "employer" | "eligibility" | "producer" | "attestation"
 * @param {object} field - an object that may have any of the following fields:
 *                      showIf - an object from the enrollment form reducer
 *                              (may or may not be an attribute of a field/section)
 *                              -- see configurableFormPropTypes
 *                      readOnly - array from the enrollment form reducer
 *                              (may or may not be an attribute of a field/section)
 *                              -- see configurableFormPropTypes
 *                      autoValue - array from the enrollment form reducer
 *                              (may or may not be an attribute of a field/section)
 *                              -- see configurableFormPropTypes
 *                      dropdownOptions - array from the enrollment form reducer
 *                              (may or may not be an attribute of a field/section)
 *                              -- see configurableFormPropTypes
 *                      validationsIf - array from the enrollment form reducer
 *                              (may or may not be an attribute of a field/section)
 *                              -- see configurableFormPropTypes
 *
 * @returns {object} object containing a SUBSET of the enrollment
 *          application state that is relevant only to the field/section that
 *          called this function, thereby avoiding unnecessary re-renders by not
 *          tracking any values that don't impact this field's logic
 */

export const getRelevantAppState = (
    enrollmentAppState,
    reducerName,
    currentSectionName,
    field,
) => {
    const {
        showIf = null, readOnly = null,
        autoValue = null, dropdownOptions = null, validationsIf = null,
    } = field;
    const appState = {};
    const conditions = [];

    if (showIf) {
        if (showIf.conditions) {
            conditions.push(...showIf.conditions.filter(appStateFilter));
        }
    }
    if (readOnly) {
        readOnly.forEach((ro) => {
            if (ro.conditions) {
                conditions.push(...ro.conditions.filter(appStateFilter));
            }
        });
    }
    if (autoValue) {
        autoValue.forEach((val) => {
            if (val.conditions) {
                conditions.push(...val.conditions.filter(appStateFilter));
            }
        });
    }
    if (dropdownOptions) {
        dropdownOptions.forEach((option) => {
            if (option.conditions) {
                conditions.push(...option.conditions.filter(appStateFilter));
            }
        });
    }

    if (validationsIf) {
        validationsIf.forEach((validation) => {
            if (validation.conditions) {
                conditions.push(...validation.conditions.filter(appStateFilter));
            }
        });
    }

    conditions.forEach((condition) => {
        let key = condition.appState.stateKey;
        if (key === '__this__') {
            key = currentSectionName;
        }
        const subKey = condition.appState.stateSubKey;
        let value = (enrollmentAppState[reducerName][key] || {})[subKey];
        if (value === undefined || value === null) {
            value = '';
        }
        // we flatten the map because mapStateToProps()
        // is too slow when it's a map of maps. we parse this key in the field components
        appState[`appState_${key}_${subKey}`] = value;
    });

    return appState;
};

/**
 *
 * @param {object} propsWithFlatAppStateKeys - props passed in by the field/section component,
 *                                             with some key names following the format
 *                                             "appState_<keyName>_<subKeyName>": value
 *
 * @returns {object} object with the appState keys converted to a nested object,
 * e.g., { keyName: { subKeyName: value } }
 */

export const makeAppStateNested = (propsWithFlatAppStateKeys) => {
    const appStateKeys = Object.keys(propsWithFlatAppStateKeys)
        .filter((key) => key.indexOf('appState_') === 0);

    const appState = {};

    appStateKeys.forEach((key) => {
        const keyParts = key.split('_');
        const stateKey = keyParts[1];
        const stateSubKey = keyParts[2];
        appState[stateKey] = {
            ...appState[stateKey],
            [stateSubKey]: propsWithFlatAppStateKeys[key],
        };
    });

    return appState;
};
