import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'semantic-ui-react';
import {
    userPreference, getPcISid, storeRoleName, storeDelegateInfo,
} from '../../../actions/renewalActions';
import { getPermissions } from '../../../actions/permissionsActions';
import { updateLicenseCheck } from '../../../actions/reviewCardsAction';
import { axios } from '../../../utils/api/axiosConfig';
import getNewBusinessCase from '../../../actions/newBusinessDashboardAction';
import { getEnrollingCaseCount, getEnrollingCases, getRLNotCompletedCases } from '../../../actions/caseTrackAction';

const apiRoutes = require('../../../utils/api/apiRouteConfig');

const PcisPopup = (props) => {
    const userName = useSelector((state) => (state.renewals && state.renewals.logInData && state.renewals.logInData[0] ? state.renewals.logInData[0].userName : ''));
    const role = useSelector((state) => (state.renewals && state.renewals.logInData && state.renewals.logInData[0] ? state.renewals.logInData[0].role : ''));
    const optumId = useSelector((state) => (state.renewals && state.renewals.logInData && state.renewals.logInData[0] ? state.renewals.logInData[0].optumId : ''));
    const userPermissions = useSelector((state) => state.renewals.userRolesPermissions.associations);
    const selectedPcis = useSelector((state) => state.renewals.val);
    const overall = useSelector((state) => state.overall);

    const pcisOptions = [];
    (userPermissions || []).forEach((permission, i) => {
        const permissionCopy = permission;
        permissionCopy.key = i;
        permissionCopy.value = permissionCopy.type === 'Broker' ? permissionCopy.extValue : userName;
        permissionCopy.text = (permissionCopy.type === 'Broker' ? `${permissionCopy.bneDisplayName} (PCIS: ${permissionCopy.extValue})` : '');
        pcisOptions.push(permissionCopy);
    });
    const dispatch = useDispatch();

    // Gets permission names to pull from DB
    const getPermissionNames = (permissions) => {
        let permissionsData = '';
        permissions.roles[0].permissionsList.map((permission) => {
            permissionsData = permissionsData !== '' ? `${permissionsData},${permission.permissionName}` : permission.permissionName;
        });
        dispatch(getPermissions(permissionsData));
    };
    const getExternalCasesCount = (userId) => {
        dispatch(getEnrollingCaseCount(userId, overall.platform));
        dispatch(getEnrollingCases(userId, overall.platform));
        dispatch(getRLNotCompletedCases(userId));
    }
    // Called when user chooses from pcis dropdown
    const handlePcisSelection = (e, { value }) => {
        if (value !== 'undefined') {
            const query = {
                userName,
                role,
                optumId,
                multiUserId: value,

            };
            dispatch(userPreference(query));
        }
        const permissions = userPermissions.filter((data) => data.extValue === value);
        getPermissionNames(permissions[0]);
        dispatch(getPcISid(value));
        dispatch(storeRoleName(permissions[0].roles[0].roleName));
        dispatch(storeDelegateInfo(permissions[0].bneDisplayName));
        getExternalCasesCount(value);
        dispatch(getNewBusinessCase({ userName, dateLimit: 1, role, platform: overall.platform, pcisId: value }));
        //make call to get-restrictedStates-having-license in service layer
        axios.get(`${apiRoutes.quickQuotes}/get-restrictedStates-having-license?pcis=${value}`).then((resp) => {
            //store response array to redux under reviewCards
            dispatch(updateLicenseCheck(resp.data.licenseCheck))
        })


    };

    const { open } = props;

    return (
        <Modal
            style={{ width: '50rem', marginTop: '15rem' }}
            centered={false}
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={open}
        >
            <Modal.Header className="centerAlign"><h2>Please select who you are working on behalf of today.</h2></Modal.Header>
            <Modal.Content>
                <p className="centerAlign">
                    <span className="activeItem font-size-20">{userName}</span>
                    {' '}
                    working on behalf of
                </p>
                <div className=" centerAlign">
                    <Form.Dropdown
                        placeholder="Select one"
                        search
                        selection
                        options={pcisOptions}
                        className="marginBottomMedium"
                        onChange={handlePcisSelection}
                        value={selectedPcis}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button primary compact disabled={selectedPcis === ''} onClick={() => props.handleClose()}>Continue</Button>
            </Modal.Actions>
        </Modal>
    );
};

PcisPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default PcisPopup;
