import deepCopy from '../../../utils/deepCopy';
import { isEmpty } from '../../../utils/formatters/strings';

/**
 * @description Returns an altered version of the input list of plans, with the desired premium adjusted
 * @param {Array} plans Array of plan objects
 * @param {string} code Code of desired plan to change
 * @param {number} premium The new total monthly premium to set
 * @param {Object} rates Object containing all the new final rates after adjustment
 * @param {Object} adjustments Object containing all rate adjustments made by the user
 */

const findMemberRate = (rates, coverage, planType, plan, index = 0) => {
    if(['LTD','STD'].includes(planType)){
        let volumeAmount = planType === 'LTD' ? 100 : 10;
        let rate = rates[planType.toLowerCase()];
        let maxBenefitAmount = planType === 'LTD' ? plan.maximumMonthlyBenefit : plan.maximumWeeklyBenefit;
        if(planType === 'LTD'){
            maxBenefitAmount = plan.censusRates && plan.censusRates[index] && plan.censusRates[index].maximumMonthlyBenefit ? plan.censusRates[index].maximumMonthlyBenefit : maxBenefitAmount;
        }else {
            maxBenefitAmount = plan.censusRates && plan.censusRates[index] && plan.censusRates[index].maximumWeeklyBenefit ? plan.censusRates[index].maximumWeeklyBenefit : maxBenefitAmount;
        }
        return ((parseFloat(maxBenefitAmount) / volumeAmount) * parseFloat(rate)).toFixed(2);
    }else{
        switch (coverage) {
            case 'EE':
                return rates.ee ? rates.ee.toString() : ''
            case 'EE/SP':
                return rates.es ? rates.es.toString() : ''
            case 'EE/CH':
                return rates.ec ? rates.ec.toString() : ''
            case 'EE/FAM':
                return rates.ef ? rates.ef.toString() : ''
            default:
                return ''
        }
    }
}

const setRateVol = (planType, plan, rates, premium, isAgeBanded) => {
    if (planType === 'Life') {
        return {
            ...plan.finalRates,
            basicLifeRateVol: rates.basicLife.toString(),
            totalLifeRateVol: rates.total.toString(),
            totalMonthlyPremium: premium.toString(),
        };
    } else if (planType === 'STD') {
        return {
            ...plan.finalRates,
            ...(isAgeBanded
                ? { ageBands: rates.std }
                : { rateOver10WeeklyBenefit: rates.std.toString() }),
            stdRate: isAgeBanded ? rates.std : rates.std.toString(),
            totalMonthlyPremium: premium.toString(),
            isAgeBanded
        };
    } else if (planType === 'LTD') {
        return {
            ...plan.finalRates,
            ...(isAgeBanded
                ? { ageBands: rates.ltd }
                : { timesRatesPer100ofMCP: rates.ltd.toString() }),
            ltdRate: isAgeBanded ? rates.ltd : rates.ltd.toString(),
            totalMonthlyPremium: premium.toString(),
            isAgeBanded
        };
    } else {
        return {
            ...plan.finalRates,
            totalMonthlyPremium: premium.toString(),
            isAgeBanded
        };
    }
}

export const findAndUpdatePremiumAndRates = (plans, code, premium, rates, adjustments, isMMRCase = false, isAgeBanded = false,
    selectedClass = null, source = null, platform = null) => {

    for (let plan of plans) {
        if (code === plan.code && (!isEmpty(selectedClass) ? (plan?.selectedClass === selectedClass) : true)) {
            premium = isMMRCase && plan.member?.length === 0 ? '0.00' : premium;
            // Construct new rates object. Convert rates to strings for consistency,
            // as that is how they usually come back from the view.
            const ratesObj = (plans[0].planType !== 'Life' && plans[0].planType !== 'STD' && plans[0].planType !== 'LTD') ?
                isMMRCase && plan.tierStructureId === 'B' ?
                    {
                        employee: rates.ee ? rates.ee.toString() : '',
                        empAndFamily: rates.ef ? rates.ef.toString() : ''
                    } 
                :
                    {
                        employee: rates.ee ? rates.ee.toString() : '',
                        empAndSpouse: rates.es ? rates.es.toString() : '',
                        empAndChild: rates.ec ? rates.ec.toString() : '',
                        empAndFamily: rates.ef ? rates.ef.toString() : ''
                    }
            :
                setRateVol(plans[0].planType, plan, rates, premium, isAgeBanded)

            if ((plan.planType.toUpperCase() === 'LIFE' || plan.planType.toUpperCase() === 'STD' ||
                plan.planType.toUpperCase() === 'LTD') && plan.quote) {

                    if (plan.planType.toUpperCase() === 'LIFE') {
                        ratesObj.basicLifeTotalPremium = isMMRCase && plan.member?.length === 0 ? '0.00' : getBasicLifeStdLtdMonthlyPremium(plan, rates, 'life', isMMRCase).toString();
                    }

                    if (plan.planType.toUpperCase() === 'STD') {
                        ratesObj.stdTotalPremium = isMMRCase && plan.member?.length === 0 ? '0.00' : isAgeBanded ?
                            premium
                        :
                            platform === 'CIRRUS' && source === 'full' ?
                                premium
                            :
                                getBasicLifeStdLtdMonthlyPremium(plan, rates, 'std', isMMRCase).toString();
                    }

                    if (plan.planType.toUpperCase() === 'LTD') {
                        ratesObj.ltdTotalPremium = isMMRCase && plan.member?.length === 0 ? '0.00' : isAgeBanded ?
                            premium
                        :
                            platform === 'CIRRUS' && source === 'full' ?
                                premium
                            :
                                getBasicLifeStdLtdMonthlyPremium(plan, rates, 'ltd', isMMRCase).toString();
                    }
            }

            plan.finalMonthlyPremium = premium.toString();
            plan.finalRates = ratesObj;
            plan.rateAdjustments = adjustments;
            plan['isAgeBanded'] = isAgeBanded;
            if (isMMRCase) {
                if (plan.planType === 'Life' || plan.planType === 'STD' || plan.planType === 'LTD') {
                    plan.quote.totalMonthlyPremium ? plan.quote.totalMonthlyPremium : plan.quote = premium.toString();
                } else {
                    plan.quote = premium.toString();
                }

                if(!['LTD','STD', 'Supplement Life'].includes(plan.planType) || !isAgeBanded){
                    plan.member = plan.member.map((member, index) => {
                        member.total = findMemberRate(rates, member.coverages[plan.planType.toLowerCase()], plan.planType, plan, index)
                        return member
                    })
                }
            }

            break;
        }
    }

    return plans;
}

// Helper function to calculate new total basic life monthly premium based off adjusted rates
const getBasicLifeStdLtdMonthlyPremium = (plan, rates, productType, isMMRCase = false) => {
    const isClassing = plan.hasOwnProperty('classRates');
    let individualRates;

    if (isClassing) {
        const slcClass = plan.selectedClass;
        individualRates = plan.classRates[slcClass].quote.censusRates
    } else {
        individualRates = plan?.censusRates ? plan.censusRates : [];
    }

    if (productType === 'life') {
        const basicLifeMonthlyPremium = parseFloat(rates.basicLife) * parseFloat(plan.quote.BasicLifeTotVolAmount) / 1000;
        return basicLifeMonthlyPremium.toFixed(2);
    } else if (productType === 'std') {
        let stdMonthlyPremium = 0;
        // if(isMMRCase){
        //     const { employeeRatesComposite, maximumWeeklyBenefit } = plan;
        //     employeeRatesComposite && employeeRatesComposite.forEach(emp=>{
        //         stdMonthlyPremium += (parseFloat(maximumWeeklyBenefit) / 10) * parseFloat(rates.std);
        //     })
        // }else{
            for (const censusRate of individualRates) {
                stdMonthlyPremium += (parseFloat(censusRate.maximumWeeklyBenefit) / 10) * parseFloat(rates.std);
            }
       // }

        return stdMonthlyPremium.toFixed(2);
    } else {
        let ltdMonthlyPremium = 0;
        // if(isMMRCase){
        //     const { employeeRatesComposite, maximumMonthlyBenefit } = plan;
        //     employeeRatesComposite && employeeRatesComposite.forEach(emp=>{
        //         ltdMonthlyPremium += (parseFloat(maximumMonthlyBenefit) / 100) * parseFloat(rates.ltd);
        //     })
        // }else{
            for (const censusRate of individualRates) {
                ltdMonthlyPremium += (parseFloat(censusRate.maximumMonthlyBenefit) / 100) * parseFloat(rates.ltd);
            }
       // }

        return ltdMonthlyPremium.toFixed(2);
    }
}

/**
 * @desciption Called to set the adjusted rates and new premium for an individual plan
 */
export const handleAdjustRatesAndPremium = (state, action, type = "selected") => {
    let newState;
    if (type === "selected") { // update plans within product reducer
        // We need to update the rates in all places where we store the plan, so copy each of those
        let newPlans = deepCopy(state.plans);
        let newVisiblePlans = deepCopy(state.visiblePlans);
        let newSelectedPlans = deepCopy(state.selectedPlans);
        let newAllPlans = deepCopy(state.allPlans);
        // Find the plan and update the premium for that plan

        newPlans = findAndUpdatePremiumAndRates(
            newPlans, action.planCode, action.premium, action.rates, action.adjustments, false, 
            action.isAgeBanded, action.selectedClass, action.source, action.platform
        );
        newVisiblePlans = findAndUpdatePremiumAndRates(
            newVisiblePlans, action.planCode, action.premium, action.rates, action.adjustments, 
            false, action.isAgeBanded, action.selectedClass, action.source, action.platform
        );
        newSelectedPlans = findAndUpdatePremiumAndRates(
            newSelectedPlans, action.planCode, action.premium, action.rates, action.adjustments, 
            false, action.isAgeBanded, action.selectedClass, action.source, action.platform
        );
        newAllPlans = findAndUpdatePremiumAndRates(
            newAllPlans, action.planCode, action.premium, action.rates, action.adjustments, 
            false, action.isAgeBanded, action.selectedClass, action.source, action.platform
        );

        newState = {
            ...state,
            plans: newPlans,
            visiblePlans: newVisiblePlans,
            selectedPlans: newSelectedPlans,
            allPlans: newAllPlans
        };

        if (newState.specialty) {
            const planType = action.planType.charAt(0).toUpperCase() + action.planType.slice(1);
            localStorage.setItem(`selected_specialty_` + planType, JSON.stringify(newSelectedPlans.map(plan => plan.code)));
        }

    } else { // update enrolled plans in enrollment reducer
        const planType = (action.planType === 'std' || action.planType === 'ltd') ?
            action.planType.toUpperCase()
        :
            action.planType.charAt(0).toUpperCase() + action.planType.slice(1);

        let newEnrolledPlans = deepCopy(state[`enrolled${planType}Plans`]);
        newEnrolledPlans = findAndUpdatePremiumAndRates(
            newEnrolledPlans, action.planCode, action.premium, action.rates, action.adjustments, 
            false, action.isAgeBanded, action.selectedClass, action.source, action.platform
        );

        newState = {
            ...state,
            [`enrolled${planType}Plans`]: newEnrolledPlans
        };
    }
    
    return {
        ...newState
    };
}

export const calculateTotalMonthlyPremium = (plan, ageBands, prodType, isMMRCase = false) => {
    let totalPremium = 0;

    let individualRates;
    const isClassing = plan.hasOwnProperty('classRates');

    if (isClassing) {
        const slcClass = plan.selectedClass;
        individualRates = plan.classRates[slcClass].quote.censusRates;
    } else {
        individualRates = plan?.censusRates ? plan.censusRates : [];
    }

    //Comparing employees age to age-band groupings, to get appropriate rate value
    if(isMMRCase){
        if (plan.member) {
            plan.member.forEach( (mem, index) => {
                let employeeRate;
                let employeePremium;

                for (const ageBand of ageBands) {
                    //Checking if employee's age falls into youngest or oldest groupings
                    if (typeof ageBand.minAge === 'string' || typeof ageBand.maxAge === 'string') {
                        if (typeof ageBand.minAge === 'string') {
                            if (parseFloat(mem.age) <= ageBand.maxAge) {
                                employeeRate = ageBand.rate;
                            }
                        }

                        if (typeof ageBand.maxAge === 'string') {
                            if (parseFloat(mem.age) >= ageBand.minAge) {
                                employeeRate = ageBand.rate;
                            }
                        }
                    }

                    //Check if employee's age falls into current age-band grouping
                    if ((parseFloat(mem.age) >= ageBand.minAge) && (parseFloat(mem.age) <= ageBand.maxAge)) {
                        employeeRate = ageBand.rate;
                    }

                    //If the employee's rate is found, calculate the employee's premium
                    if (!isEmpty(employeeRate)) {
                        if (prodType === 'std') {
                            let maximumWeeklyBenefit = plan.censusRates && plan.censusRates[index]?.maximumWeeklyBenefit ? plan.censusRates[index].maximumWeeklyBenefit : plan.maximumWeeklyBenefit;
                            employeePremium = (parseFloat(maximumWeeklyBenefit) / 10) * parseFloat(employeeRate);
                            mem.total = employeePremium;
                        } else {
                            let maximumMonthlyBenefit = plan.censusRates && plan.censusRates[index]?.maximumMonthlyBenefit ? plan.censusRates[index].maximumMonthlyBenefit : plan.maximumMonthlyBenefit;
                            employeePremium = (parseFloat(maximumMonthlyBenefit) / 100) * parseFloat(employeeRate);
                            mem.total = employeePremium;
                        }

                        totalPremium += employeePremium;
                        break;
                    }
                }
            })
        }
    }else{
        for (const censusRate of individualRates) {
            let employeeRate;
            let employeePremium;
    
            for (const ageBand of ageBands) {
                //Checking if employee's age falls into youngest or oldest groupings
                if (typeof ageBand.minAge === 'string' || typeof ageBand.maxAge === 'string') {
                    if (typeof ageBand.minAge === 'string') {
                        if (parseFloat(censusRate.age) <= ageBand.maxAge) {
                            employeeRate = ageBand.rate;
                        }
                    }
    
                    if (typeof ageBand.maxAge === 'string') {
                        if (parseFloat(censusRate.age) >= ageBand.minAge) {
                            employeeRate = ageBand.rate;
                        }
                    }
                }
    
                //Check if employee's age falls into current age-band grouping
                if ((parseFloat(censusRate.age) >= ageBand.minAge) && (parseFloat(censusRate.age) <= ageBand.maxAge)) {
                    employeeRate = ageBand.rate;
                }
    
                //If the employee's rate is found, calculate the employee's premium
                if (!isEmpty(employeeRate)) {
                    if (prodType === 'std') {
                        employeePremium = (parseFloat(censusRate.maximumWeeklyBenefit) / 10) * parseFloat(employeeRate);
                    } else {
                        employeePremium = (parseFloat(censusRate.maximumMonthlyBenefit) / 100) * parseFloat(employeeRate);
                    }
    
                    totalPremium += employeePremium;
                    break;
                }
            }
        }
    }

    return totalPremium.toFixed(2);
}