import * as types from '../../actions/actionTypes';
import { handleRequestPlans, handleReceiveSpecialtyPlans, handleRequestRates, handleReceiveSpecialtyRates, handleReceiveSpecialtyAllPlans } from './helpers/fetchingReducerHelpers';
import {handleUpdatePlan, handleUpdateSuppLifeSelectedPlan,handleUpdateAllPlans, handleChangeFilterCriteria, handleChangeSortOrder, handleResetFilterCriteria, handleTogglePreferredPlans, handleEmployeeClassFilter } from './helpers/filteringReducerHelpers';
import { initialFilterCriteria, initialState, initialfilterTotalsCache } from './consts/suppLifeReducerConsts';
import { setAdvanceFilterData,setAdvanceFilterWith30Plans, handleFilterChange, viewFilteredPlans, resetFitlers, setBasicFilterDirectly, setBasicFilterDirectlyUhc } from './helpers/advanceFilterHelper'
import {SET_SUPP_LIFE_COMPONENTS} from "../../actions/actionTypes";
import deepCopy from "../../utils/deepCopy";
import {receiveSuppLifePlans} from "./suppDepLifeReducer";

const requestSuppLifePlans = (state, action) => {
    return {
        ...state,
        isFetchingPlans: true,
        quickQuotePlansError: null,
    }
}

export default function suppLife(state = initialState, action) {

    switch (action.type) {
        case types.SERVICE.SUPPLIFE.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(state,action, initialFilterCriteria, initialFilterCriteria);
        // case types.SERVICE.SUPPLIFE.REQUEST_RENEWAL_PLANS:
        // return handleRequestPlansRenewals(state, initialFilterCriteria, initialFilterCriteria, action.fullProps, 'suppLife');
        case types.REQUEST_SUPP_LIFE_PLANS:
            return requestSuppLifePlans(state, action);
        case types.RECEIVE_SUPP_LIFE_PLANS:
            return receiveSuppLifePlans(state, action);
        case types.SERVICE.SUPPLIFE.RECEIVE_QUICK_QUOTE_PLANS:
            return handleReceiveSpecialtyPlans(state, action);

        case types.SERVICE.SUPPLIFE.REQUEST_QUICK_QUOTE_RATES:
            return handleRequestRates(state);

        case types.SERVICE.SUPPLIFE.RECEIVE_QUICK_QUOTE_RATES:
            return handleReceiveSpecialtyRates(state, action);

        case types.SERVICE.SUPPLIFE.SET_BASIC_FILTERS_DIRECTLY:
            return setBasicFilterDirectly(state);

        case types.SERVICE.SUPPLIFE.RECEIVE_QUICK_QUOTE_ALL_PLANS:
            return handleReceiveSpecialtyAllPlans(state, action.payload);

        case types.SERVICE.SUPPLIFE.UPDATE_PLAN:
            return handleUpdatePlan(state, action);

        case types.SERVICE.SUPPLIFE.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, initialFilterCriteria);

        case types.SERVICE.SUPPLIFE.APPLY_FILTER:
            return handleFilterChange(state, action.payload);

            case types.SERVICE.SUPPLIFE.VIEW_FILTERED_PLANS:
                return viewFilteredPlans(state, action.payload);

            case types.SERVICE.SUPPLIFE.RESET_FILTERS:
                return resetFitlers(state);

        case types.SERVICE.SUPPLIFE.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlansMap: {},
                selectedPlans: [],
            };

        case types.SERVICE.SUPPLIFE.CLEAR_SELECTED_VISIBLE_PLANS:
            return handleSelectedVisiblePlans(state, action);

            case types.SERVICE.SUPPLIFE.SET_ADVANCE_FILTER_DATA:
                return setAdvanceFilterData(state, state.allPlans);

            case types.SERVICE.SUPPLIFE.SET_ADVANCE_FILTER_30_PLANS:
            return setAdvanceFilterWith30Plans(state, state.allPlans);

            case types.SERVICE.SUPPLIFE.UPDATE_SELECTED_PLAN:
             return handleUpdateSuppLifeSelectedPlan(state,action);

            case types.SERVICE.SUPPLIFE.UPDATE_ALL_PLANS:
                return handleUpdateAllPlans(state, action);

                case types.SERVICE.LIFE.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);
            case types.RESET_FLOW:
            case types.RESET_SHOPPING_FLOW_SPECIALTY:
            case types.RESET_TO_INITIALSTATE:
            return initialState
        default:
            return state
    }
}
export const handleSelectedVisiblePlans = (state, action) => {
    const newVisiblePlans = deepCopy(state.visiblePlans);
    let newAllPlans = deepCopy(state.allPlans);
    let newPlans = deepCopy(state.plans);
    let updatedVisiblePlans = newVisiblePlans.map(plan => {
        if (plan.selected === true) {
            return {
              ...plan,
              SLSpPlan: { ...plan.SLSpPlan, selected: false },
              SLChPlan: { ...plan.SLChPlan, selected: false },
              selected: false,
            };
        }

        return plan;
    });

     newPlans = newPlans.map((plan) => {
       return {
         ...plan,
         SLSpPlan: { ...plan.SLSpPlan, selected: false },
         SLChPlan: { ...plan.SLChPlan, selected: false },
         selected: false,
       };
     });
     newAllPlans = newAllPlans.map((plan) => {
       return {
         ...plan,
         SLSpPlan: { ...plan.SLSpPlan, selected: false },
         SLChPlan: { ...plan.SLChPlan, selected: false },
         selected: false,
       };
     });

    return {
        ...state,
        visiblePlans: updatedVisiblePlans,
        plans: newPlans,
        allPlans: newAllPlans
    };
};
