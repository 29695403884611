import { MMDDYYYYtoIsoString, isoStringToMMDDYYYY, convertDateString } from '../formatters/dates';
import { formComparePlanObject } from '../../components/activities/Renewal/RenewalsCommonComponents/ComparePlans/RenewalComparePlans';
import { identifyPlatform } from '../../components/activities/Renewal/Review/reviewUtils';
import setCoverages from './proposalUtils/setCoverages';
import { MARKET_TYPES, getMarketType } from '../businessLogic/platformUtils'

const deepCopy = require('../deepCopy');

export const extractSalesRep = (state) => {
    const salesRep = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment
        && state.reviewCards.ownerAssignment.salesRep) {
        state.reviewCards.ownerAssignment.salesRep.forEach((sales) => {
            const data = {
                name: sales ? sales.salesRepName ? sales.salesRepName : 'N/A' : 'N/A',
                phone: 'N/A',
            };
            salesRep.push(data);
        });
    }
    return salesRep;
};
export const extractBrokers = (state) => {
    const producer = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.producer) {
        state.reviewCards.ownerAssignment.producer.map((prod, i) => {
            const data = {
                name: prod ? prod.producerName ? prod.producerName : 'N/A' : 'N/A',
                phone: prod ? prod.producerPhone ? prod.producerPhone : 'N/A' : 'N/A',
            };
            producer.push(data);
        });
    }
    return producer;
};
export const extractGeneralAgent = (state) => {
    const generalAgent = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.generalAgent) {
        state.reviewCards.ownerAssignment.generalAgent.map((agent, i) => {
            const data = {
                name: agent ? agent.generalAgentName ? agent.generalAgentName : 'N/A' : 'N/A',
                phone: 'N/A',
            };
            generalAgent.push(data);
        });
    }
    return generalAgent;
};

export const extractAgency = (state) => {
    const agencies = [];
    if (state.reviewCards && state.reviewCards.ownerAssignment && state.reviewCards.ownerAssignment.agency) {
        state.reviewCards.ownerAssignment.agency.map((agency, i) => {
            const data = {
                name: agency ? agency.agencyName ? agency.agencyName : 'N/A' : 'N/A',
                phone: agency ? agency.agencyPhone ? agency.agencyPhone : 'N/A' : 'N/A',
            };
            agencies.push(data);
        });
    }
    return agencies;
};

export const getCovergaes = (coverage) => {
    switch(coverage){
        case 'EE':
            return 'EO';
        case 'EE/CH':
            return 'EC';
        case 'EE/SP':
            return 'ES';           
        case 'EE/FAM':
            return 'EF';
        default:
            return coverage;

    }
};

export const findCommonPlans = (state) => {
    let finalDentalPlans; let finalVisionPlans; let
        finalLifePlans;
    const commonPlansArray = {
        dentalPlans: [],
        visionPlans: [],
        lifePlans: [],
    };
    if (state.decide.shoppingDentalPlans || state.decide.shoppingVisionPlans || state.decide.shoppingLifePlans) {
        finalDentalPlans = state.decide.shoppingDentalPlans ? [...state.decide.shoppingDentalPlans] : [];
        finalVisionPlans = state.decide.shoppingVisionPlans ? [...state.decide.shoppingVisionPlans] : [];
        finalLifePlans = state.decide.shoppingLifePlans ? [...state.decide.shoppingLifePlans] : [];
        if (state.decide.dental) {
            if (state.decide.dental.selectedPlans) {
                state.decide.dental.selectedPlans.map((dentalAlternate) => {
                    let planExist = false;
                    finalDentalPlans.map((dentalPlans, index)  => {
                        if (dentalAlternate.code === dentalPlans.code) {
                            planExist = true;
                            finalDentalPlans[index] = { //// added for Rate Override, as state.dental contains overriden rates
                                ...dentalPlans,
                                ...dentalAlternate,
                            }
                        }
                    });
                    if (!planExist) { finalDentalPlans.push(dentalAlternate); }
                });
            }
        }
        if (state.decide.vision) {
            if (state.decide.vision.selectedPlans) {
                state.decide.vision.selectedPlans.map((visionAlternate) => {
                    let planExist = false;
                    finalVisionPlans.map((visionPlans, index) => {
                        if (visionAlternate.code === visionPlans.code) { 
                            planExist = true; 
                            finalVisionPlans[index] = { //// added for Rate Override, as state.vision contains overriden rates
                                ...visionPlans,
                                ...visionAlternate,
                            }
                        }
                    });
                    if (!planExist) { finalVisionPlans.push(visionAlternate); }
                });
            }
        }

        if (state.decide.life) {
            if (state.decide.life.selectedPlans) {
                state.decide.life.selectedPlans.map((lifeAlternate, index) => {
                    let planExist = false;
                    finalLifePlans.map((lifePlans) => {
                        if (lifeAlternate.code === lifePlans.code) { 
                            planExist = true;
                            finalLifePlans[index] = { //// added for Rate Override, as state.life contains overriden rates
                                ...lifePlans,
                                ...lifeAlternate,
                            } }
                    });
                    if (!planExist) { finalLifePlans.push(lifeAlternate); }
                });
            }
        }
        commonPlansArray.dentalPlans = finalDentalPlans;
        commonPlansArray.visionPlans = finalVisionPlans;
        commonPlansArray.lifePlans = finalLifePlans;
    }

    return commonPlansArray;
};

const filterZeroMembershipPlans = (state) =>{
    let filteredPlans= []
    if(state.decide.renewalCaseData.situsState === "CA")
    {
        filteredPlans = state.decide.shoppingPlans.filter(plan=>!(plan.quote === "0.00" || plan.quote ==="0"))
    }
    else
    {
        filteredPlans = state.decide.shoppingPlans
    }
    return filteredPlans
}

export const decideHelperMMR = (state, useEnrolledPlans) => {
    let pdfData;
    const effectiveDateRenewal = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(state.decide.renewalCaseData.effectiveDate));
    const groupSize = deepCopy(state.decide.renewalData.memberGroup.locations.employeeCounts[0] ? state.decide.renewalData.memberGroup.locations.employeeCounts[0].type === 'FTE' ? state.decide.renewalData.memberGroup.locations.employeeCounts[0].count : 0 : 0);
    const eligibleEmployees = state.review?.renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '';
    let employeesRenArray = deepCopy(state.decide.renewalData.members);
    employeesRenArray.sort((a, b) => a.lastName.localeCompare(b.lastName)); // Displays employees alphabetically
    const ibrEmployees = deepCopy(state.decide.renewalData.Ibr.quote.census.employee);
    let employeesRenewal = [];
    let groupCount = 0;
    const commonPlans = findCommonPlans(state);

    const medicalPlans = !useEnrolledPlans ? filterZeroMembershipPlans(state) : state.rules.enrollment.enrolledPlans.standalone || [];
    const dentalPlans = !useEnrolledPlans ? commonPlans.dentalPlans : state.rules.enrollment.enrolledDentalPlans || [];
    const visionPlans = !useEnrolledPlans ? commonPlans.visionPlans : state.rules.enrollment.enrolledVisionPlans || [];
    const lifePlans = !useEnrolledPlans ? commonPlans.lifePlans : state.rules.enrollment.enrolledLifePlans || [];
    const depLifePlans = !useEnrolledPlans ? state.decide.dependentLife.selectedPlans || [] : state.rules.enrollment.enrolledDependentLifePlans || [];
    const stdPlans = !useEnrolledPlans ? state.decide.std?.selectedPlans || [] : state.rules.enrollment.enrolledSTDPlans || [];
    const ltdPlans = !useEnrolledPlans ? state.decide.ltd?.selectedPlans || [] : state.rules.enrollment.enrolledLTDPlans || [];
    const suppLifePlans = !useEnrolledPlans ? state.decide.suppLife?.selectedPlans || [] : state.rules.enrollment.enrolledSuppLifePlans || [];
    const planRates = state.decide.renewalData?state.decide.renewalData.planRates?state.decide.renewalData.planRates:'':'';
    const prods = [];
    if (medicalPlans && medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans && dentalPlans.length > 0) prods.push('dental');
    if (visionPlans && visionPlans.length > 0) prods.push('vision');
    if (lifePlans && lifePlans.length > 0) prods.push('life');
    if (depLifePlans && depLifePlans.length > 0) prods.push('lifeDep');
    if (stdPlans && stdPlans.length > 0) prods.push('std');
    if (ltdPlans && ltdPlans.length > 0) prods.push('ltd');
    if (suppLifePlans && suppLifePlans.length > 0) prods.push('suppLife');

    let finalVisionPlan = [];
    let isPushed=false;
    let isRateGuaranteePlan;
    if(identifyPlatform(state.decide.renewalData) === 'CIRRUS' ){
     visionPlans.map(plan=>{
        isPushed=false;
        planRates &&  planRates.map(x=>{
             if(x.planCode==plan.planCode && x.renewalPlanType && x.renewalPlanType=="Renewal"){
                isPushed=true;
                 isRateGuaranteePlan={
                     ...plan,
                     rateGuarBegin:x.rateGuarBegin,
                     rateGuarEnd:x.rateGuarEnd
                 }
                 finalVisionPlan.push(isRateGuaranteePlan);
             }
         })
         if(!isPushed){
            finalVisionPlan.push(plan);
         }
     })
    }else{
        finalVisionPlan=visionPlans
    }
    const planOfferingData = deepCopy(state.decide.renewalData.Ibr.quote.planOfferingData[0].existingPlanPkg.plans.plan);
    employeesRenArray.map((emp) => {
        const matchedEmp =ibrEmployees.find((emp1)=>emp1.xref==emp.employeeId);
        const dependentArray = matchedEmp && matchedEmp.dependents ? matchedEmp.dependents.dependent.map((dep) => dep.depType) : [];
        if(dependentArray.includes('S') && dependentArray.includes('C')){
            emp.coverageLevel = 'EF';
        }
        else if(dependentArray.includes('S')){
            emp.coverageLevel = 'ES';
        }
        else if(dependentArray.includes('C')){
            emp.coverageLevel = 'EC';
        }
        else{
            emp.coverageLevel = 'EO';
        }
        let productCoverages = {};
        matchedEmp.electedPlans.plan.map((plan) => {
            const matchedPlan = planOfferingData.find((plan1) => plan1.planID === plan.planID);
            if(matchedPlan){
                const productType = matchedPlan.productLineType === 'BASIC_LIFE_EE' ? 'basicLife'  :matchedPlan.productLineType.toLowerCase();
                const coverageType = getCovergaes(plan.coverageType);
                productCoverages[productType] = coverageType;
            }
        })
        emp.coverages = productCoverages;

    });

    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Self') {
            // temp.coverages = {
            //     medical: emp.coverageLevel,
            //     dental: emp.coverageLevel,
            //     vision: emp.coverageLevel,
            //     basicLife: emp.coverageLevel,
            //     dependentLife: emp.coverageLevel,
            //     std: emp.coverageLevel,
            //     ltd: emp.coverageLevel,
            // };

            temp.dependents = [];
            temp.dob = convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY');
            temp.employeeStatus = emp.status ? emp.status : '';
            temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
            employeesRenewal.push(temp);
            groupCount += 1;
        }
    });
    const groupSizeRenewal = employeesRenewal.length;
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Spouse' || temp.relationship === 'Civil Union Partner' || temp.relationship === 'Domestic Partner' || temp.relationship === 'Life Partner' || temp.relationship === 'Child' || temp.relationship === 'Dependent Child') {
            if (employeesRenewal && employeesRenewal.length > 0) {
                employeesRenewal.forEach((y, i) => {
                    if (temp.employeeId === y.employeeId) {
                        temp.coverages = {
                            medical: emp.coverages.medical,
                            dental: emp.coverages.dental,
                            vision: emp.coverages.vision,
                            basicLife: emp.coverages.life || emp.coverages.basicLife,
                            dependentLife: emp.coverages.dependentLife
                            // std: emp.coverageLevel,
                            // ltd: emp.coverageLevel,
                        };
                        temp.dob = convertDateString(emp.birthDate, '-', 'YYYYMMDD', '-', 'MMDDYYYY');
                        temp.employeeStatus = emp.status ? emp.status : '';
                        temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
                        temp.relationship = temp.relationship ? temp.relationship : '';
                        employeesRenewal[i].dependents.push(temp);
                    }
                });
            }
        }
    });
    const covLevelEmp = [];
    covLevelEmp.push(employeesRenewal);
    const tempSelectedProducts = {
        medical: medicalPlans.length > 0,
        dental: dentalPlans.length > 0,
        vision: visionPlans.length > 0,
        basicLife: lifePlans.length > 0,
        dependentLife: depLifePlans.length > 0,
        std: stdPlans.length > 0,
        ltd: ltdPlans.length > 0,
        suppLife: suppLifePlans.length > 0
    };

    employeesRenewal = setCoverages(tempSelectedProducts, covLevelEmp, medicalPlans, dentalPlans, visionPlans, lifePlans, depLifePlans, stdPlans, ltdPlans, suppLifePlans);
    const companyProfileRenewal = deepCopy(state.companyProfile);
    // companyProfileRenewal.sicCode = deepCopy(state.decide.renewalData.memberGroup.industryCode)
    if (companyProfileRenewal.sicCode === '' || companyProfileRenewal.sicCode == null) {
        companyProfileRenewal.sicCode = '-';
    }
    companyProfileRenewal.sicData = {
        // division: "",
        // majorGroup: 99,
        // industryGroup: 999,
        sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
        description: '-',
        // divisionDescription: "Public Administration",
        // majorGroupDescription: "Nonclassifiable Establishments",
        // industryGroupDescription: "Nonclassifiable Establishments",
        productAvailability: {
            dental: false,
            vision: false,
            life: false,
            dependentLife: false,
            ltd: false,
            std: false,
        },
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    let zipObj = {
        zipCode: state.decide.renewalCaseData.zipCode,
        stateCode: state.decide.renewalCaseData.situsState,
        state: state.decide.renewalCaseData.situsState
    }
    companyProfileRenewal.employees = employeesRenewal;
    companyProfileRenewal.effectiveDate = effectiveDateRenewal;
    companyProfileRenewal.locations[0].fte = employeesRenewal[0].length;
    companyProfileRenewal.locations[0].totalEligEmps = state.decide.renewalData.Ibr.group.primaryLocation.totalEligible;
    companyProfileRenewal.selectedProducts = state.companyProfile.userCardsSelectedProducts;
    companyProfileRenewal.companyName = state.decide.renewalData.Ibr.group.employer.name;
    const zipObjRenewal = zipObj;
    const specialtyCriteriaRenewal = state.specialtyCriteria;

    const { customPlanNames } = state.decide;

    let isComposite = false;
    if (medicalPlans && medicalPlans.length > 0) {
        medicalPlans.forEach((plan) => {
            if (plan.isComposite) { isComposite = true; }
        });
    }

    if (Array.isArray(medicalPlans) && medicalPlans.length > 0) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
            }
        });
    }

    let currentPlans = decideCurrentPlanMMR(state.decide);

    pdfData = {
        effectiveDate: effectiveDateRenewal,
        eligibleEmployees,
        groupSize: groupSizeRenewal,
        zipObj: zipObjRenewal,
        quickQuoteRequest: {
            zipObj: zipObjRenewal,
            effectiveDate: effectiveDateRenewal,
            otherDetails: {
                includeSpecialty: dentalPlans.length > 0 || visionPlans.length > 0 || lifePlans.length > 0,
                sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
                selectedProducts: prods,
                isPreferredPlans: true,
            },
        },
        isCirrus: !!((identifyPlatform(state.decide.renewalData) === 'CIRRUS' || identifyPlatform(state.decide.renewalData) === 'DUAL')),
        companyProfile: companyProfileRenewal,
        overall:state.overall,
        brokers: extractBrokers(state),
        generalAgent: extractGeneralAgent(state),
        salesRep: extractSalesRep(state),
        agencies: extractAgency(state),
        // salesRep: [
        //     {
        //         name: state.reviewCards.ownerAssignment.salesRep[0] ? state.reviewCards.ownerAssignment.salesRep[0].salesRepName : "N/A",
        //         phone: "N/A"
        //     }
        // ],
        employees: employeesRenewal,
        medicalPlans,
        dental: { selectedPlans: dentalPlans },
        vision: { selectedPlans: finalVisionPlan },
        life: { selectedPlans: lifePlans },
        lifeDep: { selectedPlans: depLifePlans },
        std: { selectedPlans: stdPlans },
        ltd: { selectedPlans: ltdPlans },
        suppLife: {selectedPlans: suppLifePlans},
        sendMail: false,
        ratingMethod: {
            age: !isComposite,
            tier: isComposite,
        },
        specialtyCriteria: specialtyCriteriaRenewal,
        creationDate: new Date().toLocaleString('en-us', { timeZoneName: 'short' }),
        currentPlans 
    };
    return pdfData;
};
export const reviewHelperMMR = (state) => {
    let pdfData;
    const effectiveDateRenewal = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(state.review.renewalCaseData.effectiveDate));
    const groupSize = deepCopy(state.review.renewalData.memberGroup.locations.employeeCounts[0] ? state.review.renewalData.memberGroup.locations.employeeCounts[0].count : '3');
    const eligibleEmployees = state.review?.renewalData?.Ibr?.group?.primaryLocation?.totalEligible || '';
    let employeesRenArray = deepCopy(state.review.renewalData.members);
    employeesRenArray.sort((a, b) => a.lastName.localeCompare(b.lastName)); // Displays employees alphabetically
    const ibrEmployees = deepCopy(state.review.renewalData.Ibr.quote.census.employee);
    let employeesRenewal = [];
    let groupCount = 0;
    const formattedPlan = [];
    let isComposite = false;
    if(state.review && state.review.medical.selectedPlans && state.review.medical.selectedPlans.length > 0){
        const renewalSelected = state.review.medical.selectedPlans.filter((plan) => ((plan.renewalPlanType === 'renewing') && plan.selected === true));
        renewalSelected.forEach((renewalPlan) => {
            if (renewalPlan && (renewalPlan.defaultRatingType === 'composite')) { isComposite = true; }
            formattedPlan.push(renewalPlan);
        });
    } 
    
    // if (state.review.shoppingPlans && state.review.shoppingPlans.length > 0) {
    //     const renewalSelected = state.review.shoppingPlans.filter((plan) => ((plan.planType === "Medical") && (plan.renewalPlan !== true || !plan.hasOwnProperty('renewalPlan'))));
    //     renewalSelected.forEach((renewalPlan) => {
    //         if (renewalPlan && renewalPlan.isComposite === 'Composite4Tier') { isComposite = true; }
    //         formattedPlan.push(renewalPlan);
    //     });
    // }

    let medicalPlans = formattedPlan;
    let dentalPlans = state.review.dental.selectedPlans || [];
    let visionPlans = state.review.vision.selectedPlans || [];
    let lifePlans = state.review.life.selectedPlans || [];
    let depLifePlans = state.review.dependentLife.selectedPlans || [];

    const planRates = state.review.renewalData ? state.review.renewalData.planRates ? state.review.renewalData.planRates : '' : '';
    const prods = [];

    if (!medicalPlans){
        medicalPlans=[]
    }
    if (!dentalPlans){
        dentalPlans=[]
    }
    if (!visionPlans){
        visionPlans=[]
    }
    if (!lifePlans){
        lifePlans=[]
    }
    if (!depLifePlans){
        depLifePlans=[]
    }
    
    if (medicalPlans && medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans && dentalPlans.length > 0) prods.push('dental');
    if (visionPlans && visionPlans.length > 0) prods.push('vision');
    if (lifePlans && lifePlans.length > 0) prods.push('life');
    if (depLifePlans && depLifePlans.length > 0) prods.push('lifeDep');
    
    let  finalVisionPlan = [];
    let isRateGuaranteePlan;
    let isPushed=false;
    finalVisionPlan=visionPlans
    
    employeesRenArray.map((emp) => {
        const matchedEmp =ibrEmployees.find((emp1)=>emp1.xref==emp.employeeId);
        const dependentArray = matchedEmp.dependents?matchedEmp.dependents.dependent.map((dep) => dep.depType) : [];
        if(dependentArray.includes('S') && dependentArray.includes('C')){
            emp.coverageLevel = 'EF';
        }
        else if(dependentArray.includes('S')){
            emp.coverageLevel = 'ES';
        }
        else if(dependentArray.includes('C')){
            emp.coverageLevel = 'EC';
        }
        else{
            emp.coverageLevel = 'EO';
        }

    });

    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Self') {
            temp.coverages = {
                medical: emp.coverageLevel,
                dental: emp.coverageLevel,
                vision: emp.coverageLevel,
                basicLife: emp.coverageLevel,
                dependentLife: emp.coverageLevel,
                std: emp.coverageLevel,
                ltd: emp.coverageLevel,
            };

            temp.dependents = [];
            temp.dob = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
            temp.employeeStatus = emp.status ? emp.status : '';
            temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
            employeesRenewal.push(temp);
            groupCount += 1;
        }
    });
    const groupSizeRenewal = employeesRenewal.length;
    employeesRenArray.map((emp) => {
        const temp = deepCopy(emp);
        if (temp.relationship === 'Spouse' || temp.relationship === 'Civil Union Partner' || temp.relationship === 'Domestic Partner' || temp.relationship === 'Life Partner' || temp.relationship === 'Child' || temp.relationship === 'Dependent Child') {
            if (employeesRenewal && employeesRenewal.length > 0) {
                employeesRenewal.forEach((y, i) => {
                    if (temp.employeeId === y.employeeId) {
                        temp.coverages = {
                            medical: emp.coverageLevel,
                            dental: emp.coverageLevel,
                            vision: emp.coverageLevel,
                            basicLife: emp.coverageLevel,
                            dependentLife: emp.coverageLevel,
                            std: emp.coverageLevel,
                            ltd: emp.coverageLevel,
                        };
                        temp.dob = MMDDYYYYtoIsoString(isoStringToMMDDYYYY(emp.birthDate));
                        temp.employeeStatus = emp.status ? emp.status : '';
                        temp.outOfArea = emp.outOfArea ? emp.outOfArea : 'N/A';
                        temp.relationship = temp.relationship ? temp.relationship : '';
                        employeesRenewal[i].dependents.push(temp);
                    }
                });
            }
        }
    });

    const covLevelEmp = [];
    covLevelEmp.push(employeesRenewal);
    const tempSelectedProducts = {
        medical: medicalPlans.length > 0,
        dental: dentalPlans.length > 0,
        vision: visionPlans.length > 0,
        basicLife: lifePlans.length > 0,
        dependentLife: depLifePlans.length > 0
    };

    employeesRenewal = setCoverages(tempSelectedProducts, covLevelEmp, medicalPlans, dentalPlans, visionPlans, lifePlans, depLifePlans);
    const companyProfileRenewal = deepCopy(state.companyProfile);
    // companyProfileRenewal.sicCode = deepCopy(state.review.renewalData.memberGroup.industryCode)
    if (companyProfileRenewal.sicCode === '' || companyProfileRenewal.sicCode == null) {
        companyProfileRenewal.sicCode = '-';
    }
    companyProfileRenewal.sicData = {
        // division: "",
        // majorGroup: 99,
        // industryGroup: 999,
        sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
        description: '-',
        // divisionDescription: "Public Administration",
        // majorGroupDescription: "Nonclassifiable Establishments",
        // industryGroupDescription: "Nonclassifiable Establishments",
        productAvailability: {
            dental: false,
            vision: false,
            life: false,
            dependentLife: false,
            ltd: false,
            std: false,
        },
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };

    let zipObj = {
        zipCode: state.review.renewalCaseData.zipCode,
        stateCode: state.review.renewalCaseData.situsState,
        state: state.review.renewalCaseData.situsState
    }
    companyProfileRenewal.employees = employeesRenewal;
    companyProfileRenewal.locations[0].fte = employeesRenewal.length;
    companyProfileRenewal.effectiveDate = effectiveDateRenewal;
    companyProfileRenewal.selectedProducts = state.companyProfile.userCardsSelectedProducts;
    const zipObjRenewal = zipObj
    const specialtyCriteriaRenewal = state.specialtyCriteria;

    specialtyCriteriaRenewal.sicObj = {
        display: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
    };
    const { customPlanNames } = state.review;

    if (Array.isArray(medicalPlans) && medicalPlans.length > 0) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
            }
        });
    }

    pdfData = {
        effectiveDate: effectiveDateRenewal,
        groupSize: groupSizeRenewal,
        eligibleEmployees,
        zipObj: zipObjRenewal,
        quickQuoteRequest: {
            zipObj: zipObjRenewal,
            effectiveDate: effectiveDateRenewal,
            otherDetails: {
                includeSpecialty: dentalPlans.length > 0 || visionPlans.length > 0 || lifePlans.length > 0,
                sicCode: companyProfileRenewal.sicCode ? companyProfileRenewal.sicCode : '-',
                selectedProducts: prods,
                isPreferredPlans: true,
            },
        },
        isCirrus: !!((identifyPlatform(state.review.renewalData) === 'CIRRUS' || identifyPlatform(state.review.renewalData) === 'DUAL')),
        companyProfile: companyProfileRenewal,
        brokers: extractBrokers(state),
        generalAgent: extractGeneralAgent(state),
        salesRep: extractSalesRep(state),
        agencies: extractAgency(state),

        employees: employeesRenewal,
        medicalPlans,
        dental: { selectedPlans: dentalPlans },
        vision: { selectedPlans: finalVisionPlan },
        life: { selectedPlans: lifePlans },
        lifeDep: { selectedPlans: depLifePlans },
        sendMail: false,
        ratingMethod: {
            age: !isComposite,
            tier: isComposite,
        },
        specialtyCriteria: specialtyCriteriaRenewal,
        creationDate: new Date().toLocaleString('en-us', { timeZoneName: 'short' }),
    };
    return pdfData;
};

export const decideCurrentPlanMMR = (decide) => {
    let currentPlanData;
    let medicalPlans = [];
    let dentalPlans = [];
    let visionPlans = [];
    let lifePlans = [];
    let depLifePlans = [];
    let ltdPlans = [];
    let stdPlans = [];
    let suppLifePlans =[]

    decide.medical && decide.medical.set && decide.medical.set.forEach((plan, i) => {
        if(decide?.renewalCaseData?.situsState === "CA" ){
            if(!(plan[0].quote === "0.00" || plan[0].quote ==="0"))
            {
                medicalPlans.push(plan[0]);
            }           
        }
        else{
            medicalPlans.push(plan[0]);
        }
    });
    decide.dental && decide.dental.set && decide.dental.set.forEach((plan, i) => {
        dentalPlans.push(plan[0]);
    });
    decide.vision && decide.vision.set && decide.vision.set.forEach((plan, i) => {
        visionPlans.push(plan[0]);
    });
    decide.life && decide.life.set && decide.life.set.forEach((plan, i) => {
        lifePlans.push(plan[0]);
    });
    decide.dependentLife && decide.dependentLife.set && decide.dependentLife.set.forEach((plan, i) => {
        depLifePlans.push(plan[0]);
    });
    decide.ltd && decide.ltd.set && decide.ltd.set.forEach((plan, i) => {
        ltdPlans.push(plan[0]);
    });
    decide.std && decide.std.set && decide.std.set.forEach((plan, i) => {
        stdPlans.push(plan[0]);
    });
    decide.suppLife && decide.suppLife.set && decide.suppLife.set.forEach((plan, i) => {
        suppLifePlans.push(plan[0]);
    });



    const prods = [];
    if (medicalPlans && medicalPlans.length > 0) prods.push('medical');
    if (dentalPlans && dentalPlans.length > 0) prods.push('dental');
    if (visionPlans && visionPlans.length > 0) prods.push('vision');
    if (lifePlans && lifePlans.length > 0) prods.push('life');
    if (depLifePlans && depLifePlans.length > 0) prods.push('lifeDep');
    if (ltdPlans && ltdPlans.length > 0) prods.push('ltd');
    if (stdPlans && stdPlans.length > 0) prods.push('std');
    if (suppLifePlans && stdPlans.length > 0) prods.push('suppLife');


    const { customPlanNames } = decide;

    if (Array.isArray(medicalPlans) && medicalPlans.length > 0) {
        medicalPlans.map((plan) => {
            if (plan.code) {
                plan.customPlanName = customPlanNames && customPlanNames.medical && customPlanNames.medical[plan.code] ? customPlanNames.medical[plan.code] : '';
            }
        });
    }
    currentPlanData = {
        medicalPlans,
        dentalPlans,
        visionPlans,
        lifePlans,
        depLifePlans,
        ltdPlans,
        stdPlans,
        suppLifePlans
    };
     return currentPlanData;
};
