import { stateswithOptionalEmployeeStatus } from "../../../../../uhcTemplate/templateParser/constants";

export const employeeCols = (isNewYork, zipIsRequired, salaryIsOptional, classOptions, overallPlatform, isBasicLifeSelected, stateCode,isLifeStdLtdSelected) => {
    
    let employeeJSON = {
    columns: [
        {
            name: 'First Name',
            keyName: 'firstName',
            type: 'text',
            size: 'large',
            validationRules: {
                required: false,
                name: true
            },
        },
        {
            name: 'Last Name',
            keyName: 'lastName',
            type: 'text',
            size: 'large',
            validationRules: {
                required: false,
                name: true
            },
        },
        {
            name: 'Suffix (Optional)',
            keyName: 'suffix',
            type: 'dropdown',
            options: [
                {'':''},
                {'Jr.': 'JR'},
                {'Sr.': 'SR'},
                {'I': '1'},
                {'II': '2'},
                {'III': '3'},
                {'IV': '4'},
                {'V': '5'},
                {'VI': '6'},
                {'VII': '7'},
                {'VIII': '8'}
            ],
            size: 'tiny',
            validationRules: {
                required: false,
            },
        },
        {
            name: 'Date of Birth',
            keyName: 'dob',
            type: 'text',
            size: 'large',
            placeholder: 'MM/DD/YYYY',
            validationRules: {
                required: isRequiredCheck(isNewYork,isBasicLifeSelected,overallPlatform),
                autoDate: true,
                date: true,
                validAge: true
            },
        },
        {
            name: 'Age',
            keyName: 'age',
            type: 'text',
            size: 'tiny',
            validationRules: {
                required: isRequiredCheck(isNewYork,isBasicLifeSelected,overallPlatform),
                age: true,
            },
        },
        {
            name: 'Gender',
            keyName: 'gender',
            type: 'dropdown',
            size: 'med',
            // 'Male' is the 1st option because typing something in returns all options containing that substring
            // Typing 'male' doesn't narrow it down, but 'Male' is first option so user can press tab
            // Typing 'f' narrows it down to 'Female' as only option
            options: isNewYork ? ['Male', 'Female', 'Non-Binary'] : ['Male', 'Female'],
            validationRules: {
                 required: isRequiredCheck(isNewYork,isBasicLifeSelected,overallPlatform),
               },
        },
        {
            name: 'Employee Status',
            keyName: 'employeeStatus',
            type: 'dropdown',
            size:  stateswithOptionalEmployeeStatus.includes(stateCode)?'large':"med",
            options: overallPlatform && overallPlatform === 'PRIME' ?
            stateswithOptionalEmployeeStatus.includes(stateCode)? 
            ["Active",
            "COBRA",
            "1099 Independent Contractors"
            ]
            : ['Active', 'COBRA'] : ['Active', 'COBRA', 'Retired'],// show Retired option for NY/CT states
            validationRules: {
                required: true,
            },
        },
        // {
        //     name: 'Special Employee Status',
        //     keyName: 'specialEmployeeStatus',
        //     type: 'dropdown',
        //     size: 'med',
        //     options: ['K1', 'Contractor', 'None'],
        //     defaultValue: 'None',
        //     validationRules: {
        //         required: false,
        //     },
        // },
        {
            name: 'Coverage Types',
            keyName: 'coverages',
            type: 'custom',
            size: 'med',
            validationRules: {
                required: false,
                coverages: true,
            },
        },
        {
            name: 'Out of Area (Medical Only)',
            keyName: 'outOfArea',
            type: 'dropdown',
            placeholder: '',
            options: ['Yes', 'No'],
            size: 'tiny',
            defaultValue: 'No',
            validationRules: {
                required: false,
            },
        },
        {
            name: salaryIsOptional ? 'Salary (Optional)' : 'Salary',
            keyName: 'salary',
            type: 'text',
            size: 'small',
            validationRules: {
                currency: true,
                required: !salaryIsOptional,
            },
        },
        {
            name: zipIsRequired ? 'Employee Zip Code' : 'Employee Zip Code\n(Optional)',
            keyName: 'zipCode',
            type: 'text',
            size: 'med',
            placeholder: 'e.g. 06010',
            validationRules: {
                required: !!zipIsRequired,
                zipCode: true,
            },
        },
    ],
    itemNames: {
        singular: 'N/A', // not used
        plural: 'N/A', // not used
        keyName: 'employees',
    },
    spouse: [],
    children: [],

  }

  if (classOptions && classOptions.length > 0) {

    const outOfAreaIndex = employeeJSON.columns.findIndex(e => {return e.keyName === "outOfArea"})
    const classDropdown = {
      name: 'Employee Classes',
      keyName: 'optedLifeClass',
      type: 'dropdown',
      placeholder: '',
      options: classOptions,
      size: 'small',
      defaultValue: '',
      validationRules: {
          required: true,
      }
  }

    employeeJSON.columns.splice(outOfAreaIndex + 1, 0, classDropdown)
  }

  if(isLifeStdLtdSelected)
  {
      const employeeStatusIndex = employeeJSON.columns.findIndex(e => {return e.keyName === "employeeStatus"});
      const classDropdown = 
      {
            name: 'Special Employee Status',
            keyName: 'specialEmployeeStatus',
            type: 'dropdown',
            size: 'med',
            options: ['K1', 'Contractor', 'None'],
            defaultValue: 'None',
            validationRules: {
                required: false,
            },
        }
    employeeJSON.columns.splice(employeeStatusIndex + 1, 0, classDropdown)
  }

  return employeeJSON
};

export const newEmployee = {
    firstName: '',
    lastName: '',
    suffix: '',
    coverages: {
        basicLife: 'EL',
        dental: 'EE',
        ltd: 'EE',
        medical: 'EE',
        std: 'EE',
        suppLife: 'EE',
        vision: 'EE',
    },
    age: '',
    dob: '',
    salary: '',
    zipCode: '',
    gender: '',
    employeeStatus: 'Active',
    specialEmployeeStatus: 'None',
    status: 'Not Started',
    outOfArea: 'No',
    newEmployee: true, // Using this flag to add fields
    dependents: [],
    pcpAssignment: [],
    productSelection: [],
    waiveCoverage: {
        isWaivingAll: '',
        medicalWaiverReason: '',
    },
    medicalCoverageInfo: {
        medicalWaived: true,
        medicalWaiverReason: '',
        pcpTable: '',
    },
    waiveReason: '',
    optedLifeClass: ''
};

export const newDependent = {
    relationship: '',
    dob: '',
    age: '',
    gender: '',
    newDependent: true,
};

const isRequiredCheck = (isNewYork, isBasicLifeSelected, overallPlatform) => {
  if (overallPlatform && overallPlatform === "CIRRUS") {
    if (isNewYork) {
      if (isBasicLifeSelected) {
          // platform is oxford/cirrus and if selected zip code is newyork
          // age,dob,gender are required when basic life is selected
        return true;
      } else {
          // platform is oxford/cirrus and if selected zip code is newyork
          // age,dob,gender are not required when basic life is not selected
        return false;
      }
    } else {// platform is oxford/cirrus and for oxford age,dob,gender are always required if selected zip code is not newyork
        return true;
    }
  } else { // platform is prime and for prime age,dob,gender are always required
      return true;
  }
};
