import { axios } from '../utils/api/axiosConfig';
import { RESET_COPY_FLAG, SOLARIS, DISABLE_SAVE_AND_EXIT } from './actionTypes';
import { CASE_PROGRESS, getNBCaseProgress, getCaseIndexForNB } from '../reducers/products/helpers/companyProfileConsts';
import apiRoutes from '../utils/api/apiRouteConfig';
import { saveOrUpdateCaseTracking } from './caseTrackAction';
import solarisPayload from '../utils/solaris/solarisPayload';
import sendLog from './logActions';
import { getMarketType, MARKET_TYPES } from '../utils/businessLogic/platformUtils';
import suppLife from '../reducers/products/suppLifeReducer';

/**
 * Compares the old plan selected codes to the new plan selected code and
 * returns whether anything has changed
 * @param {array} newPlans - array of the selected plans from the frontend
 * @param {array} oldPlans - array of the previously selected plans
 *
 * @returns {boolean}
 */
const compareSelectedPlans = (newPlans = [], oldPlans = []) => {
    if (newPlans.length !== oldPlans.length) {
        return true;
    }
    let newPlansIncluded = 0;

    newPlans.forEach((code) => {
        let plansTheSame = 0;
        oldPlans.forEach((oldCode) => {
            if (code === oldCode) {
                plansTheSame += 1;
            }
        });
        newPlansIncluded += plansTheSame;
    });

    if (newPlansIncluded !== newPlans.length) {
        return true;
    }

    return false;
};

/**
 * Determines whether any plans have changed sinced the last sendToSolaris call
 * @param {array} medical - array of selected medical plans
 * @param {array} solarisMedical - array of selected medical plans previously sent to solaris
 * @param {array} dental - array of selected dental plans
 * @param {array} solarisDental - array of selected dental plans previously sent to solaris
 * @param {array} vision - array of selected vision plans
 * @param {array} std - array of selected std plans
 * @param {array} solarisStd - array of selected std plans previously sent to solaris
 * @param {array} ltd - array of selected ltd plans
 * @param {array} suppLife - array of selected suppLife plans
 * @param {array} solarisLtd - array of selected ltd plans previously sent to solaris
 * @param {array} solarisVision - array of selected vision plans previously sent to solarisss
 */
const checkPlansChanged = (medical, solarisMedical, dental,
    solarisDental, vision, solarisVision, life, solarisLife, lifeDep, solarisLifeDep, std, solarisStd, ltd, solarisLtd, suppLife, solarisSuppLife) => {
    const medicalUpdate = compareSelectedPlans(medical, solarisMedical);
    const dentalUpdate = compareSelectedPlans(dental, solarisDental);
    const visionUpdate = compareSelectedPlans(vision, solarisVision);
    const lifeUpdate = compareSelectedPlans(life, solarisLife);
    const lifeDepUpdate = compareSelectedPlans(lifeDep, solarisLifeDep);
    const stdUpdate = compareSelectedPlans(std, solarisStd);
    const ltdUpdate = compareSelectedPlans(ltd, solarisLtd);
    const suppLifeUpdate = compareSelectedPlans(suppLife, solarisSuppLife);
    if (medicalUpdate || dentalUpdate || visionUpdate || lifeUpdate || lifeDepUpdate || stdUpdate || ltdUpdate || suppLifeUpdate) {
        return true;
    }
    return false;
};

/**
 * Retrieves the PCIS_ID of the producer if it exists
 * @param {Objec} ownerAssignment - contains the different owner arrays,
 *                                  uses the producer array
 */
// const getPCISCode = (ownerAssignment) => {
//     const { producer } = ownerAssignment;
//     if (producer.length > 0) {
//         return producer[0].PCIS_ID;
//     }
//     return '';
// };

/**
 * Sends the required information to the service layer to send to solaris
 * @param {string} lastUpdatedPage - the name of the last page you're on, defaulted to empty string
 * @param {string} status - status of the case can be Quoting or enrollment,
 *                          defaulted to Quoting
 * @param {boolean} shouldUpdateCase - whether or not the case should be updated
 *                                     defaulted to true
 * @param {boolean} isMicroGrpPause - whether or not the case is a Micro group, causing the new status:
 *                                    Pending Acceptance to be set defaulted to false
 */
const sendToSolaris = (lastUpdatedPage = '', status = CASE_PROGRESS.QUOTING, shouldUpdateCase = true, isMicroGrpPause = false) => async (dispatch, getState) => new Promise((resolve, reject) => {
    dispatch({ type: DISABLE_SAVE_AND_EXIT, payload: true })
    const state = getState();
    const {
        // companyProfile,
        caseTracking,
        quotes: {
            selectedPlans: medicalSelectedPlans,
        },
        caseTracking: {
            isCopyQuoteOriginal: copyQuote,
        },
        dental: {
            selectedPlans: dentalSelectedPlans,
        },
        vision: {
            selectedPlans: visionSelectedPlans,
        },
        life: {
            selectedPlans: lifeSelectedPlans,
        },
        lifeDep: {
            selectedPlans: lifeDepSelectedPlans,
        },
        std: {
            selectedPlans: stdSelectedPlans,
        },
        ltd: {
            selectedPlans: ltdSelectedPlans,
        },
        suppLife: {
            selectedPlans: suppLifeSelectedPlans,
        },
        solarisReducer: {
            medicalCodes: solarisMedicalCodes,
            dentalCodes: solarisDentalCodes,
            visionCodes: solarisVisionCodes,
            lifeCodes: solarisLifeCodes,
            lifeDepCodes: solarisLifeDepCodes,
            stdCodes : solarisStdCodes,
            ltdCodes : solarisLtdCodes,
            suppLifeCodes : solarisSuppLifeCodes,
            shouldGenerateId,
            firstTimeEnroll,
        },
        rules: {
            enrollment: {
                enrolledPlans,
                enrolledDentalPlans,
                enrolledVisionPlans,
                enrolledLifePlans,
                enrolledDependentLifePlans,
                enrolledSTDPlans,
                enrolledLTDPlans,
                enrolledSuppLifePlans,
            },
        },
    } = state;
    const enroll = status === CASE_PROGRESS.ENROLLING;
    const statusEnrolled = status === CASE_PROGRESS.SUBMITTED || status === CASE_PROGRESS.PENDING || status === CASE_PROGRESS.ACCEPTED;
    const idx = getCaseIndexForNB(caseTracking);
    let dentalPlans = [];
    let visionPlans = [];
    let medicalPlans = [];
    let lifePlans = [];
    let lifeDepPlans = [];
    let stdPlans = [];
    let ltdPlans = [];
    let suppLifePlans = [];

    let dentalCodes = [];
    let visionCodes = [];
    let medicalCodes = [];
    let lifeCodes = [];
    let lifeDepCodes = [];
    let stdCodes = [];
    let ltdCodes = [];
    let suppLifeCodes = [];

    if (enroll || statusEnrolled) {
        dentalCodes = enrolledDentalPlans.map((plan) => plan.code);
        visionCodes = enrolledVisionPlans.map((plan) => plan.code);
        medicalCodes = Object.keys(enrolledPlans).map(
            (key) => enrolledPlans[key],
        ).flat().map((plan) => plan.code) || [];
        lifeCodes = enrolledLifePlans.map((plan) => plan.code);
        lifeDepCodes = enrolledDependentLifePlans.map((plan) => plan.code);
        stdCodes = enrolledSTDPlans.map((plan) => plan.code);
        ltdCodes = enrolledLTDPlans.map((plan) => plan.code);
        suppLifeCodes = enrolledSuppLifePlans.map((plan) => plan.code);

        dentalPlans = enrolledDentalPlans;
        visionPlans = enrolledVisionPlans;
        medicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat() || [];
        if (state.overall.platform === 'PRIME') {
            medicalPlans.forEach((medicalPlan) => {
                medicalPlan.planGroup = medicalPlan.innetleNm;
            });
        }
        lifePlans = enrolledLifePlans;
        lifeDepPlans = enrolledDependentLifePlans;
        stdPlans = enrolledSTDPlans;
        ltdPlans = enrolledLTDPlans;
        suppLifePlans = enrolledSuppLifePlans;

        dispatch({ type: SOLARIS.SHOULD_GENERATE_ID, val: false });
    } else {
        medicalCodes = medicalSelectedPlans.map((plan) => plan.code);
        dentalCodes = dentalSelectedPlans.map((plan) => plan.code);
        visionCodes = visionSelectedPlans.map((plan) => plan.code);
        lifeCodes = lifeSelectedPlans.map((plan) => plan.code);
        lifeDepCodes = lifeDepSelectedPlans.map((plan) => plan.code);
        suppLifeCodes = suppLifeSelectedPlans.map((plan) => plan.code);
        stdCodes = stdSelectedPlans.map((plan) => plan.code);
        ltdCodes = ltdSelectedPlans.map((plan) => plan.code);
        medicalPlans = medicalSelectedPlans;
        dentalPlans = dentalSelectedPlans;
        visionPlans = visionSelectedPlans;
        lifePlans = lifeSelectedPlans;
        lifeDepPlans = lifeDepSelectedPlans;
        stdPlans = stdSelectedPlans;
        ltdPlans = ltdSelectedPlans;
        suppLifePlans = suppLifeSelectedPlans;
    }

    const plansChanged = checkPlansChanged(
        medicalCodes, solarisMedicalCodes,
        dentalCodes, solarisDentalCodes,
        visionCodes, solarisVisionCodes,
        lifeCodes, solarisLifeCodes,
        lifeDepCodes, solarisLifeDepCodes,
        stdCodes, solarisStdCodes,
        ltdCodes, solarisLtdCodes,
        suppLifeCodes, solarisSuppLifeCodes,
    );

    if (copyQuote) {
        dispatch({ type: SOLARIS.COPY_QUOTE });
        dispatch({ type: RESET_COPY_FLAG });
    }

    if (plansChanged || (enroll && firstTimeEnroll) || statusEnrolled || copyQuote) {
        // let the index be 0, unless there is more one case
        if (enroll && firstTimeEnroll) {
            dispatch({ type: SOLARIS.FIRST_TIME_ENROLL, val: false });
        }
        if (!enroll && !firstTimeEnroll) {
            dispatch({ type: SOLARIS.FIRST_TIME_ENROLL, val: true });
        }

        if (!enroll && !shouldGenerateId && !statusEnrolled) {
            dispatch({ type: SOLARIS.SHOULD_GENERATE_ID, val: true });
        }

        dispatch({ type: SOLARIS.UPDATE_STATUS, status });
        dispatch({ type: SOLARIS.SEND_TO_SOLARIS });
        let tempStatus = status;

        const currentStatus = getNBCaseProgress(state);
        if ((currentStatus === 'Enrolling' || currentStatus === 'Enrolled') && status === 'Quoting') {
            tempStatus = currentStatus;
        }

        if (isMicroGrpPause) {
            tempStatus = CASE_PROGRESS.PENDACCEPT;
        }
        dispatch(saveOrUpdateCaseTracking(lastUpdatedPage, tempStatus, idx))
            .then(async (res) => {
                dispatch({
                    type: SOLARIS.UPDATE_QUOTED_PLANS,
                    medicalCodes,
                    dentalCodes,
                    visionCodes,
                    lifeCodes,
                    lifeDepCodes,
                    stdCodes,
                    ltdCodes,
                    suppLifeCodes,
                });

                const payload = solarisPayload(
                    medicalPlans, dentalPlans, visionPlans, lifePlans,
                    lifeDepPlans, stdPlans, ltdPlans, suppLifePlans,state, res, status,
                );

                if (getMarketType() === MARKET_TYPES.UHC) {
                    let body = {
                        caseId: payload.caseId,
                        caseProgress: ["Processing in error"]
                    }
                    let { data: logExist } = await axios.post(`${apiRoutes.quickQuotes}/getSolarisLogs`, body)
                    if (logExist) {
                        return resolve()
                    }
                }
                const route = apiRoutes.quickQuotes;

                axios.post(`${route}/sendQuoteToSolaris`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((result) => {
                        dispatch({ type: SOLARIS.SEND_SUCCESS });
                        const {
                            data: {
                                message,
                                failureReason,
                            },
                        } = result;
                        sendLog({
                            type: SOLARIS.SEND_SUCCESS,
                            caseId: payload.caseId,
                            message,
                            payload,
                            failureReason,
                        });
                        resolve();
                    })
                    .catch((err) => {
                        dispatch({ type: SOLARIS.SEND_ERROR, errorMessage: err.message });
                        const {
                            response: {
                                data: {
                                    logId: splunkLogId,
                                    message: serverMessage,
                                },
                            },
                            message,
                        } = err;
                        sendLog({
                            type: SOLARIS.SEND_ERROR,
                            caseId: payload.caseId,
                            message,
                            splunkLogId,
                            payload,
                            serverMessage,
                            codeBase: 'UI',
                        });
                        reject(err);
                    });
            });
    } else if (shouldUpdateCase) {
        dispatch({ type: SOLARIS.SHOULD_GENERATE_ID, val: false });
        if (isMicroGrpPause) {
            dispatch(saveOrUpdateCaseTracking(lastUpdatedPage, CASE_PROGRESS.PENDACCEPT, idx));
        } else {
            const currentStatus = getNBCaseProgress(state);
            if ((currentStatus === 'Enrolling' || currentStatus === 'Enrolled') && status === 'Quoting') {
                status = currentStatus;
            }
            dispatch(saveOrUpdateCaseTracking(lastUpdatedPage, status, idx));
        }
    }
});

export default sendToSolaris;
