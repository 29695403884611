import { store } from "../..";
import { isUhcRenewals } from "../businessLogic/platformUtils";

export const isCaseNotFinalized = () => {
    let fullProps = store.getState();
    let activeStatus = ['In Progress','Not Started','InProgress','inProgress'];
    let isuhcRenewal = isUhcRenewals();
    let renewalPlan;
    let currentCaseProgress;
    if (fullProps.renewals.globalSearchFlag) {
        currentCaseProgress = fullProps.renewals.renewalSearchCase.caseProgressUI;
    } else {
        renewalPlan = fullProps.renewals.renewals.find(plan => plan.memberGroupId === fullProps.renewals.memberGroupId)
        currentCaseProgress = renewalPlan.caseProgressUI;
    }
    return !isuhcRenewal || (isuhcRenewal && currentCaseProgress && activeStatus.includes(currentCaseProgress));
}