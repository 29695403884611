import { constraintNames, dataTypes } from './constants'
import { getAge } from '../../utils/formatters/numbers'

export const checkConstraint = (value, constraintName, constraintValue) => {
    switch (constraintName) {
        case constraintNames.dataType:
            return verifyDataType(value, constraintValue);
        case constraintNames.maxLength:
            return verifyMaxLength(value, constraintValue);
        case constraintNames.minLength:
            return verifyMinLength(value, constraintValue);
        case constraintNames.dropDownValues:
            return verifyDropdownValue(value, constraintValue);
        case constraintNames.length:
            return verifyLength(value, constraintValue);
        case constraintNames.minValue:
            return verifyMinValue(value, constraintValue);
        case constraintNames.maxValue:
            return verifyMaxValue(value, constraintValue);
        case constraintNames.regex:
            return verifyRegex(value, constraintValue);
        case constraintNames.validDate:
            return verifyDate(value, constraintValue);
        case constraintNames.dobRange:
            return verifyDobRange(value, constraintValue)
    }
}

function verifyMaxLength(value, maxLength) {
    if (typeof maxLength !== dataTypes.number)
        return true;
    if (typeof value === dataTypes.number)
        value = value.toString();
    return value.length <= maxLength
}

function verifyMinLength(value, minLength) {
    if (typeof minLength !== dataTypes.number)
        return true;
    if (typeof value === dataTypes.number)
        value = value.toString();
    return value.length >= minLength
}

function verifyDropdownValue(value, dropDownValues) {
    return dropDownValues?.includes(value);
}

function verifyLength(value, length) {
    if (typeof value === dataTypes.number)
        value = value.toString();
    return value.length === length;
}

function verifyMinValue(value, minValue) {
    if (typeof value === dataTypes.string)
        value = parseInt(value);
    return value >= minValue;
}

function verifyMaxValue(value, maxValue) {
    if (typeof value === dataTypes.string)
        value = parseInt(value);
    return value <= maxValue;
}

function verifyDataType(value, constraintValue) {
    return constraintValue.includes(typeof value);
}

function verifyRegex(value, constraintValue) {
    const regex = new RegExp(constraintValue);
    return regex.test(value)
}

function verifyDate(value, isValidDateFunc) {
    return isValidDateFunc(value);
}

function verifyDobRange(value, constraintValue) {
    if (getAge(new Date(value)) < 14 || getAge(new Date(value)) > 115) {
        return false
    }
    else {
        return true
    }
}