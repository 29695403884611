import nonConfigEmployerInfo from './EmployerInfoFields';
import returnJson from './EmployeeInfoFields';

const nonConfigEligibilityInfo = require('./EligibilityInfoFields');
const nonConfigProducerGA = require('./ProducerGAFields');
const nonConfigAttestation = require('./EmployerAttestation');
const nonConfigEmployeeAttestation = require('./EmployeeAttestation.json');
const nonConfigStandardHRA = require('./StandardHRA');

function returnNonConfigurableFieldData(life, overallPlatform, state, isUHCNB) {

  const nonConfigurableFieldData = {
    ...nonConfigEmployerInfo,
    ...nonConfigEligibilityInfo,
    ...nonConfigProducerGA,
    ...nonConfigAttestation,
    ...returnJson(life, overallPlatform, state, isUHCNB),
    ...nonConfigEmployeeAttestation,
    ...nonConfigStandardHRA
};

  return(
    nonConfigurableFieldData
  )
}


export const augmentNonConfigurableFieldDataInFormSections = (formSections, life, overallPlatform, state, isUHCNB) => {
    formSections.forEach((formSection, i) => {
        formSection.fields.forEach((field, j) => {
            if (field.isConfigurable === false
                && returnNonConfigurableFieldData(life, overallPlatform,state, isUHCNB)[formSection.sectionName]
                && returnNonConfigurableFieldData(life, overallPlatform, state, isUHCNB)[formSection.sectionName][field.fieldName]) {
                formSections[i].fields[j] = {
                    ...field,
                    ...returnNonConfigurableFieldData(life, overallPlatform, state, isUHCNB)[formSection.sectionName][field.fieldName],
                };
            }
        });
    });
    return formSections;
};
