/**
 * Sets the coverages of each employee based on the selected plans for each product
 * @param {Object} selectedProducts - object with boolean values representing whether medical,
 *                                    dental, vision or life
 * @param {Array} employees - array of locations of employees, e.g. [[emp1, emp2], [emp3, emp4]]
 * @param {Array} medical - the selected medical plans
 * @param {Array} dental - the selected dental plans
 * @param {Array} vision - the selected vision plans
 * @param {Array} life - the selected life plans
 */
const setCoverages = (
    selectedProducts, employees, medical, dental, vision, life, dependentLife, std, ltd, overall,
) => {
    const updatedSelectedProducts = Object.keys(selectedProducts).map((product) => {
        switch (product) {
            case 'medical':
                if (medical.length > 0) { return product; }
                break;
            case 'dental':
                if (dental.length > 0) { return product; }
                break;
            case 'vision':
                if (vision.length > 0) { return product; }
                break;
            case 'ltd':
                if (ltd.length > 0) { return product; }
                break;
            case 'std':
                if (std.length > 0) { return product; }
                break;
            case 'basicLife':
            case 'life':
                if (life.length > 0) { return product; }
                break;
            case 'dependentLife':
                if (dependentLife.length > 0) { return product; }
                break;
            default:
                return null;
        }
        return null;
    });

    let filteredProducts;
    if (overall && overall.source === 'quick-quotes') {
        filteredProducts = overall.quickQuoteRequest.otherDetails.selectedProducts;
    } else {
        filteredProducts = updatedSelectedProducts
            .filter((product) => (product));
    }
    const empLocations = employees.map((location) => {
        const updatedEmployees = location.map((employee) => {
            const { coverages: empCoverages } = employee;
            const updatedCoverages = {};
            filteredProducts.forEach((product) => {
                if (product === 'life') {
                    updatedCoverages.basicLife = empCoverages.basicLife;
                } else {
                    updatedCoverages[product] = empCoverages[product];
                }
            });
            const updatedEmployee = {
                ...employee,
                coverages: updatedCoverages,
            };
            return updatedEmployee;
        });
        return updatedEmployees;
    });

    return empLocations;
};

export default setCoverages;
