import { getCompositeRate, getAgeBandedRate } from '../../../utils/businessLogic/calculateQuotes';

const specialtyCoverageMap = {
    EO: 'employee',
    ES: 'empAndSpouse',
    EC: 'empAndChild',
    EF: 'empAndFamily',
    EE: 'employee',
    'EE+SP': 'empAndSpouse',
    'EE+CH': 'empAndChild',
    'EE+FAM': 'empAndFamily',
    'EE/SP': 'empAndSpouse',
    'EE/CH': 'empAndChild',
    'EE/FAM': 'empAndFamily',
    employee: 'employee',
    empAndSpouse: 'empAndSpouse',
    empAndChild: 'empAndChild',
    empAndFamily: 'empAndFamily',
};

export const calculateDependentTotal = (employee, plan, dependentsTotal, isAgeBanded) => {
    let finalDependentsTotal = dependentsTotal;
    switch (employee.coverages.medical) {
        case 'EO':
        case 'EE':
            break;
        case 'ES':
        case 'EE/SP':
            if (isAgeBanded) {
                employee.dependents.forEach((dependent) => {
                    if (dependent.relationship === 'Spouse' || dependent.relationship === 'Civil Union Partner' || dependent.relationship === 'Domestic Partner' || dependent.relationship === 'Life Partner') {
                        const age = dependent.age && dependent.age !== '' ? dependent.age : '';
                        finalDependentsTotal += getAgeBandedRate(
                            age,
                            plan.ageBands,
                            employee.coverages.medical || employee.coverages.coverages.medical,
                        );
                    }
                });
            } else {
                const dependentCost = getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    employee.coverages.medical || employee.coverages.coverages.medical,
                    employee.age,
                ) - getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    'EE',
                    employee.age,
                );
                finalDependentsTotal += dependentCost;
            }
            break;
        case 'EC':
        case 'EE/CH':
            if (isAgeBanded) {
                employee.dependents.forEach((dependent) => {
                    if (dependent.relationship === 'Child' || dependent.relationship === 'Dependent Child') {
                        finalDependentsTotal += getAgeBandedRate(
                            dependent.age || '',
                            plan.ageBands,
                            employee.coverages.medical || employee.coverages.coverages.medical,
                        );
                    }
                });
            } else {
                const dependentCost = getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    employee.coverages.medical || employee.coverages.coverages.medical,
                    employee.age,
                ) - getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    'EE',
                    employee.age,
                );

                finalDependentsTotal += dependentCost;
            }
            break;
        case 'EF':
        case 'EE/FAM':
            if (isAgeBanded) {
                employee.dependents.forEach((dependent) => {
                    const denpendentsTotal = getAgeBandedRate(
                        dependent.age || '',
                        plan.ageBands,
                        employee.coverages.medical || employee.coverages.coverages.medical,
                    );

                    finalDependentsTotal += isNaN(denpendentsTotal) ? 0 : +denpendentsTotal;
                });
            } else {
                const dependentCost = getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    employee.coverages.medical || employee.coverages.coverages.medical,
                    employee.age,
                ) - getCompositeRate(
                    plan.compositeRates,
                    plan.compositeTypeName,
                    'EE',
                    employee.age,
                );

                finalDependentsTotal += dependentCost;
            }
            break;
        default:
            break;
    }

    return finalDependentsTotal;
};

export const calculateDependentTotalSpecialty = (product, employee, plan, dependentsTotal, isWATwoTier = false) => {
    let finalDependentsTotal = dependentsTotal;
    const productCoverage = employee.coverages && employee.coverages[product] ? employee.coverages[product]
        : employee.coverages.coverages && employee.coverages.coverages[product] ? employee.coverages.coverages[product] : '';
    const coverage = specialtyCoverageMap[productCoverage];

    if (product === 'life') {
        switch (coverage) {
            default:
                break;
        }
    } else {
        if (isWATwoTier) {
            switch (coverage) {
                case 'employee':
                    break;
                case 'empAndSpouse':
                case 'empAndChild':
                case 'empAndFamily':
                    finalDependentsTotal = plan.finalRates
                        ? parseFloat(plan.finalRates.empAndFamily) - parseFloat(plan.finalRates.employee)
                        : plan.monthlyPremium
                            ? parseFloat(plan.monthlyPremium.empAndFamily) - parseFloat(plan.monthlyPremium.employee)
                            : 0;
                    break;
                default:
                    break;
            }
        } else {
            switch (coverage) {
                case 'employee':
                    break;
                case 'empAndSpouse':
                case 'empAndChild':
                case 'empAndFamily':
                    finalDependentsTotal = plan.finalRates
                        ? parseFloat(plan.finalRates[coverage]) - parseFloat(plan.finalRates.employee)
                        : plan.monthlyPremium
                            ? parseFloat(plan.monthlyPremium[coverage]) - parseFloat(plan.monthlyPremium.employee)
                            : 0;
                    break;
                default:
                    break;
            }
        }
    }
    return finalDependentsTotal;
};
