import { isUndefinedOrNull } from "../../../../utils/validation/commonValidations";
import { areaCoverageType } from './ComparePlans/RenewalComparePlans';
import { parseRxString } from "../../../../utils/formatters/strings";

export const formateAgeBand = (rates) => {
    let ageBands = [];

    if (rates) {
        rates.forEach(rate => {
            if (rate.band === "0-14") {
                ageBands.push(
                    { minAge: "<", maxAge: "14", total: rate.total }
                )
            } else if (rate.band === "64-99") {
                ageBands.push(
                    { minAge: "64", maxAge: "+", total: rate.total }
                )
            } else {
                ageBands.push(
                    { minAge: rate.band, maxAge: rate.band, total: rate.total }
                )
            }
        })
    }
    return ageBands;
}

export const formatCompositeRates = (rates) => {
    let compositeRates = []

    if (rates) {
        rates.forEach(rate => {
            compositeRates.push({
                employeeType: rate.tier,
                employeeTypeDisplay: rate.tier,
                rate: rate.total
            })
        })
    }
    return compositeRates
}

export const FilterRenewalsMedicalData = (renewalData, customPlanNames) => {

    let medicalPlanSets = []

    if (renewalData && renewalData.products && renewalData.renewalPlanMaps && renewalData.planRates) {
        renewalData.renewalPlanMaps.map((renewalPlanMapSet, i) => {
            if (renewalPlanMapSet.productType && renewalPlanMapSet.productType === "Medical") {
                let medicalSet = {}
                let currentPlan = filterMedicalData(renewalData, customPlanNames, renewalPlanMapSet.currentPlanCode, 'Current', null)
                let renewingPlan = filterMedicalData(renewalData, customPlanNames, renewalPlanMapSet.renewalPlanCode, 'Renewal', renewalPlanMapSet.currentPlanCode)
                medicalSet['current'] = currentPlan
                medicalSet['renewing'] = renewingPlan
                medicalPlanSets.push(medicalSet)
            }
        })
    }

    return medicalPlanSets
}
const temp_isUhcRewardsValid = (effectiveDate) => {
    const toBeComparedDate = new Date('2023-01-01');
    const newEffDate = effectiveDate.split('T')
    return new Date(newEffDate[0]) >= toBeComparedDate;
}
export const filterMedicalData = (renewalData, customPlanNames, planCode, renewalPlanType, currentPlanCode) => {
    let planObj = {
        planType: 'Medical',
        platform: 'CIRRUS',
        code: planCode,
        medicalPlanCode: planCode,
        renewalPlanType: renewalPlanType === 'Renewal' ? 'renewing' : 'current',
        renewalPlan: renewalPlanType === 'Renewal',
        rx: {
            tiers: []
        },
        deductible: {},
        oopMax: {},
        hospitalCoinsurance: {},
        hraContribution: {
            min: '',
            max: '',
        },
        ageBands: []
    }
    if (renewalData && renewalData.products) {
        renewalData.products.plans.map(plan => {
            if (plan.code && planCode === plan.code) {

                planObj.codeDisplay = plan.name

                const keyBenefits = plan.benefitValues.find(({ code }) => code === 'keyBenefits');
                const benefitSummary = plan.benefitValues.find(({ code }) => code === 'benefitSummary');
                const planHighlight = plan.benefitValues.find(({ code }) => code === 'planHighlightsTiered');
                const planMetaData = plan.benefitValues.find(({ code }) => code === 'planMetadata');
                const ridersData = plan.benefitValues.find(({ code }) => code === 'riders');
                const uhcRewardsPremiumMap = {
                    "Current":"X",
                    "Renewal":"Premium"
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "productType")) {
                    planObj["medicalProductType"] = benefitSummary.benefitValue.find(({ code }) => code === "productType").value
                }

                if (keyBenefits.benefitValue.find(({ code }) => code === "LT")) {
                    planObj["licenseType"] = keyBenefits.benefitValue.find(({ code }) => code === "LT").value
                }

                if (keyBenefits.benefitValue.find(({ code }) => code === "planType")) {
                    planObj["planGroup"] = keyBenefits.benefitValue.find(({ code }) => code === "planType").value
                }

                if (keyBenefits.benefitValue.find(({ code }) => code === "EMDED")) {
                    planObj["nonEmbeddedDeductible"] = keyBenefits.benefitValue.find(({ code }) => code === "EMDED").value === 'Embedded' ? false : true
                }

                if (keyBenefits.benefitValue.find(({ code }) => code === "OOAMemberEnrollmentAllowed")) {
                    planObj["ooa"] = keyBenefits.benefitValue.find(({ code }) => code === "OOAMemberEnrollmentAllowed").value
                }

                if (planMetaData.benefitValue.find(({ code }) => code === "OOANetworkAccessType")) {
                    planObj["areaCoverage"] = areaCoverageType(planMetaData.benefitValue.find(({ code }) => code === "OOANetworkAccessType").value)
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "rxPlanCode")) {
                    planObj["rxPlanCode"] = benefitSummary.benefitValue.find(({ code }) => code === "rxPlanCode").value
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "InAreaNetworkName")) {
                    planObj["medicalPlanType"] = benefitSummary.benefitValue.find(({ code }) => code === "InAreaNetworkName").value
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "planCategory")) {
                    planObj["medicalPlanCategory"] = benefitSummary.benefitValue.find(({ code }) => code === "planCategory").value
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "metallicLevel")) {
                    planObj["metal"] = benefitSummary.benefitValue.find(({ code }) => code === "metallicLevel").value
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "isHSAEligible")) {
                    planObj["hsa"] = benefitSummary.benefitValue.find(({ code }) => code === "isHSAEligible").value !== '' && benefitSummary.benefitValue.find(({ code }) => code === "isHSAEligible").value === 'FALSE' ? false : true
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "isHRAEligible")) {
                    planObj["hra"] = benefitSummary.benefitValue.find(({ code }) => code === "isHRAEligible").value !== '' && benefitSummary.benefitValue.find(({ code }) => code === "isHRAEligible").value === 'FALSE' ? false : true
                }

                if (keyBenefits.benefitValue.find(({ code }) => code === "MEDRXDED")) {
                    planObj["combinedMedRxDeductible"] = keyBenefits.benefitValue.find(({ code }) => code === "MEDRXDED").value !== '' && keyBenefits.benefitValue.find(({ code }) => code === "MEDRXDED").value === 'Separate' ? false : true
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "pcpRequired")) {
                    planObj["pcpRequired"] = benefitSummary.benefitValue.find(({ code }) => code === "pcpRequired").value !== '' && benefitSummary.benefitValue.find(({ code }) => code === "pcpRequired").value === 'FALSE' ? false : true
                }
                if (benefitSummary.benefitValue.find(({ code }) => code === "CareCash")) {
                    planObj["careCash"] = benefitSummary.benefitValue.find(({ code }) => code === "CareCash").value === 'X' ? true : null
                }
                
                if (benefitSummary.benefitValue.find(({ code }) => code === "uhcRewardsPremium") && temp_isUhcRewardsValid(renewalData.memberGroup.renewalEffectiveDate)) {
                    planObj["uhcRewards"] = benefitSummary.benefitValue.find(({ code }) => code === "uhcRewardsPremium").value === uhcRewardsPremiumMap[renewalPlanType] ? 'uhcRewardsPremium' : "uhcRewardsCore"
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "EMRCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj["emergencyRoomCopay"] = planHighlight.benefitValue.find(({ code }) => code === "EMRCOPAY").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "EMRCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj["emergencyRoomCopayOut"] = planHighlight.benefitValue.find(({ code }) => code === "EMRCOPAY").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "UCCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj["urgentCareCopay"] = planHighlight.benefitValue.find(({ code }) => code === "UCCOPAY").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "UCCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj["urgentCareCopayOut"] = planHighlight.benefitValue.find(({ code }) => code === "UCCOPAY").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj["physicianCoinsurance"] = planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj["physicianCoinsuranceOut"] = planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "PCPCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj["pcpCopay"] = planHighlight.benefitValue.find(({ code }) => code === "PCPCOPAY").value
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "PCPCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj["pcpCopayOut"] = planHighlight.benefitValue.find(({ code }) => code === "PCPCOPAY").value
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "SPECCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj["specialistCopay"] = planHighlight.benefitValue.find(({ code }) => code === "SPECCOPAY").value
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "SPECCOPAY") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj["specialistCopayOut"] = planHighlight.benefitValue.find(({ code }) => code === "SPECCOPAY").value
                }

                //RX

                let rxPlancodeObj = benefitSummary.benefitValue.find(({ code }) => code === "rxPlanCode")
                const rx1 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX1') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX1').copay : 'N/A';
                const rx2 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX2') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX2').copay : 'N/A';
                const rx3 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX3') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX3').copay : 'N/A';
                const rx4 = planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX4') ? planHighlight.benefitValue.find(({ code, tier }) => code === 'Rx' && tier === 'RX4').copay : 'N/A';
                const formRxPlanCode = `${rx1}/${rx2}/${rx3}/${rx4}`;
                const rxVal = parseRxString(rxPlancodeObj && rxPlancodeObj.value !== '' ? rxPlancodeObj.value : formRxPlanCode);
                planObj.rx["deductible"] = rxVal.deductible;

                planObj.rx["specialty"] = false

                if (planHighlight.benefitValue.find(({ code }) => code === "Rx") && planHighlight.benefitValue.find(({ tier }) => tier === "RX1")) {
                    planObj.rx.tiers[0] = {
                        tierNum: 1,
                        copay: planHighlight.benefitValue[planHighlight.benefitValue.findIndex(({ tier }) => tier === "RX1")].value
                    }
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "Rx") && planHighlight.benefitValue.find(({ tier }) => tier === "RX2")) {
                    planObj.rx.tiers[1] = {
                        tierNum: 2,
                        copay: planHighlight.benefitValue[planHighlight.benefitValue.findIndex(({ tier }) => tier === "RX2")].value
                    }
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "Rx") && planHighlight.benefitValue.find(({ tier }) => tier === "RX3")) {
                    planObj.rx.tiers[2] = {
                        tierNum: 3,
                        copay: planHighlight.benefitValue[planHighlight.benefitValue.findIndex(({ tier }) => tier === "RX3")].value
                    }
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "Rx") && planHighlight.benefitValue.find(({ tier }) => tier === "RX4")) {
                    planObj.rx.tiers[3] = {
                        tierNum: 4,
                        copay: planHighlight.benefitValue[planHighlight.benefitValue.findIndex(({ tier }) => tier === "RX4")].value
                    }
                }

                //DEDUCTIBLE 

                if (planHighlight.benefitValue.find(({ code }) => code === "INDDED") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj.deductible["individualIn"] = planHighlight.benefitValue.find(({ code, tier }) => code === "INDDED" && tier === "IN").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "INDDED") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj.deductible["individualOut"] = planHighlight.benefitValue.find(({ code, tier }) => code === "INDDED" && tier === "OUT").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "FAMDED") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj.deductible["familyIn"] = planHighlight.benefitValue.find(({ code, tier }) => code === "FAMDED" && tier === "IN").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "FAMDED") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj.deductible["familyOut"] = planHighlight.benefitValue.find(({ code, tier }) => code === "FAMDED" && tier === "OUT").value;
                }

                //OOP MAX

                if (planHighlight.benefitValue.find(({ code }) => code === "INDOOM") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj.oopMax["individualIn"] = planHighlight.benefitValue.find(({ code, tier }) => code === "INDOOM" && tier === "IN").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "INDOOM") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj.oopMax["individualOut"] = planHighlight.benefitValue.find(({ code, tier }) => code === "INDOOM" && tier === "OUT").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "FAMOOM") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj.oopMax["familyIn"] = planHighlight.benefitValue.find(({ code, tier }) => code === "FAMOOM" && tier === "IN").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "FAMOOM") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj.oopMax["familyOut"] = planHighlight.benefitValue.find(({ code, tier }) => code === "FAMOOM" && tier === "OUT").value;
                }

                // HOSPITAL COINSURANCE

                if (planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS") && planHighlight.benefitValue.find(({ tier }) => tier === "IN")) {
                    planObj.hospitalCoinsurance["in"] = planHighlight.benefitValue.find(({ code, tier }) => code === "PHYCOINS" && tier === "IN").value;
                }

                if (planHighlight.benefitValue.find(({ code }) => code === "PHYCOINS") && planHighlight.benefitValue.find(({ tier }) => tier === "OUT")) {
                    planObj.hospitalCoinsurance["out"] = planHighlight.benefitValue.find(({ code, tier }) => code === "PHYCOINS" && tier === "OUT").value;
                }

                if (benefitSummary.benefitValue.find(({ code }) => code === "coInsurance")) {
                    let coInsuranceValues = benefitSummary.benefitValue.find(({ code }) => code === "coInsurance").value.split('/')
                    planObj["coInsurance"] = coInsuranceValues[0] + ' / ' + coInsuranceValues[1]
                }

                if (planMetaData.benefitValue.find(({ code }) => code === "healthyNYInd")) {
                    planObj["healthyNYPlan"] = planMetaData.benefitValue.find(({ code }) => code === "healthyNYInd").value === 'N' ? false : true
                }

                if (planMetaData.benefitValue.find(({ code }) => code === "HIOS")) {
                    planObj["hiosID"] = planMetaData.benefitValue.find(({ code }) => code === "HIOS").value
                    planObj["HISOID"] = planMetaData.benefitValue.find(({ code }) => code === "HIOS").value
                }

                //Riders
                if (ridersData && ridersData.benefitValue) {
                    if (ridersData.benefitValue.find(({ code }) => code === "age29")) {
                        if (ridersData.benefitValue.find(({ code }) => code === "age29").value.toUpperCase() === 'FALSE')
                            planObj["riderAge29"] = false
                        else
                            planObj["riderAge29"] = true
                    }

                    if (ridersData.benefitValue.find(({ code }) => code === "dp")) {
                        if (ridersData.benefitValue.find(({ code }) => code === "dp").value.toUpperCase() === 'FALSE')
                            planObj["riderDomesticPartner"] = false
                        else
                            planObj["riderDomesticPartner"] = true
                    }
                    if (ridersData.benefitValue.find(({ code }) => code === "specialtyVisionRider")) {
                        if (ridersData.benefitValue.find(({ code }) => code === "specialtyVisionRider").value.toUpperCase() === "X")
                            planObj["visionRider"] = true
                        else
                            planObj["visionRider"] = false
                    }

                }

                let currentPlanRate = null
                if (renewalPlanType === 'Renewal' && currentPlanCode)
                    currentPlanRate = renewalData.planRates.find(planRate => planRate.planCode && planRate.planCode === currentPlanCode && planRate.renewalPlanType === 'Current')

                renewalData.planRates.map(planRate => {
                    if (planRate.planCode && planRate.planCode === planCode && planRate.renewalPlanType === renewalPlanType) {

                        planObj["defaultRatingType"] = planRate.rateTable.ratingMethod === 'AgeBanded' ? 'ageBands' : 'composite'

                        if (planRate.rateTable.ratingMethod === 'AgeBanded') {
                            planObj["ageBands"] = formateAgeBand(planRate.rateTable.rate)
                            planObj["isAgeBanded"] = true
                            planObj["premiumTotalAgeBandedMonthly"] = planRate.rateTotals[0].total
                            planObj["premiumTotalAgeBandedAnnual"] = planRate.rateTotals[1].total
                        } else {
                            planObj["compositeRates"] = formatCompositeRates(planRate.rateTable.rate)
                            planObj['compositeTypeName'] = ''
                            planObj["isComposite"] = true
                            planObj["premiumTotalCompositeMonthly"] = planRate.rateTotals[0].total
                            planObj["premiumTotalCompositeAnnual"] = planRate.rateTotals[1].total
                        }

                        planObj["quote"] = planRate.rateTotals[0].total

                        planObj["selected"] = !isUndefinedOrNull(planRate.isChecked) ? planRate.isChecked : true
                        planObj["enrolledCount"] = planRate.empCount
                        planObj['employeeRates'] = planRate.employeeRates ? planRate.employeeRates : []

                        planObj["renewalChangePercent"] = null

                        if (currentPlanRate) {
                            let renewingQuote = parseFloat(planRate.rateTotals[0].total)
                            let currentQuote = parseFloat(currentPlanRate.rateTotals[0].total)
                            let difference = 0
                            let percentage = 0

                            if ((currentQuote === 0.00 && renewingQuote === 0.00) || currentQuote === renewingQuote) {
                                percentage = parseFloat(0).toFixed(2)
                            }
                            else if (currentQuote === 0) {
                                percentage = parseFloat(100).toFixed(2)
                            }
                            else if (renewingQuote === 0) {
                                percentage = parseFloat(-100).toFixed(2)
                            }
                            else {
                                difference = renewingQuote - currentQuote
                                percentage = ((parseFloat(difference) / parseFloat(currentQuote)) * 100).toFixed(2)
                            }
                            planObj["renewalChangePercent"] = percentage
                        }
                    }
                })

                planObj['customPlanName'] = customPlanNames.medical && customPlanNames.medical[planCode] ? customPlanNames.medical[planCode] : ''

            }
        })
    }

    return planObj
}