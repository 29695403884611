import * as types from '../../actions/actionTypes';
import { formatSalesRepRenewals } from '../../components/activities/Renewal/RenewalsCommonComponents/saveDetails';

const initialMDVContr = {
    medical: {
        eValue: 0,
        dValue: 0,
    },
    dental: {
        eValue: 0,
        dValue: 0,
    },
    vision: {
        eValue: 0,
        dValue: 0,
    },
    life:{
        eValue: 0,
        dValue: 0
    }
}
const createDocumentSection = (
    sectionName, {
        files = [],
    } = {},
) => ({
    sectionName,
    files,

});

const initialState = {
    loading: true,
    errorMessage: '',
    mdvContribution: initialMDVContr,
    requiredDocuments: {
        wagesAndTax: createDocumentSection('Wages and Tax'),
        employerInformation: createDocumentSection('Employer Information'),
        peoElcAttestation: createDocumentSection('PEO/ELC Attestation'),
        writtenAssurance: createDocumentSection('Written Assurance'),
        commonOwnership: createDocumentSection('Common Ownership'),
        HSACertificateOfUnderstanding: createDocumentSection('HSACertificateofUnderstanding'),
        employerCertification: createDocumentSection('EmployerCertification'),
        selectHRAForm: createDocumentSection('SelectHRAForm'),
        smallEmployerGroupDisclosureForm: createDocumentSection('Small Group Form')
    },
    premiumToggle: false,
    premiumRates: {
        medical: [],
        dental: [],
        vision: [],
        life:[]
    },
    customPlanNames: {
        medical: {},
        dental: {},
        vision: {},
        life: {},
        depLife: {}
    }
};

export default function renewalsEnrollmentReducer(state = initialState, action) {
    switch (action.type) {

        case types.GET_ENROLLMENT_DETAILS:
            let newState = action.payload
            let reqDocs = state.requiredDocuments
            return {
                ...state,
                ...action.payload,
                selectedProducts: action.selectedProducts,
                newProducts: action.newProducts,
                requiredDocuments: reqDocs
            }
        case types.REMOVE_ENROLLMENT_PLAN:
            const newList = state.renewalData.planRates.map((item) => {
                if (item.planCode === action.payload.query.code) {
                    const updatedItem = {
                        ...item,
                        isChecked: false,
                    };
                    return updatedItem;
                }
                return item;
            });

            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: newList
                }
            }
        case types.REMOVE_ENROLLMENT_PLAN_SHOPPING:
            const newPlanRatesList = state.renewalData.planRates.map((item) => {
                if (item.planCode === action.payload.query.code) {
                    const updatedItem = {
                        ...item,
                        isChecked: false,
                    };
                    return updatedItem;
                }
                return item;
            });
            const newShoppingList = state.shoppingPlans.map((item) => {
                if (item.code === action.payload.query.code) {
                    const updatedItem = {
                        ...item,
                        selected: false,
                    };
                    return updatedItem;
                }
                return item;
            });

            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: newPlanRatesList
                },
                shoppingPlans: newShoppingList
            }

        case types.UPDATE_ENROLLEMENT_ACC_DECIDE:
            const newPlanRates = state.renewalData.planRates.map((item) => {
                if (state.enrolledPlans[item.planCode]) {
                    const updatedItem = {
                        ...item,
                        isChecked: state.enrolledPlans[item.planCode].isEnrolled,
                    };
                    return updatedItem
                }
                return item
            });
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    planRates: newPlanRates
                }
            }

        case types.UPDATE_EMPLOYEES_ENROLL_STATUS:
            const { caseSubmitted } = state;

            // Don't update enroll status to In Progress, if the case is submitted status.
            if (caseSubmitted && action.payload === 'In Progress') {
                return {
                    ...state
                }
            }

            return {
                ...state,
                employeesEnrollStatus: action.payload
            }

        case types.UPDATE_MDV_ENROLL:
            const tempMDV = initialMDVContr;
            tempMDV.medical.eValue = action.payload.medEValue != null ?
                action.payload.medEValue :
                state.mdvContribution.medical.eValue;
            tempMDV.medical.dValue = action.payload.medDValue != null ?
                action.payload.medDValue :
                state.mdvContribution.medical.dValue;
            tempMDV.dental.eValue = action.payload.denEValue != null ?
                action.payload.denEValue :
                state.mdvContribution.dental.eValue;
            tempMDV.dental.dValue = action.payload.denDValue != null ?
                action.payload.denDValue :
                state.mdvContribution.dental.dValue;
            tempMDV.vision.eValue = action.payload.visEValue != null ?
                action.payload.visEValue :
                state.mdvContribution.vision.eValue;
            tempMDV.vision.dValue = action.payload.visDValue != null ?
                action.payload.visDValue :
                state.mdvContribution.vision.dValue;
            if (state.mdvContribution.life || action.payload.lifeEValue) {
            tempMDV.life.eValue = action.payload.lifeEValue != null ?
                action.payload.lifeEValue :
                state.mdvContribution.life.eValue;
            tempMDV.life.dValue = action.payload.lifeDValue != null ?
            action.payload.lifeDValue :
            state.mdvContribution.life.dValue;            
            }
            return {
                ...state,
                mdvContribution: tempMDV,
                errorMessage: action.payload.errorMessage != null ? action.payload.errorMessage : state.errorMessage,
            }



        case types.UPDATE_MDV_CONTRIBUTION_ENROLL:
            const tempMDVCont = initialMDVContr;
            tempMDVCont.medical.eValue = action.payload.medEValue != null ?
                action.payload.medEValue :
                state.mdvContribution.medical.eValue;
            tempMDVCont.medical.dValue = action.payload.medDValue != null ?
                action.payload.medDValue :
                state.mdvContribution.medical.dValue;
            tempMDVCont.dental.eValue = action.payload.denEValue != null ?
                action.payload.denEValue :
                state.mdvContribution.dental.eValue;
            tempMDVCont.dental.dValue = action.payload.denDValue != null ?
                action.payload.denDValue :
                state.mdvContribution.dental.dValue;
            tempMDVCont.vision.eValue = action.payload.visEValue != null ?
                action.payload.visEValue :
                state.mdvContribution.vision.eValue;
            tempMDVCont.vision.dValue = action.payload.visDValue != null ?
                action.payload.visDValue :
                state.mdvContribution.vision.dValue;
            tempMDVCont.life.eValue = action.payload.lifeEValue != null ?
                action.payload.lifeEValue :
                state.mdvContribution.life.eValue;    
            tempMDVCont.life.dValue = action.payload.lifeDValue != null ?
                action.payload.lifeDValue :
                state.mdvContribution.life.dValue; 

            return {
                ...state,
                mdvContribution: tempMDVCont,
                errorMessage: action.payload.errorMessage != null ? action.payload.errorMessage : state.errorMessage,
            }



        case types.SAVE_ENROLLMENT_RATES_RENEWALS:
            return {
                ...state,
                premiumRates: action.payload
            }

        case types.ADD_SELECTED_PRODUCTS_TO_RENEWAL_ENROLLMENT:
            return {
                ...state,
                selectedProducts: action.payload
            }

        case types.ADD_NEW_PRODUCTS_TO_RENEWAL_ENROLLMENT:
            return {
                ...state,
                newProducts: action.payload
            }
        case types.ENROLLMENT_MODIFIED_PAGE_SUCCESS:
            return {
                ...state,
                enrollmentRes: action.payload
            }
        case types.RESET_RENEWAL_ENROLLMENT:
        case types.RESET_RENEWALS:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_FLOW:
            return initialState
            
        case types.SAVE_MEDICAL_FINALIZE_RATES:
            return {
                ...state,
                [`${action.product}FinalizeRates`]: action.rates,
            }
        case types.UPDATE_SIC_CODE_TO_RENEWAL_DATA:
            return {
                ...state,
                renewalData: {
                    ...state.renewalData,
                    userEnteredSic: action.payload
                }
            }
        case types.UPDATE_SALES_REP_RENEWALS_ENROLLMENT:
            let finalSalesRepData = formatSalesRepRenewals(action.salesRep, action.platform)
            return {
                ...state,
                salesRepData: finalSalesRepData
            }

        case types.UPDATE_CASE_SUBMITTED:
            return {
                ...state,
                caseSubmitted: action.payload
            }

        case types.UPDATE_PREMIUM_TOGGLE_VALUE_ENROLLMENT:
            return {
                ...state,
                premiumToggle: action.payload
            }

        case types.UPDATE_RENEWAL_ENROLLMENT_PLANS:
            return {
                ...state,
                ...action.payload
            }

        case types.UPDATE_MEMBERS_IN_PLANS:
            return {
                ...state,
                ...action.payload
            }
            
        case types.SET_TERMED_PLATFORM:
            return {
                ...state,
                termedPlatform: action.payload
            }

        default:
            return state;

    }
}