import {
    INITIALIZE_PRODUCER_INFO, UPDATE_PRODUCER_INFO, COMPLETED_PRODUCER_INFO, RESET_FLOW, RESET_TO_INITIALSTATE, REMOVE_PRODUCER, REMOVE_GENERAL_AGENT, SET_GENERAL_AGENTS, SET_PRODUCERS, SAVE_PRODUCER_FORM, SET_COMMISSION_PAYABLE_FIELDS, SAVE_CRID_CODE, POPULATE_PRODUCER_INFO
} from '../../../actions/actionTypes';
import { initializeEnrollmentPageState } from './helper';
import { getRelevantAppState, makeAppStateNested } from '../../../components/shared/ConfigurableForms/helpers/getRelevantAppState';
import { checkIfObjectMeetsConditions } from '../../../components/shared/ConfigurableForms/helpers/findObjectThatMeetsConditions';
import { checkCompletionProducers, checkCompletionGeneralAgents } from '../../../components/shared/ConfigurableForms/helpers/conditionCheckers';
import { checkValidationsOnBlur } from '../../../components/shared/ConfigurableForms/helpers/validationCheckers';
import { isUndefinedOrNullOrEmpty } from '../../../utils/validation/commonValidations';
import { checkEachSection } from '../../../components/activities/Enrollment/EmployerAppForms/EnrollmentFormCompletionHandler';
import { getEmployerProgress } from '../../../components/activities/Enrollment/EnrollManager/helpers/getProgress';

const initialState = {
    errors: {},
};

export default function producerReducer(state = initialState, action) {
    switch (action.type) {
        case INITIALIZE_PRODUCER_INFO:
            const initializedState = initializeEnrollmentPageState(action.payload, action.conditionEvaluators);
            return {
                ...state,
                ...initializedState,
            };

        case UPDATE_PRODUCER_INFO: {
            const {
                value, group, field, isError,
            } = action;

            if (isError) {
                const resolvedErrorGroup = state.errors[group] || {};
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [group]: {
                            ...resolvedErrorGroup,
                            [field]: value,
                        },
                    },
                };
            }

            var checkField = field.slice(0, -1)
            var getIndex = field.slice(-1)
            if (checkField === "producerCommissionPayableTo" && value !== null && value != state.producer['producerCommissionPayableTo' + getIndex]) {
                state.producer['producerCommissionPayableToPcisId' + getIndex] = ""
            }

            const resolvedGroup = state[group] || {};
            return {
                ...state,
                [group]: {
                    ...resolvedGroup,
                    [field]: value,
                },
            };
        }
    


        case REMOVE_PRODUCER: {
            const { application, producerIndex, isUhcRenewal } = action;
            const producer = { ...application.producer.producer };
            const producerErrors = { ...application.producer.errors.producer };
            for (const property in producer) {
                // Checking if the object property belongs to the additional contact being removed
                // Object properties need to be removed for the completion check to work
                if (property.slice(-1) == producerIndex) {
                    delete producer[property];
                }
            }
            if(!isUhcRenewal){
                for (const property in producerErrors) {
                    // Checking if the object property belongs to the additional contact being removed
                    // Object properties need to be removed for the completion check to work
                    if (property.slice(-1) == producerIndex) {
                        delete producerErrors[property];
                    }
                }
            }
            else{
                for (const property in producer) {
                    let restProducers = property.slice(-1) 
                    if (restProducers > producerIndex) {
                        let keyName = property.slice(0,-1) + (restProducers - 1)
                        producer[keyName] = producer[property]
                        delete producer[property]
                    }
                }
            }
            return {
                ...state,
                producer,
                errors: {
                    ...state.errors,
                    producer: producerErrors,
                },
            };
        }

        case SET_PRODUCERS: {
            const { application, producers, overall } = action;
            let appProducers = application.producer.producer;

            if (overall && overall.appConfigDetails && overall.appConfigDetails.marketType === 'PRIME') { //for UHC Market Type
                const producerErrors = { ...application.producer.errors.producer };
                for (const property in producerErrors) {
                    //clear error list of producer accordingly after adding new producer in NB
                    if (property in producers && !isUndefinedOrNullOrEmpty(producers[property])) {
                        delete producerErrors[property];
                    }
                }
                appProducers = {
                    ...appProducers,
                    ...producers,
                };
                return {
                    ...state,
                    producer: appProducers,
                    errors: {
                        ...state.errors,
                        producer: producerErrors,
                    },
                };
            }
            
            appProducers = {
                ...appProducers,
                ...producers,
            };
            return {
                ...state,
                producer: appProducers,
            };
        }

        case SET_COMMISSION_PAYABLE_FIELDS: {
            const { application, commissionPayableFields } = action;
            let appProducers = application.producer.producer;
            appProducers = {
                ...appProducers,
                ...commissionPayableFields,
            };
            return {
                ...state,
                producer: appProducers,
            };
        }

        case SET_GENERAL_AGENTS: {
            const { application, agents } = action;
            let appAgents = application.producer.generalAgent;

            appAgents = {
                // ...appAgents,
                ...agents,
            };
            return {
                ...state,
                generalAgent: appAgents,
            };
        }

        case REMOVE_GENERAL_AGENT: {
            const { application, generalAgentIndex } = action;
            const generalAgent = { ...application.producer.generalAgent };
            const generalAgentErrors = { ...application.producer.errors.generalAgent };
            for (const property in generalAgent) {
                // Checking if the object property belongs to the additional contact being removed
                // Object properties need to be removed for the completion check to work
                if (property.slice(-1) == generalAgentIndex) {
                    delete generalAgent[property];
                }
            }
            for (const property in generalAgentErrors) {
                // Checking if the object property belongs to the additional contact being removed
                // Object properties need to be removed for the completion check to work
                if (property.slice(-1) == generalAgentIndex) {
                    delete generalAgentErrors[property];
                }
            }
            return {
                ...state,
                generalAgent,
                errors: {
                    ...state.errors,
                    generalAgent: generalAgentErrors,
                },
            };
        }

        case COMPLETED_PRODUCER_INFO: {
            const {
                application, enrollmentForm, conditionEvaluators, appStateProps, fullProps
            } = action;
            let completed = true;
            let sectionErrorProducer = {}
            let sectionErrorGA = {}

            for (const rulesSection of enrollmentForm.producer) {
                let showSection = true
                if (rulesSection.showIf) {
                    let appState = getRelevantAppState(application, 'producer', rulesSection.sectionName, rulesSection);
                    appState = makeAppStateNested(appState);
                    showSection = checkIfObjectMeetsConditions(
                        rulesSection.sectionName,
                        rulesSection.showIf,
                        {
                            ...conditionEvaluators,
                            appState,
                        },
                        fullProps.companyProfile,
                        fullProps.rules.enrollment
                    );
                }

                if (showSection) {
                    if (rulesSection.sectionName === 'producer') {
                        let producerCompletionData = checkCompletionProducers(
                            application, conditionEvaluators, appStateProps, fullProps
                        );
                        if (producerCompletionData.inCompleteSectionNames.length > 0) {
                            completed = false;
                        }
                        if (application && application.producer && application.producer.errors && application.producer.errors.producer) {
                            sectionErrorProducer = application.producer.errors.producer || {}
                            if (completed === true) {
                                Object.keys(sectionErrorProducer).map(key => {
                                    sectionErrorProducer[key] = null
                                })
                            }
                        }
                    } else if (rulesSection.sectionName === 'generalAgent') {
                        let producerCompletionData = checkCompletionGeneralAgents(
                            application, conditionEvaluators, appStateProps, fullProps
                        );
                        if (producerCompletionData.inCompleteSectionNames.length > 0) {
                            completed = false;
                        }
                        if (application && application.producer && application.producer.errors && application.producer.errors.generalAgent) {
                            sectionErrorGA = application.producer.errors.generalAgent || {}
                            if (completed === true) {
                                Object.keys(sectionErrorGA).map(key => {
                                    sectionErrorGA[key] = null
                                })
                            }
                        }
                    } else {
                        const eachSectionIncompletionData = checkEachSection('producer', rulesSection, rulesSection.sectionName, application, conditionEvaluators, fullProps);
                        completed = eachSectionIncompletionData.completed===false?false:completed;
                    }
                }
            }
            return {
                ...state,
                completed,
                errors: {
                    ...state.errors,
                    producer: sectionErrorProducer,
                    generalAgent: sectionErrorGA
                }
            };
        }
        case SAVE_PRODUCER_FORM: {
            return {
                ...state,
                formSaved: true,
            };
        }
        case SAVE_CRID_CODE: {
            let updatedProducer = state.producer;
            const cridCode = action.cridCode || []
            cridCode.forEach(code => {
                updatedProducer = {...updatedProducer, ...code}
            })
            return {
                ...state,
                producer: updatedProducer
            }
        }
        case POPULATE_PRODUCER_INFO: {
            return {
                ...state,
                ...action.payload
            }
        }
        case RESET_TO_INITIALSTATE:
            return initialState;
        default:
            return state;
    }
}