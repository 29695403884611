import companyProfile from '../../../../../reducers/products/companyProfileReducer';
import { axios } from '../../../../../utils/api/axiosConfig';
import { inSubmittedStatus, getNBCaseProgress } from '../../../../../reducers/products/helpers/companyProfileConsts';
import { toMMDDYYYYFormatSlash } from '../../../../../utils/formatters/dates';
import { isUhcRenewals } from '../../../../../utils/businessLogic/platformUtils';
import { isGuaranteedRule } from '../../../../../utils/businessLogic/guaranteedPeriod';

const apiRoutes = require('../../../../../utils/api/apiRouteConfig');

const makePercentage = (numComplete, total) => Math.floor((numComplete / total) * 100);

/**
 * Checks an employee for status of cobra
 * @param {Object} employee Individual employee being checked
 */
const employeeCobraCheck = (employee) => {
    // Checks if employeeInfo object exists or not and if it does it looks at the nested value for status
    const { employeeInfo, employeeStatus } = employee;
    const isCobra = (!employeeInfo && employeeStatus === 'COBRA') || (employeeInfo && employeeInfo.employeeStatus === 'COBRA');
    return isCobra;
};
/**
 *
 * @param {object} applicationState state.enrollment.application
 * @param {boolean} isRenewal true if renewal flow
 * @param {boolean} includeStandardHRAinOverallProgress true when standard hra is selected
 * @param {boolean} standardHRACompleted true when standard hra form has been completed 
 */
 const employee1099Check = (employee) => {
    // Checks if employeeInfo object exists or not and if it does it looks at the nested value for status
    const { employeeInfo, employeeStatus } = employee;
    const is1099 = (!employeeInfo && employeeStatus === '1099 Independent Contractors') || (employeeInfo && employeeInfo.employeeStatus === '1099 Independent Contractors');
    return is1099;
};
export const getEmployerProgress = (applicationState, isRenewal = false, includeStandardHRAinOverallProgress = false, standardHRACompleted  = false) => {
    const {
        employer, eligibility, producer, attestation,
    } = applicationState;
    const { attestation: employerAttestation } = attestation;
    const pages = isRenewal
        ? [employer, eligibility, producer]
        : [employer, eligibility, producer, employerAttestation];
     let numPages = pages.length;
        if(includeStandardHRAinOverallProgress){
            numPages++;
        }
        
    let completedPages = 0;
    pages.forEach((page) => {
        if (page && page.completed) {
            completedPages += 1;
        }
    });
    
    if(includeStandardHRAinOverallProgress && standardHRACompleted){
        completedPages++;
      }
    return makePercentage(completedPages, numPages);
};

export const getEmployeeStats = (employees) => {
    let numEmployeesNotAttested = 0;
    let numEmployees = 0;
    let completedEmployees = 0;
    for (let i = 0; i < employees.length; i++) {
        const employeeGroup = employees[i];
        for (let j = 0; j < employeeGroup.length; j++) {
            const employee = employeeGroup[j];
            numEmployees += 1;
            if (employee && employee.status && employee.status === 'Complete') {
                completedEmployees += 1;
            }
            if (!employee.memberId && employee.waiveCoverage && employee.waiveCoverage.isWaivingAll !== 'Yes') {
                numEmployeesNotAttested += 1;
            }
        }
    }
    return {
        numEmployeesNotAttested,
        numEmployees,
        completedEmployees
    }
}

/**
 *
 * @param {object} companyProfile state.companyProfile
 */
export const getEmployeeProgress = (fullProps) => {
    const { companyProfile, overall: { platform } } = fullProps;
    const { employees } = companyProfile;
    const { employeeEnrollmentCompletion } = fullProps.enrollment.application.employee;
    const isSubmitted = inSubmittedStatus(getNBCaseProgress(fullProps));
    const closeEnrollmentLeft = employeeEnrollmentCompletion ? 0 : 1;
    const { completed: employeeAttestationCompleted } = fullProps.enrollment.application.attestation.employeeAttestation

    const {numEmployeesNotAttested, numEmployees, completedEmployees} = getEmployeeStats(employees);
    if (isSubmitted) { return 100; }
    
    const employeeAttestationLeft = employeeAttestationCompleted ? 0 : 1
    if(platform === 'PRIME') {
        return makePercentage(completedEmployees, numEmployees) - closeEnrollmentLeft - employeeAttestationLeft;
    } else {
        return makePercentage(completedEmployees, numEmployees) - closeEnrollmentLeft - employeeAttestationLeft - numEmployeesNotAttested;
    }
};

/**
 * Returns a string representing the current enrollment status of the employees in companyProfile.employees
 * @param {object} companyProfile state.companyProfile
 */
export const getEmployeeEnrollmentStatus = (fullProps) => {
    const progress = getEmployeeProgress(fullProps);
    if (progress === 100) {
        return 'Completed';
    }
    if (progress > 0 && progress < 100) {
        return 'In Progress';
    }
    return 'Not Started';
};

export const getPaymentProgress = (paymentStatus) => {
    let numComplete = 0;
    if (paymentStatus.payment.completed()) {
        numComplete += 1;
    }
    if (paymentStatus.billing.completed()) {
        numComplete += 1;
    }
    return makePercentage(numComplete, 2);
};

export const getDocumentProgress = (employerProgress, documentSections, visableSections, requiredSections, completedSections) => {
    const total = Object.keys(requiredSections).length;
    const completed = completedSections.length;
    if (total === 0) {
        if (employerProgress === 100) {
            return 100;
        }
        // If the user hasn't finished the employer application,
        // we don't know if they will have more required docs in the future
        return 0;
    }
    return makePercentage(completed, total);
};
export const getRLDocumentProgress = (employerProgress, documentSections, visableSections, requiredSections, completedSections) => {
    const total = Object.keys(requiredSections).length;
    const completed = completedSections.length;
    if (total === 0) {
        return 100;
    }
    return makePercentage(completed, total);
};

export const getTotalProgress = (
    employerProgress,
    employeeProgress,
    paymentProgress,
    documentProgress,
) => {
    let totalProgress = 0;
    if (employerProgress === 100) { totalProgress += 100; }
    if (employeeProgress === 100) { totalProgress += 100; }
    if (paymentProgress === 100) { totalProgress += 100; }
    if (documentProgress === 100) { totalProgress += 100; }
    return makePercentage(totalProgress, 400);
};

export const getRLTotalProgress = (
    employerProgress,
    employeeProgress,
    documentProgress,
) => {
    let totalProgress = 0;
    if (employerProgress === 100) { totalProgress += 100; }
    if (employeeProgress === 100) { totalProgress += 100; }
    if (documentProgress === 100) { totalProgress += 100; }
    return makePercentage(totalProgress, 300);
};

/**
 * Function that takes state code and returns participation minimum
 * @param {String} stateCode State code of group
 */
const stateParticipationMinimum = (stateCode, isHNY) => {
    switch (stateCode) {
        case 'CT':
            return 75;
        case 'NY':
            return isHNY ? 50 : 60;
        case 'NJ':
            return 75;
        case 'NH':
            return 75;
        default:
            return 100;
    }
};

/**
 * Returns the most chosen plan from an object
 * @param {Object} planCounts Object representing plan names and counts of employees enrolling
 */
const mostChosenPlan = (planCounts) => {
    const sortedChosenPlan = Object.keys(planCounts).sort((x, y) => planCounts[y] - planCounts[x]);
    // if there are multiple plans and the most choosen is to waive
    if (sortedChosenPlan.length > 1 && sortedChosenPlan[0] === 'waive') {
        return sortedChosenPlan[1];
    }
    return sortedChosenPlan[0];
};
/**
 * Returns the total chosen plan from an object
 * @param {Object} planCounts Object representing plan names and counts of employees enrolling
 */
const calculateTotalChosenPlan = (planCounts) => {
    let totalChosenPlan = 0;

    for (const key in planCounts) {
        if (key !== 'waive') {
            totalChosenPlan += planCounts[key];
        }
    }
    return totalChosenPlan;
};
/**
 * Returns the funding type for dental.
 * @param {Object} plans Object representing plans users take to enroll.
 * Funding Type:
 * Voluntary - When plan(s) Voluntary.
 * Contributory - When plan(s) Contributory.
 * Contributory - When plans Voluntary + Contributory.
 */
const calculateDentalFundingType = (plans) => {
    let fundingType = '';
    for (const plan of plans) {
        if (fundingType === '') {
            fundingType = plan.fundingType;
        } else if (fundingType.toUpperCase() === 'VOLUNTARY') {
            fundingType = plan.fundingType;
        }
    }
    return fundingType;
};

/**
 *
 * @param {Array} plans array of objects representing plans in enrollment
 * @param {Number} potentialEnrollees Number representing how many eligible employees the group has (number eligible - cobra)
 * @param {Object} planCount array of objects representing the employees of a group at the main location
 * @returns An object containing boolean representing if the group of employees pass participation and the associated plan errors
 */
const handleDentalParticipation = (plans, potentialEnrollees, planCount) => {
    let isDentalEnrolled = false;
    let totalChosenDentalCount; let
        fundingType;

    // TODO this is breaking on importing
    if (!planCount) {
        return { passed: false, error: 'Invalid product selection' };
    }

    if (plans && plans.length > 0) {
        isDentalEnrolled = true;
        totalChosenDentalCount = calculateTotalChosenPlan(planCount);
        fundingType = calculateDentalFundingType(plans);
    }

    // if the only chosen option is to waive or there are no dental plans in enrollment
    if (isDentalEnrolled) {
        // if(ibrParticipation > 0){
        //     if((ibrParticipation> (totalChosenDentalCount / potentialEnrollees) * 100)){
        //         return { passed: false, error: `Dental Must have ${ibrParticipation}% participation, and group currently at ${((totalChosenDentalCount / potentialEnrollees) * 100 || 0).toFixed(2)}%.` };
        //     }else{
        //         return { passed: true, error: '' };
        //     }
        // }
        switch (fundingType) {
            case 'CONTRIBUTORY':
            case 'Contributory':
                if (totalChosenDentalCount / potentialEnrollees >= 0.5) {
                    return { passed: true, error: '' };
                }
                const dentalPercentage = ((totalChosenDentalCount / potentialEnrollees) * 100 || 0).toFixed(2);
                return { passed: false, error: `Dental must have a minimum of 50% participation, and group currently at ${dentalPercentage}%.` };
            case 'VOLUNTARY':
            case 'Voluntary':
                if (totalChosenDentalCount >= 2) {
                    return { passed: true, error: '' };
                }
                return { passed: false, error: 'Dental minimum of 2 eligible employees must be enrolled' };
            default:
                return { passed: false, error: 'Invalid funding type' };
        }
    }
    // if the most choosen option is to waive then fail participation
    return { passed: false, error: 'Dental does not meet minimum particpation' };
};
/**
 *
 * @param {Array} plans array of objects representing plans in enrollment
 * @param {Number} potentialEnrollees Number representing how many eligible employees the group has (number eligible - cobra)
 * @param {Object} planCount array of objects representing the employees of a group at the main location
 * @returns An object containing boolean representing if the group of employees pass participation and the associated plan errors
 */
const handleVisionParticipation = (plans, potentialEnrollees, planCount) => {
    // Get highest participation plan name and count values from planCount
    let mostChosenVision;
    let mostChosenVisionInfo;
    // TODO this is breaking on importing
    if (!planCount) {
        return { passed: false, error: 'Invalid product selection' };
    }
    // if there is only 1 plan
    if (plans && plans.length === 1) {
        [mostChosenVisionInfo] = plans;
        mostChosenVision = mostChosenVisionInfo.code;
    } else if (plans && plans.length > 1) {
        // if there are multiple Vision plans
        mostChosenVision = mostChosenPlan(planCount);
        mostChosenVisionInfo = plans.find((plan) => plan.code === mostChosenVision);
    } else {
        // should only get here if there are no Vision plans in enrollment
        mostChosenVision = 'waive';
    }
    if (mostChosenVision !== 'waive') {
        const mostChosenVisionCount = planCount[mostChosenVision];
        const { visionPlanTypeDescription } = mostChosenVisionInfo;
        // if(ibrParticipation > 0){
        //     if((ibrParticipation> (mostChosenVisionCount / potentialEnrollees) * 100)){
        //         return { passed: false, error: `Vision Must have ${ibrParticipation}% participation, and group currently at ${((mostChosenVisionCount / potentialEnrollees) * 100 || 0).toFixed(2)}%.` };
        //     }else{
        //         return { passed: true, error: '' };
        //     } 
        // }
        switch (visionPlanTypeDescription) {
            case '100% ER Paid':
            case '100% ER PAID':
            case 'EE Core/Vol Dep':
            case '50/50':
            {
                if (mostChosenVisionCount / potentialEnrollees >= 0.5) {
                    return { passed: true, error: '' };
                }
                const visionPercantage = (
                    (mostChosenVisionCount / potentialEnrollees) * 100 || 0
                ).toFixed(2);
                return { passed: false, error: `Vision must have a minimum of 50% participation, and group currently at ${visionPercantage}%.` };
            }
            case 'Voluntary':
            case 'VOLUNTARY':
                if (mostChosenVisionCount >= 1) {
                    return { passed: true, error: '' };
                }
                return { passed: false, error: 'Vision minimum of 2 eligible employees, at least 1 must be enrolled' };
            default:
                return { passed: false, error: 'Invalid funding type' };
        }
    }
    // if the most choosen option is to waive then fail participation
    return { passed: false, error: 'Vision does not meet minimum particpation' };
};

// Determines which funding type rule to use for life classing
const checkLifeContributionType = (plans) => {
    // Builds unique list of funding types from enrolled life plans
    const fundTypes = [...new Set(plans.map((plan) => plan.fundingType))];
    // If there is only one type of funding, use that
    if (fundTypes.length === 1) {
        return fundTypes[0];
    }
    // If there is a mix of contrib & non contrib, treat as contrib
    if ((fundTypes.includes('Contributory') && fundTypes.includes('Non-Contributory')) || (fundTypes.includes('Contributary') && fundTypes.includes('Non-Contributary'))) {
        return 'Contributory';
    }

    return '';
};

const totalLifeEnrollees = (employees) => {
    let total = 0;
    employees.forEach((employee) => {
        // Grab product selection from employee then grab employees selection for products
        let infoEmployeeStatus = null;
        let productSelection = {};

        if (employee.employeeInfo && employee.employeeInfo.employeeStatus && (JSON.stringify(employee.employeeInfo.employeeStatus) !== JSON.stringify({}))) {
            infoEmployeeStatus = employee.employeeInfo.employeeStatus.toLowerCase();
        }
        if (employee.productSelection) {
            productSelection = employee.productSelection;
        }
        const employeeStatus = employee.employeeStatus && (JSON.stringify(employee.employeeStatus) !== JSON.stringify({})) ? employee.employeeStatus.toLowerCase() : null;

        // only count employee if they arent cobra productSelection is not always initialized this should prevent breaks
        if (employeeStatus !== 'cobra' && infoEmployeeStatus !== 'cobra' && productSelection && productSelection[0]) {
            const employeeProductSelection = productSelection[0];
            if (employeeProductSelection.life && employeeProductSelection.life !== '' && employeeProductSelection.life !== 'waive') {
                total += 1;
            }
        }
    });
    return total;
};

/**
 *  @param {Array} array of passing/error entries from each of the different classed life/disability groups
 *  @returns An object containing a boolean representing if the group of employees pass participation and the associated plan errors
 */
const parseLifeAndDisabilityClassingReturn = (returnArray) => {
    let passedArray = []
    let errorArray = []

    returnArray.forEach(returnObj => {
        passedArray.push(returnObj.passed);
        returnObj.error ? errorArray.push(returnObj.error) : '';
    })

    return { passed: !passedArray.includes(false), errors: errorArray }
}

/**
 * Purpose of function is to filter out employees with specific statuses I.E. Cobra
 *  @param {Array} array of potential employees
 *  @returns int representing total number of potential employees
 */
const potentialEmployeeCount = (employees) => {
    let total = 0
    employees.forEach(employee => {

        const employeeStatus = employee.employeeStatus && (JSON.stringify(employee.employeeStatus) !== JSON.stringify({})) ? employee.employeeStatus.toLowerCase() : null;
        let infoEmployeeStatus = null;

        if (employee.employeeInfo && employee.employeeInfo.employeeStatus && (JSON.stringify(employee.employeeInfo.employeeStatus) !== JSON.stringify({}))) {
            infoEmployeeStatus = employee.employeeInfo.employeeStatus.toLowerCase();
        }

        if (employeeStatus !== 'cobra' && infoEmployeeStatus !== 'cobra') {
            total += 1
        }
    })
    return total;
}

/**
 *
 * @param {Array} plans array of objects representing plans in enrollment
 * @param {Number} potentialEnrollees Number representing how many eligible employees the group has (number eligible - cobra)
 * @param {Object} planCount array of objects representing the employees of a group at the main location
 * @returns An object containing boolean representing if the group of employees pass participation and the associated plan errors
 */
const handleLifeParticipation = (plans, potentialEnrollees, planCount, contribution, employeeClassing, employees) => {
    const { classingOpted, classes } = employeeClassing;

    if (classingOpted) {
        let returnArray = [];
        classes.forEach(empClass => {
            let classEmployees = []
            employees.forEach(employee => {
                if (employee.optedLifeClass === empClass) {
                    classEmployees.push(employee)
                }
            })

            let classPlan = plans.filter(plan => plan.selectedClass === empClass)
            const potentialClassEnrollees = potentialEmployeeCount(classEmployees)
            const totalEnrolled = totalLifeEnrollees(classEmployees);
            const fundType = checkLifeContributionType(classPlan);

            switch (fundType) {
                case 'Contributory':
                case 'EMP LIFE':
                case 'Contributary':
                    // if user enters a contribution of 25-99%
                    if (contribution >= 25 && totalEnrolled / potentialClassEnrollees >= 0.75) {
                        returnArray.push({ passed: true, error: '' });
                        break;
                    }else if(isUhcRenewals() && totalEnrolled / potentialClassEnrollees >= 0.75){
                        returnArray.push({ passed: false, error: `${empClass} Basic Life Employer Contribution for Employee should be atleast 25% and currently it is at ${contribution}%. `,failedContribution: true });
                        break;
                    }
                    const lifePercent = ((totalEnrolled / potentialClassEnrollees) * 100 || 0).toFixed(2);
                    returnArray.push({ passed: false, error: `${empClass} Life Must have 75% participation, and group currently at ${lifePercent}%.` });
                    break;
                case 'Non-Contributory':
                case 'Non-Contributary':
                    if (totalEnrolled / potentialClassEnrollees === 1) {
                        returnArray.push({ passed: true, errors: [] });
                        break;
                    }
                    const lifePercentage = ((totalEnrolled / potentialClassEnrollees) * 100 || 0).toFixed(2);
                    if(potentialClassEnrollees === 0){
                        returnArray.push({ passed: false , error: `${empClass} Class must have at least one employee in order to proceed. Please correct the enrollment in order to continue.` });
                    }else{
                        returnArray.push({ passed: false , error: `${empClass} Life must have 100% participation and group currently at ${lifePercentage}` });
                    }
                    break;
                default:
                    returnArray.push({ passed: false, error: 'Invalid funding type' });
            }
        });
        return parseLifeAndDisabilityClassingReturn(returnArray);
    } else {
            // Get highest participation plan name and count values from planCount
        let mostChosenLife;
        let mostChosenLifeInfo;
        // TODO this is breaking on importing
        if (!planCount) {
            return { passed: false, error: 'Invalid product selection' };
        }
        // if there is only 1 plan
        if (plans && plans.length === 1) {
            [mostChosenLifeInfo] = plans;
            mostChosenLife = mostChosenLifeInfo.code;
        } else if (plans && plans.length > 1) {
            // if there are multiple Life plans
            mostChosenLife = mostChosenPlan(planCount);
            mostChosenLifeInfo = plans.find((plan) => plan.code === mostChosenLife);
        } else {
            // should only get here if there are no Life plans in enrollment
            mostChosenLife = 'waive';
        }
        if (mostChosenLife !== 'waive') {
            const mostChosenLifeCount = planCount[mostChosenLife];
            const { fundingType } = mostChosenLifeInfo;
            // if(ibrParticipation > 0){
            //     if(ibrParticipation> (mostChosenLifeCount / potentialEnrollees) * 100){
            //         return { passed: false, error: `Life Must have ${ibrParticipation}% participation, and group currently at ${((mostChosenLifeCount / potentialEnrollees) * 100 || 0).toFixed(2)}%.` };
            //     }else{
            //         return { passed: true, error: '' };
            //     }  
            // }
            switch (fundingType) {
                case 'Contributory':
                // for IBR
                case 'EMP LIFE':
                case 'Contributary':
                    // if user enters a contribution of 25-99%
                    if (contribution >= 25 && mostChosenLifeCount / potentialEnrollees >= 0.75) {
                        return { passed: true, error: '' };
                    }else if(isUhcRenewals() && mostChosenLifeCount / potentialEnrollees >= 0.75){
                        return { passed: false, error: `Basic Life Employer Contribution for Employee should be atleast 25% and currently it is at ${contribution}%. `,failedContribution: true };
                    }
                    const lifePercen = ((mostChosenLifeCount / potentialEnrollees) * 100 || 0).toFixed(2);
                    return { passed: false, error: `Life Must have 75% participation, and group currently at ${lifePercen}%.` };
                case 'Non-Contributory':
                case 'Non-Contributary':
                    // TODO find out if dental non contributory plans actually exist
                    if (mostChosenLifeCount / potentialEnrollees === 1) {
                        return { passed: true, errors: [] };
                    }
                    const lifePercantage = ((mostChosenLifeCount / potentialEnrollees) * 100 || 0).toFixed(2);
                    return { passed: false, error: `Life must have 100% participation, and group currently at ${lifePercantage}%.` };
                default:
                    return { passed: false, error: 'Invalid funding type' };
            }
        }
            // if the most choosen option is to waive then fail participation
    return { passed: false, error: 'Life does not meet minimum particpation' };
    }
};

// Determines which contribution type rule to use for disability classing
const checkDisabilityContributionType = (plans) => {
    // Builds unique list of contribution types from enrolled life plans
    const contributionType = [...new Set(plans.map((plan) => plan.contributionType.toLowerCase()))];
    // If there is only one type of funding, use that
    if (contributionType.length === 1) {
        return contributionType[0];
    }
    // If there is a mix of contrib & non contrib, treat as contrib
    if (contributionType.includes('non-contributory')) {
        return "non-contributory"
    } else if (contributionType.includes('contributory')) {
        return 'contributory'
    } else if (contributionType.includes('voluntary')) {
        return 'voluntary'
    }

    return '';
};

const totalDisabilityEnrollees = (employees, productType) => {
    let total = 0;
    employees.forEach((employee) => {
        // Grab product selection from employee then grab employees selection for products
        let infoEmployeeStatus = null;
        let productSelection = {};

        if (employee.employeeInfo && employee.employeeInfo.employeeStatus && (JSON.stringify(employee.employeeInfo.employeeStatus) !== JSON.stringify({}))) {
            infoEmployeeStatus = employee.employeeInfo.employeeStatus.toLowerCase();
        }
        if (employee.productSelection) {
            productSelection = employee.productSelection;
        }
        const employeeStatus = employee.employeeStatus && (JSON.stringify(employee.employeeStatus) !== JSON.stringify({})) ? employee.employeeStatus.toLowerCase() : null;

        // only count employee if they arent cobra productSelection is not always initialized this should prevent breaks
        if (employeeStatus !== 'cobra' && infoEmployeeStatus !== 'cobra' && productSelection && productSelection[0]) {
            const employeeProductSelection = productSelection[0];
            if (employeeProductSelection[productType] && employeeProductSelection[productType] !== '' && employeeProductSelection[productType] !== 'waive') {
                total += 1;
            }
        }
    });
    return total;
};

/**
 *
 * @param {Array} plans array of objects representing plans in enrollment
 * @param {Number} potentialEnrollees Number representing how many eligible employees the group has (number eligible - cobra)
 * @param {Object} planCount array of objects representing the employees of a group at the main location
 * @returns An object containing boolean representing if the group of employees pass participation and the associated plan errors
 */
 const handleDisabilityParticipation = (plans, potentialEnrollees, planCount, contribution, employeeClassing, employees, productType) => {
    const { classingOpted, classes } = employeeClassing;
    const productTypeFull = productType === 'std' ? "Short Term Disability" : "Long Term Disability"
    let disabilityPercentage

    if (classingOpted) {
        let returnArray = []
        classes.forEach(empClass => {
            let classEmployees = []
            employees.forEach(employee => {
                if (employee.optedLifeClass === empClass) {//find out right field name
                    classEmployees.push(employee)
                }
            })

            let classPlan = plans.filter(plan => plan.selectedClass === empClass)
            const potentialClassEnrollees = potentialEmployeeCount(classEmployees)
            const totalEnrolled = totalDisabilityEnrollees(classEmployees, productType);
            const contributionType = checkDisabilityContributionType(classPlan);

            switch (contributionType) {
                case 'non-contributory':
                    if (totalEnrolled / potentialClassEnrollees === 1) {
                        returnArray.push({ passed: true, errors: [] });
                        break;
                    }
                    disabilityPercentage = ((totalEnrolled / potentialClassEnrollees) * 100 || 0).toFixed(2);
                    returnArray.push({ passed: false, error: `${empClass} ${productTypeFull} must have 100% participation, and group currently at ${disabilityPercentage}%.` });
                    break;
                case 'contributory':
                    if (totalEnrolled / potentialClassEnrollees >= 0.5) {
                        returnArray.push({ passed: true, error: '' });
                        break;
                    }
                    returnArray.push({ passed: false, error: ` ${empClass} ${productTypeFull} must have 50% participation, and group currently at ${((totalEnrolled / potentialClassEnrollees) * 100 || 0).toFixed(2)}%.` });
                    break;
                case 'voluntary':
                    if (totalEnrolled / potentialClassEnrollees >= 0.25) {
                        returnArray.push({ passed: true, error: '' });
                        break;
                    }
                    returnArray.push({ passed: false, error: `${empClass} ${productTypeFull} must have 25% participation, and group currently at ${((totalEnrolled / potentialClassEnrollees) * 100 || 0).toFixed(2)}%.` });
                    break;
                default:
                    returnArray.push({ passed: false, error: 'Invalid contribution type' });
            }
        });

        return parseLifeAndDisabilityClassingReturn(returnArray);
    } else {

    // Get highest participation plan name and count values from planCount
    let mostChosenDisability;
    let mostChosenDisabilityInfo;
    // TODO this is breaking on importing
    if (!planCount) {
        return { passed: false, error: 'Invalid product selection' };
    }
    // if there is only 1 plan
    if (plans && plans.length === 1) {
        [mostChosenDisabilityInfo] = plans;
        mostChosenDisability = mostChosenDisabilityInfo.code;
    } else if (plans && plans.length > 1) {
        // if there are multiple Life plans
        mostChosenDisability = mostChosenPlan(planCount);
        mostChosenDisabilityInfo = plans.find((plan) => plan.code === mostChosenDisability);
    } else {
        // should only get here if there are no disability plans in enrollment
        mostChosenDisability = 'waive';
    }
    if (mostChosenDisability !== 'waive') {
        const mostChosenDisabilityCount = planCount[mostChosenDisability];
        const { contributionType } = mostChosenDisabilityInfo;
        switch (contributionType.toLowerCase()) {
            case 'non-contributory':
                if (mostChosenDisabilityCount / potentialEnrollees === 1) {
                    return { passed: true, error: '' };
                }
                disabilityPercentage = ((mostChosenDisabilityCount / potentialEnrollees) * 100 || 0).toFixed(2);
                return { passed: false, error: `${productTypeFull} must have 100% participation, and group currently at ${disabilityPercentage}%.` };
            case 'contributory':
                if (mostChosenDisabilityCount / potentialEnrollees >= 0.5) {
                    return { passed: true, error: '' };
                }
                return { passed: false, error: `${productTypeFull} must have 50% participation, and group currently at ${((mostChosenDisabilityCount / potentialEnrollees) * 100 || 0).toFixed(2)}%.` };
            case 'voluntary':
                if (mostChosenDisabilityCount / potentialEnrollees >= 0.25) {
                    return { passed: true, error: '' };
                }
                return { passed: false, error: `${productTypeFull} must have 25% participation, and group currently at ${((mostChosenDisabilityCount / potentialEnrollees) * 100 || 0).toFixed(2)}%.` };
            default:
                return { passed: false, error: 'Invalid contribution type' };
        }
    }
    // if the most choosen option is to waive then fail participation
    return { passed: false, error: `${productTypeFull} does not meet minimum particpation` };
    }
};


/**
 *
 * @param {Array} plans array of objects representing plans in enrollment
 * @param {Number} potentialEnrollees Number representing how many eligible employees the group has (number eligible - cobra)
 * @param {Object} planCount array of objects representing the employees of a group at the main location
 * @param {Array} employees array of all employees
 * @returns An object containing boolean representing if the group of employees pass participation and the associated plan errors
 */
const handleSuppLifeParticipation = (plans, potentialEnrollees, planCount, employees) => {
        // Get highest participation plan name and count values from planCount
    let mostChosenSuppLife;
    let mostChosenSuppLifeInfo;
    // TODO this is breaking on importing
    if (!planCount) {
        return { passed: false, error: 'Invalid product selection' };
    }
    // if there is only 1 plan
    if (plans && plans.length === 1) {
        [mostChosenSuppLifeInfo] = plans;
        mostChosenSuppLife = mostChosenSuppLifeInfo.code;
    } else if (plans && plans.length > 1) {
        // if there are multiple Life plans
        mostChosenSuppLife = mostChosenPlan(planCount);
        mostChosenSuppLifeInfo = plans.find((plan) => plan.code === mostChosenSuppLife);
    } else {
        // should only get here if there are no Life plans in enrollment
        mostChosenSuppLife = 'waive';
    }
    if (mostChosenSuppLife !== 'waive') {
        const mostChosenSuppLifeCount = planCount[mostChosenSuppLife];


        if (mostChosenSuppLifeCount / potentialEnrollees >= 0.25) {
            return { passed: true, error: '' };
        } else {
            return {passed: false, error: `Supplemental Life Must have 25% participation, and group currently at ${(mostChosenSuppLifeCount / potentialEnrollees) * 100 || 0}%.`}
        }
    }
        // if the most choosen option is to waive then fail participation
    return { passed: false, error: 'Supplemental Life does not meet minimum particpation' };
}

/**
 * Function that iterates over employees and returns an object representing number of employees enrolling in plans separated by product line that excludes cobra from the count
 * @param {Array} employees Array of employees
 */
const getProductPlanCounter = (employees) => {
    const productPlanCount = {};
    employees.forEach((employee) => {
        // Grab product selection from employee then grab employees selection for products
        let infoEmployeeStatus = null;
        let productSelection = {};

        if (employee.employeeInfo && employee.employeeInfo.employeeStatus && (JSON.stringify(employee.employeeInfo.employeeStatus) !== JSON.stringify({}))) {
            infoEmployeeStatus = employee.employeeInfo.employeeStatus.toLowerCase();
        }
        if (employee.productSelection) {
            productSelection = employee.productSelection;
        }
        const employeeStatus = employee.employeeStatus && (JSON.stringify(employee.employeeStatus) !== JSON.stringify({})) ? employee.employeeStatus.toLowerCase() : null;

        // only count employee if they arent cobra productSelection is not always initialized this should prevent breaks
        if (employeeStatus !== 'cobra' && infoEmployeeStatus !== 'cobra' && productSelection && productSelection[0]) {
            const employeeProductSelection = productSelection[0];
            // Loop through selections and add to counter
            Object.entries(employeeProductSelection).forEach(([product, planName]) => {
                if (product in productPlanCount) {
                    if (planName in productPlanCount[product]) {
                        productPlanCount[product][planName] += 1;
                    } else {
                        productPlanCount[product] = { ...productPlanCount[product], [planName]: 1 };
                    }
                } else {
                    productPlanCount[product] = { [planName]: 1 };
                }
            });
        }
    });
    return productPlanCount;
};

const isValidWaiver = (empWaiveReason, stateInvalidWaiveReason) => {
    let isValid = true;
    if (empWaiveReason) {
        for (const invalidWaiverReason of stateInvalidWaiveReason) {
            if (invalidWaiverReason.trim().toUpperCase() === empWaiveReason.trim().toUpperCase()) {
                isValid = false;
                break;
            }
        }
    }
    return isValid;
};

const getValidWaivers = ((invalidWaivers, allEmployees) => allEmployees.filter(
    (employee) => isUhcRenewals() ? (employee.coverages
            && employee.coverages.medical 
            && ['', '-', 'select', 'waive', 'W'].includes(employee.coverages.medical) 
            && isValidWaiver(employee.waiveReason, invalidWaivers))  
            : (employee.waiveOption
            && employee.waiveOption.length > 0
            && (employee.waiveOption.includes('medical') || employee.waiveCoverage.isWaivingAll === 'Yes') 
            && isValidWaiver(employee.waiveReason, invalidWaivers)),
    // && !employeeCobraCheck(employee),
).length);

const getInvalidWaivers = ((invalidWaivers, allEmployees) => allEmployees.filter(
    (employee) => isUhcRenewals() ? (employee.coverages
            && employee.coverages.medical 
            && ['', '-', 'select', 'waive', 'W'].includes(employee.coverages.medical) && !isValidWaiver(employee.waiveReason, invalidWaivers)) 
            :(employee.waiveOption
            && employee.waiveOption.length > 0
            && (employee.waiveOption.includes('medical') || employee.waiveCoverage.isWaivingAll === 'Yes') && !isValidWaiver(employee.waiveReason, invalidWaivers)),
    // && !employeeCobraCheck(employee),
).length);

// const getIbrParticipationPercent = (product, ibrEmpCount, ibrPlans) =>{
//     let productLineType = {
//         medical : 'MEDICAL',
//         dental : 'DENTAL',
//         vision : 'VISION',
//         life : 'BASIC_LIFE_EE'
//     }
//     let totalProductParticipation = 0;
//     ibrPlans && ibrPlans.forEach(plan=>{
//         if(plan.productLineType === productLineType[product]){
//             plan.clientDefinedData?.data && plan.clientDefinedData.data.forEach(cdd=>{
//                 if(cdd.key === 'EmployeeEnrollCount'){
//                     totalProductParticipation += parseInt(cdd.value)
//                 }
//             })
//         }
//     })
//     return (totalProductParticipation/ibrEmpCount)*100;
// }

const getparticipationData = (state, eligibleCount, numMedicalEnrollees, allEmployees) => {
    const { store } = require('../../../../../index');
    const fullProps = store.getState();
    let participation = 0;
    let participationPercent = 0;
    let postValidParticipation = 0;
    const medERContribution = isUhcRenewals() ?  fullProps.renewalEnrollment.mdvContribution.medical.eValue : fullProps.enrollment.application.eligibility.contribution.medicalEmployerContribution
    const participationData = fullProps.overall.participationRules;
    const basedOff = participationData ? participationData.basedOff : '';
    const invalidMedicalWaivers = participationData ? participationData.invalidMedicalWaivers : [];
    const numValidMedicalWaiversPrime = getValidWaivers(invalidMedicalWaivers, allEmployees);
    const numInvalidMedicalWaiversPrime = getInvalidWaivers(invalidMedicalWaivers, allEmployees);
    const activeEmployeeCount = isUhcRenewals() ? allEmployees.filter((employee) => employee.employeeStatus === 'Active' && !(employee.coverages && employee.coverages.medical && ['', '-', 'select', 'waive', 'W'].includes(employee.coverages.medical))).length : allEmployees.filter((employee) => employee.employeeStatus === 'Active' && !(employee.waiveOption && employee.waiveOption.length > 0 && (employee.waiveOption.includes('medical') || employee.waiveCoverage.isWaivingAll === 'Yes'))).length;
    const cobraEmployeeCount = isUhcRenewals() ? allEmployees.filter((employee) => employee.employeeStatus === 'COBRA' && !(employee.coverages && employee.coverages.medical && ['', '-', 'select', 'waive', 'W'].includes(employee.coverages.medical))).length : allEmployees.filter((employee) => employee.employeeStatus === 'COBRA' && !(employee.waiveOption && employee.waiveOption.length > 0 && (employee.waiveOption.includes('medical') || employee.waiveCoverage.isWaivingAll === 'Yes'))).length;
    const numberOfEnrolles = activeEmployeeCount + cobraEmployeeCount;
    const numberOfEligible = activeEmployeeCount + numValidMedicalWaiversPrime + numInvalidMedicalWaiversPrime;
    const totalEligibleParticipation = eligibleCount !== 0 ? (numMedicalEnrollees / eligibleCount) * 100 : 0;
    const medicalEmployerContribution = fullProps.enrollment.application.eligibility.contribution.medicalEmployerContribution || 0;
    const employees = fullProps?.companyProfile?.employees;
    let bolEEsValid = false;
    participationPercent = participationData ? participationData.participationPercentMin : 0;
    if (numberOfEligible !== numValidMedicalWaiversPrime) {
        postValidParticipation = ((numberOfEnrolles - cobraEmployeeCount) / (numberOfEligible - numValidMedicalWaiversPrime)) * 100;
    }
    switch (basedOff) {
        case 'Post Valid Waivers':
            if(state === 'WV' && medERContribution < 100){
                participation = 100
                break
            }
            participation = postValidParticipation;
            break;
        case 'Total Eligible':
            participation = totalEligibleParticipation;
            break;
        case 'Exception':
            switch (state) {
                case 'WV':
                case 'AK':
                    participation = postValidParticipation;
                    if (parseInt(medicalEmployerContribution) === 100) {
                        participationPercent = 100;
                    }
                    break;
                case 'MA':
                    participation = postValidParticipation;
                    if (eligibleCount <= 5) {
                        participationPercent = 100;
                    }
                    break;
                case 'SC':
                    const minimumParticipation = Math.min(postValidParticipation, totalEligibleParticipation);
                    if (postValidParticipation >= participationPercent && totalEligibleParticipation >= 50) {
                        participation = postValidParticipation;
                    } else {
                        participation = minimumParticipation;
                    }
                    if (eligibleCount <= 3) {
                        participationPercent = 100;
                    }
                    break;
                case 'CA':
                    const minParticipation = Math.min(postValidParticipation, totalEligibleParticipation);
                    //If employer pays 100% premium, all employees must enroll unless they use the Other group waiver reason
                    // let employeeLength = employees.length
                    // employees[0].forEach((employee)=>{
                    //     if(employee.contactInfo.state==="CA"){
                            
                    //     }
                    // })
                    // for(let i=0;i<employees.length;i++){
                    //     for(let j=0; j< employees[i].length;j++){

                    //     }
                    // }
                    // const employeeCount = employees[0].length > 0 && employees[0].filter(emp =>emp.employeeStatus === 'Active').length;
                    // const ooaEmployeesCount = employees[0].filter(employee => employee.contactInfo && employee.contactInfo.state === "CA").length;
                    // const empParticipation = parseInt((ooaEmployeesCount / employeeCount) * 100);
                    // if (empParticipation <= 50) {
                    //     participation = empParticipation;
                    //     participationPercent = eligibleCount <= 4 ? 60 : participationPercent;
                    //     bolEEsValid = false
                    //     break;
                    // }
                    const employeeCount = employees[0].length > 0 && employees[0].filter(emp =>emp.employeeStatus === 'Active').length;
                    if (employees && (parseFloat(medERContribution) === 100)) {
                        for (let i = 0; i < employees.length; i++) {
                            for (let j = 0; j < employees[i].length; j++){
                                if ((employees[i][j].waiveReason !== "Other group coverage with Kaiser Choice Simplified Staff plan") && employees[i][j].waiveReason !== "" && employees[i][j].waiveCoverage.isWaivingAll !== "No") {
                                    bolEEsValid = false
                                    break;
                                }else{
                                    bolEEsValid = true
                                }
                            }
                        }
                    }else{
                        bolEEsValid = true;
                    }
                    if(!bolEEsValid){
                        if((parseFloat(medERContribution) === 100)) {
                            participation = (numMedicalEnrollees + numValidMedicalWaiversPrime)/(employeeCount) * 100;
                            participationPercent = 100;
                        }else if(minParticipation !== 100){
                            participation = minParticipation;
                            participationPercent = 100;
                        }
                    }else{
                        if (postValidParticipation >= participationPercent) {
                            participation = postValidParticipation;
                        } else {
                            participation = minParticipation;
                        }
                        if((parseFloat(medERContribution) === 100) && bolEEsValid) {
                            participation = numMedicalEnrollees/(employeeCount-numInvalidMedicalWaiversPrime) * 100;
                            participationPercent = 100;
                        } else if (employeeCount <= 4) {
                            participationPercent = 60;
                        }
                    }    
                    break;
                default:
                    participation = 0;
            }
            break;
        case 'Both':
            if (totalEligibleParticipation >= 50) {
                participation = ((numberOfEnrolles - cobraEmployeeCount) / (numberOfEligible - numValidMedicalWaiversPrime)) * 100;
            }else{
                participation = totalEligibleParticipation;
            }
            break;
        default:
            participation = 0;
    }
    participation = participation < 0 ? 0 : participation;

    return ({
        participation,
        participationPercent,
    });
};

/**
 *  Checks the participation formula for the company in enrollment
 * @param {object} companyProfile state.companyProfile
 * @param {object} rules state.rules
 * @param {object} contribution state.enrollment.aplication.employer.contribution
 * @param {object} enrollment state.enrollment.application
 */
export const getParticipation = (companyProfile, rules, employeeClassing, contribution = null, enrollment, flow = '', numEligibleEmployees = '', platform, newProducts=[] ) => {
    // destructure parameters
    const {
        employees, locations, primaryLocationIdx, selectedProducts, effectiveDate
    } = companyProfile;
    const { stateCode } = locations[primaryLocationIdx].zipData;
    const allEmployees = employees.flat();
    const {
        enrolledPlans, enrolledDentalPlans, enrolledLifePlans, enrolledVisionPlans, enrolledSTDPlans, enrolledLTDPlans, enrolledSuppLifePlans
    } = rules.enrollment;
    const eligibilityForm = rules.enrollmentForm.eligibility;
    const { employer, eligibility } = enrollment.application;
    const flatMedicalPlans = Object.keys(enrolledPlans).map((key) => enrolledPlans[key]).flat();
    // grab basic life contribution amount to be passed to life handler
    const { basicLifeEmployerContribution, lifeEmployerContribution, longTermDisabilityEmployerContribution, shortTermDisabilityEmployerContribution } = contribution || {};
    const basicLifeEmployerContributionNumber = Number(basicLifeEmployerContribution) || Number(lifeEmployerContribution) ;
    const longTermDisabilityEmployerContributionNumber = Number(longTermDisabilityEmployerContribution) || "";
    const shortTermDisabilityEmployerContributionNumber = Number(shortTermDisabilityEmployerContribution) || "";

    // check status of employees for any incomplete
    const inComplete = allEmployees.find((employee) => employee.status !== 'Complete');
    if (inComplete) {
        return { passedParticipation: false, participation: null, participationResults: null };
    }
    // Grab global constants for participation
    let participation;
    let participationCondition = false;
    const participationResults = {};
    const numEmployees = allEmployees.length;
    // count number of employees that have cobra flag
    const numCobraEmployees = allEmployees.filter(
        (employee) => employeeCobraCheck(employee),
    ).length;
    // Loop through all locations and sum up totalEligEmps for each location
    let eligibleCount = 0;
    if(flow !== 'renewals'){
        locations.forEach((location) => {
            const { totalEligEmps } = location;
            eligibleCount += parseInt(totalEligEmps, 10);
        });
        eligibleCount = enrollment.application.eligibility.participation.numEligibleEmployees ? parseInt(enrollment.application.eligibility.participation.numEligibleEmployees, 10) : eligibleCount;
    }else{
        // For Renewals only
        eligibleCount = enrollment.application.employer && enrollment.application.employer.participation && enrollment.application.employer.participation.numEligibleEmployees ? enrollment.application.employer.participation.numEligibleEmployees : '';
    }
    // subtract number of COBRAs out of total eligibility count
    // eligibleCount -= numCobraEmployees;

    // count of employees in enrollment who arent waiving medical coverage and arent COBRA
    let numMedicalEnrollees;
    if(flow !== 'renewals'){
        numMedicalEnrollees = allEmployees.filter(
            (employee) => employee.waiveOption && (!employee.waiveOption.includes('medical') && !employee.waiveOption.includes('allProducts')) && !employeeCobraCheck(employee),
        ).length;
    }else{
        // For Renewals
        numMedicalEnrollees = employees[primaryLocationIdx] && employees[primaryLocationIdx].filter(
            (employee) => employee.productSelection[primaryLocationIdx]
                && employee.productSelection[primaryLocationIdx].medical !== 'waive'
                && employee.productSelection[primaryLocationIdx].medical !== 'select'
                && !employeeCobraCheck(employee)
        ).length;
    }

    // count of employees in enrollment who arent waiving medical coverage
    let numMedicalEnrolleesIncludingCobra;
    if(flow !== 'renewals'){
        numMedicalEnrolleesIncludingCobra = allEmployees.filter(
            (employee) => employee.waiveOption && !employee.waiveOption.includes('medical'),
        ).length;
    }else{
        // For Renewals
        numMedicalEnrolleesIncludingCobra = employees[primaryLocationIdx] && employees[primaryLocationIdx].filter(
            (employee) => employee.productSelection[primaryLocationIdx]
                && employee.productSelection[primaryLocationIdx].medical !== 'waive'
                && employee.productSelection[primaryLocationIdx].medical !== 'select',
        ).length;
    }

    // count of employees in enrollment who arent cobra and are waiving with any reason excluding Other (reason not listed)
    let numValidMedicalWaivers;
    if(flow !== 'renewals'){
        numValidMedicalWaivers = allEmployees.filter(
            (employee) => employee.waiveOption
                && employee.waiveOption.length > 0
                && (
                    employee.waiveOption.includes('medical')
                    || ((flatMedicalPlans || []).length > 0) && employee.waiveOption.includes('allProducts')
                )
                //&& stateCode === 'NY' ? isValidWaiverOxfordNB(employee.waiveReason) : employee.waiveReason !== 'Other (reason not listed)' // will remove later
                && employee.waiveReason
                && isValidWaiverOxford(employee.waiveReason, stateCode)
                && employee.waiveReason !== ''
                && !employeeCobraCheck(employee),
        ).length;
    }else{
        // For Renewals
        numValidMedicalWaivers = employees[primaryLocationIdx] && employees[primaryLocationIdx].filter(
            (employee) => employee.productSelection[primaryLocationIdx]
                && employee.productSelection[primaryLocationIdx].medical === 'waive'
                && employee.waiveReason
                && isValidWaiverOxford(employee.waiveReason, stateCode)
                //&& employee.waiveReason !== 'Other (reason not listed)', // will remove later
        ).length;
    }
    // Create object of products and plans along with associated counts
    const productPlanCount = getProductPlanCounter(allEmployees);
    if (Object.keys(productPlanCount).length === 0) {
        return { passedParticipation: false, participation: null, participationResults: null };
    }

    // case where there are more employees than there are eligible employees
    // console.log(`numEmployees: ${numEmployees} numValidMedicalWaivers: ${numValidMedicalWaivers} numCobraEmployees: ${numCobraEmployees} eligibleCount: ${eligibleCount}`);
    // if (numEmployees - numValidMedicalWaivers - numCobraEmployees > eligibleCount) {
    //     participationResults.medical = { passed: false, error: 'Too many employees enrolling in medical' };
    //     return { passedParticipation: false, participation: null, participationResults };
    // }

    // // case where there are too many active employees
    // if (eligibleCount - numValidMedicalWaivers - numCobraEmployees < 0) {
    //     // console.log('Too many employees enrolling in medical');
    //     participationResults.medical = { passed: false, error: 'Too many active employees' };
    //     return { passedParticipation: false, participation: null, participationResults };
    // }

    // used for debugging
    // console.log(productPlanCount)
    // console.log(`numCobraEmployees:${numCobraEmployees} eligibleCount:${eligibleCount} numMedicalEnrollees:${numMedicalEnrollees} numValidMedicalWaivers:${numValidMedicalWaivers}`)
    // loop through selected products evaluating participation on a product by product basis
    Object.entries(selectedProducts).forEach(([product, selection]) => {
        // Check if case is eligible for Guaranteed Period.
        const participationRules = eligibilityForm.find(form=>form.sectionName==="participation");
         const { store } = require('../../../../../index');
         const {overall : {appConfigDetails}} = store.getState();
        let guaranteedPeriod;
        if (platform === 'PRIME') {
            guaranteedPeriod = participationRules && participationRules.guaranteedPeriodUhc ? participationRules.guaranteedPeriodUhc : null
        } else {
            guaranteedPeriod = participationRules && participationRules.guaranteedPeriod ? participationRules.guaranteedPeriod : null
        }
         let isGuaranteedPeriod = isGuaranteedRule(guaranteedPeriod, effectiveDate, platform, appConfigDetails.guaranteedPeriod)
         //let isUhcNewproduct = isUhcRenewals() && newProducts.includes(product);
        // let ibrParticipation = 0;
        //  if(isUhcRenewals() && !isUhcNewproduct && selection && ibrPlans.length > 0){
        //     ibrParticipation = getIbrParticipationPercent(product, ibrEmpCount, ibrPlans );
        //  } 
        if (selection) {
            switch (product) {
                case 'medical':
                    if (platform === 'PRIME') {
                        const participationData = getparticipationData(stateCode, eligibleCount, numMedicalEnrollees, allEmployees);
                        participation = participationData.participation;
                        let { participationPercent } = participationData;
                        participationCondition = participation >= participationPercent;
                        // if(ibrParticipation > 0 ){
                        //     let totalChosenMedicalCount = calculateTotalChosenPlan(productPlanCount.medical);
                        //     if(ibrParticipation> ((totalChosenMedicalCount/(numEmployees - numCobraEmployees))*100) && !participationCondition){
                        //         //participationPercent = ibrParticipation;
                        //         participationCondition = false;
                        //     }else{
                        //         participationCondition = true
                        //     }
                        // }
                        //if (flow !== 'renewals') {
                            if (participation && (participation % 1) !== 0) {
                                participation = participation.toFixed(2);
                            }
                        if(participationPercent > participation) {
                            participationResults[product] = {
                                passed: isGuaranteedPeriod ? true : participationCondition,
                                error: isGuaranteedPeriod ? '' : participationCondition ? '' : `${stateCode} medical must have a minimum of ${participationPercent}% participation, and group currently at ${participation}%`,
                            };
                        }
                        //}
                    } else {
                        const isHNY = flatMedicalPlans.some((plan) => plan.licenseType === 'HNY');
                        switch (stateCode) {
                            case 'CT':
                                // Participation needs to be >= 75%
                                // (# of Enrollees excluding COBRA enrolling) / (Eligible Count - Valid waivers (excluding Other (reason not listed)))
                                participation = ((numMedicalEnrollees)
                                    / (eligibleCount - numValidMedicalWaivers))
                                    * 100 || 0;
                                participationCondition = participation >= 75;
                                break;
                            case 'NY':
                                // Participation needs to be >= 60%, 50% if HNY
                                // (# of Enrollees excluding COBRA enrolling) / (Eligible Count - Valid waivers (excluding Other (reason not listed)))
                                participation = ((numMedicalEnrollees)
                                    / (eligibleCount - numValidMedicalWaivers))
                                    * 100 || 0;
                                participationCondition = isHNY ? participation >= 50 : participation >= 60;
                                break;
                            case 'NJ':
                                // Needs to be >= 75%
                                // [(Total # of valid waivers)  + (Total number of employees enrolling) - (Total number of COBRA enrolling)] / [(Total number of EE being offered coverage (i.e., total eligible))]                                // Ask: Is there a difference between number of waivers and valid waivers?
                                participation = ((numMedicalEnrollees + numValidMedicalWaivers)
                                        / (eligibleCount))
                                    * 100 || 0;
                                participationCondition = participation >= 75;
                                break;
                            case 'NH':
                                // Participation needs to be >= 50%
                                // (# of Enrollees excluding COBRA enrolling) / (Eligible Count - Valid waivers (excluding Other (reason not listed)))
                                participation = ((numMedicalEnrollees)
                                    / (eligibleCount - numValidMedicalWaivers))
                                    * 100 || 0;
                                participationCondition = participation >= 75;
                                break;
                            default:
                                // TODO: for all prime states
                                // This is just added for testing purpose
                                participation = 75;
                                participationCondition = participation >= 75;
                                break;
                        }
                        //Medical participation check is for NB or newly added medical on RL
                        if (flow !== 'renewals' || (flow==='renewals' && newProducts.includes('medical'))) {
                            if ((participation % 1) !== 0) {
                                participation = participation.toFixed(2);
                            }
                            participationResults[product] = {
                                passed: isGuaranteedPeriod ? true : participationCondition,
                                error: isGuaranteedPeriod ? '' : participationCondition ? '' : `${isHNY ? `Healthy ${stateCode}` : stateCode} medical must have a minimum of ${stateParticipationMinimum(stateCode, isHNY)}% participation, and group currently at ${participation}%`,
                            };
                        }
                    }
                    break;
                case 'dental':
                    participationResults[product] = handleDentalParticipation(enrolledDentalPlans, numEmployees - numCobraEmployees, productPlanCount.dental);
                    break;
                case 'vision':
                    participationResults[product] = handleVisionParticipation(enrolledVisionPlans, numEmployees - numCobraEmployees, productPlanCount.vision);
                    break;
                case 'basicLife':    
                case 'life':
                    participationResults[product] = handleLifeParticipation(enrolledLifePlans, numEmployees - numCobraEmployees, productPlanCount.life, basicLifeEmployerContributionNumber, employeeClassing, allEmployees);
                    break;
                case 'std':
                    participationResults[product] = handleDisabilityParticipation(enrolledSTDPlans, numEmployees - numCobraEmployees, productPlanCount.std, shortTermDisabilityEmployerContributionNumber, employeeClassing, allEmployees, 'std')
                    break;
                case 'ltd':
                    participationResults[product] = handleDisabilityParticipation(enrolledLTDPlans, numEmployees - numCobraEmployees, productPlanCount.ltd, longTermDisabilityEmployerContributionNumber, employeeClassing, allEmployees, "ltd")
                    break;
                case 'suppLife':
                    participationResults[product] = handleSuppLifeParticipation(enrolledSuppLifePlans, numEmployees - numCobraEmployees, productPlanCount.suppLife, allEmployees)
                default:
                    break;
            }
        }
    });
    // if any products in the participations results have a false value this means that one of the selected products didnt pass the formulas
    let allProoductsPass = true;
    let contributionFail = false;

    Object.entries(participationResults).forEach(([product, productObj]) => {
        const { passed, error } = productObj;
        if (!passed) {
            console.log(`${product} failed with errors`);
            allProoductsPass = false;
        }
        if(productObj && productObj.failedContribution) {
            contributionFail = productObj.failedContribution || false;
        }
    });
    return { passedParticipation: allProoductsPass, participation, participationResults,contributionFail };
};

export const eligEmployeesInfo = (companyProfile, enrollment) => {
    const { employees, locations, primaryLocationIdx } = companyProfile;
    const { stateCode } = locations[primaryLocationIdx].zipData;
    const { eligibility } = enrollment.application;
    const allEmployees = employees.flat();
    let censusEligEmployees = allEmployees.length - allEmployees.filter(
        (employee) => employeeCobraCheck(employee),
    ).length;
    //filter out 1099 Independent Contractors so they are not included in eligible employees count for state of CA
    if(stateCode === 'CA'){
        censusEligEmployees = censusEligEmployees - allEmployees.filter(
            (employee) => employee1099Check(employee),
        ).length;    
    }
    let eligibleCount = 0;
    companyProfile.locations.forEach((location) => {
        const { totalEligEmps } = location;
        eligibleCount += parseInt(totalEligEmps, 10);
    });
    eligibleCount = enrollment.application.eligibility.participation.numEligibleEmployees ? parseInt(enrollment.application.eligibility.participation.numEligibleEmployees, 10) : eligibleCount;
    const passedEligibilityNumbers = eligibleCount === censusEligEmployees;

    return { censusEligEmployees, eligibleCount, passedEligibilityNumbers };
};

export const isValidWaiverOxford = (waiverReason, state) => {

    const MEDICAID = 'MEDICAID';
    const MEDICARE = 'MEDICARE';
    const SPOUSAL = 'SPOUSAL';
    const PARENTS = 'PARENTS';
    const VA = 'VA';
    const OTHER_GROUP_COVERAGE = 'OTHER GROUP COVERAGE';
    const NJ_FAMILYCARE = 'NJ FAMILYCARE';
    const TRICARE = 'TRICARE';
    const CHAMPUS_MILITARY = 'CHAMPUS (MILITARY)';
    const OTHER_GROUP_COVERAGE_FROM_DIFFERENT_EMPLOYER = 'OTHER GROUP COVERAGE FROM A DIFFERENT EMPLOYER';
    const COBRA_FROM_PREVIOUS_EMPLOYER = 'COBRA FROM PREVIOUS EMPLOYER';
    const RELIGIOUS_BELIEFS = 'RELIGIOUS BELIEFS';
    const OTHER_FEDEREAL_OR_STATE_SPONSORED_HEALTH_PLANS = 'OTHER FEDEREAL OR STATE SPONSORED HEALTH PLANS';
    const AT_NO_COST_GOVERNMENT_PLAN = 'AT-NO-COST GOVERNMENT PLAN';
    const INDIVIDUAL_EXCHANGE = 'INDIVIDUAL EXCHANGE';
    const INDIVIDUAL = 'INDIVIDUAL';
    const PARENTAL_COVERAGE = 'PARENTAL COVERAGE'


    const ctValidWaiverReasonList = [
        MEDICAID, 
        MEDICARE, 
        SPOUSAL, 
        PARENTS,
        OTHER_FEDEREAL_OR_STATE_SPONSORED_HEALTH_PLANS,
        AT_NO_COST_GOVERNMENT_PLAN,
        INDIVIDUAL_EXCHANGE,
        VA,
        INDIVIDUAL

    ];
    const nyValidWaiverReasonList = [
        MEDICAID, 
        MEDICARE, 
        VA, 
        SPOUSAL,
        PARENTS,
        PARENTAL_COVERAGE,
        TRICARE,
    ];
    const njValidWaiverReasonList = [
        MEDICAID, 
        MEDICARE, 
        SPOUSAL, 
        PARENTS, 
        OTHER_GROUP_COVERAGE, 
        NJ_FAMILYCARE, 
        TRICARE 
    ];
    const nhValidWaiverReasonList = [
        MEDICAID, 
        MEDICARE, 
        SPOUSAL, 
        CHAMPUS_MILITARY,
        PARENTS, 
        COBRA_FROM_PREVIOUS_EMPLOYER, 
        VA, 
        TRICARE, 
        RELIGIOUS_BELIEFS,
        OTHER_GROUP_COVERAGE_FROM_DIFFERENT_EMPLOYER,
        INDIVIDUAL
    ];
    
    switch (state) {
        case 'CT':
            return ctValidWaiverReasonList.includes(waiverReason.toUpperCase());
        case 'NY':
            return nyValidWaiverReasonList.includes(waiverReason.toUpperCase());
        case 'NJ':
            return njValidWaiverReasonList.includes(waiverReason.toUpperCase());
        case 'NH':
            return nhValidWaiverReasonList.includes(waiverReason.toUpperCase());
        default:
            return null;
    }
}   