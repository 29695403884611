
export const standaloneFilter = (plan) => plan.standAlone;

export const packageFilter = (plan, selectedPackageCode) => {
    if (plan.planType === 'MEDICAL_ASO') { // ASO plans don't have packages, so this filter should have no effect on ASO plans
        return true;
    }

    return plan.packages.includes(selectedPackageCode);
};

const favoriteFilter = (plan) => plan.favorited;

const planCodeFilter = (plan, planCode) => plan.code === planCode;
const planCodeFilterMed= (plan, planCode) => plan.codeDisplay === planCode;

const ratingMethodFilter = (plan, ratingMethod) => {
    switch (ratingMethod) {
        case 'age':
            return plan.isAgeBanded;
        case 'tier':
            return plan.isComposite;
        default:
            return plan.isAgeBanded;
    }
};

const oopFilter = (plan, oopRange) => {
    const oop = parseInt(plan.oopMax.individualIn);
    if (isNaN(oop)) {
        return true;
    }
    return oopRange[0] <= oop && oopRange[1] >= oop;
};

const deductibleFilter = (plan, dedRange) => {
    const deductible = parseInt(plan.deductible.individualIn);
    if (isNaN(deductible)) {
        return true;
    }
    return dedRange[0] <= deductible && dedRange[1] >= deductible;
};

const metalTypeFilter = (plan, metalTypes) => metalTypes.includes(plan.metal.toLowerCase());

const planTypeFilter = (plan, planTypes) => planTypes.includes(plan.planType.toLowerCase());

const licenseTypeFilter = (plan, licenseTypes) => {
    if (plan.planType === 'MEDICAL_ASO') {
        return true;
    }

    return licenseTypes.includes(plan.licenseType.toLowerCase());
};

const premiumFilter = (plan, premRange) => {
    const premium = parseInt(plan.quote);
    return premRange[0] <= premium && premRange[1] >= premium;
};

const hsaFilter = (plan) => plan.hsa;

const hraFilter = (plan) => plan.hra;

const hrahsaFilter = (plan) => plan.hra || plan.hsa;

const uhcRewardsFilter = (plan, uhcRewards) => uhcRewards.includes(plan.uhcRewards);

const isTieredFilter = (plan) => plan.isTiered === true;

const networkFilter = (plan, networks) => networks.includes(plan.medicalPlanType);

const pcpRequiredFilter = (plan, isRequired) => plan.pcpRequired === isRequired;

// Rider Domestic Partner, Rider Age 29
const riderDomesticPartnerFilter = (plan, isRequired) => plan.riderDomesticPartner === isRequired;
const riderAge29Filter = (plan, isRequired) => plan.riderAge29 === isRequired;

const ooaFilter = (plan, ooaTypes) => {
    if (ooaTypes.includes('Out')) {
        ooaTypes.push('All'); // for Prime, areaCoverage can be "Out" or "All" but both should be treated as "Out" on the UI
    }
    return ooaTypes.includes(plan.areaCoverage);
};

export const filterPlans = (plans, _criteria, isCirrus, allRatesFetched, _filterTotalsCache, sortEnabled, ascending, isUhcRenewal = false) => {
    let visiblePlans = JSON.parse(JSON.stringify(plans));
    const criteria = JSON.parse(JSON.stringify(_criteria));
    const filterTotalsCache = JSON.parse(JSON.stringify(_filterTotalsCache));
    const planNetworks = Object.keys(criteria.network);

    // If the user only wants to see favorited plans, we filter on that first since it will likely be a very small list
    if (criteria.favorite) {
        visiblePlans = visiblePlans.filter(favoriteFilter);
    }

    let filterTotalKey; // to be used later

    // First we filter on what the user wants to display (standalone or a specific package)
    if (plans && plans.length > 0 && (plans[0].platform !== 'CIRRUS')) { // Cirrus plans don't have medical packages
        if (criteria.standaloneSelected) {
            filterTotalKey = 'standalone';
            visiblePlans = visiblePlans.filter(standaloneFilter);
        } else {
            filterTotalKey = criteria.selectedPackageCode;
            visiblePlans = visiblePlans.filter((plan) => packageFilter(plan, criteria.selectedPackageCode));
        }
    } else {
        filterTotalKey = 'standalone';
    }

    // We need to return the total length of each possible filter to display on UI
    // If we haven't had this view before, we generate & cache it; otherwise, return from the cache
    let visiblePlanFilterTotals;

    visiblePlanFilterTotals = {
        ratingMethod: {
            age: visiblePlans.filter((plan) => ratingMethodFilter(plan, 'age')).length,
            tier: visiblePlans.filter((plan) => ratingMethodFilter(plan, 'tier')).length,
        },
        planType: {
            medical: visiblePlans.filter((plan) => planTypeFilter(plan, ['medical'])).length,
            dental: visiblePlans.filter((plan) => planTypeFilter(plan, ['dental'])).length,
            vision: visiblePlans.filter((plan) => planTypeFilter(plan, ['vision'])).length,
        },
        ooa: {
            In: visiblePlans.filter((plan) => ooaFilter(plan, ['In'])).length,
            Out: visiblePlans.filter((plan) => ooaFilter(plan, ['Out'])).length,
            'Out - Live and Travel': visiblePlans.filter((plan) => ooaFilter(plan, ['Out - Live and Travel'])).length,
            'Out - Travel': visiblePlans.filter((plan) => ooaFilter(plan, ['Out - Travel'])).length,
        },
        hsaHra: {
            hsa: visiblePlans.filter(hsaFilter).length,
            hra: visiblePlans.filter(hraFilter).length,
            hrahsa: visiblePlans.filter(hrahsaFilter).length,
        },
        uhcRewardLevel: {
            uhcRewardsCore: visiblePlans.filter((plan) => uhcRewardsFilter(plan, ['uhcRewardsCore'])).length,
            uhcRewardsPremium: visiblePlans.filter((plan) => uhcRewardsFilter(plan, ['uhcRewardsPremium'])).length,
        },
        tiered: {
            isTiered: visiblePlans.filter(isTieredFilter).length,
        },
        network: planNetworks.reduce((obj, item) => (obj[item] = visiblePlans.filter((plan) => networkFilter(plan, [item])).length, obj), {}),
        pcpRequired: {
            yes: visiblePlans.filter((plan) => pcpRequiredFilter(plan, true)).length,
            no: visiblePlans.filter((plan) => pcpRequiredFilter(plan, false)).length,
        },
        riderDomesticPartner: {
            yes: visiblePlans.filter((plan) => riderDomesticPartnerFilter(plan, true)).length,
            no: visiblePlans.filter((plan) => riderDomesticPartnerFilter(plan, false)).length,
        },
        riderAge29: {
            yes: visiblePlans.filter((plan) => riderAge29Filter(plan, true)).length,
            no: visiblePlans.filter((plan) => riderAge29Filter(plan, false)).length,
        },
    };

    if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
        visiblePlanFilterTotals.licenseType = {
            hmo: visiblePlans.filter((plan) => licenseTypeFilter(plan, ['hmo'])).length,
            ppo: visiblePlans.filter((plan) => licenseTypeFilter(plan, ['ppo'])).length,
            ins: visiblePlans.filter((plan) => licenseTypeFilter(plan, ['ins'])).length,
            hny: visiblePlans.filter((plan) => licenseTypeFilter(plan, ['hny'])).length,
            epo: visiblePlans.filter((plan) => licenseTypeFilter(plan, ['epo'])).length,
        };
    }

    if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
        visiblePlanFilterTotals.metalType = {
            platinum: visiblePlans.filter((plan) => metalTypeFilter(plan, ['platinum'])).length,
            gold: visiblePlans.filter((plan) => metalTypeFilter(plan, ['gold'])).length,
            silver: visiblePlans.filter((plan) => metalTypeFilter(plan, ['silver'])).length,
            bronze: visiblePlans.filter((plan) => metalTypeFilter(plan, ['bronze'])).length,
        };
    }

    if (isUhcRenewal || allRatesFetched) {
        const numAgePlans = visiblePlans.filter((plan) => ratingMethodFilter(plan, 'age')).length;
        const numCompositePlans = visiblePlans.filter((plan) => ratingMethodFilter(plan, 'tier')).length;

        visiblePlanFilterTotals.ratingMethod = {
            age: numAgePlans,
            tier: numCompositePlans,
        };

        const { defaultRatingType } = visiblePlans.length > 0 ? visiblePlans[0].defaultRatingType || '' : '';

        if (criteria.ratingMethod.age || criteria.ratingMethod.tier) {
            if (criteria.ratingMethod.age) {
                criteria.ratingMethod.age = true;
            } else if (criteria.ratingMethod.tier) {
                criteria.ratingMethod.tier = true;
            }
        } else {
            if (defaultRatingType === 'ageBands') {
                criteria.ratingMethod.age = true;
            } else if (defaultRatingType === 'composite') {
                criteria.ratingMethod.tier = true;
            }
        }
    }

    filterTotalsCache[filterTotalKey] = visiblePlanFilterTotals;

    // If a total is 0, change the filter criteria to uncheck that box
    Object.keys(visiblePlanFilterTotals).forEach((categoryKey) => {
        Object.keys(visiblePlanFilterTotals[categoryKey]).forEach((fieldKey) => {
            if (visiblePlanFilterTotals[categoryKey][fieldKey] === 0) {
                criteria[categoryKey][fieldKey] = false;
            }
        });
    });

    // If the user is searching for a specific plan code, we just use that filter and don't worry about the rest
    if (criteria.planCode !== null) {
        visiblePlans = visiblePlans.filter((plan) => planCodeFilterMed(plan, criteria.planCode));
    } else {
        // We only want to be able to filter by premiums if all the premiums have loaded
        if (allRatesFetched) {
            visiblePlans = visiblePlans.filter((plan) => premiumFilter(plan, criteria.premRange));
        }

        // Now we filter on the rest of the criteria (checkbox & slider input)
        // Each member of the filters array is an object with the filter function and the input
        const filters = [
            {
                filter: oopFilter,
                input: criteria.oopRange,
            },
            {
                filter: deductibleFilter,
                input: criteria.dedRange,
            },
        ];

        // Add metal type filter if the # of metal types checked is > 0 and < all possible metal types
        if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
            const allMetalTypes = Object.keys(criteria.metalType);
            const visibleMetalTypes = allMetalTypes.filter((key) => criteria.metalType[key]);
            if (visibleMetalTypes.length > 0 && visibleMetalTypes.length < allMetalTypes.length) {
                filters.push({
                    filter: metalTypeFilter,
                    input: visibleMetalTypes,
                });
            }
        }

        // Add plan type filter if the # of plan types checked is > 0 and < all possible plan types
        const allPlanTypes = Object.keys(criteria.planType);
        const visiblePlanTypes = allPlanTypes.filter((key) => criteria.planType[key]);
        if (visiblePlanTypes.length > 0 && visiblePlanTypes.length < allPlanTypes.length) {
            filters.push({
                filter: planTypeFilter,
                input: visiblePlanTypes,
            });
        }

        // Add license type filter if the # of license types checked is > 0 and < all possible license types
        if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
            const allLicenseTypes = Object.keys(criteria.licenseType);
            const visibleLicenseTypes = allLicenseTypes.filter((key) => criteria.licenseType[key]);
            if (visibleLicenseTypes.length > 0 && visibleLicenseTypes.length < allLicenseTypes.length) {
                filters.push({
                    filter: licenseTypeFilter,
                    input: visibleLicenseTypes,
                });
            }
        }

        // Add network type filter if the # of network types checked is > 0 and < all possible network types
        if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
            const visibleNetworks = planNetworks.filter((key) => criteria.network[key]);
            if (visibleNetworks.length > 0 && visibleNetworks.length < planNetworks.length) {
                filters.push({
                    filter: networkFilter,
                    input: visibleNetworks,
                });
            }
        }

        // Add PCP filter if only one kind of pcp required type is checked
        if (plans.length > 0 &&  plans[0].planType !== 'MEDICAL_ASO') {
            const visiblePcpRequiredValues = Object.keys(criteria.pcpRequired).filter((key) => criteria.pcpRequired[key]);
            if (visiblePcpRequiredValues.length === 1) {
                filters.push({
                    filter: pcpRequiredFilter,
                    input: visiblePcpRequiredValues[0] === 'yes',
                });
            }
        }

        // Add Uhc Rewards filter if only one kind of rewards required type is checked
        const visibleUhcRewards = criteria.uhcRewardLevel && Object.keys(criteria.uhcRewardLevel).filter((key) => criteria.uhcRewardLevel[key]);
        if (visibleUhcRewards && visibleUhcRewards.length > 0) {
            filters.push({
                filter: uhcRewardsFilter,
                input: visibleUhcRewards,
            });
        }

        // Add Rider Domestic Partner if only one kind of pcp required type is checked
        if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
            const visibleRiderDomesticPartnerRequiredValues = Object.keys(criteria.riderDomesticPartner).filter((key) => criteria.riderDomesticPartner[key]);
            if (visibleRiderDomesticPartnerRequiredValues.length === 1) {
                filters.push({
                    filter: riderDomesticPartnerFilter,
                    input: visibleRiderDomesticPartnerRequiredValues[0] === 'yes',
                });
            }
        }

        // Add Rider Age 29 if only one kind of pcp required type is checked
        if (plans.length > 0 && plans[0]?.planType !== 'MEDICAL_ASO') {
            const visibleRiderAge29RequiredValues = Object.keys(criteria.riderAge29).filter((key) => criteria.riderAge29[key]);
            if (visibleRiderAge29RequiredValues.length === 1) {
                filters.push({
                    filter: riderAge29Filter,
                    input: visibleRiderAge29RequiredValues[0] === 'yes',
                });
            }
        }

        // Add OOA filter if the # of checkboxes selected is > 0 and < all possible OOA types (which consties by platform)
        const numOOAOptions = isCirrus ? 3 : 2;
        const visibleOOATypes = Object.keys(criteria.ooa).filter((key) => criteria.ooa[key]);
        if (visibleOOATypes.length > 0 && visibleOOATypes.length < numOOAOptions) {
            filters.push({
                filter: ooaFilter,
                input: visibleOOATypes,
            });
        }

        // NOTE: This is a similar check as the metal & plan filters, except it's a boolean checker
        const visibleRatingMethodTypes = Object.keys(criteria.ratingMethod).filter((key) => criteria.ratingMethod[key]);
        if (visibleRatingMethodTypes.length === 1) {
            filters.push({
                filter: ratingMethodFilter,
                input: visibleRatingMethodTypes[0],
            });
        }

        // Add HRA and HSA filters if those criteria boxes are checked
        if (criteria.hsaHra.hra && criteria.hsaHra.hsa) {
            filters.push({ filter: hrahsaFilter, input :null });
        }
        else if (criteria.hsaHra.hsa) filters.push({ filter: hsaFilter, input: null });
        else if (criteria.hsaHra.hra) filters.push({ filter: hraFilter, input: null });
        if (criteria.tiered.isTiered) filters.push({ filter: isTieredFilter, input: null });

        // Return plans that pass every filter
        visiblePlans = visiblePlans.filter((plan) => filters.every((obj) => obj.filter(plan, obj.input)));

        if (sortEnabled) {
            visiblePlans = sortPlans(visiblePlans, ascending);
        }
    }

    // Setting default rating method in first call itself.
    // Not sure of impact. But Not setting it was causing issues.
    const { defaultRatingType } = plans.length > 0 ? plans[0] : '';
    if (criteria.ratingMethod.age || criteria.ratingMethod.tier) {
        if (criteria.ratingMethod.age) {
            criteria.ratingMethod.age = true;
        } else if (criteria.ratingMethod.tier) {
            criteria.ratingMethod.tier = true;
        }
    } else {
        if (defaultRatingType === 'ageBands') {
            criteria.ratingMethod.age = true;
        } else if (defaultRatingType === 'composite') {
            criteria.ratingMethod.tier = true;
        }
    }

    return {
        visiblePlans,
        filterTotalsCache,
        visiblePlanFilterTotals,
        filterCriteria: criteria,
    };
};

export const roundToNearestN = (N, number) => Math.ceil(number / N) * N;

export const findNonPremiumDomainMaxes = (category, plans) => {
    let domainMax = 0;

    plans.forEach((plan) => {
        if (category === 'oop') {
            const planOop = parseInt(plan.oopMax.individualIn);
            if (planOop > domainMax) {
                domainMax = planOop;
            }
        } else if (category === 'ded') {
            const planDed = parseInt(plan.deductible.individualIn);
            if (planDed > domainMax) {
                domainMax = planDed;
            }
        }
    });
    return [0, roundToNearestN(100, domainMax + 100)];
};

export const findPremiumDomainMax = (plans) => {
    let premDomainMax = 0;

    plans.forEach((plan) => {
        const prem = parseFloat(plan.quote);

        if (prem > premDomainMax) {
            premDomainMax = prem;
        }
    });

    // We add $100 for the same reason as with non-premium maxes (above)
    return roundToNearestN(100, premDomainMax + 100);
};

export const sortPlans = (plans, ascending) => {
    if (plans.length === 0) {
        return plans;
    }
    const { planType } = plans.length > 0 ? plans[0] : '';
    if (planType === 'life_dep_basic') {
        return JSON.parse(JSON.stringify(plans)).sort((planA, planB) => {
            // TODO: remove conversion to 0.00 once BL rates defect is fixed. This is only needed to prevent app from breaking
            // when BL rates are not loaded.
            const planAQuote =  planA.finalMonthlyPremium; //US2988525 dependant life premium sort
            const planBQuote =  planB.finalMonthlyPremium; //US2988525 dependant life premium sort
            return (parseFloat(planAQuote) - parseFloat(planBQuote)) * (ascending ? 1 : -1);
        });
    } else if (planType === 'suppLife') {
        return JSON.parse(JSON.stringify(plans)).sort((planA, planB) => {
            const planAQuote =  (planA.byCensus?.totalEEMonthlyPremium  ? planA.byCensus.totalEEMonthlyPremium : '0.00'); //US2988525 dependant life premium sort
            const planBQuote =  (planB.byCensus?.totalEEMonthlyPremium ? planB.byCensus.totalEEMonthlyPremium : '0.00'); //US2988525 dependant life premium sort
            return (parseFloat(planAQuote) - parseFloat(planBQuote)) * (ascending ? 1 : -1);
        });
    }

    return JSON.parse(JSON.stringify(plans)).sort((planA, planB) => {
        // TODO: remove conversion to 0.00 once BL rates defect is fixed. This is only needed to prevent app from breaking
        // when BL rates are not loaded.
        const planAQuote = (planType === 'Life'|| planType === 'STD'|| planType === 'LTD') ? (planA.quote ? planA.quote.totalMonthlyPremium : '0.00') : planA.quote;
        const planBQuote = (planType === 'Life' || planType === 'STD' || planType === 'LTD') ? (planB.quote ? planB.quote.totalMonthlyPremium : '0.00') : planB.quote;
        return (parseFloat(planAQuote) - parseFloat(planBQuote)) * (ascending ? 1 : -1);
    });
};

// if selected plan already exists in array, deselect and delete from array. Otherwise, add to selected array
//
// Parameters
//  plan:          selected plan
//  currentPlans:  selectedPlans array
//  pkg:           package of selected plan
export const togglePlanMembership = (action, plan, currentPlans, pkg, isUhcRenewals = false, parentPlanCode) => {
    const newPlans = JSON.parse(JSON.stringify(currentPlans));
    const isClassingCase = (plan.planType.toLowerCase() === 'life' || plan.planType.toLowerCase() === 'std' || plan.planType.toLowerCase() === 'ltd') && plan.selectedClass && plan.selectedClass.trim() !== '';

    if (newPlans.length > 0) {
        if (action === 'selected') {
            var index
            if (plan.planType.toLowerCase() === 'medical') {                                                                    
                index = newPlans.findIndex((selectedPlan) => {
                    if (isUhcRenewals && plan.renewalPlanType === 'renewing') {
                        return selectedPlan.code === plan.code && selectedPlan && selectedPlan.selectedPackages && selectedPlan.selectedPackages.includes(pkg) && selectedPlan.id === plan.id 
                    }

                    if (isUhcRenewals) {
                        plan.packageId = pkg ? pkg : '';
                    }

                    return selectedPlan.code === plan.code && selectedPlan && selectedPlan.selectedPackages && selectedPlan.selectedPackages.includes(pkg)
                });
            } else if (isClassingCase) {    // Conditions to check if it's a life classing case
                index = newPlans.findIndex(selectedPlan => selectedPlan.code === plan.code && selectedPlan.selectedClass === plan.selectedClass);
            } else {
                index = newPlans.findIndex((selectedPlan) => selectedPlan.code === plan.code);
            }

            // plan does not exist in current plans
            if (index === -1) {
                if (!isClassingCase) {
                    plan.selectedPackages = [];
                    plan.selectedPackages.push(pkg);
                }

                let newPlan = JSON.parse(JSON.stringify(plan))

                if (parentPlanCode) {
                    newPlan = {...newPlan, parentPlanCode}
                }

                newPlans.push(newPlan);
            } else {
                // plan exists in current plans
                if (plan.planType.toLowerCase() === 'medical') {
                    if (newPlans[index].selectedPackages.includes(pkg)) {
                        if (newPlans[index].selectedPackages.length > 1) {
                            const packageIndex = newPlans[index].selectedPackages.indexOf(pkg);
                            newPlans[index].selectedPackages.splice(packageIndex, 1);
                        } else {
                            newPlans.splice(index, 1);
                        }
                    } else {
                        newPlans[index].selectedPackages.push(pkg);
                    }
                } else {
                    newPlans.splice(index, 1);
                }
            }
        } else {
            const index = newPlans.findIndex((selectedPlan) => selectedPlan.code === plan.code);
            if (index === -1) {
                newPlans.push(JSON.parse(JSON.stringify(plan)));
            } else {
                newPlans.splice(index, 1);
            }
        }
    } else if (action === 'selected') {
        if (!isClassingCase) {
            plan.selectedPackages = [];
            plan.selectedPackages.push(pkg);
        }

        let newPlan = JSON.parse(JSON.stringify(plan))

        if (parentPlanCode) {
            newPlan = {...newPlan, parentPlanCode}
        }

        newPlans.push(newPlan);
    } else {
        newPlans.push(JSON.parse(JSON.stringify(plan)));
    }

    return newPlans;
};

// Function to add/remove selected plan to Packages array in selectedPlansMap
//
// Parameters
//  plan:          selected plan
//  currentPlans:  selectedPlans Map
//  pkg:           package of selected plan
export const togglePlanMap = (plan, currentPlans = {}, pkg) => {
    const packageKeyExists = currentPlans[pkg]?.length > 0;
    let selectedIdx = -1;
    if (packageKeyExists) {
        selectedIdx = currentPlans[pkg].findIndex((newPlan) => newPlan.code === plan.code);
    }

    const newPlans = currentPlans;

    // Add or remove the plan from selectedPlansMap
    if (selectedIdx === -1) {
        if (!packageKeyExists) {
            // add plan to package array
            newPlans[pkg] = [plan];
        } else {
            newPlans[pkg] = newPlans[pkg].concat(plan);
        }
    } else if (newPlans[pkg].some((foundPlan) => foundPlan.code === plan.code)) {
        const newIndex = newPlans[pkg].findIndex((newPlan) => newPlan.code === plan.code);
        newPlans[pkg] = newPlans[pkg].slice(0, newIndex).concat(newPlans[pkg].slice(newIndex + 1));
    } else {
        newPlans[pkg] = newPlans[pkg].concat(plan);
    }

    return newPlans;
};

export const checkIfPlanFitsCriteria = (plan, criteria) => {
    let metalType = true;
    if (plan.planType !== 'MEDICAL_ASO') {
        const allMetalTypes = Object.keys(criteria.metalType);
        const visibleMetalTypes = allMetalTypes.filter((key) => criteria.metalType[key]);
        if (visibleMetalTypes.length > 0 && visibleMetalTypes.length < allMetalTypes.length) {
            metalType = metalTypeFilter(plan, visibleMetalTypes);
        }
    }

    let licenseType = true;
    if (plan.planType !== 'MEDICAL_ASO') {
        const allLicenseTypes = Object.keys(criteria.licenseType);
        const visibleLicenseTypes = allLicenseTypes.filter((key) => criteria.licenseType[key]);
        if (visibleLicenseTypes.length > 0 && visibleLicenseTypes.length < allLicenseTypes.length) {
            licenseType = licenseTypeFilter(plan, visibleLicenseTypes);
        }
    }

    let planType = true;
    const allPlanTypes = Object.keys(criteria.planType);
    const visiblePlanTypes = allPlanTypes.filter((key) => criteria.planType[key]);
    if (visiblePlanTypes.length > 0 && visiblePlanTypes.length < allPlanTypes.length) {
        planType = planTypeFilter(plan, visiblePlanTypes);
    }

    let ratingMethod = true;
    const visibleRatingMethodTypes = Object.keys(criteria.ratingMethod).filter((key) => criteria.ratingMethod[key]);
    if (visibleRatingMethodTypes.length === 1) {
        ratingMethod = ratingMethodFilter(plan, visibleRatingMethodTypes[0]);
    }

    return {
        filters: {
            dedRange: deductibleFilter(plan, criteria.dedRange),
            favorite: criteria.favorite ? favoriteFilter(plan) : true,
            licenseType,
            metalType,
            oopRange: oopFilter(plan, criteria.oopRange),
            planType,
            premRange: premiumFilter(plan, criteria.premRange),
            ratingMethod,
        },
        singleMultiView: {
            selectedPackageCode: plan.packages && plan.packages.length > 0 ? packageFilter(plan, criteria.selectedPackageCode) : true,
            standaloneSelected: plan.packages && plan.packages.length === 0 ? criteria.standaloneSelected === true : true,
        },
    };
};

export const filterSpecialtyPlans = (plans, allPlans, _criteria, allRatesFetched, _filterTotalsCache, sortEnabled, ascending) => {
    allPlans = JSON.parse(JSON.stringify(allPlans));
    let visiblePlans = JSON.parse(JSON.stringify(plans));
    const criteria = JSON.parse(JSON.stringify(_criteria));
    const filterTotalsCache = JSON.parse(JSON.stringify(_filterTotalsCache));

    if (criteria.favorite) {
        visiblePlans = visiblePlans.filter(favoriteFilter);
    }
    
    if (criteria.planCode !== null) {
        visiblePlans = visiblePlans.filter((plan) => planCodeFilter(plan, criteria.planCode));
        if (visiblePlans.length === 0) {
            visiblePlans = allPlans.filter((plan) => planCodeFilter(plan, criteria.planCode));
        }
    }

    return {
        visiblePlans,
        filterTotalsCache,
        filterCriteria: criteria,
    };
};

export const getSelectedPlansBenefitRiders = (selectedPlans, optionalBenefitRiders) => {
    let ridersCodeMap = new Map();
    for (let i = 0; i < selectedPlans.length; i++) {
        if (selectedPlans[i].optionalRider) {
            for (let j = 0; j < selectedPlans[i].optionalRider.length; j++) {
                ridersCodeMap.set(selectedPlans[i].optionalRider[j].riderCode, true);
            }
        }
    }
    
    return optionalBenefitRiders?.filter(rider => ridersCodeMap.get(rider.riderCode) === true);
}

export const addSelectedBenRiderDetailInPlan = (plan, selectedPlans) => {
    const planIdx = selectedPlans.findIndex(selectedPlan => selectedPlan.code === plan.code)
    if(planIdx > -1){
        plan = {
            ...plan,
            selectedBenefitRiders : selectedPlans[planIdx].selectedBenefitRiders
        }
    }   
    return plan;
}
