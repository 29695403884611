import { findHighestCoverage } from "../../components/activities/Renewal/Review/reviewComponents/FilterIBR";
import { isUhcRenewals } from "../businessLogic/platformUtils";

const { isoStringToMMDDYYYY, formatIbrDOB } = require("../formatters/dates");
const { getAgeFromDOB } = require("../formatters/numbers");
// const { formatIbrDOB } = require('../formatters/dates');

/** Function to convert IBR employees into usable format for UI components and api calls. */
export const getMembersMappingFromIBR = (Ibr) => {

    const planOfferingData = Ibr.quote.planOfferingData;
    const planProductMap = new Map();
    for (const planOffering of planOfferingData) {
        if (planOffering.newPlanPkg) {
            planOffering.newPlanPkg.plans.plan.forEach(plan => {
                planProductMap.set(plan.planID, plan.productLineType);
            })
        }
    }

    const depTypeMapping = {
        'C': 'Child',
        'S': 'Spouse'
    }

    const renewalEffDate = Ibr.quote.quoteEffDate ? Ibr.quote.quoteEffDate.year + "-" + (Ibr.quote.quoteEffDate.month < 10 ? '0' : '') + Ibr.quote.quoteEffDate.month + "-" + (Ibr.quote.quoteEffDate.day < 10 ? '0' : '') + Ibr.quote.quoteEffDate.day : ''
    const effectiveDate = isoStringToMMDDYYYY(renewalEffDate);

    let members = [];

    if (Ibr && Ibr.quote && Ibr.quote.census && Ibr.quote.census.employee && Ibr.quote.census.employee.length > 0) {
        Ibr.quote.census.employee.forEach((member) => {

            let memberDOB = formatIbrDOB(member.dateOfBirth.month, member.dateOfBirth.day, member.dateOfBirth.year);
            let empData = {
                employeeId: member.xref,
                age: getAgeFromDOB(memberDOB, effectiveDate),
                dependents: [],
                dob: memberDOB,
                firstName: member.first ? member.first : '',
                middleName: member.middle ? member.middle : '',
                middleInitial: member.middle ? member.middle : '',
                lastName: member.last ? member.last : '',
                gender: member.genderType ? member.genderType === 'M' ? 'Male' : 'Female' : '',
                zipCode: member.homeZip ? member.homeZip : '',
                contactInfo: {
                    streetAddress: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeAddr1').value : '',
                    city: member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity') ? member.clientDefinedData.data.find(({ key }) => key === 'EmployeeCity').value : '',
                    state: member.clientDefinedData.data.find(({ key }) => key === 'State') ? member.clientDefinedData.data.find(({ key }) => key === 'State').value : '',
                    zipCode: member.clientDefinedData.data.find(({ key }) => key === 'Zip') ? member.clientDefinedData.data.find(({ key }) => key === 'Zip').value : '',
                },
                employeeStatus: member.status ? member.status : 'Active',
                salary: member.salary ? member.salary : '',
                smoker: member.smoker ? member.smoker : '',
                ssn: member.ssn ? member.ssn : '',
            };

            let dependents = [];
            let memberDependents = member.dependents && member.dependents.dependent && member.dependents.dependent.length > 0 ? member.dependents.dependent : null

            if (memberDependents) {
                memberDependents.forEach((dep) => {

                    const depDOB = formatIbrDOB(dep.dateOfBirth.month, dep.dateOfBirth.day, dep.dateOfBirth.year);
                    let depDetails = {
                        relationship: dep.depType ? depTypeMapping[dep.depType] : '',
                        age: getAgeFromDOB(depDOB, effectiveDate),
                        firstName: dep.first ? dep.first : '',
                        middleName: dep.middle ? dep.middle : '',
                        middleInitial: dep.middle ? dep.middle : '',
                        lastName: dep.last ? dep.last : '',
                        suffix: '',
                        dob: depDOB,
                        gender: dep.genderType ? dep.genderType === 'M' ? 'Male' : 'Female' : '',
                        streetAddress: empData.contactInfo.streetAddress ? empData.contactInfo.streetAddress : '',
                        streetAddressln2: empData.contactInfo.streetAddressln2 ? empData.contactInfo.streetAddressln2 : '',
                        city: empData.contactInfo.city ? empData.contactInfo.city : '',
                        state: empData.contactInfo.state ? empData.contactInfo.state : '',
                        zipCode: empData.contactInfo.zipCode ? empData.contactInfo.zipCode : '',
                        smoker: dep.smoker ? dep.smoker : '',
                        employeeId: empData.xref,
                        ssn: dep.ssn ? dep.ssn : '',
                    };
                    dependents.push(depDetails);
                });
            }

            if (member.renewingPlans && member.renewingPlans.plan) {
                const productTypeMap = {
                    'MEDICAL': 'medical',
                    'DENTAL': 'dental',
                    'VISION': 'vision',
                    'BASIC_LIFE_EE' : 'life'
                }
                const coverageTypeMap = {
                    'EMP': 'EE',
                    'EE': 'EE',
                    'ESP': 'EE/SP',
                    'EE/SP': 'EE/SP',
                    'ECH': 'EE/CH',
                    'EE/CH': 'EE/CH',
                    'FAM': 'EE/FAM',
                    'EE/FAM': 'EE/FAM',
                    'WAV': 'W',
                    'W': 'W',
                }
                empData.coverages = {};
                member.renewingPlans.plan.forEach(plan => {
                    let productType = planProductMap.get(plan.planID);
                    switch (productType) {
                        case 'MEDICAL':
                        case 'DENTAL':
                        case 'VISION':
                            const productTypeValue = productTypeMap[productType];
                            const coverageTypeValue = coverageTypeMap[plan.coverageType];
                            empData.coverages[productTypeValue] = coverageTypeValue;
                            break;
                        case 'BASIC_LIFE_EE':
                            if(isUhcRenewals()){
                                let depLifePlanCovType = ""
                                let spouseFlag = false;
                                let childFlag = false;
                                member.dependents && member.dependents.dependent &&  member.dependents.dependent.forEach((dep)=>{
                                    depLifePlanCovType =  dep.renewingCoverageElections.plan.filter((plan)=> plan.planID === "0A" ||  plan.planID === "0B" || plan.planID === "0C" )
                                    if(depLifePlanCovType){
                                        if(dep.depType === "S")
                                            spouseFlag = true;
                                        else if(dep.depType === "C")
                                            childFlag = true;
                                    }
                                })
                                let finalCoverageType = "EMP"
                                if(spouseFlag === true && childFlag === true)
                                    finalCoverageType = "FAM"
                                else if(spouseFlag === true && childFlag === false)
                                    finalCoverageType = "ESP"
                                else if(spouseFlag === false && childFlag === true)
                                    finalCoverageType = "ECH"

                                const productTypeVal = productTypeMap[productType];
                                const coverageTypeVal = coverageTypeMap[finalCoverageType];
                                empData.coverages[productTypeVal] = coverageTypeVal;
                        }
                            break;
                    }
                });
                
                if(empData.coverages && !empData.coverages.medical) {
                    empData.coverages['medical'] = '';
                }
                // TODO: Life Coverages needs to be corrected.
                // empData.coverages['life'] = 'EE' // Hardcoding for now. Will correct it in future.
            }
            empData.dependents = dependents;
            members.push(empData);
        })
    }
    return members;
}


export const getMemberMappingFromCompanyProfile = (renEffectiveDate,employees) => {

    const renewalEffDate = renEffectiveDate;
    const effectiveDate = isoStringToMMDDYYYY(renewalEffDate);

    let members = [];
    if(employees.length >0)
    {
        employees[0].forEach((member,index) => {
            let memberDOB = member.dob;
            let empData = {
                employeeId: member.subscriberID ? member.subscriberID : index+1,
                age: getAgeFromDOB(memberDOB, effectiveDate), 
                dependents: [],
                dob: memberDOB,
                firstName: member.firstName ? member.firstName : '',
                middleName: member.middleName ? member.middleName : '',
                middleInitial: member.middleInitial ? member.middleInitial : '',
                lastName: member.lastName ? member.lastName : '',
                gender: member.gender ? member.gender : '',
                zipCode: member.zipCode ? member.zipCode : '',
                contactInfo: member.contactInfo ?{
                    streetAddress: member.contactInfo.streetAddress,
                    city: member.contactInfo.city,
                    state: member.contactInfo.state,
                    zipCode: member.zipCode,
                } : {},
                employeeStatus: member.status ? member.status : 'Active',
                salary: member.salary ? member.salary : '',
                smoker: member.smoker ? member.smoker : '',
                ssn: member.ssnTin ? member.ssnTin : '',
            };

            let dependents = [];
            let memberDependents = member.dependents && member.dependents.length > 0 ? member.dependents : null

            if (memberDependents) {
                memberDependents.forEach((dep) => {

                    const depDOB = dep.dob;
                    let depDetails = {
                        relationship: dep.relationship ? dep.relationship : '',
                        age: getAgeFromDOB(depDOB, effectiveDate),
                        firstName: dep.firstName ? dep.firstName : '',
                        middleName: dep.middleName ? dep.middleName : '',
                        middleInitial: dep.middleInitial ? dep.middleInitial : '',
                        lastName: dep.lastName ? dep.lastName : '',
                        suffix: '',
                        dob: depDOB,
                        gender: dep.gender ? dep.gender: '',
                        streetAddress: empData.contactInfo.streetAddress ? empData.contactInfo.streetAddress : '',
                        streetAddressln2: empData.contactInfo.streetAddressln2 ? empData.contactInfo.streetAddressln2 : '',
                        city: empData.contactInfo.city ? empData.contactInfo.city : '',
                        state: empData.contactInfo.state ? empData.contactInfo.state : '',
                        zipCode: empData.contactInfo.zipCode ? empData.contactInfo.zipCode : '',
                        smoker: dep.smoker ? dep.smoker : '',
                        employeeId: dep.subscriberID,
                        ssn: dep.ssnTin ? dep.ssnTin : '',
                    };
                    dependents.push(depDetails);
                });
            }

            if (member.coverages) {
                empData.coverages = member.coverages;
            }

            empData.dependents = dependents;
            if (empData?.coverages?.medical !== 'W' && member.employeeStatus !== '1099 Independent Contractors') {
                members.push(empData);
            }
        })
    }
    return members;
}
