import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';

const apiRoutes = require('../utils/api/apiRouteConfig');

export const bindDESQuoteToList = (updatedCasesList) => (dispatch) => {
    dispatch({
        type: types.RECEIVE_NEW_BUSINESS_CASE,
        payload: updatedCasesList,
    });
}

const getNewBusinessCase = (args) => (dispatch) => {
    let userType = '';
    let pcisId = '';

    if(args.userType){
        userType = args.userType;
    }
    if(args.pcisId && args.pcisId!=='')
    {
        pcisId = `&pcisId=${args.pcisId}`;
    }

    let pageNo;
    if (args.skip && args.filter && args.filter.length > 0) {
        pageNo = Math.ceil(args.skip/20);
    } else if (args.skip) {
        pageNo = Math.ceil((args.skip / 20));
    } else {
        pageNo = 1;
    }

    let url = "";
    let dispatchType = "";

    let platform = args.platform && args.platform === 'PRIME' ? 'PRIME': 'CIRRUS';

    if(args.dateLimit)
    {
        url = `${apiRoutes.quickQuotes}/get-nb-cases?userName=${args.userName}&platform=${platform}&dateLimit=${args.dateLimit}&userType=${userType}${pcisId}`
        dispatchType = types.RECEIVE_NEW_BUSINESS_CASE
    }else if (args.filter) {
        let urlParameters = args.filter
        let tempUrl=""
        urlParameters.forEach((filter) => {
            tempUrl += `${"&"+filter.id+"="+filter.value}`;
        });
        // to skip and limit
        url = `${apiRoutes.quickQuotes}/get-nb-cases?userName=${args.userName}&platform=${platform}&skip=${pageNo}&limit=${args.limit}${tempUrl}&userType=${userType}${pcisId}`
        dispatchType = types.RECEIVE_NEW_BUSINESS_CASE_RANGE
    }
    else if (args.skip==20) {
        url = `${apiRoutes.quickQuotes}/get-nb-cases?userName=${args.userName}&platform=${platform}&skip=${pageNo}&limit=${args.limit}&userType=${userType}${pcisId}`
        dispatchType = types.RECEIVE_NEW_BUSINESS_CASE
    }
    else {
        url = `${apiRoutes.quickQuotes}/get-nb-cases?userName=${args.userName}&platform=${platform}&skip=${pageNo}&limit=${args.limit}&userType=${userType}${pcisId}`
        dispatchType = types.RECEIVE_NEW_BUSINESS_CASE_RANGE
    
    }
    dispatch({
        type: types.REQUEST_NEW_BUSINESS_CASE,
    });

    axios.get(url)
        .then((res) => {
            dispatch({
                type: dispatchType,
                payload: res.data,
            });
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: types.ERROR_NEW_BUSINESS_CASE,
                    payload: err.response.data.message,
                });
            }
        });
};

export default getNewBusinessCase;
