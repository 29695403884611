import * as types from '../../actions/actionTypes';
// import { replaceAtIndex } from '../../utils/formatters/objects';
import deepCopy from '../../utils/deepCopy';
// import { populateRenewalEmployeeData } from './helpers/renewalCompanyProfileHelper';
import { CASE_PROGRESS, PEND_ACCEPTANCE_REASON, QUOTE_STATUS, QUOTE_PROGRESS_STATUS } from './helpers/companyProfileConsts';
import { isUndefinedOrNullOrEmpty } from '../../utils/validation/commonValidations';
import { getCoverageCounts } from '../../components/activities/FullShopping/Census/helpers/validationHelpers';

const initialState = {
    renewalCompanyProfileUpdated: false,
    isUpdatingProfile: false,
    updateError: null,
    _id: null,
    memberGroupId: null,
    companyName: '',
    primaryContact: {
        firstName: '',
        lastName: '',
        fullName: '',
        email: '',
    },
    locations: [
        {
            id: 0,
            totalEmps: '',
            totalEligEmps: '',
            atne: '',
            fte: '',
            fteRule: '',
            primaryInd: false,
            zipData: {},
            zipError: false,
            totalEligEmpsError: false,
            totalEmpsError: false,
            atneError: false,
            fteError: false,
        },
    ],
    primaryLocationIdx: 0,
    importData: [],
    importSummary: [],
    editImport: '',
    importStatus: '',
    groupSize: null,
    effectiveDate: '',
    sicCode: '',
    franchiseCode: '',
    NY_SHOP: false,
    employees: [],
    savedPlans: [],
    notes: '',
    brokerId: '',
    finishedCensus: false,
    selectedProducts: {},
    caseProgress: CASE_PROGRESS.QUOTING,
    pendingAcceptanceReason: PEND_ACCEPTANCE_REASON.NOT_STARTED,
    quoteStatus: QUOTE_STATUS.QUOTING,
    quoteProgress: QUOTE_PROGRESS_STATUS.SHOPPING,
    requestAssistanceView: false,
    employeeSavingLoader: false,
    employeeSaveReturnLoader: false,
    employerCertificationSavingLoader: false,
    employerCertificationSavingReturnLoader: false,
    employerCertification: {},
    fiLFButtonEnable: 'N',
    isMicroGroup: false,
    dependentRules: {},
    finalSubmitFlag: false,
    employeesSsn: [],
    prevSavedEmployees: [],
    packageSavingsEnable: '',
    packageSavingProductsSetFocus: false,
    activeDates: [],
    ssnCheckPopup: false,
    rates: {
        Shopping: {},
        Proposed: {},
        MoveToEnroll: {},
        CloseEnrollment: {},
        FinalizeEnrollment: {},
        PreInstallCensusChange: {},
        PostEnrollPreInstall: {},
    }
};

export default function companyProfile(state = initialState, action) {
    switch (action.type) {
        case types.PROFILE_INPUT:
            return {
                ...state,
            };
        case types.PROFILE_UPDATING:
            return {
                ...state,
                isUpdatingProfile: true,
            };
        case types.PROFILE_UPDATED:
            return {
                ...state,
                isUpdatingProfile: false,
                ...action.companyProfile,
            };
        case types.PROFILE_UPDATE_ERRORED:
            return {
                ...state,
                isUpdatingProfile: false,
                updateError: action.error,
            };
        case types.PROFILE_RETRIEVED:
            // Will utilize this when we have to retrieve profiles from the DB for updating
            const locations = [...action.companyProfile.locations];
            const updatedLocations = locations.map((location) => {
                const updatedLoc = { ...location };
                location.atneFTE === '' ? updatedLoc.atneFTEError = true
                    : updatedLoc.atneFTEError = false;
                location.totalEligEmps === '' ? updatedLoc.totalEligEmpsError = true
                    : updatedLoc.totalEligEmpsError = false;
                location.totalEmps === '' ? updatedLoc.totalEmpsError = true
                    : updatedLoc.totalEmpsError = false;
                location.primaryInd ? updatedLoc.id = 0 : updatedLoc.id = Date.now();
                location.zipData.zipCode === '' ? updatedLoc.zipError = true
                    : updatedLoc.zipError = false;
                return updatedLoc;
            });

            action.companyProfile.locations = updatedLocations;
            return {
                ...state,
                ...action.companyProfile,
            };
        // return state;
        case types.IMPORT_PROFILE:
            return {
                ...action.companyProfile.newState,
            };
        case types.RESET_PROFILE:
        case types.RESET_FLOW:
        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
            return initialState;

        case types.SAVE_INDIVIDUAL_EMPLOYEE: {
            const newEmployees = deepCopy(state.employees);
            if (newEmployees.length > 0) {
                newEmployees[action.locationIndex][action.employeeIndex] = {
                    ...newEmployees[action.locationIndex][action.employeeIndex],
                    ...action.employee,
                    formSaved: true
                };
            }
            if(newEmployees.length > 0 && state.specialtyCriteria ){
                    const coverageCounts = getCoverageCounts(
                        state.specialtyCriteria.viewSpecialty,
                        newEmployees,
                        state.locations[0].zipData.stateCode
                      );
                    state.specialtyCriteria = {
                             ...state.specialtyCriteria,
                            viewSpecialty: state.specialtyCriteria.viewSpecialty,
                            dentalCensusSummary: coverageCounts.dentalCensus,
                            visionCensusSummary: coverageCounts.visionCensus,
                            lifeCensusSummary: coverageCounts.lifeCensus,
                            stdCensusSummary: coverageCounts.stdCensus,
                            ltdCensusSummary: coverageCounts.ltdCensus,
                            platform: state.overall.platform,
                    }
            }
            // Saving a separate instance of the employees array after exiting the employee enrollment forms
            // Used to check whether employee data was updated outside of the forms (i.e. added dependent from quote census)
            let tempEmployees;
            // First time saving enrollment form, previously saved employees will be same as newEmployees
            if (state.prevSavedEmployees) {
                if (state.prevSavedEmployees.length === 0) {
                    tempEmployees = deepCopy(state.employees)
                } else {
                    // Every additional save, update the prevSavedEmployees with updated info
                    tempEmployees = deepCopy(state.prevSavedEmployees)
                    tempEmployees[action.locationIndex][action.employeeIndex] = {
                        ...tempEmployees[action.locationIndex][action.employeeIndex],
                        ...action.employee,
                    };
                }
            }
            return {
                ...state,
                employees: newEmployees,
                prevSavedEmployees: tempEmployees
            };
        }

        case types.REQUESTED_EMPLOYEE_ASSISTANCE: {
            return {
                ...state,
                requestAssistanceView: true,
            };
        }

        case types.UPDATE_CASE_PROGRESS:
            return {
                ...state,
                caseProgress: action.caseProgress,
            };

        case types.UPDATE_PEND_ACCEPTANCE_REASON:
            return {
                ...state,
                pendingAcceptanceReason: action.pendingAcceptanceReason
            };

        case types.UPDATE_CASE_PEND_REP:
            return {
                ...state,
                casePendRep: action.casePendRep,
            };

        case types.UPDATE_QUOTE_STATUS:
            return {
                ...state,
                quoteStatus: action.quoteStatus,
            };
        case types.UPDATE_QUOTE_PROGRESS:
            return {
                ...state,
                quoteProgress: action.quoteProgress,
            };

        // for renewals
        case types.UPDATE_RENEWAL_COMPANY_PROFILE: {
            const newState = action.payload;
            delete newState.selectedProducts;
            return {
                ...state,
                ...newState,
            };
        }
        case types.ERROR_ZIP_MAPPINGS: {
            return {
                ...state,
                zipError: action.payload,
            };
        }
        case types.UPDATE_RENEWAL_EFFECTIVE_DATE: {
            return {
                ...state,
                effectiveDate: action.payload,
            };
        }
        case types.UPDATE_IMPORT_DATA:
            return {
                ...state,
                importData: action.importData,
            };
        case types.UPDATE_EDIT_IMPORT:
            return {
                ...state,
                editImport: action.editImport,
            };
        case types.UPDATE_IMPORT_STATUS:
            return {
                ...state,
                importStatus: action.status,
            };
        case types.UPDATE_RENEWALS_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload,
            };
        // For Renewals
        case types.UPDATE_USER_CARDS_SELECTED_PRODUCTS:
            return {
                ...state,
                userCardsSelectedProducts: action.payload,
            };

        case types.SET_EMPLOYEE_SAVE_LOADER:
            return {
                ...state,
                employeeSavingLoader: action.payload,
            };
        case types.SET_EMPLOYERCERTIFICATION_SAVE_LOADER:
            return {
                ...state,
                employerCertificationSavingLoader: action.payload,
            };
        case types.SET_EMPLOYERCERTIFICATION_SAVE_RETURN_LOADER:
            return {
                ...state,
                employerCertificationSavingReturnLoader: action.payload,
            };
        case types.SET_EMPLOYEE_SAVE_RETURN_LOADER:
            return {
                ...state,
                employeeSaveReturnLoader: action.payload,
            };
        case types.SET_SSN_CHECK_POPUP: 
            return {
                ...state,
                ssnCheckPopup: action.payload
            }
        case types.UPDATE_ENROLLMENT_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload
            }
        case types.UPDATE_ALT_ID_MEM_GROUP:
            const alternateIDArray = action.alternateIds;
            const newEmployees = deepCopy(state.employees);
            let counter = 0;
            let isWaived = false;
            const updatedemployeesSsn = deepCopy(state.employeesSsn)
            let situsState = state.locations && state.locations[0]?.zipData?.stateCode ? state.locations[0].zipData.stateCode : '';
            newEmployees.forEach(employeeGroup => {
                employeeGroup.forEach(employee => {
                    employee.ssnTin = employee.employeeInfo && employee.employeeInfo.ssnTin ? employee.employeeInfo.ssnTin.toString().replaceAll("-", "") : ""
                    employee.tempSSN = ""
                    isWaived = isAllProductWaived(employee);
                    let isNiceOnlyEmp = false;
                    if(situsState === 'CA'){
                        isNiceOnlyEmp = isEmployeeNICEOnly(employee, action.enrolledPlans);
                    }
                    if (!isWaived && (situsState !== 'CA' || (situsState === 'CA' && !isNiceOnlyEmp))) {
                        if (updatedemployeesSsn.indexOf(employee.employeeInfo.ssnTin) === -1) {
                            if (employee.employeeInfo && employee.employeeInfo.ssnTin === "") {
                                employee.tempSSN = alternateIDArray[counter].toString();
                                employee.employeeInfo.ssnTin = alternateIDArray[counter].toString();
                                counter++;
                            }
                            employee.memberId = alternateIDArray[counter].toString();
                            employee.altId = alternateIDArray[counter].toString();
                            if (employee.employeeInfo) {
                                employee.employeeInfo.altId = alternateIDArray[counter].toString();
                                counter++;
                            }
                            updatedemployeesSsn.push(employee.employeeInfo.ssnTin);
                        }
                    }
                })
            })
            return {
                ...state,
                employees: newEmployees,
                employeesSsn: updatedemployeesSsn
            }
        case types.UPDATE_RENEWAL_SIC_CODE_COMPANY_PROFILE:
            return {
                ...state,
                sicCode: action.payload,
            };
        case types.UPDATE_COMPANY_PROFILE_LOCATIONS:
            return {
                ...state,
                locations: action.payload,
            };
        case types.SAVE_EMPLOYER_CERTIFICATION_INFO:
            return {
                ...state,
                employerCertification: action.payload,
            };
        case types.UPDATE_COMPANY_PROFILE_EMPLOYEES:
            return {
                ...state,
                employees: action.payload,
            };
        case types.UPDATE_PRIME_ANCILLARY_RENEWAL_TO_NB:
            return {
                ...initialState,
                ...action.payload
            }
        case types.SAVE_SIC_DATA_IN_COMPANY_PROFILE_RL:
            return {
                ...state,
                sicData: action.payload,
            };
        case types.UPDATE_FILF_BUTTON_ENABLE_DISABLE:
            return {
                ...state,
                fiLFButtonEnable: action.payload,
            };
        //US3430326 Identify MicroGroups
        case types.UPDATE_MICROGROUP_STATUS:
            return {
                ...state,
                isMicroGroup: action.status,
            };
        case types.UPDATE_FTERULE_QQ:
            let modifiedLocation = deepCopy(state.locations)
            modifiedLocation[action.index] = {
                ...modifiedLocation[action.index],
                fteRule: action.rule
            }
            return {
                ...state,
                locations: modifiedLocation
            }
        case types.UPDATE_FTERULE_QQ_ERROR:
            let modifiedLocationErr = deepCopy(state.locations)
            modifiedLocationErr[action.index] = {
                ...modifiedLocationErr[action.index],
                fteError: action.error
            }
            return {
                ...state,
                locations: modifiedLocationErr
            }
        case types.UPDATE_DEPENDENT_RULES:
            return {
                ...state,
                dependentRules: action.rules,
            };
        case types.UPDATE_FINAL_SUBMIT_FLAG:
            return {
                ...state,
                finalSubmitFlag: action.payload
            }
        case types.SET_UNSAVED_ENROLL_FORM_CHANGES:
            return {
                ...state,
                unsavedEnrollFormChanges: action.payload
            }
        case types.PACKAGE_SAVINGS_SET_PRODUCT_FOCUS:
            return {
                ...state,
                packageSavingProductsSetFocus: action.payload
            }
        case types.SET_CANNABIS_EMP:
            return{
                ...state,
                cannabisEmp:action.payload
            }
        case types.SET_ACTIVE_DATES:
            return {
                ...state,
                activeDates : action.payload
            }
        case types.SAVE_MEDICAL_PARTICIPATION:
            return{
                ...state,
                medicalParticipation:action.payload
            }

        case types.UPDATE_TERMINATED_EMPLOYEES_AND_DEPENDENTS:
            return{
                ...state,
                terminatedEmployees : action.payload.terminatedEmployees,
                terminatedDependents : action.payload.terminatedDependents
            }
        
        case types.UPDATE_RENEWAL_RATE_REFERENCE_ID:
            if (action.payload.isUhcRenewals) {
                const {
                    quoteStatus,
                    product,
                    rates,
                } = action.payload;
                return {
                    ...state,
                    rates: {
                        ...state.rates,
                        [quoteStatus]: {
                            ...state.rates[quoteStatus],
                            [`${product}Rates`]: rates
                        }
                    }
                }
            } else {
                return state;
            }

        case types.IS_SIC_ENTERED:
            return{
                ...state,
                isSicEntered:action.payload
            }
        default:
            return state;
    }
}

function isAllProductWaived(employee) {
    let isWaived = true;
    let selectedProdLength, waivedCounter = 0, product;
    if (employee.productSelection.length > 0) {
        product = employee.productSelection[0];
        selectedProdLength = 0;
        Object.keys(product).forEach(empProduct => {
            if (!isUndefinedOrNullOrEmpty(product[empProduct])) {
                selectedProdLength = selectedProdLength + 1;
            }
        })
        if (!isUndefinedOrNullOrEmpty(product.medical) && product.medical === 'waive') {
            waivedCounter++;
        }
        if (!isUndefinedOrNullOrEmpty(product.dental) && product.dental === 'waive') {
            waivedCounter++;
        }
        if (!isUndefinedOrNullOrEmpty(product.vision) && product.vision === 'waive') {
            waivedCounter++;
        }
        if (!isUndefinedOrNullOrEmpty(product.life) && product.life === 'waive') {
            waivedCounter++;
        }
        isWaived = selectedProdLength === waivedCounter
    }
    return isWaived;
}
function isEmployeeNICEOnly(employee, enrolledPlans) {
    let NICEOnlyPlans = enrolledPlans.filter(plan => plan.platform === 'NICE').map(plan=>plan.code);
    let checkIsNiceonlyMember = false;
    let employeeProduct = {};
    if (employee.productSelection.length > 0) {
        employeeProduct = employee.productSelection[0];
    }
    if (employeeProduct && employeeProduct.medical) {
        if (NICEOnlyPlans.includes(employeeProduct.medical) && isAllSpecialtyProductNotSelectedOrWaived(employeeProduct)) {
            checkIsNiceonlyMember = true;
        }
    }
    return checkIsNiceonlyMember;
}

function isAllSpecialtyProductNotSelectedOrWaived(selectedProduct) {
    const productMap = ['dental', 'vision', 'life'];
    return productMap.every(product => isUndefinedOrNullOrEmpty(selectedProduct[product]) || selectedProduct[product] === 'waive')
}