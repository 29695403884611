import {marketTypes} from '../constants';
import {getUHCSchema} from './uhcSchemaHolder';

/** data = {
*    marketType: 'UHC/OXFORD'
* }
*/
let data = null

/**
 * 
 * @param {String} marketType | UHC/OXFORD
 * @param {String} schemaName | name of the schema e.g. companyInfo
 * @returns the schema object
 */
export const getSchema = (schemaName) => {
    if(data.marketType === marketTypes.UHC){
        return getUHCSchema(schemaName);
    }
    else{

    }
}

export const setData = (fieldName, fieldValue) => {
    if(data === null)
        data = {};
    data[fieldName] = fieldValue;
}

export const clearMemory = () => {
    data = null;
}