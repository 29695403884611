export const memberType = ["Employee", "Dependent"]
export const relationshipType = ["Child (CT/NY Only)", "Spouse", "Domestic Partner", "Civil Union Partner (CT/NJ Only)", "Dependent Child (NJ/NH Only)"]
export const genderType = ["Female", "Male", "F", "M","Non-Binary","N"]
export const employeeStatusType = ["Active", "COBRA", "Retired"]
export const specialEmployeeStatusType = ["K1", "Contractor", "None"]
export const yesNoType = ["Yes", "No"]
export const coverageTypes = ["Employee Only", "Elected", "Employee & Spouse", "Employee & Child", "Employee & Family", "Waive", "EE", "EL", "ES", "EC", "FAM", "W"]
export const suffixType = ["Jr.", "Sr.", "I", "II", "III", "IV", "V", "VI", "VII", "VIII"]
export const waivingAllType = ["Waive All Products", "No"]
export const noSSNType = ["Expatriate", "Newborn", "Refusal to Provide", "Personal ID Number Satisfied", "Unknown"]
export const otherCarrierNameType = ["AETNA", "ADMINISTAFF", "ANTHEM", "HEALTHNET", "HUMANA", "KAISER", "STARMARK", "MEDICAL MUTUAL", "ASSURANT", "WELLPATH", "CAREFIRST", "FEDERATED", "REGENCE", "TRUSTMARK", "FIDELITY", "MEDICA", "COVENTRY", "BLUE CROSS/BLUE SHIELD", "BLUE CROSS ONLY", "BLUE SHIELD ONLY", "CONNECTICARE (CTCARE)", "HARVARD PILGRIM"];
export const medicareReasonType = ["Age", "Disability (not actively at work)", "End stage renal disease (ESRD)", "Disabled but actively at work", "Unknown"]
export const phoneType = ["Home", "Mobile", "Work"]
export const pcpAssignmentType = ["Auto Assign", "Enter in a PCP code", "N/A"]
export const dissabilityType = ["Mental", "Physical"]