export const workSheets = {
    companyInfo: 'Company Info',
    location: 'Location',
    employeeInfo: 'Employee Info',
    enrollmentInfo: 'Enrollment Info',
    enrollmentForm: 'NEW Enrollment Form'//for paper case
}

export const dataTypes = {
    number: "number",
    string: "string",
    object: "object",
    array: "array",
}

export const constraintNames = {
    dataType: 'dataType',
    length: 'length',
    maxLength: 'maxLength',
    minLength: 'minLength',
    dropDownValues: 'dropDownValues',
    minValue: 'minValue',
    maxValue: 'maxValue',
    regex: 'regex',
    validDate: 'validDate',
    dobRange: 'dobRange'
}

export const regexTypes = {
    number: /^\d+$/,
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    capitalCharacter: /^[A-Z]/,
    noSpecialCharacters: /^[a-zA-Z ]*$/,
    noSpecialButNumbersAllowed: /^[a-zA-Z0-9 ]*$/,
    ssnTin: /^\d{3}-\d{2}-\d{4}$/,
    date: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
    telephone: /^\d{3}-\d{3}-\d{4}$/
}

export const constrintErrors = {
    requiredField: (fieldName, cell) => `<b>${fieldName}</b> is a required field (row: ${cell.row}, col: ${cell.col})`,
    dataType: (fieldName, value, cell) => `<b>${fieldName}</b> has invalid data type (row: ${cell.row}, col: ${cell.col})`,
    length: (fieldName, value, cell) => `Length of the <b>${fieldName}</b> should be ${value} (row: ${cell.row}, col: ${cell.col})`,
    maxLength: (fieldName, value, cell) => `Maximum length of the <b>${fieldName}</b> should be less than or equal to ${value} (row: ${cell.row}, col: ${cell.col})`,
    minLength: (fieldName, value, cell) => `Minimum length of the<b>${fieldName}</b> should be greater than or equal to ${value} (row: ${cell.row}, col: ${cell.col})`,
    dropDownValues: (fieldName, values, cell) => `<b>${fieldName}</b> should have value from the ${values} (row: ${cell.row}, col: ${cell.col})`,
    minValue: (fieldName, value, cell) => `Minimum value of the <b>${fieldName}</b> should be greater than or equal to ${value} (row: ${cell.row}, col: ${cell.col})`,
    maxValue: (fieldName, value, cell) => `Maximum value of the <b>${fieldName}</b> should be less than or equal to ${value} (row: ${cell.row}, col: ${cell.col})`,
    regex: (fieldName, value, cell) => `<b>${fieldName}</b> has invalid data (row: ${cell.row}, col: ${cell.col})`,
    userTypeMissing: (row, col) => `User type is missing at (row: ${row}, col: ${col})`,
    validDate: (fieldName, value, cell) => `Date is invalid at (row: ${cell.row}, col: ${cell.col})`,
    spouseCount: `You cannot enter <b>more than one spouse</b>`
}

export const constrintErrorsSubmittedCensus = {
    invalidRelationshipEmployee: (row, col) => `excel line ${row} Not uploaded, employee is retired (this is the RR row)`,
    invalidRelationshipSpouse: (row, col) => `excel line ${row} Not uploaded, employee is retired (this is a Spouse under the above RR row)`,
    invalidRelationshipChild: (row, col) => `excel line ${row} Not uploaded, employee is retired (this is a Child under the above RR row)`,
    missingRelationShip: (row, col) => `The Submitted Census document your are trying to upload is missing a Relationship value on one or more records. Please update any records missing this value and then Upload again.`,
}

export const dropDowns = {
    quoteNotQuote: ['Quote', 'Do not Quote'],
    empType: ['Employee', 'Dependent'],
    relationship: [
        'Child',
        'Spouse',
        'Domestic Partner',
        'Civil Union Partner'],
    suffix: ['Jr.', 'Sr.', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'],
    gender: ['Female', 'Male'],
    empStatus: ['Active', 'COBRA'],
    specialEmpStatus: ['K1', 'Contractor', 'None'],
    empClass: ['Hourly', 'Salaried'],
    yesNo: ['Yes', 'No'],
    coverageType: [
        'Employee Only',
        'Employee & Spouse',
        'Employee & Child',
        'Employee & Family',
        'Waive', 'EE', 'ES', 'EC'],
    lifeCoverage:['Elected','Waive'],
    stdLTDCoverage:['Employee Only','Waive'],
    medicalWaiverReason: [
        'AHCCS',
        'At-No-Cost Government Plan,Champus (Military)',
        'COBRA From Previous Employer',
        'Cost or Do Not Want',
        'Cost',
        'Do Not Want',
        'Employee Welfare Benefit Plan',
        'Federal Employee Health Benefit Plan',
        'Foreign Government Coverage',
        'Indian/Tribal Health Services',
        'Individual',
        'Individual Exchange',
        'Medicaid',
        'Medi-Cal',
        'Medicare',
        'Military Plan under 10USC 1071',
        'Other Group Coverage',
        'Parents',
        'Peace Corps Plan',
        'Public Health Plan',
        'SHOP Exchange',
        'Spousal',
        'Spousal COBRA',
        'Religious Beliefs',
        'Retiree through Group Coverage',
        'State Health Benefits Risk Pool',
        'Title XIX of Soc. Sec Act (immunization for children)',
        'Tricare',
        'Trisure (Military)',
        'UnitedHealth One',
        'VA',
        'Other (reason not listed)'
    ],
    isWaivingAll: ['Waive All Products', 'No'],
    pcpAssignment: ['Auto Assign', 'Enter in a PCP code', 'N/A'],
    noSSNReason: [
        'Expatriate',
        'Newborn',
        'Refusal to Provide',
        'Personal ID Number Satisfied',
        'Unknown',
    ],
    telephoneType: ['Home', 'Mobile', 'Work'],
    nameOtherCarrier: [
        'AETNA',
        'ADMINISTAFF',
        'ANTHEM',
        'HEALTHNET',
        'HUMANA',
        'KAISER',
        'STARMARK',
        'MEDICAL MUTUAL',
        'ASSURANT',
        'WELLPATH',
        'CAREFIRST',
        'FEDERATED',
        'REGENCE',
        'TRUSTMARK',
        'FIDELITY',
        'MEDICA',
        'COVENTRY',
        'BLUE CROSS/BLUE SHIELD',
        'BLUE CROSS ONLY',
        'BLUE SHIELD ONLY',
        'CONNECTICARE (CTCARE)',
        'HARVARD PILGRIM',
        'MEDICARE'
    ],
    medicareReason: [
        'Age',
        'Disability (not actively at work)',
        'End stage renal disease (ESRD)',
        'Disabled but actively at work',
        'Unknown'
    ],
    disabilityType: ['Mental', 'Physical'],
    nonBinaryGender: ['Male', 'Female', 'Non-Binary'],
    employeeStatusOptions: [
        "Active",
        "COBRA",
        "1099 Independent Contractors"
      ],

}

export const userTypes = {
    employee: 'Employee',
    dependent: 'Dependent'
}

export const userTypesSubmittedCensus = {
   EE:'EE',
   CB:'CB',
   WV:'WV',
   MC:'MC',
   SP:'SP',
   CH:'CH',
   ST:'ST',
   HM:'HM',
   HP:'HP',
   RR:'RR',
   MC:'MC',
   SS:'SS',
}

export const marketTypes = {
    UHC: 'UHC',
    OXFORD: 'OXFORD'
}

export const dependencyNames = {
    WORKSHEET_DEPENDENCY: 'WORKSHEET_DEPENDENCY',
    SELF_DEPENDENCY: 'SELF_DEPENDENCY',
    AFTER_PARSE_DEPENDENCY: 'AFTER_PARSE_DEPENDENCY',
    CHILD_PARENT_DEPENDENCY: 'CHILD_PARENT_DEPENDENCY',
    PARENT_CHILD_DEPENDENCY: 'PARENT_CHILD_DEPENDENCY',
}

export const PAGE_SOURCE = {
    COMPANY_PROFILE: 'companyProfile',
    EMP_CENSUS: 'employeeCensus',
    EMPLOYEE_ENROLLMENT: 'employeeEnrollment',
    ENROLL_MANAGER: 'enrollmentManager'
}

export const getColsArr = (worksheetName) => {
    switch (worksheetName) {
        case workSheets.companyInfo:
            return ['A', 'B', 'C', 'D', 'E', 'F', 'G']
        case workSheets.location:
            return ['A', 'B', 'C', 'D', 'E', 'F']
        case workSheets.employeeInfo:
            return ['B', 'D', 'E', 'F', 'H', 'I', 'J', 'K', 'L', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W']
        case workSheets.enrollmentInfo:
            return [
                'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'AA', 'AC', 'AE', 'AF', 'AG',
                'AH', 'AI', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 
                'AX', 'AY', 'BA', 'BB', 'BH'
            ]
    }
}

export const valuesToIgnore = [
    "Not available for Enrollment",
    'N/A',
    'Auto Assigned'
];

export const statesWithNonBinary = ['NY'];
export const employeeAndDependentGenderKeys = {
    employeeInfo: {
        employee: 'K',
        dependent: 'K'
    },
    enrollmentInfo: {
        employee: 'H',
        dependent: 'H'
    }
}
export const stateswithOptionalEmployeeStatus=['CA']
export const employeeStatusKeys = {
    employeeInfo: {
        employee: 'L',
        dependent: 'L'
    },
    enrollmentInfo: {
        employee: 'C',
        dependent: 'C'
    }
}

