import { createHistory } from '../../actions/historyAction';
import { getEnrollmentStateRefined } from '../../reducers/products/enrollment/helper';


export const updateEnrollmentCreateHistory = (value, sectionParentPage, sectionName, fieldNameDisplay, memberName = '', extraDesc = '') => {
    let description = "Changed in Section : " + sectionName + ", Field : " + fieldNameDisplay;
    let payload = value + " " + memberName + " " + extraDesc
    let pageName = sectionParentPage === "Enroll Manager" ? "Enrollment Manager" : "Enrollment Manager ( " + (sectionParentPage || sectionName) + " )";
    return (dispatch, getState) => {
        const fullProps = getState()
        dispatch(createHistory(description, pageName, 'Success', payload, getEnrollmentStateRefined(fullProps), 'NB'))
    }
}

export const auditUserAction = (sectionName, field, value) => {
    const payload = `${typeof field === 'string' ? field.toUpperCase() : field} - ${typeof (value) === 'object' ? JSON.stringify(value) : value}`;
    const description = `${sectionName !== undefined ? sectionName + '.' : ''}  ${typeof (value) === 'object' ? ' ' + field.toUpperCase() : payload}`;

    return (dispatch) => new Promise((resolve, reject) => {
        const currentPagePath = window.location.pathname;
        const pageName = currentPagePath.includes('summary') ? 'Quote Review' :
            currentPagePath.includes('plans') ? 'Shopping page (Plan Selection)' : "";

        if (pageName !== '') {
            dispatch(createHistory(description, pageName, "Success", payload, "", "NB"));
        }
    });
}
export const dashboardActionCreateHistoryHelper = (actionName, dashboardType, payload = '') => {
    return (dispatch) => {
        const description = "Action :: " + actionName;
        const pageName = dashboardType === 'RL' ? 'Renewal Dashboard' : 'New Business Dashboard';
        dispatch(createHistory(description, pageName, "Success", payload, "", dashboardType));
    }
}


export const reduceCreateHistoryPayload = (historyArray = []) => {
    const length = historyArray.length
    const employerPages = ["Enrollment Manager ( Employer Information )", "Enrollment Manager ( Eligibility Information )", "Enrollment Manager ( producer )", "Enrollment Manager ( Attestation )"]
    const employeePages = ["Enrollment Manager ( Employee Information )"]
    // if (length > 2 && (employeePages.includes(historyArray[length - 2].pageName) || employeePages.includes(historyArray[length - 3].pageName))) {
    //     for (let i = 0; i < length - 3; i++) {
    //         delete historyArray[i].response
    //     }
    // }
    // else if (length > 3 && (employerPages.includes(historyArray[length - 2].pageName) || employerPages.includes(historyArray[length - 3].pageName))) {
    //     if (historyArray[length - 1].pageName === "Enrollment Manager ( Eligibility Information )") {
    //         for (let i = 0; i < length - 2; i++) {
    //             delete historyArray[i].response
    //         }
    //     }
    //     else {
    //         for (let i = 0; i < length - 3; i++) {
    //             delete historyArray[i].response
    //         }
    //     }
    // }
    const isEnrollmentForm = !!historyArray.find(history => employerPages.includes(history.pageName) || employeePages.includes(history.pageName));
    if (isEnrollmentForm) {
        let latestResponse = null;
        for (let i = historyArray.length - 1; i >= 0; i--) {
            if (historyArray[i].response) {
                latestResponse = historyArray[i].response;
                break;
            }
        }
        historyArray.forEach((history, index) => {
            delete history.response;
            if (index === length - 1) {
                history.response = latestResponse;
            }
        })
    }
}