export const updateRateToDefaultType = (rate, isAgeBanded = false) => {
    const updatedRate = { ...rate };
    // update employee rates
    if (isAgeBanded) {
        updatedRate.employeeRates = updatedRate.employeeRatesAgeBanded;
    } else {
        updatedRate.employeeRates = updatedRate.employeeRatesComposite;
    }
    setPlanDefaultType(updatedRate, isAgeBanded);
    return updatedRate;
}

export const setPlanDefaultType = (objectWithRate, isAgeBanded) => {
    // update plan/rate object to set default values
    if (isAgeBanded) {
        objectWithRate.isAgeBanded = true;
        objectWithRate.defaultRatingType = 'ageBands';
    } else {
        objectWithRate.isComposite = true;
        objectWithRate.defaultRatingType = 'composite';
    }
}

export const setAlternateRatingMethod = (ibrObj, alternateRateObj) => {
    if (ibrObj.ibrRatingType === "composite") {
        let ibrObjCopy = {
            ...ibrObj,
        };

        if(alternateRateObj && alternateRateObj.ageBands) {
            let newAgeBandedRatingID=alternateRateObj.platformAgeBandedRatingID
            newAgeBandedRatingID=(alternateRateObj.defaultRatingType!=="ageBands" )?newAgeBandedRatingID.slice(0,-3)+"PR"+newAgeBandedRatingID.slice(-3):alternateRateObj.platformAgeBandedRatingID
            ibrObjCopy = {
                ...ibrObjCopy,
                ageBandedTypeName: alternateRateObj.ageBandedTypeName,
                ageBands: alternateRateObj.ageBands,
                benefitRiderRates: alternateRateObj.benefitRiderRates,
                employeeRatesAgeBanded: alternateRateObj.employeeRatesAgeBanded,
                isAgeBanded: true,
                platformAgeBandedRatingID:
                newAgeBandedRatingID,
                premiumTotalAgeBandedAnnual:
                    alternateRateObj.premiumTotalAgeBandedAnnual,
                premiumTotalAgeBandedMonthly:
                    alternateRateObj.premiumTotalAgeBandedMonthly,
            }
        }

        if (!ibrObjCopy.employeeRatesComposite)
            ibrObjCopy.employeeRatesComposite = ibrObj.employeeRates;

        if (!ibrObjCopy.medicalPlanCode)
            ibrObjCopy.medicalPlanCode = ibrObj.code;

        if (!ibrObjCopy.premiumTotalCompositeAnnual)
            ibrObjCopy.premiumTotalCompositeAnnual =
                parseFloat(ibrObj.finalMonthlyPremium) * 12;

        if (!ibrObjCopy.premiumTotalCompositeMonthly)
            ibrObjCopy.premiumTotalCompositeMonthly = parseFloat(
                ibrObj.finalMonthlyPremium
            );

        return ibrObjCopy;
    } else {
        let ibrObjCopy = {
            ...ibrObj,
        };

        if(alternateRateObj && alternateRateObj.compositeRates) {
            let newcompositeRatingId=alternateRateObj.platformCompositeRatingID
            newcompositeRatingId=(alternateRateObj.defaultRatingType==="ageBands")?newcompositeRatingId.slice(0,-3)+"PR"+newcompositeRatingId.slice(-3):alternateRateObj.platformCompositeRatingID;
            ibrObjCopy = {
                ...ibrObjCopy,
                compositeTypeName: alternateRateObj.compositeTypeName,
                compositeRates: alternateRateObj.compositeRates,
                benefitRiderRates: alternateRateObj.benefitRiderRates,
                employeeRatesComposite: alternateRateObj.employeeRatesComposite,
                isComposite: true,
                platformCompositeRatingID:
                newcompositeRatingId,
                premiumTotalCompositeAnnual:
                    alternateRateObj.premiumTotalCompositeAnnual,
                premiumTotalCompositeMonthly:
                    alternateRateObj.premiumTotalCompositeMonthly,
            }
        }
    
        if (!ibrObjCopy.employeeRatesAgeBanded)
            ibrObjCopy.employeeRatesAgeBanded = ibrObj.employeeRates;

        if (!ibrObjCopy.medicalPlanCode)
            ibrObjCopy.medicalPlanCode = ibrObj.code;

        if (!ibrObjCopy.premiumTotalAgeBandedAnnual)
            ibrObjCopy.premiumTotalAgeBandedAnnual =
                parseFloat(ibrObj.finalMonthlyPremium) * 12;

        if (!ibrObjCopy.premiumTotalAgeBandedMonthly)
            ibrObjCopy.premiumTotalAgeBandedMonthly = parseFloat(
                ibrObj.finalMonthlyPremium
            );

        return ibrObjCopy;
    }
};
