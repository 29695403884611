import PropTypes from 'prop-types';

// Object definitions that will be used throughout the app

const checkPropTypes = (objectPropTypes, object, objectName) => {
    PropTypes.checkPropTypes(objectPropTypes, object, 'prop', objectName);
};

export const zipObjPropTypes = {
    city: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    countyFIPSCode: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    marketNo: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    stateCode: PropTypes.string.isRequired,
    stateFIPSCode: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
};

export const checkZipObj = (zipObj) => {
    checkPropTypes(zipObjPropTypes, zipObj, 'zipObj');
};
