export const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    planType: {
               ino: false,
               incentive: false,
               indemnity: false,
               passive: false
           }

};

export const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    // TBD
};

export const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        zipCode: null,
        zipObj: {},
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    isFetchingPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    plans: [],
    allPlans:[],
    visiblePlans: [],
    selectedPlans: [],
    favoritedPlans: [],
    selectedPlansMap: {},
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    filterTotalsCache: initialfilterTotalsCache,
    specialty: true,
    sortEnabled: true,
    ascending: true,
    showPreferredPlans: true,
    filteredResults:[],
    appliedFilters:{},
    advanceFilterProductType:'ltd',
    displayLeftSideFilter:false,
    isSendingRateAdjustments: false,
    rateAdjustmentsError: false,
    employeeClassing : {
        classingOpted : false, // when opts for any class from dropdown
        classes : [], // List of opted classes by the user
        selectedClass : '', // Class selected on plan Selection Page
        employeeSelectedClasses: [] // Subset of LTD classes that have employees tied them on quote census
    },
    classingOptions:["Hourly", "Salaried", "Union", "Non-Union", "Part-Time", "Full-Time", "Management", "Non-Management"],
    classRatesFetched: false,
    isFetchingClassRates: false,
};

export const ltdFilterSet = [
     {
         filterGroup:'Plan Code',
         filterId:'plancode',
         planField:'code',
         type:'list',
         place:'dropdown',
         display:false,
         values: {}
     },
     {
         filterGroup:'Contribution Type',
         filterId:'contributionType',
         planField:'contributionType',
         type:'list',
         place:'basic',
         display:true,
         values: {}
     },
     {
          filterGroup:'Maximum Monthly Benefit',
          filterId:'maxMonthlyBenefit',
          planField:'maxMonthlyBenefit',
          type:'list',
          place:'basic',
          display:true,
          values: {}
      },
     {
          filterGroup:'Benefit Percent',
          filterId:'benefitpercent',
          planField:'benefitPercent',
          type:'list',
          place:'basic',
          display:true,
          values: {}
     },
      {
           filterGroup:'Benefit Duration',
           filterId:'benefitduration',
           planField:'benifitDuration',
           type:'list',
           place:'basic',
           display:true,
           values: {}
      },
      {
           filterGroup:'Elimination Period',
           filterId:'eliminationperiod',
           planField:'eliminationPeriod',
           type:'list',
           place:'basic',
           display:true,
           values: {}
      },
      {
        filterGroup:'Rate Type',
        filterId:'rateType',
        planField:'rateType',
        type:'list',
        place:'basic',
        display:true,
        values: {}
    },
];