import { quickQuotes as serviceLayer } from '../../utils/api/apiRouteConfig';
import { axios } from '../../utils/api/axiosConfig';
import { isMmr } from '../../utils/businessLogic/platformUtils';
import { logError } from '../../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../../utils/errorLogging/logKeys';

export const getPlanModificationDetils = (type) => {
    switch(type) {
        case 'medical':
            return [
                {
                    path: ['standAlone', 'popular', 'hsa', 'hra', 'combinedMedRxDeductible', 'nonEmbeddedDeductible', 'pcpRequired', 'isExchange', 'preferredPlan', 'limitedPlanDistribution'],
                    modificationType: 'booleanToString'
                },
                {
                    path: ['deductible.individualIn', 'deductible.individualOut', 'deductible.familyIn', 'deductible.familyOut', 'oopMax.individualIn', 'oopMax.individualOut', 'oopMax.familyIn', 'oopMax.familyOut', 'pcpCopay', 'specialistCopay', 'pcpCoinsuranceIn', 'emergencyRoomCopay', 'urgentCareCopay'],
                    modificationType: 'addDollarSign'
                },
                {
                    path: ['hospitalCoinsurance.in', 'hospitalCoinsurance.out'],
                    modificationType: 'changeToPercentage'
                },
                {
                    path: ['rx.tiers.i.copay'],
                    modificationType: 'convertToIntegerFormat'
                }
            ]
        default:
            return []
    }
}

export const formatData = (value, modificationType) => {
    switch (modificationType) {
        case 'booleanToString':
            return value ? 'Y' : 'N'
        case 'addDollarSign':
            if (value.indexOf("$") >= 0) {
                value = value.replace(/\$/gi, '')
            }
            return '$' + value
        case 'changeToPercentage':
            return parseFloat(value) * 100 + '%'
        case 'convertToIntegerFormat':
            return parseInt(value).toString()
        default:
            break;
    }
}

export const getAndSetValueFromPath = (data, path, modificationType) => {
    if(!data || !path) {
        return ''
    }
    let pathArr = path.split('.'), i = 0
    for(i=0;i<pathArr.length-1;i++) {
        if(pathArr[i] === 'i') {
            for(let j=0;j<data.length;j++) {
                getAndSetValueFromPath(data, j + '.' + pathArr.slice(i+1).join('.'), modificationType)
            }
        } else {
            data = data[pathArr[i]]
            if(!data) {
                return ''
            }
        }
    }
    if(data[pathArr[i]]) {
        data[pathArr[i]] = formatData(data[pathArr[i]], modificationType)
    }
    return data
}

export const formatPlan = (plan, type) => {
    let modificationDetails = getPlanModificationDetils(type)
    modificationDetails.forEach(detail => {
        detail.path.forEach(detailPath => {
            getAndSetValueFromPath(plan, detailPath, detail.modificationType)
        })
    })
    return plan
}

export const calculatePercentage = (currentQuote, renewingQuote) => {
    let difference = 0
    let percentage = 0
    currentQuote = parseFloat(currentQuote)
    renewingQuote = parseFloat(renewingQuote)
    if ((currentQuote === 0.00 && renewingQuote === 0.00) || currentQuote === renewingQuote) {
        percentage = parseFloat(0).toFixed(2)
    }
    else if (currentQuote === 0) {
        percentage = parseFloat(100).toFixed(2)
    }
    else if (renewingQuote === 0) {
        percentage = parseFloat(-100).toFixed(2)
    }
    else {
        difference = renewingQuote - currentQuote
        percentage = ((parseFloat(difference) / parseFloat(currentQuote)) * 100).toFixed(2)
    }
    return percentage;
}

const findDependentCount = (members = []) => {
    let totalDependent = 0
    members.forEach(member => {
        totalDependent += member.dependents.length
    })
    return totalDependent
}

const modifyPercentage = (data) => {
    if(!data)
        return "+0%";
    if(!(data[0] === '+' || data[0] === '-'))
        data = "+"+data;
    return `${data}%`
}

const findRewardsValue = (data) =>{
    if(data === "uhcRewardsCore")
        return 'C'
    else if(data === "uhcRewardsPremium")
        return 'P'
    else
       return 'N'
}

const getRxBenCopayAmt = (rxTiers) => {
    let RxBenCopayAmt = ''
    rxTiers.forEach((rxTier, index) => {
        RxBenCopayAmt += rxTier.copay
        if (index < rxTiers.length - 1) {
            RxBenCopayAmt += '/'
        }
    })
    return RxBenCopayAmt
}

const getEligiblePlanInd = (hsa) => {
    return hsa === true || hsa === 'Y' ? 'HSA' : '';
}

const toLowerCase = (val) => {
    if (val) {
        return val.toLowerCase()
    }
    return ''
}

const getLifeProductDescription = (productType) => {
    if (productType === 'Basic EE Life') {
        return 'EMP LIFE'
    } else if (productType === 'Basic Dep Life') {
        return 'SP LIFE'
    } else if (productType === 'BASIC_LIFE_CHILD') {
        return 'CH LIFE'
    } else {
        return ''
    }
}

const removeDollarSign = (price) => {
    let index = price.indexOf('$')
    if(index >= 0) {
        price = price.slice(index+1)
    }
    return price.trim()
}

const findSalaryMult = (val) => {
    let index = val.indexOf('X')
    if(index >= 0) {
        val = val.slice(0, index)
    }
    return val
}

const getLifeBenefitAmount = (data) => {
    if (data?.benefitDetails?.benefitType === "Flat Amount" || (data?.lifeBenefitType && data?.lifeBenefitType.includes('FLAT'))) {
        if (data.productType === 'Basic EE Life') {
            return removeDollarSign(findValueFromPath(data, 'benefitAmount', ''))
        } else if (data.productType === 'Basic Dep Life') {
            return removeDollarSign(findValueFromPath(data, 'benefitAmount', '/').split('/')[0])
        } else if (data.productType === 'BASIC_LIFE_CHILD') {
            return removeDollarSign(findValueFromPath(data, 'benefitAmount', '/').split('/')[1])
        }
    } else {
        return findSalaryMult(findValueFromPath(data, 'benefitAmount', ''))
    }
}

const getLifePlanType = (data) => {
    if (data?.benefitDetails?.benefitType === "Flat Amount") {
        return 'DOLLAR' 
    } else {
        return 'MULTIPLE'
    }
}

const getLifeBenefitType = (data) => {
    if (data?.benefitDetails?.benefitType === "Flat Amount") {
        if (data.productType === 'Basic EE Life') {
            return 'FLAT EE'
        } else if (data.productType === 'Basic Dep Life') {
            return 'FLAT SP'
        } else if (data.productType === 'BASIC_LIFE_CHILD') {
            return 'FLAT CH'
        }
    } else {
        return 'SAL MULT'
    }
}

const getFlatPlanRatePerEmployee = (data) => {
    if (data?.benefitDetails?.benefitType === "Flat Amount") {
        let enrolledCount = data?.enrolledCount ? parseInt(data.enrolledCount) : 0
        let finalMonthlyPremium = data.finalMonthlyPremium ? parseFloat(data.finalMonthlyPremium) : 0
        if (enrolledCount > 0) {
            return (finalMonthlyPremium/enrolledCount).toFixed(2)
        } else {
            return '0.00'
        }
    } else {
        return 'NA'
    } 
}

// '@' sign in mapping path shows that value before that sign need to format using after sign function name
const getPlanOptionsMapping = (product, isMMRCase) => {
    switch (product) {
        case 'medical':
            return {
                IndInNetdeductibleAmount: 'deductible.individualIn',
                FamilyInNetDeductibleAmount: 'deductible.familyIn',
                PhysicianCopayAmount: 'pcpCopay',
                SpecialistCopayAmount: 'specialistCopay',
                PreventiveCareTypeCode: '',
                HospitalInNetCoInsurancePct: 'hospitalCoinsurance.in',
                HospitalOutNetCoInsurancePct: 'hospitalCoinsurance.out',
                EligiblePlanInd: 'hsa.@.getEligiblePlanInd',
                CombinedMedRxDeductibleOOPInd: 'combinedMedRxDeductible',
                IndOutNetDeductibleAmount: 'deductible.individualOut',
                FamilyOutNetDeductibleAmount: 'deductible.familyOut',
                IndividualInNetOopMaxAmount: 'oopMax.individualIn',
                FamilyInNetOopMaxAmount: 'oopMax.familyIn',
                IndividualOutNetOopMaxAmount: 'oopMax.individualOut',
                FamilyOutNetOopMaxAmount: 'oopMax.familyOut',
                RxBenCopayAmt: 'rx.tiers.@.getRxBenCopayAmt',
                PhyOfficeInNetCoInsurancePct: '',
                EmerVisitCopayAmount: '',
                InNetUccCopayAmount: '',
                NetworkBenefitIndicator: '',
                TieredPlanIndicator: '',
                NonEmbeddedDedAndOOP: '',
                HraPlanAvailableInd: '',
                PrimaryCarePhyRequiredInd: 'pcpRequired',
                PregPlan: '',
                MedicalPlanType: 'medicalPlanType',
            }
        case 'dental':
            return {
                IndNetDeductibleAmount: 'deductible.individualIn',
                IndOutNetDeductibleAmount: 'deductible.individualOut',
                FamilyInNetDeductibleAmount: 'deductible.familyIn',
                FamilyOutNetDeductibleAmount: 'deductible.familyOut',
                MajServicesInNetCoInsurancePct: 'coinsurance.majorIn',
                MajServiceOutNetCoInsurancePct: 'coinsurance.majorOut',
                MinorRestorativeInNetCoInsPct: 'coinsurance.minorIn',
                MinorRestorativeOutNetCoInsPct: 'coinsurance.minorOut',
                OrthoInNetCoinsurancePct: 'coinsurance.orthodonticsIn',
                OrthodonticsCoveredOutNetInd: 'coinsurance.orthodonticsOut',
                PreventiveInNetCoInsurancePct: 'coinsurance.preventiveIn',
                PreventiveOutNetCoInsurancePct: 'coinsurance.preventiveOut',
                LifetimeOrthoInNetBenAmount: '',
                InNetAnnualMaxBenefitAmount: 'maxBenefit.in',
                OutNetAnnualMaxBenefitAmount: 'maxBenefit.out',
                LifetimeOrthoOutNetBenAmount: '',
                WaitingPeriod: 'waitingPeriodMonths',
                WaitingPeriodOrtho: '',
                RolloverMaxAccountIndicator: '',
                UsualCustomaryPercentile: '',
                AnnDedMaxBenAppliesPrevDiag: '',
                OrthodontiaBenefit: '',
                ImplantINPercent: 'coinsurance.implantIn',
                ImplantOOOPercent: 'coinsurance.implantOut',
                EndoPerioOralInnetPct: 'endodonticServices.inEndo',
                EndoPerioOralOutnetPct: 'endodonticServices.oonEndo',
                PlanFundingDescription: 'fundingType.@.toLowerCase',
                BasicServInNetCoinsurancePct: 'coinsurance.basicIn',
                BasicServOutNetCoinsurancePct: 'coinsurance.basicOut',
            }
        case 'vision':
            return {
                VisionPlanType: '',
                InNetExamCopayAmount: 'exam.copayIn',
                InNetMaterialCopayAmount: 'materialCopayIn',
                NumofMonthsBetweenCoveredExam: '',
                FramesBenefitFrequency: 'frames.coveredMonthsInterval',
                LensesBenefitFrequency: 'lenses.coveredMonthsInterval',
                OutNetExamMaxAmount: 'exam.allowanceOut',
                OutNetFramesMaxAmount: 'frames.allowanceOut',
                OutNetContactLensesMaxAmount: 'contactLenses.allowanceOut',
                OutNetSingleVisionLensesMaxAmt: '',
                InnetworkContactLensAllowance: 'contactLenses.allowanceIn',
                RetailFrameAllowance: '',
            }
        case 'life':
            return {
                LifeProductDescription: 'productType.@.getLifeProductDescription',
                LifeBenefitAmount: '@.getLifeBenefitAmount',
                LifePlanType: '@.getLifePlanType',
                LifeBenefitType: '@.getLifeBenefitType',
                CalRatePerDollar1000Life: 'finalRates.basicLifeRateVol',
                CalRatePerDollar1000ADD: 'finalRates.aDnDLifeRateVol',
                LifeAddRatePer1000: 'finalRates.totalLifeRateVol',
                FlatPlanRatePerEmployee: '@.getFlatPlanRatePerEmployee',
                TotalLifeVolume: 'finalRates.BasicLifeTotVolAmount',
                MonthlyLifePremium: 'finalRates.basicLifeTotalPremium',
                MonthlyADDPremium: 'finalRates.aDndTotalPremium',
                MonthlyLifeADDPremium: 'finalMonthlyPremium',
            }
        case 'life_dep_basic':
        case 'basic_life_child':
            return {
                LifeProductDescription: 'productType.@.getLifeProductDescription',
                LifeBenefitAmount: '@.getLifeBenefitAmount',
                LifePlanType: '@.getLifePlanType',
                LifeBenefitType: '@.getLifeBenefitType',
                DependentLifeRate: isMMRCase ? `${product === 'life_dep_basic' ? 'depLifeSpouseRate' : 'depLifeChildRate'}` : `finalRates.${product === 'life_dep_basic' ? 'depLifeSpouseRate' : 'depLifeChildRate'}`,
                MonthlyLifeADDPremium: isMMRCase ? 'depLifeTotPremium' : 'finalMonthlyPremium',
            }
        case 'ltd':
            return {
                BenefitAmount : 'weeklyBenefitAmount',
                BenefitPercent : '',
                MonthlyMaximum : 'finalRates.maximumWeeklyBenefit',
                Duration : 'benifitDuration',
                EliminationPeriodAccident : 'eliminationPeriod',
                PreExistingCondition : 'preEx',
                RecurrentDisability : 'recurrent',
                DefinitionOfDisability : 'defOfDis',
                ownoccperiod : '',
                ssintegration : 'ssOffset',
                limitation : '',
                RequiredParticipation : '',
                Subjectivesymptoms : '',
                survivor : 'lumpSum',
                PlanFundingDescription : 'contributionType',
                RehabilitationServices : 'rehabBen',
                EarningsTest : 'earningsTest',
                TransplantBenefit : 'transplantBen',
                WorkIncentiveBenefit : 'workIncentive'
            }    
        case 'std':
            return {
                BenefitAmount : 'weeklyBenefitAmount',
                BenefitPercent : '',
                MonthlyMaximum : 'finalRates.maximumWeeklyBenefit',
                Duration : 'benifitDuration',
                EliminationPeriodAccident : 'eliminationPeriod',
                EliminationPeriodSickness : '',
                PreExistingCondition : 'preEx',
                DefinitionOfDisability : '',
                RequiredParticipation : '',
                firstdayhospital : '',
                PlanFundingDescription : 'contributionType',
                Maternity : 'maternity',
                RehabilitationServices : 'rehab',
                LumpSumSurvivorBenefit : 'lumpSum',
                CoverageType : 'coverageType'
            }
            case 'Supplement Life':
            case 'supplement life':
                return {
                    BenefitAmount : '',
                    BenefitPercent : '',
                    MonthlyMaximum : '',
                    Duration : 'benifitDuration',
                    EliminationPeriodAccident : 'eliminationPeriod',
                    EliminationPeriodSickness : '',
                    PreExistingCondition : 'preEx',
                    DefinitionOfDisability : '',
                    RequiredParticipation : '',
                    firstdayhospital : '',
                    PlanFundingDescription : 'contributionType',
                }
        default:
            return {};
    }
}

// '@' sign in mapping path shows that value before that sign need to format using after sign function name
const getClientDefinedDataMapping = (product) => {
    switch (product) {
        case 'medical':
            return {
                MetalTier: 'metal',
                PlanCategory: 'medicalPlanCategory',
                EmployeeEnrollCount: 'enrolledCount',
                OISABITName: 'UnitedHealthcare',
                ProductInsuranceLicenseType: 'licenseType',
                PlatformIdentifier: 'platform',
                PolicyNumber: '', // TODO
                ForcedUrgedIndicator: '',
                BillTypeCodeChanged: '',
                MedicareOptionCode: '',
                COBRAContinuanceCode: '',
                DependentEnrollCount: 'member.@.findDependentCount',
                GrandfatherInd: '',
                PlanDedYear: '',
                EventAdminInd: '',
                RxDeductible: 'rx.deductible',
                LegalName: 'legalName',
                HiosId: 'hiosId',
                oonCarrierID: 'oonCarrierID',
                CostDifferentialPercentage : 'renewalChangePercent.@.modifyPercentage',
                underwriterExceptionCode1: 'underwriterExceptionCode',
                UhcRewardsInd : 'uhcRewards.@.modifyInd'
            }
        case 'dental':
            return {
                PlanCategory: 'fundingType',
                EmployeeEnrollCount: 'enrolledCount',
                Tier1Subcount: '',
                Tier2Subcount: '',
                Tier3Subcount: '',
                Tier4Subcount: '',
                DentDualPlanIndicator: '',
                DependentEnrollCount: 'member.@.findDependentCount',
                EventAdminInd: '',
                PolicyNumber: '',
                RenewalRateID: 'platformRatingID',
                CostDifferentialPercentage : 'renewalChangePercent.@.modifyPercentage',
                underwriterExceptionCode1: 'underwriterExceptionCode',
                LegalName: 'legalName',
            }
        case 'vision':
            return {
                PlanCategory: 'visionPlanTypeDescription',
                EmployeeEnrollCount: 'enrolledCount',
                Tier1Subcount: '',
                Tier2Subcount: '',
                Tier3Subcount: '',
                Tier4Subcount: '',
                DependentEnrollCount: 'member.@.findDependentCount',
                EventAdminInd: '',
                PolicyNumber: '',
                ForcedUrgedIndicator: '',
                RenewalRateID: 'platformRatingID',
                CostDifferentialPercentage : 'renewalChangePercent.@.modifyPercentage',
                underwriterExceptionCode1: 'underwriterExceptionCode',
                RateGuaranteePeriod: 'rateGuarantee',
                LegalName: 'legalName',
            }
        case 'life':
            return {
                PlanCategory: '@.getLifeBenefitType',
                EmployeeEnrollCount: 'enrolledCount',
                RateGuaranteePeriod: 'rateGuarantee',
                CostDifferentialPercentage: 'renewalChangePercent.@.modifyPercentage',
                RenewalRateID: 'platformRatingID', // not found
                DiscountRatio: '', // not found
                StepwiseQuoteID: '', // not found
                PolicyNumber: '', // not found
                LegalName: 'legalName',
                underwriterExceptionCode1: 'underwriterExceptionCode', // not found
                basicLifePlanCode: 'code',
                basicLifeGiAmount: 'guaranteedIssue',
                basicLifeContribution: 'fundingType',
                basicLifeAddPlanCode: 'pairedAdnDDetails.code',
                basicLifeAddGiAmount: 'pairedAdnDDetails.guaranteedIssue',
                basicLifeAddContribution: 'pairedAdnDDetails.fundingType',
            }
        case 'life_dep_basic':
        case 'basic_life_child':
            return {
                PlanCategory: '@.getLifeBenefitType',
                DependentEnrollCount: 'member.@.findDependentCount', // need to check
                RateGuaranteePeriod: 'rateGuarantee',
                CostDifferentialPercentage: 'renewalChangePercent.@.modifyPercentage',
                RenewalRateID: 'platformRatingID', // not found
                DiscountRatio: '', // not found
                StepwiseQuoteID: '', // not found
                dependentLifePlanCode: 'code',
                dependentLifeGiAmount: 'guaranteedIssue',
                dependentLifeContribution: 'fundingType',
                LegalName: 'legalName',
            }
        case 'ltd':
            return {
                PlanCategory : 'productType',
                EmployeeEnrollCount : 'enrolledCount',
                DependentEnrollCount : '0',
                StepwiseQuoteID : '',
                DiscountRatio : '',
                CostDifferentialPercentage : 'renewalChangePercent.@.modifyPercentage',
                RateGuaranteePeriod : 'rateGuarantee',
                PolicyNumber : '',
                EligibleEmployeeCount : '',
                MinimumWorkHours : '',
                TotalMonthlyCoveredPayroll : 'finalRates.totalMCP',
                LTDplanCode : 'code',
            } 
        case 'std':
            return {
                PlanCategory : 'productType',
                EmployeeEnrollCount : 'enrolledCount',
                DependentEnrollCount : '0',
                StepwiseQuoteID : '',
                DiscountRatio : '',
                CostDifferentialPercentage : 'renewalChangePercent.@.modifyPercentage',
                RateGuaranteePeriod : 'rateGuarantee',
                PolicyNumber : '',
                EligibleEmployeeCount : '',
                MinimumWorkHours : '',
                CalculatedTotWklyBen : 'finalRates.totalWeeklyBenefit',
                STDplanCode : 'code',
            }  
            case "SL":
            case 'SLS':
            case 'SLC':
            return {
                PlanCategory : 'Standard',
                EmployeeEnrollCount : '0',
                DependentEnrollCount : '',
                PolicyNumber : '',
                EligibleEmployeeCount : '',
                MinimumWorkHours : '',
                CalculatedTotWklyBen : '',
                SULplanCode:'code',
                RateGuaranteePeriod:'rateGuarantee',
            } 
            case "SA":
            case 'SAS':
            case 'SAC':
                return {
                    PlanCategory : 'Standard',
                    EmployeeEnrollCount : '0',
                    DependentEnrollCount : '',
                    PolicyNumber : '',
                    EligibleEmployeeCount : '',
                    MinimumWorkHours : '',
                    CalculatedTotWklyBen : '',
                    SULADDplanCode:'code',
                    RateGuaranteePeriod:'rateGuarantee',
                }              
        default:
            return {};
    }
}

const formatValue = (functionName, data) => {
    switch (functionName) {
        case 'findDependentCount':
            return findDependentCount(data)
        case 'getRxBenCopayAmt':
            return getRxBenCopayAmt(data)
        case 'modifyPercentage':
            return modifyPercentage(data)
        case 'toLowerCase':
            return toLowerCase(data)
        case 'modifyInd':
            return findRewardsValue(data)
        case 'getLifeProductDescription':
            return getLifeProductDescription(data)
        case 'getLifeBenefitAmount':
            return getLifeBenefitAmount(data)
        case 'getLifePlanType':
            return getLifePlanType(data)
        case 'getLifeBenefitType':
            return getLifeBenefitType(data)
        case 'getFlatPlanRatePerEmployee':
            return getFlatPlanRatePerEmployee(data)
        case 'getEligiblePlanInd' :
            return getEligiblePlanInd(data)   
        default:
            return data
    }
}

const findValueFromPath = (data, path, defaultValue = '') => {
    if(!data || !path) {
        return defaultValue
    }
    let pathArr = path.split('.')
    for(let i=0;i<pathArr.length;i++) {
        if (pathArr[i] === '@') {
            data = formatValue(pathArr[i+1], data)
            i++
        } else {
            data = data[pathArr[i]]
        }
        if(!data) {
            return defaultValue
        }
    }
    return data
}

const generatePlanOptionsAndCDDArr = (mapping, rootObj, isCDDFields) => {
    let finalObjArr = []
    Object.keys(mapping).forEach(key => {
        let finalObj = {}
        if (isCDDFields) {
            finalObj.key = key
            finalObj.value = findValueFromPath(rootObj, mapping[key])
        } else {
            finalObj.planOptionID = key
            finalObj.planOptionValueID = findValueFromPath(rootObj, mapping[key])
        }
        finalObjArr.push(finalObj)
    })
    if (isCDDFields && rootObj?.planType?.toLowerCase() === 'medical') {
        finalObjArr.push({
            key: 'RenewalRateID',
            value: rootObj?.defaultRatingType === 'composite' ? rootObj?.platformCompositeRatingID : rootObj?.platformAgeBandedRatingID
        })

        rootObj?.selectedBenefitRiders?.forEach(benefitRider=>{                                              //plan can select 1 or more optional benefitRider
            if(benefitRider && !(benefitRider==="RAP" || benefitRider==="SEO" || benefitRider==="SPO"))      //ridersToBeFiltered
            {
                finalObjArr.push({
                    key: 'RiderCode',
                    value : benefitRider
                })
            }          
        })
    }
    
    return finalObjArr
}

const getPlanOptionsAndCDD = (plan, isMMRCase) => {
    let planOptions = generatePlanOptionsAndCDDArr(getPlanOptionsMapping(plan.planType.toLowerCase(), isMMRCase), plan, false)
    let clientDefinedData = generatePlanOptionsAndCDDArr(getClientDefinedDataMapping(plan.planType.toLowerCase() === "supplement life"  && plan.productType  ?  plan.productType:plan.planType.toLowerCase() ), plan, true)
    return {planOptions, clientDefinedData}
}


const findRates = (plan) => {
    let suppLifeAgebandedMapping={
        "SL" :"slEEAgeBands",
        "SA":"slEEADnDAgeBands",
        "SLS":"slSPAgeBands",
        "SAS":"slSPADnDAgeBands",
        "SLC":"slCHAgeBands",
        "SAC":"slCHADnDAgeBAnds"
    }
    let suppLifeCompositeMapping={
        "SL" :"slEERate",
        "SA":"slEEADnDRate",
        "SLS":"slSPRate",
        "SAS":"slSPADnDRate",
        "SLC": "slCHRate",
        "SAC": "slCHADnDRate"  
    }
    if (plan.planType.toLowerCase() === 'life' || plan.planType.toLowerCase() === 'life_dep_basic') {
        let compositeRatesObj = {}
        for (let index=0;index<4;index++) {
            compositeRatesObj[`tier${index+1}`] = '0.00'
        }
        return { compositeRates: compositeRatesObj }
    } else if (!['Medical','LTD','STD',"Supplement Life"].includes(plan.planType)) {
        let compositeRatesObj = {}
        Object.values(plan.finalRates).forEach((rate, index) => {
            compositeRatesObj[`tier${index+1}`] = rate
        })
        return { compositeRates: compositeRatesObj }
    } else if (['std'].includes(plan.planType.toLowerCase()) && plan.defaultRatingType === 'composite') {
        let compositeRatesObj = {}
        compositeRatesObj[`tier1`] = plan.finalRates?.stdRate ? plan.finalRates.stdRate : plan.finalRates.rateOver10WeeklyBenefit
        return { compositeRates: compositeRatesObj }
    } else if (['ltd'].includes(plan.planType.toLowerCase()) && plan.defaultRatingType === 'composite') { 
        let compositeRatesObj = {}
        compositeRatesObj[`tier1`] = plan.finalRates?.ltdRate ? plan.finalRates.ltdRate : plan.finalRates.timesRatesPer100ofMCP
        return { compositeRates: compositeRatesObj }
    }
    else if(plan.planType ==="Supplement Life"){
        let productType = plan?.productType        
        if (plan.finalRates.byCensus[suppLifeAgebandedMapping[productType]]) {
            let ageBand =plan.finalRates.byCensus[suppLifeAgebandedMapping[productType]].map(rate => {
                return {
                    minAge: rate.minAge,
                    maxAge: rate.maxAge,
                    genderType: "A",
                    rates: { tier1: rate.rate || rate.total }
                }
            })
            return { ageRates: {ageBand} }
        }
        else if(plan.finalRates.byCensus[suppLifeCompositeMapping[productType]]){
            let compositeRatesObj = {}
            
            compositeRatesObj[`tier1`] = plan.finalRates.byCensus[suppLifeCompositeMapping[productType]]
        
        return { compositeRates: compositeRatesObj }
        }
     
    }
     else if (plan.defaultRatingType === 'composite') {
        let compositeRatesObj = {}
        plan.compositeRates.forEach((rate, index) => {
            compositeRatesObj[`tier${index+1}`] = rate.rate
        })
        return { compositeRates: compositeRatesObj }
    } else {
        let finalAgeBandRates = ['ltd'].includes(plan.planType.toLowerCase()) && plan.finalRates?.ltdRate ? plan.finalRates.ltdRate : 
        ['std'].includes(plan.planType.toLowerCase()) && plan.finalRates?.stdRate ? plan.finalRates.stdRate : plan.ageBands;
        let ageBand = finalAgeBandRates?.map(rate => {
            return {
                minAge: rate.minAge,
                maxAge: rate.maxAge,
                genderType: "A",
                rates: { tier1: rate.rate || rate.total }
            }
        })
        return { ageRates: {ageBand} }
    }
}

export const findTierStructureId = (rate, product, defaultRatingType = '', planTierStructureId) => {
    if (product === 'Medical' || product === 'LTD' || product === 'STD' || product =="Supplement Life" ) {
        if (defaultRatingType === 'ageBands'|| (product =="Supplement Life"  && rate.ageRates)) {
            switch(planTierStructureId) {
                case '1':
                case '2':
                case '5':
                    return planTierStructureId;
                default:
                    return '2';
            }
        } else {
            if (Object.keys(rate.compositeRates).length == 2) {
                switch(planTierStructureId) {
                    case '6':
                    case '7':
                    case 'B':
                        return planTierStructureId;
                    default:
                        return 'B';
                }
            } else if(Object.keys(rate.compositeRates).length == 3) {
                switch(planTierStructureId) {
                    case 'C':
                    case '3':
                    case '9':
                        return planTierStructureId;
                    default:
                        return 'C';
                }
            } else {
                switch(planTierStructureId) {
                    case 'A':
                    case '4':
                    case '8':
                        return planTierStructureId;
                    default:
                        return 'A';
                }
            }
        }
    } else {
        if (Object.keys(rate.compositeRates).length == 2) {
            return 'B'
        } else {
            return 'A'
        }
    }
}

export const generateIbrFormattedPlan = (plan, isMMRCase = false) => {
    let {planOptions, clientDefinedData} = getPlanOptionsAndCDD(plan, isMMRCase)
    let rates = findRates(plan)
    let suppLifeProductLineMapping={
        "SL" :"SUP_LIFE_EE",
        "SA":"SUP_ADD_EE",
        "SLS":"SUP_LIFE_SPOUSE",
        "SAS":"SUP_ADD_SPOUSE",
        "SLC":"SUP_LIFE_CHILD",
        "SAC":"SUP_ADD_CHILD"
    }
    let suppLifeProductWiseTotalPremium = plan?.finalRates?.byCensus?.productWiseTotalPremium
    let suppLifePremiumMapping={
        "SL" :"totalSlEEPremium",
        "SA":"totalSlEEADnDPremium",
        "SLS":"totalSlSPPremium",
        "SAS":"totalSlSPADnDPremium",
        "SLC":"totalSlCHPremium",
        "SAC":"totalSlCHADnDPremium"
    }
    let suppLifeTotalPremium = plan?.productType && plan.planType.toLowerCase()=="supplement life" ? suppLifePremiumMapping[plan.productType]: null;
    let suppLifeProductLineType = plan?.productType && plan.planType.toLowerCase()=="supplement life" ? suppLifeProductLineMapping[plan.productType]: null;
    let finalPlanObj = {
        planID: plan.planType.toLowerCase() === 'medical' ? plan.code + '/' + plan.rxPlanCode : 
                (plan.planType.toLowerCase() === 'life' || plan.planType.toLowerCase() === 'life_dep_basic' || plan.planType.toLowerCase() === 'basic_life_child') ? plan.primePlanCode : ['ltd','std', 'supplement life'].includes(plan.planType.toLowerCase()) ? plan.piaPlanCode : plan.code,
        planXREF: (plan.planType.toLowerCase() === 'life' || plan.planType.toLowerCase() === 'life_dep_basic' || plan.planType.toLowerCase() === 'basic_life_child') ? plan.primePlanCode : ['ltd','std','supplement life'].includes(plan.planType.toLowerCase()) ? plan.piaPlanCode : plan.code,
        productLineType: suppLifeProductLineType ? suppLifeProductLineType: plan.planType.toLowerCase() === 'life' ? 'BASIC_LIFE_EE' : plan.planType.toLowerCase() === 'life_dep_basic' ? 'BASIC_LIFE_SPOUSE' : plan.planType.toUpperCase(),
        tierStructureID: findTierStructureId(rates, plan.planType, plan.defaultRatingType, plan.tierStructureId),
        options: {
            planOption: planOptions
        },
        probationaryPeriods: {
            probationaryPeriod: [{
                periodType: "D",
                days: 0
            }]
        },
        rates: JSON.parse(JSON.stringify(rates)),
        planRate:(plan.planType.toLowerCase() === 'supplement life')? suppLifeProductWiseTotalPremium && suppLifeProductWiseTotalPremium[suppLifeTotalPremium] :(plan.planType.toLowerCase() === 'life' ) ? plan.finalMonthlyPremium :  (['ltd','std'].includes(plan.planType.toLowerCase())) ? plan.totalMonthlyPremium : 
                (plan.planType.toLowerCase() === 'life_dep_basic' || plan.planType.toLowerCase() === 'basic_life_child') ? plan.depLifeTotPremium : plan.quote,
        clientDefinedData: {
            data: clientDefinedData
        }
    }
    if (plan.planType.toLowerCase() === 'medical') {
        finalPlanObj.productXREF = plan.licenseType
        finalPlanObj.contributions = {
            contribution: [{
                coverageType: "EMP",
                contribType: "Voluntary",
                amount: {
                    pct: "1.0"
                }
            }]
        }
        finalPlanObj.planFamilyName = plan.selectedPackages.length > 0 ? plan.selectedPackages[0] : ''
    }
    return finalPlanObj
}

export const updateIbrPlanDetails = (Ibr, store, planPkgFieldName) => {
    Ibr.quote.planOfferingData[0][planPkgFieldName].plans.plan.forEach(plan => {
        plan.clientDefinedData.data.forEach(cddObj => {
            if (cddObj.key === 'PlanDedYear') {
                cddObj.value = findValueFromPath(store, 'enrollment.application.employer.additionalGeneralInformationRenewal.medicalBenefitPlanOption', 'C')[0]
            }
        })
    })
}

export const getHiosIdAndLegalName = (medicalPlans, effectiveDate, stateCode, zipCode, OISABIT, groupSize) => {
    return new Promise((resolve, reject) => {
        try {
            const hiosReqBody = {
                reqBody: {
                    hiosRequestMsg: {
                        hiosRequest: {
                            reqData: {
                                oiCd: OISABIT.OI,
                                saCd: OISABIT.SA,
                                bitCd: OISABIT.BIT,
                                effDate: effectiveDate,
                                grpSize: groupSize,
                                stateCd: stateCode,
                                zipCd: zipCode,
                                acrInd: "Y",
                                mktTyp: 0,
                                networkNbr: 0,
                                dentPlan: "",
                                dualdentPlan: "",
                                visnInd: "N",
                                lifeInd: "N",
                                medreqTbl: stateCode === "CA" ? (medicalPlans.filter(plan=>plan.installPlatform === 'CIRRUS' || plan?.platform === 'PRIME').map(plan => {
                                    return {
                                        pgmYr: plan.code && plan.code.slice(0, 2),
                                        hlthplnCd: plan.code && plan.code.slice(2),
                                        rxCd: plan.rxPlanCode,
                                        metalLvl: plan.metal && plan.metal[0]
                                    }
                                })) : (medicalPlans.map(plan => {
                                    return {
                                        pgmYr: plan.code && plan.code.slice(0, 2),
                                        hlthplnCd: plan.code && plan.code.slice(2),
                                        rxCd: plan.rxPlanCode,
                                        metalLvl: plan.metal && plan.metal[0]
                                    }
                                }))
                            }
                        }
                    }
                }
            }
            axios.post(`${serviceLayer}/install/hiosapi`, hiosReqBody)
                .then((resp) => {
                    if (resp?.data?.hiosResponseMsg?.hiosData?.overallresCd === 'S') {
                        resp?.data?.hiosResponseMsg?.hiosData?.medresTbl?.forEach(hiosResp => {
                            if (hiosResp.medresCd === 'S') {
                                let planIdx = medicalPlans.findIndex(plan => plan.code === hiosResp.pgmYr + hiosResp.hlthplnCd)
                                if (planIdx >= 0) {
                                    medicalPlans[planIdx].hiosId = hiosResp.hiosplnID
                                    medicalPlans[planIdx].legalName = hiosResp.innetleNm
                                    medicalPlans[planIdx].oonCarrierID = hiosResp.outnetleNm
                                }
                            }
                        })
                        resolve(medicalPlans)
                    }
                }).catch((err) => {
                    logError(KEY_NAME, UHC_REN_APIS.HIOS_API, 'mmrHelper.getHiosIdAndLegalName', hiosReqBody, err);
                    console.log('HIOS API err', err)
                    reject()
                });
        } catch(err) {
            console.log(err)
            reject()
        }
    })
}