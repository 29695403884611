import { findAndUpdatePremiumAndRates } from "./rateAdjustmentHelper";
import deepCopy from "../../../utils/deepCopy";


const findAndTogglePlan = (plans, code, field, id = -1, isUhcRenewals = false) => {
    for (let sets of plans) {
        for(let plan of sets){
            let planMatchCondition = isUhcRenewals ? 
                    code === plan.code && plan.id === id && plan.renewalPlanType === 'renewing' :
                    code === plan.code && plan.renewalPlanType === 'renewing';
            
            if (planMatchCondition) {
                plan[field] = !plan[field];
                break;
            }
        }
    }
    return plans;
}
const findAndToggleAlternatePlan = (plans, code, field) => {
    for (let plan of plans) {
        if (code === plan.code && plan.renewalPlanType === 'alternate') {
            plan[field] = !plan[field];
            break;
        }
    }
    return plans;
}

const togglePlanMembership = (action, plan, currentPlans, pkg, isUhcRenewals = false) => {
    const newPlans =  deepCopy(currentPlans || [])
    if (newPlans.length > 0) {
        if (action === 'selected') {
            var index = newPlans.findIndex((selectedPlan) => {
                if(isUhcRenewals && plan.renewalPlanType === 'renewing') {
                    if(!pkg || pkg.toLowerCase() === 'single') {
                        return selectedPlan.code === plan.code && selectedPlan.id === plan.id
                    } else {
                        return selectedPlan.code === plan.code && selectedPlan.selectedPackages.includes(pkg) && selectedPlan.id === plan.id
                    }
                } 
                return selectedPlan.code === plan.code
            });
            // plan does not exist in current plans
            if (index === -1) {
                plan.selectedPackages = [];
                if (pkg) {
                    plan.selectedPackages.push(pkg);
                }
                newPlans.push(plan);
            } else {
                // plan exists in current plans
                if(!pkg || pkg.toLowerCase() === 'single') {
                    newPlans.splice(index, 1);
                } else if (newPlans[index].selectedPackages.includes(pkg)) {
                    if (newPlans[index].selectedPackages.length > 1) {
                        const packageIndex = newPlans[index].selectedPackages.indexOf(pkg);
                        newPlans[index].selectedPackages.splice(packageIndex, 1);
                    } else {
                        newPlans.splice(index, 1);
                    }
                } else if (pkg) {
                    newPlans[index].selectedPackages.push(pkg);
                }
            }
        } else {
            var index = newPlans.findIndex((selectedPlan) => selectedPlan.code === plan.code);
            if (index === -1) {
                newPlans.push(plan);
            } else {
                newPlans.splice(index, 1);
            }
        }
    } else if (action === 'selected') {
        plan.selectedPackages = [];
        if (pkg) {
            plan.selectedPackages.push(pkg);
        }
        newPlans.push(plan);
    } else {
        newPlans.push(plan);
    }
    return newPlans;
};

export const handleUpdatePlanRenewal = (state, action, type) => {

    // We must update the selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans,selectedPlansState,selectedPlansStateLife,alternatePlans;
    if(type === 'medical') {
        newPlans = state.medical && state.medical.set||[]
        selectedPlansState = state.medical && state.medical.selectedPlans||[]
        alternatePlans=state.alternate||[]
    } else if(type === 'dental'){
        newPlans = state.dental && state.dental.set||[]
        selectedPlansState = state.dental && state.dental.selectedPlans||[]
        alternatePlans=state.alternate||[]
    }
    else if(type === 'vision'){
        newPlans = state.vision && state.vision.set||[]
        selectedPlansState = state.vision && state.vision.selectedPlans||[]
        alternatePlans=state.alternate||[]
    }
    else if(type === 'life'){
        newPlans = state.life && state.life.set||[]
        selectedPlansState = state.life && state.life.selectedPlans||[]
        alternatePlans=state.alternate||[]
    }
    else if(type === 'LIFE_DEP_BASIC'){
        newPlans = state.dependentLife && state.dependentLife.set||[]
        selectedPlansState = state.dependentLife && state.dependentLife.selectedPlans||[]
        alternatePlans=state.alternate||[]
    }
    newPlans = findAndTogglePlan(newPlans, action.plan.code, action.field, action.plan.id, action.isUhcRenewals);
    alternatePlans=findAndToggleAlternatePlan(alternatePlans,action.plan.code, action.field);
    let newSelectedPlans;

    if (action.field === "selected") {
        newSelectedPlans = togglePlanMembership('selected', action.plan, selectedPlansState, action.pkg, action.isUhcRenewals);
    }

    let newSpecialityPlan = {
        set:newPlans,
        selectedPlans: newSelectedPlans
    }
    switch (type){
        case 'medical':
            return {
                ...state,
                medical:newSpecialityPlan,
                alternate:alternatePlans
            };
        case 'dental':
            return {
                ...state,
                dental:newSpecialityPlan,
                alternate:alternatePlans
            };
        case 'vision':
            return {
                ...state,
                vision:newSpecialityPlan,
                alternate:alternatePlans

            };
        case 'life':
            return {
                ...state,
                life:newSpecialityPlan,
                alternate:alternatePlans

            };
        case 'LIFE_DEP_BASIC':
            return {
                ...state,
                dependentLife:newSpecialityPlan,
                alternate:alternatePlans

            };
    }
}

export const handleUpdatePlanRenewalShopping = (state, action, type) => {
    // We must update the selected booleans in the current visible plans,
    // as well as the master list of plans, since future filtering will use that master list
    let newPlans,selectedPlansState,selectedPlansStateLife,alternatePlans;
    if(type === 'medical'){
        newPlans = deepCopy(state.medical.set||[])
        selectedPlansState = deepCopy(state.medical.selectedPlans||[])
        alternatePlans = deepCopy(state.alternate||[])
    }
    if(type === 'dental'){
        newPlans = deepCopy(state.dental.set||[])
        selectedPlansState = deepCopy(state.dental.selectedPlans||[])
        alternatePlans = deepCopy(state.alternate||[])
    }
    else if(type === 'vision'){
        newPlans =  deepCopy(state.vision.set||[])
        selectedPlansState =  deepCopy(state.vision.selectedPlans||[])
        alternatePlans =  deepCopy(state.alternate||[])
    }
    else if(type === 'life'){
        newPlans =  deepCopy(state.life.set||[])
        selectedPlansState =  deepCopy(state.life.selectedPlans||[])
        alternatePlans =  deepCopy(state.alternate||[])
    }
    else if(type === 'LIFE_DEP_BASIC'){
        newPlans =  deepCopy(state.dependentLife.set||[])
        selectedPlansState =  deepCopy(state.dependentLife.selectedPlans||[])
        alternatePlans =  deepCopy(state.alternate||[])
    }
    newPlans = findAndTogglePlan(newPlans, action.plan.code, action.field, action.plan.id, action.isUhcRenewals);
    alternatePlans=findAndToggleAlternatePlan(alternatePlans,action.plan.code, action.field);
    let newSelectedPlans, newSelectedPlansLife;

    if (action.field === "selected") {
        newSelectedPlans = togglePlanMembership('selected', action.plan, selectedPlansState, action.pkg, action.isUhcRenewals);
    }

    let newSpecialityPlan = {
        set:newPlans,
        selectedPlans: newSelectedPlans
    }
    switch (type){
        case 'medical':
            return {
                ...state,
                medical:newSpecialityPlan,
                alternate:alternatePlans
            };
        case 'dental':
            return {
                ...state,
                dental:newSpecialityPlan,
                alternate:alternatePlans
            };
        case 'vision':
            return {
                ...state,
                vision:newSpecialityPlan,
                alternate:alternatePlans

            };
        case 'life':
            if(newSpecialityPlan.selectedPlans.length === 0)
                {
                    state.dependentLife.selectedPlans = []
                    return {
                        ...state,
                        life:newSpecialityPlan,
                        alternate:alternatePlans
        
                    };
                }
                else{
                    return {
                        ...state,
                        life:newSpecialityPlan,
                        alternate:alternatePlans
        
                    };
                }
            
        case 'LIFE_DEP_BASIC':
            return {
                ...state,
                dependentLife:newSpecialityPlan,
                alternate:alternatePlans

            };
    }
}

export const handleAdjustRenewalsRatesAndPremium = (state, action, type) => {
    // We need to update the rates in all places where we store the plan, so copy each of those
    let newPlans,selectedPlansState;
    if (type === 'dental') {
        newPlans = state.dental.set
        selectedPlansState = state.dental.selectedPlans
    } else if (type === 'vision') {
        newPlans = state.vision.set
        selectedPlansState = state.vision.selectedPlans
    } else if (type === 'life') {
        newPlans = state.life.set
        selectedPlansState = state.life.selectedPlans
    } else if (type === 'ltd') {
        newPlans = state.ltd.set
        selectedPlansState = state.ltd.selectedPlans
    } else if (type === 'std') {
        newPlans = state.std.set
        selectedPlansState = state.std.selectedPlans
    }

    let newSelectedPlans;
    let newPlansSet = [];
    let newShoppingPlans = [];
    const formatedType = ['ltd','std'].includes(type) ? type.toUpperCase() : type.charAt(0).toUpperCase() + type.slice(1);
    // Find the plan and update the premium for that plan

    if (action.isUhcRenewals) {
        // extract renewing, current plans from set
        const renewingPlans = newPlans.flat().filter(plan => plan.renewalPlanType === 'renewing') || [];
        const currentPlans = newPlans.flat().filter(plan => plan.renewalPlanType === 'current') || [];
        const shoppingPlans = state[`shopping${formatedType}Plans`] ? [...state[`shopping${formatedType}Plans`]] : [];

        // get updated renewing, selected and shopping plans
        const updatedRenewingPlans = findAndUpdatePremiumAndRates(renewingPlans, action.planCode, action.premium, action.rates, action.adjustments, action.isMMRCase, action.isAgeBanded);
        newShoppingPlans = findAndUpdatePremiumAndRates(shoppingPlans, action.planCode, action.premium, action.rates, action.adjustments, action.isMMRCase, action.isAgeBanded);
        newSelectedPlans = findAndUpdatePremiumAndRates(selectedPlansState, action.planCode, action.premium, action.rates, action.adjustments, action.isMMRCase, action.isAgeBanded);

        if (action.isMMRCase) {
            currentPlans.forEach((currentPlan, index) => {
                newPlansSet = [...newPlansSet, [currentPlan, updatedRenewingPlans[index]]]
            })
        } else {
            // create set for current and renewing plans
            updatedRenewingPlans.forEach(renewingPlan => {
                const currentPlan = currentPlans.find(plan => plan.code === renewingPlan.code);
                if (currentPlan) {
                    newPlansSet = [...newPlansSet, [currentPlan, renewingPlan]];
                }
            })
        }
        newPlans = newPlansSet;
    } else {
        newPlans = findAndUpdatePremiumAndRates(newPlans, action.planCode, action.premium, action.rates, action.adjustments);
        newSelectedPlans = findAndUpdatePremiumAndRates(selectedPlansState, action.planCode, action.premium, action.rates, action.adjustments);
        newShoppingPlans = [...state[`shopping${formatedType}Plans`]];
    }

    let newSpecialityPlan = {
        set:newPlans,
        selectedPlans: newSelectedPlans
    }

    switch (type) {
        case 'dental':
            return {
                ...state,
                rateAdjustAllow: action.isAllowed,                
                dental:newSpecialityPlan,
                shoppingDentalPlans: newShoppingPlans
            };
        case 'vision':
            return {
                ...state,
                rateAdjustAllow: action.isAllowed,                
                vision:newSpecialityPlan,
                shoppingVisionPlans: newShoppingPlans
            };
        case 'life':
            return {
                ...state,
                rateAdjustAllow: action.isAllowed,                
                life:newSpecialityPlan,
                shoppingLifePlans: newShoppingPlans
            };
        case 'ltd':
            return {
                ...state,
                rateAdjustAllow: action.isAllowed,                
                ltd:newSpecialityPlan,
                shoppingLTDPlans: newShoppingPlans
            }; 
        case 'std':
            return {
                ...state,
                rateAdjustAllow: action.isAllowed,                
                std:newSpecialityPlan,
                shoppingSTDPlans: newShoppingPlans
            };        
    }

}

export const handleMMRSpecialtyShopping = (state, action, type) => {
    switch (type){
        case 'dental':
            let dentalPlanCodes = action.payload
            let dentalPlans = state.mmr.dentalPlans.concat(dentalPlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    dentalPlans: dentalPlans
                }
            };
        case 'vision':
            let visionPlanCodes = action.payload
            let visionPlans = state.mmr.visionPlans.concat(visionPlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    visionPlans: visionPlans,
                }
            };
        case 'life':
            let lifePlanCodes = action.payload
            let lifePlans = state.mmr.lifePlans.concat(lifePlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    lifePlans: lifePlans
                }
            };
        case 'LIFE_DEP_BASIC':
            let depLifePlanCodes = action.payload
            let dependentLifePlans = state.mmr.dependentLifePlans.concat(depLifePlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    dependentLifePlans: dependentLifePlans
                }
            };
        case 'std':
            let stdPlanCodes = action.payload
            let stdPlans = state.mmr.stdPlans.concat(stdPlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    stdPlans: stdPlans
                }
            };
        case 'ltd':
            let ltdPlanCodes = action.payload
            let ltdPlans = state.mmr.ltdPlans.concat(ltdPlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    ltdPlans: ltdPlans
                }
            };
        case 'sul':
        case 'supplife':
            let sulPlanCodes = action.payload
            let sulPlans = state.mmr.sulPlans.concat(sulPlanCodes);
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    sulPlans: sulPlans,
                    suppLifePlans:sulPlans,
                }
            };
       
    }
}
export const handleMMRSpecialtyLoader = (state, action, type) => {
    switch (type){
        case 'dental':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingDental: action.payload
                }
            };
        case 'vision':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingVision:action.payload,
                }
            };
        case 'life':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingLife: action.payload
                }
            };
        case 'LIFE_DEP_BASIC':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingDependentLife: action.payload
                }
            };
        case 'ltd':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingLTDRates: action.payload
                }
            }
        case 'std':
            return {
                ...state,
                mmr: {
                    ...state.mmr,
                    isFetchingSTDRates: action.payload
                }
            }
        case 'sul':
        case 'supplife':
            return {
                ...state,
                mmr:{
                    ...state.mmr,
                    isFetchingSULRates:action.payload,
                    isFetchingSUPPLIFERates:action.payload
                }
            }
    }
}