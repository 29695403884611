import * as types from '../../actions/actionTypes';
import {
    filterPlans,
    findNonPremiumDomainMaxes,
    findPremiumDomainMax,
    standaloneFilter,
    packageFilter,
    sortPlans,
    getSelectedPlansBenefitRiders,
} from './helpers/filterHelper';
import {
    handlePlansError,
    handleRequestPlans,
    handleRequestRates,
    handleRatesError,
    handleRequestBenefitRiderRates,
    handleRequestPlanRatesWithSelectedRiders,
    handleReceivePlanRatesWithSelectedRiders,
    handleSaveSelectedRidersInPlans,
    handleRestorePremiumOnDeselect
} from './helpers/fetchingReducerHelpers';
import {
    handleUpdatePlan,
    handleUpdateAllPlans,
    handleChangeSortOrder,
    handleChangeFilterCriteria,
    handleResetFilterCriteria,
    handleTogglePreferredPlans,
    handleChangeCustomPlanName,
    handleReceiveBenefitRiderRates,
} from './helpers/filteringReducerHelpers';
import { calculateQuickQuotesFromRates } from '../../utils/businessLogic/calculateQuotes';
import deepCopy from '../../utils/deepCopy';
import { updateRateForInAreaPlans } from '../../utils/businessLogic/InAreaRates';
import { generateSelectedPlansMap } from '../../utils/stateStorePayload/expand/expandFunctions';

const initialFilterCriteria = {
    favorite: false,
    planCode: null,
    ratingMethod: {
        age: false,
        tier: false,
    },
    planType: {
        medical: false,
        dental: false,
        vision: false,
    },
    ooa: {
        In: false,
        Out: false,
        'Out - Live and Travel': false,
        'Out - Travel': false,
    },
    tiered: {
        isTiered: false,
    },
    network: {},
    pcpRequired: {
        yes: false,
        no: false,
    },
    oopRange: [0, 9999999],
    dedRange: [0, 9999999],
    premRange: [0, 9999999],

    // unique to Fully Insured

    standaloneSelected: true,
    selectedPackageCode: null,

    metalType: {
        platinum: false,
        gold: false,
        silver: false,
        bronze: false,
    },
    licenseType: {
        hmo: false,
        ppo: false,
        ins: false,
        hny: false,
        epo: false,
    },
    hsaHra: {
        hsa: false,
        hra: false,
    },
    uhcRewardLevel: {
        uhcRewardsCore: false,
        uhcRewardsPremium: false,
    },
    riderDomesticPartner: {
        yes: false,
        no: false,
    },
    riderAge29: {
        yes: false,
        no: false,
    },
};

const initialfilterTotalsCache = {};

const initialVisiblePlanFilterTotals = {
    ratingMethod: {
        age: 0,
        tier: 0,
    },
    planType: {
        medical: 0,
        dental: 0,
        vision: 0,
    },
    metalType: {
        platinum: 0,
        gold: 0,
        silver: 0,
        bronze: 0,
    },
    licenseType: {
        hmo: 0,
        ppo: 0,
        ins: 0,
        hny: 0,
        epo: 0,
    },
    hsaHra: {
        hsa: 0,
        hra: 0,
    },
    uhcRewardLevel: {
        uhcRewardsCore: 0,
        uhcRewardsPremium: 0,
    },
    tiered: {
        isTiered: 0,
    },
    ooa: {
        In: 0,
        Out: 0,
        'Out - Live and Travel': 0,
        'Out - Travel': 0,
    },
    network: {},
    pcpRequired: {
        yes: 0,
        no: 0,
    },
    riderDomesticPartner: {
        yes: 0,
        no: 0,
    },
    riderAge29: {
        yes: 0,
        no: 0,
    },
};

const initialBreadCrumbAccess = {
    full: true,
    employee: false,
    plans: false,
    summary: false,
    enrollmanager: false,
    finalizecase: false
};

const initialContributions = {
    // Medical Contributions
    medicalEmployeeContributionMethod: '%',
    medicalDependentContributionMethod: '%',
    medicalEmployeeContribution: 50,
    medicalDependentContribution: 0,
    // Dental Contributions
    dentalEmployeeContributionMethod: '%',
    dentalDependentContributionMethod: '%',
    dentalEmployeeContribution: 0,
    dentalDependentContribution: 0,
    // Vision Contributions
    visionEmployeeContributionMethod: '%',
    visionDependentContributionMethod: '%',
    visionEmployeeContribution: 0,
    visionDependentContribution: 0,
    // life Contributions
    lifeEmployeeContributionMethod: '%',
    //lifeDependentContributionMethod: '%',
    lifeEmployeeContribution: 0,
    //lifeDependentContribution: 0,
    
    //Supp Life Contributions
    suppLifeEmployeeContributionMethod: '%',
    suppLifeEmployeeContribution: 0,
    
    medicalEmployeeContributionUpdated: false,

    stdEmployeeContributionMethod: '%',
    // stdDependentContributionMethod: '%',
    stdEmployeeContribution: 0,
    // stdDependentContribution: 0,

    ltdEmployeeContributionMethod: '%',
    // ltdDependentContributionMethod: '%',
    ltdEmployeeContribution: 0,
    // ltdDependentContribution: 0,
};

const initialState = {
    quickQuoteRequest: {
        groupSize: null,
        zipCode: null,
        zipObj: {},
        effectiveDate: null,
        stateCode: null,
        countyCode: null,
    },
    packages: [],
    selectedPackageLabel: null,
    isFetchingPlans: false,
    numRateBatchesFetching: 0,
    quickQuotePlansError: null,
    quickQuoteRatesError: null,
    zipCodeReturnError: null,
    sicCodeReturnError: null,
    isCirrus: true,
    planNetworks: [],
    plans: [],
    visiblePlans: [],
    selectedPlans: [],
    selectedPlansMap: {},
    favoritedPlans: [],
    visiblePlanFilterTotals: initialVisiblePlanFilterTotals,
    sortEnabled: true,
    ascending: true,
    numStandalonePlans: 0,
    numRatesFetched: 0,
    allRatesFetched: true,
    filterCriteria: initialFilterCriteria,
    breadCrumbAccess: initialBreadCrumbAccess,
    filterTotalsCache: initialfilterTotalsCache,
    oopDomainMax: [0, 9999999],
    dedDomainMax: [0, 9999999],
    premDomainMax: [0, 9999999],
    maxAgeBandedPlans: null,
    showPreferredPlans: true,
    showAnnualPremium: false,
    contributions: initialContributions,
    optionalBenefitRiders : [],
    selectedPlansBenefitRiders : [],
    numBenRidersRatesFetched : 0,
    allBenRidersRatesFetched : false,
    deselectedPlanCodes : [],
    allNewRatesFetched : true,
    medicalAndRxPlanCodeSearchError: ''
};

export default function quotes(state = initialState, action) {
    let newPlans;
    let newSelectedPlans;
    let newState;

    switch (action.type) {
        case types.SERVICE.FI.REQUEST_QUICK_QUOTE_PLANS:
            return handleRequestPlans(
                state,
                action,
                initialFilterCriteria,
                initialfilterTotalsCache,
            );

        // Configure returned plans details into array of 'plan' objects
        //
        // Parameters
        //  payload:        The returned json object holding all plan details
        case types.SERVICE.FI.RECEIVE_QUICK_QUOTE_PLANS: {
            // TODO: Keshav : Verify Selected Plans ( When we move QQ to FS)
            // TODO: Shrey : Verify Ben Rider Defect changes
            const {
                quickQuoteRequest: { zipObj: { zipCode: currentZip } },
                payload: { medicalPlans },
                medicalPlanCode
            } = action;
            const {
                quickQuoteRequest: { zipObj: { zipCode: prevZip } },
                selectedPlans: stateSelectedPlans,
                selectedPlansMap: stateSelectedPlansMap,
                favoritedPlans: stateFavoritedPlans,
                plans: statePlans
            } = state;

            const changedZip = currentZip !== prevZip;
            const selectedPlans = changedZip ? [] : stateSelectedPlans;
            const selectedPlansMap = changedZip ? {} : stateSelectedPlansMap;

            let plans = [];
            if (medicalPlanCode) {
                plans = statePlans.concat(medicalPlans.filter((plan) => plan.code === medicalPlanCode));
            } else {
                plans = medicalPlans.map((plan) => {
                    const selected = selectedPlans.some((pln) => (pln.code === plan.code));
                    const favorited = stateFavoritedPlans.some((pln) => (pln.code === plan.code));
                    return {
                        ...plan,
                        selected,
                        favorited,
                    };
                });
            }

            // Get a list of unique plan networks available
            const planNetworksObj = {};
            plans.forEach((plan) => {
                const network = plan.medicalPlanType;
                if (planNetworksObj[network] !== false) {
                    // We are setting it to FALSE so we can use this object as the initial filter criteria
                    planNetworksObj[network] = false;
                }
            });
            const planNetworkKeys = Object.keys(planNetworksObj);
            const totals = {
                ...state.visiblePlanFilterTotals,
                network: planNetworkKeys.reduce(
                    (obj, item) => ((obj[item] = 0), obj),
                    {},
                ),
            };

            let visiblePlans;
            const isCirrus = medicalPlans[0].platform === 'CIRRUS';
            let standaloneSelected = true;
            let numStandalonePlans = 0;
            let selectedPackageCode = null;
            if (!isCirrus) {
                const standalonePlans = plans.filter(standaloneFilter);
                numStandalonePlans = standalonePlans.length;
                if (action.payload.medicalPackages.length === 0) {
                    // If Prime zip code with 0 packages
                    // Display standalone plans (we assume that if there are 0 packages, then there aren't also 0 standalone plans - that would mean 0 total plans)
                    visiblePlans = standalonePlans;
                } else {
                    // Display plans from the 1st package
                    standaloneSelected = false;
                    selectedPackageCode = action.payload.medicalPackages[0].code;
                    visiblePlans = plans.filter((plan) => packageFilter(plan, selectedPackageCode));
                }
            } else {
                visiblePlans = JSON.parse(JSON.stringify(plans));
            }

            const favoritedPlans = [];
            const favoritedPlanCodes = JSON.parse(
                localStorage.getItem(
                    `favorites_${plans[0].platform}_${currentZip}_${action.quickQuoteRequest.countyCode}`,
                ) || '[]',
            );
            favoritedPlanCodes.forEach((code) => {
                const planIdx = plans.findIndex((plan) => plan.code === code);
                if (planIdx === -1) {
                    // if old favorited plans dont exist in current plans

                } else {
                    const visiblePlanIdx = visiblePlans.findIndex(
                        (plan) => plan.code === code,
                    );
                    plans[planIdx].favorited = true;
                    visiblePlans[visiblePlanIdx].favorited = true;
                    favoritedPlans.push(plans[planIdx]);
                }
            });

            return {
                ...state,
                quickQuoteRequest: action.quickQuoteRequest,
                isFetchingPlans: false,
                plans,
                visiblePlans,
                planNetworks: planNetworkKeys,
                favoritedPlans,
                selectedPlans,
                selectedPlansMap,
                packages: action.payload.medicalPackages,
                isCirrus,
                numStandalonePlans,
                visiblePlanFilterTotals: totals,
                filterCriteria: {
                    ...state.filterCriteria,
                    standaloneSelected,
                    selectedPackageCode,
                    network: planNetworksObj,
                    oopRange: findNonPremiumDomainMaxes(
                        'oop',
                        action.payload.medicalPlans,
                    ),
                    dedRange: findNonPremiumDomainMaxes(
                        'ded',
                        action.payload.medicalPlans,
                    ),
                },
                numRatesFetched: 0,
                oopDomainMax: findNonPremiumDomainMaxes(
                    'oop',
                    action.payload.medicalPlans,
                ), // update oopDomainMax & dedDomainMax
                dedDomainMax: findNonPremiumDomainMaxes(
                    'ded',
                    action.payload.medicalPlans,
                ),
                rxPlans: action.payload.rxPlans,
                optionalBenefitRiders: action.payload.optionalBenefitRider,
                selectedPlansBenefitRiders : isCirrus ? [] :  getSelectedPlansBenefitRiders(selectedPlans,action.payload.optionalBenefitRider)
            };
        }
        case types.SERVICE.FI.ERROR_QUICK_QUOTE_PLANS:
            return handlePlansError(state, action.payload);

        case types.SERVICE.FI.DISPLAY_MEDICAL_AND_RX_PLAN_SEARCH_ERROR_MESSAGE: {
            return {
                ...action.prevState,
                isFetchingPlans: false,
                medicalAndRxPlanCodeSearchError: action.payload
            }
        }
        
        case types.SERVICE.FI.CLEAR_ERROR_MESSAGE:
            return handlePlansError(state, action.payload);

        case types.SERVICE.FI.REQUEST_QUICK_QUOTE_RATES:
            return handleRequestRates(state);

        // Configures the rates response into the 'plans' array of objects (note that rates come in batches of 10)
        //
        // Parameters
        //  rates:          Array of rates - either age-banded or tiered
        //  index:          Keeps track of how many rates have already come. Necessary due to having to read rates 10 at a time
        //  quotes:         The calculated rate for the plan
        case types.SERVICE.FI.RECEIVE_QUICK_QUOTE_RATES: {
            newPlans = JSON.parse(JSON.stringify(state.plans));
            newSelectedPlans = JSON.parse(JSON.stringify(state.selectedPlans));
            const selectedPlanCodes = JSON.parse(localStorage.getItem('selected_medical') || '[]');
            const numRatesFetched = state.numRatesFetched + action.rates.length;
            const allRatesFetched = action.isProposal ? true : state.plans.length === numRatesFetched || action.planCodesLength === numRatesFetched;

            // Add the rates from this batch to their corresponding plans
            action.rates.forEach((rate) => {
                if (action.employees && action.platform && action.platform=='PRIME') {
                    // for in area plans we need to update the rates if employee is OOA
                    rate = updateRateForInAreaPlans(rate, action.employees, newPlans);
                }
                const allPlanIdx = newPlans.map((plan, idx) => plan.code === rate.medicalPlanCode ? idx : -1).filter(idx => idx !== -1);
                // const planIdx = newPlans.findIndex(
                //     (plan) => plan.code === rate.medicalPlanCode,
                // );
                // const selectedPlanIdx = newSelectedPlans.findIndex(
                //     (plan) => plan.code === rate.medicalPlanCode,
                // );

                const selectedPlanIdx = newSelectedPlans.map((plan, idx) => plan.code === rate.medicalPlanCode ? idx : -1).filter(idx => idx !== -1);

                const totalPremiumKey = state.filterCriteria.ratingMethod.age || state.filterCriteria.ratingMethod.tier 
                    ? state.filterCriteria.ratingMethod.age
                    ? 'premiumTotalAgeBandedMonthly'
                    : 'premiumTotalCompositeMonthly' 
                    : rate.defaultRatingType === 'ageBands' 
                    ? 'premiumTotalAgeBandedMonthly'
                    : 'premiumTotalCompositeMonthly';


                const employeeRateType = state.filterCriteria.ratingMethod.age || state.filterCriteria.ratingMethod.tier  
                    ? state.filterCriteria.ratingMethod.age 
                    ? 'employeeRatesAgeBanded' 
                    : 'employeeRatesComposite'
                    : rate.defaultRatingType === 'ageBands' 
                    ? 'employeeRatesAgeBanded'
                    : 'employeeRatesComposite';

                // Keep track of platform rating IDs that we SAVED upstream
                allPlanIdx.forEach(planIdx => {
                    let savedPlatformRatings;
                    if (action.savedUpstream && newPlans[planIdx].savedPlatformRatings) {
                        // Add this new saved rating to our history of saved ratings
                        savedPlatformRatings = newPlans[planIdx].savedPlatformRatings;
                        savedPlatformRatings.push({
                            id: rate.platformRatingID,
                            timestamp: rate.platformRatingTimestamp,
                        });
                    } else {
                        // Initialize the plan object with the history of saved ratings
                        savedPlatformRatings = rate.savedPlatformRatings || [];
                    }
                    const benefitRiderRates = newPlans[planIdx].benefitRiderRates ? newPlans[planIdx].benefitRiderRates : [];
                    const updatedEmployeeRates = rate.employeeRatesAgeBanded && rate.employeeRatesComposite ? rate[employeeRateType] : rate.employeeRates
                    newPlans[planIdx] = {
                        ...newPlans[planIdx],
                        ...rate,
                        employeeRates: updatedEmployeeRates,
                        savedPlatformRatings,
                        quote: rate[totalPremiumKey],
                        benefitRiderRates
                    };
                })

                selectedPlanIdx.forEach(index => {
                    let savedPlatformRatings;
                    if (action.savedUpstream && newSelectedPlans[index].savedPlatformRatings) {
                        // Add this new saved rating to our history of saved ratings
                        savedPlatformRatings = newSelectedPlans[index].savedPlatformRatings;
                        savedPlatformRatings.push({
                            id: rate.platformRatingID,
                            timestamp: rate.platformRatingTimestamp,
                        });
                    } else {
                        // Initialize the plan object with the history of saved ratings
                        savedPlatformRatings = rate.savedPlatformRatings || [];
                    }
                    const benefitRiderRates = newSelectedPlans[index].benefitRiderRates ? newSelectedPlans[index].benefitRiderRates : [];
                    const updatedEmployeeRates = rate.employeeRatesAgeBanded && rate.employeeRatesComposite ? rate[employeeRateType] : rate.employeeRates
                    newSelectedPlans[index] = {
                        ...newSelectedPlans[index],
                        ...rate,
                        employeeRates: updatedEmployeeRates,
                        savedPlatformRatings,
                        quote: rate[totalPremiumKey],
                        benefitRiderRates
                    };
                })
            });

            newPlans = sortPlans(newPlans, state.ascending);

            newState = {
                ...state,
                plans: newPlans,
                selectedPlans: newSelectedPlans,
                allRatesFetched,
                ...filterPlans(
                    newPlans,
                    state.filterCriteria,
                    state.isCirrus,
                    allRatesFetched,
                    state.filterTotalsCache,
                    state.sortEnabled,
                    state.ascending,
                ),
                numRateBatchesFetching: Math.max(state.numRateBatchesFetching - 1, 0),
                numRatesFetched,
            };
            // If we are done fetching rates, then we can find the premDomainMax
            if (!action.desQuote) {
                if (state.plans.length === numRatesFetched) {
                    newState.premDomainMax = [0, findPremiumDomainMax(newPlans)];
                    newState.filterCriteria.premRange = newState.premDomainMax;
                }
            }

            return newState;
        }

        case types.SERVICE.FI.CLEAR_SELECTED_PLANS:
            return {
                ...state,
                selectedPlans: [],
                selectedPlansMap: {},
            };

        case types.SERVICE.FI.ERROR_QUICK_QUOTE_RATES:
            return handleRatesError(state, action.payload);

        case types.REQUEST_BENEFIT_RIDER_RATES:
            return handleRequestBenefitRiderRates(state);

        case types.RECEIVE_BENEFIT_RIDER_RATES:
            return handleReceiveBenefitRiderRates(state,action);

        case types.REQUEST_PLAN_RATES_WITH_SELECTED_RIDERS :
            return handleRequestPlanRatesWithSelectedRiders(state,action)

        case types.RECEIVE_PLANS_RATES_WITH_SELECTED_RIDERS :
            return handleReceivePlanRatesWithSelectedRiders(state,action);

        case types.SAVE_SELECTED_RIDERS_IN_PLANS:
            return handleSaveSelectedRidersInPlans(state,action);

        case types.RESTORE_PREMIUM_ON_DESELECT:
            return handleRestorePremiumOnDeselect(state,action);

        case types.SERVICE.FI.CHANGE_FILTER_CRITERIA:
            return handleChangeFilterCriteria(state, action, {...initialFilterCriteria , network : state.filterCriteria.network});

        case types.SERVICE.FI.RESET_FILTER_CRITERIA:
            return handleResetFilterCriteria(state, initialFilterCriteria);

        case types.SERVICE.FI.CHANGE_SORT_ORDER:
            return handleChangeSortOrder(state, action);

        case types.SERVICE.FI.UPDATE_PLAN:
            return handleUpdatePlan(state, action, 'FI');

        case types.SERVICE.FI.UPDATE_ALL_PLANS:
            return handleUpdateAllPlans(state, action);

        // Updates the 'ages' array, then calculates the new rates for 'plans' and 'selectedPlans' arrays (but not
        // 'visiblePlans', which is updated in UPDATE_VISIBLE_PLANS. The function also automatically updates the filters
        // for premiums to allow for the new, and possibly higher, costs
        //
        // Parameters
        //  index:          The value indicating which employee had their age changed
        //  age:            The new age value to assign to the employee
        case types.SERVICE.FI.UPDATE_AGES: {
            const quotes = calculateQuickQuotesFromRates(
                state.plans,
                action.newAges,
                state.isCirrus,
            );
            let newMaxPrem = parseFloat(state.filterCriteria.premRange[1]);

            newPlans = state.plans.map((plan, i) => {
                if (quotes[i] > newMaxPrem) {
                    newMaxPrem = Math.ceil(parseFloat(quotes[i]) / 100) * 100;
                }
                return { ...plan, quote: quotes[i] };
            });

            newSelectedPlans = state.selectedPlans.map((plan, i) => ({ ...plan, quote: quotes[i] }));

            const newPremDomainMax = findPremiumDomainMax(newPlans);
            const finalPremDomainMax = newPremDomainMax > state.premDomainMax[1]
                ? newPremDomainMax
                : state.premDomainMax[1];

            const newFilterCriteria = {
                ...state.filterCriteria,
                premRange: [state.filterCriteria.premRange[0], newMaxPrem],
            };

            return {
                ...state,
                plans: newPlans,
                selectedPlans: newSelectedPlans,
                premDomainMax: [state.premDomainMax[0], finalPremDomainMax],
                filterCriteria: newFilterCriteria,
                visiblePlans: filterPlans(
                    newPlans,
                    newFilterCriteria,
                    state.isCirrus,
                    state.allRatesFetched,
                    state.filterTotalsCache,
                    state.sortEnabled,
                    state.ascending,
                ).visiblePlans,
            };
        }

        case types.UPDATE_BREADCRUMB_ACCESS:
            // REMOVE AFTER TESTING
            // const inefficientObject = initialBreadCrumbAccess;

            // Only allowing new access - never removing access
            // TODO: This may need to be changed. If and when a user goes back a page and changes information, new information might have to be entered
            // TODO: on the next screen fast forwarding will again be disallowed
            // REMOVE AFTER TESTING
            // inefficientObject[action.page] = true;

            return {
                ...state,
                // REMOVE AFTER TESTING
                // breadCrumbAccess: inefficientObject,
                breadCrumbAccess: {
                    ...state.breadCrumbAccess,
                    [action.page]: true,
                },
            };
        case types.RESET_BREADCRUMB_ACCESS:
            return {
                ...state,
                breadCrumbAccess: {
                    ...initialBreadCrumbAccess,
                    [action.page]: true,
                },
            };
        case types.ZIP_RETURN_ERROR:
            return {
                ...state,
                zipCodeReturnError: action.payload,
            };

        case types.SIC_RETURN_ERROR:
            return {
                ...state,
                sicCodeReturnError: action.payload,
            };

        case types.RECEIVE_ENROLLMENT_RULES:
            if (action.maxAgeBandedPlans) {
                return {
                    ...state,
                    maxAgeBandedPlans: action.maxAgeBandedPlans,
                };
            }
            return state;

        case types.SERVICE.FI.TOGGLE_PREFERRED_PLANS:
            return handleTogglePreferredPlans(state);

        case types.CHANGE_EMPLOYEE_CONTRIB:
            // If the ContributionSliderContainer slider changes, set amount and method to %
            if (action.product === 'none') {
                return {
                    ...state,
                    contributions: {
                        ...state.contributions,
                        medicalEmployeeContributionMethod: '%',
                        medicalDependentContributionMethod: '%',
                        medicalEmployeeContribution: action.payload,
                        medicalDependentContribution: 0,
                        medicalEmployeeContributionUpdated: true
                    },
                };
            }

            // Dependents only
            if (action.isDependent) {
                return {
                    ...state,
                    contributions: {
                        ...state.contributions,
                        [`${action.product}DependentContributionMethod`]: action.conversionMethod,
                        [`${action.product}DependentContribution`]: action.payload,
                    },
                };
                // Employees
            }

            return {
                ...state,
                contributions: {
                    ...state.contributions,
                    [`${action.product}EmployeeContributionMethod`]: action.conversionMethod,
                    [`${action.product}EmployeeContribution`]: action.payload,
                },
            };
        case types.CHANGE_EMPLOYEE_LIFE_CONTRIB:

            // If the ContributionSliderContainer slider changes, set amount and method to %
            if (action.product === 'none') {
                return {
                    ...state,
                    contributions: {
                        ...state.contributions,
                        lifeEmployeeContributionMethod: '%',
                        lifeDependentContributionMethod: '%',
                        lifeEmployeeContribution: action.payload,
                        lifeDependentContribution: 0,
                    },
                };
            }

            // Dependents only
            if (action.isDependent) {
                return {
                    ...state,
                    contributions: {
                        ...state.contributions,
                        [`${action.product}DependentContributionMethod`]: action.conversionMethod,
                        [`${action.product}DependentContribution`]: action.payload,
                    },
                };
                // Employees
            }

            return {
                ...state,
                contributions: {
                    ...state.contributions,
                    [`${action.product}EmployeeContributionMethod`]: action.conversionMethod,
                    [`${action.product}EmployeeContribution`]: action.payload,
                },
            };
        case types.TOGGLE_MONTHLY_ANNUAL:
            return {
                ...state,
                showAnnualPremium: !state.showAnnualPremium,
            };

        case types.RESET_TO_INITIALSTATE:
        case types.RESET_RENEWALS:
        case types.RESET_FLOW:
        case types.RESET_TO_INITIALSTATE_REN_PRIME_ANC:
            return deepCopy(initialState);
            // let newState = deepCopy(initialState)
            // newState.breadCrumbAccess = {
            //     full: true,
            //     employee: false,
            //     plans: false,
            //     summary: false,
            //     enrollmanager: false,
            // }
            // return newState;

        case types.SERVICE.FI.CHANGE_CUSTOM_PLAN_NAME:
            return handleChangeCustomPlanName(state, action);

        case types.UPDATE_SELECTED_MEDICAL_PLAN:
            return {
                ...state,
                selectedPlans: action.payload,
                // selectedPlansMap: {
                //     Single: action.payload,
                // },
                selectedPlansMap: generateSelectedPlansMap(action.payload),
            };

        case types.UPDATE_SAVE_STORE_VALUE:
            return {
                ...state,
                saveStoreUpdated: action.value,
            };

        case types.UPDATE_PACKAGE_LABEL:
            return {
                ...state,
                selectedPackageLabel: action.payload
            }
        case types.UPDATE_PREFERRED_PLANS_PKG:
            return {
                ...state,
                selectedPlans: action.selectedPlans,
                selectedPlansMap: action.selectedPlansMap
            }
        case types.USER_FAVORITE_MEDICAL_PLANS:
            const { plans, selectedPlans, visiblePlans, favoritedPlans } = action.allPlansObject
            return {
                ...state,
                plans,
                selectedPlans,
                visiblePlans,
                favoritedPlans
            }
        case types.INITIALIZE_MEDICAL_FILTER_CRITERIA:
            return {
                ...state,
                filterCriteria: {
                    ...initialState.filterCriteria,
                    ...action.payload
                }
            }
        default:
            return state;
    }
}
