import * as types from './actionTypes';
import { axios } from '../utils/api/axiosConfig';
import sendLog from './logActions';
import { history } from '../utils/history';
import { getEnrollmentRules, getRateAdjustmentRules } from './rulesActions';
import { filterReconciledCensus } from '../components/activities/Renewal/Review/reviewComponents/FilterIBR';
import { setSpecialtyCriteria } from './quickQuotesActions';
import { updateInitialStepperAccess, setMedicalCensusSummary } from './renewalActions';
import { getRenewalCompanyProfileData } from './companyProfileActions';
import { getEnrollmentForm } from './enrollmentFormActions';
import { createHistory } from './historyAction';
import { isUhcRenewals, isMmr } from '../utils/businessLogic/platformUtils';
import { resetFinalizeFlag } from './finalizeCaseSummaryActions';
import { findNYPlanCutoffDate } from '../utils/formatters/dates';
import { getZipObjUhcRenewals } from '../utils/Renewal/getZipObjUhc';
import { logError } from '../utils/errorLogging/logError';
import { KEY_NAME, UHC_REN_APIS } from '../utils/errorLogging/logKeys';
import { updateExchangeIndicator } from '../components/activities/Renewal/RenewalsCommonComponents/ComparePlans/RenewalComparePlans';

const apiRoutes = require('../utils/api/apiRouteConfig');

export function lastPageType(val) {
    return (dispatch) => {
        const page = {
            val,
        };
        dispatch({
            type: types.REQUEST_PAGE_TYPE,
            payload: page,
        });
    };
}

/** get review page details */
export function decideRenewalDetails(val, isPreviousPageEnrollment=false,isFinalizeStepper=false) {
    if (val.pageType === 'shopping') {
        // TODO add in logic for if pageType is shopping
    }else if(isMmr()){
        if(val.pageType !== 'finalize')
            history.push('/decideDetail');     
    }else{
        if(!isFinalizeStepper)
            history.push('/decideDetail'); 
        else
            history.push('/finalize')
    }
    return (dispatch, getState) => {
        const fullProps = getState();

        const decideDetailsRequest = {
            val,
        };
        dispatch({
            type: types.RESET_DECIDE_FLAG,
            payload: false
        });
        dispatch({
            type: types.ENABLE_RENEWAL_STEPPER_FLAG,
            payload: false
        });
        sendLog({
            action: 'SUBMIT_DECIDE_REQUEST',
            ...decideDetailsRequest,
        });

        const memberGroupId = fullProps.review?.val?.customerNumber || fullProps.review?.renewalCaseData?.memberGroupId;
        const quoteTrackingNumber = fullProps.caseTracking.currentQuoteId;
        const effectiveDate = fullProps.renewals?.renewalCaseData?.effectiveDate || fullProps.review?.renewalCaseData?.effectiveDate;
        const priorYearDataSelected = fullProps.renewals && fullProps.renewals.priorYearDataSelected ? fullProps.renewals.priorYearDataSelected : 'N';
        let decideRequest = {
            "memberGroupId": memberGroupId,
            "quoteTrackingNumber": quoteTrackingNumber
        };

        const isUhcRenewal = isUhcRenewals();
        
        dispatch(getRenewalCompanyProfileData(memberGroupId, false));
        const apiRequestUrl = `${apiRoutes.quickQuotes}${isUhcRenewal ? '/uhcRenewal' : ''}/decidePageData?memberGroupId=${memberGroupId}&quoteTrackingNumber=${quoteTrackingNumber}&priorYearDataSelected=${priorYearDataSelected}&effectiveDate=${effectiveDate}`;

        axios.get(apiRequestUrl)
            .then((res) => {
                if (res.data.stepperAccess)
                    dispatch(updateInitialStepperAccess(res.data.stepperAccess))
                else {
                    const initialStepperAccess = {
                        renewaldashboard: true,
                        reviewDetail: true,
                        decide: false,
                        enrollment: false,
                        finalize: false,
                        finalizeCaseRenewal:false
                    }
                    dispatch(updateInitialStepperAccess(initialStepperAccess))
                }
                if(val.pageType && val.pageType=='review' && res.data.shoppingPlans && res.data.shoppingPlans.length>0){
                    res.data.shoppingPlans=updateExchangeIndicator(res.data.shoppingPlans)
                }
                dispatch(getEnrollmentForm(res.data.renewalCaseData.situsState, 'renewals'))
                dispatch({
                    type: types.RECEIVE_DECIDE_DETAILS,
                    payload: res.data,
                    decideDetailsRequest,
                    source: 'decide-details',
                });
                dispatch({
                    type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                    payload: true
                });
                dispatch({
                    type: types.RESET_DECIDE_FLAG,
                    payload: true
                });
                let zipObj = isUhcRenewal ? getZipObjUhcRenewals(res.data.renewalCaseData) : {
                    "zipCode": res.data.renewalData.memberGroup.locations.location.zipCode,
                    "city": res.data.renewalData.memberGroup.locations.location.city,
                    "stateCode": res.data.renewalData.memberGroup.locations.location.state,
                    "county": res.data.renewalData.memberGroup.locations.location.county,
                }
                let employeeCount = res.data.renewalData.memberGroup.locations.employeeCounts[0].count != 0 ? res.data.renewalData.memberGroup.locations.employeeCounts[0].count : res.data.renewalData.memberGroup.locations.employeeCounts[1].count
                dispatch(getEnrollmentRules(!isUhcRenewal ? 'CIRRUS': 'PRIME', zipObj, res.data.renewalCaseData.effectiveDate, employeeCount))
                dispatch(getRateAdjustmentRules(res.data.renewalCaseData.situsState, res.data.renewalData.memberGroup.locations.employeeCounts[0].count, res.data.renewalCaseData.effectiveDate))

                if (res.data && res.data.renewalData && res.data.renewalData.Ibr) {
                    const coverageCounts = filterReconciledCensus(res.data)
                    const isMMRCase = isMmr()
                    dispatch(setSpecialtyCriteria({
                        ...fullProps.specialtyCriteria,
                        dentalCensusSummary: coverageCounts.dentalCensus,
                        visionCensusSummary: coverageCounts.visionCensus,
                        lifeCensusSummary: coverageCounts.lifeCensus,
                        dependentLifeCensusSummary: coverageCounts.dependentLifeCensus,
                        platform: isMMRCase || isUhcRenewal ? res.data.renewalCaseData.platform : res.data.salesRepData[0].platform.toUpperCase(),
                    }))
                    if(isUhcRenewal) {
                        dispatch(setMedicalCensusSummary({
                            medicalCensusSummary: coverageCounts.medicalCensus 
                        }));
                    }
                } else if (res.data && res.data.renewalData && res.data.renewalData.members) {
                    const coverageCounts = filterReconciledCensus(res.data);
                    dispatch(setSpecialtyCriteria({
                        ...fullProps.specialtyCriteria,
                        dentalCensusSummary: coverageCounts.dentalCensus,
                        visionCensusSummary: coverageCounts.visionCensus,
                        lifeCensusSummary: coverageCounts.lifeCensus,
                        dependentLifeCensusSummary: coverageCounts.dependentLifeCensus,
                        platform: 'CIRRUS',
                    }))
                }
                if (isMmr() && val.pageType === 'finalize') {
                    dispatch(resetFinalizeFlag(true))
                }
                dispatch(createHistory("Renewal Decide request", "Decide", "Success", decideRequest, res.data, 'RL'));
            })
            .catch((err) => {
                dispatch(createHistory("Renewal Decide request", "Decide", "Error", decideRequest, err, 'RL'));
                dispatch({
                    type: types.ENABLE_RENEWAL_STEPPER_FLAG,
                    payload: true
                });
                dispatch({
                    type: types.ERR_DECIDE_DETAILS,
                    payload: err,
                });
                dispatch({
                    type: types.RESET_DECIDE_FLAG,
                    payload: true
                });
                if (isMmr() && val.pageType === 'finalize') {
                    dispatch(resetFinalizeFlag(true))
                }
                if(isUhcRenewal) {
                    const reqBody = {
                        memberGroupId, quoteTrackingNumber, priorYearDataSelected
                    }
                    logError(KEY_NAME, UHC_REN_APIS.DECIDE_PAGE_DATA, 'decideActions.decideRenewalDetails', reqBody, err);
                }
            });
    };
}
export function updateDecideRenewalDetails(decideData) {
    return (dispatch, getState) => {
        let reqUrl;
        const isUhcRenewal = isUhcRenewals();
        if(!isUhcRenewal) {
            reqUrl = `${apiRoutes.quickQuotes}/renewalUpdate/`
        } else {
            reqUrl = `${apiRoutes.quickQuotes}/uhcRenewal/renewalUpdateUhc/`
            decideData.isMMRCase = isMmr()
            decideData.effectiveDate = getState().renewals?.renewalCaseData?.effectiveDate
            decideData.isPriorYear = getState().renewals?.priorYearDataSelected === 'Y'
        }
        axios.put(reqUrl, decideData)
            .then(() => {
                dispatch({
                    type: types.UPDATED_DECIDE_DETAILS,
                    decide: {
                        ...decideData,
                    },
                });
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_UPDATE_DECIDE_DETAILS,
                    payload: err,
                });
                if(isUhcRenewal) {
                    logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'decideActions.decideToFinalize', decideData, err);
                }
            });
    };
}

export function decideToFinalize(decideData) {
    return (dispatch, getState) => {
        dispatch({
            type: types.UPDATE_BEEN_TO_FINALIZE,
            payload: true,
        });
        const fullProps = getState()
        const { caseTracking } = fullProps;
        const quoteTrackingNumber = caseTracking.currentQuoteId;
        decideData.quoteTrackingNumber = quoteTrackingNumber;
        let reqUrl;
        const isUhcRenewal = isUhcRenewals(); 
        if(!isUhcRenewal) {
            reqUrl = `${apiRoutes.quickQuotes}/renewalUpdate/`
        } else {
            reqUrl = `${apiRoutes.quickQuotes}/uhcRenewal/renewalUpdateUhc/`
            decideData.effectiveDate = getState().renewals?.renewalCaseData?.effectiveDate
            decideData.isPriorYear = getState().renewals?.priorYearDataSelected === 'Y'
        }

        axios.put(reqUrl, decideData)
            .then(() => {
                dispatch({
                    type: types.UPDATED_DECIDE_DETAILS,
                    decide: {
                        ...decideData,
                    },
                });
                history.push('/finalize');
            })
            .catch((err) => {
                dispatch({
                    type: types.ERR_UPDATE_DECIDE_DETAILS,
                    payload: err,
                });
                if(isUhcRenewal) {
                    logError(KEY_NAME, UHC_REN_APIS.RENEWAL_UPDATE, 'decideActions.decideToFinalize', decideData, err);
                }
            });
    };
}
export function updateDecideData(decideData) {
    return (dispatch) => {
        dispatch({
            type: types.RECEIVE_DECIDE_DETAILS,
            payload: decideData
        });
        dispatch({
            type: types.GET_ENROLLMENT_DETAILS,
            payload: decideData,
            selectedProducts: decideData.selectedProducts,
            newProducts: decideData.newProducts
        });
    };
}
export function updatePremiumToggle(toggleData) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_PREMIUM_TOGGLE_VALUE',
            toggleData,
        });
        dispatch({
            type: types.UPDATE_PREMIUM_TOGGLE_VALUE,
            payload: toggleData,
        });
    };
}

export function removeDecidePlan(query) {
    const data = {
        query,
    };
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_DECIDE_PLAN',
            query,
        });
        dispatch({
            type: types.REMOVE_DECIDE_PLAN,
            payload: data,
        });
    };
}

export function updateSelectedDecidePlan(query, value) {
    const data = {
        query,
        value,
    };
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_SELECTED_PLAN_DECIDE',
            query,
        });
        dispatch({
            type: types.UPDATE_SELECTED_PLAN_DECIDE,
            payload: data,
        });
    };
}

export function updateMDVContDecide(query) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE MDV CONTRIBUTION DECIDE',
            query,
        });
        dispatch({
            type: types.UPDATE_MDV_CONTRIBUTION_DECIDE,
            payload: query,
        });
    };
}

export function saveRemoveModalPreference(value) {
    const flag = value;
    return (dispatch) => {
        sendLog({
            action: 'REMOVE_MODAL_PREFERENCE',
            flag,
        });
        dispatch({
            type: types.REMOVE_MODAL_PREFERENCE,
            payload: flag,
        });
    };
}

export function addEnrollPlans(planCode, flag, optionPlan, isAdditionalPlan, planType = '') {
    const data = {
        planCode,
        isEnrolled: flag,
        optionPlan,
        isAdditionalPlan,
        planType,

    };
    return (dispatch) => {
        dispatch(createHistory('Add Enrolled Plans', 'Decide', 'Success', planType, data, 'RL'));
        sendLog({
            action: 'ADD_ENROLLED_PLANS',
            data,
        });
        dispatch({
            type: types.ADD_ENROLLED_PLANS,
            payload: data,
        });
    };
}

export function updateEnrollPlans(planCode, flag) {
    const data = {
        planCode,
        isEnrolled: flag,
    };
    return (dispatch) => {
        dispatch(createHistory('Update Enrolled Plans', 'Decide', 'Success', {}, data, 'RL'));
        sendLog({
            action: 'UPDATE_ENROLLED_PLANS',
            data,
        });
        dispatch({
            type: types.UPDATE_ENROLLED_PLANS,
            payload: data,
        });
    };
}

export function addMaxPlansSelectionLimit(effectiveDate, state = "") {
    let data = {}
    let effDate = ""
    if (state && state === "NY" && effectiveDate) {
        let tempEffDate = ""
        if (effectiveDate && effectiveDate.includes('T')) {
            tempEffDate = effectiveDate.split('T')
            effDate = tempEffDate[0]
        }
        const { newCutoffDate, currentDate } = findNYPlanCutoffDate(effectiveDate)
        if (currentDate.isAfter(newCutoffDate)) {
            data.NY = 3
            data.CT = 4
            data.NJ = 4
        } else {
            data.NY = 4
            data.CT = 4
            data.NJ = 4
        }
    } else {
        data = {
            NY: 3,
            CT: 4,
            NJ: 4,
        };
    }
    return (dispatch) => {
        sendLog({
            action: 'ADD_MAX_PLAN_SELECTION_LIMIT',
            data,
        });
        dispatch({
            type: types.ADD_MAX_PLAN_SELECTION_LIMIT,
            payload: data,
        });
    };
}

export function updateSelectedEnrollPlansCount(count) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_ENROLLED_PLANS_SELECTED_COUNT',
            count,
        });
        dispatch({
            type: types.UPDATE_ENROLLED_PLANS_SELECTED_COUNT,
            payload: count,
        });
    };
}

export function updateSelectedEnrollAdditionalPlansCount(count) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_ENROLL_ADDITIONAL_PLANS_SELCETED_COUNT',
            count,
        });
        dispatch({
            type: types.UPDATE_ENROLL_ADDITIONAL_PLANS_SELCETED_COUNT,
            payload: count,
        });
    };
}

export function updateIsErrorSelectedEnrollPlansCount(errorValue) {
    return (dispatch) => {
        sendLog({
            action: 'IS_ERROR_ENROLL_PLANS_COUNT',
        });
        dispatch({
            type: types.IS_ERROR_ENROLL_PLANS_COUNT,
            payload: errorValue,
        });
    };
}

export function daysLeftForRenewal(days) {
    return (dispatch) => {
        sendLog({
            action: 'DAYS_LEFT_FOR_RENEWAL',
            days,
        });
        dispatch({
            type: types.DAYS_LEFT_FOR_RENEWAL,
            payload: days,
        });
    };
}

export function isEligibleToFinalizeFromDecide(isEligibleBool) {
    return (dispatch) => {
        sendLog({
            action: 'IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE',
            isEligibleBool,
        });
        dispatch({
            type: types.IS_ELIGIBLE_TO_FINALIZE_FROM_DECIDE,
            value: isEligibleBool,
        });
    };
}

export function updateBeenToFinalize(val) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_BEEN_TO_FINALIZE',
            val,
        });
        dispatch({
            type: types.UPDATE_BEEN_TO_FINALIZE,
            payload: val,
        });
    };
}

export function updateBeenToRenewalShopping(val) {
    return (dispatch) => {
        sendLog({
            action: 'UPDATE_BEEN_TO_RENEWALSHOPPING',
            val,
        });
        dispatch({
            type: types.UPDATE_BEEN_TO_RENEWALSHOPPING,
            payload: val,
        });
    };
}
export function addShoppingPlansToDecide() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const shoppingPlans = fullProps.renewalShopping.selectedPlans.length > 0 ?
            fullProps.renewalShopping.selectedPlans : [];
        sendLog({
            action: 'ADD_SHOPPING_PLANS_TO_DECIDE',
            shoppingPlans,
        });
        dispatch({
            type: types.ADD_SHOPPING_PLANS_TO_DECIDE,
            payload: shoppingPlans,
        });
    };
}

export function updatePlanSelectedDecide() {
    return (dispatch, getState) => {
        const fullProps = getState();
        const newshopList = fullProps.decide.shoppingPlans.map((item) => {
            const updatedItem = {
                ...item,
                selected: false,
            };
            return updatedItem;
        });
        sendLog({
            action: 'UPDATE_PLAN_SELECTION_DECIDE',
            newshopList,
        });
        dispatch({
            type: types.UPDATE_PLAN_SELECTION_DECIDE,
            payload: newshopList,
        });
    };
}
export function addEnrolledPlans() {
    return (dispatch) => {
        sendLog({
            action: 'SET_ENROLLED_PLANS_DECIDE',
        });
        dispatch({
            type: types.SET_ENROLLED_PLANS_DECIDE,
        });
    };
}

export function adjustRenewalsRatesAndPremium(serviceType, premium, rates, adjustments, planCode,isAllowed = true, isAgeBanded = false) {
    return (dispatch, getState) => {
        const fullProps = getState();

        sendLog({
            action: 'ADJUST RENEWALS RATES AND PREMIUMS',
        });

        dispatch({
            type: types.SERVICE[serviceType].ADJUST_RENEWALS_RATE,
            premium,
            rates,
            adjustments,
            planCode,
            serviceType,
            isUhcRenewals: isUhcRenewals(),
            isMMRCase: isMmr(),
            isAllowed,
            groupSize: fullProps.companyProfile.groupSize,
            isAgeBanded
        });
        dispatch({
            type: types.UHC_RENEWALS_UPDATE_USER_ADJUST_FLAG,
            product: serviceType.toLowerCase()
        })
    };
}

export function togglePlanEnrollmentSpecialityRenewals(plan, withinPackage, serviceType = 'FI') {
    return (dispatch, getState) => {
        sendLog({
            action: 'TOGGLE PLAN RENEWAL ENROLLMENT SPECIALITY',
            planCode: plan.codeDisplay,
        });

        const usingAgeBandedRating = getState().quotes.filterCriteria
            .ratingMethod.age;

        dispatch({
            type: types.SERVICE[serviceType].TOGGLE_PLAN_ENROLL_RENEWAL,
            plan,
            withinPackage,
            usingAgeBandedRating,
        });
    };
}

export function updateRenewalsCustomPlanName(productType, planCode, customName) {
    return (dispatch) => {

        sendLog({
            action: 'UPDATE_RENEWALS_CUSTOM_PLAN_NAME',
            productType,
            planCode,
            customName
        });

        dispatch({
            type: types.UPDATE_RENEWALS_CUSTOM_PLAN_NAME,
            productType,
            planCode,
            customName,
        });
    };
}

export function setHWTriggerError(flag) {
    return (dispatch) => {
        dispatch({
            type: types.SET_HW_TRIGGER_ERROR,
            payload: flag
        });
    }
}

export function resetDecideFlag(val) {
    return (dispatch) => {
        dispatch({
            type: types.RESET_DECIDE_FLAG,
            payload: val
        });
    }
}

export function resetSpecialtyReducers() {
    return (dispatch) => {
        dispatch({
            type: types.RESET_SHOPPING_FLOW_SPECIALTY,
        });
    }
}

export function updateSelectedPlans(selectedPlans, product) {
    return (dispatch) => {
        dispatch({
            type: types.UPDATE_DECIDE_SELECTED_PLANS_MMR,
            product,
            payload: selectedPlans
        })
    }
}